import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LoadingTableDataIcon } from '../LoadingTableDataIcon';
import { AgGridReact } from 'ag-grid-react';
import { motion } from 'framer-motion';
import { Pagination } from '@mantine/core';

interface IComponentProps {
  refs?: any;
  columnDefs: any;
  rowData?: any;
  loading?: boolean;
  setLoading?: any | null;
  onRowClicked?: any;
  onRowDoubleClicked?: any;
  onCellClicked?: any;
  onCellDoubleClicked?: any;
  onCellValueChanged?: any;
  onRowDragEnd?: any;
  onFirstDataRendered?: any;
  rowDragManaged?: boolean;
  animateRows?: boolean;
  sizeColumnsToFit?: boolean;
  defaultColDef?: any;
  rowSelection?: any;
  overlayNoRowsTemplate?: string;
  stopEditingWhenCellsLoseFocus?: boolean;
  className?: string;
  rowHeight?: number;
  visiblePaging?: boolean;
  paging?: {
    totalPage?: number;
    currentPage?: number;
  };
  onChangePage?: any;
  rowClassRules?: any;
  rowStyle?: any;
  getRowStyle?: any;
  suppressCellFocus?: boolean;
}

/**
 * AgGridReact
 * @param columnDefs 컬럼
 * @param rowData 행 데이터
 * @constructor
 */
const PbAgGridReact = ({
  refs = null,
  columnDefs,
  rowData,
  loading = false,
  setLoading = null,
  onRowClicked = (row: any) => {},
  onRowDoubleClicked = (row: any) => {},
  onCellClicked = (event: any) => {},
  onCellDoubleClicked = (event: any) => {},
  onCellValueChanged = (event: any) => {},
  onRowDragEnd = (event: any) => {},
  onFirstDataRendered = (params: any) => {},
  rowDragManaged = false,
  animateRows = false,
  sizeColumnsToFit = false,
  defaultColDef = {},
  rowSelection = 'single',
  overlayNoRowsTemplate = '데이터가 없습니다.',
  stopEditingWhenCellsLoseFocus = true,
  className = '',
  rowHeight = 45,
  visiblePaging = false,
  paging = {
    totalPage: 1,
    currentPage: 1,
  },
  onChangePage = (event: any) => {},
  rowClassRules,
  rowStyle = {},
  getRowStyle = (event: any) => {},
  suppressCellFocus = false,
}: PropsWithChildren<IComponentProps>) => {
  // 테이블의 참조를 정의함
  const gridRef = useRef<any>(null);

  // 페이징의 현재 페이지를 정의함
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(
    paging?.currentPage!,
  );

  // 컬럼 가로 크기를 비율로 조장해서 테이블 가로 크기 안에 맞도록 조정함
  const handle_onGridSizeChanged = useCallback(() => {
    if (sizeColumnsToFit) {
      if (refs === null) {
        gridRef.current.api.sizeColumnsToFit();
      } else {
        refs.current.api.sizeColumnsToFit();
      }
    }
  }, []);

  useEffect(() => {
    return () => {};
  }, []);

  // 데이터가 변경될 때 실행함
  useEffect(() => {
    if (setLoading !== null) {
      // 로딩바를 숨김
      setLoading(false);
    }

    return () => {};
  }, [rowData]);

  useEffect(() => {
    // 페이징의 현재 페이지에 적용함
    setCurrentPageIndex(paging?.currentPage!);

    return () => {};
  }, [paging?.currentPage]);

  return (
    <div className="relative w-full h-full">
      {/* 로딩 중 아이콘 */}
      <motion.div
        initial="close"
        animate={loading ? 'open' : 'close'}
        variants={{
          open: {
            display: 'block',
            opacity: 1,
            transition: {
              duration: 0,
            },
          },
          close: {
            opacity: 0,
            transition: {
              type: 'spring',
              bounce: 0,
              duration: 0.2,
            },
            transitionEnd: {
              display: 'none',
            },
          },
        }}
        // className="absolute left-0 top-0 w-full h-full border-1 border-gray-300/50 z-20"
        className="absolute left-0 top-0 w-full h-full z-20"
      >
        <div className="w-full h-full bg-white/80">
          <LoadingTableDataIcon visible={true} />
        </div>
      </motion.div>

      <div className="w-full h-full">
        <div
          style={{
            height: visiblePaging ? 'calc(100% - 50px)' : '100%',
          }}
          className="w-full"
        >
          <AgGridReact
            ref={refs === null ? gridRef : refs}
            columnDefs={columnDefs}
            rowData={rowData}
            onRowClicked={onRowClicked}
            onRowDoubleClicked={onRowDoubleClicked}
            onCellClicked={onCellClicked}
            onCellDoubleClicked={onCellDoubleClicked}
            onCellValueChanged={onCellValueChanged}
            onRowDragEnd={onRowDragEnd}
            defaultColDef={defaultColDef}
            rowSelection={rowSelection}
            animateRows={animateRows}
            onGridSizeChanged={handle_onGridSizeChanged}
            onComponentStateChanged={handle_onGridSizeChanged}
            overlayNoRowsTemplate={overlayNoRowsTemplate}
            stopEditingWhenCellsLoseFocus={stopEditingWhenCellsLoseFocus}
            className={['ag-theme-alpine', className].join(' ')}
            rowHeight={rowHeight || 45}
            rowClassRules={rowClassRules}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            suppressCellFocus={suppressCellFocus}
            // domLayout="autoHeight"
          ></AgGridReact>
        </div>

        {/* 페이징 */}
        {visiblePaging && (
          <div
            style={{ height: '50px' }}
            className="flex justify-center items-end"
          >
            <Pagination
              total={paging?.totalPage!}
              siblings={3}
              value={currentPageIndex}
              onChange={(event: any) => {
                // 페이징의 현재 페이지에 적용함
                setCurrentPageIndex(event);

                onChangePage(event);
              }}
              size="lg"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PbAgGridReact;
