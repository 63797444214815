import { atom } from 'recoil';
import { IImageCarousel } from '../interfaces/app.interface';

/**
 * 이미지 캐러셀
 */
export const imageCarouselStore = atom({
  key: 'imageCarouselStore',
  default: {
    imageList: [],
    startIndex: 0,
  } as IImageCarousel,
});
