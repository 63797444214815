import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbMonthPicker } from '../PbMonthPicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModalPageScrollTabStep,
  IComponentModalPageScrollTab,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as cmnApi from '../../apis/cmn.api';
import _ from 'lodash';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  Select,
  Radio,
  Checkbox,
  Input,
  Modal,
  PasswordInput,
  TextInput,
  Group,
  Popover,
} from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import { number } from 'prop-types';
import { PbAgGridReact } from '../PbAgGridReact';
import PbSelect from '../PbSelect/PbSelect.component';
import { phoneNumberformatter } from '../../utils/app.util';
import { notifications } from '@mantine/notifications';
import { MN2302220202_ManagerClient } from '../MN2302220202_ManagerClient';
import * as slsApi from '../../apis/sls.api';
import { ValueFormatterParams } from 'ag-grid-community';
import { decode } from 'html-entities';
import * as appUtil from '../../utils/app.util';

interface IComponentProps {
  id?: string;
  userId?: string;
  useModifyMode?: boolean;
  data?: any;
  onClick?: () => void;
}

/**
 * 시스템 관리 > 사용자 관리 > 사용자 상세, 수정
 * @param id 컴포넌트 모달 아이디
 * @param userId 사용자 아이디
 * @param useModifyMode 수정 모드 사용 여부
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220870 = ({
  id = '',
  userId = '',
  useModifyMode = false,
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 테이블을 정의함
  const [readAuthTargetUserTable, setReadAuthTargetUserTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  const readAuthTargetUserTableRef = useRef<any>(null);

  // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 팝업 출력 여부를 정의함
  const [visibleReadAuthTargetUser, setVisibleReadAuthTargetUser] =
    useState<boolean>(false);

  // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 검색 폼 그리드 > 검색 > 셀렉트를 정의함
  const [readAuthTargetUserSearchSelect, setReadAuthTargetUserSearchSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 검색 폼 그리드 > 검색 > 입력을 정의함
  const [readAuthTargetUserSearchInput, setReadAuthTargetUserSearchInput] =
    useState<string>('');

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    if (!userId) {
      // 신규
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '사용자',
          targetId: 'step-2',
        },
        {
          label: '영업기회 조회 권한',
          targetId: 'step-4',
        },
      ];
    } else if (userId && !useModifyMode) {
      // 조회
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '사용자',
          targetId: 'step-2',
        },
        {
          label: '접속 이력',
          targetId: 'step-3',
        },
        {
          label: '영업기회 조회 권한',
          targetId: 'step-4',
        },
      ];
    } else if (userId && useModifyMode) {
      // 수정
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '사용자',
          targetId: 'step-2',
        },
        {
          label: '영업기회 조회 권한',
          targetId: 'step-4',
        },
      ];
    }

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 검색 > 셀렉트의 아이템을 초기화함
  const initReadAuthTargetUserSearchSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '고객사명',
        value: 'bp_name',
      },
      {
        label: '영업기회명',
        value: 'sls_prtn_name',
      },
      {
        label: '영업담당자명',
        value: 'ntrn_cntc_name',
      },
      {
        label: '태그',
        value: 'tag',
      },
    );

    // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    setReadAuthTargetUserSearchSelect((pre: ISelect) => ({
      value: 'bp_name',
      item: tmpOptionItem,
    }));
  };

  // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 테이블을 초기화함
  const initReadAuthTargetUserTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'checkbox',
        width: 60,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      },
      {
        headerName: '영업기회명',
        field: 'slsPrtnName',
        width: 250,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.slsPrtnName);
        },
      },
      {
        headerName: 'Industry',
        children: [
          {
            headerName: '그룹',
            field: 'ndstDntfName',
            width: 150,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.data.ndstDntfName);
            },
          },
          {
            headerName: '분류',
            field: 'ndstSubName',
            width: 150,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.data.ndstSubName);
            },
          },
        ],
      },
      {
        headerName: '고객사',
        children: [
          {
            headerName: '이름',
            field: 'bpName',
            width: 220,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.data.cmpnRgstNmbr);
            },
          },
          {
            headerName: '사업자등록번호',
            field: 'cmpnRgstNmbr',
            width: 150,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.data.cmpnRgstNmbr);
            },
          },
          {
            headerName: 'Account',
            field: 'cntGrpName',
            width: 200,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.data.cntGrpName);
            },
          },
          {
            headerName: '매출처',
            field: 'slsPlcName',
            width: 200,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.data.slsPlcName);
            },
          },
        ],
      },
      {
        headerName: 'Solution',
        children: [
          { headerName: '그룹', field: 'slsSltnName', width: 150 },
          { headerName: '분류', field: 'slsSubName', width: 150 },
        ],
      },
      { headerName: '딜종류', field: 'slsDealName', width: 150 },
      { headerName: '납품구분', field: 'slsDlvrName', width: 150 },
      {
        headerName: '프로젝트기간',
        children: [
          { headerName: '수주일', field: 'xpctFxdDate', width: 130 },
          { headerName: '시작일', field: 'stmtStrtDate', width: 130 },
          { headerName: '완료일', field: 'stmtCmplDate', width: 130 },
        ],
      },
      {
        headerName: '규모(원)',
        children: [
          { headerName: '계약', field: 'cntrSizeMnt', width: 130 },
          { headerName: 'NSR', field: 'nsrSclMnt', width: 130 },
        ],
      },
      { headerName: 'NSR X 확도', field: 'nsrxprb', width: 130 },
      { headerName: '영업단계', field: 'slsStgName', width: 130 },
      { headerName: '확도', field: 'slsPrbbName', width: 90 },
      {
        headerName: '제안결과',
        children: [
          { headerName: '결과', field: 'sgstRsltName', width: 130 },
          { headerName: '결과코드', field: 'sgstRsltCode', width: 130 },
        ],
      },
      { headerName: '담당자', field: 'ntrnCntcName', width: 100 },
      { headerName: '등록자', field: 'rgstName', width: 100 },
      { headerName: '등록일시', field: 'rgstDate', width: 130 },
    );

    // 테이블에 적용함
    setReadAuthTargetUserTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (readAuthTargetUserTable.column.length === 0) {
      return;
    }

    getReadAuthTargetUserTableData();

    return () => {};
  }, [readAuthTargetUserTable.column]);

  // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 테이블 데이터를 불러옴
  const getReadAuthTargetUserTableData = () => {
    slsApi
      .getSlsPrtns({
        searchKeyword: readAuthTargetUserSearchInput.trim(),
        searchType: readAuthTargetUserSearchSelect.value,
        currPageIdx: 1,
        pageSize: 10000,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          data.data.list.map((item: any, index: number) => {
            item.cntrSizeMnt = addComma(item.cntrSizeMnt);
            item.nsrSclMnt = addComma(item.nsrSclMnt);
          });

          // 검색 결과 테이블에 적용함
          setReadAuthTargetUserTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
            }),
          );
        }
      })
      .catch((error: any) => {});
  };

  // 숫자를 천단위 , 추가
  const addComma = (value: string) => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  /**
   * 기본 정보
   */

  // 셀렉트의 아이템이 기본값 이름을 반환함
  const noneSelect = (): string => {
    let result: string = '';

    if (!(userId && !useModifyMode)) {
      result = '선택하세요.';
    }

    return result;
  };

  /* 회사명 */
  // 기본정보 > 회사명 셀렉트를 정의함
  const [cmpnNameSelect, setCmpnNameSelect] = useState<string>('');
  const [cmpnNameSelectItem, setCmpnNameSelectItem] = useState<IOptionItem[]>(
    [],
  );

  // 기본정보 > 회사명 셀렉트에서 선택한 아이템을 변경함
  const handleCmpnNameSelect_onChange = (event: any) => {
    setCmpnNameSelect(event);
  };

  // 기본정보 > 회사명 셀렉트의 아이템을 초기화함
  const initCmpnNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: noneSelect(),
      value: '',
    });

    // 회사명을 불러옴
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'CMPN', // 공통 - 상위코드 - 회사
      })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 사용자 등록 > 기본정보 > 회사명 > 셀렉트의 아이템에 적용함
        setCmpnNameSelectItem(tmpOptionItem);
        // setCmpnNameSelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  /* 권한 그룹명 */
  // 기본정보 > 권한 그룹명 셀렉트를 정의함
  const [thrtGrpNameSelect, setThrtGrpNameSelect] = useState<string>('');
  const [thrtGrpNameSelectItem, setThrtGrpNameSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 기본정보 > 권한 그룹명 셀렉트에서 선택한 아이템을 변경함
  const handleThrtGrpNameSelect_onChange = (event: any) => {
    setThrtGrpNameSelect(event);
  };

  // 기본정보 > 권한 그룹명 셀렉트의 아이템을 초기화함
  const initThrtGrpNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: noneSelect(),
      value: '',
    });

    // 상세 권한 그룹명을 불러옴
    cmnApi.getAuthorityGroupsAll({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.thrtGrpName,
          value: item.thrtGrpId,
        });
      });

      // 검색 폼 그리드 > 권한 그룹명 > 셀렉트의 아이템에 적용함
      setThrtGrpNameSelectItem(tmpOptionItem);
    });
  };

  /* 등록일  */
  // 기본정보 폼 그리드 > 등록일 > 날짜 피커를 정의함
  const [addDatePicker, setAddDatePicker] = useState<Date | null>(null);

  // 기본정보 폼 그리드 > 등록일 > 날짜 피커를 변경함
  const handleAddDatePicker_onChange = (event: any) => {
    setAddDatePicker(event.target.value);
  };

  /* 수정일  */
  // 기본정보 폼 그리드 > 수정일 > 날짜 피커를 정의함
  const [modifyDatePicker, setModifyDatePicker] = useState<Date | null>(null);

  // 기본정보 폼 그리드 > 수정일 > 날짜 피커를 변경함
  const handleModifyDatePicker_onChange = (event: any) => {
    setModifyDatePicker(event.target.value);
  };

  /**
   * 권한 - 관리자 권한 부여 _ 임시 : 운영중 권한체계 다시 확립 필요
   */

  const [superAuthSelect, setSuperAuthSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  const handleSuperAuthSelect_onChange = (event: any) => {
    setSuperAuthSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  const initSuperAuthSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: noneSelect(),
      value: '',
    });

    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'THRT_DVSN',
      })
      .then((data: IApiResult) => {
        data.data.list.map((item: any, index: number) => {
          tmpOptionItem.push({
            label: item.dtlCodeDscr,
            value: item.dtlCodeId,
          });
        });
      });

    setSuperAuthSelect((pre: ISelect) => ({
      ...pre,
      item: tmpOptionItem,
    }));
  };

  /* // */

  /* // 기본 정보 */

  /**
   * 사용자 정보
   */

  /* 아이디 */
  // 사용자 정보 > 아이디 입력을 정의함(로그인 아이디)
  const [lgnIdInput, setLgnIdInput] = useState<string>('');

  // 사용자 정보 > 아이디 값을 변경함
  const handleLgnIdInput_onChange = (event: any) => {
    setLgnIdConfirm('N');
    setLgnIdInput(event.target.value);
  };

  // 사용자 정보 > 아이디 값을 초기화함
  const initUserIdInput = () => {
    // 사용자 정보 > 아이디 입력에 적용함
    setLgnIdInput('');
  };

  const [lgnIdConfirm, setLgnIdConfirm] = useState<string>('');

  // 이름 > 중복확인 버튼을 정의함
  const handleDuplicateConfirmButton_onClick = () => {
    if (_.isEmpty(lgnIdInput)) {
      setModal({
        title: '알림',
        // content: '아이디를 입력해주세요.',
        content: 'E-mail을 입력해주세요.',
      });
      return;
    }

    // 로그인 아이디 중복확인
    cmnApi
      .getCheckUserId({
        lgnId: lgnIdInput, // 로그인 아이디
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          // 중복 확인 : 로그인 아이디 사용 가능
          case '200':
            setLgnIdConfirm('Y');
            setModal({
              title: '알림',
              content: '사용 가능한 E-mail 입니다.',
            });
            break;

          // 중복 확인 : 아이디 사용 불가
          case '500':
            setLgnIdConfirm('N');
            setModal({
              title: '알림',
              // content: data.message,
              content: '이미 사용중인 E-mail 입니다.',
            });
            break;

          default:
            break;
        }
      });
  };

  /* 비밀번호 */
  // 사용자 정보 > 비밀번호  입력을 정의함
  const [pswrInput, setPswrInput] = useState<string>('');

  // 사용자 정보 > 비밀번호  값을 변경함
  const handlePswrInput_onChange = (event: any) => {
    setPswrInput(event.target.value);
  };

  // 사용자 정보 > 비밀번호 값을 초기화함
  const initPswrInput = () => {
    // 사용자 정보 > 비밀번호 입력에 적용함
    setPswrInput('');
  };

  // 사용자 정보 > 비밀번호 확인 입력을 정의함
  const [confirmPswrInput, setConfirmPswrInput] = useState<string>('');

  // 사용자 정보 > 비밀번호 확인 값을 변경함
  const handleConfirmPswrInput_onChange = (event: any) => {
    setConfirmPswrInput(event.target.value);
  };

  // 사용자 정보 > 비밀번호 확인 값을 초기화함
  const initConfirmPswrInput = () => {
    // 사용자 정보 > 비밀번호 입력에 적용함
    setConfirmPswrInput('');
  };

  /* 이름 */
  // 사용자 정보 > 이름 입력을 정의함
  const [nameInput, setNameInput] = useState<string>('');

  // 사용자 정보 > 이름 값을 변경함
  const handleNameInput_onChange = (event: any) => {
    setNameInput(event.target.value);
  };

  // 사용자 정보 > 이름 값을 초기화함
  const initNameInput = () => {
    // 사용자 정보 > 이름 입력에 적용함
    setNameInput('');
  };

  /* 닉네임 */
  // 사용자 정보 > 닉네임 입력을 정의함
  const [nickNameInput, setNickNameInput] = useState<string>('');

  // 사용자 정보 > 닉네임 값을 변경함
  const handleNickNameInput_onChange = (event: any) => {
    setNickNameInput(event.target.value);
  };

  // 사용자 정보 > 닉네임 값을 초기화함
  const initNickNameInput = () => {
    // 사용자 정보 > 닉네임 입력에 적용함
    setNickNameInput('');
  };

  /* E-mail */
  // 사용자 정보 > E-mail 입력을 정의함
  const [mlInput, setMlInput] = useState<string>('');

  // 사용자 정보 > E-mail 값을 변경함
  const handleMlInput_onChange = (event: any) => {
    setMlInput(event.target.value);
  };

  // 사용자 정보 > E-mail 값을 초기화함
  const initMlInput = () => {
    // 사용자 정보 > E-mail 입력에 적용함
    setMlInput('');
  };

  /* 본부 */
  // 사용자 정보 > 본부 셀렉트를 정의함
  const [hdqrCodeSelect, setHdqrCodeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 사용자 정보 > 본부 셀렉트에서 선택한 아이템을 변경함
  const handleHdqrCodeSelect_onChange = (event: any) => {
    setHdqrCodeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 사용자 정보 > 본부 셀렉트의 아이템을 초기화함
  const initHdqrCodeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: noneSelect(),
      value: '',
    });
    // 본부명을 불러옴
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'HDQR', // 공통 - 상위코드 - 본부
      })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 사용자 정보 > 본부 셀렉트의 아이템에 적용함
        setHdqrCodeSelect((pre: ISelect) => ({
          // value: data.data.list[0].dtlCodeId,
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  /* 부서 */
  // 사용자 정보 > 부서 셀렉트를 정의함
  const [dprtCodeSelect, setDprtCodeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 사용자 정보 > 부서 셀렉트에서 선택한 아이템을 변경함
  const handleDprtCodeSelect_onChange = (event: any) => {
    setDprtCodeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 사용자 정보 > 부서 셀렉트의 아이템을 초기화함
  const initDprtCodeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];
    tmpOptionItem.push({
      label: noneSelect(),
      value: '',
    });
    // 부서명을 불러옴
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'DPRT', // 공통 - 상위코드 - 부서
      })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 사용자 정보 > 부서 셀렉트의 아이템에 적용함
        setDprtCodeSelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  /* 휴대폰 */

  // 사용자 정보 > 휴대폰 prefix 셀렉트를 정의
  // const [celPhnPrefixSelect, setCelPhnPrefixSelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });

  // 사용자 정보 > 휴대폰 prefix 셀렉트를 변경함
  // const handleCelPhnPrefixSelect_onChange = (event: any) => {
  //   setCelPhnPrefixSelect((pre: ISelect) => ({
  //     ...pre,
  //     value: event,
  //   }));
  // };

  // 사용자 정보 > 휴대폰 prefix 셀렉트의 아이템을 초기화함
  // const initCelPhnPrefixSelect = () => {
  //   let tmpOptionItem: IOptionItem[] = [];

  //   tmpOptionItem.push(
  //     {
  //       label: '010',
  //       value: '010'
  //     },
  //     {
  //       label: '011',
  //       value: '011'
  //     },
  //     {
  //       label: '016',
  //       value: '016'
  //     },
  //     {
  //       label: '018',
  //       value: '018'
  //     },
  //   );

  //   // 사용자 정보 > 휴대폰 prefix 셀렉트의 아이템에 적용함
  //   setCelPhnPrefixSelect((pre: ISelect) => ({
  //     item: tmpOptionItem,
  //     value: '010',
  //   }));
  // };

  // 사용자 정보 > 휴대폰 번호를 정의함
  const [celPhnInput, setCelPhnInput] = useState<string>('');

  // 사용자 정보 > 휴대폰 번호를 변경함
  const handleCelPhnInput_onChange = (event: any) => {
    setCelPhnInput(phoneNumberformatter(event.target.value));
  };

  // 사용자 정보 > 휴대폰 아이템을 초기화함
  const initCelPhnInput = () => {
    // 사용자 정보 > 휴대폰 아이템에 적용함
    setCelPhnInput('');
  };

  /* 사용 여부 */
  // 사용자 정보 > 사용 여부 셀렉트를 정의함
  // 상세 검색 > 사용 여부 라디오 버튼을 정의함
  const [useYnRadio, setUseYnRadio] = useState<string>('Y');

  // 상세 검색 > 사용 여부 셀렉트에서 선택한 아이템을 변경함
  const handleUseYnRadio_onChange = (event: any) => {
    setUseYnRadio(event);
  };

  /* // 사용자 정보 */

  /**
   * 설치 구분
   */

  // 사용자정보 > 설치 구분 셀렉트를 정의함
  const [setupClassCodeSelect, setSetupClassCodeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 사용자정보 > 설치 구분 셀렉트에서 선택한 아이템을 변경함
  const handleSetupClassCodeSelect_onChange = (event: any) => {
    setSetupClassCodeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 기본정보 > 설치 구분 셀렉트의 아이템을 초기화함
  const initSetupClassCodeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    if (userId && !useModifyMode) {
      tmpOptionItem.push({
        label: '',
        value: '',
      });
    } else {
      tmpOptionItem.push({
        label: noneSelect(),
        value: '',
      });
    }

    // 설치구분을 불러옴
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'NSTL_CTGR',
      })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 사용자 등록 > 사용자정보 > 설치 구분 > 셀렉트의 아이템에 적용함
        setSetupClassCodeSelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  // 영업기회 조회 권한 섹션 > 테이블을 정의함
  const [accessAuthTable, setAccessAuthTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  const accessAuthTableRef = useRef<any>(null);

  // 영업기회 조회 권한 섹션 > 영업 기회 목록 > 테이블을 정의함
  const [slsPrtnsTable, setSlsPrtnsTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 영업기회 조회 권한 섹션 > 테이블을 초기화함
  const initAccessAuthTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 추가, 수정 상태일 때만 체크박스를 추가함
    if (!(userId && !useModifyMode)) {
      // 컬럼에 추가함
      tmpColumn.push({
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      });
    }

    // 컬럼에 추가함
    tmpColumn.push({
      headerName: '영업기회명',
      field: 'slsPrtnName',
      // width: 300,
      // suppressSizeToFit: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return decode(params.data.slsPrtnName);
      },
    });

    // 영업기회 조회 권한 섹션 > 테이블에 적용함
    setAccessAuthTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 영업기회 조회 권한 섹션 > 테이블 > 전체 삭제 버튼을 클릭함
  const handleAccessAuthDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '영업기회 조회 권한 전체를 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });

            setAccessAuthTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: [],
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 영업기회 조회 권한 섹션 > 테이블 > 선택 삭제 버튼을 클릭함
  const handleAccessAuthSelectedDeleteButton_onClick = () => {
    // 삭제할 아이디 목록을 정의함
    let selectedId: string[] = [];

    // 삭제할 아이디 목록을 불러옴
    accessAuthTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedId.push(item.slsPrtnId);
    });

    if (selectedId.length === 0) {
      setModal({
        title: '알림',
        content: '삭제할 영업기회 조회 권한을 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: '선택하신 영업기회 조회 권한을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });

            // 목록에서 삭제할 영업기회 조회 권한을 적용한 후 최종 목록을 정의함
            let tmlTableData: any = accessAuthTable.data.filter(
              (item: any) => !_.includes(selectedId, item.slsPrtnId),
            );

            setAccessAuthTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: tmlTableData,
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 영업기회 조회 권한 섹션 > 검색 폼 그리드 > 적용 버튼을 클릭함
  const handleReadAuthTargetUserApplyButton_onClick = (event: any) => {
    // 추가할 목록을 정의함
    let selectedId: string[] = [];

    // 추가할 아이디 목록을 불러옴
    readAuthTargetUserTableRef.current.api
      .getSelectedRows()
      .map((item: any) => {
        selectedId.push(item.slsPrtnId);
      });

    if (selectedId.length === 0) {
      notifications.show({
        title: '확인',
        message: (
          <span className="text-lg">
            추가할 영업 기회를 체크하여 선택하세요.
          </span>
        ),
        color: 'pink',
      });

      return;
    }

    // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 팝업 출력 여부에 적용함
    setVisibleReadAuthTargetUser(false);

    // 목록을 불러옴
    let tmlReadAuthTableData: any[] = _.cloneDeep(accessAuthTable.data);

    // 기존에 없던 영업 기회를 목록에 추가함
    selectedId.map((item: string) => {
      if (_.findIndex(tmlReadAuthTableData, { slsPrtnId: item }) === -1) {
        tmlReadAuthTableData.push(
          _.find(readAuthTargetUserTable.data, { slsPrtnId: item }),
        );
      }
    });

    // 영업기회 조회 권한 폼 그리드 > 테이블에 적용함
    setAccessAuthTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlReadAuthTableData,
    }));

    notifications.show({
      title: '확인',
      message: <span className="text-lg">영업 기회를 추가하였습니다.</span>,
      color: 'indigo',
    });
  };

  // 영업기회 조회 권한 섹션 > 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const readAuthTargetUserSearchSelect_onChange = (event: any) => {
    setReadAuthTargetUserSearchSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 영업기회 조회 권한 섹션 > 검색 폼 그리드 > 검색 폼 그리드 > 검색어 > 입력의 값을 변경함
  const readAuthTargetUserSearchInput_onChange = (event: any) => {
    setReadAuthTargetUserSearchInput(event.currentTarget.value);
  };

  // 영업기회 조회 권한 섹션 > 검색 폼 그리드 > 검색 폼 그리드 > 검색어 > 입력에서 키를 입력함
  const readAuthTargetUserSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 검색 결과 테이블 데이터를 불러옴
      getReadAuthTargetUserTableData();
    }
  };

  // 영업기회 조회 권한 섹션 > 검색 폼 그리드 > 검색 폼 그리드 > 검색어 > 버튼을 클릭함
  const handleReadAuthTargetUserSearchButton_onChange = () => {
    // 검색 결과 테이블 데이터를 불러옴
    getReadAuthTargetUserTableData();
  };

  /* // 설치 구분 */

  /**
   * CS 구분
   */

  // 사용자정보 > CS 구분 셀렉트를 정의함
  const [csCodeSelect, setCsCodeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 사용자정보 > CS 구분 셀렉트에서 선택한 아이템을 변경함
  const handleCsCodeSelect_onChange = (event: any) => {
    setCsCodeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 기본정보 > CS 구분 셀렉트의 아이템을 초기화함
  const initCsCodeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    if (userId && !useModifyMode) {
      tmpOptionItem.push({
        label: '',
        value: '',
      });
    } else {
      tmpOptionItem.push({
        label: noneSelect(),
        value: '',
      });
    }

    // CS 구분을 불러옴
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'CS_DPRT',
      })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 사용자 등록 > 사용자정보 > CS 구분 > 셀렉트의 아이템에 적용함
        setCsCodeSelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  /* // CS 구분 */

  /**
   * 접속 이력
   */

  // 사용자 상세 > 접속 이력 테이블
  const [connHistoryListTable, setConnHistoryListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 사용자 상세 > 접속 이력 테이블을 초기화함
  const initConnHistoryListTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '번호',
        field: 'no',
        width: 80,
        suppressSizeToFit: true,
      },
      {
        headerName: '접속IP',
        field: 'cnctIp',
        width: 180,
        suppressSizeToFit: true,
      },
      {
        headerName: '접속 일시',
        field: 'rgstDate',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: '로그인 결과',
        field: 'hstrId',
      },
    );

    // 사용자 상세 > 접속 이력 테이블에 적용함
    setConnHistoryListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /* // 접속 이력 */

  /**
   * 처리
   */

  // 사용자 정보 상세 데이터를 정의함
  const [userInfoData, setUserInfoData] = useState<any>(null);

  // 사용자 정보 상세 데이터를 불러옴
  const getCmnAuthDetail = () => {
    cmnApi
      .getUser({
        userId: userId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 사용자 정보 상세 데이터에 적용함
          setUserInfoData(data.data);
        }
      });
  };

  // 영업기회 조회 권한을 추가하기 위하여 영업 기회 관리 목록을 불러옴
  const getSlsPrtnsTableData = () => {
    slsApi
      .getSlsPrtns({
        currPageIdx: 1,
        searchType: 'tag',
        pageSize: 1000,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // data.data.list.map((item: any, index: number) => {
          //   item.cntrSizeMnt = addComma(item.cntrSizeMnt);
          //   item.nsrSclMnt = addComma(item.nsrSclMnt);
          // });

          // 검색 결과 테이블에 적용함
          setSlsPrtnsTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));
        }
      })
      .catch((error: any) => {});
  };

  // 비밀번호 초기화
  const handleInitPasswordButton_onClick = () => {
    setModal({
      title: '확인',
      content: '임시 비밀번호를 메일로 발송하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              cmnApi
                .getFindPassword({
                  lgnId: userInfoData.lgnId,
                })
                .then((data: IApiResult) => {
                  switch (data.code) {
                    case '200':
                      setModal({
                        title: '알림',
                        content: '메일을 발송했습니다.',
                        callback() {
                          // 컴포넌트 모달을 닫음
                          setRemoveComponentModal(id);
                        },
                      });
                      break;

                    case '500':
                      console.log('> 메일 발송에 Error:', data);
                      setModal({
                        title: '오류',
                        content: '메일 발송에 실패하였습니다.',
                      });
                      break;

                    default:
                      break;
                  }
                })
                .catch((error: any) => {
                  console.log('> 권한 정보 수정 Error:', error);
                  setModal({
                    title: '오류',
                    content: '메일 발송에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // 컴포넌트 모달 > 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    setModal({
      title: '확인',
      content: `사용자 [${userInfoData.lgnId}] 정보를 삭제하시겠습니까/`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 권한 그룹 정보를 삭제함
              cmnApi
                .deleteUser({
                  userId: userId,
                })
                .then((data: IApiResult) => {
                  switch (data.code) {
                    case '200':
                      // 목록 새로고침을 추가함
                      setAddRefreshList('MN2302220804Table');

                      // 모달을 출력함
                      setModal({
                        title: '알림',
                        content: '삭제하였습니다.',
                        callback: () => setRemoveComponentModal(id),
                      });
                      break;

                    case '':
                      // 모달을 출력함
                      setModal({
                        title: t('common.error'),
                        content: '삭제에 실패하였습니다.',
                      });
                      break;

                    default:
                      break;
                  }
                });
            }}
          />
        </div>
      ),
    });
  };

  // 컴포넌트 모달 > 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '사용자 정보 수정',
        content: (
          <MN2302220870 id={tmpId} userId={userId} useModifyMode={true} />
        ),
        size: 1500,
      });
    }, 100);
  };

  // 컴포넌트 모달 > 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    // 기본정보 입력값 유효성 확인함
    // 회사명 선택 확인
    if (_.isEmpty(cmpnNameSelect)) {
      setModal({
        title: '알림',
        content: '회사명을 선택하세요.',
      });
      return;
    }

    // 권한 그룹명 선택 확인
    if (_.isEmpty(thrtGrpNameSelect)) {
      setModal({
        title: '알림',
        content: '권한그룹명을 선택하세요.',
      });
      return;
    }

    // 사용자 등록의 입력 값 유효성을 확인함

    // 사용자 아이디 입력 확인
    if (_.isEmpty(lgnIdInput.trim())) {
      setModal({
        title: '알림',
        // content: '아이디를 입력하세요.',
        content: '아이디(E-mail)을 입력하세요.',
      });
      return;
    }

    // 사용자 아이디 중복 확인
    if (lgnIdConfirm === 'Y') {
      // 중복 확인됨
    } else {
      setModal({
        title: '알림',
        // content: '아이디 중복확인을 해주세요.',
        content: '아이디(E-mail) 중복확인을 해주세요.',
      });
      return;
    }

    // 비밀번호 입력 확인
    // if (_.isEmpty(pswrInput.trim())) {
    //   setModal({
    //     title: '알림',
    //     content: '비밀번호를 입력하세요.',
    //   });
    //   return;
    // }

    // 비밀번호 확인 입력 확인
    // if (_.isEmpty(confirmPswrInput.trim())) {
    //   setModal({
    //     title: '알림',
    //     content: '비밀번호 확인을 입력하세요.',
    //   });
    //   return;
    // }

    // 비밀번호 / 비밀번호 확인 정보 확인
    // if (pswrInput === confirmPswrInput) {
    //   // 비밀번호 / 비밀번호 확인 같음
    // } else {
    //   setModal({
    //     title: '알림',
    //     content: '비밀번호 확인 정보를 정확하게 입력하세요.',
    //   });
    //   return;
    // }

    // 이름 입력 확인
    if (_.isEmpty(nameInput.trim())) {
      setModal({
        title: '알림',
        content: '이름을 입력하세요.',
      });
      return;
    }

    // Email 입력 확인
    // if (_.isEmpty(mlInput.trim())) {
    //   setModal({
    //     title: '알림',
    //     content: 'Email을 입력하세요.',
    //   });
    //   return;
    // }

    // 휴대폰 입력 확인
    if (_.isEmpty(celPhnInput.trim()) || celPhnInput.length < 8) {
      setModal({
        title: '알림',
        content: '휴대폰 번호를 입력하세요.',
      });
      return;
    }

    // 영업기회 조회 권한 영업 기회의 아이디를 정의함
    let tmpSlsXpsrTrgtList: string[] = [];

    // 영업기회 조회 권한 영업 기회의 아이디를 불러옴
    accessAuthTable.data.map((item: any) => {
      tmpSlsXpsrTrgtList.push(item.slsPrtnId);
    });

    // 사용자 등록 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 사용자 등록을 처리함
              cmnApi
                .postUser({
                  celPhn: celPhnInput.trim().replace('-', ''), // 휴대폰
                  cmpnCode: cmpnNameSelect, // 회사코드
                  dprtCode: dprtCodeSelect.value, // 부서 코드
                  hdqrCode: hdqrCodeSelect.value, // 본부 코드
                  lgnId: lgnIdInput.trim(), // 로그인 아이디
                  ml: lgnIdInput.trim(), // Email
                  name: nameInput.trim(), // 이름
                  nickName: nickNameInput.trim(), // 닉네임
                  nstlCtgrCode: setupClassCodeSelect.value, // 설치구분 코드
                  csDprtCode: csCodeSelect.value, // CS 부서코드
                  pstnCode: '', // 직급 코드
                  thrtGrpId: thrtGrpNameSelect, // 권한 그룹 코드
                  useYn: useYnRadio, // 사용자 상태(사용여부)
                  thrtDvsnCode: superAuthSelect.value, // 권한 구분 코드
                  slsXpsrTrgtList: tmpSlsXpsrTrgtList,
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220804Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);
                      },
                    });
                  } else {
                    console.log('> 사용자 등록 Error:', data);

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> 사용자 등록 Error:', error);

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // 컴포넌트 모달 > 저장 버튼(수정)을 클릭함
  const handleSaveUpdateButton_onClick = () => {
    // 기본정보 입력값 유효성 확인함
    // 회사명 선택 확인
    if (_.isEmpty(cmpnNameSelect)) {
      setModal({
        title: '알림',
        content: '회사명을 선택하세요.',
      });
      return;
    }

    // 권한 그룹명 선택 확인
    if (_.isEmpty(thrtGrpNameSelect)) {
      setModal({
        title: '알림',
        content: '권한그룹명을 선택하세요.',
      });
      return;
    }

    // 사용자 등록의 입력 값 유효성을 확인함

    // 사용자 아이디 입력 확인
    if (_.isEmpty(lgnIdInput.trim())) {
      setModal({
        title: '알림',
        // content: '사용자 아이디를 입력하세요.',
        content: 'E-mail을 입력하세요.',
      });
      return;
    }

    // 사용자 아이디 중복 확인
    // if (lgnIdConfirm === "Y") {
    //   // 중복 확인됨
    // } else {
    //   setModal({
    //     title: '알림',
    //     // content: '아이디 중복확인을 해주세요.',
    //     content: 'E-mail 중복확인을 해주세요.',
    //   });
    //   return;
    // }

    // 비밀번호 입력 확인
    // if (_.isEmpty(pswrInput.trim())) {
    //   setModal({
    //     title: '알림',
    //     content: '비밀번호를 입력하세요.',
    //   });
    //   return;
    // }

    // 비밀번호 확인 입력 확인
    // if (_.isEmpty(confirmPswrInput.trim())) {
    //   setModal({
    //     title: '알림',
    //     content: '비밀번호 확인을 입력하세요.',
    //   });
    //   return;
    // }

    // 비밀번호 / 비밀번호 확인 정보 확인
    // if (pswrInput === confirmPswrInput) {
    //   // 비밀번호 / 비밀번호 확인 같음
    // } else {
    //   setModal({
    //     title: '알림',
    //     content: '비밀번호 확인 정보를 정확하게 입력하세요.',
    //   });
    //   return;
    // }

    // 이름 입력 확인
    if (_.isEmpty(nameInput.trim())) {
      setModal({
        title: '알림',
        content: '이름을 입력하세요.',
      });
      return;
    }

    // Email 입력 확인
    // if (_.isEmpty(mlInput.trim())) {
    //   setModal({
    //     title: '알림',
    //     content: 'Email을 입력하세요.',
    //   });
    //   return;
    // }

    // 휴대폰 입력 확인
    if (_.isEmpty(celPhnInput.trim()) || celPhnInput.length < 8) {
      setModal({
        title: '알림',
        content: '휴대폰 번호를 입력하세요.',
      });
      return;
    }

    // 영업기회 조회 권한 영업 기회의 아이디를 정의함
    let tmpSlsXpsrTrgtList: string[] = [];

    // 영업기회 조회 권한 영업 기회의 아이디를 불러옴
    accessAuthTable.data.map((item: any) => {
      tmpSlsXpsrTrgtList.push(item.slsPrtnId);
    });

    console.log('> tmpSlsXpsrTrgtList:', tmpSlsXpsrTrgtList);

    // 사용자 수정 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 사용자 수정을 처리함
              cmnApi
                .putUser({
                  userId: userId, // 사용자 아이디
                  celPhn: celPhnInput.trim().replaceAll('-', ''), // 휴대폰
                  cmpnCode: cmpnNameSelect, // 회사코드
                  dprtCode: dprtCodeSelect.value, // 부서 코드
                  hdqrCode: hdqrCodeSelect.value, // 본부 코드
                  // lgnId: lgnIdInput.trim(), // 로그인 아이디
                  ml: lgnIdInput.trim(), // Email
                  name: nameInput.trim(), // 이름
                  nickName: nickNameInput.trim(), // 닉네임
                  nstlCtgrCode: setupClassCodeSelect.value, // 설치 구분
                  csDprtCode: csCodeSelect.value, // CS 부서코드
                  pstnCode: '', // 직급 코드
                  thrtGrpId: thrtGrpNameSelect, // 권한 그룹 코드
                  useYn: useYnRadio, // 사용자 상태(사용여부)
                  thrtDvsnCode: superAuthSelect.value, // 권한 구분 코드
                  slsXpsrTrgtList: tmpSlsXpsrTrgtList,
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220804Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);

                        setTimeout(() => {
                          // 조회 컴포넌트 모달을 추가함
                          let tmpId: string = nanoid();

                          // 컴포넌트 모달을 추가함
                          setAddComponentModal({
                            id: tmpId,
                            title: '사용자 정보 상세',
                            content: (
                              <MN2302220870 id={tmpId} userId={userId} />
                            ),
                            size: 1500,
                          });
                        }, 100);
                      },
                    });
                  } else {
                    console.log('> 사용자 수정 실패:', data);

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> 사용자 수정 Error:', error);

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    setLgnIdConfirm('N');

    initCmpnNameSelect();

    // 기본정보 > 권한 그룹명 셀렉트의 아이템을 초기화함
    initThrtGrpNameSelect();

    // 기본정보 폼 그리드 > 등록일 설정
    setAddDatePicker(moment().toDate());

    // 기본정보 폼 그리드 > 수정일 설정
    setModifyDatePicker(moment().toDate());

    // 기본정보 폼 그리드 > 권한 구분 초기화
    initSuperAuthSelect();

    // 사용자 정보 > 아이디 > 입력을 초기화함
    initUserIdInput();

    // 사용자 정보 > 비밀번호 > 입력을 초기화함
    initPswrInput();

    // 사용자 정보 > 비밀번호 확인 > 입력을 초기화함
    initConfirmPswrInput();

    // 사용자 정보 > 이름 > 입력을 초기화함
    initNameInput();

    // 사용자 정보 > 닉네임 > 입력을 초기화함
    initNickNameInput();

    // 사용자 정보 > E-mail > 입력을 초기화함
    initMlInput();

    // 사용자 정보 > 본부 셀렉트의 아이템을 초기화함
    initHdqrCodeSelect();

    // 사용자 정보 > 부서 셀렉트의 아이템을 초기화함
    initDprtCodeSelect();

    // 사용자 정보 > 휴대폰 > 입력을 초기화함
    // initCelPhnPrefixSelect();
    initCelPhnInput();

    // 사용자 정보 > 접속 이력 > 테이블을 초기화함
    initConnHistoryListTable();

    // 사용자 정보 > 설치 구분 > 테이블을 초기화함
    initSetupClassCodeSelect();

    // 사용자 정보 > CS 구분 > 테이블을 초기화함
    initCsCodeSelect();

    // 영업기회 조회 권한 섹션 > 테이블을 초기화함
    initAccessAuthTable();

    // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 검색 > 셀렉트의 아이템을 초기화함
    initReadAuthTargetUserSearchSelect();

    // 영업기회 조회 권한 폼 그리드 > 영업 기회 > 테이블을 초기화함
    initReadAuthTargetUserTable();

    if (userId) {
      // 상세/수정의 경우 호출됨
      // 사용자 정보 상세를 불러옴
      getCmnAuthDetail();
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (celPhnInput.length === 10) {
      setCelPhnInput(celPhnInput.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    }
    if (celPhnInput.length === 11) {
      setCelPhnInput(
        celPhnInput
          .replace(/-/g, '')
          .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
      );
    }
    if (celPhnInput.length === 13) {
      setCelPhnInput(
        celPhnInput
          .replace(/-/g, '')
          .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
      );
    }
  }, [celPhnInput]);

  // 권한 활동 상세 정보 초기화
  useEffect(() => {
    if (!userInfoData) {
      return;
    }

    // 사용자 등록 > 기본정보 > 회사명 > 셀렉트의 아이템에 적용함
    setCmpnNameSelect(userInfoData.cmpnCode);
    // setCmpnNameSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: userInfoData.cmpnCode,
    // }));

    // 사용자 등록 > 기본정보 > 권한 그룹명 > 셀렉트의 아이템에 적용함
    setThrtGrpNameSelect(userInfoData.thrtGrpId);

    // 사용자 등록 > 기본정보 > 권한 구분 코드 > 셀렉트의 아이템에 적용함
    setSuperAuthSelect((pre: ISelect) => ({
      ...pre,
      value: userInfoData.thrtDvsnCode,
    }));

    // 기본정보 폼 그리드 > 등록일 설정
    setAddDatePicker(moment(userInfoData.rgstDate, 'YYYY-MM-DD').toDate());

    // 기본정보 폼 그리드 > 수정일 설정
    setModifyDatePicker(moment(userInfoData.mdfcDate, 'YYYY-MM-DD').toDate());

    // 사용자 정보 > 아이디 입력에 적용함
    // setLgnIdInput(userInfoData.lgnId);
    setLgnIdInput(userInfoData.ml);

    // 사용자 정보 > 비밀번호 입력에 적용함
    setPswrInput(userInfoData.pswr);

    // 사용자 정보 > 비밀번호 확인 > 입력을 초기화함
    setConfirmPswrInput(userInfoData.pswr);

    // 사용자 정보 > 이름 입력에 적용함
    setNameInput(userInfoData.name);

    // 사용자 정보 > 닉네임 입력에 적용함
    setNickNameInput(userInfoData.nickName);

    // 사용자 정보 > E-mail 입력에 적용함
    setMlInput(userInfoData.ml);

    // 사용자 정보 > 본부 셀렉트의 아이템에 적용함
    setHdqrCodeSelect((pre: ISelect) => ({
      ...pre,
      value: userInfoData.hdqrCode,
    }));

    // 사용자 정보 > 부서 셀렉트의 아이템에 적용함
    setDprtCodeSelect((pre: ISelect) => ({
      ...pre,
      value: userInfoData.dprtCode,
    }));

    // 사용자 정보 > 휴대폰 prefix 셀렉트의 아이템에 적용함
    // setCelPhnPrefixSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: userInfoData.celPhn.substring(0,3),
    // }));

    // 사용자 정보 > 휴대폰 셀렉트의 아이템에 적용함
    setCelPhnInput(userInfoData.celPhn);

    // 사용자 정보 > 사용 여부 셀렉트의 아이템에 적용함
    setUseYnRadio(userInfoData.useYn);

    // 사용자 정보 > 설치 구분 셀렉트의 아이템에 적용함
    setSetupClassCodeSelect((pre: ISelect) => ({
      ...pre,
      value: userInfoData.nstlCtgrCode,
    }));

    // 사용자 정보 > CS 구분 셀렉트의 아이템에 적용함
    setCsCodeSelect((pre: ISelect) => ({
      ...pre,
      value: userInfoData.csDprtCode,
    }));

    // 사용자 정보 > 접속 이력 정보를 적용함
    setConnHistoryListTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: userInfoData.list,
    }));

    // 영업기회 조회 권한 테이블
    setAccessAuthTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: userInfoData.slsCtvtXpsrVoList,
    }));

    return () => {};
  }, [userInfoData]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  return (
    <div className="space-y-7">
      {/* 기본 정보 */}
      <div className="space-y-5">
        {/* 기본 정보 폼 그리드 */}
        <PbFormGrid id="step-1" label="기본 정보" cols={2}>
          <PbFormGridCol label="회사명" withAsterisk={true}>
            {/* 셀렉트 */}
            {/*<Select*/}
            {/*  onChange={handleCmpnNameSelect_onChange}*/}
            {/*  data={cmpnNameSelect.item}*/}
            {/*  value={cmpnNameSelect.value}*/}
            {/*  readOnly={userId && !useModifyMode ? true : false}*/}
            {/*  variant={userId && !useModifyMode ? 'unstyled' : 'default'}*/}
            {/*  className="w-full"*/}
            {/*/>*/}
            {cmpnNameSelectItem.length > 0 && (
              <Select
                onChange={handleCmpnNameSelect_onChange}
                data={cmpnNameSelectItem}
                value={cmpnNameSelect}
                readOnly={userId && !useModifyMode ? true : false}
                variant={userId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            )}
          </PbFormGridCol>
          <PbFormGridCol label="권한 그룹명" withAsterisk={true}>
            {/* 셀렉트 */}
            <Select
              onChange={handleThrtGrpNameSelect_onChange}
              data={thrtGrpNameSelectItem}
              value={thrtGrpNameSelect}
              className="w-full"
              readOnly={userId && !useModifyMode ? true : false}
              variant={userId && !useModifyMode ? 'unstyled' : 'default'}
            />
            {/*<PbSelect*/}
            {/*  onChange={handleThrtGrpNameSelect_onChange}*/}
            {/*  data={thrtGrpNameSelect.item}*/}
            {/*  value={thrtGrpNameSelect.value}*/}
            {/*  setSelect={setThrtGrpNameSelect}*/}
            {/*  className="w-full"*/}
            {/*  readOnly={userId && !useModifyMode ? true : false}*/}
            {/*  variant={userId && !useModifyMode ? 'unstyled' : 'default'}*/}
            {/*/>*/}
          </PbFormGridCol>
          <PbFormGridCol
            label="권한"
            colSpan={2}
            isHidden={loginUser.thrtDvsnCode === 'MNGR' ? false : true}
          >
            <PbSelect
              onChange={handleSuperAuthSelect_onChange}
              data={superAuthSelect.item}
              value={superAuthSelect.value}
              setSelect={setSuperAuthSelect}
              readOnly={userId && !useModifyMode ? true : false}
              variant={userId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-1/2"
            />
          </PbFormGridCol>
          <PbFormGridCol label="등록일">
            {/* 날짜 피커 */}
            <DatePickerInput
              onChange={handleAddDatePicker_onChange}
              value={addDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              clearable={true}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="수정일">
            {/* 날짜 피커 */}
            <DatePickerInput
              onChange={handleModifyDatePicker_onChange}
              value={modifyDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              clearable={true}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 사용자 정보 */}
      <div className="space-y-5">
        {/* 사용자 정보 폼 그리드 */}
        <PbFormGrid id="step-2" label="사용자 정보" cols={2}>
          <PbFormGridCol label="아이디" withAsterisk={true}>
            {/* 텍스트 입력 */}
            <TextInput
              placeholder="E-mail을 입력하세요."
              onChange={handleLgnIdInput_onChange}
              value={lgnIdInput}
              className="w-full"
              readOnly={!userId ? false : true}
              variant={!userId ? 'default' : 'unstyled'}
            />

            {/* 신규 상태 */}
            {!userId && (
              <>
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleDuplicateConfirmButton_onClick}
                >
                  중복 검사
                </Button>
              </>
            )}

            {/* 조회 상태 */}
            {userId && !useModifyMode && <></>}

            {/* 수정 상태 */}
            {userId && useModifyMode && (
              <>
                {/* <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleDuplicateConfirmButton_onClick}
                >
                  중복 검사
                </Button> */}
              </>
            )}
          </PbFormGridCol>

          <PbFormGridCol label="이름" withAsterisk={true}>
            {/* 텍스트 입력 */}
            <TextInput
              placeholder="이름을 입력하세요."
              onChange={handleNameInput_onChange}
              value={nameInput}
              className="w-full"
              readOnly={userId && !useModifyMode ? true : false}
              variant={userId && !useModifyMode ? 'unstyled' : 'default'}
            />
          </PbFormGridCol>

          <PbFormGridCol label="닉네임" colSpan={2}>
            {/* 텍스트 입력 */}
            <TextInput
              placeholder={
                userId && !useModifyMode ? '' : '닉네임을 입력하세요.'
              }
              onChange={handleNickNameInput_onChange}
              value={nickNameInput}
              className="w-full"
              readOnly={userId && !useModifyMode ? true : false}
              variant={userId && !useModifyMode ? 'unstyled' : 'default'}
            />
          </PbFormGridCol>

          {/* 신규 상태 */}
          {!userId && (
            <>
              {/* <PbFormGridCol label="비밀번호" withAsterisk={true}>
                <PasswordInput
                  placeholder={t('auth.enterPassword').toString()}
                  onChange={handlePswrInput_onChange}
                  value={pswrInput}
                  className="w-full"
                  readOnly={userId && !useModifyMode ? true : false}
                />
              </PbFormGridCol>

              <PbFormGridCol label="비밀번호 확인" withAsterisk={true}>
                <PasswordInput
                  placeholder={t('auth.enterPassword').toString()}
                  onChange={handleConfirmPswrInput_onChange}
                  value={confirmPswrInput}
                  className="w-full"
                  readOnly={userId && !useModifyMode ? true : false}
                />

              </PbFormGridCol> */}
            </>
          )}

          {/* 수정 상태 */}
          {userId && useModifyMode && (
            <>
              <PbFormGridCol label="비밀번호" withAsterisk={true} colSpan={2}>
                {/* <TextInput
                    onChange={handlePswrInput_onChange}
                    value={pswrInput}
                    className="w-full"
                    readOnly={true}
                  /> */}
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleInitPasswordButton_onClick}
                >
                  비밀번호 초기화
                </Button>
              </PbFormGridCol>
            </>
          )}

          {/* <PbFormGridCol label="E-mail" withAsterisk={true}>
            <TextInput
              placeholder="E-mail을 입력하세요."
              onChange={handleMlInput_onChange}
              value={mlInput}
              className="w-full"
              readOnly={userId && !useModifyMode ? true : false}
              variant={userId && !useModifyMode ? 'unstyled' : 'default'}
            />
          </PbFormGridCol> */}
          <PbFormGridCol label="본부">
            {/* 셀렉트 */}
            <PbSelect
              onChange={handleHdqrCodeSelect_onChange}
              data={hdqrCodeSelect.item}
              value={hdqrCodeSelect.value}
              setSelect={setHdqrCodeSelect}
              className="w-full"
              readOnly={userId && !useModifyMode ? true : false}
              variant={userId && !useModifyMode ? 'unstyled' : 'default'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="부서">
            {/* 셀렉트 */}
            <PbSelect
              onChange={handleDprtCodeSelect_onChange}
              data={dprtCodeSelect.item}
              value={dprtCodeSelect.value}
              setSelect={setDprtCodeSelect}
              className="w-full"
              readOnly={userId && !useModifyMode ? true : false}
              variant={userId && !useModifyMode ? 'unstyled' : 'default'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="휴대폰" withAsterisk={true}>
            {/* 셀렉트 */}
            {/* <Select
              onChange={handleCelPhnPrefixSelect_onChange}
              data={celPhnPrefixSelect.item}
              value={celPhnPrefixSelect.value}
              className="w-full"
              readOnly={userId && !useModifyMode ? true : false}
            /> */}
            {/* 텍스트 입력 */}
            <TextInput
              placeholder="휴대폰 번호를 입력하세요."
              onChange={handleCelPhnInput_onChange}
              value={phoneNumberformatter(celPhnInput)}
              className="w-full"
              readOnly={userId && !useModifyMode ? true : false}
              variant={userId && !useModifyMode ? 'unstyled' : 'default'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="사용 여부">
            <Radio.Group
              name="radioGroup1"
              onChange={handleUseYnRadio_onChange}
              value={useYnRadio}
              size="sm"
            >
              <Group mt="xs">
                <Radio
                  value="Y"
                  label="사용"
                  disabled={userId && !useModifyMode ? true : false}
                />
                <Radio
                  value="N"
                  label="미사용"
                  disabled={userId && !useModifyMode ? true : false}
                />
              </Group>
            </Radio.Group>
          </PbFormGridCol>
          <PbFormGridCol label="설치 구분">
            <PbSelect
              onChange={handleSetupClassCodeSelect_onChange}
              data={setupClassCodeSelect.item}
              value={setupClassCodeSelect.value}
              setSelect={setSetupClassCodeSelect}
              className="w-full"
              readOnly={userId && !useModifyMode ? true : false}
              variant={userId && !useModifyMode ? 'unstyled' : 'default'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="CS 구분">
            <PbSelect
              onChange={handleCsCodeSelect_onChange}
              data={csCodeSelect.item}
              value={csCodeSelect.value}
              setSelect={setCsCodeSelect}
              className="w-full"
              readOnly={userId && !useModifyMode ? true : false}
              variant={userId && !useModifyMode ? 'unstyled' : 'default'}
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 신규 상태 */}
      {!userId && <></>}

      {/* 조회 상태 */}
      {userId && !useModifyMode && (
        <PbSection id="step-3" label="접속 이력">
          {/* 테이블 */}
          <div className="w-full h-80">
            <PbAgGridReact
              columnDefs={connHistoryListTable.column}
              rowData={connHistoryListTable.data}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="single"
              sizeColumnsToFit={true}
            />
          </div>
        </PbSection>
      )}

      {/* 영업기회 조회 권한 섹션 */}
      {cmpnNameSelect === 'DTNC' && (
        <PbSection
          id="step-4"
          label="영업기회 조회 권한"
          rightForm={
            <>
              {/* 버튼 */}
              {/* 추가, 수정 상태 */}
              {!(userId && !useModifyMode) && (
                <div className="flex justify-end items-center space-x-2">
                  {/* 버튼 */}
                  <Button
                    variant="outline"
                    color="pink"
                    radius="xl"
                    onClick={handleAccessAuthDeleteButton_onClick}
                  >
                    전체 삭제
                  </Button>
                  {/* 버튼 */}
                  <Button
                    color="pink"
                    radius="xl"
                    onClick={handleAccessAuthSelectedDeleteButton_onClick}
                  >
                    선택 삭제
                  </Button>
                  {/* 버튼 */}
                  {/* 버튼 */}
                  <Popover
                    opened={visibleReadAuthTargetUser}
                    onClose={() => setVisibleReadAuthTargetUser(false)}
                    position="top-end"
                    transitionProps={{ transition: 'pop' }}
                    withArrow={false}
                    shadow="xl"
                    zIndex={100}
                    offset={10}
                  >
                    <Popover.Target>
                      <Button
                        onClick={() => setVisibleReadAuthTargetUser(true)}
                        color="indigo"
                        radius="xl"
                      >
                        추가
                      </Button>
                    </Popover.Target>

                    <Popover.Dropdown className="border-1 !border-gray-500">
                      <PbSection label="추가할 영업 기회 선택">
                        <div className="space-y-3">
                          {/* 검색 폼 그리드 */}
                          <PbFormGrid cols={1}>
                            <PbFormGridCol label="검색">
                              {/* 셀렉트 */}
                              <PbSelect
                                onChange={
                                  readAuthTargetUserSearchSelect_onChange
                                }
                                data={readAuthTargetUserSearchSelect.item}
                                value={readAuthTargetUserSearchSelect.value}
                                setSelect={setReadAuthTargetUserSearchSelect}
                              />

                              {/* 텍스트 입력 */}
                              <TextInput
                                placeholder="검색어를 입력하세요."
                                onChange={
                                  readAuthTargetUserSearchInput_onChange
                                }
                                onKeyUp={readAuthTargetUserSearchInput_onKeyUp}
                                value={readAuthTargetUserSearchInput}
                                className="w-1/5"
                              />

                              {/* 버튼 */}
                              <div className="flex justify-center items-center space-x-2">
                                {/* 버튼 */}
                                <Button
                                  color="indigo"
                                  radius="xl"
                                  onClick={
                                    handleReadAuthTargetUserSearchButton_onChange
                                  }
                                >
                                  검색
                                </Button>
                              </div>
                            </PbFormGridCol>
                          </PbFormGrid>

                          {/* 테이블 */}
                          <div style={{ width: '1100px' }} className="h-96">
                            <PbAgGridReact
                              refs={readAuthTargetUserTableRef}
                              columnDefs={readAuthTargetUserTable.column}
                              rowData={readAuthTargetUserTable.data}
                              defaultColDef={{
                                resizable: true,
                                sortable: true,
                                wrapHeaderText: false,
                                autoHeaderHeight: true,
                              }}
                              rowSelection="multiple"
                              // sizeColumnsToFit={true}
                            />
                          </div>

                          <div className="flex justify-end items-center space-x-2">
                            {/* 버튼 */}
                            <Button
                              color="indigo"
                              radius="xl"
                              onClick={
                                handleReadAuthTargetUserApplyButton_onClick
                              }
                            >
                              적용
                            </Button>
                          </div>
                        </div>
                      </PbSection>
                    </Popover.Dropdown>
                  </Popover>{' '}
                </div>
              )}
            </>
          }
        >
          <div className="space-y-3">
            {/* 테이블 */}
            <div className="w-full h-80">
              <PbAgGridReact
                refs={accessAuthTableRef}
                columnDefs={accessAuthTable.column}
                rowData={accessAuthTable.data}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="multiple"
                sizeColumnsToFit={true}
              />
            </div>
          </div>
        </PbSection>
      )}

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 신규 상태 */}
        {!userId && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveButton_onClick}
            >
              저장
            </Button>
          </>
        )}

        {/* 조회 상태 */}
        {userId && !useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleGoModifyButton_onClick}
            >
              수정 전환
            </Button>
          </>
        )}

        {/* 수정 상태 */}
        {userId && useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              variant="outline"
              color="pink"
              radius="xl"
              size="md"
              onClick={handleRemoveButton_onClick}
            >
              삭제
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveUpdateButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220870;
