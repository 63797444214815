import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';
import * as appUtil from '../../utils/app.util';
import { useRecoilState } from 'recoil';
import { ILanguage } from '../../interfaces/app.interface';
import { languageStore } from '../../stores/language.store';
import PbSelect from '../PbSelect/PbSelect.component';

interface ILanguageSelectProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 언어 선택
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const LanguageSelect = ({
  data,
  onClick,
}: PropsWithChildren<ILanguageSelectProps>) => {
  // 언어를 정의함
  const { t, i18n } = useTranslation();

  // 현재 선택한 언어를 정의함
  const [language, setLanguage] = useRecoilState<ILanguage>(languageStore);

  // 언어 선택폼을 변경함
  const handleLanguageSelect_onChange = (event: any) => {
    // 현재 언어를 Local Storage에 저장함
    appUtil.setLanguage(event).then(() => {
      setLanguage({ language: event });
    });
  };

  useEffect(() => {
    // 현재 선택한 언어를 초기화함
    setLanguage({ language: appUtil.getLanguage() });

    return () => {};
  }, []);

  // 언어가 변경될 때마다 실행함
  useEffect(() => {
    if (!language.language) {
      return;
    }

    (async () => {
      await i18n.changeLanguage(language.language);
    })();

    return () => {};
  }, [language.language]);

  return (
    <PbSelect
      onChange={handleLanguageSelect_onChange}
      data={[
        { value: 'en', label: 'English' },
        { value: 'kr', label: '한국어' },
        { value: 'jp', label: '日本語' },
      ]}
      value={language.language}
      setSelect={setLanguage}
    />
  );
};

export default LanguageSelect;
