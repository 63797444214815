import {
  IApiResult,
  IFileNameExtension,
  ILoginUser,
  IToken,
} from '../interfaces/app.interface';
import * as cmnApi from '../apis/cmn.api';
import { number } from 'prop-types';
import _ from 'lodash';

/**
 * 공통 함수
 */

/**
 * API URL을 불러옴
 */
export const getApiUrl = (): string => {
  let result: string = '';

  if (process.env.REACT_APP_API_PORT === '80') {
    result = `${process.env.REACT_APP_API_HOST}`;
  } else {
    result = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`;
  }

  return result;
};

/**
 * Resize Image URL을 불러옴
 */
export const getResizeImageUrl = (): string => {
  let result: string = process.env.REACT_APP_RESIZE_IMAGE_PATH || '';

  return result;
};

/**
 * Thumbnail Image URL을 불러옴
 */
export const getThumbnailImageUrl = (): string => {
  let result: string = process.env.REACT_APP_THUMBNAIL_IMAGE_PATH || '';

  return result;
};

/**
 * 로그인한 사용자 토큰을 Local Storage에 저장함
 * @param params 토큰
 */
export const setToken = async (params: IToken) => {
  await localStorage.setItem('token', btoa(JSON.stringify(params) || ''));
};

/**
 * 로그인한 사용자 토큰을 Local Storage에서 불러옴
 */
export const getToken = (): IToken => {
  let data: IToken = {
    email: '',
    userId: '',
    tokenType: '',
    accessToken: '',
    accessTokenExpireSecond: 0,
    refreshToken: '',
    refreshTokenExpireSecond: 0,
  };

  if (localStorage.getItem('token')) {
    data = JSON.parse(atob(localStorage.getItem('token') || ''));
  }

  return data;
};

/**
 * 로그인한 사용자 토큰을 Local Storage에서 삭제함
 */
export const removeToken = () => {
  localStorage.removeItem('token');
};

/**
 * 로그인한 사용자를 Local Storage에 저장함
 * @param params 사용자
 */
export const setLoginUser = async (params: any) => {
  await localStorage.setItem('loginUser', btoa(JSON.stringify(params) || ''));
};

/**
 * 로그인한 사용자를 Local Storage에서 불러옴
 */
export const getLoginUser = (): ILoginUser => {
  let data: ILoginUser = {
    id: '',
    name: '',
  };

  if (localStorage.getItem('loginUser')) {
    data = JSON.parse(atob(localStorage.getItem('loginUser') || ''));
  }

  return data;
};

/**
 * 로그인한 사용자를 Local Storage에서 삭제함
 */
export const removeLoginUser = () => {
  localStorage.removeItem('loginUser');
};

/**
 * 현재 언어를 Local Storage에 저장함
 * @param params 선택한 언어
 */
export const setLanguage = async (params: any) => {
  await localStorage.setItem('language', params || 'en');
};

/**
 * 현재 언어를 Local Storage에서 불러옴
 */
export const getLanguage = (): string => {
  let data: string = '';

  if (localStorage.getItem('language')) {
    data = localStorage.getItem('language') || 'en';
  } else {
    data = 'en';
  }

  return data;
};

/**
 * 로그인 아이디를 Local Storage에 저장함
 * @param params 아이디
 */
export const setSaveId = async (params: any) => {
  await localStorage.setItem('saveId', params || '');
};

/**
 * 로그인 아이디를 Local Storage에서 불러옴
 */
export const getSaveId = (): string => {
  let data: string = '';

  if (localStorage.getItem('saveId')) {
    data = localStorage.getItem('saveId') || '';
  } else {
    data = '';
  }

  return data;
};

/**
 * 휴대폰 번호에 정규식을 적용함
 */
export const phoneNumberformatter = (phone: string): string => {
  let str: string = '';

  str = phone
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

  return str;
};

/**
 * 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
 * @param id 컴포넌트 모달 아이디
 * @param active 페이지 스크롤 탭 아이디
 */
export const pageScrollToTarget = (id: string, active: string) => {
  // let tmpLocation: number =
  //   (document.querySelector(`#step-${active + 1}`) as HTMLDivElement)
  //     ?.offsetTop || 0;

  let tmpLocation: number =
    (document.querySelector(`#${active}`) as HTMLDivElement)?.offsetTop || 0;

  let tmpPage = document.querySelector(
    `section[aria-describedby="${id}-body"] .mantine-ScrollArea-viewport`,
  ) as HTMLDivElement;

  if (tmpPage === null) {
    // tmpPage = document.querySelector(`#${id}-body`) as HTMLDivElement;
    tmpPage = document.querySelector(
      `section[aria-describedby="${id}-body"].mantine-Paper-root`,
    ) as HTMLDivElement;
  }

  tmpPage?.scrollTo({
    top: tmpLocation - 10,
    behavior: 'smooth',
  });
};

/**
 * 공통코드를 불러옴
 * @param code 공통코드
 * @param callback 콜백 함수
 */
export const getCommonCode = (
  code: string = '',
  callback: Function = () => {},
) => {
  cmnApi
    .getDetailCodesAll({
      grpCodeId: code,
    })
    .then((data: IApiResult) => {
      if (data.data.list !== undefined) {
        callback(data.data.list);
      }
    });
};

/**
 * 날짜와 날짜 사이의 일자를 구함
 * @param startDate 시작일자
 * @param endDate 종료일자
 */
export const getDatesBetween = (startDate: Date, endDate: Date): Date[] => {
  const currentDate: Date = new Date(startDate.getTime());
  const dates: Date[] = [];

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

// 숫자에 콤마를 추가함
export const getFormatNumber = (number: any) => {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

/**
 * 파일명에서 파일 확장자 및 아이콘을 추출함
 * @param fileName 파일명
 */
export const getFileNameExtension = (fileName: string): IFileNameExtension => {
  // 확장자를 정의함
  let tmpExt: IFileNameExtension[] = [
    { ext: '.jpg', icon: ['far', 'image'], isImage: true },
    { ext: '.jpeg', icon: ['far', 'image'], isImage: true },
    { ext: '.png', icon: ['far', 'image'], isImage: true },
    { ext: '.gif', icon: ['far', 'image'], isImage: true },
    { ext: '.bmp', icon: ['far', 'image'], isImage: true },
    { ext: '.tif', icon: ['far', 'image'], isImage: true },
    { ext: '.mp3', icon: ['far', 'file-audio'], isImage: false },
    { ext: '.mp4', icon: ['far', 'file-video'], isImage: false },
    { ext: '.pdf', icon: ['far', 'file-pdf'], isImage: false },
    { ext: '.xls', icon: ['far', 'file-excel'], isImage: false },
    { ext: '.xlsx', icon: ['far', 'file-excel'], isImage: false },
    { ext: '.ppt', icon: ['far', 'file-powerpoint'], isImage: false },
    { ext: '.pptx', icon: ['far', 'file-powerpoint'], isImage: false },
    { ext: '.doc', icon: ['far', 'file-word'], isImage: false },
    { ext: '.docx', icon: ['far', 'file-word'], isImage: false },
    { ext: '.txt', icon: ['far', 'file-lines'], isImage: false },
    { ext: '.xml', icon: ['far', 'file-lines'], isImage: false },
    { ext: '.json', icon: ['far', 'clone'], isImage: false },
    { ext: '.zip', icon: ['far', 'file-zipper'], isImage: false },
    { ext: '.tar', icon: ['far', 'file-zipper'], isImage: false },
    { ext: '.gz', icon: ['far', 'file-zipper'], isImage: false },
    { ext: '.7z', icon: ['far', 'file-zipper'], isImage: false },
  ];

  // 결과를 정의함
  let tmpResult: IFileNameExtension = {
    ext: '',
    icon: ['far', 'note-sticky'],
    isImage: false,
  };

  // 파일명에서 확장자를 추출함
  let tmpFileExt: string = _.padEnd(fileName, 5);

  // 파일 확장자를 추출함
  tmpExt.map((item: IFileNameExtension) => {
    if (_.includes(tmpFileExt, item.ext)) {
      tmpResult = item;
    }
  });

  return tmpResult;
};

// 셀렉트의 값에 해당하는 텍스트를 불러옴
export const getSelectText = (item: any[], value: any) => {
  return _.find(item, { value: value })?.label || '';
};
