import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useElementSize } from '@mantine/hooks';
import { PbTable } from '../PbTable';
import { ITableColumn } from '../../interfaces/app.interface';
import { useRecoilState } from 'recoil';
import { removeComponentModalStore } from '../../stores/componentModal.store';
import { PbButton } from '../PbButton';
import { LoadingTableDataIcon } from '../LoadingTableDataIcon';
import { AgGridReact } from 'ag-grid-react';
import { PbAgGridReact } from '../PbAgGridReact';

interface IComponentProps {
  id?: string;
  detailData?: any[];
}

/**
 * 운영 관리 > CS 조회 > 등록 > 네트워크 작업 결과 > 상세 이력
 * @param id 컴포넌트 모달 아이디
 * @param networkDetailData 네트워크 작업 결과 행의 상세 이력
 * @constructor
 */
const MN2302220502_NetworkDetail = ({
  id = '',
  detailData,
}: PropsWithChildren<IComponentProps>) => {
  // 페이지의 내용 영역 크기를 정의함
  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useElementSize();

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 네트워크 공사 작업 결과 상세 이력 테이블을 정의함
  const [networkDetailTable, setNetworkDetailTable] = useState<{
    column: any;
    data: any;
    loading?: boolean;
  }>({ column: [], data: [], loading: false });

  // 네트워크 공사 작업 결과 상세 이력 테이블의 로딩 여부를 정의함
  const [networkDetailTableLoading, setNetworkDetailTableLoading] =
    useState<boolean>(false);

  // 네트워크 공사 작업 결과 상세 이력 테이블을 초기화함
  const initNetworkDetailTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '구분',
        field: 'ntwrWorkName',
        width: 200,
        suppressSizeToFit: true,
      },
      { headerName: '제목', field: 'ttl' },
    );

    // 검색 결과 테이블에 적용함
    setNetworkDetailTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 네트워크 공사 작업 결과 상세 이력 테이블 데이터를 불러옴
  const getNetworkDetailTableData = () => {
    // 네트워크 공사 작업 결과 상세 이력 테이블의 로딩 여부에 적용함
    setNetworkDetailTableLoading(true);

    // 네트워크 공사 작업 결과 상세 이력 테이블에 적용함
    setNetworkDetailTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: detailData,
    }));

    // 네트워크 공사 작업 결과 상세 이력 테이블의 로딩 여부에 적용함
    setNetworkDetailTableLoading(false);
  };

  useEffect(() => {
    // 네트워크 공사 작업 결과 상세 이력 테이블을 초기화함
    initNetworkDetailTable();

    return () => {};
  }, []);

  // 네트워크 공사 작업 결과 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (networkDetailTable.column.length === 0) {
      return;
    }

    // 네트워크 공사 작업 결과 상세 이력 테이블 데이터를 불러옴
    getNetworkDetailTableData();

    return () => {};
  }, [networkDetailTable.column]);

  return (
    <div className="space-y-7">
      {/* 테이블 */}
      <div className="w-full h-132">
        <PbAgGridReact
          columnDefs={networkDetailTable.column}
          rowData={networkDetailTable.data}
          loading={networkDetailTableLoading}
          setLoading={setNetworkDetailTableLoading}
          defaultColDef={{
            resizable: true,
            sortable: true,
            wrapHeaderText: false,
            autoHeaderHeight: true,
          }}
          rowSelection="single"
          sizeColumnsToFit={true}
        />
      </div>

      {/* 테이블 */}
      {/*<div className="relative w-full h-80">*/}
      {/*  /!* 로딩 중 아이콘 *!/*/}
      {/*  <LoadingTableDataIcon visible={networkDetailTable.loading} />*/}

      {/*  <AgGridReact*/}
      {/*    columnDefs={networkDetailTable.column}*/}
      {/*    rowData={networkDetailTable.data}*/}
      {/*    defaultColDef={{*/}
      {/*      initialWidth: 150,*/}
      {/*      resizable: true,*/}
      {/*      sortable: true,*/}
      {/*      wrapHeaderText: false,*/}
      {/*      autoHeaderHeight: true,*/}
      {/*      suppressSizeToFit: true,*/}
      {/*    }}*/}
      {/*    rowSelection="single"*/}
      {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
      {/*    className="ag-theme-alpine"*/}
      {/*  ></AgGridReact>*/}
      {/*</div>*/}

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        <div>
          <PbButton
            label="닫기"
            backgroundColor="white"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          />
        </div>
      </div>
    </div>
  );
};

export default MN2302220502_NetworkDetail;
