import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Button, TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  IComponentModal,
  IComponentModalPageScrollTab,
  ILeftMenu,
  ILoginUser,
  IModal,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbFormGrid } from '../PbFormGrid';
import moment from 'moment';
import { DateInput, TimeInput } from '@mantine/dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notifications } from '@mantine/notifications';

interface IComponentProps {
  id: string;
  prtnStartDate: Date;
  prtnStartTime: string;
  prtnEndDate: Date;
  prtnEndTime: string;
  selectedCellData?: any;
  selectedRowData?: any;
  data?: any;
  onClick?: () => void;
  useModifyMode?: boolean;
  callback?: (result: any) => void;
}

/**
 * 설치 관리 > 작업 현황 > 상세 > 작업배정 > 협력사/팀 배정
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220305_WorkDistributionModify = ({
  id,
  prtnStartDate,
  prtnStartTime,
  prtnEndDate,
  prtnEndTime,
  selectedCellData = {},
  selectedRowData = {},
  data,
  onClick,
  useModifyMode = false,
  callback = (result: any) => {},
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 작업배정 > 시작일시 > 날짜 입력을 정의함
  const [startDateInput, setStartDateInput] = useState<Date>(prtnStartDate);
  const startDateInputRef = useRef<HTMLInputElement>(null);

  // 작업배정 > 시작일시 > 시간 입력을 정의함
  const [startTimeInput, setStartTimeInput] = useState<string>(prtnStartTime);
  const startTimeInputRef = useRef<HTMLInputElement>(null);

  // 작업배정 > 종료일시 > 날짜 입력을 정의함
  const [endDateInput, setEndDateInput] = useState<Date>(prtnEndDate);
  const endDateInputRef = useRef<HTMLInputElement>(null);

  // 작업배정 > 종료일시 > 시간 입력을 정의함
  const [endTimeInput, setEndTimeInput] = useState<string>(prtnEndTime);
  const endTimeInputRef = useRef<HTMLInputElement>(null);

  // 작업배정 > 내용 > 입력을 정의함
  const [contentInput, setContentInput] = useState<string>('');
  const contentInputRef = useRef<HTMLInputElement>(null);

  // 삭제 버튼을 클릭함
  const handleDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 부모창으로 결과를 넘김
            callback({
              resultType: 'delete',
              selectedCellData: selectedCellData,
              selectedRowData: selectedRowData,
            });

            // 컴포넌트 모달을 닫음
            setRemoveComponentModal(id);

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 적용 버튼을 클릭함
  const handleApplyButton_onClick = () => {
    if (startTimeInput === '') {
      setModal({
        title: '알림',
        content: '시작시간을 입력하세요.',
      });

      startTimeInputRef.current?.focus();
      return;
    }

    if (endDateInput === null) {
      setModal({
        title: '알림',
        content: '종료날짜를 입력하세요.',
      });

      endDateInputRef.current?.focus();
      return;
    }

    if (endTimeInput === '') {
      setModal({
        title: '알림',
        content: '종료시간을 입력하세요.',
      });

      endTimeInputRef.current?.focus();
      return;
    }

    if (
      `${moment(startDateInput).format('YYYYMMDD')}${startTimeInput}` >
      `${moment(endDateInput).format('YYYYMMDD')}${endTimeInput}`
    ) {
      setModal({
        title: '알림',
        content: '시작/종료일시를 올바르게 입력하세요.',
      });

      endDateInputRef.current?.focus();
      return;
    }

    if (contentInput.trim() === '') {
      setModal({
        title: '알림',
        content: '내용을 입력하세요.',
      });

      contentInputRef.current?.focus();
      return;
    }

    // 부모창으로 결과를 넘김
    callback({
      resultType: 'update',
      startDateTime:
        moment(startDateInput).format('YYYYMMDD') +
        startTimeInput.replaceAll(':', ''),
      endDateTime:
        moment(endDateInput).format('YYYYMMDD') +
        endTimeInput.replaceAll(':', ''),
      selectedCellData: selectedCellData,
      selectedRowData: selectedRowData,
      content: contentInput,
    });

    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);
  };

  // 로그인 정보가 준비될 때 실행함
  useEffect(() => {
    if (!loginUser.id) {
      return;
    }

    // 내용에 기본값을 적용함
    if (useModifyMode) {
      // 수정일 때
      setContentInput(selectedRowData.ttl);
    } else {
      // 신규일 때
      setContentInput(selectedCellData.ttl);
    }

    return () => {};
  }, [loginUser.id]);

  return (
    <div className="">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 협력사/팀 폼 그리드 */}
        <PbFormGrid label="협력사/팀" cols={1}>
          <PbFormGridCol label="협력사/팀">
            <div>
              {/* 신규일 때 */}
              {!useModifyMode && (
                <span>
                  {selectedCellData.prtnName} / {selectedCellData.teamName}
                </span>
              )}
              {/* 수정일 때 */}
              {useModifyMode && (
                <span>
                  {selectedRowData.prtnName} / {selectedRowData.teamName}
                </span>
              )}
            </div>
          </PbFormGridCol>
        </PbFormGrid>

        {/* 작업배정 폼 그리드 */}
        <PbFormGrid label="작업배정" cols={2}>
          <PbFormGridCol label="시작일시" withAsterisk={true} colSpan={2}>
            {/* 날짜 */}
            <DateInput
              ref={startDateInputRef}
              value={startDateInput}
              valueFormat="YYYY.MM.DD"
              disabled={true}
              className="w-1/2"
            />

            {/* 시간 */}
            <TimeInput
              ref={startTimeInputRef}
              rightSection={
                <div
                  onClick={() => startTimeInputRef.current?.showPicker()}
                  className="button-event flex justify-center items-center"
                >
                  <FontAwesomeIcon
                    icon={['far', 'clock']}
                    className="w-4 h-4"
                  />
                </div>
              }
              placeholder="시간을 입력하세요."
              onChange={(event: any) => {
                setStartTimeInput(event.currentTarget.value);
              }}
              value={startTimeInput}
              className="w-1/2"
            />
          </PbFormGridCol>

          <PbFormGridCol label="종료일시" withAsterisk={true} colSpan={2}>
            {/* 날짜 */}
            <DateInput
              ref={endDateInputRef}
              value={endDateInput}
              onChange={(event: any) => {
                setEndDateInput(event);
              }}
              placeholder="일자를 입력하세요."
              valueFormat="YYYY.MM.DD"
              className="w-1/2"
            />

            {/* 시간 */}
            <TimeInput
              ref={endTimeInputRef}
              rightSection={
                <div
                  onClick={() => endTimeInputRef.current?.showPicker()}
                  className="button-event flex justify-center items-center"
                >
                  <FontAwesomeIcon
                    icon={['far', 'clock']}
                    className="w-4 h-4"
                  />
                </div>
              }
              onChange={(event: any) => {
                setEndTimeInput(event.currentTarget.value);
              }}
              value={endTimeInput}
              className="w-1/2"
            />
          </PbFormGridCol>

          <PbFormGridCol label="내용" colSpan={2} withAsterisk={true}>
            {/* 텍스트 입력 */}
            <TextInput
              ref={contentInputRef}
              placeholder="내용을 입력하세요."
              onChange={(event: any) => {
                setContentInput(event.currentTarget.value);
              }}
              value={contentInput}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 버튼 */}
        <Button
          variant="outline"
          color="gray"
          radius="xl"
          size="md"
          onClick={() => setRemoveComponentModal(id)}
        >
          닫기
        </Button>

        {/* 버튼 */}
        {useModifyMode && (
          <Button
            color="pink"
            radius="xl"
            size="md"
            onClick={handleDeleteButton_onClick}
          >
            삭제
          </Button>
        )}

        {/* 버튼 */}
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={handleApplyButton_onClick}
        >
          적용
        </Button>
      </div>
    </div>
  );
};

export default MN2302220305_WorkDistributionModify;
