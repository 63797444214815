import React, { PropsWithChildren } from 'react';
import {
  IFileDownloadList,
  IImageCarousel,
} from '../../interfaces/app.interface';
import * as appUtil from '../../utils/app.util';
import { useRecoilState } from 'recoil';
import { imageCarouselStore } from '../../stores/imageCarousel.store';
import { getResizeImageUrl } from '../../utils/app.util';

interface IComponentProps {
  data?: IFileDownloadList[];
  onClick?: () => void;
  visibleSwipeRightAction?: boolean;
  onClickRemoveFileButton?: (event: any) => void;
}

/**
 * 파일 이미지 미리보기 목록
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const FlexImageFilePreviewList = ({
  data,
  onClick,
  visibleSwipeRightAction = false,
  onClickRemoveFileButton = (event: any) => {},
}: PropsWithChildren<IComponentProps>) => {
  // 이미지 캐러셀 저장소를 정의함
  const [imageCarousel, setImageCarousel] =
    useRecoilState<IImageCarousel>(imageCarouselStore);

  return (
    <div className="flex justify-center items-center gap-1">
      {data
        ?.filter(
          (item: IFileDownloadList) =>
            appUtil.getFileNameExtension(item.originalFileName).isImage,
        )
        .map((item: IFileDownloadList, index: number) => (
          <div
            key={index}
            onClick={() => {
              let tmpFileList: any[] = [];
              let tmpFileNameList: string[] = [];

              data
                ?.filter(
                  (item: IFileDownloadList) =>
                    appUtil.getFileNameExtension(item.originalFileName).isImage,
                )
                .map((subItem: IFileDownloadList) => {
                  tmpFileList.push(
                    `${appUtil.getThumbnailImageUrl()}${subItem.saveFileName}`,
                  );

                  tmpFileNameList.push(subItem.originalFileName);
                });

              // ImageViewer.Multi.show({
              //   images: tmpFileList,
              //   defaultIndex: index,
              //   renderFooter: (imageItem: string, imageIndex: number) => (
              //     <div className="px-3 pb-4">
              //       {/* 파일명 */}
              //       <span className="text-xs text-white font-semibold">
              //         <Ellipsis
              //           direction="middle"
              //           content={tmpFileNameList[imageIndex]}
              //         />
              //       </span>
              //     </div>
              //   ),
              // });
            }}
            title={`파일: ${item.originalFileName}`}
            className="button-bg-opacity-event w-full m-auto flex justify-center items-center bg-gray-100 border border-gray-300 rounded-md overflow-hidden"
          >
            <div
              style={{
                paddingBottom: '100%',
              }}
              className="h-0"
            >
              {/*<span className="text-xs text-gray-500 font-medium">*/}
              {/*  <Ellipsis*/}
              {/*    direction="middle"*/}
              {/*    content={item.originalFileName}*/}
              {/*  />*/}
              {/*</span>*/}
              <img
                src={`${appUtil.getThumbnailImageUrl()}${item.saveFileName}`}
                onClick={() => {
                  let tmpFileList: any[] = [];

                  data
                    ?.filter(
                      (filterItem: IFileDownloadList) =>
                        appUtil.getFileNameExtension(
                          filterItem.originalFileName,
                        ).isImage,
                    )
                    .map((imageItem: any) => {
                      tmpFileList.push(
                        [
                          appUtil.getResizeImageUrl(),
                          imageItem.saveFileName,
                        ].join(''),
                      );
                    });

                  // 이미지 캐러셀 저장소에 적용함
                  setImageCarousel({
                    imageList: tmpFileList,
                    startIndex: index,
                  });
                }}
                className="button-event w-16 h-16"
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default FlexImageFilePreviewList;
