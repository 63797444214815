import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  ILeftMenu,
  ILoginUser,
  IPageNavigation,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import * as appUtil from '../../utils/app.util';
import { useTranslation } from 'react-i18next';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { LanguageMenu } from '../LanguageMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ITopBarProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 상단바
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const TopBar = ({ data, onClick }: PropsWithChildren<ITopBarProps>) => {
  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내비게이션을 정의함
  const [pageNavigation, setPageNavigation] = useState<IPageNavigation[]>([]);

  // 페이지 내비게이션 태그를 정의함
  const [pageNavigationTag, setPageNavigationTag] = useState<JSX.Element[]>([]);

  // 로그아웃 버튼을 클릭함
  const handleLogoutButton_onClick = () => {
    // 로그인한 사용자를 Local Storage에서 삭제함
    appUtil.removeLoginUser();

    // 로그인한 사용자 토큰을 Local Storage에서 삭제함
    appUtil.removeToken();

    // 로그인 페이지로 이동함
    // navigate('/login');
    window.location.href = '/login';
  };

  // 페이지 내비게이션 태그를 생성함
  const getPageNavigationTag = (): JSX.Element[] => {
    let result: JSX.Element[] = [];

    pageNavigation.map((item: IPageNavigation, index: number) => {
      result.push(
        <li
          key={index}
          onClick={() => handlePageNavigationItem_onClick(item.link)}
          className={[
            'button-event breadcrumb-item',
            pageNavigation.length === index + 1 && 'active',
          ].join(' ')}
        >
          {item.title}
        </li>,
      );
    });

    return result;
  };

  // 페이지 내비게이션 아이템을 클릭함
  const handlePageNavigationItem_onClick = (link: string) => {
    // 해당 페이지로 이동함
    // navigate(link);
    window.location.href = link;
  };

  // 컴포넌트 샘플 버튼을 클릭함
  const handleComponentSampleButton_onClick = () => {
    // 해당 페이지로 이동함
    // navigate('/sample');
    window.location.href = '/sample';
  };

  useEffect(() => {
    return () => {};
  }, []);

  // 선택한 페이지의 정보가 생성될 때 실행함
  useEffect(() => {
    // 페이지 내비게이션을 정의함
    let tmpPageNavigation: IPageNavigation[] = [];

    // 내비게이션에 1딘계 페이지를 추가함
    if (selectedPage.oneMenuId) {
      if (selectedPage.oneKrnMenu !== 'HOME') {
        tmpPageNavigation.push({
          title: selectedPage.oneKrnMenu,
          link: '/',
        });
      }
    }

    // 내비게이션에 2딘계 페이지를 추가함
    if (selectedPage.twoMenuId) {
      tmpPageNavigation.push({
        title: selectedPage.twoKrnMenu,
        link: `/${selectedPage.menuId}`,
      });
    }

    // 내비게이션에 3딘계 페이지를 추가함
    if (selectedPage.threeMenuId) {
      tmpPageNavigation.push({
        title: selectedPage.threeKrnMenu,
        link: `/${selectedPage.menuId}`,
      });
    }

    // 페이지 내비게이션에 적용함
    setPageNavigation(tmpPageNavigation);

    return () => {};
  }, [selectedPage]);

  // 페이지 내비게이션이 생성될 때 실행함
  useEffect(() => {
    // 페이지 내비게이션 태그에 적용함
    setPageNavigationTag(getPageNavigationTag());

    return () => {};
  }, [pageNavigation]);

  return (
    <div>
      <div className="location">
        <div className="inner">
          <div className="breadcrumb-wrapper">
            <ol className="breadcrumb">
              <li
                onClick={() => handlePageNavigationItem_onClick('/dashboard')}
                className="button-event breadcrumb-item home"
              >
                <i className="icon-home"></i>

                <div className="pt-0.5 flex justify-center items-center">
                  <span className="text-base">HOME</span>
                </div>
              </li>
              {pageNavigationTag}
            </ol>
          </div>

          <div className="id-wrapper">
            <div className="space-x-2">
              {loginUser.cmpnName && (
                <>
                  <span className="text-lg text-gray-600 font-bold">
                    {loginUser.cmpnName}
                  </span>

                  <span className="text-sm text-gray-400 font-bold">/</span>
                </>
              )}
              {loginUser.dprtName && (
                <>
                  <span className="text-lg text-gray-600 font-bold">
                    {loginUser.dprtName}
                  </span>

                  <span className="text-sm text-gray-400 font-bold">/</span>
                </>
              )}
              {loginUser.name && (
                <span className="text-lg text-gray-600 font-bold">
                  {loginUser.name}
                </span>
              )}
            </div>

            {/* 언어: 로그아웃 */}
            <div onClick={handleLogoutButton_onClick} className="button-event">
              <span className="btn-logout">{t('auth.logout')}</span>
            </div>
          </div>

          {/* 언어 선택 메뉴 */}
          {(process.env.REACT_APP_MODE === 'localhost' ||
            process.env.REACT_APP_MODE === 'dev') && (
            <div>
              <LanguageMenu />
            </div>
          )}

          {/* 개발자용 컴포넌트 샘플 페이지 */}
          {(process.env.REACT_APP_MODE === 'localhost' ||
            process.env.REACT_APP_MODE === 'dev') && (
            <div>
              <div
                onClick={handleComponentSampleButton_onClick}
                className="button-event ml-3 px-1 py-0.5 bg-gray-800 leading-none rounded"
              >
                <span className="text-sm text-white font-bold">Dev</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
