import { atom } from 'recoil';
import { IModal } from '../interfaces/app.interface';

/**
 * 모달
 */
export const modalStore = atom({
  key: 'modalStore',
  default: {
    title: '',
    content: '',
  } as IModal,
});

/**
 * 모달 삭제
 */
export const removeModalStore = atom({
  key: 'removeModalStore',
  default: false as boolean,
});
