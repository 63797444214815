import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useDownloadExcel } from 'react-export-table-to-excel';
import _ from 'lodash';
import {
  IApiListResult,
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  IMatchMenuIdPageId,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import * as cmnApi from '../apis/cmn.api';
import moment from 'moment';
import { PbButton } from '../components/PbButton';
import { PbTable } from '../components/PbTable';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { PbDatePicker } from '../components/PbDatePicker';
import { PbInputText } from '../components/PbInputText';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  componentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import { MN2302220870 } from '../components/MN2302220870';
import { nanoid } from 'nanoid';
import { Button, Select, TextInput } from '@mantine/core';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { AgGridReact } from 'ag-grid-react';
import { ValueFormatterParams } from 'ag-grid-community';
import { PbAgGridReact } from '../components/PbAgGridReact';
import PbSelect from '../components/PbSelect/PbSelect.component';

/**
 * 시스템 관리 > 사용자 관리
 * @constructor
 */
const MN2302220801Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**
   * 사용자 등록
   */

  // 페이지 상단바 > 등록 버튼을 클릭함
  const handleRegButton_onChange = () => {
    let tmpId: string = 'MN2302220870';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '사용자 등록',
      content: <MN2302220870 id={tmpId} />,
      size: 1500,
    });
  };

  /* // 사용자 등록 */

  /**
   * 검색 구분 / 검색어 입력
   */

  // 검색 폼 그리드 > 검색어 > 검색구분 셀렉트를 정의함
  const [searchCategorySelect, setSearchCategorySelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');

  // 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCategorySelect_onChange = (event: any) => {
    setSearchCategorySelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchInput(event.currentTarget.value);
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력에서 키를 입력함
  const handleSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
  const handleSearchButton_onChange = () => {
    // 테이블의 페이지를 변경함
    searchResultTablePagingCurrentPageRef.current = 1;
    setSearchResultTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: searchResultTablePagingCurrentPageRef.current,
    }));

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();
  };

  // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
  const handleSearchCancelButton_onChange = () => {
    // 검색 폼 그리드 > 검색어 > 셀렉트 값을 초기화함
    setSearchCategorySelect((pre: ISelect) => ({
      ...pre,
      value: 'name',
    }));

    // 검색 폼 그리드 > 검색어 > 검색어 입력 값을 초기화함
    setSearchInput('');

    // 검색 폼 그리드 > 사용 여부 > 셀렉트 값을 초기화함
    setSearchUserStateSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 권한 그룹명 > 셀렉트 값을 초기화함
    setSearchAuthGroupSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));
  };

  // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
  const initSearchCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '이름',
        value: 'name',
      },
      {
        label: '아이디',
        value: 'user_id',
      },
      {
        label: '회사명',
        value: 'cmpn_code',
      },
      {
        label: '전화번호',
        value: 'cel_phn',
      },
      {
        label: '이메일',
        value: 'ml',
      },
    );

    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    setSearchCategorySelect((pre: ISelect) => ({
      value: 'name',
      item: tmpOptionItem,
    }));
  };

  /* // 검색 구분 / 검색어 입력 */

  /**
   * 검색 / 검색 조건 초기화 / 상세검색 열기 / 상세검색 닫기
   */

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    setHideDetailSearch(!hideDetailSearch);
  };

  /* // 검색 / 검색 조건 초기화 / 상세검색 열기 / 상세검색 닫기 */

  /**
   * 검색 : 사용 여부
   */

  // 검색 폼 그리드 > 사용 여부 > 셀렉트를 정의함
  const [searchUserStateSelect, setSearchUserStateSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 사용 여부 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchUserStateSelect_onChange = (event: any) => {
    setSearchUserStateSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 사용 여부 > 셀렉트의 아이템을 초기화함
  const initSearchUserStateSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '사용',
        value: 'Y',
      },
      {
        label: '미사용',
        value: 'N',
      },
    );

    // 검색 폼 그리드 > 사용 여부 > 셀렉트의 아이템에 적용함
    setSearchUserStateSelect((pre: ISelect) => ({
      value: '',
      item: tmpOptionItem,
    }));
  };

  /* // 검색 : 사용 여부  */

  /**
   * 검색 : 권한 그룹명
   */

  // 검색 폼 그리드 > 권한 그룹명 > 셀렉트를 정의함
  const [searchAuthGroupSelect, setSearchAuthGroupSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 권한 그룹명 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchAuthGroupSelect_onChange = (event: any) => {
    setSearchAuthGroupSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 권한 그룹명 > 셀렉트의 아이템을 초기화함
  const initSearchAuthGroupSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 권한 그룹명을 불러옴
    cmnApi.getAuthorityGroupsAll({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.thrtGrpName,
          value: item.thrtGrpId,
        });
      });

      // 검색 폼 그리드 > 권한 그룹명 > 셀렉트의 아이템에 적용함
      setSearchAuthGroupSelect((pre: ISelect) => ({
        ...pre,
        item: tmpOptionItem,
      }));
    });
  };

  /* // 검색 : 권한 그룹명  */

  /**
   * 설치 구분
   */

  // 사용자정보 > 설치 구분 셀렉트를 정의함
  const [setupClassCodeSelect, setSetupClassCodeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 사용자정보 > 설치 구분 셀렉트에서 선택한 아이템을 변경함
  const handleSetupClassCodeSelect_onChange = (event: any) => {
    setSetupClassCodeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 기본정보 > 설치 구분 셀렉트의 아이템을 초기화함
  const initSetupClassCodeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 설치구분을 불러옴
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'NSTL_CTGR',
      })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 사용자 등록 > 사용자정보 > 설치 구분 > 셀렉트의 아이템에 적용함
        setSetupClassCodeSelect((pre: ISelect) => ({
          value: '',
          item: tmpOptionItem,
        }));
      });
  };

  /* // 설치 구분 */

  /**
   * CS 구분
   */

  // 사용자정보 > CS 구분 셀렉트를 정의함
  const [csCodeSelect, setCsCodeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 사용자정보 > CS 구분 셀렉트에서 선택한 아이템을 변경함
  const handleCsCodeSelect_onChange = (event: any) => {
    setCsCodeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 기본정보 > CS 구분 셀렉트의 아이템을 초기화함
  const initCsCodeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // CS 구분을 불러옴
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'CS_DPRT',
      })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 사용자 등록 > 사용자정보 > CS 구분 > 셀렉트의 아이템에 적용함
        setCsCodeSelect((pre: ISelect) => ({
          value: '',
          item: tmpOptionItem,
        }));
      });
  };

  /* // CS 구분 */

  /**
   * 엑셀 다운로드
   */

  // 검색 결과 테이블의 엑셀 다운로드 버튼을 클릭함
  const handleSearchResultTableExportExcelButton_onClick = () => {
    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="md"
            onClick={() => {
              cmnApi
                .getUserExcel({
                  searchType: searchCategorySelect.value, // 검색 구분
                  searchKeyword: searchInput.trim(), // 검색어
                  thrtGrpId: searchAuthGroupSelect.value, // 권한 그룹 아이디
                  useYn: searchUserStateSelect.value, // 사용 여부
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    ['사용자 관리_', moment().format('YYYYMMDD'), '.xlsx'].join(
                      '',
                    ),
                  );
                  document.body.appendChild(link);
                  link.click();
                });

              setRemoveModal(true);
            }}
          />
        </div>
      ),
    });
  };

  /* // 엑셀 다운로드  */

  /**
   * 사용자 관리 테이블
   */

  // 검색 결과 테이블 데이터를 불러옴
  const getSearchResultTableData = () => {
    // 검색 결과 테이블의 로딩 여부에 적용함
    setSearchResultTableLoading(true);

    cmnApi
      .getCmnUsers({
        searchType: searchCategorySelect.value, // 검색 구분
        searchKeyword: searchInput.trim(), // 검색어
        thrtGrpId: searchAuthGroupSelect.value, // 권한 그룹 아이디
        useYn: searchUserStateSelect.value, // 사용 여부''
        nstlCtgrCode: setupClassCodeSelect.value, // 설치 구분
        csDprtCode: csCodeSelect.value, // CS 구분
        pageSize: searchResultTablePaging.rowPerPage,
        currPageIdx: searchResultTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setSearchResultTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
            }),
          );

          // 테이블을 페이징함
          setSearchResultTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }

        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      });
  };

  // 검색 결과 테이블의 행을 클릭함
  const handleSearchResultTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220870';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '사용자 정보 상세',
      content: <MN2302220870 id={tmpId} userId={row.data.userId} />,
      size: 1500,
    });
  };

  // 검색 결과 테이블을 정의함
  const [searchResultTable, setSearchResultTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 검색 결과 테이블 페이징을 정의함
  const [searchResultTablePaging, setSearchResultTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const searchResultTablePagingCurrentPageRef = useRef<number>(1);

  // 검색 결과 테이블의 로딩 여부를 정의함
  const [searchResultTableLoading, setSearchResultTableLoading] =
    useState<boolean>(false);

  // 검색 결과 테이블을 초기화함
  const initSearchResultTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'no',
        headerName: '번호',
        width: 80,
        suppressSizeToFit: true,
      },
      {
        // field: 'userId',
        field: 'lgnId',
        headerName: '아이디',
      },
      {
        field: 'name',
        headerName: '이름',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: 'nickName',
        headerName: '닉네임',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: 'thrtGrpName',
        headerName: '권한 그룹명',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        field: 'cmpnName',
        headerName: '회사명',
        width: 150,
        suppressSizeToFit: true,
      },
      // {
      //   field: 'celPhn',
      //   headerName: '전화번호',
      //   width: 120,
      //   suppressSizeToFit: true,
      // },
      // {
      //   field: 'ml',
      //   headerName: '이메일',
      // },
      {
        field: 'csDprtName',
        headerName: 'CS 구분',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        field: 'nstlCtgrName',
        headerName: '설치 구분',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        field: 'useYn',
        headerName: '사용 여부',
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.data.useYn === 'Y') {
            return '사용';
          } else {
            return '미사용';
          }
        },
        width: 90,
        suppressSizeToFit: true,
      },
      {
        field: 'rgstDate',
        headerName: '등록일',
        width: 120,
        suppressSizeToFit: true,
      },
      // {
      //   field: 'mdfcDate',
      //   headerName: '수정일',
      //   width: 130,
      //   suppressSizeToFit: true,
      // },
    );

    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /* // 사용자 관리 테이블  */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    //initial states
    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
    initSearchCategorySelect();

    // 검색 폼 그리드 > 사용 여부 > 셀렉트의 아이템을 초기화함
    initSearchUserStateSelect();

    // 검색 폼 그리드 > 권한 그룹명 > 셀렉트의 아이템을 초기화함
    initSearchAuthGroupSelect();

    // 검색 폼 그리드 > 설치 구분 셀렉트의 아이템을 초기화함
    initSetupClassCodeSelect();

    // 검색 폼 그리드 > CS 구분 셀렉트의 아이템을 초기화함
    initCsCodeSelect();

    // 사용자 목록 테이블 초기화
    initSearchResultTable();

    return () => {};
  }, []);

  // 검색 결과 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (searchResultTable.column.length === 0) {
      return;
    }

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();

    return () => {};
  }, [searchResultTable.column]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220804Table') > -1) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220804Table');
    }

    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={
        <>
          {/* 버튼 */}
          <Button color="indigo" radius="xl" onClick={handleRegButton_onChange}>
            등록
          </Button>
        </>
      }
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-10">
        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid cols={2}>
            <PbFormGridCol label="검색어" colSpan={2}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchCategorySelect_onChange}
                data={searchCategorySelect.item}
                value={searchCategorySelect.value}
                setSelect={setSearchCategorySelect}
              />

              {/* 텍스트 입력 */}
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={handleSearchInput_onChange}
                onKeyUp={handleSearchInput_onKeyUp}
                value={searchInput}
                className="w-1/5"
              />

              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleSearchButton_onChange}
                >
                  검색
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchCancelButton_onChange}
                >
                  검색 조건 초기화
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleShowSearchDetailButton_onChange}
                >
                  {hideDetailSearch ? (
                    <>
                      상세검색 열기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-down']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      상세검색 닫기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-up']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  )}
                </Button>
              </div>
            </PbFormGridCol>
            <PbFormGridCol label="사용 여부" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchUserStateSelect_onChange}
                data={searchUserStateSelect.item}
                value={searchUserStateSelect.value}
                setSelect={setSearchUserStateSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="권한 그룹명" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchAuthGroupSelect_onChange}
                data={searchAuthGroupSelect.item}
                value={searchAuthGroupSelect.value}
                setSelect={setSearchAuthGroupSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="설치 구분" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSetupClassCodeSelect_onChange}
                data={setupClassCodeSelect.item}
                value={setupClassCodeSelect.value}
                setSelect={setSetupClassCodeSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="CS 구분" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleCsCodeSelect_onChange}
                data={csCodeSelect.item}
                value={csCodeSelect.value}
                setSelect={setCsCodeSelect}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 테이블 */}
          <div className="space-y-3">
            <div className="flex justify-between items-center space-x-5">
              <div>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchResultTableExportExcelButton_onClick}
                >
                  엑셀 다운로드
                </Button>
              </div>

              <div className="flex justify-center items-center space-x-5">
                <div className="flex justify-center items-center">
                  <span className="text-base text-gray-600 font-bold">
                    총 {searchResultTablePaging.totalRow}건
                  </span>
                </div>
              </div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-160">
              <PbAgGridReact
                columnDefs={searchResultTable.column}
                rowData={searchResultTable.data}
                loading={searchResultTableLoading}
                setLoading={setSearchResultTableLoading}
                onRowDoubleClicked={handleSearchResultTable_onRowDoubleClicked}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
                visiblePaging={true}
                paging={{
                  totalPage: searchResultTablePaging.totalPage,
                  currentPage: searchResultTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  searchResultTablePagingCurrentPageRef.current = event;
                  setSearchResultTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: searchResultTablePagingCurrentPageRef.current,
                  }));

                  // 검색 결과 테이블 데이터를 불러옴
                  getSearchResultTableData();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220801Page;
