import React, { PropsWithChildren, useEffect, useState, useRef } from 'react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbInputText } from '../PbInputText';
import { PbButton } from '../PbButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbMonthPicker } from '../PbMonthPicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import prettyBytes from 'pretty-bytes';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  IComponentModalPageScrollTab,
  IComponentModalPageScrollTabStep,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PbDatePicker } from '../PbDatePicker';
import * as slsApi from '../../apis/sls.api';
import * as cmnApi from '../../apis/cmn.api';
import * as nstlApi from '../../apis/nstl.api';
import _ from 'lodash';
import { PbTable } from '../PbTable';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { MN2302220202_Manager } from '../MN2302220202_Manager';
import { PbInputNumber } from '../PbInputNumber';
import { addRefreshListStore } from '../../stores/refreshList.store';
import {
  Button,
  Checkbox,
  FileButton,
  NumberInput,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { AgGridReact } from 'ag-grid-react';
import * as csApi from '../../apis/cs.api';
import { MN2302220502_Network } from '../MN2302220502_Network';
import { MN2302220502_CsHistory } from '../MN2302220502_CsHistory';
import { PbTextarea } from '../PbTextarea';
import PbSection from '../PbSection/PbSection.component';
import { PreviewImage } from '../PreviewImage';
import * as appUtil from '../../utils/app.util';
import { overlayStore } from '../../stores/overlay.store';
import { notifications } from '@mantine/notifications';
import { PbAgGridReact } from '../PbAgGridReact';
import PbSelect from '../PbSelect/PbSelect.component';
import { decode } from 'html-entities';
import { FlexImageFilePreviewList } from '../FlexImageFilePreviewList';
import { phoneNumberformatter } from '../../utils/app.util';

interface IMN2302220204Props {
  id?: string;
  csId?: string;
  useModifyMode?: boolean;
  historyMode?: boolean;
}

/**
 * 운영 관리 > CS 조회 > 등록, 조회, 수정
 * @param id 컴포넌트 모달 아이디
 * @param csId 운영 아이디
 * @param useModifyMode 수정 모드 사용 여부
 * @param historyMode 이력 등록 모드 사용 여부
 * @constructor
 */

// 고객 정보 폼 그리드 > 프로젝트코드 > 입력을 정의함
let valProjectCodeInput: string = '';

// 고객 정보 폼 그리드 > 고객사코드 > 입력을 정의함
let valClientCodeInput: string = '';

// CS 정보 폼 그리드 > 접수유형1 셀렉트 값을 정의함
let valReceptionType1Select: string = '';
let valReceptionType2Select: string = '';

// 고객 정보 폼 그리드 > Shop명 > 입력을 정의함
let valShopCodeInput: string = '';

const MN2302220502 = ({
  id = '',
  csId = '',
  useModifyMode = false,
  historyMode = false,
}: PropsWithChildren<IMN2302220204Props>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 화면 위 검은 화면 저장소를 정의함
  const [visibleOverlay, setVisibleOverlay] =
    useRecoilState<boolean>(overlayStore);

  // CS 작업 폼 그리드 > CS작업상태 > 셀렉트를 정의함
  const [csWorkStateSelect, setCsWorkStateSelect] = useState<string>('');
  const [csWorkStateSelectItem, setCsWorkStateSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 이관 정보 폼 그리드 > 이관부서 > 셀렉트를 정의함
  const [transferDepartmentSelect, setTransferDepartmentSelect] =
    useState<string>('');
  const [transferDepartmentSelectItem, setTransferDepartmentSelectItem] =
    useState<IOptionItem[]>([]);

  // 이관 정보 폼 그리드 > 이관담당자 > 셀렉트를 정의함
  const [transferWorkerSelect, setTransferWorkerSelect] = useState<string>('');
  const [transferWorkerSelectItem, setTransferWorkerSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 고객 정보 폼 그리드 > 프로젝트명 > 셀렉트를 정의함
  const [projectNameSelect, setProjectNameSelect] = useState<string>('');
  const [projectNameSelectItem, setProjectNameSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 고객 정보 폼 그리드 > 프로젝트코드 > 입력을 정의함
  const [projectCodeInput, setProjectCodeInput] = useState<string>('');

  // 고객 정보 폼 그리드 > 고객사명 > 셀렉트를 정의함
  const [clientNameSelect, setClientNameSelect] = useState<string>('');
  const [clientNameSelectItem, setClientNameSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 고객 정보 폼 그리드 > 고객사코드 > 입력을 정의함
  const [clientCodeInput, setClientCodeInput] = useState<string>('');

  // 고객 정보 폼 그리드 > Shop명 > 셀렉트를 정의함
  const [shopNameSelect, setShopNameSelect] = useState<string>('');
  const [shopNameSelectItem, setShopNameSelectItem] = useState<IOptionItem[]>(
    [],
  );

  // CS 정보 폼 그리드 > 문의명 > 입력을 정의함
  const [questionInput, setQuestionInput] = useState<string>('');

  // CS 정보 폼 그리드 > CS번호 > 입력을 정의함
  const [csNoInput, setCsNoInput] = useState<string>('');

  // CS 정보 폼 그리드 > CS진행상태 > 셀렉트를 정의함
  const [csProcessStateSelect, setCsProcessStateSelect] = useState<string>('');
  const [csProcessStateSelectItem, setCsProcessStateSelectItem] = useState<
    IOptionItem[]
  >([]);

  // CS 정보 폼 그리드 > CS진행상태 > 입력을 정의함
  const [csProcessStateInput, setCsProcessStateInput] = useState<string>('');

  // CS 정보 폼 그리드 > 처리완료요청일 > 날짜 피커를 정의함
  const [completionRequestDatePicker, setCompletionRequestDatePicker] =
    useState<Date | null>(null);

  // CS 정보 폼 그리드 > 접수유형 > 접수유형 1 셀렉트를 정의함
  const [receptionType1Select, setReceptionType1Select] = useState<string>('');
  const [receptionType1SelectItem, setReceptionType1SelectItem] = useState<
    IOptionItem[]
  >([]);
  const [receptionType1SelectText, setReceptionType1SelectText] =
    useState<string>('');

  // CS 정보 폼 그리드 > 접수유형 > 접수유형 2 셀렉트를 정의함
  const [receptionType2Select, setReceptionType2Select] = useState<string>('');
  const [receptionType2SelectItem, setReceptionType2SelectItem] = useState<
    IOptionItem[]
  >([]);
  const [receptionType2SelectText, setReceptionType2SelectText] =
    useState<string>('');

  // CS 정보 폼 그리드 > 장애구분 > 셀렉트를 정의함
  const [obstacleTypeSelect, setObstacleTypeSelect] = useState<string>('');
  const [obstacleTypeSelectItem, setObstacleTypeSelectItem] = useState<
    IOptionItem[]
  >([]);

  // CS 정보 폼 그리드 > 고객특성 > 셀렉트를 정의함
  const [customerCharacteristicsSelect, setCustomerCharacteristicsSelect] =
    useState<string>('');
  const [
    customerCharacteristicsSelectItem,
    setCustomerCharacteristicsSelectItem,
  ] = useState<IOptionItem[]>([]);

  // CS 정보 폼 그리드 > 접수일시 > 입력을 정의함
  const [receptionDatePicker, setReceptionDatePicker] = useState<Date | null>(
    null,
  );

  // CS 정보 폼 그리드 > 회신완료일시 > 입력을 정의함
  const [replyCompletionDatePicker, setReplyCompletionDatePicker] =
    useState<Date | null>(null);

  // CS 정보 폼 그리드 > 현상내용 > 입력을 정의함
  const [eventContentInput, setEventContentInput] = useState<string>('');

  // CS 정보 폼 그리드 > 원인내용 > 입력을 정의함
  const [causeContentInput, setCauseContentInput] = useState<string>('');

  // CS 정보 폼 그리드 > 처리결과 > 입력을 정의함
  const [processingResultInput, setProcessingResultInput] =
    useState<string>('');

  // CS 상세 데이터를 정의함
  const [csData, setCsData] = useState<any>(null);

  // 이관 및 처리 이력 테이블을 정의함
  const [historyTable, setHistoryTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 첨부파일 다운로드를 클릭함
  const handleFileDownload_onClick = (fileId: string, rgnlFileName: string) => {
    slsApi
      .getSlsCtvtFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  // 컴포넌트 모달 > 저장 버튼(신규)을 클릭함
  const handleSaveAddButton_onClick = () => {
    // CS 작업 폼 그리드 > CS작업상태 > 셀렉트를 확인함
    if (csWorkStateSelect === '') {
      setModal({
        title: '알림',
        content: 'CS작업상태를 선택해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 문의명 > 입력을 확인함
    if (questionInput.trim() === '') {
      setModal({
        title: '알림',
        content: '문의명을 입력해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 처리완료요청일 > 날짜 피커를 확인함
    if (!completionRequestDatePicker) {
      setModal({
        title: '알림',
        content: '처리완료요청일을 입력해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 접수유형 > 셀렉트를 확인함
    if (receptionType1Select === '') {
      setModal({
        title: '알림',
        content: '접수유형을 선택해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 접수유형 > 셀렉트를 확인함
    if (receptionType2Select === '') {
      setModal({
        title: '알림',
        content: '접수유형을 선택해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 장애구분 > 셀렉트를 확인함
    if (obstacleTypeSelect === '') {
      setModal({
        title: '알림',
        content: '장애구분을 선택해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 고객특성 > 셀렉트를 확인함
    if (customerCharacteristicsSelect === '') {
      setModal({
        title: '알림',
        content: '고객특성을 선택해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 현상내용 > 입력을 확인함
    if (eventContentInput.trim() === '') {
      setModal({
        title: '알림',
        content: '현상내용을 입력해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 처리결과 > 입력을 확인함
    if (processingResultInput.trim() === '') {
      setModal({
        title: '알림',
        content: '처리결과를 입력해 주세요.',
      });

      return;
    }

    // 기존 첨부파일 아이디를 불러옴
    let tmpPreFileId: string[] = [];

    // 기존 첨부파일 설명을 불러옴
    // let tmpPreFileDescriptions: string[] = [];

    // 기존 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile === null)
      .map((item: any) => {
        tmpPreFileId.push(item.fileId);
        // tmpPreFileDescriptions.push(item.fileDscr);
      });

    // 신규 첨부파일을 불러옴
    let tmpNewFile: any[] = [];

    // 신규 첨부파일 설명을 불러옴
    // let tmpNewFileDescriptions: string[] = [];

    // 신규 첨부파일 구분을 불러옴
    // let tmpNewCsStateCodeList: string[] = [];

    // 신규 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile !== null)
      .map((item: any) => {
        tmpNewFile.push(item.newFile);
        // tmpNewFileDescriptions.push(item.fileDscr);

        // 임시!!
        // tmpNewCsStateCodeList.push('ETC');
      });

    // 첨부파일의 설명을 확인함
    // let checkFileDescriptions: boolean = false;

    // fileTable.data.map((item: any) => {
    //   if (!item.fileDscr.trim()) {
    //     checkFileDescriptions = true;
    //   }
    // });

    // if (checkFileDescriptions) {
    //   setModal({
    //     title: '알림',
    //     content: '첨부 파일의 설명을 입력해 주세요.',
    //   });

    //   return;
    // }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            // 화면 위의 검은 화면 출력 여부에 적용함
            setVisibleOverlay(true);

            notifications.show({
              title: '알림',
              message: '저장 중입니다.',
            });

            setRemoveModal(true);

            // CS 등록을 처리함
            csApi
              .postCs({
                csSttsCode: csWorkStateSelect || '', // CS 상태코드
                csDprtCode: transferDepartmentSelect || '', // CS 부서코드
                csMngrId: transferWorkerSelect || '', // CS 담당자 아이디
                prjcCode: projectNameSelect || '', // 프로젝트 코드
                bpCode: clientNameSelect || '', // 고객사 코드
                shopId: shopNameSelect || '', // Shop 아이디
                nqryName: questionInput.trim() || '', // 문의 그룹
                cstyGrpCode: receptionType1Select || '', // CS 유형 그룹 코드 - 접수유형1
                cstyCode: receptionType2Select || '', // CS 유형 코드 - 접수유형 2
                dsblDntfCode: obstacleTypeSelect || '', // 장애구분 코드
                cstmTrbtCode: customerCharacteristicsSelect || '', // 고객특성 코드
                przCntn: eventContentInput.trim() || '', // 현상내용
                csDtls: causeContentInput.trim() || '', // 원인 내용
                prcsRslt: processingResultInput.trim() || '', // 처리 결과
                prcsRqstDate: completionRequestDatePicker
                  ? moment(completionRequestDatePicker).format('YYYYMMDD')
                  : '', // 처리완료 요청일자
                useYn: 'Y', // 사용여부
                // calCntrId: params.calCntrId || '', // 콜센타 아이디
                // prtnTeamId: params.prtnTeamId || '', // 운영팀 아이디
                // dvlpTeamId: params.dvlpTeamId || '', // 개발팀 아이디
                // fldTeamId: params.fldTeamId || '', // 현장팀 아이디
                fileList: tmpNewFile, // 첨부파일
                // fileDscrList: tmpNewFileDescriptions,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  // 화면 위의 검은 화면 출력 여부에 적용함
                  setVisibleOverlay(false);

                  notifications.show({
                    title: '알림',
                    message: '저장하였습니다.',
                  });

                  // 목록 새로고침을 추가함
                  setAddRefreshList('MN2302220503Table');

                  // 컴포넌트 모달을 닫음
                  setRemoveComponentModal(id);

                  // setModal({
                  //   title: '알림',
                  //   content: '저장하였습니다.',
                  //   callback: () => {
                  //     // 목록 새로고침을 추가함
                  //     setAddRefreshList('MN2302220503Table');
                  //
                  //     // 컴포넌트 모달을 닫음
                  //     setRemoveComponentModal(id);
                  //
                  //     // setTimeout(() => {
                  //     //   // 조회 컴포넌트 모달을 추가함
                  //     //   let tmpId: string = nanoid();
                  //
                  //     //   // 컴포넌트 모달을 추가함
                  //     //   setAddComponentModal({
                  //     //     id: tmpId,
                  //     //     title: 'CS 상세',
                  //     //     content: <MN2302220502 id={tmpId} csId={csId} />,
                  //     //     size: 1500,
                  //     //   });
                  //     // }, 100);
                  //   },
                  // });
                } else {
                  // 화면 위의 검은 화면 출력 여부에 적용함
                  setVisibleOverlay(false);

                  setModal({
                    title: '오류',
                    content: (
                      <>
                        <div>저장에 실패하였습니다.</div>
                        <div>({data.message})</div>
                      </>
                    ),
                  });
                }
              })
              .catch((error: any) => {
                // 화면 위의 검은 화면 출력 여부에 적용함
                setVisibleOverlay(false);

                setModal({
                  title: '오류',
                  content: `저장에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 컴포넌트 모달 > 저장 버튼(수정)을 클릭함
  const handleSaveUpdateButton_onClick = () => {
    // CS 작업 폼 그리드 > CS작업상태 > 셀렉트를 확인함
    if (csWorkStateSelect === '') {
      setModal({
        title: '알림',
        content: 'CS작업상태를 선택해 주세요.',
      });

      return;
    }

    // 이관 정보 폼 그리드 > 이관부서 > 셀렉트를 확인함
    // CS작업상태 항목이 '이관'일 때만 처리함
    if (csWorkStateSelect === 'SCLT') {
      if (transferDepartmentSelect === '') {
        setModal({
          title: '알림',
          content: '이관부서를 입력해 주세요.',
        });

        return;
      }
    }

    // CS 정보 폼 그리드 > 문의명 > 입력을 확인함
    if (questionInput.trim() === '') {
      setModal({
        title: '알림',
        content: '문의명을 입력해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 처리완료요청일 > 날짜 피커를 확인함
    if (!completionRequestDatePicker) {
      setModal({
        title: '알림',
        content: '처리완료요청일을 입력해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 접수유형 > 셀렉트를 확인함
    if (receptionType1Select === '') {
      setModal({
        title: '알림',
        content: '접수유형을 선택해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 접수유형 > 셀렉트를 확인함
    if (receptionType2Select === '') {
      setModal({
        title: '알림',
        content: '접수유형을 선택해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 장애구분 > 셀렉트를 확인함
    if (obstacleTypeSelect === '') {
      setModal({
        title: '알림',
        content: '장애구분을 선택해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 고객특성 > 셀렉트를 확인함
    if (customerCharacteristicsSelect === '') {
      setModal({
        title: '알림',
        content: '고객특성을 선택해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 현상내용 > 입력을 확인함
    if (eventContentInput.trim() === '') {
      setModal({
        title: '알림',
        content: '현상내용을 입력해 주세요.',
      });

      return;
    }

    // CS 정보 폼 그리드 > 처리결과 > 입력을 확인함
    if (processingResultInput.trim() === '') {
      setModal({
        title: '알림',
        content: '처리결과를 입력해 주세요.',
      });

      return;
    }

    // 기존 첨부파일 아이디를 불러옴
    let tmpPreFileId: string[] = [];

    // 기존 첨부파일 설명을 불러옴
    let tmpPreFileDescriptions: string[] = [];

    // 기존 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile === null)
      .map((item: any) => {
        tmpPreFileId.push(item.fileId);
        tmpPreFileDescriptions.push(item.fileDscr);
      });

    // 신규 첨부파일을 불러옴
    let tmpNewFile: any[] = [];

    // 신규 첨부파일 구분을 불러옴
    let tmpNewSlsfClsfCodeList: string[] = [];

    // 신규 첨부파일 설명을 불러옴
    let tmpNewFileDescriptions: string[] = [];

    // 신규 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile !== null)
      .map((item: any) => {
        tmpNewFile.push(item.newFile);
        tmpNewSlsfClsfCodeList.push(item.slsfClsfCode);
        tmpNewFileDescriptions.push(item.fileDscr);
      });

    // 첨부파일의 설명을 확인함
    let checkFileDescriptions: boolean = false;

    fileTable.data.map((item: any) => {
      if (!item.fileDscr.trim()) {
        checkFileDescriptions = true;
      }
    });

    // if (checkFileDescriptions) {
    //   setModal({
    //     title: '알림',
    //     content: '첨부 파일의 설명을 입력해 주세요.',
    //   });
    //
    //   return;
    // }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            // 화면 위의 검은 화면 출력 여부에 적용함
            setVisibleOverlay(true);

            notifications.show({
              title: '알림',
              message: '저장 중입니다.',
            });

            setRemoveModal(true);

            // CS 수정을 처리함
            csApi
              .postCsUpdate({
                csId: csId || '',
                csSttsCode: csWorkStateSelect || '', // CS 상태코드
                csDprtCode: transferDepartmentSelect || '', // CS 부서코드
                csMngrId: transferWorkerSelect || '', // CS 담당자 아이디
                prjcCode: projectNameSelect || '', // 프로젝트 코드
                bpCode: clientNameSelect || '', // 고객사 코드
                shopId: shopNameSelect || '', // Shop 아이디
                nqryName: questionInput.trim() || '', // 문의 그룹
                cstyGrpCode: receptionType1Select || '', // CS 유형 그룹 코드 - 접수유형1
                cstyCode: receptionType2Select || '', // CS 유형 코드 - 접수유형 2
                dsblDntfCode: obstacleTypeSelect || '', // 장애구분 코드
                cstmTrbtCode: customerCharacteristicsSelect || '', // 고객특성 코드
                przCntn: eventContentInput.trim() || '', // 현상내용
                csDtls: causeContentInput.trim() || '', // 원인 내용
                prcsRslt: processingResultInput.trim() || '', // 처리 결과
                prcsRqstDate: completionRequestDatePicker
                  ? moment(completionRequestDatePicker).format('YYYYMMDD')
                  : '', // 처리완료 요청일자
                useYn: 'Y', // 사용여부
                // calCntrId: params.calCntrId || '', // 콜센타 아이디
                // prtnTeamId: params.prtnTeamId || '', // 운영팀 아이디
                // dvlpTeamId: params.dvlpTeamId || '', // 개발팀 아이디
                // fldTeamId: params.fldTeamId || '', // 현장팀 아이디
                fileList: tmpNewFile, // 신규 첨부파일
                bfFileIdList: tmpPreFileId, // 기존 첨부파일
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  setModal({
                    title: '알림',
                    content: '저장하였습니다.',
                    callback: () => {
                      // 화면 위의 검은 화면 출력 여부에 적용함
                      setVisibleOverlay(false);

                      notifications.show({
                        title: '알림',
                        message: '저장하였습니다.',
                      });

                      // 목록 새로고침을 추가함
                      setAddRefreshList('MN2302220503Table');

                      // 컴포넌트 모달을 닫음
                      setRemoveComponentModal(id);

                      setTimeout(() => {
                        // 조회 컴포넌트 모달을 추가함
                        let tmpId: string = nanoid();

                        // 컴포넌트 모달을 추가함
                        setAddComponentModal({
                          id: tmpId,
                          title: 'CS 상세',
                          content: <MN2302220502 id={tmpId} csId={csId} />,
                          size: 1500,
                        });
                      }, 100);
                    },
                  });
                } else {
                  // 화면 위의 검은 화면 출력 여부에 적용함
                  setVisibleOverlay(false);

                  setModal({
                    title: '오류',
                    content: (
                      <>
                        <div>저장에 실패하였습니다.</div>
                        <div>({data.message})</div>
                      </>
                    ),
                  });
                }
              })
              .catch((error: any) => {
                // 화면 위의 검은 화면 출력 여부에 적용함
                setVisibleOverlay(false);

                setModal({
                  title: '오류',
                  content: `저장에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 컴포넌트 모달 > 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    setModal({
      title: '확인',
      content: `CS 정보[${csData.nqryName}] 를 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // CS을 삭제함
            csApi
              .deleteCs({
                csId: csId,
              })
              .then((data: IApiResult) => {
                switch (data.code) {
                  case '200':
                    // 모달을 출력함
                    setModal({
                      title: '알림',
                      content: '삭제하였습니다.',
                      callback: () => setRemoveComponentModal(id),
                    });
                    break;

                  case '':
                    // 모달을 출력함
                    setModal({
                      title: t('common.error'),
                      content: '삭제에 실패하였습니다.',
                    });
                    break;

                  default:
                    break;
                }
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 컴포넌트 모달 > 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: 'CS 이력 등록',
        content: (
          <MN2302220502
            id={tmpId}
            csId={csId}
            useModifyMode={true}
            historyMode={true}
          />
        ),
        size: 1500,
      });
    }, 100);
  };

  // CS 작업 폼 그리드 > CS작업상태 > 셀렉트에서 선택한 아이템을 변경함
  const handleCsWorkStateSelect_onChange = (event: any) => {
    setCsWorkStateSelect(event);
    // setCsWorkStateSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 이관 정보 폼 그리드 > 이관부서 셀렉트에서 선택한 아이템을 변경함
  const handleTransferDepartmentSelect_onChange = (event: any) => {
    setTransferDepartmentSelect(event);
    // setTransferDepartmentSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 이관 정보 폼 그리드 > 이관담당자 셀렉트에서 선택한 아이템을 변경함
  const handleTransferWorkerSelect_onChange = (event: any) => {
    setTransferWorkerSelect(event);
    // setTransferWorkerSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 이관부서 값 설정용 - 상세 정보 우선 검색시 대비용
  const [initTransferWorker, setInitTransferWorker] = useState<string>('');

  // 고객 정보 폼 그리드 > 프로젝트명 셀렉트에서 선택한 아이템을 변경함
  const handleProjectNameSelect_onChange = (event: any) => {
    valProjectCodeInput = event;
    // valClientCodeInput = '';
    // valShopCodeInput = '';

    setProjectNameSelect(event);
    // setProjectNameSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));

    setProjectCodeInput(event);
  };

  // 고객 정보 폼 그리드 > 고객사명 셀렉트에서 선택한 아이템을 변경함
  const handleClientNameSelect_onChange = (event: any) => {
    valClientCodeInput = event;
    // valShopCodeInput = '';
    setClientNameSelect(event);
    // setClientNameSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));

    setClientCodeInput(event);
  };

  // 고객사명 설정용 - 상세 정보 우선 검색시 대비용
  const [initClientName, setInitClientName] = useState<string>('');

  // 고객 정보 폼 그리드 > Shop명 셀렉트에서 선택한 아이템을 변경함
  const handleShopNameSelect_onChange = (event: any) => {
    valShopCodeInput = event;
    setShopNameSelect(event);
    // setShopNameSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // Shop명 값 설정용 - 상세 정보 우선 검색시 대비용
  const [initShopName, setInitShopName] = useState<string>('');

  // 고객정보 폼 그리드 > 이력 > 네트워크 작업 결과 버튼을 클릭함
  const handleNetworkResultButton_onChange = () => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '네트워크 작업 결과',
      content: (
        <MN2302220502_Network
          id={tmpId}
          prjcCode={projectNameSelect}
          bpCode={clientNameSelect}
          shipToCode={shopNameSelect}
        />
      ),
      size: 1600,
      button: (
        <div>
          <PbButton
            label="닫기"
            backgroundColor="white"
            size="md"
            onClick={() => setRemoveComponentModal(tmpId)}
          />
        </div>
      ),
    });
  };

  // 고객정보 폼 그리드 > 이력 > CS 히스토리 버튼을 클릭함
  const handleCsHistoryButton_onChange = () => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'CS 히스토리',
      content: (
        <MN2302220502_CsHistory
          id={tmpId}
          prjcCode={projectNameSelect}
          bpCode={clientNameSelect}
          shipToCode={shopNameSelect}
        />
      ),
      size: 1700,
      button: (
        <>
          <div>
            <PbButton
              label="자세히 보기"
              backgroundColor="purple"
              size="md"
              // onClick={handleCsHistoryComponentModalDetailButton_onClick}
            />
          </div>

          <div>
            <PbButton
              label="닫기"
              backgroundColor="white"
              size="md"
              onClick={() => setRemoveComponentModal(tmpId)}
            />
          </div>
        </>
      ),
    });
  };

  // CS 정보 폼 그리드 > 문의명 입력의 값을 변경함
  const handleQuestionInput_onChange = (event: any) => {
    setQuestionInput(event.target.value);
  };

  // CS 정보 폼 그리드 > CS진행상태 셀렉트에서 선택한 아이템을 변경함
  const handleCsProcessStateSelect_onChange = (event: any) => {
    setCsProcessStateSelect(event);
    // setCsProcessStateSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // CS 정보 폼 그리드 > 처리완료요청일 날짜 피커를 변경함
  const handleCompletionRequestDatePicker_onChange = (event: any) => {
    setCompletionRequestDatePicker(event);
  };

  // CS 정보 폼 그리드 > 접수유형1 셀렉트에서 선택한 아이템을 변경함
  const handleReceptionType1Select_onChange = (event: any) => {
    setReceptionType1Select(event);
    // setReceptionType1Select((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // CS 정보 폼 그리드 > 접수유형2 셀렉트에서 선택한 아이템을 변경함
  const handleReceptionType2Select_onChange = (event: any) => {
    setReceptionType2Select(event);
    // setReceptionType2Select((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 접수유형2 - 상세 정보 우선 검색시 대비용
  const [initReceptionType2, setInitReceptionType2] = useState<string>('');

  // CS 정보 폼 그리드 > 장애구분 셀렉트에서 선택한 아이템을 변경함
  const handleObstacleTypeSelect_onChange = (event: any) => {
    setObstacleTypeSelect(event);
    // setObstacleTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // CS 정보 폼 그리드 > 고객특성 셀렉트에서 선택한 아이템을 변경함
  const handleCustomerCharacteristicsSelect_onChange = (event: any) => {
    setCustomerCharacteristicsSelect(event);
    // setCustomerCharacteristicsSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // CS 정보 폼 그리드 > 현상내용 입력의 값을 변경함
  const handleEventContentInput_onChange = (event: any) => {
    var sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 8192) {
    //   // 8192 byte 제한
    //   setEventContentInput((pre) => pre);
    // } else {
    //   setEventContentInput(sVal);
    // }
    setEventContentInput(sVal);
  };

  // CS 정보 폼 그리드 > 원인내용 입력의 값을 변경함
  const handleCauseContentInput_onChange = (event: any) => {
    var sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 8192) {
    //   // 8192 byte 제한
    //   setCauseContentInput((pre) => pre);
    // } else {
    //   setCauseContentInput(sVal);
    // }
    setCauseContentInput(sVal);
  };

  // CS 정보 폼 그리드 > 처리결과 입력의 값을 변경함
  const handleProcessingResultInput_onChange = (event: any) => {
    var sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 8192) {
    //   // 8192 byte 제한
    //   setProcessingResultInput((pre) => pre);
    // } else {
    //   setProcessingResultInput(sVal);
    // }
    setProcessingResultInput(sVal);
  };

  // 처리결과 첨부 파일 폼 그리드 > 테이블의 구분 컬럼에서 버튼을 클릭함
  const handleFileTableDntfCodeButton_onClick = (fileId: string) => {
    setModal({
      title: '선택',
      content: (
        <div className="space-y-2">
          <div className="">
            <span>항목을 선택하세요.</span>
          </div>

          <div className="space-y-1">
            <Button
              onClick={() =>
                handleFileTableDntfCodeItemButton_onClick(fileId, 'PCTR')
              }
              variant="outline"
              color="violet"
              className="w-full"
              style={{
                width: '100%',
              }}
            >
              사진
            </Button>

            <Button
              onClick={() =>
                handleFileTableDntfCodeItemButton_onClick(fileId, 'ETC')
              }
              variant="outline"
              color="violet"
              className="w-full"
              style={{
                width: '100%',
              }}
            >
              기타
            </Button>
          </div>
        </div>
      ),
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '취소',
    });
  };

  // 처리결과 첨부 파일 폼 그리드 > 테이블의 구분 컬럼 > 모달 > 항목 선택 버튼을 클릭함
  const handleFileTableDntfCodeItemButton_onClick = (
    fileId: string,
    selectedItem: string,
  ) => {
    // 테이블의 전체 데이터를 불러옴
    let tmpRowData: any = [];

    fileTableRef.current.api.forEachNode((node: any) =>
      tmpRowData.push(node.data),
    );

    // 수정할 행의 순서를 불러옴
    let tmpIndex: number = _.findIndex(tmpRowData, {
      fileId: fileId,
    });

    // 선택한 값을 적용함
    tmpRowData[tmpIndex]['fileDntfCode'] = selectedItem;

    // 모달을 닫음
    setRemoveModal(true);
  };

  // CS 상세 데이터를 불러옴
  const getCsData = () => {
    csApi
      .getCs({
        csId: csId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // CS 상세 데이터에 적용함
          setCsData(data.data);
        }
      });
  };

  /**************************** 기타 Function ****************************/
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    if (!csId) {
      // 신규
      tmpTab = [
        {
          label: '작업상태',
          targetId: 'step-1',
        },
        {
          label: '고객',
          targetId: 'step-2',
        },
        {
          label: 'CS',
          targetId: 'step-3',
        },
        {
          label: '첨부파일',
          targetId: 'step-4',
        },
        {
          label: '이관',
          targetId: 'step-5',
        },
      ];
    } else if (csId && !useModifyMode) {
      // 조회
      tmpTab = [
        {
          label: '작업상태',
          targetId: 'step-1',
        },
        {
          label: '고객',
          targetId: 'step-2',
        },
        {
          label: 'CS',
          targetId: 'step-3',
        },
        {
          label: '첨부파일',
          targetId: 'step-4',
        },
        {
          label: '이관',
          targetId: 'step-5',
        },
      ];
    } else if (csId && useModifyMode) {
      // 수정
      tmpTab = [
        {
          label: '작업상태',
          targetId: 'step-1',
        },
        {
          label: '고객',
          targetId: 'step-2',
        },
        {
          label: 'CS',
          targetId: 'step-3',
        },
        {
          label: '첨부파일',
          targetId: 'step-4',
        },
        {
          label: '이관',
          targetId: 'step-5',
        },
      ];
    }

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // CS 작업 폼 그리드 > CS작업상태 > 셀렉트의 아이템을 초기화함
  const initCsWorkStateSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!csId || (csId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 상세 공통코드(CS 상태)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'CS_STTS' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // CS 작업 폼 그리드 > CS작업상태 > 셀렉트의 아이템에 적용함
        setCsWorkStateSelectItem(tmpOptionItem);
        // setCsWorkStateSelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // 이관 정보 폼 그리드 > 이관부서 > 셀렉트의 아이템을 초기화함
  const initTransferDepartmentSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!csId || (csId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 상세 공통코드(부서)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'CS_DPRT' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 이관 정보 폼 그리드 > 이관부서 > 셀렉트의 아이템에 적용함
        setTransferDepartmentSelectItem(tmpOptionItem);
        // setTransferDepartmentSelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // 이관 정보 폼 그리드 > 이관담당자 > 셀렉트의 아이템을 초기화함
  const initTransferWorkerSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!csId || (csId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    if (transferDepartmentSelect === '') {
      // 고객 정보 폼 그리드 > 이관담당자 > 셀렉트의 아이템에 적용함
      setTransferWorkerSelect('');
      setTransferWorkerSelectItem(tmpOptionItem);
      // setTransferWorkerSelect((pre: ISelect) => ({
      //   value: '',
      //   item: tmpOptionItem,
      // }));
      setInitTransferWorker(''); // 이관부서 변경 대비 이관담당자 초기화
    } else {
      // CS 등록 - 고객사명 > 셀렉트박스를 불러옴
      csApi
        .getCsMngr({ dprtCode: transferDepartmentSelect })
        .then((data: IApiResult) => {
          data.data.list.map((item: any, index: number) => {
            tmpOptionItem.push({
              label: `${item.name}: ${phoneNumberformatter(item.celPhn)}`,
              value: item.userId,
            });
          });

          if (initTransferWorker === 'Y') {
            // 고객 정보 폼 그리드 > 이관담당자 > 셀렉트의 아이템에 적용함
            setTransferWorkerSelect(csData.csMngrId);
            setTransferWorkerSelectItem(tmpOptionItem);
            // setTransferWorkerSelect((pre: ISelect) => ({
            //   value: csData.csMngrId,
            //   item: tmpOptionItem,
            // }));
            setInitTransferWorker(''); // 이관부서 변경 대비 이관담당자 초기화
          } else {
            // 고객 정보 폼 그리드 > 이관담당자 > 셀렉트의 아이템에 적용함
            setTransferWorkerSelect('');
            setTransferWorkerSelectItem(tmpOptionItem);
            // setTransferWorkerSelect((pre: ISelect) => ({
            //   value: '',
            //   item: tmpOptionItem,
            // }));
          }
        });
    }
  };

  // 고객 정보 폼 그리드 > 프로젝트명 > 셀렉트의 아이템을 초기화함
  const initProjectNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!csId || (csId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // CS 등록 - 프로젝트명 > 셀렉트박스를 불러옴
    csApi.getPrjcs({}).then((data: IApiResult) => {
      data.data.list.map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.prjcName,
          value: item.prjcCode,
        });
      });

      // 고객 정보 폼 그리드 > 프로젝트명 > 셀렉트의 아이템에 적용함
      setProjectNameSelect(valProjectCodeInput);
      setProjectNameSelectItem(tmpOptionItem);
      // setProjectNameSelect({
      //   value: valProjectCodeInput,
      //   item: tmpOptionItem,
      // });

      // 고객 정보 폼 그리드 > 프로젝트코드 > 입력을 초기화함
      valProjectCodeInput = valProjectCodeInput;
      setProjectCodeInput(valProjectCodeInput);
    });

    valProjectCodeInput = '';
    valClientCodeInput = '';
  };

  // 고객 정보 폼 그리드 > 고객사명 > 셀렉트의 아이템을 초기화함
  const initClientNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!csId || (csId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 고객 정보 폼 그리드 > 프로젝트명 > 셀렉트에 선택한 값이 있을 때만 진행함
    if (valProjectCodeInput !== '') {
      // CS 등록 - 고객사명 > 셀렉트박스를 불러옴
      csApi
        .getBps({
          prjcCode: valProjectCodeInput,
        })
        .then((data: IApiResult) => {
          data.data.list.map((item: any, index: number) => {
            tmpOptionItem.push({
              label: item.bpName,
              value: item.bpCode,
            });
          });

          if (initClientName === 'Y') {
            // 고객 정보 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
            setClientNameSelect(valClientCodeInput);
            setClientNameSelectItem(tmpOptionItem);
            // setClientNameSelect({
            //   // value: csData.bpCode,
            //   value: valClientCodeInput,
            //   item: tmpOptionItem,
            // });
            // 고객 정보 폼 그리드 > 고객사코드 > 입력을 초기화함
            // setClientCodeInput(csData.bpCode);
            setClientCodeInput(valClientCodeInput);
            setInitClientName(''); // 이관부서 변경 대비 이관담당자 초기화
          } else {
            // 고객 정보 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
            setClientNameSelect(valClientCodeInput);
            setClientNameSelectItem(tmpOptionItem);
            // setClientNameSelect({
            //   value: valClientCodeInput,
            //   item: tmpOptionItem,
            // });

            // 고객 정보 폼 그리드 > 고객사코드 > 입력을 초기화함
            setClientCodeInput(valClientCodeInput);
          }
        });
    }

    // 고객 정보 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
    setClientNameSelect('');
    setClientNameSelectItem(tmpOptionItem);
    // setClientNameSelect({
    //   value: '',
    //   item: tmpOptionItem,
    // });
  };

  // 고객 정보 폼 그리드 > Shop명 > 셀렉트의 아이템을 초기화함
  const initShopNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!csId || (csId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 고객 정보 폼 그리드 > 프로젝트명 > 셀렉트에 선택한 값이 있을 때만 진행함
    // 고객 정보 폼 그리드 > 고객사명 > 셀렉트에 선택한 값이 있을 때만 진행함
    if (valProjectCodeInput !== '' && valClientCodeInput !== '') {
      // CS 등록 - Shop명 > 셀렉트박스를 불러옴
      csApi
        .getShops({
          prjcCode: valProjectCodeInput,
          bpCode: valClientCodeInput,
        })
        .then((data: IApiResult) => {
          if (data.data.list.length > 0) {
            data.data.list.map((item: any, index: number) => {
              tmpOptionItem.push({
                label: item.drsId,
                value: item.drsId,
              });
            });
          }

          if (initShopName === 'Y') {
            // 고객 정보 폼 그리드 > Shop명 > 셀렉트의 아이템에 적용함
            setShopNameSelect(valShopCodeInput);
            setShopNameSelectItem(tmpOptionItem);
            // setShopNameSelect({
            //   // value: csData.shopId,
            //   value: valShopCodeInput,
            //   item: tmpOptionItem,
            // });
            setInitShopName(valShopCodeInput); // Shop명 변경 대비 이관담당자 초기화
          } else {
            // 고객 정보 폼 그리드 > Shop명 > 셀렉트의 아이템에 적용함
            setShopNameSelect(valShopCodeInput);
            setShopNameSelectItem(tmpOptionItem);
            // setShopNameSelect({
            //   value: valShopCodeInput,
            //   item: tmpOptionItem,
            // });
          }
        });
    }

    // 고객 정보 폼 그리드 > Shop명 > 셀렉트의 아이템에 적용함
    setShopNameSelect('');
    setShopNameSelectItem(tmpOptionItem);
    // setShopNameSelect({
    //   value: '',
    //   item: tmpOptionItem,
    // });
  };

  // CS 정보 폼 그리드 > 접수유형 > 접수유형 1 셀렉트의 아이템을 초기화함
  const initReceptionType1Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!csId || (csId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // CS 접수유형 조회(셀렉트박스)를 불러옴
    csApi.getCsType({ depth: 1 }).then((data: IApiResult) => {
      data.data.list.map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });

      // CS 정보 폼 그리드 > 접수유형 > 접수유형 1 셀렉트의 아이템에 적용함
      setReceptionType1Select(valReceptionType1Select);
      setReceptionType1SelectItem(tmpOptionItem);
      // setReceptionType1Select({
      //   value: valReceptionType1Select,
      //   item: tmpOptionItem,
      // });
    });
  };

  // CS 정보 폼 그리드 > 접수유형 > 접수유형 2 셀렉트의 아이템을 초기화함
  const initReceptionType2Select = () => {
    let tmpOptionItem2: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!csId || (csId && useModifyMode)) {
      tmpOptionItem2.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // CS 접수유형 조회(셀렉트박스)를 불러옴
    csApi
      .getCsType({
        depth: 2,
        searchKeyword: receptionType1Select,
      })
      .then((data: IApiResult) => {
        data.data.list.map((item: any, index: number) => {
          tmpOptionItem2.push({
            label: item.dtlCodeDscr,
            value: item.dtlCodeId,
          });

          if (initReceptionType2 === 'Y') {
            // 고객 정보 폼 그리드 > 접수유형 > 접수유형 2 > 셀렉트의 아이템에 적용함
            setReceptionType2Select(csData.cstyCode);
            setReceptionType2SelectItem(tmpOptionItem2);
            // setReceptionType2Select({
            //   value: csData.cstyCode,
            //   item: tmpOptionItem2,
            // });
            setInitReceptionType2('');
          } else {
            // CS 정보 폼 그리드 > 접수유형 > 접수유형 2 셀렉트의 아이템에 적용함
            setReceptionType2Select(valReceptionType2Select);
            setReceptionType2SelectItem(tmpOptionItem2);
            // setReceptionType2Select((pre: ISelect) => ({
            //   value: valReceptionType2Select,
            //   item: tmpOptionItem2,
            // }));
          }
        });
      });
  };

  // CS 정보 폼 그리드 > 장애구분 > 셀렉트의 아이템을 초기화함
  const initObstacleType1Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!csId || (csId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 상세 공통코드(장애구분)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'DSBL_CLSF' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // CS 정보 폼 그리드 > 장애구분 > 셀렉트의 아이템에 적용함
        setObstacleTypeSelectItem(tmpOptionItem);
        // setObstacleTypeSelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // CS 정보 폼 그리드 > 고객특성 > 셀렉트의 아이템을 초기화함
  const initCustomerCharacteristicsSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!csId || (csId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 상세 공통코드(고객특성)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'CSTM_CHRC' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // CS 정보 폼 그리드 > 고객특성 > 셀렉트의 아이템에 적용함
        setCustomerCharacteristicsSelectItem(tmpOptionItem);
        // setCustomerCharacteristicsSelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // 이관 및 처리 이력 테이블을 초기화함
  const initHistoryTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '부서',
        field: 'csDprtName',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: '담당자',
        field: 'csMngrName',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: 'CS작업상태',
        field: 'csSttsName',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '등록일시',
        field: 'rgstDate',
        width: 160,
        suppressSizeToFit: true,
      },
      {
        headerName: '처리결과',
        field: 'prcsRslt',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '첨부파일 미리보기',
        field: 'fileList',
        width: 200,
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center space-x-3">
            {/* 첨부파일 목록만큼 다운로드 아이콘을 생성함 */}
            {params.value.map((item: any, index: number) => {
              // 이미지 파일의 확장자를 정의함
              let tmpImageFileExt: string[] = [
                '.jpg',
                '.jpeg',
                '.png',
                '.gif',
                '.bmp',
                '.tif',
              ];

              // 첨부파일에서 확장자를 추출함
              let tmpFileExt: string = _.padEnd(item.rgnlFileName, 5);

              let result: any = <></>;

              // 이미지 파일의 확장자가 이미지인지 확인함
              tmpImageFileExt.map((imageItem: string) => {
                if (_.includes(tmpFileExt, imageItem)) {
                  result = (
                    <div
                      onClick={() => {
                        let tmpId: string = nanoid();

                        // 컴포넌트 모달을 추가함
                        setAddComponentModal({
                          id: tmpId,
                          title: '이미지 미리보기',
                          content: (
                            <PreviewImage
                              data={`${appUtil.getResizeImageUrl()}${
                                item.pldFileName
                              }`}
                            />
                          ),
                          // size: 1900,
                          closeOnClickOutside: true,
                        });
                      }}
                      title={`${item.slsfClsfName}: ${item.rgnlFileName}`}
                      className="button-event w-9 h-9 p-px flex justify-center items-center border"
                    >
                      <img
                        src={`${appUtil.getThumbnailImageUrl()}${
                          item.pldFileName
                        }`}
                        className="w-full"
                      />
                    </div>
                  );

                  return;
                }
              });

              return result;
            })}
          </div>
        ),
        suppressSizeToFit: true,
      },
      {
        headerName: '첨부파일',
        field: 'fileList',
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center space-x-3">
            {/* 첨부파일 목록만큼 다운로드 아이콘을 생성함 */}
            {params.value.map((item: any, index: number) => (
              <div
                key={index}
                onClick={() =>
                  handleHistoryTableFileDownload_onClick(
                    item.fileId,
                    item.rgnlFileName,
                  )
                }
                title={item.rgnlFileName}
                className="button-event px-0.5 py-0.5 flex justify-center items-center"
              >
                <FontAwesomeIcon
                  icon={['far', 'file']}
                  className="w-5 h-5 text-gray-500"
                />
              </div>
            ))}
          </div>
        ),
      },
    );

    // 이관 및 처리 이력 테이블에 적용함
    setHistoryTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 이관 및 처리 이력 테이블 데이터를 불러옴
  const getHistoryTableData = () => {
    // 이관 및 처리 이력 테이블에 적용함
    setHistoryTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: csData.hstrList,
    }));
  };

  // 이관 및 처리 폼 그리드 > 테이블 > 첨부파일 다운로드를 클릭함
  const handleHistoryTableFileDownload_onClick = (
    fileId: string,
    rgnlFileName: string,
  ) => {
    csApi
      .getCsFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  /**
   * 첨부파일 추가
   */

  // 첨부 파일 섹션 > 테이블을 정의함
  const [fileTable, setFileTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 첨부 파일 섹션 > 미리보기 테이블을 정의함
  const [previewFileImages, setPreviewFileImages] = useState<any[]>([]);

  // 첨부 파일 섹션 > 테이블 참조를 정의함
  const fileTableRef = useRef<any>(null);

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블을 초기화함
  const initFileTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 등록, 수정 모드일 경우에만 적용함
    if (!(csId && !useModifyMode)) {
      // 컬럼에 추가함
      tmpColumn.push({
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      });
    }

    // 컬럼에 추가함
    tmpColumn.push(
      // {
      //   field: 'checkbox',
      //   width: 50,
      //   checkboxSelection: true,
      //   headerCheckboxSelection: true,
      // },
      // {
      //   headerName: '구분',
      //   field: 'fileDntfCode',
      //   width: 120,
      //   cellRenderer: (params: any) => (
      //     <div className="w-full h-full flex justify-start items-center">
      //       {/* 버튼 */}
      //       <Button
      //         onClick={() =>
      //           handleFileTableDntfCodeButton_onClick(params.data.fileId)
      //         }
      //         variant="outline"
      //         color="indigo"
      //         style={{
      //           width: '100%',
      //         }}
      //         className=""
      //         disabled={csId && !useModifyMode ? true : false}
      //       >
      //         {params.value === 'PCTR' && '사진'}
      //         {params.value === 'ETC' && '기타'}
      //       </Button>
      //     </div>
      //   ),
      //   editable: false,
      // },
      {
        headerName: '파일명',
        field: 'rgnlFileName',
        editable: false,
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center">
            <div>
              <span
                onClick={() =>
                  handleFileDownload_onClick(
                    params.data.fileId,
                    params.data.rgnlFileName,
                  )
                }
                title={`다운로드: ${params.data.rgnlFileName}`}
                className="cursor-pointer hover:underline"
              >
                {params.value}
              </span>
            </div>
          </div>
        ),
      },
      {
        headerName: '용량',
        field: 'fileSize',
        editable: false,
        valueFormatter: (params: any) => prettyBytes(_.toNumber(params.value)),
        width: 150,
        suppressSizeToFit: true,
        type: 'rightAligned',
      },
      // {
      //   headerName: '설명',
      //   field: 'fileDscr',
      //   width: 400,
      //   suppressSizeToFit: true,
      // },
    );

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블 데이터를 불러옴
  const getFileTableData = () => {
    csData.hstrList[0].fileList.map((item: any, index: number) => {
      item['newFile'] = null;
    });

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: csData.hstrList[0].fileList,
    }));

    // 이미지 미리보기용 첨부파일 목록을 정의함
    let tmpFileList: any[] = [];

    // 이미지 미리보기용 첨부파일 목록을 생성함
    csData.hstrList[0].fileList.map((fileItem: any) => {
      tmpFileList.push({
        id: fileItem.fileId,
        originalFileName: fileItem.rgnlFileName,
        saveFileName: fileItem.pldFileName,
        size: fileItem.fileSize,
      });
    });

    // 이미지 미리보기용 첨부파일 목록에 적용함
    setPreviewFileImages(tmpFileList);
  };

  // 첨부 파일 섹션 > 전체 삭제 버튼을 클릭함
  const handleFileSectionDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '전체 첨부파일을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 첨부 파일 섹션 > 테이블에 적용함
            setFileTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: [],
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 첨부 파일 섹션 > 선택 삭제 버튼을 클릭함
  const handleFileSectionSelectedDeleteButton_onClick = () => {
    // 삭제할 첨부 파일 아이디 목록을 정의함
    let selectedFileId: string[] = [];

    // 삭제할 첨부 파일 아이디 목록을 불러옴
    fileTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedFileId.push(item.fileId);
    });

    if (selectedFileId.length === 0) {
      setModal({
        title: '알림',
        content: '삭제할 첨부 파일을 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: '선택하신 첨부파일을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 첨부 파일 목록에서 삭제할 첨부 파일을 적용한 후 최종 목록을 정의함
            let tmlFileTableData: any = fileTable.data.filter(
              (item: any) => !_.includes(selectedFileId, item.fileId),
            );

            // 첨부 파일 섹션 > 테이블에 적용함
            setFileTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: tmlFileTableData,
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 첨부 파일 섹션 > 추가 버튼을 클릭함
  const handleFileSectionAddButton_onClick = (event: any) => {
    // 첨부 파일 목록을 불러옴
    let tmlFileTableData: any = _.cloneDeep(fileTable.data);

    // 첨부 파일 목록에 새로운 첨부 파일을 추가함
    tmlFileTableData.push({
      fileId: nanoid(),
      csResultFileType: 'new',
      fileDntfCode: 'ETC',
      rgnlFileName: event.name,
      fileSize: event.size,
      fileDscr: '',
      newFile: event,
    });

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlFileTableData,
    }));
  };

  /* // 첨부파일 추가 */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    // CS 작업 폼 그리드 > CS작업상태 > 셀렉트의 아이템을 초기화함
    initCsWorkStateSelect();

    // CS 작업 폼 그리드 > 이관부서 > 셀렉트의 아이템을 초기화함
    initTransferDepartmentSelect();

    // 이관 정보 폼 그리드 > 이관담당자 셀렉트의 아이템을 초기화함
    // initTransferWorkerSelect();

    // 고객 정보 폼 그리드 > 프로젝트명 셀렉트의 아이템을 초기화함
    initProjectNameSelect();

    // 고객 정보 폼 그리드 > 고객사명 셀렉트의 아이템을 초기화함
    initClientNameSelect();

    // 고객 정보 폼 그리드 > Shop명 셀렉트의 아이템을 초기화함
    initShopNameSelect();

    // CS 정보 폼 그리드 > 처리완료요청일 날짜 피커를 초기화함
    setCompletionRequestDatePicker(moment().toDate());

    // CS 정보 폼 그리드 > 접수유형1 셀렉트의 아이템을 초기화함
    initReceptionType1Select();

    // CS 정보 폼 그리드 > 접수유형2 셀렉트의 아이템을 초기화함
    // initReceptionType2Select();

    // CS 정보 폼 그리드 > 장애구분 셀렉트의 아이템을 초기화함
    initObstacleType1Select();

    // CS 정보 폼 그리드 > 고객특성 셀렉트의 아이템을 초기화함
    initCustomerCharacteristicsSelect();

    // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블을 초기화함
    initFileTable();

    // 이관 및 처리 이력 테이블을 초기화함
    initHistoryTable();

    // 수정 상태일 때는 기존에 저장한 데이터를 불러옴
    if (csId) {
      // CS 상세 데이터를 불러옴
      getCsData();
    }

    return () => {};
  }, []);

  // CS 상세 데이터를 불러온 후 실행함
  useEffect(() => {
    if (!csData) {
      return;
    }

    // 이관 정보 폼 그리드 > 이관담당자 셀렉트 데이타 있음을 확인함
    setInitTransferWorker('Y');

    // 고객 정보 폼 그리드 > 고객사명 셀렉트 데이타 있음을 확인함
    setInitClientName('Y');

    // 고객 정보 폼 그리드 > Shop명 셀렉트 데이타 있음을 확인함
    setInitShopName('Y');

    // 고객 정보 폼 그리드 > 접수유형2 셀렉트 데이타 있음을 확인함
    setInitReceptionType2('Y');

    // CS 작업 폼 그리드 > CS작업상태 > 셀렉트의 아이템을 초기화함
    setCsWorkStateSelect(csData.csSttsCode);
    // setCsWorkStateSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: csData.csSttsCode,
    // }));

    // 이관 정보 폼 그리드 > 이관부서 셀렉트의 아이템을 초기화함
    setTransferDepartmentSelect(csData.csDprtCode);
    // setTransferDepartmentSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: csData.csDprtCode,
    // }));

    // 이관 정보 폼 그리드 > 이관담당자 셀렉트의 아이템을 초기화함
    setTransferWorkerSelect(csData.csMngrId);
    // setTransferWorkerSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: csData.csMngrId,
    // }));

    // 고객 정보 폼 그리드 > 프로젝트명 셀렉트의 아이템을 초기화함
    setProjectNameSelect(csData.prjcCode);
    // setProjectNameSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: csData.prjcCode,
    // }));

    // 고객 정보 폼 그리드 > 프로젝트코드 입력을 초기화함
    valProjectCodeInput = csData.prjcCode;
    valClientCodeInput = csData.bpCode;
    valShopCodeInput = csData.shopId;
    setProjectCodeInput(csData.prjcCode);

    // 고객 정보 폼 그리드 > 고객사명 셀렉트의 아이템을 초기화함
    setTimeout(() => {
      setClientNameSelect(csData.bpCode);
      // setClientNameSelect((pre: ISelect) => ({
      //   ...pre,
      //   value: csData.bpCode,
      // }));

      // 고객 정보 폼 그리드 > 고객사코드 입력을 초기화함
      setClientCodeInput(csData.bpCode);
    }, 500);

    // 고객 정보 폼 그리드 > Shop명 > 셀렉트의 아이템에 적용함
    setTimeout(() => {
      setShopNameSelect(csData.shopId);
      // setShopNameSelect((pre: ISelect) => ({
      //   ...pre,
      //   value: csData.shopId,
      // }));
    }, 1000);

    // CS 정보 폼 그리드 > 문의명 > 입력을 초기화함
    setQuestionInput(csData.nqryName);

    // CS 정보 폼 그리드 > CS번호 > 입력을 초기화함
    setCsNoInput(csData.csId);

    // CS 정보 폼 그리드 > 처리완료요청일 날짜 피커를 초기화함
    setCompletionRequestDatePicker(
      moment(csData.prcsRqstDate, 'YYYY-MM-DD').toDate(),
    );

    // CS 정보 폼 그리드 > 접수유형1 셀렉트의 아이템을 초기화함
    setReceptionType1Select(csData.cstyGrpCode);
    // setReceptionType1Select((pre: ISelect) => ({
    //   ...pre,
    //   value: csData.cstyGrpCode,
    // }));

    valReceptionType1Select = csData.cstyGrpCode;
    valReceptionType2Select = csData.cstyCode;

    // CS 정보 폼 그리드 > 장애구분 셀렉트의 아이템을 초기화함
    setObstacleTypeSelect(csData.dsblDntfCode);
    // setObstacleTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: csData.dsblDntfCode,
    // }));

    // CS 정보 폼 그리드 > 고객특성 셀렉트의 아이템을 초기화함
    setCustomerCharacteristicsSelect(csData.cstmTrbtCode);
    // setCustomerCharacteristicsSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: csData.cstmTrbtCode,
    // }));

    // CS 정보 폼 그리드 > 접수일시 날짜 피커를 초기화함
    setReceptionDatePicker(moment(csData.rgstDate, 'YYYY-MM-DD').toDate());

    // CS 정보 폼 그리드 > 회신완료일시 날짜 피커를 초기화함
    setReplyCompletionDatePicker(
      moment(csData.mdfcDate, 'YYYY-MM-DD').toDate(),
    );

    // CS 정보 폼 그리드 > 현상내용 입력을 초기화함
    setEventContentInput(csData.przCntn);

    // CS 정보 폼 그리드 > 원인내용 입력을 초기화함
    setCauseContentInput(csData.csDtls);

    // CS 정보 폼 그리드 > 처리결과 입력을 초기화함
    setProcessingResultInput(csData.prcsRslt);

    return () => {};
  }, [csData]);

  // CS 정보 폼 그리드 > 이관부서 셀렉트에서 선택한 아이템이 변경될 때 실행함
  useEffect(() => {
    // CS 정보 폼 그리드 > 이관담당자 셀렉트의 아이템을 초기화함
    initTransferWorkerSelect();

    return () => {};
  }, [transferDepartmentSelect]);

  // 고객 정보 폼 그리드 > 프로젝트명 셀렉트에서 선택한 아이템이 변경될 때 실행함
  useEffect(() => {
    // 고객 정보 폼 그리드 > 고객사명 셀렉트의 아이템을 초기화함
    initClientNameSelect();

    return () => {};
  }, [projectNameSelect]);

  // 고객 정보 폼 그리드 > 프로젝트명 셀렉트에서 선택한 아이템이 변경될 때 실행함
  useEffect(() => {
    // 고객 정보 폼 그리드 > Shop명 셀렉트의 아이템을 초기화함
    initShopNameSelect();

    return () => {};
  }, [clientNameSelect]);

  // 고객 정보 폼 그리드 > 접수유형2 셀렉트에서 선택한 아이템이 변경될 때 실행함
  useEffect(() => {
    // CS 정보 폼 그리드 > 접수유형2 셀렉트의 아이템을 초기화함
    initReceptionType2Select();
    return () => {};
  }, [receptionType1Select]);

  // 이관 및 처리 이력 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (historyTable.column.length === 0 || !csData) {
      return;
    }

    // 이관 및 처리 이력 테이블 데이터를 불러옴
    getHistoryTableData();

    return () => {};
  }, [historyTable.column, csData]);

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (fileTable.column.length === 0 || csData === null) {
      return;
    }

    // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블 데이터를 불러옴
    // 이력 등록일 때는 첨부파일을 불러오지 않음(데이터를 수정하기 위함이 아닌 복사를 위한 데이터이기 때문)
    if (!historyMode) {
      getFileTableData();
    }

    return () => {};
  }, [fileTable.column, csData]);

  // CS작업상태, 처리완료요청일이 변경될 때 실행함
  useEffect(() => {
    let tmpCsProcessStateInput: string = '';

    if (
      completionRequestDatePicker !== null &&
      completionRequestDatePicker !== undefined
    ) {
      if (csWorkStateSelect === 'RPLY_CMPL') {
        // CS작업상태가 회신완료이면 CS진행상태를 완료로 설정함
        tmpCsProcessStateInput = '완료';
      } else {
        let nowDate: any = new Date();

        tmpCsProcessStateInput =
          nowDate <= completionRequestDatePicker ? '진행중' : '미처리';
      }
    }

    // CS 정보 폼 그리드 > CS진행상태 > 입력에 적용함
    setCsProcessStateInput(tmpCsProcessStateInput);

    return () => {};
  }, [csWorkStateSelect, completionRequestDatePicker]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  useEffect(() => {
    setReceptionType1SelectText(
      appUtil.getSelectText(receptionType1SelectItem, receptionType1Select),
    );

    return () => {};
  }, [receptionType1Select]);

  useEffect(() => {
    setReceptionType2SelectText(
      appUtil.getSelectText(receptionType2SelectItem, receptionType2Select),
    );

    return () => {};
  }, [receptionType2Select]);

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* CS 작업 폼 그리드 */}
        <PbFormGrid id="step-1" label="CS 작업" cols={1}>
          <PbFormGridCol label="CS작업상태" withAsterisk={true}>
            {/* 셀렉트 */}
            <Select
              onChange={handleCsWorkStateSelect_onChange}
              data={csWorkStateSelectItem}
              value={csWorkStateSelect}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
            />

            {/* 버튼 */}
            {csId &&
              !useModifyMode &&
              csWorkStateSelect !== '' &&
              csWorkStateSelect !== 'RPLY_CMPL' && (
                <>
                  {/* 버튼 */}
                  <Button
                    color="indigo"
                    radius="xl"
                    onClick={handleGoModifyButton_onClick}
                  >
                    이력 등록
                  </Button>
                </>
              )}
          </PbFormGridCol>
        </PbFormGrid>

        {/* 이관 정보 폼 그리드 */}
        {/* CS작업상태 항목이 '이관'일 때만 표시됨 */}
        <PbFormGrid
          label="이관 정보"
          cols={2}
          isHidden={csWorkStateSelect !== 'SCLT'}
        >
          <PbFormGridCol label="이관부서" withAsterisk={true}>
            {/* 셀렉트 */}
            <Select
              onChange={handleTransferDepartmentSelect_onChange}
              data={transferDepartmentSelectItem}
              value={transferDepartmentSelect}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="이관담당자">
            {/* 셀렉트 */}
            <Select
              onChange={handleTransferWorkerSelect_onChange}
              data={transferWorkerSelectItem}
              value={transferWorkerSelect}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 고객 정보 폼 그리드 */}
        <PbFormGrid id="step-2" label="고객 정보" cols={2}>
          <PbFormGridCol label="프로젝트명">
            {/* 셀렉트 */}
            <Select
              onChange={handleProjectNameSelect_onChange}
              data={projectNameSelectItem}
              value={decode(projectNameSelect)}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
              placeholder="검색어를 입력하시거나 목록을 선택하세요."
              searchable={true}
              clearable={true}
            />
          </PbFormGridCol>
          <PbFormGridCol label="프로젝트코드">
            {/* 텍스트 입력 */}
            <TextInput
              value={projectCodeInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객사명">
            {/* 셀렉트 */}
            <Select
              onChange={handleClientNameSelect_onChange}
              data={clientNameSelectItem}
              value={clientNameSelect}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객사코드">
            {/* 텍스트 입력 */}
            <TextInput
              value={clientCodeInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="Shop명">
            {/* 셀렉트 */}
            <Select
              onChange={handleShopNameSelect_onChange}
              data={shopNameSelectItem}
              value={shopNameSelect}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol
            label="이력확인"
            isHidden={
              csId && shopNameSelect !== null && shopNameSelect !== ''
                ? false
                : true
            }
          >
            <Button
              color="indigo"
              radius="xl"
              onClick={handleNetworkResultButton_onChange}
            >
              네트워크 작업 결과
            </Button>
            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              onClick={handleCsHistoryButton_onChange}
            >
              CS 히스토리
            </Button>
          </PbFormGridCol>
        </PbFormGrid>

        {/* CS 정보 폼 그리드 */}
        <PbFormGrid id="step-3" label="CS 정보" cols={2}>
          <PbFormGridCol label="문의명" colSpan={2} withAsterisk={true}>
            {/* 텍스트 입력 */}
            <TextInput
              placeholder={csId && !useModifyMode ? '' : '문의명을 입력하세요.'}
              onChange={handleQuestionInput_onChange}
              value={decode(questionInput)}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="CS번호">
            {/* 텍스트 입력 */}
            <TextInput
              value={csNoInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="CS진행상태">
            {/* 텍스트 입력 */}
            <TextInput
              value={csProcessStateInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="처리완료요청일" withAsterisk={true}>
            {/* 날짜 피커 */}
            <DatePickerInput
              placeholder={
                csId && !useModifyMode ? '' : '처리 완료 요청일을 선택하세요.'
              }
              onChange={handleCompletionRequestDatePicker_onChange}
              value={completionRequestDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              firstDayOfWeek={0}
              clearable={true}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="접수유형" withAsterisk={true}>
            {csId && !useModifyMode ? (
              // 조회

              <div className="flex justify-center items-center space-x-1 whitespace-nowrap">
                <span className="mantine-ka5ttw">
                  {receptionType1SelectText}
                </span>
                <span className="mantine-ka5ttw">/</span>
                <span className="mantine-ka5ttw">
                  {receptionType2SelectText}
                </span>
              </div>
            ) : (
              // 등록, 수정

              <>
                {/* 셀렉트 */}
                <Select
                  onChange={handleReceptionType1Select_onChange}
                  data={receptionType1SelectItem}
                  value={receptionType1Select}
                  readOnly={csId && !useModifyMode ? true : false}
                  variant={csId && !useModifyMode ? 'unstyled' : 'default'}
                  className="w-1/2"
                />

                {/* 셀렉트 */}
                <Select
                  onChange={handleReceptionType2Select_onChange}
                  data={receptionType2SelectItem}
                  value={receptionType2Select}
                  readOnly={csId && !useModifyMode ? true : false}
                  variant={csId && !useModifyMode ? 'unstyled' : 'default'}
                  className="w-1/2"
                />
              </>
            )}
          </PbFormGridCol>
          <PbFormGridCol label="장애구분" withAsterisk={true}>
            {/* 셀렉트 */}
            <Select
              onChange={handleObstacleTypeSelect_onChange}
              data={obstacleTypeSelectItem}
              value={obstacleTypeSelect}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객특성" withAsterisk={true}>
            {/* 셀렉트 */}
            <Select
              onChange={handleCustomerCharacteristicsSelect_onChange}
              data={customerCharacteristicsSelectItem}
              value={customerCharacteristicsSelect}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="접수일시">
            {/* 날짜 피커 */}
            <DatePickerInput
              value={receptionDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              firstDayOfWeek={0}
              clearable={true}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="회신완료일시">
            {/* 날짜 피커 */}
            <DatePickerInput
              value={replyCompletionDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              firstDayOfWeek={0}
              clearable={true}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol
            label="현상내용"
            colSpan={2}
            withAsterisk={true}
            className="h-40"
          >
            {/* 텍스트 영역 */}
            <Textarea
              placeholder={
                csId && !useModifyMode ? '' : '현상내용을 입력하세요.'
              }
              onChange={handleEventContentInput_onChange}
              value={decode(eventContentInput)}
              minRows={5}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="원인내용" colSpan={2} className="h-40">
            {/* 텍스트 영역 */}
            <Textarea
              placeholder={
                csId && !useModifyMode ? '' : '원인내용을 입력하세요.'
              }
              onChange={handleCauseContentInput_onChange}
              value={decode(causeContentInput)}
              minRows={5}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol
            label="처리결과"
            colSpan={2}
            withAsterisk={true}
            className="h-40"
          >
            {/* 텍스트 영역 */}
            <Textarea
              placeholder={
                csId && !useModifyMode ? '' : '처리결과를 입력하세요.'
              }
              onChange={handleProcessingResultInput_onChange}
              value={decode(processingResultInput)}
              minRows={5}
              readOnly={csId && !useModifyMode ? true : false}
              variant={csId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          {/* <PbFormGridCol label="처리결과 첨부파일" colSpan={3} className="h-24">

          </PbFormGridCol> */}
        </PbFormGrid>

        {/* 첨부 파일 섹션 */}
        {/* 조회 상태 */}
        {csId && !useModifyMode && (
          <PbSection id="step-4" label="첨부 파일">
            <div className="space-y-3">
              {/* 테이블 */}
              <div className="w-full h-80">
                <PbAgGridReact
                  refs={fileTableRef}
                  columnDefs={fileTable.column}
                  rowData={fileTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>

              {/* 첨부파일 미리보기 */}
              <div className="flex justify-start items-center space-x-1">
                <FlexImageFilePreviewList data={previewFileImages} />
              </div>
            </div>

            {/* 테이블 */}
            {/*<div className="w-full h-80">*/}
            {/*  <AgGridReact*/}
            {/*    ref={fileTableRef}*/}
            {/*    columnDefs={fileTable.column}*/}
            {/*    rowData={fileTable.data}*/}
            {/*    defaultColDef={{*/}
            {/*      resizable: true,*/}
            {/*      sortable: true,*/}
            {/*      editable: false,*/}
            {/*      wrapHeaderText: false,*/}
            {/*      autoHeaderHeight: true,*/}
            {/*      suppressSizeToFit: false,*/}
            {/*    }}*/}
            {/*    onGridReady={(event) => event.api.sizeColumnsToFit()}*/}
            {/*    rowSelection="single"*/}
            {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
            {/*    className="ag-theme-alpine"*/}
            {/*  ></AgGridReact>*/}
            {/*</div>*/}
          </PbSection>
        )}
        {/* 첨부 파일 섹션 */}
        {/* 추가, 수정 상태 */}
        {(!csId || useModifyMode) && (
          <PbSection
            id="step-4"
            label="처리결과 첨부 파일"
            rightForm={
              <>
                {/* 버튼 */}
                <div className="flex justify-end items-center space-x-2">
                  {/* 버튼 */}
                  <Button
                    variant="outline"
                    color="pink"
                    radius="xl"
                    onClick={handleFileSectionDeleteButton_onClick}
                  >
                    전체 삭제
                  </Button>

                  {/* 버튼 */}
                  <Button
                    color="pink"
                    radius="xl"
                    onClick={handleFileSectionSelectedDeleteButton_onClick}
                  >
                    선택 삭제
                  </Button>

                  {/* 버튼 */}
                  <FileButton onChange={handleFileSectionAddButton_onClick}>
                    {(props: any) => (
                      <Button color="indigo" radius="xl" {...props}>
                        추가
                      </Button>
                    )}
                  </FileButton>
                </div>
              </>
            }
          >
            <div className="space-y-3">
              {/* 테이블 */}
              <div className="w-full h-80">
                <PbAgGridReact
                  refs={fileTableRef}
                  columnDefs={fileTable.column}
                  rowData={fileTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    editable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="multiple"
                  sizeColumnsToFit={true}
                />
              </div>
            </div>
          </PbSection>
        )}

        {/* 이관 및 처리 섹션 */}
        <PbSection id="step-5" label="이관 및 처리">
          {/* 테이블 */}
          <div className="w-full h-80">
            <PbAgGridReact
              columnDefs={historyTable.column}
              rowData={historyTable.data}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="single"
              sizeColumnsToFit={true}
            />
          </div>

          {/* 테이블 */}
          {/*<div className="w-full h-80">*/}
          {/*  <AgGridReact*/}
          {/*    columnDefs={historyTable.column}*/}
          {/*    rowData={historyTable.data}*/}
          {/*    defaultColDef={{*/}
          {/*      resizable: true,*/}
          {/*      sortable: true,*/}
          {/*      wrapHeaderText: false,*/}
          {/*      autoHeaderHeight: true,*/}
          {/*      suppressSizeToFit: true,*/}
          {/*    }}*/}
          {/*    rowSelection="single"*/}
          {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
          {/*    className="ag-theme-alpine"*/}
          {/*  ></AgGridReact>*/}
          {/*</div>*/}
        </PbSection>

        {/* 댓글 섹션 */}
        <PbSection label="댓글" className="hidden">
          <div className="space-y-2">
            {/* 댓글 내용 및 버튼 */}
            <div className="flex space-x-2">
              <div className="grow">
                {/* 텍스트 영역 */}
                <Textarea
                  placeholder={
                    csId && !useModifyMode ? '' : '댓글을 입력하세요.'
                  }
                  // onChange={handleProcessingResultInput_onChange}
                  // value={processingResultInput}
                  minRows={5}
                  className="w-full"
                />
              </div>

              <div className="flex-none w-32">
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="sm"
                  // onClick={handleFileSectionDeleteButton_onClick}
                  style={{ width: '100%', height: '100%' }}
                >
                  저장
                </Button>
              </div>

              <div className="flex-none w-32">
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="indigo"
                  radius="sm"
                  // onClick={handleFileSectionDeleteButton_onClick}
                  style={{ width: '100%', height: '100%' }}
                >
                  이미지 추가
                </Button>
              </div>
            </div>

            {/* 첨부 이미지 */}
            <div className="flex justify-start items-center space-x-2">
              {/* 첨부 이미지 */}
              <div className="relative w-24 h-24 border">
                {/* 닫기 버튼 */}
                <div className="absolute right-0 top-0">
                  <div
                    // onClick={() => handleModal_onClose(item.id!)}
                    className="button-event p-0.5 flex justify-center items-center hover:bg-gray-100 bg-gray-200"
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'close']}
                      className="w-5 h-5 text-gray-700"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* 댓글 목록 */}
            <div className="space-y-2">
              {/* 댓글 */}
              <div className="px-2 py-1 bg-gray-100 space-y-2 rounded">
                {/* 작성 정보 */}
                <div className="flex justify-start items-center space-x-3">
                  {/* 팀 구분 */}
                  <div className="px-1 py-0.5 flex justify-center items-center bg-indigo-500 rounded-md">
                    <span className="text-xs text-white">운영팀</span>
                  </div>

                  {/* 작성자 */}
                  <div className="flex justify-center items-center">
                    <span className="text-md text-gray-700 font-bold">
                      가나다
                    </span>
                  </div>

                  {/* 작성일시 */}
                  <div className="flex justify-center items-center">
                    <span className="text-xs text-gray-700 font-bold">
                      2023.05.05 13:00:11
                    </span>
                  </div>
                </div>

                {/* 내용 */}
                <div className="p-1 bg-white rounded-md">1234</div>

                {/* 첨부 이미지 */}
                <div className="flex justify-start items-center space-x-2">
                  {/* 첨부 이미지 */}
                  <div className="relative w-24 h-24 bg-white border">
                    {/* 닫기 버튼 */}
                    <div className="absolute right-0 top-0">
                      <div
                        // onClick={() => handleModal_onClose(item.id!)}
                        className="button-event p-0.5 flex justify-center items-center hover:bg-gray-100 bg-gray-200"
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'close']}
                          className="w-5 h-5 text-gray-700"
                        />
                      </div>
                    </div>
                  </div>

                  {/* 첨부 이미지 */}
                  <div className="relative w-24 h-24 bg-white border">
                    {/* 닫기 버튼 */}
                    <div className="absolute right-0 top-0">
                      <div
                        // onClick={() => handleModal_onClose(item.id!)}
                        className="button-event p-0.5 flex justify-center items-center hover:bg-gray-100 bg-gray-200"
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'close']}
                          className="w-5 h-5 text-gray-700"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* 댓글 */}
              <div className="px-2 py-1 bg-gray-100 space-y-2 rounded">
                {/* 작성 정보 */}
                <div className="flex justify-start items-center space-x-3">
                  {/* 팀 구분 */}
                  <div className="px-1 py-0.5 flex justify-center items-center bg-indigo-500 rounded-md">
                    <span className="text-xs text-white">운영팀</span>
                  </div>

                  {/* 작성자 */}
                  <div className="flex justify-center items-center">
                    <span className="text-md text-gray-700 font-bold">
                      가나다
                    </span>
                  </div>

                  {/* 작성일시 */}
                  <div className="flex justify-center items-center">
                    <span className="text-xs text-gray-700 font-bold">
                      2023.05.05 13:00:11
                    </span>
                  </div>
                </div>

                {/* 내용 */}
                <div className="p-1 bg-white rounded-md">1234</div>

                {/* 첨부 이미지 */}
                <div className="flex justify-start items-center space-x-2">
                  {/* 첨부 이미지 */}
                  <div className="relative w-24 h-24 bg-white border">
                    {/* 닫기 버튼 */}
                    <div className="absolute right-0 top-0">
                      <div
                        // onClick={() => handleModal_onClose(item.id!)}
                        className="button-event p-0.5 flex justify-center items-center hover:bg-gray-100 bg-gray-200"
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'close']}
                          className="w-5 h-5 text-gray-700"
                        />
                      </div>
                    </div>
                  </div>

                  {/* 첨부 이미지 */}
                  <div className="relative w-24 h-24 bg-white border">
                    {/* 닫기 버튼 */}
                    <div className="absolute right-0 top-0">
                      <div
                        // onClick={() => handleModal_onClose(item.id!)}
                        className="button-event p-0.5 flex justify-center items-center hover:bg-gray-100 bg-gray-200"
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'close']}
                          className="w-5 h-5 text-gray-700"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PbSection>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 신규 상태 */}
        {!csId && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveAddButton_onClick}
            >
              저장
            </Button>
          </>
        )}

        {/* 조회 상태 */}
        {csId && !useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleGoModifyButton_onClick}
            >
              수정 전환
            </Button>
          </>
        )}

        {/* 수정 상태 */}
        {csId && useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              variant="outline"
              color="pink"
              radius="xl"
              size="md"
              onClick={handleRemoveButton_onClick}
            >
              삭제
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveUpdateButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220502;
