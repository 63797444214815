import React, { PropsWithChildren, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import _, { forEach, now } from 'lodash';
import { PbTable } from '../PbTable';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import * as sttnApi from '../../apis/sttn.api';
import { PbInputNumber } from '../PbInputNumber';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  Group,
  NumberInput,
  Select,
  Tabs,
  TextInput,
} from '@mantine/core';
import { Radio } from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import MN2302220351_Partner from '../MN2302220351_Partner/MN2302220351_Partner.component';
import PbSelect from '../PbSelect/PbSelect.component';

interface IComponentProps {
  id: string;
  type?: string;
  pSplrTypeCode?: string;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * 미등록 작업 관리 상세 - 협력사 정보 등록
 * @param id 화면 아이디
 * @param type 추가/수정 여부
 * @param pSplrTypeCode 협력사 설치 구분
 * @param data 상세 정보
 * @param onClick <이벤트>
 * @param callback modal 수정정보 반영을 위한 이벤트
 * @constructor
 */
const MN2302220351_GridModify = ({
  id = '',
  type = '',
  pSplrTypeCode = '',
  data,
  onClick,
  callback,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 변수 정의
   */

  // 협력사명 + 팀명 정의
  const [prtnAndTeamNameInput, setPrtnAndTeamNameInput] = useState<string>('');

  // 협력사명 정의
  const [prtnNameInput, setPrtnNameInput] = useState<string>('');

  // 협력사(팀)명 정의
  const [prtnTeamNameInput, setPrtnTeamNameInput] = useState<string>('');

  // 내용 정의
  const [ttlInput, setTtlInput] = useState<string>('');

  // 협력사코드 정의
  const [splrIdInput, setSplrIdInput] = useState<string>('');

  // 팀코드 정의
  const [teamIdInput, setTeamIdInput] = useState<string>('');

  // 작업 코드 정의
  const [splrTypeCodeInput, setSplrTypeCodeInput] = useState<string>('');

  // 작업일정 시작일 정의
  const [workStrtDatePicker, setWorkStrtDatePicker] = useState<Date | null>(
    null,
  );
  const handleWorkStrtDatePicker_onChange = (event: any) => {
    setWorkStrtDatePicker(event);
  };

  // 작업일정 종료일 정의
  const [workEndDatePicker, setWorkEndDatePicker] = useState<Date | null>(null);
  const handleWorkEndDatePicker_onChange = (event: any) => {
    setWorkEndDatePicker(event);
  };

  // 내용 변경 event
  const handleTtlInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 30) {
      // 30byte 제한
      setTtlInput((pre) => pre);
    } else {
      setTtlInput(sVal);
    }
  };

  // 작업일정 시작 > 시간
  const [workStrtHourSelect, setWorkStrtHourSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 작업일정 시작 > 분
  const [workStrtMinSelect, setWorkStrtMinSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 작업일정 종료 > 시간
  const [workEndHourSelect, setWorkEndHourSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 작업일정 종료 > 분
  const [workEndMinSelect, setWorkEndMinSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 작업일정 시작 > 시간 단위 변경
  const handleWorkStrtHourSelect_onChange = (event: any) => {
    setWorkStrtHourSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 작업일정 시작 > 분 단위 변경
  const handleWorkStrtMinSelect_onChange = (event: any) => {
    setWorkStrtMinSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 작업일정 종료 > 시간 단위 변경
  const handleWorkEndHourSelect_onChange = (event: any) => {
    setWorkEndHourSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 작업일정 종료 > 분 단위 변경
  const handleWorkEndMinSelect_onChange = (event: any) => {
    setWorkEndMinSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  /* // 변수 정의 */

  /**
   * 처리
   */

  // 입력값 byte 확인
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  const handlePartnerAddButton_onClick = (event: any) => {
    let tmpId: string = nanoid();
    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '협력사(팀) 불러오기',
      content: (
        <MN2302220351_Partner
          id={tmpId}
          pSplrTypeCode={pSplrTypeCode}
          callback={(data: any) => {
            setPrtnAndTeamNameInput(`${data.prtnName} : ${data.teamName}`);
            setPrtnNameInput(data.prtnName);
            setPrtnTeamNameInput(data.teamName);
            setSplrIdInput(data.splrId);
            setTeamIdInput(data.teamId);
            setSplrTypeCodeInput(data.splrTypeCode);
          }}
        />
      ),
      size: 1500,
    });
  };

  // 적용 버튼을 클릭함
  const handleApply_onClickButton = () => {
    if (callback !== undefined) {
      if (_.isEmpty(prtnTeamNameInput.trim())) {
        setModal({
          title: '알림',
          content: '협력사(팀)을 선택하세요.',
        });
        return;
      }

      if (_.isEmpty(ttlInput.trim())) {
        setModal({
          title: '알림',
          content: '내용을 입력하세요.',
        });
        return;
      }

      if (!workStrtDatePicker) {
        setModal({
          title: '알림',
          content: '작업시작일을 입력하세요.',
        });
        return;
      }

      if (!workEndDatePicker) {
        setModal({
          title: '알림',
          content: '작업종료일을 입력하세요.',
        });
        return;
      }

      let strtDttm =
        moment(workStrtDatePicker).format('YYYY.MM.DD') +
        ' ' +
        workStrtHourSelect.value +
        ':' +
        workStrtMinSelect.value;
      let endDttm =
        moment(workEndDatePicker).format('YYYY.MM.DD') +
        ' ' +
        workEndHourSelect.value +
        ':' +
        workEndMinSelect.value;

      if (strtDttm > endDttm) {
        setModal({
          title: '알림',
          content: '작업 종료일시는 시작일시보다 이후의 일시로 입력하세요.',
        });
        return;
      }

      let rtnObj = {
        modifyRowIndex: type === 'REQ' ? -1 : data.modifyRowIndex,
        prtnName: prtnNameInput.trim(),
        teamName: prtnTeamNameInput.trim(),
        ttl: ttlInput.trim(),
        splrId: splrIdInput.trim(),
        teamId: teamIdInput.trim(),
        splrTypeCode: splrTypeCodeInput.trim(),
        workStrtDttm: _.toString(strtDttm),
        workEndDttm: _.toString(endDttm),
        type: type,
      };

      callback(rtnObj);

      // 컴포넌트 모달을 닫음
      setRemoveComponentModal(id);
    }
  };

  // 시간 selectbox 초기화
  const initWorkHourSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    for (let h = 0; h < 24; h++) {
      if (h < 10) {
        tmpOptionItem.push({
          label: '0' + h,
          value: '0' + h,
        });
      } else {
        tmpOptionItem.push({
          label: _.toString(h),
          value: _.toString(h),
        });
      }
    }

    setWorkStrtHourSelect((pre: any) => ({
      value: '00',
      item: tmpOptionItem,
    }));

    setWorkEndHourSelect((pre: any) => ({
      value: '23',
      item: tmpOptionItem,
    }));
  };

  // 분 selectbox 초기화
  const initWorkMinSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '00',
        value: '00',
      },
      {
        label: '30',
        value: '30',
      },
    );

    setWorkStrtMinSelect((pre: any) => ({
      value: '00',
      item: tmpOptionItem,
    }));

    setWorkEndMinSelect((pre: any) => ({
      value: '30',
      item: tmpOptionItem,
    }));
  };

  const getWorkTime = (date: any) => {
    let times: any[] = [];

    if (_.split(date, ' ').length > 1) {
      let tmpTimes = _.split(_.split(date, ' ')[1], ':');
      if (tmpTimes.length > 1) {
        times.push(tmpTimes[0], tmpTimes[1]);
      } else {
        times.push('00', '00');
      }
    } else {
      times.push('00', '00');
    }
    return times;
  };

  /* // */

  /**
   * useEffect
   */

  // 화면 로딩 후 한번만 실행
  useEffect(() => {
    // 시간, 분 selectbox 초기화
    initWorkHourSelect();
    initWorkMinSelect();
    if (_.isEqual(type, 'MOD')) {
      // 수정정보 입력창 정보 설정
      setSplrIdInput(data.splrId);
      setSplrIdInput(data.teamId);
      setSplrTypeCodeInput(data.splrTypeCode);
      setPrtnNameInput(data.prtnName);
      setPrtnTeamNameInput(data.teamName);
      setPrtnAndTeamNameInput(`${data.prtnName} : ${data.teamName}`);
      setTtlInput(data.ttl);
      setWorkStrtDatePicker(moment(data.workStrtDttm).toDate());
      setWorkEndDatePicker(moment(data.workEndDttm).toDate());

      let wortStrtArray: any = getWorkTime(data.workStrtDttm);
      let wortEndArray: any = getWorkTime(data.workEndDttm);

      // 시간/분 정보 설정
      setWorkStrtHourSelect((pre: any) => ({
        ...pre,
        value: wortStrtArray[0],
      }));
      setWorkStrtMinSelect((pre: any) => ({
        ...pre,
        value: wortStrtArray[1],
      }));
      setWorkEndHourSelect((pre: any) => ({
        ...pre,
        value: wortEndArray[0],
      }));
      setWorkEndMinSelect((pre: any) => ({
        ...pre,
        value: wortEndArray[1],
      }));
    } else {
      let currDate: any = moment(new Date(), 'YYYY.MM.DD').toDate();
      setWorkStrtDatePicker(currDate);
      setWorkEndDatePicker(currDate);
    }
  }, []);

  /* // */

  return (
    <div className="space-y-7">
      <div className="space-y-5">
        {/* 공사작업 내역 폼 그리드 */}
        <PbFormGrid label="작업 정보" cols={2}>
          <PbFormGridCol label="협력사(팀)명">
            <TextInput
              value={prtnAndTeamNameInput}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handlePartnerAddButton_onClick}
            >
              협력사(팀) 불러오기
            </Button>
          </PbFormGridCol>
          <PbFormGridCol label="내용">
            <TextInput
              placeholder="내용을 입력하세요.."
              value={ttlInput}
              onChange={handleTtlInput_onChange}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="작업 일정" colSpan={2}>
            <DatePickerInput
              placeholder={'작업 시작일을 선택하세요.'}
              onChange={handleWorkStrtDatePicker_onChange}
              value={workStrtDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              variant="default"
              firstDayOfWeek={0}
              clearable={true}
              className="w-48"
            />
            <PbSelect
              onChange={handleWorkStrtHourSelect_onChange}
              data={workStrtHourSelect.item}
              value={workStrtHourSelect.value}
              setSelect={setWorkStrtHourSelect}
              className="w-20"
            />
            <span className="text-sm">시</span>
            <PbSelect
              onChange={handleWorkStrtMinSelect_onChange}
              data={workStrtMinSelect.item}
              value={workStrtMinSelect.value}
              setSelect={setWorkStrtMinSelect}
              className="w-20"
            />
            <span className="text-sm">분</span>
            <span className="text-sm">~</span>
            <DatePickerInput
              placeholder={'작업 종료일을 선택하세요.'}
              onChange={handleWorkEndDatePicker_onChange}
              value={workEndDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              firstDayOfWeek={0}
              variant="default"
              clearable={true}
              className="w-48"
            />
            <PbSelect
              onChange={handleWorkEndHourSelect_onChange}
              data={workEndHourSelect.item}
              value={workEndHourSelect.value}
              setSelect={setWorkEndHourSelect}
              className="w-20"
            />
            <span className="text-sm">시</span>
            <PbSelect
              onChange={handleWorkEndMinSelect_onChange}
              data={workEndMinSelect.item}
              value={workEndMinSelect.value}
              setSelect={setWorkEndMinSelect}
              className="w-20"
            />
            <span className="text-sm">분</span>
          </PbFormGridCol>
        </PbFormGrid>

        <div className="component-modal-button-area space-x-2">
          <Button
            variant="outline"
            color="gray"
            radius="xl"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          >
            닫기
          </Button>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={handleApply_onClickButton}
          >
            적용
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MN2302220351_GridModify;
