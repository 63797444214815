import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  Checkbox,
  Group,
  Radio,
  Popover,
  Drawer,
} from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
  IComponentModalPageScrollTabStep,
  IComponentModalPageScrollTab,
  ITablePaging,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PageLayout } from '../../components/PageLayout';
import { PbFormGrid } from '../../components/PbFormGrid';
import { PbFormGridCol } from '../../components/PbFormGridCol';
import { PbButton } from '../PbButton';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../../stores/refreshList.store';
import { ValueFormatterParams } from 'ag-grid-community';
import * as prtnApi from '../../apis/prtn.api';
import { MN2302220621 } from '../MN2302220621';
import { MN2302220641 } from '../MN2302220641';
import MN2302220631_PrtnVltn from '../MN2302220631_PrtnVltn/MN2302220631_PrtnVltn.component';
import { PbAgGridReact } from '../PbAgGridReact';
import PbSection from '../PbSection/PbSection.component';
import * as slsApi from '../../apis/sls.api';
import { notifications } from '@mantine/notifications';
import * as cmnApi from '../../apis/cmn.api';
import PbSelect from '../PbSelect/PbSelect.component';
import { phoneNumberformatter } from '../../utils/app.util';
import * as appUtil from '../../utils/app.util';
import { useDisclosure } from '@mantine/hooks';
import { number } from 'prop-types';
import { decode } from 'html-entities';
import * as sttnApi from '../../apis/sttn.api';

interface IMN2302220631Props {
  id?: string;
  splrId?: string;
  data?: any;
  useModifyMode?: boolean;
  onClick?: () => void;
}

/**
 * 협력사 관리 > 협력사 등록/상세/수정
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220631 = ({
  id = '',
  splrId = '',
  data,
  useModifyMode = false,
  onClick,
}: PropsWithChildren<IMN2302220631Props>) => {
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**************************** API 호출 ****************************/
  //협력사 등록 API
  const saveAddPrtnInfo = () => {
    // 팀 정보를 가공하여 저장용 데이터를 정의함
    let tmpTeamData: any[] = [];

    // 팀 정보를 가공하여 저장용 데이터를 생성함
    teamTable.map((item: any) => {
      tmpTeamData.push({
        teamName: item.teamName,
        teamDscr: item.teamDscr || '',
        cntcId: item.cntcId,
        rgstId: loginUser.id,
        // splrId - 협력사 ID 는 Server 에서 자동 생성
      });
    });

    prtnApi
      .postPrtn({
        prtnName: prtnNameInput,
        splrTypeCode: prtnCategoryRadio,
        cmpnRgstNmbr: prtnRegNoInput,
        drs: prtnAddrInput,
        rprsName: prtnCeoNameInput,
        mainPhnNmbr: prtnPhonNoInput,
        // cntcId: cntcIdInput,
        prtnTmRegParamVoList: tmpTeamData, // <-- '컬럼이름'을 부장님이 만드신 컬럼 이름으로 변경하시면 됩니다.
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          setModal({
            title: '알림',
            content: '저장하였습니다.',
            callback: () => {
              // 목록 새로고침을 추가함
              setAddRefreshList('MN2302220601Table');

              // 컴포넌트 모달을 닫음
              setRemoveComponentModal(id);
            },
          });
        } else {
          console.log('> 협력사(팀) 등록 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>등록에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  //작업 현황 조회 API
  const getWorkStateTable = () => {
    prtnApi
      .getPrtnWorkList({
        splrId: splrId,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          if (data.data.list.length == 0) {
            var emptyList = [
              {
                fulWork: '0',
                prcd: '0',
                workCmpl: '0',
                nspcCmpl: '0',
                pmNspcCmpl: '0',
                dly: '0',
              },
            ];
            setWorkStateTable((pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: emptyList,
            }));
          } else {
            setWorkStateTable((pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list.slice(0, 1),
            }));
          }
        }
      });
  };

  //작업 현황 상세 조회 API
  const getWorkStateDtlTable = (pSplrId: string, pNstlSttsCode: string) => {
    prtnApi
      .getPrtnPrtnWorkStts({
        splrId: pSplrId,
        nstlSttsCode: pNstlSttsCode,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          // 검색 결과 테이블에 적용함
          setWorkStateDtlTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data,
            }),
          );
        }
      });
  };

  //협력사 삭제 API
  const deletePrtn = () => {
    prtnApi
      .deletePrtn({
        splrId: splrId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 목록 새로고침
          setAddRefreshList('MN2302220601Table');

          // 컴포넌트 모달을 닫음
          setRemoveComponentModal(id);
        } else {
          console.log('> 협력사 삭제 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>삭제에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  //협력사 수정 API
  const saveModifyPrtnInfo = () => {
    // 팀 정보를 가공하여 저장용 데이터를 정의함
    let tmpTeamData: any[] = [];

    // 팀 정보를 가공하여 저장용 데이터를 생성함
    teamTable.map((item: any) => {
      tmpTeamData.push({
        teamId: item.new === undefined ? item.teamId || '' : '',
        teamName: item.teamName,
        teamDscr: item.teamDscr || '',
        cntcId: item.cntcId,
        splrId: splrId,
        rgstId: loginUser.id,
      });
    });

    prtnApi
      .putPrtn({
        splrId: splrId,
        cmpnRgstNmbr: prtnRegNoInput,
        // cntcId: cntcIdInput,
        drs: prtnAddrInput,
        mainPhnNmbr: prtnPhonNoInput,
        prtnName: prtnNameInput,
        rprsName: prtnCeoNameInput,
        splrTypeCode: prtnCategoryRadio,
        prtnTmRegParamVoList: tmpTeamData,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          setModal({
            title: '알림',
            content: '저장하였습니다.',
            callback: () => {
              // 목록 새로고침을 추가함
              setAddRefreshList('MN2302220601Table');

              // 컴포넌트 모달을 닫음
              setRemoveComponentModal(id);

              setTimeout(() => {
                // 조회 컴포넌트 모달을 추가함
                let tmpId: string = nanoid();

                // 컴포넌트 모달을 추가함
                setAddComponentModal({
                  id: tmpId,
                  title: '협력사 상세',
                  content: (
                    <MN2302220631
                      id={tmpId}
                      splrId={splrId}
                      useModifyMode={false}
                    />
                  ),
                  size: 1500,
                });
              }, 100);
            },
          });
        } else {
          console.log('> 협력사(팀) 수정 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>수정에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  // 협력사 평가 조회 API
  const getPrtnVltnTable = () => {
    prtnApi
      .getPrtnVltnList({
        splrId: splrId,
        useYn: 'Y',
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          setPrtnVltnTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));
        }
      });
  };

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    if (!splrId) {
      // 신규
      tmpTab = [
        {
          label: '협력사',
          targetId: 'step-1',
        },
        {
          label: '팀',
          targetId: 'step-2',
        },
      ];
    } else if (splrId && !useModifyMode) {
      // 조회
      tmpTab = [
        {
          label: '협력사',
          targetId: 'step-1',
        },
        {
          label: '팀',
          targetId: 'step-2',
        },
        {
          label: '작업 현황',
          targetId: 'step-3',
        },
        {
          label: '협력사 평가',
          targetId: 'step-4',
        },
      ];
    } else if (splrId && useModifyMode) {
      // 수정
      tmpTab = [
        {
          label: '협력사',
          targetId: 'step-1',
        },
        {
          label: '팀',
          targetId: 'step-2',
        },
        {
          label: '작업 현황',
          targetId: 'step-3',
        },
        {
          label: '협력사 평가',
          targetId: 'step-4',
        },
      ];
    }

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 팀 정보 폼 그리드 > 담당자 > 테이블을 초기화함
  const initTeamTargetUserTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      },
      // {
      //   headerName: '회사',
      //   field: 'cmpnName',
      //   width: 130,
      //   suppressSizeToFit: true,
      // },
      // {
      //   headerName: '본부',
      //   field: 'hdqrName',
      //   width: 130,
      //   suppressSizeToFit: true,
      // },
      // {
      //   headerName: '부서',
      //   field: 'dprtName',
      //   width: 120,
      //   suppressSizeToFit: true,
      // },
      // {
      //   headerName: '직급',
      //   field: 'pstnName',
      //   width: 100,
      //   suppressSizeToFit: true,
      // },
      {
        headerName: '닉네임',
        field: 'nickName',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '이름',
        field: 'name',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '휴대폰번호',
        field: 'celPhn',
        width: 160,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => phoneNumberformatter(params.value),
      },
      { headerName: '이메일', field: 'ml' },
    );

    // 테이블에 적용함
    setTeamTargetUserTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 팀 정보 폼 그리드 > 담당자 > 테이블 데이터를 불러옴
  const getTeamTargetUserTableData = () => {
    // 테이블에 적용함
    setTeamTargetUserTable((pre: { column: any; data: any }) => ({
      ...pre,
      loading: true,
    }));

    cmnApi
      .getCmnUsers({
        searchType: teamTargetUserSearchSelect.value,
        searchKeyword: teamTargetUserSearchInput,
        useYn: 'Y',
        pageSize: 10000,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 팀 정보 폼 그리드 > 담당자 > 테이블에 적용함
          // 업체가 협력업체(PRTN)인 경우만 필터링함
          setTeamTargetUserTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list.filter(
                (item: any) => item.cmpnCode === 'PRTN',
              ),
              loading: false,
            }),
          );
        }
      })
      .catch((error: any) => {
        // 팀 정보 폼 그리드 > 담당자 > 테이블에 적용함
        setTeamTargetUserTable(
          (pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            loading: false,
          }),
        );
      });
  };

  /**************************** STATE ****************************/
  //협력사
  const [prtnNameInput, setPrtnNameInput] = useState<string>(''); //협력사명
  const [prtnCategoryRadio, setPrtnCategoryRadio] =
    useState<string>('NTWR_CNST'); //유형코드
  const [prtnCeoNameInput, setPrtnCeoNameInput] = useState<string>(''); //대표자명
  const [prtnRegNoInput, setPrtnRegNoInput] = useState<string>(''); //사업자등록번호
  const [prtnAddrInput, setPrtnAddrInput] = useState<string>(''); //주소
  const [prtnPhonNoInput, setPrtnPhonNoInput] = useState<string>(''); //대표 번호

  //파트너사 상세 조회
  const getPrtn = (splrId: string) => {
    prtnApi
      .getPrtn({
        splrId: splrId,
      })
      .then((data: IApiResult) => {
        if (data.data != null) {
          setDetailData(data.data);
          setPrtnNameInput(data.data.prtnName);
          setPrtnCategoryRadio(data.data.splrTypeCode);
          setPrtnCeoNameInput(data.data.rprsName);
          setPrtnRegNoInput(data.data.cmpnRgstNmbr);
          setPrtnAddrInput(data.data.drs);
          setPrtnPhonNoInput(data.data.mainPhnNmbr);
          // setCntcIdInput(data.data.cntcId);
          // setCntcNameInput(data.data.name);
          // setCntcUnitInput(data.data.hdqrName);
          // setCntcDpmtInput(data.data.dprtName);
          // setCntcEmailInput(data.data.ml);
          // setCntcPhoneNoInput(data.data.celPhn);

          // 팀 정보 폼 그리드 > 테이블에 적용함
          setTeamTable(data.data.prtnTmVoList);
          // setTeamTable((pre: { column: any; data: any }) => ({
          //   ...pre,
          //   data: data.data.prtnTmVoList,
          // }));

          // console.log('> data.data.prtnTmVoList:', data.data.prtnTmVoList);
        }
      });
  };

  //담당자
  const [cntcIdInput, setCntcIdInput] = useState<string>(''); //아이디
  const [cntcNameInput, setCntcNameInput] = useState<string>(''); //이름
  const [cntcUnitInput, setCntcUnitInput] = useState<string>(''); //본부
  const [cntcDpmtInput, setCntcDpmtInput] = useState<string>(''); //부서
  const [cntcEmailInput, setCntcEmailInput] = useState<string>(''); //이메일
  const [cntcPhoneNoInput, setCntcPhoneNoInput] = useState<string>(''); //휴대폰

  //Detail Data
  const [detailData, setDetailData] = useState<any>();

  //작업현황 테이블
  const workStateTableRef = useRef<any>(null);

  //작업현황 테이블 state
  const [workStateTable, setWorkStateTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  //작업현황 상세 테이블 state
  const [workStateDtlTable, setWorkStateDtlTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  // 팀 정보 폼 그리드 > 테이블 참조를 정의함
  const teamTableRef = useRef<any>(null);

  // 팀 정보 폼 그리드 > 테이블을 정의함
  const [teamTableColumn, setTeamTableColumn] = useState<any>([]);
  const [teamTable, setTeamTable] = useState<any>([]);
  // const [teamTable, setTeamTable] = useState<{
  //   column: any;
  //   data: any;
  // }>({ column: [], data: [] });

  // 팀 정보 폼 그리드 > 담당자 > 팝업 출력 여부를 정의함
  const [visibleTeamTargetUser, setVisibleTeamTargetUser] =
    useState<boolean>(false);

  // 팀 정보 폼 그리드 > 담당자 > 검색 폼 그리드 > 검색어 > 셀렉트를 정의함
  const [teamTargetUserSearchSelect, setTeamTargetUserSearchSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 팀 정보 폼 그리드 > 담당자 > 검색 폼 그리드 > 검색어 > 입력을 정의함
  const [teamTargetUserSearchInput, setTeamTargetUserSearchInput] =
    useState<string>('');

  // 팀 정보 폼 그리드 > 담당자 > 테이블 참조를 정의함
  const teamTargetUserTableRef = useRef<any>(null);

  // 팀 정보 폼 그리드 > 담당자 > 테이블을 정의함
  const [teamTargetUserTable, setTeamTargetUserTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  /**************************** ON EVENT LISTENER ****************************/
  //협력사 유형 on change
  const handlePrtnCategoryRadio_onChange = (event: any) => {
    setPrtnCategoryRadio(event);
  };

  //협력사 이름 on change
  const handlePrtnNameInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 30) {
      setPrtnNameInput((pre) => pre);
    } else {
      setPrtnNameInput(sVal);
    }
  };

  //협력사 대표자 on change
  const handlePrtnCeoNameInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 30) {
      setPrtnCeoNameInput((pre) => pre);
    } else {
      setPrtnCeoNameInput(sVal);
    }
  };

  //협력사 사업자등록번호 on change
  const handlePrtnRegNoInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 30) {
      setPrtnRegNoInput((pre) => pre);
    } else {
      setPrtnRegNoInput(sVal);
    }
  };

  //협력사 주소 on change
  const handlePrtnAddrInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 50) {
      setPrtnAddrInput((pre) => pre);
    } else {
      setPrtnAddrInput(sVal);
    }
  };

  //협력사 대표 번호 on change
  const handlePrtnPhonNoInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 30) {
      setPrtnPhonNoInput((pre) => pre);
    } else {
      setPrtnPhonNoInput(phoneNumberformatter(sVal));
    }
  };

  //삭제 버튼 이벤트
  const handleDeleteBtn_onClick = () => {
    setModal({
      title: '확인',
      content: `협력사 [${detailData.prtnName}] 정보를 삭제 하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);

              //협력사 정보 삭제
              deletePrtn();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  //수정 전환 버튼 이벤트
  const handleModifyBtn_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);
    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '협력사 수정',
        content: (
          <MN2302220631 id={tmpId} splrId={splrId} useModifyMode={true} />
        ),
        size: 1500,
      });
    }, 100);
  };

  //담당자 불러오기 버튼 on click
  const handleCntcCallBtn_onClick = () => {
    let tmpId: string = nanoid();
    setAddComponentModal({
      id: tmpId,
      title: '담당자 불러오기',
      content: (
        <MN2302220621
          id={tmpId}
          callback={(data: any) => {
            setCntcIdInput(data.userId || ''); //선택한 담당자 아이디
            setCntcNameInput(data.name || ''); //선택한 담당자 이름
            setCntcUnitInput(data.hdqrName || ''); //선택한 담당자 본부
            setCntcDpmtInput(data.dprtName || ''); //선택한 담당자 부서
            setCntcEmailInput(data.ml || ''); //선택한 담당자 이메일
            setCntcPhoneNoInput(data.celPhn || ''); //선택한 담당자 전화번호
          }}
        />
      ),
      size: 1400,
    });
  };

  //작업 목록 버튼 on click
  const handleWorkListBtn_onClick = () => {
    let tmpId: string = nanoid();
    setAddComponentModal({
      id: tmpId,
      title: '작업 현황',
      content: (
        <MN2302220641
          id={tmpId}
          splrId={splrId}
          data={workStateTable.data[0]}
        />
      ),
      size: 1400,
    });
  };

  //신규 저장 버튼 on click
  const handleAddSaveBtn_onClick = () => {
    //필수값 여부 확인
    if (!prtnNameInput.trim()) {
      setModal({
        title: '알림',
        content: '협력사명을 입력해 주세요.',
      });
      return;
    }

    if (teamTable.length < 1) {
      setModal({
        title: '알림',
        content: '팀을 입력해주세요.',
      });
      return;
    }

    // 팀 정보의 팀 이름이 있는지를 정의함
    let chkTeam: boolean = false;

    // 팀 정보의 팀 이름이 있는지 판단함
    teamTable.map((item: any) => {
      if (item.teamName === undefined || item.teamName.trim() === '') {
        chkTeam = true;
      }
    });

    if (chkTeam) {
      setModal({
        title: '알림',
        content: '팀 정보에서 팀명이 없는 팀이 있습니다.',
      });
      return;
    }

    setModal({
      title: '확인',
      content: '저장 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              //협력사 등록 API Call
              saveAddPrtnInfo();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  //수정 저장 버튼 on click
  const handleModifySaveBtn_onClick = () => {
    //필수값 여부 확인
    if (!prtnNameInput.trim()) {
      setModal({
        title: '알림',
        content: '협력사명을 입력해 주십시오.',
      });
      return;
    }

    if (teamTable.length < 1) {
      setModal({
        title: '알림',
        content: '팀을 입력해주세요.',
      });
      return;
    }

    // 팀 정보의 팀 이름이 있는지를 정의함
    let chkTeam: boolean = false;

    // 팀 정보의 팀 이름이 있는지 판단함
    teamTable.map((item: any) => {
      if (item.teamName === undefined || item.teamName.trim() === '') {
        chkTeam = true;
      }
    });

    if (chkTeam) {
      setModal({
        title: '알림',
        content: '팀 정보에서 팀명이 없는 팀이 있습니다.',
      });
      return;
    }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              //협력사 저장 API Call
              saveModifyPrtnInfo();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  // 팀 정보 폼 그리드 > 전체 삭제 버튼을 클릭함
  const handleTeamDeleteButton_onClick = () => {
    slsApi
      .getSlsNqryTrgt({})
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          setModal({
            title: '확인',
            content: '전체 팀을 삭제하시겠습니까?',
            useOkayButton: false,
            useCancelButton: true,
            cancelButtonLabel: '아니오',
            button: (
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={() => {
                  setRemoveModal(true);

                  // 팀 정보 폼 그리드 > 테이블에 적용함
                  setTeamTable([]);
                  // setTeamTable((pre: { column: any; data: any }) => ({
                  //   ...pre,
                  //   data: [],
                  // }));

                  notifications.show({
                    title: '확인',
                    message: (
                      <span className="text-lg">전체 팀을 삭제하였습니다.</span>
                    ),
                    color: 'indigo',
                  });
                }}
              >
                예
              </Button>
            ),
          });
        }
      })
      .catch((error: any) => {});
  };

  // 팀 정보 폼 그리드 > 선택 삭제 버튼을 클릭함
  const handleTeamSelectedDeleteButton_onClick = () => {
    slsApi
      .getSlsNqryTrgt({})
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 삭제할 조회 권한 아이디 목록을 정의함
          let selectedId: string[] = [];

          // 삭제할 조회 권한 아이디 목록을 불러옴
          teamTableRef.current.api.getSelectedRows().map((item: any) => {
            selectedId.push(item.teamId);
          });

          if (selectedId.length === 0) {
            notifications.show({
              title: '확인',
              message: (
                <span className="text-lg">
                  삭제할 팀을 체크하여 선택하세요.
                </span>
              ),
              color: 'pink',
            });

            return;
          }

          setModal({
            title: '확인',
            content: '선택하신 팀을 삭제하시겠습니까?',
            useOkayButton: false,
            useCancelButton: true,
            cancelButtonLabel: '아니오',
            button: (
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={() => {
                  setRemoveModal(true);

                  // 팀 목록에서 삭제할 팀을 적용한 후 최종 목록을 정의함
                  let tmpTeamTableData: any = teamTable.filter(
                    (item: any) => !_.includes(selectedId, item.teamId),
                  );

                  // 팀 정보 폼 그리드 > 테이블에 적용함
                  setTeamTable(tmpTeamTableData);
                  // setTeamTable((pre: { column: any; data: any }) => ({
                  //   ...pre,
                  //   data: tmpTeamTableData,
                  // }));

                  notifications.show({
                    title: '확인',
                    message: (
                      <span className="text-lg">
                        선택하신 팀을 삭제하였습니다.
                      </span>
                    ),
                    color: 'indigo',
                  });
                }}
              >
                예
              </Button>
            ),
          });
        }
      })
      .catch((error: any) => {});
  };

  // 팀 정보 폼 그리드 > 담당자 > 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const teamTargetUserSearchSelect_onChange = (event: any) => {
    setTeamTargetUserSearchSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 팀 정보 폼 그리드 > 담당자 > 검색 폼 그리드 > 검색어 > 입력의 값을 변경함
  const teamTargetUserSearchInput_onChange = (event: any) => {
    setTeamTargetUserSearchInput(event.currentTarget.value);
  };

  // 팀 정보 폼 그리드 > 담당자 > 검색 폼 그리드 > 검색어 > 입력에서 키를 입력함
  const teamTargetUserSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 검색 결과 테이블 데이터를 불러옴
      getTeamTargetUserTableData();
    }
  };

  // 팀 정보 폼 그리드 > 담당자 > 검색 폼 그리드 > 검색어 > 버튼을 클릭함
  const handleTeamTargetUserSearchButton_onChange = () => {
    // 검색 결과 테이블 데이터를 불러옴
    getTeamTargetUserTableData();
  };

  // 팀 정보 폼 그리드 > 담당자 > 적용 버튼을 클릭함
  const handleTeamTargetUserApplyButton_onClick = (event: any) => {
    // 추가할 담당자 목록을 정의함
    let selectedId: string[] = [];

    // 추가할 담당자 아이디 목록을 불러옴
    teamTargetUserTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedId.push(item.userId);
    });

    if (selectedId.length === 0) {
      notifications.show({
        title: '확인',
        message: (
          <span className="text-lg">추가할 담당자를 체크하여 선택하세요.</span>
        ),
        color: 'pink',
      });

      return;
    }

    // 팀 정보 폼 그리드 > 담당자 > 팝업 출력 여부에 적용함
    setVisibleTeamTargetUser(false);

    // 담당자 목록을 불러옴
    let tmlteamTableData: any[] = _.cloneDeep(teamTable);

    // 기존에 없던 담당자를 목록에 추가함
    selectedId.map((item: string) => {
      // if (_.findIndex(tmlteamTableData, { userId: item }) === -1) {
      tmlteamTableData.push(_.find(teamTargetUserTable.data, { userId: item }));
      // }
    });

    // 팀 정보 폼 그리드 > 테이블에 적용함
    setTeamTable(tmlteamTableData);
    // setTeamTable((pre: { column: any; data: any }) => ({
    //   ...pre,
    //   data: tmlteamTableData,
    // }));

    notifications.show({
      title: '확인',
      message: <span className="text-lg">담당자를 추가하였습니다.</span>,
      color: 'indigo',
    });
  };

  // 작업현황 상세 데이타 불러오기
  const handleSearchPrtnWorkDtlBtn_onClick = (event: any) => {
    let pNstlSttsCode = '';
    if (_.isEqual(event.column.colId, 'prcd')) {
      pNstlSttsCode = 'PRCD';
    } else if (_.isEqual(event.column.colId, 'workCmpl')) {
      pNstlSttsCode = 'WORK_CMPL';
    } else if (_.isEqual(event.column.colId, 'nspcCmpl')) {
      pNstlSttsCode = 'NSPC_CMPL';
    } else if (_.isEqual(event.column.colId, 'pmNspcCmpl')) {
      pNstlSttsCode = 'PM_NSPC_CMPL';
    }
    getWorkStateDtlTable(splrId, pNstlSttsCode);
  };

  /**************************** INIT ****************************/
  //작업 현황 테이블 초기화
  const initWorkStateTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      {
        headerName: '전체 작업',
        field: 'fulWork',
      },
      {
        headerName: '진행 중',
        field: 'prcd',
      },
      {
        headerName: '작업 완료',
        field: 'workCmpl',
      },
      {
        headerName: '검수 완료',
        field: 'nspcCmpl',
      },
      {
        headerName: 'PM 검수 완료',
        field: 'pmNspcCmpl',
      },
    );

    setWorkStateTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  //작업 현황 상세 테이블 초기화
  const initWorkStateDtlTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      {
        headerName: '번호',
        field: 'no',
      },
      {
        headerName: '계약명',
        field: 'cntrName',
      },
      {
        headerName: 'SHOP명',
        field: 'shipToCode',
      },
      {
        headerName: '담당PM',
        field: 'pmName',
      },
      {
        headerName: '진행상태',
        field: 'cmplName',
      },
      {
        headerName: '작업상태',
        field: 'nstlSttsName',
      },
      {
        headerName: '설치계획일(Network)',
        field: 'xpctDateNtwr',
      },
      {
        headerName: '협력사(Network)',
        field: 'prtnNtwrName',
      },
      {
        headerName: '설치계획일(ESL)',
        field: 'xpctDateEsl',
      },
      {
        headerName: '협력사(ESL)',
        field: 'prtnEslName',
      },
      {
        headerName: 'PM검수 완료일',
        field: 'workDate',
      },
      {
        headerName: '계산서 발행일',
        field: 'billDate',
      },
    );

    setWorkStateDtlTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 팀 정보 폼 그리드 > 테이블을 초기화함
  const initTeamTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 추가, 수정 상태일 때만 체크박스를 추가함
    if (!splrId || useModifyMode) {
      // 컬럼에 추가함
      tmpColumn.push({
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      });
    }

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '팀명',
        field: 'teamName',
      },
      {
        headerName: '담당자명',
        field: 'name',
        editable: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '담당자 연락처',
        field: 'celPhn',
        editable: false,
        width: 170,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => phoneNumberformatter(params.value),
      },
      {
        headerName: '팀 설명',
        field: 'teamDscr',
      },
    );

    // 팀 정보 폼 그리드 > 테이블에 적용함
    setTeamTableColumn(tmpColumn);
    // setTeamTable((pre: { column: any; data: any }) => ({
    //   ...pre,
    //   column: tmpColumn,
    // }));
  };

  // 팀 정보 테이블의 셀을 더블클릭함
  const handleTeamTable_onCellDoubleClicked = (event: any) => {
    // 담당자명, 담당자연락처 컬럼을 클릭했을 때만 실행함
    if (event.column.colId === 'name' || event.column.colId === 'celPhn') {
      // 드로어의 편집 대상에 적용함
      setDrawerSelectedItem(event.data);

      // 드로어를 출력함
      openDrawer();
    }
  };

  // 팀 정보 폼 그리드 > 담당자 > 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
  const initTeamTargetUserSearchSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '이름',
        value: 'name',
      },
      {
        label: '닉네임',
        value: 'nick_name',
      },
    );

    // 팀 정보 폼 그리드 > 담당자 > 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    setTeamTargetUserSearchSelect((pre: ISelect) => ({
      value: 'name',
      item: tmpOptionItem,
    }));
  };

  /**************************** 협력사 평가 ****************************/
  // 협력사 평가 테이블을 정의함
  const [prtnVltnTable, setPrtnVltnTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  const prtnVltnTableRef = useRef<any>(null);

  // 협력사 평가 테이블 초기화
  const initPrtnVltnTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      {
        headerName: '평가일',
        field: 'rgstDate',
        valueFormatter: (params: ValueFormatterParams) => {
          return `${moment(params.data.rgstDate).format('YYYY-MM-DD HH:mm')}`;
        },
        width: 180,
        suppressSizeToFit: true,
      },
      {
        headerName: '내용',
        field: 'dtl',
      },
      {
        headerName: '평가자',
        field: 'rgstName',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '',
        field: '',
        width: 80,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="w-full object-appear col-span-3 space-y-3">
            <div className="object-appear space-y-3">
              <Button
                color="indigo"
                radius="xl"
                onClick={() => handleDeletePrtnVltnBtn_onClick(params)}
                hidden={useModifyMode}
              >
                삭제
              </Button>
            </div>
          </div>
        ),
      },
    );

    setPrtnVltnTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 협력사 평가 등록 / 수정
  const handleAddPrtnVltnBtn_onClick = (params: any) => {
    let tmpId: string = nanoid();
    let type: string = 'REQ';

    if (params.data) {
      type = 'MOD';
    }

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '협력사 평가 입력',
      content: (
        <MN2302220631_PrtnVltn
          data={params.data}
          id={tmpId}
          splrId={splrId}
          type={type}
          callback={(data: any) => {
            getPrtnVltnTable();
          }}
        />
      ),
      size: 1000,
    });
  };

  // 협력사 평가 삭제
  const handleDeletePrtnVltnBtn_onClick = (params: any) => {
    // 협력사 평가 삭제(미사용) 처리
    setModal({
      title: '확인',
      content: '삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);
              deletePrtnVltn(params.data.vltnId);
            }}
          />
        </div>
      ),
    });
  };

  //협력사 평가 삭제 API
  const deletePrtnVltn = (pVltnId: string) => {
    prtnApi
      .deletePrtnVltn({
        splrId: splrId,
        vltnId: pVltnId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 평가 목록을 불러옴
          getPrtnVltnTable();

          setModal({
            title: '알림',
            content: '삭제하였습니다.',
            callback: () => {},
          });
        } else {
          console.log('> 협력사 평가 삭제 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>삭제에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    if (splrId) {
      getPrtn(splrId);
    }

    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    //작업 현황 테이블 초기화 (컬럼)
    initWorkStateTable();

    //작업 현황 상세 테이블 초기화 (컬럼)
    initWorkStateDtlTable();

    // 팀 정보 폼 그리드 > 테이블을 초기화함
    initTeamTable();

    // 팀 정보 폼 그리드 > 담당자 > 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
    initTeamTargetUserSearchSelect();

    // 팀 정보 폼 그리드 > 담당자 > 테이블을 초기화함
    initTeamTargetUserTable();

    // 협력사 평가 테이블 초기화
    initPrtnVltnTable();

    // 팀의 담당자 선택 드로어 테이블을 초기화함
    initDrawerTable();

    return () => {};
  }, []);

  // 협력사 평가 목록 테이블 값 초기화
  useEffect(() => {
    if (prtnVltnTable.column.length === 0) {
      return;
    }
    getPrtnVltnTable();
    return () => {};
  }, [prtnVltnTable.column]);

  // 작업현황 테이블 값 초기화
  useEffect(() => {
    if (workStateTable.column.length === 0) {
      return;
    }
    getWorkStateTable();
    getWorkStateDtlTable(splrId, '');
    return () => {};
  }, [workStateTable.column]);

  // 팀 정보 폼 그리드 > 담당자 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (teamTargetUserTable.column.length === 0) {
      return;
    }

    // 조회 권한 폼 그리드 > 대상자 > 테이블 데이터를 불러옴
    getTeamTargetUserTableData();

    return () => {};
  }, [teamTargetUserTable.column]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220631Table') > -1) {
      // 협력사 평가 목록 테이블 데이터를 불러옴
      getPrtnVltnTable();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220631Table');
    }

    return () => {};
  }, [refreshList]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  /**************************** 기타 Function ****************************/
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  /**
   * 팀의 담당자 선택 드로어
   */

  // 드로어의 출력 여부를 정의함
  const [openedDrawer, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  // 드로어의 편집 대상을 정의함
  const [drawerSelectedItem, setDrawerSelectedItem] = useState<any>(null);

  // 검색 조건 셀렉트를 정의함
  const [drawerSearchSelectItem, setDrawerSearchSelectItem] = useState<
    IOptionItem[]
  >([]);
  const [drawerSearchSelect, setDrawerSearchSelect] = useState<string>('name');

  // 검색어 입력을 정의함
  const [drawerSearchInput, setDrawerSearchInput] = useState<string>('');

  // 테이블을 정의함
  const [drawerTableColumn, setDrawerTableColumn] = useState<any>([]);
  const [drawerTable, setDrawerTable] = useState<any>([]);
  const [drawerFilterTable, setDrawerFilterTable] = useState<any>([]);
  const drawerTableRef = useRef<any>(null);

  // 테이블 페이징을 정의함
  const [drawerTablePaging, setDrawerTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const drawerTablePagingCurrentPageRef = useRef<number>(1);

  // 검색 조건 셀렉트의 아이템을 초기화함
  const initDrawerSearchSelectItem = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '이름',
        value: 'name',
      },
      {
        label: '닉네임',
        value: 'nick_name',
      },
    );

    setDrawerSearchSelectItem(tmpOptionItem);
  };

  // 테이블을 초기화함
  const initDrawerTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'checkbox',
        width: 60,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      },
      {
        field: 'nickName',
        headerName: '닉네임',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.nickName);
        },
      },
      {
        field: 'name',
        headerName: '이름',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.name);
        },
      },
      {
        field: 'celPhn',
        headerName: '휴대폰번호',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.celPhn);
        },
      },
      {
        field: 'ml',
        headerName: '이메일',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.ml);
        },
      },
    );

    // 테이블에 적용함
    setDrawerTableColumn(tmpColumn);
  };

  // 테이블의 데이터를 불러옴
  const getDrawerTableData = () => {
    cmnApi
      .getCmnUsers({
        searchType: drawerSearchSelect,
        searchKeyword: '',
        useYn: 'Y',
        pageSize: 10000,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 테이블에 적용함
          setDrawerTable(
            data.data.list.filter((item: any) => item.cmpnCode === 'PRTN'),
          );
          setDrawerFilterTable(
            data.data.list.filter((item: any) => item.cmpnCode === 'PRTN'),
          );
        }
      });
  };

  // 검색 조건 셀렉트의 값을 변경함
  const drawerSearchSelect_onChange = (event: any) => {
    setDrawerSearchSelect(event);
  };

  // 검색어 입력의 값을 변경함
  const drawerSearchInput_onChange = (event: any) => {
    setDrawerSearchInput(event.currentTarget.value);
  };

  // 검색어 입력에서 키를 입력함
  const drawerSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 검색 버튼을 클릭함
      drawerSearchButton_onClick();
    }
  };

  // 검색 버튼을 클릭함
  const drawerSearchButton_onClick = () => {
    // 검색어에 맞게 데이터를 필터링함
    let tmpData: any = drawerTable.filter(
      (filterItem: any) =>
        filterItem.nickName.indexOf(drawerSearchInput) > -1 ||
        filterItem.name.indexOf(drawerSearchInput) > -1 ||
        filterItem.celPhn.indexOf(drawerSearchInput) > -1 ||
        filterItem.ml.indexOf(drawerSearchInput) > -1,
    );

    // 필터링한 데이터를 테이블에 적용함
    setDrawerFilterTable(tmpData);
  };

  // 선택한 사용자를 담당자에 적용함
  const drawerAddButton_onClick = () => {
    // 선택한 항목을 정의함
    let selectedId: any = [];

    // 선택한 항목을 불러옴
    drawerTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedId.push({
        userId: item.userId,
      });
    });

    // 선택한 항목이 없음
    if (selectedId.length === 0) {
      setModal({
        title: '알림',
        content: `${
          drawerSelectedItem === null ? '추가' : '수정'
        }할 항목을 체크하여 선택하세요.`,
      });

      return;
    }

    setModal({
      title: '확인',
      content: `선택하신 ${selectedId.length}개의 항목을 ${
        drawerSelectedItem === null ? '추가' : '수정'
      }하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            let tmpTableData: any = [];

            if (drawerSelectedItem === null) {
              // 항목을 추가함
              selectedId.map((item: any) => {
                tmpTableData.push({
                  ..._.find(drawerTable, {
                    userId: item.userId,
                  }),
                  teamId: nanoid(),
                  new: true,
                });
              });

              // // 기존에 없던 항목만 추가함
              // selectedId.map((item: any) => {
              //   if (
              //     _.findIndex(teamTable, {
              //       userId: item.userId,
              //     }) === -1
              //   ) {
              //     tmpTableData.push(
              //       _.find(drawerTable, {
              //         userId: item.userId,
              //       }),
              //     );
              //   }
              // });

              // 테이블에 적용함
              setTeamTable([...teamTable, ...tmpTableData]);
            } else {
              // 항목을 수정함
              let tmpTeamTableData: any = _.cloneDeep(teamTable);

              selectedId.map((item: any) => {
                // 드로어 테이블에서 선택한 항목을 불러옴
                let tmpSelectedRow = _.find(drawerTable, {
                  userId: item.userId,
                });

                tmpTeamTableData.map((subItem: any) => {
                  if (subItem.teamId === drawerSelectedItem.teamId) {
                    subItem.cntcId = tmpSelectedRow.userId;
                    subItem.name = tmpSelectedRow.name;
                    subItem.nickName = tmpSelectedRow.nickName;
                    subItem.celPhn = tmpSelectedRow.celPhn;
                  }
                });
              });

              // 테이블에 적용함
              setTeamTable(tmpTeamTableData);
            }

            notifications.show({
              title: '알림',
              message: `${
                drawerSelectedItem === null ? '추가' : '수정'
              }하였습니다.`,
            });

            // 드로어를 닫음
            closeDrawer();
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 드로어가 출력될 때 실행함
  useEffect(() => {
    if (!openedDrawer) {
      // 드로어의 편집 대상을 초기화함
      setDrawerSelectedItem(null);

      return;
    }

    // 검색 조건 셀렉트의 아이템을 초기화함
    initDrawerSearchSelectItem();

    // 검색어를 초기화함
    setDrawerSearchInput('');

    // 데이터를 불러옴
    getDrawerTableData();

    return () => {};
  }, [openedDrawer]);

  return (
    <div className="space-y-7">
      <div className="space-y-5">
        {/* 협력사 정보 */}
        <PbFormGrid id="step-1" label="협력사 정보" cols={2}>
          <PbFormGridCol label="협력사명" withAsterisk={true}>
            <TextInput
              placeholder=""
              value={prtnNameInput}
              readOnly={splrId && !useModifyMode ? true : false}
              variant={splrId && !useModifyMode ? 'unstyled' : 'default'}
              onChange={handlePrtnNameInput_onChange}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="유형">
            <Radio.Group
              size="sm"
              name="prtnCategory"
              onChange={handlePrtnCategoryRadio_onChange}
              value={prtnCategoryRadio}
              className="w-full"
            >
              <Group>
                <Radio
                  value="NTWR_CNST"
                  label="네트워크 공사"
                  disabled={splrId && !useModifyMode ? true : false}
                />
                <Radio
                  value="ESL_NSTL"
                  label="ESL 설치"
                  disabled={splrId && !useModifyMode ? true : false}
                />
              </Group>
            </Radio.Group>
          </PbFormGridCol>
          <PbFormGridCol label="대표자명">
            <TextInput
              placeholder=""
              value={prtnCeoNameInput}
              readOnly={splrId && !useModifyMode ? true : false}
              variant={splrId && !useModifyMode ? 'unstyled' : 'default'}
              onChange={handlePrtnCeoNameInput_onChange}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="사업자등록번호">
            <TextInput
              placeholder=""
              value={prtnRegNoInput}
              readOnly={splrId && !useModifyMode ? true : false}
              variant={splrId && !useModifyMode ? 'unstyled' : 'default'}
              onChange={handlePrtnRegNoInput_onChange}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="주소">
            <TextInput
              placeholder=""
              value={prtnAddrInput}
              readOnly={splrId && !useModifyMode ? true : false}
              variant={splrId && !useModifyMode ? 'unstyled' : 'default'}
              onChange={handlePrtnAddrInput_onChange}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="대표번호">
            <TextInput
              placeholder=""
              value={phoneNumberformatter(prtnPhonNoInput)}
              readOnly={splrId && !useModifyMode ? true : false}
              variant={splrId && !useModifyMode ? 'unstyled' : 'default'}
              onChange={handlePrtnPhonNoInput_onChange}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 팀 정보 */}
        <PbSection
          id="step-2"
          label="팀 정보"
          rightForm={
            <>
              {/* 버튼 */}
              {/* 추가, 수정 상태 */}
              {(!splrId || useModifyMode) && (
                <div className="flex justify-end items-center space-x-2">
                  {/* 버튼 */}
                  <Button
                    variant="outline"
                    color="pink"
                    radius="xl"
                    onClick={handleTeamDeleteButton_onClick}
                  >
                    전체 삭제
                  </Button>

                  {/* 버튼 */}
                  <Button
                    color="pink"
                    radius="xl"
                    onClick={handleTeamSelectedDeleteButton_onClick}
                  >
                    선택 삭제
                  </Button>

                  {/* 버튼 */}
                  <Button onClick={openDrawer} color="indigo" radius="xl">
                    추가
                  </Button>

                  {/* 버튼 */}
                  {/*<Popover*/}
                  {/*  opened={visibleTeamTargetUser}*/}
                  {/*  onClose={() => setVisibleTeamTargetUser(false)}*/}
                  {/*  position="top-end"*/}
                  {/*  transitionProps={{ transition: 'pop' }}*/}
                  {/*  withArrow={false}*/}
                  {/*  shadow="xl"*/}
                  {/*  zIndex={100}*/}
                  {/*  offset={10}*/}
                  {/*>*/}
                  {/*  <Popover.Target>*/}
                  {/*    <Button*/}
                  {/*      onClick={() => setVisibleTeamTargetUser(true)}*/}
                  {/*      color="indigo"*/}
                  {/*      radius="xl"*/}
                  {/*    >*/}
                  {/*      추가*/}
                  {/*    </Button>*/}
                  {/*  </Popover.Target>*/}

                  {/*  <Popover.Dropdown className="border-1 !border-gray-500">*/}
                  {/*    <PbSection label="추가할 팀의 담당자 선택">*/}
                  {/*      <div className="space-y-3">*/}
                  {/*        /!* 검색 폼 그리드 *!/*/}
                  {/*        <PbFormGrid cols={1}>*/}
                  {/*          <PbFormGridCol label="검색">*/}
                  {/*            /!* 셀렉트 *!/*/}
                  {/*            <PbSelect*/}
                  {/*              onChange={teamTargetUserSearchSelect_onChange}*/}
                  {/*              data={teamTargetUserSearchSelect.item}*/}
                  {/*              value={teamTargetUserSearchSelect.value}*/}
                  {/*              setSelect={setTeamTargetUserSearchSelect}*/}
                  {/*            />*/}

                  {/*            /!* 텍스트 입력 *!/*/}
                  {/*            <TextInput*/}
                  {/*              placeholder="검색어를 입력하세요."*/}
                  {/*              onChange={teamTargetUserSearchInput_onChange}*/}
                  {/*              onKeyUp={teamTargetUserSearchInput_onKeyUp}*/}
                  {/*              value={teamTargetUserSearchInput}*/}
                  {/*              className="w-1/5"*/}
                  {/*            />*/}

                  {/*            /!* 버튼 *!/*/}
                  {/*            <div className="flex justify-center items-center space-x-2">*/}
                  {/*              /!* 버튼 *!/*/}
                  {/*              <Button*/}
                  {/*                color="indigo"*/}
                  {/*                radius="xl"*/}
                  {/*                onClick={*/}
                  {/*                  handleTeamTargetUserSearchButton_onChange*/}
                  {/*                }*/}
                  {/*              >*/}
                  {/*                검색*/}
                  {/*              </Button>*/}
                  {/*            </div>*/}
                  {/*          </PbFormGridCol>*/}
                  {/*        </PbFormGrid>*/}

                  {/*        /!* 테이블 *!/*/}
                  {/*        <div style={{ width: '700px' }} className="h-96">*/}
                  {/*          <PbAgGridReact*/}
                  {/*            refs={teamTargetUserTableRef}*/}
                  {/*            columnDefs={teamTargetUserTable.column}*/}
                  {/*            rowData={teamTargetUserTable.data}*/}
                  {/*            defaultColDef={{*/}
                  {/*              resizable: true,*/}
                  {/*              sortable: true,*/}
                  {/*              wrapHeaderText: false,*/}
                  {/*              autoHeaderHeight: true,*/}
                  {/*            }}*/}
                  {/*            rowSelection="multiple"*/}
                  {/*            sizeColumnsToFit={true}*/}
                  {/*          />*/}
                  {/*        </div>*/}

                  {/*        <div className="flex justify-end items-center space-x-2">*/}
                  {/*          /!* 버튼 *!/*/}
                  {/*          <Button*/}
                  {/*            color="indigo"*/}
                  {/*            radius="xl"*/}
                  {/*            onClick={handleTeamTargetUserApplyButton_onClick}*/}
                  {/*          >*/}
                  {/*            적용*/}
                  {/*          </Button>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </PbSection>*/}
                  {/*  </Popover.Dropdown>*/}
                  {/*</Popover>*/}
                </div>
              )}
            </>
          }
        >
          <div className="space-y-3">
            {/* 테이블 */}
            <div className="w-full h-96">
              <PbAgGridReact
                refs={teamTableRef}
                columnDefs={teamTableColumn}
                rowData={teamTable}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                  editable: splrId && !useModifyMode ? false : true,
                }}
                rowSelection="multiple"
                sizeColumnsToFit={true}
                onCellDoubleClicked={handleTeamTable_onCellDoubleClicked}
              />
            </div>
          </div>
        </PbSection>

        {/* 담당자 정보 */}
        <div className="content-wrapper" hidden={true}>
          <div className="title-wrapper mt-1">
            <h3 className="leading-none">
              <span className="text-lg text-gray-600 font-semibold">
                담당자 정보
              </span>
            </h3>
            <div className="right-btn !space-x-2">
              {(!splrId || useModifyMode) && (
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleCntcCallBtn_onClick}
                >
                  담당자 불러오기
                </Button>
              )}
            </div>
          </div>
          <PbFormGrid label="" cols={2}>
            {/* <PbFormGridCol label="아이디">
              <TextInput
                placeholder=""
                value={cntcIdInput}
                readOnly={true}
                className="w-full"
              />
            </PbFormGridCol> */}
            <PbFormGridCol label="이름">
              <TextInput
                placeholder=""
                value={cntcNameInput}
                readOnly={true}
                variant={'unstyled'}
                className="w-full"
              />
            </PbFormGridCol>
            {/* <PbFormGridCol label="본부">
              <TextInput
                placeholder=""
                value={cntcUnitInput}
                readOnly={true}
                className="w-full"
              />
            </PbFormGridCol> */}
            {/* <PbFormGridCol label="부서">
              <TextInput
                placeholder=""
                value={cntcDpmtInput}
                readOnly={true}
                className="w-full"
              />
            </PbFormGridCol> */}
            <PbFormGridCol label="휴대폰">
              <TextInput
                placeholder=""
                value={cntcPhoneNoInput}
                readOnly={true}
                variant={'unstyled'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="E-mail" colSpan={2}>
              <TextInput
                placeholder=""
                value={cntcEmailInput}
                readOnly={true}
                variant={'unstyled'}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>
        </div>

        {/* 작업 현황 테이블 */}
        {splrId && (
          <div id="step-3" className="content-wrapper">
            <div className="title-wrapper mt-1">
              <h3 className="leading-none">
                <span className="text-lg text-gray-600 font-semibold">
                  작업 현황
                </span>
              </h3>
              <div className="right-btn !space-x-2 hidden">
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleWorkListBtn_onClick}
                >
                  작업 목록
                </Button>
              </div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-44">
              <PbAgGridReact
                refs={workStateTableRef}
                columnDefs={workStateTable.column}
                rowData={workStateTable.data}
                // onCellDoubleClicked={(event: any) =>
                //   handleSearchPrtnWorkDtlBtn_onClick(event)
                // }
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
              />
            </div>
          </div>
        )}

        {/* 작업 현황 상세 테이블 */}
        {splrId && (
          <div id="step-3" className="content-wrapper">
            <div className="title-wrapper mt-1">
              <h3 className="leading-none">
                <span className="text-lg text-gray-600 font-semibold">
                  작업 현황 상세
                </span>
              </h3>
              <div className="right-btn !space-x-2"></div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-60">
              <PbAgGridReact
                columnDefs={workStateDtlTable.column}
                rowData={workStateDtlTable.data}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
              />
            </div>
          </div>
        )}

        {/* 협력사 평가 테이블 */}
        {splrId && (
          <div id="step-4" className="content-wrapper">
            <div className="title-wrapper mt-1">
              <h3 className="leading-none">
                <span className="text-lg text-gray-600 font-semibold">
                  협력사 평가
                </span>
              </h3>
              <div className="right-btn !space-x-2">
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleAddPrtnVltnBtn_onClick}
                  hidden={useModifyMode}
                >
                  평가 등록
                </Button>
              </div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-60">
              <PbAgGridReact
                refs={prtnVltnTableRef}
                columnDefs={prtnVltnTable.column}
                rowData={prtnVltnTable.data}
                onRowDoubleClicked={(event: any) =>
                  handleAddPrtnVltnBtn_onClick(event)
                }
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
              />
            </div>
          </div>
        )}
      </div>

      {/* 팀의 담당자 선택 드로어 */}
      <Drawer
        opened={openedDrawer}
        onClose={closeDrawer}
        position="right"
        size={700}
        title={
          <span className="text-xl font-semibold">
            팀의 담당자 {drawerSelectedItem === null ? '추가' : '수정'}
          </span>
        }
        zIndex={1000}
        // className="drawer-element"
      >
        <div className="space-y-3">
          {/* 검색 폼 그리드 */}
          <PbFormGrid cols={1}>
            <PbFormGridCol label="검색">
              {/*/!* 셀렉트 *!/*/}
              {/*<Select*/}
              {/*  onChange={drawerSearchSelect_onChange}*/}
              {/*  data={drawerSearchSelectItem}*/}
              {/*  value={drawerSearchSelect}*/}
              {/*  className="w-28"*/}
              {/*/>*/}

              {/* 텍스트 입력 */}
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={drawerSearchInput_onChange}
                onKeyUp={drawerSearchInput_onKeyUp}
                value={drawerSearchInput}
              />

              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={drawerSearchButton_onClick}
                >
                  검색
                </Button>
              </div>
            </PbFormGridCol>
          </PbFormGrid>

          {/* 테이블의 상단 버튼 */}
          <div className="flex justify-end items-center space-x-2">
            {/* 할당 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              onClick={drawerAddButton_onClick}
            >
              선택한 사용자를 담당자에 적용
            </Button>
          </div>

          {/* 테이블 */}
          <div style={{ height: 'calc(100vh - 220px)' }}>
            <PbAgGridReact
              refs={drawerTableRef}
              columnDefs={drawerTableColumn}
              rowData={drawerFilterTable}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection={drawerSelectedItem === null ? 'multiple' : 'single'}
              sizeColumnsToFit={true}
              visiblePaging={false}
              paging={{
                totalPage: drawerTablePaging.totalPage,
                currentPage: drawerTablePaging.currentPage,
              }}
              onChangePage={(event: any) => {
                // 테이블의 페이지를 변경함
                drawerTablePagingCurrentPageRef.current = event;
                setDrawerTablePaging((pre: ITablePaging) => ({
                  ...pre,
                  currentPage: drawerTablePagingCurrentPageRef.current,
                }));

                // 테이블의 데이터를 불러옴
                getDrawerTableData();
              }}
            />
          </div>

          {/* 테이블의 하단 버튼 */}
          <div className="flex justify-end items-center space-x-2">
            {/* 할당 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              onClick={drawerAddButton_onClick}
            >
              선택한 사용자를 담당자에 적용
            </Button>
          </div>
        </div>
      </Drawer>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        {!splrId && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleAddSaveBtn_onClick}
              >
                저장
              </Button>
            </div>
          </>
        )}
        {splrId && !useModifyMode && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleModifyBtn_onClick}
              >
                수정 전환
              </Button>
            </div>
          </>
        )}
        {splrId && useModifyMode && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                variant="outline"
                color="pink"
                radius="xl"
                size="md"
                onClick={handleDeleteBtn_onClick}
              >
                삭제
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleModifySaveBtn_onClick}
              >
                저장
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220631;
