import React, { PropsWithChildren, useEffect, useState } from 'react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import _, { forEach, now } from 'lodash';
import { PbTable } from '../PbTable';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import * as sttnApi from '../../apis/sttn.api';
import { PbInputNumber } from '../PbInputNumber';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  Group,
  NumberInput,
  Select,
  Tabs,
  TextInput,
} from '@mantine/core';
import { Radio } from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import MN2302220351_Partner from '../MN2302220351_Partner/MN2302220351_Partner.component';
import PbSelect from '../PbSelect/PbSelect.component';

interface IComponentProps {
  id: string;
  type?: string;
  pSplrTypeCode?: string;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * 설치 > 진행작업관리 > Shop별 설치 작업결과 등록 > 소프트웨어 정보 등록
 * @param id 화면 아이디
 * @param type 추가/수정 여부
 * @param pSplrTypeCode 협력사 설치 구분
 * @param data 상세 정보
 * @param onClick <이벤트>
 * @param callback modal 수정정보 반영을 위한 이벤트
 * @constructor
 */
const MN2302220352_GridModify = ({
  id = '',
  type = '',
  pSplrTypeCode = '',
  data,
  onClick,
  callback,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 변수 정의
   */

  // 내용 정의
  const [ttlInput, setTtlInput] = useState<string>('');

  // 내용 변경 event
  const handleTtlInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 50) {
      // 50byte 제한
      setTtlInput((pre) => pre);
    } else {
      setTtlInput(sVal);
    }
  };

  // 구분 정의
  const [workTypeSelect, setWorkTypeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 구분 초기화
  const initWorkTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];
    tmpOptionItem.push(
      {
        label: '서버 IP',
        value: 'SRVR_IP',
      },
      {
        label: '게이트웨이 IP',
        value: 'GTWY_IP',
      },
    );
    setWorkTypeSelect((pre: any) => ({
      value: 'SRVR_IP',
      item: tmpOptionItem,
    }));
  };

  // 구분 변경
  const handleWorkTypeSelect_onChange = (event: any) => {
    setWorkTypeSelect((pre: any) => ({
      ...pre,
      value: event,
    }));
  };

  /* // 변수 정의 */

  /**
   * 처리
   */

  // 입력값 byte 확인
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  // 적용 을 클릭함
  const handleApply_onClickButton = () => {
    if (callback !== undefined) {
      if (_.isEmpty(ttlInput.trim())) {
        setModal({
          title: '알림',
          content: '내용을 입력하세요.',
        });
        return;
      }

      let tmpWorkType: any = workTypeSelect.item.filter((item: any) =>
        _.isEqual(item.value, workTypeSelect.value),
      );

      let rtnObj = {
        modifyRowIndex: type === 'REQ' ? 0 : data.modifyRowIndex,
        ntwrWorkName: tmpWorkType[0].label,
        ntwrWorkCode: workTypeSelect.value,
        ttl: ttlInput.trim(),
        type: type,
      };
      callback(rtnObj);
      // 컴포넌트 모달을 닫음
      setRemoveComponentModal(id);
    }
  };

  /* // */

  /**
   * useEffect
   */

  // 화면 로딩 후 한번만 실행
  useEffect(() => {
    // 구분 초기화
    initWorkTypeSelect();
    if (_.isEqual(type, 'MOD')) {
      // 수정정보 입력창 정보 설정
      if (data.ttl) {
        setTtlInput(data.ttl);
      } else {
        setTtlInput('');
      }
      setWorkTypeSelect((pre: any) => ({
        ...pre,
        value: data.ntwrWorkCode,
      }));
    }
  }, []);

  /* // */

  return (
    <div className="space-y-7">
      <div className="space-y-5">
        {/* 소프트웨어 정보 폼 그리드 */}
        <PbFormGrid label="소프트웨어 정보" cols={2}>
          <PbFormGridCol label="구분">
            <PbSelect
              onChange={handleWorkTypeSelect_onChange}
              data={workTypeSelect.item}
              value={workTypeSelect.value}
              setSelect={setWorkTypeSelect}
              className="w-full"
              readOnly={type === 'MOD' ? true : false}
              variant={type === 'MOD' ? 'unstyled' : 'default'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="내용">
            <TextInput
              placeholder="내용을 입력하세요."
              value={ttlInput}
              onChange={handleTtlInput_onChange}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        <div className="component-modal-button-area space-x-2">
          <Button
            variant="outline"
            color="gray"
            radius="xl"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          >
            닫기
          </Button>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={handleApply_onClickButton}
          >
            적용
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MN2302220352_GridModify;
