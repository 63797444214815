import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  Checkbox,
  Group,
  Radio,
} from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PageLayout } from '../../components/PageLayout';
import { PbFormGrid } from '../../components/PbFormGrid';
import { PbFormGridCol } from '../../components/PbFormGridCol';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../../stores/refreshList.store';
import * as prtnApi from '../../apis/prtn.api';

interface IMN2302220621Props {
  id?: string;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * 협력사 관리 > 협력사 등록 > 담당자 불러오기
 * @param id 컴포넌트 모달 아이디
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220621 = ({
  id = '',
  data,
  onClick,
  callback,
}: PropsWithChildren<IMN2302220621Props>) => {
  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  /**************************** API 호출 ****************************/
  //사용자 목록 조회
  const getCntcList = () => {
    prtnApi
      .getPrtnPrtnSearchUser({
        currPageIdx: '', // 현재 페이지
        searchKeyword: cntcNameInput, //검색어
        searchType: 'name', //검색 구분
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setCntcListTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));
        }
      });
  };

  /**************************** STATE ****************************/
  //담당자명 검색 state
  const [cntcNameInput, setCntcNameInput] = useState<string>('');
  //담당자 테이블
  const cntcListTableRef = useRef(null);
  //담당자 테이블 state
  const [cntcListTable, setCntcListTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  /**************************** ON EVENT LISTENER ****************************/
  //담당자명 검색 on change
  const handleCntcNameInput_onChange = (event: any) => {
    setCntcNameInput(event.target.value);
  };
  //담당자명 검색 on keyup
  const handleCnteNameInput_onKeyup = (event: any) => {
    if (event.keyCode === 13) {
      getCntcList();
    }
  };
  //검색 버튼 이벤트
  const handleCntcSerchBtn_onClick = () => {
    getCntcList();
  };
  //초기화 버튼 이벤트
  const handleCancelButton_onClick = () => {
    setCntcNameInput('');
  };

  // 담당자 테이블의 행을 클릭함
  const handleCntcListTable_onRowDoubleClicked = (row: any) => {
    if (callback !== undefined) {
      callback(row.data);
      // 컴포넌트 모달을 닫음
      setRemoveComponentModal(id);
    }
  };

  /**************************** INIT ****************************/
  //담당자 테이블 초기화
  const initCntcListTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      // {
      //   column: 'userId',
      //   title: '아이디',
      //   width: '0',
      //   align: 'center',
      // },
      {
        field: 'name',
        headerName: '이름',
      },
      // {
      //   column: 'cmpnName',
      //   title: '회사명',
      //   width: '0',
      //   align: 'center',
      // },
      // {
      //   column: 'dprtName',
      //   title: '부서명',
      //   width: '0',
      //   align: 'center',
      // },
      {
        field: 'celPhn',
        headerName: '전화번호',
      },
      {
        field: 'ml',
        headerName: '이메일',
      },
    );

    setCntcListTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    initCntcListTable(); //담당자 목록 테이블 초기화(컬럼)
    return () => {};
  }, []);
  // 담당자 목록 테이블 값 초기화
  useEffect(() => {
    if (cntcListTable.column.length === 0) {
      return;
    }
    getCntcList();
    return () => {};
  }, [cntcListTable.column]);

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 검색 폼 그리드 */}
        <PbFormGrid label="검색" cols={2}>
          <PbFormGridCol label="담당자명" colSpan={2}>
            {/* 검색어 텍스트 입력 */}
            <TextInput
              placeholder="검색어를 입력하세요."
              onChange={handleCntcNameInput_onChange}
              onKeyUp={handleCnteNameInput_onKeyup}
              value={cntcNameInput}
              className="w-full"
            />
            {/* 버튼 */}
            <div className="flex justify-center items-center space-x-2">
              <Button
                color="indigo"
                radius="xl"
                onClick={handleCancelButton_onClick}
              >
                초기화
              </Button>
              <Button
                color="indigo"
                radius="xl"
                onClick={handleCntcSerchBtn_onClick}
              >
                검색
              </Button>
            </div>
          </PbFormGridCol>
        </PbFormGrid>
        {/* 테이블 */}
        <div className="w-full h-152">
          <AgGridReact
            columnDefs={cntcListTable.column}
            rowData={cntcListTable.data}
            onRowDoubleClicked={handleCntcListTable_onRowDoubleClicked}
            defaultColDef={{
              initialWidth: 150,
              resizable: true,
              sortable: false,
              wrapHeaderText: false,
              autoHeaderHeight: true,
              suppressSizeToFit: false,
            }}
            rowSelection="single"
            overlayNoRowsTemplate="데이터가 없습니다."
            className="ag-theme-alpine"
            onGridReady={(event) => event.api.sizeColumnsToFit()}
          />
        </div>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        <div>
          <Button
            color="indigo"
            radius="xl"
            onClick={() => setRemoveComponentModal(id)}
          >
            닫기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MN2302220621;
