import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loadingAnimation from './loading.json';
import Lottie from 'lottie-react';

interface IComponentProps {
  visible?: boolean;
}

/**
 * 테이블 데이터 로딩 아이콘
 * @param visible 출력 여부
 * @constructor
 */
const LoadingTableDataIcon = ({
  visible = false,
}: PropsWithChildren<IComponentProps>) => {
  return (
    <>
      {visible && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center bg-white/0 z-20">
          <div className="w-96 h-96 flex justify-center items-center">
            <Lottie animationData={loadingAnimation} loop={true} />
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingTableDataIcon;
