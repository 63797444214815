import axios, { AxiosResponse } from 'axios';
import { IApiListResult, IApiResult } from '../interfaces/app.interface';
import api from '../utils/axios.util';
import { postNstlNotRgst } from './nstl.api';

/**
 * WORK: 작업
 */

/**
 * 작업 현황 목록 - Mobile
 * GET /api/work/stt/mbl
 */
export const getWorkSttMbl = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/work/stt/mbl',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업 현황 상세 조회(팀 포함 협력사 대상)
 * GET /api/work/stt/{dcmnDntfNmbr}/{cmpnCode}
 */
export const getSttDcmnDntfNmbrCmpnCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/work/stt', params.dcmnDntfNmbr, params.cmpnCode].join('/'),
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업 현황 상세 조회 - 기본정보/네트워크/ESL/품목/검수
 * GET /api/work/stt/dtl/all/{dcmnDntfNmbr}
 */
export const getSttDtlAllDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/work/stt/dtl/all', params.dcmnDntfNmbr].join('/'),
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 댓글 첨부파일만 등록
 * POST /api/work/prgr/tch
 * @param params dcmnDntfNmbr 주문번호
 * @param params splrId 협력사 아이디
 * @param params teamId 팀 아이디
 * @param params fileList 첨부파일 목록
 * @param params dtl 첨부파일 설명
 * @param params bfFileIdList 이전 첨부파일 아이디 목록
 */
export const postPrgrTch = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/work/prgr/tch',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postPrgrTch Error:', error);
    });

  return result;
};

/**
 * 작업 내역/결과 수정(네트워크)
 * PUT /api/work/work-ntwr/{dcmnDntfNmbr}
 */
export const putWorkNtwrDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/work/work-ntwr', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putWorkNtwrDcmnDntfNmbr Error:', error);
    });

  return result;
};

/**
 * 작업 내역 - 재고이전 포함 작업완료(ESL)
 * PUT /api/work/work-esl/nvnt-rqst/{dcmnDntfNmbr}
 */
export const putWorkEslNvntRqstDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/work/work-esl/nvnt-rqst', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putWorkEslNvntRqstDcmnDntfNmbr Error:', error);
    });

  return result;
};

/**
 * PM 검수 취소
 * PUT /api/work/pm-nspc-cancel/{dcmnDntfNmbr}
 */
export const putPmNspcCancelDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/work/pm-nspc-cancel', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putPmNspcCancelDcmnDntfNmbr Error:', error);
    });

  return result;
};

/**
 * PM 검수 상세 등록/수정(PM 검수 완료)
 * POST /api/work/pm-nspc-rgst/{dcmnDntfNmbr}
 */
export const postPmNspcRgstDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: ['/api/work/pm-nspc-rgst', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postPmNspcRgstDcmnDntfNmbr Error:', error);
    });

  return result;
};

/**
 * 작업 현황 목록
 * GET /api/work/stt
 */
export const getWorkStt = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/work/stt',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업배정 목록
 * GET /api/work/asgn
 */
export const getAsgn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/work/asgn',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업배정 - 일별 주문 목록
 * GET /api/work/asgn/day/dcmn
 */
export const getAsgnDayDcmn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/work/asgn/day/dcmn',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업 현황 목록 엑셀 다운로드
 * GET /api/work/stt-excel
 */
export const getSttExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/work/stt-excel',
    responseType: 'blob',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업배정 - 일별 협력사 목록
 * GET /api/work/asgn/day/prtn
 */
export const getAsgnDayPrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/work/asgn/day/prtn',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업배정 - 기간내 협력사 가동 목록
 * GET /api/work/asgn/prtn
 */
export const getAsgnPrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/work/asgn/prtn',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업배정 - 일별 협력사 가동불가 사유 저장
 * POST /api/work/unasgn
 */
export const postUnasgn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/work/unasgn',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postUnasgn Error:', error);
    });

  return result;
};

/**
 * 작업배정 - 배정 화면 상세
 * GET /api/work/asgn/{dcmnDntfNmbr}
 */
export const getAsgnDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/work/asgn', params.dcmnDntfNmbr].join('/'),
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업 배정 - 작업 등록
 * POST /api/work/nstl/asgn
 */
export const postNstlAsgn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/work/nstl/asgn',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postNstlAsgn Error:', error);
    });

  return result;
};

/**
 * 설치 확인서 엑셀 다운로드
 * GET /api/work/setup/report/excel
 */
export const getSetUpReportExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/work/setup/report/excel',
    responseType: 'blob',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업현황 메모 업데이트
 * PUT /api/work/update/memo
 */
export const putUpdateMemo = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/work/update/memo'].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putUpdateMemo Error:', error);
    });

  return result;
};

/**
 * 작업 배정 - 납품 등록
 * POST /api/work/drvl/asgn
 */
export const postDrvlAsgn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/work/drvl/asgn',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postDrvlAsgn Error:', error);
    });

  return result;
};

/**
 * 내 작업 목록 조회
 * GET /api/work/stt/myworks
 */
export const getSttMyWorks = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/work/stt/myworks',
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 작업 예정 생성
 * POST /api/work/forecast
 */
export const postForecast = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/work/forecast',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postForecast Error:', error);
    });

  return result;
};

/**
 * 작업 예정 수정
 * PUT /api/work/forecast/{frcsId}
 */
export const putForecastFrcsId = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/work/forecast', params.frcsId].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putForecastFrcsId Error:', error);
    });

  return result;
};

/**
 * 작업 예정 삭제
 * DELETE /api/work/forecast/{frcsId}
 */
export const deleteForecastFrcsId = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/work/forecast', params.frcsId].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> deleteForecastFrcsId Error:', error);
    });

  return result;
};

/**
 * 작업 예정 - 적용
 * POST /api/work/forecast/{frcsId}/apply
 */
export const postForecastFrcsIdApply = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: ['/api/work/forecast', params.frcsId, 'apply'].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postForecastFrcsIdApply Error:', error);
    });

  return result;
};
