import React, { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  IApiResult,
  IConfig,
  ILoginUser,
  IToken,
  IWindowMessage,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import * as appUtil from '../../utils/app.util';
import * as cmnApi from '../../apis/cmn.api';
import { configStore } from '../../stores/config.store';
import { notifications } from '@mantine/notifications';
import _ from 'lodash';

interface IGlobalProcessProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 전역 처리
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const GlobalProcess = ({
  data,
  onClick,
}: PropsWithChildren<IGlobalProcessProps>) => {
  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 환경 설정 저장소를 정의함
  const [config, setConfig] = useRecoilState<IConfig>(configStore);

  // 윈도우 메시지를 감시함
  const handleWindowMessage_onReceiveMessage = (event: MessageEvent<any>) => {
    // 윈도우 메시지를 정의함
    let tmpMessage: IWindowMessage = {
      type: null,
      command: '',
      data: '',
      createAt: Date.now(),
    };

    // 사용자 정의 윈도우 메시지만 처리함
    if (event.data.type === undefined) {
      return;
    }

    // 메시지를 처리함
    switch (event.data.type) {
      case 'error':
        switch (event.data.command) {
          case 'ERR_NETWORK':
            notifications.show({
              color: 'red',
              title: event.data.data[0],
              message: event.data.data[1],
              autoClose: 10000,
            });
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    // 로그인한 사용자 토큰을 Local Storage에서 불러옴
    let token: IToken = appUtil.getToken();

    if (!token.accessToken) {
      // 로그인 페이지로 이동함
      // navigate('/login');
      window.location.href = '/login';
    }

    // 사용자 상세 정보를 불러옴
    cmnApi.getUser({ userId: token.userId }).then((data: IApiResult) => {
      // 권한 그룹 상세 정보를 불러옴
      cmnApi
        .getAuthorityGroup({
          thrtGrpId: data.data.thrtGrpId,
        })
        .then((subData: IApiResult) => {
          let tmpLoginUser: ILoginUser = {
            ...loginUser,
            id: data.data.userId,
            name: data.data.name,
            ml: data.data.ml,
            celPhn: data.data.celPhn,
            cmpnCode: data.data.cmpnCode,
            cmpnName: data.data.cmpnName,
            dprtCode: data.data.dprtCode,
            dprtName: data.data.dprtName,
            hdqrCode: data.data.hdqrCode,
            hdqrName: data.data.hdqrName,
            thrtGrpId: data.data.thrtGrpId,
            thrtGrpName: data.data.thrtGrpName,
            thrtDvsnCode: data.data.thrtDvsnCode,
            thrtDvsnName: data.data.thrtDvsnName,
            nstlCtgrCode: data.data.nstlCtgrCode,
            nstlCtgrName: data.data.nstlCtgrName,
            splrTmList: data.data.splrTmList,
            authorityGroup: [],
            useYn: data.data.useYn,
            isPartner: data.data.cmpnCode === 'PRTN' ? true : false,
          };

          // 권한 그룹의 상세 권한을 적용함
          if (subData.code === '200') {
            // tmpLoginUser.authorityGroup = subData.data.list;

            // 사용자가 속해 있는 권한 그룹에 대한 상세 권한을 적용함
            let tmpUserAuthGroup: any = subData.data.list.filter(
              (filterItem: any) =>
                filterItem.thrtGrpId === tmpLoginUser.thrtGrpId,
            );

            tmpLoginUser.authorityGroup = tmpUserAuthGroup;
          }

          // 로그인한 사용자 저장소에 적용함
          setLoginUser(tmpLoginUser);
        });
    });

    // 좌측 메뉴 형태를 불러옴
    (async () => {
      let tmpMenuType: string = (await localStorage.getItem('menuType')) || '';

      switch (tmpMenuType) {
        case 'Y':
          setConfig((pre: IConfig) => ({
            ...pre,
            menuType: true,
          }));
          break;

        default:
          setConfig((pre: IConfig) => ({
            ...pre,
            menuType: false,
          }));
          break;
      }
    })();

    // 윈도우 메시지를 감시함
    window.addEventListener('message', handleWindowMessage_onReceiveMessage);

    return () => {
      // 윈도우 메시지를 해제함
      window.removeEventListener(
        'message',
        handleWindowMessage_onReceiveMessage,
      );
    };
  }, []);

  return <></>;
};

export default GlobalProcess;
