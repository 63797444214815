import React, { PropsWithChildren, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  ITableColumn,
  IOptionItem,
  ISelect,
  IComponentModalPageScrollTabStep,
  IComponentModalPageScrollTab,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import _ from 'lodash';
import { PbTable } from '../PbTable';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import * as sttnApi from '../../apis/sttn.api';
import * as nstlApi from '../../apis/nstl.api';
import { PbInputNumber } from '../PbInputNumber';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  Group,
  NumberInput,
  Select,
  Tabs,
  TextInput,
} from '@mantine/core';
import { Radio } from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import { PbAgGridReact } from '../PbAgGridReact';
import * as appUtil from '../../utils/app.util';
import { ValueFormatterParams } from 'ag-grid-community';
import { decode } from 'html-entities';

interface IComponentProps {
  id: any;
  docEntry?: string;
  data?: any;
  onClick?: () => void;
}

/**
 * 재고 이동 요청 이력 상세
 * @param id 화면아이디
 * @param docEntry 재고이전 문서번호
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220453 = ({
  id = '',
  docEntry = '',
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 프로젝트 정보
   */

  // 프로젝트 정보 > 프로젝트명 입력을 정의함
  const [prjcNameInput, setPrjcNameInput] = useState<string>('');

  // 프로젝트 정보 > 고객사명 입력을 정의함
  const [bpNameInput, setBpNameInput] = useState<string>('');

  // 프로젝트 정보 > Shop명 입력을 정의함
  const [shipCodeInput, setShipCodeInput] = useState<string>('');

  // 주문번호
  const [pDcmnDntfNmbr, setPDcmnDntfNmbr] = useState<string>('');

  /* // 프로젝트 정보 */

  /**
   * 요청 정보
   */

  // 요청 정보 > 협력사명 입력을 정의함
  const [prtnNameInput, setPrtnNameInput] = useState<string>('');

  // 요청 정보 > 요청일시 입력을 정의함
  const [rgstDateInput, setRgstDateInput] = useState<string>('');

  // 요청 정보 > 요청 일괄 취소 여부룰 정의함
  const [nvntCnclYn, setNvntCnclYn] = useState<string>('');

  /* // 요청 정보 */

  /**
   * 품목 정보
   */

  const [itemListTable, setItemListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    tmpTab = [
      {
        label: '프로젝트',
        targetId: 'step-1',
      },
      {
        label: '요청',
        targetId: 'step-2',
      },
      {
        label: '품목',
        targetId: 'step-3',
      },
    ];

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 품목 정보 테이블을 초기화함
  const initItemListTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'stckTypeName',
        headerName: '재고유형',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        field: 'stckSttsName',
        headerName: '재고상태',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        field: 'itemDtls',
        headerName: '품목명',
        width: 350,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.itemDtls);
        },
      },
      {
        field: 'itemNmbr',
        headerName: '품목 번호',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        field: 'qntt',
        headerName: '수량',
        width: 100,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'ttl',
        headerName: '메모',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.ttl);
        },
      },
      {
        field: 'nvntCnclYn',
        headerName: '요청 취소 여부',
        cellRenderer: (params: any) => (
          <>{params.data.nvntCnclYn === 'N' ? '요청 취소' : '-'}</> //
        ),
        width: 130,
        suppressSizeToFit: true,
      },
    );

    // 품목 정보 테이블에 적용함
    setItemListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /* // 품목 정보 */

  /**
   * event
   */

  // 현황 > 재고 이동 요청 상세 > 요청 일괄 취소
  const handleRequestAllCancelButton_onClick = () => {
    if (itemListTable.data.length < 1) {
      setModal({
        title: '알림',
        content: '요청 취소 대상이 없습니다.',
      });
      return;
    }

    // 현황 > 재고 이동 요청 상세 > 요청 일괄 취소 처리
    setModal({
      title: '확인',
      content: '요청한 내용을 모두 취소하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 현황 > 재고 이동 요청 상세 > 요청 일괄 취소
              nstlApi
                .putNstlNvntCncl({
                  nvntDcmnDntfNmbr: docEntry, // 재고이전 요청 문서번호
                })
                .then((data: IApiResult) => {
                  if (data.code === '204') {
                    setModal({
                      title: '알림',
                      content: '재고 이전 요청 취소되었습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220403Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);

                        setTimeout(() => {
                          // 조회 컴포넌트 모달을 추가함
                          let tmpId: string = nanoid();

                          // 컴포넌트 모달을 추가함
                          setAddComponentModal({
                            id: tmpId,
                            title: '재고 이동 요청 상세',
                            content: (
                              <MN2302220453 id={tmpId} docEntry={docEntry} />
                            ),
                            size: 1500,
                          });
                        }, 100);
                      },
                    });
                  } else {
                    console.log(
                      '현황 > 재고 이동 요청 상세 > 요청 일괄 취소 Error:',
                      data,
                    );

                    setModal({
                      title: '알림',
                      content: data.message,
                    });
                  }
                })
                .catch((error: any) => {
                  console.log(
                    '현황 > 재고 이동 요청 상세 > 요청 일괄 취소 Error:',
                    error,
                  );

                  setModal({
                    title: '오류',
                    content: data.message,
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  /* //  */

  /**
   * api
   */

  // 재고 이동 요청 상세 데이터를 불러옴
  const getStockMoveRequestTableData = () => {
    sttnApi
      .getSttnNvntDetail({
        docEntry: docEntry, // 재고이전 문서번호
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 주문번호
          setPDcmnDntfNmbr(data.data.dcmnDntfNmbr);

          // 프로젝트 정보 > 프로젝트명
          setPrjcNameInput(data.data.prjcName);

          // 프로젝트 정보 > 고객사명
          setBpNameInput(data.data.bpName);

          // 프로젝트 정보 > Shop명
          setShipCodeInput(data.data.shipCode);

          // 요청 정보 > 협력사명
          setPrtnNameInput(data.data.prtnName);

          // 요청 정보 > 요청일시
          setRgstDateInput(data.data.rgstDate);

          // 요청 일괄 취소 여부
          setNvntCnclYn(data.data.nvntCnclYn);

          // 검색 결과 테이블에 적용함
          setItemListTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));
        }
      });
  };

  /* //  */

  /**
   * useEffect
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    // 품목 정보 테이블 초기화
    initItemListTable();

    // 재고 이동 요청 상세 데이터를 불러옴
    getStockMoveRequestTableData();

    return () => {};
  }, []);

  // 데이타 불러오기 성공시
  useEffect(() => {
    if (!itemListTable) {
      return;
    }

    return () => {};
  }, [itemListTable]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  /* //  */

  return (
    <div className="space-y-7">
      <div className="space-y-5">
        {/* 프로젝트 정보 폼 그리드 */}
        <PbFormGrid id="step-1" label="프로젝트 정보" cols={2}>
          <PbFormGridCol label="프로젝트명">
            {/* 텍스트 입력 */}
            <TextInput
              value={decode(prjcNameInput)}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="고객사명">
            {/* 텍스트 입력 */}
            <TextInput
              value={decode(bpNameInput)}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="Shop명" colSpan={2}>
            {/* 텍스트 입력 */}
            <TextInput
              value={decode(shipCodeInput)}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 요청 정보 폼 그리드 */}
        <PbFormGrid id="step-2" label="요청 정보" cols={2}>
          <PbFormGridCol label="협력사명">
            <TextInput
              value={decode(prtnNameInput)}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="요청일">
            <TextInput
              value={rgstDateInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 테이블 */}
      <div id="step-3" className="space-y-3">
        <div className="flex justify-between items-center space-x-5">
          <div className="text-base font-bold font-medium-2">품목 정보</div>

          <div className="flex justify-center items-center space-x-5">
            <span className="text-base text-blue-700 font-bold">
              * 요청 품목 내용은 개별 취소 불가하며, 일괄 취소만 가능합니다.
            </span>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              disabled={_.isEqual(nvntCnclYn, 'Y') ? false : true}
              onClick={handleRequestAllCancelButton_onClick}
            >
              요청 일괄 취소
            </Button>
          </div>
        </div>

        {/* 테이블 */}
        <div className="w-full h-92">
          <PbAgGridReact
            columnDefs={itemListTable.column}
            rowData={itemListTable.data}
            defaultColDef={{
              resizable: true,
              sortable: true,
              wrapHeaderText: false,
              autoHeaderHeight: true,
            }}
            rowSelection="single"
            sizeColumnsToFit={true}
          />
        </div>

        {/* 테이블 */}
        {/*<div className="w-full h-92">*/}
        {/*  <AgGridReact*/}
        {/*    columnDefs={itemListTable.column}*/}
        {/*    rowData={itemListTable.data}*/}
        {/*    defaultColDef={{*/}
        {/*      initialWidth: 150,*/}
        {/*      resizable: true,*/}
        {/*      sortable: true,*/}
        {/*      wrapHeaderText: false,*/}
        {/*      autoHeaderHeight: true,*/}
        {/*      suppressSizeToFit: true,*/}
        {/*    }}*/}
        {/*    rowSelection="single"*/}
        {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
        {/*    className="ag-theme-alpine"*/}
        {/*  ></AgGridReact>*/}
        {/*</div>*/}
        {/* 컴포넌트 모달 버튼 */}
        <div className="component-modal-button-area space-x-2">
          <Button
            variant="outline"
            color="gray"
            radius="xl"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          >
            닫기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MN2302220453;
