import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import { useRecoilState } from 'recoil';
import { FileInput, ScrollArea } from '@mantine/core';
import { ILoginUser } from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { DefaultButton } from '../defaultButton';
import api from '../../utils/axios.util';

/**
 * 파일 업로드
 * @param props
 * @constructor
 */
const FileUpload: React.FC<any> = (props: any) => {
  //
  // const queryClient = useQueryClient();

  // 로그인한 사용자 저장소를 정의함
  const [loginClientStore, setLoginClientStore] =
    useRecoilState<ILoginUser>(loginStore);

  // 업로드할 파일을 정의함
  const [fileUploadForm, setFileUploadForm] = useState<any>(null);

  // 업로드된 파일 목록을 정의함
  const [fileList, setFileList] = useState<any>([]);

  // 업로드 진행율을 정의함
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  // 다운로드 진행율을 정의함
  const [downloadProgress, setDownloadProgress] = useState<number>(0);

  // //
  // const { isLoading, isError, data, error } = useQuery(
  //   'uploadedFiles',
  //   getMemberList,
  //   {
  //     refetchOnWindowFocus: false,
  //     retry: 0,
  //     onSuccess: (data) => {
  //       console.log('> onSuccess:', data);
  //     },
  //     onError: (error) => {
  //       console.log('> onError:', error);
  //     },
  //   },
  // );

  // const {
  //   isLoading: isLoadingFileListServerStore,
  //   isError: isErrorFileListServerStore,
  //   data: fileListServerStore,
  //   error: errorFileListServerStore,
  // } = useQuery({
  //   queryKey: ['fileList'],
  //   queryFn: () => getFileList2(),
  //   onSuccess: (data) => {},
  //   onError: (error) => {},
  //   refetchOnWindowFocus: false,
  //   retry: 1,
  // });

  // 파일을 업로드함
  const fileUpload = () => {
    api({
      method: 'POST',
      url: 'https://ems-dev-api.sysnova.kr/api/temp/uploadFile',
      headers: {
        // Authorization: `Bearer ${loginClientStore.accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      data: {
        file: fileUploadForm,
        fileDesc: `파일 업로드 테스트: ${Date.now()}`,
      },
      onUploadProgress: (progressEvent) => {
        let tmpProgress = Math.floor((progressEvent.progress || 0) * 100);
        // 업로드 진행율에 적용함
        setUploadProgress(tmpProgress);

        if (tmpProgress === 100) {
          // 업로드할 파일을 초기화함
          setFileUploadForm(null);

          // 업로드 진행율을 초기화함
          setUploadProgress(0);
        }

        console.log('> fileUpload progress:', progressEvent);
      },
    })
      .then((response: AxiosResponse<any>) => {
        // console.log('> fileUpload response:', response.data);

        // 업로드한 파일 목록을 불러옴
        getFileList();

        // if (response.data !== undefined) {
        //   console.log('> TestLogin:', response.data);
        // } else {
        //   console.log('> TestLogin error');
        // }
      })
      .catch((error) => {
        console.log('> fileUpload error:', error.response);
      })
      .finally(() => {
        // 업로드할 파일을 초기화함
        setFileUploadForm(null);
      });
  };

  // 업로드한 파일 목록을 불러옴
  const getFileList = () => {
    api({
      method: 'GET',
      url: 'https://ems-dev-api.sysnova.kr/api/temp/searchFileList',
      headers: {
        // Authorization: `Bearer ${loginClientStore.accessToken}`,
      },
      params: {},
    })
      .then((response: AxiosResponse<any>) => {
        console.log('> getFileist:', response.data.rows);

        // 업로드된 파일 목록에 적용함
        setFileList(_.orderBy(response.data.rows, ['fileId'], ['desc']));
      })
      .catch((error) => {
        console.log('> getFileist error:', error.response);
      });
  };

  // 임시!
  // 업로드한 파일 목록을 불러옴
  const getFileList2 = async (): Promise<any> => {
    await api({
      method: 'GET',
      url: 'https://ems-dev-api.sysnova.kr/api/temp/searchFileList',
      headers: {
        // Authorization: `Bearer ${loginClientStore.accessToken}`,
      },
      params: {},
    })
      .then((response: AxiosResponse<any>) => {
        return _.orderBy(response.data.rows, ['fileId'], ['desc']) || [];
      })
      .catch((error) => {
        console.log('> getFileist error:', error.response);

        return [];
      });

    return [];
  };

  const handleFileUploadButton_onClick = () => {
    if (fileUploadForm === null) {
      return;
    }

    if (uploadProgress > 0) {
      return;
    }

    // 파일을 업로드함
    fileUpload();
  };

  const handleFileDownloadButton_onClick = (item: any) => {
    if (downloadProgress > 0) {
      return;
    }

    api({
      method: 'GET',
      url: `https://ems-dev-api.sysnova.kr/api/temp/downloadFile/${item.fileId}`,
      responseType: 'blob',
      headers: {
        // Authorization: `Bearer ${loginClientStore.accessToken}`,
      },
      params: {},
      onDownloadProgress: (progressEvent) => {
        let tmpProgress = Math.floor((progressEvent.progress || 0) * 100);
        // 다운로드 진행율에 적용함
        setDownloadProgress(tmpProgress);

        if (tmpProgress === 100) {
          // 다운로드 진행율을 초기화함
          setDownloadProgress(0);
        }
      },
    })
      .then((response: AxiosResponse<any>) => {
        console.log('> handleFileDownloadButton_onClick:', response.data);

        let url: Blob | string = window.URL.createObjectURL(
          new Blob([response.data]),
        );
        let link: HTMLAnchorElement = document.createElement('a');
        link.href = url;
        link.setAttribute('download', item.rgnlFileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          '> handleFileDownloadButton_onClick error:',
          error.response,
        );
      });
  };

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (!loginClientStore.id) {
      return;
    }

    // 업로드한 파일 목록을 불러옴
    getFileList();

    // console.log('>>> step.1');
    // // 임시!
    // queryClient.invalidateQueries({ queryKey: ['fileList'] });
    // console.log('>>> step.2');

    return () => {};
  }, [loginClientStore]);

  return (
    <div className="space-y-3">
      <div className="flex justify-center items-end space-x-2">
        <FileInput
          placeholder="파일을 선택하세요."
          label="File"
          clearable
          withAsterisk
          onChange={setFileUploadForm}
          value={fileUploadForm}
          className="w-72"
        />

        <DefaultButton
          radius="base"
          onClick={handleFileUploadButton_onClick}
          className="h-9"
        >
          업로드
          {uploadProgress > 0 && (
            <div className="ml-1">
              <span className="text-xs font-bold text-white">
                ({uploadProgress}%)
              </span>
            </div>
          )}
        </DefaultButton>
      </div>

      <div className="flex justify-center items-center">
        <div className="relative w-104 flex justify-center items-center py-3 bg-gray-100 rounded">
          <ScrollArea h={250} className="flex justify-center items-center">
            <div className="w-96 space-y-1">
              {fileList.map((item: any) => (
                <div
                  key={item.fileId}
                  onClick={() => handleFileDownloadButton_onClick(item)}
                  className="button-event w-full px-4 py-2 flex justify-start items-center bg-white rounded-md select-none"
                >
                  <span className="text-sm font-semibold truncate">
                    {item.fileId}: {item.rgnlFileName}
                  </span>
                </div>
              ))}
            </div>
          </ScrollArea>

          {/* 다운로드 진행율 */}
          <div className="absolute right-0 translate-x-14">
            <span className="text-sm font-bold">
              {downloadProgress > 0 && `${downloadProgress}%`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
