import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { removeComponentModalStore } from '../../stores/componentModal.store';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import PbSelect from '../PbSelect/PbSelect.component';
import { Button, TextInput } from '@mantine/core';
import { phoneNumberformatter } from '../../utils/app.util';
import { IModal } from '../../interfaces/app.interface';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { notifications } from '@mantine/notifications';

interface IComponentProps {
  id: string;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * 영업 관리 > 영업 기회 조회 > 등록, 조회, 수정 > 담당자(내부) 편집
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220202_ManagerClient = ({
  id,
  data,
  onClick,
  callback,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 이름 > 입력을 정의함
  const [nameInput, setNameInput] = useState<string>('');

  // 직급 > 입력을 정의함
  const [positionInput, setPositionInput] = useState<string>('');

  // 부서 > 입력을 정의함
  const [departmentInput, setDepartmentInput] = useState<string>('');

  // 전화번호 > 입력을 정의함
  const [phoneInput, setPhoneInput] = useState<string>('');

  // 이메일주소 > 입력을 정의함
  const [emailInput, setEmailInput] = useState<string>('');

  // 이름 > 입력의 값을 변경함
  const handleNameInput_onChange = (event: any) => {
    setNameInput(event.target.value);
  };

  // 직급 > 입력의 값을 변경함
  const handlePositionInput_onChange = (event: any) => {
    setPositionInput(event.target.value);
  };

  // 부서 > 입력의 값을 변경함
  const handleDepartmentInput_onChange = (event: any) => {
    setDepartmentInput(event.target.value);
  };

  // 전화번호 > 입력의 값을 변경함
  const handlePhoneInput_onChange = (event: any) => {
    setPhoneInput(phoneNumberformatter(event.target.value));
  };

  // 이메일주소 > 입력의 값을 변경함
  const handleEmailInput_onChange = (event: any) => {
    setEmailInput(event.target.value);
  };

  // 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    if (callback === undefined) {
      return;
    }

    // 이름 > 입력을 확인함
    if (!nameInput.trim()) {
      setModal({
        title: '알림',
        content: '담당자(고객사) 이름을 입력해 주세요.',
      });

      return;
    }

    let tmpData: any = {
      cstmMngrName: nameInput.trim(),
      cstmMngrPstn: positionInput.trim(),
      cstmMngrDprt: departmentInput.trim(),
      cstmMngrClph: phoneInput.trim(),
      cstmMngrMl: emailInput.trim(),
    };

    callback(tmpData);

    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);
  };

  useEffect(() => {
    // 수정일 때는 불러온 데이터를 입력 항목에 적용함
    if (data !== undefined) {
      setNameInput(data.cstmMngrName);
      setPositionInput(data.cstmMngrPstn);
      setDepartmentInput(data.cstmMngrDprt);
      setPhoneInput(data.cstmMngrClph);
      setEmailInput(data.cstmMngrMl);
    }

    return () => {};
  }, []);

  return (
    <div className="space-y-7">
      <PbFormGrid cols={2}>
        <PbFormGridCol label="이름" withAsterisk={true}>
          {/* 텍스트 입력 */}
          <TextInput
            placeholder="이름을 입력하세요."
            onChange={handleNameInput_onChange}
            value={nameInput}
            className="w-full"
          />
        </PbFormGridCol>
        <PbFormGridCol label="직급">
          {/* 텍스트 입력 */}
          <TextInput
            placeholder="직급을 입력하세요."
            onChange={handlePositionInput_onChange}
            value={positionInput}
            className="w-full"
          />
        </PbFormGridCol>
        <PbFormGridCol label="부서">
          {/* 텍스트 입력 */}
          <TextInput
            placeholder="부서를 입력하세요."
            onChange={handleDepartmentInput_onChange}
            value={departmentInput}
            className="w-full"
          />
        </PbFormGridCol>
        <PbFormGridCol label="전화번호">
          {/* 텍스트 입력 */}
          <TextInput
            placeholder="전화번호를 입력하세요."
            onChange={handlePhoneInput_onChange}
            value={phoneNumberformatter(phoneInput)}
            className="w-full"
          />
        </PbFormGridCol>
        <PbFormGridCol label="이메일주소" colSpan={2}>
          {/* 텍스트 입력 */}
          <TextInput
            placeholder="이메일주소를 입력하세요."
            onChange={handleEmailInput_onChange}
            value={emailInput}
            className="w-full"
          />
        </PbFormGridCol>
      </PbFormGrid>

      {/* 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 버튼 */}
        <Button color="indigo" radius="xl" onClick={handleSaveButton_onClick}>
          <span>저장</span>
        </Button>
      </div>
    </div>
  );
};

export default MN2302220202_ManagerClient;
