import React, { PropsWithChildren } from 'react';
import { nanoid } from 'nanoid';

interface IComponentProps {
  id?: string;
  label?: string;
  rightForm?: JSX.Element;
  onClick?: () => void;
  className?: string;
}

/**
 * 섹션
 * @param label 폼 그리드 레이블
 * @param rightForm 레이블 오른쪽 폼 영역
 * @param onClick <이벤트>
 * @param className 클래스 명
 * @param children 기타 props
 * @constructor
 */
const PbSection = ({
  id = nanoid(),
  label = '',
  rightForm = <></>,
  onClick,
  className = '',
  children,
}: PropsWithChildren<IComponentProps>) => {
  return (
    <div id={id} className={[className].join(' ')}>
      <div className="content-wrapper">
        {/* title-wrapper */}
        {label && (
          <div className="title-wrapper">
            {/*<h3 className="title">{label}</h3>*/}
            <h3 className="leading-none">
              <span className="text-lg text-gray-600 font-semibold">
                {label}
              </span>
            </h3>
            <div className="right-btn !space-x-2">{rightForm}</div>
          </div>
        )}

        <div>{children}</div>
      </div>
    </div>
  );
};

export default PbSection;
