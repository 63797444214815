import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { Button, Select, TextInput, Checkbox, Radio } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { MN2302220354 } from '../components/MN2302220354'; //=상세 Component
import * as nstlApi from '../apis/nstl.api';
import { PbAgGridReact } from '../components/PbAgGridReact';
import PbSelect from '../components/PbSelect/PbSelect.component';

/**
 * 설치 관리 > 완료작업 빌링 현황
 * @constructor
 */
const MN2302220304Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);
  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);
  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);
  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);
  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**************************** API 호출 ****************************/
  //빌링 목록 조회
  const getBillingList = () => {
    let startDate = '';
    let endDate = '';
    startDate = workDatePicker[0]
      ? moment(workDatePicker[0]).format('YYYYMMDD')
      : '';
    endDate = workDatePicker[1]
      ? moment(workDatePicker[1]).format('YYYYMMDD')
      : '';

    // 빌링 현황 테이블의 로딩 여부에 적용함
    setBillingTableLoading(true);

    nstlApi
      .getNstlBilling({
        searchType: billingSearchSelect.value,
        searchKeyword: billingSearchInput,
        billYn: billYnSelect.value,
        stDate: startDate,
        endDate: endDate,
        pageSize: billingTablePaging.rowPerPage,
        currPageIdx: billingTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setBillingTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));

          // 테이블을 페이징함
          setBillingTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }

        // 빌링 현황 테이블의 로딩 여부에 적용함
        setBillingTableLoading(false);
      });
  };

  /**************************** STATE ****************************/
  //빌링 검색 조건 셀렉트
  const [billingSearchSelect, setBillingSearchSelect] = useState<ISelect>({
    value: '',
    item: [],
  });
  //빌링 검색어
  const [billingSearchInput, setBillingSearchInput] = useState<string>('');
  //검수 완료 기간
  const [workDatePicker, setWorkDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);
  //빌 발행 여부
  const [billYnSelect, setBillYnSelect] = useState<ISelect>({
    value: '',
    item: [],
  });
  //빌링 현황 테이블 Ref
  const billingTableRef = useRef(null);
  //빌링 현황 테이블 state
  const [billingTable, setBillingTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  // 빌링 현황 테이블 페이징을 정의함
  const [billingTablePaging, setBillingTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const billingTablePagingCurrentPageRef = useRef<number>(1);

  // 빌링 현황 테이블의 로딩 여부를 정의함
  const [billingTableLoading, setBillingTableLoading] =
    useState<boolean>(false);

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  /**************************** ON EVENT LISTENER ****************************/
  //빌링 검색 셀렉트 변경 이벤트
  const handleBillingSearchSelect_onChange = (event: any) => {
    setBillingSearchSelect({
      ...billingSearchSelect,
      value: event,
    });
  };

  //빌링 검색어 이벤트
  const handleBillingSearchInput_onChange = (event: any) => {
    setBillingSearchInput(event.target.value);
  };

  // 검수 완료 기간 데이트피커 변경 이벤트
  const handleWorkDatePicker_onChange = (event: any) => {
    setWorkDatePicker(event);
  };

  //계산서 발행 셀렉트 변경 이벤트
  const handleBillYnSelect_onChange = (event: any) => {
    setBillYnSelect({
      ...billYnSelect,
      value: event,
    });
  };

  //제목 검색 on keyup
  const handleBillingSearchInput__onEnterKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      billingTablePagingCurrentPageRef.current = 1;
      setBillingTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: billingTablePagingCurrentPageRef.current,
      }));

      getBillingList();
    }
  };

  //제목 검색 버튼 이벤트
  const handleBillingSearchBtn_onClick = () => {
    // 테이블의 페이지를 변경함
    billingTablePagingCurrentPageRef.current = 1;
    setBillingTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: billingTablePagingCurrentPageRef.current,
    }));

    getBillingList();
  };

  //검색 조건 초기화 버튼 이벤트
  const handleCancelBtn_onClick = () => {
    setBillingSearchInput('');
    setBillingSearchSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));
    setWorkDatePicker((pre: [Date | null, Date | null]) => ({
      ...pre,
    }));
    setBillYnSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));
  };

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    setHideDetailSearch(!hideDetailSearch);
  };

  //메뉴얼 목록 클릭 (ROW Click)
  const handleBillingTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220314';

    //컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '완료작업 빌링 현황 상세',
      content: <MN2302220354 id={tmpId} dcmnDntfNmbr={row.data.dcmnDntfNmbr} />,
      size: 1500,
    });
  };

  /**************************** INIT ****************************/
  //빌링 검색 셀렉트 초기화
  const initBillingSearchSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];
    tmpOptionItem.push(
      {
        label: '프로젝트 명',
        value: 'prjc_name',
      },
      {
        label: 'Shop 명',
        value: 'ship_to_code',
      },
      {
        label: '협력사 명',
        value: 'sls_prtn_name',
      },
    );

    // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
    setBillingSearchSelect({
      value: 'prjc_name',
      item: tmpOptionItem,
    });
  };

  //계산서 발행 여부 셀렉트 초기화
  const initBillYnSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];
    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '발행',
        value: 'Y',
      },
      {
        label: '미발행',
        value: 'N',
      },
    );

    // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
    setBillYnSelect({
      value: '',
      item: tmpOptionItem,
    });
  };

  //빌링 현황 테이블 초기화
  const initBillingTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      // {
      //   field: 'no',
      //   headerName: '순번',
      //   width: 80,
      //   suppressSizeToFit: true,
      // },
      {
        field: 'prjcName',
        headerName: '프로젝트 명',
      },
      {
        headerName: '계약명',
        field: 'cntrName',
      },
      // {
      //   field: 'cntrNmbr',
      //   headerName: '판매 계약 번호',
      //   width: 120,
      //   suppressSizeToFit: true,
      // },
      // {
      //   field: 'dcmnDntfNmbr',
      //   headerName: '주문 번호(비화면)',
      //   hide: true,
      //   width: 120,
      //   suppressSizeToFit: true,
      // },
      // {
      //   field: 'dcmnScrnNmbr',
      //   headerName: '주문 번호',
      //   width: 100,
      //   suppressSizeToFit: true,
      // },
      {
        field: 'shipToCode',
        headerName: 'Shop 명',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        field: 'workSchedule',
        headerName: '전체 작업 기간',
        valueFormatter: (params: any) => {
          return `${params.data.workStrtDttm} ~ ${params.data.workEndDttm}`;
        },
        width: 290,
        suppressSizeToFit: true,
      },
      {
        field: 'workDate',
        headerName: 'PM 검수 완료일',
        width: 130,
        suppressSizeToFit: true,
      },
      // {
      //   field: 'dlvrNmbr',
      //   headerName: '납품 번호',
      //   valueFormatter: (params: any) =>
      //     params.value === '' ? '-' : params.value,
      //   width: 100,
      //   suppressSizeToFit: true,
      // },
      // {
      //   field: 'rnvcNmbr',
      //   headerName: 'AR 송장 번호',
      //   valueFormatter: (params: any) =>
      //     params.value === '' ? '-' : params.value,
      //   width: 120,
      //   suppressSizeToFit: true,
      // },
      {
        field: 'billDate',
        headerName: '계산서 발행일',
        valueFormatter: (params: any) =>
          params.value === '' ? '-' : params.value,
        width: 120,
        suppressSizeToFit: true,
      },
    );
    setBillingTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    initBillingSearchSelect(); //빌링 검색 셀렉트 초기화
    initBillYnSelect(); //계산서 발행 여부 셀렉트 초기화
    initBillingTable(); //빌링 현황 테이블 초기화
    return () => {};
  }, []);

  // 빌링현황 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (billingTable.column.length === 0 || billingTable === null) {
      return;
    }
    getBillingList();
    return () => {};
  }, [billingTable.column]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }
    if (_.indexOf(refreshList, 'MN2302220304Table') > -1) {
      // 테이블의 페이지를 변경함
      billingTablePagingCurrentPageRef.current = 1;
      setBillingTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: billingTablePagingCurrentPageRef.current,
      }));

      // 빌링현황 테이블 데이터를 불러옴
      getBillingList();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220304Table');
    }

    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        {/* 캘린더
        <div>
          <PbCalendar />
        </div> */}

        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid label="" cols={2}>
            <PbFormGridCol label="검색어" colSpan={2}>
              <PbSelect
                onChange={handleBillingSearchSelect_onChange}
                data={billingSearchSelect.item}
                value={billingSearchSelect.value}
                setSelect={setBillingSearchSelect}
                style={{ minWidth: '10rem' }}
              />
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={handleBillingSearchInput_onChange}
                onKeyUp={handleBillingSearchInput__onEnterKeyPress}
                value={billingSearchInput}
                style={{ minWidth: '10rem' }}
                className="w-full"
              />
              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleBillingSearchBtn_onClick}
                >
                  검색
                </Button>
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleCancelBtn_onClick}
                >
                  검색 조건 초기화
                </Button>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleShowSearchDetailButton_onChange}
                >
                  {hideDetailSearch ? (
                    <>
                      상세검색 열기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-down']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      상세검색 닫기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-up']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  )}
                </Button>
              </div>
            </PbFormGridCol>
            <PbFormGridCol label="검수 완료 기간" isHidden={hideDetailSearch}>
              <DatePickerInput
                type="range"
                placeholder="시작 일자 - 종료 일자"
                onChange={handleWorkDatePicker_onChange}
                value={workDatePicker}
                valueFormat="YYYY.MM.DD"
                locale="ko"
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="계산서 발행 여부" isHidden={hideDetailSearch}>
              <PbSelect
                onChange={handleBillYnSelect_onChange}
                data={billYnSelect.item}
                value={billYnSelect.value}
                setSelect={setBillYnSelect}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 테이블 */}
          <div className="space-y-3">
            <div className="flex justify-end items-center space-x-5">
              <div className="flex justify-center items-center">
                <span className="text-base text-gray-600 font-bold">
                  총 {billingTablePaging.totalRow}건
                </span>
              </div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-152">
              <PbAgGridReact
                columnDefs={billingTable.column}
                rowData={billingTable.data}
                loading={billingTableLoading}
                setLoading={setBillingTableLoading}
                onRowDoubleClicked={handleBillingTable_onRowDoubleClicked}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
                visiblePaging={true}
                paging={{
                  totalPage: billingTablePaging.totalPage,
                  currentPage: billingTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  billingTablePagingCurrentPageRef.current = event;
                  setBillingTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: billingTablePagingCurrentPageRef.current,
                  }));

                  // 테이블 데이터를 불러옴
                  getBillingList();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220304Page;
