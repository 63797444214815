import React, { useEffect } from 'react';
import { IToken } from '../interfaces/app.interface';
import * as appUtil from '../utils/app.util';

/**
 * 메인
 * @constructor
 */
const MainPage = () => {
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 로그인한 사용자 토큰을 Local Storage에서 불러옴
    let token: IToken = appUtil.getToken();

    if (!token.accessToken) {
      // 로그인 페이지로 이동함
      window.location.href = '/login';
    } else {
      // 대시보드 페이지로 이동함
      window.location.href = '/dashboard';
    }

    return () => {};
  }, []);

  return <></>;
};

export default MainPage;
