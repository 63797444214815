import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { PbAgGridReact } from '../PbAgGridReact';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
  IPageContent,
  IImageCarousel,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as prtnApi from '../../apis/prtn.api';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import moment from 'moment/moment';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  Button,
  Select,
  TextInput,
  Radio,
  Group,
  Textarea,
} from '@mantine/core';
import MN2302220352_GridModify from '../MN2302220352_GridModify/MN2302220352_GridModify.component';
import PbSection from '../PbSection/PbSection.component';
import * as appUtil from '../../utils/app.util';
import prettyBytes from 'pretty-bytes';
import * as slsApi from '../../apis/sls.api';
import { imageCarouselStore } from '../../stores/imageCarousel.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PbSelect from '../PbSelect/PbSelect.component';
import { ValueFormatterParams } from 'ag-grid-community';

interface IComponentProps {
  id?: string;
  type?: string;
  splrId?: string;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * 협력사 평가 등록 / 수정
 * @param id 화면 아이디
 * @param type 등록/수정 여부
 * @param splrId 협력사 아이디
 * @param data 평가 정보
 * @param onClick <이벤트>
 * @param callback modal 수정정보 반영을 위한 이벤트
 * @constructor
 */
const MN2302220631_PrtnVltn = ({
  id = '',
  type = '',
  splrId = '',
  data,
  onClick,
  callback,
}: PropsWithChildren<IComponentProps>) => {
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 평가 정보 변수
   */
  // 평가 내용
  const [dtlInput, setDtlInput] = useState<string>('');

  // 평가자
  const [rgstName, setRgstName] = useState<string>('');

  // 평가일
  const [rgstDate, setRgstDate] = useState<string>('');

  /* 사용 여부 */
  // 사용 여부 라디오를 정의함
  const [useYnRadio, setUseYnRadio] = useState<string>('Y');

  // 사용 여부 라디오에서 선택한 아이템을 변경함
  const handleUseYnRadio_onChange = (event: any) => {
    setUseYnRadio(event);
  };

  /* // 평가 정보 변수 */

  /**
   * Event / Func / Api
   */

  // 입력값 byte 확인
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  // 내용 변경 event
  const handleDtlInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 3000) {
      // 3000byte 제한
      setDtlInput((pre) => pre);
    } else {
      setDtlInput(sVal);
    }
  };

  //협력사 평가 등록 API
  const savePrtnVltnInfo = () => {
    prtnApi
      .postPrtnVltn({
        splrId: splrId,
        dtl: dtlInput.trim(),
      })
      .then((result: IApiResult) => {
        if (result.code === '200') {
          setModal({
            title: '알림',
            content: '저장하였습니다.',
            callback: () => {
              // 목록 새로고침을 추가함
              setAddRefreshList('MN2302220631Table');

              // 컴포넌트 모달을 닫음
              setRemoveComponentModal(id);
            },
          });
        } else {
          console.log('> 협력사 평가 등록 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>등록에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  //협력사 평가 삭제 API
  const deletePrtnVltn = () => {
    prtnApi
      .deletePrtnVltn({
        splrId: splrId,
        vltnId: data.vltnid,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          setModal({
            title: '알림',
            content: '삭제하였습니다.',
            callback: () => {
              // 컴포넌트 모달을 닫음
              setRemoveComponentModal(id);
            },
          });
        } else {
          console.log('> 협력사 평가 삭제 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>삭제에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  // 저장 버튼(수정)을 클릭함
  const handleModifySaveBtn_onClick = () => {
    if (_.isEmpty(dtlInput)) {
      setModal({
        title: '알림',
        content: '내용을 입력하세요.',
      });
      return;
    }

    // Shop별 설치 일정 계획 수정 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              if (_.isEqual(type, 'REQ')) {
                // 협력사 저장
                savePrtnVltnInfo();
              } else if (_.isEqual(type, 'MOD')) {
                // 협력사 수정
                saveModifyPrtnVltnInfo();
              }
              if (callback !== undefined) {
                callback({});
              }
            }}
          />
        </div>
      ),
    });
  };

  //협력사 수정 API
  const saveModifyPrtnVltnInfo = () => {
    prtnApi
      .putPrtnVltn({
        splrId: splrId,
        vltnId: data.vltnId,
        dtl: dtlInput.trim(),
      })
      .then((retult: IApiResult) => {
        if (retult.code === '200') {
          setModal({
            title: '알림',
            content: '저장하였습니다.',
            callback: () => {
              // 컴포넌트 모달을 닫음
              setRemoveComponentModal(id);
            },
          });
        } else {
          console.log('> 협력사 평가 수정 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>수정에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      })
      .catch((error: any) => {
        console.log('> 협력사 평가 수정 Error:', error);

        setModal({
          title: '오류',
          content: error,
        });
      });
  };

  /* // Event / Func / Api */

  /**
   * useEffect
   */

  // 사용자 정보 로딩 후 실행
  useEffect(() => {
    if (!loginUser.id) {
      return;
    }

    if (_.isEqual(type, 'REQ')) {
      setDtlInput(''); // 평가 내용
      setRgstName(_.toString(loginUser.name)); // 평가자
      setRgstDate(moment(new Date()).format('YYYY.MM.DD hh:mm')); // 평가일
    } else if (_.isEqual(type, 'MOD')) {
      setDtlInput(data.dtl); // 평가 내용
      setRgstName(data.rgstName); // 평가자
      setRgstDate(moment(data.rgstDate).format('YYYY-MM-DD HH:mm')); // 평가일
    }
  }, [loginUser.id]);

  // 화면 로딩 후 한번만 실행
  useEffect(() => {}, []);

  /* // useEffect */

  return (
    <div className="space-y-7">
      <div className="space-y-5">
        <PbFormGrid label="협력사 평가 정보" cols={2}>
          <PbFormGridCol label="평가 내용" colSpan={2}>
            {/* 텍스트 영역 */}
            <Textarea
              placeholder="평가를 입력하세요."
              onChange={handleDtlInput_onChange}
              value={dtlInput}
              minRows={10}
              readOnly={_.isEqual(type, 'REQ') ? false : true}
              variant={_.isEqual(type, 'REQ') ? 'default' : 'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="평가자" isHidden={_.isEqual(type, 'REQ')}>
            <TextInput
              value={rgstName}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>
          <PbFormGridCol label="평가일" isHidden={_.isEqual(type, 'REQ')}>
            <TextInput
              value={rgstDate}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>

          <PbFormGridCol label="사용 여부" isHidden={true}>
            <Radio.Group
              name="radioGroup1"
              onChange={handleUseYnRadio_onChange}
              value={useYnRadio}
              size="sm"
            >
              <Group mt="xs">
                <Radio value="Y" label="사용" />
                <Radio value="N" label="미사용" />
              </Group>
            </Radio.Group>
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        <div>
          <Button
            variant="outline"
            color="gray"
            radius="xl"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          >
            닫기
          </Button>
        </div>
        {_.isEqual(type, 'REQ') && (
          <div>
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleModifySaveBtn_onClick}
            >
              저장
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MN2302220631_PrtnVltn;
