import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  Checkbox,
  Group,
  Radio,
  Tabs,
} from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PageLayout } from '../../components/PageLayout';
import { PbFormGrid } from '../../components/PbFormGrid';
import { PbFormGridCol } from '../../components/PbFormGridCol';
import PbSection from '../PbSection/PbSection.component';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../../stores/refreshList.store';
import * as prtnApi from '../../apis/prtn.api';
import { PbAgGridReact } from '../PbAgGridReact';

interface IMN2302220641Props {
  id?: string;
  splrId?: string;
  data?: any;
  onClick?: () => void;
}

/**
 * 협력사관리 > 협력사 상세 > 작업 목록
 * @param data <인자>
 * @param splrId <협력사 ID>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220641 = ({
  id = '',
  splrId = '',
  data,
  onClick,
}: PropsWithChildren<IMN2302220641Props>) => {
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  /**************************** API 호출 ****************************/
  const getWorkStateList = (workType: string, setTable: Function) => {
    prtnApi
      .getPrtnWorkList({
        splrId: splrId,
        searchType: workType,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));
        }
      });
  };

  /**************************** STATE ****************************/
  //작업 상태 유형 탭 state
  const [workTypeTab, setWorkTypeTab] = useState<string>('full');

  //작업 현황 테이블
  //작업유형 : 전체
  const [fullTable, setFullTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  //작업유형 : 진행 중
  const [prcdTable, setPrcdTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  //작업유형 : 작업완료
  const [workCmplTable, setWorkCmplTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  //작업유형 : 검수완료
  const [nspcCmplTable, setNspcCmplTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  //작업유형 : PM 검수완료
  const [pmNspcCmplTable, setPmNspcCmplTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  //작업유형 : 지연
  const [dlyTable, setDlyTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  /**************************** ON EVENT LISTENER ****************************/
  const handleWorkTypeTab_onChange = (event: any) => {
    setWorkTypeTab(event);
  };

  /**************************** INIT ****************************/
  //작업 현황 테이블 초기화
  const initWorkStateTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      {
        field: 'prjcName',
        headerName: '프로젝트 명',
      },
      {
        field: 'bpName',
        headerName: '고객사',
      },
      {
        field: 'shipToCode',
        headerName: 'Shop 명',
      },
      {
        field: 'splrTypeName',
        headerName: '유형',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        field: 'nstlSttsName',
        headerName: '진행 상태',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        field: 'workDttm',
        headerName: '작업 기간',
        valueFormatter: (params: any) => {
          return `${params.data.workStrtDttm} ~ ${params.data.workEndDttm}`;
        },
        width: 300,
        suppressSizeToFit: true,
      },
    );

    setFullTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
    setPrcdTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
    setWorkCmplTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
    setNspcCmplTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
    setPmNspcCmplTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
    setDlyTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    initWorkStateTable(); //작업 현황 테이블 초기화 (컬럼)
    return () => {};
  }, []);
  // 작업현황 목록 테이블 값 초기화
  useEffect(() => {
    if (dlyTable.column.length === 0) {
      return;
    }
    getWorkStateList('', setFullTable);
    getWorkStateList('prcd', setPrcdTable);
    getWorkStateList('work_cmpl', setWorkCmplTable);
    getWorkStateList('nspc_cmpl', setNspcCmplTable);
    getWorkStateList('pm_nspc_cmpl', setPmNspcCmplTable);
    getWorkStateList('dly', setDlyTable);

    return () => {};
  }, [dlyTable.column]);

  return (
    <div className="space-y-7">
      <div className="space-y-5">
        <PbSection label="작업 상태">
          {/* 탭 */}
          <Tabs
            variant="outline"
            value={workTypeTab}
            onTabChange={handleWorkTypeTab_onChange}
          >
            <Tabs.List>
              <Tabs.Tab value="full">
                <span className="text-lg">전체 Shop ({data.fulWork})</span>
              </Tabs.Tab>
              <Tabs.Tab value="prcd">
                <span className="text-lg">진행 중 ({data.prcd})</span>
              </Tabs.Tab>
              <Tabs.Tab value="work_cmpl">
                <span className="text-lg">작업완료 ({data.workCmpl})</span>
              </Tabs.Tab>
              <Tabs.Tab value="nspc_cmpl">
                <span className="text-lg">검수완료 ({data.nspcCmpl})</span>
              </Tabs.Tab>
              <Tabs.Tab value="pm_nspc_cmpl">
                <span className="text-lg">PM검수완료 ({data.pmNspcCmpl})</span>
              </Tabs.Tab>
              <Tabs.Tab value="dly">
                <span className="text-lg">지연 ({data.dly})</span>
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="full" pt="xl">
              {/* 전체 Shop 테이블 */}
              <div className="w-full h-152">
                <PbAgGridReact
                  columnDefs={fullTable.column}
                  rowData={fullTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="prcd" pt="xl">
              {/* 진행 중 테이블 */}
              <div className="w-full h-152">
                <PbAgGridReact
                  columnDefs={prcdTable.column}
                  rowData={prcdTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="work_cmpl" pt="xl">
              {/* 작업 완료 테이블 */}
              <div className="w-full h-152">
                <PbAgGridReact
                  columnDefs={workCmplTable.column}
                  rowData={workCmplTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="nspc_cmpl" pt="xl">
              {/* 검수완료 테이블 */}
              <div className="w-full h-152">
                <PbAgGridReact
                  columnDefs={nspcCmplTable.column}
                  rowData={nspcCmplTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="pm_nspc_cmpl" pt="xl">
              {/* PM검수완료 테이블 */}
              <div className="w-full h-152">
                <PbAgGridReact
                  columnDefs={pmNspcCmplTable.column}
                  rowData={pmNspcCmplTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="dly" pt="xl">
              {/* 지연 테이블 */}
              <div className="w-full h-152">
                <PbAgGridReact
                  columnDefs={dlyTable.column}
                  rowData={dlyTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>
            </Tabs.Panel>
          </Tabs>
        </PbSection>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        <div>
          <Button
            variant="outline"
            color="gray"
            radius="xl"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          >
            닫기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MN2302220641;
