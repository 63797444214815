import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  Checkbox,
  Radio,
  Tabs,
} from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import PbSection from '../components/PbSection/PbSection.component';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import TreeView, {
  flattenTree,
  INode,
  NodeId,
  ITreeViewOnNodeSelectProps,
  ITreeViewOnSelectProps,
} from 'react-accessible-treeview';
import * as cmnApi from '../apis/cmn.api';
import { MN2302220811 } from '../components/MN2302220811'; //등록,상세,수정 Component

/**
 * 시스템 관리 > 메뉴 관리
 * @constructor
 */
const MN2302220801Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);
  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);
  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);
  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);
  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /******************************** API 호출 ********************************/
  //메뉴 목록 조회
  const getMenuList = (menuCategoryTab: string, setTree: Function) => {
    cmnApi
      .getSystemMenus({
        menuDvsnCode: menuCategoryTab,
        useYn: 'Y',
        pageSize: 2000,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          var useYMenuList = data.data.list;
          cmnApi
            .getSystemMenus({
              menuDvsnCode: menuCategoryTab,
              useYn: 'N',
              pageSize: 2000,
            })
            .then((data: IApiResult) => {
              if (data.data.list !== undefined) {
                var dvsnCode = menuCategoryTab;
                var treeData: any[] = [];
                var useNMenuList = data.data.list;
                var result = [...useYMenuList, ...useNMenuList];

                for (let menu of result) {
                  // HOME 메뉴 미출력 - 메뉴 정보 변경시 소스도 변경되어야 함
                  if (_.isEqual(menu.oneMenuId, 'MN2302220100')) {
                    // HOME 이면 로직 통과
                  } else {
                    // Level : 1 을 발견하면 처리
                    if (menu.twoMenuId === '') {
                      var oneMenu: any = {
                        id: menu.oneMenuId,
                        name: menu.oneKrnMenu,
                        oneSortRdr: menu.oneSortRdr,
                        level: 1,
                        children: [],
                      };
                      //Level 2 목록 생성
                      var oneChildren = result.filter(
                        (item: any, pos: any) =>
                          item.oneMenuId === menu.oneMenuId &&
                          item.twoMenuId !== '',
                      );
                      for (let oneChild of oneChildren) {
                        // Level : 2 을 발견하면 처리
                        if (oneChild.threeMenuId === '') {
                          var twoMenu: any = {
                            id: oneChild.twoMenuId,
                            name: oneChild.twoKrnMenu,
                            twoSortRdr: oneChild.twoSortRdr,
                            level: 2,
                            children: [],
                          };
                          //Level 3 목록 생성
                          var twoChildren = oneChildren.filter(
                            (item: any, pos: any) =>
                              item.twoMenuId === oneChild.twoMenuId &&
                              item.threeMenuId !== '',
                          );
                          for (let twoChild of twoChildren) {
                            //Level : 3 을 발견하면 처리
                            if (twoChild.fourMenuId === '') {
                              var threeMenu: any = {
                                id: twoChild.threeMenuId,
                                name: twoChild.threeKrnMenu,
                                threeSortRdr: twoChild.threeSortRdr,
                                level: 3,
                                children: [],
                              };
                              //Level 4 목록 생성
                              var threeChildren = twoChildren.filter(
                                (item: any, pos: any) =>
                                  item.threeMenuId === twoChild.threeMenuId &&
                                  item.fourMenuId !== '',
                              );
                              for (let threeChild of threeChildren) {
                                var fourMenu: any = {
                                  id: threeChild.fourMenuId,
                                  name: threeChild.fourKrnMenu,
                                  fourSortRdr: threeChild.fourSortRdr,
                                  level: 4,
                                };
                                threeMenu.children.push(fourMenu);
                              }
                              threeMenu.children = threeMenu.children.sort(
                                (a: any, b: any) =>
                                  Number(a.fourSortRdr - b.fourSortRdr),
                              );
                              twoMenu.children.push(threeMenu);
                            }
                          }
                          twoMenu.children = twoMenu.children.sort(
                            (a: any, b: any) =>
                              Number(a.threeSortRdr - b.threeSortRdr),
                          );
                          oneMenu.children.push(twoMenu);
                        }
                      }
                      oneMenu.children = oneMenu.children.sort(
                        (a: any, b: any) => Number(a.twoSortRdr - b.twoSortRdr),
                      );
                      treeData.push(oneMenu);
                    }
                  }
                }

                //ROOT
                var rootMenu: any = {
                  id: 'ROOT_' + dvsnCode,
                  name: '/',
                  children: treeData.sort((a: any, b: any) =>
                    Number(a.oneSortRdr - b.oneSortRdr),
                  ),
                };

                //Tree Data
                var tmpTreeData: any = {
                  id: '',
                  name: '',
                  children: [rootMenu],
                };

                // 트리 데이터에 적용함
                setTree(tmpTreeData);
              }
            });
        }
      });
  };

  /******************************** STATE ********************************/
  //메뉴 유형 선택 탭
  const [menuCategoryTab, setMenuCategoryTab] = useState<string>('WEB');

  //WEB Menu 트리 데이터를 정의함
  const [webMenuTreeData, setWebMenuTreeData] = useState<any>(null);
  //WEB Menu  트리 노드를 정의함
  const [webMenuTreeNode, setWebMenuTreeNode] = useState<INode[]>([]);
  //WEB Menu  선택한 트리 노드를 정의함
  const [selectedWebMenuTreeNode, setSelectedWebMenuTreeNode] =
    useState<ITreeViewOnNodeSelectProps | null>(null);

  //MBL Menu 트리 데이터를 정의함
  const [mblMenuTreeData, setMblMenuTreeData] = useState<any>(null);
  //MBL Menu  트리 노드를 정의함
  const [mblMenuTreeNode, setMblMenuTreeNode] = useState<INode[]>([]);
  //MBL Menu  선택한 트리 노드를 정의함
  const [selectedMblMenuTreeNode, setSelectedMblMenuTreeNode] =
    useState<ITreeViewOnNodeSelectProps | null>(null);

  /**************************** ON EVENT LISTENER ****************************/
  //메뉴 유형 탭 선택 이벤트
  const handleMenuCategoryTab_onChange = (event: any) => {
    setMenuCategoryTab(event);
  };

  //WEB 트리 노드 클릭 이벤트
  const handleWebMenuTreeViewNode_onClick = (
    node: ITreeViewOnNodeSelectProps,
  ) => {
    getMenuList('WEB', setWebMenuTreeData);
    //폴더를 선택하면 종료함
    if (node.isBranch) {
      return;
    }
    //선택한 트리 노드에 적용함
    setSelectedWebMenuTreeNode(node);
    //메뉴 상세
    let tmpId: string = nanoid();
    setAddComponentModal({
      id: tmpId,
      title: '메뉴 상세',
      content: (
        <MN2302220811
          id={tmpId}
          menuId={String(node.element.id)}
          useAddMode={false}
          useModifyMode={false}
        />
      ),
      size: 1500,
    });
  };
  //WEB 하위 메뉴 추가 버튼 클릭
  const handleWebMenuAddBtn_onClick = (e: any) => {
    e.stopPropagation();
    var menuId = e.target.dataset.menuid;
    //메뉴 추가
    let tmpId: string = nanoid();
    setAddComponentModal({
      id: tmpId,
      title: '메뉴 추가',
      content: (
        <MN2302220811
          id={tmpId}
          menuId={menuId}
          useAddMode={true}
          useModifyMode={false}
        />
      ),
      size: 1500,
    });
  };

  //MBL 트리 노드 클릭 이벤트
  const handleMblMenuTreeViewNode_onClick = (
    node: ITreeViewOnNodeSelectProps,
  ) => {
    getMenuList('MBL', setMblMenuTreeData);
    //폴더를 선택하면 종료함
    if (node.isBranch) {
      return;
    }
    //선택한 트리 노드에 적용함
    setSelectedMblMenuTreeNode(node);
    //메뉴 상세 조회
    let tmpId: string = nanoid();
    setAddComponentModal({
      id: tmpId,
      title: '메뉴 상세',
      content: (
        <MN2302220811
          id={tmpId}
          menuId={String(node.element.id)}
          useModifyMode={false}
        />
      ),
      size: 800,
    });
  };
  //MBL 하위 메뉴 추가 버튼 클릭
  const handleMblMenuAddBtn_onClick = (e: any) => {
    e.stopPropagation();
    var menuId = e.target.dataset.menuid;
    //메뉴 추가
    let tmpId: string = nanoid();
    setAddComponentModal({
      id: tmpId,
      title: '메뉴 추가',
      content: (
        <MN2302220811
          id={tmpId}
          menuId={menuId}
          useAddMode={true}
          useModifyMode={false}
        />
      ),
      size: 1500,
    });
  };

  /******************************** USE EFFECT ********************************/
  //페이지 로딩 후 한번만 실행함
  useEffect(() => {
    getMenuList('WEB', setWebMenuTreeData);
    getMenuList('MBL', setMblMenuTreeData);
    return () => {};
  }, []);

  //WEB Menu 트리 데이터가 변경될 때 실행함
  useEffect(() => {
    if (webMenuTreeData === null) {
      return;
    }
    //트리 데이터를 이용하여 트리 노드를 생성함
    let tmpFlattenTreeNode: INode[] = flattenTree(webMenuTreeData);
    //트리 노드에 적용함
    setWebMenuTreeNode(tmpFlattenTreeNode);
    return () => {};
  }, [webMenuTreeData]);

  //MBL Menu 트리 데이터가 변경될 때 실행함
  useEffect(() => {
    if (mblMenuTreeData === null) {
      return;
    }
    //트리 데이터를 이용하여 트리 노드를 생성함
    let tmpFlattenTreeNode: INode[] = flattenTree(mblMenuTreeData);
    //트리 노드에 적용함
    setMblMenuTreeNode(tmpFlattenTreeNode);
    return () => {};
  }, [mblMenuTreeData]);

  //목록 새로고침 시
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }
    if (_.indexOf(refreshList, 'MN2302220801Tree') > -1) {
      getMenuList('WEB', setWebMenuTreeData);
      getMenuList('MBL', setMblMenuTreeData);
      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220801Tree');
    }
    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        <div className="space-y-12 w-full h-full pl-2">
          <Tabs
            value={menuCategoryTab}
            onTabChange={handleMenuCategoryTab_onChange}
          >
            <Tabs.List>
              <Tabs.Tab value="WEB">WEB</Tabs.Tab>
              <Tabs.Tab value="MBL">MOBILE</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="WEB" pt="xs">
              {/* WEB 트리 */}
              {webMenuTreeNode.length > 0 && (
                <TreeView
                  className=""
                  data={webMenuTreeNode}
                  aria-label="WEB Menu"
                  onNodeSelect={handleWebMenuTreeViewNode_onClick}
                  defaultExpandedIds={['ROOT_WEB', 'ROOT_MBL']}
                  nodeRenderer={({
                    element,
                    isBranch,
                    isExpanded,
                    getNodeProps,
                    level,
                    handleSelect,
                  }) => (
                    <div
                      {...getNodeProps()}
                      style={{ paddingLeft: 20 * (level - 1) }}
                      className="button-event flex justify-start items-center space-x-1"
                    >
                      {/* 폴더인지 아이템인지 구분 */}
                      {isBranch ? (
                        isExpanded === true ? (
                          <div className="flex justify-center items-center">
                            <FontAwesomeIcon
                              icon={['far', 'square-minus']}
                              className="w-4 h-4 text-gray-500"
                            />
                          </div>
                        ) : (
                          <div className="flex justify-center items-center">
                            <FontAwesomeIcon
                              icon={['far', 'square-plus']}
                              className="w-4 h-4 text-gray-500"
                            />
                          </div>
                        )
                      ) : (
                        <></>
                      )}
                      {/* 메뉴 아이템 이름 */}
                      <div className="flex justify-center items-center">
                        <span className="text-lg">{element.name}</span>
                      </div>
                      {/* 하위 메뉴 추가 버튼 */}
                      {level < 4 && (
                        <button
                          data-menuid={element.id}
                          onClick={handleWebMenuAddBtn_onClick}
                        >
                          +
                        </button>
                      )}
                    </div>
                  )}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel value="MBL" pt="xs">
              {/* MBL 트리 */}
              {mblMenuTreeNode.length > 0 && (
                <TreeView
                  className=""
                  data={mblMenuTreeNode}
                  aria-label="MBL Menu"
                  onNodeSelect={handleMblMenuTreeViewNode_onClick}
                  nodeRenderer={({
                    element,
                    isBranch,
                    isExpanded,
                    getNodeProps,
                    level,
                    handleSelect,
                  }) => (
                    <div
                      {...getNodeProps()}
                      style={{ paddingLeft: 20 * (level - 1) }}
                      className="button-event flex justify-start items-center space-x-1"
                    >
                      {/* 폴더인지 아이템인지 구분 */}
                      {isBranch ? (
                        isExpanded === true ? (
                          <div className="flex justify-center items-center">
                            <FontAwesomeIcon
                              icon={['far', 'square-minus']}
                              className="w-4 h-4 text-gray-500"
                            />
                          </div>
                        ) : (
                          <div className="flex justify-center items-center">
                            <FontAwesomeIcon
                              icon={['far', 'square-plus']}
                              className="w-4 h-4 text-gray-500"
                            />
                          </div>
                        )
                      ) : (
                        <></>
                      )}
                      {/* 메뉴 아이템 이름 */}
                      <div className="flex justify-center items-center">
                        <span className="text-lg">{element.name}</span>
                      </div>
                      {/* 하위 메뉴 추가 버튼 */}
                      {level < 4 && (
                        <button
                          data-menuid={element.id}
                          onClick={handleMblMenuAddBtn_onClick}
                        >
                          +
                        </button>
                      )}
                    </div>
                  )}
                />
              )}
            </Tabs.Panel>
          </Tabs>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220801Page;
