import { atom } from 'recoil';

/**
 * 목록 새로고침
 */
export const refreshListStore = atom({
  key: 'refreshListStore',
  default: [] as string[],
});

/**
 * 추가할 목록 새로고침
 */
export const addRefreshListStore = atom({
  key: 'addRefreshListStore',
  default: '' as string,
});

/**
 * 삭제할 목록 새로고침
 */
export const removeRefreshListStore = atom({
  key: 'removeRefreshListStore',
  default: '' as string,
});
