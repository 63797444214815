import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbMonthPicker } from '../PbMonthPicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModalPageScrollTabStep,
  IComponentModalPageScrollTab,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PbDatePicker } from '../PbDatePicker';
import * as cmnApi from '../../apis/cmn.api';
import _, { forEach } from 'lodash';
import { PbTable } from '../PbTable';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { getSlsPrtn } from '../../apis/sls.api';
import * as csApi from '../../apis/cs.api';
import { PbInputNumber } from '../PbInputNumber';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  NumberInput,
  Select,
  TextInput,
  Group,
  Tabs,
  Checkbox,
  Radio,
} from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import { IGroupCellRendererParams } from 'ag-grid-community';
import { PbAgGridReact } from '../PbAgGridReact';
import * as appUtil from '../../utils/app.util';

interface IComponentProps {
  id?: string;
  thrtGrpId?: string;
  webType?: string;
  useModifyMode?: boolean;
  data?: any;
  onClick?: () => void;
}

/**
 * 시스템 관리 > 권한 그룹 관리 > 조회, 수정
 * @param id 컴포넌트 모달 아이디
 * @param thrtGrpId 권한 아이디
 * @param webType EB , Mobile 구분
 * @param useModifyMode 수정 모드 사용 여부
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220860 = ({
  id = '',
  thrtGrpId = '',
  webType = '',
  useModifyMode = false,
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 입려값 byte 확인
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  /**
   * 권한 그룹명
   */

  // 기본정보 > 권한 그룹명 입력을 정의함
  const [thrtGrpNameInput, setThrtGrpNameInput] = useState<string>('');

  // 기본정보 > 권한 그룹명 값을 변경함
  const handleAuthGrpNameInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 30) {
      // 30byte 제한
      setThrtGrpNameInput((pre) => pre);
    } else {
      setThrtGrpNameInput(sVal);
    }

    setAuthGroupNameConfirm('N');
  };

  // 권한그룹명 > 중복확인 버튼을 정의함
  const handleDuplicateConfirmButton_onClick = () => {
    if (_.isEmpty(thrtGrpNameInput.trim())) {
      setModal({
        title: '알림',
        content: '권한그룹명을 입력해주세요.',
      });
      return;
    }

    // 권한그룹명 중복확인
    cmnApi
      .getCheckAuthorityGroup({
        thrtGrpName: thrtGrpNameInput, // 권한그룹명
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          // 중복 확인 : 권한그룹명 사용 가능
          case '200':
            setAuthGroupNameConfirm('Y');
            setModal({
              title: '알림',
              content: '사용 가능한 권한그룹명 입니다.',
            });
            break;

          // 중복 확인 : 권한그룹명 사용 불가
          case '500':
            setAuthGroupNameConfirm('N');
            setModal({
              title: '알림',
              content: '이미 사용 중인 권한그룹명 입니다..',
            });
            break;

          default:
            break;
        }
      });
  };

  /* //권한그룹명 */

  /**
   * 사용여부
   */

  // 상세 검색 > 사용 여부 라디오 버튼을 정의함
  const [useYnRadio, setUseYnRadio] = useState<string>('Y');

  // 상세 검색 > 사용 여부 셀렉트에서 선택한 아이템을 변경함
  const handleUseYnSelect_onChange = (event: any) => {
    setUseYnRadio(event);
  };

  /* //사용여부 */

  /**
   * 등록일
   */

  // 기본정보 폼 그리드 > 등록일 > 날짜 피커를 정의함
  const [addDatePicker, setAddDatePicker] = useState<Date | null>(null);

  // 기본정보 폼 그리드 > 등록일 > 날짜 피커를 변경함
  const handleAddDatePicker_onChange = (event: any) => {
    setAddDatePicker(event.target.value);
  };

  /* //등록일 */

  /**
   * 수정일
   */

  // 기본정보 폼 그리드 > 수정일 > 날짜 피커를 정의함
  const [modifyDatePicker, setModifyDatePicker] = useState<Date | null>(null);

  // 기본정보 폼 그리드 > 수정일 > 날짜 피커를 변경함
  const handleModifyDatePicker_onChange = (event: any) => {
    setModifyDatePicker(event.target.value);
  };

  /* //수정일 */

  /**
   * 권한 목록
   */

  // 권한 상세 > 기본정보 > 권한설정(WEB) > 테이블 참조를 정의함
  const authWebMenuListTableRef = useRef<any>(null);

  // 권한 상세 > 권한 설정 > 메뉴 목록(WEB) 테이블 정의
  const [authWebMenuListTable, setAuthWebMenuListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 권한 상세 > 기본정보 > 권한설정(MBL) > 테이블 참조를 정의함
  const authMblMenuListTableRef = useRef<any>(null);

  // 권한 상세 > 권한 설정 > 메뉴 목록(MBL) 테이블 정의
  const [authMblMenuListTable, setAuthMblMenuListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 권한 상세 > 권한 설정 > 메뉴 목록(WEB) - 선택권한에 따른 데이타 변경 정의
  // const [tmpWebAuthMenuListTable, setTmpWebAuthMenuListTable] = useState<{
  //   column: any;
  //   data: any;
  // }>({ column: [], data: [] });

  // 권한 상세 > 권한 설정 > 메뉴 목록(MBL) - 선택권한에 따른 데이타 변경 정의
  // const [tmpMblAuthMenuListTable, setTmpMblAuthMenuListTable] = useState<{
  //   column: any;
  //   data: any;
  // }>({ column: [], data: [] });

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    if (!thrtGrpId) {
      // 신규
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '권한 선택',
          targetId: 'step-2',
        },
        {
          label: '권한 설정',
          targetId: 'step-3',
        },
      ];
    } else if (thrtGrpId && !useModifyMode) {
      // 조회
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '권한 선택',
          targetId: 'step-2',
        },
        {
          label: '권한 설정',
          targetId: 'step-3',
        },
      ];
    } else if (thrtGrpId && useModifyMode) {
      // 수정
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '권한 선택',
          targetId: 'step-2',
        },
        {
          label: '권한 설정',
          targetId: 'step-3',
        },
      ];
    }

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 권한등록 > 권한설정 테이블을 초기화함
  const initAuthMenuListTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];
    let tmpColumn2: any = [];

    // 기본 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '메뉴명',
        children: [
          { headerName: '1depth', field: 'oneKrnMenu' },
          { headerName: '2depth', field: 'twoKrnMenu' },
          { headerName: '3depth', field: 'threeKrnMenu' },
          { headerName: '4depth', field: 'fourKrnMenu' },
        ],
      },
      {
        field: 'mnthRltnUseYn',
        headerName: '사용 여부',
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center">
            <Checkbox
              onChange={handleWebAuthGroupListTableCheckbox_onChange}
              defaultChecked={params.value === 'Y' ? true : false}
              value={[
                params.data.oneMenuId,
                params.data.twoMenuId,
                params.data.threeMenuId,
              ].join(',')}
              disabled={true}
            />
          </div>
        ),
        width: 100,
        suppressSizeToFit: true,
      },
    );

    tmpColumn2.push(
      {
        headerName: '메뉴명',
        children: [
          { headerName: '1depth', field: 'oneKrnMenu' },
          { headerName: '2depth', field: 'twoKrnMenu' },
          { headerName: '3depth', field: 'threeKrnMenu' },
          { headerName: '4depth', field: 'fourKrnMenu' },
        ],
      },
      {
        field: 'mnthRltnUseYn',
        headerName: '사용 여부',
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center">
            <Checkbox
              onChange={handleMblAuthGroupListTableCheckbox_onChange}
              defaultChecked={params.value === 'Y' ? true : false}
              value={[
                params.data.oneMenuId,
                params.data.twoMenuId,
                params.data.threeMenuId,
              ].join(',')}
              disabled={true}
            />
          </div>
        ),
        width: 100,
        suppressSizeToFit: true,
      },
    );

    // WEB 메뉴 테이블에 적용함
    setAuthWebMenuListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));

    // Mobile 메뉴 테이블에 적용함
    setAuthMblMenuListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn2,
    }));
  };

  /* // 권한 목록 */

  /**
   * 상세 데이타
   */

  // 권한그룹명 중복 확인
  const [authGroupNameConfirm, setAuthGroupNameConfirm] = useState<string>('');

  // 권한 그룹 정보 상세 데이터를 정의함
  const [authGroupData, setAuthGroupData] = useState<any>(null);

  // 권한 그룹 정보 상세 데이터를 불러옴
  const getAuthGroupDetail = () => {
    cmnApi
      .getAuthorityGroup({
        thrtGrpId: thrtGrpId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 권한 그룹 정보 상세 데이터에 적용함
          setAuthGroupData(data.data);
        }
      });
  };

  // 권한 설정 섹션 > 테이블의 행에서 사용여부 체크박스를 변경함
  const handleWebAuthGroupListTableCheckbox_onChange = (event: any) => {
    // 테이블의 전체 데이터를 불러옴
    let tmpRowData: any = [];

    authWebMenuListTableRef.current.api.forEachNode((node: any) =>
      tmpRowData.push(node.data),
    );

    // 선택한 행의 메뉴 아이디를 각각 분리함
    let tmpRowId: string[] = event.target.value.split(',');

    // 수정할 행의 순서를 불러옴
    let tmpIndex: number = _.findIndex(tmpRowData, {
      oneMenuId: tmpRowId[0],
      twoMenuId: tmpRowId[1],
      threeMenuId: tmpRowId[2],
    });

    // 체크박스의 체크 여부에 따라 값을 적용함
    tmpRowData[tmpIndex]['mnthRltnUseYn'] = event.target.checked ? 'Y' : 'N';
  };

  // 권한 설정 섹션 > 테이블의 행에서 사용여부 체크박스를 변경함
  const handleMblAuthGroupListTableCheckbox_onChange = (event: any) => {
    // 테이블의 전체 데이터를 불러옴
    let tmpRowData: any = [];

    authMblMenuListTableRef.current.api.forEachNode((node: any) =>
      tmpRowData.push(node.data),
    );

    // 선택한 행의 메뉴 아이디를 각각 분리함
    let tmpRowId: string[] = event.target.value.split(',');

    // 수정할 행의 순서를 불러옴
    let tmpIndex: number = _.findIndex(tmpRowData, {
      oneMenuId: tmpRowId[0],
      twoMenuId: tmpRowId[1],
      threeMenuId: tmpRowId[2],
    });

    // 체크박스의 체크 여부에 따라 값을 적용함
    tmpRowData[tmpIndex]['mnthRltnUseYn'] = event.target.checked ? 'Y' : 'N';
  };

  /* 권한 설정
   * [2023.04.24] 권한 설정 탭 & 권한 설정 대상 메뉴 목록 관련 설정 추가 (곽상준)
   */

  /* 권한 설정 탭 */
  //권한 설정 선택 탭 데이터를 정의함
  const [thrtCategoryTab, setThrtCategoryTab] = useState<string>(webType);
  //권한 설정 선택 이벤트를 정의
  const handleThrtCategoryTab_onChange = (event: any) => {
    setThrtCategoryTab(event);
  };

  // 권한 선택 정보 가져오기
  const getAuthorityGroupTrgt = () => {
    // 권한에 설정된 전체 메뉴 모두 가져오기 - 전체 메뉴 검색 뒤 필터링
    cmnApi.getAuthorityGroupTrgt({}).then((data: IApiResult) => {
      if (data.data.list !== undefined) {
        setAuthorities(data.data.list);
      }
    });
  };

  // 권한 선택 > 선택된 권한 의 메뉴(WEB) 정보 가져오기
  // const getWebAuthority = (selectThrtId: string) => {
  //   cmnApi
  //     .getAuthority({
  //       thrtId: selectThrtId,
  //       menuDvsnCode: 'WEB',
  //     })
  //     .then((data: IApiResult) => {
  //       if (data.data.list !== undefined) {
  //         // 권한에 대한 메뉴 저장
  //         setCheckedAuthWebMenuList((pre: { thrtId: any; item: any }) => ({
  //           thrtId: data.data.thrtId,
  //           item: data.data.list,
  //         }));
  //       }
  //     });
  // };

  const [thrtWebList, setThrtWebList] = useState<any[]>([]);
  const [thrtMblList, setThrtMblList] = useState<any[]>([]);

  // 권한 선택 > 선택된 권한 의 메뉴 정보 가져오기
  const getAuthorityUser = (selectThrtIds: any) => {
    initUserThrt(); // 메뉴 권한 초기화 후 재설정

    cmnApi
      .getAuthorityUser({
        thrtIds: selectThrtIds,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          let tmpWebList: any = data.data.list.filter((item: any) =>
            _.isEqual(item.menuDvsnCode, 'WEB'),
          );

          setThrtWebList(tmpWebList);
          // console.log("Web Length : ", tmpWebList.length);
          // console.log("authWebMenuListTable Length : ", authWebMenuListTable.data.length);
          // authWebMenuListTable.data.map((item:any, index:number) => {
          //   tmpWebList.map((subItem:any, subIndex:number) => {
          //     if (_.isEqual(item.mnthRltnTargetId, subItem.mnthRltnTargetId) &&
          //             _.isEqual(subItem.mnthRltnUseYn, 'Y')) {
          //       // 권한이 있는 메뉴만
          //       console.log(" >> subItem.mnthRltnTargetId :: " , subItem.mnthRltnTargetId);
          //       item.mnthRltnUseYn = subItem.mnthRltnUseYn;
          //     }
          //   });
          // });

          let tmpMblList: any = data.data.list.filter((item: any) =>
            _.isEqual(item.menuDvsnCode, 'MBL'),
          );
          setThrtMblList(tmpMblList);

          // authMblMenuListTable.data.map((item:any, index:number) => {
          //   tmpMblList.map((subItem:any, subIndex:number) => {
          //     if (_.isEqual(item.mnthRltnTargetId, subItem.mnthRltnTargetId) &&
          //             _.isEqual(subItem.mnthRltnUseYn, 'Y')) {
          //       // 권한이 있는 메뉴만
          //       item.mnthRltnUseYn = subItem.mnthRltnUseYn;
          //     }
          //   });
          // });

          // // 권한설정 테이블(WEB) 데이타 설정
          // setAuthWebMenuListTable((pre: { column: any; data: any }) => ({
          //   ...pre,
          //   data: tmpWebList,
          // }));

          // // 권한설정 테이블(MBL) 데이타 설정
          // setAuthMblMenuListTable((pre: { column: any; data: any }) => ({
          //   ...pre,
          //   data: tmpMblList,
          // }));

          // 권한에 대한 메뉴 저장
          // setCheckedAuthMblMenuList((pre: { thrtId: any; item: any }) => ({
          //   thrtId: data.data.thrtId,
          //   item: data.data.list,
          // }));
        }
      });
  };

  // 사용 메뉴 권한 초기화
  const initUserThrt = () => {
    let tmpWebList: any[] = _.cloneDeep(authWebMenuListTable.data);
    // 웹 권한 초기화
    tmpWebList.map((item: any, index: number) => {
      item.mnthRltnUseYn = 'N';
    });

    setAuthWebMenuListTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmpWebList,
    }));

    let tmpMblList: any[] = _.cloneDeep(authMblMenuListTable.data);

    // 모바일 권한 초기화
    tmpMblList.map((item: any, index: number) => {
      item.mnthRltnUseYn = 'N';
    });

    setAuthMblMenuListTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmpMblList,
    }));
  };

  // 사용 가능한 모든 메뉴 가져오기
  const getAllMenuList = () => {
    getWebMenuList('WEB');
    getMlbMenuList('MBL');
  };

  // Web 메뉴 가져오기
  const getWebMenuList = (tabId: string) => {
    cmnApi
      .getAuthorityTarget({
        menuDvsnCode: tabId,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 권한설정 테이블(WEB) 데이타 설정
          setAuthWebMenuListTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));

          // setTmpWebAuthMenuListTable((pre: { column: any; data: any }) => ({
          //   ...pre,
          //   data: data.data.list,
          // }));
        }
      });
  };

  // Mobile 메뉴 가져오기
  const getMlbMenuList = (tabId: string) => {
    cmnApi
      .getAuthorityTarget({
        menuDvsnCode: tabId,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 권한설정 테이블(Mobile) 데이타 설정
          setAuthMblMenuListTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));

          // setTmpMblAuthMenuListTable((pre: { column: any; data: any }) => ({
          //   ...pre,
          //   data: data.data.list,
          // }));
        }
      });
  };

  // 사용 가능한 권한의 메뉴 목록을 정의함
  const [authorities, setAuthorities] = useState<any[]>([]);

  // 권한선택 Checkbox group 정의
  const [authoritiesCheckbox, setAuthoritiesCheckbox] = useState<string[]>([
    '',
  ]);

  // 선택된 권한별 사용 메뉴(WEB) 목록을 정의함
  const [checkedAuthWebMenuList, setCheckedAuthWebMenuList] = useState<{
    thrtId: any;
    item: any;
  }>({
    thrtId: '',
    item: [],
  });

  // 선택된 권한별 사용 메뉴(MBL) 목록을 정의함
  const [checkedAuthMblMenuList, setCheckedAuthMblMenuList] = useState<{
    thrtId: any;
    item: any;
  }>({
    thrtId: '',
    item: [],
  });

  // 권한별 정보의 목록을 정의함 - 권한 설정 목록(WEB) 확인용
  const [checkedWebAuthList, setCheckedWebAuthList] = useState<ISelect[]>([]);

  // 권한별 정보의 목록을 정의함 - 권한 설정 목록(Mobile) 확인용
  const [checkedMblAuthList, setCheckedMblAuthList] = useState<ISelect[]>([]);

  // 권한선택 checkbox 선택
  const handleSetupClassCodeCheckbox_onChange = (event: any) => {
    // 권한설정 테이블 데이타 설정 - 사용메뉴 정보 초기화 후 다시 설정
    // setAuthWebMenuListTable((pre: { column: any; data: any }) => ({
    //   ...pre,
    //   data: _.cloneDeep(tmpWebAuthMenuListTable.data),
    // }));

    // setAuthMblMenuListTable((pre: { column: any; data: any }) => ({
    //   ...pre,
    //   data: _.cloneDeep(tmpMblAuthMenuListTable.data),
    // }));

    if (event.length > 0) {
      let thrtIdStr: any = '';
      event.map((item: any, index: number) => {
        if (index === 0) {
          thrtIdStr = item;
        } else {
          thrtIdStr = thrtIdStr + ',' + item;
        }
      });

      getAuthorityUser(thrtIdStr);
      //   checkedWebAuthList.map((authList: any, index: number) => {
      //     // 권한 목록 loop
      //     if (_.isEqual(initAuthWebMenu, 'Y')) {
      //       if (_.isEqual(event[event.length - 1], authList.thrtId)) {
      //         initAuthWebMenu = 'N';
      //       } else {
      //         initAuthWebMenu = 'Y'; // 저장된 권한 목록에 선택권한 정보가 없으면 검색
      //       }
      //     }
      //   });

      //   if (initAuthWebMenu === 'Y') {
      //     getWebAuthority(event[event.length - 1]);
      //   } else {
      //     setAuthWebMenuUseYn(event);
      //   }

      //   let initAuthMblMenu = 'Y';
      //   checkedMblAuthList.map((authList: any, index: number) => {
      //     // 권한 목록 loop
      //     if (_.isEqual(initAuthMblMenu, 'Y')) {
      //       if (_.isEqual(event[event.length - 1], authList.thrtId)) {
      //         initAuthMblMenu = 'N';
      //       } else {
      //         initAuthMblMenu = 'Y'; // 저장된 권한 목록에 선택권한 정보가 없으면 검색
      //       }
      //     }
      //   });

      //   if (initAuthMblMenu === 'Y') {
      //     getMblAuthority(event[event.length - 1]);
      //   } else {
      //     setAuthMblMenuUseYn(event);
      //   }
    } else {
      initUserThrt();
    }
    setAuthoritiesCheckbox(event);
  };

  // 메뉴(WEB)의 사용여부 출력
  // const setAuthWebMenuUseYn = (checkedEvent: any) => {
  //   let tmpAuthList: any = [];
  //   let tmpAuthListTable: any = [];

  //   checkedEvent.map((eventId: any, index: number) => {
  //     checkedWebAuthList.map((authList: any, index: number) => {
  //       // 권한 목록 loop
  //       if (_.isEqual(eventId, authList.thrtId)) {
  //         tmpAuthList.push(authList.item);
  //       }
  //     });
  //   });

  //   tmpAuthListTable = _.cloneDeep(tmpWebAuthMenuListTable);

  //   tmpAuthList.map((useMenuList: any, index: number) => {
  //     // 사용 가능 메뉴 목록의 집합
  //     useMenuList.map((useMenu: any, index: number) => {
  //       // 사용 가능 메뉴 목록
  //       tmpAuthListTable.data.map((item: any, index: number) => {
  //         // 권한 설정 테이블 의 사용여부 설정
  //         if (
  //           _.isEqual(item.mnthRltnTargetId, useMenu.mnthRltnTargetId) &&
  //           _.isEqual(useMenu.mnthRltnUseYn, 'Y')
  //         ) {
  //           // 권한이 있는 메뉴만
  //           item.mnthRltnUseYn = useMenu.mnthRltnUseYn;
  //         }
  //       });
  //     });
  //   });

  //   // 권한설정 테이블(WEB) 데이타 설정
  //   setAuthWebMenuListTable((pre: { column: any; data: any }) => ({
  //     ...pre,
  //     data: tmpAuthListTable.data,
  //   }));
  // };

  // 메뉴(MBL)의 사용여부 출력
  // const setAuthMblMenuUseYn = (checkedEvent: any) => {
  //   let tmpAuthList: any = [];
  //   let tmpAuthListTable: any = [];

  //   checkedEvent.map((eventId: any, index: number) => {
  //     checkedMblAuthList.map((authList: any, index: number) => {
  //       // 권한 목록 loop
  //       if (_.isEqual(eventId, authList.thrtId)) {
  //         tmpAuthList.push(authList.item);
  //       }
  //     });
  //   });

  //   tmpAuthListTable = _.cloneDeep(tmpMblAuthMenuListTable);

  //   tmpAuthList.map((useMenuList: any, index: number) => {
  //     // 사용 가능 메뉴 목록의 집합
  //     useMenuList.map((useMenu: any, index: number) => {
  //       // 사용 가능 메뉴 목록
  //       tmpAuthListTable.data.map((item: any, index: number) => {
  //         // 권한 설정 테이블 의 사용여부 설정
  //         if (
  //           _.isEqual(item.mnthRltnTargetId, useMenu.mnthRltnTargetId) &&
  //           _.isEqual(useMenu.mnthRltnUseYn, 'Y')
  //         ) {
  //           // 권한이 있는 메뉴만
  //           item.mnthRltnUseYn = useMenu.mnthRltnUseYn;
  //         }
  //       });
  //     });
  //   });

  //   // 권한설정 테이블(MBL) 데이타 설정
  //   setAuthMblMenuListTable((pre: { column: any; data: any }) => ({
  //     ...pre,
  //     data: tmpAuthListTable.data,
  //   }));
  // };

  /**
   * 처리
   */

  // 컴포넌트 모달 > 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    setModal({
      title: '확인',
      content: `권한 그룹 [${authGroupData.thrtGrpName}] 정보를 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 권한 그룹 정보를 삭제함
              cmnApi
                .deleteAuthorityGroup({
                  thrtGrpId: thrtGrpId,
                })
                .then((data: IApiResult) => {
                  switch (data.code) {
                    case '200':
                      // 모달을 출력함
                      setModal({
                        title: '알림',
                        content: '삭제하였습니다.',
                        callback: () => {
                          // 목록 새로고침을 추가함
                          setAddRefreshList('MN2302220803Table');

                          setRemoveComponentModal(id);
                        },
                      });
                      break;

                    default:
                      console.log('> 권한그룹 정보 삭제 Error:', data);

                      // 모달을 출력함
                      setModal({
                        title: t('common.error'),
                        content: '삭제에 실패하였습니다.',
                      });
                      break;
                  }
                })
                .catch((error: any) => {
                  console.log('> 권한그룹 정보 삭제 Error:', error);

                  setModal({
                    title: t('common.error'),
                    content: '삭제에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // 컴포넌트 모달 > 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '권한 그룹 정보 수정',
        content: (
          <MN2302220860
            id={tmpId}
            thrtGrpId={thrtGrpId}
            useModifyMode={true}
            webType={thrtCategoryTab}
          />
        ),
        size: 1500,
      });
    }, 100);
  };

  // 컴포넌트 모달 > 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    // 권한 정보 수정의 입력 값 유효성을 확인함

    // 권한그룹명 중복 확인
    if (authGroupNameConfirm === 'Y') {
      // 중복 확인됨
    } else {
      setModal({
        title: '알림',
        content: '권한그룹명 중복확인을 해주세요.',
      });
      return;
    }

    // 권한 선택 에서 체크한 체크박스의 값을 가져옴(권한 아이디)
    let tmpThrtGrpRltnListMap: any[] = [];
    authoritiesCheckbox.map((item: any) => {
      tmpThrtGrpRltnListMap.push({
        [item]: 'Y',
      });
    });

    // 권한 정보 등록 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 권한정보 수정을 처리함
              cmnApi
                .postAuthorityGroup({
                  thrtGrpName: thrtGrpNameInput || '', // 권한그룹명
                  thrtGrpDscr: '', // 권한그룹 설명
                  thrtGrpRltnListMap: tmpThrtGrpRltnListMap, // 권한 선택 정보
                  useYn: useYnRadio || '', // 사용여부
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220803Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);
                      },
                    });
                  } else {
                    console.log('> 권한그룹 정보 등록 Error:', data);

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> 권한그룹 정보 등록 Error:', error);

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // 권한 그룹 정보 수정 저장 처리
  const handleSaveUpdateButton_onClick = () => {
    // 권한 그룹 정보 수정의 입력 값 유효성을 확인함

    // 기본정보 > 사용여부 셀렉트를 확인함
    // if (!useYnSelect.value) {
    //   setModal({
    //     title: '알림',
    //     content: '사용 여부를 선택해 주세요.',
    //   });

    //   return;
    // }

    // 권한 선택 에서 체크한 체크박스의 값을 가져옴(권한 아이디)
    let tmpThrtGrpRltnListMap: any[] = [];
    authoritiesCheckbox.map((item: any) => {
      tmpThrtGrpRltnListMap.push({
        [item]: 'Y',
      });
    });

    // 권한 그룹 정보 수정 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 권한 그룹 정보 수정을 처리함
              cmnApi
                .putAuthorityGroup({
                  thrtGrpId: thrtGrpId, // 권한 그룹 아이디
                  thrtGrpName: thrtGrpNameInput || '', // 권한그룹명
                  thrtGrpDscr: '', // 권한그룹 설명
                  thrtGrpRltnListMap: tmpThrtGrpRltnListMap, // 권한 상세 정보
                  useYn: useYnRadio || '', // 사용여부
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220803Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);

                        setTimeout(() => {
                          // 조회 컴포넌트 모달을 추가함
                          let tmpId: string = nanoid();

                          // 컴포넌트 모달을 추가함
                          setAddComponentModal({
                            id: tmpId,
                            title: '권한 그룹 정보 상세',
                            content: (
                              <MN2302220860
                                id={tmpId}
                                thrtGrpId={thrtGrpId}
                                useModifyMode={false}
                                webType={thrtCategoryTab}
                              />
                            ),
                            size: 1500,
                          });
                        }, 100);
                      },
                    });
                  } else {
                    console.log('> 권한 그룹 정보 수정 Error:', data);

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> 권한 그룹 정보 수정 Error:', error);

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    if (thrtGrpId) {
      // 상세/수정의 경우 호출됨
      // 권한 그룹 상세 데이터를 불러옴
      setAuthGroupNameConfirm('Y');

      // 권한 상세 데이터를 불러옴
      getAuthGroupDetail();
    } else {
      // 권한명 중복 확인
      setAuthGroupNameConfirm('N');
    }

    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    // 기본정보 > 권한명 값을 초기화함
    setThrtGrpNameInput('');

    // 기본정보 폼 그리드 > 등록일 > 날짜 피커를 초기화함
    setAddDatePicker(moment().toDate());

    // 기본정보 폼 그리드 > 수정일 > 날짜 피커를 초기화함
    setModifyDatePicker(moment().toDate());

    // 권한 선택 데이터를 불러옴
    getAuthorityGroupTrgt();

    // 권한 그룹 등록 > 권한 설정 테이블을 초기화함
    initAuthMenuListTable();

    // 권한 선택시 사용될 메뉴 전체 가져오기
    getAllMenuList();

    return () => {};
  }, []);

  // 권한 그룹 상세 정보 초기화
  useEffect(() => {
    if (!authGroupData) {
      return;
    }

    // 기본정보 > 권한명 값을 초기화함
    setThrtGrpNameInput(authGroupData.thrtGrpName);

    // 상세 검색 > 사용 여부 설정
    setUseYnRadio(authGroupData.useYn);

    // 기본정보 폼 그리드 > 등록일 설정
    setAddDatePicker(moment(authGroupData.rgstDate, 'YYYY-MM-DD').toDate());

    // 기본정보 폼 그리드 > 수정일 설정
    setModifyDatePicker(moment(authGroupData.mdfcDate, 'YYYY-MM-DD').toDate());

    // 권한 선택 체크박스에 기본값을 체크하기 위한 배열
    let tmpAuthoritiesCheckbox: string[] = [];
    let tmpAuthStr: any = '';
    let idx: number = 0;
    authGroupData.list.map((item: any, index: number) => {
      if (item.thrtGrpRltnUseYn === 'Y') {
        tmpAuthoritiesCheckbox.push(item.thrtId);
        if (idx === 0) {
          tmpAuthStr = item.thrtId;
          idx++;
        } else {
          tmpAuthStr = tmpAuthStr + ',' + item.thrtId;
          idx++;
        }
      }
    });

    // 권한 선택 체크박스
    setAuthoritiesCheckbox(tmpAuthoritiesCheckbox);

    // 그룹에서 사용하는 메뉴 권한 불러오기
    getAuthorityUser(tmpAuthStr);

    // // 권한 그룹에 선택되어 있는 메뉴정보(WEB) 검색하기
    // getWebAuthority(item.thrtId);

    // // 권한 그룹에 선택되어 있는 메뉴정보(MBL) 검색하기
    // getMblAuthority(item.thrtId);

    return () => {};
  }, [authGroupData]);

  useEffect(() => {
    if (!thrtWebList && !thrtMblList) {
      return;
    }

    // WEB 메뉴 정보 재설정
    let tmpWebList: any[] = _.cloneDeep(authWebMenuListTable.data);
    tmpWebList.map((item: any, index: number) => {
      thrtWebList.map((subItem: any, subIndex: number) => {
        if (
          _.isEqual(item.mnthRltnTargetId, subItem.mnthRltnTargetId) &&
          _.isEqual(subItem.mnthRltnUseYn, 'Y')
        ) {
          // 권한이 있는 메뉴만
          item.mnthRltnUseYn = subItem.mnthRltnUseYn;
        }
      });
    });

    setAuthWebMenuListTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmpWebList,
    }));

    // Mobile 메뉴 정보 재설정
    let tmpMblList: any[] = _.cloneDeep(authMblMenuListTable.data);
    tmpMblList.map((item: any, index: number) => {
      thrtMblList.map((subItem: any, subIndex: number) => {
        if (
          _.isEqual(item.mnthRltnTargetId, subItem.mnthRltnTargetId) &&
          _.isEqual(subItem.mnthRltnUseYn, 'Y')
        ) {
          // 권한이 있는 메뉴만
          item.mnthRltnUseYn = subItem.mnthRltnUseYn;
        }
      });
    });

    setAuthMblMenuListTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmpMblList,
    }));

    return () => {};
  }, [
    authWebMenuListTable.data.length,
    authMblMenuListTable.data.length,
    thrtWebList,
    thrtMblList,
  ]);

  // 선택된 권한에 대한 메뉴(WEB) 정보 검색시 실행
  // useEffect(() => {
  //   if (_.isEmpty(checkedAuthWebMenuList.thrtId)) {
  //     return;
  //   }

  //   // 권한 목록(WEB) 저장
  //   let tmpWebAuthList: any[] = checkedWebAuthList;
  //   tmpWebAuthList.push(checkedAuthWebMenuList);
  //   setCheckedWebAuthList(tmpWebAuthList);

  //   // 선택한 권한에 대한 메뉴(WEB)사용여부 적용
  //   setAuthWebMenuUseYn(authoritiesCheckbox); // 데이타 로드 시간차 대비로 검색을 진행 한 경우는 useEffect 에서 진행

  //   return () => {};
  // }, [checkedAuthWebMenuList]);

  // 선택된 권한에 대한 메뉴(MBL) 정보 검색시 실행
  // useEffect(() => {
  //   if (_.isEmpty(checkedAuthMblMenuList.thrtId)) {
  //     return;
  //   }

  //   // 권한 목록(MBL) 저장
  //   let tmpMblAuthList: any[] = checkedMblAuthList;
  //   tmpMblAuthList.push(checkedAuthMblMenuList);
  //   setCheckedMblAuthList(tmpMblAuthList);

  //   // 선택한 권한에 대한 메뉴(MBL)사용여부 적용
  //   setAuthMblMenuUseYn(authoritiesCheckbox); // 데이타 로드 시간차 대비로 검색을 진행 한 경우는 useEffect 에서 진행

  //   return () => {};
  // }, [checkedAuthMblMenuList]);

  // 메뉴 테이블 정보 변경시 실행

  //권한 설정 탭 변경 설정
  useEffect(() => {
    //TODO : 권한 설정 대상 메뉴 목록 출력 (곽상준)
    //권한 메뉴 대상 목록 조회 (param : thrtCategoryTab (WEB or MBL))
    getAuthorityGroupTrgt();

    // 권한 선택시 사용될 메뉴 전체 가져오기
    // getAllMenuList();

    // 선택한 권한에 대한 메뉴사용여부 적용
    // setAuthMenuUseYn(authoritiesCheckbox);

    return () => {};
  }, [thrtCategoryTab]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  /* //처리 */

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 권한 상세 폼 그리드 */}
        <PbFormGrid id="step-1" label="기본 정보" cols={2}>
          <PbFormGridCol label="권한 그룹명" withAsterisk={true}>
            <TextInput
              placeholder="권한 그룹명을 입력하세요."
              onChange={handleAuthGrpNameInput_onChange}
              value={thrtGrpNameInput}
              readOnly={thrtGrpId && !useModifyMode ? true : false}
              variant={thrtGrpId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />

            <Button
              color="indigo"
              radius="xl"
              hidden={thrtGrpId && !useModifyMode ? true : false}
              onClick={handleDuplicateConfirmButton_onClick}
            >
              중복 확인
            </Button>
          </PbFormGridCol>
          <PbFormGridCol label="사용 여부">
            <Radio.Group
              name="radioGroup1"
              onChange={handleUseYnSelect_onChange}
              value={useYnRadio}
              size="sm"
            >
              <Group mt="xs" className="mt-0">
                <Radio
                  value="Y"
                  label="사용"
                  disabled={thrtGrpId && !useModifyMode ? true : false}
                />
                <Radio
                  value="N"
                  label="미사용"
                  disabled={thrtGrpId && !useModifyMode ? true : false}
                />
              </Group>
            </Radio.Group>
          </PbFormGridCol>
          <PbFormGridCol label="등록일" isHidden={true}>
            {/* 날짜 피커 */}
            <DatePickerInput
              onChange={handleAddDatePicker_onChange}
              value={addDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              clearable={true}
              className="w-full"
              readOnly={true}
            />
          </PbFormGridCol>
          <PbFormGridCol label="수정일" isHidden={true}>
            {/* 날짜 피커 */}
            <DatePickerInput
              onChange={handleModifyDatePicker_onChange}
              value={modifyDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              clearable={true}
              className="w-full"
              readOnly={true}
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 권한 선택 섹션 */}
        <PbSection id="step-2" label="권한 선택">
          <div className="px-1 py-1 border">
            <Checkbox.Group
              onChange={handleSetupClassCodeCheckbox_onChange}
              value={authoritiesCheckbox}
            >
              <Group>
                {authorities.map((item: any, index: number) => (
                  <div key={index} className="w-60">
                    <Checkbox
                      value={item.thrtId}
                      label={item.thrtName}
                      disabled={thrtGrpId && !useModifyMode ? true : false}
                    />
                  </div>
                ))}
              </Group>
            </Checkbox.Group>
          </div>
        </PbSection>

        {/* 권한 설정 섹션 */}
        <PbSection id="step-3" label="권한 설정">
          {/* 탭 */}
          <Tabs
            variant="outline"
            value={thrtCategoryTab}
            onTabChange={handleThrtCategoryTab_onChange}
          >
            {/* 탭 헤더 */}
            <Tabs.List>
              <Tabs.Tab
                value="WEB"
                icon={
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={['fab', 'chrome']}
                      className="w-4 h-4 text-gray-600"
                    />
                  </div>
                }
              >
                <span className="text-lg">WEB</span>
              </Tabs.Tab>
              <Tabs.Tab
                value="MBL"
                icon={
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'mobile-screen-button']}
                      className="w-4 h-4 text-gray-600"
                    />
                  </div>
                }
              >
                <span className="text-lg">MOBILE</span>
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="WEB" pt="xs">
              {/* 테이블 */}
              <div className="w-full h-152">
                <PbAgGridReact
                  refs={authWebMenuListTableRef}
                  columnDefs={authWebMenuListTable.column}
                  rowData={authWebMenuListTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                    cellRendererParams: {
                      checkbox: true,
                    } as IGroupCellRendererParams,
                  }}
                  rowSelection="multiple"
                  sizeColumnsToFit={true}
                />
              </div>

              {/*<div className="w-full h-152 mt-0">*/}
              {/*  <AgGridReact*/}
              {/*    ref={authWebMenuListTableRef}*/}
              {/*    columnDefs={authWebMenuListTable.column}*/}
              {/*    rowData={authWebMenuListTable.data}*/}
              {/*    defaultColDef={{*/}
              {/*      initialWidth: 150,*/}
              {/*      resizable: true,*/}
              {/*      sortable: true,*/}
              {/*      wrapHeaderText: false,*/}
              {/*      autoHeaderHeight: true,*/}
              {/*      suppressSizeToFit: false,*/}
              {/*      cellRendererParams: {*/}
              {/*        checkbox: true,*/}
              {/*      } as IGroupCellRendererParams,*/}
              {/*    }}*/}
              {/*    onGridReady={(event) => event.api.sizeColumnsToFit()}*/}
              {/*    rowSelection="multiple"*/}
              {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
              {/*    className="ag-theme-alpine"*/}
              {/*  ></AgGridReact>*/}
              {/*</div>*/}
            </Tabs.Panel>
            <Tabs.Panel value="MBL" pt="xs">
              {/* 테이블 */}
              <div className="w-full h-152">
                <PbAgGridReact
                  refs={authMblMenuListTableRef}
                  columnDefs={authMblMenuListTable.column}
                  rowData={authMblMenuListTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                    cellRendererParams: {
                      checkbox: true,
                    } as IGroupCellRendererParams,
                  }}
                  rowSelection="multiple"
                  sizeColumnsToFit={true}
                />
              </div>

              {/*<div className="w-full h-152 mt-0">*/}
              {/*  <AgGridReact*/}
              {/*    ref={authMblMenuListTableRef}*/}
              {/*    columnDefs={authMblMenuListTable.column}*/}
              {/*    rowData={authMblMenuListTable.data}*/}
              {/*    defaultColDef={{*/}
              {/*      initialWidth: 150,*/}
              {/*      resizable: true,*/}
              {/*      sortable: true,*/}
              {/*      wrapHeaderText: false,*/}
              {/*      autoHeaderHeight: true,*/}
              {/*      suppressSizeToFit: false,*/}
              {/*      cellRendererParams: {*/}
              {/*        checkbox: true,*/}
              {/*      } as IGroupCellRendererParams,*/}
              {/*    }}*/}
              {/*    onGridReady={(event) => event.api.sizeColumnsToFit()}*/}
              {/*    rowSelection="multiple"*/}
              {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
              {/*    className="ag-theme-alpine"*/}
              {/*  ></AgGridReact>*/}
              {/*</div>*/}
            </Tabs.Panel>
          </Tabs>
        </PbSection>
        {/* 테이블 */}
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 신규 상태 */}
        {!thrtGrpId && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveButton_onClick}
            >
              저장
            </Button>
          </>
        )}

        {/* 조회 상태 */}
        {thrtGrpId && !useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleGoModifyButton_onClick}
            >
              수정 전환
            </Button>
          </>
        )}

        {/* 수정 상태 */}
        {thrtGrpId && useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              variant="outline"
              color="pink"
              radius="xl"
              size="md"
              onClick={handleRemoveButton_onClick}
            >
              삭제
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveUpdateButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220860;
