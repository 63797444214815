import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useDownloadExcel } from 'react-export-table-to-excel';
import _ from 'lodash';
import {
  IApiResult,
  ILeftMenu,
  ILoginUser,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import { Button, TextInput } from '@mantine/core';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { PbAgGridReact } from '../components/PbAgGridReact';
import MN2302220880 from '../components/MN2302220880/MN2302220880.component';
import * as appUtil from '../utils/app.util';
import * as grpApi from '../apis/grp.api';
import { ValueFormatterParams } from 'ag-grid-community';
import { decode } from 'html-entities';

/**
 * 시스템 관리 > 설치 그룹 관리
 * @constructor
 */
const MN2302220808Page = () => {
  /**
   * 저장소
   */

  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**
   * 상단바
   */

  // 등록 버튼을 클릭함
  const handleRegButton_onClick = () => {
    let tmpId: string = 'MN2302220880';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '설치 그룹 등록',
      content: <MN2302220880 id={tmpId} />,
      size: 1000,
    });
  };

  /**
   * 검색
   */

  // 검색어 입력을 정의함
  const [searchTextInput, setSearchTextInput] = useState<string>('');
  const searchTextInputRef = useRef<string>('');

  // 검색어 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchTextInput(event.target.value);
    searchTextInputRef.current = event.target.value;
  };

  // 검색어 입력에서 키를 입력함
  const handleSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 검색 버튼을 클릭함
  const handleSearchButton_onClick = () => {
    // 테이블의 페이지를 변경함
    searchResultTablePagingCurrentPageRef.current = 1;
    setSearchResultTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: searchResultTablePagingCurrentPageRef.current,
    }));

    // 테이블의 데이터를 불러옴
    getSearchResultTableData();
  };

  // 검색 조건 초기화 버튼을 클릭함
  const handleSearchCancelButton_onClick = () => {
    // 설치 그룹명을 초기화함
    setSearchTextInput('');
    searchTextInputRef.current = '';

    // 검색 버튼을 클릭함
    handleSearchButton_onClick();
  };

  /**
   * 검색 결과 테이블
   */

  // 테이블을 정의함
  const [searchResultTableColumn, setSearchResultTableColumn] = useState<any>(
    [],
  );
  const [searchResultTable, setSearchResultTable] = useState<any>([]);
  const searchResultTableRef = useRef<any>(null);

  // 테이블 페이징을 정의함
  const [searchResultTablePaging, setSearchResultTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const searchResultTablePagingCurrentPageRef = useRef<number>(1);

  // 테이블의 로딩 여부를 정의함
  const [searchResultTableLoading, setSearchResultTableLoading] =
    useState<boolean>(false);

  // 테이블을 초기화함
  const initSearchResultTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'no',
        headerName: '번호',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'grpName',
        headerName: '설치 그룹명',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.grpName);
        },
      },
      {
        field: 'grpDscr',
        headerName: '설명',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.grpDscr);
        },
      },
      {
        field: 'grpTmCnt',
        headerName: '소속 팀 개수',
        width: 120,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'rgstDate',
        headerName: '등록일자',
        width: 130,
        suppressSizeToFit: true,
      },
    );

    // 테이블에 적용함
    setSearchResultTableColumn(tmpColumn);
  };

  // 테이블의 데이터를 불러옴
  const getSearchResultTableData = () => {
    // 검색 결과 테이블의 로딩 여부에 적용함
    setSearchResultTableLoading(true);

    grpApi
      .getGrps({
        searchKeyword: searchTextInputRef.current.trim(),
        pageSize: searchResultTablePaging.rowPerPage,
        currPageIdx: searchResultTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 테이블에 적용함
          setSearchResultTable(data.data.list);

          // 테이블을 페이징함
          setSearchResultTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }
      })
      .finally(() => {
        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      });
  };

  // 테이블의 행을 더블클릭함
  const handleSearchResultTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220880';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '설치 그룹 상세',
      content: <MN2302220880 id={tmpId} groupId={row.data.grpId} />,
      size: 1000,
    });
  };

  /**
   * 초기화
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 검색 결과 테이블을 초기화함
    initSearchResultTable();

    // 테이블의 데이터를 불러옴
    getSearchResultTableData();

    return () => {};
  }, []);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220808Page') > -1) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 테이블의 데이터를 불러옴
      getSearchResultTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220808Page');
    }

    // 검색어 입력의 값을 초기화함
    setSearchTextInput('');
    searchTextInputRef.current = '';

    return () => {};
  }, [refreshList]);

  /**
   * 렌더링
   */

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={
        <>
          {/* 버튼 */}
          <Button color="indigo" radius="xl" onClick={handleRegButton_onClick}>
            등록
          </Button>
        </>
      }
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-5">
        {/* 검색 폼 그리드 */}
        <PbFormGrid label="검색" cols={2}>
          <PbFormGridCol label="설치 그룹명" colSpan={2}>
            {/* 검색어 텍스트 입력 */}
            <TextInput
              placeholder="검색어를 입력하세요."
              size="sm"
              onChange={handleSearchInput_onChange}
              onKeyUp={handleSearchInput_onKeyUp}
              value={searchTextInput}
            />

            {/* 버튼 */}
            <div className="flex justify-center items-center space-x-2">
              {/* 검색 버튼 */}
              <Button
                color="indigo"
                radius="xl"
                onClick={handleSearchButton_onClick}
              >
                검색
              </Button>

              {/* 검색 조건 초기화 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                onClick={handleSearchCancelButton_onClick}
              >
                검색 조건 초기화
              </Button>
            </div>
          </PbFormGridCol>
        </PbFormGrid>

        {/* 검색 결과 테이블 */}
        <div className="space-y-3">
          {/* 조회 결과 개수 */}
          <div className="flex justify-between items-center space-x-5">
            <div></div>

            <div className="flex justify-center items-center space-x-5">
              <div className="flex justify-center items-center">
                <span className="text-base text-gray-600 font-bold">
                  총 {searchResultTablePaging.totalRow}건
                </span>
              </div>
            </div>
          </div>

          {/* 테이블 */}
          <div className="w-full h-160">
            <PbAgGridReact
              refs={searchResultTableRef}
              columnDefs={searchResultTableColumn}
              rowData={searchResultTable}
              loading={searchResultTableLoading}
              setLoading={setSearchResultTableLoading}
              onRowDoubleClicked={handleSearchResultTable_onRowDoubleClicked}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="single"
              sizeColumnsToFit={true}
              visiblePaging={true}
              paging={{
                totalPage: searchResultTablePaging.totalPage,
                currentPage: searchResultTablePaging.currentPage,
              }}
              onChangePage={(event: any) => {
                // 테이블의 페이지를 변경함
                searchResultTablePagingCurrentPageRef.current = event;
                setSearchResultTablePaging((pre: ITablePaging) => ({
                  ...pre,
                  currentPage: searchResultTablePagingCurrentPageRef.current,
                }));

                // 테이블의 데이터를 불러옴
                getSearchResultTableData();
              }}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220808Page;
