import React, { PropsWithChildren, useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Stepper } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import {
  IComponentModal,
  IComponentModalPageScrollTab,
  IComponentModalPageScrollTabStep,
  IPosition,
  IPositionId,
  ISizeId,
} from '../../interfaces/app.interface';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  modifySizeComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import { useElementSize } from '@mantine/hooks';
import sleep from 'sleep-promise';
import { ComponentModal } from '../ComponentModal';

interface IComponentModalManagerProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 컴포넌트 모달 매니저
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const ComponentModalManager = ({
  data,
  onClick,
}: PropsWithChildren<IComponentModalManagerProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 컴포넌트 모달의 크기를 정의함
  const {
    ref: componentModalRef,
    width: componentModalWidth,
    height: componentModalHeight,
  } = useElementSize();

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 크기를 수정할 컴포넌트 모달 저장소를 정의함
  const [modifySizeComponentModal, setModifySizeComponentModal] =
    useRecoilState<ISizeId | null>(modifySizeComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 컴포넌트 모달의 출력 여부를 정의함
  const [visibleComponentModal, setVisibleComponentModal] = useState<string[]>(
    [],
  );

  // 컴포넌트 모달을 닫음
  const handleModal_onClose = (id: string) => {
    setRemoveComponentModal(id);
  };

  // 컴포넌트 모달의 새 최상단 위치를 불러옴
  const getMaxZIndex = (): number => {
    // 최상단의 컴포넌트 모달을 불러옴
    let maxZIndex = _.maxBy(componentModal, 'zIndex') as IComponentModal;

    // 컴포넌트 모달이 하나도 없을 경우에는 기본값을 적용함
    if (maxZIndex === undefined) {
      return 200;
    }

    return maxZIndex.zIndex! + 1;
  };

  useEffect(() => {
    // 화면의 최상단으로 스크롤함
    window.scrollTo(0, 0);
  }, []);

  // 컴포넌트 모달이 추가됨
  useEffect(() => {
    if (addComponentModal === null) {
      return;
    }

    // 컴포넌트 모달 아이디를 지정했을 경우, 중복되는 레이어라면 새 레이어로 추가하지 않음
    if (addComponentModal.id !== undefined || addComponentModal.id !== '') {
      let findLayer = _.find(componentModal, {
        id: addComponentModal.id,
      }) as IComponentModal;

      if (findLayer !== undefined) {
        return;
      }
    }

    // 컴포넌트 모달 기본값을 생성함
    let tmpComponentModal: IComponentModal = {
      id: addComponentModal.id || nanoid(),
      title: addComponentModal.title || '제목 없음',
      content: addComponentModal.content || <></>,
      size: addComponentModal.size || 'xl',
      button: addComponentModal.button || <></>,
      closeOnClickOutside: addComponentModal.closeOnClickOutside || false,
      centered:
        addComponentModal.centered === undefined
          ? true
          : addComponentModal.centered,
      zIndex: addComponentModal.zIndex || getMaxZIndex(),
    };

    (async () => {
      await sleep(100);

      // 컴포넌트 모달을 추가함
      setComponentModal((pre: IComponentModal[]) => [
        ...pre,
        tmpComponentModal,
      ]);

      // 추가한 컴포넌트 모달을 임시 공간에서 삭제함
      setAddComponentModal(null);

      // 컴포넌트 모달의 출력 여부에 추가함
      setVisibleComponentModal((pre: string[]) => [
        ...pre,
        tmpComponentModal.id!,
      ]);
    })();
  }, [addComponentModal]);

  // 크기를 수정할 컴포넌트 모달이 수정됨
  useEffect(() => {
    if (modifySizeComponentModal === null) {
      return;
    }

    // 대상 컴포넌트 모달의 순서를 불러옴
    let componentModalIndex: number = _.findIndex(componentModal, {
      id: modifySizeComponentModal?.id,
    });

    (async () => {
      // 컴포넌트 모달을 수정함
      setComponentModal((pre: IComponentModal[]) => [
        ...pre.slice(0, componentModalIndex),
        {
          ...pre[componentModalIndex],
          viewSize: {
            width: modifySizeComponentModal.width,
            height: modifySizeComponentModal.height,
          },
        },
        ...pre.slice(componentModalIndex + 1),
      ]);

      // 컴포넌트 모달을 임시 공간에서 삭제함
      setModifySizeComponentModal(null);
    })();
  }, [modifySizeComponentModal]);

  // 컴포넌트 모달이 삭제됨
  useEffect(() => {
    if (removeComponentModal === null) {
      return;
    }

    // 삭제할 컴포넌트 모달의 순서를 불러옴
    let removeComponentModalIndex: number = _.findIndex(componentModal, {
      id: removeComponentModal,
    });

    (async () => {
      // 컴포넌트 모달 출력 여부에서 삭제함
      setVisibleComponentModal((pre: string[]) => [
        ...pre.slice(0, removeComponentModalIndex),
        ...pre.slice(removeComponentModalIndex + 1),
      ]);

      await sleep(100);

      // 컴포넌트 모달을 삭제함
      setComponentModal((pre: IComponentModal[]) => [
        ...pre.slice(0, removeComponentModalIndex),
        ...pre.slice(removeComponentModalIndex + 1),
      ]);

      // 삭제한 컴포넌트 모달을 임시 공간에서 삭제함
      setRemoveComponentModal(null);
    })();
  }, [removeComponentModal]);

  // 전체 컴포넌트 모달이 삭제됨
  useEffect(() => {
    if (!removeAllComponentModal) {
      return;
    }

    // 컴포넌트 모달을 삭제함
    setComponentModal([]);

    // 삭제한 전체 컴포넌트 모달을 임시 공간에서 삭제함
    setRemoveAllComponentModal(false);
  }, [removeAllComponentModal]);

  // 컴포넌트 모달에 페이지 스크롤 탭이 추가됨
  useEffect(() => {
    if (addPageScrollTabComponentModal === null) {
      return;
    }

    // 대상 컴포넌트 모달의 순서를 불러옴
    let componentModalIndex: number = _.findIndex(componentModal, {
      id: addPageScrollTabComponentModal.id,
    });

    (async () => {
      // 컴포넌트 모달을 수정함
      setComponentModal((pre: IComponentModal[]) => [
        ...pre.slice(0, componentModalIndex),
        {
          ...pre[componentModalIndex],
          pageScrollTab: addPageScrollTabComponentModal.tab,
        },
        ...pre.slice(componentModalIndex + 1),
      ]);

      // 컴포넌트 모달을 임시 공간에서 삭제함
      setAddPageScrollTabComponentModal(null);
    })();
  }, [addPageScrollTabComponentModal]);

  // 컴포넌트 모달에 페이지 스크롤 탭이 추가됨
  useEffect(() => {
    if (selectPageScrollTabComponentModal === null) {
      return;
    }

    // 대상 컴포넌트 모달의 순서를 불러옴
    let componentModalIndex: number = _.findIndex(componentModal, {
      id: selectPageScrollTabComponentModal.id,
    });

    (async () => {
      // 컴포넌트 모달을 수정함
      setComponentModal((pre: IComponentModal[]) => [
        ...pre.slice(0, componentModalIndex),
        {
          ...pre[componentModalIndex],
          pageScrollTab: {
            active: selectPageScrollTabComponentModal.active,
            tab: pre[componentModalIndex].pageScrollTab!.tab,
          },
        },
        ...pre.slice(componentModalIndex + 1),
      ]);

      // 컴포넌트 모달을 임시 공간에서 삭제함
      setSelectPageScrollTabComponentModal(null);
    })();
  }, [selectPageScrollTabComponentModal]);

  return (
    <>
      {componentModal.map((item: IComponentModal, index: number) => (
        // <ComponentModal
        //   key={index}
        //   item={item}
        //   visibleComponentModal={visibleComponentModal}
        // />
        <Modal
          key={index}
          id={item.id}
          opened={_.indexOf(visibleComponentModal, item.id) > -1 ? true : false}
          onClose={() => handleModal_onClose(item.id!)}
          centered={item.centered}
          closeOnClickOutside={item.closeOnClickOutside}
          withCloseButton={false}
          title={
            <div
              style={{
                padding: '1.2rem 1.5rem',
              }}
              className="flex justify-between items-center border border-b"
            >
              {/* 제목 */}
              <div className="flex justify-center items-center truncate">
                <span className="text-2xl font-bold text-gray-800">
                  {item.title}
                </span>
              </div>

              <div className="flex justify-center items-center space-x-7">
                {/* 페이지 스크롤 탭 */}
                <div id="page-scroll-tab">
                  {item.pageScrollTab !== null && (
                    <>
                      <div className="flex justify-center items-center space-x-5">
                        {item.pageScrollTab?.tab.map(
                          (
                            tabItem: IComponentModalPageScrollTabStep,
                            tabIndex: number,
                          ) => (
                            <div
                              onClick={() => {
                                setSelectPageScrollTabComponentModal({
                                  id: item.id!,
                                  active: tabItem.targetId,
                                });
                              }}
                              className="button-event flex justify-center items-center space-x-2"
                            >
                              {/* 번호 */}
                              <div
                                className={[
                                  'w-10 h-10 flex justify-center items-center rounded-full',
                                  item.pageScrollTab?.active ===
                                  tabItem.targetId
                                    ? 'object-appear bg-indigo-400'
                                    : ' bg-gray-100',
                                ].join(' ')}
                              >
                                <span
                                  className={[
                                    'font-bold',
                                    item.pageScrollTab?.active ===
                                    tabItem.targetId
                                      ? 'text-xl text-white'
                                      : 'text-sm text-gray-800',
                                  ].join(' ')}
                                >
                                  {tabIndex + 1}
                                </span>
                              </div>

                              {/* 탭 이름 */}
                              <div className="flex justify-center items-center">
                                <span className="text-md font-bold text-gray-800">
                                  {tabItem.label}
                                </span>
                              </div>
                            </div>
                          ),
                        )}
                      </div>

                      {/*<Stepper*/}
                      {/*  active={item.pageScrollTab?.active || 0}*/}
                      {/*  onStepClick={(step: number) => {*/}
                      {/*    setSelectPageScrollTabComponentModal({*/}
                      {/*      id: item.id!,*/}
                      {/*      active: step,*/}
                      {/*    });*/}
                      {/*  }}*/}
                      {/*  breakpoint="sm"*/}
                      {/*  completedIcon={*/}
                      {/*    <div className="w-full h-full flex justify-center items-center">*/}
                      {/*      <FontAwesomeIcon*/}
                      {/*        icon={['fas', 'chevron-right']}*/}
                      {/*        className="w-3 h-3 text-blue-300"*/}
                      {/*      />*/}
                      {/*    </div>*/}
                      {/*  }*/}
                      {/*>*/}
                      {/*  {item.pageScrollTab?.tab.map(*/}
                      {/*    (*/}
                      {/*      tabItem: IComponentModalPageScrollTabStep,*/}
                      {/*      tabIndex: number,*/}
                      {/*    ) => (*/}
                      {/*      <Stepper.Step*/}
                      {/*        key={tabIndex}*/}
                      {/*        label={tabItem.label}*/}
                      {/*        // description=""*/}
                      {/*      ></Stepper.Step>*/}
                      {/*    ),*/}
                      {/*  )}*/}
                      {/*</Stepper>*/}
                    </>
                  )}
                </div>

                {/* 모달 닫기 버튼 */}
                <div
                  onClick={() => handleModal_onClose(item.id!)}
                  className="button-event p-0.5 flex justify-center items-center hover:bg-gray-100 rounded-md"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'close']}
                    className="w-7 h-7 text-gray-500"
                  />
                </div>
              </div>
            </div>
          }
          size={item.size || 'md'}
          padding={0}
          radius="md"
          // padding={item.padding || 'xl'}
          closeOnEscape={false}
          transitionProps={{ transition: 'pop' }}
          // overlayProps={{
          //   // color: 'rgb(0, 0, 0)',
          //   opacity: 0.5,
          //   blur: 2,
          // }}
          zIndex={item.zIndex}
        >
          {/* 모달 내용 */}
          <div className="w-full pt-1">
            {item.content && <div className="px-2">{item.content}</div>}
          </div>
        </Modal>
      ))}
    </>
  );
};

export default ComponentModalManager;
