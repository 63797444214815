import React, { PropsWithChildren, useMemo } from 'react';

interface IDefaultButtonProps {
  backgroundColor?:
    | 'black'
    | 'white'
    | 'dark'
    | 'gray'
    | 'lightGray'
    | 'red'
    | 'pink'
    | 'purple'
    | 'violet'
    | 'indigo'
    | 'blue'
    | 'cyan'
    | 'teal'
    | 'green'
    | 'lime'
    | 'yellow'
    | 'orange';
  labelColor?: 'black' | 'white';
  borderColor?:
    | 'none'
    | 'black'
    | 'white'
    | 'dark'
    | 'gray'
    | 'lightGray'
    | 'red'
    | 'pink'
    | 'purple'
    | 'violet'
    | 'indigo'
    | 'blue'
    | 'cyan'
    | 'teal'
    | 'green'
    | 'lime'
    | 'yellow'
    | 'orange';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  radius?: 'none' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | 'full';
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

/**
 * 버튼
 * @param backgroundColor 버튼 배경 색상
 * @param labelColor 버튼 문구 색상
 * @param borderColor 버튼 테두리 색상
 * @param size 버튼 크기
 * @param radius 버튼 모서리 형태
 * @param disabled 버튼 비활성화 여부
 * @param onClick 버튼 클릭 이벤트
 * @param children 기타 props
 * @param className 스타일
 * @constructor
 */
const DefaultButton = ({
  backgroundColor = 'blue',
  labelColor = 'white',
  borderColor = 'none',
  size = 'sm',
  radius = 'sm',
  disabled = false,
  onClick,
  className = '',
  children,
}: PropsWithChildren<IDefaultButtonProps>) => {
  // 버튼의 배경 색상을 정의함
  const backgroundColorStyle: string = useMemo(() => {
    switch (backgroundColor) {
      case 'black':
        return 'bg-black';

      case 'white':
        return 'bg-white';

      case 'dark':
        return 'bg-gray-700';

      case 'gray':
        return 'bg-gray-400';

      case 'lightGray':
        return 'bg-gray-300';

      case 'red':
        return 'bg-red-500';

      case 'pink':
        return 'bg-pink-500';

      case 'purple':
        return 'bg-purple-500';

      case 'violet':
        return 'bg-violet-500';

      case 'indigo':
        return 'bg-indigo-500';

      case 'blue':
        return 'bg-blue-500';

      case 'cyan':
        return 'bg-cyan-500';

      case 'teal':
        return 'bg-teal-500';

      case 'green':
        return 'bg-green-500';

      case 'lime':
        return 'bg-lime-500';

      case 'yellow':
        return 'bg-yellow-400';

      case 'orange':
        return 'bg-orange-400';

      default:
        return 'bg-blue-500';
    }
  }, [backgroundColor]);

  // 버튼의 문구 색상을 정의함
  const labelColorStyle: string = useMemo(() => {
    switch (labelColor) {
      case 'black':
        return 'text-black';

      case 'white':
        return 'text-white';

      default:
        return 'text-white';
    }
  }, [labelColor]);

  // 버튼의 테두리 색상을 정의함
  const borderColorStyle: string = useMemo(() => {
    switch (borderColor) {
      case 'none':
        return '';

      case 'black':
        return 'border-black';

      case 'white':
        return 'border-white';

      case 'dark':
        return 'border-gray-700';

      case 'gray':
        return 'border-gray-400';

      case 'lightGray':
        return 'border-gray-300';

      case 'red':
        return 'border-red-500';

      case 'pink':
        return 'border-pink-500';

      case 'purple':
        return 'border-purple-500';

      case 'violet':
        return 'border-violet-500';

      case 'indigo':
        return 'border-indigo-500';

      case 'blue':
        return 'border-blue-500';

      case 'cyan':
        return 'border-cyan-500';

      case 'teal':
        return 'border-teal-500';

      case 'green':
        return 'border-green-500';

      case 'lime':
        return 'border-lime-500';

      case 'yellow':
        return 'border-yellow-400';

      case 'orange':
        return 'border-orange-400';

      default:
        return 'border-blue-500';
    }
  }, [borderColor]);

  // 버튼의 크기를 정의함
  const sizeStyle: string = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'h-6 px-3 text-xs';

      case 'sm':
        return 'h-7 px-4 text-sm';

      case 'md':
        return 'h-8 px-5 text-base';

      case 'lg':
        return 'h-9 px-6 text-lg';

      case 'xl':
        return 'h-10 px-7 text-xl';

      default:
        return 'h-7 px-4 text-sm';
    }
  }, [size]);

  // 버튼의 모서리 형태를 정의함
  const radiusStyle: string = useMemo(() => {
    switch (radius) {
      case 'none':
        return 'rounded-none';

      case 'sm':
        return 'rounded-sm';

      case 'base':
        return 'rounded';

      case 'md':
        return 'rounded-md';

      case 'lg':
        return 'rounded-lg';

      case 'xl':
        return 'rounded-xl';

      case 'full':
        return 'rounded-full';

      default:
        return 'rounded-sm';
    }
  }, [radius]);

  return (
    <button
      type="button"
      className={[
        backgroundColorStyle,
        labelColorStyle,
        borderColorStyle && 'border',
        borderColorStyle,
        sizeStyle,
        radiusStyle,
        'flex justify-center items-center leading-none',
        'enabled:active:translate-y-px',
        'disabled:opacity-60',
        className,
      ].join(' ')}
      disabled={disabled}
      onClick={onClick}
    >
      {children || 'Button'}
    </button>
  );
};

export default DefaultButton;
