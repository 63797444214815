import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import { AgGridReact } from 'ag-grid-react';
import { ICellRendererParams } from 'ag-grid-community';
import PbSection from '../PbSection/PbSection.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  Textarea,
  FileInput,
  Checkbox,
  Group,
  Radio,
  Anchor,
  FileButton,
} from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PageLayout } from '../../components/PageLayout';
import { PbFormGrid } from '../../components/PbFormGrid';
import { PbFormGridCol } from '../../components/PbFormGridCol';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../../stores/refreshList.store';
import { PreviewImage } from '../PreviewImage';
import * as appUtil from '../../utils/app.util';
import { HtmlEditor } from '../HtmlEditor';
import { useElementSize } from '@mantine/hooks';
import { decode, encode } from 'html-entities';
import { overlayStore } from '../../stores/overlay.store';
import { notifications } from '@mantine/notifications';
import * as ntcbApi from '../../apis/ntcb.api';
import * as cmnApi from '../../apis/cmn.api';
import { PbAgGridReact } from '../PbAgGridReact';
import PbSelect from '../PbSelect/PbSelect.component';
import { FlexImageFilePreviewList } from '../FlexImageFilePreviewList';
import * as slsApi from '../../apis/sls.api';

interface IMN2302220711Props {
  id?: string;
  brdId?: string;
  useModifyMode?: boolean;
  data?: any;
  onClick?: () => void;
}

/**
 * 게시판 > 메뉴얼 > 등록,상세,수정
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220711 = ({
  id = '',
  brdId = '',
  useModifyMode = false,
  data,
  onClick,
}: PropsWithChildren<IMN2302220711Props>) => {
  // 언어를 정의함
  const { t } = useTranslation();
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);
  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 화면 위 검은 화면 저장소를 정의함
  const [visibleOverlay, setVisibleOverlay] =
    useRecoilState<boolean>(overlayStore);

  /**************************** API ****************************/
  //메뉴얼 유형 공통코드 조회
  const getManualTypeCode = () => {
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'MNL_TYPE',
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          let tmpOptionItem: IOptionItem[] = [];
          // tmpOptionItem.push({
          //   label: '전체',
          //   value: '',
          // });
          for (var code of data.data.list) {
            tmpOptionItem.push({
              label: code.dtlCodeDscr,
              value: code.dtlCodeId.toLowerCase(),
            });
          }
          // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
          setManualCategorySelect('esl_sw');
          setManualCategorySelectItem(tmpOptionItem);
          // setManualCategorySelect({
          //   value: 'esl_sw',
          //   item: tmpOptionItem,
          // });
        }
      });
  };

  //메뉴얼 상세 조회
  const getManualDetail = (brdId: string) => {
    ntcbApi
      .getManual({
        brdId: brdId,
      })
      .then((data: IApiResult) => {
        if (data.data != null) {
          setManualTitleInput(data.data.ttl);
          setManualCategorySelect(data.data.mnlTypeCode);
          // setManualCategorySelect((pre: ISelect) => ({
          //   ...pre,
          //   value: data.data.mnlTypeCode,
          // }));
          setManualCategoryInput(data.data.mnlTypeName);
          setManualRgsterInput(data.data.rgstName);
          // setManualDescInput(data.data.dtl);
          setManualDescInput(decode(data.data.dtl));
          setManualRgstDate(data.data.rgstDate);
          setManualModifyDate(data.data.mdfcDate);

          //파일 데이터
          data.data.fileList.map((item: any, index: number) => {
            item['newFile'] = null;
          });
          setFileTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.fileList,
          }));

          // 이미지 미리보기용 첨부파일 목록을 정의함
          let tmpFileList: any[] = [];

          // 이미지 미리보기용 첨부파일 목록을 생성함
          data.data.fileList.map((fileItem: any) => {
            tmpFileList.push({
              id: fileItem.fileId,
              originalFileName: fileItem.rgnlFileName,
              saveFileName: fileItem.pldFileName,
              size: fileItem.fileSize,
            });
          });

          // 이미지 미리보기용 첨부파일 목록에 적용함
          setPreviewFileImages(tmpFileList);

          //링크 데이터
          setLinkTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.linkList,
          }));
        }
      });
  };

  //메뉴얼 신규 저장
  const saveManual = (tmpNewFile: any[], tmpNewFileDescriptions: any[]) => {
    // 화면 위의 검은 화면 출력 여부에 적용함
    setVisibleOverlay(true);

    notifications.show({
      title: '알림',
      message: '저장 중입니다.',
    });

    ntcbApi
      .postManual({
        ttl: manualTitleInput,
        // dtl: manualDescInput,
        dtl: encode(manualDescInput.trim() || ''),
        mnlTypeCode: manualCategorySelect,
        fileList: tmpNewFile,
        fileDscrList: tmpNewFileDescriptions,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 화면 위의 검은 화면 출력 여부에 적용함
          setVisibleOverlay(false);

          notifications.show({
            title: '알림',
            message: '저장하였습니다.',
          });

          // 목록 새로고침을 추가함
          setAddRefreshList('MN2302220701Table');
          // 컴포넌트 모달을 닫음
          setRemoveComponentModal(id);

          // setModal({
          //   title: '알림',
          //   content: '저장하였습니다.',
          //   callback: () => {
          //     // 목록 새로고침을 추가함
          //     setAddRefreshList('MN2302220701Table');
          //     // 컴포넌트 모달을 닫음
          //     setRemoveComponentModal(id);
          //   },
          // });
        } else {
          console.log('> 메뉴얼 등록 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>저장에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      })
      .catch((error: any) => {
        // 화면 위의 검은 화면 출력 여부에 적용함
        setVisibleOverlay(false);

        setModal({
          title: '오류',
          content: `저장에 실패하였습니다.(${error.message})`,
        });
      });
  };

  //메뉴얼 수정 저장
  const modifyManual = (
    bfFileId: any[],
    bfFileDescriptions: any[],
    tmpNewFile: any[],
    tmpNewFileDescriptions: any[],
  ) => {
    // 화면 위의 검은 화면 출력 여부에 적용함
    setVisibleOverlay(true);

    notifications.show({
      title: '알림',
      message: '저장 중입니다.',
    });

    ntcbApi
      .putManual({
        brdId: brdId,
        mnlTypeCode: manualCategorySelect,
        // linkUrlList: params.linkUrlList,
        // linkDscrList: params.linkDscrList,
        bfFileIdList: bfFileId,
        bfFileDscrList: bfFileDescriptions,
        fileList: tmpNewFile,
        fileDscrList: tmpNewFileDescriptions,
        ttl: manualTitleInput,
        // dtl: manualDescInput,
        dtl: encode(manualDescInput.trim() || ''),
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 화면 위의 검은 화면 출력 여부에 적용함
          setVisibleOverlay(false);

          notifications.show({
            title: '알림',
            message: '저장하였습니다.',
          });

          // 목록 새로고침
          setAddRefreshList('MN2302220701Table');
          // 컴포넌트 모달을 닫음
          setRemoveComponentModal(id);
          setTimeout(() => {
            // 조회 컴포넌트 모달을 추가함
            let tmpId: string = nanoid();
            // 컴포넌트 모달을 추가함
            setAddComponentModal({
              id: tmpId,
              title: '메뉴얼 상세',
              content: (
                <MN2302220711 id={tmpId} brdId={brdId} useModifyMode={false} />
              ),
              size: 1500,
            });
          }, 100);

          // setModal({
          //   title: '알림',
          //   content: '저장하였습니다.',
          //   callback: () => {
          //     // 목록 새로고침
          //     setAddRefreshList('MN2302220701Table');
          //     // 컴포넌트 모달을 닫음
          //     setRemoveComponentModal(id);
          //     setTimeout(() => {
          //       // 조회 컴포넌트 모달을 추가함
          //       let tmpId: string = nanoid();
          //       // 컴포넌트 모달을 추가함
          //       setAddComponentModal({
          //         id: tmpId,
          //         title: '메뉴얼 상세',
          //         content: (
          //           <MN2302220711
          //             id={tmpId}
          //             brdId={brdId}
          //             useModifyMode={false}
          //           />
          //         ),
          //         size: 1500,
          //       });
          //     }, 100);
          //   },
          // });
        } else {
          // 화면 위의 검은 화면 출력 여부에 적용함
          setVisibleOverlay(false);

          setModal({
            title: '오류',
            content: (
              <>
                <div>저장에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  //메뉴얼 삭제
  const deleteManual = () => {
    ntcbApi
      .deleteManual({
        brdId: brdId,
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          case '200':
            // 목록 새로고침을 추가함
            setAddRefreshList('MN2302220701Table');
            // 모달을 출력함
            setModal({
              title: '알림',
              content: '삭제하였습니다.',
              callback: () => setRemoveComponentModal(id),
            });
            break;
          case '':
            console.log('메뉴얼 삭제 실패 : ' + data);
            // 모달을 출력함
            setModal({
              title: t('common.error'),
              content: '삭제에 실패하였습니다.',
            });
            break;
          default:
            break;
        }
      });
  };

  //파일 다운로드
  const fileDownload = (fileId: string, rgnlFileName: string) => {
    ntcbApi
      .getNncmFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  /**************************** STATE ****************************/
  const [manualTitleInput, setManualTitleInput] = useState<string>(''); //메뉴얼 제목
  const manualTitleInputRef = useRef<HTMLInputElement>(null);

  const [manualCategorySelect, setManualCategorySelect] = useState<string>('');
  const [manualCategorySelectItem, setManualCategorySelectItem] = useState<
    IOptionItem[]
  >([]);

  const [manualCategoryInput, setManualCategoryInput] = useState<string>(''); //메뉴얼 유형
  const [manualRgsterInput, setManualRgsterInput] = useState<string>(''); //메뉴얼 등록자
  const [manualDescInput, setManualDescInput] = useState<string>(''); //메뉴얼 설명
  const manualDescInputRef = useRef<HTMLInputElement>(null);

  const [manualRgstDate, setManualRgstDate] = useState<string>(''); //메뉴얼 등록일
  const [manualModifyDate, setManualModifyDate] = useState<string>(''); //메뉴얼 수정일

  //메뉴얼 내역
  //메뉴얼 내역 테이블 (파일)
  const [fileTable, setFileTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  // 첨부 파일 섹션 > 테이블 참조를 정의함
  const fileTableRef = useRef<any>(null);

  // 첨부 파일 섹션 > 미리보기 테이블을 정의함
  const [previewFileImages, setPreviewFileImages] = useState<any[]>([]);

  //메뉴얼 내역
  //메뉴얼 내역 테이블 (링크)
  const [linkTable, setLinkTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  // 첨부 파일 섹션 > 테이블 참조를 정의함
  const linkTableRef = useRef<any>(null);

  // 에디터의 크기를 정의함
  const {
    ref: editorRef,
    width: editorWidth,
    height: editorHeight,
  } = useElementSize();

  /**************************** INIT ****************************/
  //메뉴얼 유형 셀렉트 초기화
  const initManualCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];
    tmpOptionItem.push(
      {
        label: '네트워크',
        value: 'NTWR',
      },
      {
        label: 'ESL(S/W)',
        value: 'ESL_SW',
      },
      {
        label: 'ESL(H/W)',
        value: 'ESL_HW',
      },
      {
        label: '기타',
        value: 'ETC',
      },
    );
    // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
    setManualCategorySelect('ESL_SW');
    setManualCategorySelectItem(tmpOptionItem);
    // setManualCategorySelect({
    //   value: 'ESL_SW',
    //   item: tmpOptionItem,
    // });
  };

  //메뉴얼 내역 테이블 초기화 (파일)
  const initFileTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 등록, 수정일 경우에만 적용함
    if (!(brdId && !useModifyMode)) {
      // 컬럼에 추가함
      tmpColumn.push({
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      });
    }

    // 컬럼에 추가함
    tmpColumn.push(
      // {
      //   width: 50,
      //   checkboxSelection: true,
      //   headerCheckboxSelection: true,
      // },
      {
        headerName: '파일명',
        field: 'rgnlFileName',
        editable: false,
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center">
            <div>
              <span
                onClick={() =>
                  fileDownload(params.data.fileId, params.data.rgnlFileName)
                }
                title={`다운로드: ${params.data.rgnlFileName}`}
                className="cursor-pointer hover:underline"
              >
                {params.value}
              </span>
            </div>
          </div>
        ),
      },
      // {
      //   headerName: '설명',
      //   width: 300,
      //   field: 'fileDscr',
      //   suppressSizeToFit: true,
      // },
      {
        headerName: '용량',
        field: 'fileSize',
        width: 150,
        editable: false,
        valueFormatter: (params: any) => prettyBytes(_.toNumber(params.value)),
        suppressSizeToFit: true,
        type: 'rightAligned',
      },
      // {
      //   headerName: '다운로드',
      //   field: 'fileId',
      //   width: 100,
      //   hide: brdId && !useModifyMode ? false : true,
      //   cellRenderer: (params: any) => {
      //     var item = params.data;
      //     let fileDownloadIcon: JSX.Element = <></>;
      //     fileDownloadIcon = (
      //       <div
      //         onClick={() => fileDownload(item.fileId, item.rgnlFileName)}
      //         className="button-event flex justify-center items-center"
      //       >
      //         <FontAwesomeIcon
      //           icon={['far', 'file']}
      //           className="w-5 h-5 text-gray-500"
      //         />
      //       </div>
      //     );
      //     return (
      //       <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center space-x-3">
      //         {fileDownloadIcon}
      //       </div>
      //     );
      //   },
      //   suppressSizeToFit: true,
      // },
      // {
      //   headerName: '첨부파일 미리보기',
      //   field: 'fileList',
      //   width: 200,
      //   cellRenderer: (params: any) => {
      //     let item: any = params.data;
      //
      //     // 이미지 파일의 확장자를 정의함
      //     let tmpImageFileExt: string[] = [
      //       '.jpg',
      //       '.jpeg',
      //       '.png',
      //       '.gif',
      //       '.bmp',
      //       'tif',
      //     ];
      //
      //     // 첨부파일에서 확장자를 추출함
      //     let tmpFileExt: string = _.padEnd(item.rgnlFileName, 5);
      //
      //     let result: any = <></>;
      //
      //     // 이미지 파일의 확장자가 이미지인지 확인함
      //     tmpImageFileExt.map((imageItem: string) => {
      //       if (_.includes(tmpFileExt, imageItem)) {
      //         console.log('> newFile:', item);
      //
      //         result = (
      //           <div
      //             onClick={() => {
      //               let tmpId: string = nanoid();
      //
      //               // 컴포넌트 모달을 추가함
      //               setAddComponentModal({
      //                 id: tmpId,
      //                 title: '이미지 미리보기',
      //                 content: (
      //                   <PreviewImage
      //                     data={`${appUtil.getResizeImageUrl()}${
      //                       item.pldFileName
      //                     }`}
      //                   />
      //                 ),
      //                 // size: 1900,
      //                 closeOnClickOutside: true,
      //               });
      //             }}
      //             title={`${item.slsfClsfName}: ${item.rgnlFileName}`}
      //             className="button-event w-9 h-9 p-px flex justify-center items-center border"
      //           >
      //             {item.newFile ? (
      //               <img
      //                 src={URL.createObjectURL(item.newFile)}
      //                 className="w-full"
      //               />
      //             ) : (
      //               <img
      //                 src={`${appUtil.getThumbnailImageUrl()}${
      //                   item.pldFileName
      //                 }`}
      //                 className="w-full"
      //               />
      //             )}
      //           </div>
      //         );
      //
      //         return;
      //       }
      //     });
      //
      //     return (
      //       <div className="h-full flex justify-start items-center space-x-3">
      //         {result}
      //       </div>
      //     );
      //   },
      //   suppressSizeToFit: true,
      // },
    );

    setFileTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  //메뉴얼 내역 테이블 초기화 (링크)
  const initLinkTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: '링크 URL',
        field: 'url',
        width: 600,
        editable: false,
      },
      { headerName: '설명', field: 'linkDscr', width: 360 },
    );
    setLinkTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 첨부파일 다운로드를 클릭함
  const handleFileDownload_onClick = (fileId: string, rgnlFileName: string) => {
    ntcbApi
      .getNncmFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  /**************************** ON EVENT LISTENER ****************************/
  //메뉴얼 제목 on change
  const handleManualTitleInput_onChange = (event: any) => {
    setManualTitleInput(event.target.value);
  };
  //메뉴얼 등록자 on change
  const handleManualRgsterInput_onChange = (event: any) => {
    setManualRgsterInput(event.target.value);
  };
  //메뉴얼 유형 셀렉트 변경 이벤트
  const handleManualCategorySelect_onChange = (event: any) => {
    setManualCategorySelect(event);
    // setManualCategorySelect({
    //   ...manualCategorySelect,
    //   value: event,
    // });
  };
  //메뉴얼 설명 on change
  const handleManualDescInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 500) {
      setManualDescInput((pre) => pre);
    } else {
      setManualDescInput(sVal);
    }
  };

  // 첨부 파일 섹션 > 추가 버튼을 클릭함
  const handleFileSectionAddButton_onClick = (event: any) => {
    // 첨부 파일 목록을 불러옴
    let tmlFileTableData: any = _.cloneDeep(fileTable.data);
    // 첨부 파일 목록에 새로운 첨부 파일을 추가함
    tmlFileTableData.push({
      fileId: nanoid(),
      rgnlFileName: event.name,
      fileSize: event.size,
      fileDscr: '-',
      newFile: event,
    });
    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlFileTableData,
    }));
  };

  // 첨부 파일 섹션 > 선택 삭제 버튼을 클릭함
  const handleFileSectionSelectedDeleteButton_onClick = () => {
    // 삭제할 첨부 파일 아이디 목록을 정의함
    let selectedFileId: string[] = [];
    // 삭제할 첨부 파일 아이디 목록을 불러옴
    fileTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedFileId.push(item.fileId);
    });
    if (selectedFileId.length === 0) {
      setModal({
        title: '알림',
        content: '삭제할 첨부 파일을 체크하여 선택하세요.',
      });

      return;
    }
    setModal({
      title: '확인',
      content: '선택하신 첨부파일을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 첨부 파일 목록에서 삭제할 첨부 파일을 적용한 후 최종 목록을 정의함
            let tmlFileTableData: any = fileTable.data.filter(
              (item: any) => !_.includes(selectedFileId, item.fileId),
            );
            // 첨부 파일 섹션 > 테이블에 적용함
            setFileTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: tmlFileTableData,
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 첨부 파일 섹션 > 전체 삭제 버튼을 클릭함
  const handleFileSectionDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '전체 첨부파일을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);
            // 첨부 파일 섹션 > 테이블에 적용함
            setFileTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: [],
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  //수정 전환 버튼 이벤트
  const handleModifyBtn_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);
    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();
      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '메뉴얼 수정',
        content: <MN2302220711 id={tmpId} brdId={brdId} useModifyMode={true} />,
        size: 1500,
      });
    }, 100);
  };

  // 컴포넌트 모달 > 저장 버튼(신규)을 클릭함
  const handleAddSaveBtn_onClick = () => {
    //필수 값 확인
    if (!manualTitleInput.trim()) {
      setModal({
        title: '알림',
        content: '제목을 입력해 주세요.',
      });

      manualTitleInputRef.current?.focus();
      return;
    }
    if (!manualDescInput.trim()) {
      setModal({
        title: '알림',
        content: '내용을 입력해 주세요.',
      });

      let obj = document.querySelector(
        '.manual-desc-input .ck-content',
      ) as HTMLDivElement;
      obj.focus();

      return;
    }
    let tmpNewLink: any[] = []; // 신규 링크를 불러옴
    let tmpNewLinkDescriptions: string[] = []; // 신규 링크 설명을 불러옴
    // 신규 링크 정보를 저장함
    linkTable.data
      .filter((item: any) => item.newLink !== null)
      .map((item: any) => {
        tmpNewLink.push(item.newLink);
        tmpNewLinkDescriptions.push(item.linkDscr);
      });
    // 링크의 설명을 확인함
    let checkLinkDescriptions: boolean = false;
    linkTable.data.map((item: any) => {
      if (!item.linkDscr.trim()) {
        checkLinkDescriptions = true;
      }
    });
    // if (checkLinkDescriptions) {
    //   setModal({
    //     title: '알림',
    //     content: '링크의 설명을 입력해 주세요.',
    //   });
    //   return;
    // }

    let tmpNewFile: any[] = []; // 신규 첨부파일을 불러옴
    let tmpNewFileDescriptions: string[] = []; // 신규 첨부파일 설명을 불러옴
    // 신규 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile !== null)
      .map((item: any) => {
        tmpNewFile.push(item.newFile);
        tmpNewFileDescriptions.push(item.fileDscr);
      });

    // 첨부파일의 설명을 확인함
    let checkFileDescriptions: boolean = false;
    fileTable.data.map((item: any) => {
      if (!item.fileDscr.trim()) {
        checkFileDescriptions = true;
      }
    });
    // if (checkFileDescriptions) {
    //   setModal({
    //     title: '알림',
    //     content: '첨부 파일의 설명을 입력해 주세요.',
    //   });
    //   return;
    // }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);
            saveManual(tmpNewFile, tmpNewFileDescriptions);
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 수정의 저장 버튼 on click
  const handleModifySaveBtn_onClick = () => {
    //필수 값 확인
    if (!manualTitleInput.trim()) {
      setModal({
        title: '알림',
        content: '제목을 입력해 주세요.',
      });

      manualTitleInputRef.current?.focus();
      return;
    }
    if (!manualDescInput.trim()) {
      setModal({
        title: '알림',
        content: '내용을 입력해 주세요.',
      });

      let obj = document.querySelector(
        '.manual-desc-input .ck-content',
      ) as HTMLDivElement;
      obj.focus();

      return;
    }

    let tmpNewLink: any[] = []; // 신규 링크를 불러옴
    let tmpNewLinkDescriptions: string[] = []; // 신규 링크 설명을 불러옴
    // 신규 링크 정보를 저장함
    linkTable.data
      .filter((item: any) => item.newLink !== null)
      .map((item: any) => {
        tmpNewLink.push(item.newLink);
        tmpNewLinkDescriptions.push(item.linkDscr);
      });

    console.log(tmpNewLinkDescriptions);

    // 링크의 설명을 확인함
    let checkLinkDescriptions: boolean = false;
    linkTable.data.map((item: any) => {
      if (!item.linkDscr.trim()) {
        checkLinkDescriptions = true;
      }
    });
    // if (checkLinkDescriptions) {
    //   setModal({
    //     title: '알림',
    //     content: '링크의 설명을 입력해 주세요.',
    //   });
    //   return;
    // }

    let bfFileId: any[] = [];
    let bfFileDescriptions: string[] = [];
    let tmpNewFile: any[] = []; // 신규 첨부파일을 불러옴
    let tmpNewFileDescriptions: string[] = []; // 신규 첨부파일 설명을 불러옴
    // 신규 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile !== null)
      .map((item: any) => {
        tmpNewFile.push(item.newFile);
        tmpNewFileDescriptions.push(item.fileDscr);
      });
    // 기존 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile == null)
      .map((item: any) => {
        bfFileId.push(item.fileId);
        bfFileDescriptions.push('-');
        // bfFileDescriptions.push(item.fileDscr);
      });

    // 첨부파일의 설명을 확인함
    let checkFileDescriptions: boolean = false;
    fileTable.data.map((item: any) => {
      if (!item.fileDscr.trim()) {
        checkFileDescriptions = true;
      }
    });
    // if (checkFileDescriptions) {
    //   setModal({
    //     title: '알림',
    //     content: '첨부 파일의 설명을 입력해 주세요.',
    //   });
    //   return;
    // }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              modifyManual(
                bfFileId,
                bfFileDescriptions,
                tmpNewFile,
                tmpNewFileDescriptions,
              );
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  // 삭제 버튼 on click
  const handleDeleteBtn_onClick = () => {
    setModal({
      title: '확인',
      content: `메뉴얼 [${manualTitleInput}]을 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              deleteManual();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  //매뉴얼 내역
  //행 삭제

  // 에디터의 값이 변경됨
  const handleHetmlEditor_onChange = (event: any) => {
    setManualDescInput(event);
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    getManualTypeCode(); //메뉴얼 유형 셀렉트 초기화
    if (brdId) {
      getManualDetail(brdId);
    }

    initFileTable();
    return () => {};
  }, []);

  /**************************** 기타 Function ****************************/
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 메뉴얼 정보 폼 그리드 */}
        <PbFormGrid label="메뉴얼 정보" cols={2}>
          <PbFormGridCol label="메뉴얼 제목" colSpan={2} withAsterisk={true}>
            <TextInput
              ref={manualTitleInputRef}
              placeholder="제목을 입력하세요."
              onChange={handleManualTitleInput_onChange}
              readOnly={brdId && !useModifyMode ? true : false}
              variant={brdId && !useModifyMode ? 'unstyled' : 'default'}
              value={decode(manualTitleInput)}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="유형">
            {brdId && !useModifyMode && (
              <TextInput
                placeholder=""
                value={manualCategoryInput}
                readOnly={brdId && !useModifyMode ? true : false}
                variant={brdId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            )}
            {(!brdId || useModifyMode) && (
              <Select
                onChange={handleManualCategorySelect_onChange}
                data={manualCategorySelectItem}
                value={manualCategorySelect}
                className="w-full"
              />
            )}
          </PbFormGridCol>
          <PbFormGridCol label="등록자">
            <TextInput
              placeholder=""
              onChange={handleManualRgsterInput_onChange}
              value={!brdId ? loginUser.name : manualRgsterInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          {/*<PbFormGridCol label="내용" colSpan={2} className="h-36">*/}
          {/*  /!* 텍스트 영역 *!/*/}
          {/*  <Textarea*/}
          {/*    placeholder="내용을 입력 해주세요."*/}
          {/*    onChange={handleManualDescInput_onChange}*/}
          {/*    value={manualDescInput}*/}
          {/*    className="w-full"*/}
          {/*    readOnly={brdId && !useModifyMode ? true : false}*/}
          {/*    variant={brdId && !useModifyMode ? 'unstyled' : 'default'}*/}
          {/*    minRows={5}*/}
          {/*  />*/}
          {/*</PbFormGridCol>*/}

          {/* 설명 */}
          {/* 등록 */}
          {!brdId && (
            <PbFormGridCol
              label="내용"
              colSpan={2}
              withAsterisk={true}
              className="h-124"
            >
              {/* 에디터 */}
              <div ref={editorRef} className="manual-desc-input w-full h-full">
                <HtmlEditor
                  data={manualDescInput}
                  // width={editorWidth}
                  width={1056}
                  height={375}
                  callback={handleHetmlEditor_onChange}
                />
              </div>
            </PbFormGridCol>
          )}

          {/* 설명 */}
          {/* 수정 */}
          {brdId && useModifyMode && (
            <PbFormGridCol label="내용" colSpan={2} className="h-124">
              {/* 에디터 */}
              <div ref={editorRef} className="w-full h-full">
                {editorWidth > 0 && manualDescInput && (
                  <HtmlEditor
                    data={manualDescInput}
                    width={editorWidth}
                    height={375}
                    callback={handleHetmlEditor_onChange}
                  />
                )}
              </div>
            </PbFormGridCol>
          )}

          {/* 설명 */}
          {/* 조회 */}
          {brdId && !useModifyMode && (
            <PbFormGridCol label="내용" colSpan={2} className="">
              {/* HTML 뷰어 */}
              <div dangerouslySetInnerHTML={{ __html: manualDescInput }} />
            </PbFormGridCol>
          )}

          {brdId && (
            <>
              <PbFormGridCol label="등록일">
                <TextInput
                  placeholder=""
                  onChange={handleManualRgsterInput_onChange}
                  value={manualRgstDate}
                  readOnly={true}
                  variant="unstyled"
                  className="w-full"
                />
              </PbFormGridCol>
              <PbFormGridCol label="수정일">
                <TextInput
                  placeholder=""
                  onChange={handleManualRgsterInput_onChange}
                  value={manualModifyDate}
                  readOnly={true}
                  variant="unstyled"
                  className="w-full"
                />
              </PbFormGridCol>
            </>
          )}
        </PbFormGrid>

        {/* 첨부 파일 섹션 */}
        {/* 조회 상태 */}
        {brdId && !useModifyMode && (
          <PbSection label="첨부 파일">
            <div className="space-y-3">
              {/* 테이블 */}
              <div className="w-full h-80">
                <PbAgGridReact
                  refs={fileTableRef}
                  columnDefs={fileTable.column}
                  rowData={fileTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>

              {/* 첨부파일 미리보기 */}
              <div className="flex justify-start items-center space-x-1">
                <FlexImageFilePreviewList data={previewFileImages} />
              </div>
            </div>

            {/* 테이블 */}
            {/*<div className="w-full h-80">*/}
            {/*  <AgGridReact*/}
            {/*    columnDefs={fileTable.column}*/}
            {/*    rowData={fileTable.data}*/}
            {/*    defaultColDef={{*/}
            {/*      resizable: true,*/}
            {/*      sortable: false,*/}
            {/*      editable: false,*/}
            {/*      wrapHeaderText: false,*/}
            {/*      autoHeaderHeight: true,*/}
            {/*      suppressSizeToFit: false,*/}
            {/*    }}*/}
            {/*    onGridReady={(event) => event.api.sizeColumnsToFit()}*/}
            {/*    rowSelection="single"*/}
            {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
            {/*    className="ag-theme-alpine"*/}
            {/*  ></AgGridReact>*/}
            {/*</div>*/}
          </PbSection>
        )}
        {/* 첨부 파일 섹션 */}
        {/* 추가, 수정 상태 */}
        {(!brdId || useModifyMode) && (
          <PbSection
            label="첨부 파일"
            rightForm={
              <>
                {/* 버튼 */}
                <div className="flex justify-end items-center space-x-2">
                  {/* 버튼 */}
                  <Button
                    variant="outline"
                    color="pink"
                    radius="xl"
                    onClick={handleFileSectionDeleteButton_onClick}
                  >
                    전체 삭제
                  </Button>

                  {/* 버튼 */}
                  <Button
                    color="pink"
                    radius="xl"
                    onClick={handleFileSectionSelectedDeleteButton_onClick}
                  >
                    선택 삭제
                  </Button>

                  {/* 버튼 */}
                  <FileButton onChange={handleFileSectionAddButton_onClick}>
                    {(props: any) => (
                      <Button color="indigo" radius="xl" {...props}>
                        추가
                      </Button>
                    )}
                  </FileButton>
                </div>
              </>
            }
          >
            <div className="space-y-3">
              {/* 테이블 */}
              <div className="w-full h-80">
                <AgGridReact
                  ref={fileTableRef}
                  columnDefs={fileTable.column}
                  rowData={fileTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: false,
                    editable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                    suppressSizeToFit: false,
                  }}
                  onGridReady={(event) => event.api.sizeColumnsToFit()}
                  rowSelection="multiple"
                  overlayNoRowsTemplate="데이터가 없습니다."
                  className="ag-theme-alpine"
                ></AgGridReact>
              </div>
            </div>
          </PbSection>
        )}
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        {!brdId && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleAddSaveBtn_onClick}
              >
                저장
              </Button>
            </div>
          </>
        )}
        {brdId && !useModifyMode && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleModifyBtn_onClick}
              >
                수정 전환
              </Button>
            </div>
          </>
        )}
        {brdId && useModifyMode && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                variant="outline"
                color="pink"
                radius="xl"
                size="md"
                onClick={handleDeleteBtn_onClick}
              >
                삭제
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleModifySaveBtn_onClick}
              >
                저장
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220711;
