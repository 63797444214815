import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { selectedPageStore } from '../stores/selectedPage.store';
import { PageLayout } from '../components/PageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Select, TextInput, Progress } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import { AgGridReact } from 'ag-grid-react';
import { ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import _ from 'lodash';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IModal,
  IComponentModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { pageContentStore } from '../stores/page.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PbTable } from '../components/PbTable';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import * as cmnApi from '../apis/cmn.api';
import * as csApi from '../apis/cs.api';
import PbSection from '../components/PbSection/PbSection.component';
import { nanoid } from 'nanoid';
import { MN2302220502 } from '../components/MN2302220502';
import { addComponentModalStore } from '../stores/componentModal.store';
import { PbAgGridReact } from '../components/PbAgGridReact';

/**
 * 대시보드 페이지
 * @constructor
 */
const DashboardPage = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  /**
   * Dashboard 1 - ESL 설치작업 진척 현황
   */

  // 현황 테이블을 정의함
  const [statusTable, setStatusTable] = useState<{
    column: ITableColumn[];
    data: any;
  }>({ column: [], data: [] });

  /* // */

  /**
   * Dashboard 2 - 프로젝트별 작업 진행 현황
   */

  /* 프로젝트 셀렉트 */
  // 프로젝트 셀렉트를 정의함
  const [projectCodeSelect, setProjectCodeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 프로젝트 셀렉트에서 선택한 아이템을 변경함
  const handleProjectCodeSelect_onChange = (event: any) => {
    setProjectCodeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 프로젝트 셀렉트의 아이템을 초기화함
  const initProjectCodeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // CS 현황 테이블의 로딩 여부에 적용힘
    setCsStateListTableLoading(true);

    // 부서명을 불러옴
    cmnApi.getDshbEsl({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.prjcName,
          value: item.prjcCode,
        });
      });

      // 프로젝트 셀렉트의 아이템에 적용함
      if (data.data.list !== undefined && data.data.list.length > 0) {
        setProjectCodeSelect({
          value: data.data.list[0].prjcCode,
          item: tmpOptionItem,
        });
      }

      // CS 현황 테이블의 로딩 여부에 적용힘
      setCsStateListTableLoading(false);
    });
  };

  /* 프로젝트별 작업 진행 현황 */
  const [projectStatusTable, setProjectStatusTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 현황 테이블을 초기화함
  const initProjectStatusTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'splrTypeName',
        headerName: '유형',
        width: 230,
      },
      {
        field: 'allShop',
        headerName: '전체 Shop',
        width: 200,
      },
      {
        field: 'prcd',
        headerName: '진행 중',
        width: 200,
      },
      {
        field: 'workCmpl',
        headerName: '작업 완료',
        width: 200,
      },
      {
        field: 'nspcCmpl',
        headerName: '검수 완료',
        width: 200,
      },
      {
        field: 'pmNspcCmpl',
        headerName: 'PM 검수 완료',
        width: 200,
      },
      {
        field: 'dly',
        headerName: '지연',
        width: 200,
      },
    );

    // 현황 테이블에 적용함
    setProjectStatusTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 진행중 작업 리스트 데이터를 불러옴
  const getProjectStatusTableData = () => {
    cmnApi
      .getDshbWorkPrcd({
        prjcCode: projectCodeSelect.value,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setProjectStatusTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
            }),
          );
        }
      });
  };

  /* 진행중 작업 리스트 */
  const [workProjectListTable, setWorkProjectListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 테이블의 참조를 정의함
  const ongoingCsListTableRef = useRef<any>(null);

  // 테이블의 컬럼 크기를 조정함
  const handleOngoingCsListTableRef_onGridSizeChanged = useCallback(() => {
    ongoingCsListTableRef.current.api.sizeColumnsToFit();
  }, []);

  // 테이블의 컬럼 크기를 조정함
  const handleOngoingCsListTable_onGridSizeChanged = useCallback(() => {
    ongoingCsListTableRef.current.api.sizeColumnsToFit();
  }, []);

  // 검색 결과 테이블의 행을 클릭함
  const handleOngoingCsListTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'CS 상세',
      content: <MN2302220502 id={tmpId} csId={row.data.csId} />,
      size: 1500,
    });
  };

  // 진행중 작업 리스트 테이블을 초기화함
  const initWorkProjectListTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'no',
        headerName: '순번',
        width: 80,
      },
      {
        field: 'bpName',
        headerName: '고객사명',
        width: 450,
      },
      // {
      //   field: 'shipToCode',
      //   headerName: 'Shop명',
      // },
      {
        field: 'splrTypeName',
        headerName: '유형',
        width: 300,
      },
      {
        field: 'totalSplr',
        headerName: '협력사명',
        width: 300,
      },
      {
        field: 'workStrtDttm',
        headerName: '작업 기간',
        valueFormatter: (params: ValueFormatterParams) => {
          return `${moment(params.data.workStrtDttm).format(
            'YYYY-MM-DD',
          )} ~ ${moment(params.data.workEndDttm).format('YYYY-MM-DD')}`;
        },
        width: 300,
      },
    );

    // 현황 테이블에 적용함
    setWorkProjectListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 진행 중 작업 리스트 테이블 데이터를 불러옴
  const getWorkProjectListTableData = () => {
    cmnApi
      .getDshbPrcd({
        prjcCode: projectCodeSelect.value,
        isDshb: 'Y',
      })
      .then((data: IApiResult) => {
        if (data.data !== null && data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setWorkProjectListTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
            }),
          );
        }
      });
  };

  // 사용자 정보가 변경될 때 실행함
  useEffect(() => {
    if (!loginUser.id) {
      return;
    }

    return () => {};
  }, [loginUser.id]);

  // 프로젝트 선택
  useEffect(() => {
    if (!projectCodeSelect) {
      return;
    }

    // 프로젝트 진행상황 불러오기
    // getProjectStatusTableData();

    // 진행 중 작업리스트 불러오기
    getWorkProjectListTableData();

    return () => {};
  }, [projectCodeSelect]);

  /* // Dashboard - 2 */

  /**
   * Dashboard - 3
   */

  // CS 현황을 정의함
  const [csStatus, setCsStatus] = useState<
    {
      name: string;
      proceeding: number;
      completed: number;
      untreated: number;
    }[]
  >([]);

  // 테이블의 참조를 정의함
  const csStateListTableRef = useRef<any>(null);

  // 테이블의 컬럼 크기를 조정함
  const handleCsStateListTable_onGridSizeChanged = useCallback(() => {
    csStateListTableRef.current.api.sizeColumnsToFit();
  }, []);

  // CS 접수 통계 데이터를 불러옴
  const getCssStats = () => {
    // 데이터를 생성함
    let tmpData: any[] = [];

    // 상세 공통코드(CS_DPRT: 팀)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'CS_DPRT' })
      .then((data: IApiResult) => {
        tmpData = _.sortBy(data.data.list, ['sortRdr']);

        csApi.getCssStats({}).then((data: IApiResult) => {
          if (data.data.list !== undefined) {
            // 데이터를 생성함
            let tmpData2: any[] = [];

            tmpData.map((item: any) => {
              let tmpData3: any = _.find(data.data.list, {
                csDprtCode: item.dtlCodeId,
              });

              if (tmpData3 !== undefined) {
                tmpData2.push({
                  name: item.dtlCodeDscr,
                  proceeding: tmpData3.csIngCnt || 0,
                  completed: tmpData3.csEndCnt || 0,
                  untreated: tmpData3.csNotCnt || 0,
                });
              } else {
                tmpData2.push({
                  name: item.dtlCodeDscr,
                  proceeding: 0,
                  completed: 0,
                  untreated: 0,
                });
              }
            });

            // CS 현황에 적용함
            setCsStatus(tmpData2);
          }
        });
      });
  };

  // CS 현황 테이블을 정의함
  const [csStateListTable, setCsStateListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // CS 현황 테이블의 로딩 여부를 정의함
  const [csStateListTableLoading, setCsStateListTableLoading] =
    useState<boolean>(false);

  // CS 현황 테이블을 초기화함
  const initCsStateListTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'name',
        headerName: '구분',
      },
      {
        field: 'total',
        headerName: '전체',
        width: 120,
        cellRenderer: (params: any) => {
          return (
            <span
              className={[
                (params.value === 0 || params.value === '0') && 'text-blue-200',
              ].join(' ')}
            >
              {params.value}
            </span>
          );
        },
        suppressSizeToFit: true,
      },
      {
        field: 'proceeding',
        headerName: '진행중',
        width: 120,
        cellRenderer: (params: any) => {
          return (
            <span
              className={[
                (params.value === 0 || params.value === '0') && 'text-blue-200',
              ].join(' ')}
            >
              {params.value}
            </span>
          );
        },
        suppressSizeToFit: true,
      },
      {
        field: 'completed',
        headerName: '완료',
        width: 120,
        cellRenderer: (params: any) => {
          return (
            <span
              className={[
                (params.value === 0 || params.value === '0') && 'text-blue-200',
              ].join(' ')}
            >
              {params.value}
            </span>
          );
        },
        suppressSizeToFit: true,
      },
      {
        field: 'untreated',
        headerName: '미처리',
        width: 120,
        cellRenderer: (params: any) => {
          return (
            <span
              className={[
                (params.value === 0 || params.value === '0') && 'text-blue-200',
              ].join(' ')}
            >
              {params.value}
            </span>
          );
        },
        suppressSizeToFit: true,
      },
    );

    // CS 현황 테이블에 적용함
    setCsStateListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // CS 접수유형을 정의함
  const [csReceptionType, setCsReceptionType] = useState<
    {
      id: string;
      name: string;
      count: number;
    }[]
  >([]);

  // CS 접수 유형 통계 데이터를 불러옴
  const getCssStatsDtl = () => {
    // 데이터를 생성함
    let tmpData: any[] = [];

    csApi.getCssStatsDtl({}).then((data: IApiResult) => {
      if (data.data.list !== undefined) {
        data.data.list.map((item: any, index: number) => {
          tmpData.push({
            id: index,
            name: item.cstyGrpName,
            count: +item.cstyGrpCnt,
          });
        });

        // CS 접수유형에 적용함
        setCsReceptionType(tmpData);
      }
    });
  };

  // 진행중 CS 테이블을 정의함
  const [ongoingCsListTable, setOngoingCsListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 진행중 CS 테이블 페이징을 정의함
  const [ongoingCsListTablePaging, setOngoingCsListTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const ongoingCsListTablePagingCurrentPageRef = useRef<number>(1);

  // 진행중 CS 테이블의 로딩 여부를 정의함
  const [ongoingCsListTableLoading, setOngoingCsListTableLoading] =
    useState<boolean>(false);

  //진행중 CS 테이블을 초기화함
  const initOngoingCsListTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'cstyName',
        headerName: '유형',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        field: 'csSttsName',
        headerName: 'CS 상태',
        width: 110,
        suppressSizeToFit: true,
      },
      {
        field: 'nqryName',
        headerName: '문의명',
      },
      {
        field: 'bpName',
        headerName: '고객사명',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        field: 'shopName',
        headerName: 'Shop명',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        field: 'nowDprtName',
        headerName: '담당부서',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        field: 'nowMngrName',
        headerName: '담당자',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: 'rgstDate',
        headerName: '등록일시',
        width: 130,
        suppressSizeToFit: true,
      },
    );

    // 진행중 CS 테이블에 적용함
    setOngoingCsListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 진행중 CS 데이타 불러오기
  const getDashPrcdCs = () => {
    // 진행중 CS 테이블의 로딩 여부에 적용함
    setOngoingCsListTableLoading(true);
    // let xcptWrkCodeList: string[] = [];

    // xcptWrkCodeList.push('cmpl');
    // xcptWrkCodeList.push('dly');

    // 회신완료 데이터는 제외함
    cmnApi
      .getDshbPrcdCs({
        xcptWrkCodeList: 'cmpl',
        pageSize: ongoingCsListTablePaging.rowPerPage,
        currPageIdx: ongoingCsListTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setOngoingCsListTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
            }),
          );

          // 테이블을 페이징함
          setOngoingCsListTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }

        // 진행중 CS 테이블의 로딩 여부에 적용함
        setOngoingCsListTableLoading(false);
      })
      .catch((error: any) => {
        // 진행중 CS 테이블의 로딩 여부에 적용함
        setOngoingCsListTableLoading(false);
      });
  };

  // CS 현황 검색 완료시
  useEffect(() => {
    if (!csStatus) {
      return;
    }

    let tmpCsStatus: any[] = _.cloneDeep(csStatus);

    // 데이터에 전체 컬럼을 추가함
    tmpCsStatus.map((item: any, index: number) => {
      item['name'] = item['name'];

      item['total'] =
        item['proceeding'] + item['completed'] + item['untreated'];
    });

    // CS 현황 설정
    setCsStateListTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: tmpCsStatus,
    }));

    return () => {};
  }, [csStatus]);

  /* // Dashboard - 3 */

  /**
   *
   */

  /* // */

  /**
   *
   */

  /* // */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    /* Dashboard - 2 */
    // 프로젝트별 작업 진행 현황 테이블을 초기화함
    initProjectStatusTable();

    // 진행중 작업 리스트 테이블을 초기화함
    initWorkProjectListTable();

    // 프로젝트 셀렉트의 아이템을 초기화함
    initProjectCodeSelect();
    /* // Dashboard - 2 */

    /* Dashboard - 3 */

    // CS 접수 통계 테이블 초기화함
    initCsStateListTable();

    // 진행중 CS 목록 테이블 초기화함
    initOngoingCsListTable();

    // CS 접수 통계 데이터를 불러옴
    getCssStats();

    // CS 접수 유형 통계 데이터를 불러옴
    getCssStatsDtl();

    // 진행중 CS 목록 데이터를 불러옴
    getDashPrcdCs();

    /* // Dashboard - 3 */

    return () => {};
  }, []);

  return (
    <PageLayout
      pageInfoBarLeftArea="대시보드"
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      <div className="space-y-12">
        {/*<div>*/}
        {/*  /!* 프로젝트별 작업 진행 현황 폼 그리드 *!/*/}
        {/*  <PbFormGrid label="프로젝트별 작업 진행 현황" cols={1}>*/}
        {/*    <PbFormGridCol label="프로젝트 선택">*/}
        {/*      /!* 셀렉트 *!/*/}
        {/*      <Select*/}
        {/*        onChange={handleProjectCodeSelect_onChange}*/}
        {/*        data={projectCodeSelect.item}*/}
        {/*        value={projectCodeSelect.value}*/}
        {/*        className="w-full"*/}
        {/*      />*/}
        {/*    </PbFormGridCol>*/}
        {/*  </PbFormGrid>*/}

        {/*  <div className="p-2 border-r border-b border-l space-y-5">*/}
        {/*    <div className="">*/}
        {/*      <PbSection label="진행 현황">*/}
        {/*        <AgGridReact*/}
        {/*          columnDefs={projectStatusTable.column}*/}
        {/*          rowData={projectStatusTable.data}*/}
        {/*          defaultColDef={{*/}
        {/*            initialWidth: 150,*/}
        {/*            resizable: true,*/}
        {/*            sortable: true,*/}
        {/*            wrapHeaderText: false,*/}
        {/*            autoHeaderHeight: true,*/}
        {/*            suppressSizeToFit: false,*/}
        {/*          }}*/}
        {/*          onGridReady={(event) => event.api.sizeColumnsToFit()}*/}
        {/*          rowSelection="single"*/}
        {/*          domLayout="autoHeight"*/}
        {/*          overlayNoRowsTemplate="데이터가 없습니다."*/}
        {/*          className="ag-theme-alpine"*/}
        {/*        ></AgGridReact>*/}
        {/*      </PbSection>*/}
        {/*    </div>*/}

        {/*    <div className="">*/}
        {/*      <PbSection label="진행 중 작업 리스트">*/}
        {/*        <AgGridReact*/}
        {/*          columnDefs={workProjectListTable.column}*/}
        {/*          rowData={workProjectListTable.data}*/}
        {/*          // onRowClicked={handleOngoingCsListTable_onRowDoubleClicked}*/}
        {/*          defaultColDef={{*/}
        {/*            initialWidth: 150,*/}
        {/*            resizable: true,*/}
        {/*            sortable: true,*/}
        {/*            wrapHeaderText: false,*/}
        {/*            autoHeaderHeight: true,*/}
        {/*            suppressSizeToFit: false,*/}
        {/*          }}*/}
        {/*          onGridReady={(event) => event.api.sizeColumnsToFit()}*/}
        {/*          rowSelection="single"*/}
        {/*          domLayout="autoHeight"*/}
        {/*          overlayNoRowsTemplate="데이터가 없습니다."*/}
        {/*          className="ag-theme-alpine"*/}
        {/*        ></AgGridReact>*/}
        {/*      </PbSection>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/* 운영 권한일 때만 출력 */}
        {/* CS 현황 */}
        {_.find(loginUser?.authorityGroup, { thrtId: 'AU2305030004' }) !==
          undefined && (
          <PbSection label="CS 현황" className="">
            <div className="flex justify-center items-start space-x-5">
              {/* 왼쪽 영역 */}
              <div className="relative w-1/2 h-80">
                {/* 테이블 */}
                <div className="w-full h-80">
                  <PbAgGridReact
                    columnDefs={csStateListTable.column}
                    rowData={csStateListTable.data}
                    loading={csStateListTableLoading}
                    setLoading={setCsStateListTableLoading}
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      wrapHeaderText: false,
                      autoHeaderHeight: true,
                    }}
                    rowSelection="single"
                    sizeColumnsToFit={true}
                  />
                </div>

                {/*/!* 로딩 중 아이콘 *!/*/}
                {/*<LoadingTableDataIcon visible={csStateListTable.loading} />*/}

                {/*<AgGridReact*/}
                {/*  ref={csStateListTableRef}*/}
                {/*  columnDefs={csStateListTable.column}*/}
                {/*  rowData={csStateListTable.data}*/}
                {/*  // onRowClicked={handleOngoingCsListTable_onRowDoubleClicked}*/}
                {/*  defaultColDef={{*/}
                {/*    resizable: true,*/}
                {/*    sortable: true,*/}
                {/*    wrapHeaderText: false,*/}
                {/*    autoHeaderHeight: true,*/}
                {/*  }}*/}
                {/*  // onGridReady={(event) => event.api.sizeColumnsToFit()}*/}
                {/*  rowSelection="single"*/}
                {/*  // domLayout="autoHeight"*/}
                {/*  onGridSizeChanged={handleCsStateListTable_onGridSizeChanged}*/}
                {/*  onComponentStateChanged={*/}
                {/*    handleCsStateListTable_onGridSizeChanged*/}
                {/*  }*/}
                {/*  overlayNoRowsTemplate="데이터가 없습니다."*/}
                {/*  className="ag-theme-alpine"*/}
                {/*></AgGridReact>*/}
              </div>

              {/* 오른쪽 영역 */}
              <div className="w-1/2">
                <div className="w-full grid grid-cols-3 gap-1">
                  {csReceptionType.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="object-appear p-1 bg-white border border-gray-200 space-y-2"
                    >
                      {/* 이름 */}
                      <div className="flex justify-between items-center space-x-3">
                        <div className="leading-none truncate">
                          <span className="text-lg font-bold">{item.name}</span>
                        </div>

                        <div>
                          {/*<span className="text-xl text-gray-500 font-bold underline underline-offset-4 decoration-indigo-300">*/}
                          <span className="text-xl text-gray-500 font-bold">
                            {item.count}
                          </span>
                        </div>
                      </div>

                      <div className="flex justify-center items-center">
                        <div className="grow">
                          <Progress
                            size="xl"
                            radius="xs"
                            color="indigo"
                            label={item.count}
                            value={
                              (item.count * 100) /
                              _.maxBy(csReceptionType, 'count')!.count
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </PbSection>
        )}

        {/* 운영 권한일 때만 출력 */}
        {/* 진행 중 CS */}
        {_.find(loginUser?.authorityGroup, { thrtId: 'AU2305030004' }) !==
          undefined && (
          <PbSection label="미완료 CS" className="">
            {/* 테이블 */}
            <div className="w-full h-104">
              <PbAgGridReact
                columnDefs={ongoingCsListTable.column}
                rowData={ongoingCsListTable.data}
                loading={ongoingCsListTableLoading}
                setLoading={setOngoingCsListTableLoading}
                onRowDoubleClicked={handleOngoingCsListTable_onRowDoubleClicked}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
                // visiblePaging={true}
                // paging={{
                //   totalPage: ongoingCsListTablePaging.totalPage,
                //   currentPage: ongoingCsListTablePaging.currentPage,
                // }}
                // onChangePage={(event: any) => {
                //   // 테이블의 페이지를 변경함
                //   ongoingCsListTablePagingCurrentPageRef.current = event;
                //   setOngoingCsListTablePaging((pre: ITablePaging) => ({
                //     ...pre,
                //     currentPage: ongoingCsListTablePagingCurrentPageRef.current,
                //   }));
                //
                //   // 테이블 데이터를 불러옴
                //   getDashPrcdCs();
                // }}
              />
            </div>
          </PbSection>
        )}
      </div>
    </PageLayout>
  );
};

export default DashboardPage;
