import React, { PropsWithChildren, useEffect, useState } from 'react';
import prettyBytes from 'pretty-bytes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as slsApi from '../../apis/sls.api';
import { useRecoilState } from 'recoil';
import {
  IApiResult,
  IComponentModal,
  IImageCarousel,
} from '../../interfaces/app.interface';
import { imageCarouselStore } from '../../stores/imageCarousel.store';
import PbSection from '../PbSection/PbSection.component';
import _ from 'lodash';
import * as appUtil from '../../utils/app.util';
import { Button } from '@mantine/core';
import { nanoid } from 'nanoid';
import { ModifyComment } from '../ModifyComment';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import * as nstlApi from '../../apis/nstl.api';

interface IComponentProps {
  id: string;
  dcmnDntfNmbr: string;
  splrId: string;
  teamId: string;
  splrTypeCode: string;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 댓글
 * @param dcmnDntfNmbr 문서번호
 * @param splrId 협력사 아이디
 * @param teamId 협력사(팀) 아이디
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Comment = ({
  id,
  dcmnDntfNmbr = '',
  splrId = '',
  teamId = '',
  splrTypeCode = '',
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IComponentProps>) => {
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 이미지 캐러셀 저장소를 정의함
  const [imageCarousel, setImageCarousel] =
    useRecoilState<IImageCarousel>(imageCarouselStore);

  // 댓글을 정의함
  const [comment, setComment] = useState<any[]>([]);

  // 상세 내용을 불러옴
  const getDetailData = (callback: Function = () => {}) => {
    switch (splrTypeCode) {
      // 네트워크 공사 작업 내역
      case 'NTWR_CNST':
        nstlApi
          .getNstlWorkNtwr({
            splrId: splrId,
            teamId: teamId,
            dcmnDntfNmbr: dcmnDntfNmbr,
          })
          .then((data: IApiResult) => {
            if (data.data !== undefined) {
              callback(data.data);
            }
          });
        break;

      // ESL 설치 작업 내역
      case 'ESL_NSTL':
        nstlApi
          .getNstlWorkEsl({
            splrId: splrId,
            teamId: teamId,
            dcmnDntfNmbr: dcmnDntfNmbr,
          })
          .then((data: IApiResult) => {
            if (data.data !== undefined) {
              callback(data.data);
            }
          });
        break;

      default:
        break;
    }
  };

  // 댓글작성 버튼을 클릭함
  const handleAddCommentButton_onClick = () => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '댓글 작성',
      content: (
        <ModifyComment
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          splrId={splrId}
          teamId={teamId}
          commentData={null}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData((data: any) => {
              // 댓글에 적용함
              setComment(data.prgrRsltList);
            });
          }}
        />
      ),
      size: 1000,
    });
  };

  // 댓글 수정 버튼을 클릭함
  const handleModifyButton_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '댓글 수정',
      content: (
        <ModifyComment
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          splrId={splrId}
          teamId={teamId}
          commentData={event}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData((data: any) => {
              // 댓글에 적용함
              setComment(data.prgrRsltList);
            });
          }}
        />
      ),
      size: 1000,
    });
  };

  // 첨부파일 다운로드를 클릭함
  const handleFileDownload_onClick = (fileId: string, rgnlFileName: string) => {
    slsApi
      .getSlsCtvtFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  useEffect(() => {
    // 상세 내용을 불러옴
    getDetailData((data: any) => {
      // 댓글에 적용함
      setComment(data.prgrRsltList);
    });

    return () => {};
  }, []);

  return (
    <PbSection
      label="댓글"
      rightForm={
        <div>
          {/* 댓글작성 */}
          <Button
            variant="outline"
            color="gray"
            radius="xl"
            onClick={handleAddCommentButton_onClick}
          >
            댓글작성
          </Button>
        </div>
      }
    >
      <div className="space-y-4">
        {/* 댓글 없음 */}
        {comment.length === 0 && (
          <div className="py-1 border-1 border-gray-200 rounded-md space-y-3">
            <div className="mx-1 px-1 py-3 flex justify-center items-center bg-gray-100 rounded-md">
              <span>댓글이 없습니다.</span>
            </div>
          </div>
        )}

        {/* 댓글 있음 */}
        {comment.map((item: any, index: number) => (
          <div
            key={index}
            className="pb-1 border-1 border-gray-300/70 rounded-md space-y-3"
          >
            {/* 댓글 기본 정보 */}
            <div className="px-1">
              {/* 줄 공백 */}
              <div className="h-3" />

              <div className="flex justify-between items-center">
                <div className="flex justify-center items-center space-x-3">
                  {/* 설치 카테고리 */}
                  {item.nstlCtgrName !== '' && (
                    <span className="text-base text-lime-600 font-bold">
                      {item.nstlCtgrName}
                    </span>
                  )}

                  {/* 작성자 */}
                  <span className="text-base text-blue-600 font-bold">
                    {item.name}
                  </span>

                  {/* 작성일시 */}
                  <span className="text-xs text-gray-600">{item.rgstDate}</span>
                </div>

                <div className="flex justify-center items-center">
                  {/* 댓글수정 */}
                  <Button
                    color="indigo"
                    radius="xl"
                    size="xs"
                    onClick={() => handleModifyButton_onClick(item)}
                  >
                    수정
                  </Button>
                </div>
              </div>
            </div>

            {/* 댓글 내용 */}
            <div className="mx-1 p-1 bg-gray-100 rounded-md">
              <div
                dangerouslySetInnerHTML={{
                  __html: item.dtl.replaceAll('\r\n', '<br />'),
                }}
              />
            </div>

            {/* 첨부파일: 이미지 */}
            {item.fileList.filter(
              (filterItem: any) => filterItem.fileDntfCode === 'PCTR',
            ).length > 0 && (
              <div className="px-1 flex flex-wrap gap-1">
                {item.fileList
                  .filter(
                    (filterItem: any) => filterItem.fileDntfCode === 'PCTR',
                  )
                  .map((file: any, index: number) => (
                    <div
                      key={index}
                      className="border-1 border-gray-200 rounded-md overflow-hidden"
                    >
                      <img
                        key={index}
                        src={[
                          appUtil.getThumbnailImageUrl(),
                          file.pldFileName,
                        ].join('')}
                        onClick={() => {
                          let tmpFileList: any[] = [];

                          item.fileList
                            .filter(
                              (subFile: any) => subFile.fileDntfCode === 'PCTR',
                            )
                            .map((subFile: any) => {
                              tmpFileList.push(
                                [
                                  appUtil.getThumbnailImageUrl(),
                                  subFile.pldFileName,
                                ].join(''),
                              );
                            });

                          // 이미지 캐러셀 저장소에 적용함
                          setImageCarousel({
                            imageList: tmpFileList,
                            startIndex: index,
                          });
                        }}
                        className="button-event w-20 h-20"
                      />
                    </div>
                  ))}
              </div>
            )}

            {/* 첨부파일: 기타 */}
            {item.fileList.filter(
              (filterItem: any) =>
                filterItem.fileDntfCode !== 'PCTR' ||
                filterItem.fileDntfCode === undefined,
            ).length > 0 && (
              <div className="px-2 pt-0.5">
                {item.fileList
                  .filter(
                    (filterItem: any) =>
                      filterItem.fileDntfCode !== 'PCTR' ||
                      filterItem.fileDntfCode === undefined,
                  )
                  .map((file: any, index: number) => (
                    <>
                      {index > 0 && (
                        <div className="h-5 flex justify-center items-center">
                          <div className="w-full h-px border-t border-gray-100"></div>
                        </div>
                      )}

                      <div
                        key={index}
                        onClick={() =>
                          handleFileDownload_onClick(
                            file.fileId,
                            file.rgnlFileName,
                          )
                        }
                        // className="button-event px-1 h-12 flex justify-start items-center bg-gray-100 rounded space-y-1"
                        className="button-event py-0.5 flex justify-start items-center"
                      >
                        <div className="flex justify-center items-center space-x-3">
                          <div className="flex justify-center items-center">
                            <FontAwesomeIcon
                              icon={['far', 'file']}
                              className="w-6 h-6 text-gray-500"
                            />
                          </div>

                          <div className="space-y-1">
                            {/* 파일명 */}
                            <div className="leading-none">
                              <span className="text-base text-gray-600 font-semibold">
                                {file.rgnlFileName}
                              </span>
                            </div>

                            {/* 용량 */}
                            <div className="leading-none">
                              <span className="text-sm text-gray-400">
                                {prettyBytes(_.toNumber(file.fileSize))}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </PbSection>
  );
};

export default Comment;
