import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { HtmlEditor } from '../components/HtmlEditor';
import { PageLayout } from '../components/PageLayout';
import { PbButton } from '../components/PbButton';
import { PbTable } from '../components/PbTable';
import { ISelect, ITableColumn } from '../interfaces/app.interface';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { PbMonthPicker } from '../components/PbMonthPicker';
import { PbSelect2 } from '../components/PbSelect2';
import { PbInputText } from '../components/PbInputText';
import { PbCheckbox } from '../components/PbCheckbox';
import { PbRadio } from '../components/PbRadio';
import { PbSwitch } from '../components/PbSwitch';

/**
 * 컴포넌트 샘플 페이지
 * @constructor
 */
const SamplePage = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 년월 피커를 정의함
  const [monthPicker, setMonthPicker] = useState<any>();

  // 테이블 컬럼을 정의함
  const [tableColumn, setTableColumn] = useState<ITableColumn[]>([]);

  // 테이블 데이터를 정의함
  const [tableData, setTableData] = useState<any[]>([]);

  // 텍스트 입력을 정의함
  const [inputText, setInputText] = useState<string>('');

  // 셀렉트를 정의함
  const [select, setSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 체크박스를 정의함
  const [checkedCheckbox, setCheckedCheckbox] = useState<boolean>(false);

  // 라디오를 정의함
  const [selectedRadio, setSelectedRadio] = useState<string>('');

  // 폼 그리드의 컬럼 나타냄을 정의함
  const [hideColumn, setHideColumn] = useState<boolean>(false);

  // 스위치를 정의함
  const [checkedSwitch, setCheckedSwitch] = useState<boolean>(false);

  // 소스 코드를 정의함
  let sourceCode: any = [
    {
      name: 'htmlEditor',
      sourceCode: `
import { HtmlEditor } from '../components/HtmlEditor';

return (
  <HtmlEditor />
);
`,
    },
    {
      name: 'monthPicker',
      sourceCode: `
import { PbMonthPicker } from "../components/PbMonthPicker";

// 년월 피커를 정의함
const [monthPicker, setMonthPicker] = useState<any>();

// 년월 피커를 변경함
const handleMonthPicker_onChange = (event: any) => {
  setMonthPicker(event.target.value);
};

return (
  <PbMonthPicker
    onChange={handleMonthPicker_onChange}
    value={monthPicker}
  />
);
`,
    },
    {
      name: 'button',
      sourceCode: `
import { PbButton } from '../components/PbButton';

// 버튼을 클릭함
const handleButton_onClick = () => {
  console.log('Clicked Button');
};
  
return (
  <>
    {/* 기본 버튼 */}
    <PbButton
      label="버튼: 색상(purple), 크기(lg)"
      backgroundColor="purple"
      size="lg"
      onClick={handleButton_onClick}
    />
    
    {/* 버튼 비활성화 */}
    <PbButton
      label="버튼: 색상(white), 크기(sm)"
      backgroundColor="white"
      size="sm"
      disabled={true}
      onClick={handleButton_onClick}
    />
  </>
);
`,
    },
    {
      name: 'inputText',
      sourceCode: `
import { PbInputText } from '../components/PbInputText';

// 텍스트 입력을 정의함
const [inputText, setInputText] = useState<string>('');

// 텍스트 입력의 값을 변경함
const handleInputText_onChange = (event: any) => {
  setInputText(event.target.value);
};

return (
  <PbInputText
    placeholder="입력하세요."
    size="full"
    onChange={handleInputText_onChange}
    value={inputText}
  />);
`,
    },
    {
      name: 'select',
      sourceCode: `
import { PbSelect } from "../components/PbSelect2";

// 셀렉트를 정의함
const [select, setSelect] = useState<ISelect>({
  value: '',
  item: [],
});

// 셀렉트의 선택한 아이템을 변경함
const handleSelect_onChange = (event: any) => {
  setSelect({ ...select, value: event.target.value });
};

// 페이지 로딩 후 한번만 실행함
useEffect(() => {
  // 셀렉트의 아이템에 적용함
  setSelect({
    value: '',
    item: [
      {
        label: '아이템1',
        value: '1',
      },
      {
        label: '아이템2',
        value: '2',
      },
      {
        label: '아이템3',
        value: '3',
      },
    ],
  });

  return () => {};
}, []);

return (
  <>
    {/* 기본 */}
    <PbSelect
      onChange={handleSelect_onChange}
      item={select.item}
      value={select.value}
      useDefaultItem={true}
    />
  
    {/* 크기 지정 */}
    <PbSelect
      size="sm"
      onChange={handleSelect_onChange}
      item={select.item}
      value={select.value}
      useDefaultItem={true}
    />
  </>
);
`,
    },
    {
      name: 'checkbox',
      sourceCode: `
import { PbCheckbox } from "../components/PbCheckbox";

// 체크박스를 정의함
const [checkedCheckbox, setCheckedCheckbox] = useState<boolean>(false);

// 체크박스를 변경함
const handleCheckbox_onChange = (event: any) => {
  // 체크박스에 적용함
  setCheckedCheckbox(event.target.checked);
};

return (
  <PbCheckbox
    onChange={handleCheckbox_onChange}
    label="체크박스"
    checked={checkedCheckbox}
  />
);
`,
    },
    {
      name: 'radio',
      sourceCode: `
import { PbRadio } from '../components/PbRadio';

// 라디오를 정의함
const [selectedRadio, setSelectedRadio] = useState<string>('');

// 라디오를 변경함
const handleRadio_onChange = (event: any) => {
  // 라디오에 적용함
  setSelectedRadio(event.target.value);
};

return (
  <PbRadio
    group="test"
    item={[
      {
        label: '라디오1',
        value: '1',
      },
      {
        label: '라디오2',
        value: '2',
      },
      {
        label: '라디오3',
        value: '3',
      },
    ]}
    onChange={handleRadio_onChange}
    value={selectedRadio}
  />
);
`,
    },
    {
      name: 'switch',
      sourceCode: `
import { PbSwitch } from "../components/PbSwitch";

// 스위치를 정의함
const [checkedSwitch, setCheckedSwitch] = useState<boolean>(false);

// 스위치를 변경함
const handleSwitch_onChange = (event: any) => {
  // 스위치에 적용함
  setCheckedSwitch(event.target.checked);
};

return (
  <PbSwitch
    onChange={handleSwitch_onChange}
    checked={checkedSwitch}
  />
);
`,
    },
    {
      name: 'table',
      sourceCode: `
import { PbTable } from "../components/PbTable";
import { ITableColumn } from "../interfaces/app.interface";

// 테이블 컬럼을 정의함
const [tableColumn, setTableColumn] = useState<ITableColumn[]>([]);

// 테이블 데이터를 정의함
const [tableData, setTableData] = useState<any[]>([]);

// 테이블 컬럼을 생성함
const createTableColumn = () => {
  // 테이블 컬럼을 정의함
  let tmpTableColumn: ITableColumn[] = [];

  // 테이블 컬럼에 추가함
  tmpTableColumn.push(
    {
      column: 'col1',
      title: '컬럼1',
      width: '0',
      align: 'left',
    },
    {
      column: 'col2',
      title: '컬럼2',
      width: '0',
      align: 'center',
    },
    {
      column: 'col3',
      title: '컬럼3',
      width: '0',
      align: 'right',
    },
  );

  // 테이블 컬럼에 적용함
  setTableColumn(tmpTableColumn);
};

// 테이블 데이터를 생성함
const createTableData = () => {
  // 테이블 데이터를 정의함
  let tmpTableData: any[] = [];

  tmpTableData.push(
    {
      col1: '데이터1.1',
      col2: '데이터1.2',
      col3: '데이터1.3',
    },
    {
      col1: '데이터2.1',
      col2: '데이터2.2',
      col3: '데이터2.3',
    },
    {
      col1: '데이터3.1',
      col2: '데이터3.2',
      col3: '데이터3.3',
    },
  );

  // 테이블 데이터에 적용함
  setTableData(tmpTableData);
};

// 페이지 로딩 후 한번만 실행함
useEffect(() => {
  // 테이블 컬럼을 생성함
  createTableColumn();

  // 테이블 데이터를 생성함
  createTableData();
  
  // 셀렉트의 아이템에 적용함
  setSelect({
    value: '',
    item: [
      {
        label: '아이템1',
        value: '1',
      },
      {
        label: '아이템2',
        value: '2',
      },
      {
        label: '아이템3',
        value: '3',
      },
    ],
  });

  return () => {};
}, []);

return (
  <PbTable
    column={tableColumn}
    data={tableData}
    striped={false}
    highlightOnHover={true}
    userSelectNone={true}
    usePagination={true}
    rowPerPage={5}
    onClickRow={(item: any) => console.log('> clicked item: ', item)}
  />
);
`,
    },
    {
      name: 'formGrid',
      sourceCode: `
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';

// 폼 그리드의 컬럼 나타냄을 정의함(컬럼 숨김이 필요할 때만 사용함)
const [hideColumn, setHideColumn] = useState<boolean>(false);

return (
  <>
    <PbFormGrid label="기본" cols={2}>
      <PbFormGridCol label="항목1" colSpan={2}>
        {/* 버튼 */}
        <div className="flex justify-center items-center space-x-2">
          <PbButton
            label="검색"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              console.log('검색');
            }}
          />
  
          <PbButton
            label="초기화"
            backgroundColor="white"
            size="sm"
            onClick={() => {
              console.log('초기화');
            }}
          />
        </div>
      </PbFormGridCol>
      <PbFormGridCol label="항목2"></PbFormGridCol>
      <PbFormGridCol label="항목3"></PbFormGridCol>
      <PbFormGridCol label="항목4"></PbFormGridCol>
      <PbFormGridCol label="항목5"></PbFormGridCol>
    </PbFormGrid>
    
    <PbFormGrid label="컬럼 숨김 적용" cols={2}>
      <PbFormGridCol label="항목1" colSpan={2}>
        {/* 버튼 */}
        <div className="flex justify-center items-center space-x-2">
          <PbButton
            label="검색"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              console.log('검색');
            }}
          />

          <PbButton
            label="초기화"
            backgroundColor="white"
            size="sm"
            onClick={() => {
              console.log('초기화');
            }}
          />

          <PbButton
            label={hideColumn ? '컬럼 열기' : '컬럼 닫기'}
            backgroundColor="white"
            size="sm"
            onClick={() => setHideColumn(!hideColumn)}
          />
        </div>
      </PbFormGridCol>
      <PbFormGridCol label="항목2"></PbFormGridCol>
      <PbFormGridCol label="항목3"></PbFormGridCol>
      <PbFormGridCol
        label="항목4: 숨김 컬럼"
        isHidden={hideColumn}
      ></PbFormGridCol>
      <PbFormGridCol
        label="항목5: 숨김 컬럼"
        isHidden={hideColumn}
      ></PbFormGridCol>
    </PbFormGrid>
  </>
);
`,
    },
  ];

  // 년월 피커를 변경함
  const handleMonthPicker_onChange = (event: any) => {
    setMonthPicker(event.target.value);
  };

  // 버튼을 클릭함
  const handleButton_onClick = () => {
    console.log('Clicked Button');
  };

  // 텍스트 입력의 값을 변경함
  const handleInputText_onChange = (event: any) => {
    setInputText(event.target.value);
  };

  // 셀렉트의 선택한 아이템을 변경함
  const handleSelect_onChange = (event: any) => {
    setSelect({ ...select, value: event.target.value });
  };

  // 체크박스를 변경함
  const handleCheckbox_onChange = (event: any) => {
    // 체크박스에 적용함
    setCheckedCheckbox(event.target.checked);
  };

  // 라디오를 변경함
  const handleRadio_onChange = (event: any) => {
    // 라디오에 적용함
    setSelectedRadio(event.target.value);
  };

  // 스위치를 변경함
  const handleSwitch_onChange = (event: any) => {
    // 스위치에 적용함
    setCheckedSwitch(event.target.checked);
  };

  // 테이블 컬럼을 생성함
  const createTableColumn = () => {
    // 테이블 컬럼을 정의함
    let tmpTableColumn: ITableColumn[] = [];

    // 테이블 컬럼에 추가함
    tmpTableColumn.push(
      {
        column: 'col1',
        title: '컬럼1',
        width: '0',
        align: 'left',
      },
      {
        column: 'col2',
        title: '컬럼2',
        width: '0',
        align: 'center',
      },
      {
        column: 'col3',
        title: '컬럼3',
        width: '0',
        align: 'right',
      },
    );

    // 테이블 컬럼에 적용함
    setTableColumn(tmpTableColumn);
  };

  // 테이블 데이터를 생성함
  const createTableData = () => {
    // 테이블 데이터를 정의함
    let tmpTableData: any[] = [];

    tmpTableData.push(
      {
        col1: '데이터1.1',
        col2: '데이터1.2',
        col3: '데이터1.3',
      },
      {
        col1: '데이터2.1',
        col2: '데이터2.2',
        col3: '데이터2.3',
      },
      {
        col1: '데이터3.1',
        col2: '데이터3.2',
        col3: '데이터3.3',
      },
    );

    // 테이블 데이터에 적용함
    setTableData(tmpTableData);
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 테이블 컬럼을 생성함
    createTableColumn();

    // 테이블 데이터를 생성함
    createTableData();

    // 셀렉트의 아이템에 적용함
    setSelect({
      value: '',
      item: [
        {
          label: '아이템1',
          value: '1',
        },
        {
          label: '아이템2',
          value: '2',
        },
        {
          label: '아이템3',
          value: '3',
        },
      ],
    });

    return () => {};
  }, []);

  return (
    <PageLayout
      pageInfoBarLeftArea={'컴포넌트 샘플'}
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-20">
        {/* 버튼 */}
        <div className="space-y-2">
          {/* 이름 */}
          <div className="flex justify-start items-center leading-none">
            <div className="px-2 py-1 bg-black">
              <span className="text-lg text-white font-bold">버튼: Button</span>
            </div>
          </div>

          {/* 소스 */}
          <div className="px-2 pb-1 bg-gray-100 rounded">
            <div className="whitespace-pre">
              {_.find(sourceCode, { name: 'button' }).sourceCode}
            </div>
          </div>

          {/* 컴포넌트 */}
          <div className="w-full p-2 border space-y-2">
            <div className="flex justify-start items-center space-x-2">
              <PbButton
                label="버튼: 색상(purple), 크기(lg)"
                backgroundColor="purple"
                size="lg"
                onClick={handleButton_onClick}
              />

              <PbButton
                label="버튼: 색상(purple), 크기(md)"
                backgroundColor="purple"
                size="md"
                onClick={handleButton_onClick}
              />

              <PbButton
                label="버튼: 색상(purple), 크기(sm)"
                backgroundColor="purple"
                size="sm"
                onClick={handleButton_onClick}
              />

              <PbButton
                label="버튼: 색상(purple), 크기(sm)"
                backgroundColor="purple"
                size="sm"
                disabled={true}
                onClick={handleButton_onClick}
              />
            </div>

            <div className="flex justify-start items-center space-x-2">
              <PbButton
                label="버튼: 색상(white), 크기(lg)"
                backgroundColor="white"
                size="lg"
                onClick={handleButton_onClick}
              />

              <PbButton
                label="버튼: 색상(white), 크기(md)"
                backgroundColor="white"
                size="md"
                onClick={handleButton_onClick}
              />

              <PbButton
                label="버튼: 색상(white), 크기(sm)"
                backgroundColor="white"
                size="sm"
                onClick={handleButton_onClick}
              />

              <PbButton
                label="버튼: 색상(white), 크기(sm)"
                backgroundColor="white"
                size="sm"
                disabled={true}
                onClick={handleButton_onClick}
              />
            </div>
          </div>
        </div>

        {/* 텍스트 입력 */}
        <div className="space-y-2">
          {/* 이름 */}
          <div className="flex justify-start items-center leading-none">
            <div className="px-2 py-1 bg-black">
              <span className="text-lg text-white font-bold">
                텍스트 입력: Input Text
              </span>
            </div>
          </div>

          {/* 소스 */}
          <div className="px-2 pb-1 bg-gray-100 rounded">
            <div className="whitespace-pre">
              {_.find(sourceCode, { name: 'inputText' }).sourceCode}
            </div>
          </div>

          {/* 컴포넌트 */}
          <div className="w-full p-2 border">
            <PbInputText
              placeholder="입력하세요."
              size="md"
              onChange={handleInputText_onChange}
              value={inputText}
            />
          </div>
        </div>

        {/* 셀렉트 */}
        <div className="space-y-2">
          {/* 이름 */}
          <div className="flex justify-start items-center leading-none">
            <div className="px-2 py-1 bg-black">
              <span className="text-lg text-white font-bold">
                셀렉트: Select
              </span>
            </div>
          </div>

          {/* 소스 */}
          <div className="px-2 pb-1 bg-gray-100 rounded">
            <div className="whitespace-pre">
              {_.find(sourceCode, { name: 'select' }).sourceCode}
            </div>
          </div>

          {/* 컴포넌트 */}
          <div className="w-full p-2 border space-y-2">
            <PbSelect2
              onChange={handleSelect_onChange}
              item={select.item}
              value={select.value}
              useDefaultItem={true}
            />

            <PbSelect2
              size="sm"
              onChange={handleSelect_onChange}
              item={select.item}
              value={select.value}
              useDefaultItem={true}
            />
          </div>
        </div>

        {/* 체크박스 */}
        <div className="space-y-2">
          {/* 이름 */}
          <div className="flex justify-start items-center leading-none">
            <div className="px-2 py-1 bg-black">
              <span className="text-lg text-white font-bold">
                체크박스: Checkbox
              </span>
            </div>
          </div>

          {/* 소스 */}
          <div className="px-2 pb-1 bg-gray-100 rounded">
            <div className="whitespace-pre">
              {_.find(sourceCode, { name: 'checkbox' }).sourceCode}
            </div>
          </div>

          {/* 컴포넌트 */}
          <div className="w-full p-2 border">
            <PbCheckbox
              onChange={handleCheckbox_onChange}
              label="체크박스"
              checked={checkedCheckbox}
            />
          </div>
        </div>

        {/* 라디오 */}
        <div className="space-y-2">
          {/* 이름 */}
          <div className="flex justify-start items-center leading-none">
            <div className="px-2 py-1 bg-black">
              <span className="text-lg text-white font-bold">
                라디오: Radio
              </span>
            </div>
          </div>

          {/* 소스 */}
          <div className="px-2 pb-1 bg-gray-100 rounded">
            <div className="whitespace-pre">
              {_.find(sourceCode, { name: 'radio' }).sourceCode}
            </div>
          </div>

          {/* 컴포넌트 */}
          <div className="w-full p-2 border">
            <PbRadio
              group="test"
              item={[
                {
                  label: '라디오1',
                  value: '1',
                },
                {
                  label: '라디오2',
                  value: '2',
                },
                {
                  label: '라디오3',
                  value: '3',
                },
              ]}
              onChange={handleRadio_onChange}
              value={selectedRadio}
            />
          </div>
        </div>

        {/* 스위치 */}
        <div className="space-y-2">
          {/* 이름 */}
          <div className="flex justify-start items-center leading-none">
            <div className="px-2 py-1 bg-black">
              <span className="text-lg text-white font-bold">
                스위치: Switch
              </span>
            </div>
          </div>

          {/* 소스 */}
          <div className="px-2 pb-1 bg-gray-100 rounded">
            <div className="whitespace-pre">
              {_.find(sourceCode, { name: 'switch' }).sourceCode}
            </div>
          </div>

          {/* 컴포넌트 */}
          <div className="w-full p-2 border">
            <PbSwitch
              onChange={handleSwitch_onChange}
              checked={checkedSwitch}
            />
          </div>
        </div>

        {/* 년월 피커 */}
        <div className="space-y-2">
          {/* 이름 */}
          <div className="flex justify-start items-center leading-none">
            <div className="px-2 py-1 bg-black">
              <span className="text-lg text-white font-bold">
                년월 피커: Month Picker
              </span>
            </div>
          </div>

          {/* 소스 */}
          <div className="px-2 pb-1 bg-gray-100 rounded">
            <div className="whitespace-pre">
              {_.find(sourceCode, { name: 'monthPicker' }).sourceCode}
            </div>
          </div>

          {/* 컴포넌트 */}
          <div className="w-full p-2 border">
            <PbMonthPicker
              onChange={handleMonthPicker_onChange}
              value={monthPicker}
            />
          </div>
        </div>

        {/* 테이블 */}
        <div className="space-y-2">
          {/* 이름 */}
          <div className="flex justify-start items-center leading-none">
            <div className="px-2 py-1 bg-black">
              <span className="text-lg text-white font-bold">
                테이블: Table
              </span>
            </div>
          </div>

          {/* 소스 */}
          <div className="px-2 pb-1 bg-gray-100 rounded">
            <div className="whitespace-pre">
              {_.find(sourceCode, { name: 'table' }).sourceCode}
            </div>
          </div>

          {/* 컴포넌트 */}
          <div className="w-full p-2 border">
            <PbTable
              column={tableColumn}
              data={tableData}
              striped={false}
              highlightOnHover={true}
              userSelectNone={true}
              usePagination={true}
              rowPerPage={5}
              onClickRow={(item: any) => console.log('> clicked item: ', item)}
            />
          </div>
        </div>

        {/* 폼 그리드 */}
        <div className="space-y-2">
          {/* 이름 */}
          <div className="flex justify-start items-center leading-none">
            <div className="px-2 py-1 bg-black">
              <span className="text-lg text-white font-bold">
                폼 그리드: Form Grid
              </span>
            </div>
          </div>

          {/* 소스 */}
          <div className="px-2 pb-1 bg-gray-100 rounded">
            <div className="whitespace-pre">
              {_.find(sourceCode, { name: 'formGrid' }).sourceCode}
            </div>
          </div>

          {/* 컴포넌트 */}
          <div className="w-full p-2 border space-y-10">
            <div>
              <PbFormGrid label="기본" cols={2}>
                <PbFormGridCol label="항목1" colSpan={2}>
                  {/* 버튼 */}
                  <div className="flex justify-center items-center space-x-2">
                    <PbButton
                      label="검색"
                      backgroundColor="purple"
                      size="sm"
                      onClick={() => {
                        console.log('검색');
                      }}
                    />

                    <PbButton
                      label="초기화"
                      backgroundColor="white"
                      size="sm"
                      onClick={() => {
                        console.log('초기화');
                      }}
                    />
                  </div>
                </PbFormGridCol>
                <PbFormGridCol label="항목2"></PbFormGridCol>
                <PbFormGridCol label="항목3"></PbFormGridCol>
                <PbFormGridCol label="항목4"></PbFormGridCol>
                <PbFormGridCol label="항목5"></PbFormGridCol>
              </PbFormGrid>
            </div>

            <div>
              <PbFormGrid label="컬럼 숨김 적용" cols={2}>
                <PbFormGridCol label="항목1" colSpan={2}>
                  {/* 버튼 */}
                  <div className="flex justify-center items-center space-x-2">
                    <PbButton
                      label="검색"
                      backgroundColor="purple"
                      size="sm"
                      onClick={() => {
                        console.log('검색');
                      }}
                    />

                    <PbButton
                      label="초기화"
                      backgroundColor="white"
                      size="sm"
                      onClick={() => {
                        console.log('초기화');
                      }}
                    />

                    <PbButton
                      label={hideColumn ? '컬럼 열기' : '컬럼 닫기'}
                      backgroundColor="white"
                      size="sm"
                      onClick={() => setHideColumn(!hideColumn)}
                    />
                  </div>
                </PbFormGridCol>
                <PbFormGridCol label="항목2"></PbFormGridCol>
                <PbFormGridCol label="항목3"></PbFormGridCol>
                <PbFormGridCol
                  label="항목4: 숨김 컬럼"
                  isHidden={hideColumn}
                ></PbFormGridCol>
                <PbFormGridCol
                  label="항목5: 숨김 컬럼"
                  isHidden={hideColumn}
                ></PbFormGridCol>
              </PbFormGrid>
            </div>
          </div>
        </div>

        {/* 에디터 */}
        <div className="space-y-2">
          {/* 이름 */}
          <div className="flex justify-start items-center leading-none">
            <div className="px-2 py-1 bg-black">
              <span className="text-lg text-white font-bold">
                HTML 에디터: HTML Editor
              </span>
            </div>
          </div>

          {/* 소스 */}
          <div className="px-2 pb-1 bg-gray-100 rounded">
            <div className="whitespace-pre">
              {_.find(sourceCode, { name: 'htmlEditor' }).sourceCode}
            </div>
          </div>

          {/* 컴포넌트 */}
          <div className="w-full p-2 border">
            <HtmlEditor />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default SamplePage;
