import React, { PropsWithChildren } from 'react';

interface IPbMonthPickerProps {
  value?: any;
  onChange?: (event: any) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

/**
 * 년월 선택 피커
 * @param value 현재 년월
 * @param onChange 변경 이벤트
 * @param disabled 비활성화 여부
 * @param readOnly 읽기 전용 여부
 * @constructor
 */
const PbMonthPicker = ({
  value,
  onChange,
  disabled = false,
  readOnly = false,
}: PropsWithChildren<IPbMonthPickerProps>) => {
  return (
    <input
      type="month"
      onChange={onChange}
      value={value}
      className="date-picker"
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};

export default PbMonthPicker;
