import axios, { AxiosResponse } from 'axios';
import { IApiListResult, IApiResult } from '../interfaces/app.interface';
import api from '../utils/axios.util';
import * as appUtil from '../utils/app.util';

/**
 * CS: 운영 관리
 */

/**
 * CS 목록
 * GET /api/cs/css
 * @param params csDprtCode CS 부서코드
 * @param params csSttsCode CS 작업 상태
 * @param params csSttsCode2 CS 진행 상태
 * @param params cstmTrbtCode 고객 특성 코드
 * @param params cstyCode CS 유형 코드
 * @param params cstyGrpCode CS 유형 그룹 코드
 * @param params currPageIdx 현재페이지
 * @param params dsblDntfCode 장애 구분 코드
 * @param params prcsRqstEnDate 처리완료 요청 기간 종료일
 * @param params prcsRqstStDate 처리완료 요청 기간 시작일
 * @param params searchKeyword 검색어
 * @param params searchType 검색 구분
 * @param params rgstStDate 등록 기간 시작일
 * @param params rgstEnDate 등록 기간 종료일
 *
 */
export const getCss = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/cs/css',
    params: {
      csDprtCode: params.csDprtCode || '',
      csSttsCode: params.csSttsCode || '',
      csSttsCode2: params.csSttsCode2 || '',
      cstmTrbtCode: params.cstmTrbtCode || '',
      cstyCode: params.cstyCode || '',
      cstyGrpCode: params.cstyGrpCode || '',
      currPageIdx: params.currPageIdx || '1',
      dsblDntfCode: params.dsblDntfCode || '',
      prcsRqstEnDate: params.prcsRqstEnDate || '',
      prcsRqstStDate: params.prcsRqstStDate || '',
      rgstStDate: params.rgstStDate || '',
      rgstEnDate: params.rgstEnDate || '',
      searchKeyword: params.searchKeyword || '',
      searchType: params.searchType || '',
      order: params.order || 'rgstDate',
      pageSize: params.pageSize || 1000,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * CS 상세
 * GET /api/cs/cs/{csId}
 * @param params csId CS 아이디
 */
export const getCs = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cs/cs', params.csId].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * CS 엑셀 다운로드
 * GET /api/cs/cs-excel
 * @param params csDprtCode CS 부서코드
 * @param params csSttsCode CS 작업 상태
 * @param params csSttsCode2 CS 진행 상태
 * @param params cstmTrbtCode 고객 특성 코드
 * @param params cstyCode CS 유형 코드
 * @param params cstyGrpCode CS 유형 그룹 코드
 * @param params dsblDntfCode 장애 구분 코드
 * @param params prcsRqstEnDate 처리완료 요청 기간 종료일
 * @param params prcsRqstStDate 처리완료 요청 기간 시작일
 * @param params searchKeywork 검색어
 * @param params searchType 검색 구분
 */
export const getCsExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/cs/cs-excel',
    responseType: 'blob',
    params: {
      csDprtCode: params.csDprtCode || '',
      csSttsCode: params.csSttsCode || '',
      csSttsCode2: params.csSttsCode2 || '',
      cstmTrbtCode: params.cstmTrbtCode || '',
      cstyCode: params.cstyCode || '',
      cstyGrpCode: params.cstyGrpCode || '',
      dsblDntfCode: params.dsblDntfCode || '',
      order: params.order || 'rgstDate',
      prcsRqstEnDate: params.prcsRqstEnDate || '',
      prcsRqstStDate: params.prcsRqstStDate || '',
      rgstStDate: params.rgstStDate || '',
      rgstEnDate: params.rgstEnDate || '',
      searchKeyword: params.searchKeyword || '',
      searchType: params.searchType || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * CS 파일 다운로드
 * GET /api/cs/cs-file/{fileId}
 * @param params fileId 파일 아이디
 */
export const getCsFile = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: ['/api/cs/cs-file', params.fileId].join('/'),
    responseType: 'blob',
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {
      console.log('> getCsFile Error:', error);
    });

  return result;
};

/**
 * CS 접수 통계
 * GET /api/cs/css-stats
 * @param params csMngrName CS 담당자 이름
 */
export const getCssStats = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/cs/css-stats',
    params: {
      csMngrName: params.csMngrName || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> getCssStats error:', error);
    });

  return result;
};

/**
 * CS 접수유형 통계
 * GET /api/cs/css-stats-dtl
 */
export const getCssStatsDtl = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/cs/css-stats-dtl',
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * CS 진행상황 등록
 * POST /api/cs/cs-prgr
 * @param params csId CS 아이디
 * @param params fileList
 * @param params dtl
 */
export const postCsPrgr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/cs/cs-prgr',
    data: {
      csId: params.csId || '',
      fileList: params.fileList || '',
      dtl: params.dtl || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * CS 등록
 * POST /api/cs/cs
 * @param params.csSttsCode CS 상태코드
 * @param params.csDprtCode CS 부서코드
 * @param params.csMngrId CS 담당자 아이디
 * @param params.prjcCode 프로젝트 코드
 * @param params.bpCode BP 코드
 * @param params.shopId SHOP 아이디
 * @param params.nqryName 문의 그룹
 * @param params.cstyGrpCode CS 유형 그룹 코드
 * @param params.cstyCode CS 유형 코드
 * @param params.dsblDntfCode 장애 구분 코드
 * @param params.cstmTrbtCode 고객 특성 코드
 * @param params.przCntn 현상 내용
 * @param params.csDtls 원인 내용
 * @param params.prcsRslt 처리 결과
 * @param params.prcsRqstDate 처리완료 요청 일자
 * @param params.useYn 사용여부
 * @param params.calCntrId 콜센터 아이디
 * @param params.prtnTeamId 운영팀 아이디
 * @param params.dvlpTeamId 개발팀 아이디
 * @param params.fldTeamId 현장팀 아이디
 * @param params.fileList 첨부 파일
 */
export const postCs = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/cs/cs',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      nqryName: params.nqryName || '' /* 문의 그룹 */,
      csSttsCode: params.csSttsCode || '' /* CS 상태코드 */,
      csDtls: params.csDtls || '' /* 원인 내용 */,
      przCntn: params.przCntn || '' /* 현상 내용 */,
      cstmTrbtCode: params.cstmTrbtCode || '' /* 고객 특성 코드 */,
      dvlpTeamId: params.dvlpTeamId || '' /* 개발팀 아이디 */,
      dsblDntfCode: params.dsblDntfCode || '' /* 장애 구분 코드 */,
      prcsRslt: params.prcsRslt || '' /* 처리 결과 */,
      prtnTeamId: params.prtnTeamId || '' /* 운영팀 아이디 */,
      fldTeamId: params.fldTeamId || '' /* 현장팀 아이디 */,
      cstyGrpCode: params.cstyGrpCode || '' /* CS 유형 그룹 코드 */,
      calCntrId: params.calCntrId || '' /* 콜센터 아이디 */,
      csDprtCode: params.csDprtCode || '' /* CS 부서코드 */,
      csMngrId: params.csMngrId || '' /* CS 담당자 아이디 */,
      prcsRqstDate: params.prcsRqstDate || '' /* 처리완료 요청 일자 */,
      prjcCode: params.prjcCode || '' /* 프로젝트 코드 */,
      shopId: params.shopId || '' /* SHOP 아이디 */,
      bpCode: params.bpCode || '' /* BP 코드 */,
      cstyCode: params.cstyCode || '' /* CS 유형 코드 */,
      useYn: params.useYn || '' /* 사용여부 */,
      fileList: params.fileList || '' /* 첨부 파일 */,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> POST /api/cs/cs error:', error);
    });

  return result;
};

/**
 * CS 수정
 * POST /api/cs/cs-update
 * @param params csId CS 아이디
 * @param params csSttsCode CS 상태코드
 * @param params csDprtCode CS 부서코드
 * @param params csMngrId CS 담당자 아이디
 * @param params prjcCode 프로젝트 코드
 * @param params bpCode Bp 코드(고객사코드)
 * @param params shopId SHOP 아이디
 * @param params nqryName 문의그룹
 * @param params cstyGrpCode CS 유형 그룹 코드
 * @param params cstyCode CS 유형 코드
 * @param params dsblDntfCode 장애구분 코드
 * @param params cstmTrbtCode 고객 특성 코드
 * @param params przCntn 현상 내용
 * @param params csDtls 원인 내용
 * @param params prcsRslt 처리결과
 * @param params prcsRqstDate 처리완료 요청 일자
 * @param params useYn 사용여부
 * @param params calCntrId 콜센타 아이디
 * @param params prtnTeamId 운영팀 아이디
 * @param params dvlpTeamId 개발팀 아이디
 * @param params fldTeamId 현장팀 아이디
 * @param params fileList 첨부파일
 * @param params bfFileIdList 기존 첨부파일
 * @param params bfFileDscrList 기존 첨부파일 설명
 */
export const postCsUpdate = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/cs/cs-update',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      csId: params.csId || '',
      csSttsCode: params.csSttsCode || '', // CS 상태코드
      csDprtCode: params.csDprtCode || '', // CS 부서코드
      csMngrId: params.csMngrId || '', // CS 담당자 아이디
      prjcCode: params.prjcCode || '', // 프로젝트 코드
      bpCode: params.bpCode || '', // 고객사 코드
      shopId: params.shopId || '', // Shop 아이디
      nqryName: params.nqryName || '', // 문의 그룹
      cstyGrpCode: params.cstyGrpCode || '', // CS 유형 그룹 코드
      cstyCode: params.cstyCode || '', // CS 유형 코드
      dsblDntfCode: params.dsblDntfCode || '', // 장애구분 코드
      cstmTrbtCode: params.cstmTrbtCode || '', // 고객특성 코드
      przCntn: params.przCntn || '', // 현상내용
      csDtls: params.csDtls || '', // 원인 내용
      prcsRslt: params.prcsRslt || '', // 처리 결과
      prcsRqstDate: params.prcsRqstDate || '', // 처리완료 요청일자
      useYn: params.useYn || '', // 사용여부
      calCntrId: params.calCntrId || '', // 콜센타 아이디
      prtnTeamId: params.prtnTeamId || '', // 운영팀 아이디
      dvlpTeamId: params.dvlpTeamId || '', // 개발팀 아이디
      fldTeamId: params.fldTeamId || '', // 현장팀 아이디
      fileList: params.fileList, // 첨부파일
      bfFileIdList: params.bfFileIdList, // 기존 첨부파일
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postCsUpdate Error:', error);
    });

  return result;
};

/**
 * CS 삭제
 * DELETE /api/cs/cs/{csId}
 * @param params csId CS 아이디
 */
export const deleteCs = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/cs/cs', params.csId].join('/'),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * CS 진행상황 삭제
 * DELETE /api/cs/cs-prgr/{csPrgrId}
 * @paraOm params csPrgrId CS 진행상황 아이디
 */
export const deleteCsPrgr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/cs/cs-prgr/', params.csPrgrId].join(''),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * CS 등록 - 프로젝트명 셀렉트박스
 * GET /api/cs/prjcs
 */
export const getPrjcs = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cs/prjcs'].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * CS 등록 - 고객사명 셀렉트박스
 * GET /api/cs/bps/{prjcCode}
 * @param params prjcCode 프로젝트 코드
 */
export const getBps = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cs/bps', params.prjcCode].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * CS 등록 - Shop명 셀렉트박스
 * GET /api/cs/shops/{prjcCode}/{bpCode}
 * @param params bpCode bp 코드
 */
export const getShops = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cs/shops', params.bpCode].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * CS 등록 - Shop명 셀렉트박스
 * GET /api/cs/shops/{prjcCode}/{bpCode}
 * @param params prjcCode 프로젝트 코드
 * @param params bpCode bp 코드
 */
export const _getShops = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cs/shops/', params.prjcCode, params.bpCode].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * CS 접수유형 조회(셀렉트박스)
 * GET /api/cs/cs-type
 * @param params depth 검색 구분(1: 접수유형, 2: 접수유형상세)
 * @param params searchKeyword 접수 유형
 */
export const getCsType = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cs/cs-type'].join('/'),
    params: {
      depth: params.depth || '',
      searchKeyword: params.searchKeyword || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * CS 등록 - 네트워크 작업 결과
 * GET /api/cs/ntwr-rstl/{prjcCode}/{bpCode}/{shipToCode}
 * @param params prjcCode 프로젝트코드
 * @param params bpCode 고객사코드
 * @param params shipToCode shop코드(shopId 또는 shipToCode라 함)
 */
export const getNtwrRstl = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: [
      '/api/cs/ntwr-rstl',
      params.prjcCode,
      params.bpCode,
      params.shipToCode,
    ].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * CS 히스토리 목록
 * GET /api/cs/cs-hstrs/{prjcCode}/{bpCode}/{shipToCode}
 * @param params prjcCode 프로젝트코드
 * @param params bpCode 고객사코드
 * @param params shipToCode shop코드(shopId 또는 shipToCode라 함)
 */
export const getCsHstrs = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: [
      '/api/cs/cs-hstrs',
      params.prjcCode,
      params.bpCode,
      params.shipToCode,
    ].join('/'),
    params: {
      currPageIdx: 1,
      pageSize: 1000,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error:', error);
    });

  return result;
};

/**
 * CS 등록 - 고객사명 셀렉트박스
 * GET /api/cs/cs-mngr/{dprtCode}
 * @param params dprtCode 부서 코드
 */
export const getCsMngr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/cs/cs-mngr', params.dprtCode].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('getCsMngr Error:', error);
    });

  return result;
};
