import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Select, Tabs, TextInput, Tooltip } from '@mantine/core';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IModal,
  IComponentModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { pageContentStore } from '../stores/page.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { addComponentModalStore } from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { Radio, Group, Checkbox } from '@mantine/core';
import { PageLayout } from '../components/PageLayout';
import * as nstlApi from '../apis/nstl.api';
import * as csApi from '../apis/cs.api';
import * as sttnApi from '../apis/sttn.api';
import { ReactSortable } from 'react-sortablejs';
import * as cmnApi from '../apis/cmn.api';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { nanoid } from 'nanoid';
import Joi from 'joi';
import { notifications } from '@mantine/notifications';

/**
 * 시스템 관리 > 웹 메뉴 관리
 * @constructor
 */
const MN2302220807Page = ({ menuType }: { menuType: string }) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 메뉴를 정의함
  const [baseMenuList, setBaseMenuList] = useState<any[]>([]);
  const [menuList, setMenuList] = useState<any[]>([]);
  const [subMenuList, setSubMenuList] = useState<any[]>([]);

  // 선택한 메뉴를 정의함
  const [selectedSubMenu, setSelectedSubMenu] = useState<any>(null);

  // 메뉴 구분 셀렉트를 정의함
  const [menuTypeSelect, setMenuTypeSelect] = useState<string>('P');
  const [menuTypeSelectItem, setMenuTypeSelectItem] = useState<IOptionItem[]>(
    [],
  );

  // 사용여부 셀렉트를 정의함
  const [menuUsedSelect, setMenuUsedSelect] = useState<string>('Y');
  const [menuUsedSelectItem, setMenuUsedSelectItem] = useState<IOptionItem[]>(
    [],
  );

  // 유효성 검사 형식을 정의함
  const userSchema: any = Joi.object({
    menuName: Joi.string()
      .min(2)
      .max(30)
      // .pattern(new RegExp(/^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9]+$/))
      .required()
      .messages({
        'string.empty': '메뉴 이름을 입력하세요.',
        'string.min': '메뉴 이름은 최소 2글자 이상입니다.',
        // 'string.pattern.base':
        //   '메뉴 이름은 한글, 알파벳 대/소문자, 숫자만 가능합니다.',
      }),
    menuUrl: Joi.string().min(2).max(100).required().messages({
      'string.empty': '화면 접속 주소를 입력하세요.',
      'string.min': '화면 접속 주소는 최소 2글자 이상입니다.',
    }),
  });

  // 메뉴 목록을 불러옴
  const getMenuList = (menuType: string) => {
    // 사용이 가능한 메뉴를 불러옴
    cmnApi
      .getSystemMenus({
        menuDvsnCode: menuType,
        useYn: 'Y',
        pageSize: 10000,
      })
      .then((useData: IApiResult) => {
        if (useData.data.list !== undefined) {
          // 정렬을 위해 불러온 메뉴를 보정함
          useData.data.list.map((item: any) => {
            item.oneSortRdr = +item.oneSortRdr;
            item.twoSortRdr = +item.twoSortRdr;
            item.threeSortRdr = +item.threeSortRdr;
            item.used = true;
          });

          // 사용이 중지된 메뉴를 불러옴
          cmnApi
            .getSystemMenus({
              menuDvsnCode: menuType,
              useYn: 'N',
              pageSize: 10000,
            })
            .then((noUseData: IApiResult) => {
              if (noUseData.data.list !== undefined) {
                // 정렬을 위해 불러온 메뉴를 보정함
                noUseData.data.list.map((item: any) => {
                  item.oneSortRdr = +item.oneSortRdr;
                  item.twoSortRdr = +item.twoSortRdr;
                  item.threeSortRdr = +item.threeSortRdr;
                  item.used = false;
                });

                // 메뉴의 최종 목록을 생성함
                let tmpData: any[] = [
                  ...useData.data.list,
                  ...noUseData.data.list,
                ];

                // 메뉴를 순서대로 정렬함
                tmpData = _.orderBy(
                  tmpData,
                  ['oneSortRdr', 'twoSortRdr', 'threeSortRdr'],
                  ['asc', 'asc', 'asc'],
                );

                // 원본 메뉴에 적용함
                setBaseMenuList(_.cloneDeep(tmpData));

                // 메뉴를 불러옴
                let tmpMenuList: any[] = _.uniqBy(
                  tmpData.filter(
                    (item: any) => item.oneMenuId !== 'MN2302220100',
                  ),
                  'oneMenuId',
                );

                // 메뉴에 적용함
                setMenuList(tmpMenuList);

                let tmpSubMenuList: any = {};

                // 메뉴에 해당하는 하위 메뉴를 불러옴
                tmpMenuList.map((item: any) => {
                  tmpSubMenuList[item.oneMenuId] = '';
                });

                // 하위 메뉴에 적용함
                setSubMenuList(
                  tmpData.filter(
                    (item: any) =>
                      item.oneMenuId !== 'MN2302220100' &&
                      item.twoMenuId !== '',
                  ),
                );
              }
            });
        }
      });
  };

  // 메뉴 구분 셀렉트 아이템을 불러옴
  const getMenuTypeItem = () => {
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'MENU_TYPE',
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          let tmpOptionItem: IOptionItem[] = [];

          data.data.list.map((item: any) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          });

          // 메뉴 구분 셀렉트 아이템에 적용함
          setMenuTypeSelectItem(tmpOptionItem);
        }
      });
  };

  // 사용여부 셀렉트 아이템을 불러옴
  const getMenuUsedItem = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '사용',
        value: 'Y',
      },
      {
        label: '미사용',
        value: 'N',
      },
    );

    // 사용여부 셀렉트 아이템에 적용함
    setMenuUsedSelectItem(tmpOptionItem);
  };

  // 메뉴 이름을 변경함
  const handleMenuNameInput_onChange = (event: any) => {
    setSelectedSubMenu((pre: any) => ({
      ...pre,
      twoKrnMenu: event.target.value,
    }));
  };

  // 화면 접속 URL을 변경함
  const handleMenuUrlInput_onChange = (event: any) => {
    setSelectedSubMenu((pre: any) => ({
      ...pre,
      twoUrl: event.target.value,
    }));
  };

  // 메뉴 설명을 변경함
  const handleMenuDescInput_onChange = (event: any) => {
    setSelectedSubMenu((pre: any) => ({
      ...pre,
      twoKrnDscr: event.target.value,
    }));
  };

  // 메뉴 구분을 변경함
  const handleMenuTypeSelect_onChange = (event: any) => {
    setMenuTypeSelect(event);

    setSelectedSubMenu((pre: any) => ({
      ...pre,
      twoMenuTypeCode: event,
    }));
  };

  // 사용여부를 변경함
  const handleMenuUsedSelect_onChange = (event: any) => {
    setMenuUsedSelect(event);

    setSelectedSubMenu((pre: any) => ({
      ...pre,
      used: event === 'Y' ? true : false,
    }));
  };

  // 하위 메뉴 추가 버튼을 클릭함
  const handleAddSubMenuButton_onClick = (item: any) => {
    // 선택한 메뉴의 상위 메뉴 정보를 불러옴
    let tmpMenuGroup: any = menuList.filter(
      (filterItem: any) => filterItem.oneMenuId === item.oneMenuId,
    )[0];

    // 선택한 메뉴의 하위 메뉴 목록의 개수를 불러옴
    let tmpNewOrderNo: number =
      subMenuList.filter(
        (filterItem: any) => filterItem.oneMenuId === item.oneMenuId,
      ).length || 0 + 1;

    let tmpNewSubMenu: any = {
      oneJpnsDscr: tmpMenuGroup.oneJpnsDscr,
      oneJpnsMenu: tmpMenuGroup.oneJpnsMenu,
      oneKrnDscr: tmpMenuGroup.oneKrnDscr,
      oneKrnMenu: tmpMenuGroup.oneKrnMenu,
      oneMenuCmpn: tmpMenuGroup.oneMenuCmpn,
      oneMenuId: tmpMenuGroup.oneMenuId,
      oneMenuTypeCode: tmpMenuGroup.oneMenuTypeCode,
      oneMenuTypeName: tmpMenuGroup.oneMenuTypeName,
      oneNglsDscr: tmpMenuGroup.oneNglsDscr,
      oneNglsMenu: tmpMenuGroup.oneNglsMenu,
      oneSortRdr: tmpMenuGroup.oneSortRdr,
      oneUrl: tmpMenuGroup.oneUrl,
      twoJpnsDscr: '',
      twoJpnsMenu: '',
      twoKrnDscr: '',
      twoKrnMenu: '',
      twoMenuCmpn: '',
      twoMenuId: '',
      twoMenuTypeCode: 'P',
      twoMenuTypeName: '페이지',
      twoNglsDscr: '',
      twoNglsMenu: '',
      twoSortRdr: tmpNewOrderNo,
      twoUrl: '/',
      threeJpnsDscr: '',
      threeJpnsMenu: '',
      threeKrnDscr: '',
      threeKrnMenu: '',
      threeMenuCmpn: '',
      threeMenuId: '',
      threeMenuTypeCode: '',
      threeMenuTypeName: '',
      threeNglsDscr: '',
      threeNglsMenu: '',
      threeSortRdr: 0,
      threeUrl: '',
      fourJpnsDscr: '',
      fourJpnsMenu: '',
      fourKrnDscr: '',
      fourKrnMenu: '',
      fourMenuCmpn: '',
      fourMenuId: '',
      fourMenuTypeCode: '',
      fourMenuTypeName: '',
      fourNglsDscr: '',
      fourNglsMenu: '',
      fourSortRdr: '',
      fourUrl: '',
      used: false,
    };

    // 메뉴구분, 사용여부의 초기값을 적용함
    setMenuTypeSelect(tmpNewSubMenu.twoMenuTypeCode);
    setMenuUsedSelect(tmpNewSubMenu.used ? 'Y' : 'N');

    // 선택한 메뉴에 적용함(신규 등록)
    setSelectedSubMenu(tmpNewSubMenu);
  };

  // 임시삭제 버튼을 클릭함
  const handleTempDeleteButton_onClick = () => {
    setSubMenuList((pre: any) =>
      pre.filter(
        (filterItem: any) => filterItem.twoMenuId !== selectedSubMenu.twoMenuId,
      ),
    );

    // 선택한 메뉴를 초기화함
    setSelectedSubMenu(null);
  };

  // 임시저장 버튼을 클릭함
  const handleTempSaveButton_onClick = () => {
    // 입력 항목의 유효성을 검사함
    const data = userSchema.validate({
      menuName: selectedSubMenu.twoKrnMenu,
      menuUrl: selectedSubMenu.twoUrl,
    });

    // 유효성 검사가 모두 통과되면 오류가 undefined로 반환됨
    if (data.error !== undefined) {
      // 오류를 출력함
      setModal({
        title: '알림',
        content: data.error.message,
      });

      return;
    }

    if (selectedSubMenu.twoMenuId === '') {
      // 메뉴를 추가함

      let tmpSelectedSubMenu: any = _.cloneDeep(selectedSubMenu);

      tmpSelectedSubMenu.twoMenuId = nanoid();
      tmpSelectedSubMenu.twoMenuTypeCode = menuTypeSelect;
      tmpSelectedSubMenu.used = menuUsedSelect === 'Y' ? true : false;

      setSubMenuList((pre: any) => [...pre, tmpSelectedSubMenu]);
    } else {
      // 메뉴를 수정함

      let tmpSubMenuList: any = _.cloneDeep(subMenuList);

      tmpSubMenuList = tmpSubMenuList.map((item: any) => {
        if (item.twoMenuId === selectedSubMenu.twoMenuId) {
          item = selectedSubMenu;
          item.twoMenuTypeCode = menuTypeSelect;
          item.used = menuUsedSelect === 'Y' ? true : false;
        }

        return item;
      });

      setSubMenuList(tmpSubMenuList);
    }

    // 선택한 메뉴를 초기화함
    setSelectedSubMenu(null);
  };

  // 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    // HOME 메뉴를 불러옴
    let extraMenu: any = baseMenuList.filter(
      (item: any) => item.oneMenuId === 'MN2302220100',
    );

    // 저장용 최종 메뉴 목록을 정의함
    let tmpMenuList: any = [...extraMenu, ...menuList, ...subMenuList];

    // 추가된 메뉴를 정의함
    let tmpAddMenuList: any[] = [];

    // 삭제된 메뉴를 정의함
    let tmpRemoveMenuList: any[] = [];

    // 수정된 메뉴를 정의함
    let tmpModifyMenuList: any[] = [];

    // 추가된 메뉴를 불러옴
    tmpMenuList.map((item: any) => {
      if (
        _.findIndex(baseMenuList, {
          oneMenuId: item.oneMenuId,
          twoMenuId: item.twoMenuId,
        }) < 0
      ) {
        tmpAddMenuList.push(item);
      }
    });

    // 삭제된 메뉴를 불러옴
    baseMenuList.map((item: any) => {
      if (
        _.findIndex(tmpMenuList, {
          oneMenuId: item.oneMenuId,
          twoMenuId: item.twoMenuId,
        }) < 0
      ) {
        tmpRemoveMenuList.push(item);
      }
    });

    // 수정된 메뉴를 불러옴
    baseMenuList.map((item: any, index: number) => {
      if (
        _.findIndex(tmpMenuList, {
          oneMenuId: item.oneMenuId,
          twoMenuId: item.twoMenuId,
        }) >= 0
      ) {
        let tmpFindMenu: any = _.find(tmpMenuList, {
          oneMenuId: item.oneMenuId,
          twoMenuId: item.twoMenuId,
        });

        // 임의의 컬럼을 삭제함
        delete tmpFindMenu['chosen'];
        delete tmpFindMenu['selected'];

        if (_.isEqual(item, tmpFindMenu) === false) {
          tmpModifyMenuList.push(tmpFindMenu);
        }
      }
    });

    console.log('> tmpAddMenuList:', tmpAddMenuList);
    console.log('> tmpRemoveMenuList:', tmpRemoveMenuList);
    console.log('> tmpModifyMenuList:', tmpModifyMenuList);

    if (
      tmpAddMenuList.length +
        tmpRemoveMenuList.length +
        tmpModifyMenuList.length ===
      0
    ) {
      setModal({
        title: '확인',
        content: '편집된 내용이 없습니다.',
      });

      return;
    }

    console.log('> 추가:', tmpAddMenuList);
    console.log('> 수정:', tmpModifyMenuList);
    console.log('> 삭제:', tmpRemoveMenuList);

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 메뉴를 추가함
            const addMenu = (menus: any[]) => {
              if (menus.length === 0) {
                // 메뉴를 삭제함
                removeMenu(tmpRemoveMenuList);

                return;
              }

              let tmpMenu: any = menus[0];

              cmnApi
                .postMenu({
                  prntMenuId: tmpMenu.oneMenuId,
                  krnMenu: tmpMenu.twoKrnMenu,
                  krnDscr: tmpMenu.twoKrnDscr,
                  url: tmpMenu.twoUrl,
                  menuDvsnCode: menuType,
                  menuTypeCode: tmpMenu.twoMenuTypeCode,
                  useYn: tmpMenu.used ? 'Y' : 'N',
                  sortRdr: tmpMenu.twoSortRdr,
                })
                .then((data: IApiResult) => {
                  menus.shift();
                  addMenu(menus);
                });
            };

            // 메뉴를 삭제함
            const removeMenu = (menus: any[]) => {
              if (menus.length === 0) {
                // 메뉴를 수정함
                modifyMenu(tmpModifyMenuList);

                return;
              }

              let tmpMenu: any = menus[0];

              cmnApi
                .deleteMenu({
                  menuId: tmpMenu.twoMenuId,
                })
                .then((data: IApiResult) => {
                  menus.shift();
                  removeMenu(menus);
                });
            };

            // 메뉴를 수정함
            const modifyMenu = (menus: any[]) => {
              if (menus.length === 0) {
                setModal({
                  title: '확인',
                  content: '저장하였습니다.',
                });

                return;
              }

              let tmpMenu: any = menus[0];

              cmnApi
                .putMenu({
                  menuId: tmpMenu.twoMenuId,
                  url: tmpMenu.twoUrl,
                  krnMenu: tmpMenu.twoKrnMenu,
                  krnDscr: tmpMenu.twoKrnDscr,
                  menuTypeCode: tmpMenu.twoMenuTypeCode,
                  sortRdr: tmpMenu.twoSortRdr,
                  useYn: tmpMenu.used ? 'Y' : 'N',
                })
                .then((data: IApiResult) => {
                  menus.shift();
                  modifyMenu(menus);
                });
            };

            // 메뉴를 추가함
            addMenu(tmpAddMenuList);
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 메뉴 목록을 불러옴
    getMenuList(menuType);
    // getMenuList('MBL');

    // 메뉴 구분 셀렉트 아이템을 불러옴
    getMenuTypeItem();

    // 사용여부 셀렉트 아이템을 불러옴
    getMenuUsedItem();

    return () => {};
  }, []);

  // 웹 메뉴가 변경될 때 실행함
  useEffect(() => {
    // console.log('> menuList:', menuList);
    return () => {};
  }, [menuList]);

  // 웹 메뉴가 변경될 때 실행함
  useEffect(() => {
    // console.log('> subMenuList:', subMenuList);
    return () => {};
  }, [subMenuList]);

  useEffect(() => {
    // console.log('> menuUsedSelect:', menuUsedSelect);
    return () => {};
  }, [menuUsedSelect]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={
        <div className="flex justify-center items-center space-x-3">
          <div className="flex justify-center items-center">
            <span className="text-base text-gray-500 font-semibold">
              편집한 메뉴를 실제로 시스템에 적용하려면 오른쪽의 [저장] 버튼을
              클릭하세요.
            </span>
          </div>

          {/* 저장 버튼 */}
          <div>
            <Button
              color="indigo"
              radius="xl"
              onClick={handleSaveButton_onClick}
            >
              저장
            </Button>
          </div>
        </div>
      }
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="w-full flex justify-start items-start space-x-10">
        {/* 메뉴 편집 */}
        <div className="flex justify-start items-center">
          <ReactSortable
            dragClass="menu-dragging"
            list={menuList}
            setList={() => {}}
            // setList={setMenuList}
            animation={200}
            easing="ease-out"
            className="space-y-2"
            onUpdate={(event: any) => {
              // 순서를 변경한 메뉴의 아이디를 불러옴
              let tmpItem: HTMLDivElement = event.item;

              // 순서를 변경한 하위 메뉴의 정보를 불러옴
              let tmpItemData: any = _.find(menuList, {
                oneMenuId: tmpItem.dataset.id,
              });

              let tmpMenuList: any = _.cloneDeep(menuList);

              // 순서를 변경한 하위 메뉴의 실제 순서를 변경함
              tmpMenuList.splice(event.oldIndex, 1);
              tmpMenuList.splice(event.newIndex, 0, tmpItemData);

              // 정렬 순서 번호를 재지정함
              tmpMenuList.map((item: any, index: number) => {
                item.oneSortRdr = index + 2;
              });

              // 순서를 변경한 메뉴를 메뉴 목록에 적용함
              setMenuList(tmpMenuList);
            }}
          >
            {menuList.map((item: any) => (
              <div key={item.oneMenuId}>
                {/* 메뉴 */}
                <div
                  style={{ padding: '7px 5px 7px 15px' }}
                  className="menu-dragging flex justify-start items-center bg-blue-100 space-x-2 border-l border-blue-300 cursor-move"
                >
                  {/* 아이콘 */}
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'folder']}
                      className="w-4 h-4 text-gray-500"
                    />
                  </div>

                  {/* 메뉴 이름 */}
                  <div className="w-full flex justify-between items-center">
                    {/* 메뉴 이름 */}
                    <div className="flex justify-center items-center">
                      <span className="text-base text-gray-500 font-semibold leading-none">
                        {item.oneKrnMenu}
                      </span>
                    </div>

                    {/* 하위 메뉴 추가 버튼 */}
                    <Tooltip label="하위 메뉴 추가">
                      <div
                        onClick={() => handleAddSubMenuButton_onClick(item)}
                        style={{ padding: '5px 10px' }}
                        className="button-event flex justify-center items-center"
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'plus']}
                          className="w-3 h-3 text-gray-500"
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>

                {/* 하위 메뉴 */}
                <div className="px-2 py-1 border-l border-gray-200">
                  <ReactSortable
                    dragClass="sub-menu-dragging"
                    list={subMenuList}
                    setList={() => {}}
                    // setList={setSubMenuList}
                    animation={200}
                    easing="ease-out"
                    className="space-y-2"
                    onUpdate={(event: any) => {
                      // 순서를 변경한 하위 메뉴의 아이디를 불러옴
                      let tmpSubItem: HTMLDivElement = event.item;

                      // 순서를 변경한 하위 메뉴의 정보를 불러옴
                      let tmpSubItemData: any = _.find(subMenuList, {
                        twoMenuId: tmpSubItem.dataset.id,
                      });

                      // 순서를 변경한 하위 메뉴의 그룹에 포함된 하위 메뉴들을 불러옴
                      let tmpSubItemGroupData: any = _.filter(subMenuList, {
                        oneMenuId: tmpSubItemData.oneMenuId,
                      });

                      // 순서를 변경한 하위 메뉴의 실제 순서를 변경함
                      tmpSubItemGroupData.splice(event.oldIndex, 1);
                      tmpSubItemGroupData.splice(
                        event.newIndex,
                        0,
                        tmpSubItemData,
                      );

                      // 정렬 순서 번호를 재지정함
                      tmpSubItemGroupData.map((item: any, index: number) => {
                        item.twoSortRdr = index + 1;
                      });

                      // 순서를 변경한 하위 메뉴를 메뉴 목록에 적용함
                      let newSubMenuList: any[] = [
                        ...subMenuList.filter(
                          (filterItem: any) =>
                            filterItem.oneMenuId !== tmpSubItemData.oneMenuId,
                        ),
                        ...tmpSubItemGroupData,
                      ];
                      setSubMenuList(newSubMenuList);
                    }}
                  >
                    {subMenuList
                      .filter(
                        (filterItem: any) =>
                          filterItem.oneMenuId === item.oneMenuId,
                      )
                      .map((subItem: any) => (
                        <div
                          key={subItem.twoMenuId}
                          onClick={() => {
                            setMenuTypeSelect(subItem.twoMenuTypeCode);
                            setMenuUsedSelect(subItem.used ? 'Y' : 'N');

                            // 선택한 메뉴에 적용함
                            setSelectedSubMenu(subItem);
                          }}
                          className="sub-menu-dragging space-y-1 cursor-move"
                        >
                          {subItem.twoMenuId === selectedSubMenu?.twoMenuId ? (
                            <div className="flex justify-start items-center underline underline-offset-2 decoration-amber-500">
                              <span className="text-sm text-amber-500 font-semibold leading-none">
                                {subItem.twoKrnMenu}
                              </span>
                            </div>
                          ) : (
                            <div className="flex justify-start items-center">
                              <span className="text-sm text-gray-500 font-semibold leading-none">
                                {subItem.twoKrnMenu}
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                  </ReactSortable>
                </div>
              </div>
            ))}
          </ReactSortable>
        </div>

        {/* 상세 정보 편집 */}
        <div className="relative flex justify-start items-center">
          {/* 편집 폼 그리드 */}
          {selectedSubMenu !== null && (
            <div className="fixed top-48 space-y-3 z-20">
              <PbFormGrid
                label={
                  selectedSubMenu.twoMenuId === '' ? '메뉴 추가' : '메뉴 수정'
                }
                cols={2}
              >
                <PbFormGridCol label="상위 메뉴 이름">
                  <TextInput
                    placeholder=""
                    readOnly={true}
                    value={selectedSubMenu.oneKrnMenu}
                    className="w-full"
                  />
                </PbFormGridCol>
                <PbFormGridCol label="메뉴 이름">
                  <TextInput
                    placeholder=""
                    onChange={handleMenuNameInput_onChange}
                    value={selectedSubMenu.twoKrnMenu}
                    className="w-full"
                  />
                </PbFormGridCol>
                <PbFormGridCol label="화면 접속 URL" colSpan={2}>
                  <TextInput
                    placeholder=""
                    onChange={handleMenuUrlInput_onChange}
                    value={selectedSubMenu.twoUrl}
                    className="w-full"
                  />
                </PbFormGridCol>
                <PbFormGridCol label="메뉴 설명" colSpan={2}>
                  <TextInput
                    placeholder=""
                    onChange={handleMenuDescInput_onChange}
                    value={selectedSubMenu.twoKrnDscr}
                    className="w-full"
                  />
                </PbFormGridCol>
                <PbFormGridCol label="메뉴 구분">
                  <Select
                    onChange={handleMenuTypeSelect_onChange}
                    data={menuTypeSelectItem}
                    value={menuTypeSelect}
                    className="w-full"
                  />
                </PbFormGridCol>
                <PbFormGridCol label="사용여부">
                  <Select
                    onChange={handleMenuUsedSelect_onChange}
                    data={menuUsedSelectItem}
                    value={menuUsedSelect}
                    className="w-full"
                  />
                </PbFormGridCol>
              </PbFormGrid>

              {/* 편집 버튼 */}
              <div className="flex justify-end items-center space-x-2">
                <div>
                  <Button
                    variant="outline"
                    color="gray"
                    radius="xl"
                    size="md"
                    onClick={() => setSelectedSubMenu(null)}
                  >
                    취소
                  </Button>
                </div>

                {selectedSubMenu.twoMenuId !== '' && (
                  <div>
                    <Button
                      variant="outline"
                      color="pink"
                      radius="xl"
                      size="md"
                      onClick={handleTempDeleteButton_onClick}
                    >
                      임시삭제
                    </Button>
                  </div>
                )}

                <div>
                  <Button
                    color="indigo"
                    radius="xl"
                    size="md"
                    onClick={handleTempSaveButton_onClick}
                  >
                    임시저장
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220807Page;
