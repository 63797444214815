import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  IPageContent,
  IComponentModalPageScrollTabStep,
  IComponentModalPageScrollTab,
  ITablePaging,
  IApiResult,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import _, { forEach } from 'lodash';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { Button, Select, TextInput, Drawer } from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import { ValueFormatterParams } from 'ag-grid-community';
import { PbAgGridReact } from '../PbAgGridReact';
import * as appUtil from '../../utils/app.util';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import { decode } from 'html-entities';
import * as grpApi from '../../apis/grp.api';

interface IComponentProps {
  id?: string;
  groupId?: string;
  useModifyMode?: boolean;
  data?: any;
  onClick?: () => void;
}

/**
 * 시스템 관리 > 설치 권한 그룹 관리 > 조회, 수정
 * @param id 컴포넌트 모달 아이디
 * @param groupId 권한 아이디
 * @param useModifyMode 수정 모드 사용 여부
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220880 = ({
  id = '',
  groupId = '',
  useModifyMode = false,
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  /**
   * 저장소
   */

  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 기본 정보
   */

  // 설치 그룹명 입력을 정의함
  const [groupNameInput, setGroupNameInput] = useState<string>('');
  const groupNameInputRef = useRef<HTMLInputElement>(null);

  // 설치 그룹 설명 입력을 정의함
  const [groupDescriptionInput, setGroupDescriptionInput] =
    useState<string>('');
  const groupDescriptionInputRef = useRef<HTMLInputElement>(null);

  // 설치 그룹명 중복확인을 정의함
  const [checkGroupName, setCheckGroupName] = useState<boolean>(false);
  const [checkGroupNameResult, setCheckGroupNameResult] =
    useState<boolean>(false);

  // 설치 그룹명 입력의 값을 변경함
  const handleGroupNameInput_onChange = (event: any) => {
    setGroupNameInput(event.currentTarget.value);

    // 설치 그룹명 중복확인을 초기화함
    setCheckGroupName(false);
  };

  // 설치 그룹 설명 입력의 값을 변경함
  const handleGroupDescriptionInput_onChange = (event: any) => {
    setGroupDescriptionInput(event.currentTarget.value);
  };

  // 설치 그룹명 중복 확인 버튼을 클릭함
  const handleGroupNameDuplicateConfirmButton_onClick = () => {
    if (groupNameInput.trim() === '') {
      setModal({
        title: '알림',
        content: '설치 그룹명을 입력해 주세요.',
      });

      groupNameInputRef.current?.focus();
      return;
    }

    if (!groupId) {
      // 신규
      grpApi
        .getRgstCheck({
          grpName: groupNameInput,
        })
        .then((data: IApiResult) => {
          if (data.code === '200') {
            // 설치 그룹명 중복확인을 완료함
            setCheckGroupName(true);

            // 설치 그룹명 중복확인 결과에 적용함
            setCheckGroupNameResult(true);

            setModal({
              title: '알림',
              content: '사용할 수 있는 설치 그룹명입니다.',
            });
          } else {
            // 설치 그룹명 중복확인을 초기화함
            setCheckGroupName(false);

            setModal({
              title: '알림',
              content: '이미 사용 중인 설치 그룹명입니다.',
            });
          }
        });
    } else {
      // 수정
      grpApi
        .getCrctCheck({
          grpId: groupId,
          grpName: groupNameInput,
        })
        .then((data: IApiResult) => {
          if (data.code === '200') {
            // 설치 그룹명 중복확인을 완료함
            setCheckGroupName(true);

            // 설치 그룹명 중복확인 결과에 적용함
            setCheckGroupNameResult(true);

            setModal({
              title: '알림',
              content: '사용할 수 있는 설치 그룹명입니다.',
            });
          } else {
            // 설치 그룹명 중복확인을 초기화함
            setCheckGroupName(false);

            setModal({
              title: '알림',
              content: '이미 사용 중인 설치 그룹명입니다.',
            });
          }
        });
    }
  };

  /**
   * 설치 그룹에 할당된 팀 테이블
   */

  // 테이블을 정의함
  const [groupTableColumn, setGroupTableColumn] = useState<any>([]);
  const [groupTable, setGroupTable] = useState<any>([]);
  const groupTableRef = useRef<any>(null);

  // 테이블 페이징을 정의함
  const [groupTablePaging, setGroupTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const groupTablePagingCurrentPageRef = useRef<number>(1);

  // 테이블을 초기화함
  const initGroupTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 추가, 수정 상태일 때만 아래의 컬럼을 추가함
    if (!(groupId && !useModifyMode)) {
      // 컬럼에 추가함
      tmpColumn.push({
        field: 'checkbox',
        width: 60,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      });
    }

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'splrName',
        headerName: '협력사',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.splrName);
        },
      },
      {
        field: 'teamName',
        headerName: '팀',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.teamName);
        },
      },
      {
        field: 'teamDscr',
        headerName: '설명',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.teamDscr);
        },
      },
      {
        field: 'cntcName',
        headerName: '담당자',
        width: 120,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.cntcName);
        },
      },
    );

    // 테이블에 적용함
    setGroupTableColumn(tmpColumn);
  };

  // 테이블의 데이터를 불러옴
  const getGroupTableData = () => {
    let tmpData: any = [];

    console.log('> detailData:', detailData);

    // 테이블에 적용함
    setGroupTable(detailData.grpTmVoList);
  };

  // 테이블의 전체 삭제 버튼을 클릭함
  const handleGroupTableAllDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '전체 항목을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 테이블에 적용함
            setGroupTable([]);

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 테이블의 선택 삭제 버튼을 클릭함
  const handleGroupTableDeleteButton_onClick = () => {
    // 선택한 항목을 정의함
    let selectedId: any = [];

    // 선택한 항목을 불러옴
    groupTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedId.push(`${item.splrId} ${item.teamId}`);
    });

    // 선택한 항목이 없음
    if (selectedId.length === 0) {
      setModal({
        title: '알림',
        content: '삭제할 항목을 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: `선택하신 ${selectedId.length}개의 항목을 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 선택한 항목을 삭제함
            let tmpTableData: any = groupTable.filter(
              (item: any) =>
                !_.includes(selectedId, `${item.splrId} ${item.teamId}`),
            );

            // 테이블에 적용함
            setGroupTable(tmpTableData);

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });
          }}
        >
          예
        </Button>
      ),
    });
  };

  /**
   * 설치 그룹에 할당할 팀 드로어
   */

  // 드로어의 출력 여부를 정의함
  const [openedDrawer, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  // 검색 조건 셀렉트를 정의함
  const [drawerSearchSelectItem, setDrawerSearchSelectItem] = useState<
    IOptionItem[]
  >([]);
  const [drawerSearchSelect, setDrawerSearchSelect] = useState<string>('');

  // 검색어 입력을 정의함
  const [drawerSearchInput, setDrawerSearchInput] = useState<string>('');

  // 테이블을 정의함
  const [drawerTableColumn, setDrawerTableColumn] = useState<any>([]);
  const [drawerTable, setDrawerTable] = useState<any>([]);
  const [drawerFilterTable, setDrawerFilterTable] = useState<any>([]);
  const drawerTableRef = useRef<any>(null);

  // 테이블 페이징을 정의함
  const [drawerTablePaging, setDrawerTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const drawerTablePagingCurrentPageRef = useRef<number>(1);

  // 테이블을 초기화함
  const initDrawerTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'checkbox',
        width: 60,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      },
      {
        field: 'splrName',
        headerName: '협력사',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.splrName);
        },
      },
      {
        field: 'teamName',
        headerName: '팀',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.teamName);
        },
      },
      {
        field: 'cntcName',
        headerName: '담당자',
        width: 100,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.cntcName);
        },
      },
    );

    // 테이블에 적용함
    setDrawerTableColumn(tmpColumn);
  };

  // 테이블의 데이터를 불러옴
  const getDrawerTableData = () => {
    grpApi.getGrpIdTeams({}).then((data: IApiResult) => {
      if (data.code === '200') {
        // 테이블에 적용함
        setDrawerTable(data.data);
        setDrawerFilterTable(data.data);
      }
    });
  };

  // 검색어 입력의 값을 변경함
  const drawerSearchInput_onChange = (event: any) => {
    setDrawerSearchInput(event.currentTarget.value);
  };

  // 검색어 입력에서 키를 입력함
  const drawerSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 검색 버튼을 클릭함
      drawerSearchButton_onClick();
    }
  };

  // 검색 버튼을 클릭함
  const drawerSearchButton_onClick = () => {
    // 검색어에 맞게 데이터를 필터링함
    let tmpData: any = drawerTable.filter(
      (filterItem: any) =>
        filterItem.splrName.indexOf(drawerSearchInput) > -1 ||
        filterItem.teamName.indexOf(drawerSearchInput) > -1 ||
        filterItem.cntcName.indexOf(drawerSearchInput) > -1,
    );

    // 필터링한 데이터를 테이블에 적용함
    setDrawerFilterTable(tmpData);
  };

  // 선택한 팀을 설치 그룹에 할당함
  const drawerAddButton_onClick = () => {
    // 선택한 항목을 정의함
    let selectedId: any = [];

    // 선택한 항목을 불러옴
    drawerTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedId.push({
        splrId: item.splrId,
        teamId: item.teamId,
      });
    });

    // 선택한 항목이 없음
    if (selectedId.length === 0) {
      setModal({
        title: '알림',
        content: '추가할 항목을 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: `선택하신 ${selectedId.length}개의 항목을 추가하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            let tmpTableData: any = [];

            // 기존에 없던 항목만 추가함
            selectedId.map((item: any) => {
              if (
                _.findIndex(groupTable, {
                  splrId: item.splrId,
                  teamId: item.teamId,
                }) === -1
              ) {
                tmpTableData.push(
                  _.find(drawerTable, {
                    splrId: item.splrId,
                    teamId: item.teamId,
                  }),
                );
              }
            });

            // 테이블에 적용함
            setGroupTable([...groupTable, ...tmpTableData]);

            notifications.show({
              title: '알림',
              message: '추가하였습니다.',
            });

            // 드로어를 닫음
            closeDrawer();
          }}
        >
          예
        </Button>
      ),
    });
  };

  /**
   * 하단 버튼
   */

  // 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '설치 그룹 수정',
        content: (
          <MN2302220880 id={tmpId} groupId={groupId} useModifyMode={true} />
        ),
        size: 1000,
      });
    }, 100);
  };

  // 저장 버튼(신규)을 클릭함
  const handleSaveButton_onClick = () => {
    if (groupNameInput.trim() === '') {
      setModal({
        title: '알림',
        content: '설치 그룹명을 입력해 주세요.',
      });

      groupNameInputRef.current?.focus();
      return;
    }

    if (!checkGroupName) {
      setModal({
        title: '알림',
        content: '설치 그룹명의 중복 확인이 필요합니다.',
      });

      return;
    }

    // 설치 그룹에 할당된 팀을 불러옴
    let tmpTeam: any[] = [];
    groupTable.map((item: any) => {
      tmpTeam.push({
        splrId: item.teamId,
        teamId: item.teamId,
      });
    });

    if (tmpTeam.length === 0) {
      setModal({
        title: '알림',
        content: '설치 그룹에 할당할 팀을 추가해 주세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 저장함
            grpApi
              .postGrp({
                grpName: groupNameInput,
                grpDscr: groupDescriptionInput,
                grpTmRegParamVoList: tmpTeam,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  notifications.show({
                    title: '알림',
                    message: '저장하였습니다.',
                  });

                  // 목록 새로고침을 추가함
                  setAddRefreshList('MN2302220808Page');

                  // 컴포넌트 모달을 닫음
                  setRemoveComponentModal(id);
                } else {
                  console.log('> 설치 그룹 저장 Error:', data);

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                }
              })
              .catch((error: any) => {
                console.log('> 설치 그룹 저장 Error:', data);

                setModal({
                  title: '오류',
                  content: '저장에 실패하였습니다.',
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 저장 버튼(수정)을 클릭함
  const handleSaveUpdateButton_onClick = () => {
    if (groupNameInput.trim() === '') {
      setModal({
        title: '알림',
        content: '설치 그룹명을 입력해 주세요.',
      });

      groupNameInputRef.current?.focus();
      return;
    }

    if (!checkGroupName) {
      setModal({
        title: '알림',
        content: '설치 그룹명의 중복 확인이 필요합니다.',
      });

      return;
    }

    // 설치 그룹에 할당된 팀을 불러옴
    let tmpTeam: any[] = [];
    groupTable.map((item: any) => {
      tmpTeam.push({
        splrId: item.splrId,
        teamId: item.teamId,
      });
    });

    if (tmpTeam.length === 0) {
      setModal({
        title: '알림',
        content: '설치 그룹에 할당할 팀을 추가해 주세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 저장함
            grpApi
              .putGrp({
                grpId: groupId,
                grpName: groupNameInput,
                grpDscr: groupDescriptionInput,
                grpTmRegParamVoList: tmpTeam,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  notifications.show({
                    title: '알림',
                    message: '저장하였습니다.',
                  });

                  // 목록 새로고침을 추가함
                  setAddRefreshList('MN2302220808Page');

                  // 컴포넌트 모달을 닫음
                  setRemoveComponentModal(id);

                  setTimeout(() => {
                    // 조회 컴포넌트 모달을 추가함
                    let tmpId: string = nanoid();

                    // 컴포넌트 모달을 추가함
                    setAddComponentModal({
                      id: tmpId,
                      title: '설치 그룹 상세',
                      content: <MN2302220880 id={tmpId} groupId={groupId} />,
                      size: 1000,
                    });
                  }, 100);
                } else {
                  console.log('> 설치 그룹 저장 Error:', data);

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                }
              })
              .catch((error: any) => {
                console.log('> 설치 그룹 저장 Error:', data);

                setModal({
                  title: '오류',
                  content: '저장에 실패하였습니다.',
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    setModal({
      title: '확인',
      content: '설치 그룹을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 삭제함
            grpApi
              .deleteGrp({
                grpId: groupId,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  notifications.show({
                    title: '알림',
                    message: '삭제하였습니다.',
                  });

                  // 목록 새로고침을 추가함
                  setAddRefreshList('MN2302220808Page');

                  // 컴포넌트 모달을 닫음
                  setRemoveComponentModal(id);
                } else {
                  console.log('> 설치 그룹 삭제 Error:', data);

                  setModal({
                    title: '오류',
                    content: '삭제에 실패하였습니다.',
                  });
                }
              })
              .catch((error: any) => {
                console.log('> 설치 그룹 삭제 Error:', data);

                setModal({
                  title: '오류',
                  content: '삭제에 실패하였습니다.',
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  /**
   * 공통
   */

  // 상세 정보를 정의함
  const [detailData, setDetailData] = useState<any>(null);

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    if (!groupId) {
      // 신규
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '할당된팀',
          targetId: 'step-2',
        },
      ];
    } else if (groupId && !useModifyMode) {
      // 조회
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '할당된팀',
          targetId: 'step-2',
        },
      ];
    } else if (groupId && useModifyMode) {
      // 수정
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '할당된팀',
          targetId: 'step-2',
        },
      ];
    }

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 상세 정보를 불러옴
  const getDetailData = () => {
    grpApi
      .getGrpId({
        grpId: groupId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 상세 정보에 적용함
          setDetailData(data.data);

          console.log('> data.data:', data.data);
        }
      });
  };

  /**
   * 초기화
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 설치 그룹에 할당된 팀 테이블을 초기화함
    initGroupTable();

    // 설치 그룹에 할당할 팀 테이블을 초기화함
    initDrawerTable();

    // 수정일 때, 상세 정보를 불러옴
    if (groupId) {
      // 설치 그룹명 중복확인을 초기화함
      setCheckGroupName(true);

      // 상세 정보를 불러옴
      getDetailData();
    }

    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    return () => {};
  }, []);

  // 상세 정보를 불러왔을 때 실행함
  useEffect(() => {
    if (detailData === null) {
      return;
    }

    // 설치 그룹명 입력에 적용함
    setGroupNameInput(detailData.grpName);

    // 설치 그룹 설명 입력에 적용함
    setGroupDescriptionInput(detailData.grpDscr);

    // 설치 그룹에 할당된 팀 테이블의 데이터를 불러옴
    getGroupTableData();

    return () => {};
  }, [detailData]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  // 드로어가 출력될 때 실행함
  useEffect(() => {
    if (!openedDrawer) {
      return;
    }

    // 검색어를 초기화함
    setDrawerSearchInput('');

    // 설치 그룹에 할당할 팀 테이블의 데이터를 불러옴
    getDrawerTableData();

    return () => {};
  }, [openedDrawer]);

  /**
   * 렌더링
   */

  return (
    <div className="modal-element space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 설치 그룹 상세 폼 그리드 */}
        <PbFormGrid id="step-1" label="기본 정보" cols={1}>
          <PbFormGridCol label="설치 그룹명" withAsterisk={true}>
            {/* 중복 확인 결과 */}
            {checkGroupNameResult ? (
              <TextInput
                value={groupNameInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            ) : (
              <>
                <TextInput
                  ref={groupNameInputRef}
                  placeholder="설치 그룹명을 입력하세요."
                  onChange={handleGroupNameInput_onChange}
                  value={groupNameInput}
                  readOnly={groupId && !useModifyMode ? true : false}
                  variant={groupId && !useModifyMode ? 'unstyled' : 'default'}
                  className="w-full"
                />

                <Button
                  color="indigo"
                  radius="xl"
                  hidden={groupId && !useModifyMode ? true : false}
                  onClick={handleGroupNameDuplicateConfirmButton_onClick}
                >
                  중복 확인
                </Button>
              </>
            )}
          </PbFormGridCol>
          <PbFormGridCol label="설치 그룹 설명">
            <TextInput
              ref={groupDescriptionInputRef}
              placeholder="설치 그룹에 대한 설명을 입력하세요."
              onChange={handleGroupDescriptionInput_onChange}
              value={groupDescriptionInput}
              readOnly={groupId && !useModifyMode ? true : false}
              variant={groupId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 설치 그룹에 소속된 팀 섹션 */}
        <PbSection
          id="step-2"
          label="설치 그룹에 할당된 팀"
          rightForm={
            <>
              {/* 버튼 */}
              {/* 추가, 수정 상태 */}
              {!(groupId && !useModifyMode) && (
                <div className="flex justify-end items-center space-x-2">
                  {/* 전체 삭제 버튼 */}
                  <Button
                    variant="outline"
                    color="pink"
                    radius="xl"
                    onClick={handleGroupTableAllDeleteButton_onClick}
                  >
                    전체 삭제
                  </Button>

                  {/* 선택 삭제 버튼 */}
                  <Button
                    color="pink"
                    radius="xl"
                    onClick={handleGroupTableDeleteButton_onClick}
                  >
                    선택 삭제
                  </Button>

                  {/* 추가 버튼 */}
                  <Button onClick={openDrawer} color="indigo" radius="xl">
                    추가
                  </Button>
                </div>
              )}
            </>
          }
        >
          <div className="space-y-3">
            {/* 테이블 */}
            <div className="w-full h-108">
              <PbAgGridReact
                refs={groupTableRef}
                columnDefs={groupTableColumn}
                rowData={groupTable}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="multiple"
                sizeColumnsToFit={true}
                visiblePaging={true}
                paging={{
                  totalPage: groupTablePaging.totalPage,
                  currentPage: groupTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  groupTablePagingCurrentPageRef.current = event;
                  setGroupTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: groupTablePagingCurrentPageRef.current,
                  }));

                  // 테이블의 데이터를 불러옴
                  getGroupTableData();
                }}
              />
            </div>
          </div>
        </PbSection>
      </div>

      {/* 설치 그룹에 할당된 팀 드로어 */}
      <Drawer
        opened={openedDrawer}
        onClose={closeDrawer}
        position="right"
        size={700}
        title={
          <span className="text-xl font-semibold">설치 그룹에 할당할 팀</span>
        }
        zIndex={1000}
        // className="drawer-element"
      >
        <div className="space-y-3">
          {/* 검색 폼 그리드 */}
          <PbFormGrid cols={1}>
            <PbFormGridCol label="검색">
              {/* 셀렉트 */}
              {/*<Select*/}
              {/*  data={drawerSearchSelectItem}*/}
              {/*  value={drawerSearchSelect}*/}
              {/*/>*/}

              {/* 텍스트 입력 */}
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={drawerSearchInput_onChange}
                onKeyUp={drawerSearchInput_onKeyUp}
                value={drawerSearchInput}
              />

              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={drawerSearchButton_onClick}
                >
                  검색
                </Button>
              </div>
            </PbFormGridCol>
          </PbFormGrid>

          {/* 테이블의 상단 버튼 */}
          <div className="flex justify-end items-center space-x-2">
            {/* 선택한 팀을 설치 그룹에 할당 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              onClick={drawerAddButton_onClick}
            >
              선택한 팀을 설치 그룹에 할당
            </Button>
          </div>

          {/* 테이블 */}
          <div style={{ height: 'calc(100vh - 220px)' }}>
            <PbAgGridReact
              refs={drawerTableRef}
              columnDefs={drawerTableColumn}
              rowData={drawerFilterTable}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="multiple"
              sizeColumnsToFit={true}
              visiblePaging={true}
              paging={{
                totalPage: drawerTablePaging.totalPage,
                currentPage: drawerTablePaging.currentPage,
              }}
              onChangePage={(event: any) => {
                // 테이블의 페이지를 변경함
                drawerTablePagingCurrentPageRef.current = event;
                setDrawerTablePaging((pre: ITablePaging) => ({
                  ...pre,
                  currentPage: drawerTablePagingCurrentPageRef.current,
                }));

                // 테이블의 데이터를 불러옴
                getDrawerTableData();
              }}
            />
          </div>

          {/* 테이블의 하단 버튼 */}
          <div className="flex justify-end items-center space-x-2">
            {/* 설치 그룹에 할당 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              onClick={drawerAddButton_onClick}
            >
              선택한 팀을 설치 그룹에 할당
            </Button>
          </div>
        </div>
      </Drawer>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 신규 상태 */}
        {!groupId && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveButton_onClick}
            >
              저장
            </Button>
          </>
        )}

        {/* 조회 상태 */}
        {groupId && !useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleGoModifyButton_onClick}
            >
              수정 전환
            </Button>
          </>
        )}

        {/* 수정 상태 */}
        {groupId && useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              variant="outline"
              color="pink"
              radius="xl"
              size="md"
              onClick={handleRemoveButton_onClick}
            >
              삭제
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveUpdateButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220880;
