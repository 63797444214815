import { atom } from 'recoil';
import { ILoginUser } from '../interfaces/app.interface';

/**
 * 로그인한 사용자
 */
export const loginStore = atom({
  key: 'loginStore',
  default: {
    id: '',
    name: '',
    accessToken: '',
    refreshToken: '',
  } as ILoginUser,
});
