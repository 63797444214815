import React, { PropsWithChildren, useEffect, useRef } from 'react';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';

interface IComponentProps {
  events?: any[];
  setEvents?: any;
  date?: string;
  data?: any;
  onClick?: () => void;
  dateClick?: (arg: any) => void;
  eventClick?: (arg: any) => void;
  droppable?: boolean;
  editable?: boolean;
}

/**
 * FullCalendar
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PbFullCalendar = ({
  events = [],
  setEvents,
  date,
  data,
  onClick,
  dateClick,
  eventClick,
  droppable = false,
  editable = false,
}: PropsWithChildren<IComponentProps>) => {
  // 달력의 참조를 정의함
  const calendarRef = useRef<any>(null);

  // 이벤트 레이아웃을 처리함
  const renderEventContent = (eventInfo: any) => {
    return { html: eventInfo.event.title };
    // return (
    //   <div className="px-0.5 flex justify-start items-center space-x-2">
    //     {eventInfo.timeText && (
    //       <span className="text-sm1">{eventInfo.timeText}</span>
    //     )}
    //
    //     {eventInfo.event.title && (
    //       <span className="text-sm1 font-bold">{eventInfo.event.title}</span>
    //     )}
    //   </div>
    // );
  };

  // 날짜를 클릭함
  const handleCalendar_onDateClick = (arg: any) => {
    console.log('> date click:', arg);

    console.log('> calendarRef:', calendarRef.current.getApi());

    // calendarRef.current.getApi().next();
  };

  // 일정을 클릭함
  const handleCalendar_onEventClick = (arg: any) => {
    console.log('> event click:', arg);
  };

  // 일정을 수정함
  const handleCalendar_onEventChange = (arg: any) => {
    console.log('> event change:', arg);
    // setEvents();

    // setEvents([
    //   { title: 'Meeting', start: new Date() },
    //   {
    //     title: '테스트123',
    //     start: '2023-05-15',
    //     classNames: 'text-white bg-indigo-500 border-indigo-500',
    //   },
    // ]);
  };

  useEffect(() => {
    return () => {};
  }, []);

  // 지정한 날짜가 변경될 때 실행함
  useEffect(() => {
    if (date === '') {
      calendarRef.current
        .getApi()
        .gotoDate(moment(new Date()).format('YYYYMM'));
    } else {
      calendarRef.current.getApi().gotoDate(moment(date).format('YYYYMM'));
    }

    return () => {};
  }, [date]);

  return (
    <>
      {/*<FullCalendar*/}
      {/*  ref={calendarRef}*/}
      {/*  locale="ko"*/}
      {/*  plugins={[dayGridPlugin, interactionPlugin]}*/}
      {/*  initialView="dayGridMonth"*/}
      {/*  headerToolbar={false}*/}
      {/*  weekends={true}*/}
      {/*  events={events}*/}
      {/*  droppable={droppable}*/}
      {/*  editable={editable}*/}
      {/*  showNonCurrentDates={false}*/}
      {/*  buttonText={{*/}
      {/*    today: '오늘',*/}
      {/*  }}*/}
      {/*  // initialDate={initialDate}*/}
      {/*  eventContent={renderEventContent}*/}
      {/*  dateClick={dateClick}*/}
      {/*  eventClick={eventClick}*/}
      {/*  eventChange={handleCalendar_onEventChange}*/}
      {/*/>*/}
    </>
  );
};

export default PbFullCalendar;
