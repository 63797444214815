import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import { useRecoilState } from 'recoil';
import { removeComponentModalStore } from '../../stores/componentModal.store';
import * as appUtil from '../../utils/app.util';
import _ from 'lodash';
import { number } from 'prop-types';
import { IOptionItem } from '../../interfaces/app.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IComponentProps {
  id: string;
  selectedData: any;
  data?: any;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 파트너사 관리 > 비가동 관리 > 배정구분 선택
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220603_SelectWorkType = ({
  id,
  selectedData,
  data,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IComponentProps>) => {
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 배정구분을 정의함
  const [workType, setWorkType] = useState<IOptionItem[]>([]);

  // 버튼을 클릭함
  const handleSelectButton_onClick = (param: any) => {
    // 부모창으로 결과를 넘김
    callback({
      label: param.label,
      value: param.value,
    });

    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);
  };

  useEffect(() => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '미지정',
      value: '',
    });

    // 공통코드를 불러옴
    appUtil.getCommonCode('PRTN_CLSF', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        // 작업일반은 제외함
        if (item.dtlCodeId !== 'WORK_GNRL') {
          tmpOptionItem.push({
            label: item.dtlCodeDscr,
            value: item.dtlCodeId,
          });
        }
      });

      // 배정구분에 적용함
      setWorkType(tmpOptionItem);
    });

    return () => {};
  }, []);

  return (
    <div className="pb-2 space-y-2">
      {/*<div className="flex justify-between items-center">*/}
      {/*  <span className="">*/}
      {/*    {selectedData.no} / {selectedData.col1} / {selectedData.col2}*/}
      {/*  </span>*/}
      {/*</div>*/}
      <div className="h-0.5" />

      <div className="w-full space-y-1">
        {workType.map((item: IOptionItem, index: number) => (
          <Button
            key={index}
            color="indigo"
            size="md"
            onClick={() => handleSelectButton_onClick(item)}
            className="!w-full"
          >
            <div className="flex justify-center items-center space-x-2">
              {item.value === selectedData.prtnCode && (
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'check']}
                    className="w-4 h-4 text-amber-200"
                  />
                </div>
              )}
              <span>{item.label}</span>
              {item.value === selectedData.prtnCode && (
                <div className="w-4 h-4"></div>
              )}
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default MN2302220603_SelectWorkType;
