import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import { AgGridReact } from 'ag-grid-react';
import { ICellRendererParams } from 'ag-grid-community';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  Textarea,
  FileInput,
  FileButton,
  Checkbox,
  Group,
  Radio,
  Anchor,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PageLayout } from '../../components/PageLayout';
import { PbFormGrid } from '../../components/PbFormGrid';
import { PbFormGridCol } from '../../components/PbFormGridCol';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../../stores/refreshList.store';
import * as cmnApi from '../../apis/cmn.api';
import { deleteMenu } from '../../apis/cmn.api';
import PbSelect from '../PbSelect/PbSelect.component';

interface MN2302220811Props {
  id?: string;
  menuId?: string;
  useAddMode?: boolean;
  useModifyMode: boolean;
  data?: any;
  onClick?: () => void;
}

/**
 * 시스템관리 > 메뉴 관리 > 등록,상세,수정 모달
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220811 = ({
  id = '',
  menuId = '',
  useAddMode = false,
  useModifyMode = false,
  data,
  onClick,
}: PropsWithChildren<MN2302220811Props>) => {
  // 언어를 정의함
  const { t } = useTranslation();
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);
  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**************************** API ****************************/
  //메뉴타임 공통코드 조회
  const getMenuTypeCode = () => {
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'MENU_TYPE',
      })
      .then((data: IApiResult) => {
        console.log(data.data.list);

        if (data.data.list !== undefined) {
          let tmpOptionItem: IOptionItem[] = [];
          // tmpOptionItem.push({
          //   label: '전체',
          //   value: '',
          // });
          for (var code of data.data.list) {
            tmpOptionItem.push({
              label: code.dtlCodeDscr,
              value: code.dtlCodeId,
            });
          }
          // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
          setMenuTypeCodeSelect({
            value: 'P',
            item: tmpOptionItem,
          });
        }
      });
  };
  //메뉴 상세 조회
  const getMenuDetail = (menuId: string) => {
    cmnApi
      .getMenu({
        menuId: menuId,
      })
      .then((data: IApiResult) => {
        if (data.data != null) {
          setUperMenuNameInput(data.data.krnMenu);
          setUperMenuDvsnCodeInput(data.data.menuDvsnCode);

          if (!useAddMode) {
            setMenuNameInput(data.data.krnMenu);
            setMenuUrlInput(data.data.url);
            setMenuDescInput(data.data.krnDscr);
            setMenuUseYnInput(data.data.useYn === 'Y' ? '사용' : '미사용');
            setMenuUseYnSelect((pre: ISelect) => ({
              ...pre,
              value: data.data.useYn,
            }));
            setMenuTypeCodeInput(
              data.data.menuTypeCode === 'P'
                ? '페이지'
                : data.data.menuTypeCode === 'M'
                ? '모달'
                : '링크',
            );
            setMenuTypeCodeSelect((pre: ISelect) => ({
              ...pre,
              value: data.data.menuTypeCode,
            }));
          }
        } else {
          if (menuId === 'ROOT_WEB') {
            setUperMenuNameInput('/');
            setUperMenuDvsnCodeInput('WEB');
          } else if (menuId === 'ROOT_MBL') {
            setUperMenuNameInput('/');
            setUperMenuDvsnCodeInput('MBL');
          }
        }
      });
  };

  //메뉴 등록 저장
  const saveMenu = () => {
    cmnApi
      .postMenu({
        prntMenuId: menuId.startsWith('ROOT') ? '' : menuId,
        krnMenu: menuNameInput,
        krnDscr: menuDescInput,
        url: menuUrlInput,
        menuDvsnCode: uperMenuDvsnCodeInput,
        menuTypeCode: menuTypeCodeSelect.value,
        useYn: menuUseYnSelect.value,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 목록 새로고침
          setAddRefreshList('MN2302220801Tree');
          // 컴포넌트 모달을 닫음
          setRemoveComponentModal(id);
        } else {
          console.log('> 메뉴 등록 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>저장에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  //메뉴 수정 저장
  const modifyMenu = () => {
    cmnApi
      .putMenu({
        menuId: menuId,
        url: menuUrlInput,
        krnMenu: menuNameInput,
        krnDscr: menuDescInput,
        menuTypeCode: menuTypeCodeSelect.value,
        useYn: menuUseYnSelect.value,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          setModal({
            title: '알림',
            content: '저장하였습니다.',
            callback: () => {
              // 목록 새로고침
              setAddRefreshList('MN2302220801Tree');
              // 컴포넌트 모달을 닫음
              setRemoveComponentModal(id);
              setTimeout(() => {
                // 조회 컴포넌트 모달을 추가함
                let tmpId: string = nanoid();
                // 컴포넌트 모달을 추가함
                setAddComponentModal({
                  id: tmpId,
                  title: '메뉴 정보',
                  content: (
                    <MN2302220811
                      id={tmpId}
                      menuId={menuId}
                      useAddMode={false}
                      useModifyMode={false}
                    />
                  ),
                  size: 1500,
                });
              }, 100);
            },
          });
        } else {
          console.log('> 메뉴 수정 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>저장에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  //메뉴 삭제
  const deleteMenu = () => {
    cmnApi
      .deleteMenu({
        menuId: menuId,
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          case '200':
            // 목록 새로고침을 추가함
            setAddRefreshList('MN2302220801Tree');
            // 모달을 출력함
            setModal({
              title: '알림',
              content: '삭제하였습니다.',
              callback: () => setRemoveComponentModal(id),
            });
            break;

          case '':
            console.log('메뉴 삭제 실패 : ' + data);
            // 모달을 출력함
            setModal({
              title: t('common.error'),
              content: '삭제에 실패하였습니다.',
            });
            break;
          default:
            break;
        }
      });
  };

  /**************************** STATE ****************************/
  const [uperMenuNameInput, setUperMenuNameInput] = useState<string>(''); //상위 메뉴 이름
  const [uperMenuDvsnCodeInput, setUperMenuDvsnCodeInput] =
    useState<string>(''); //상위 메뉴 구분 코드 (WEB, MBL)

  const [menuNameInput, setMenuNameInput] = useState<string>(''); //메뉴 이름
  const [menuUrlInput, setMenuUrlInput] = useState<string>(''); //메뉴 URL
  const [menuDescInput, setMenuDescInput] = useState<string>(''); //메뉴 설명
  const [menuUseYnInput, setMenuUseYnInput] = useState<string>(''); //메뉴 사용여부
  const [menuUseYnSelect, setMenuUseYnSelect] = useState<ISelect>({
    //메뉴 사용여부 셀렉트
    value: '',
    item: [],
  });
  const [menuTypeCodeInput, setMenuTypeCodeInput] = useState<string>(''); //메뉴 타입
  const [menuTypeCodeSelect, setMenuTypeCodeSelect] = useState<ISelect>({
    //메뉴 타입 셀렉트
    value: '',
    item: [],
  });

  /**************************** ON EVENT LISTENER ****************************/
  //메뉴 이름 입력 이벤트
  const handleMenuNameInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 30) {
      setMenuNameInput((pre) => pre);
    } else {
      setMenuNameInput(sVal);
    }
  };
  //화면 접속 URL 입력 이벤트
  const handleMenuUrlInput_onChange = (event: any) => {
    var sVal = event.target.value;
    setMenuUrlInput(sVal);
  };
  //메뉴 설명 입력 이벤트
  const handleMenuDescInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 50) {
      setMenuDescInput((pre) => pre);
    } else {
      setMenuDescInput(sVal);
    }
  };
  //메뉴 타입 셀렉트 변경 이벤트
  const handleMenuTypeCodeYnSelect_onChange = (event: any) => {
    setMenuTypeCodeSelect({
      ...menuTypeCodeSelect,
      value: event,
    });
  };

  //메뉴 사용여부 셀렉트 변경 이벤트
  const handleMenuUseYnSelect_onChange = (event: any) => {
    setMenuUseYnSelect({
      ...menuUseYnSelect,
      value: event,
    });
  };

  //추가 저장 버튼 on click
  const handleAddSaveBtn_onClick = () => {
    //필수값 여부 확인
    if (!menuNameInput.trim()) {
      setModal({
        title: '알림',
        content: '메뉴 이름을 입력해 주십시오.',
      });
      return;
    }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              saveMenu();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  //수정 전환 버튼 이벤트
  const handleModifyBtn_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);
    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();
      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '메뉴 수정',
        content: (
          <MN2302220811
            id={tmpId}
            menuId={menuId}
            useAddMode={false}
            useModifyMode={true}
          />
        ),
        size: 1500,
      });
    }, 100);
  };

  // 수정의 저장 버튼 on click
  const handleModifySaveBtn_onClick = () => {
    //필수값 여부 확인
    if (!menuNameInput.trim()) {
      setModal({
        title: '알림',
        content: '메뉴 이름을 입력해 주십시오.',
      });
      return;
    }
    if (!menuUrlInput.trim()) {
      setModal({
        title: '알림',
        content: 'URL 을 입력해 주십시오.',
      });
      return;
    }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              modifyMenu();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  // 삭제 버튼 on click
  const handleDeleteBtn_onClick = () => {
    setModal({
      title: '확인',
      content: `메뉴 [${menuNameInput}]을 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              deleteMenu();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  /**************************** INIT ****************************/
  //메뉴 사용여부 셀렉트 초기화
  const initMenuUseYnSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];
    tmpOptionItem.push(
      {
        label: '사용',
        value: 'Y',
      },
      {
        label: '미사용',
        value: 'N',
      },
    );
    setMenuUseYnSelect({
      value: 'Y',
      item: tmpOptionItem,
    });
  };

  //메뉴 타입 셀렉트 초기화
  const initMenuTypeCodeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];
    tmpOptionItem.push(
      {
        label: '페이지',
        value: 'P',
      },
      {
        label: '모달',
        value: 'M',
      },
      {
        label: '링크',
        value: 'L',
      },
    );
    setMenuTypeCodeSelect({
      value: 'P',
      item: tmpOptionItem,
    });
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    initMenuUseYnSelect(); //메뉴사용여부 셀렉트
    getMenuTypeCode(); //메뉴 유형 코드 셀렉트 초기화
    if (menuId) {
      getMenuDetail(menuId);
    }
    return () => {};
  }, []);

  /**************************** 기타 Function ****************************/
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 공지 정보 폼 그리드 */}
        <PbFormGrid label="메뉴 정보" cols={2}>
          {useAddMode && (
            <PbFormGridCol label="상위 메뉴 이름" colSpan={2}>
              <TextInput
                placeholder=""
                readOnly={true}
                variant={!useAddMode && !useModifyMode ? 'unstyled' : 'default'}
                value={uperMenuNameInput}
                className="w-full"
              />
            </PbFormGridCol>
          )}
          <PbFormGridCol label="메뉴 이름" colSpan={2}>
            <TextInput
              placeholder=""
              onChange={handleMenuNameInput_onChange}
              readOnly={!useAddMode && !useModifyMode ? true : false}
              variant={!useAddMode && !useModifyMode ? 'unstyled' : 'default'}
              value={menuNameInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="화면 접속 URL" colSpan={2}>
            <TextInput
              placeholder=""
              onChange={handleMenuUrlInput_onChange}
              readOnly={!useAddMode && !useModifyMode ? true : false}
              variant={!useAddMode && !useModifyMode ? 'unstyled' : 'default'}
              value={menuUrlInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="메뉴 설명" colSpan={2}>
            <TextInput
              placeholder=""
              onChange={handleMenuDescInput_onChange}
              readOnly={!useAddMode && !useModifyMode ? true : false}
              variant={!useAddMode && !useModifyMode ? 'unstyled' : 'default'}
              value={menuDescInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="메뉴 타입" colSpan={2}>
            {!useAddMode && !useModifyMode && (
              <TextInput
                placeholder=""
                value={menuTypeCodeInput}
                readOnly={true}
                variant={!useAddMode && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            )}
            {(useAddMode || useModifyMode) && (
              <PbSelect
                onChange={handleMenuTypeCodeYnSelect_onChange}
                data={menuTypeCodeSelect.item}
                value={menuTypeCodeSelect.value}
                setSelect={setMenuTypeCodeSelect}
                className="w-full"
              />
            )}
          </PbFormGridCol>
          <PbFormGridCol label="사용여부" colSpan={2}>
            {!useAddMode && !useModifyMode && (
              <TextInput
                placeholder=""
                value={menuUseYnInput}
                readOnly={true}
                variant={!useAddMode && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            )}
            {(useAddMode || useModifyMode) && (
              <PbSelect
                onChange={handleMenuUseYnSelect_onChange}
                data={menuUseYnSelect.item}
                value={menuUseYnSelect.value}
                setSelect={setMenuUseYnSelect}
                className="w-full"
              />
            )}
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        {useAddMode && !useModifyMode && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleAddSaveBtn_onClick}
              >
                저장
              </Button>
            </div>
          </>
        )}
        {!useAddMode && !useModifyMode && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleModifyBtn_onClick}
              >
                수정 전환
              </Button>
            </div>
          </>
        )}
        {!useAddMode && useModifyMode && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                variant="outline"
                color="pink"
                radius="xl"
                size="md"
                // onClick={handleDeleteBtn_onClick}
              >
                삭제
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleModifySaveBtn_onClick}
              >
                저장
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220811;
