import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Input,
  Modal,
  PasswordInput,
  TextInput,
} from '@mantine/core';
import * as appUtil from '../../utils/app.util';
import { useLocation } from 'react-router-dom';
import {
  IApiResult,
  IComponentModal,
  ILoginUser,
  IMessageModal,
  IModal,
  IToken,
} from '../../interfaces/app.interface';
import * as cmnApi from '../../apis/cmn.api';
import { useRecoilState } from 'recoil';
import { loginStore } from '../../stores/login.store';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { FindId } from '../FindId';
import { FindPassword } from '../FindPassword';
import { ChangeTmpPassword } from '../ChangeTmpPassword';
import { PbButton } from '../PbButton';
import { PbInputText } from '../PbInputText';
import { PbCheckbox } from '../PbCheckbox';
import { modals } from '@mantine/modals';
import ModalManager from '../ModalManager/ModalManager.component';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { nanoid } from 'nanoid';
import MN2302220202 from '../MN2302220202/MN2302220202.component';
import { addComponentModalStore } from '../../stores/componentModal.store';
import { ComponentModalManager } from '../ComponentModalManager';
import useCallback from 'react';

interface ILoginProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 로그인
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const Login = ({ data, onClick }: PropsWithChildren<ILoginProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 메시지 모달을 정의함
  const [
    openedMessageModal,
    { open: openMessageModal, close: closeMessageModal },
  ] = useDisclosure(false);
  const [messageModal, setMessageModal] = useState<IMessageModal>({
    title: '',
    content: '',
  });

  // 컴포넌트 모달을 정의함
  const [
    openedComponentModal,
    { open: openComponentModal, close: closeComponentModal },
  ] = useDisclosure(false);
  const [componentModal, setComponentModal] = useState<IComponentModal>({
    title: '',
    content: '',
  });

  // 사용자 로그인 정보를 정의함
  const [userId, setUserId] = useState<string>('');
  const [userPassword, setUserPassword] = useState<string>('');

  // 아이디 저장 체크박스를 정의함
  const [saveIdCheckBox, setSaveIdCheckBox] = useState<boolean>(false);

  const [reSettingPswr, setReSettingPswr] = useState<string>('');

  // 사용자 로그인 정보 참조를 정의함
  const userIdRef = useRef<HTMLInputElement>(null);
  const userPasswordRef = useRef<HTMLInputElement>(null);

  // 비밀번호 입력의 값을 변경함
  const handleUserPasswordInput_onChange = (event: any) => {
    setUserPassword(event.target.value);
  };

  // 비밀번호 입력에서 키를 입력함
  const handleUserPasswordInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      handleLoginButton_onClick();
    }
  };

  // 아이디 저장 체크박스를 변경함
  const handleSaveUserIdCheckbox_onChange = (event: any) => {
    // 체크박스에 적용함
    setSaveIdCheckBox(event.target.checked);
  };

  // 아이디 입력의 값을 변경함
  const handleUserIdInput_onChange = (event: any) => {
    setUserId(event.target.value);
  };

  // 로그인 버튼을 클릭함
  const handleLoginButton_onClick = () => {
    // 아이디 > 입력을 확인함
    if (!userId.trim()) {
      setModal({
        title: '알림',
        content: '아이디를 입력해 주세요.',
      });

      userIdRef.current?.focus();
      return;
    }

    // 비밀번호 > 입력을 확인함
    if (!userPassword.trim()) {
      setModal({
        title: '알림',
        content: '비밀번호를 입력해 주세요.',
      });

      userPasswordRef.current?.focus();
      return;
    }

    // 로그인을 처리함
    cmnApi
      .postLogin({
        lgnId: userId,
        userPw: userPassword,
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          // 로그인 성공
          case '200':
            // 로그인한 사용자의 토큰을 정의함
            let tmpToken: IToken = {
              email: userId,
              userId: data.data.userId,
              tokenType: data.data.tokenType,
              accessToken: data.data.accessToken,
              accessTokenExpireSecond: data.data.accessTokenExpireSecond,
              refreshToken: data.data.refreshToken,
              refreshTokenExpireSecond: data.data.refreshTokenExpireSecond,
            };

            (async () => {
              // 로그인 아이디를 Local Storage에 저장함
              if (saveIdCheckBox) {
                await appUtil.setSaveId(userId);
              } else {
                await appUtil.setSaveId('');
              }

              // 로그인한 사용자 토큰을 Local Storage에 저장함
              await appUtil.setToken(tmpToken);

              if (data.data.tmprPswrUseYn === 'Y') {
                checkTmpLoginInfo();
              } else {
                // 메인 페이지로 이동함
                // navigate('/dashboard');
                window.location.href = '/dashboard';
              }
            })();
            break;

          // 사용중지(use_yn=N) 아이디
          case '202':
            setModal({
              title: '알림',
              content: data.message,
            });
            userIdRef.current?.focus();
            break;

          // 아이디 또는 비밀번호 오류
          case '500':
            // 모달을 출력함
            /* 언어: 오류 */
            /* 언어: 아이디 또는 비밀번호가 올바르지 않습니다. */
            setModal({
              title: t('common.error'),
              content: t('auth.errorIdPassword'),
            });
            userPasswordRef.current?.focus();
            break;

          default:
            break;
        }
      });
  };

  // Azure AD 로그인 버튼을 클릭함
  const handleAzureAdLoginButton_onClick = () => {
    window.location.href = [appUtil.getApiUrl(), '/azure/ad-login'].join('');
  };

  // 아이디 찾기 버튼을 클릭함
  const handleFindIdButton_onClick = () => {
    // 메시지 모달을 출력함
    /* 언어: 아이디 찾기 */
    /* 언어: 아이디 또는 비밀번호가 올바르지 않습니다. */
    setComponentModal({
      title: t('auth.findId') || '',
      content: <FindId />,
      size: 'sm',
      button: (
        <>
          {/* 언어: 취소 */}
          <Button onClick={closeComponentModal} color="gray" size="sm">
            {t('common.cancel')}
          </Button>
        </>
      ),
    });
    openComponentModal();
  };

  // 비밀번호 찾기 버튼을 클릭함
  const handleFindPasswordButton_onClick = () => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '비밀번호 초기화',
      content: <FindPassword id={tmpId} />,
      size: 500,
    });

    // 메시지 모달을 출력함
    {
      /* 언어: 비밀번호 찾기 */
      /* 언어: 아이디 또는 비밀번호가 올바르지 않습니다. */
    }
    // setComponentModal({
    //   title: t('auth.findPassword') || '',
    //   content: <FindPassword />,
    //   size: 'sm',
    //   button: (
    //     <>
    //       {/* 언어: 취소 */}
    //       <Button onClick={closeComponentModal} color="gray" size="sm">
    //         {t('common.cancel')}
    //       </Button>
    //     </>
    //   ),
    // });
    // openComponentModal();
  };

  // 비밀번호 재설정
  const checkTmpLoginInfo = () => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '비밀번호 재설정',
      content: (
        <ChangeTmpPassword
          id={tmpId}
          tmpPassword={userPassword}
          callback={(data: any) => {
            // 메인 페이지로 이동함
            // navigate('/');
            window.location.href = '/';
          }}
        />
      ),
      size: 500,
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 로그인한 사용자 토큰을 Local Storage에서 불러옴
    let token: IToken = appUtil.getToken();

    if (token.accessToken) {
      // 대시보드 페이지로 이동함
      // window.location.href = '/dashboard';
    }

    // 로그인 아이디를 Local Storage에서 불러옴
    let saveId: string = appUtil.getSaveId() || '';
    setSaveIdCheckBox(saveId ? true : false);
    setUserId(saveId);

    userIdRef.current?.focus();

    return () => {};
  }, []);

  return (
    <>
      {/* 모달 매니저 */}
      <ModalManager />

      {/* 컴포넌트 모달 매니저 */}
      <ComponentModalManager />

      <div className="px-10 py-7 bg-white border border-gray-200 rounded-b space-y-5">
        {/* 입력폼 */}
        <div className="w-full space-y-2">
          {/* 아이디 */}
          <div className="flex justify-center items-center space-x-2">
            <div className="flex-none w-20 flex justify-start items-center">
              {/* 언어: 아이디 */}
              <span className="text-sm text-gray-700 font-semibold">
                {t('auth.id')}
              </span>
            </div>

            <div className="grow">
              {/* 텍스트 입력 */}
              {/* 언어: 아이디를 입력하세요. */}
              <TextInput
                ref={userIdRef}
                placeholder={t('auth.enterId').toString()}
                onChange={handleUserIdInput_onChange}
                value={userId}
                className="w-full"
              />
            </div>
          </div>

          {/* 비밀번호 */}
          <div className="flex justify-center items-center space-x-2">
            <div className="flex-none w-20 flex justify-start items-center">
              {/* 언어: 비밀번호 */}
              <span className="text-sm text-gray-700 font-semibold">
                {t('auth.password')}
              </span>
            </div>

            <div className="grow">
              {/* 텍스트 입력 */}
              {/* 언어: 비밀번호를 입력하세요. */}
              <PasswordInput
                ref={userPasswordRef}
                placeholder={t('auth.enterPassword').toString()}
                onChange={handleUserPasswordInput_onChange}
                onKeyUp={handleUserPasswordInput_onKeyUp}
                value={userPassword}
                className="w-full"
              />
            </div>
          </div>
        </div>

        {/* 버튼 */}
        {/*<div className="w-full flex justify-center items-center">*/}
        <div className="w-full space-y-2">
          {/* 버튼 */}
          {/* 언어: 로그인 */}
          <Button
            color="indigo"
            // radius="xl"
            size="md"
            onClick={handleLoginButton_onClick}
            className="!w-full"
          >
            {t('auth.login').toString()}
          </Button>

          {/* 버튼 */}
          {/* 개발자용 컴포넌트 샘플 페이지 */}
          {/*{(process.env.REACT_APP_MODE === 'localhost' ||*/}
          {/*  process.env.REACT_APP_MODE !== 'vietnam') && (*/}
          <Button
            // radius="xl"
            size="md"
            onClick={handleAzureAdLoginButton_onClick}
            className="!w-full"
          >
            Azure AD 로그인
          </Button>
          {/*)}*/}
        </div>

        {/* 기타 링크 */}
        <div className="flex justify-between items-center">
          {/* 언어: 아이디 저장 */}
          {/*<PbCheckbox*/}
          {/*  onChange={handleSaveUserIdCheckbox_onChange}*/}
          {/*  label={t('auth.saveId').toString()}*/}
          {/*  checked={saveIdCheckBox}*/}
          {/*/>*/}

          {/* 체크박스 */}
          {/* 언어: 아이디 저장 */}
          <Checkbox
            onChange={handleSaveUserIdCheckbox_onChange}
            label={t('auth.saveId').toString()}
            checked={saveIdCheckBox}
          />

          {/* 언어: 아이디 찾기 */}
          {/*<Button onClick={handleFindIdButton_onClick} variant="subtle" compact>*/}
          {/*  {t('auth.findId')}*/}
          {/*</Button>*/}

          {/* 언어: 비밀번호 찾기 */}
          <Button
            onClick={handleFindPasswordButton_onClick}
            variant="subtle"
            compact={true}
          >
            {t('auth.findPassword')}
          </Button>
        </div>
      </div>

      {/* 메시지 모달 */}
      <Modal
        opened={openedMessageModal}
        onClose={closeMessageModal}
        withCloseButton={false}
        title={
          <span className="text-sm font-bold text-gray-800">
            {messageModal.title}
          </span>
        }
        centered={true}
        zIndex={30}
      >
        <div className="space-y-2">
          {messageModal.content && (
            <div>
              <span className="text-sm font-medium text-gray-600">
                {messageModal.content}
              </span>
            </div>
          )}

          {/* 버튼 */}
          <div className="flex justify-end items-center">
            {/* 언어: 확인 */}
            <Button onClick={closeMessageModal} size="sm">
              {t('common.ok')}
            </Button>
          </div>
        </div>
      </Modal>

      {/* 컴포넌트 모달 */}
      <Modal
        opened={openedComponentModal}
        onClose={closeComponentModal}
        withCloseButton={false}
        title={
          <span className="text-sm font-bold text-gray-800">
            {componentModal.title}
          </span>
        }
        size={componentModal.size || 'md'}
        padding={componentModal.padding || 'md'}
        centered={true}
        zIndex={30}
      >
        <div className="space-y-2">
          {componentModal.content && (
            <div>
              <span className="text-sm font-medium text-gray-600">
                {componentModal.content}
              </span>
            </div>
          )}

          {/* 버튼 */}
          <div className="flex justify-end items-center space-x-2">
            {!componentModal.button && (
              <>
                {/* 언어: 확인 */}
                <Button onClick={closeComponentModal} size="sm">
                  {t('common.ok')}
                </Button>
              </>
            )}
            {componentModal.button && <>{componentModal.button}</>}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Login;
