import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ILoginUser } from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { Login } from '../../components/Login';
import { LanguageSelect } from '../../components/LanguageSelect';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import loadingAnimation from '../../components/LoadingTableDataIcon/loading2.json';
import { overlayStore } from '../../stores/overlay.store';

/**
 * 사용자 > 로그인
 * @constructor
 */
const LoginPage = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 화면 위 검은 화면 저장소를 정의함
  const [visibleOverlay, setVisibleOverlay] =
    useRecoilState<boolean>(overlayStore);

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      {/* 화면 위 검은 화면 */}
      {visibleOverlay && (
        <div className="object-opacity-appear absolute left-0 top-0 w-screen h-screen z-1000">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center bg-white/0 z-20">
            <div className="w-96 h-96 flex justify-center items-center">
              <Lottie animationData={loadingAnimation} loop={true} />
            </div>
          </div>

          <div className="w-screen h-screen bg-black/60" />
        </div>
      )}

      <div className="relative w-full h-screen">
        {/* 상단바 */}
        {(process.env.REACT_APP_MODE === 'localhost' ||
          process.env.REACT_APP_MODE === 'dev') && (
          <div className="px-5 py-2 flex justify-end items-end border-b border-gray-100">
            {/* 언어 선택폼 */}
            <LanguageSelect />
          </div>
        )}

        {/* 로그인 영역 */}
        <div className="absolute pb-10 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
          {/* 로고 */}
          <div className="main-menu rounded-t">
            <div className="navbar-header">
              <div className="navbar-brand">
                <h1 className="header-logo"></h1>
              </div>
            </div>
          </div>

          <Login />

          {/* 저작권 */}
          <div className="mt-1 flex justify-center items-center">
            <span className="text-sm text-gray-500 font-semibold">
              Copyright ⓒ 2023 Dtonic all rights reserved.
            </span>
          </div>

          {/* 임시! */}
          {(process.env.REACT_APP_MODE === 'localhost' ||
            process.env.REACT_APP_MODE === 'dev') && (
            <div className="mt-2">
              <div className="flex justify-center items-center">
                <span className="text-xs text-gray-500 font-semibold">
                  id: tempemstest@gmail.com
                </span>
              </div>
              <div className="flex justify-center items-center">
                <span className="text-xs text-gray-500 font-semibold">
                  pw: 1234
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginPage;
