import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Progress,
  RingProgress,
  Select,
  TextInput,
  Tabs,
  Group,
  Popover,
} from '@mantine/core';
import { useDownloadExcel } from 'react-export-table-to-excel';
import {
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IApiResult,
  IOptionItem,
  IPageContent,
  IModal,
  IComponentModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { pageContentStore } from '../stores/page.store';
import { PageLayout } from '../components/PageLayout';
import { PbButton } from '../components/PbButton';
import { PbTable } from '../components/PbTable';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { PbInputText } from '../components/PbInputText';
import { PbDatePicker } from '../components/PbDatePicker';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import * as sttnApi from '../apis/sttn.api';
import { MonthPickerInput, DatePickerInput } from '@mantine/dates';
import { AgGridReact } from 'ag-grid-react';
import { availableActions } from 'hygen';
import sleep from 'sleep-promise';
import { nanoid } from 'nanoid';
import { MN2302220452 } from '../components/MN2302220452';
import { addComponentModalStore } from '../stores/componentModal.store';
import { ValueFormatterParams } from 'ag-grid-community';
import PbSection from '../components/PbSection/PbSection.component';
import { PbAgGridReact } from '../components/PbAgGridReact';
import { PbHelpPopover } from '../components/PbHelpPopover';

/**
 * 현황 관리 > ESL 설치작업 진척 현황
 * @constructor
 */
const MN2302220402Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**
   * ESL 진척현황
   */

  // ESL 진척현황 데이저 저장소
  const [eslStatus, setEslStatus] = useState<{
    billPcntg: string; // 진척도 billing
    blngCmplCnt: string; // 설치완료 - Billing 완료
    blngNcmpCnt: string; // 설치완료 - Billing 미완료
    cmplPcntg: string; // 진척도 실적
    planCmplCnt: string; // 설치 미완료 - 계획 수립
    planNcmpCnt: string; // 설치 미완료 - 계획 미수립
    totalCnt: string; // 전체
    totalPrjcEnd: string; // 전체 프로젝트 일정 - 종료일
    totalPrjcStrt: string; // 전체 프로젝트 일정 - 시작일
  }>();

  // 진척현황 테이블을 정의함
  const [eslStatusTable, setEslStatusTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 진척현황 테이블 페이징을 정의함
  const [eslStatusTablePaging, setEslStatusTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 5,
      currentPage: 1,
    });
  const eslStatusTablePagingCurrentPageRef = useRef<number>(1);

  // 진척현황 테이블의 로딩 여부를 정의함
  const [eslStatusTableLoading, setEslStatusTableLoading] =
    useState<boolean>(false);

  // 검색 결과 테이블을 초기화함
  const initEslStatusTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      // {
      //   headerName: '번호',
      //   field: 'no',
      //   width: 70,
      //   suppressSizeToFit: true,
      // },
      {
        headerName: '프로젝트명',
        field: 'prjcName',
        width: 250,
        suppressSizeToFit: true,
      },
      {
        headerName: '계약명',
        field: 'cntrName',
        width: 250,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => {
          return (
            <>
              [{params.data.cntrNmbr}] {params.data.cntrName}
            </>
          );
        },
      },
      {
        headerName: '고객사명',
        field: 'bpName',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '계획 Shop',
        field: 'totalCnt',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: '건수',
        children: [
          {
            headerName: '전체',
            field: 'totalCnt',
            width: 120,
            suppressSizeToFit: true,
          },
          {
            headerName: '계획 미수립',
            field: 'planNcmpCnt',
            width: 120,
            suppressSizeToFit: true,
          },
          {
            headerName: '계획 수립',
            field: 'planCmplCnt',
            width: 120,
            suppressSizeToFit: true,
          },
          {
            headerName: 'Billing 미완료',
            field: 'blngNcmpCnt',
            width: 120,
            suppressSizeToFit: true,
          },
          {
            headerName: 'Billing 완료',
            field: 'blngCmplCnt',
            width: 120,
            suppressSizeToFit: true,
          },
        ],
      },
      {
        headerName: '진척도',
        field: 'totalCnt', //'totalCnt,blngCmplCnt,blngNcmpCnt,billPcntg',
        width: 260,
        cellRenderer: (params: any) => {
          return (
            //eslStatusTable.data.lenght > 0 && (
            <div className="w-full row-span-2 col-span-3 space-y-3">
              <div className="space-y-3">
                {/* 실적 */}
                <div className=" grid grid-cols-4 gap-1 items-center space-x-3">
                  <div className="relative">
                    <span className="text-md">실적</span>
                  </div>
                  <div className="relative col-span-2 grow">
                    {' '}
                    {/* 회색 : 계획 Shop수, 파랑 : 설치완료 + 빌링 미완료 */}
                    <Progress
                      size="xl"
                      radius="xs"
                      color="indigo"
                      value={_.toNumber(
                        _.toNumber(params.data.totalCnt) !== 0 &&
                          _.isNumber(_.toNumber(params.data.totalCnt))
                          ? _.multiply(
                              _.divide(
                                _.add(
                                  _.isEmpty(params.data.blngCmplCnt)
                                    ? 0
                                    : _.toNumber(params.data.blngCmplCnt),
                                  _.isEmpty(params.data.blngNcmpCnt)
                                    ? 0
                                    : _.toNumber(params.data.blngNcmpCnt),
                                ),
                                _.toNumber(params.data.totalCnt),
                              ),
                              100,
                            ).toFixed(1)
                          : 0,
                      )}
                    />
                  </div>
                  <div className="relative">
                    {_.toNumber(params.data.totalCnt) !== 0 &&
                    _.isNumber(_.toNumber(params.data.totalCnt))
                      ? _.multiply(
                          _.divide(
                            _.add(
                              _.isEmpty(params.data.blngCmplCnt)
                                ? 0
                                : _.toNumber(params.data.blngCmplCnt),
                              _.isEmpty(params.data.blngNcmpCnt)
                                ? 0
                                : _.toNumber(params.data.blngNcmpCnt),
                            ),
                            _.toNumber(params.data.totalCnt),
                          ),
                          100,
                        ).toFixed(1)
                      : 0}
                    %
                  </div>
                </div>
              </div>
              {/* Billing */}
              <div className="space-y-3">
                <div className=" grid grid-cols-4 gap-1 items-center space-x-3">
                  <div className="relative w-80">
                    <span className="text-md">Billing</span>
                  </div>
                  <div className="relative col-span-2 grow w-100">
                    {' '}
                    {/* 회색 : 설치완료 + 빌링 미완료, 파랑 : 빌링완료(설치완료) */}
                    <Progress
                      size="xl"
                      radius="xs"
                      color="indigo"
                      value={_.toNumber(
                        _.add(
                          _.toNumber(params.data.blngCmplCnt),
                          _.toNumber(params.data.blngNcmpCnt),
                        ) !== 0
                          ? _.multiply(
                              _.divide(
                                _.toNumber(params.data.blngCmplCnt),
                                _.add(
                                  _.toNumber(params.data.blngCmplCnt),
                                  _.toNumber(params.data.blngNcmpCnt),
                                ),
                              ),
                              100,
                            ).toFixed(1)
                          : 0,
                      )}
                    />
                  </div>
                  <div className="relative w-50">
                    {_.add(
                      _.toNumber(params.data.blngCmplCnt),
                      _.toNumber(params.data.blngNcmpCnt),
                    ) !== 0
                      ? _.multiply(
                          _.divide(
                            _.toNumber(params.data.blngCmplCnt),
                            _.add(
                              _.toNumber(params.data.blngCmplCnt),
                              _.toNumber(params.data.blngNcmpCnt),
                            ),
                          ),
                          100,
                        ).toFixed(1)
                      : 0}
                    %
                  </div>
                </div>
              </div>
            </div>
          );
          // );
        },
        suppressSizeToFit: true,
      },
      {
        headerName: '프로젝트 일정',
        children: [
          {
            headerName: '시작일자',
            field: 'fctvStrtDate',
            width: 120,
            suppressSizeToFit: true,
          },
          {
            headerName: '종료일자',
            field: 'fctvEndDate',
            width: 120,
            suppressSizeToFit: true,
          },
        ],
      },
      // {
      //   headerName: '프로젝트 일정',
      //   field: '',
      //   valueFormatter: (params: ValueFormatterParams) => {
      //     return `${moment(params.data.workStrtDttm).format(
      //       'YYYY-MM-DD',
      //     )} ~ ${moment(params.data.workEndDttm).format('YYYY-MM-DD')}`;
      //   },
      //   width: 230,
      //   suppressSizeToFit: true,
      // },
    );

    // 검색 결과 테이블에 적용함
    setEslStatusTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
      loading: false,
    }));
  };

  /* //  */

  /**
   * 프로젝트 정보 통계
   */

  // 진척현황 주간/월간 통계 정의
  const [prjcStatsCategoryTab, setPrjcStatsCategoryTab] =
    useState<string>('WEEK_STATS');

  // 진척현황 주간/월간 통계 이벤트를 정의
  const handlePrjcStatsCategoryTab_onChange = (event: any) => {
    setPrjcStatsCategoryTab(event);
  };

  // 프로젝트 정보 통계(주간) 테이블
  const [prjcStatsWeekList, setPrjcStatsWeekList] = useState<any[]>([]);

  // 프로젝트 정보 통계(주간) 테이블
  const [prjcStatsWeekTable, setPrjcStatsWeekTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  const prjcStatsWeekTableRef = useRef<any>(null);

  // 프로젝트 정보 통계(주간) 테이블의 로딩 여부를 정의함
  const [prjcStatsWeekTableLoading, setPrjcStatsWeekTableLoading] =
    useState<boolean>(false);

  // 프로젝트 정보 통계(월간) 테이블
  const [prjcStatsMonthList, setPrjcStatsMonthList] = useState<any[]>([]);

  // 프로젝트 정보 통계(월간) 테이블
  const [prjcStatsMonthTable, setPrjcStatsMonthTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 프로젝트 정보 통계(월간) 테이블의 로딩 여부를 정의함
  const [prjcStatsMonthTableLoading, setPrjcStatsMonthTableLoading] =
    useState<boolean>(false);

  // 주간 통계 정보 column 정의
  const eslPrcjWeekCategoryItem: { headerName: string; field: string }[] = [
    {
      headerName: '계획 수립',
      field: 'allPlan',
    },
    {
      headerName: 'Billing 미완료',
      field: 'blngNcmp',
    },
    {
      headerName: 'Billing 완료',
      field: 'blngCmpl',
    },
  ];

  // 진척현황 전체 통계(주간) 테이블을 초기화함
  const initPrjcStatsWeekTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];
    let tmpWeekColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push({
      headerName: '구분',
      field: 'eslStats',
      pinned: 'left',
      align: 'center',
      width: 130,
    });

    let monthTxt = '';
    // 컬럼을 생성함
    _.orderBy(prjcStatsWeekList, ['yearMonth', 'weeks'], ['asc'])?.map(
      (item: any, index: number) => {
        if (_.isEqual(monthTxt, moment(item.yearMonth).format('yyyyMM'))) {
        } else {
          // 월 단위 column 생성
          tmpColumn.push({
            field: moment(monthTxt).format('yyyyMM'),
            headerName: moment(monthTxt).format('yyyy.MM'),
            align: 'center',
            width: 90,
            children: tmpWeekColumn, // 월에 포함된 주단위 column 생성
          });

          tmpWeekColumn = [];
        }

        // 주별 데이타 column 생성
        let tmpChildrenColumn = {
          field: `${moment(item.yearMonth).format('yyyyMM')}_${item.weeks}`,
          headerName: item.weeks,
          width: 60,
          cellRenderer: (params: any) => {
            return (
              <span
                className={[
                  (params.value === 0 || params.value === '0') &&
                    'text-blue-200',
                ].join(' ')}
              >
                {params.value}
              </span>
            );
          },
        };
        tmpWeekColumn.push(tmpChildrenColumn);

        monthTxt = moment(item.yearMonth).format('yyyyMM');
      },
    );

    // 데이터를 정의함
    let tmpData: any[] = [];
    // 데이터를 생성함
    eslPrcjWeekCategoryItem.map(
      (item: { headerName: string; field: string }, index: number) => {
        let tmpDataRow: any = {};

        tmpDataRow['eslStats'] = item.headerName;

        prjcStatsWeekList?.map((subItem: any, index: number) => {
          tmpDataRow[
            `${moment(subItem.yearMonth).format('yyyyMM')}_${subItem.weeks}`
          ] = subItem[item.field];
        });
        tmpData.push(tmpDataRow);
      },
    );

    // 검색 결과 테이블에 적용함
    setPrjcStatsWeekTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
      data: tmpData,
    }));
  };

  // 월간 통계 정보 column 정의
  const eslPrcjMonthCategoryItem: { headerName: string; field: string }[] = [
    {
      headerName: '계획 수립',
      field: 'monthAllPlan',
    },
    {
      headerName: 'Billing 미완료',
      field: 'monthBlngNcmp',
    },
    {
      headerName: 'Billing 완료',
      field: 'monthBlngCmpl',
    },
  ];

  // 진척현황 전체 통계(월간) 테이블을 초기화함
  const initPrjcStatsMonthTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push({
      headerName: '구분',
      field: 'eslStats',
      pinned: 'left',
      align: 'center',
      width: 130,
    });

    // 컬럼을 생성함
    _.orderBy(prjcStatsMonthList, ['month'], ['asc'])?.map(
      (item: any, index: number) => {
        tmpColumn.push({
          field: moment(item.month).format('yyyyMM'),
          headerName: moment(item.month).format('yyyy.MM'),
          align: 'center',
          width: 100,
          cellRenderer: (params: any) => {
            return (
              <span
                className={[
                  (params.value === 0 || params.value === '0') &&
                    'text-blue-200',
                ].join(' ')}
              >
                {params.value}
              </span>
            );
          },
        });
      },
    );

    // 데이터를 정의함
    let tmpData: any[] = [];

    // 데이터를 생성함
    eslPrcjMonthCategoryItem.map(
      (item: { headerName: string; field: string }, index: number) => {
        let tmpDataRow: any = {};

        tmpDataRow['eslStats'] = item.headerName;

        prjcStatsMonthList?.map((subItem: any, index: number) => {
          tmpDataRow[moment(subItem.month).format('yyyyMM')] =
            subItem[item.field];
        });

        tmpData.push(tmpDataRow);
      },
    );

    // 검색 결과 테이블에 적용함
    setPrjcStatsMonthTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
      data: tmpData,
    }));
  };

  /* // 프로젝트 정보 통계 */

  /**
   *
   */

  /* //  */

  /**
   * Event
   */

  // ESL 진척현황 테이블의 행을 클릭함
  const handleEslStatusTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220452';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'ESL 설치작업 진척 현황 상세',
      content: (
        <MN2302220452
          id={tmpId}
          cntrNmbr={row.data.cntrNmbr}
          dcmnDntfNmbr={row.data.dcmnDntfNmbr}
        />
      ),
      size: 1500,
    });
  };

  const handleSetupStatusCnt_onClick = (param: string) => {
    // 테이블의 페이지를 변경함
    eslStatusTablePagingCurrentPageRef.current = 1;
    setEslStatusTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: eslStatusTablePagingCurrentPageRef.current,
    }));

    getEslStatus(param);
  };

  /* //  */

  /**
   * 데이타 불러오기
   */

  // ESL 진척현황 정보 불러오기
  const getEslStatus = (pCmplFlag?: string) => {
    // 진척현황 테이블의 로딩 여부에 적용함
    setEslStatusTableLoading(true);

    // 프로젝트 정보 통계(주간) 테이블의 로딩 여부에 적용함
    setPrjcStatsWeekTableLoading(true);

    // 프로젝트 정보 통계(월간) 테이블의 로딩 여부에 적용함
    setPrjcStatsMonthTableLoading(true);

    // ESL 진척현황 불러오기
    sttnApi
      .getSttnEsl({
        cmplFlag: pCmplFlag || '',
        pageSize: eslStatusTablePaging.rowPerPage,
        currPageIdx: eslStatusTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        // ESL 진척 현황 적용
        setEslStatus(data.data.cntVo);

        // 진척 주간 통계 데이타 적용
        setPrjcStatsWeekList(data.data.weekList);

        // 진척 월간 통계 데이타 적용
        setPrjcStatsMonthList(data.data.monList);

        // 진척현황 테이블 데이타 적용
        setEslStatusTable((pre: { column: any; data: any }) => ({
          ...pre,
          data: data.data.prjcList.list,
        }));

        // 테이블을 페이징함
        setEslStatusTablePaging((pre: ITablePaging) => ({
          ...pre,
          totalPage: data.data.prjcList.page.totPageCnt,
          totalRow: data.data.prjcList.page.totItemCnt,
        }));

        // 진척현황 테이블의 로딩 여부에 적용함
        setEslStatusTableLoading(false);

        // 프로젝트 정보 통계(주간) 테이블의 로딩 여부에 적용함
        setPrjcStatsWeekTableLoading(false);

        // 프로젝트 정보 통계(월간) 테이블의 로딩 여부에 적용함
        setPrjcStatsMonthTableLoading(false);
      });
  };

  /* //  */

  /**
   * useEffect
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 진척현황 테이블 초기화
    //initEslStatusTable();

    // ESL 진척현황 데이타 불러오기
    getEslStatus();

    return () => {};
  }, []);

  // ESL 진척현황 정보 불러오기 성공시 실행
  useEffect(() => {
    if (prjcStatsWeekList.length === 0) {
      return;
    }

    // 진척 통계(주간) 테이블 초기화
    initPrjcStatsWeekTable();

    return () => {};
  }, [prjcStatsWeekList]);

  // ESL 진척현황 정보 불러오기 성공시 실행
  useEffect(() => {
    if (prjcStatsMonthList.length === 0) {
      return;
    }

    // 진척 통계(월간) 테이블 초기화
    initPrjcStatsMonthTable();

    return () => {};
  }, [prjcStatsMonthList]);

  // ESL 진척현황 정보 불러오기 성공시 실행
  useEffect(() => {
    if (eslStatusTable.data.length === 0) {
      return;
    }

    // 진척현황 테이블 초기화
    initEslStatusTable();

    return () => {};
  }, [eslStatusTable.data.length]);

  /* //  */

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-5">
        <div className="flex justify-between items-center space-x-5">
          <div>
            <div className="flex justify-center items-center">
              <span className="text-base text-gray-600 font-bold">
                전체 프로젝트 일정 : {eslStatus?.totalPrjcStrt} ~{' '}
                {eslStatus?.totalPrjcEnd}
              </span>
            </div>
          </div>
        </div>

        {/* 진척 현황 */}
        <div className="h-full flex justify-between items-center space-x-5">
          {/* 왼쪽 테이블 */}
          <div className="w-3/5">
            <div className="table-wrapper">
              <table className="table-list">
                <colgroup className="mobile-none">
                  <col />
                  <col />
                  <col />
                </colgroup>

                <thead>
                  <tr>
                    <th rowSpan={2} className="w-1/5 border-r leading-none">
                      <div className="flex justify-center items-center space-x-2">
                        <span>전체</span>

                        <PbHelpPopover>
                          <span className="text-sm">
                            해당 프로젝트의 전체 주문 건입니다.
                          </span>
                        </PbHelpPopover>
                      </div>
                    </th>
                    <th
                      colSpan={2}
                      style={{ height: '3.5rem' }}
                      className="!py-0 w-1/5 border-r leading-5"
                    >
                      <span>
                        설치
                        <br />
                        미완료
                      </span>
                    </th>
                    <th
                      colSpan={2}
                      style={{ height: '3.5rem' }}
                      className="!py-0 w-1/5 leading-5"
                    >
                      <span>
                        설치
                        <br />
                        완료
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{ height: '3.5rem' }}
                      className="!py-0 w-1/5 border-r leading-5"
                    >
                      <div className="flex justify-center items-center space-x-2">
                        <span>
                          계획
                          <br />
                          미수립
                        </span>

                        <PbHelpPopover>
                          <span className="text-sm">
                            EMS에 작업 일정 및 협력사를 등록했지만, PM 검수가
                            완료되지 않은 주문 건입니다.
                          </span>
                        </PbHelpPopover>
                      </div>
                    </th>
                    <th
                      style={{ height: '3.5rem' }}
                      className="!py-0 w-1/5 border-r leading-5"
                    >
                      <div className="flex justify-center items-center space-x-2">
                        <span>
                          계획
                          <br />
                          수립
                        </span>

                        <PbHelpPopover>
                          <span className="text-sm">
                            EMS에 작업 일정 및 협력사를 등록하지 않은 주문
                            건입니다.
                          </span>
                        </PbHelpPopover>
                      </div>
                    </th>
                    <th
                      style={{ height: '3.5rem' }}
                      className="!py-0 w-1/5 border-r leading-5"
                    >
                      <div className="flex justify-center items-center space-x-2">
                        <span>
                          Billing
                          <br />
                          미완료
                        </span>

                        <PbHelpPopover>
                          <span className="text-sm">
                            PM 검수 완료 처리가 된 주문 중, 빌링 처리가 되지
                            않은 건입니다.
                          </span>
                        </PbHelpPopover>
                      </div>
                    </th>
                    <th
                      style={{ height: '3.5rem' }}
                      className="!py-0 w-1/5 leading-5"
                    >
                      <div className="flex justify-center items-center space-x-2">
                        <span>
                          Billing
                          <br />
                          완료
                        </span>

                        <PbHelpPopover>
                          <span className="text-sm">
                            PM 검수 완료 처리가 된 주문 중, 빌링 처리까지 완료된
                            건입니다.
                          </span>
                        </PbHelpPopover>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td
                      onClick={() => handleSetupStatusCnt_onClick('')}
                      className="flex-none h-12 button-event"
                    >
                      <span>{eslStatus?.totalCnt}</span>
                    </td>
                    <td
                      onClick={() => handleSetupStatusCnt_onClick('plan_ncmp')}
                      className="flex-none h-12 button-event"
                    >
                      <span>{eslStatus?.planNcmpCnt}</span>
                    </td>
                    <td
                      onClick={() => handleSetupStatusCnt_onClick('plan_cmpl')}
                      className="flex-none h-12 button-event"
                    >
                      <span>{eslStatus?.planCmplCnt}</span>
                    </td>
                    <td
                      onClick={() => handleSetupStatusCnt_onClick('blng_ncmp')}
                      className="flex-none h-12 button-event"
                    >
                      <span>{eslStatus?.blngNcmpCnt}</span>
                    </td>
                    <td
                      onClick={() => handleSetupStatusCnt_onClick('blng_cmpl')}
                      className="flex-none h-12 button-event"
                    >
                      <span>{eslStatus?.blngCmplCnt}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* 오른쪽 테이블 */}
          <div className="w-2/5">
            <div className="table-wrapper">
              <table className="table-list">
                <colgroup className="mobile-none">
                  <col />
                  <col />
                  <col />
                </colgroup>

                <thead>
                  <tr>
                    <th rowSpan={2} className="w-1/5 border-r leading-none">
                      <span>진척도</span>
                    </th>
                    <th
                      style={{ height: '5.25rem' }}
                      className="w-1/5 !bg-white leading-5"
                    >
                      <div className="flex justify-center items-center space-x-2">
                        <span>실적</span>

                        <PbHelpPopover width={250}>
                          <span className="text-sm">
                            (빌링미완료 + 빌링완료) / 전체
                          </span>
                        </PbHelpPopover>
                      </div>
                    </th>
                    <th
                      style={{ height: '5.25rem' }}
                      className="w-3/5 !bg-white leading-none"
                    >
                      <div className="w-full pr-5 flex justify-center items-center">
                        {/* 차트 */}
                        <div className="grow">
                          <Progress
                            size="xl"
                            radius="xs"
                            color="indigo"
                            value={
                              isNaN(
                                _.toNumber(
                                  _.toNumber(eslStatus?.totalCnt) !== 0
                                    ? _.multiply(
                                        _.divide(
                                          _.add(
                                            _.toNumber(eslStatus?.blngCmplCnt),
                                            _.toNumber(eslStatus?.blngNcmpCnt),
                                          ),
                                          _.toNumber(eslStatus?.totalCnt),
                                        ),
                                        100,
                                      ).toFixed(1)
                                    : 0,
                                ),
                              )
                                ? 0
                                : _.toNumber(
                                    _.toNumber(eslStatus?.totalCnt) !== 0
                                      ? _.multiply(
                                          _.divide(
                                            _.add(
                                              _.toNumber(
                                                eslStatus?.blngCmplCnt,
                                              ),
                                              _.toNumber(
                                                eslStatus?.blngNcmpCnt,
                                              ),
                                            ),
                                            _.toNumber(eslStatus?.totalCnt),
                                          ),
                                          100,
                                        ).toFixed(1)
                                      : 0,
                                  )
                            }
                          />
                        </div>

                        {/* 퍼센트 */}
                        <div className="flex-none w-14 flex justify-end items-center">
                          <span>
                            {isNaN(
                              _.toNumber(
                                _.toNumber(eslStatus?.totalCnt) !== 0
                                  ? _.multiply(
                                      _.divide(
                                        _.add(
                                          _.toNumber(eslStatus?.blngCmplCnt),
                                          _.toNumber(eslStatus?.blngNcmpCnt),
                                        ),
                                        _.toNumber(eslStatus?.totalCnt),
                                      ),
                                      100,
                                    ).toFixed(1)
                                  : 0,
                              ),
                            )
                              ? 0
                              : _.toNumber(
                                  _.toNumber(eslStatus?.totalCnt) !== 0
                                    ? _.multiply(
                                        _.divide(
                                          _.add(
                                            _.toNumber(eslStatus?.blngCmplCnt),
                                            _.toNumber(eslStatus?.blngNcmpCnt),
                                          ),
                                          _.toNumber(eslStatus?.totalCnt),
                                        ),
                                        100,
                                      ).toFixed(1)
                                    : 0,
                                )}
                            %
                          </span>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{ height: '5.25rem' }}
                      className="w-1/5 !bg-white leading-5"
                    >
                      <div className="flex justify-center items-center space-x-2">
                        <span>Billing</span>

                        <PbHelpPopover width={250}>
                          <span className="text-sm">
                            빌링완료 / (빌링미완료 + 빌링완료)
                          </span>
                        </PbHelpPopover>
                      </div>
                    </th>
                    <th
                      style={{ height: '5.25rem' }}
                      className="w-3/5 !bg-white leading-none"
                    >
                      <div className="w-full pr-5 flex justify-center items-center">
                        {/* 차트 */}
                        <div className="grow">
                          <Progress
                            size="xl"
                            radius="xs"
                            color="lime"
                            value={
                              isNaN(
                                _.toNumber(
                                  _.add(
                                    _.toNumber(eslStatus?.blngCmplCnt),
                                    _.toNumber(eslStatus?.blngNcmpCnt),
                                  ) !== 0
                                    ? _.multiply(
                                        _.divide(
                                          _.toNumber(eslStatus?.blngCmplCnt),
                                          _.add(
                                            _.toNumber(eslStatus?.blngCmplCnt),
                                            _.toNumber(eslStatus?.blngNcmpCnt),
                                          ),
                                        ),
                                        100,
                                      ).toFixed(1)
                                    : 0,
                                ),
                              )
                                ? 0
                                : _.toNumber(
                                    _.add(
                                      _.toNumber(eslStatus?.blngCmplCnt),
                                      _.toNumber(eslStatus?.blngNcmpCnt),
                                    ) !== 0
                                      ? _.multiply(
                                          _.divide(
                                            _.toNumber(eslStatus?.blngCmplCnt),
                                            _.add(
                                              _.toNumber(
                                                eslStatus?.blngCmplCnt,
                                              ),
                                              _.toNumber(
                                                eslStatus?.blngNcmpCnt,
                                              ),
                                            ),
                                          ),
                                          100,
                                        ).toFixed(1)
                                      : 0,
                                  )
                            }
                          />
                        </div>

                        {/* 퍼센트 */}
                        <div className="flex-none w-14 flex justify-end items-center">
                          <span>
                            {isNaN(
                              _.toNumber(
                                _.add(
                                  _.toNumber(eslStatus?.blngCmplCnt),
                                  _.toNumber(eslStatus?.blngNcmpCnt),
                                ) !== 0
                                  ? _.multiply(
                                      _.divide(
                                        _.toNumber(eslStatus?.blngCmplCnt),
                                        _.add(
                                          _.toNumber(eslStatus?.blngCmplCnt),
                                          _.toNumber(eslStatus?.blngNcmpCnt),
                                        ),
                                      ),
                                      100,
                                    ).toFixed(1)
                                  : 0,
                              ),
                            )
                              ? 0
                              : _.toNumber(
                                  _.add(
                                    _.toNumber(eslStatus?.blngCmplCnt),
                                    _.toNumber(eslStatus?.blngNcmpCnt),
                                  ) !== 0
                                    ? _.multiply(
                                        _.divide(
                                          _.toNumber(eslStatus?.blngCmplCnt),
                                          _.add(
                                            _.toNumber(eslStatus?.blngCmplCnt),
                                            _.toNumber(eslStatus?.blngNcmpCnt),
                                          ),
                                        ),
                                        100,
                                      ).toFixed(1)
                                    : 0,
                                )}
                            %
                          </span>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>

        {/* 사용 안함!! */}
        {/* 진척 현황 */}
        <div className="h-50 flex space-x-5 hidden">
          {/* 왼쪽 현황 */}
          <div className="w-3/5 p-1 flex justify-center items-center div-bg-color border border-gray-200">
            {eslStatus && (
              <div className="w-full grid grid-cols-4 gap-1">
                {/* 전체 */}
                <div className="relative row-span-2 py-1 bg-white border border-gray-200 rounded-md space-y-3">
                  {/* 이름 */}
                  <div className="flex justify-center items-center">
                    <div>
                      <span className="text-xl font-bold">전체</span>
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center">
                      <span className="text-xl text-indigo-800 font-bold">
                        {eslStatus?.totalCnt} 건
                      </span>
                    </div>
                  </div>
                </div>

                {/* 설치 완료 */}
                <div className="relative row-span-2 py-1 bg-white border border-gray-200 rounded-md space-y-3">
                  {/* 이름 */}
                  <div className="flex justify-center items-center">
                    <div>
                      <span className="text-xl font-bold">설치 완료</span>
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center">
                      <span className="text-xl text-indigo-800 font-bold">
                        {eslStatus?.blngCmplCnt} 건
                      </span>
                    </div>
                  </div>
                </div>

                {/* Billing 미완료 */}
                <div className="relative row-span-2 py-1 bg-white border border-gray-200 rounded-md space-y-3">
                  {/* 이름 */}
                  <div className="flex justify-center items-center">
                    <div>
                      <span className="text-xl font-bold">Billing 미완료</span>
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center">
                      <span className="text-xl text-indigo-800 font-bold">
                        {eslStatus?.blngNcmpCnt} 건
                      </span>
                    </div>
                  </div>
                </div>

                {/* 설치 미완료 */}
                <div className="relative row-span-2 py-1 bg-white border border-gray-200 rounded-md space-y-3">
                  {/* 이름 */}
                  <div className="flex justify-center items-center">
                    <div>
                      <span className="text-xl font-bold">설치 미완료</span>
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <div className="flex justify-center items-center">
                      <span className="text-xl text-indigo-800 font-bold">
                        {eslStatus?.planNcmpCnt} 건
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* 오른쪽 현황 */}
          <div className="w-2/5 p-1 flex justify-center items-start div-bg-color border border-gray-200">
            {eslStatus && (
              <div className="w-full grid grid-cols-4 gap-1">
                {/* {csReceptionType.map((item: any, index: number) => ( */}
                <div className="relative row-span-2 py-1 bg-white border border-gray-200 rounded-md space-y-3">
                  <div className="flex justify-center items-center ">
                    <div>
                      <span className="text-xl font-bold">진척도</span>
                    </div>
                  </div>
                </div>

                <div className="w-full row-span-2 col-span-3 p-1 bg-white border border-gray-200 rounded-md space-y-3">
                  <div className="row-span-2 p-1 bg-white border border-gray-200 rounded-md space-y-3">
                    {/* 실적 */}
                    <div className=" grid grid-cols-4 gap-1 items-center space-x-3">
                      <div className="relative w-80">
                        <span className="text-md">실적</span>
                      </div>
                      <div className="relative col-span-2 grow w-100">
                        {' '}
                        {/* 회색 : 계획 Shop수, 파랑 : 빌링 완료 + 빌링 미완료 */}
                        <Progress
                          size="xl"
                          radius="xs"
                          color="indigo"
                          value={_.toNumber(
                            _.toNumber(eslStatus?.totalCnt) !== 0
                              ? _.multiply(
                                  _.divide(
                                    _.add(
                                      _.toNumber(eslStatus?.blngCmplCnt),
                                      _.toNumber(eslStatus?.blngNcmpCnt),
                                    ),
                                    _.toNumber(eslStatus?.totalCnt),
                                  ),
                                  100,
                                ).toFixed(1)
                              : 0,
                          )}
                        />
                      </div>
                      <div className="relative w-50">
                        {_.toNumber(
                          _.toNumber(eslStatus?.totalCnt) !== 0
                            ? _.multiply(
                                _.divide(
                                  _.add(
                                    _.toNumber(eslStatus?.blngCmplCnt),
                                    _.toNumber(eslStatus?.blngNcmpCnt),
                                  ),
                                  _.toNumber(eslStatus?.totalCnt),
                                ),
                                100,
                              ).toFixed(1)
                            : 0,
                        )}
                        %
                      </div>
                    </div>
                  </div>

                  {/* Billing */}
                  <div className="row-span-2 p-1 bg-white border border-gray-200 rounded-md space-y-3">
                    <div className=" grid grid-cols-4 gap-1 items-center space-x-3">
                      <div className="relative w-80">
                        <span className="text-md">Billing</span>
                      </div>
                      <div className="relative col-span-2 grow w-100">
                        {' '}
                        {/* 회색 : 빌링 완료 + 빌링 미완료, 파랑 : 빌링완료(설치완료) */}
                        <Progress
                          size="xl"
                          radius="xs"
                          color="indigo"
                          value={_.toNumber(
                            _.add(
                              _.toNumber(eslStatus?.blngCmplCnt),
                              _.toNumber(eslStatus?.blngNcmpCnt),
                            ) !== 0
                              ? _.multiply(
                                  _.divide(
                                    _.toNumber(eslStatus?.blngCmplCnt),
                                    _.add(
                                      _.toNumber(eslStatus?.blngCmplCnt),
                                      _.toNumber(eslStatus?.blngNcmpCnt),
                                    ),
                                  ),
                                  100,
                                ).toFixed(1)
                              : 0,
                          )}
                        />
                      </div>
                      <div className="relative w-50">
                        {_.toNumber(
                          _.add(
                            _.toNumber(eslStatus?.blngCmplCnt),
                            _.toNumber(eslStatus?.blngNcmpCnt),
                          ) !== 0
                            ? _.multiply(
                                _.divide(
                                  _.toNumber(eslStatus?.blngCmplCnt),
                                  _.add(
                                    _.toNumber(eslStatus?.blngCmplCnt),
                                    _.toNumber(eslStatus?.blngNcmpCnt),
                                  ),
                                ),
                                100,
                              ).toFixed(1)
                            : 0,
                        )}
                        %
                      </div>
                    </div>
                  </div>
                </div>
                {/* ))} */}
              </div>
            )}
          </div>
        </div>

        <div className="space-y-3">
          <PbSection label="작업 상태">
            {/* 탭 */}
            <Tabs
              variant="outline"
              value={prjcStatsCategoryTab}
              onTabChange={handlePrjcStatsCategoryTab_onChange}
            >
              {/* 탭 헤더 */}
              <Tabs.List>
                <Tabs.Tab
                  value="WEEK_STATS"
                  icon={
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'calendar-week']}
                        className="w-4 h-4 text-gray-600"
                      />
                    </div>
                  }
                >
                  <span className="text-lg">주간</span>
                </Tabs.Tab>
                <Tabs.Tab
                  value="MONTH_STATS"
                  icon={
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['far', 'calendar']}
                        className="w-4 h-4 text-gray-600"
                      />
                    </div>
                  }
                >
                  <span className="text-lg">월간</span>
                </Tabs.Tab>
              </Tabs.List>
              {/* 주간 현황 */}
              <Tabs.Panel value="WEEK_STATS" pt="xl">
                {/* 테이블 */}
                <div className="w-full h-72">
                  <PbAgGridReact
                    refs={prjcStatsWeekTableRef}
                    columnDefs={prjcStatsWeekTable.column}
                    rowData={prjcStatsWeekTable.data}
                    loading={prjcStatsWeekTableLoading}
                    setLoading={setPrjcStatsWeekTableLoading}
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      wrapHeaderText: false,
                      autoHeaderHeight: true,
                    }}
                    rowSelection="single"
                    sizeColumnsToFit={false}
                  />
                </div>
              </Tabs.Panel>
              {/* 월간 현황 */}
              <Tabs.Panel value="MONTH_STATS" pt="xl">
                {/* 테이블 */}
                <div className="w-full h-60">
                  <PbAgGridReact
                    columnDefs={prjcStatsMonthTable.column}
                    rowData={prjcStatsMonthTable.data}
                    loading={prjcStatsMonthTableLoading}
                    setLoading={setPrjcStatsMonthTableLoading}
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      wrapHeaderText: false,
                      autoHeaderHeight: true,
                    }}
                    rowSelection="single"
                    sizeColumnsToFit={false}
                  />
                </div>

                {/*/!* 테이블 *!/*/}
                {/*<div className="relative w-full h-60">*/}
                {/*  /!* 로딩 중 아이콘 *!/*/}
                {/*  /!* <LoadingTableDataIcon visible={searchResultTable.loading} /> *!/*/}

                {/*  <AgGridReact*/}
                {/*    columnDefs={prjcStatsMonthTable.column}*/}
                {/*    rowData={prjcStatsMonthTable.data}*/}
                {/*    // onRowClicked={handleEslStatusTable_onRowDoubleClicked}*/}
                {/*    defaultColDef={{*/}
                {/*      initialWidth: 150,*/}
                {/*      resizable: true,*/}
                {/*      sortable: true,*/}
                {/*      wrapHeaderText: false,*/}
                {/*      autoHeaderHeight: true,*/}
                {/*      suppressSizeToFit: true,*/}
                {/*    }}*/}
                {/*    rowSelection="single"*/}
                {/*    // overlayNoRowsTemplate={*/}
                {/*    //   searchResultTable.loading*/}
                {/*    //     ? '데이터가 없습니다.'*/}
                {/*    //     : '불러오는 중'*/}
                {/*    // }*/}
                {/*    className="ag-theme-alpine"*/}
                {/*  ></AgGridReact>*/}
                {/*</div>*/}
              </Tabs.Panel>
            </Tabs>
          </PbSection>
        </div>

        <div className="space-y-3">
          <div className="flex justify-end items-center space-x-5">
            <div>
              <div className="flex justify-center items-center">
                <span className="text-base text-gray-600 font-bold">
                  총 {eslStatusTablePaging.totalRow}건
                </span>
              </div>
            </div>
          </div>

          {/* 테이블 */}
          <div className="w-full h-172">
            <PbAgGridReact
              columnDefs={eslStatusTable.column}
              rowData={eslStatusTable.data}
              loading={eslStatusTableLoading}
              setLoading={setEslStatusTableLoading}
              onRowDoubleClicked={handleEslStatusTable_onRowDoubleClicked}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="single"
              sizeColumnsToFit={true}
              rowHeight={88}
              className={'align-middle'}
              visiblePaging={true}
              paging={{
                totalPage: eslStatusTablePaging.totalPage,
                currentPage: eslStatusTablePaging.currentPage,
              }}
              onChangePage={(event: any) => {
                // 테이블의 페이지를 변경함
                eslStatusTablePagingCurrentPageRef.current = event;
                setEslStatusTablePaging((pre: ITablePaging) => ({
                  ...pre,
                  currentPage: eslStatusTablePagingCurrentPageRef.current,
                }));

                // 테이블 데이터를 불러옴
                getEslStatus();
              }}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220402Page;
