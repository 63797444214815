import { atom } from 'recoil';
import { IPageContent } from '../interfaces/app.interface';

/**
 * 페이지 내용
 */
export const pageContentStore = atom({
  key: 'pageContentStore',
  default: {
    pageContentWidth: 0,
  } as IPageContent,
});
