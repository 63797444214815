import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Select } from '@mantine/core';
import { ISelect } from '../../interfaces/app.interface';
import { useInterval } from '@mantine/hooks';

interface IComponentProps {
  refs?: any;
  onChange?: any;
  data?: any;
  value?: string;
  setSelect: any;
  readOnly?: boolean;
  searchable?: boolean;
  variant?: any;
  placeholder?: string;
  clearable?: boolean;
  onClick?: () => void;
  className?: string;
  style?: any;
  tag?: string;
}

/**
 * Mantine Select
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PbSelect = ({
  refs = null,
  onChange = (event: any) => {},
  data = [],
  value = '',
  setSelect,
  readOnly = false,
  onClick,
  searchable = false,
  variant = 'default',
  placeholder = '',
  clearable = false,
  className = '',
  style = {},
  tag = '',
}: PropsWithChildren<IComponentProps>) => {
  const selectRef = useRef<HTMLInputElement>(null);
  const dataRef = useRef<any>([]);
  const valueRef = useRef<string>('');
  const loopRef = useRef<number>(30);

  // 지정한 시간 간격마다 셀렉트의 아이템이 바인딩되었는지 확인함
  const interval = useInterval(() => {
    setSelect((pre: ISelect) => ({
      ...pre,
      value: valueRef.current,
    }));

    // console.log(
    //   `> ${tag} ref interval:`,
    //   loopRef.current,
    //   valueRef.current,
    //   readOnly,
    // );

    // 셀렉트의 아이템이 바인딩되었고, 셀렉트의 값도 적용했다면 인터벌을 중지함
    if (refs === null) {
      if (selectRef.current!.value !== '') {
        interval.stop();
      }
    } else {
      if (refs.current.value !== '') {
        interval.stop();
      }
    }

    // 무한 대기를 방지하기 위해 인터벌을 중지함
    if (loopRef.current === 0) {
      interval.stop();
    }

    loopRef.current -= 1;
  }, 100);

  useEffect(() => {
    if (tag) {
      console.log('----------');
      console.log(`> ${tag} select / data:`, data.length, data);
      console.log(`> ${tag} select / value:`, value);
    }

    dataRef.current = data;
    valueRef.current = value;

    interval.start();
    return interval.stop;
  }, []);

  useEffect(() => {
    if (tag) {
      console.log(`> ${tag} select / data - data:`, data.length, data);
      console.log(`> ${tag} select / data - value:`, value);
    }

    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    if (tag) {
      console.log(`> ${tag} select / value - data:`, data.length, data);
      console.log(`> ${tag} select / value - value:`, value);
    }

    valueRef.current = value;
  }, [value]);

  return (
    <Select
      ref={refs === null ? selectRef : refs}
      onChange={onChange}
      data={data}
      value={value}
      searchable={searchable}
      readOnly={readOnly}
      variant={variant}
      placeholder={placeholder}
      clearable={clearable}
      style={style}
      className={className}
    />
  );
};

export default PbSelect;
