import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Box, Pagination, ScrollArea } from '@mantine/core';
import _ from 'lodash';
import { ITableColumn } from '../../interfaces/app.interface';

interface IPbTableProps {
  reference?: any;
  label?: string;
  rightForm?: JSX.Element;
  column?: ITableColumn[];
  data?: any;
  striped?: boolean;
  highlightOnHover?: boolean;
  userSelectNone?: boolean;
  usePagination?: boolean;
  rowPerPage?: number;
  useHorizontalScroll?: boolean;
  width?: number;
  maxScrollWidth?: number;
  onClickRow?: (item: any) => void;
  onClickCell?: (item: any) => void;
}

/**
 * 테이블
 * @param reference 테이블 레퍼런스
 * @param label 테이블 레이블
 * @param rightForm 레이블 오른쪽 폼 영역
 * @param column 테이블 컬럼
 * @param data 테이블 데이터
 * @param striped 줄무늬 행 배경 색상 여부
 * @param highlightOnHover 행 위에 마우스 오버 시, 배경 색상 변경 여부
 * @param userSelectNone 블럭 선택 사용 여부
 * @param usePagination 페이징 사용 여부
 * @param rowPerPage 한 페이지에 출력할 행 개수
 * @param useHorizontalScroll 가로 스크롤 사용 여부
 * @param width 테이블 너비
 * @param maxScrollWidth 테이블 가로 스크롤 너비
 * @param onClickRow 행 클릭 이벤트
 * @param onClickCell 셀 클릭 이벤트
 * @constructor
 */
const PbTable = ({
  reference = null,
  label,
  rightForm = <></>,
  column,
  data,
  striped = false,
  highlightOnHover = true,
  userSelectNone = false,
  usePagination = false,
  rowPerPage = 3,
  useHorizontalScroll = false,
  width = 0,
  maxScrollWidth = 0,
  onClickRow,
  onClickCell,
}: PropsWithChildren<IPbTableProps>) => {
  // 현재 페이지 번호를 정의함
  const [activePage, setActivePage] = useState<number>(1);

  // 전체 페이지 개수를 정의함
  const [totalPage, setTotalPage] = useState<number>(1);

  // 현재 페이지의 목록을 정의함
  const [activePageList, setActivePageList] = useState<any>([]);

  // 테이블 태그를 정의함
  const tableTag: JSX.Element = (
    <div ref={reference} className="table-wrapper">
      <table className="table-list">
        <colgroup className="mobile-none">
          {column?.map((item: ITableColumn, index: number) => (
            <col key={index} />
          ))}
        </colgroup>

        <thead>
          <tr>
            {column?.map((item: any, index: number) => (
              <th key={index}>{item.title}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {activePageList.map((item: any, index: number) => (
            <tr
              key={index}
              onClick={() => (onClickRow ? onClickRow(item) : {})}
              className={[
                striped && index % 2 === 1 && 'bg-gray-100',
                highlightOnHover && 'hover:bg-gray-100/50',
                userSelectNone && 'select-none',
                onClickRow !== undefined &&
                  'cursor-pointer active:bg-gray-200/50',
              ].join(' ')}
            >
              {column?.map((subItem: any, subIndex: number) => (
                <td
                  key={subIndex}
                  onClick={() =>
                    onClickCell !== undefined
                      ? onClickCell({
                          column: subItem,
                          value: item[subItem.column],
                        })
                      : {}
                  }
                  className={[
                    subItem.align === 'left' ? '!text-left' : '',
                    subItem.align === 'center' ? '!text-center' : '',
                    subItem.align === 'right' ? '!text-right' : '',
                    onClickCell !== undefined ? 'button-event' : '',
                  ].join(' ')}
                >
                  {subItem.cellRenderer === undefined && item[subItem.column]}
                  {subItem.cellRenderer !== undefined &&
                    subItem.cellRenderer(item[subItem.column])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  useEffect(() => {
    return () => {};
  }, []);

  // 데이터가 변경될 때 실행함
  useEffect(() => {
    // 전체 페이지 개수를 계산함
    let tmpTotalPage: number = Math.ceil(data.length / rowPerPage);

    // 전체 페이지 개수에 적용함
    setTotalPage(tmpTotalPage);

    // 페이징을 사용하지 않으면 전체 목록을 현재 페이지의 목록에 적용함
    if (!usePagination) {
      // 현재 페이지의 목록에 적용함
      setActivePageList(data);
    }

    return () => {};
  }, [data]);

  // 현재 페이지 번호가 변경될 때 실행함
  useEffect(() => {
    if (!usePagination) {
      return;
    }

    // 현재 페이지의 목록을 불러옴
    let tmpActivePageList: any = data.slice(
      (activePage - 1) * rowPerPage,
      (activePage - 1) * rowPerPage + rowPerPage,
    );
    tmpActivePageList = _.cloneDeep(tmpActivePageList);

    // 현재 페이지의 목록에 적용함
    setActivePageList(tmpActivePageList);

    return () => {};
  }, [data, activePage]);

  return (
    <div>
      <div className="content-wrapper">
        {/* title-wrapper */}
        {label && (
          <div className="title-wrapper">
            {/*<h3 className="title">{label}</h3>*/}
            <h3 className="leading-none">
              <span className="text-lg text-gray-600 font-semibold">
                {label}
              </span>
            </h3>
            <div className="right-btn !space-x-2">{rightForm}</div>
          </div>
        )}

        <div className="w-full space-y-7">
          {useHorizontalScroll && (
            <ScrollArea style={{ width: width }}>
              <Box style={{ width: maxScrollWidth }}>{tableTag}</Box>
            </ScrollArea>
          )}
          {!useHorizontalScroll && <>{tableTag}</>}

          {/* 페이징 */}
          {usePagination && (
            <div className="flex justify-center items-center">
              <Pagination
                onChange={setActivePage}
                total={totalPage}
                value={activePage}
                size="lg"
                color="gray"
                withEdges
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PbTable;
