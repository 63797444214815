import React, { PropsWithChildren } from 'react';
import { IOptionItem } from '../../interfaces/app.interface';

interface IPbSelectProps {
  size?: 'tiny' | 'mini' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'half' | 'full';
  item?: IOptionItem[];
  value?: string;
  useDefaultItem?: boolean;
  defaultItemLabel?: string;
  onChange?: (event: any) => void;
  disabled?: boolean;
}

/**
 * 셀렉트
 * @param size 가로 크기
 * @param item 아이템 목록
 * @param value 선택한 값
 * @param useDefaultItem 기본 선택 아이템 사용 여부
 * @param defaultItemLabel 기본 선택 아이템 레이블
 * @param onChange 아이템 변경 이벤트
 * @param disabled 비활성화 여부
 * @constructor
 */
const PbSelect2 = ({
  size,
  item,
  value = '',
  useDefaultItem = true,
  defaultItemLabel = '선택하세요.',
  onChange,
  disabled = false,
}: PropsWithChildren<IPbSelectProps>) => {
  return (
    <div
      className={['select-wrapper', size && `pb-form-size-${size}`].join(' ')}
    >
      <select
        onChange={onChange ? onChange : () => {}}
        value={value}
        className={[
          'form-select',
          'pb-form-size-full',
          disabled && '!bg-gray-100',
        ].join(' ')}
        disabled={disabled}
      >
        {useDefaultItem && <option value="">{defaultItemLabel}</option>}
        {item?.map((item: IOptionItem, index: number) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PbSelect2;
