import React, { PropsWithChildren } from 'react';
import { useElementSize } from '@mantine/hooks';

interface IPbFormGridColProps {
  label?: string;
  colSpan?: number;
  isHidden?: boolean;
  withAsterisk?: boolean;
  rightExtraArea?: any | null;
  isBlank?: boolean;
  className?: string;
  onClick?: () => void;
}

/**
 * 폼 그리드 > 컬럼
 * @param label 폼 그리드 컬럼 레이블
 * @param colSpan 컬럼 병합 개수
 * @param isHidden 컬럼 숨김 여부
 * @param isBlank 빈 칸 처리 여부
 * @param className CSS 클래스
 * @param withAsterisk 필수 입력 여부
 * @param onClick <이벤트>
 * @param children 기타 props
 * @constructor
 */
const PbFormGridCol = ({
  label = '',
  colSpan,
  isHidden = false,
  isBlank = false,
  className,
  withAsterisk = false,
  rightExtraArea = null,
  onClick,
  children,
}: PropsWithChildren<IPbFormGridColProps>) => {
  const { ref, width, height } = useElementSize();

  return (
    <div
      className={[
        colSpan !== undefined ? `col-span-${colSpan}` : '',
        isHidden ? 'hidden' : '',
        'overflow-hidden',
        className,
      ].join(' ')}
    >
      <div ref={ref} className="h-full flex justify-start items-center">
        {isBlank === true ? (
          <div className="w-full h-full bg-white" />
        ) : (
          <>
            <div className="h-full form-grid-col-label flex-none flex justify-center items-center">
              {label}
              {withAsterisk && (
                <div className="ml-1">
                  <span className="text-base text-red-500">*</span>
                </div>
              )}
              {rightExtraArea !== null && rightExtraArea}
            </div>
            {height > 0 && (
              <div
                style={{ minHeight: height }}
                className="h-full form-grid-col-value grow flex justify-start items-center space-x-2"
              >
                {children}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PbFormGridCol;
