import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

interface IBottomBarProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 하단바
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const BottomBar = ({ data, onClick }: PropsWithChildren<IBottomBarProps>) => {
  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 링크를 클릭함
  const handleLink_onClick = (link: string) => {
    // 해당 페이지로 이동함
    // navigate(link);
    window.location.href = link;
  };

  return (
    <div className="footer">
      {/* inner */}
      <div className="inner">
        {/* company-info-wrapper */}
        <div className="company-info-wrapper">
          <div className="footer-logo"></div>
          <div className="company-info">
            <ul>
              <li>Dtonic Corporation</li>
              <li>
                6th/7th Floor, 200, Seohyeon-ro, Bundang-gu,
                <br />
                Seongnam-si, Gyeonggi-do,
                <br />
                Republic of Korea
              </li>
            </ul>
          </div>
        </div>
        {/* // company-info-wrapper */}

        {/* menu-wrapper */}
        <div className="menu-wrapper">
          <div className="menu">
            <ul>
              <li>
                <a onClick={() => handleLink_onClick('/bm0101')}>영업관리</a>
              </li>
              <li>
                <a onClick={() => handleLink_onClick('/')}>설치관리</a>
              </li>
              <li>
                <a onClick={() => handleLink_onClick('/')}>현황 관리</a>
              </li>
              <li>
                <a onClick={() => handleLink_onClick('/')}>운영 관리</a>
              </li>
              <li>
                <a onClick={() => handleLink_onClick('/')}>파트너사 관리</a>
              </li>
              <li>
                <a onClick={() => handleLink_onClick('/')}>게시판 관리</a>
              </li>
              <li>
                <a onClick={() => handleLink_onClick('/')}>시스템 관리</a>
              </li>
            </ul>
          </div>

          <div className="company-info">
            <ul>
              <li>
                상호 :<br />
                디토닉㈜
              </li>
              <li>
                대표자명:
                <br />
                전용주
              </li>
              <li>
                사업자등록번호:
                <br />
                264-81-37380
              </li>
              <li>
                이메일:
                <br />
                info@dtonic.io
              </li>
              <li>
                전화번호:
                <br />
                031-689-4770
              </li>
              <li>
                주소:
                <br />
                경기도 성남시 분당구 서현동 245-5 6층, 7층
                <br />
                (수인분당선 서현역 5번 출구 도보 4분, 삼성생명건물)
              </li>
            </ul>
          </div>
        </div>
        {/* // menu-wrapper */}
      </div>
      {/* // inner */}

      {/* copyright-wrapper */}
      <div className="copyright-wrapper">
        <div className="copyright">
          Copyright ⓒ dtonic.io All Rights Reserved.
        </div>
      </div>
      {/* // copyright-wrapper */}
    </div>
  );
};

export default BottomBar;
