import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import _, { forEach, now } from 'lodash';
import { PbTable } from '../PbTable';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import * as cmnApi from '../../apis/cmn.api';
import * as nstlApi from '../../apis/nstl.api';
import { PbInputNumber } from '../PbInputNumber';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  Group,
  NumberInput,
  Select,
  Tabs,
  TextInput,
} from '@mantine/core';
import { Radio } from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import { decode, encode } from 'html-entities';
import PbSelect from '../PbSelect/PbSelect.component';

interface IComponentProps {
  id: string;
  type?: string;
  dcmnDntfNmbr?: string;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * 재고 이동 요청
 * @param id 화면아이디
 * @param type 수정여부
 * @param dcmnDntfNmbr 문서 번호
 * @param data <인자>
 * @param onClick <이벤트>
 * @param callback 상위 모달에 데이타 적용
 * @constructor
 */

// 재고 유형 select 정의함
let valStockTypeSelect: string = '';

// 재고 상태 select 정의함
let valStockStateSelect: any = [];

function Pb(props: {
  onChange: (event: any) => void;
  data: IOptionItem[];
  refs: React.MutableRefObject<HTMLInputElement>;
  className: string;
  value: string;
  searchable: boolean;
}) {
  return null;
}

const MN2302220352_Stock_Modify = ({
  id = '',
  type = '',
  dcmnDntfNmbr = '',
  data,
  onClick,
  callback,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 변수
   */

  // 품목 목록
  const [itemSelect, setItemSelect] = useState<ISelect>({
    value: '',
    item: [],
  });
  const itemSelectRef = useRef<HTMLInputElement>(null);

  // 품목 번호
  const [itemNumberInput, setItemNumberInput] = useState<string>('');

  // 재고 유형
  const [stockTypeSelect, setStockTypeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });
  const stockTypeSelectRef = useRef(null);

  // 재고 상태
  const [stockStateSelect, setStockStateSelect] = useState<ISelect>({
    value: '',
    item: [],
  });
  const stockStateSelectRef = useRef(null);

  // 재고 수량
  const [stockCountInput, setStockCountInput] = useState<string>('');

  // 메모
  const [stockMemoInput, setStockMemoInput] = useState<string>('');

  /* // 변수 */

  /**
   * event handle
   */

  // 품목 명 변경
  const handleItemSelect_onChange = (event: any) => {
    setItemSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
    setItemNumberInput(event);
  };

  // 재고 유형 변경
  const handleStockTypeSelect_onChange = (event: any) => {
    valStockTypeSelect = event;

    setStockTypeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));

    if (_.isEqual(event, 'STCK_RTRN')) {
      setStockStateSelect((pre: ISelect) => ({
        value: 'CMN',
        item: valStockStateSelect,
      }));
    } else {
      setStockStateSelect((pre: ISelect) => ({
        value: '',
        item: [{ label: '-', value: '' }],
      }));
    }
  };

  // 재고 상태 변경
  const handleStockStateSelect_onChange = (event: any) => {
    setStockStateSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 수량 변경
  const handleStockCountInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 10) {
      // 10byte 제한
      setStockCountInput((pre) => pre);
    } else {
      setStockCountInput(_.toString(_.toNumber(sVal)));
    }
  };

  // 메모 변경
  const handleStockMemoInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 30) {
      // 30byte 제한
      setStockMemoInput((pre) => pre);
    } else {
      setStockMemoInput(sVal);
    }
  };

  // 재고 유형 초기화
  const initStockTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'STCK_TYPE',
      })
      .then((data: IApiResult) => {
        data.data.list.map((item: any, index: number) => {
          tmpOptionItem.push({
            label: item.dtlCodeDscr,
            value: item.dtlCodeId,
          });
        });
      });

    valStockTypeSelect = 'STCK_AD';

    // 재고 유형 적용
    setStockTypeSelect((pre: ISelect) => ({
      value: 'STCK_AD',
      item: tmpOptionItem,
    }));
  };

  // 재고 상태 초기화
  const initStockStateSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'STCK_STTS',
      })
      .then((data: IApiResult) => {
        data.data.list.map((item: any, index: number) => {
          tmpOptionItem.push({
            label: item.dtlCodeDscr,
            value: item.dtlCodeId,
          });
        });
      });

    // 재고 상태 적용
    valStockStateSelect = tmpOptionItem;

    if (_.isEqual(valStockTypeSelect, 'STCK_RTRN')) {
      setStockStateSelect((pre: ISelect) => ({
        value: 'CMN',
        item: valStockStateSelect,
      }));
    } else {
      setStockStateSelect((pre: ISelect) => ({
        value: '',
        item: [{ label: '-', value: '' }],
      }));
    }
  };

  // 품목명 초기화
  const initItemSelect = () => {
    let tmpOptionItem: IOptionItem[] = [
      {
        label: '선택하세요.',
        value: '',
      },
    ];

    nstlApi
      .getNstlNvntTrnsItems({
        dcmnDntfNmbr: dcmnDntfNmbr,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          _.sortBy(data.data.list, ['no']).map((item: any, index: number) => {
            tmpOptionItem.push({
              label: decode(item.itemDtls),
              value: item.itemNmbr,
            });
          });

          // 품목 목록
          setItemSelect((pre: ISelect) => ({
            ...pre,
            item: tmpOptionItem,
          }));
        }
      });

    // 품목 목록
    setItemSelect((pre: ISelect) => ({
      ...pre,
      item: tmpOptionItem,
    }));
  };

  // useEffect(() => {
  //   if (itemSelect.item.length === 0) {
  //     return;
  //   }
  //
  //   setItemSelect((pre: ISelect) => ({
  //     ...pre,
  //     value: itemSelect.value,
  //   }));
  //
  //   console.log('> pre.value:', itemSelect);
  //
  //   return () => {};
  // }, [itemSelect.item]);

  /* // event handle */

  /**
   * callback
   */

  // 적용 을 클릭함
  const handleApply_onClickButton = () => {
    if (callback !== undefined) {
      if (_.isEmpty(stockTypeSelect.value)) {
        setModal({
          title: '알림',
          content: '품목을 선택하세요.',
        });
        return;
      }

      if (_.isEmpty(stockCountInput.trim())) {
        setModal({
          title: '알림',
          content: '수량을 입력하세요.',
        });
        return;
      }

      // if (_.isEmpty(stockMemoInput.trim())) {
      //   setModal({
      //     title: '알림',
      //     content: '내용을 입력하세요.',
      //   });
      //   return;
      // }

      let rtnObj = {
        modifyRowIndex: type === 'REQ' ? 0 : data.modifyRowIndex,
        stckTypeCode: stockTypeSelect.value,
        stckTypeName: (stockTypeSelectRef.current! as HTMLInputElement).value,
        stckSttsCode: stockStateSelect.value,
        stckSttsName: (stockStateSelectRef.current! as HTMLInputElement).value,
        itemCode: itemSelect.value,
        itemName: (itemSelectRef.current! as HTMLInputElement).value,
        itemNumber: itemNumberInput,
        quantity: stockCountInput.trim(),
        ttl: stockMemoInput.trim(),
      };
      callback(rtnObj);
      // 컴포넌트 모달을 닫음
      setRemoveComponentModal(id);
    }
  };

  /* // callback */

  /**
   * 기타 처리
   */

  // 입력값 byte 확인
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  /* // 기타 처리 */

  /**
   * useEffect
   */

  // 화면 로딩 후 한번만 실행
  useEffect(() => {
    // 재고 유형 초기화
    initStockTypeSelect();

    // 품목 selectbox 초기화
    initItemSelect();

    // 재고 상태 초기화
    initStockStateSelect();

    // 수량 초기화
    setStockCountInput('');
  }, []);

  // 재고 유형 변경시
  useEffect(() => {
    // 재고 상태 초기화
    initStockStateSelect();

    if (_.isEqual(type, 'MOD')) {
      if (_.isEqual(valStockTypeSelect, 'STCK_RTRN')) {
        setStockStateSelect((pre: ISelect) => ({
          ...pre,
          value: data.stckSttsCode,
        }));
      }
    }
  }, [stockTypeSelect, valStockTypeSelect]);

  useEffect(() => {
    if (_.isEqual(type, 'MOD')) {
      // 품목명
      setItemSelect((pre: ISelect) => ({
        ...pre,
        value: data.itemCode,
      }));

      // 재고 상태 적용
      valStockTypeSelect = data.stckTypeCode;

      // 재고 유형 적용
      setStockTypeSelect((pre: ISelect) => ({
        ...pre,
        value: data.stckTypeCode,
      }));

      // 품목 번호
      setItemNumberInput(data.itemNumber);

      // 수량 초기화
      setStockCountInput(data.quantity);

      // 메모
      setStockMemoInput(data.ttl);
    }
  }, [data]);
  /* // useEffect */

  return (
    <div className="space-y-7">
      <div className="space-y-5 h-112">
        <PbFormGrid label="재고 이동 요청" cols={2}>
          <PbFormGridCol label="품목명" withAsterisk={true}>
            <PbSelect
              refs={itemSelectRef}
              onChange={handleItemSelect_onChange}
              data={itemSelect.item}
              value={itemSelect.value}
              setSelect={setItemSelect}
              placeholder="검색어를 입력하시거나 목록을 선택하세요."
              readOnly={_.isEqual(type, 'REQ') ? false : true}
              searchable={_.isEqual(type, 'REQ') ? true : false}
              clearable={_.isEqual(type, 'REQ') ? true : false}
              variant={_.isEqual(type, 'REQ') ? 'default' : 'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="품목 번호">
            <TextInput
              value={itemNumberInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="재고 유형" withAsterisk={true}>
            <PbSelect
              refs={stockTypeSelectRef}
              onChange={handleStockTypeSelect_onChange}
              data={stockTypeSelect.item}
              value={stockTypeSelect.value}
              setSelect={setStockTypeSelect}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="재고 상태" withAsterisk={true}>
            <PbSelect
              refs={stockStateSelectRef}
              onChange={handleStockStateSelect_onChange}
              data={stockStateSelect.item}
              value={stockStateSelect.value}
              setSelect={setStockStateSelect}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="수량" withAsterisk={true}>
            <TextInput
              type="number"
              value={stockCountInput}
              onChange={handleStockCountInput_onChange}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="메모">
            <TextInput
              placeholder="내용을 입력하세요."
              value={stockMemoInput}
              onChange={handleStockMemoInput_onChange}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        <div className="component-modal-button-area">
          <div>
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>
          </div>
          <div>
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleApply_onClickButton}
            >
              적용
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MN2302220352_Stock_Modify;
