import React, { PropsWithChildren } from 'react';
import { nanoid } from 'nanoid';

interface IPbFormGridProps {
  id?: string;
  isHidden?: boolean;
  label?: string;
  rightForm?: JSX.Element;
  cols: number;
  onClick?: () => void;
  className?: string;
}

/**
 * 폼 그리드
 * @param isHidden 숨김 여부
 * @param label 폼 그리드 레이블
 * @param rightForm 레이블 오른쪽 폼 영역
 * @param cols 한 행의 컬럼 개수
 * @param onClick <이벤트>
 * @param className 클래스 명
 * @param children 기타 props
 * @constructor
 */
const PbFormGrid = ({
  id = nanoid(),
  isHidden = false,
  label = '',
  rightForm = <></>,
  cols,
  onClick,
  className = '',
  children,
}: PropsWithChildren<IPbFormGridProps>) => {
  return (
    <div id={id} className={[isHidden ? 'hidden' : '', className].join(' ')}>
      <div className="content-wrapper">
        {/* title-wrapper */}
        {label && (
          <div className="title-wrapper">
            {/*<h3 className="title">{label}</h3>*/}
            <h3 className="leading-none">
              <span className="text-lg text-gray-600 font-semibold">
                {label}
              </span>
            </h3>
            <div className="right-btn !space-x-2">{rightForm}</div>
          </div>
        )}

        <div
          className={['form-grid', 'grid', `grid-cols-${cols}`, 'gap-px'].join(
            ' ',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default PbFormGrid;
