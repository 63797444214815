import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Select,
  Tabs,
  Group,
  Checkbox,
  Radio,
  Popover,
} from '@mantine/core';
import { DatePickerInput, MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IModal,
  IComponentModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { pageContentStore } from '../stores/page.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { addComponentModalStore } from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import MN2302220353 from '../components/MN2302220353/MN2302220353.component';
import MN2302220303_Days from '../components/MN2302220303_Days/MN2302220303_Days.component';
import * as nstlApi from '../apis/nstl.api';
import * as csApi from '../apis/cs.api';
import * as sttnApi from '../apis/sttn.api';
import { PbFullCalendar } from '../components/PbFullCalendar';
import { PbAgGridReact } from '../components/PbAgGridReact';
import { ValueFormatterParams } from 'ag-grid-community';
import { useDisclosure } from '@mantine/hooks';
import { PbHelpPopover } from '../components/PbHelpPopover';
import PbSelect from '../components/PbSelect/PbSelect.component';

/**
 * 설치 관리 > PM검수 대기 현황
 * @constructor
 */
const MN2302220303Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 선택한 탭을 정의함
  const [selectedTab, setSelectedTab] = useState<string>('list');

  // 캘린더 이벤트를 정의함
  const [calendarEvent, setCalendarEvent] = useState<any[]>([]);

  // 캘린더 이벤트 테이블을 정의함
  const [calendarEventTable, setCalendarEventTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 캘린더 이벤트 테이블 페이징을 정의함
  const [calendarEventTablePaging, setCalendarEventTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const calendarEventTablePagingCurrentPageRef = useRef<number>(1);

  // 캘린더 이벤트 테이블의 로딩 여부를 정의함
  const [calendarEventTableLoading, setCalendarEventTableLoading] =
    useState<boolean>(false);

  // 캘린더의 검색한 년월을 정의함
  const [searchYm, setSearchYm] = useState<string>(
    moment(new Date()).format('YYYYMM'),
  );

  // 탭을 변경함
  const handleTabs_onTabChange = (event: any) => {
    // 선택한 탭에 적용함
    setSelectedTab(event);
  };

  // 캘린더의 이전달, 다음달 버튼을 클릭함
  const handleCalendarMonthButton_onClick = (param: number) => {
    let tmpYm: string = '';

    if (param === 0) {
      // 오늘(이번달)로 이동함
      tmpYm = moment(new Date()).format('YYYYMM');
    } else {
      // 이전달, 다음달로 이동함
      tmpYm = moment(searchYm).add(param, 'months').format('YYYYMM');
    }

    // 검색한 년월에 적용함
    setSearchYm(tmpYm);
  };

  // 캘린더의 날짜를 클릭함
  const handleCalendar_onDateClick = (arg: any) => {
    let tmpId: string = `calendarEvent_${arg.dateStr}`;

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: moment(arg.dateStr).format('YYYY년 MM월 DD일'),
      content: (
        <MN2302220303_Days
          id={tmpId}
          searchYmd={moment(arg.dateStr).format('YYYYMMDD')}
        />
      ),
      size: 750,
      closeOnClickOutside: true,
    });
  };

  // 캘린더의 이벤트를 클릭함
  const handleCalendar_onEventClick = (arg: any) => {
    let tmpItem: any = arg.event.extendedProps.item;
    let tmpId: string = 'MN2302220353';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'PM 검수 대기 현황 상세',
      content: (
        <MN2302220353
          id={tmpId}
          dcmnDntfNmbr={tmpItem.dcmnDntfNmbr}
        />
      ),
      size: 1500,
    });
  };

  /**
   * 상단 프로젝트/프로젝트 일자 선택 영역
   */

  // 프로젝트명 > 셀렉트를 정의함
  const [projectNameSelect, setProjectNameSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 프로젝트명 셀렉트에서 선택한 아이템을 변경함
  const handleProjectNameSelect_onChange = (event: any) => {
    setProjectNameSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 프로젝트명 > 셀렉트의 아이템을 초기화함
  const initProjectNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 프로젝트명 > 셀렉트박스를 불러옴
    csApi.getPrjcs({}).then((data: IApiResult) => {
      data.data.list.map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.prjcName,
          value: item.prjcCode,
        });
      });

      // 프로젝트명 > 셀렉트의 아이템에 적용함
      setProjectNameSelect({
        // value: data.data.list[0].prjcCode,
        value: '',
        item: tmpOptionItem,
      });
    });
  };

  /* //  */

  /**
   * 검색 조건 적용
   */

  // 전체 보기
  const [isShowAllCheckbox, setIsShowAllCheckbox] = useState<string>('N');

  // 작업 상태 코드
  const [workCheckbox, setWorkCheckbox] = useState<string>('');

  // 전체 미등록 / 네트워크 공사 정보만 미등록 / ESL 설치 정보만 미등록
  const [setUpCheckbox, setSetUpCheckbox] = useState<string[]>(['']);

  // 검색 폼 그리드 > 진행정보 > 라디오를 정의함
  const [setupStatusRadio, setSetupStatusRadio] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 진행정보 > 도움말 출력여부를 정의함
  const [
    openedSearchSetupHelpPopOver,
    { close: closeSearchSetupHelpPopOver, open: openSearchSetupHelpPopOver },
  ] = useDisclosure(false);

  // 검색 폼 그리드 > 진행정보 > 라디오의 아이템을 초기화함
  const initSetupStatusRadio = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체보기',
        value: '',
      },
      {
        label: 'PM 검수 미완료',
        value: 'NSPC_CMPL',
      },
      {
        label: 'PM 검수 완료',
        value: 'PM_NSPC_CMPL',
      },
    );

    // 검색 폼 그리드 > 진행정보 > 라디오의 아이템에 적용함
    setSetupStatusRadio((pre: ISelect) => ({
      value: 'NSPC_CMPL',
      item: tmpOptionItem,
    }));
  };

  const handleSetUpCheckbox_onChange = (event: any) => {
    if (event.length > 0) {
      setSetUpCheckbox([event[event.length - 1]]);
    }
    setWorkCheckbox([event[event.length - 1]][0]);
  };

  // 검색 폼 그리드 > 진행정보 > 라디오에서 선택한 아이템을 변경함
  const handleSetUpStatusRadio_onChange = (event: any) => {
    setSetupStatusRadio((pre: ISelect) => ({
      ...pre,
      value: event.target.value,
    }));
  };

  /* 담당PM */
  // 검색 폼 그리드  > 담당 PM 셀렉트를 정의함
  const [pmNameSelect, setPmNameSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 담당 PM 셀렉트에서 선택한 아이템을 변경함
  const handlePmNameSelect_onChange = (event: any) => {
    setPmNameSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템을 초기화함
  const initPmNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: 'all',
      },
      {
        label: '담당자 없음',
        value: '',
      },
    );

    // 판매 계약 담당PM 목록을 불러옴
    sttnApi.getSttnErpSearchPm({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.name,
          value: item.userId,
        });
      });

      // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템에 적용함
      setPmNameSelect((pre: ISelect) => ({
        value: 'all',
        item: tmpOptionItem,
      }));
    });
  };

  /* //  */

  /**
   * PM 검수 대기 목록
   */

  // PM 검수 대기 목록 테이블을 초기화함
  const initPmInspectionTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      // {
      //   headerName: '번호',
      //   field: 'no',
      //   width: 80,
      //   suppressSizeToFit: true,
      // },
      // {
      //   headerName: '주문번호',
      //   field: 'dcmnDntfNmbr',
      //   width: 100,
      //   suppressSizeToFit: true,
      // },
      {
        headerName: '프로젝트명',
        field: 'prjcName',
      },
      {
        headerName: '계약명',
        field: 'cntrName',
      },
      // {
      //   headerName: '프로젝트 코드',
      //   field: 'prjcCode',
      //   width: 130,
      //   suppressSizeToFit: true,
      // },
      // {
      //   headerName: '고객사명',
      //   field: 'bpName',
      //   width: 200,
      //   suppressSizeToFit: true,
      // },
      {
        headerName: 'Shop명',
        field: 'shipToCode',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: '작업 상태',
        field: 'nstlSttsCode',
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.data.nstlSttsCode === 'PM_NSPC_CMPL') {
            return 'PM 검수완료';
          } else if (params.data.nstlSttsCode === 'NSPC_CMPL') {
            return '검수완료';
          } else if (params.data.nstlSttsCode === 'WORK_CMPL') {
            return '작업완료';
          } else if (params.data.nstlSttsCode === 'PRCD') {
            return '진행중';
          }
        },
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '담당 PM',
        field: 'pmName',
      },
      // {
      //   headerName: '작업 일정',
      //   field: 'workDate',
      //   cellRenderer: (params: any) =>
      //     `${params.data.workStrtDttm} ~ ${params.data.workEndDttm}`,
      //   width: 250,
      //   suppressSizeToFit: true,
      // },
      {
        headerName: '작업일정',
        children: [
          {
            headerName: '시작일시',
            field: 'workStrtDttm',
            width: 190,
            suppressSizeToFit: true,
          },
          {
            headerName: '종료일시',
            field: 'workEndDttm',
            width: 190,
            suppressSizeToFit: true,
          },
        ],
      },
    );

    // PM 검수 대기 목록 테이블에 적용함
    setCalendarEventTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // PM 검수 대기 목록 테이블 데이터를 불러옴
  const getPmInspectionTableData = () => {
    // PM 검수 대기 테이블의 로딩 여부에 적용함
    setCalendarEventTableLoading(true);

    nstlApi
      .getNstlPmNspcStnds({
        isShowAll: 'Y', // 전체 보기(*)
        // nstlSttsCode: workCheckbox, // 작업 상태코드
        nstlSttsCode: setupStatusRadio.value, // 작업 상태코드
        prjcCode: projectNameSelect.value, // 프로젝트 코드
        workBaseYearMonth: '', // 작업 기준 년월
        pmId: pmNameSelect.value, // 담당PM
        pageSize: calendarEventTablePaging.rowPerPage,
        currPageIdx: calendarEventTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // PM 검수 대기 목록 테이블에 적용함
          setCalendarEventTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
            }),
          );

          // 테이블을 페이징함
          setCalendarEventTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }

        // PM 검수 대기 테이블의 로딩 여부에 적용함
        setCalendarEventTableLoading(false);
      });
  };

  // PM 검수 대기 목록 테이블의 행을 클릭함
  const handlePmInspectionProjectTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220353';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'PM 검수 대기 현황 상세',
      content: (
        <MN2302220353
          id={tmpId}
          dcmnDntfNmbr={row.data.dcmnDntfNmbr}
        />
      ),
      size: 1500,
    });
  };

  // PM검수대기 정보 - 달력용 데이타 불러오기
  const getCalendarEventData = () => {
    nstlApi
      .getNstlPmNspcStnds({
        isShowAll: 'N', // 전체 보기(*)
        // nstlSttsCode: workCheckbox, // 작업 상태코드
        nstlSttsCode: setupStatusRadio.value, // 작업 상태코드
        prjcCode: projectNameSelect.value, // 프로젝트 코드
        workBaseYearMonth: searchYm, // 작업 기준 년월
        pmId: pmNameSelect.value, // 담당PM
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          let tmpCalendarEvent: any[] = [];

          // 이벤트의 색상을 정의함
          const eventBgColor: { [index: string]: string } = {
            prcd: 'bg-blue-600 text-white',
            work_cmpl: 'bg-lime-600 text-white',
            dly: 'bg-orange-600 text-white',
          };

          // 캘린더 이벤트를 추가함
          data.data.list.map((item: any) => {
            tmpCalendarEvent.push({
              // title: `<span class="px-0.5 pt-0.5">${'' || 'Shop 미지정'} (${
              //   item.splrTypeName
              // }): ${item.nstlSttsName}</span>`,
              title: `<div class="px-0.5"><b>${item.dcmnScrnNmbr}</b> / ${
                item.shipToCode || 'Shop 미지정'
              } / ${item.prjcName}</div>`,
              start: moment(item.workStrtDttm).format('YYYY-MM-DD'),
              end: moment(item.workEndDttm).add(1, 'd').format('YYYY-MM-DD'),
              // classNames: [
              //   'border-t border-b border-white',
              //   eventBgColor[item.nstlSttsCode.toLowerCase()],
              // ].join(' '),
              classNames: ['border-t border-b border-white'].join(' '),
              extendedProps: {
                item: item,
              },
            });
          });

          // 캘린더 이벤트에 적용함
          setCalendarEvent(tmpCalendarEvent);
        }
      })
      .catch((error: any) => {});
  };

  /* // PM 검수 대기 목록 */

  /**
   * 엑셀 다운로드
   */

  // 검색 결과 테이블의 엑셀 다운로드 버튼을 클릭함
  const handleNonRegisterProjectTableExportExcelButton_onClick = () => {
    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <>
          {/* 버튼 */}
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              nstlApi
                .getPmNspcStndExcel({
                  isShowAll: 'Y', // 전체 보기(*)
                  // nstlSttsCode: workCheckbox, // 작업 상태코드
                  nstlSttsCode: setupStatusRadio.value, // 작업 상태코드
                  prjcCode: projectNameSelect.value, // 프로젝트 코드(*)
                  workBaseYearMonth: '', // 작업 기준 년월
                  pmId: pmNameSelect.value, // 담당PM
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    [
                      'PM 검수 대기 현황_',
                      moment().format('YYYYMMDD'),
                      '.xlsx',
                    ].join(''),
                  );
                  document.body.appendChild(link);
                  link.click();
                });

              setRemoveModal(true);
            }}
          >
            예
          </Button>
        </>
      ),
    });
  };

  /* // 엑셀 다운로드 */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 프로젝트 선택 초기화
    initProjectNameSelect();

    // 검색 폼 그리드 > 진행정보 > 라디오의 아이템을 초기화함
    initSetupStatusRadio();

    //  검색 폼 그리드 > PM 담당자 셀렉트의 아이템을 초기화함
    initPmNameSelect();

    // 검색 조건 초기화 - 전체 검색
    setSetUpCheckbox(['']);

    // 캘린더 이벤트 데이터를 불러옴
    getCalendarEventData();

    // PM 검수 대기 목록 테이블 초기화
    initPmInspectionTable();

    return () => {};
  }, []);

  // 캘린더 이벤트 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (calendarEventTable.column.length === 0) {
      return;
    }

    // 테이블의 페이지를 변경함
    calendarEventTablePagingCurrentPageRef.current = 1;
    setCalendarEventTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: calendarEventTablePagingCurrentPageRef.current,
    }));

    // PM 검수 대기 목록 데이타 가져오기
    getPmInspectionTableData();

    return () => {};
  }, [
    calendarEventTable.column,
    projectNameSelect.value,
    pmNameSelect.value,
    setUpCheckbox,
    setupStatusRadio.value,
  ]);

  // 년월이 변경될 때 실행함
  useEffect(() => {
    // 캘린더 이벤트의 데이터를 불러옴
    getCalendarEventData();

    return () => {};
  }, [
    projectNameSelect.value,
    pmNameSelect.value,
    setUpCheckbox,
    setupStatusRadio.value,
    searchYm,
  ]);

  // 프로젝트와 체크박스가 변경된 후 실행함
  useEffect(() => {
    // 테이블의 페이지를 변경함
    calendarEventTablePagingCurrentPageRef.current = 1;
    setCalendarEventTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: calendarEventTablePagingCurrentPageRef.current,
    }));

    // PM 검수 대기 목록 테이블 데이터를 불러옴
    getPmInspectionTableData();

    return () => {};
  }, [
    projectNameSelect.value,
    pmNameSelect.value,
    setUpCheckbox,
    setupStatusRadio.value,
  ]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }
    if (_.indexOf(refreshList, 'MN2302220303Table') > -1) {
      // 테이블의 페이지를 변경함
      calendarEventTablePagingCurrentPageRef.current = 1;
      setCalendarEventTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: calendarEventTablePagingCurrentPageRef.current,
      }));

      // PM 검수 대기 목록 테이블 데이터를 불러옴
      getPmInspectionTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220303Table');
    }

    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-5">
        {/* 검색 폼 그리드 */}
        <PbFormGrid cols={2}>
          <PbFormGridCol label="프로젝트">
            {/* 셀렉트 */}
            <PbSelect
              placeholder="검색어를 입력하시거나 목록을 선택하세요."
              onChange={handleProjectNameSelect_onChange}
              data={projectNameSelect.item}
              value={projectNameSelect.value}
              setSelect={setProjectNameSelect}
              className="w-full"
              searchable={true}
              clearable={true}
            />
          </PbFormGridCol>

          <PbFormGridCol
            label="진행정보"
            rightExtraArea={
              <div className="ml-2 flex justify-center items-center space-x-2">
                <PbHelpPopover>
                  <span className="text-sm">PM 검수 미완료 = 작업완료</span>
                </PbHelpPopover>
              </div>
              // <Popover
              //   width={200}
              //   position="bottom"
              //   withArrow
              //   shadow="sm"
              //   opened={openedSearchSetupHelpPopOver}
              // >
              //   <Popover.Target>
              //     <div
              //       onMouseEnter={openSearchSetupHelpPopOver}
              //       onMouseLeave={closeSearchSetupHelpPopOver}
              //       className="ml-2 flex justify-center items-center"
              //     >
              //       <FontAwesomeIcon
              //         icon={['fas', 'circle-info']}
              //         className="w-5 h-5 text-blue-400"
              //       />
              //     </div>
              //   </Popover.Target>
              //   <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
              //     <span className="text-xs">PM 검수 미완료 = 작업완료</span>
              //   </Popover.Dropdown>
              // </Popover>
            }
          >
            {/*/!* 체크박스 *!/*/}
            {/*<Checkbox.Group*/}
            {/*  value={setUpCheckbox}*/}
            {/*  onChange={handleSetUpCheckbox_onChange}*/}
            {/*>*/}
            {/*  <Group>*/}
            {/*    <Checkbox value="" label="전체 Shop" />*/}
            {/*    <Checkbox value="NSPC_CMPL" label="검수 완료" />*/}
            {/*    <Checkbox value="PM_NSPC_CMPL" label="PM 검수 완료" />*/}
            {/*  </Group>*/}
            {/*</Checkbox.Group>*/}

            {/* 라디오 */}
            <Radio.Group name="setupStatus" value={setupStatusRadio.value}>
              <Group
                onChange={handleSetUpStatusRadio_onChange}
                className="space-x-1"
              >
                {setupStatusRadio.item.map((item: any, index: number) => (
                  <Radio key={index} value={item.value} label={item.label} />
                ))}
              </Group>
            </Radio.Group>
          </PbFormGridCol>
          <PbFormGridCol label="담당PM" colSpan={2}>
            {/* 셀렉트 */}
            <Select
              onChange={handlePmNameSelect_onChange}
              data={pmNameSelect.item}
              value={pmNameSelect.value}
              className="w-full"
              searchable={true}
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 탭 */}
        <Tabs
          variant="outline"
          onTabChange={handleTabs_onTabChange}
          value={selectedTab}
        >
          <Tabs.List>
            <Tabs.Tab
              value="list"
              icon={
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'list']}
                    className="w-4 h-4 text-gray-600"
                  />
                </div>
              }
            >
              <span className="text-lg">리스트</span>
            </Tabs.Tab>
            <Tabs.Tab
              value="calendar"
              icon={
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['far', 'calendar']}
                    className="w-4 h-4 text-gray-600"
                  />
                </div>
              }
            >
              <span className="text-lg">캘린더</span>
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>

        {/* 선택한 탭에 따라 내용 변경 */}
        <div className="relative">
          <div
            className={[
              'absolute left-0 top-0 w-full h-152 space-y-3',
              selectedTab === 'list' ? 'z-30' : 'hidden',
            ].join(' ')}
          >
            <div className="flex justify-between items-center space-x-5">
              <div>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={
                    handleNonRegisterProjectTableExportExcelButton_onClick
                  }
                >
                  엑셀 다운로드
                </Button>
              </div>
              <div className="flex justify-center items-center">
                <span className="text-base text-gray-600 font-bold">
                  총 {calendarEventTablePaging.totalRow}건
                </span>
              </div>
            </div>
            <div className="w-full h-172">
              <PbAgGridReact
                columnDefs={calendarEventTable.column}
                rowData={calendarEventTable.data}
                loading={calendarEventTableLoading}
                setLoading={setCalendarEventTableLoading}
                onRowDoubleClicked={
                  handlePmInspectionProjectTable_onRowDoubleClicked
                }
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
                visiblePaging={true}
                paging={{
                  totalPage: calendarEventTablePaging.totalPage,
                  currentPage: calendarEventTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  calendarEventTablePagingCurrentPageRef.current = event;
                  setCalendarEventTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: calendarEventTablePagingCurrentPageRef.current,
                  }));

                  // 테이블 데이터를 불러옴
                  getPmInspectionTableData();
                }}
              />
            </div>
          </div>

          {/* 흰색으로 영역 덮음 */}
          {selectedTab !== 'calendar' && (
            <div className="absolute left-0 top-0 w-full h-full bg-white z-20" />
          )}
          {/* 풀캘린더 */}
          <div className="space-y-2">
            {/* 년월 정보 */}
            <div className="flex justify-between items-center">
              {/* 왼쪽 영역 */}
              <div className="w-40 flex justify-start items-center"></div>

              {/* 가운데 영역 */}
              <div className="flex justify-center items-center">
                {/* 이전달 */}
                <div
                  onClick={() => handleCalendarMonthButton_onClick(-1)}
                  className="button-event px-1 py-1 flex justify-center items-center"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-left']}
                    className="w-5 h-5 text-gray-400"
                  />
                </div>

                {/* 년월 */}
                <div className="pt-0.5 leading-none">
                  <span className="text-3xl font-bold leading-none">
                    {moment(searchYm).format('YYYY.MM')}
                  </span>
                </div>

                {/* 다음달 */}
                <div
                  onClick={() => handleCalendarMonthButton_onClick(1)}
                  className="button-event px-1 py-1 flex justify-center items-center"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="w-5 h-5 text-gray-400"
                  />
                </div>
              </div>

              {/* 오른쪽 영역 */}
              <div className="w-40 flex justify-end items-center">
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  size="md"
                  onClick={() => handleCalendarMonthButton_onClick(0)}
                >
                  오늘
                </Button>
              </div>
            </div>

            {/* 풀캘린더 */}
            <PbFullCalendar
              date={searchYm}
              events={calendarEvent}
              setEvents={setCalendarEvent}
              dateClick={handleCalendar_onDateClick}
              eventClick={handleCalendar_onEventClick}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220303Page;
