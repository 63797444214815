import React, { PropsWithChildren, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import * as cmnApi from '../../apis/cmn.api';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { IApiResult, IModal } from '../../interfaces/app.interface';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { getFindPassword } from '../../apis/cmn.api';
import { Button, Input, Modal, PasswordInput, TextInput } from '@mantine/core';

interface IComponentProps {
  id?: string;
  tmpPassword: string;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * <Component 이름>
 * @param id 화면 아이디
 * @param tmpPassword 임시 비밀번호
 * @param data <인자>
 * @param onClick <이벤트>
 * @param callback 콜백 이벤트
 * @constructor
 */
const ChangeTmpPassword = ({
  id = '',
  tmpPassword = '',
  data,
  onClick,
  callback,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 임시 비밀번호 입력을 정의함
  // const [tmpPassword, setTmpPassword] = useState<string>('');

  // 신규 비밀번호 입력을 정의함
  const [newPassword, setNewPassword] = useState<string>('');

  // 비밀번호 확인 입력을 정의함
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  // 임시 비밀번호 입력의 값을 변경함
  // const handleTmpPasswordInput_onChange = (event: any) => {
  //   setTmpPassword(event.target.value);
  // };

  // 신규 비밀번호 입력의 값을 변경함
  const handleNewPasswordInput_onChange = (event: any) => {
    setNewPassword(event.target.value);
  };

  // 비밀번호 확인 입력의 값을 변경함
  const handleConfirmPasswordInput_onChange = (event: any) => {
    setConfirmPassword(event.target.value);
  };

  const handleSendEmailButton_onClick = () => {
    if (newPassword === confirmPassword) {
    } else {
      setModal({
        title: '알림',
        content: '신규 비밀번호와 확인 정보가 다릅니다.',
      });

      return;
    }

    cmnApi
      .postChanagePassword({
        newPswr: newPassword,
        pswr: tmpPassword,
      })
      .then((data: IApiResult) => {
        // console.log('> data:', data);

        switch (data.code) {
          // 비밀번호 변경 성공
          case '200':
            setModal({
              title: '알림',
              content: '비밀번호가 변경되었습니다.',
              callback() {
                if (callback !== undefined) {
                  callback(data);
                  // 컴포넌트 모달을 닫음
                  setRemoveComponentModal(id);
                }
              },
            });

            break;

          // 비밀번호 변경 실패
          case '500':
            console.log('> 비밀번호 변경 Error:', data);
            setModal({
              title: '알림',
              content: data.message,
            });
            break;

          default:
            break;
        }
      });
  };

  return (
    <div className="pb-2 space-y-5">
      {/* 입력폼 */}
      <div className="w-full space-y-2">
        {/* 임시비밀번호 */}
        {/* <div className="flex justify-center items-center space-x-2">
          <div className="w-25 flex justify-start items-center">
            <span className="text-sm text-gray-500 font-semibold">임시 비밀번호</span>
          </div>
          <PasswordInput
            placeholder="임시 비밀번호를 입력하세요."
            onChange={handleTmpPasswordInput_onChange}
            value={tmpPassword}
            className="grow"
          />
        </div> */}

        {/* 신규 비밀번호 */}
        <div className="flex justify-center items-center space-x-2">
          <div className="w-25 flex justify-start items-center">
            <span className="text-sm text-gray-500 font-semibold">
              신규 비밀번호
            </span>
          </div>
          <PasswordInput
            placeholder="신규 비밀번호를 입력하세요."
            onChange={handleNewPasswordInput_onChange}
            value={newPassword}
            className="grow"
          />
        </div>

        {/* 신규 비밀번호 확인 */}
        <div className="flex justify-center items-center space-x-2">
          <div className="w-25 flex justify-start items-center">
            <span className="text-sm text-gray-500 font-semibold">
              비밀번호 확인
            </span>
          </div>
          <PasswordInput
            placeholder="확인 비밀번호를 입력하세요."
            onChange={handleConfirmPasswordInput_onChange}
            value={confirmPassword}
            className="grow"
          />
        </div>
      </div>

      {/* 버튼 */}
      <div className="flex justify-center items-center">
        {/* 버튼 */}
        <Button
          color="indigo"
          radius="xl"
          onClick={handleSendEmailButton_onClick}
          className="grow"
        >
          비밀번호 재설정
        </Button>
      </div>

      {/* 참고 */}
      {/* <div className="flex justify-center items-center">
        <span className="text-sm text-gray-500 font-semibold">
          비밀번호를 재설정 합니다.
        </span>
      </div> */}
    </div>
  );
};

export default ChangeTmpPassword;
