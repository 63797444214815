import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LeftMenu } from '../LeftMenu';
import { TopBar } from '../TopBar';
import { GlobalProcess } from '../GlobalProcess';
import { useRecoilState } from 'recoil';
import {
  ILeftMenu,
  IPageContent,
  IToken,
} from '../../interfaces/app.interface';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { BottomBar } from '../BottomBar';
import { Overlay, ScrollArea } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { pageContentStore } from '../../stores/page.store';
import { ComponentModalManager } from '../ComponentModalManager';
import ModalManager from '../ModalManager/ModalManager.component';
import { RefreshListManager } from '../RefreshListManager';
import * as appUtil from '../../utils/app.util';
import { overlayStore } from '../../stores/overlay.store';
import Lottie from 'lottie-react';
import loadingAnimation from '../LoadingTableDataIcon/loading2.json';
import { ImageCarouselManager } from '../ImageCarouselManager';

interface IPageProps {
  pageInfoBarLeftArea?: any;
  pageInfoBarCenterArea?: any;
  pageInfoBarRightArea?: any;
  enablePageInfoBarBackgroundColor?: boolean;
  onClick?: () => void;
}

/**
 * 페이지 레이아웃
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PageLayout = ({
  pageInfoBarLeftArea = null,
  pageInfoBarCenterArea = <></>,
  pageInfoBarRightArea = <></>,
  enablePageInfoBarBackgroundColor = true,
  onClick,
  children,
}: PropsWithChildren<IPageProps>) => {
  // 페이지의 내용 영역 크기를 정의함
  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useElementSize();

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 선택한 페이지의 이름을 정의함
  const [selectedPageName, setSelectedPageName] = useState<string>('');

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 화면 위 검은 화면 저장소를 정의함
  const [visibleOverlay, setVisibleOverlay] =
    useRecoilState<boolean>(overlayStore);

  // 스크롤 영역을 정의함
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {};
  }, []);

  // 선택한 페이지가 생성됐을 때 실행함
  useEffect(() => {
    let tmpSelectedPageName: string = '';

    // 내비게이션에 1딘계 페이지를 추가함
    if (selectedPage.oneMenuId) {
      tmpSelectedPageName = selectedPage.oneKrnMenu;
    }

    // 내비게이션에 2딘계 페이지를 추가함
    if (selectedPage.twoMenuId) {
      tmpSelectedPageName = selectedPage.twoKrnMenu;
    }

    // 내비게이션에 3딘계 페이지를 추가함
    if (selectedPage.threeMenuId) {
      tmpSelectedPageName = selectedPage.threeKrnMenu;
    }

    // 선택한 페이지의 이름에 적용함
    setSelectedPageName(tmpSelectedPageName);

    return () => {};
  }, [selectedPage]);

  // 페이지의 내용 영역 크기가 변경될 때 실행함
  useEffect(() => {
    // 페이지 내용 저장소에 적용함
    setPageContent({
      pageContentWidth: contentWidth,
    });

    return () => {};
  }, [contentWidth]);

  return (
    <>
      {/* 전역 처리 */}
      <GlobalProcess />

      {/* 모달 매니저 */}
      <ModalManager />

      {/* 컴포넌트 모달 매니저 */}
      <ComponentModalManager />

      {/* 목록 새로고침 매니저 */}
      <RefreshListManager />

      {/* 이미지 캐러셀 매니저 */}
      <ImageCarouselManager />

      {/* 화면 위 검은 화면 + 로딩 중 */}
      {visibleOverlay && (
        <div className="object-opacity-appear absolute left-0 top-0 w-screen h-screen z-1000">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center bg-white/0 z-20">
            <div className="w-96 h-96 flex justify-center items-center">
              <Lottie animationData={loadingAnimation} loop={true} />
            </div>
          </div>

          <div className="w-screen h-screen bg-black/60" />
        </div>
      )}

      {/* 로그아웃 상태에서는 전체 화면을 하얀 색으로 덮기 */}
      {!(appUtil.getToken() as IToken).accessToken && (
        <div className="absolute left-0 top-0 w-screen h-screen bg-white z-90" />
      )}

      <div className="flex relative w-full h-full">
        {/* 왼쪽 메뉴 */}
        <div className="flex-none">
          <LeftMenu />
        </div>

        {/* 오른쪽 본문 */}
        <div className="grow">
          <ScrollArea viewportRef={scrollAreaRef} className="h-screen">
            {/* 상단바 */}
            <TopBar />

            {/* 정보바 */}
            <div
              className={[
                'page-title',
                enablePageInfoBarBackgroundColor
                  ? 'page-title-bg-color'
                  : 'bg-white',
              ].join(' ')}
            >
              <div className="inner">
                {/* 페이지 이름 */}
                <div className="title-wrapper">
                  <i className="icon-01"></i>
                  <h2 className="title leading-none">
                    {pageInfoBarLeftArea === null
                      ? selectedPageName
                      : pageInfoBarLeftArea}
                  </h2>
                </div>

                {/* 중간 영역 */}
                <div className="info-wrapper">{pageInfoBarCenterArea}</div>

                {/* 오른쪽 영역 */}
                <div className="info-wrapper">{pageInfoBarRightArea}</div>
              </div>
            </div>

            {/* 정보바 하단 가로 구분선 */}
            {!enablePageInfoBarBackgroundColor && (
              <div className="h-px px-8">
                <div className="border-b px-10"></div>
              </div>
            )}

            {/* 페이지 */}
            <div ref={contentRef} className="px-8 pt-6 pb-20">
              {children}
            </div>

            {/* TOP 버튼 */}
            <div className="absolute right-8 bottom-10 z-50">
              <div
                onClick={() => {
                  scrollAreaRef.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }}
                className="button-event w-12 h-12 flex justify-center items-center bg-black/70 rounded-full"
              >
                <FontAwesomeIcon
                  icon={['fas', 'arrow-up']}
                  className="w-5 h-5 text-white"
                />
              </div>
            </div>

            {/* 하단바 */}
            <BottomBar />
          </ScrollArea>
        </div>
      </div>
    </>
  );
};

export default PageLayout;
