import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { PbAgGridReact } from '../PbAgGridReact';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
  IPageContent,
  IImageCarousel,
  IComponentModalPageScrollTab,
  IComponentModalPageScrollTabStep,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as nstlApi from '../../apis/nstl.api';
import * as cmnApi from '../../apis/cmn.api';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { Button, Select, TextInput } from '@mantine/core';
import MN2302220352_GridModify from '../MN2302220352_GridModify/MN2302220352_GridModify.component';
import PbSection from '../PbSection/PbSection.component';
import * as appUtil from '../../utils/app.util';
import prettyBytes from 'pretty-bytes';
import * as slsApi from '../../apis/sls.api';
import { imageCarouselStore } from '../../stores/imageCarousel.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PbSelect from '../PbSelect/PbSelect.component';

interface MN2302220352_NetworkProps {
  id?: string;
  splrId?: string;
  teamId?: string;
  dcmnDntfNmbr?: string;
  dcmnScrnNmbr?: string;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * 진행중 작업 관리 상세 _ Network 작업결과 등록
 * @param id 화면 아이디
 * @param splrId 협력사 아이디
 * @param teamId 팀 아이디
 * @param dcmnDntfNmbr 주문 번호(Doc Entry)
 * @param dcmnScrnNmbr 주문 번호(Doc Num) - B1 문서 번호
 * @param data <인자>
 * @param onClick <이벤트>
 * @param callback modal 수정정보 반영을 위한 이벤트
 * @constructor
 */

let valSwInfoListTable: any[] = [];

let loadSplrId: string = '';
let loadTeamId: string = ''; // 팀 확인용 변수(react 영역 밖에 설정)

const MN2302220352_Network = ({
  id = '',
  splrId = '',
  teamId = '',
  dcmnDntfNmbr = '',
  dcmnScrnNmbr = '',
  data,
  onClick,
  callback,
}: PropsWithChildren<MN2302220352_NetworkProps>) => {
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 이미지 캐러셀 저장소를 정의함
  const [imageCarousel, setImageCarousel] =
    useRecoilState<IImageCarousel>(imageCarouselStore);

  /**************************** API 호출 ****************************/

  /**************************** STATE ****************************/

  /**
   * 협력사/검수자 구분에 따른 화면 제어 변수
   * 사용자 설치 구분코드(작업검수자,PM검수자, 설치담당자 등)
   * nstlCtgr
   */
  const [isWorkNspc, setIsWorkNspc] = useState<boolean>(false);
  const [isPmNspc, setIsPmNspc] = useState<boolean>(false);
  const [isNtwrPrtn, setIsNtwrPrtn] = useState<boolean>(false);
  const [isNstlPrtn, setIsNstlPrtn] = useState<boolean>(false);

  const isWorkNspcRef = useRef<boolean>(false);
  const isPmNspcRef = useRef<boolean>(false);
  const isNtwrPrtnRef = useRef<boolean>(false);
  const isNstlPrtnRef = useRef<boolean>(false);

  // 이 컴포넌트의 전체 데이터를 정의함
  const [apiData, setApiData] = useState<any | null>(null);

  //작업 내용 선택 셀렉트
  const [workContSelect, setWorkContSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  //작업 상태 선택 셀렉트
  const [workStateSelect, setWorkStateSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  //프로젝트 이름
  const [projectNameInput, setProjectNameInput] = useState<string>('');

  //고객사명
  const [customerNameInput, setCustomerNameInput] = useState<string>('');

  //Shop 명
  const [shopNameInput, setShopNameInput] = useState<string>('');

  //주문번호
  const [orderNoInnput, setOrderNoInnput] = useState<string>('');

  //협력사 명
  const [prtnNameInput, setPrtnNameInput] = useState<string>('');

  //협력사(팀) 명
  const [prtnTeamNameInput, setPrtnTeamNameInput] = useState<string>('');

  //작업일정
  const [workDateInput, setWorkDateInput] = useState<string>('');

  //작업상태
  const [workStatusInput, setWorkStatusInput] = useState<string>('');

  // 소프트웨어 정보 테이블
  const swInfoTableRef = useRef<any>(null);

  //소프트웨어 정보 테이블
  const [swInfoTable, setSwInfoTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  /**************************** ON EVENT LISTENER ****************************/
  //작업 내용 셀렉트 변경 이벤트
  const handleWorkContSelect_onChange = (event: any) => {
    setWorkContSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));

    loadTeamId = event; // 변경 값으로 협력사(팀) 아이디 설정 - 다시 로드하기 위함

    // 작업 내용이 변경되면, 데이타 다시 불러온다
    initNetwork();
  };

  //작업 상태 셀렉트 변경 이벤트
  const handleWorkStateSelect_onChange = (event: any) => {
    setWorkStateSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  /**************************** INIT ****************************/

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    tmpTab = [
      {
        label: '작업상태',
        targetId: 'step-1',
      },
      {
        label: '프로젝트',
        targetId: 'step-2',
      },
      {
        label: '작업',
        targetId: 'step-3',
      },
      {
        label: '댓글',
        targetId: 'step-4',
      },
    ];

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 네트워크 공사 작업 내역 셀렉트 초기화
  const initWorkContSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    nstlApi
      .getNstlNotRgst({
        dcmnDntfNmbr: dcmnDntfNmbr,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          // 네트워크 공사 작업 내역 분리
          let tmpNetworkList: any = data.data.installPrtnList.filter(
            (item: any) => _.isEqual(item.splrTypeCode, 'NTWR_CNST'),
          );

          if (isWorkNspc || isPmNspc) {
            // 작업검수자, PM 검수자
            // 작업,PM 검수자는 데이타 그대로 출력
          } else {
            if (isNtwrPrtn) {
              tmpNetworkList = tmpNetworkList.filter((item: any) =>
                _.isEqual(item.teamId, loadTeamId),
              );
            }
          }

          tmpNetworkList.map((item: any) => {
            tmpOptionItem.push({
              label: item.ttl,
              value: item.teamId,
            });
          });
        }
      })
      .catch((error: any) => {});

    setWorkContSelect({
      value: loadTeamId,
      item: tmpOptionItem,
    });
  };

  //작업 상태 셀렉트 초기화
  const initWorkStateSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 설치구분 상세를 불러옴
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'NSTL_STTS',
      })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            if (
              item.dtlCodeId === 'PRCD' || // 진행중
              item.dtlCodeId === 'WORK_CMPL'
            ) {
              // 작업완료
              // 작업검수자, PM 검수자, 네트워크 설치
              if (
                isWorkNspcRef.current ||
                isPmNspcRef.current ||
                isNtwrPrtnRef.current
              ) {
                tmpOptionItem.push({
                  label: item.dtlCodeDscr,
                  value: item.dtlCodeId,
                });
              }
            } else if (item.dtlCodeId === 'NSPC_CMPL') {
              // 검수완료
              // 작업검수자, PM 검수자
              if (isWorkNspcRef.current || isPmNspcRef.current) {
                tmpOptionItem.push({
                  label: item.dtlCodeDscr,
                  value: item.dtlCodeId,
                });
              }
            } else if (item.dtlCodeId === 'PM_NSPC_CMPL') {
              // PM 검수완료
              // PM 검수완료 는 PM검수대기 현황 에서
            }
          },
        );

        setWorkStateSelect({
          value: 'PRCD',
          item: tmpOptionItem,
        });
      });
  };

  //소프트웨어 정보 테이블
  const initSwInfoTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      {
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      },
      {
        field: 'ntwrWorkName',
        headerName: '구분',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        field: 'ttl',
        headerName: '내용',
      },
      {
        headerName: '',
        field: '',
        width: 100,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="w-full object-appear col-span-3 space-y-3">
            <div className="object-appear space-y-3">
              <Button
                color="indigo"
                radius="xl"
                onClick={() => handleAddSwInfoBtn_onClick(params)}
              >
                수정
              </Button>
            </div>
          </div>
        ),
      },
    );

    setSwInfoTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /**
   *
   */

  // 첨부파일 다운로드를 클릭함
  const handleFileDownload_onClick = (fileId: string, rgnlFileName: string) => {
    slsApi
      .getSlsCtvtFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  // 소프트웨어 정보 행추가 / 수정
  const handleAddSwInfoBtn_onClick = (params: any) => {
    let tmpId: string = nanoid();
    let type: string = 'REQ';

    if (params.data) {
      type = 'MOD';
    }

    if (_.isEqual(type, 'REQ') && valSwInfoListTable.length > 1) {
      setModal({
        title: '알림',
        content: '게이트웨이, 서버는 각 1건씩만 등록 가능합니다.',
      });
      return;
    }

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '소프트웨어 정보 수정',
      content: (
        <MN2302220352_GridModify
          data={params.data}
          id={tmpId}
          type={type}
          callback={(data: any) => {
            if (_.isEqual(type, 'REQ')) {
              let ongoing: boolean = true;
              valSwInfoListTable.map((item: any) => {
                if (_.isEqual(item.ntwrWorkCode, data.ntwrWorkCode)) {
                  // 게이트웨이, 서버는 각 1건만 등록 가능(SRVR_IP, GTWY_IP)
                  setModal({
                    title: '알림',
                    content: '게이트웨이, 서버는 각 1건씩만 등록 가능합니다.',
                  });
                  ongoing = false;
                  return;
                }
              });

              if (ongoing) {
                // 각 한건일 경우 정상 진행
                data.modifyRowIndex = valSwInfoListTable.length;
                valSwInfoListTable.push(data);
              }
            } else if (_.isEqual(type, 'MOD')) {
              valSwInfoListTable.map((item: any) => {
                if (_.isEqual(item.modifyRowIndex, data.modifyRowIndex)) {
                  item.ttl = data.ttl;
                  item.ntwrWorkCode = data.ntwrWorkCode;
                }
              });
            }

            setSwInfoTable((pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: valSwInfoListTable,
            }));
          }}
        />
      ),
      size: 1500,
    });
  };

  // 소프트웨어 정보 테이블 행 삭제
  const handleRmSwInfoBtn_onClick = () => {
    let selectedRows: string[] = [];

    // 삭제할 첨부 파일 아이디 목록을 불러옴
    swInfoTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedRows.push(item.modifyRowIndex);
    });

    let tmlSwInfoTableData: any = swInfoTable.data.filter(
      (item: any) => !_.includes(selectedRows, item.modifyRowIndex),
    );

    // 행삭제 데이타 소프트웨어 테이블 적용
    setSwInfoTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlSwInfoTableData,
    }));
  };
  /* //  */

  /**
   * 처리
   */

  // Shop별 설치 일정 계획 상세 이동 버튼 클릭
  const handleMoveDetailBtn_onClick = () => {
    setRemoveComponentModal(id);
  };

  // 작업 결과 조회(네트워크)
  const getNstlWorkNtwr = () => {
    nstlApi
      .getNstlWorkNtwr({
        splrId: loadSplrId,
        teamId: loadTeamId,
        dcmnDntfNmbr: dcmnDntfNmbr,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          // 이 컴포넌트의 전체 데이터에 적용함
          setApiData(data.data);

          setWorkStateSelect((pre: ISelect) => ({
            ...pre,
            value: data.data.nstlSttsCode,
          }));
          setProjectNameInput(data.data.prjcName); // 프로젝트 이름
          setCustomerNameInput(data.data.bpName); // 고객사명
          setShopNameInput(data.data.shipToCode); // Shop명
          setOrderNoInnput(dcmnScrnNmbr); // 주문번호(Doc Num)
          setPrtnNameInput(data.data.prtnName); // 협력사명
          setPrtnTeamNameInput(`${data.data.prtnName} (${data.data.teamName})`); // 협력사(팀)명
          setWorkDateInput(
            `${data.data.workStrtDttm} ~ ${data.data.workEndDttm}`,
          ); // 작업 일정
          setWorkStatusInput(data.data.nstlSttsName); // 작업 상태

          if (data.data.ntwrList.length > 0) {
            data.data.ntwrList.map((item: any, index: number) => {
              item['modifyRowIndex'] = index; // 수정용 key 값 생성
              item.checkbox = index;
            });
            // 소프트웨어 정보 테이블 적용
            setSwInfoTable((pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.ntwrList,
            }));
          } else {
            let tmpList: any = [
              {
                ntwrWorkCode: 'GTWY_IP',
                ntwrWorkName: '게이트웨이 IP',
                modifyRowIndex: 0,
              },
              {
                ntwrWorkCode: 'SRVR_IP',
                ntwrWorkName: '서버 IP',
                modifyRowIndex: 1,
              },
            ];

            // 소프트웨어 정보 테이블 적용
            setSwInfoTable((pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: tmpList,
            }));
          }
        }
      })
      .catch((error: any) => {
        // 검색 결과 테이블에 적용함
        setSwInfoTable((pre: { column: ITableColumn[]; data: any }) => ({
          ...pre,
          loading: false,
        }));
      });
  };

  // 컴포넌트 모달 > 저장 버튼(수정)을 클릭함
  const handleModifySaveBtn_onClick = () => {
    let ongoing: boolean = true;
    // 소프트웨어 정보의 '내용' 존재 확인
    swInfoTable.data.map((item: any, index: number) => {
      if (_.isEmpty(item.ttl)) {
        setModal({
          title: '알림',
          content: '소프트웨어 정보의 내용을 입력하세요.',
        });
        ongoing = false;
      }
    });

    if (ongoing) {
      // Shop별 설치 일정 계획 수정 저장 처리
      setModal({
        title: '확인',
        content: '저장하시겠습니까?',
        useOkayButton: false,
        useCancelButton: true,
        cancelButtonLabel: '아니오',
        button: (
          <div>
            <PbButton
              label="예"
              backgroundColor="purple"
              size="sm"
              onClick={() => {
                setRemoveModal(true);

                // Shop 별 설치 작업결과 저장 을 처리함
                nstlApi
                  .putNstlWorkNtwr({
                    dcmnDntfNmbr: dcmnDntfNmbr,
                    installWorkNtwrParamList: swInfoTable.data,
                    nstlSttsCode: workStateSelect.value,
                    splrId: loadSplrId,
                    teamId: loadTeamId,
                  })
                  .then((data: IApiResult) => {
                    if (data.code === '200') {
                      setModal({
                        title: '알림',
                        content: '저장하였습니다.',
                        callback: () => {
                          // 목록 새로고침을 추가함
                          setAddRefreshList('MN2302220352Table');

                          // 컴포넌트 모달을 닫음
                          setRemoveComponentModal(id);
                        },
                      });
                    } else {
                      console.log(
                        '> 설치 > Shop별 설치 일정계획 수정 Error:',
                        data,
                      );

                      setModal({
                        title: '오류',
                        content: '저장에 실패하였습니다.',
                      });
                    }
                  })
                  .catch((error: any) => {
                    console.log(
                      '> 설치 > Shop별 설치 일정계획 수정 Error:',
                      error,
                    );

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  });
              }}
            />
          </div>
        ),
      });
    }
  };

  // splrId 에 따라서 화면을 다시 로드한다.
  const initNetwork = () => {
    initWorkContSelect(); //작업 내용 셀렉트 초기화
    initWorkStateSelect(); //작업 상태 셀렉트 초기화

    initSwInfoTable(); //소프트웨어 정보 테이블 초기화

    getNstlWorkNtwr();
  };
  /* //  */

  /**************************** USE EFFECT ****************************/

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    loadSplrId = splrId;
    loadTeamId = teamId;

    return () => {};
  }, []);

  // Network 정보 변경시
  useEffect(() => {
    // Network 작업 정보 수정을 위한 javascript 영역 변수에 복사
    valSwInfoListTable = _.cloneDeep(swInfoTable.data);
    return () => {};
  }, [swInfoTable.data]);

  useEffect(() => {
    if (_.isEmpty(loginUser.id)) {
      return;
    }

    if (loginUser.nstlCtgrCode === 'WORK_NSPC') {
      // 작업 검수자
      setIsWorkNspc(true);
      isWorkNspcRef.current = true;
    } else if (loginUser.nstlCtgrCode === 'PM_NSPC') {
      // PM 검수자
      setIsPmNspc(true);
      isPmNspcRef.current = true;
    } else if (loginUser.nstlCtgrCode === 'NTWR_PRTN') {
      // 네트워크 협력사
      setIsNtwrPrtn(true);
      isNtwrPrtnRef.current = true;
    } else if (loginUser.nstlCtgrCode === 'NSTL_PRTN') {
      // ESL 협력사
      setIsNstlPrtn(true);
      isNstlPrtnRef.current = true;
    }

    initNetwork(); // 화면 정보 데이타 초기화
  }, [loginUser.id]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  return (
    <>
      <div className="space-y-7">
        <div className="space-y-5">
          <PbFormGrid id="step-1" className="mb-2" label="" cols={2}>
            <PbFormGridCol label="작업 내역" colSpan={2}>
              <PbSelect
                onChange={handleWorkContSelect_onChange}
                data={workContSelect.item}
                value={workContSelect.value}
                setSelect={setWorkContSelect}
                className="w-80"
              />
              <div className="flex justify-center items-center space-x-2 w-full">
                <div className="right-btn">
                  <Button
                    variant="outline"
                    color="gray"
                    radius="xl"
                    onClick={handleMoveDetailBtn_onClick}
                    hidden={true}
                  >
                    Shop별 설치 일정 계획 상세 이동
                  </Button>
                </div>
              </div>
            </PbFormGridCol>
            <PbFormGridCol label="작업 상태" colSpan={2}>
              <PbSelect
                onChange={handleWorkStateSelect_onChange}
                data={workStateSelect.item}
                value={workStateSelect.value}
                setSelect={setWorkStateSelect}
                className="w-80"
              />
            </PbFormGridCol>
          </PbFormGrid>

          <PbFormGrid
            id="step-2"
            className="mb-2"
            label="프로젝트 정보"
            cols={2}
          >
            <PbFormGridCol label="프로젝트 명">
              <TextInput
                placeholder=""
                value={projectNameInput}
                readOnly={true}
                variant={'unstyled'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="고객사 명">
              <TextInput
                placeholder=""
                value={customerNameInput}
                readOnly={true}
                variant={'unstyled'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Shop 명">
              <TextInput
                placeholder=""
                value={shopNameInput}
                readOnly={true}
                variant={'unstyled'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="주문 번호">
              <TextInput
                placeholder=""
                value={orderNoInnput}
                readOnly={true}
                variant={'unstyled'}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          <PbFormGrid id="step-3" className="mb-2" label="작업 정보" cols={2}>
            <PbFormGridCol label="담당 협력사(팀)">
              <TextInput
                placeholder=""
                value={prtnTeamNameInput}
                readOnly={true}
                variant={'unstyled'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="작업 일정">
              <TextInput
                placeholder=""
                value={workDateInput}
                readOnly={true}
                variant={'unstyled'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="작업 상태" colSpan={2}>
              <TextInput
                placeholder=""
                value={workStatusInput}
                readOnly={true}
                variant={'unstyled'}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          <div className="content-wrapper">
            <div className="title-wrapper mt-1">
              <h3 className="leading-none">
                <span className="text-lg text-gray-600 font-semibold">
                  소프트웨어 정보
                </span>
              </h3>
              <div className="right-btn !space-x-2">
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleRmSwInfoBtn_onClick}
                >
                  - 행 삭제
                </Button>
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleAddSwInfoBtn_onClick}
                >
                  + 행 추가
                </Button>
              </div>
            </div>
            <div className="w-full h-44">
              <PbAgGridReact
                refs={swInfoTableRef}
                columnDefs={swInfoTable.column}
                rowData={swInfoTable.data}
                defaultColDef={{
                  resizable: true,
                  sortable: false,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                sizeColumnsToFit={true}
                rowSelection="single"
                overlayNoRowsTemplate="데이터가 없습니다."
                className="ag-theme-alpine"
              />
            </div>
          </div>

          {/* 댓글 섹션 */}
          {apiData !== null && (
            <PbSection id="step-4" label="댓글">
              <div className="space-y-3">
                {apiData.prgrRsltList.map((item: any, index: number) => (
                  <div
                    key={index}
                    className="pb-1 border-1 border-gray-200 rounded-md space-y-3"
                  >
                    {/* 댓글 기본 정보 */}
                    <div className="px-1">
                      {/* 줄 공백 */}
                      <div className="h-2" />

                      <div className="flex justify-between items-center">
                        <div className="flex justify-center items-center space-x-3">
                          {/* 설치 카테고리 */}
                          {item.nstlCtgrName !== '' && (
                            <span className="text-base text-orange-600 font-bold">
                              {item.nstlCtgrName}
                            </span>
                          )}

                          {/* 작성자 */}
                          <span className="text-base text-blue-600 font-bold">
                            {item.name}
                          </span>

                          {/* 작성일시 */}
                          <span className="text-xs text-gray-600">
                            {item.rgstDate}
                          </span>
                        </div>

                        <div className="flex justify-center items-center">
                          {/* 댓글수정 */}
                          <Button
                            color="indigo"
                            radius="xl"
                            size="xs"
                            // onClick={() => handleModifyButton_onClick(item)}
                          >
                            수정
                          </Button>
                        </div>
                      </div>
                    </div>

                    {/* 댓글 내용 */}
                    <div className="mx-1 p-1 bg-gray-100 rounded-md">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.dtl.replaceAll('\r\n', '<br />'),
                        }}
                      />
                    </div>

                    {/* 첨부파일: 이미지 */}
                    {item.fileList.filter(
                      (filterItem: any) => filterItem.fileDntfCode === 'PCTR',
                    ).length > 0 && (
                      <div className="px-1 flex flex-wrap gap-1">
                        {item.fileList
                          .filter(
                            (filterItem: any) =>
                              filterItem.fileDntfCode === 'PCTR',
                          )
                          .map((file: any, index: number) => (
                            <div
                              key={index}
                              className="border-1 border-gray-200 rounded-md overflow-hidden"
                            >
                              <img
                                key={index}
                                src={[
                                  appUtil.getThumbnailImageUrl(),
                                  file.pldFileName,
                                ].join('')}
                                onClick={() => {
                                  let tmpFileList: any[] = [];

                                  item.fileList
                                    .filter(
                                      (subFile: any) =>
                                        subFile.fileDntfCode === 'PCTR',
                                    )
                                    .map((subFile: any) => {
                                      tmpFileList.push(
                                        [
                                          appUtil.getThumbnailImageUrl(),
                                          subFile.pldFileName,
                                        ].join(''),
                                      );
                                    });

                                  // 이미지 캐러셀 저장소에 적용함
                                  setImageCarousel({
                                    imageList: tmpFileList,
                                    startIndex: index,
                                  });
                                }}
                                className="button-event w-20 h-20"
                              />
                            </div>
                          ))}
                      </div>
                    )}

                    {/* 첨부파일: 기타 */}
                    {item.fileList.filter(
                      (filterItem: any) =>
                        filterItem.fileDntfCode !== 'PCTR' ||
                        filterItem.fileDntfCode === undefined,
                    ).length > 0 && (
                      <div className="px-2 pt-0.5">
                        {item.fileList
                          .filter(
                            (filterItem: any) =>
                              filterItem.fileDntfCode !== 'PCTR' ||
                              filterItem.fileDntfCode === undefined,
                          )
                          .map((file: any, index: number) => (
                            <>
                              {index > 0 && (
                                <div className="mt-1 mb-1 h-px border-t border-gray-100" />
                              )}
                              <div
                                key={index}
                                onClick={() =>
                                  handleFileDownload_onClick(
                                    file.fileId,
                                    file.rgnlFileName,
                                  )
                                }
                                // className="button-event px-1 h-12 flex justify-start items-center bg-gray-100 rounded space-y-1"
                                className="button-event flex justify-start items-center"
                              >
                                <div className="flex justify-center items-center space-x-3">
                                  <div className="flex justify-center items-center">
                                    <FontAwesomeIcon
                                      icon={['far', 'file']}
                                      className="w-6 h-6 text-gray-500"
                                    />
                                  </div>

                                  <div className="space-y-1">
                                    {/* 파일명 */}
                                    <div className="leading-none">
                                      <span className="text-base text-gray-600 font-semibold">
                                        {file.rgnlFileName}
                                      </span>
                                    </div>

                                    {/* 용량 */}
                                    <div className="leading-none">
                                      <span className="text-sm text-gray-400">
                                        {prettyBytes(_.toNumber(file.fileSize))}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </PbSection>
          )}
        </div>

        {/* 컴포넌트 모달 버튼 */}
        <div className="component-modal-button-area">
          {/* 수정 */}
          <div>
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>
          </div>
          {/* <div>
          <Button
            variant="outline"
            color="pink"
            radius="xl"
            size="md"
            // onClick={handleDeleteBtn_onClick}
          >
            삭제
          </Button>
        </div> */}
          <div>
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleModifySaveBtn_onClick}
            >
              저장
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MN2302220352_Network;
