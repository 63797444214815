import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Popover,
  Progress,
  Select,
  Tabs,
  TextInput,
} from '@mantine/core';
import { DatePickerInput, MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IModal,
  IComponentModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { pageContentStore } from '../stores/page.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { addComponentModalStore } from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { Radio, Group, Checkbox } from '@mantine/core';
import { PageLayout } from '../components/PageLayout';
import { PbFullCalendar } from '../components/PbFullCalendar';
import * as nstlApi from '../apis/nstl.api';
import * as cmnApi from '../apis/cmn.api';
import * as sttnApi from '../apis/sttn.api';
import MN2302220202 from '../components/MN2302220202/MN2302220202.component';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { PbFormGrid } from '../components/PbFormGrid';
import * as csApi from '../apis/cs.api';
import { MN2302220302_Days } from '../components/MN2302220302_Days';
import MN2302220352 from '../components/MN2302220352/MN2302220352.component';
import { PbAgGridReact } from '../components/PbAgGridReact';
import PbSelect from '../components/PbSelect/PbSelect.component';
import MN2302220351 from '../components/MN2302220351/MN2302220351.component';
import { PbHelpPopover } from '../components/PbHelpPopover';
import PbSection from '../components/PbSection/PbSection.component';
import { useDisclosure } from '@mantine/hooks';

/**
 * 설치 관리 > 진행중 작업 관리
 * @constructor
 */

// 검색 폼 그리드 > 프로젝트 > 입력을 정의함
// let valProjectSelect: string = '';

const MN2302220302Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 검색 폼 그리드 > 프로젝트 > 셀렉트를 정의함
  const [projectSelect, setProjectSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 설치상태 > 라디오를 정의함
  const [installStatusRadio, setInstallStatusRadio] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 작업유형 > 라디오를 정의함
  const [workTypeRadio, setWorkTypeRadio] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 작업유형 > ESL
  const [isEslNstl, setIsEslNstl] = useState<string>('N');
  // 작업유형 > 네트워크
  const [isNtwrCnst, setIsNtwrCnst] = useState<string>('N');

  // 네트워크 / ESL 설치의 경우 일부 기능 제한
  const [funcHiddenFlag, setFuncHiddenFlag] = useState<boolean>(true);

  // 선택한 탭을 정의함
  const [selectedTab, setSelectedTab] = useState<string>('list');

  // 캘린더 이벤트를 정의함
  const [calendarEvent, setCalendarEvent] = useState<any[]>([]);

  // 캘린더 이벤트 테이블을 정의함
  const [calendarEventTable, setCalendarEventTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 캘린더 이벤트 테이블 페이징을 정의함
  const [calendarEventTablePaging, setCalendarEventTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const calendarEventTablePagingCurrentPageRef = useRef<number>(1);

  // 캘린더 이벤트 테이블의 로딩 여부를 정의함
  const [calendarEventTableLoading, setCalendarEventTableLoading] =
    useState<boolean>(false);

  // 캘린더의 검색한 년월을 정의함
  const [searchYm, setSearchYm] = useState<string>(
    moment(new Date()).format('YYYYMM'),
  );

  // ESL 진척현황 데이저 저장소
  const [eslStatus, setEslStatus] = useState<{
    billPcntg: string; // 진척도 billing
    blngCmplCnt: string; // 설치완료 - Billing 완료
    blngNcmpCnt: string; // 설치완료 - Billing 미완료
    cmplPcntg: string; // 진척도 실적
    planCmplCnt: string; // 설치 미완료 - 계획 수립
    planNcmpCnt: string; // 설치 미완료 - 계획 미수립
    totalCnt: string; // 전체
    totalPrjcEnd: string; // 전체 프로젝트 일정 - 종료일
    totalPrjcStrt: string; // 전체 프로젝트 일정 - 시작일
  }>();

  // 검색 폼 그리드 > 프로젝트 > 셀렉트의 아이템을 초기화함
  const initProjectSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // CS 등록 - 프로젝트명 > 셀렉트박스를 불러옴
    csApi.getPrjcs({}).then((data: IApiResult) => {
      data.data.list.map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.prjcName,
          value: item.prjcCode,
        });
      });

      // 검색 폼 그리드 > 프로젝트 > 셀렉트의 아이템에 적용함
      setProjectSelect({
        value: '',
        item: tmpOptionItem,
      });

      // 검색 폼 그리드 > 프로젝트 > 셀렉트를 초기화함
      // valProjectSelect = '';
    });

    // valProjectSelect = '';
  };

  // 검색 폼 그리드 > 설치상태 > 라디오의 아이템을 초기화함
  const initInstallStatusRadio = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '진행중',
        value: 'prcd',
      },
      {
        label: '검수완료',
        value: 'nspc_cmpl',
      },
      {
        label: '지연',
        value: 'dly',
      },
    );

    // 검색 폼 그리드 > 설치상태 > 라디오의 아이템에 적용함
    setInstallStatusRadio((pre: ISelect) => ({
      value: 'prcd',
      item: tmpOptionItem,
    }));
  };

  // 검색 폼 그리드 > 설치상태 > 라디오의 아이템을 초기화함
  const initWorkTypeRadio = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'SPLR_TYPE',
      })
      .then((data: IApiResult) => {
        data.data.list.map((item: any, index: number) => {
          tmpOptionItem.push({
            label: item.dtlCodeDscr,
            value: item.dtlCodeId,
          });
        });

        // 검색 폼 그리드 > 작업유형 > 라디오의 아이템에 적용함
        setWorkTypeRadio((pre: ISelect) => ({
          value: '',
          item: tmpOptionItem,
        }));
      });

    // 검색 폼 그리드 > 작업유형 > 라디오의 아이템에 적용함
    setWorkTypeRadio((pre: ISelect) => ({
      value: '',
      item: tmpOptionItem,
    }));
  };

  /* 담당PM */
  // 검색 폼 그리드  > 담당 PM 셀렉트를 정의함
  const [pmNameSelect, setPmNameSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 담당 PM 셀렉트에서 선택한 아이템을 변경함
  const handlePmNameSelect_onChange = (event: any) => {
    setPmNameSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템을 초기화함
  const initPmNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: 'all',
      },
      {
        label: '담당자 없음',
        value: '',
      },
    );

    // 판매 계약 담당PM 목록을 불러옴
    sttnApi.getSttnErpSearchPm({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.name,
          value: item.userId,
        });
      });

      // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템에 적용함
      setPmNameSelect((pre: ISelect) => ({
        value: 'all',
        item: tmpOptionItem,
      }));
    });
  };

  // 진행중작업목록 테이블을 초기화함
  const initCalendarEventTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '프로젝트명',
        field: 'prjcName',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '계약명',
        field: 'cntrName',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '고객사명',
        field: 'bpName',
      },
      {
        headerName: '설치상태',
        field: 'nstlSttsName',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: '담당 PM',
        field: 'pmName',
      },
      {
        headerName: '작업일정',
        children: [
          {
            headerName: '시작일시',
            field: 'workStrtDttm',
            width: 190,
            suppressSizeToFit: true,
          },
          {
            headerName: '종료일시',
            field: 'workEndDttm',
            width: 190,
            suppressSizeToFit: true,
          },
        ],
      },
    );

    // 진행중작업목록 테이블에 적용함
    setCalendarEventTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 진행중작업목록 테이블의 데이터를 불러옴
  const getCalendarEventTableData = () => {
    // 진행중작업목록 테이블의 로딩 여부에 적용함
    setCalendarEventTableLoading(true);

    nstlApi
      .getPrcds({
        isShowAll: 'Y',
        nstlSttsCode: installStatusRadio.value || '',
        isEslNstl: isEslNstl,
        isNtwrCnst: isNtwrCnst,
        prjcCode: projectSelect.value || '',
        workBaseYearMonth: '',
        cmpnCode: loginUser.cmpnCode,
        userId: loginUser.id,
        pmId: pmNameSelect.value, // 담당PM
        pageSize: calendarEventTablePaging.rowPerPage,
        currPageIdx: calendarEventTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 진행중작업목록 테이블에 적용함
          setCalendarEventTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));

          // 테이블을 페이징함
          setCalendarEventTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }

        // 진행중작업목록 테이블의 로딩 여부에 적용함
        setCalendarEventTableLoading(false);
      })
      .catch((error: any) => {
        // 진행중작업목록 테이블의 로딩 여부에 적용함
        setCalendarEventTableLoading(false);
      });
  };

  // 진행중작업목록(캘린더용) 데이터를 불러옴
  const getCalendarEventData = () => {
    // 진행중작업목록(캘린더용) 적용함
    setCalendarEvent([]);

    nstlApi
      .getPrcds({
        isShowAll: 'N',
        nstlSttsCode: installStatusRadio.value || '',
        isEslNstl: isEslNstl,
        isNtwrCnst: isNtwrCnst,
        prjcCode: projectSelect.value || '',
        workBaseYearMonth: searchYm,
        cmpnCode: loginUser.cmpnCode,
        userId: loginUser.id,
        pmId: pmNameSelect.value, // 담당PM
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          let tmpCalendarEvent: any[] = [];

          // 이벤트의 색상을 정의함
          const eventBgColor: { [index: string]: string } = {
            prcd: 'bg-indigo-500 text-white',
            work_cmpl: 'bg-gray-500 text-white',
            nspc_cmpl: 'bg-gray-600 text-white',
            dly: 'bg-pink-600 text-white',
          };

          // 캘린더 이벤트를 추가함
          data.data.list.map((item: any) => {
            tmpCalendarEvent.push({
              title: `<span class="px-0.5 pt-0.5">${item.nstlSttsName} | ${
                item.shipToCode || 'Shop 미지정'
              }</span>`,
              start: moment(item.workStrtDttm).format('YYYY-MM-DD'),
              end: moment(item.workEndDttm).add(1, 'd').format('YYYY-MM-DD'),
              classNames: [
                'border-t border-b border-white',
                eventBgColor[item.nstlSttsCode.toLowerCase()],
              ].join(' '),
              extendedProps: {
                item: item,
              },
            });
          });

          // 진행중작업목록(캘린더용) 적용함
          setCalendarEvent(tmpCalendarEvent);
        }
      })
      .catch((error: any) => {});
  };

  // ESL 진척현황을 불러옴
  const getEslStatus = () => {
    // ESL 진척현황 불러오기
    sttnApi
      .getSttnEslPrtn({
        currPageIdx: 1,
        pageSize: 100000,
        cmplFlag: '',
        userId: loginUser.id,
        cmpnCode: loginUser.cmpnCode,
      })
      .then((data: IApiResult) => {
        // ESL 진척 현황 적용
        setEslStatus(data.data.cntVo);
      });
  };

  // 검색 폼 그리드 > 프로젝트 > 셀렉트에서 선택한 아이템을 변경함
  const handleProjectSelect_onChange = (event: any) => {
    // valProjectSelect = event;
    setProjectSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 설치상태 > 라디오에서 선택한 아이템을 변경함
  const handleInstallStatusRadio_onChange = (event: any) => {
    setInstallStatusRadio((pre: ISelect) => ({
      ...pre,
      value: event.target.value,
    }));
  };

  // 검색 폼 그리드 > 작업유형 > 라디오에서 선택한 아이템을 변경함
  const handleWorkTypeRadio_onChange = (event: any) => {
    if (_.isEqual(event.target.value, 'ESL_NSTL')) {
      setIsEslNstl('Y');
      setIsNtwrCnst('N');
    } else if (_.isEqual(event.target.value, 'NTWR_CNST')) {
      setIsEslNstl('N');
      setIsNtwrCnst('Y');
    } else {
      setIsEslNstl('Y');
      setIsNtwrCnst('Y');
    }

    setWorkTypeRadio((pre: ISelect) => ({
      ...pre,
      value: event.target.value,
    }));
  };

  // 탭을 변경함
  const handleTabs_onTabChange = (event: any) => {
    // 선택한 탭에 적용함
    setSelectedTab(event);
  };

  // 캘린더의 이전달, 다음달 버튼을 클릭함
  const handleCalendarMonthButton_onClick = (param: number) => {
    let tmpYm: string = '';

    if (param === 0) {
      // 오늘(이번달)로 이동함
      tmpYm = moment(new Date()).format('YYYYMM');
    } else {
      // 이전달, 다음달로 이동함
      tmpYm = moment(searchYm).add(param, 'months').format('YYYYMM');
    }

    // 검색한 년월에 적용함
    setSearchYm(tmpYm);
  };

  // 캘린더의 날짜를 클릭함
  const handleCalendar_onDateClick = (arg: any) => {
    let tmpId: string = `calendarEvent_${arg.dateStr}`;

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: moment(arg.dateStr).format('YYYY년 MM월 DD일'),
      content: (
        <MN2302220302_Days
          id={tmpId}
          searchYmd={moment(arg.dateStr).format('YYYYMMDD')}
        />
      ),
      size: 750,
      closeOnClickOutside: true,
    });
  };

  // 캘린더의 이벤트를 클릭함
  const handleCalendar_onEventClick = (arg: any) => {
    let tmpItem: any = arg.event.extendedProps.item;
    let tmpId: string = 'MN2302220352';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'Shop별 설치 일정 계획 상세',
      content: (
        <MN2302220352
          id={tmpId}
          dcmnDntfNmbr={tmpItem.dcmnDntfNmbr}
          useModifyMode={false}
        />
      ),
      size: 1500,
    });
  };

  const handleOngoingProjectTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220352';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: `Shop별 설치 일정 계획 상세 - ${row.data.shipToCode}`,
      content: (
        <MN2302220352
          id={tmpId}
          dcmnDntfNmbr={row.data.dcmnDntfNmbr}
          useModifyMode={false}
        />
      ),
      size: 1500,
    });
  };

  /**
   * 엑셀 다운로드
   */

  // 검색 결과 테이블의 엑셀 다운로드 버튼을 클릭함
  const handleOngoingProjectTableExportExcelButton_onClick = () => {
    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <>
          {/* 버튼 */}
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              nstlApi
                .getNstlPrcdExcel({
                  isShowAll: 'Y',
                  nstlSttsCode: installStatusRadio.value || '',
                  isEslNstl: isEslNstl,
                  isNtwrCnst: isNtwrCnst,
                  prjcCode: projectSelect.value || '',
                  workBaseYearMonth: '',
                  cmpnCode: loginUser.cmpnCode,
                  userId: loginUser.id,
                  pmId: pmNameSelect.value, // 담당PM
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    [
                      '진행중 작업 관리_',
                      moment().format('YYYYMMDD'),
                      '.xlsx',
                    ].join(''),
                  );
                  document.body.appendChild(link);
                  link.click();
                });

              setRemoveModal(true);
            }}
          >
            예
          </Button>
        </>
      ),
    });
  };

  /* // 엑셀 다운로드 */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 검색 폼 그리드 > 프로젝트 > 셀렉트의 아이템을 초기화함
    initProjectSelect();

    // 검색 폼 그리드 > 설치상태 > 라디오의 아이템을 초기화함
    initInstallStatusRadio();

    // 검색 폼 그리드 > 담당PM > 셀렉트의 아이템을 초기화함
    initPmNameSelect();

    return () => {};
  }, []);

  useEffect(() => {
    if (!loginUser.id) {
      return;
    }

    // 검수자/협력사 별로 명시적으로 표시함
    if (
      // _.isEqual(loginUser.thrtDvsnCode, 'MNGR') || // 관리자
      (_.isEqual(loginUser.nstlCtgrCode, 'WORK_NSPC') || // 작업검수자
        _.isEqual(loginUser.nstlCtgrCode, 'PM_NSPC')) && // PM 검수자
      !_.isEqual(loginUser.cmpnCode, 'PRTN')
    ) {
      // 검색 폼 그리드 > 작업유형 > 라디오의 아이템을 초기화함
      setFuncHiddenFlag(false); // 작업검수자, PM 검수자만 가능한 검색 기능 활성화 Flag
      initWorkTypeRadio();
      setIsEslNstl('Y'); // 진행중 목록에 협력사 구분 없어짐
      setIsNtwrCnst('Y');
    } else {
      // 네트워크 / ESL 설치 협력사는 유형 선택 제외
      if (_.isEqual(loginUser.cmpnCode, 'PRTN')) {
        // 협력사는 사용자 정보에 맞는 데이타만 검색 가능
        if (_.isEqual(loginUser.nstlCtgrCode, 'NSTL_PRTN')) {
          // 협력사라도 타 작업도 일단 확인은 가능
          setIsEslNstl('Y');
          setIsNtwrCnst('Y');
          // setIsEslNstl('Y');
          // setIsNtwrCnst('N');
          setFuncHiddenFlag(true);
        } else if (_.isEqual(loginUser.nstlCtgrCode, 'NTWR_PRTN')) {
          // setIsEslNstl('N');
          // 협력사라도 타 작업도 일단 확인은 가능
          setIsEslNstl('Y');
          setIsNtwrCnst('Y');
          // setIsNtwrCnst('Y');
          setFuncHiddenFlag(true);
        } else {
          setIsEslNstl('N');
          setIsNtwrCnst('N');
          setFuncHiddenFlag(true);
        }
      }
    }

    // 캘린더 이벤트 데이터를 불러옴
    getCalendarEventData();

    // 캘린더 이벤트 테이블을 초기화함
    initCalendarEventTable();

    return () => {};
  }, [
    loginUser.id,
    loginUser.cmpnCode,
    loginUser.thrtDvsnCode,
    loginUser.nstlCtgrCode,
  ]);

  // 캘린더 이벤트 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (calendarEventTable.column.length === 0) {
      return;
    }

    // 테이블의 페이지를 변경함
    calendarEventTablePagingCurrentPageRef.current = 1;
    setCalendarEventTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: calendarEventTablePagingCurrentPageRef.current,
    }));

    // 진행중 작업관리 테이블의 데이터를 불러옴
    getCalendarEventTableData();

    return () => {};
  }, [
    calendarEventTable.column,
    projectSelect.value,
    installStatusRadio.value,
    workTypeRadio.value,
    pmNameSelect.value,
  ]);

  // 년월이 변경될 때 실행함
  useEffect(() => {
    if (!loginUser.id) {
      return;
    }
    // 캘린더 이벤트의 데이터를 불러옴
    getCalendarEventData();

    // ESL 진척현황을 불러옴
    getEslStatus();

    return () => {};
  }, [
    loginUser.id,
    projectSelect.value,
    installStatusRadio.value,
    workTypeRadio.value,
    pmNameSelect.value,
    searchYm,
  ]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }
    if (_.indexOf(refreshList, 'MN2302220302Table') > -1) {
      // 테이블의 페이지를 변경함
      calendarEventTablePagingCurrentPageRef.current = 1;
      setCalendarEventTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: calendarEventTablePagingCurrentPageRef.current,
      }));

      // 진행중 작업관리 테이블의 데이터를 불러옴
      getCalendarEventTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220302Table');
    }

    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-5">
        {/* 검색 폼 그리드 */}
        <PbFormGrid cols={2}>
          <PbFormGridCol label="프로젝트">
            {/* 셀렉트 */}
            <PbSelect
              placeholder="검색어를 입력하시거나 목록을 선택하세요."
              onChange={handleProjectSelect_onChange}
              data={projectSelect.item}
              value={projectSelect.value}
              setSelect={setProjectSelect}
              className="w-full"
              searchable={true}
              clearable={true}
            />
          </PbFormGridCol>

          <PbFormGridCol label="설치상태">
            {/* 라디오 */}
            <Radio.Group name="installStatus" value={installStatusRadio.value}>
              <Group
                onChange={handleInstallStatusRadio_onChange}
                className="space-x-1"
              >
                {installStatusRadio.item.map((item: any, index: number) => (
                  <Radio key={index} value={item.value} label={item.label} />
                ))}
              </Group>
            </Radio.Group>
          </PbFormGridCol>
          <PbFormGridCol label="작업유형" isHidden={true}>
            <Radio.Group name="workType">
              <Group
                onChange={handleWorkTypeRadio_onChange}
                className="space-x-1"
              >
                {workTypeRadio.item.map((item: any, index: number) => (
                  <Radio key={index} value={item.value} label={item.label} />
                ))}
              </Group>
            </Radio.Group>
          </PbFormGridCol>
          <PbFormGridCol label="담당PM" colSpan={2} isHidden={funcHiddenFlag}>
            {/* 셀렉트 */}
            <Select
              onChange={handlePmNameSelect_onChange}
              data={pmNameSelect.item}
              value={pmNameSelect.value}
              className="w-full"
              placeholder="검색어를 입력하시거나 목록을 선택하세요."
              searchable={true}
              clearable={true}
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 진척 현황 */}
        <PbSection label="ESL 설치작업 진척 현황" className="mt-2">
          <div className="h-full flex justify-between items-center space-x-5">
            {/* 왼쪽 테이블 */}
            <div className="w-3/5">
              <div className="table-wrapper">
                <table className="table-list">
                  <colgroup className="mobile-none">
                    <col />
                    <col />
                    <col />
                  </colgroup>

                  <thead>
                    <tr>
                      <th rowSpan={2} className="w-1/5 border-r leading-none">
                        <div className="flex justify-center items-center space-x-2">
                          <span>전체</span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              해당 프로젝트의 전체 주문 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        colSpan={2}
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <span>
                          설치
                          <br />
                          미완료
                        </span>
                      </th>
                      <th
                        colSpan={2}
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 leading-5"
                      >
                        <span>
                          설치
                          <br />
                          완료
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            계획
                            <br />
                            미수립
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              EMS에 작업 일정 및 협력사를 등록했지만, PM 검수가
                              완료되지 않은 주문 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            계획
                            <br />
                            수립
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              EMS에 작업 일정 및 협력사를 등록하지 않은 주문
                              건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            Billing
                            <br />
                            미완료
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              PM 검수 완료 처리가 된 주문 중, 빌링 처리가 되지
                              않은 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            Billing
                            <br />
                            완료
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              PM 검수 완료 처리가 된 주문 중, 빌링 처리까지
                              완료된 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td
                        // onClick={() =>
                        //   handleSetupStatusCnt_onClick('')
                        // }
                        className="flex-none h-12"
                      >
                        <span>{eslStatus?.totalCnt}</span>
                      </td>
                      <td
                        // onClick={() =>
                        //   handleSetupStatusCnt_onClick('plan_ncmp')
                        // }
                        className="flex-none h-12"
                      >
                        <span>{eslStatus?.planNcmpCnt}</span>
                      </td>
                      <td
                        // onClick={() =>
                        //   handleSetupStatusCnt_onClick('plan_cmpl')
                        // }
                        className="flex-none h-12"
                      >
                        <span>{eslStatus?.planCmplCnt}</span>
                      </td>
                      <td
                        // onClick={() =>
                        //   handleSetupStatusCnt_onClick('blng_ncmp')
                        // }
                        className="flex-none h-12"
                      >
                        <span>{eslStatus?.blngNcmpCnt}</span>
                      </td>
                      <td
                        // onClick={() =>
                        //   handleSetupStatusCnt_onClick('blng_cmpl')
                        // }
                        className="flex-none h-12"
                      >
                        <span>{eslStatus?.blngCmplCnt}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* 오른쪽 테이블 */}
            <div className="w-2/5">
              <div className="table-wrapper">
                <table className="table-list">
                  <colgroup className="mobile-none">
                    <col />
                    <col />
                    <col />
                  </colgroup>

                  <thead>
                    <tr>
                      <th rowSpan={2} className="w-1/5 border-r leading-none">
                        <span>진척도</span>
                      </th>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-1/5 !bg-white leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>실적</span>

                          <PbHelpPopover width={250}>
                            <span className="text-sm">
                              (빌링미완료 + 빌링완료) / 전체
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-3/5 !bg-white leading-none"
                      >
                        <div className="w-full pr-5 flex justify-center items-center">
                          {/* 차트 */}
                          <div className="grow">
                            <Progress
                              size="xl"
                              radius="xs"
                              color="indigo"
                              value={
                                isNaN(
                                  _.toNumber(
                                    _.toNumber(eslStatus?.totalCnt) !== 0
                                      ? _.multiply(
                                          _.divide(
                                            _.add(
                                              _.toNumber(
                                                eslStatus?.blngCmplCnt,
                                              ),
                                              _.toNumber(
                                                eslStatus?.blngNcmpCnt,
                                              ),
                                            ),
                                            _.toNumber(eslStatus?.totalCnt),
                                          ),
                                          100,
                                        ).toFixed(1)
                                      : 0,
                                  ),
                                )
                                  ? 0
                                  : _.toNumber(
                                      _.toNumber(eslStatus?.totalCnt) !== 0
                                        ? _.multiply(
                                            _.divide(
                                              _.add(
                                                _.toNumber(
                                                  eslStatus?.blngCmplCnt,
                                                ),
                                                _.toNumber(
                                                  eslStatus?.blngNcmpCnt,
                                                ),
                                              ),
                                              _.toNumber(eslStatus?.totalCnt),
                                            ),
                                            100,
                                          ).toFixed(1)
                                        : 0,
                                    )
                              }
                            />
                          </div>

                          {/* 퍼센트 */}
                          <div className="flex-none w-14 flex justify-end items-center">
                            <span>
                              {isNaN(
                                _.toNumber(
                                  _.toNumber(eslStatus?.totalCnt) !== 0
                                    ? _.multiply(
                                        _.divide(
                                          _.add(
                                            _.toNumber(eslStatus?.blngCmplCnt),
                                            _.toNumber(eslStatus?.blngNcmpCnt),
                                          ),
                                          _.toNumber(eslStatus?.totalCnt),
                                        ),
                                        100,
                                      ).toFixed(1)
                                    : 0,
                                ),
                              )
                                ? 0
                                : _.toNumber(
                                    _.toNumber(eslStatus?.totalCnt) !== 0
                                      ? _.multiply(
                                          _.divide(
                                            _.add(
                                              _.toNumber(
                                                eslStatus?.blngCmplCnt,
                                              ),
                                              _.toNumber(
                                                eslStatus?.blngNcmpCnt,
                                              ),
                                            ),
                                            _.toNumber(eslStatus?.totalCnt),
                                          ),
                                          100,
                                        ).toFixed(1)
                                      : 0,
                                  )}
                              %
                            </span>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-1/5 !bg-white leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>Billing</span>

                          <PbHelpPopover width={250}>
                            <span className="text-sm">
                              빌링완료 / (빌링미완료 + 빌링완료)
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-3/5 !bg-white leading-none"
                      >
                        <div className="w-full pr-5 flex justify-center items-center">
                          {/* 차트 */}
                          <div className="grow">
                            <Progress
                              size="xl"
                              radius="xs"
                              color="lime"
                              value={
                                isNaN(
                                  _.toNumber(
                                    _.add(
                                      _.toNumber(eslStatus?.blngCmplCnt),
                                      _.toNumber(eslStatus?.blngNcmpCnt),
                                    ) !== 0
                                      ? _.multiply(
                                          _.divide(
                                            _.toNumber(eslStatus?.blngCmplCnt),
                                            _.add(
                                              _.toNumber(
                                                eslStatus?.blngCmplCnt,
                                              ),
                                              _.toNumber(
                                                eslStatus?.blngNcmpCnt,
                                              ),
                                            ),
                                          ),
                                          100,
                                        ).toFixed(1)
                                      : 0,
                                  ),
                                )
                                  ? 0
                                  : _.toNumber(
                                      _.add(
                                        _.toNumber(eslStatus?.blngCmplCnt),
                                        _.toNumber(eslStatus?.blngNcmpCnt),
                                      ) !== 0
                                        ? _.multiply(
                                            _.divide(
                                              _.toNumber(
                                                eslStatus?.blngCmplCnt,
                                              ),
                                              _.add(
                                                _.toNumber(
                                                  eslStatus?.blngCmplCnt,
                                                ),
                                                _.toNumber(
                                                  eslStatus?.blngNcmpCnt,
                                                ),
                                              ),
                                            ),
                                            100,
                                          ).toFixed(1)
                                        : 0,
                                    )
                              }
                            />
                          </div>

                          {/* 퍼센트 */}
                          <div className="flex-none w-14 flex justify-end items-center">
                            <span>
                              {isNaN(
                                _.toNumber(
                                  _.add(
                                    _.toNumber(eslStatus?.blngCmplCnt),
                                    _.toNumber(eslStatus?.blngNcmpCnt),
                                  ) !== 0
                                    ? _.multiply(
                                        _.divide(
                                          _.toNumber(eslStatus?.blngCmplCnt),
                                          _.add(
                                            _.toNumber(eslStatus?.blngCmplCnt),
                                            _.toNumber(eslStatus?.blngNcmpCnt),
                                          ),
                                        ),
                                        100,
                                      ).toFixed(1)
                                    : 0,
                                ),
                              )
                                ? 0
                                : _.toNumber(
                                    _.add(
                                      _.toNumber(eslStatus?.blngCmplCnt),
                                      _.toNumber(eslStatus?.blngNcmpCnt),
                                    ) !== 0
                                      ? _.multiply(
                                          _.divide(
                                            _.toNumber(eslStatus?.blngCmplCnt),
                                            _.add(
                                              _.toNumber(
                                                eslStatus?.blngCmplCnt,
                                              ),
                                              _.toNumber(
                                                eslStatus?.blngNcmpCnt,
                                              ),
                                            ),
                                          ),
                                          100,
                                        ).toFixed(1)
                                      : 0,
                                  )}
                              %
                            </span>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </PbSection>

        {/* 탭 */}
        <Tabs
          variant="outline"
          onTabChange={handleTabs_onTabChange}
          value={selectedTab}
        >
          <Tabs.List>
            <Tabs.Tab
              value="list"
              icon={
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'list']}
                    className="w-4 h-4 text-gray-600"
                  />
                </div>
              }
            >
              <span className="text-lg">리스트</span>
            </Tabs.Tab>
            <Tabs.Tab
              value="calendar"
              icon={
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['far', 'calendar']}
                    className="w-4 h-4 text-gray-600"
                  />
                </div>
              }
            >
              <span className="text-lg">캘린더</span>
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>

        {/* 선택한 탭에 따라 내용 변경 */}
        <div className="relative">
          <div
            className={[
              'absolute left-0 top-0 w-full h-152 space-y-3',
              selectedTab === 'list' ? 'z-30' : 'hidden',
            ].join(' ')}
          >
            <div className="flex justify-between items-center space-x-5">
              <div>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleOngoingProjectTableExportExcelButton_onClick}
                >
                  엑셀 다운로드
                </Button>
              </div>
              <div className="flex justify-center items-center">
                <span className="text-base text-gray-600 font-bold">
                  총 {calendarEventTablePaging.totalRow}건
                </span>
              </div>
            </div>
            <div className="w-full h-172">
              <PbAgGridReact
                columnDefs={calendarEventTable.column}
                rowData={calendarEventTable.data}
                loading={calendarEventTableLoading}
                setLoading={setCalendarEventTableLoading}
                onRowDoubleClicked={
                  handleOngoingProjectTable_onRowDoubleClicked
                }
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
                visiblePaging={true}
                paging={{
                  totalPage: calendarEventTablePaging.totalPage,
                  currentPage: calendarEventTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  calendarEventTablePagingCurrentPageRef.current = event;
                  setCalendarEventTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: calendarEventTablePagingCurrentPageRef.current,
                  }));

                  // 테이블 데이터를 불러옴
                  getCalendarEventTableData();
                }}
              />
            </div>
          </div>

          {/* 흰색으로 영역 덮음 */}
          {selectedTab !== 'calendar' && (
            <div className="absolute left-0 top-0 w-full h-full bg-white z-20" />
          )}
          {/* 풀캘린더 */}
          <div className="space-y-2">
            {/* 년월 정보 */}
            <div className="flex justify-between items-center">
              {/* 왼쪽 영역 */}
              <div className="w-40 flex justify-start items-center"></div>

              {/* 가운데 영역 */}
              <div className="flex justify-center items-center">
                {/* 이전달 */}
                <div
                  onClick={() => handleCalendarMonthButton_onClick(-1)}
                  className="button-event px-1 py-1 flex justify-center items-center"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-left']}
                    className="w-5 h-5 text-gray-400"
                  />
                </div>

                {/* 년월 */}
                <div className="pt-0.5 leading-none">
                  <span className="text-3xl font-bold leading-none">
                    {moment(searchYm).format('YYYY.MM')}
                  </span>
                </div>

                {/* 다음달 */}
                <div
                  onClick={() => handleCalendarMonthButton_onClick(1)}
                  className="button-event px-1 py-1 flex justify-center items-center"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'chevron-right']}
                    className="w-5 h-5 text-gray-400"
                  />
                </div>
              </div>

              {/* 오른쪽 영역 */}
              <div className="w-40 flex justify-end items-center">
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  size="md"
                  onClick={() => handleCalendarMonthButton_onClick(0)}
                >
                  오늘
                </Button>
              </div>
            </div>

            {/* 풀캘린더 */}
            <PbFullCalendar
              date={searchYm}
              events={calendarEvent}
              setEvents={setCalendarEvent}
              dateClick={handleCalendar_onDateClick}
              eventClick={handleCalendar_onEventClick}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220302Page;
