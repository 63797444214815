import React, { PropsWithChildren, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  IComponentModalPageScrollTab,
  IComponentModalPageScrollTabStep,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import _ from 'lodash';
import { PbTable } from '../PbTable';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import * as sttnApi from '../../apis/sttn.api';
import { PbInputNumber } from '../PbInputNumber';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  Group,
  NumberInput,
  Select,
  Tabs,
  TextInput,
} from '@mantine/core';
import { Radio } from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import PbSelect from '../PbSelect/PbSelect.component';
import * as appUtil from '../../utils/app.util';

interface IComponentProps {
  id?: string;
  cntrNmbr?: string;
  useModifyMode?: boolean;
}

/**
 * 현황 관리 > ERP 판매 계약 현황 > 상세 , 수정
 * @param id 컴포넌트 모달 아이디
 * @param cntrNmbr 계약 번호
 * @param useModifyMode 수정 모드 사용 여부
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220451 = ({
  id = '',
  cntrNmbr = '',
  useModifyMode = false,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 계약 정보
   */

  // 계약 정보 > 프로젝트명 입력을 정의함
  const [prjcNameInput, setPrjcNameInput] = useState<string>('');

  // 계약 정보 > 판매 계약번호명 입력을 정의함
  const [cntrNmbrInput, setCntrNmbrInput] = useState<string>('');

  // 계약 정보 > 프로젝트 코드 입력을 정의함
  const [prjcCodeInput, setPrjcCodeInput] = useState<string>('');

  // 계약 정보 > 프로젝트 일정 입력을 정의함
  const [fctvDateInput, setFctvDateInput] = useState<string>('');

  // 계약 정보 > 계약 진행 상황 입력을 정의함
  const [cntrPrgrNameInput, setCntrPrgrNameInput] = useState<string>('');

  /* // 계약 정보 */

  /**
   * 고객사 정보
   */

  // 고객사 정보 > 고객사명 입력을 정의함
  const [bpNameInput, setBpNameInput] = useState<string>('');

  // 고객사 정보 > 고객사 코드 입력을 정의함
  const [bpCodeInput, setBpCodeInput] = useState<string>('');

  /* // 고객사 정보 */

  /**
   * 담당자 정보
   */

  // 담당자 정보 > 담당 PM 상세 Input Text 정의함
  const [pmNameInput, setPmNameInput] = useState<string>('');

  // 담당자 정보 > 담당 PM 셀렉트를 정의함
  const [pmNameSelect, setPmNameSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 담당자 정보 > 담당 PM 셀렉트에서 선택한 아이템을 변경함
  const handlePmNameSelect_onChange = (event: any) => {
    setPmNameSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    if (!cntrNmbr) {
      // 신규
      tmpTab = [
        {
          label: '계약',
          targetId: 'step-1',
        },
        {
          label: '고객사',
          targetId: 'step-2',
        },
        {
          label: '담당자',
          targetId: 'step-3',
        },
      ];
    } else if (cntrNmbr && !useModifyMode) {
      // 조회
      tmpTab = [
        {
          label: '계약',
          targetId: 'step-1',
        },
        {
          label: '고객사',
          targetId: 'step-2',
        },
        {
          label: '담당자',
          targetId: 'step-3',
        },
      ];
    } else if (cntrNmbr && useModifyMode) {
      // 수정
      tmpTab = [
        {
          label: '계약',
          targetId: 'step-1',
        },
        {
          label: '고객사',
          targetId: 'step-2',
        },
        {
          label: '담당자',
          targetId: 'step-3',
        },
      ];
    }

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 담당자 정보 > 담당 PM 셀렉트의 아이템을 초기화함
  const initPmNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '선택하세요.',
      value: '',
    });

    // 판매 계약 담당PM 목록을 불러옴
    sttnApi.getSttnErpSearchPm({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.name,
          value: item.userId,
        });
      });

      // 담당자 정보 > 담당 PM 셀렉트의 아이템에 적용함
      setPmNameSelect((pre: ISelect) => ({
        ...pre,
        item: tmpOptionItem,
      }));
    });
  };

  /* // 담당자 정보 */

  /**
   * 사용여부
   */

  // 사용여부 > 사용 여부 라디오 버튼을 정의함
  const [useYnRadio, setUseYnRadio] = useState<string>('Y');

  // 사용여부 > 사용 여부 셀렉트에서 선택한 아이템을 변경함
  const handleUseYnSelect_onChange = (event: any) => {
    setUseYnRadio(event);
  };

  /* //사용여부 */

  /**
   * 이벤트
   */

  // 컴포넌트 모달 > 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: 'ERP 판매 계약 수정',
        content: (
          <MN2302220451 id={tmpId} cntrNmbr={cntrNmbr} useModifyMode={true} />
        ),
        size: 1500,
      });
    }, 100);
  };

  // 컴포넌트 모달 > 저장 버튼(수정)을 클릭함
  const handleSaveUpdateButton_onClick = () => {
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // ERP 판매계약 수정을 처리함
            sttnApi
              .putSttnErp({
                cntrNmbr: cntrNmbr,
                pmId: pmNameSelect.value, // 담당자PM 아이디
                useYn: useYnRadio, // 사용 여부
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  setModal({
                    title: '알림',
                    content: '저장하였습니다.',
                    callback: () => {
                      // 목록 새로고침을 추가함
                      setAddRefreshList('MN2302220401Table');

                      // 컴포넌트 모달을 닫음
                      setRemoveComponentModal(id);

                      setTimeout(() => {
                        // 조회 컴포넌트 모달을 추가함
                        let tmpId: string = nanoid();

                        // 컴포넌트 모달을 추가함
                        setAddComponentModal({
                          id: tmpId,
                          title: 'ERP 판매계약 상세',
                          content: (
                            <MN2302220451
                              id={tmpId}
                              cntrNmbr={cntrNmbr}
                              useModifyMode={false}
                            />
                          ),
                          size: 1500,
                        });
                      }, 100);
                    },
                  });
                } else {
                  console.log('> ERP 판매계약 Error:', data);

                  setModal({
                    title: '오류',
                    content: (
                      <>
                        <div>저장에 실패하였습니다.</div>
                        <div>({data.message})</div>
                      </>
                    ),
                  });
                }
              })
              .catch((error: any) => {
                console.log('> ERP 판매계약 Error:', error);

                setModal({
                  title: '오류',
                  content: `저장에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 컴포넌트 모달 > 삭제 버튼을 클릭함
  // const handleRemoveButton_onClick = () => {
  //   setModal({
  //     title: '확인',
  //     content: `ERP 판매계약 정보[${.nqryName}] 를 삭제하시겠습니까?`,
  //     useOkayButton: false,
  //     useCancelButton: true,
  //     cancelButtonLabel: '아니오',
  //     button: (
  //       <Button
  //         color="indigo"
  //         radius="xl"
  //         size="md"
  //         onClick={() => {
  //           setRemoveModal(true);

  //           // CS을 삭제함
  //           sttnApi
  //             .deleteCs({
  //               csId: csId,
  //             })
  //             .then((data: IApiResult) => {
  //               switch (data.code) {
  //                 case '200':
  //                   // 모달을 출력함
  //                   setModal({
  //                     title: '알림',
  //                     content: '삭제하였습니다.',
  //                     callback: () => setRemoveComponentModal(id),
  //                   });
  //                   break;

  //                 case '':
  //                   // 모달을 출력함
  //                   setModal({
  //                     title: t('common.error'),
  //                     content: '삭제에 실패하였습니다.',
  //                   });
  //                   break;

  //                 default:
  //                   break;
  //               }
  //             });
  //         }}
  //       >
  //         예
  //       </Button>
  //     ),
  //   });
  // };

  /* // 이벤트 */

  /**
   * 처리
   */

  // ERP 판매 계약 상세 데이터를 정의함
  const [erpContractData, setErpContractData] = useState<any>(null);

  // ERP 판매 계약 상세 데이터를 불러옴
  const getErpContractData = () => {
    sttnApi
      .getSttnErp({
        cntrNmbr: cntrNmbr,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          setErpContractData(data.data);
        }
      });
  };

  /* // 처리 */

  useEffect(() => {
    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    initPmNameSelect();

    getErpContractData();
  }, []);

  useEffect(() => {
    if (!erpContractData) {
      return;
    }

    // 계약 정보 > 프로젝트명 값 설정
    setPrjcNameInput(erpContractData.prjcName);

    // 계약 정보 > 판매 계약번호명 값 설정
    setCntrNmbrInput(erpContractData.cntrNmbr);

    // 계약 정보 > 프로젝트 코드 값 설정
    setPrjcCodeInput(erpContractData.prjcCode);

    // 계약 정보 > 프로젝트 일정 값 설정
    setFctvDateInput(
      `${erpContractData.fctvStrtDate} ~ ${erpContractData.fctvEndDate}`,
    );

    // 계약 정보 > 계약 진행 상황 값 설정
    setCntrPrgrNameInput(erpContractData.cntrPrgrName);

    // 고객사 정보 > 고객사명 값 설정
    setBpNameInput(erpContractData.bpName);

    // 고객사 정보 > 고객사 코드 값 설정
    setBpCodeInput(erpContractData.bpCode);

    // 담당자 정보 > 담당 PM 셀렉트의 값 설정
    setPmNameInput(erpContractData.pmName);
    setPmNameSelect((pre: ISelect) => ({
      ...pre,
      value: erpContractData.pmId,
    }));

    // 사용여부 > 사용 여부 값 설정
    setUseYnRadio(erpContractData.useYn);
  }, [erpContractData]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 계약 정보 폼 그리드 */}
        <PbFormGrid id="step-1" label="계약 정보" cols={2}>
          <PbFormGridCol label="프로젝트명">
            {/* 텍스트 입력 */}
            <TextInput
              value={prjcNameInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="판매 계약 번호">
            {/* 텍스트 입력 */}
            <TextInput
              value={cntrNmbrInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="프로젝트 코드">
            {/* 텍스트 입력 */}
            <TextInput
              value={prjcCodeInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="프로젝트 일정">
            {/* 텍스트 입력 */}
            <TextInput
              value={fctvDateInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="계약 진행 상황" colSpan={2}>
            {/* 텍스트 입력 */}
            <TextInput
              value={cntrPrgrNameInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 고객사 정보 폼 그리드 */}
        <PbFormGrid id="step-2" label="고객사 정보" cols={2}>
          <PbFormGridCol label="고객사명">
            <TextInput
              value={bpNameInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객사 코드">
            <TextInput
              value={bpCodeInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 담당자 정보 폼 그리드 */}
        <PbFormGrid id="step-3" label="담당자 정보" cols={2}>
          {/* 조회 상태 */}
          {cntrNmbr && !useModifyMode && (
            <PbFormGridCol label="담당 PM" colSpan={2}>
              <TextInput
                value={pmNameInput}
                readOnly={true}
                variant={'unstyled'}
                className="w-full"
              />
            </PbFormGridCol>
          )}
          {/* 수정 상태 */}
          {cntrNmbr && useModifyMode && (
            <PbFormGridCol label="담당 PM" colSpan={2}>
              <PbSelect
                onChange={handlePmNameSelect_onChange}
                data={pmNameSelect.item}
                value={pmNameSelect.value}
                setSelect={setPmNameSelect}
                variant={cntrNmbr && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
          )}
        </PbFormGrid>

        {/* 사용여부 폼 그리드 */}
        <PbFormGrid label="사용 여부" cols={2}>
          <PbFormGridCol label="사용 여부" colSpan={2}>
            <Radio.Group
              name="radioGroup1"
              onChange={handleUseYnSelect_onChange}
              value={useYnRadio}
              size="sm"
            >
              <Group mt="xs">
                <Radio
                  value="Y"
                  label="사용"
                  //readOnly={thrtId && !useModifyMode ? true : false}
                  disabled={cntrNmbr && !useModifyMode ? true : false}
                />
                <Radio
                  value="N"
                  label="미사용"
                  //readOnly={thrtId && !useModifyMode ? true : false}
                  disabled={cntrNmbr && !useModifyMode ? true : false}
                />
              </Group>
            </Radio.Group>
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 신규 상태 */}
        {!cntrNmbr && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              // onClick={handleSaveButton_onClick}
            >
              저장
            </Button>
          </>
        )}

        {/* 조회 상태 */}
        {cntrNmbr && !useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleGoModifyButton_onClick}
            >
              수정 전환
            </Button>
          </>
        )}

        {/* 수정 상태 */}
        {cntrNmbr && useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            {/* <Button
              variant="outline"
              color="pink"
              radius="xl"
              size="md"
              onClick={handleRemoveButton_onClick}
            >
              삭제
            </Button> */}

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveUpdateButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220451;
