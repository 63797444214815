import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Carousel } from '@mantine/carousel';
import { useElementSize, useHotkeys } from '@mantine/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { imageCarouselStore } from '../../stores/imageCarousel.store';
import { useRecoilState } from 'recoil';
import { IImageCarousel } from '../../interfaces/app.interface';
import _ from 'lodash';

interface IComponentProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 이미지 캐러셀 매니저
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const ImageCarouselManager = ({
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 이미지 캐러셀 저장소를 정의함
  const [imageCarousel, setImageCarousel] =
    useRecoilState<IImageCarousel>(imageCarouselStore);

  // 캐러셀의 실제 크기를 정의함
  const { ref, width, height } = useElementSize();

  // 이미지 캐러셀을 닫음
  const closeImageCarousel = () => {
    setImageCarousel({
      imageList: [],
      startIndex: 0,
    });
  };

  return (
    <>
      {imageCarousel.imageList.length > 0 && (
        <div
          ref={ref}
          className="absolute left-0 top-0 w-screen h-screen z-2000"
        >
          <div
            style={{
              width: `calc(${width || window.innerWidth}px - 200px)`,
              height: `calc(${height || window.innerHeight}px - 200px)`,
            }}
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20"
          >
            <Carousel
              maw={width || window.innerWidth - 200}
              height={height || window.innerHeight - 200}
              controlSize={50}
              controlsOffset="md"
              slideGap="md"
              withIndicators={true}
              loop={true}
              previousControlIcon={
                <FontAwesomeIcon
                  icon={['fas', 'chevron-left']}
                  className="w-5 h-5 text-black"
                />
              }
              nextControlIcon={
                <FontAwesomeIcon
                  icon={['fas', 'chevron-right']}
                  className="w-5 h-5 text-black"
                />
              }
              initialSlide={imageCarousel.startIndex}
              className="left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            >
              {imageCarousel.imageList.map((item, index) => (
                <Carousel.Slide key={index}>
                  <div className="relative w-full h-full flex justify-center items-center">
                    <img src={item} className="z-20" />

                    <div
                      onClick={closeImageCarousel}
                      className="absolute left-0 top-0 w-full h-full z-10"
                    />
                  </div>
                </Carousel.Slide>
              ))}
            </Carousel>
          </div>

          {/* 닫기 버튼 */}
          <div className="absolute right-7 top-7 w-12 h-12 flex justify-center items-center z-30">
            <div
              onClick={closeImageCarousel}
              className="button-event p-0.5 flex justify-center items-center rounded-md"
            >
              <FontAwesomeIcon
                icon={['fas', 'xmark']}
                className="w-14 h-14 text-white"
              />
            </div>
          </div>

          {/* 검은 반투명 배경 */}
          <div
            onClick={closeImageCarousel}
            className="object-opacity-appear absolute left-0 top-0 w-screen h-screen bg-black/70 z-10"
          />
        </div>
      )}
    </>
  );
};

export default ImageCarouselManager;
