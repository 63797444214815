import React, { PropsWithChildren } from 'react';
import * as appUtil from '../../utils/app.util';

interface IComponentProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 이미지 미리보기
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PreviewImage = ({
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  return (
    <div className="flex justify-center items-center">
      <img src={data} />
    </div>
  );
};

export default PreviewImage;
