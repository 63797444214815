import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { PbAgGridReact } from '../PbAgGridReact';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbMonthPicker } from '../PbMonthPicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
  IPageContent,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as nstlApi from '../../apis/nstl.api';
import _ from 'lodash';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  Select,
  Radio,
  Checkbox,
  Input,
  Modal,
  PasswordInput,
  TextInput,
  Group,
} from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import { IGroupCellRendererParams } from 'ag-grid-community';
import { number } from 'prop-types';
import { LoadingTableDataIcon } from '../LoadingTableDataIcon';
import { ValueFormatterParams } from 'ag-grid-community';
import MN2302220352_Stock_Modify from '../MN2302220352_Stock_Modify/MN2302220352_Stock_Modify.component';

interface IComponentProps {
  id: string;
  dcmnDntfNmbr?: string;
  splrId?: string;
  data?: any;
  onClick?: () => void;
}

/**
 * 설치 > 진행중작업관리 > 상세 > 작업결과 등록 > 재고이동요청
 * @param id 화면 아이디
 * @param dcmnDntfNmbr 문서번호
 * @param splrId 협력사 아이디
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */

let valStockListTable: any[] = [];

const MN2302220352_Stock = ({
  id = '',
  dcmnDntfNmbr = '',
  splrId = '',
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 변수
   */

  const stockListTableRef = useRef<any>(null);

  const [stockListTable, setStockListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  const initStockListTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'checkbox',
        width: 50,
        suppressSizeToFit: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: '재고 유형',
        field: 'stckTypeName',
        width: 140,
        suppressSizeToFit: true,
      },
      {
        headerName: '재고 상태',
        field: 'stckSttsName',
        width: 140,
        suppressSizeToFit: true,
      },
      {
        headerName: '품목명',
        field: 'itemName',
      },
      {
        headerName: '품목번호',
        field: 'itemNumber',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '수량',
        field: 'quantity',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: '메모',
        field: 'ttl',
      },
      {
        headerName: '',
        field: '',
        width: 120,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="w-full object-appear col-span-3 space-y-3">
            <div className="object-appear space-y-3">
              <div className=" grid grid-cols-3 gap-1 items-center space-x-3">
                <div className="object-appear relative">
                  <span className="text-md">{params.value}</span>
                </div>
                <div className="object-appear col-span-2 relative">
                  <Button
                    color="indigo"
                    radius="xl"
                    onClick={() => handleStockAddRowButton_onClick(params)}
                  >
                    수정
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    );

    // 재고 이동 요청 테이블에 적용
    setStockListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /* // */

  /**
   * event handle
   */

  // 행 추가
  const handleStockAddRowButton_onClick = (params: any) => {
    let tmpId: string = nanoid();
    let type: string = 'REQ';
    if (params.data) {
      type = 'MOD';
    }

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '재고 이동 요청 등록',
      content: (
        <MN2302220352_Stock_Modify
          data={params.data}
          dcmnDntfNmbr={dcmnDntfNmbr}
          type={type}
          id={tmpId}
          callback={(data: any) => {
            if (_.isEqual(type, 'REQ')) {
              let ongoing: boolean = true;
              valStockListTable.map((item: any, index: number) => {
                if (
                  _.isEqual(item.itemCode, data.itemCode) &&
                  _.isEqual(item.stckTypeCode, data.stckTypeCode) &&
                  _.isEqual(item.stckSttsCode, data.stckSttsCode)
                ) {
                  setModal({
                    title: '알림',
                    content:
                      '품목, 재고 유형, 재고 상태가 같은 경우 각 1건씩만 이동 요청이 가능합니다.',
                  });
                  ongoing = false;
                  return;
                }
              });

              if (ongoing) {
                data['modifyRowIndex'] = valStockListTable.length;
                // data.splrTypeCode = 'NTWR_CNST';
                valStockListTable.push(data);
              }
            } else if (_.isEqual(type, 'MOD')) {
              valStockListTable.map((item: any) => {
                if (_.isEqual(item.modifyRowIndex, data.modifyRowIndex)) {
                  // 재고 유형
                  item.stckTypeName = data.stckTypeName;
                  item['stckTypeCode'] = data.stckTypeCode;

                  // 재고 상태
                  item.stckSttsName = data.stckSttsName;
                  item['stckSttsCode'] = data.stckSttsCode;

                  // 품목 명
                  item['itemName'] = data.itemName;
                  item.itemCode = data.itemCode;

                  // 품목 번호
                  item.itemNumber = data.itemNumber;

                  // 수량
                  item.quantity = data.quantity;

                  // 내용
                  item.ttl = data.ttl;
                }
              });
            }

            setStockListTable((pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: valStockListTable,
            }));
          }}
        />
      ),
      size: 1500,
    });
  };

  // 행 삭제
  const handleStockDeleteRowButton_onClick = () => {
    let selectedRows: string[] = [];

    // 삭제할 행의 key 값을 불러옴
    stockListTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedRows.push(item.modifyRowIndex);
    });

    let tmlStockTableData: any = stockListTable.data.filter(
      (item: any) => !_.includes(selectedRows, item.modifyRowIndex),
    );

    // 행삭제 데이타 ESL 테이블 적용
    setStockListTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlStockTableData,
    }));
  };

  /* // */

  /**
   * api
   */

  const handleSaveBtn_onClick = () => {
    // 저장 데이타 여부 확인
    if (stockListTable.data.length < 1) {
      setModal({
        title: '알림',
        content: '저장 데이타가 없습니다.',
      });
      return;
    }

    // Shop별 설치 일정 계획 수정 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 재고 이동 요청 등록을 처리함
              nstlApi
                .postNstlNvntRgst({
                  dcmnDntfNmbr: dcmnDntfNmbr,
                  splrId: splrId,
                  list: stockListTable.data,
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220352Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);
                      },
                    });
                  } else {
                    console.log(
                      '> 설치 > Shop별 설치 일정계획 수정 Error:',
                      data,
                    );

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log(
                    '> 설치 > Shop별 설치 일정계획 수정 Error:',
                    error,
                  );

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  /* // */

  /**
   * 기타 처리
   */

  /* // */

  /**
   * useEffect
   */

  // 화면 로딩 후 한번 실행
  useEffect(() => {
    initStockListTable();

    return () => {};
  }, []);

  // 재고이동 요청 정보 변경시
  useEffect(() => {
    // 재고이동 요청 정보 수정을 위한 javascript 영역 변수에 복사
    valStockListTable = _.cloneDeep(stockListTable.data);
    return () => {};
  }, [stockListTable.data]);

  /* // */

  return (
    <div className="space-y-7">
      <div className="space-y-5">
        <div className="flex justify-center items-center div-bg-color border border-gray-200 space-y-5 p-1">
          <div className="w-full grid grid-cols-1 gap-1">
            <div className="w-full">
              <PbSection label="재고 이동 요청">
                <div className="title-wrapper mt-1">
                  <div className="right-btn !space-x-2">
                    <Button
                      variant="outline"
                      color="gray"
                      radius="xl"
                      onClick={handleStockDeleteRowButton_onClick}
                    >
                      -행 삭제
                    </Button>
                    <Button
                      variant="outline"
                      color="gray"
                      radius="xl"
                      onClick={handleStockAddRowButton_onClick}
                    >
                      +행 추가
                    </Button>
                  </div>
                </div>
                <div className="relative w-full h-52">
                  <PbAgGridReact
                    refs={stockListTableRef}
                    columnDefs={stockListTable.column}
                    rowData={stockListTable.data}
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      wrapHeaderText: true,
                      autoHeaderHeight: true,
                      cellRendererParams: {
                        DatePickerInput: true,
                      } as IGroupCellRendererParams,
                    }}
                    sizeColumnsToFit={true}
                    rowSelection="single"
                    overlayNoRowsTemplate="데이터가 없습니다."
                    className="ag-theme-alpine"
                  ></PbAgGridReact>
                </div>
              </PbSection>
            </div>
          </div>
        </div>

        <div className="component-modal-button-area">
          <div>
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>
          </div>
          <div>
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveBtn_onClick}
            >
              저장
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MN2302220352_Stock;
