import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import EditorTest from '../components/EditorTest';
import Language from '../components/Language';
import { DefaultButton } from '../components/defaultButton';
import { FileUpload } from '../components/fileUpload';
import { useRecoilState } from 'recoil';
import {
  IApiListResult,
  IApiResult,
  ILoginUser,
  IPage,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import * as b1Api from '../apis/b1.api';
import * as cmnApi from '../apis/cmn.api';
import * as csApi from '../apis/cs.api';
import CKEditorTest from '../components/CKEditorTest';
import { HtmlEditor } from '../components/HtmlEditor';
import api from '../utils/axios.util';

/**
 * 개발 테스트
 * @constructor
 */
const DevPage = () => {
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 로그인 토큰을 정의함
  // const [authToken, setAuthToken] = useState({
  //   accessToken: '',
  //   refreshToken: '',
  // });

  // B1 로그인 토큰을 정의함
  const [b1AuthToken, setB1AuthToken] = useState({
    sessionId: '',
  });

  // 임시!
  // 사용자 목록을 불러옴
  const getMemberList = () => {
    api({
      method: 'GET',
      url: 'https://ems-dev-api.sysnova.kr/api/member/searchMemberMngList',
      headers: {
        // Authorization: `Bearer ${loginUser.accessToken}`,
      },
      params: {
        authorId: 'ROLE_USER',
        currPageIdx: 1,
        pageSize: 1,
      },
    })
      .then((response: AxiosResponse<any>) => {
        // console.log('> getMemberList:', response.data);
      })
      .catch((error) => {
        // console.log('> getMemberList error:', error.response);
      });
  };

  // 임시!
  // 비지니스 파트너 목록을 불러옴
  const getBizPartnerList = () => {
    axios({
      method: 'GET',
      url: 'https://ems-dev-api.sysnova.kr/api/bizpartner/searchBizpartnerList',
      headers: {
        // Authorization: `Bearer ${loginUser.accessToken}`,
      },
      params: {
        currPageIdx: 1,
        pageSize: 1,
      },
    })
      .then((response: AxiosResponse<any>) => {
        // console.log('> getBizPartnerList:', response.data);
      })
      .catch((error) => {
        // console.log('> getBizPartnerList error:', error.response);
      });
  };

  // 임시!
  // B1 비지니스 파트너 목록을 불러옴
  const getB1BizPartnerList = () => {
    axios({
      method: 'GET',
      url: 'https://ems-dev-api.sysnova.kr/api/b1/businessPartners',
      headers: {
        // Authorization: `Bearer ${loginUser.accessToken}`,
        sessionId: b1AuthToken.sessionId,
      },
      params: {
        params: '$top=1',
      },
    })
      .then((response: AxiosResponse<any>) => {
        console.log('> getB1BizPartnerList:', response.data);
      })
      .catch((error) => {
        console.log('> getB1BizPartnerList error:', error.response);
      });
  };

  // 임시!
  // B1 로그인을 요청함
  const setB1Login = () => {
    axios({
      method: 'POST',
      url: 'https://ems-dev-api.sysnova.kr/api/b1/login',
      headers: {
        // Authorization: userMutate?.authorization,
      },
      data: {
        CompanyDB: 'DTN01KO_TEST2',
        Password: 1234,
        UserName: 'c4biz',
      },
    })
      .then((response: AxiosResponse<any>) => {
        console.log('> b1 TestLogin response:', response.data);

        if (response.data !== undefined) {
          console.log('> b1 TestLogin:', response.data.data.SessionId);

          // B1 로그인 토큰을 기억함
          setB1AuthToken({
            sessionId: response.data.data.SessionId,
          });
        } else {
          console.log('> b1 TestLogin error');
        }
      })
      .catch((error) => {
        console.log('> axios error:', error.response);
      });
  };

  // 임시!
  // 사용자를 추가함
  const addUser = () => {
    axios({
      method: 'POST',
      url: 'https://ems-dev-api.sysnova.kr/api/signup/signup',
      headers: {
        // Authorization: userMutate?.authorization,
      },
      data: {
        deptNm: '개발',
        ogdpNm: '인포인',
        rankNm: '사원',
        useYn: 'Y',
        userEmail: 'test@test.com',
        userGbCd: '00',
        userId: 'test2',
        userMoblphonFrst: '010',
        userMoblphonLast: '1111',
        userMoblphonMdle: '2222',
        userNm: '테스트',
        userPw: '1234',
      },
    })
      .then((response: AxiosResponse<any>) => {
        console.log('> signup response:', response.data);
      })
      .catch((error) => {
        console.log('> axios error:', error.response);
      });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // // 로그인을 처리함
    // cmnApi
    //   .postLogin({
    //     userId: 'T01012341234',
    //     userPw: '1234',
    //   })
    //   .then((data: IApiResult) => {
    //     // 로그인한 사용자 저장소에 적용함
    //     setLoginUser({
    //       ...loginUser,
    //       id: data.data.userId,
    //       name: data.data.userId,
    //       accessToken: data.data.accessToken,
    //       refreshToken: data.data.refreshToken,
    //     });
    //   });

    return () => {};
  }, []);

  //
  useEffect(() => {
    // if (!loginUser.accessToken) {
    //   return;
    // }

    // 공통코드를 불러옴
    // cmnApi.getCodes({}).then((data: IApiListResult) => {
    //   console.log('> getCmnCode:', data);
    // });

    // 권한 그룹 목록을 불러옴
    // cmnApi.getAuthorityGroups({}).then((data: IApiListResult) => {
    //   console.log('> getAuthorityGroups:', data);
    // });

    // 파일 정보를 불러옴
    cmnApi
      .getFiles({ fileIdList: 'FI2303150017,FI2303150018' })
      .then((data: IApiResult) => {
        // console.log('> getFiles:', data);
      });

    // // 권한 그룹을 등록함
    // cmnApi
    //   .postAuthorityGroup({
    //     thrtGrpName: 'TEST',
    //     thrtGrpDscr: 'TEST 그룹 설명',
    //     thrtGrpRltnListMap: [
    //       {
    //         MN2302220100: 'Y',
    //       },
    //       {
    //         MN2302220200: 'Y',
    //       },
    //     ],
    //     useYn: 'Y',
    //   })
    //   .then((data: IApiResult) => {
    //     console.log('> postAuthorityGroup:', data);
    //   });

    // 권한 목록을 불러옴
    cmnApi.getAuthorities({}).then((data: IApiListResult) => {
      // console.log('> getAuthorities:', data);
    });

    // // 권한을 등록함
    // cmnApi
    //   .postAuthority({
    //     thrtName: 'TEST',
    //     thrtDscr: 'TEST 설명',
    //     menuDvsnCode: 'WEB',
    //     mnthRltnListMap: [
    //       {
    //         MN2302220100: 'Y',
    //       },
    //       {
    //         MN2302220200: 'Y',
    //       },
    //     ],
    //     useYn: 'N',
    //   })
    //   .then((data: IApiResult) => {
    //     console.log('> postAuthority:', data);
    //   });

    // // 권한을 수정함
    // cmnApi
    //   .putAuthority({
    //     thrtId: 'AU2303130007',
    //     thrtName: 'TEST',
    //     thrtDscr: 'TEST 설명',
    //     menuDvsnCode: 'WEB',
    //     mnthRltnListMap: [
    //       {
    //         MN2302220100: 'Y',
    //       },
    //       {
    //         MN2302220200: 'Y',
    //       },
    //     ],
    //     useYn: 'N',
    //   })
    //   .then((data: IApiResult) => {
    //     console.log('> putAuthority:', data);
    //   });

    //
    // // 권한을 삭제함
    // cmnApi
    //   .deleteAuthority({
    //     thrtId: 'AU2303130007',
    //   })
    //   .then((data: IApiResult) => {
    //     console.log('> deleteAuthority:', data);
    //   });

    // CS 목록을 불러옴
    // csApi.getCss({}).then((data: IApiListResult) => {
    //   console.log('> getCss:', data);
    // });

    return () => {};
  }, [loginUser]);

  return (
    <div className="relative w-full h-full py-5 space-y-10">
      <div className="flex justify-center items-center">
        <span className="text-2xl font-semibold truncate">
          ems-fo-web: sample page - 테스트
        </span>
      </div>

      {/* 컴포넌트 테스트 */}
      <div className="w-full flex justify-center items-center border">
        <DefaultButton
          backgroundColor="blue"
          labelColor="white"
          size="sm"
          onClick={() => console.log('123')}
        >
          테스트
        </DefaultButton>
      </div>

      {/* 파일 업로드 */}
      <div className="w-full flex justify-center items-center">
        <div className="w-full h-full px-5">
          <FileUpload />
        </div>
      </div>

      {/* 에디터 */}
      <div className="w-full flex justify-center items-center">
        <div className="w-full h-full px-5">
          {/*<EditorTest />*/}
          {/*<CKEditorTest />*/}
          <HtmlEditor data="에디터 테스트입니다." />
        </div>
      </div>

      {/* 다국어 */}
      <div className="flex justify-center items-center">
        <div className="px-10 pb-10 bg-gray-100">
          <Language />
        </div>
      </div>
    </div>
  );
};

export default DevPage;
