import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IApiResult } from '../../interfaces/app.interface';
import * as appUtil from '../../utils/app.util';
import * as cmnApi from '../../apis/cmn.api';

interface IHtmlEditorProps {
  width?: number;
  height?: number;
  data?: any;
  callback?: Function;
  className?: any;
}

/**
 * HTML 에디터
 * @param data 컨텐츠 내용
 * @constructor
 */
const HtmlEditor = ({
  width = 800,
  height = 200,
  data,
  callback = () => {},
  className,
}: PropsWithChildren<IHtmlEditorProps>) => {
  // 사용자 정의 업로드 플러그인을 정의함
  function customUploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (
      loader: any,
    ) => {
      return customUploadAdapter(loader);
    };
  }

  // 사용자 정의 업로드 어댑터을 정의함
  const customUploadAdapter = (loader: any) => {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          loader.file.then((file: any) => {
            // 파일 정보를 불러옴
            cmnApi.postUploadFiles({ files: file }).then((data: IApiResult) => {
              // 불러온 이미지 정보를 <img /> 태그로 생성하여 에디터에 삽입함
              data.data.map((item: any) => {
                resolve({
                  default: `${appUtil.getThumbnailImageUrl()}${
                    item.pldFileName
                  }`,
                });
              });
            });
          });
        });
      },
    };
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        extraPlugins: [customUploadPlugin],
      }}
      data={data}
      onReady={(editor: any) => {
        editor.editing.view.change((writer: any) => {
          writer.setStyle(
            'width',
            `${width}px`,
            editor.editing.view.document.getRoot(),
          );

          writer.setStyle(
            'height',
            `${height}px`,
            editor.editing.view.document.getRoot(),
          );
        });

        callback(editor.getData());
      }}
      onChange={(event: any, editor: any) => {
        editor.editing.view.change((writer: any) => {
          writer.setStyle(
            'width',
            `${width}px`,
            editor.editing.view.document.getRoot(),
          );

          writer.setStyle(
            'height',
            `${height}px`,
            editor.editing.view.document.getRoot(),
          );
        });

        // const data = editor.getData();
        // console.log({ event, editor, data });
        callback(editor.getData());
      }}
      onBlur={(event: any, editor: any) => {
        // console.log('Blur.', editor);
      }}
      onFocus={(event: any, editor: any) => {
        // console.log('Focus.', editor);
      }}
    />
  );
};

export default HtmlEditor;
