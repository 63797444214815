import React, { PropsWithChildren, useEffect, useState, useRef } from 'react';
import { PbAgGridReact } from '../PbAgGridReact';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import prettyBytes from 'pretty-bytes';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  ITableColumn,
  IPageContent,
  IComponentModalPageScrollTabStep,
  IComponentModalPageScrollTab,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as nstlApi from '../../apis/nstl.api';
import * as ntcbApi from '../../apis/ntcb.api';
import _ from 'lodash';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import {
  addRefreshListStore,
  refreshListStore,
  removeRefreshListStore,
} from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { Button, TextInput, FileButton, Group } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PbSection from '../PbSection/PbSection.component';
import { IGroupCellRendererParams } from 'ag-grid-community';
import { ValueFormatterParams } from 'ag-grid-community';
import MN2302220353_Network from '../MN2302220353_Network/MN2302220353_Network.component';
import MN2302220353_Esl from '../MN2302220353_Esl/MN2302220353_Esl.component';
import MN2302220353_SwInfo from '../MN2302220353_SwInfo/MN2302220353_SwInfo.component';
import MN2302220353_EslSetup from '../MN2302220353_EslSetup/MN2302220353_EslSetup.component';
import MN2302220354 from '../MN2302220354/MN2302220354.component';
import { decode } from 'html-entities';
import * as appUtil from '../../utils/app.util';

interface IComponentProps {
  id?: string;
  dcmnDntfNmbr?: string;
  dcmnScrnNmbr?: string;
  data?: any;
  onClick?: () => void;
}

/**
 * 설치 > PM 검수 대기 현황 > Shop별 설치 일정 계획 > 상세/수정
 * @params id 화면 아이디
 * @params dcmnDntfNmbr 주문번호(Doc Entry)
 * @params dcmnScrnNmbr 주분번호(Doc Num) - B1 출력 문서 번호
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */

// PM 검수완료 여부 - script 영역 구분용 변수
let valInspectionResult: string = 'Y';

const MN2302220353 = ({
  id = '',
  dcmnDntfNmbr = '',
  dcmnScrnNmbr = '',
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**
   * 협력사/검수자 구분에 따른 화면 제어 변수
   * 사용자 설치 구분코드(작업검수자,PM검수자, 설치담당자 등)
   * nstlCtgr
   */
  const [isWorkNspc, setIsWorkNspc] = useState<boolean>(false);
  const [isPmNspc, setIsPmNspc] = useState<boolean>(false);
  const [isNtwrPrtn, setIsNtwrPrtn] = useState<boolean>(false);
  const [isNstlPrtn, setIsNstlPrtn] = useState<boolean>(false);

  /**
   * PM 검수대기 상세 데이타
   */
  const [pmInspectionData, setPmInspectionData] = useState<any>({});

  // PM 검수완료 여부 - 화면 영역 구분용 변수
  const [pmInspectionResult, setPmInspectionResult] = useState<string>('Y');

  // PM 검수 가능 여부
  const [pmInspectionAllow, setPmInspectionAllow] = useState<boolean>(false);

  /**
   * 프로젝트 정보
   */

  // 프로젝트 정보 > 프로젝트명
  const [prjcNameInput, setPrjcNameInput] = useState<string>('');

  // 프로젝트 정보 > 프로젝트명
  const handlePrjcNameInput_onChange = (event: any) => {
    setPrjcNameInput(event.target.value);
  };

  // 프로젝트 정보 > 프로젝트 일정
  const [fctvDateInput, setFctvDateInput] = useState<string>('');

  // 프로젝트 정보 > 프로젝트 일정
  const handleFctvDateInput_onChange = (event: any) => {
    setFctvDateInput(event.target.value);
  };

  // 프로젝트 정보 > 프로젝트 코드
  const [prjcCodeInput, setPrjcCodeInput] = useState<string>('');

  // 프로젝트 정보 > 프로젝트 코드
  const handlePrjcCodeInput_onChange = (event: any) => {
    setPrjcCodeInput(event.target.value);
  };

  // 프로젝트 정보 > 주문 번호(Doc Entry)
  const [dcmnDntfNmbrInput, setDcmnDntfNmbrInput] = useState<string>('');
  // 프로젝트 정보 > 주문 번호(Doc Num)
  const [dcmnScrnNmbrInput, setDcmnScrnNmbrInput] = useState<string>('');

  // 프로젝트 정보 > 주문 번호
  const handleDcmnScrnNmbrInput_onChange = (event: any) => {
    setDcmnScrnNmbrInput(event.target.value);
  };

  // 프로젝트 정보 > 설치예정일
  const [postDateInput, setPostDateInput] = useState<string>('');

  // 프로젝트 정보 > 설치예정일
  const handlePostDateInput_onChange = (event: any) => {
    setPostDateInput(event.target.value);
  };

  // 프로젝트 정보 > 영업사원
  const [slspNameInput, setSlspNameInput] = useState<string>('');

  // 프로젝트 정보 > 영업사원
  const handleSlspNameInput_onChange = (event: any) => {
    setSlspNameInput(event.target.value);
  };

  // 프로젝트 정보 > 담당 PM
  const [pmNameInput, setPmNameInput] = useState<string>('');

  // 프로젝트 정보 > 담당 PM
  const handlePmNameInput_onChange = (event: any) => {
    setPmNameInput(event.target.value);
  };

  /** // 프로젝트 정보 */

  /**
   * 계약 정보
   */

  // 계약 정보 > 계약명
  const [cntrNameInput, setCntrNameInput] = useState<string>('');

  // 계약 정보 > 계약명
  const handleCntrNameInput_onChange = (event: any) => {
    setCntrNameInput(event.target.value);
  };

  // 계약 정보 > 계약 번호
  const [cntrNmbrInput, setCntrNmbrInput] = useState<string>('');

  // 계약 정보 > 계약 번호
  const handleCntrNmbrInput_onChange = (event: any) => {
    setCntrNmbrInput(event.target.value);
  };

  /** // 계약 정보 */

  /**
   * 고객사/Shop 정보
   */

  // 고객사/Shop 정보 > 고객사명
  const [bpNameInput, setBpNameInput] = useState<string>('');

  // 고객사/Shop 정보 > 고객사명
  const handleBpNameInput_onChange = (event: any) => {
    setBpNameInput(event.target.value);
  };

  // 고객사/Shop 정보 > 고객사 코드
  const [bpCodeInput, setBpCodeInput] = useState<string>('');

  // 고객사/Shop 정보 > 고객사 코드
  const handleBpCodeInput_onChange = (event: any) => {
    setBpCodeInput(event.target.value);
  };

  // 고객사/Shop 정보 > Shop명
  const [shipToCodeInput, setShipToCodeInput] = useState<string>('');

  // 고객사/Shop 정보 > Shop명
  const handleShipToCodeInput_onChange = (event: any) => {
    setShipToCodeInput(event.target.value);
  };

  // 고객사/Shop 정보 > Shop 주소
  const [dlvrDrsInput, setDlvrDrsInput] = useState<string>('');

  // 고객사/Shop 정보 > Shop 주소
  const handleDlvrDrsInput_onChange = (event: any) => {
    setDlvrDrsInput(event.target.value);
  };

  /** // 고객사/Shop 정보 */

  /**
   * 버전 정보
   */

  // 버전 정보 정의
  const [swVersionInput, setSwVersionInput] = useState<string>('');

  // 버전 정보 변경
  const handleSwVersionInput_onChange = (event: any) => {
    setSwVersionInput(event.target.value);
  };

  /* // 버전 정보 */

  /**
   * 설치 확인서
   */

  /* // 설치 확인서 */

  // 검색 결과 테이블의 로딩 여부를 정의함
  const [searchResultTableLoading, setSearchResultTableLoading] =
    useState<boolean>(false);

  /**
   * 네트워크 공사 작업 내역
   */

  // 프로젝트 정보 > 네트워크 공사 작업 내역
  const [networkConstructionListTable, setNetworkConstructionListTable] =
    useState<{
      column: any;
      data: any;
    }>({ column: [], data: [] });

  // 네트워크 작업 결과
  const handleNetworkResultButton_onClick = (params: any) => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'Shop별 설치 작업결과 등록',
      content: (
        <MN2302220353_Network
          data={params.data}
          splrId={params.data.splrId}
          teamId={params.data.teamId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          dcmnScrnNmbr={dcmnScrnNmbr}
          pmInspectionResult={valInspectionResult}
          id={tmpId}
          callback={(data: any) => {
            // PM 검수 대기 상세 데이타 불러오기
            getNstlPmNspcDetail();
          }}
        />
      ),
      size: 1400,
    });
  };

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    tmpTab = [
      {
        label: '프로젝트',
        targetId: 'step-1',
      },
      {
        label: '고객사/Shop',
        targetId: 'step-2',
      },
      {
        label: '버전',
        targetId: 'step-3',
      },
      {
        label: '네트워크 작업',
        targetId: 'step-4',
      },
      {
        label: 'ESL 작업',
        targetId: 'step-5',
      },
    ];

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 프로젝트 정보 > 네트워크 공사 작업 내역 테이블을 초기화함
  const initNetworkConstructionListTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '내용',
        field: 'ttl',
      },
      {
        headerName: '상태',
        field: 'nstlSttsName',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '작업 일정',
        field: 'workDate',
        width: 450,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return `${moment(params.data.workStrtDttm).format(
            'YYYY-MM-DD HH:mm',
          )} ~ ${moment(params.data.workEndDttm).format('YYYY-MM-DD HH:mm')}`;
        },
      },
      {
        headerName: '협력사명',
        field: 'prtnName',
        cellRenderer: (params: any) => (
          <>{`${params.data.prtnName} (${params.data.teamName})`}</>
        ),
      },
      {
        headerName: '',
        field: 'networkResultBtn',
        width: 150,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          // PM 검수 미완료일 때만 작업 결과 버튼을 추가함
          <div
            className={[
              'w-full col-span-3 space-y-3',
              valInspectionResult === 'Y' ? 'hidden' : '',
            ].join(' ')}
          >
            <div className="space-y-3">
              <Button
                color="indigo"
                radius="xl"
                onClick={() => handleNetworkResultButton_onClick(params)}
              >
                작업 결과
              </Button>
            </div>
          </div>
        ),
      },
    );

    // 프로젝트 정보 > 네트워크 공사 작업 내역 테이블에 적용함
    setNetworkConstructionListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /** // 네트워크 공사 작업 내역 */

  /**
   * ESL 설치 작업 내역
   */

  // ESL 작업 결과
  const handleEslResultButton_onClick = (params: any) => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'Shop별 설치 작업결과 등록',
      content: (
        <MN2302220353_Esl
          data={params.data}
          splrId={params.data.splrId}
          teamId={params.data.teamId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          dcmnScrnNmbr={dcmnScrnNmbr}
          pmInspectionResult={valInspectionResult}
          id={tmpId}
          callback={(data: any) => {
            // PM 검수 대기 상세 데이타 불러오기
            getNstlPmNspcDetail();
          }}
        />
      ),
      size: 1400,
    });
  };

  // 프로젝트 정보 > ESL 설치 작업 내역
  const [eslSetUpListTable, setEslSetUpListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 프로젝트 정보 > ESL 설치 작업 내역 테이블을 초기화함
  const initEslSetUpListTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '내용',
        field: 'ttl',
      },
      {
        headerName: '상태',
        field: 'nstlSttsName',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '작업 일정',
        field: 'workDate',
        width: 450,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return `${moment(params.data.workStrtDttm).format(
            'YYYY-MM-DD HH:mm',
          )} ~ ${moment(params.data.workEndDttm).format('YYYY-MM-DD HH:mm')}`;
        },
      },
      {
        headerName: '협력사명',
        field: 'prtnName',
        cellRenderer: (params: any) => (
          <>{`${params.data.prtnName} (${params.data.teamName})`}</>
        ),
      },
      {
        headerName: '',
        field: 'eslResultBtn',
        width: 150,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          // PM 검수 미완료일 때만 작업 결과 버튼을 추가함
          <div
            className={[
              'w-full col-span-3 space-y-3',
              valInspectionResult === 'Y' ? 'hidden' : '',
            ].join(' ')}
          >
            <div className="space-y-3">
              <Button
                color="indigo"
                radius="xl"
                onClick={() => handleEslResultButton_onClick(params)}
              >
                작업 결과
              </Button>
            </div>
          </div>
        ),
      },
    );

    // 프로젝트 정보 > ESL 설치 작업 내역 테이블에 적용함
    setEslSetUpListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /** // ESL 설치 작업 내역 */

  /**
   * 설치 계획 품목
   */

  // 프로젝트 정보 > 설치 계획 품목
  // const [setUpPlanListTable, setSetUpPlanListTable] = useState<{
  //   column: any;
  //   data: any;
  //   loading?: boolean;
  // }>({ column: [], data: [], loading: false });

  // 프로젝트 정보 > 설치 계획 품목 테이블을 초기화함
  // const initSetUpPlanListTable = () => {
  //   // 컬럼을 생성함
  //   let tmpColumn: any = [];

  //   // 컬럼에 추가함
  //   tmpColumn.push(
  //     {
  //       headerName: '순번',
  //       field: 'no',
  //     },
  //     {
  //       headerName: '품목 번호',
  //       field: 'itemNmbr',
  //     },
  //     {
  //       headerName: '품목명',
  //       field: 'itemDtls',
  //     },
  //     {
  //       headerName: '제조업체',
  //       field: 'mnfcName',
  //     },
  //     {
  //       headerName: '창고',
  //       field: 'wrhsName',
  //     },
  //     {
  //       headerName: '수량',
  //       field: 'qntt',
  //     },
  //   );

  //   // 프로젝트 정보 > 설치 계획 품목 테이블에 적용함
  //   setSetUpPlanListTable((pre: { column: any; data: any }) => ({
  //     ...pre,
  //     column: tmpColumn,
  //   }));
  // };

  /** // 설치 계획 품목 */

  /**
   * 데이타 검색(api 호출)
   */

  // 진행중 작업 상세 데이타 불러오기
  const getNstlPmNspcDetail = () => {
    // loading - start
    setSearchResultTableLoading(true);

    nstlApi
      .getNstlPmNspcDetail({
        dcmnDntfNmbr: dcmnDntfNmbr,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          setPmInspectionData(data.data);
        }
        setSearchResultTableLoading(false);
      })
      .catch((error: any) => {
        setSearchResultTableLoading(false);
      });
  };

  /** //  */

  /**
   * 기타 처리
   */

  // 첨부파일 다운로드를 클릭함
  const handleFileDownload_onClick = (fileId: string, rgnlFileName: string) => {
    ntcbApi
      .getNncmFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  // PM 검수 완료
  const handleInspectionCompleteButton_onClick = () => {
    // 버전 정보 확인
    if (_.isEmpty(swVersionInput.trim())) {
      setModal({
        title: '알림',
        content: '버전 정보를 입력하세요.',
      });
      return;
    }

    // 설치 확인서 추가 여부 확인 - 최소 1건 등록 필요
    if (fileTable.data.length < 1) {
      setModal({
        title: '알림',
        content: '설치 확인서를 등록하세요.',
      });
      return;
    }

    // 기존 첨부파일 아이디를 불러옴
    let tmpPreFileId: string[] = [];

    // 기존 첨부파일 설명을 불러옴
    let tmpPreFileDescriptions: string[] = [];

    // 기존 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile === null)
      .map((item: any) => {
        tmpPreFileId.push(item.fileId);
        tmpPreFileDescriptions.push(item.fileDscr);
      });

    // 신규 첨부파일을 불러옴
    let tmpNewFile: any[] = [];
    let tmpNewFileDescriptions: string[] = []; // 신규 첨부파일 설명을 불러옴

    // 신규 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile !== null)
      .map((item: any) => {
        tmpNewFile.push(item.newFile);
        tmpNewFileDescriptions.push(item.fileDscr);
      });

    // 첨부파일의 설명을 확인함
    let checkFileDescriptions: boolean = false;
    fileTable.data.map((item: any) => {
      if (!item.fileDscr.trim()) {
        checkFileDescriptions = true;
      }
    });

    // PM 검수 완료 처리
    setModal({
      title: '확인',
      content: 'PM검수를 완료 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // PM 검수 완료 처리함
              nstlApi
                .postNstlPmNspcDetail({
                  dcmnDntfNmbr: dcmnDntfNmbr,
                  vrsnNfrm: swVersionInput.trim(),
                  fileList: tmpNewFile,
                  fileDscrList: tmpNewFileDescriptions,
                  bfFileIdList: tmpPreFileId,
                  bfFileDscrList: tmpPreFileDescriptions,
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220303Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);

                        setTimeout(() => {
                          // 조회 컴포넌트 모달을 추가함
                          let tmpId: string = nanoid();

                          // 컴포넌트 모달을 추가함
                          setAddComponentModal({
                            id: tmpId,
                            title: 'PM 검수 대기 현황 상세',
                            content: (
                              <MN2302220353
                                id={tmpId}
                                dcmnDntfNmbr={dcmnDntfNmbr}
                                dcmnScrnNmbr={dcmnScrnNmbr}
                              />
                            ),
                            size: 1500,
                          });
                        }, 100);
                      },
                    });
                  } else {
                    console.log('> PM검수 완료 실패:', data);

                    setModal({
                      title: '오류',
                      content: data.message,
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> PM검수 완료 Error:', error);

                  setModal({
                    title: '오류',
                    content: error,
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // PM 검수 취소
  const handleInspectionCancelButton_onClick = () => {
    // PM 검수 취소
    setModal({
      title: '확인',
      content: 'PM검수를 취소 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // PM 검수 취소를 처리함
              nstlApi
                .putNstlPmNspcCancel({
                  dcmnDntfNmbr: dcmnDntfNmbr || '',
                })
                .then((data: IApiResult) => {
                  //if (data.code === '200') {

                  // 성공 코드가 PM검수 취소의 경우만 204, 통일 필요시 Server 에서 변경 필요
                  if (data.code === '204') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220303Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);

                        setTimeout(() => {
                          // 조회 컴포넌트 모달을 추가함
                          let tmpId: string = nanoid();

                          // 컴포넌트 모달을 추가함
                          setAddComponentModal({
                            id: tmpId,
                            title: 'PM 검수 대기 현황 상세',
                            content: (
                              <MN2302220353
                                id={tmpId}
                                dcmnDntfNmbr={dcmnDntfNmbr}
                                dcmnScrnNmbr={dcmnScrnNmbr}
                              />
                            ),
                            size: 1500,
                          });
                        }, 100);
                      },
                    });
                  } else {
                    console.log('> PM검수 취소 실패:', data);

                    setModal({
                      title: '오류',
                      content: 'PM검수 취소 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> PM검수 취소 Error:', error);

                  setModal({
                    title: '오류',
                    content: 'PM검수 취소에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // 설치 결과 확인하기 버튼 클릭
  const handleSetUpResultButton_onClick = (params: any) => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 조회 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '완료작업 빌링 현황 상세',
        content: <MN2302220354 id={tmpId} dcmnDntfNmbr={dcmnDntfNmbr}/>,
        size: 1500,
      });
    }, 100);
  };

  // 네트워크 공사 작업 내역 상세보기
  const handleNetworkSwInfoDetailButton_onRowDoubleClicked = (row: any) => {
    let tmpId: string = nanoid();
    if (
      _.isEqual(
        row.api.focusService.focusedCellPosition.column.colId,
        'networkResultBtn',
      )
    ) {
      // 작업결과 cell 은 btn event 로만 작동
      return;
    }

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '소프트웨어 정보',
      content: (
        <MN2302220353_SwInfo data={row.data.workNtwrResultList} id={tmpId} />
      ),
      size: 1500,
    });
  };

  // ESL 설치 등록정보
  const handleEslSetUpDetailButton_onRowDoubleClicked = (row: any) => {
    let tmpId: string = nanoid();

    if (
      _.isEqual(
        row.api.focusService.focusedCellPosition.column.colId,
        'eslResultBtn',
      )
    ) {
      // 작업결과 cell 은 btn event 로만 작동
      return;
    }

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'ESL 설치 등록 정보',
      content: (
        <MN2302220353_EslSetup data={row.data.workEslResultList} id={tmpId} />
      ),
      size: 1500,
    });
  };

  /** //  */

  /**
   * 첨부파일 추가
   */

  // 첨부 파일 섹션 > 테이블을 정의함
  const [fileTable, setFileTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 첨부 파일 섹션 > 테이블 참조를 정의함
  const fileTableRef = useRef<any>(null);

  // 첨부 파일 > 테이블을 초기화함
  const initFileTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    if (_.isEqual(pmInspectionResult, 'N')) {
      // 검수 완료 전
      tmpColumn.push({
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      });
    } else if (_.isEqual(pmInspectionResult, 'Y')) {
      // PM 검수 완료의 상태
      tmpColumn.push({
        headerName: '첨부',
        field: 'rgnlFileName',
        editable: false,
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center space-x-3">
            <div
              onClick={() =>
                handleFileDownload_onClick(
                  params.data.fileId,
                  params.data.rgnlFileName,
                )
              }
              title={`다운로드: ${params.data.rgnlFileName}`}
              className="button-event px-0.5 py-0.5 flex justify-center items-center"
            >
              <FontAwesomeIcon
                icon={['fas', 'download']}
                className="w-5 h-5 text-indigo-600"
              />
            </div>
            <div>
              <span>{params.value}</span>
            </div>
          </div>
        ),
      });
    }

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '파일명',
        field: 'rgnlFileName',
        // width: 670,
        editable: false,
      },
      {
        headerName: '용량',
        field: 'fileSize',
        editable: false,
        valueFormatter: (params: any) => prettyBytes(_.toNumber(params.value)),
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '설명',
        field: 'fileDscr',
        width: 400,
        suppressSizeToFit: true,
      },
    );

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블 데이터를 불러옴
  const getFileTableData = () => {
    pmInspectionData.fileList.map((item: any, index: number) => {
      item['newFile'] = null;
    });

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: pmInspectionData.fileList,
    }));
  };

  // 첨부 파일 섹션 > 전체 삭제 버튼을 클릭함
  const handleFileSectionDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '전체 첨부파일을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 첨부 파일 섹션 > 테이블에 적용함
            setFileTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: [],
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 첨부 파일 섹션 > 선택 삭제 버튼을 클릭함
  const handleFileSectionSelectedDeleteButton_onClick = () => {
    // 삭제할 첨부 파일 아이디 목록을 정의함
    let selectedFileId: string[] = [];

    // 삭제할 첨부 파일 아이디 목록을 불러옴
    fileTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedFileId.push(item.fileId);
    });

    if (selectedFileId.length === 0) {
      setModal({
        title: '알림',
        content: '삭제할 첨부 파일을 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: '선택하신 첨부파일을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 첨부 파일 목록에서 삭제할 첨부 파일을 적용한 후 최종 목록을 정의함
            let tmlFileTableData: any = fileTable.data.filter(
              (item: any) => !_.includes(selectedFileId, item.fileId),
            );

            // 첨부 파일 섹션 > 테이블에 적용함
            setFileTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: tmlFileTableData,
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 첨부 파일 섹션 > 추가 버튼을 클릭함
  const handleFileSectionAddButton_onClick = (event: any) => {
    // 첨부 파일 목록을 불러옴
    let tmlFileTableData: any = _.cloneDeep(fileTable.data);

    // 첨부 파일 목록에 새로운 첨부 파일을 추가함
    tmlFileTableData.push({
      fileId: nanoid(),
      csResultFileType: 'new',
      fileDntfCode: 'ETC',
      rgnlFileName: event.name,
      fileSize: event.size,
      fileDscr: '',
      newFile: event,
    });

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlFileTableData,
    }));
  };

  /* // 첨부파일 추가 */

  /**
   * useEffect
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    if (!dcmnDntfNmbr) {
      return;
    }

    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    // // 네트워크 공사 작업 목록 초기화
    // initNetworkConstructionListTable();
    //
    // // ESL 설치 작업 내역 목록 초기화
    // initEslSetUpListTable();

    // 설치 계획 품목 목록 초기화
    // initSetUpPlanListTable();

    // PM 일정 상세 데이타 불러오기
    getNstlPmNspcDetail();

    return () => {};
  }, []);

  // 상세 정보 검색 완료 후 실행
  useEffect(() => {
    if (
      pmInspectionData === undefined ||
      pmInspectionData === null ||
      !pmInspectionData.prjcName
    ) {
      return;
    }

    // 설치 확인서 파일 테이블에 적용함
    setFileTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: pmInspectionData.fileList,
      loading: false,
    }));

    // PM 검수완료 여부 확인
    pmInspectionData.installPrtnList.map((item: any, index: number) => {
      if (_.isEqual(item.nstlSttsCode, 'PM_NSPC_CMPL')) {
        // 기본 검수완료로 본다.
        valInspectionResult = 'Y';
      } else if (_.isEqual(item.nstlSttsCode, 'NSPC_CMPL')) {
        valInspectionResult = 'N'; // 하나라도 PM 검수완료가 아니면, PM검수완료 상태로 안봄
        setPmInspectionResult('N');
      } else {
        setAddRefreshList('MN2302220303Table');
        setRemoveComponentModal(id);
      }
    });

    // 네트워크 공사 작업 목록 초기화
    initNetworkConstructionListTable();

    // ESL 설치 작업 내역 목록 초기화
    initEslSetUpListTable();

    // 네트워크 공사 작업 내역 분리
    let tmpNetworkConstructionList: any =
      pmInspectionData.installPrtnList.filter((item: any) =>
        _.isEqual(item.splrTypeCode, 'NTWR_CNST'),
      );

    // 네트워크 공사 작업 내역 적용
    setNetworkConstructionListTable(
      (pre: { column: ITableColumn[]; data: any }) => ({
        ...pre,
        data: tmpNetworkConstructionList,
      }),
    );

    // ESL 설치 작업 내역 분리
    let tmpEslSetUpList: any = pmInspectionData.installPrtnList.filter(
      (item: any) => _.isEqual(item.splrTypeCode, 'ESL_NSTL'),
    );

    // ESL 설치 작업 내역 적용
    setEslSetUpListTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: tmpEslSetUpList,
    }));

    // 프로젝트 정보 > 프로젝트명
    setPrjcNameInput(pmInspectionData.prjcName);

    // 프로젝트 정보 > 프로젝트 일정
    setFctvDateInput(
      pmInspectionData.fctvStrtDate + ' ~ ' + pmInspectionData.fctvEndDate,
    );

    // 프로젝트 정보 > 프로젝트 코드
    setPrjcCodeInput(pmInspectionData.prjcCode);

    // 프로젝트 정보 > 주문 번호
    setDcmnDntfNmbrInput(pmInspectionData.dcmnDntfNmbr);
    setDcmnScrnNmbrInput(pmInspectionData.dcmnScrnNmbr);

    // 프로젝트 정보 > 설치예정일
    setPostDateInput(pmInspectionData.postDate);

    // 프로젝트 정보 > 영업사원
    setSlspNameInput(pmInspectionData.slspName);

    // 프로젝트 정보 > 담당 PM
    setPmNameInput(pmInspectionData.pmName);

    // 계약 정보 > 계약명
    setCntrNameInput(pmInspectionData.cntrName);

    // 계약 정보 > 계약 번호
    setCntrNmbrInput(pmInspectionData.cntrNmbr);

    // 고객사/Shop 정보 > 고객사명
    setBpNameInput(pmInspectionData.bpName);

    // 고객사/Shop 정보 > 고객사 코드
    setBpCodeInput(pmInspectionData.bpCode);

    // 고객사/Shop 정보 > Shop명
    setShipToCodeInput(pmInspectionData.shipToCode);

    // 고객사/Shop 정보 > Shop 주소
    setDlvrDrsInput(pmInspectionData.dlvrDrs);

    // 버전정보 정보 적용
    setSwVersionInput(pmInspectionData.vrsnNfrm);

    // 설치 확인서 첨부파일 테이블 초기화
    initFileTable();

    return () => {};
  }, [pmInspectionData]);

  // PM 검수 가능여부 판단을 위함
  useEffect(() => {
    if (
      _.isEmpty(loginUser.id) ||
      pmInspectionData === null ||
      !pmInspectionData.prjcName
    ) {
      return;
    }

    if (_.isEqual(loginUser.id, pmInspectionData.pmId)) {
      setPmInspectionAllow(true);
    } else {
      setPmInspectionAllow(false);
    }

    if (loginUser.nstlCtgrCode === 'WORK_NSPC') {
      // 작업 검수자
      setIsWorkNspc(true);
    } else if (loginUser.nstlCtgrCode === 'PM_NSPC') {
      // PM 검수자
      setIsPmNspc(true);
    } else if (loginUser.nstlCtgrCode === 'NTWR_PRTN') {
      // 네트워크 협력사
      setIsNtwrPrtn(true);
    } else if (loginUser.nstlCtgrCode === 'NSTL_PRTN') {
      // ESL 협력사
      setIsNstlPrtn(true);
    }

    return () => {};
  }, [loginUser.id, pmInspectionData]);

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (fileTable.column.length === 0 || pmInspectionData === null) {
      return;
    }

    // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블 데이터를 불러옴
    getFileTableData();

    return () => {};
  }, [fileTable.column, pmInspectionData]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }
    if (_.indexOf(refreshList, 'MN2302220353Table') > -1) {
      // PM 일정 상세 데이타 불러오기
      getNstlPmNspcDetail();
      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220353Table');
      // 목록 새로고침을 추가함
      setAddRefreshList('MN2302220303Table');
    }
    return () => {};
  }, [refreshList]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  /** //  */

  return (
    <div className="space-y-7">
      {/* 프로젝트 정보 */}
      <div className="space-y-5">
        {/* 프로젝트 폼 그리드 */}
        <PbFormGrid id="step-1" label="프로젝트" cols={2}>
          <PbFormGridCol label="프로젝트명">
            <TextInput
              onChange={handlePrjcNameInput_onChange}
              value={prjcNameInput}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>
          <PbFormGridCol label="프로젝트 일정">
            <TextInput
              onChange={handleFctvDateInput_onChange}
              value={fctvDateInput}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>
          <PbFormGridCol label="프로젝트 코드">
            <TextInput
              onChange={handlePrjcCodeInput_onChange}
              value={prjcCodeInput}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>
          <PbFormGridCol label="주문 번호">
            <TextInput
              onChange={handleDcmnScrnNmbrInput_onChange}
              value={dcmnScrnNmbrInput}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>
          <PbFormGridCol label="설치예정일">
            <TextInput
              onChange={handlePostDateInput_onChange}
              value={postDateInput}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>
          <PbFormGridCol label="영업 사원">
            <TextInput
              onChange={handleSlspNameInput_onChange}
              value={slspNameInput}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>
          <PbFormGridCol label="담당 PM" colSpan={2}>
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handlePmNameInput_onChange}
              value={pmNameInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 계약 정보 */}
      <div className="space-y-5">
        <PbFormGrid label="계약 정보" cols={2}>
          <PbFormGridCol label="계약명">
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handleCntrNameInput_onChange}
              value={decode(cntrNameInput)}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="계약 번호">
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handleCntrNmbrInput_onChange}
              value={cntrNmbrInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 고객사/Shop 정보 */}
      <div className="space-y-5">
        <PbFormGrid id="step-2" label="고객사/Shop 정보" cols={2}>
          <PbFormGridCol label="고객사명">
            <TextInput
              onChange={handleBpNameInput_onChange}
              value={bpNameInput}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객사 코드">
            <TextInput
              onChange={handleBpCodeInput_onChange}
              value={bpCodeInput}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>
          <PbFormGridCol label="Shop명" colSpan={2}>
            <TextInput
              onChange={handleShipToCodeInput_onChange}
              value={shipToCodeInput}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>
          <PbFormGridCol label="Shop 주소" colSpan={2}>
            <TextInput
              onChange={handleDlvrDrsInput_onChange}
              value={dlvrDrsInput}
              className="w-full"
              readOnly={true}
              variant="unstyled"
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>
      <div className="space-y-5">
        <PbFormGrid id="step-3" label="버전 정보" cols={2}>
          <PbFormGridCol
            label="소프트웨어 버전"
            colSpan={2}
            withAsterisk={true}
          >
            <TextInput
              onChange={handleSwVersionInput_onChange}
              value={swVersionInput}
              className="w-full"
              readOnly={
                isPmNspc && _.isEqual(pmInspectionResult, 'N') ? false : true
              }
              variant={
                isPmNspc && _.isEqual(pmInspectionResult, 'N')
                  ? 'default'
                  : 'unstyled'
              }
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      <div className="flex justify-center items-center div-bg-color border border-gray-200 space-y-5 p-1">
        <div className="w-full grid grid-cols-1 gap-1">
          <div className="w-full">
            <PbSection label="설치 확인서">
              <div className="space-y-3">
                <div className="w-full h-52">
                  <PbAgGridReact
                    refs={fileTableRef}
                    columnDefs={fileTable.column}
                    rowData={fileTable.data}
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      wrapHeaderText: false,
                      autoHeaderHeight: true,
                    }}
                    rowSelection="single"
                    sizeColumnsToFit={true}
                  />
                </div>
                {isPmNspc && _.isEqual(pmInspectionResult, 'N') && (
                  <div className="flex justify-end items-center space-x-2">
                    {/* 버튼 */}
                    <Button
                      variant="outline"
                      color="pink"
                      radius="xl"
                      onClick={handleFileSectionDeleteButton_onClick}
                    >
                      전체 삭제
                    </Button>

                    {/* 버튼 */}
                    <Button
                      color="pink"
                      radius="xl"
                      onClick={handleFileSectionSelectedDeleteButton_onClick}
                    >
                      선택 삭제
                    </Button>

                    {/* 버튼 */}
                    <FileButton onChange={handleFileSectionAddButton_onClick}>
                      {(props: any) => (
                        <Button color="indigo" radius="xl" {...props}>
                          추가
                        </Button>
                      )}
                    </FileButton>
                  </div>
                )}
              </div>
            </PbSection>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center div-bg-color border border-gray-200 space-y-5 p-1">
        <div className="w-full grid grid-cols-1 gap-1">
          <div className="w-full">
            <PbSection id="step-4" label="네트워크 공사 작업 내역">
              {/* 네트워크 공사 작업 내역 */}
              <div className="w-full h-52">
                <PbAgGridReact
                  columnDefs={networkConstructionListTable.column}
                  rowData={networkConstructionListTable.data}
                  loading={searchResultTableLoading}
                  setLoading={setSearchResultTableLoading}
                  onRowDoubleClicked={
                    handleNetworkSwInfoDetailButton_onRowDoubleClicked
                  }
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                    cellRendererParams: {
                      DatePickerInput: true,
                    } as IGroupCellRendererParams,
                  }}
                  sizeColumnsToFit={true}
                  rowSelection="single"
                  overlayNoRowsTemplate="데이터가 없습니다."
                  className="ag-theme-alpine"
                ></PbAgGridReact>
              </div>
            </PbSection>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center bg-sky-50 border border-gray-200 space-y-5 p-1">
        <div className="w-full grid grid-cols-1 gap-1">
          <div className="w-full">
            <PbSection id="step-5" label="ESL 설치 작업 내역">
              {/* ESL 설치 작업 내역 */}
              <div className="w-full h-52">
                <PbAgGridReact
                  columnDefs={eslSetUpListTable.column}
                  rowData={eslSetUpListTable.data}
                  loading={searchResultTableLoading}
                  setLoading={setSearchResultTableLoading}
                  onRowDoubleClicked={
                    handleEslSetUpDetailButton_onRowDoubleClicked
                  }
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                    cellRendererParams: {
                      DatePickerInput: true,
                      TextInput: true,
                    } as IGroupCellRendererParams,
                  }}
                  sizeColumnsToFit={true}
                  rowSelection="single"
                  overlayNoRowsTemplate="데이터가 없습니다."
                  className="ag-theme-alpine"
                ></PbAgGridReact>
              </div>
            </PbSection>
          </div>
        </div>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        <>
          <Button
            variant="outline"
            color="gray"
            radius="xl"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          >
            닫기
          </Button>

          {isPmNspc &&
            _.isEqual(pmInspectionResult, 'N') &&
            pmInspectionAllow && (
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleInspectionCompleteButton_onClick}
              >
                PM 검수 완료
              </Button>
            )}

          {_.isEqual(pmInspectionResult, 'Y') && (
            <>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleSetUpResultButton_onClick}
              >
                설치 결과 확인하기
              </Button>
            </>
          )}

          {isPmNspc &&
            _.isEqual(pmInspectionResult, 'Y') &&
            pmInspectionAllow && (
              <>
                <Button
                  color="pink"
                  radius="xl"
                  size="md"
                  onClick={handleInspectionCancelButton_onClick}
                >
                  PM 검수 취소
                </Button>
              </>
            )}
        </>
      </div>
    </div>
  );
};

export default MN2302220353;
