import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { PbAgGridReact } from '../PbAgGridReact';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbMonthPicker } from '../PbMonthPicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModalPageScrollTabStep,
  IComponentModalPageScrollTab,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as nstlApi from '../../apis/nstl.api';
import _ from 'lodash';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  Select,
  Radio,
  Checkbox,
  Input,
  Modal,
  PasswordInput,
  TextInput,
  Group,
} from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import { IGroupCellRendererParams } from 'ag-grid-community';
import { number } from 'prop-types';
import { LoadingTableDataIcon } from '../LoadingTableDataIcon';
import { ValueFormatterParams } from 'ag-grid-community';
import MN2302220351_GridModify from '../MN2302220351_GridModify/MN2302220351_GridModify.component';
import { decode } from 'html-entities';
import * as appUtil from '../../utils/app.util';

interface IComponentProps {
  id?: string;
  dcmnDntfNmbr?: string;
  useModifyMode?: boolean;
  data?: any;
  onClick?: () => void;
}

/**
 * 설치 > 미등록작업 > Shop별 설치 일정 계획 상세
 * @params id 화면 아이디
 * @params dcmnDntfNmbr 주문번호
 * @params useModifyMode 수정여부
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */

// 프로젝트 정보 > 네트워크 공사 작업 내역 데이터를 정의함
let valNetworkListTable: any[] = [];

// 프로젝트 정보 > ESL 공사 작업 내역 데이터를 정의함
let valEslListTable: any[] = [];

const MN2302220351 = ({
  id = '',
  dcmnDntfNmbr = '',
  useModifyMode = false,
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 미등록 작업 상세 데이타
   */
  const [notRgstWorkData, setNotRgstWorkData] = useState<any>({});

  /**
   * 프로젝트 정보
   */

  // 프로젝트 정보 > 프로젝트명
  const [prjcNameInput, setPrjcNameInput] = useState<string>('');

  // 프로젝트 정보 > 프로젝트명
  const handlePrjcNameInput_onChange = (event: any) => {
    setPrjcNameInput(event.target.value);
  };

  // 프로젝트 정보 > 프로젝트 일정
  const [fctvDateInput, setFctvDateInput] = useState<string>('');

  // 프로젝트 정보 > 프로젝트 일정
  const handleFctvDateInput_onChange = (event: any) => {
    setFctvDateInput(event.target.value);
  };

  // 프로젝트 정보 > 프로젝트 코드
  const [prjcCodeInput, setPrjcCodeInput] = useState<string>('');

  // 프로젝트 정보 > 프로젝트 코드
  const handlePrjcCodeInput_onChange = (event: any) => {
    setPrjcCodeInput(event.target.value);
  };

  // 프로젝트 정보 > 주문 번호(Doc Entry)
  const [dcmnDntfNmbrInput, setDcmnDntfNmbrInput] = useState<string>('');

  // 프로젝트 정보 > 주문 번호(Doc Num)
  const [dcmnScrnNmbrInput, setDcmnScrnNmbrInput] = useState<string>('');

  // 프로젝트 정보 > 주문 번호
  const handleDcmnScrnNmbrInput_onChange = (event: any) => {
    setDcmnScrnNmbrInput(event.target.value);
  };

  // 프로젝트 정보 > 설치예정일
  const [postDateInput, setPostDateInput] = useState<string>('');

  // 프로젝트 정보 > 설치예정일
  const handlePostDateInput_onChange = (event: any) => {
    setPostDateInput(event.target.value);
  };

  // 프로젝트 정보 > 영업사원
  const [slspNameInput, setSlspNameInput] = useState<string>('');

  // 프로젝트 정보 > 영업사원
  const handleSlspNameInput_onChange = (event: any) => {
    setSlspNameInput(event.target.value);
  };

  // 프로젝트 정보 > 담당 PM
  const [pmNameInput, setPmNameInput] = useState<string>('');

  // 프로젝트 정보 > 담당 PM
  const handlePmNameInput_onChange = (event: any) => {
    setPmNameInput(event.target.value);
  };

  /** // 프로젝트 정보 */

  /**
   * 계약 정보
   */

  // 계약 정보 > 계약명
  const [cntrNameInput, setCntrNameInput] = useState<string>('');

  // 계약 정보 > 계약명
  const handleCntrNameInput_onChange = (event: any) => {
    setCntrNameInput(event.target.value);
  };

  // 계약 정보 > 계약 번호
  const [cntrNmbrInput, setCntrNmbrInput] = useState<string>('');

  // 계약 정보 > 계약 번호
  const handleCntrNmbrInput_onChange = (event: any) => {
    setCntrNmbrInput(event.target.value);
  };

  /** // 계약 정보 */

  /**
   * 고객사/Shop 정보
   */

  // 고객사/Shop 정보 > 고객사명
  const [bpNameInput, setBpNameInput] = useState<string>('');

  // 고객사/Shop 정보 > 고객사명
  const handleBpNameInput_onChange = (event: any) => {
    setBpNameInput(event.target.value);
  };

  // 고객사/Shop 정보 > 고객사 코드
  const [bpCodeInput, setBpCodeInput] = useState<string>('');

  // 고객사/Shop 정보 > 고객사 코드
  const handleBpCodeInput_onChange = (event: any) => {
    setBpCodeInput(event.target.value);
  };

  // 고객사/Shop 정보 > Shop명
  const [shipToCodeInput, setShipToCodeInput] = useState<string>('');

  // 고객사/Shop 정보 > Shop명
  const handleShipToCodeInput_onChange = (event: any) => {
    setShipToCodeInput(event.target.value);
  };

  // 고객사/Shop 정보 > Shop 주소
  const [dlvrDrsInput, setDlvrDrsInput] = useState<string>('');

  // 고객사/Shop 정보 > Shop 주소
  const handleDlvrDrsInput_onChange = (event: any) => {
    setDlvrDrsInput(event.target.value);
  };

  /** // 고객사/Shop 정보 */

  /**
   * 상세보기 / 간략히 보기
   */

  // 상세보기/ 간략히 보기를 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  // 상세보기/ 간략히 보기 버튼을 클릭함
  const handleShowDetailButton_onChange = () => {
    // setHideDetailSearch(!hideDetailSearch);
  };

  /* // 상세보기 / 간략히 보기 */

  /**
   * 네트워크 공사 작업 내역
   */

  // 최초 등록 확인용
  const [workSaveType, setWorkSaveType] = useState<string>('NEW');

  // 네트워크 공사 행삭제 용
  const networkListTableRef = useRef<any>(null);

  // 프로젝트 정보 > 네트워크 공사 작업 내역
  const [networkListTable, setNetworkListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // const [tmpNetworkList, setTmpNetworkList] = useState<[]>([]);

  const handleNtwrWorkCheckbox_onChange = (event: any) => {
    console.log(event);
    // setPrjcNameInput(event.target.value);
  };

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    if (!dcmnDntfNmbr) {
      // 신규
      tmpTab = [
        {
          label: '프로젝트',
          targetId: 'step-1',
        },
        {
          label: '계약',
          targetId: 'step-2',
        },
        {
          label: '네트워크 작업',
          targetId: 'step-3',
        },
        {
          label: 'ESL 작업',
          targetId: 'step-4',
        },
      ];
    } else if (dcmnDntfNmbr && !useModifyMode) {
      // 조회
      tmpTab = [
        {
          label: '프로젝트',
          targetId: 'step-1',
        },
        {
          label: '계약',
          targetId: 'step-2',
        },
        {
          label: '네트워크 작업',
          targetId: 'step-3',
        },
        {
          label: 'ESL 작업',
          targetId: 'step-4',
        },
      ];
    } else if (dcmnDntfNmbr && useModifyMode) {
      // 수정
      tmpTab = [
        {
          label: '프로젝트',
          targetId: 'step-1',
        },
        {
          label: '계약',
          targetId: 'step-2',
        },
        {
          label: '네트워크 작업',
          targetId: 'step-3',
        },
        {
          label: 'ESL 작업',
          targetId: 'step-4',
        },
      ];
    }

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 프로젝트 정보 > 네트워크 공사 작업 내역 테이블을 초기화함
  const initNetworkConstructionListTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any[] = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '내용',
        field: 'ttl',
      },
      {
        headerName: '작업 일정',
        field: 'workDate',
        width: 450,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return `${moment(params.data.workStrtDttm).format(
            'YYYY-MM-DD HH:mm',
          )} ~ ${moment(params.data.workEndDttm).format('YYYY-MM-DD HH:mm')}`;
        },
      },
      {
        headerName: '협력사(팀)명',
        field: 'teamName',
        cellRenderer: (params: any) => (
          <>{`${params.data.prtnName} (${params.data.teamName})`}</>
        ),
      },
    );

    // 수정의 경우
    if (useModifyMode) {
      // checkbox 추가
      tmpColumn.unshift({
        field: 'checkbox',
        width: 50,
        suppressSizeToFit: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      });

      // 수정 버튼 추가
      tmpColumn.push({
        headerName: '',
        field: '',
        width: 250,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="w-full col-span-3 space-y-3">
            <div className="space-y-3">
              <Button
                color="indigo"
                radius="xl"
                onClick={() => handleNetworkModifyButton_onClick(params)}
              >
                수정
              </Button>
            </div>
          </div>
        ),
      });
    }

    // 프로젝트 정보 > 네트워크 공사 작업 내역 테이블에 적용함
    setNetworkListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /** // 네트워크 공사 작업 내역 */

  /**
   * ESL 설치 작업 내역
   */

  // ESL 행삭제 용
  const eslSetUpListTableRef = useRef<any>(null);

  // 프로젝트 정보 > ESL 설치 작업 내역
  const [eslSetUpListTable, setEslSetUpListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 프로젝트 정보 > ESL 설치 작업 내역 테이블을 초기화함
  const initEslSetUpListTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '내용',
        field: 'ttl',
      },
      {
        headerName: '작업 일정',
        field: 'workDate',
        width: 450,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return `${moment(params.data.workStrtDttm).format(
            'YYYY-MM-DD HH:mm',
          )} ~ ${moment(params.data.workEndDttm).format('YYYY-MM-DD HH:mm')}`;
        },
      },
      {
        headerName: '협력사(팀)명',
        field: 'teamName',
        cellRenderer: (params: any) => (
          <>{`${params.data.prtnName} (${params.data.teamName})`}</>
        ),
      },
    );

    if (useModifyMode) {
      tmpColumn.unshift({
        field: 'checkbox',
        width: 50,
        suppressSizeToFit: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      });

      // 컬럼에 추가함
      tmpColumn.push({
        headerName: '',
        field: '',
        width: 250,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="w-full col-span-3 space-y-3">
            <div className="space-y-3">
              <div className=" grid grid-cols-3 gap-1 items-center space-x-3">
                <div className="relative">
                  <span className="text-md">{params.value}</span>
                </div>
                <div className="col-span-2 relative">
                  <Button
                    color="indigo"
                    radius="xl"
                    onClick={() => handleEslSetUpModifyButton_onClick(params)}
                  >
                    수정
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ),
      });
    }

    // 프로젝트 정보 > ESL 설치 작업 내역 테이블에 적용함
    setEslSetUpListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /** // ESL 설치 작업 내역 */

  /**
   * 설치 계획 품목
   */

  // 프로젝트 정보 > 설치 계획 품목
  const [setUpPlanListTable, setSetUpPlanListTable] = useState<{
    column: any;
    data: any;
    loading?: boolean;
  }>({ column: [], data: [], loading: false });

  // 프로젝트 정보 > 설치 계획 품목 테이블을 초기화함
  const initSetUpPlanListTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '순번',
        field: 'no',
        // width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: '품목 번호',
        field: 'itemNmbr',
        // width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: '품목명',
        field: 'itemDtls',
        valueFormatter: (params: any) => decode(params.value),
      },
      {
        headerName: '제조업체',
        field: 'mnfcName',
      },
      {
        headerName: '창고',
        field: 'wrhsName',
        // width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: '수량',
        field: 'qntt',
        // width: 100,
        suppressSizeToFit: true,
      },
    );

    // 프로젝트 정보 > 설치 계획 품목 테이블에 적용함
    setSetUpPlanListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /** // 설치 계획 품목 */

  /**
   * 데이타 검색(api 호출)
   */

  // 미등록 작업 상세 데이타 불러오기
  const getNotRgstWorkData = () => {
    nstlApi
      .getNstlNotRgst({
        dcmnDntfNmbr: dcmnDntfNmbr,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          data.data.installPrtnList.map((item: any, index: number) => {
            item['modifyRowIndex'] = index; // 수정용 key 값 생성
            item.checkbox = index;
          });
          setNotRgstWorkData(data.data);
        }
      })
      .catch((error: any) => {
        // 검색 결과 테이블에 적용함
        setSetUpPlanListTable((pre: { column: ITableColumn[]; data: any }) => ({
          ...pre,
          loading: false,
        }));
      });
  };

  /** // 데이타 검색(api 호출) */

  /**
   * 기타 처리
   */

  // 컴포넌트 모달 > 저장 버튼(수정)을 클릭함
  const handleSaveUpdateButton_onClick = () => {
    let inputList: any[] = [];
    inputList = _.concat(
      _.cloneDeep(networkListTable.data),
      _.cloneDeep(eslSetUpListTable.data),
    );

    // 날짜 저장 포맷 맞춤 : YYYY.MM.DD HH:mm > YYYYMMDDHHmm
    inputList.map((item: any) => {
      item.workStrtDttm = moment(item.workStrtDttm).format('YYYYMMDDHHmm');
      item.workEndDttm = moment(item.workEndDttm).format('YYYYMMDDHHmm');
    });

    if (_.isEqual(workSaveType, 'NEW')) {
      // Shop별 설치 일정 계획 수정 저장 처리
      setModal({
        title: '확인',
        content: '저장하시겠습니까?',
        useOkayButton: false,
        useCancelButton: true,
        cancelButtonLabel: '아니오',
        button: (
          <div>
            <PbButton
              label="예"
              backgroundColor="purple"
              size="sm"
              onClick={() => {
                setRemoveModal(true);

                // 설치 > Shop별 설치 일정계획 수정을 처리함
                nstlApi
                  .postNstlNotRgst({
                    dcmnDntfNmbr: dcmnDntfNmbr,
                    nstlList: inputList,
                  })
                  .then((data: IApiResult) => {
                    if (data.code === '200') {
                      setModal({
                        title: '알림',
                        content: '저장하였습니다.',
                        callback: () => {
                          // 목록 새로고침을 추가함
                          setAddRefreshList('MN2302220301Table');

                          // 컴포넌트 모달을 닫음
                          setRemoveComponentModal(id);

                          setTimeout(() => {
                            // 조회 컴포넌트 모달을 추가함
                            let tmpId: string = nanoid();

                            // 컴포넌트 모달을 추가함
                            setAddComponentModal({
                              id: tmpId,
                              title: 'Shop별 설치 일정 계획 상세',
                              content: (
                                <MN2302220351
                                  id={tmpId}
                                  dcmnDntfNmbr={dcmnDntfNmbr}
                                />
                              ),
                              size: 1500,
                            });
                          }, 100);
                        },
                      });
                    } else {
                      console.log(
                        '> 설치 > Shop별 설치 일정계획 수정 Error:',
                        data,
                      );

                      setModal({
                        title: '오류',
                        content: '저장에 실패하였습니다.',
                      });
                    }
                  })
                  .catch((error: any) => {
                    console.log(
                      '> 설치 > Shop별 설치 일정계획 수정 Error:',
                      error,
                    );

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  });
              }}
            />
          </div>
        ),
      });
    } else {
      // Shop별 설치 일정 계획 수정 저장 처리
      setModal({
        title: '확인',
        content: '저장하시겠습니까?',
        useOkayButton: false,
        useCancelButton: true,
        cancelButtonLabel: '아니오',
        button: (
          <div>
            <PbButton
              label="예"
              backgroundColor="purple"
              size="sm"
              onClick={() => {
                setRemoveModal(true);

                // 설치 > Shop별 설치 일정계획 수정을 처리함
                nstlApi
                  .putNstlPrtns({
                    dcmnDntfNmbr: dcmnDntfNmbr,
                    installPrtnParamVoList: inputList,
                  })
                  .then((data: IApiResult) => {
                    if (data.code === '200') {
                      setModal({
                        title: '알림',
                        content: '저장하였습니다.',
                        callback: () => {
                          // 목록 새로고침을 추가함
                          setAddRefreshList('MN2302220301Table');

                          // 컴포넌트 모달을 닫음
                          setRemoveComponentModal(id);

                          setTimeout(() => {
                            // 조회 컴포넌트 모달을 추가함
                            let tmpId: string = nanoid();

                            // 컴포넌트 모달을 추가함
                            setAddComponentModal({
                              id: tmpId,
                              title: 'Shop별 설치 일정 계획 상세',
                              content: (
                                <MN2302220351
                                  id={tmpId}
                                  dcmnDntfNmbr={dcmnDntfNmbr}
                                />
                              ),
                              size: 1500,
                            });
                          }, 100);
                        },
                      });
                    } else {
                      console.log(
                        '> 설치 > Shop별 설치 일정계획 수정 Error:',
                        data,
                      );

                      setModal({
                        title: '오류',
                        content: '저장에 실패하였습니다.',
                      });
                    }
                  })
                  .catch((error: any) => {
                    console.log(
                      '> 설치 > Shop별 설치 일정계획 수정 Error:',
                      error,
                    );

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  });
              }}
            />
          </div>
        ),
      });
    }
  };

  // 컴포넌트 모달 > 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: 'Shop별 설치 일정 계획 수정',
        content: (
          <MN2302220351
            id={tmpId}
            dcmnDntfNmbr={dcmnDntfNmbr}
            useModifyMode={true}
          />
        ),
        size: 1500,
      });
    }, 100);
  };

  // Network 행추가 / 수정
  const handleNetworkModifyButton_onClick = (params: any) => {
    let tmpId: string = nanoid();
    let type: string = 'REQ';
    if (params.data) {
      type = 'MOD';
    }

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '공사 내역 수정',
      content: (
        <MN2302220351_GridModify
          data={params.data}
          pSplrTypeCode="NTWR_CNST"
          id={tmpId}
          type={type}
          callback={(data: any) => {
            let ongoing: boolean = true;

            valNetworkListTable.map((item: any) => {
              if (
                _.isEqual(item.teamId, data.teamId) &&
                !_.isEqual(item.modifyRowIndex, data.modifyRowIndex)
              ) {
                setModal({
                  title: '알림',
                  content: '협력사(팀)은 중복 등록 불가능합니다.',
                });
                ongoing = false;
                return;
              }
            });

            // 네크워크 작업 수정
            if (ongoing) {
              // 중복 협력사가 없어야 진행
              if (_.isEqual(type, 'REQ')) {
                data.modifyRowIndex = valNetworkListTable.length;
                data.splrTypeCode = 'NTWR_CNST';
                valNetworkListTable.push(data);
              } else if (_.isEqual(type, 'MOD')) {
                valNetworkListTable.map((item: any) => {
                  if (_.isEqual(item.modifyRowIndex, data.modifyRowIndex)) {
                    item.prtnName = data.prtnName;
                    item.teamName = data.teamName;
                    item.ttl = data.ttl;
                    item.workDate = `${moment(data.workStrtDttm).format(
                      'YYYY-MM-DD HH:mm',
                    )} 
                                  ~ ${moment(data.workEndDttm).format(
                                    'YYYY-MM-DD HH:mm',
                                  )}`;
                    item.workStrtDttm = data.workStrtDttm;
                    item.workEndDttm = data.workEndDttm;
                    item.splrId = data.splrId;
                    item.teamId = data.teamId;
                    item.splrTypeCode = 'NTWR_CNST';
                  }
                });
              }

              setNetworkListTable(
                (pre: { column: ITableColumn[]; data: any }) => ({
                  ...pre,
                  data: valNetworkListTable,
                }),
              );
            }
          }}
        />
      ),
      size: 1500,
    });
  };

  // ESL 행추가 / 수정
  const handleEslSetUpModifyButton_onClick = (params: any) => {
    let tmpId: string = nanoid();
    let type: string = 'REQ';
    if (params.data) {
      type = 'MOD';
    }

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '공사 내역 수정',
      content: (
        <MN2302220351_GridModify
          data={params.data}
          pSplrTypeCode="ESL_NSTL"
          id={tmpId}
          type={type}
          callback={(data: any) => {
            let ongoing: boolean = true;

            valEslListTable.map((item: any) => {
              if (
                _.isEqual(item.teamId, data.teamId) &&
                !_.isEqual(item.modifyRowIndex, data.modifyRowIndex)
              ) {
                setModal({
                  title: '알림',
                  content: '협력사(팀)은 중복 등록 불가능합니다.',
                });
                ongoing = false;
                return;
              }
            });

            // ESL 작업 수정
            if (ongoing) {
              // 중복 협력사가 없어야 진행
              if (_.isEqual(type, 'REQ')) {
                data.modifyRowIndex = valEslListTable.length;
                data.splrTypeCode = 'ESL_NSTL';
                valEslListTable.push(data);
              } else if (_.isEqual(type, 'MOD')) {
                valEslListTable.map((item: any) => {
                  if (_.isEqual(item.modifyRowIndex, data.modifyRowIndex)) {
                    item.prtnName = data.prtnName;
                    item.teamName = data.teamName;
                    item.ttl = data.ttl;
                    item.workDate = `${moment(data.workStrtDttm).format(
                      'YYYY-MM-DD HH:mm',
                    )} 
                                  ~ ${moment(data.workEndDttm).format(
                                    'YYYY-MM-DD HH:mm',
                                  )}`;
                    item.workStrtDttm = data.workStrtDttm;
                    item.workEndDttm = data.workEndDttm;
                    item.splrId = data.splrId;
                    item.teamId = data.teamId;
                    item.splrTypeCode = 'ESL_NSTL';
                  }
                });
              }

              setEslSetUpListTable(
                (pre: { column: ITableColumn[]; data: any }) => ({
                  ...pre,
                  data: valEslListTable,
                }),
              );
            }
          }}
        />
      ),
      size: 1500,
    });
  };

  const handleNetworkDeleteRowButton_onClick = () => {
    let selectedRows: string[] = [];

    // 삭제할 행의 key 값을 불러옴
    networkListTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedRows.push(item.modifyRowIndex);
    });

    let tmlNetworkTableData: any = networkListTable.data.filter(
      (item: any) => !_.includes(selectedRows, item.modifyRowIndex),
    );

    // 행삭제 데이타 ESL 테이블 적용
    setNetworkListTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlNetworkTableData,
    }));
  };

  const handleEslSetupDeleteRowButton_onClick = () => {
    let selectedRows: string[] = [];

    // 삭제할 첨부 파일 아이디 목록을 불러옴
    eslSetUpListTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedRows.push(item.modifyRowIndex);
    });

    let tmlEslTableData: any = eslSetUpListTable.data.filter(
      (item: any) => !_.includes(selectedRows, item.modifyRowIndex),
    );

    // 행삭제 데이타 ESL 테이블 적용
    setEslSetUpListTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlEslTableData,
    }));
  };

  const handleNetworkAddRowButton_onClick = () => {};

  /** // 기타 처리 */

  /**
   * useEffect
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    if (!dcmnDntfNmbr) {
      return;
    }

    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    // 네트워크 공사 작업 목록 초기화
    initNetworkConstructionListTable();

    // ESL 설치 작업 내역 목록 초기화
    initEslSetUpListTable();

    // 설치 계획 품목 목록 초기화
    initSetUpPlanListTable();

    // 미등록 작업 상세 데이타 불러오기
    getNotRgstWorkData();

    return () => {};
  }, []);

  // 상세 정보 검색 완료 후 실행
  useEffect(() => {
    if (!notRgstWorkData.prjcName) {
      return;
    }

    // 프로젝트 정보 > 프로젝트명
    setPrjcNameInput(notRgstWorkData.prjcName);

    // 프로젝트 정보 > 프로젝트 일정
    setFctvDateInput(
      notRgstWorkData.fctvStrtDate + ' ~ ' + notRgstWorkData.fctvEndDate,
    );

    // 프로젝트 정보 > 프로젝트 코드
    setPrjcCodeInput(notRgstWorkData.prjcCode);

    // 프로젝트 정보 > 주문 번호
    setDcmnDntfNmbrInput(notRgstWorkData.dcmnDntfNmbr);
    setDcmnScrnNmbrInput(notRgstWorkData.dcmnScrnNmbr);

    // 프로젝트 정보 > 설치예정일
    setPostDateInput(notRgstWorkData.postDate);

    // 프로젝트 정보 > 영업사원
    setSlspNameInput(notRgstWorkData.slspName);

    // 프로젝트 정보 > 담당 PM
    setPmNameInput(notRgstWorkData.pmName);

    // 계약 정보 > 계약명
    setCntrNameInput(notRgstWorkData.cntrName);

    // 계약 정보 > 계약 번호
    setCntrNmbrInput(notRgstWorkData.cntrNmbr);

    // 고객사/Shop 정보 > 고객사명
    setBpNameInput(notRgstWorkData.bpName);

    // 고객사/Shop 정보 > 고객사 코드
    setBpCodeInput(notRgstWorkData.bpCode);

    // 고객사/Shop 정보 > Shop명
    setShipToCodeInput(notRgstWorkData.shipToCode);

    // 고객사/Shop 정보 > Shop 주소
    setDlvrDrsInput(notRgstWorkData.dlvrDrs);

    // 검색 결과 테이블에 적용함
    setSetUpPlanListTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: notRgstWorkData.installItemList,
      loading: false,
    }));

    // 네트워크 공사 작업 내역 분리
    let tmpNetworkList: any = notRgstWorkData.installPrtnList.filter(
      (item: any) => _.isEqual(item.splrTypeCode, 'NTWR_CNST'),
    );

    setNetworkListTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: _.cloneDeep(tmpNetworkList),
    }));

    // ESL 설치 작업 내역 분리
    let tmpEslSetUpList: any = notRgstWorkData.installPrtnList.filter(
      (item: any) => _.isEqual(item.splrTypeCode, 'ESL_NSTL'),
    );

    // ESL 설치 작업 내역 적용
    setEslSetUpListTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: tmpEslSetUpList,
    }));

    if (tmpNetworkList.length > 0 || tmpEslSetUpList.length > 0) {
      setWorkSaveType('MOD');
    }

    return () => {};
  }, [notRgstWorkData.prjcName]);

  // Network 정보 변경시
  useEffect(() => {
    // Network 작업 정보 수정을 위한 javascript 영역 변수에 복사
    valNetworkListTable = _.cloneDeep(networkListTable.data);
    return () => {};
  }, [networkListTable.data]);

  // ESL 정보 변경시
  useEffect(() => {
    // ESL 작업 정보 수정을 위한 javascript 영역 변수에 복사
    valEslListTable = _.cloneDeep(eslSetUpListTable.data);
    return () => {};
  }, [eslSetUpListTable.data]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  /** //  */

  return (
    <div className="space-y-7">
      {/* 프로젝트 정보 */}
      <div className="space-y-5">
        {/* 프로젝트 폼 그리드 */}
        <PbFormGrid id="step-1" label="프로젝트" cols={2}>
          <PbFormGridCol label="프로젝트명">
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handlePrjcNameInput_onChange}
              value={prjcNameInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="프로젝트 일정">
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handleFctvDateInput_onChange}
              value={fctvDateInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="프로젝트 코드" isHidden={hideDetailSearch}>
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handlePrjcCodeInput_onChange}
              value={prjcCodeInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="설치예정일" isHidden={hideDetailSearch}>
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handlePostDateInput_onChange}
              value={postDateInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객사명" isHidden={hideDetailSearch}>
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handleBpNameInput_onChange}
              value={bpNameInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객사 코드" isHidden={hideDetailSearch}>
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handleBpCodeInput_onChange}
              value={bpCodeInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="영업 사원" isHidden={hideDetailSearch}>
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handleSlspNameInput_onChange}
              value={slspNameInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="담당 PM" isHidden={hideDetailSearch}>
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handlePmNameInput_onChange}
              value={pmNameInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 계약 정보 */}
      <div className="space-y-5">
        <PbFormGrid
          id="step-2"
          label="계약 정보" 
          cols={2} 
          isHidden={hideDetailSearch}>
          <PbFormGridCol label="계약명" isHidden={hideDetailSearch}>
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handleCntrNameInput_onChange}
              value={decode(cntrNameInput)}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="계약 번호" isHidden={hideDetailSearch}>
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handleCntrNmbrInput_onChange}
              value={cntrNmbrInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 고객사/Shop 정보 */}
      <div className="space-y-5">
        <PbFormGrid
          label="주문 정보"
          cols={2}
          isHidden={hideDetailSearch}
        >
          <PbFormGridCol label="주문 번호" isHidden={hideDetailSearch}>
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handleDcmnScrnNmbrInput_onChange}
              value={dcmnScrnNmbrInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="Shop명" isHidden={hideDetailSearch}>
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handleShipToCodeInput_onChange}
              value={shipToCodeInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol
            label="Shop주소"
            colSpan={2}
            isHidden={hideDetailSearch}
          >
            {/* 텍스트 입력 */}
            <TextInput
              onChange={handleDlvrDrsInput_onChange}
              value={dlvrDrsInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
        </PbFormGrid>
        {/* <Button
          variant="outline"
          color="gray"
          radius="xl"
          onClick={handleShowDetailButton_onChange}
        >
          {hideDetailSearch ? (
            <>
              상세 보기
              <div className="ml-1 flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fas', 'caret-down']}
                  className="w-5 h-5"
                />
              </div>
            </>
          ) : (
            <>
              간략히 보기
              <div className="ml-1 flex justify-center items-center">
                <FontAwesomeIcon
                  icon={['fas', 'caret-up']}
                  className="w-5 h-5"
                />
              </div>
            </>
          )}
        </Button> */}
      </div>
      <div className="flex justify-center items-center div-bg-color border border-gray-200 space-y-5 p-1">
        <div className="w-full grid grid-cols-1 gap-1">
          <div className="w-full">
            <PbSection
              id="step-3"
              label="네트워크 공사 작업 내역"
              rightForm={
                <>
                  {/* 수정 상태 */}
                  {dcmnDntfNmbr && useModifyMode && (
                    <div className="right-btn !space-x-2">
                      <Button
                        variant="outline"
                        color="gray"
                        radius="xl"
                        onClick={handleNetworkDeleteRowButton_onClick}
                      >
                        -행 삭제
                      </Button>
                      <Button
                        variant="outline"
                        color="gray"
                        radius="xl"
                        onClick={handleNetworkModifyButton_onClick}
                      >
                        +행 추가
                      </Button>
                    </div>
                  )}
                </>
              }
            >
              {/* 네트워크 공사 작업 내역 */}
              <div className="relative w-full h-52">
                <PbAgGridReact
                  refs={networkListTableRef}
                  columnDefs={networkListTable.column}
                  rowData={networkListTable.data}
                  // onRowClicked={handleNonRegisterProjectTable_onClickRow}
                  defaultColDef={{
                    initialWidth: 150,
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    cellRendererParams: {
                      DatePickerInput: true,
                    } as IGroupCellRendererParams,
                  }}
                  sizeColumnsToFit={true}
                  rowSelection="single"
                  overlayNoRowsTemplate="데이터가 없습니다."
                  className="ag-theme-alpine"
                ></PbAgGridReact>
              </div>
            </PbSection>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center bg-sky-50 border border-gray-200 space-y-5 p-1">
        <div className="w-full grid grid-cols-1 gap-1">
          <div className="w-full">
            <PbSection
              id="step-4"
              label="ESL 설치 작업 내역"
              rightForm={
                <>
                  {/* 수정 상태 */}
                  {dcmnDntfNmbr && useModifyMode && (
                    <div className="right-btn !space-x-2">
                      <Button
                        variant="outline"
                        color="gray"
                        radius="xl"
                        onClick={handleEslSetupDeleteRowButton_onClick}
                      >
                        -행 삭제
                      </Button>
                      <Button
                        variant="outline"
                        color="gray"
                        radius="xl"
                        onClick={handleEslSetUpModifyButton_onClick}
                      >
                        +행 추가
                      </Button>
                    </div>
                  )}
                </>
              }
            >
              {/* ESL 설치 작업 내역 */}
              <div className="relative w-full h-52">
                <PbAgGridReact
                  refs={eslSetUpListTableRef}
                  columnDefs={eslSetUpListTable.column}
                  rowData={eslSetUpListTable.data}
                  // onRowClicked={handleNonRegisterProjectTable_onClickRow}
                  defaultColDef={{
                    initialWidth: 150,
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    cellRendererParams: {
                      DatePickerInput: true,
                      TextInput: true,
                    } as IGroupCellRendererParams,
                  }}
                  sizeColumnsToFit={true}
                  rowSelection="multiple"
                  overlayNoRowsTemplate="데이터가 없습니다."
                  className="ag-theme-alpine"
                ></PbAgGridReact>
              </div>
            </PbSection>
          </div>
          <div className="w-full">
            <PbSection label="설치 계획 품목">
              {/* 설치 계획 품목 */}
              <div className="relative w-full h-52">
                <PbAgGridReact
                  columnDefs={setUpPlanListTable.column}
                  rowData={setUpPlanListTable.data}
                  // onRowClicked={handleSearchResultTable_onClickRow}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  sizeColumnsToFit={true}
                  rowSelection="single"
                  overlayNoRowsTemplate="데이터가 없습니다."
                  className="ag-theme-alpine"
                ></PbAgGridReact>
              </div>
            </PbSection>
          </div>
        </div>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 신규 상태 */}
        {!dcmnDntfNmbr && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            {/* <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveButton_onClick}
            >
              저장
            </Button> */}
          </>
        )}

        {/* 조회 상태 */}
        {dcmnDntfNmbr && !useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              hidden={_.isEmpty(pmNameInput) ? true : false}
              onClick={handleGoModifyButton_onClick}
            >
              수정 전환
            </Button>
          </>
        )}

        {/* 수정 상태 */}
        {dcmnDntfNmbr && useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveUpdateButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220351;
