import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Progress,
  RingProgress,
  Select,
  TextInput,
} from '@mantine/core';
import { useDownloadExcel } from 'react-export-table-to-excel';
import {
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IApiResult,
  IOptionItem,
  IPageContent,
  IModal,
  IComponentModal,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { pageContentStore } from '../stores/page.store';
import { PageLayout } from '../components/PageLayout';
import { PbButton } from '../components/PbButton';
import { PbTable } from '../components/PbTable';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { PbInputText } from '../components/PbInputText';
import { PbDatePicker } from '../components/PbDatePicker';
import * as cmnApi from '../apis/cmn.api';
import * as csApi from '../apis/cs.api';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import * as slsApi from '../apis/sls.api';
import { MonthPickerInput, DatePickerInput } from '@mantine/dates';
import { AgGridReact } from 'ag-grid-react';
import { availableActions } from 'hygen';
import { LoadingTableDataIcon } from '../components/LoadingTableDataIcon';
import sleep from 'sleep-promise';
import { nanoid } from 'nanoid';
import { MN2302220502 } from '../components/MN2302220502';
import { addComponentModalStore } from '../stores/componentModal.store';
import PbSelect from '../components/PbSelect/PbSelect.component';

/**
 * 운영 관리 > CS 현황
 * @constructor
 */

// 검색 폼 그리드 > 검색어 > 셀렉트를 정의함
let valSearchCategorySelect: string = '';

// 검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
let valSearchInput: string = '';

// 검색 폼 그리드 > 상세검색의 나타냄을 정의함
let valHideDetailSearch: boolean = false;

// 검색 폼 그리드 > 등록기간 > 날짜 피커를 정의함
let valSearchRegDatePicker: [Date | null, Date | null] = [null, null];

// 검색 폼 그리드 > 처리완료요청기간 > 날짜 피커를 정의함
let valSearchProcessingCompletionRequestDatePicker: [Date | null, Date | null] =
  [null, null];

// 검색 폼 그리드 > 접수유형 > 접수유형 1 셀렉트를 정의함
let valSearchReceptionType1Select: string = '';

// 검색 폼 그리드 > 접수유형 > 접수유형 2 셀렉트를 정의함
let valSearchReceptionType2Select: string = '';

// 검색 폼 그리드 > 장애구분 > 셀렉트를 정의함
let valSearchObstacleTypeSelect: string = '';

// 검색 폼 그리드 > 고객특성 > 셀렉트를 정의함
let valSearchCustomerCharacteristicsSelect: string = '';

// 검색 폼 그리드 > 현재담당부서 > 셀렉트를 정의함
let valSearchCurrentDepartmentSelect: string = '';

// 검색 폼 그리드 > CS작업상태 > 셀렉트를 정의함
let valSearchCsWorkStateSelect: string = '';

// 검색 폼 그리드 > CS진행상태 > 셀렉트를 정의함
let valSearchCsProcessStateSelect: string = '';

// 검색 결과 테이블 > 정렬 셀렉트를 정의함
let valSearchResultTableSortSelect: string = '';

const MN2302220501Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // CS 현황을 정의함
  const [csStatus, setCsStatus] = useState<
    {
      name: string;
      proceeding: number;
      completed: number;
      untreated: number;
    }[]
  >([]);

  // CS 접수유형을 정의함
  const [csReceptionType, setCsReceptionType] = useState<
    {
      id: string;
      name: string;
      count: number;
    }[]
  >([]);

  // 검색 폼 그리드 > 검색어 > 셀렉트를 정의함
  const [searchCategorySelect, setSearchCategorySelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  // 검색 폼 그리드 > 등록기간 > 날짜 피커를 정의함
  const [searchRegDatePicker, setSearchRegDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  // 검색 폼 그리드 > 처리완료요청기간 > 날짜 피커를 정의함
  const [
    searchProcessingCompletionRequestDatePicker,
    setSearchProcessingCompletionRequestDatePicker,
  ] = useState<[Date | null, Date | null]>([null, null]);

  // 검색 폼 그리드 > 접수유형 > 접수유형 1 셀렉트를 정의함
  const [searchReceptionType1Select, setSearchReceptionType1Select] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 폼 그리드 > 접수유형 > 접수유형 2 셀렉트를 정의함
  const [searchReceptionType2Select, setSearchReceptionType2Select] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 폼 그리드 > 장애구분 > 셀렉트를 정의함
  const [searchObstacleTypeSelect, setSearchObstacleTypeSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 폼 그리드 > 고객특성 > 셀렉트를 정의함
  const [
    searchCustomerCharacteristicsSelect,
    setSearchCustomerCharacteristicsSelect,
  ] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 현재담당부서 > 셀렉트를 정의함
  const [searchCurrentDepartmentSelect, setSearchCurrentDepartmentSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 폼 그리드 > CS작업상태 > 셀렉트를 정의함
  const [searchCsWorkStateSelect, setSearchCsWorkStateSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 폼 그리드 > CS진행상태 > 셀렉트를 정의함
  const [searchCsProcessStateSelect, setSearchCsProcessStateSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 결과 테이블 > 정렬 셀렉트를 정의함
  const [searchResultTableSortSelect, setSearchResultTableSortSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 결과 테이블을 정의함
  const [searchResultTable, setSearchResultTable] = useState<{
    column: any;
    data: any;
    loading?: boolean;
  }>({ column: [], data: [], loading: false });

  // 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCategorySelect_onChange = (event: any) => {
    valSearchCategorySelect = event;
    setSearchCategorySelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    valSearchInput = event.currentTarget.value;
    setSearchInput(event.currentTarget.value);
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력에서 키를 입력함
  const handleSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
  const handleSearchButton_onChange = () => {
    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      loading: true,
    }));

    // 검색 폼 그리드 > 현재담당부서 > 셀렉트 값에 적용함
    valSearchCurrentDepartmentSelect = '';
    setSearchCurrentDepartmentSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();
  };

  // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
  const handleSearchCancelButton_onChange = () => {
    // 검색 폼 그리드 > 검색어 > 셀렉트 값을 초기화함
    valSearchCategorySelect = 'NQRY_NAME';
    setSearchCategorySelect((pre: ISelect) => ({
      ...pre,
      value: 'NQRY_NAME',
    }));

    // 검색 폼 그리드 > 검색어 > 검색어 입력 값을 초기화함
    valSearchInput = '';
    setSearchInput('');

    // 검색 폼 그리드 > 등록기간 > 날짜 피커 값을 초기화함
    valSearchRegDatePicker = [null, null];
    setSearchRegDatePicker([null, null]);

    // 검색 폼 그리드 > 처리완료요청기간 > 날짜 피커 값을 초기화함
    valSearchProcessingCompletionRequestDatePicker = [null, null];
    setSearchProcessingCompletionRequestDatePicker([null, null]);

    // 검색 폼 그리드 > 접수유형 > 접수유형 1 셀렉트 값을 초기화함
    valSearchReceptionType1Select = '';
    setSearchReceptionType1Select((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 장애구분 > 셀렉트 값을 초기화함
    valSearchObstacleTypeSelect = '';
    setSearchObstacleTypeSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 고객특성 > 셀렉트 값을 초기화함
    valSearchCustomerCharacteristicsSelect = '';
    setSearchCustomerCharacteristicsSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 현재담당부서 > 셀렉트 값을 초기화함
    valSearchCurrentDepartmentSelect = '';
    setSearchCurrentDepartmentSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > CS작업상태 > 셀렉트 값을 초기화함
    valSearchCsWorkStateSelect = '';
    setSearchCsWorkStateSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > CS진행상태 > 셀렉트 값을 초기화함
    valSearchCsProcessStateSelect = '';
    setSearchCsProcessStateSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 현재담당부서 > 셀렉트 값에 적용함
    valSearchCurrentDepartmentSelect = '';
    setSearchCurrentDepartmentSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));
  };

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    valHideDetailSearch = !hideDetailSearch;
    setHideDetailSearch(!hideDetailSearch);
  };

  // 검색 폼 그리드 > 등록기간 > 날짜 피커를 변경함
  const handleSearchRegDatePicker_onChange = (event: any) => {
    valSearchRegDatePicker = event;
    setSearchRegDatePicker(event);
  };

  // 검색 폼 그리드 > 처리완료요청기간 > 날짜 피커를 변경함
  const handleSearchProcessingCompletionRequestDatePicker_onChange = (
    event: any,
  ) => {
    valSearchProcessingCompletionRequestDatePicker = event;
    setSearchProcessingCompletionRequestDatePicker(event);
  };

  // 검색 폼 그리드 > 접수유형 > 접수유형 1 셀렉트에서 선택한 아이템을 변경함
  const handleSearchReceptionType1Select_onChange = (event: any) => {
    valSearchReceptionType1Select = event;
    setSearchReceptionType1Select((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 접수유형 > 접수유형 2 셀렉트에서 선택한 아이템을 변경함
  const handleSearchReceptionType2Select_onChange = (event: any) => {
    valSearchReceptionType2Select = event;
    setSearchReceptionType2Select((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 장애구분 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchObstacleTypeSelect_onChange = (event: any) => {
    valSearchObstacleTypeSelect = event;
    setSearchObstacleTypeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 고객특성 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCustomerCharacteristicsSelect_onChange = (event: any) => {
    valSearchCustomerCharacteristicsSelect = event;
    setSearchCustomerCharacteristicsSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 현재담당부서 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCurrentDepartmentSelect_onChange = (event: any) => {
    valSearchCurrentDepartmentSelect = event;
    setSearchCurrentDepartmentSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > CS작업상태 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCsWorkStateSelect_onChange = (event: any) => {
    valSearchCsWorkStateSelect = event;
    setSearchCsWorkStateSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > CS진행상태 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCsProcessStateSelect_onChange = (event: any) => {
    valSearchCsProcessStateSelect = event;
    setSearchCsProcessStateSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 결과 테이블의 엑셀 다운로드 버튼을 클릭함
  const handleSearchResultTableExportExcelButton_onClick = () => {
    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <>
          {/* 버튼 */}
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              csApi
                .getCsExcel({
                  csDprtCode: searchCurrentDepartmentSelect.value,
                  csSttsCode: searchCsWorkStateSelect.value,
                  csSttsCode2: searchCsProcessStateSelect.value,
                  cstmTrbtCode: searchCustomerCharacteristicsSelect.value,
                  cstyCode: searchReceptionType2Select.value,
                  cstyGrpCode: searchReceptionType1Select.value,
                  currPageIdx: '1',
                  dsblDntfCode: searchObstacleTypeSelect.value,
                  rgstStDt: searchRegDatePicker[0]
                    ? moment(searchRegDatePicker[0]).format('YYYYMMDD')
                    : '',
                  rgstEnDate: searchRegDatePicker[1]
                    ? moment(searchRegDatePicker[1]).format('YYYYMMDD')
                    : '',
                  prcsRqstStDate: searchProcessingCompletionRequestDatePicker[0]
                    ? moment(
                        searchProcessingCompletionRequestDatePicker[0],
                      ).format('YYYYMMDD')
                    : '',
                  prcsRqstEnDate: searchProcessingCompletionRequestDatePicker[1]
                    ? moment(
                        searchProcessingCompletionRequestDatePicker[1],
                      ).format('YYYYMMDD')
                    : '',
                  searchKeyword: searchInput.trim(),
                  searchType: searchCategorySelect.value || '',
                  order: searchResultTableSortSelect.value,
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    ['CS_', moment().format('YYYYMMDD'), '.xlsx'].join(''),
                  );
                  document.body.appendChild(link);
                  link.click();
                });

              setRemoveModal(true);
            }}
          >
            예
          </Button>
        </>
      ),
    });
  };

  // 검색 결과 테이블 > 정렬 셀렉트에서 선택한 아이템을 변경함
  const handleSearchResultTableSortSelect_onChange = (event: any) => {
    valSearchResultTableSortSelect = event;
    setSearchResultTableSortSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));

    // 정렬 순서를 정의함
    let tmpSortType: string[] = event.split(',');

    // 데이터를 정렬함
    let tmpData: any[] = _.orderBy(
      searchResultTable.data,
      [tmpSortType[0]],
      [tmpSortType[1] === 'desc' ? 'desc' : 'asc'],
    );

    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmpData,
    }));
  };

  // 부서별 현황 총합을 클릭함
  const handleSelectedCsStatus_onClick = (param: string) => {
    // 검색 폼 그리드 > 현재담당부서 > 셀렉트 아이템에 해당하는 값을 불러옴
    let tmpValue: string =
      _.find(searchCurrentDepartmentSelect.item, {
        label: param,
      })?.value || '';

    // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
    handleSearchCancelButton_onChange();

    // 검색 폼 그리드 > 현재담당부서 > 셀렉트 값에 적용함
    valSearchCurrentDepartmentSelect = tmpValue;
    setSearchCurrentDepartmentSelect((pre: ISelect) => ({
      ...pre,
      value: tmpValue,
    }));

    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      loading: true,
    }));

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();
  };

  // 접수유형별 현황 총합을 클릭함
  const handleSelectedReceptionType1_onClick = (param: string) => {
    // 검색 폼 그리드 > 접수유형 > 접수유형 1 셀렉트 아이템에 해당하는 값을 불러옴
    let tmpValue: string =
      _.find(searchReceptionType1Select.item, {
        label: param,
      })?.value || '';

    // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
    handleSearchCancelButton_onChange();

    // 검색 폼 그리드 > 현재담당부서 > 셀렉트 값에 적용함
    valSearchReceptionType1Select = tmpValue;
    setSearchReceptionType1Select((pre: ISelect) => ({
      ...pre,
      value: tmpValue,
    }));

    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      loading: true,
    }));

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();
  };

  // CS 접수 통계 데이터를 불러옴
  const getCssStats = () => {
    // 데이터를 생성함
    let tmpData: any[] = [];

    // 상세 공통코드(CS_DPRT: 팀)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'CS_DPRT' })
      .then((data: IApiResult) => {
        tmpData = _.sortBy(data.data.list, ['sortRdr']);

        csApi
          .getCssStats({
            csMngrName: loginUser.name,
          })
          .then((data: IApiResult) => {
            if (data.data.list !== undefined) {
              // 데이터를 생성함
              let tmpData2: any[] = [];
              tmpData.map((item: any) => {
                let tmpData3: any = _.find(data.data.list, {
                  csDprtCode: item.dtlCodeId,
                });

                if (tmpData3 !== undefined) {
                  tmpData2.push({
                    name: item.dtlCodeDscr,
                    proceeding: tmpData3.csIngCnt || 0,
                    completed: tmpData3.csEndCnt || 0,
                    untreated: tmpData3.csNotCnt || 0,
                  });
                } else {
                  tmpData2.push({
                    name: item.dtlCodeDscr,
                    proceeding: 0,
                    completed: 0,
                    untreated: 0,
                  });
                }
              });

              // CS 현황에 적용함
              setCsStatus(tmpData2);
            }
          });
      });
  };

  // CS 접수 유형 통계 데이터를 불러옴
  const getCssStatsDtl = () => {
    // 데이터를 생성함
    let tmpData: any[] = [];

    csApi.getCssStatsDtl({}).then((data: IApiResult) => {
      if (data.data.list !== undefined) {
        data.data.list.map((item: any, index: number) => {
          tmpData.push({
            id: index,
            name: item.cstyGrpName,
            count: +item.cstyGrpCnt,
          });
        });

        // CS 접수유형에 적용함
        setCsReceptionType(tmpData);
      }
    });
  };

  // 검색 결과 테이블의 행을 클릭함
  const handleSearchResultTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'CS 상세',
      content: <MN2302220502 id={tmpId} csId={row.data.csId} />,
      size: 1500,
    });
  };

  // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
  const initSearchCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '문의명',
        value: 'NQRY_NAME',
      },
      {
        label: '현재담당부서',
        value: 'CS_DPRT_CODE',
      },
      {
        label: '현재담당자',
        value: 'CS_MNGR_ID',
      },
      {
        label: 'CS번호',
        value: 'CS_ID',
      },
    );

    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    valSearchCategorySelect = 'NQRY_NAME';
    setSearchCategorySelect((pre: ISelect) => ({
      value: 'NQRY_NAME',
      item: tmpOptionItem,
    }));
  };

  // 검색 폼 그리드 > 접수유형 > 접수유형 1 셀렉트의 아이템을 초기화함
  const initsearchReceptionType1Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // CS 접수유형 조회(셀렉트박스)를 불러옴
    csApi.getCsType({ depth: 1 }).then((data: IApiResult) => {
      data.data.list.map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });

      // 검색 폼 그리드 > 접수유형 > 접수유형 1 셀렉트의 아이템에 적용함
      valSearchReceptionType1Select = '';
      setSearchReceptionType1Select((pre: ISelect) => ({
        value: '',
        item: tmpOptionItem,
      }));
    });
  };

  // 검색 폼 그리드 > 접수유형 > 접수유형 2 셀렉트의 아이템을 초기화함
  const initsearchReceptionType2Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    if (_.isEmpty(searchReceptionType1Select.value)) {
    } else {
      // CS 접수유형 조회(셀렉트박스)를 불러옴
      csApi
        .getCsType({
          depth: 2,
          searchKeyword: searchReceptionType1Select.value,
        })
        .then((data: IApiResult) => {
          data.data.list.map((item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          });
        });
    }

    // 검색 폼 그리드 > 접수유형 > 접수유형 2 셀렉트의 아이템에 적용함
    valSearchReceptionType2Select = '';
    setSearchReceptionType2Select({
      value: '',
      item: tmpOptionItem,
    });
  };

  // 검색 폼 그리드 > 장애구분 > 셀렉트의 아이템을 초기화함
  const initSearchObstacleTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(장애 구분)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'DSBL_CLSF' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 장애구분 > 셀렉트의 아이템에 적용함
        setSearchObstacleTypeSelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  // 검색 폼 그리드 > 고객특성 > 셀렉트의 아이템을 초기화함
  const initSearchCustomerCharacteristicsSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(고객 특성)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'CSTM_CHRC' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 고객특성 > 셀렉트의 아이템에 적용함
        setSearchCustomerCharacteristicsSelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  // 검색 폼 그리드 > 현재담당부서 > 셀렉트의 아이템을 초기화함
  const initSearchCurrentDepartmentSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(부서)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'CS_DPRT' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 현재담당부서 > 셀렉트의 아이템에 적용함
        setSearchCurrentDepartmentSelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  // 검색 폼 그리드 > CS작업상태 > 셀렉트의 아이템을 초기화함
  const initSearchCsWorkStateSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(CS 상태)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'CS_STTS' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > CS작업상태 > 셀렉트의 아이템에 적용함
        setSearchCsWorkStateSelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  // 검색 폼 그리드 > CS진행상태 > 셀렉트의 아이템을 초기화함
  const initSearchCsProcessStateSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '진행중',
        value: 'prcd',
      },
      {
        label: '완료',
        value: 'cmpl',
      },
      {
        label: '미처리',
        value: 'dly',
      },
    );

    // 검색 폼 그리드 > CS진행상태 > 셀렉트의 아이템에 적용함
    setSearchCsProcessStateSelect((pre: ISelect) => ({
      ...pre,
      item: tmpOptionItem,
    }));
  };

  // 검색 결과 테이블 > 정렬 셀렉트의 아이템을 초기화함
  const initSearchResultTableSortSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '최근 등록일',
        value: 'rgstDate',
      },
      {
        label: '리드타임 짧은 순',
        value: 'readTime',
      },
      // {
      //   label: '최근 등록일부터',
      //   value: 'rgstDate,desc',
      // },
      // {
      //   label: '예전 등록일부터',
      //   value: 'rgstDate,asc',
      // },
      // {
      //   label: '고객사명',
      //   value: 'bpName,asc',
      // },
      // {
      //   label: '계정',
      //   value: 'cnt,asc',
      // },
      // {
      //   label: '최근 프로젝트 완료일부터',
      //   value: 'stmtCmplDate,desc',
      // },
      // {
      //   label: '예전 프로젝트 완료일부터',
      //   value: 'stmtCmplDate,asc',
      // },
    );

    // 검색 결과 테이블 > 정렬 셀렉트의 아이템에 적용함
    valSearchResultTableSortSelect = 'rgstDate';
    setSearchResultTableSortSelect((pre: ISelect) => ({
      value: 'rgstDate',
      item: tmpOptionItem,
    }));
  };

  // 검색 결과 테이블을 초기화함
  const initSearchResultTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '접수유형',
        children: [
          { headerName: '그룹', field: 'cstyGrpName' },
          { headerName: '분류', field: 'cstyName' },
        ],
      },
      { headerName: '장애구분', field: 'dsblDntfName', width: 100 },
      { headerName: '문의명', field: 'nqryName', width: 400 },
      { headerName: 'Shop', field: 'shopId', width: 200 },
      { headerName: '고객특성', field: 'cstmTrbtName', width: 100 },
      { headerName: '현재담당부서', field: 'nowDprtName' },
      { headerName: '현재담당자', field: 'nowMngrName' },
      { headerName: '콜센터담당자', field: 'calCntrName' },
      { headerName: '운영팀담당자', field: 'prtnTeamName' },
      { headerName: '개발팀담당자', field: 'dvlpTeamName' },
      { headerName: '현장팀담당자', field: 'fldTeamName' },
      { headerName: 'CS작업상태', field: 'csSttsName', width: 120 },
      { headerName: 'CS진행상태', field: 'csWrkCode', width: 120 },
      { headerName: '리드타임', field: 'readTime', width: 100 },
      { headerName: '처리완료요청일', field: 'prcsRqstDate' },
      { headerName: '등록일시', field: 'rgstDate', suppressSizeToFit: true },
    );

    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 검색 결과 테이블 데이터를 불러옴
  const getSearchResultTableData = () => {
    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: any; data: any }) => ({
      ...pre,
      loading: true,
    }));

    csApi
      .getCss({
        csDprtCode: valSearchCurrentDepartmentSelect,
        csSttsCode: valSearchCsWorkStateSelect,
        csSttsCode2: valSearchCsProcessStateSelect,
        cstmTrbtCode: valSearchCustomerCharacteristicsSelect,
        cstyCode: valSearchReceptionType2Select,
        cstyGrpCode: valSearchReceptionType1Select,
        currPageIdx: '',
        dsblDntfCode: valSearchObstacleTypeSelect,
        rgstStDt: valSearchRegDatePicker[0]
          ? moment(valSearchRegDatePicker[0]).format('YYYYMMDD')
          : '',
        rgstEnDate: valSearchRegDatePicker[1]
          ? moment(valSearchRegDatePicker[1]).format('YYYYMMDD')
          : '',
        prcsRqstStDate: valSearchProcessingCompletionRequestDatePicker[0]
          ? moment(valSearchProcessingCompletionRequestDatePicker[0]).format(
              'YYYYMMDD',
            )
          : '',
        prcsRqstEnDate: valSearchProcessingCompletionRequestDatePicker[1]
          ? moment(valSearchProcessingCompletionRequestDatePicker[1]).format(
              'YYYYMMDD',
            )
          : '',
        searchKeyword: valSearchInput.trim(),
        searchType: valSearchCategorySelect || '',
        pageSize: 1000,
        order: valSearchResultTableSortSelect,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setSearchResultTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
              loading: false,
            }),
          );
        }
      })
      .catch((error: any) => {
        // 검색 결과 테이블에 적용함
        setSearchResultTable((pre: { column: ITableColumn[]; data: any }) => ({
          ...pre,
          loading: false,
        }));
      });
    // csApi
    //   .getCss({
    //     csDprtCode: searchCurrentDepartmentSelect.value,
    //     csSttsCode: searchCsWorkStateSelect.value,
    //     csSttsCode2: searchCsProcessStateSelect.value,
    //     cstmTrbtCode: searchCustomerCharacteristicsSelect.value,
    //     cstyCode: searchReceptionType2Select.value,
    //     cstyGrpCode: searchReceptionType1Select.value,
    //     currPageIdx: '',
    //     dsblDntfCode: searchObstacleTypeSelect.value,
    //     rgstStDt: searchRegDatePicker[0]
    //       ? moment(searchRegDatePicker[0]).format('YYYYMMDD')
    //       : '',
    //     rgstEnDate: searchRegDatePicker[1]
    //       ? moment(searchRegDatePicker[1]).format('YYYYMMDD')
    //       : '',
    //     prcsRqstStDate: searchProcessingCompletionRequestDatePicker[0]
    //       ? moment(searchProcessingCompletionRequestDatePicker[0]).format(
    //           'YYYYMMDD',
    //         )
    //       : '',
    //     prcsRqstEnDate: searchProcessingCompletionRequestDatePicker[1]
    //       ? moment(searchProcessingCompletionRequestDatePicker[1]).format(
    //           'YYYYMMDD',
    //         )
    //       : '',
    //     searchKeyword: searchInput.trim(),
    //     searchType: searchCategorySelect.value || '',
    //     pageSize: 1000,
    //     order: searchResultTableSortSelect.value,
    //   })
    //   .then((data: IApiResult) => {
    //     if (data.data.list !== undefined) {
    //       // 검색 결과 테이블에 적용함
    //       setSearchResultTable(
    //         (pre: { column: ITableColumn[]; data: any }) => ({
    //           ...pre,
    //           data: data.data.list,
    //           loading: false,
    //         }),
    //       );
    //     }
    //   })
    //   .catch((error: any) => {
    //     // 검색 결과 테이블에 적용함
    //     setSearchResultTable((pre: { column: ITableColumn[]; data: any }) => ({
    //       ...pre,
    //       loading: false,
    //     }));
    //   });
  };

  useEffect(() => {
    // CS 접수 통계 데이터를 불러옴
    getCssStats();

    // CS 접수 유형 통계 데이터를 불러옴
    getCssStatsDtl();

    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
    initSearchCategorySelect();

    // 검색 폼 그리드 > 접수유형 > 접수유형 1 셀렉트의 아이템을 초기화함
    initsearchReceptionType1Select();

    // 검색 폼 그리드 > 접수유형 > 접수유형 2 셀렉트의 아이템을 초기화함
    initsearchReceptionType2Select();

    // 검색 폼 그리드 > 장애구분 > 셀렉트의 아이템을 초기화함
    initSearchObstacleTypeSelect();

    // 검색 폼 그리드 > 고객특성 > 셀렉트의 아이템을 초기화함
    initSearchCustomerCharacteristicsSelect();

    // 검색 폼 그리드 > 현재담당부서 > 셀렉트의 아이템을 초기화함
    initSearchCurrentDepartmentSelect();

    // 검색 폼 그리드 > CS작업상태 > 셀렉트의 아이템을 초기화함
    initSearchCsWorkStateSelect();

    // 검색 폼 그리드 > CS진행상태 > 셀렉트의 아이템을 초기화함
    initSearchCsProcessStateSelect();

    // 검색 결과 테이블 > 정렬 셀렉트의 아이템을 초기화함
    initSearchResultTableSortSelect();

    // 검색 결과 테이블을 초기화함
    initSearchResultTable();

    return () => {};
  }, []);

  // 검색 폼 그리드 > 접수유형 > 접수유형 1 셀렉트에서 선택한 아이템이 변경될 때 실행함
  useEffect(() => {
    // 검색 폼 그리드 > 접수유형 > 접수유형 2 셀렉트의 아이템을 초기화함
    initsearchReceptionType2Select();

    return () => {};
  }, [searchReceptionType1Select.value]);

  // 검색 결과 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (searchResultTable.column.length === 0) {
      return;
    }

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();

    return () => {};
  }, [searchResultTable.column]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220503Table') > -1) {
      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220503Table');
    }

    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-10">
        {/* 현황 */}
        <div className="h-92 flex space-x-5">
          {/* 왼쪽 현황 */}
          <div className="w-3/5 p-1 flex justify-center items-center div-bg-color border border-gray-200">
            <div className="w-full grid grid-cols-4 gap-1">
              {/* 전체 */}
              {csStatus.length > 0 && (
                <div className="object-appear relative row-span-2 py-1 bg-white border border-gray-200 rounded-md space-y-3">
                  {/* 이름 */}
                  <div className="flex justify-center items-center">
                    <div>
                      <span className="text-xl font-bold">전체</span>
                    </div>
                  </div>

                  {/* 링 차트 */}
                  <div className="flex justify-center items-center">
                    <RingProgress
                      size={130}
                      label={
                        <div className="flex justify-center items-center">
                          <span className="text-xl text-indigo-800 font-bold">
                            {_.sumBy(csStatus, 'proceeding') +
                              _.sumBy(csStatus, 'completed') +
                              _.sumBy(csStatus, 'untreated')}
                            건
                          </span>
                        </div>
                      }
                      sections={[
                        {
                          value: _.sumBy(csStatus, 'proceeding'),
                          color: 'lime',
                        },
                        {
                          value: _.sumBy(csStatus, 'completed'),
                          color: 'indigo',
                        },
                        {
                          value: _.sumBy(csStatus, 'untreated'),
                          color: 'pink',
                        },
                      ]}
                    />
                  </div>

                  <div className="absolute bottom-4 left-0 w-full px-1">
                    {/* 진행중 */}
                    <div className="flex justify-center items-center">
                      <div className="flex-none w-12">
                        <span className="text-sm text-gray-500 font-bold">
                          진행중
                        </span>
                      </div>

                      <div className="grow">
                        <Progress
                          size="xl"
                          radius="xs"
                          color="lime"
                          label={_.sumBy(csStatus, 'proceeding').toString()}
                          value={
                            (_.sumBy(csStatus, 'proceeding') * 100) /
                            (_.sumBy(csStatus, 'proceeding') +
                              _.sumBy(csStatus, 'completed') +
                              _.sumBy(csStatus, 'untreated'))
                          }
                        />
                      </div>
                    </div>

                    {/* 완료 */}
                    <div className="flex justify-center items-center">
                      <div className="flex-none w-12">
                        <span className="text-sm text-gray-500 font-bold">
                          완료
                        </span>
                      </div>

                      <div className="grow">
                        <Progress
                          size="xl"
                          radius="xs"
                          color="indigo"
                          label={_.sumBy(csStatus, 'completed').toString()}
                          value={
                            (_.sumBy(csStatus, 'completed') * 100) /
                            (_.sumBy(csStatus, 'proceeding') +
                              _.sumBy(csStatus, 'completed') +
                              _.sumBy(csStatus, 'untreated'))
                          }
                        />
                      </div>
                    </div>

                    {/* 미처리 */}
                    <div className="flex justify-center items-center">
                      <div className="flex-none w-12">
                        <span className="text-sm text-gray-500 font-bold">
                          미처리
                        </span>
                      </div>

                      <div className="grow">
                        <Progress
                          size="xl"
                          radius="xs"
                          color="pink"
                          label={_.sumBy(csStatus, 'untreated').toString()}
                          value={
                            (_.sumBy(csStatus, 'untreated') * 100) /
                            (_.sumBy(csStatus, 'proceeding') +
                              _.sumBy(csStatus, 'completed') +
                              _.sumBy(csStatus, 'untreated'))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* 부서별 */}
              {csStatus.map((item: any, index: number) => (
                <div
                  key={index}
                  className="object-appear p-1 bg-white border border-gray-200 rounded-md space-y-3"
                >
                  {/* 이름 */}
                  <div className="flex justify-between items-center space-x-3">
                    <div className="leading-none truncate">
                      <span className="text-xl font-bold">{item.name}</span>
                    </div>

                    {/* 총합 링크 */}
                    <div
                      onClick={() => handleSelectedCsStatus_onClick(item.name)}
                      className="button-event"
                    >
                      <span className="text-3xl text-gray-500 font-bold underline underline-offset-4 decoration-indigo-300">
                        {item.proceeding + item.completed + item.untreated}
                      </span>
                    </div>
                  </div>

                  <div>
                    {/* 진행중 */}
                    <div className="flex justify-center items-center">
                      <div className="flex-none w-12">
                        <span className="text-sm text-gray-500 font-bold">
                          진행중
                        </span>
                      </div>

                      <div className="grow">
                        <Progress
                          size="xl"
                          radius="xs"
                          color="lime"
                          label={item.proceeding}
                          value={
                            (item.proceeding * 100) /
                            (item.proceeding + item.completed + item.untreated)
                          }
                        />
                      </div>
                    </div>

                    {/* 완료 */}
                    <div className="flex justify-center items-center">
                      <div className="flex-none w-12">
                        <span className="text-sm text-gray-500 font-bold">
                          완료
                        </span>
                      </div>

                      <div className="grow">
                        <Progress
                          size="xl"
                          radius="xs"
                          color="indigo"
                          label={item.completed}
                          value={
                            (item.completed * 100) /
                            (item.proceeding + item.completed + item.untreated)
                          }
                        />
                      </div>
                    </div>

                    {/* 미처리 */}
                    <div className="flex justify-center items-center">
                      <div className="flex-none w-12">
                        <span className="text-sm text-gray-500 font-bold">
                          미처리
                        </span>
                      </div>

                      <div className="grow">
                        <Progress
                          size="xl"
                          radius="xs"
                          color="pink"
                          label={item.untreated}
                          value={
                            (item.untreated * 100) /
                            (item.proceeding + item.completed + item.untreated)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* 오른쪽 현황 */}
          <div className="w-2/5 p-1 flex justify-center items-start div-bg-color border border-gray-200">
            <div className="w-full grid grid-cols-3 gap-1">
              {csReceptionType.map((item: any, index: number) => (
                <div
                  key={index}
                  className="object-appear p-1 bg-white border border-gray-200 rounded-md space-y-3"
                >
                  {/* 이름 */}
                  <div className="flex justify-between items-center space-x-3">
                    <div className="leading-none truncate">
                      <span className="text-xl font-bold">{item.name}</span>
                    </div>

                    {/* 총합 링크 */}
                    <div
                      onClick={() =>
                        handleSelectedReceptionType1_onClick(item.name)
                      }
                      className="button-event"
                    >
                      <span className="text-3xl text-gray-500 font-bold underline underline-offset-4 decoration-indigo-300">
                        {item.count}
                      </span>
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <div className="grow">
                      <Progress
                        size="xl"
                        radius="xs"
                        color="indigo"
                        label={item.count}
                        value={
                          (item.count * 100) /
                          _.maxBy(csReceptionType, 'count')!.count
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid cols={2}>
            <PbFormGridCol label="검색어" colSpan={2}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchCategorySelect_onChange}
                data={searchCategorySelect.item}
                value={searchCategorySelect.value}
                setSelect={setSearchCategorySelect}
              />

              {/* 텍스트 입력 */}
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={handleSearchInput_onChange}
                onKeyUp={handleSearchInput_onKeyUp}
                value={searchInput}
                className="w-1/5"
              />

              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleSearchButton_onChange}
                >
                  검색
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchCancelButton_onChange}
                >
                  검색 조건 초기화
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleShowSearchDetailButton_onChange}
                >
                  {hideDetailSearch ? (
                    <>
                      상세검색 열기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-down']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      상세검색 닫기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-up']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  )}
                </Button>
              </div>
            </PbFormGridCol>
            <PbFormGridCol label="등록기간" isHidden={hideDetailSearch}>
              {/* 년월 피커 */}
              <DatePickerInput
                type="range"
                placeholder="기간을 선택하세요."
                onChange={handleSearchRegDatePicker_onChange}
                value={searchRegDatePicker}
                valueFormat="YYYY.MM.DD"
                locale="ko"
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="처리완료요청기간" isHidden={hideDetailSearch}>
              {/* 년월 피커 */}
              <DatePickerInput
                type="range"
                placeholder="기간을 선택하세요."
                onChange={
                  handleSearchProcessingCompletionRequestDatePicker_onChange
                }
                value={searchProcessingCompletionRequestDatePicker}
                valueFormat="YYYY.MM.DD"
                locale="ko"
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="접수유형" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchReceptionType1Select_onChange}
                data={searchReceptionType1Select.item}
                value={searchReceptionType1Select.value}
                setSelect={setSearchReceptionType1Select}
                className="w-1/2"
              />

              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchReceptionType2Select_onChange}
                data={searchReceptionType2Select.item}
                value={searchReceptionType2Select.value}
                setSelect={setSearchReceptionType2Select}
                className="w-1/2"
              />
            </PbFormGridCol>
            <PbFormGridCol label="장애구분" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchObstacleTypeSelect_onChange}
                data={searchObstacleTypeSelect.item}
                value={searchObstacleTypeSelect.value}
                setSelect={setSearchObstacleTypeSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="고객특성" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchCustomerCharacteristicsSelect_onChange}
                data={searchCustomerCharacteristicsSelect.item}
                value={searchCustomerCharacteristicsSelect.value}
                setSelect={setSearchCustomerCharacteristicsSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="현재담당부서" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchCurrentDepartmentSelect_onChange}
                data={searchCurrentDepartmentSelect.item}
                value={searchCurrentDepartmentSelect.value}
                setSelect={setSearchCurrentDepartmentSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="CS작업상태" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchCsWorkStateSelect_onChange}
                data={searchCsWorkStateSelect.item}
                value={searchCsWorkStateSelect.value}
                setSelect={setSearchCsWorkStateSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="CS진행상태" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchCsProcessStateSelect_onChange}
                data={searchCsProcessStateSelect.item}
                value={searchCsProcessStateSelect.value}
                setSelect={setSearchCsProcessStateSelect}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 테이블 */}
          <div className="space-y-3">
            <div className="flex justify-between items-center space-x-5">
              <div>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchResultTableExportExcelButton_onClick}
                >
                  엑셀 다운로드
                </Button>
              </div>

              <div className="flex justify-center items-center space-x-5">
                <div className="flex justify-center items-center">
                  <span className="text-base text-gray-600 font-bold">
                    총 {searchResultTable.data.length}건
                  </span>
                </div>

                {/* 셀렉트 */}
                <div>
                  {/* 셀렉트 */}
                  <PbSelect
                    // onChange={handleSearchResultTableSortSelect_onChange}
                    data={searchResultTableSortSelect.item}
                    value={searchResultTableSortSelect.value}
                    setSelect={setSearchResultTableSortSelect}
                  />
                </div>
              </div>
            </div>

            {/* 테이블 */}
            <div className="relative w-full h-152">
              {/* 로딩 중 아이콘 */}
              <LoadingTableDataIcon visible={searchResultTable.loading} />

              <AgGridReact
                columnDefs={searchResultTable.column}
                rowData={searchResultTable.data}
                onRowDoubleClicked={handleSearchResultTable_onRowDoubleClicked}
                defaultColDef={{
                  initialWidth: 150,
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                  suppressSizeToFit: true,
                }}
                rowSelection="single"
                overlayNoRowsTemplate="데이터가 없습니다."
                className="ag-theme-alpine"
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220501Page;
