import React, { PropsWithChildren, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbMonthPicker } from '../PbMonthPicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModalPageScrollTabStep,
  IComponentModalPageScrollTab,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as sttnApi from '../../apis/sttn.api';
import _ from 'lodash';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  Select,
  Radio,
  Checkbox,
  Input,
  Modal,
  PasswordInput,
  TextInput,
  Group,
  Tabs,
  Progress,
} from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import { number } from 'prop-types';
import { IGroupCellRendererParams } from 'ag-grid-community';
import { ValueFormatterParams } from 'ag-grid-community';
import { PbAgGridReact } from '../PbAgGridReact';
import * as appUtil from '../../utils/app.util';

interface IComponentProps {
  id: string;
  prjcCode?: string;
  cntrNmbr?: string;
  dcmnDntfNmbr?: string;
  data?: any;
  onClick?: () => void;
}

/**
 * ESL 설치작업 진척 현황 상세
 * @param id 화면 아이디
 * @param prjcCode 프로젝트 아이디
 * @param cntrNmbr 계약번호
 * @param dcmnDntfNmbr 주문번호
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220452 = ({
  id = '',
  prjcCode = '',
  cntrNmbr = '',
  dcmnDntfNmbr = '',
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 프로젝트 정보
   */

  // 프로젝트 정보
  const [eslPrjcStatus, setEslPrjcStatus] = useState<{
    bpCode: string;
    bpName: string;
    fctvEndDate: string;
    fctvStrtDate: string;
    prjcCode: string;
    prjcName: string;
  }>();

  // 프로젝트명
  const [prjcNameInput, setPrjcNameInput] = useState<string>('');

  // 프로젝트 코드
  const [prjcCodeInput, setPrjcCodeInput] = useState<string>('');

  // 프로젝트 일정
  const [prjcPeriodInput, setPrjcPeriodInput] = useState<string>('');

  // 고객사명
  const [bpNameInput, setBpNameInput] = useState<string>('');

  // 고객사코드
  const [bpCodeInput, setBpCodeInput] = useState<string>('');

  /* //  */

  /**
   * 프로젝트 통계
   */

  const [eslStatusTable, setEslStatusTable] = useState<{
    column: any;
    data: any;
    loading?: boolean;
  }>({ column: [], data: [], loading: false });

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    tmpTab = [
      {
        label: '프로젝트',
        targetId: 'step-1',
      },
      {
        label: '주/월간 현황',
        targetId: 'step-2',
      },
      {
        label: '진행현황',
        targetId: 'step-3',
      },
    ];

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 검색 결과 테이블을 초기화함
  const initEslStatusTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '계획 Shop',
        field: 'totalCnt',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: '계획 미수립',
        field: 'planNcmpCnt',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '계획 수립',
        field: 'planCmplCnt',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Billing 미완료',
        field: 'blngNcmpCnt',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Billing 완료',
        field: 'blngCmplCnt',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '진척도(%)',
        field: 'progressPercent',
        width: 130,
        cellRenderer: (params: any) => {
          return (
            <div className="w-full row-span-2 col-span-3 space-y-3">
              <div className="space-y-3">
                {/* 실적 */}
                <div className=" grid grid-cols-3 gap-1 items-center space-x-3">
                  <div className="relative">
                    <span className="text-md">실적</span>
                  </div>
                  <div className="col-span-2 relative">
                    {params.data.cmplPcntg}
                  </div>
                </div>
              </div>
              {/* Billing */}
              <div className="space-y-3">
                <div className=" grid grid-cols-3 gap-1 items-center space-x-3">
                  <div className="relative w-80">
                    <span className="text-md">Billing</span>
                  </div>
                  <div className="col-span-2 relative">
                    {params.data.billPcntg}
                  </div>
                </div>
              </div>
            </div>
          );
        },
        suppressSizeToFit: true,
      },
      {
        headerName: '그래프',
        field: 'progressGraph',
        cellRenderer: (params: any) => {
          return (
            <div className="w-full row-span-2 space-y-3 align-middle p-1">
              <div className="space-y-3">
                {/* 실적 - 회색 : 계획 Shop수, 파랑 : 빌링완료 + 빌링 미완료 */}
                {/* (빌링미완료 + 빌링완료) / 전체 */}
                <Progress
                  size="xl"
                  radius="xs"
                  color="indigo"
                  value={_.toNumber(
                    _.toNumber(params.data.totalCnt) !== 0
                      ? _.multiply(
                          _.divide(
                            _.add(
                              _.toNumber(params.data.blngCmplCnt),
                              _.toNumber(params.data.blngNcmpCnt),
                            ),
                            _.toNumber(params.data.totalCnt),
                          ),
                          100,
                        ).toFixed(1)
                      : 0,
                  )}
                />
              </div>
              {/* Billing */}
              <div className="space-y-3">
                <div className="">
                  {/* Billing - 회색 : 빌링완료 + 빌링 미완료, 파랑 : 빌링수 */}
                  {/* 빌링완료 / (빌링미완료 + 빌링완료) */}
                  <Progress
                    size="xl"
                    radius="xs"
                    color="indigo"
                    value={_.toNumber(
                      _.add(
                        _.toNumber(params.data.blngCmplCnt),
                        _.toNumber(params.data.blngNcmpCnt),
                      ) !== 0
                        ? _.multiply(
                            _.divide(
                              _.toNumber(params.data.blngCmplCnt),
                              _.add(
                                _.toNumber(params.data.blngCmplCnt),
                                _.toNumber(params.data.blngNcmpCnt),
                              ),
                            ),
                            100,
                          ).toFixed(1)
                        : 0,
                    )}
                  />
                </div>
              </div>
            </div>
          );
        },
      },
      {
        headerName: '프로젝트 일정',
        field: 'prjcDttm',
        width: 230,
        valueFormatter: (params: ValueFormatterParams) => {
          return `${moment(params.data.workStrtDttm).format(
            'YYYY-MM-DD',
          )} ~ ${moment(params.data.workEndDttm).format('YYYY-MM-DD')}`;
        },
        suppressSizeToFit: true,
      },
    );

    // 검색 결과 테이블에 적용함
    setEslStatusTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
      loading: false,
    }));
  };

  /* // 프로젝트 통계 */

  /**
   * 프로젝트 주간/월간 통계
   */

  // 진척현황 주간/월간 통계 정의
  const [prjcStatsCategoryTab, setPrjcStatsCategoryTab] =
    useState<string>('WEEK_STATS');

  // 진척현황 주간/월간 통계 이벤트를 정의
  const handlePrjcStatsCategoryTab_onChange = (event: any) => {
    setPrjcStatsCategoryTab(event);
  };

  // 프로젝트 정보 통계(주간) 테이블
  const [prjcStatsWeekList, setPrjcStatsWeekList] = useState<any[]>([]);

  // 프로젝트 정보 통계(주간) 테이블
  const [prjcStatsWeekTable, setPrjcStatsWeekTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 프로젝트 정보 통계(월간) 테이블
  const [prjcStatsMonthList, setPrjcStatsMonthList] = useState<any[]>([]);

  // 프로젝트 정보 통계(월간) 테이블
  const [prjcStatsMonthTable, setPrjcStatsMonthTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 주간 통계 정보 column 정의
  const eslPrcjWeekCategoryItem: { headerName: string; field: string }[] = [
    {
      headerName: '계획',
      field: 'allPlan',
    },
    {
      headerName: 'Billing 미완료',
      field: 'blngNcmp',
    },
    {
      headerName: 'Billing 완료',
      field: 'blngCmpl',
    },
  ];

  // 진척현황 전체 통계(주간) 테이블을 초기화함
  const initPrjcStatsWeekTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];
    let tmpWeekColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push({
      headerName: '구분',
      field: 'eslStats',
      align: 'center',
      pinned: 'left',
      width: 120,
      suppressSizeToFit: true,
    });

    let monthTxt = '';
    // 컬럼을 생성함
    _.orderBy(prjcStatsWeekList, ['yearMonth', 'weeks'], ['asc'])?.map(
      (item: any, index: number) => {
        if (_.isEqual(monthTxt, moment(item.yearMonth).format('yyyyMM'))) {
          // 같은 달은 한번만 column 생성을 위함
        } else {
          // 월 단위 column 생성
          tmpColumn.push({
            field: moment(monthTxt).format('yyyyMM'),
            headerName: moment(monthTxt).format('yyyy.MM'),
            align: 'center',
            width: 90,
            children: tmpWeekColumn, // 월에 포함된 주단위 column 생성
          });
          tmpWeekColumn = [];
        }

        // 주별 데이타 column 생성
        let tmpChildrenColumn = {
          field: `${moment(item.yearMonth).format('yyyyMM')}_${item.weeks}`,
          headerName: item.weeks,
          width: 60,
          cellRenderer: (params: any) => {
            return (
              <span
                className={[
                  (params.value === 0 || params.value === '0') &&
                    'text-blue-200',
                ].join(' ')}
              >
                {params.value}
              </span>
            );
          },
        };
        tmpWeekColumn.push(tmpChildrenColumn);

        monthTxt = moment(item.yearMonth).format('yyyyMM');
      },
    );

    // 데이터를 정의함
    let tmpData: any[] = [];
    // 데이터를 생성함
    eslPrcjWeekCategoryItem.map(
      (item: { headerName: string; field: string }, index: number) => {
        let tmpDataRow: any = {};

        tmpDataRow['eslStats'] = item.headerName;

        prjcStatsWeekList?.map((subItem: any, index: number) => {
          tmpDataRow[
            `${moment(subItem.yearMonth).format('yyyyMM')}_${subItem.weeks}`
          ] = subItem[item.field];
        });
        tmpData.push(tmpDataRow);
      },
    );

    // 검색 결과 테이블에 적용함
    setPrjcStatsWeekTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
      data: tmpData,
    }));
  };

  // 월간 통계 정보 column 정의
  const eslPrcjMonthCategoryItem: { headerName: string; field: string }[] = [
    {
      headerName: '계획',
      field: 'monthAllPlan',
    },
    {
      headerName: 'Billing 미완료',
      field: 'monthBlngCmpl',
    },
    {
      headerName: 'Billing 완료',
      field: 'monthBlngCmpl',
    },
  ];

  // 진척현황 전체 통계(월간) 테이블을 초기화함
  const initPrjcStatsMonthTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push({
      headerName: '구분',
      field: 'eslStats',
      align: 'center',
      pinned: 'left',
      width: 120,
      suppressSizeToFit: true,
    });

    // 컬럼을 생성함
    _.orderBy(prjcStatsMonthList, ['month'], ['asc'])?.map(
      (item: any, index: number) => {
        tmpColumn.push({
          field: moment(item.month).format('yyyyMM'),
          headerName: moment(item.month).format('yyyy.MM'),
          align: 'center',
          width: 100,
          cellRenderer: (params: any) => {
            return (
              <span
                className={[
                  (params.value === 0 || params.value === '0') &&
                    'text-blue-200',
                ].join(' ')}
              >
                {params.value}
              </span>
            );
          },
        });
      },
    );

    // 데이터를 정의함
    let tmpData: any[] = [];

    // 데이터를 생성함
    eslPrcjMonthCategoryItem.map(
      (item: { headerName: string; field: string }, index: number) => {
        let tmpDataRow: any = {};

        tmpDataRow['eslStats'] = item.headerName;

        prjcStatsMonthList?.map((subItem: any, index: number) => {
          tmpDataRow[moment(subItem.month).format('yyyyMM')] =
            subItem[item.field];
        });

        tmpData.push(tmpDataRow);
      },
    );

    // 검색 결과 테이블에 적용함
    setPrjcStatsMonthTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
      data: tmpData,
    }));
  };

  /* // 프로젝트 주간/월간 통계 */

  /**
   * 계획 수립 / Billing 미완료 / Billing 완료 현황 테이블
   */

  // 계획 수립 / Billing 미완료 / Billing 완료 Tab
  const [eslStatsCategoryTab, setEslStatsCategoryTab] =
    useState<string>('WORK_COMPLETE');

  // 계획 수립 / Billing 미완료 / Billing 완료 Tab
  const handleEslStatsCategoryTab_onChange = (event: any) => {
    setEslStatsCategoryTab(event);
  };

  // 계획 수립 테이블을 정의함
  const [workCompleteListTable, setWorkCompleteListTable] = useState<{
    column: any;
    data: any;
    loading?: boolean;
  }>({ column: [], data: [], loading: false });

  // Billing 미완료 테이블을 정의함
  const [billingNonCompleteListTable, setBillingNonCompleteListTable] =
    useState<{
      column: any;
      data: any;
      loading?: boolean;
    }>({ column: [], data: [], loading: false });

  // Billing 완료 테이블을 정의함
  const [billingCompleteListTable, setBillingCompleteListTable] = useState<{
    column: any;
    data: any;
    loading?: boolean;
  }>({ column: [], data: [], loading: false });

  // 계획 수립 테이블을 초기화함
  const initWorkCompleteTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '주문 번호',
        field: 'dcmnDntfNmbr',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Shop명',
        field: 'shipToCode',
      },
      {
        headerName: '협력사명',
        field: 'totalSplr',
      },
      {
        headerName: '설치 시작일',
        field: 'resultDate',
        width: 150,
        suppressSizeToFit: true,
      },
    );

    // 검색 결과 테이블에 적용함
    setWorkCompleteListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
      loading: false,
    }));
  };

  // Billing 미완료 테이블을 초기화함
  const initBillingNonCompleteTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '주문 번호',
        field: 'dcmnDntfNmbr',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Shop명',
        field: 'shipToCode',
      },
      {
        headerName: '협력사명',
        field: 'totalSplr',
      },
      {
        headerName: 'PM 검수 완료일',
        field: 'resultDate',
        width: 150,
        suppressSizeToFit: true,
      },
    );

    // 검색 결과 테이블에 적용함
    setBillingNonCompleteListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
      loading: false,
    }));
  };

  // Billing 완료 테이블을 초기화함
  const initBillingCompleteTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '주문 번호',
        field: 'dcmnDntfNmbr',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Shop명',
        field: 'shipToCode',
      },
      {
        headerName: '협력사명',
        field: 'totalSplr',
      },
      {
        headerName: 'Billing 완료일',
        field: 'resultDate',
        width: 150,
        suppressSizeToFit: true,
      },
    );

    // 검색 결과 테이블에 적용함
    setBillingCompleteListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
      loading: false,
    }));
  };

  /* // 계획 수립 / Billing 미완료 / Billing 완료 현황 테이블 */

  /**
   * 데이타 불러오기
   */

  // ESL 진척현황 상세 정보 불러오기
  const getEslDetailStatus = () => {
    // 상세 공통코드(CS_DPRT: 팀)를 불러옴
    sttnApi
      .getSttnEslDetail({
        cntrNmbr: cntrNmbr,
      })
      .then((data: IApiResult) => {
        // tmpData = _.sortBy(data.data.list, ['sortRdr']);

        // 프로젝트 정보 적용
        setEslPrjcStatus(data.data.prjcInfo);

        if (data.data.cntVo.length > 0) {
          setEslStatusTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.cntVo,
          }));
        }

        // 진척 주간 통계 데이타 적용
        setPrjcStatsWeekList(data.data.weekList);

        // 진척 월간 통계 데이타 적용
        setPrjcStatsMonthList(data.data.monList);
      });
  };

  // ESL 진척현황 상세 정보(계획 수립 / Billing 미완료 / Billing 완료) 불러오기
  const getSttnEslOrderList = (listType: string) => {
    // 진척현황 설치 정보 검색
    // 설치완료 : plan_cmpl, Billing 미완료 : blng_ncmp, Billing 완료 : blng_cmpl
    sttnApi
      .getSttnEslOrderList({
        cntrNmbr: cntrNmbr,
        dcmnDntfNmbr: dcmnDntfNmbr,
        searchType: listType,
      })
      .then((data: IApiResult) => {
        if (_.isEqual(listType, 'plan_cmpl')) {
          setWorkCompleteListTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.list,
            loading: false,
          }));
        } else if (_.isEqual(listType, 'blng_ncmp')) {
          setBillingNonCompleteListTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.list,
            loading: false,
          }));
        } else if (_.isEqual(listType, 'blng_cmpl')) {
          setBillingCompleteListTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.list,
            loading: false,
          }));
        }
      });
  };
  /* //  */

  /**
   * useEffect
   */

  // 최초 한번만 수행됨
  useEffect(() => {
    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    // ESL 진척현황 상세 정보
    getEslDetailStatus();

    // 통계 정보
    initEslStatusTable();

    // 계획 수립 / Billing 미완료 / Billing 완료 테이블 초기화
    initWorkCompleteTable();
    initBillingNonCompleteTable();
    initBillingCompleteTable();

    // 계획 수립 / Billing 미완료 / Billing 완료
    // 계획 수립 : plan_cmpl, Billing 미완료 : blng_ncmp, Billing 완료 : blng_cmpl
    getSttnEslOrderList('plan_cmpl');
    getSttnEslOrderList('blng_ncmp');
    getSttnEslOrderList('blng_cmpl');

    return () => {};
  }, []);

  // 프로젝트 정보 불러오기 성공시
  useEffect(() => {
    if (!eslPrjcStatus) {
      return;
    }

    // 프로젝트명
    setPrjcNameInput(eslPrjcStatus.prjcName);

    // 프로젝트 코드
    setPrjcCodeInput(eslPrjcStatus.prjcCode);

    // 프로젝트 일정
    setPrjcPeriodInput(
      eslPrjcStatus.fctvStrtDate + ' ~ ' + eslPrjcStatus.fctvEndDate,
    );

    // 고객사명
    setBpNameInput(eslPrjcStatus.bpName);

    // 고객사코드
    setBpCodeInput(eslPrjcStatus.bpCode);

    // 월간 통계 테이블
    initPrjcStatsWeekTable();

    // 주간 통계 테이블
    initPrjcStatsMonthTable();

    return () => {};
  }, [eslPrjcStatus]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  /* //  */

  return (
    <div className="space-y-7">
      {/* 프로젝트 정보 */}
      <div className="space-y-5">
        {/* 프로젝트 정보 폼 그리드 */}
        <PbFormGrid id="step-1" label="프로젝트 정보" cols={2}>
          <PbFormGridCol label="프로젝트명">
            <TextInput
              value={prjcNameInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="프로젝트 코드">
            <TextInput
              value={prjcCodeInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="프로젝트 일정" colSpan={2}>
            <TextInput
              value={prjcPeriodInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객사명">
            <TextInput
              value={bpNameInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객사 코드">
            <TextInput
              value={bpCodeInput}
              className="w-full"
              readOnly={true}
              variant={'unstyled'}
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>
      {/* 통계 */}
      <div className="space-y-5">
        {/* 테이블 */}
        <div className="w-full h-44">
          <PbAgGridReact
            columnDefs={eslStatusTable.column}
            rowData={eslStatusTable.data}
            defaultColDef={{
              resizable: true,
              sortable: true,
              wrapHeaderText: false,
              autoHeaderHeight: true,
            }}
            rowSelection="single"
            sizeColumnsToFit={true}
            rowHeight={92}
          />
        </div>
      </div>
      {/* 계획 지표 주간/월간 */}
      <div className="space-y-3">
        <PbSection id="step-2" label="">
          {/* 탭 */}
          <Tabs
            variant="outline"
            value={prjcStatsCategoryTab}
            onTabChange={handlePrjcStatsCategoryTab_onChange}
          >
            {/* 탭 헤더 */}
            <Tabs.List>
              <Tabs.Tab
                value="WEEK_STATS"
                icon={
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'calendar-week']}
                      className="w-4 h-4 text-gray-600"
                    />
                  </div>
                }
              >
                <span className="text-lg">주간</span>
              </Tabs.Tab>
              <Tabs.Tab
                value="MONTH_STATS"
                icon={
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={['far', 'calendar']}
                      className="w-4 h-4 text-gray-600"
                    />
                  </div>
                }
              >
                <span className="text-lg">월간</span>
              </Tabs.Tab>
            </Tabs.List>
            {/* 주간 현황 */}
            <Tabs.Panel value="WEEK_STATS" pt="xl">
              {/* 테이블 */}
              <div className="w-full h-72">
                <PbAgGridReact
                  columnDefs={prjcStatsWeekTable.column}
                  rowData={prjcStatsWeekTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={false}
                />
              </div>
            </Tabs.Panel>
            {/* 월간 현황 */}
            <Tabs.Panel value="MONTH_STATS" pt="xl">
              {/* 테이블 */}
              <div className="w-full h-60">
                <PbAgGridReact
                  columnDefs={prjcStatsMonthTable.column}
                  rowData={prjcStatsMonthTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={false}
                />
              </div>
            </Tabs.Panel>
          </Tabs>
        </PbSection>
      </div>
      <div className="space-y-5">
        <PbSection id="step-3" label="">
          {/* 탭 */}
          <Tabs
            variant="outline"
            value={eslStatsCategoryTab}
            onTabChange={handleEslStatsCategoryTab_onChange}
          >
            {/* 탭 헤더 */}
            <Tabs.List>
              <Tabs.Tab
                value="WORK_COMPLETE"
                icon={
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'circle-check']}
                      className="w-4 h-4 text-gray-600"
                    />
                  </div>
                }
              >
                <span className="text-lg">계획 수립 현황</span>
              </Tabs.Tab>
              <Tabs.Tab
                value="WORK_NON_COMPLETE"
                icon={
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'list-ol']}
                      className="w-4 h-4 text-gray-600"
                    />
                  </div>
                }
              >
                <span className="text-lg">Billing 미완료 현황</span>
              </Tabs.Tab>
              <Tabs.Tab
                value="BILLING_NON_COMPLETE"
                icon={
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'list-check']}
                      className="w-4 h-4 text-gray-600"
                    />
                  </div>
                }
              >
                <span className="text-lg">Billing 완료 현황</span>
              </Tabs.Tab>
            </Tabs.List>

            {/* 계획 수립 현황 */}
            <Tabs.Panel value="WORK_COMPLETE" pt="xl">
              {/* 테이블 */}
              <div className="w-full h-52">
                <PbAgGridReact
                  columnDefs={workCompleteListTable.column}
                  rowData={workCompleteListTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>
            </Tabs.Panel>
            {/* Billing 미완료 현황 */}
            <Tabs.Panel value="WORK_NON_COMPLETE" pt="xl">
              {/* 테이블 */}
              <div className="w-full h-52">
                <PbAgGridReact
                  columnDefs={billingNonCompleteListTable.column}
                  rowData={billingNonCompleteListTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>
            </Tabs.Panel>
            {/* BILLING 미완료 현황 */}
            <Tabs.Panel value="BILLING_NON_COMPLETE" pt="xl">
              {/* 테이블 */}
              <div className="w-full h-52">
                <PbAgGridReact
                  columnDefs={billingCompleteListTable.column}
                  rowData={billingCompleteListTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>
            </Tabs.Panel>
          </Tabs>
        </PbSection>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        <Button
          variant="outline"
          color="gray"
          radius="xl"
          size="md"
          onClick={() => setRemoveComponentModal(id)}
        >
          닫기
        </Button>
      </div>
    </div>
  );
};

export default MN2302220452;
