import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbInputText } from '../PbInputText';
import { PbButton } from '../PbButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbMonthPicker } from '../PbMonthPicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import prettyBytes from 'pretty-bytes';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  IComponentModalPageScrollTab,
  IComponentModalPageScrollTabStep,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PbDatePicker } from '../PbDatePicker';
import * as slsApi from '../../apis/sls.api';
import * as cmnApi from '../../apis/cmn.api';
import _ from 'lodash';
import { PbTable } from '../PbTable';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { MN2302220202_Manager } from '../MN2302220202_Manager';
import { PbInputNumber } from '../PbInputNumber';
import { addRefreshListStore } from '../../stores/refreshList.store';
import {
  Button,
  FileButton,
  NumberInput,
  Select,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { AgGridReact } from 'ag-grid-react';
import api from '../../utils/axios.util';
import { AxiosResponse } from 'axios';
import { getSlsCtvtFile, getSlsPrtnOnlySlsPrtnId } from '../../apis/sls.api';
import PbSection from '../PbSection/PbSection.component';
import * as appUtil from '../../utils/app.util';
import MN2302220202 from '../MN2302220202/MN2302220202.component';
import { PreviewImage } from '../PreviewImage';
import { getResizeImageUrl } from '../../utils/app.util';
import { decode } from 'html-entities';
import { overlayStore } from '../../stores/overlay.store';
import { notifications } from '@mantine/notifications';
import * as ntcbApi from '../../apis/ntcb.api';
import { PbAgGridReact } from '../PbAgGridReact';
import PbSelect from '../PbSelect/PbSelect.component';
import { ValueFormatterParams } from 'ag-grid-community';
import { FlexImageFilePreviewList } from '../FlexImageFilePreviewList';

interface IMN2302220204Props {
  id?: string;
  slsCtvtId?: string;
  slsPrtnId?: string;
  initSlsPrtnId?: string;
  useModifyMode?: boolean;
}

/**
 * 영업 관리 > 영업 활동 조회 > 등록, 조회, 수정
 * @param id 컴포넌트 모달 아이디
 * @param slsCtvtId 영업 활동 아이디
 * @param slsPrtnId 영업 기회 아이디
 * @param useModifyMode 수정 모드 사용 여부
 * @constructor
 */

let valSlsCtvtId: string = '';

const MN2302220204 = ({
  id = '',
  slsCtvtId = '',
  slsPrtnId = '',
  initSlsPrtnId = '',
  useModifyMode = false,
}: PropsWithChildren<IMN2302220204Props>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 화면 위 검은 화면 저장소를 정의함
  const [visibleOverlay, setVisibleOverlay] =
    useRecoilState<boolean>(overlayStore);

  // 영업 활동 정보 폼 그리드 > 영업기회명 > 셀렉트를 정의함
  // const [salesOpportunitySelect, setSalesOpportunitySelect] = useState<ISelect>(
  //   {
  //     value: '',
  //     item: [],
  //   },
  // );
  const [salesOpportunitySelect, setSalesOpportunitySelect] =
    useState<string>('');
  const [salesOpportunitySelectItem, setSalesOpportunitySelectItem] = useState<
    IOptionItem[]
  >([]);
  const salesOpportunitySelectRef = useRef<HTMLInputElement>(null);

  // 영업 활동 정보 폼 그리드 > Market Type > 입력을 정의함
  const [marketTypeInput, setMarketTypeInput] = useState<string>('');

  // 영업 활동 정보 폼 그리드 > 담당자(내부) > 입력을 정의함
  const [managerInsideNameInput, setManagerInsideNameInput] =
    useState<string>('');

  // 영업 활동 정보 폼 그리드 > 담당자(고객사) > 입력을 정의함
  const [managerClientNameInput, setManagerClientNameInput] =
    useState<string>('');

  // 영업 활동 정보 폼 그리드 > 협력사 > 입력을 정의함
  const [salesPartnerInput, setSalesPartnerInput] = useState<string>('');

  // 영업 활동 정보 폼 그리드 > 영업단계 > 셀렉트를 정의함
  // const [salesWorkStepSelect, setSalesWorkStepSelect] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [salesWorkStepSelect, setSalesWorkStepSelect] = useState<string>('');
  const [salesWorkStepSelectItem, setSalesWorkStepSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 영업 활동 정보 폼 그리드 > 확도 > 셀렉트를 정의함
  // const [probabilitySelect, setProbabilitySelect] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [probabilitySelect, setProbabilitySelect] = useState<string>('');
  const [probabilitySelectItem, setProbabilitySelectItem] = useState<
    IOptionItem[]
  >([]);

  // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트를 정의함
  // const [salesWorkProposalResultSelect, setSalesWorkProposalResultSelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });
  const [salesWorkProposalResultSelect, setSalesWorkProposalResultSelect] =
    useState<string>('');
  const [
    salesWorkProposalResultSelectItem,
    setSalesWorkProposalResultSelectItem,
  ] = useState<IOptionItem[]>([]);
  const salesWorkProposalResultSelectRef = useRef<HTMLInputElement>(null);

  // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트의 읽기전용 여부를 정의함
  const [
    readOnlySalesWorkProposalResultSelect,
    setReadOnlySalesWorkProposalResultSelect,
  ] = useState<boolean>(true);

  // 영업 활동 정보 폼 그리드 > 제안결과내용 > 입력을 정의함
  const [
    salesWorkProposalResultContentInput,
    setSalesWorkProposalResultContentInput,
  ] = useState<string>('');
  const salesWorkProposalResultContentInputRef = useRef<HTMLInputElement>(null);

  // 영업 기회 상세 데이터를 정의함
  const [slsData, setSlsData] = useState<any>(null);

  // SWOT 분석 폼 그리드 > Strength > 입력을 정의함
  const [swotStrengthInput, setSwotStrengthInput] = useState<string>('');

  // SWOT 분석 폼 그리드 > Weakness > 입력을 정의함
  const [swotWeaknessInput, setSwotWeaknessInput] = useState<string>('');

  // SWOT 분석 폼 그리드 > Opportunities > 입력을 정의함
  const [swotOpportunitiesInput, setSwotOpportunitiesInput] =
    useState<string>('');

  // SWOT 분석 폼 그리드 > Threats > 입력을 정의함
  const [swotThreatsInput, setSwotThreatsInput] = useState<string>('');

  // 영업 내용 폼 그리드 > 영업활동일 > 날짜 피커를 정의함
  const [salesWorkDatePicker, setSalesWorkDatePicker] = useState<Date | null>(
    null,
  );

  // 영업 내용 폼 그리드 > 중요도 > 셀렉트를 정의함
  // const [importantTypeSelect, setImportantTypeSelect] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [importantTypeSelect, setImportantTypeSelect] = useState<string>('');
  const [importantTypeSelectItem, setImportantTypeSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 영업 내용 폼 그리드 > 내용 > 입력을 정의함
  const [salesContentInput, setSalesContentInput] = useState<string>('');
  const salesContentInputRef = useRef<HTMLTextAreaElement>(null);

  // 첨부 파일 섹션 > 테이블을 정의함
  const [fileTable, setFileTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 첨부 파일 섹션 > 미리보기 테이블을 정의함
  const [previewFileImages, setPreviewFileImages] = useState<any[]>([]);

  // 태그 정보 폼 그리드 > 태그 > 입력을 정의함
  const [tagInput, setTagInput] = useState<string>('');

  // 최근 활동 폼 그리드 > 테이블을 정의함
  const [recentWorkTable, setRecentWorkTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 영업 활동 상세 데이터를 정의함
  const [slsCtvtData, setSlsCtvtData] = useState<any>(null);

  // 첨부 파일 섹션 > 테이블 참조를 정의함
  const fileTableRef = useRef<any>(null);

  // 첨부 파일 섹션 > 첨부 파일 버튼 참조를 정의함
  const resetFileSectionRef = useRef<any>(null);

  // 최근 활동 섹션 > 테이블 참조를 정의함
  const recentWorkTableRef = useRef<any>(null);

  // 최근 활동 섹션 > 테이블의 행을 클릭함
  const handleRecentWorkTable_onRowDoubleClicked = (event: any) => {
    // 조회일 때만 동작함
    if (!(slsCtvtId && !useModifyMode)) {
      return;
    }

    // 영업 활동 아이디를 변경함
    slsCtvtId = event.data.slsCtvtId;
    valSlsCtvtId = event.data.slsCtvtId;

    // // 컴포넌트 모달을 닫음
    // setRemoveComponentModal(id);
    //
    // // 조회 컴포넌트 모달을 추가함
    // let tmpId: string = nanoid();
    //
    // // 컴포넌트 모달을 추가함
    // setAddComponentModal({
    //   id: tmpId,
    //   title: '영업 활동 상세',
    //   content: <MN2302220204 id={tmpId} slsCtvtId={event.data.slsCtvtId} />,
    //   size: 1500,
    // });

    // 영업 활동 상세 데이터를 불러옴
    getSlsCtvtData();

    // 영업 활동 정보 폼 그리드 > 영업기회명 > 셀렉트의 아이템을 초기화함
    initSalesOpportunitySelect();

    // 영업 활동 정보 폼 그리드 > 영업단계 > 셀렉트의 아이템을 초기화함
    initSalesWorkStepSelect();

    // 영업 활동 정보 폼 그리드 > 확도 > 셀렉트의 아이템을 초기화함
    initProbabilitySelect();

    // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트의 아이템을 초기화함
    initSalesWorkProposalResultSelect();

    // 영업 내용 폼 그리드 > 중요도 > 셀렉트의 아이템을 초기화함
    initImportantTypeSelect();

    // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블을 초기화함
    initFileTable();

    // 최근 활동 폼 그리드 > 최근 활동 > 테이블을 초기화함
    initRecentWorkTable();

    // // 영업기회상세 에서 등록이동 된 경우
    // if (slsCtvtId === '' && slsPrtnId) {
    //   setSalesOpportunitySelect((pre: ISelect) => ({
    //     ...pre,
    //     value: slsPrtnId,
    //   }));
    // }
  };

  // 첨부 파일 섹션 > 전체 삭제 버튼을 클릭함
  const handleFileSectionDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '전체 첨부파일을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 첨부 파일 섹션 > 테이블에 적용함
            setFileTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: [],
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 첨부 파일 섹션 > 선택 삭제 버튼을 클릭함
  const handleFileSectionSelectedDeleteButton_onClick = () => {
    // 삭제할 첨부 파일 아이디 목록을 정의함
    let selectedFileId: string[] = [];

    // 삭제할 첨부 파일 아이디 목록을 불러옴
    fileTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedFileId.push(item.fileId);
    });

    if (selectedFileId.length === 0) {
      setModal({
        title: '알림',
        content: '삭제할 첨부 파일을 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: '선택하신 첨부파일을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 첨부 파일 목록에서 삭제할 첨부 파일을 적용한 후 최종 목록을 정의함
            let tmlFileTableData: any = fileTable.data.filter(
              (item: any) => !_.includes(selectedFileId, item.fileId),
            );

            // 첨부 파일 섹션 > 테이블에 적용함
            setFileTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: tmlFileTableData,
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 첨부 파일 섹션 > 추가 버튼을 클릭함
  const handleFileSectionAddButton_onClick = (event: any) => {
    // 첨부 파일 목록을 불러옴
    let tmlFileTableData: any = _.cloneDeep(fileTable.data);

    // 첨부 파일 목록에 새로운 첨부 파일을 추가함
    tmlFileTableData.push({
      fileId: nanoid(),
      slsfClsfCode: 'ETC',
      slsfClsfName: '기타',
      rgnlFileName: event.name,
      pldFileName: '',
      fileSize: event.size,
      fileDscr: '',
      newFile: event,
    });

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlFileTableData,
    }));

    resetFileSectionRef.current?.();
  };

  // 컴포넌트 모달 > 저장 버튼(신규)을 클릭함
  const handleSaveAddButton_onClick = () => {
    // 영업 활동 정보 폼 그리드 > 영업기회명 > 입력을 확인함
    if (salesOpportunitySelect === '') {
      setModal({
        title: '알림',
        content: 'Project Name을 입력해 주세요.',
      });

      salesOpportunitySelectRef.current?.focus();
      return;
    }

    // 영업 활동 정보 폼 그리드 > 영업단계 > Decided 의 경우
    if (salesWorkStepSelect === 'DCDD') {
      // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트를 확인함
      if (!salesWorkProposalResultSelect) {
        setModal({
          title: '알림',
          content: '제안결과를 선택해 주세요.',
        });

        salesWorkProposalResultSelectRef.current?.focus();
        return;
      }

      // 영업 활동 정보 폼 그리드 > 제안결과내용 > 입력을 확인함
      if (!salesWorkProposalResultContentInput.trim()) {
        setModal({
          title: '알림',
          content: '제안결과내용을 입력해 주세요.',
        });

        salesWorkProposalResultContentInputRef.current?.focus();
        return;
      }
    }

    // 영업 내용 폼 그리드 > 영업활동일 > 날짜 피커를 확인함
    if (!salesWorkDatePicker) {
      setModal({
        title: '알림',
        content: '영업활동일을 입력해 주세요.',
      });

      let obj = document.querySelector(
        '.sales-work-date-picker button',
      ) as HTMLButtonElement;
      obj.focus();

      return;
    }

    // 영업 내용 폼 그리드 > 내용 > 입력을 확인함
    if (!salesContentInput.trim()) {
      setModal({
        title: '알림',
        content: '영업 내용을 입력해 주세요.',
      });

      salesContentInputRef.current?.focus();
      return;
    }

    // 기존 첨부파일 아이디를 불러옴
    let tmpPreFileId: string[] = [];

    // 기존 첨부파일 설명을 불러옴
    let tmpPreFileDescriptions: string[] = [];

    // 신규 첨부파일을 불러옴
    let tmpNewFile: any[] = [];

    // 신규 첨부파일 구분을 불러옴
    let tmpNewSlsfClsfCodeList: string[] = [];

    // 신규 첨부파일 설명을 불러옴
    let tmpNewFileDescriptions: string[] = [];

    // 신규 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile !== null)
      .map((item: any) => {
        tmpNewFile.push(item.newFile);
        tmpNewSlsfClsfCodeList.push(item.slsfClsfCode);
        tmpNewFileDescriptions.push(item.fileDscr);
      });

    // 첨부파일의 설명을 확인함
    let checkFileDescriptions: boolean = false;

    fileTable.data.map((item: any) => {
      if (!item.fileDscr.trim()) {
        checkFileDescriptions = true;
      }
    });

    // if (checkFileDescriptions) {
    //   setModal({
    //     title: '알림',
    //     content: '첨부 파일의 설명을 입력해 주세요.',
    //   });

    //   return;
    // }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            // 화면 위의 검은 화면 출력 여부에 적용함
            setVisibleOverlay(true);

            notifications.show({
              title: '알림',
              message: '저장 중입니다.',
            });

            setRemoveModal(true);

            // 영업 활동 등록을 처리함
            slsApi
              .postSlsCtvt({
                slsPrtnId: salesOpportunitySelect,
                slsStgCode: salesWorkStepSelect,
                slsPrbbCode: probabilitySelect,
                sgstRsltCode: salesWorkProposalResultSelect,
                prpsRslt: salesWorkProposalResultContentInput.trim(),
                swotStrn: swotStrengthInput.trim(),
                swotWkns: swotWeaknessInput.trim(),
                swotPrtn: swotOpportunitiesInput.trim(),
                swotThrt: swotThreatsInput.trim(),
                slsCtvtDate: salesWorkDatePicker
                  ? moment(salesWorkDatePicker).format('YYYYMMDD')
                  : '',
                slsMprtCode: importantTypeSelect,
                slsDtls: salesContentInput.trim(),
                prtnName: salesPartnerInput.trim(),
                tag: tagInput.trim(),
                bfFileIdList: tmpPreFileId,
                bfFileDscrList: tmpPreFileDescriptions,
                fileList: tmpNewFile,
                fileDscrList: tmpNewFileDescriptions,
                slsfClsfCodeList: tmpNewSlsfClsfCodeList,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  // 화면 위의 검은 화면 출력 여부에 적용함
                  setVisibleOverlay(false);

                  notifications.show({
                    title: '알림',
                    message: '저장하였습니다.',
                  });

                  // 목록 새로고침을 추가함
                  setAddRefreshList('MN2302220205Table');

                  // 컴포넌트 모달을 닫음
                  setRemoveComponentModal(id);

                  // setModal({
                  //   title: '알림',
                  //   content: '저장하였습니다.',
                  //   callback: () => {
                  //     // 목록 새로고침을 추가함
                  //     setAddRefreshList('MN2302220205Table');
                  //
                  //     // 컴포넌트 모달을 닫음
                  //     setRemoveComponentModal(id);
                  //   },
                  // });
                } else {
                  // 화면 위의 검은 화면 출력 여부에 적용함
                  setVisibleOverlay(false);

                  setModal({
                    title: '오류',
                    content: (
                      <>
                        <div>저장에 실패하였습니다.</div>
                        <div>({data.message})</div>
                      </>
                    ),
                  });
                }
              })
              .catch((error: any) => {
                // 화면 위의 검은 화면 출력 여부에 적용함
                setVisibleOverlay(false);

                setModal({
                  title: '오류',
                  content: `저장에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 컴포넌트 모달 > 저장 버튼(수정)을 클릭함
  const handleSaveUpdateButton_onClick = () => {
    console.log('> salesOpportunitySelect:', salesOpportunitySelect);

    // 영업 활동 정보 폼 그리드 > 영업기회명 > 입력을 확인함
    if (salesOpportunitySelect === '' || salesOpportunitySelect === null) {
      setModal({
        title: '알림',
        content: 'Project Name을 입력해 주세요.',
      });

      salesOpportunitySelectRef.current?.focus();
      return;
    }

    // 영업 활동 정보 폼 그리드 > 영업단계 > Decided 의 경우
    if (salesWorkStepSelect === 'DCDD') {
      // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트를 확인함
      if (!salesWorkProposalResultSelect) {
        setModal({
          title: '알림',
          content: '제안결과를 선택해 주세요.',
        });

        salesWorkProposalResultSelectRef.current?.focus();
        return;
      }

      // 영업 활동 정보 폼 그리드 > 제안결과내용 > 입력을 확인함
      if (!salesWorkProposalResultContentInput.trim()) {
        setModal({
          title: '알림',
          content: '제안결과내용을 입력해 주세요.',
        });

        salesWorkProposalResultContentInputRef.current?.focus();
        return;
      }
    }

    // SWOT 분석 폼 그리드 > Strength > 입력을 확인함
    // if (!swotStrengthInput.trim()) {
    //   setModal({
    //     title: '알림',
    //     content: 'SWOT Strength를 입력해 주세요.',
    //   });
    //   return;
    // }

    // SWOT 분석 폼 그리드 > Weakness > 입력을 확인함
    // if (!swotWeaknessInput.trim()) {
    //   setModal({
    //     title: '알림',
    //     content: 'SWOT Weakness를 입력해 주세요.',
    //   });
    //   return;
    // }

    // SWOT 분석 폼 그리드 > Opportunities > 입력을 확인함
    // if (!swotOpportunitiesInput.trim()) {
    //   setModal({
    //     title: '알림',
    //     content: 'SWOT Opportunities를 입력해 주세요.',
    //   });
    //   return;
    // }

    // SWOT 분석 폼 그리드 > Threats > 입력을 확인함
    // if (!swotThreatsInput.trim()) {
    //   setModal({
    //     title: '알림',
    //     content: 'SWOT Threats를 입력해 주세요.',
    //   });
    //   return;
    // }

    // 영업 내용 폼 그리드 > 영업활동일 > 날짜 피커를 확인함
    if (!salesWorkDatePicker) {
      setModal({
        title: '알림',
        content: '영업활동일을 입력해 주세요.',
      });

      let obj = document.querySelector(
        '.sales-work-date-picker button',
      ) as HTMLButtonElement;
      obj.focus();

      return;
    }

    // 영업 내용 폼 그리드 > 내용 > 입력을 확인함
    if (!salesContentInput.trim()) {
      setModal({
        title: '알림',
        content: '영업 내용을 입력해 주세요.',
      });

      salesContentInputRef.current?.focus();
      return;
    }

    // 태그 정보 폼 그리드 > 태그 > 입력을 확인함
    // if (!tagInput.trim()) {
    //   setModal({
    //     title: '알림',
    //     content: '태그를 입력해 주세요.',
    //   });
    //   return;
    // }

    // 기존 첨부파일 아이디를 불러옴
    let tmpPreFileId: string[] = [];

    // 기존 첨부파일 설명을 불러옴
    let tmpPreFileDescriptions: string[] = [];

    // 기존 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile === null)
      .map((item: any) => {
        tmpPreFileId.push(item.fileId);
        tmpPreFileDescriptions.push(item.fileDscr);
      });

    // 신규 첨부파일을 불러옴
    let tmpNewFile: any[] = [];

    // 신규 첨부파일 구분을 불러옴
    let tmpNewSlsfClsfCodeList: string[] = [];

    // 신규 첨부파일 설명을 불러옴
    let tmpNewFileDescriptions: string[] = [];

    // 신규 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile !== null)
      .map((item: any) => {
        tmpNewFile.push(item.newFile);
        tmpNewSlsfClsfCodeList.push(item.slsfClsfCode);
        tmpNewFileDescriptions.push(item.fileDscr);
      });

    // 첨부파일의 설명을 확인함
    let checkFileDescriptions: boolean = false;

    fileTable.data.map((item: any) => {
      if (!item.fileDscr.trim()) {
        checkFileDescriptions = true;
      }
    });

    // if (checkFileDescriptions) {
    //   setModal({
    //     title: '알림',
    //     content: '첨부 파일의 설명을 입력해 주세요.',
    //   });

    //   return;
    // }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            // 화면 위의 검은 화면 출력 여부에 적용함
            setVisibleOverlay(true);

            notifications.show({
              title: '알림',
              message: '저장 중입니다.',
            });

            setRemoveModal(true);

            // 영업 활동 수정을 처리함
            slsApi
              .putSlsCtvt({
                slsCtvtId: slsCtvtId,
                slsPrtnId: salesOpportunitySelect,
                slsStgCode: salesWorkStepSelect,
                slsPrbbCode: probabilitySelect,
                sgstRsltCode: salesWorkProposalResultSelect,
                prpsRslt: salesWorkProposalResultContentInput.trim(),
                swotStrn: swotStrengthInput.trim(),
                swotWkns: swotWeaknessInput.trim(),
                swotPrtn: swotOpportunitiesInput.trim(),
                swotThrt: swotThreatsInput.trim(),
                slsCtvtDate: salesWorkDatePicker
                  ? moment(salesWorkDatePicker).format('YYYYMMDD')
                  : '',
                slsMprtCode: importantTypeSelect,
                slsDtls: salesContentInput.trim(),
                prtnName: salesPartnerInput.trim(),
                tag: tagInput.trim(),
                bfFileIdList: tmpPreFileId,
                bfFileDscrList: tmpPreFileDescriptions,
                fileList: tmpNewFile,
                fileDscrList: tmpNewFileDescriptions,
                slsfClsfCodeList: tmpNewSlsfClsfCodeList,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  // 화면 위의 검은 화면 출력 여부에 적용함
                  setVisibleOverlay(false);

                  notifications.show({
                    title: '알림',
                    message: '저장하였습니다.',
                  });

                  // 목록 새로고침을 추가함
                  setAddRefreshList('MN2302220205Table');

                  // 컴포넌트 모달을 닫음
                  setRemoveComponentModal(id);

                  setTimeout(() => {
                    // 조회 컴포넌트 모달을 추가함
                    let tmpId: string = nanoid();

                    // 컴포넌트 모달을 추가함
                    setAddComponentModal({
                      id: tmpId,
                      title: '영업 활동 상세',
                      content: (
                        <MN2302220204 id={tmpId} slsCtvtId={slsCtvtId} />
                      ),
                      size: 1500,
                    });
                  }, 100);

                  // setModal({
                  //   title: '알림',
                  //   content: '저장하였습니다.',
                  //   callback: () => {
                  //     // 목록 새로고침을 추가함
                  //     setAddRefreshList('MN2302220205Table');
                  //
                  //     // 컴포넌트 모달을 닫음
                  //     setRemoveComponentModal(id);
                  //
                  //     setTimeout(() => {
                  //       // 조회 컴포넌트 모달을 추가함
                  //       let tmpId: string = nanoid();
                  //
                  //       // 컴포넌트 모달을 추가함
                  //       setAddComponentModal({
                  //         id: tmpId,
                  //         title: '영업 활동 상세',
                  //         content: (
                  //           <MN2302220204 id={tmpId} slsCtvtId={slsCtvtId} />
                  //         ),
                  //         size: 1500,
                  //       });
                  //     }, 100);
                  //   },
                  // });
                } else {
                  // 화면 위의 검은 화면 출력 여부에 적용함
                  setVisibleOverlay(false);

                  setModal({
                    title: '오류',
                    content: (
                      <>
                        <div>저장에 실패하였습니다.</div>
                        <div>({data.message})</div>
                      </>
                    ),
                  });
                }
              })
              .catch((error: any) => {
                // 화면 위의 검은 화면 출력 여부에 적용함
                setVisibleOverlay(false);

                setModal({
                  title: '오류',
                  content: `저장에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 컴포넌트 모달 > 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    setModal({
      title: '확인',
      content: '이 영업 활동을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 영업 활동을 삭제함
            slsApi
              .deleteSlsCtvt({
                slsCtvtId: slsCtvtId,
              })
              .then((data: IApiResult) => {
                switch (data.code) {
                  case '200':
                    // 모달을 출력함
                    setModal({
                      title: '알림',
                      content: '삭제하였습니다.',
                      callback: () => setRemoveComponentModal(id),
                    });
                    break;

                  case '':
                    // 모달을 출력함
                    setModal({
                      title: t('common.error'),
                      content: '삭제에 실패하였습니다.',
                    });
                    break;

                  default:
                    break;
                }
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 컴포넌트 모달 > 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    let tmlSlsCtvtId: string = valSlsCtvtId !== '' ? valSlsCtvtId : slsCtvtId;

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '영업 활동 수정',
        content: (
          <MN2302220204
            id={tmpId}
            slsCtvtId={tmlSlsCtvtId}
            useModifyMode={true}
          />
        ),
        size: 1500,
      });
    }, 100);
  };

  // 영업 활동 정보 폼 그리드 > 영업기회명 > 셀렉트에서 선택한 아이템을 변경함
  const handleSalesOpportunitySelect_onChange = (event: any) => {
    setSalesOpportunitySelect(event);
    // setSalesOpportunitySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));

    // 등록일 때만 기존 영업기회를 불러와서 기본값으로 적용함
    if (slsCtvtId === '') {
      getSlsPrtnData(event);
    }
  };

  // 영업 활동 정보 폼 그리드 > 협력사 > 입력의 값을 변경함
  const handleSalesPartnerInput_onChange = (event: any) => {
    setSalesPartnerInput(event.target.value);
  };

  // 영업 활동 정보 폼 그리드 > 영업단계 > 셀렉트에서 선택한 아이템을 변경함
  const handleSalesWorkStepSelect_onChange = (event: any) => {
    setSalesWorkStepSelect(event);
    // setSalesWorkStepSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 영업 활동 정보 폼 그리드 > 확도 > 셀렉트에서 선택한 아이템을 변경함
  const handleProbabilitySelect_onChange = (event: any) => {
    setProbabilitySelect(event);
    // setProbabilitySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트에서 선택한 아이템을 변경함
  const handleSalesWorkProposalResultSelect_onChange = (event: any) => {
    setSalesWorkProposalResultSelect(event);
    // setSalesWorkProposalResultSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 영업 활동 정보 폼 그리드 > 제안결과내용 > 입력의 값을 변경함
  const handleSalesWorkProposalResultContentInput_onChange = (event: any) => {
    setSalesWorkProposalResultContentInput(event.target.value);
  };

  /**
   * 프로젝트 정보
   */

  // 프로젝트 정보 폼 그리드 > 제안제출일 > 날짜 피커를 정의함
  // const [
  //   planProposalSubmissionDatePicker,
  //   setPlanProposalSubmissionDatePicker,
  // ] = useState<Date | null>(null);

  // 프로젝트 정보 폼 그리드 > 수주일 > 날짜 피커를 정의함
  // const [
  //   planEstimatedConfirmationDatePicker,
  //   setPlanEstimatedConfirmationDatePicker,
  // ] = useState<Date | null>(null);

  // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 날짜 피커를 정의함
  const [planEstimatedDatePicker, setPlanEstimatedDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  // 프로젝트 정보 폼 그리드 > 기간(월) > 입력을 정의함
  // const [planEstimatedMonthInput, setPlanEstimatedMonthInput] =
  //   useState<string>('');

  // 프로젝트 정보 폼 그리드 > 계약규모(원) > 입력을 정의함
  // const [salesContractSizeInput, setSalesContractSizeInput] =
  //   useState<string>('');

  // 프로젝트 정보 폼 그리드 > NSR규모(원) > 입력을 정의함
  // const [salesNsrSizeInput, setSalesNsrSizeInput] = useState<string>('');

  // 프로젝트 정보 폼 그리드 > NSR X 확도 > 입력을 정의함
  // const [salesNsrProbabilityInput, setSalesNsrProbabilityInput] =
  //   useState<string>('');

  // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블을 정의함
  const [salesEstimatedAmountTable, setSalesEstimatedAmountTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 프로젝트 정보 폼 그리드 > 제안제출일 > 날짜 피커를 변경함
  // const handlePlanProposalSubmissionDatePicker_onChange = (event: any) => {
  //   setPlanProposalSubmissionDatePicker(event);
  // };

  // 프로젝트 정보 폼 그리드 > 수주일 > 날짜 피커를 변경함
  // const handlePlanEstimatedConfirmationDatePicker_onChange = (event: any) => {
  //   setPlanEstimatedConfirmationDatePicker(event);
  // };

  // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 날짜 피커를 변경함
  // const handlePlanEstimatedDatePicker_onChange = (event: any) => {
  //   setPlanEstimatedDatePicker(event);
  // };

  // 프로젝트 정보 폼 그리드 > 계약규모(원) > 입력의 값을 변경함
  // const handleSalesContractSizeInput_onChange = (event: any) => {
  //   setSalesContractSizeInput(event);
  // };

  // 프로젝트 정보 폼 그리드 > NSR규모(원) > 입력의 값을 변경함
  // const handleSalesNsrSizeInput_onChange = (event: any) => {
  //   setSalesNsrSizeInput(event);
  // };

  // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블 > 컬럼의 값을 변경함
  // const handleSalesEstimatedAmountTable_onCellValueChanged = (event: any) => {
  //   let tmpData: any = [];

  //   event.node.parent.allLeafChildren.map((row: any, index: number) => {
  //     tmpData.push(row.data);
  //   });

  //   // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블의 셀 값을 계산함
  //   tmpData = sumSalesEstimatedAmountTableData(
  //     salesEstimatedAmountTable.column,
  //     tmpData,
  //   );

  //   // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블에 적용함
  //   setSalesEstimatedAmountTable((pre: { column: any; data: any }) => ({
  //     ...pre,
  //     data: tmpData,
  //   }));
  // };

  // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블의 셀 값을 계산함
  const sumSalesEstimatedAmountTableData = (column: any, data: any): any => {
    let tmpData: any = _.cloneDeep(data);

    // 국내 힙계를 초기화함
    tmpData[0]['total'] = 0;

    // 해외 힙계를 초기화함
    tmpData[1]['total'] = 0;

    // 총계를 초기화함
    tmpData[2]['total'] = 0;

    // 년월 컬럼마다 합계를 구함
    column
      .filter(
        (item: any) => item.field !== 'category' && item.field !== 'total',
      )
      .map((item: any, index: number) => {
        // 년월 컬럼마다 합계를 구함
        tmpData[2][item.field] =
          tmpData[0][item.field] + tmpData[1][item.field];

        // 국내 힙계를 구함
        tmpData[0]['total'] += +tmpData[0][item.field];

        // 해외 힙계를 구함
        tmpData[1]['total'] += +tmpData[1][item.field];

        // 총계를 구함
        tmpData[2]['total'] += +tmpData[2][item.field];
      });

    if (slsPrtnId && !useModifyMode) {
      // 계산된 값에 콤마를 추가함
      column
        .filter(
          (item: any) => item.field !== 'category' && item.field !== 'total',
        )
        .map((item: any, index: number) => {
          // 년월 컬럼마다 합계를 구함
          tmpData[2][item.field] = addComma(tmpData[2][item.field]);
          tmpData[1][item.field] = addComma(tmpData[1][item.field]);
          tmpData[0][item.field] = addComma(tmpData[0][item.field]);

          // 국내 힙계를 구함
          tmpData[0]['total'] = addComma(tmpData[0]['total']);

          // 해외 힙계를 구함
          tmpData[1]['total'] = addComma(tmpData[1]['total']);

          // 총계를 구함
          tmpData[2]['total'] = addComma(tmpData[2]['total']);
        });
    }

    return tmpData;
  };

  // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 날짜 피커를 초기화함
  const initPlanEstimatedDatePicker = () => {
    setPlanEstimatedDatePicker([null, null]);
  };

  // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블을 초기화함
  const initSalesEstimatedAmountTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 데이터를 정의함
    let tmpData: any[] = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '구분',
        field: 'category',
        width: 90,
        editable: false,
        pinned: 'left',
        suppressSizeToFit: true,
      },
      {
        headerName: '총계',
        field: 'total',
        width: 170,
        editable: false,
        pinned: 'left',
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) => getFormatNumber(params.value),
      },
    );

    // 데이터에 추가함
    tmpData.push(
      {
        category: '국내',
        total: 0,
      },
      {
        category: '해외',
        total: 0,
      },
      {
        category: '합계',
        total: 0,
      },
    );

    // 프로젝트기간에 모든 날짜가 선택되어있을 때, 기간별예상매출금액(원) 테이블을 출력함
    if (
      planEstimatedDatePicker[0] !== null &&
      planEstimatedDatePicker[1] !== null
    ) {
      // 프로젝트 시작 년월을 불러옴
      let tmpStartYear: number = +moment(planEstimatedDatePicker[0]).format(
        'YYYY',
      );
      let tmpStartMonth: number = +moment(planEstimatedDatePicker[0]).format(
        'MM',
      );

      // 프로젝트 완료 년월을 불러옴
      let tmpEndYear: number = +moment(planEstimatedDatePicker[1]).format(
        'YYYY',
      );
      let tmpEndMonth: number = +moment(planEstimatedDatePicker[1]).format(
        'MM',
      );

      // 프로젝트 기간(월)을 계산함
      let tmpBetweenMonth: number =
        (tmpEndYear - tmpStartYear) * 12 + (tmpEndMonth - tmpStartMonth) + 1;

      // 프로젝트 기간(월)만큼 컬럼을 추가함
      for (let i: number = 0; i < tmpBetweenMonth; i++) {
        // 날짜 이름으로 된 컬럼 이름을 생성함
        let tmpColumnName: string = moment(planEstimatedDatePicker[0])
          .add(i, 'M')
          .format('YYYY.MM');
        let tmpColumnField: string = moment(planEstimatedDatePicker[0])
          .add(i, 'M')
          .format('YYYYMM');

        // 컬럼에 추가함
        tmpColumn.push({
          headerName: tmpColumnName,
          field: tmpColumnField,
          width: 160,
          type: 'rightAligned',
          valueParser: (params: any) =>
            _.isNaN(_.toNumber(params.newValue))
              ? 0
              : _.toNumber(+params.newValue),
          valueFormatter: (params: any) => getFormatNumber(params.value),
        });

        // 데이터에 추가함
        tmpData[0][tmpColumnField] = 0;
        tmpData[1][tmpColumnField] = 0;
        tmpData[2][tmpColumnField] = 0;

        // 불러온 데이터가 있으면 해당 데이터를 적용함
        if (slsData.list) {
          slsData.list.map((item: any) => {
            let tmpBaseDateColumn: string = item.baseDate.replaceAll('.', '');

            switch (item.slsRvncCode) {
              // 국내
              case 'DMST':
                tmpData[0][tmpBaseDateColumn] = +item.mnt.replaceAll(',', '');
                break;

              // 해외
              case 'VRSS':
                tmpData[1][tmpBaseDateColumn] = +item.mnt.replaceAll(',', '');
                break;

              default:
                break;
            }
          });
        }
      }
    }

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블의 셀 값을 계산함
    tmpData = sumSalesEstimatedAmountTableData(tmpColumn, tmpData);

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블에 적용함
    setSalesEstimatedAmountTable((pre: { column: any; data: any }) => ({
      column: tmpColumn,
      data: tmpData,
    }));
  };

  // 숫자를 천단위 , 추가
  const addComma = (value: string) => {
    // return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value;
  };

  // 숫자에 콤마를 추가함
  const getFormatNumber = (number: any) => {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  /* // 프로젝트 정보 */

  // SWOT 분석 폼 그리드 > Strength > 입력의 값을 변경함
  const handleSwotStrengthInput_onChange = (event: any) => {
    let sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 1024) {
    //   // 1024 byte 제한
    //   setSwotStrengthInput((pre) => pre);
    // } else {
    //   setSwotStrengthInput(sVal);
    // }
    setSwotStrengthInput(sVal);
  };

  // SWOT 분석 폼 그리드 > Weakness > 입력의 값을 변경함
  const handleSwotWeaknessInput_onChange = (event: any) => {
    let sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 1024) {
    //   // 1024 byte 제한
    //   setSwotWeaknessInput((pre) => pre);
    // } else {
    //   setSwotWeaknessInput(sVal);
    // }
    setSwotWeaknessInput(sVal);
  };

  // SWOT 분석 폼 그리드 > Opportunities > 입력의 값을 변경함
  const handleSwotOpportunitiesInput_onChange = (event: any) => {
    let sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 1024) {
    //   // 1024 byte 제한
    //   setSwotOpportunitiesInput((pre) => pre);
    // } else {
    //   setSwotOpportunitiesInput(sVal);
    // }
    setSwotOpportunitiesInput(sVal);
  };

  // SWOT 분석 폼 그리드 > Threats > 입력의 값을 변경함
  const handleSwotThreatsInput_onChange = (event: any) => {
    let sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 1024) {
    //   // 1024 byte 제한
    //   setSwotThreatsInput((pre) => pre);
    // } else {
    //   setSwotThreatsInput(sVal);
    // }
    setSwotThreatsInput(sVal);
  };

  // 영업 내용 폼 그리드 > 영업활동일 > 날짜 피커를 변경함
  const handleSalesWorkDatePicker_onChange = (event: any) => {
    setSalesWorkDatePicker(event);
  };

  // 영업 내용 폼 그리드 > 중요도 > 셀렉트에서 선택한 아이템을 변경함
  const handleImportantTypeSelect_onChange = (event: any) => {
    setImportantTypeSelect(event);
    // setImportantTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 영업 내용 폼 그리드 > 내용 > 입력의 값을 변경함
  const handleSalesContentInput_onChange = (event: any) => {
    let sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 8192) {
    //   // 8192 byte 제한
    //   setSalesContentInput((pre) => pre);
    // } else {
    //   setSalesContentInput(sVal);
    // }
    setSalesContentInput(sVal);
  };

  // 첨부 파일 폼 그리드 > 테이블의 구분 컬럼에서 버튼을 클릭함
  const handleFileTableSlsfClsfCodeButton_onClick = (fileId: string) => {
    setModal({
      title: '선택',
      content: (
        <div className="space-y-2">
          <div className="">
            <span>항목을 선택하세요.</span>
          </div>

          <div className="space-y-1">
            <Button
              onClick={() =>
                handleFileTableSlsfClsfCodeItemButton_onClick(fileId, 'PRPS')
              }
              variant="outline"
              color="violet"
              className="w-full"
              style={{
                width: '100%',
              }}
            >
              제안서
            </Button>

            <Button
              onClick={() =>
                handleFileTableSlsfClsfCodeItemButton_onClick(fileId, 'RFP')
              }
              variant="outline"
              color="violet"
              className="w-full"
              style={{
                width: '100%',
              }}
            >
              RFP
            </Button>

            <Button
              onClick={() =>
                handleFileTableSlsfClsfCodeItemButton_onClick(fileId, 'ETC')
              }
              variant="outline"
              color="violet"
              className="w-full"
              style={{
                width: '100%',
              }}
            >
              기타
            </Button>
          </div>
        </div>
      ),
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '취소',
    });
  };

  // 첨부 파일 폼 그리드 > 테이블의 구분 컬럼 > 모달 > 항목 선택 버튼을 클릭함
  const handleFileTableSlsfClsfCodeItemButton_onClick = (
    fileId: string,
    selectedItem: string,
  ) => {
    // 테이블의 전체 데이터를 불러옴
    let tmpRowData: any = [];

    fileTableRef.current.api.forEachNode((node: any) =>
      tmpRowData.push(node.data),
    );

    // 수정할 행의 순서를 불러옴
    let tmpIndex: number = _.findIndex(tmpRowData, {
      fileId: fileId,
    });

    // 선택한 값을 적용함
    tmpRowData[tmpIndex]['slsfClsfCode'] = selectedItem;

    // 모달을 닫음
    setRemoveModal(true);
  };

  // 태그 정보 폼 그리드 > 태그 > 입력의 값을 변경함
  const handleTagInput_onChange = (event: any) => {
    setTagInput(event.target.value);
  };

  // 첨부파일 다운로드를 클릭함
  const handleFileDownload_onClick = (fileId: string, rgnlFileName: string) => {
    slsApi
      .getSlsCtvtFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  // 영업 기회 데이타를 불러옴
  const getSlsPrtnData = (pSlsPrtnId: string) => {
    slsApi
      .getSlsPrtnOnlySlsPrtnId({
        slsPrtnId: pSlsPrtnId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          let result: any = data.data;

          // 프로젝트 정보 출력을 위한 데이타 설정
          setSlsData(result);
        }
      });
  };

  // 영업 활동 상세 데이터를 불러옴
  const getSlsCtvtData = () => {
    slsApi
      .getSlsCtvt({
        slsCtvtId: slsCtvtId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 영업 활동 상세 데이터에 적용함
          setSlsCtvtData(data.data);
        }
      });
  };

  // 최근 활동 폼 그리드 > 최근 활동 > 테이블 데이터를 불러옴
  const getRecentWorkTableData = (slsPrtnId = '') => {
    if (!slsPrtnId && !salesOpportunitySelect) {
      return;
    }

    slsApi
      .getSlsCtvts({
        slsPrtnId: slsPrtnId || salesOpportunitySelect,
      })
      .then((data: IApiResult) => {
        if (data.data !== null && data.data.list !== undefined) {
          // 최근 활동 폼 그리드 > 최근 활동 > 테이블에 적용함
          setRecentWorkTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: _.orderBy(data.data.list, ['slsCtvtDate'], ['desc']),
          }));
        }
      });
  };

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    if (!slsPrtnId) {
      // 신규
      tmpTab = [
        {
          label: '최근 활동',
          targetId: 'step-1',
        },
        {
          label: '프로젝트 정보',
          targetId: 'step-2',
        },
        {
          label: '영업 내용',
          targetId: 'step-3',
        },
        {
          label: '첨부 파일',
          targetId: 'step-4',
        },
        {
          label: '검색 태그',
          targetId: 'step-5',
        },
      ];
    } else if (slsPrtnId && !useModifyMode) {
      // 조회
      tmpTab = [
        {
          label: '최근 활동',
          targetId: 'step-1',
        },
        {
          label: '프로젝트 정보',
          targetId: 'step-2',
        },
        {
          label: '영업 내용',
          targetId: 'step-3',
        },
        {
          label: '첨부 파일',
          targetId: 'step-4',
        },
        {
          label: '검색 태그',
          targetId: 'step-5',
        },
      ];
    } else if (slsPrtnId && useModifyMode) {
      // 수정
      tmpTab = [
        {
          label: '최근 활동',
          targetId: 'step-1',
        },
        {
          label: '프로젝트 정보',
          targetId: 'step-2',
        },
        {
          label: '영업 내용',
          targetId: 'step-3',
        },
        {
          label: '첨부 파일',
          targetId: 'step-4',
        },
        {
          label: '검색 태그',
          targetId: 'step-5',
        },
      ];
    }

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 영업 활동 정보 폼 그리드 > 영업기회명 > 셀렉트의 아이템을 초기화함
  const initSalesOpportunitySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    // if (!slsCtvtId || (slsCtvtId && useModifyMode)) {
    //   tmpOptionItem.push({
    //     label: '',
    //     value: '',
    //   });
    // }

    // 영업 기회 목록을 불러옴
    slsApi.getSlsCtvtPrtns({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: decode(item.slsPrtnName),
          value: item.slsPrtnId,
        });
      });

      // 신규일 때만 적용함
      if (!slsCtvtId) {
        // 영업 활동 정보 폼 그리드 > 영업기회명 > 셀렉트의 아이템에 적용함
        setSalesOpportunitySelect(initSlsPrtnId);
        setSalesOpportunitySelectItem(tmpOptionItem);
        // setSalesOpportunitySelect({
        //   value: initSlsPrtnId,
        //   item: tmpOptionItem,
        // });
      } else {
        // 영업 활동 정보 폼 그리드 > 영업기회명 > 셀렉트의 아이템에 적용함
        setSalesOpportunitySelectItem(tmpOptionItem);
        // setSalesOpportunitySelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      }
    });
  };

  // 영업 활동 정보 폼 그리드 > 영업단계 > 셀렉트의 아이템을 초기화함
  const initSalesWorkStepSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsCtvtId || (slsCtvtId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 상세 공통코드(영업단계구분)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'SLS_STG' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 영업 활동 정보 폼 그리드 > 영업단계 > 셀렉트의 아이템에 적용함
        setSalesWorkStepSelectItem(tmpOptionItem);
        // setSalesWorkStepSelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // 영업 활동 정보 폼 그리드 > 확도 > 셀렉트의 아이템을 초기화함
  const initProbabilitySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsCtvtId || (slsCtvtId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 상세 공통코드(확도)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'SLS_CRTN' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 영업 활동 정보 폼 그리드 > 확도 > 셀렉트의 아이템에 적용함
        setProbabilitySelectItem(tmpOptionItem);
        // setProbabilitySelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트의 아이템을 초기화함
  const initSalesWorkProposalResultSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsCtvtId || (slsCtvtId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 상세 공통코드(제안결과구분)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'PRPS_RSLT' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트의 아이템에 적용함
        setSalesWorkProposalResultSelectItem(tmpOptionItem);
        // setSalesWorkProposalResultSelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // 영업 내용 폼 그리드 > 중요도 > 셀렉트의 아이템을 초기화함
  const initImportantTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsCtvtId || (slsCtvtId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 상세 공통코드(영업 중요도)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'SLS_MPRT' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 영업 내용 폼 그리드 > 중요도 > 셀렉트의 아이템에 적용함
        setImportantTypeSelectItem(tmpOptionItem);
        // setImportantTypeSelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블을 초기화함
  const initFileTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 등록, 수정일 경우에만 적용함
    if (!(slsCtvtId && !useModifyMode)) {
      // 컬럼에 추가함
      tmpColumn.push({
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      });
    }

    // 컬럼에 추가함
    tmpColumn.push(
      // {
      //   field: 'checkbox',
      //   width: 50,
      //   checkboxSelection: true,
      //   headerCheckboxSelection: true,
      //   suppressSizeToFit: true,
      // },
      // {
      //   headerName: '구분',
      //   field: 'slsfClsfCode',
      //   width: 120,
      //   cellRenderer: (params: any) => (
      //     <div className="w-full h-full flex justify-start items-center">
      //       {/* 버튼 */}
      //       <Button
      //         onClick={() =>
      //           handleFileTableSlsfClsfCodeButton_onClick(params.data.fileId)
      //         }
      //         variant="outline"
      //         color="indigo"
      //         style={{
      //           width: '100%',
      //         }}
      //         className=""
      //         disabled={slsCtvtId && !useModifyMode ? true : false}
      //       >
      //         {params.value === 'PRPS' && '제안서'}
      //         {params.value === 'RFP' && 'RFP'}
      //         {params.value === 'ETC' && '기타'}
      //       </Button>
      //     </div>
      //   ),
      //   editable: false,
      // },
      {
        headerName: '파일명',
        field: 'rgnlFileName',
        editable: false,
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center">
            <div>
              <span
                onClick={() =>
                  handleFileDownload_onClick(
                    params.data.fileId,
                    params.data.rgnlFileName,
                  )
                }
                title={`다운로드: ${params.data.rgnlFileName}`}
                className="cursor-pointer hover:underline"
              >
                {params.value}
              </span>
            </div>
          </div>
        ),
      },
      {
        headerName: '용량',
        field: 'fileSize',
        width: 150,
        editable: false,
        valueFormatter: (params: any) => prettyBytes(_.toNumber(params.value)),
        suppressSizeToFit: true,
        type: 'rightAligned',
      },
      // {
      //   headerName: '설명',
      //   field: 'fileDscr',
      //   width: 360,
      //   suppressSizeToFit: true,
      // },
    );

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블 데이터를 불러옴
  const getFileTableData = () => {
    slsCtvtData.fileList.map((item: any, index: number) => {
      item['newFile'] = null;
    });

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: slsCtvtData.fileList,
    }));

    // 이미지 미리보기용 첨부파일 목록을 정의함
    let tmpFileList: any[] = [];

    // 이미지 미리보기용 첨부파일 목록을 생성함
    slsCtvtData.fileList.map((fileItem: any) => {
      tmpFileList.push({
        id: fileItem.fileId,
        originalFileName: fileItem.rgnlFileName,
        saveFileName: fileItem.pldFileName,
        size: fileItem.fileSize,
      });
    });

    // 이미지 미리보기용 첨부파일 목록에 적용함
    setPreviewFileImages(tmpFileList);
  };

  // 최근 활동 폼 그리드 > 최근 활동 > 테이블을 초기화함
  const initRecentWorkTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '영업활동일',
        field: 'slsCtvtDate',
        width: 130,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => {
          if (params.data.slsCtvtId === slsCtvtId) {
            return (
              <div className="flex justify-center items-center space-x-1">
                <div>
                  <span className="text-indigo-700">{params.value}</span>
                </div>
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'caret-left']}
                    className="w-5 h-5 text-amber-400"
                  />
                </div>
              </div>
            );
          } else {
            return params.value;
          }
        },
      },
      {
        headerName: '중요도',
        field: 'slsMprtName',
        width: 80,
        suppressSizeToFit: true,
      },
      {
        headerName: '내용',
        field: 'slsDtls',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.slsDtls);
        },
      },
      {
        headerName: '첨부파일',
        field: 'fileList',
        width: 300,
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center space-x-3">
            {/* 첨부파일 목록만큼 다운로드 아이콘을 생성함 */}
            {params.value.map((item: any, index: number) => (
              <div
                key={index}
                onClick={() =>
                  handleRecentWorkTableFileDownload_onClick(
                    item.fileId,
                    item.rgnlFileName,
                  )
                }
                title={`${item.slsfClsfName}: ${item.rgnlFileName}`}
                className="button-event px-0.5 py-0.5 flex justify-center items-center"
              >
                <FontAwesomeIcon
                  icon={['far', 'file']}
                  className="w-5 h-5 text-gray-500"
                />
              </div>
            ))}
          </div>
        ),
        suppressSizeToFit: true,
      },
      {
        headerName: '첨부파일 미리보기',
        field: 'fileList',
        width: 250,
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center space-x-3">
            {/* 첨부파일 목록만큼 다운로드 아이콘을 생성함 */}
            {params.value.map((item: any, index: number) => {
              // 이미지 파일의 확장자를 정의함
              let tmpImageFileExt: string[] = [
                '.jpg',
                '.jpeg',
                '.png',
                '.gif',
                '.bmp',
                'tif',
              ];

              // 첨부파일에서 확장자를 추출함
              let tmpFileExt: string = _.padEnd(item.rgnlFileName, 5);

              let result: any = <div key={nanoid()}></div>;

              // 이미지 파일의 확장자가 이미지인지 확인함
              tmpImageFileExt.map((imageItem: string, subIndex: number) => {
                if (_.includes(tmpFileExt, imageItem)) {
                  result = (
                    <div
                      key={nanoid()}
                      onClick={() => {
                        let tmpId: string = nanoid();

                        // 컴포넌트 모달을 추가함
                        setAddComponentModal({
                          id: tmpId,
                          title: '이미지 미리보기',
                          content: (
                            <PreviewImage
                              data={`${appUtil.getResizeImageUrl()}${
                                item.pldFileName
                              }`}
                            />
                          ),
                          // size: 1900,
                          closeOnClickOutside: true,
                        });
                      }}
                      title={`${item.slsfClsfName}: ${item.rgnlFileName}`}
                      className="button-event w-9 h-9 p-px flex justify-center items-center border"
                    >
                      <img
                        src={`${appUtil.getThumbnailImageUrl()}${
                          item.pldFileName
                        }`}
                        className="w-full"
                      />
                    </div>
                  );

                  return;
                }
              });

              return result;
            })}
          </div>
        ),
        suppressSizeToFit: true,
      },
    );

    // 최근 활동 폼 그리드 > 테이블에 적용함
    setRecentWorkTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /////////////////////////////////////

  // 최근 활동 폼 그리드 > 최근 활동 > 테이블 > 첨부파일 다운로드를 클릭함
  const handleRecentWorkTableFileDownload_onClick = (
    fileId: string,
    rgnlFileName: string,
  ) => {
    slsApi
      .getSlsCtvtFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  /**************************** 기타 Function ****************************/
  // 입력 byte 계산
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    // 영업 활동 정보 폼 그리드 > 영업기회명 > 셀렉트의 아이템을 초기화함
    initSalesOpportunitySelect();

    // 영업 활동 정보 폼 그리드 > 영업단계 > 셀렉트의 아이템을 초기화함
    initSalesWorkStepSelect();

    // 영업 활동 정보 폼 그리드 > 확도 > 셀렉트의 아이템을 초기화함
    initProbabilitySelect();

    // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트의 아이템을 초기화함
    initSalesWorkProposalResultSelect();

    // 영업 내용 폼 그리드 > 중요도 > 셀렉트의 아이템을 초기화함
    initImportantTypeSelect();

    // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블을 초기화함
    initFileTable();

    // 최근 활동 폼 그리드 > 최근 활동 > 테이블을 초기화함
    initRecentWorkTable();

    // 프로젝트 정보 폼 그리드 > 제안제출일 > 날짜 피커를 초기화함
    // initPlanProposalSubmissionDatePicker();

    // 프로젝트 정보 폼 그리드 > 수주일 > 날짜 피커를 초기화함
    // initPlanEstimatedConfirmationDatePicker();

    // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 날짜 피커를 초기화함
    initPlanEstimatedDatePicker();

    // 프로젝트 정보 폼 그리드 > 기간(월) > 입력을 초기화함
    // initPlanEstimatedMonthInput();

    // 프로젝트 정보 폼 그리드 > 계약규모(원) > 입력을 초기화함
    // initSalesContractSizeInput();

    // 프로젝트 정보 폼 그리드 > NSR규모(원) > 입력을 초기화함
    // initSalesNsrSizeInput();

    // 프로젝트 정보 폼 그리드 > NSR X 확도 > 입력을 초기화함
    // initSalesNsrProbabilityInput();

    // 수정 상태일 때는 기존에 저장한 데이터를 불러옴
    if (slsCtvtId) {
      // 영업 활동 상세 데이터를 불러옴
      getSlsCtvtData();
    }

    // 영업 기회 상세에서 등록으로 전환된 경우
    // 영업기회명 설정
    if (slsPrtnId) {
      setSalesOpportunitySelect(slsPrtnId);
      // setSalesOpportunitySelect((pre: ISelect) => ({
      //   ...pre,
      //   value: slsPrtnId,
      // }));
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (!planEstimatedDatePicker) {
      return;
    }

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블을 초기화함
    initSalesEstimatedAmountTable();

    return () => {};
  }, [planEstimatedDatePicker]);

  // 계획정보 출력 가능시 실행
  useEffect(() => {
    // return;

    // 영업기회에서 영업활동 등록으로 전환된 경우에만 아래의 소스를 실행함
    // if (!slsData || initSlsPrtnId === '') {
    //   return;
    // }

    if (!slsData) {
      return;
    }

    if (slsPrtnId) {
      setSalesOpportunitySelect(slsPrtnId);
    }
    // setSalesOpportunitySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsPrtnId,
    // }));

    // 프로젝트 정보 데이타는 수정/상세/기회에서 이동 구분없이 호출 / 출력한다.
    // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 날짜 피커에 초기값을 적용함
    setPlanEstimatedDatePicker([
      moment(slsData.stmtStrtDate, 'YYYY-MM-DD').toDate(),
      moment(slsData.stmtCmplDate, 'YYYY-MM-DD').toDate(),
    ]);

    // if (slsCtvtId && !useModifyMode) {
    // 조회
    // } else {
    // 영업 활동 정보 폼 그리드 > Market Type > 입력에 초기값을 적용함
    setMarketTypeInput(slsData.mrktTypeName);

    // 영업 활동 정보 폼 그리드 > 담당자(내부) > 입력에 초기값을 적용함
    setManagerInsideNameInput(slsData.name);

    let tmpManagerClientNameInput: string = '';

    // 담당자(고객사) 목록을 불러옴
    slsData.cstmMngrList.map((item: any) => {
      if (tmpManagerClientNameInput) {
        tmpManagerClientNameInput += ', ';
      }
      tmpManagerClientNameInput += item.cstmMngrName;
    });

    // 영업 활동 정보 폼 그리드 > 담당자(고객사) > 입력에 초기값을 적용함
    setManagerClientNameInput(tmpManagerClientNameInput);

    // 영업 정보 폼 그리드 > 협력사 > 입력에 초기값을 적용함
    setSalesPartnerInput(slsData.prtnName);

    // 영업 활동 정보 폼 그리드 > 영업단계 > 셀렉트에 초기값을 적용함
    // setSalesWorkStepSelect(slsData.slsStgCode);
    // setSalesWorkStepSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsData.slsStgCode,
    // }));

    // 영업 활동 정보 폼 그리드 > 확도 > 셀렉트에 초기값을 적용함
    // setProbabilitySelect(slsData.slsPrbbCode);
    // setProbabilitySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsData.slsPrbbCode,
    // }));

    // // SWOT 분석 폼 그리드 > Strength > 입력에 초기값을 적용함
    // setSwotStrengthInput(slsData.swotStrn);
    //
    // // SWOT 분석 폼 그리드 > Weakness > 입력에 초기값을 적용함
    // setSwotWeaknessInput(slsData.swotWkns);
    //
    // // SWOT 분석 폼 그리드 > Opportunities > 입력에 초기값을 적용함
    // setSwotOpportunitiesInput(slsData.swotPrtn);
    //
    // // SWOT 분석 폼 그리드 > Threats > 입력에 초기값을 적용함
    // setSwotThreatsInput(slsData.swotThrt);

    // 영업 내용 폼 그리드 > 영업활동일 > 날짜 피커에 초기값을 적용함
    setSalesWorkDatePicker(moment(new Date(), 'YYYY-MM-DD').toDate());

    // // 영업 내용 폼 그리드 > 중요도 > 셀렉트에 초기값을 적용함
    // setImportantTypeSelect(slsData.slsMprtCode);
    // setImportantTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsData.slsMprtCode,
    // }));

    // // 영업 내용 폼 그리드 > 내용 > 입력에 초기값을 적용함
    // setSalesContentInput(slsData.slsDtls);

    // 태그 정보 폼 그리드 > 태그 > 입력에 초기값을 적용함
    setTagInput(slsData.tag);
    // }

    return () => {};
  }, [slsData]);

  // 영업 활동 상세 데이터를 불러온 후 실행함
  useEffect(() => {
    if (!slsCtvtData) {
      return;
    }

    // 영업 활동 정보 폼 그리드 > 영업기회명 > 셀렉트에 초기값을 적용함
    setSalesOpportunitySelect(slsCtvtData.slsPrtnId);
    // setSalesOpportunitySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsCtvtData.slsPrtnId,
    // }));

    // 최근 활동 폼 그리드 > 최근 활동 > 테이블 데이터를 불러옴
    getRecentWorkTableData(slsCtvtData.slsPrtnId);

    // 영업 정보 폼 그리드 > 협력사 > 입력에 초기값을 적용함
    setSalesPartnerInput(slsCtvtData.prtnName);

    // 영업 활동 정보 폼 그리드 > 영업단계 > 셀렉트에 초기값을 적용함
    setSalesWorkStepSelect(slsCtvtData.slsStgCode);
    // setSalesWorkStepSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsCtvtData.slsStgCode,
    // }));

    // 영업 활동 정보 폼 그리드 > 확도 > 셀렉트에 초기값을 적용함
    setProbabilitySelect(slsCtvtData.slsPrbbCode);
    // setProbabilitySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsCtvtData.slsPrbbCode,
    // }));

    // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트에 초기값을 적용함
    setSalesWorkProposalResultSelect(slsCtvtData.sgstRsltCode);
    // setSalesWorkProposalResultSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsCtvtData.sgstRsltCode,
    // }));

    // 영업 활동 정보 폼 그리드 > 제안결과내용 > 입력에 초기값을 적용함
    setSalesWorkProposalResultContentInput(slsCtvtData.prpsRslt);

    // SWOT 분석 폼 그리드 > Strength > 입력에 초기값을 적용함
    setSwotStrengthInput(slsCtvtData.swotStrn);

    // SWOT 분석 폼 그리드 > Weakness > 입력에 초기값을 적용함
    setSwotWeaknessInput(slsCtvtData.swotWkns);

    // SWOT 분석 폼 그리드 > Opportunities > 입력에 초기값을 적용함
    setSwotOpportunitiesInput(slsCtvtData.swotPrtn);

    // SWOT 분석 폼 그리드 > Threats > 입력에 초기값을 적용함
    setSwotThreatsInput(slsCtvtData.swotThrt);

    // 영업 내용 폼 그리드 > 영업활동일 > 날짜 피커에 초기값을 적용함
    setSalesWorkDatePicker(
      moment(slsCtvtData.slsCtvtDate, 'YYYY-MM-DD').toDate(),
    );

    // 영업 내용 폼 그리드 > 중요도 > 셀렉트에 초기값을 적용함
    setImportantTypeSelect(slsCtvtData.slsMprtCode);
    // setImportantTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsCtvtData.slsMprtCode,
    // }));

    // 영업 내용 폼 그리드 > 내용 > 입력에 초기값을 적용함
    setSalesContentInput(slsCtvtData.slsDtls);

    // 태그 정보 폼 그리드 > 태그 > 입력에 초기값을 적용함
    setTagInput(slsCtvtData.tag);

    return () => {};
  }, [slsCtvtData]);

  // 영업 활동 정보 폼 그리드 > 영업기회명 > 셀렉트가 변경됐을 때 실행함
  useEffect(() => {
    if (salesOpportunitySelect === '') {
      // // 영업 활동 정보 폼 그리드 > 고객사명 > 입력을 초기화함
      // setClientNameInput('');
      // // 영업 활동 정보 폼 그리드 > 담당자(내부) > 입력을 초기화함
      // setManagerInsideNameInput('');
      // // 영업 활동 정보 폼 그리드 > 담당자(고객사) > 입력을 초기화함
      // setManagerClientNameInput('');
    } else {
      // 영업 기회 상세 데이타를 불러온다.
      getSlsPrtnData(salesOpportunitySelect);
    }

    return () => {};
  }, [salesOpportunitySelect]);

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (fileTable.column.length === 0 || slsCtvtData === null) {
      return;
    }

    // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블 데이터를 불러옴
    getFileTableData();

    return () => {};
  }, [fileTable.column, slsCtvtData]);

  // 최근 활동 폼 그리드 > 최근 활동 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (recentWorkTable.column.length === 0 || slsCtvtData === null) {
      return;
    }

    // 최근 활동 폼 그리드 > 최근 활동 > 테이블 데이터를 불러옴
    getRecentWorkTableData();

    return () => {};
  }, [recentWorkTable.column, slsCtvtData, salesOpportunitySelect]);

  // 최근 활동 폼 그리드 > 최근 활동 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (recentWorkTable.column.length === 0 || slsCtvtData === null) {
      return;
    }

    // 최근 활동 폼 그리드 > 최근 활동 > 테이블 데이터를 불러옴
    getRecentWorkTableData();

    return () => {};
  }, [recentWorkTable.column, slsCtvtData, salesOpportunitySelect]);

  // 영업 활동 정보 폼 그리드 > 영업단계 > 셀렉트가 변경될 때 실행함
  useEffect(() => {
    // 영업 활동 정보 폼 그리드 > 영업단계 > 셀렉트의 값이 'DECUDED'인지 확인함
    if (salesWorkStepSelect === 'DCDD') {
      // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트의 읽기전용 여부에 적용함
      setReadOnlySalesWorkProposalResultSelect(false);
    } else {
      // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트 초기화
      setSalesWorkProposalResultSelect('');
      // setSalesWorkProposalResultSelect((pre: ISelect) => ({
      //   ...pre,
      //   value: '',
      // }));

      // 영업 활동 정보 폼 그리드 > 제안결과내용 > 입력창의 초기화
      setSalesWorkProposalResultContentInput('');

      // 영업 활동 정보 폼 그리드 > 제안결과내용 > 입력창의 읽기전용 여부에 적용함
      // 영업 활동 정보 폼 그리드 > 제안결과 > 셀렉트의 읽기전용 여부에 적용함
      setReadOnlySalesWorkProposalResultSelect(true);
    }
  }, [salesWorkStepSelect]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  return (
    <div className="relative space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 최근 활동 섹션 */}
        <PbSection
          id="step-1"
          label="최근 활동"
          className={!slsCtvtId ? 'hidden' : ''}
        >
          {/* 테이블 */}
          <div className="w-full h-80">
            <PbAgGridReact
              refs={recentWorkTableRef}
              columnDefs={recentWorkTable.column}
              rowData={recentWorkTable.data}
              onRowDoubleClicked={handleRecentWorkTable_onRowDoubleClicked}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="single"
              sizeColumnsToFit={true}
              // rowClassRules={{
              //   'ag-grid-selected-row': (params: any) =>
              //     params.data.slsCtvtId === slsCtvtId,
              //   'ag-grid-none-selected-row': (params: any) =>
              //     params.data.slsCtvtId !== slsCtvtId,
              // }}
            />
          </div>
        </PbSection>

        {/* 영업 활동 정보 폼 그리드 */}
        <PbFormGrid label="영업 활동 정보" cols={2}>
          <PbFormGridCol label="Project Name" withAsterisk={true}>
            {/* 셀렉트 */}
            <Select
              ref={salesOpportunitySelectRef}
              placeholder={
                slsCtvtId && !useModifyMode
                  ? ''
                  : '검색어를 입력하시거나 목록을 선택하세요.'
              }
              onChange={handleSalesOpportunitySelect_onChange}
              data={salesOpportunitySelectItem}
              value={salesOpportunitySelect}
              searchable={true}
              clearable={true}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
            {/*<PbSelect*/}
            {/*  placeholder={*/}
            {/*    slsCtvtId && !useModifyMode*/}
            {/*      ? ''*/}
            {/*      : '검색어를 입력하시거나 목록을 선택하세요.'*/}
            {/*  }*/}
            {/*  onChange={handleSalesOpportunitySelect_onChange}*/}
            {/*  data={salesOpportunitySelectItem}*/}
            {/*  value={salesOpportunitySelect}*/}
            {/*  setSelect={setSalesOpportunitySelect}*/}
            {/*  searchable={true}*/}
            {/*  clearable={true}*/}
            {/*  readOnly={slsCtvtId && !useModifyMode ? true : false}*/}
            {/*  variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}*/}
            {/*  className="w-full"*/}
            {/*/>*/}
          </PbFormGridCol>
          <PbFormGridCol label="Market Type">
            <TextInput
              value={marketTypeInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="담당자(내부)">
            {/* 텍스트 입력 */}
            <TextInput
              value={managerInsideNameInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="담당자(고객사)">
            {/* 텍스트 입력 */}
            <TextInput
              value={managerClientNameInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="Deal Overview" colSpan={2} className="h-62">
            {/* 텍스트 영역 */}
            <Textarea
              placeholder={
                slsCtvtId && !useModifyMode ? '' : 'Deal Overview를 입력하세요.'
              }
              onChange={handleSalesPartnerInput_onChange}
              value={decode(salesPartnerInput)}
              minRows={10}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="영업단계">
            {/* 셀렉트 */}
            <Select
              onChange={handleSalesWorkStepSelect_onChange}
              data={salesWorkStepSelectItem}
              value={salesWorkStepSelect}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
            {/*<PbSelect*/}
            {/*  onChange={handleSalesWorkStepSelect_onChange}*/}
            {/*  data={salesWorkStepSelectItem}*/}
            {/*  value={salesWorkStepSelect}*/}
            {/*  setSelect={setSalesWorkStepSelect}*/}
            {/*  readOnly={slsCtvtId && !useModifyMode ? true : false}*/}
            {/*  variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}*/}
            {/*  className="w-full"*/}
            {/*/>*/}
          </PbFormGridCol>
          <PbFormGridCol label="확도">
            {/* 셀렉트 */}
            <Select
              onChange={handleProbabilitySelect_onChange}
              data={probabilitySelectItem}
              value={probabilitySelect}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
            {/*<PbSelect*/}
            {/*  onChange={handleProbabilitySelect_onChange}*/}
            {/*  data={probabilitySelectItem}*/}
            {/*  value={probabilitySelect}*/}
            {/*  setSelect={setProbabilitySelect}*/}
            {/*  readOnly={slsCtvtId && !useModifyMode ? true : false}*/}
            {/*  variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}*/}
            {/*  className="w-full"*/}
            {/*/>*/}
          </PbFormGridCol>
          {salesWorkStepSelect === 'DCDD' && (
            <>
              <PbFormGridCol
                label="제안결과"
                withAsterisk={!readOnlySalesWorkProposalResultSelect}
                isHidden={readOnlySalesWorkProposalResultSelect}
              >
                {/* 셀렉트 */}
                <Select
                  ref={salesWorkProposalResultSelectRef}
                  onChange={handleSalesWorkProposalResultSelect_onChange}
                  data={salesWorkProposalResultSelectItem}
                  value={salesWorkProposalResultSelect}
                  readOnly={
                    slsCtvtId && !useModifyMode
                      ? true
                      : readOnlySalesWorkProposalResultSelect
                  }
                  variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
                  className="w-full"
                />
                {/*<PbSelect*/}
                {/*  onChange={handleSalesWorkProposalResultSelect_onChange}*/}
                {/*  data={salesWorkProposalResultSelectItem}*/}
                {/*  value={salesWorkProposalResultSelect}*/}
                {/*  setSelect={setSalesWorkProposalResultSelect}*/}
                {/*  readOnly={*/}
                {/*    slsCtvtId && !useModifyMode*/}
                {/*      ? true*/}
                {/*      : readOnlySalesWorkProposalResultSelect*/}
                {/*  }*/}
                {/*  variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}*/}
                {/*  className="w-full"*/}
                {/*/>*/}
              </PbFormGridCol>
              <PbFormGridCol
                label="제안결과내용"
                withAsterisk={!readOnlySalesWorkProposalResultSelect}
                isHidden={readOnlySalesWorkProposalResultSelect}
              >
                {/* 텍스트 입력 */}
                <TextInput
                  ref={salesWorkProposalResultContentInputRef}
                  placeholder={
                    slsCtvtId && !useModifyMode
                      ? ''
                      : '제안결과내용을 입력하세요.'
                  }
                  onChange={handleSalesWorkProposalResultContentInput_onChange}
                  value={decode(salesWorkProposalResultContentInput)}
                  readOnly={
                    slsCtvtId && !useModifyMode
                      ? true
                      : readOnlySalesWorkProposalResultSelect
                  }
                  variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
                  className="w-full"
                />
              </PbFormGridCol>
            </>
          )}
        </PbFormGrid>

        {/* 프로젝트 정보 폼 그리드 */}
        <PbFormGrid id="step-2" label="프로젝트 정보" cols={2}>
          <PbFormGridCol label="Proposal Submission Date" isHidden={true}>
            {/* 날짜 피커 */}
            <DatePickerInput
              placeholder=""
              // onChange={handlePlanProposalSubmissionDatePicker_onChange}
              // value={planProposalSubmissionDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              firstDayOfWeek={0}
              clearable={true}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="Expected Order Date" isHidden={true}>
            {/* 날짜 피커 */}
            <DatePickerInput
              placeholder=""
              // onChange={handlePlanEstimatedConfirmationDatePicker_onChange}
              // value={planEstimatedConfirmationDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              firstDayOfWeek={0}
              clearable={true}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol
            label="프로젝트기간"
            withAsterisk={true}
            isHidden={true}
          >
            {/* 날짜 피커 */}
            <DatePickerInput
              type="range"
              placeholder=""
              // onChange={handlePlanEstimatedDatePicker_onChange}
              value={planEstimatedDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              firstDayOfWeek={0}
              clearable={true}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="계약규모(원)" isHidden={true}>
            {/* 숫자 입력 */}
            <NumberInput
              placeholder=""
              // onChange={handleSalesContractSizeInput_onChange}
              // value={+salesContractSizeInput}
              readOnly={true}
              variant="unstyled"
              formatter={(value) =>
                !Number.isNaN(parseFloat(value))
                  ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : ''
              }
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="기간(월)" isHidden={true}>
            {/* 숫자 입력 */}
            <NumberInput
              // value={+planEstimatedMonthInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="NSR규모(원)" isHidden={true}>
            {/* 숫자 입력 */}
            <NumberInput
              placeholder=""
              // onChange={handleSalesNsrSizeInput_onChange}
              // value={+salesNsrSizeInput}
              readOnly={true}
              variant="unstyled"
              formatter={(value) =>
                !Number.isNaN(parseFloat(value))
                  ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : ''
              }
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="NSR X 확도" colSpan={2} isHidden={true}>
            {/* 숫자 입력 */}
            <NumberInput
              // value={+salesNsrProbabilityInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          {/* 기간별예상매출금액(원) 폼 그리드 컬럼 */}
          {/* 조회 상태 */}
          {slsCtvtId && !useModifyMode && (
            <PbFormGridCol
              label="기간별예상매출금액(원)"
              colSpan={2}
              className="h-60"
            >
              <div className="w-full space-y-3">
                {/* 테이블 */}
                <div className="w-full h-56">
                  <PbAgGridReact
                    columnDefs={salesEstimatedAmountTable.column}
                    rowData={salesEstimatedAmountTable.data}
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      wrapHeaderText: false,
                      autoHeaderHeight: true,
                      editable: false,
                    }}
                    rowSelection="single"
                    sizeColumnsToFit={false}
                  />
                </div>
              </div>
            </PbFormGridCol>
          )}
          {/* 기간별예상매출금액(원) 폼 그리드 컬럼 */}
          {/* 추가, 수정 상태 */}
          {(!slsCtvtId || useModifyMode) && (
            <PbFormGridCol
              label="기간별예상매출금액(원)"
              colSpan={2}
              withAsterisk={true}
              className="h-60"
            >
              <div className="w-full space-y-3">
                {/* 테이블 */}
                <div className="w-full h-56">
                  <PbAgGridReact
                    columnDefs={salesEstimatedAmountTable.column}
                    rowData={salesEstimatedAmountTable.data}
                    // onCellValueChanged={
                    //   handleSalesEstimatedAmountTable_onCellValueChanged
                    // }
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      editable: false,
                      wrapHeaderText: false,
                      autoHeaderHeight: true,
                    }}
                    rowSelection="single"
                    sizeColumnsToFit={false}
                  />
                </div>
              </div>
            </PbFormGridCol>
          )}
        </PbFormGrid>

        {/* SWOT 분석 폼 그리드 */}
        <PbFormGrid label="SWOT 분석" cols={2}>
          <PbFormGridCol label="Strength">
            {/* 텍스트 입력 */}
            <TextInput
              placeholder={
                slsCtvtId && !useModifyMode ? '' : 'Strength를 입력하세요.'
              }
              onChange={handleSwotStrengthInput_onChange}
              value={decode(swotStrengthInput)}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="Weakness">
            {/* 텍스트 입력 */}
            <TextInput
              placeholder={
                slsCtvtId && !useModifyMode ? '' : 'Weakness를 입력하세요.'
              }
              onChange={handleSwotWeaknessInput_onChange}
              value={decode(swotWeaknessInput)}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="Opportunities">
            {/* 텍스트 입력 */}
            <TextInput
              placeholder={
                slsCtvtId && !useModifyMode ? '' : 'Opportunities를 입력하세요.'
              }
              onChange={handleSwotOpportunitiesInput_onChange}
              value={decode(swotOpportunitiesInput)}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="Threats">
            {/* 텍스트 입력 */}
            <TextInput
              placeholder={
                slsCtvtId && !useModifyMode ? '' : 'Threats를 입력하세요.'
              }
              onChange={handleSwotThreatsInput_onChange}
              value={decode(swotThreatsInput)}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 영업 내용 폼 그리드 */}
        <PbFormGrid id="step-3" label="영업 내용" cols={2}>
          <PbFormGridCol label="영업활동일" withAsterisk={true}>
            {/* 날짜 피커 */}
            <DatePickerInput
              placeholder={
                slsCtvtId && !useModifyMode ? '' : '영업활동일을 선택하세요.'
              }
              onChange={handleSalesWorkDatePicker_onChange}
              value={salesWorkDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              firstDayOfWeek={0}
              clearable={true}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="sales-work-date-picker w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="중요도">
            {/* 셀렉트 */}
            <Select
              onChange={handleImportantTypeSelect_onChange}
              data={importantTypeSelectItem}
              value={importantTypeSelect}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
            {/*<PbSelect*/}
            {/*  onChange={handleImportantTypeSelect_onChange}*/}
            {/*  data={importantTypeSelectItem}*/}
            {/*  value={importantTypeSelect}*/}
            {/*  setSelect={setImportantTypeSelect}*/}
            {/*  readOnly={slsCtvtId && !useModifyMode ? true : false}*/}
            {/*  variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}*/}
            {/*  className="w-full"*/}
            {/*/>*/}
          </PbFormGridCol>
          <PbFormGridCol
            label="내용"
            colSpan={2}
            withAsterisk={true}
            className="h-92"
          >
            {/* 텍스트 영역 */}
            <Textarea
              ref={salesContentInputRef}
              placeholder={
                slsCtvtId && !useModifyMode ? '' : '내용을 입력하세요.'
              }
              onChange={handleSalesContentInput_onChange}
              value={decode(salesContentInput)}
              minRows={15}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 첨부 파일 섹션 */}
        {/* 조회 상태 */}
        {slsCtvtId && !useModifyMode && (
          <PbSection id="step-4" label="첨부 파일">
            <div className="space-y-3">
              {/* 테이블 */}
              <div className="w-full h-80">
                <PbAgGridReact
                  refs={fileTableRef}
                  columnDefs={fileTable.column}
                  rowData={fileTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="single"
                  sizeColumnsToFit={true}
                />
              </div>

              {/* 첨부파일 미리보기 */}
              <div className="flex justify-start items-center space-x-1">
                <FlexImageFilePreviewList data={previewFileImages} />
              </div>
            </div>

            {/* 테이블 */}
            {/*<div className="w-full h-80">*/}
            {/*  <AgGridReact*/}
            {/*    columnDefs={fileTable.column}*/}
            {/*    rowData={fileTable.data}*/}
            {/*    defaultColDef={{*/}
            {/*      resizable: true,*/}
            {/*      sortable: true,*/}
            {/*      editable: false,*/}
            {/*      wrapHeaderText: false,*/}
            {/*      autoHeaderHeight: true,*/}
            {/*      suppressSizeToFit: true,*/}
            {/*    }}*/}
            {/*    rowSelection="single"*/}
            {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
            {/*    className="ag-theme-alpine"*/}
            {/*  ></AgGridReact>*/}
            {/*</div>*/}
          </PbSection>
        )}
        {/* 첨부 파일 섹션 */}
        {/* 추가, 수정 상태 */}
        {(!slsCtvtId || useModifyMode) && (
          <PbSection
            id="step-4"
            label="첨부 파일"
            rightForm={
              <>
                {/* 버튼 */}
                <div className="flex justify-end items-center space-x-2">
                  {/* 버튼 */}
                  <Button
                    variant="outline"
                    color="pink"
                    radius="xl"
                    onClick={handleFileSectionDeleteButton_onClick}
                  >
                    전체 삭제
                  </Button>

                  {/* 버튼 */}
                  <Button
                    color="pink"
                    radius="xl"
                    onClick={handleFileSectionSelectedDeleteButton_onClick}
                  >
                    선택 삭제
                  </Button>

                  {/* 버튼 */}
                  <FileButton
                    resetRef={resetFileSectionRef}
                    onChange={handleFileSectionAddButton_onClick}
                  >
                    {(props: any) => (
                      <Button color="indigo" radius="xl" {...props}>
                        추가
                      </Button>
                    )}
                  </FileButton>
                </div>
              </>
            }
          >
            <div className="space-y-3">
              {/* 테이블 */}
              <div className="w-full h-80">
                <PbAgGridReact
                  refs={fileTableRef}
                  columnDefs={fileTable.column}
                  rowData={fileTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    editable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="multiple"
                  sizeColumnsToFit={true}
                />
              </div>

              {/* 테이블 */}
              {/*<div className="w-full h-80">*/}
              {/*  <AgGridReact*/}
              {/*    ref={fileTableRef}*/}
              {/*    columnDefs={fileTable.column}*/}
              {/*    rowData={fileTable.data}*/}
              {/*    defaultColDef={{*/}
              {/*      resizable: true,*/}
              {/*      sortable: true,*/}
              {/*      editable: true,*/}
              {/*      wrapHeaderText: false,*/}
              {/*      autoHeaderHeight: true,*/}
              {/*      suppressSizeToFit: true,*/}
              {/*    }}*/}
              {/*    rowSelection="multiple"*/}
              {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
              {/*    className="ag-theme-alpine"*/}
              {/*  ></AgGridReact>*/}
              {/*</div>*/}
            </div>
          </PbSection>
        )}

        {/* 태그 정보 폼 그리드 */}
        <PbFormGrid id="step-5" label="검색을 위한 태그" cols={2}>
          <PbFormGridCol label="태그" colSpan={2} className="h-36">
            {/* 텍스트 영역 */}
            <Textarea
              placeholder={
                slsCtvtId && !useModifyMode ? '' : '태그를 입력하세요.'
              }
              onChange={handleTagInput_onChange}
              value={decode(tagInput)}
              minRows={5}
              readOnly={slsCtvtId && !useModifyMode ? true : false}
              variant={slsCtvtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 최근 활동 폼 그리드 */}
        {/*<PbFormGrid*/}
        {/*  label="최근 활동"*/}
        {/*  cols={1}*/}
        {/*  className={!slsCtvtId ? 'hidden' : ''}*/}
        {/*>*/}
        {/*  <PbFormGridCol label="최근 활동" className="h-84">*/}
        {/*    /!* 테이블 *!/*/}
        {/*    <div className="w-full h-80">*/}
        {/*      <AgGridReact*/}
        {/*        columnDefs={recentWorkTable.column}*/}
        {/*        rowData={recentWorkTable.data}*/}
        {/*        defaultColDef={{*/}
        {/*          resizable: true,*/}
        {/*          sortable: true,*/}
        {/*          wrapHeaderText: false,*/}
        {/*          autoHeaderHeight: true,*/}
        {/*          suppressSizeToFit: true,*/}
        {/*        }}*/}
        {/*        rowSelection="single"*/}
        {/*        overlayNoRowsTemplate="데이터가 없습니다."*/}
        {/*        className="ag-theme-alpine"*/}
        {/*      ></AgGridReact>*/}
        {/*    </div>*/}
        {/*  </PbFormGridCol>*/}
        {/*</PbFormGrid>*/}
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 신규 상태 */}
        {!slsCtvtId && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveAddButton_onClick}
            >
              저장
            </Button>
          </>
        )}

        {/* 조회 상태 */}
        {slsCtvtId && !useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleGoModifyButton_onClick}
            >
              수정 전환
            </Button>
          </>
        )}

        {/* 수정 상태 */}
        {slsCtvtId && useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              variant="outline"
              color="pink"
              radius="xl"
              size="md"
              onClick={handleRemoveButton_onClick}
            >
              삭제
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveUpdateButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220204;
