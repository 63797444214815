import { atom } from 'recoil';
import { ILeftMenu } from '../interfaces/app.interface';

/**
 * 왼쪽 메뉴
 */
export const leftMenuStore = atom({
  key: 'leftMenuStore',
  default: [] as ILeftMenu[],
});
