import axios, { AxiosResponse } from 'axios';
import { IApiListResult, IApiResult } from '../interfaces/app.interface';
import api from '../utils/axios.util';
import * as appUtil from '../utils/app.util';

/**
 * GRP: 설치 그룹
 */

/**
 * 설치 그룹 목록
 * GET /api/grp/grps
 */
export const getGrps = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/grp/grps'].join('/'),
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 설치 그룹 추가 기능 팀 목록
 * GET /api/grp/grp/teams
 */
export const getGrpIdTeams = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/grp/grp/teams'].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 설치 그룹 상세
 * GET /api/grp/grp/{grpId}
 * @param params grpId 설치 그룹 아이디
 */
export const getGrpId = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/grp/grp', params.grpId].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 설치 그룹 등록
 * POST /api/grp/grp
 * @param params grpName 그룹 이름
 * @param params grpDscr 그룹 설명
 * @param params grpTmRegParamVoList 설치 그룹-팀 맵핑 정보
 */
export const postGrp = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/grp/grp',
    data: {
      grpName: params.grpName || '',
      grpDscr: params.grpDscr || '',
      grpTmRegParamVoList: params.grpTmRegParamVoList || [],
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 설치 그룹 수정
 * PUT /api/grp/grp/{grpId}
 * @param params grpId 설치 그룹 아이디
 * @param params grpName 그룹 이름
 * @param params grpDscr 그룹 설명
 * @param params grpTmRegParamVoList 설치 그룹-팀 맵핑 정보
 */
export const putGrp = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/grp/grp', params.grpId].join('/'),
    data: {
      grpName: params.grpName || '',
      grpDscr: params.grpDscr || '',
      grpTmRegParamVoList: params.grpTmRegParamVoList || [],
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 설치 그룹 삭제
 * DELETE /api/grp/grp/{grpId}
 * @param params grpId 설치 그룹 아이디
 */
export const deleteGrp = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/grp/grp', params.grpId].join('/'),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 설치 그룹 등록 시 설치 그룹명 중복검사
 * GET /api/grp/grp/rgst/check
 * @param params grpName 설치 그룹명
 */
export const getRgstCheck = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/grp/grp/rgst/check'].join('/'),
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('error', error);
    });

  return result;
};

/**
 * 설치 그룹 수정 시 설치 그룹명 중복검사
 * GET /api/grp/grp/crct/check
 * @param params grpId 설치 그룹 아이디
 * @param params grpName 설치 그룹명
 */
export const getCrctCheck = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/grp/grp/crct/check'].join('/'),
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};
