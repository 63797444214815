import { atom } from 'recoil';
import { IConfig } from '../interfaces/app.interface';

/**
 * 환경 설정
 */
export const configStore = atom({
  key: 'configStore',
  default: {
    loaded: false,
    api: {
      commonUrl: {
        host: '',
        port: 0,
      },
    },
    menuType: true,
  } as IConfig,
});
