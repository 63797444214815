import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { IApiResult, IModal } from '../../interfaces/app.interface';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { notifications } from '@mantine/notifications';
import * as nstlApi from '../../apis/nstl.api';
import { Button, FileButton, Select, Textarea, TextInput } from '@mantine/core';
import * as slsApi from '../../apis/sls.api';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbFormGrid } from '../PbFormGrid';
import { removeComponentModalStore } from '../../stores/componentModal.store';
import * as appUtil from '../../utils/app.util';

interface IComponentProps {
  id: string;
  dcmnDntfNmbr: string;
  splrId?: string;
  teamId?: string;
  commentData?: any | null;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 댓글 수정
 * @param dcmnDntfNmbr 문서번호
 * @param splrId 협력사 아이디
 * @param teamId 협력사(팀) 아이디
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const ModifyComment = ({
  id,
  dcmnDntfNmbr = '',
  splrId = '',
  teamId = '',
  commentData,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IComponentProps>) => {
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 내용 입력을 정의함
  const [contentInput, setContentInput] = useState<string>('');
  const contentInputRef = useRef<any>(null);

  // 첨부파일을 정의함
  const [files, setFiles] = useState<{ id: string; new: boolean; file: any }[]>(
    [],
  );

  // 파일이 이미지인지 확인함
  const isImageFile = (fileName: string): boolean => {
    // 이미지 파일의 확장자를 정의함
    let tmpImageFileExt: string[] = [
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.bmp',
      '.tif',
    ];

    // 파일명에서 확장자를 추출함
    let tmpFileExt: string = _.padEnd(fileName, 5);

    // 파일의 확장자가 이미지인지 정의함
    let result: boolean = false;

    // 파일의 확장자가 이미지인지 확인함
    tmpImageFileExt.map((item: string) => {
      if (_.includes(tmpFileExt, item)) {
        result = true;
      }
    });

    return result;
  };

  // 내용 입력을 변경함
  const handleContentInput_onChange = (event: any) => {
    setContentInput(event.target.value);
  };

  // 첨부파일 이미지를 클릭함
  const handleFileImage_onClick = (event: any) => {
    let tmpFiles: { id: string; new: boolean; file: any }[] = _.cloneDeep(
      files.filter((item: any) => item.id !== event.id),
    );

    // 첨부파일에 적용함
    setFiles(tmpFiles);

    notifications.show({
      title: '알림',
      message: '삭제하였습니다.',
    });
  };

  // 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    if (contentInput.trim() === '') {
      notifications.show({
        title: '알림',
        message: '내용을 입력하세요.',
      });

      contentInputRef.current.focus();
      return;
    }

    // 기존 첨부파일 아이디를 불러옴
    let tmpPreFileId: string[] = [];

    // 기존 첨부파일 정보를 저장함
    files
      .filter((item: any) => item.new === false)
      .map((item: any) => {
        tmpPreFileId.push(item.id);
      });

    // 신규 첨부파일을 불러옴
    let tmpNewFile: any[] = [];

    // 신규 첨부파일 정보를 저장함
    files
      .filter((item: any) => item.new === true)
      .map((item: any) => {
        tmpNewFile.push(item.file);
      });

    setModal({
      title: '확인',
      content: '댓글을 저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            if (commentData === null) {
              // 신규
              nstlApi
                .postNstlPrgr({
                  dcmnDntfNmbr: dcmnDntfNmbr,
                  splrId: splrId,
                  teamId: teamId,
                  dtl: contentInput,
                  fileList: tmpNewFile,
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    notifications.show({
                      title: '알림',
                      message: '저장하였습니다.',
                    });

                    // 부모창으로 결과를 넘김
                    callback({
                      resultType: 'refresh',
                    });

                    // 컴포넌트 모달을 닫음
                    setRemoveComponentModal(id);
                  } else {
                    notifications.show({
                      title: '알림',
                      message: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  notifications.show({
                    title: '알림',
                    message: '저장에 실패하였습니다.',
                  });
                });
            } else {
              // 수정
              nstlApi
                .postNstlPrgrNstlPrgrId({
                  dcmnDntfNmbr: dcmnDntfNmbr,
                  splrId: splrId,
                  teamId: teamId,
                  nstlPrgrId: commentData.nstlPrgrId,
                  dtl: contentInput,
                  bfFileIdList: tmpPreFileId,
                  fileList: tmpNewFile,
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    notifications.show({
                      title: '알림',
                      message: '저장하였습니다.',
                    });

                    // 부모창으로 결과를 넘김
                    callback({
                      resultType: 'refresh',
                    });

                    // 컴포넌트 모달을 닫음
                    setRemoveComponentModal(id);
                  } else {
                    notifications.show({
                      title: '알림',
                      message: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  notifications.show({
                    title: '알림',
                    message: '저장에 실패하였습니다.',
                  });
                });
            }
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    setModal({
      title: '확인',
      content: '댓글을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            nstlApi
              .deleteNstlPrgrNstlPrgrId({
                nstlPrgrId: commentData.nstlPrgrId,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  notifications.show({
                    title: '알림',
                    message: '삭제하였습니다.',
                  });

                  // 부모창으로 결과를 넘김
                  callback({
                    resultType: 'remove',
                  });

                  // 컴포넌트 모달을 닫음
                  setRemoveComponentModal(id);
                } else {
                  notifications.show({
                    title: '알림',
                    message: '삭제에 실패하였습니다.',
                  });
                }
              })
              .catch((error: any) => {
                notifications.show({
                  title: '알림',
                  message: '삭제에 실패하였습니다.',
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 첨부파일 추가 버튼을 클릭함
  const handleAddFileButton_onClick = (event: any) => {
    if (event === null) {
      return;
    }

    let tmpFiles: { id: string; new: boolean; file: any }[] =
      _.cloneDeep(files);

    event.map((item: any) => {
      tmpFiles.push({
        id: nanoid(),
        new: true,
        file: item,
      });
    });

    // 첨부파일에 적용함
    setFiles(tmpFiles);
  };

  useEffect(() => {
    // 수정일 경우에 불러온 기본값을 적용함
    if (commentData !== null) {
      // 내용 입력에 적용함
      setContentInput(commentData.dtl);

      // 첨부파일을 정의함
      let tmpFiles: { id: string; new: boolean; file: any }[] = [];

      // 기존 첨부파일을 불러옴
      commentData.fileList.map((item: any) => {
        tmpFiles.push({
          id: item.fileId,
          new: false,
          file: item,
        });
      });

      // 첨부파일에 적용함
      setFiles(tmpFiles);
    }

    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, [files]);

  return (
    <div className="">
      {/* 편집 리스트 */}
      <PbFormGrid cols={1}>
        <PbFormGridCol label="내용" withAsterisk={true}>
          {/* 텍스트 에어리어 입력 */}
          <Textarea
            ref={contentInputRef}
            placeholder="내용을 입력하세요."
            onChange={handleContentInput_onChange}
            value={contentInput}
            minRows={5}
            className="w-full"
          />
        </PbFormGridCol>
        <PbFormGridCol label="첨부파일" withAsterisk={true}>
          <div className="flex flex-wrap gap-1">
            {files.map(
              (
                item: { id: string; new: boolean; file: any },
                index: number,
              ) => {
                if (
                  (item.new === true && isImageFile(item.file.name) === true) ||
                  (item.new === false &&
                    isImageFile(item.file.rgnlFileName) === true)
                ) {
                  // 이미지
                  return (
                    <div
                      onClick={() => {
                        setModal({
                          title: '확인',
                          content: '이 첨부파일을 삭제하시겠습니까?',
                          useOkayButton: false,
                          useCancelButton: true,
                          cancelButtonLabel: '아니오',
                          button: (
                            <Button
                              color="indigo"
                              radius="xl"
                              size="md"
                              onClick={() => {
                                setRemoveModal(true);

                                handleFileImage_onClick(item);
                              }}
                            >
                              예
                            </Button>
                          ),
                        });
                      }}
                      className="button-event border rounded-md overflow-hidden"
                    >
                      {item.new === true ? (
                        <img
                          src={URL.createObjectURL(item.file)}
                          className="w-16 h-16"
                        />
                      ) : (
                        <img
                          key={index}
                          src={[
                            appUtil.getThumbnailImageUrl(),
                            item.file.pldFileName,
                          ].join('')}
                          className="w-16 h-16"
                        />
                      )}
                    </div>
                    // </Popover.Menu>
                  );
                } else {
                  // 기타
                  return (
                    <div
                      onClick={() => {
                        setModal({
                          title: '확인',
                          content: '이 첨부파일을 삭제하시겠습니까?',
                          useOkayButton: false,
                          useCancelButton: true,
                          cancelButtonLabel: '아니오',
                          button: (
                            <Button
                              color="indigo"
                              radius="xl"
                              size="md"
                              onClick={() => {
                                setRemoveModal(true);

                                handleFileImage_onClick(item);
                              }}
                            >
                              예
                            </Button>
                          ),
                        });
                      }}
                      className="button-event border rounded-md overflow-hidden"
                    >
                      <div className="p-1 flex justify-center items-center w-16 h-16">
                        <span className="text-xs text-gray-400">
                          {item.new === true ? (
                            <span>{item.file.name}</span>
                          ) : (
                            <span>{item.file.rgnlFileName}</span>
                          )}
                        </span>
                      </div>
                    </div>
                    // </Popover.Menu>
                  );
                }
              },
            )}

            {/* 첨부파일 선택 버튼 */}
            <FileButton onChange={handleAddFileButton_onClick} multiple={true}>
              {(props) => (
                <div
                  {...props}
                  className="button-event flex justify-center items-center w-16 h-16 bg-gray-100 border border-dashed border-gray-300 rounded-md overflow-hidden"
                >
                  <div className="flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'plus']}
                      className="w-4 h-4 text-gray-400"
                    />
                  </div>
                </div>
              )}
            </FileButton>
          </div>
        </PbFormGridCol>
      </PbFormGrid>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 신규 상태 */}
        {commentData === null && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveButton_onClick}
            >
              저장
            </Button>
          </>
        )}

        {/* 수정 상태 */}
        {commentData !== null && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              variant="outline"
              color="pink"
              radius="xl"
              size="md"
              onClick={handleRemoveButton_onClick}
            >
              삭제
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ModifyComment;
