import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { PageLayout } from '../../components/PageLayout';
import moment from 'moment';
import { PbButton } from '../../components/PbButton';
import { PbSelect2 } from '../../components/PbSelect2';
import { PbTable } from '../../components/PbTable';
import { PbFormGrid } from '../../components/PbFormGrid';
import { PbFormGridCol } from '../../components/PbFormGridCol';
import { PbMonthPicker } from '../../components/PbMonthPicker';
import { PbInputText } from '../../components/PbInputText';
import * as csApi from '../../apis/cs.api';

/**
 * 운영 관리 > CS 조회
 * @constructor
 */
const CS_02_01Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 테이블 컬럼을 정의함
  const [tableColumn, setTableColumn] = useState<ITableColumn[]>([]);

  // 테이블 데이터를 정의함
  const [tableData, setTableData] = useState<any[]>([]);

  // 운영관리 현황을 불러옴
  const [cssPrtn, setCssPrtn] = useState<any>([]);

  // 검색 폼 그리드 > 문의명 > 셀렉트를 정의함
  const [searchTypeSelect, setSearchTypeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 임시 :  검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
  const [clientSearchInput, setClientSearchInput] = useState<string>('');

  // 임시 :  검색 폼 그리드 > 검색어 > 검색어 입력의 값을 변경함
  const handleClientSearchInput_onChange = (event: any) => {
    setClientSearchInput(event.target.value);
  };

  // 상세검색 조건의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  // 상세검색의 등록기간을 정의함
  const [addDate, setAddDate] = useState<{ start: string; end: string }>({
    start: '',
    end: '',
  });

  // 상세검색의 등록기간의 년월 피커를 변경함
  const handleAddStartDate_onChange = (event: any) => {
    setAddDate({
      ...addDate,
      start: event.target.value,
    });
  };
  const handleAddEndDate_onChange = (event: any) => {
    setAddDate({
      ...addDate,
      end: event.target.value,
    });
  };

  // 상세검색의 처리완료 요청기간을 정의함
  const [requestDate, setRequestDate] = useState<{
    start: string;
    end: string;
  }>({
    start: '',
    end: '',
  });

  // 상세검색의 처리완료 요청기간의 년월 피커를 변경함
  const handleRequestStartDate_onChange = (event: any) => {
    setRequestDate({
      ...requestDate,
      start: event.target.value,
    });
  };
  const handleRequestEndDate_onChange = (event: any) => {
    setRequestDate({
      ...requestDate,
      end: event.target.value,
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 오늘 날짜를 불러옴
    let today: string = moment().format('yyyy-MM');

    // 상세검색의 등록기간을 초기화함
    setAddDate({
      start: today,
      end: today,
    });

    // 상세검색의 수정기간을 초기화함
    setRequestDate({
      start: today,
      end: today,
    });

    // 임시 : 검색 폼 그리드 > ... > 셀렉트의 아이템에 적용함
    setSearchTypeSelect({
      value: '',
      item: [
        {
          label: '고객사명',
          value: '1',
        },
        {
          label: '영업기회명',
          value: '2',
        },
        {
          label: '영업담당자명',
          value: '3',
        },
      ],
    });

    return () => {};
  }, []);

  useEffect(() => {
    // 테이블 컬럼을 정의함
    let tmpTableColumn: ITableColumn[] = [];

    // 테이블 컬럼에 추가함
    tmpTableColumn.push(
      {
        column: 'no',
        title: '번호',
        width: '0',
        align: 'center',
      },
      {
        column: '',
        title: '접수유형',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '장애구분',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '문의명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '고객사명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: 'Shop명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '고객특성',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '최근 이관 정보',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '현재 담당부서',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '콜센터 담당자명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '운영팀 담당자명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '개발팀 담당자명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '현장팀 담당자명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: 'CS 작업상태',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: 'CS 진행상태',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '리드타임',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '처리완료 요청일',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '등록일시',
        width: '0',
        align: 'center',
      },
    );

    // 테이블 컬럼에 적용함
    setTableColumn(tmpTableColumn);

    // 테이블 데이터를 정의함
    let tmpTableData: any[] = [];

    // 테이블 데이터에 추가함
    // cssPrtnItem.map(
    //   (item: { title: string; column: string }, index: number) => {
    //     let tmpTableDataRow: any = {};

    //     tmpTableDataRow['step'] = item.title;

    //     cssPrtn.list?.map((subItem: any, index: number) => {
    //       tmpTableDataRow[subItem.rgstMonth] = subItem[item.column];
    //     });

    //     // 테이블 데이터에 추가함
    //     tmpTableData.push(tmpTableDataRow);
    //   },
    // );

    // 테이블 데이터에 적용함
    setTableData(tmpTableData);

    return () => {};
  }, [cssPrtn]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        {/* 검색폼 */}
        <PbFormGrid label="검색" cols={2}>
          <PbFormGridCol label="검색어" colSpan={2}>
            {/* 셀렉트 */}
            <PbSelect2
              size="xs"
              // onChange={handleClientSelect_onChange}
              item={searchTypeSelect.item}
              value={searchTypeSelect.value}
              useDefaultItem={true}
            />
            {/* 검색어 입력 */}
            <PbInputText
              placeholder="검색어를 입력하세요."
              size="sm"
              onChange={handleClientSearchInput_onChange}
              value={clientSearchInput}
            />
            <PbButton
              label="통합 검색"
              backgroundColor="purple"
              size="sm"
              onClick={() => {
                console.log('검색');
              }}
            />

            <PbButton
              label="취소"
              backgroundColor="white"
              size="sm"
              onClick={() => {
                console.log('취소');
              }}
            />

            <PbButton
              label={hideDetailSearch ? '상세검색 열기' : '상세검색 닫기'}
              backgroundColor="white"
              size="sm"
              onClick={() => setHideDetailSearch(!hideDetailSearch)}
            />
          </PbFormGridCol>
          <PbFormGridCol label="등록기간" isHidden={hideDetailSearch}>
            <PbMonthPicker
              onChange={handleAddStartDate_onChange}
              value={addDate.start}
            />

            <div className="mx-2">
              <span>~</span>
            </div>

            <PbMonthPicker
              onChange={handleAddEndDate_onChange}
              value={addDate.end}
            />
          </PbFormGridCol>
          <PbFormGridCol
            label="처리 완료 요청 기간"
            isHidden={hideDetailSearch}
          >
            <PbMonthPicker
              onChange={handleRequestStartDate_onChange}
              value={requestDate.start}
            />

            <div className="mx-2">
              <span>~</span>
            </div>

            <PbMonthPicker
              onChange={handleRequestEndDate_onChange}
              value={requestDate.end}
            />
          </PbFormGridCol>

          <PbFormGridCol label="접수 유형" isHidden={hideDetailSearch}>
            {/* 셀렉트 */}
            <PbSelect2
              size="xs"
              // onChange={handleClientSelect_onChange}
              // item={clientSelect.item}
              // value={clientSelect.value}
              useDefaultItem={true}
            />
            {/* 셀렉트 */}
            <PbSelect2
              size="xs"
              // onChange={handleClientSelect_onChange}
              // item={clientSelect.item}
              // value={clientSelect.value}
              useDefaultItem={true}
            />
          </PbFormGridCol>

          <PbFormGridCol label="접수 구분" isHidden={hideDetailSearch}>
            {/* 셀렉트 */}
            <PbSelect2
              size="xs"
              // onChange={handleClientSelect_onChange}
              // item={clientSelect.item}
              // value={clientSelect.value}
              useDefaultItem={true}
            />
          </PbFormGridCol>

          <PbFormGridCol label="고객 특성" isHidden={hideDetailSearch}>
            {/* 셀렉트 */}
            <PbSelect2
              size="xs"
              // onChange={handleClientSelect_onChange}
              // item={clientSelect.item}
              // value={clientSelect.value}
              useDefaultItem={true}
            />
          </PbFormGridCol>

          <PbFormGridCol label="현재 담당부서" isHidden={hideDetailSearch}>
            {/* 셀렉트 */}
            <PbSelect2
              size="xs"
              // onChange={handleClientSelect_onChange}
              // item={clientSelect.item}
              // value={clientSelect.value}
              useDefaultItem={true}
            />
          </PbFormGridCol>

          <PbFormGridCol label="CS 작업 상태" isHidden={hideDetailSearch}>
            {/* 셀렉트 */}
            <PbSelect2
              size="xs"
              // onChange={handleClientSelect_onChange}
              // item={clientSelect.item}
              // value={clientSelect.value}
              useDefaultItem={true}
            />
          </PbFormGridCol>

          <PbFormGridCol label="CS 진행 상태" isHidden={hideDetailSearch}>
            {/* 셀렉트 */}
            <PbSelect2
              size="xs"
              // onChange={handleClientSelect_onChange}
              // item={clientSelect.item}
              // value={clientSelect.value}
              useDefaultItem={true}
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* content-wrapper */}
        <div className="content-wrapper">
          {/* table-wrapper */}
          <div className="table-wrapper">
            {/* info-wrapper */}
            <div className="info-wrapper">
              <div className="total">
                총 조회 <span className="bold">20</span>건
              </div>
              <div className="btn-wrapper">
                <div className="btn">엑셀 다운로드</div>
              </div>

              {/* select-wrapper */}
              <div className="select-wrapper">
                <div className="form-select-wrapper">
                  <select defaultValue="1" className="form-select">
                    <option value="1">최근 등록일 순</option>
                    <option>select 1</option>
                    <option>select 2</option>
                  </select>
                </div>
              </div>
              {/* select-wrapper */}
            </div>
            {/* info-wrapper */}

            {/* table */}
            <PbTable
              column={tableColumn}
              data={tableData}
              striped={false}
              highlightOnHover={true}
              userSelectNone={true}
              usePagination={true}
              onClickRow={(item: any) => console.log('> item: ', item)}
            />
            {/* table */}
          </div>
          {/* table-wrapper */}
        </div>
        {/* content-wrapper */}
      </div>
    </PageLayout>
  );
};

export default CS_02_01Page;
