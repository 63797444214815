import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useElementSize } from '@mantine/hooks';
import _ from 'lodash';
import { PbTable } from '../PbTable';
import {
  IApiResult,
  IComponentModal,
  ITableColumn,
} from '../../interfaces/app.interface';
import * as slsApi from '../../apis/sls.api';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { nanoid } from 'nanoid';
import { PbButton } from '../PbButton';
import { MN2302220502_NetworkDetail } from '../MN2302220502_NetworkDetail';
import { getSlsPrtnSearchUser } from '../../apis/sls.api';
import { AgGridReact } from 'ag-grid-react';
import { phoneNumberformatter } from '../../utils/app.util';

interface IComponentProps {
  id?: string;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * 영업 관리 > 영업 기회 조회 > 등록 > 담당자 변경
 * @param id 컴포넌트 모달 아이디
 * @param data <인자>
 * @param onClick <이벤트>
 * @param callback 콜백 이벤트
 * @constructor
 */
const MN2302220202_Manager = ({
  id = '',
  data,
  onClick,
  callback,
}: PropsWithChildren<IComponentProps>) => {
  // 페이지의 내용 영역 크기를 정의함
  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useElementSize();

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 담당자 테이블을 정의함
  const [managerTable, setManagerTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 담당자 테이블을 초기화함
  const initManagerTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      { headerName: '아이디', field: 'userId' },
      { headerName: '이름', field: 'name' },
      { headerName: '본부명', field: 'hdqrName' },
      { headerName: '부서명', field: 'dprtName' },
      {
        headerName: '전화번호',
        field: 'celPhn',
        valueFormatter: (params: any) => phoneNumberformatter(params.value),
      },
      { headerName: '이메일', field: 'ml', suppressSizeToFit: true },
    );

    // 담당자 테이블에 적용함
    setManagerTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 담당자 테이블 데이터를 불러옴
  const getManagerTableData = () => {
    slsApi
      .getSlsPrtnSearchUser({ currPageIdx: 1, pageSize: 1000 })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 담당자 테이블에 적용함
          setManagerTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));
        }
      });
  };

  // 담당자 테이블의 행을 클릭함
  const handleManagerTable_onRowDoubleClicked = (row: any) => {
    if (callback !== undefined) {
      callback(row.data);

      // 컴포넌트 모달을 닫음
      setRemoveComponentModal(id);
    }
  };

  useEffect(() => {
    // 담당자 테이블을 초기화함
    initManagerTable();

    return () => {};
  }, []);

  // 담당자 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (managerTable.column.length === 0) {
      return;
    }

    // 담당자 테이블 데이터를 불러옴
    getManagerTableData();

    return () => {};
  }, [managerTable.column]);

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      {/*<div ref={contentRef} className="w-full h-full">*/}
      {/*<PbTable*/}
      {/*  column={managerTable.column}*/}
      {/*  data={managerTable.data}*/}
      {/*  striped={false}*/}
      {/*  highlightOnHover={true}*/}
      {/*  userSelectNone={true}*/}
      {/*  usePagination={true}*/}
      {/*  // useHorizontalScroll={true}*/}
      {/*  // width={contentWidth}*/}
      {/*  // maxScrollWidth={3000}*/}
      {/*  rowPerPage={20}*/}
      {/*  onClickRow={handleManagerTable_onRowDoubleClicked}*/}
      {/*/>*/}

      <div className="w-full h-152">
        {/* 테이블 */}
        <AgGridReact
          columnDefs={managerTable.column}
          rowData={managerTable.data}
          onRowDoubleClicked={handleManagerTable_onRowDoubleClicked}
          defaultColDef={{
            resizable: true,
            sortable: true,
            wrapHeaderText: false,
            autoHeaderHeight: true,
            suppressSizeToFit: true,
          }}
          rowSelection="single"
          className="ag-theme-alpine"
        ></AgGridReact>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        <div>
          <PbButton
            label="닫기"
            backgroundColor="white"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          />
        </div>
      </div>
    </div>
  );
};

export default MN2302220202_Manager;
