import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { IApiResult, IComponentModal } from '../../interfaces/app.interface';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import * as nstlApi from '../../apis/nstl.api';
import { Accordion } from '@mantine/core';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';

interface IComponentProps {
  id?: string;
  searchYmd: string;
  data?: any;
  onClick?: () => void;
}

/**
 * <Component 이름>
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220303_Days = ({
  id = '',
  searchYmd,
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 지정한 날짜의 캘린더의 이벤트 데이터를 정의함
  const [calendarEventAtDay, setCalendarEventAtDay] = useState<any[]>([]);

  // 캘린더의 이벤트 테이블 데이터를 불러옴
  const getCalendarEventTableData = () => {
    nstlApi
      .getPrcdsAtDay({
        // isEslNstl: params.isEslNstl || 'Y',
        // isNtwrCnst: params.isNtwrCnst || 'Y',
        // nstlSttsCode: params.nstlSttsCode,
        prjcCode: '1110230101',
        yyyymmdd: searchYmd,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 지정한 날짜의 캘린더의 이벤트 데이터에 적용함
          setCalendarEventAtDay(data.data.list);

          console.log('> modal data.data.list:', data.data.list);
        }
      })
      .catch((error: any) => {});
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 캘린더의 이벤트 테이블 데이터를 불러옴
    getCalendarEventTableData();

    return () => {};
  }, []);

  return (
    <div>
      {calendarEventAtDay.length > 0 && (
        <Accordion defaultValue="customization">
          {calendarEventAtDay.map((item: any, index: number) => (
            <Accordion.Item key={index} value={item.no.toString()}>
              <Accordion.Control>
                <div className="flex justify-between items-center">
                  <div>
                    <span className="font-bold">{item.nstlSttsName}</span>
                  </div>

                  <div>
                    {item.shipToCode || 'Shop 없음'} ({item.splrTypeName})
                  </div>
                </div>
              </Accordion.Control>
              <Accordion.Panel className="pt-1 bg-gray-100/50">
                {/* 상세정보 폼 그리드 */}
                <PbFormGrid cols={1}>
                  <PbFormGridCol label="프로젝트">
                    <span className="text-sm">{item.prjcName}</span>
                  </PbFormGridCol>

                  <PbFormGridCol label="작업일정">
                    <span className="text-sm">{item.workStrtDttm}</span>
                    <span className="text-sm">~</span>
                    <span className="text-sm">{item.workEndDttm}</span>
                  </PbFormGridCol>

                  <PbFormGridCol label="고객사명">
                    <span className="text-sm">{item.bpName}</span>
                  </PbFormGridCol>

                  <PbFormGridCol label="주문번호">
                    <span className="text-sm">{item.dcmnDntfNmbr}</span>
                  </PbFormGridCol>

                  <PbFormGridCol label="상태">
                    <span className="text-sm">{item.nstlSttsName}</span>
                  </PbFormGridCol>
                </PbFormGrid>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </div>
  );
};

export default MN2302220303_Days;
