import React, { PropsWithChildren, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IModal } from '../../interfaces/app.interface';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { modals } from '@mantine/modals';
import { PbButton } from '../PbButton';
import { Button } from '@mantine/core';

interface IComponentProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 모달 매니저
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const ModalManager = ({
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 모달 닫기 처리를 정의함
  let isClosedModal: boolean = false;

  // 모달 닫기 버튼을 클릭함
  const handleCloseModalButton_onClick = () => {
    if (modal.callback !== undefined) {
      if (!isClosedModal) {
        modal.callback();

        isClosedModal = true;
      }
    }

    // 모달을 삭제함
    setRemoveModal(true);
  };

  useEffect(() => {
    if (!modal.title) {
      modals.closeAll();

      return;
    }

    // 화면 가운데 출력 여부를 정의함
    let centered: boolean = false;

    if (modal.centered === undefined || modal.centered) {
      centered = true;
    }

    // 확인 버튼을 정의함
    let useOkayButton: boolean = true;
    let okayButtonLabel: string = '확인';

    if (modal.useOkayButton !== undefined && !modal.useOkayButton) {
      useOkayButton = false;
    }

    if (modal.okayButtonLabel !== undefined && modal.okayButtonLabel) {
      okayButtonLabel = modal.okayButtonLabel;
    }

    // 취소 버튼을 정의함
    let useCancelButton: boolean = false;
    let cancelButtonLabel: string = '취소';

    if (modal.useCancelButton !== undefined && modal.useCancelButton) {
      useCancelButton = true;
    }

    if (modal.cancelButtonLabel !== undefined && modal.cancelButtonLabel) {
      cancelButtonLabel = modal.cancelButtonLabel;
    }

    modals.open({
      title: (
        <div className="p-0.5">
          <span className="text-lg text-gray-700 font-bold">{modal.title}</span>
        </div>
      ),
      children: (
        <div className="space-y-3">
          {/* 내용 */}
          <div className="p-0.5">
            <span className="text-base text-gray-500 font-bold">
              {modal.content}
            </span>
          </div>

          {/* 버튼 */}
          <div className="w-full flex justify-end items-center space-x-2">
            {useCancelButton && (
              <>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  size="md"
                  onClick={handleCloseModalButton_onClick}
                >
                  {cancelButtonLabel}
                </Button>
              </>
            )}

            {modal.button}

            {useOkayButton && (
              <>
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  size="md"
                  onClick={handleCloseModalButton_onClick}
                >
                  {okayButtonLabel}
                </Button>
              </>
            )}
          </div>
        </div>
      ),
      withCloseButton: false,
      centered: centered,
      zIndex: 2000,
      onClose: handleCloseModalButton_onClick,
    });

    return () => {};
  }, [modal]);

  // 모달이 삭제됨
  useEffect(() => {
    if (!removeModal) {
      return;
    }

    // 모달을 삭제함
    setModal({
      title: '',
      content: '',
    });

    // 모달을 임시 공간에서 삭제함
    setRemoveModal(false);
  }, [removeModal]);

  return <></>;
};

export default ModalManager;
