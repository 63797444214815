import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useDownloadExcel } from 'react-export-table-to-excel';
import _ from 'lodash';
import {
  IApiListResult,
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  IMatchMenuIdPageId,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import * as sttnApi from '../apis/sttn.api';
import moment from 'moment';
import { PbButton } from '../components/PbButton';
import { PbTable } from '../components/PbTable';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { PbDatePicker } from '../components/PbDatePicker';
import { PbInputText } from '../components/PbInputText';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  componentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import { MN2302220451 } from '../components/MN2302220451';
import { nanoid } from 'nanoid';
import { Button, Select, TextInput } from '@mantine/core';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { AgGridReact } from 'ag-grid-react';
import { PbAgGridReact } from '../components/PbAgGridReact';
import PbSelect from '../components/PbSelect/PbSelect.component';

/**
 * 현황 관리 > ERP 판매계약 현황
 * @constructor
 */
const MN2302220701Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**
   * 검색 구분 / 검색어 입력
   */

  // 검색 폼 그리드 > 검색어 > 검색구분 셀렉트를 정의함
  const [searchCategorySelect, setSearchCategorySelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');

  // 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCategorySelect_onChange = (event: any) => {
    setSearchCategorySelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchInput(event.currentTarget.value);
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력에서 키를 입력함
  const handleSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
  const handleSearchButton_onChange = () => {
    // 테이블의 페이지를 변경함
    searchResultTablePagingCurrentPageRef.current = 1;
    setSearchResultTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: searchResultTablePagingCurrentPageRef.current,
    }));

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();
  };

  // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
  const handleSearchCancelButton_onChange = () => {
    // 검색 폼 그리드 > 검색어 > 셀렉트 값을 초기화함
    setSearchCategorySelect((pre: ISelect) => ({
      ...pre,
      value: 'bp_name',
    }));

    // 검색 폼 그리드 > 검색어 > 검색어 입력 값을 초기화함
    setSearchInput('');

    // 검색 폼 그리드 > 계약 진행 상황 > 셀렉트 값을 초기화함
    setSearchContractStateSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 사용 여부 > 셀렉트 값을 초기화함
    setSearchUseYnSelect((pre: ISelect) => ({
      ...pre,
      value: 'Y',
    }));
  };

  // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
  const initSearchCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '고객사명',
        value: 'bp_name',
      },
      {
        label: '프로젝트명',
        value: 'prjc_name',
      },
      {
        label: '계약명',
        value: 'cntr_name',
      },
    );

    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    setSearchCategorySelect((pre: ISelect) => ({
      value: 'bp_name',
      item: tmpOptionItem,
    }));
  };

  /* // 검색 구분 / 검색어 입력 */

  /**
   * 상세 검색 조건
   */

  /* 계약 진행 상황 */
  // 상세 검색 > 계약 진행 상황 > 셀렉트를 정의함
  const [searchContractStateSelect, setSearchContractStateSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 상세 검색 > 계약 진행 상황 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchContractStateSelect_onChange = (event: any) => {
    setSearchContractStateSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 상세 검색 > 계약 진행 상황 > 셀렉트의 아이템을 초기화함
  const initSearchContractStateSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '진행',
        value: 'prcd',
      },
      {
        label: '종료',
        value: 'end',
      },
    );

    // 상세 검색 > 계약 진행 상황 > 셀렉트의 아이템에 적용함
    setSearchContractStateSelect((pre: ISelect) => ({
      value: '',
      item: tmpOptionItem,
    }));
  };

  /* 사용 여부 */
  // 상세 검색 > 사용 여부 > 셀렉트를 정의함
  const [searchUseYnSelect, setSearchUseYnSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 상세 검색 > 사용 여부 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchUseYnSelect_onChange = (event: any) => {
    setSearchUseYnSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 상세 검색 > 사용 여부 > 셀렉트의 아이템을 초기화함
  const initSearchUseYnSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '사용',
        value: 'Y',
      },
      {
        label: '미사용',
        value: 'N',
      },
    );

    // 상세 검색 > 사용 여부 > 셀렉트의 아이템에 적용함
    setSearchUseYnSelect((pre: ISelect) => ({
      value: 'Y',
      item: tmpOptionItem,
    }));
  };

  /* 담당PM */
  // 검색 폼 그리드  > 담당 PM 셀렉트를 정의함
  const [pmNameSelect, setPmNameSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 담당 PM 셀렉트에서 선택한 아이템을 변경함
  const handlePmNameSelect_onChange = (event: any) => {
    setPmNameSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템을 초기화함
  const initPmNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: 'all',
      },
      {
        label: '담당자 없음',
        value: '',
      },
    );

    // 판매 계약 담당PM 목록을 불러옴
    sttnApi.getSttnErpSearchPm({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.name,
          value: item.userId,
        });
      });

      // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템에 적용함
      setPmNameSelect((pre: ISelect) => ({
        ...pre,
        item: tmpOptionItem,
      }));
    });
  };

  /* // 상세 검색 조건 */

  /**
   * 검색 / 검색 조건 초기화 / 상세검색 열기 / 상세검색 닫기
   */

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    setHideDetailSearch(!hideDetailSearch);
  };

  /* // 검색 / 검색 조건 초기화 / 상세검색 열기 / 상세검색 닫기 */

  /**
   * 엑셀 다운로드
   */

  // 검색 결과 테이블의 엑셀 다운로드 버튼을 클릭함
  const handleSearchResultTableExportExcelButton_onClick = () => {
    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="md"
            onClick={() => {
              sttnApi
                .getSttnErpExcel({
                  searchType: searchCategorySelect.value, // 검색 구분
                  searchKeyword: searchInput.trim(), // 검색어
                  cntrPrgrCode: searchContractStateSelect.value, // 계약 진행 상황 코드
                  useYn: searchUseYnSelect.value, // 사용 여부
                  pmId: pmNameSelect.value, // 담당PM
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    [
                      'ERP 판매 계약 현황_',
                      moment().format('YYYYMMDD'),
                      '.xlsx',
                    ].join(''),
                  );
                  document.body.appendChild(link);
                  link.click();
                });

              setRemoveModal(true);
            }}
          />
        </div>
      ),
    });
  };

  /* // 엑셀 다운로드  */

  /**
   * 사용자 관리 테이블
   */

  // 검색 결과 테이블 데이터를 불러옴
  const getSearchResultTableData = () => {
    // 검색 결과 테이블의 로딩 여부에 적용함
    setSearchResultTableLoading(true);

    sttnApi
      .getSttnErps({
        searchType: searchCategorySelect.value, // 검색 구분
        searchKeyword: searchInput.trim(), // 검색어
        cntrPrgrCode: searchContractStateSelect.value, // 계약 진행 상황 코드
        useYn: searchUseYnSelect.value, // 사용 여부
        pmId: pmNameSelect.value, // 담당PM
        pageSize: searchResultTablePaging.rowPerPage,
        currPageIdx: searchResultTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setSearchResultTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
            }),
          );

          // 테이블을 페이징함
          setSearchResultTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }

        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      });
  };

  // 검색 결과 테이블의 행을 클릭함
  const handleSearchResultTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220451';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'ERP 판매 계약 상세',
      content: <MN2302220451 id={tmpId} cntrNmbr={row.data.cntrNmbr} />,
      size: 1500,
    });
  };

  //등록 버튼 클릭
  const handleErpContractBtn_onClick = () => {
    let tmpId: string = 'MN2302220451';
    setAddComponentModal({
      id: tmpId,
      title: 'ERP 판매계약 등록',
      content: <MN2302220451 id={tmpId} />,
      size: 1500,
    });
  };

  // 검색 결과 테이블을 정의함
  const [searchResultTable, setSearchResultTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 검색 결과 테이블 페이징을 정의함
  const [searchResultTablePaging, setSearchResultTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const searchResultTablePagingCurrentPageRef = useRef<number>(1);

  // 검색 결과 테이블의 로딩 여부를 정의함
  const [searchResultTableLoading, setSearchResultTableLoading] =
    useState<boolean>(false);

  // 검색 결과 테이블을 초기화함
  const initSearchResultTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      // {
      //   field: 'no',
      //   headerName: '번호',
      //   width: 80,
      //   suppressSizeToFit: true,
      // },
      // {
      //   field: 'cntrNmbr',
      //   headerName: '판매 계약 번호',
      //   width: 120,
      //   suppressSizeToFit: true,
      // },
      {
        field: 'prjcName',
        headerName: '프로젝트명',
      },
      {
        field: 'cntrName',
        headerName: '계약명',
        cellRenderer: (params: any) => {
          return (
            <>
              [{params.data.cntrNmbr}] {params.data.cntrName}
            </>
          );
        },
      },
      // {
      //   field: 'prjcCode',
      //   headerName: '프로젝트 코드',
      //   width: 130,
      //   suppressSizeToFit: true,
      // },
      {
        field: 'bpName',
        headerName: '고객사명',
        width: 250,
        suppressSizeToFit: true,
      },
      // {
      //   field: 'bpCode',
      //   headerName: '고객사 코드',
      //   width: 130,
      //   suppressSizeToFit: true,
      // },
      {
        field: 'pmName',
        headerName: '담당PM',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: 'cntrPrgrName',
        headerName: '계약 진행 상황',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        field: 'useYn',
        headerName: '사용 여부',
        width: 100,
        suppressSizeToFit: true,
      },
    );

    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /* // 사용자 관리 테이블  */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 검색 폼 그리드 > 검색어 > 검색구분 셀렉트 초기화
    initSearchCategorySelect();

    // 상세 검색 > 계약 진행 상황 > 셀렉트 초기화
    initSearchContractStateSelect();

    // 상세 검색 > 사용 여부 > 셀렉트 초기화
    initSearchUseYnSelect();

    // 상세 검색 > 담당PM > 셀렉트 초기화
    initPmNameSelect();

    // 사용자 목록 테이블 초기화
    initSearchResultTable();

    return () => {};
  }, []);

  // 검색 결과 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (searchResultTable.column.length === 0) {
      return;
    }

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();

    return () => {};
  }, [searchResultTable.column]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220401Table') > -1) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220401Table');
    }

    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid label="검색" cols={2}>
            <PbFormGridCol label="검색어" colSpan={2}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchCategorySelect_onChange}
                data={searchCategorySelect.item}
                value={searchCategorySelect.value}
                setSelect={setSearchCategorySelect}
              />

              {/* 텍스트 입력 */}
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={handleSearchInput_onChange}
                onKeyUp={handleSearchInput_onKeyUp}
                value={searchInput}
                className="w-1/5"
              />

              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleSearchButton_onChange}
                >
                  검색
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchCancelButton_onChange}
                >
                  검색 조건 초기화
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleShowSearchDetailButton_onChange}
                >
                  {hideDetailSearch ? (
                    <>
                      상세검색 열기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-down']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      상세검색 닫기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-up']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  )}
                </Button>
              </div>
            </PbFormGridCol>
            <PbFormGridCol label="계약 진행 상황" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchContractStateSelect_onChange}
                data={searchContractStateSelect.item}
                value={searchContractStateSelect.value}
                setSelect={setSearchContractStateSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="사용 여부" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchUseYnSelect_onChange}
                data={searchUseYnSelect.item}
                value={searchUseYnSelect.value}
                setSelect={setSearchUseYnSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol
              label="담당PM"
              colSpan={2}
              isHidden={hideDetailSearch}
            >
              {/* 셀렉트 */}
              <PbSelect
                onChange={handlePmNameSelect_onChange}
                data={pmNameSelect.item}
                value={pmNameSelect.value}
                setSelect={setPmNameSelect}
                placeholder="검색어를 입력하시거나 목록을 선택하세요."
                searchable={true}
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 테이블 */}
          <div className="space-y-3">
            <div className="flex justify-between items-center space-x-5">
              <div>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchResultTableExportExcelButton_onClick}
                >
                  엑셀 다운로드
                </Button>
              </div>

              <div className="flex justify-center items-center space-x-5">
                <div className="flex justify-center items-center">
                  <span className="text-base text-gray-600 font-bold">
                    총 {searchResultTablePaging.totalRow}건
                  </span>
                </div>
              </div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-160">
              <PbAgGridReact
                columnDefs={searchResultTable.column}
                rowData={searchResultTable.data}
                loading={searchResultTableLoading}
                setLoading={setSearchResultTableLoading}
                onRowDoubleClicked={handleSearchResultTable_onRowDoubleClicked}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
                visiblePaging={true}
                paging={{
                  totalPage: searchResultTablePaging.totalPage,
                  currentPage: searchResultTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  searchResultTablePagingCurrentPageRef.current = event;
                  setSearchResultTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: searchResultTablePagingCurrentPageRef.current,
                  }));

                  // 검색 결과 테이블 데이터를 불러옴
                  getSearchResultTableData();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220701Page;
