import axios, { AxiosResponse } from 'axios';
import { IApiListResult, IApiResult } from '../interfaces/app.interface';
import api from '../utils/axios.util';

/**
 * NTCB: 게시판
 */

/**
 * FAQ 접수유형 (셀렉트박스)
 * GET /api/ntcb/cs-type
 * @param params depth 접수유형 구분
 */
export const getFaqCsType = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/ntcb/cs-type',
    params: {
      depth: params.depth || 1, //1: 접수유형 2: 접수유형상세
      searchKeyword: params.searchKeyword || '', //접수유형
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * FAQ 상세
 * GET /api/ntcb/faq/{brdId}
 * @param params brdId 게시판 아이디
 */
export const getFaq = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/ntcb/faq/', params.brdId].join(''),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * FAQ 목록
 * GET /api/ntcb/faq
 * @param params cstyCode CS유형 코드
 * @param params cstyGrpCode CS유형 그룹 코드
 * @param params currPageIdx 현재페이지
 * @param params searchKeyword 검색어
 */
export const getFaqs = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/ntcb/faqs',
    params: {
      cstyCode: params.cstyCode || '',
      cstyGrpCode: params.cstyGrpCode || '',
      currPageIdx: params.currPageIdx || '1',
      searchKeyword: params.searchKeyword || '',
      pageSize: params.pageSize || 2000,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 매뉴얼 상세
 * GET /api/ntcb/manual/{brdId}
 * @param params brdId 게시판 아이디
 */
export const getManual = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/ntcb/manual/', params.brdId].join(''),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 매뉴얼 목록
 * GET /api/ntcb/manuals
 * @param params currPageIdx 현재페이지
 * @param params mnlTypeCode 매뉴얼 유형 코드
 * @param params ttl 제목
 */
export const getManuals = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/ntcb/manuals',
    params: {
      currPageIdx: params.currPageIdx || '1',
      mnlTypeCode: params.mnlTypeCode || '',
      searchKeyword: params.searchKeyword || '',
      pageSize: params.pageSize || 2000,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공지사항 파일 다운로드
 * GET /api/ntcb/nncm-file/{fileId}
 * @param params fileId 파일 아이디
 */
export const getNncmFile = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: ['/api/ntcb/nncm-file/', params.fileId].join(''),
    responseType: 'blob',
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {
      console.log('> getNncmFile Error:', error);
    });

  return result;
};

/**
 * 공지사항 상세화면
 * GET /api/ntcb/notice/{brdId}
 * @param params brdId 게시글 일련 번호
 */
export const getNotice = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/ntcb/notice/', params.brdId].join(''),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공지사항 목록 조회
 * GET /api/ntcb/notices
 * @param params currPageIdx 현재페이지
 * @param params ntcTypeCode 공지 유형
 * @param params openYn 공지 여부
 * @param params ttl 게시글 제목
 */
export const getNotices = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/ntcb/notices',
    params: {
      currPageIdx: params.currPageIdx || '1',
      ntcTypeCode: params.ntcTypeCode || '',
      openYn: params.openYn || '',
      ttl: params.ttl || '',
      pageSize: params.pageSize || 2000,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * FAQ 등록
 * POST /api/ntcb/faq
 * @param params paramVo
 */
export const postFaq = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/ntcb/faq',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 매뉴얼 등록
 * POST /api/ntcb/manual
 * @param params mnlTypeCode 매뉴얼 유형 코드
 * @param params linkUrlList 링크
 * @param params linkDscrList 링크 설명
 * @param params ttl 제목
 * @param params dtl 내용
 * @param params fileList 파일
 * @param params fileDscrList 파일 설명
 */
export const postManual = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/ntcb/manual/',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      mnlTypeCode: params.mnlTypeCode || '',
      linkUrlList: params.linkUrlList || [],
      linkDscrList: params.linkDscrList || [],
      ttl: params.ttl || '',
      dtl: params.dtl || '',
      fileList: params.fileList || [],
      fileDscrList: params.fileDscrList || [],
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 매뉴얼 수정
 * POST /api/ntcb/manual/{brdId}
 * @param params brdId 게시판 아이디
 * @param params mnlTypeCode 매뉴얼 유형 코드
 * @param params linkUrlList 링크
 * @param params linkDscrList 링크 설명
 * @param params ttl 제목
 * @param params dtl 내용
 * @param params fileList 파일
 * @param params fileDscrList 파일 설명
 * @param params brdId 게시판 아이디
 */
export const putManual = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };
  console.log(params);
  await api({
    method: 'POST',
    url: ['/api/ntcb/manual/', params.brdId].join(''),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      mnlTypeCode: params.mnlTypeCode || '',
      ttl: params.ttl || '',
      dtl: params.dtl || '',

      linkUrlList: params.linkUrlList || [],
      linkDscrList: params.linkDscrList || [],
      bfLinkIdList: params.bfLinkIdList || [],
      bfLinkDscrList: params.bfLinkDscrList || [],

      fileList: params.fileList || [],
      fileDscrList: params.fileDscrList || [],
      bfFileIdList: params.bfFileIdList || [],
      bfFileDscrList: params.bfFileDscrList || [],
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공지사항 등록
 * POST /api/ntcb/notice
 * @param params ntcTypeCode 공지 유형 코드
 * @param params file 공지사항 첨부 파일
 * @param params xpsrNdt 게시 종료일
 * @param params xpsrStrt 게시 시작일
 * @param params xpsrStprYn 노출 기간 설정여부
 * @param params openYn 공개여부
 * @param params dtl 게시글 내용
 * @param params ttl 게시글 제목
 */
export const postNotice = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/ntcb/notice',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      ntcTypeCode: params.ntcTypeCode || '',
      // file: params.file || null,
      fileList: params.fileList || null,
      xpsrNdt: params.xpsrNdt || null,
      xpsrStrt: params.xpsrStrt || null,
      xpsrStprYn: params.xpsrStprYn || '',
      openYn: params.openYn || '',
      dtl: params.dtl || '',
      ttl: params.ttl || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공지사항 수정
 * POST /api/ntcb/notice/{brdId}
 * @param params ntcTypeCode 공지 유형 코드
 * @param params file 공지사항 첨부 파일
 * @param params xpsrNdt 게시 종료일
 * @param params xpsrStrt 게시 시작일
 * @param params xpsrStprYn 노출 기간 설정여부
 * @param params openYn 공개여부
 * @param params dtl 게시글 내용
 * @param params ttl 게시글 제목
 * @param params bfFileId 기존 파일 아이디
 * @param params brdId 게시판 아이디
 */
export const putNotice = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: ['/api/ntcb/notice/', params.brdId].join(''),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      ntcTypeCode: params.ntcTypeCode || '',
      // file: params.file || null,
      bfFileIdList: params.bfFileIdList || null,
      fileList: params.fileList || null,
      xpsrNdt: params.xpsrNdt || null,
      xpsrStrt: params.xpsrStrt || null,
      xpsrStprYn: params.xpsrStprYn || '',
      openYn: params.openYn || '',
      dtl: params.dtl || '',
      ttl: params.ttl || '',
      bfFileId: params.bfFileId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * FAQ 수정
 * PUT /api/ntcb/faq/{brdId}
 * @param params brdId 게시판 아이디
 * @param params paramVo
 */
export const putFaq = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/ntcb/faq/', params.brdId].join(''),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * FAQ 삭제
 * DELETE /api/ntcb/faq/{brdId}
 * @param params brdId 게시판 아이디
 */
export const deleteFaq = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/ntcb/faq/', params.brdId].join(''),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 매뉴얼 삭제
 * DELETE /api/ntcb/manual/{brdId}
 * @param params brdId 게시판 아이디
 */
export const deleteManual = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/ntcb/manual/', params.brdId].join(''),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공지사항 삭제
 * DELETE /api/ntcb/notice/{brdId}
 * @param params brdId 게시판 아이디
 */
export const deleteNotice = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/ntcb/notice/', params.brdId].join(''),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};
