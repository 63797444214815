import React, { PropsWithChildren } from 'react';

interface IPbSwitchProps {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

/**
 * 스위치
 * @param checked 체크 여부
 * @param disabled 비활성화 여부
 * @param onChange 변경 이벤트
 * @constructor
 */
const PbSwitch = ({
  checked = false,
  disabled = false,
  onChange,
}: PropsWithChildren<IPbSwitchProps>) => {
  return (
    <div className="form-check form-check-inline form-check-primary form-switch">
      <input
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        className={['form-check-input', !disabled && 'cursor-pointer'].join(
          ' ',
        )}
      />
    </div>
  );
};

export default PbSwitch;
