import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import {
  Button,
  Drawer,
  HoverCard,
  Indicator,
  Select,
  Tabs,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  IApiResult,
  IComponentModal,
  IComponentModalPageScrollTab,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ITableColumn,
  ITablePaging,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as sttnApi from '../../apis/sttn.api';
import * as prtnApi from '../../apis/prtn.api';
import { notifications } from '@mantine/notifications';
import { nanoid } from 'nanoid';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import PbSection from '../PbSection/PbSection.component';
import * as workApi from '../../apis/work.api';
import { PbAgGridReact } from '../PbAgGridReact';
import moment from 'moment/moment';
import {
  Calendar,
  DateInput,
  DatePickerInput,
  TimeInput,
} from '@mantine/dates';
import * as appUtil from '../../utils/app.util';
import _ from 'lodash';
import prettyBytes from 'pretty-bytes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MN2302220305_WorkDistributionModify } from '../MN2302220305_WorkDistributionModify';
import { addRefreshListStore } from '../../stores/refreshList.store';
import { decode } from 'html-entities';
import { ValueFormatterParams } from 'ag-grid-community';
import { useDisclosure } from '@mantine/hooks';
import * as grpApi from '../../apis/grp.api';
import { postDrvlAsgn } from '../../apis/work.api';

interface IComponentProps {
  id: string;
  dcmnDntfNmbr: string;
  splrTypeCode: string;
  prtnDate: string;
  data?: any;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 설치 관리 > 작업 현황 > 상세 > 작업배정
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220305_WorkDistribution = ({
  id = '',
  dcmnDntfNmbr = '',
  splrTypeCode = '',
  prtnDate = '',
  data,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 상세 내용을 정의함
  const [detailData, setDetailData] = useState<any>(null);

  // 설치예정일을 추가할 때 사용할 상세 내용을 정의함
  const [drawerDetailData, setDrawerDetailData] = useState<any>(null);

  /**
   * 납품예정일 섹션
   */

  // 불러온 상세 정보를 정의함
  const [deliverData, setDeliverData] = useState<any>(null);

  // 내용 입력을 정의함
  const [deliverContentInput, setDeliverContentInput] = useState<string>('');
  const deliverContentInputRef = useRef<HTMLInputElement>(null);

  // 내용 입력의 값을 변경함
  const handleDeliverContentInput_onChange = (event: any) => {
    setDeliverContentInput(event.currentTarget.value);
  };

  // 시작일시의 날짜 입력을 정의함
  const [deliverStartDateInput, setDeliverStartDateInput] = useState<Date>(
    new Date(),
  );
  const deliverStartDateInputRef = useRef<HTMLInputElement>(null);

  // 시작일시의 시간 입력을 정의함
  const [deliverStartTimeInput, setDeliverStartTimeInput] =
    useState<string>('20:00');
  const deliverStartTimeInputRef = useRef<HTMLInputElement>(null);

  // 종료일시의 날짜 입력을 정의함
  const [deliverEndDateInput, setDeliverEndDateInput] = useState<Date>(
    moment(new Date()).add(1, 'days').toDate(),
  );
  const deliverEndDateInputRef = useRef<HTMLInputElement>(null);

  // 종료일시의 시간 입력을 정의함
  const [deliverEndTimeInput, setDeliverEndTimeInput] =
    useState<string>('05:00');
  const deliverEndTimeInputRef = useRef<HTMLInputElement>(null);

  // 납품일자 날짜 피커를 정의함
  const [DeliverDatePicker, setDeliverDatePicker] = useState<Date | null>(null);

  // 납품일자 날짜 피커를 변경함
  const handleDeliverDatePicker_onChange = (event: any) => {
    setDeliverDatePicker(event);
  };

  // 선택한 설치예정일을 정의함
  const [selectedPrtnDate, setSelectedPrtnDate] = useState<string>(prtnDate);
  const selectedPrtnDateRef = useRef<string>(prtnDate);

  // 배정현황 > 조회기간 > 년월일 피커를 정의함
  const [rangeDatePicker, setRangeDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  // 조회기간의 일자들을 정의함
  const [rangeDate, setRangeDate] = useState<Date[]>([]);

  // 가동일 테이블을 정의함
  const [downtimeTable, setDowntimeTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  const downtimeTableRef = useRef<any>(null);

  // 협력사/팀 배정 현황 테이블을 정의함
  const [assignedStatusTeamTable, setAssignedStatusTeamTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 협력사/팀 배정불가 테이블을 정의함
  const [disableAssignedStatusTeamTable, setDisableAssignedStatusTeamTable] =
    useState<{
      column: any;
      data: any;
    }>({ column: [], data: [] });

  // 협력사/팀 배정가능(작업있음) 테이블을 정의함
  const [
    butEnableAssignedStatusTeamTable,
    setButEnableAssignedStatusTeamTable,
  ] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 협력사/팀 배정가능(작업없음) 테이블을 정의함
  const [enableAssignedStatusTeamTable, setEnableAssignedStatusTeamTable] =
    useState<{
      column: any;
      data: any;
    }>({ column: [], data: [] });

  // 배정된 협력사/팀 테이블을 정의함
  const [assignedTeamTable, setAssignedTeamTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 가동일 테이블을 초기화함
  const initDowntimeTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push({
      field: 'rowCategory',
      headerName: '설치일자',
      width: 200,
      pinned: 'left',
      suppressSizeToFit: true,
    });

    // 생성된 날짜만큼 컬럼을 추가함
    rangeDate?.map((item: any) => {
      tmpColumn.push({
        field: `day${moment(item).format('YYYYMMDD')}`,
        headerName: `${moment(item).format('MM')}\n${moment(item).format(
          'DD',
        )}`,
        width: 70,
        suppressSizeToFit: true,
        type: 'rightAligned',
        cellStyle: (params: any) => {
          // 설치예정일과 일치하는 날짜의 셀 배경색을 변경함
          if (
            params.data.rowType === 'enable-partner' &&
            moment(item).format('YYYYMMDD') === selectedPrtnDateRef.current
          ) {
            return { backgroundColor: 'rgb(253 230 138)' };
          } else {
            return { backgroundColor: 'rgb(255 255 255)' };
          }
        },
      });
    });

    // 테이블에 적용함
    setDowntimeTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 협력사/팀 배정 현황 테이블을 초기화함
  const initAssignedStatusTeamTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 전혀 안됨
    // 휴일, 작업불가

    // 되긴 됨
    // 작업일반, 작업추가
    // 빈값

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'disable',
        headerName: '배정불가',
        valueFormatter: (params: any) => {
          if (params.value !== null) {
            return `${params.value.prtnName} / ${params.value.teamName}`;
          }
        },
      },
      {
        field: 'butEnable',
        headerName: '배정가능(작업 있음)',
        valueFormatter: (params: any) => {
          if (params.value !== null) {
            return `${params.value.prtnName} / ${params.value.teamName}`;
          }
        },
      },
      {
        field: 'enable',
        headerName: '배정가능(작업 없음)',
        valueFormatter: (params: any) => {
          if (params.value !== null) {
            return `${params.value.prtnName} / ${params.value.teamName}`;
          }
        },
      },
    );

    // 테이블에 적용함
    setAssignedStatusTeamTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 협력사/팀 배정불가 테이블을 초기화함
  const initDisableAssignedStatusTeamTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push({
      field: 'disable',
      headerName: '배정불가',
      valueFormatter: (params: any) => {
        if (params.value !== null) {
          return `${decode(params.value.prtnName)} / ${decode(
            params.value.teamName,
          )}`;
        }
      },
    });

    // 테이블에 적용함
    setDisableAssignedStatusTeamTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 협력사/팀 배정가능(작업있음) 테이블을 초기화함
  const initButEnableAssignedStatusTeamTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push({
      field: 'butEnable',
      headerName: '배정가능(작업 있음)',
      valueFormatter: (params: any) => {
        if (params.value !== null) {
          return `${decode(params.value.prtnName)} / ${decode(
            params.value.teamName,
          )}`;
        }
      },
    });

    // 테이블에 적용함
    setButEnableAssignedStatusTeamTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 협력사/팀 배정가능(작업없음) 테이블을 초기화함
  const initEnableAssignedStatusTeamTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push({
      field: 'enable',
      headerName: '배정가능(작업 없음)',
      valueFormatter: (params: any) => {
        if (params.value !== null) {
          return `${decode(params.value.prtnName)} / ${decode(
            params.value.teamName,
          )}`;
        }
      },
    });

    // 테이블에 적용함
    setEnableAssignedStatusTeamTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 배정된 협력사/팀 테이블을 초기화함
  const initAssignedTeamTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'ttl',
        headerName: '내용',
        valueFormatter: (params: any) => decode(params.value),
      },
      {
        field: 'nstlSttsName',
        headerName: '상태',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        field: 'workStrtDttm',
        headerName: '작업일정',
        width: 300,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <>
            {moment(params.data.workStrtDttm, 'YYYYMMDDHHmm').format(
              'YYYY.MM.DD HH:mm',
            )}{' '}
            ~{' '}
            {moment(params.data.workEndDttm, 'YYYYMMDDHHmm').format(
              'YYYY.MM.DD HH:mm',
            )}
          </>
        ),
      },
      {
        field: 'prtnName',
        headerName: '협력사/팀명',
        width: 170,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => decode(params.value),
        cellRenderer: (params: any) => (
          <>
            {params.data.prtnName} / {params.data.teamName}
          </>
        ),
      },
    );

    // 테이블에 적용함
    setAssignedTeamTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 상세 내용을 불러옴
  const getDetailData = () => {
    if (
      !loginUser.id ||
      rangeDatePicker[0] === null ||
      rangeDatePicker[1] === null
    ) {
      return;
    }

    workApi
      .getAsgnDcmnDntfNmbr({
        dcmnDntfNmbr: dcmnDntfNmbr || '',
        prtnDate:
          moment(selectedPrtnDate, 'YYYYMMDD').format('YYYY-MM-DD') || '',
        splrTypeCode: splrTypeCode || '',
        srchStrtDate: moment(rangeDatePicker[0]).format('YYYY-MM-DD'),
        srchEndDate: moment(rangeDatePicker[1]).format('YYYY-MM-DD'),
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          // 상세 내용에 적용함
          setDetailData(data.data);

          console.log('> 작업현황 상세:', data.data);

          // // 납품예정일 > 내용 입력에 적용함
          // setDeliverContentInput(detailData?.workDtlBaseVo?.aa || '');
          //
          // // 납품예정일 > 납품일자 날짜 피커에 적용함
          // setDeliverDatePicker(detailData?.workDtlBaseVo?.bb || null);
        }
      });

    // 납품용으로 불러옴
    workApi
      .getSttDtlAllDcmnDntfNmbr({
        dcmnDntfNmbr: dcmnDntfNmbr || '',
        splrId: 'ALL',
        teamId: 'ALL',
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          console.log('> 납품상세:', data.data);

          // 불러온 상세 정보에 적용함
          setDeliverData(data.data.workDtlDlvrVo);

          let tmpDeliverStartDate = moment(
            data.data.workDtlDlvrVo.workStrtDttm,
          );
          let tmpDeliverEndDate = moment(data.data.workDtlDlvrVo.workEndDttm);

          // 내용 입력에 적용함
          setDeliverContentInput(data.data.workDtlDlvrVo.ttl);

          // 시작일시의 날짜 입력에 적용함
          setDeliverStartDateInput(tmpDeliverStartDate.toDate());

          // 시작일시의 시간 입력에 적용함
          setDeliverStartTimeInput(tmpDeliverStartDate.format('HH:mm'));

          // 종료일시의 날짜 입력에 적용함
          setDeliverEndDateInput(tmpDeliverEndDate.toDate());

          // 종료일시의 시간 입력에 적용함
          setDeliverEndTimeInput(tmpDeliverEndDate.format('HH:mm'));
        }
      });
  };

  // 설치예정일을 추가할 때 사용할 상세 내용을 불러옴
  const getDrawerDetailData = (selectedDate: Date) => {
    // 6개월 분량의 데이터를 불러옴
    workApi
      .getAsgnDcmnDntfNmbr({
        dcmnDntfNmbr: dcmnDntfNmbr,
        prtnDate: moment(selectedDate).format('YYYY-MM-DD'),
        splrTypeCode: splrTypeCode,
        srchStrtDate: moment(selectedDate)
          .add(-180, 'days')
          .format('YYYY-MM-DD'),
        srchEndDate: moment(selectedDate).add(180, 'days').format('YYYY-MM-DD'),
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          // 상세 내용에 적용함
          setDrawerDetailData(data.data);

          console.log('> drawer:', data.data);

          let tmpOptionItem: IOptionItem[] = [];

          tmpOptionItem.push({
            label: '선택하세요.',
            value: '',
          });

          data.data.workAsgnPrtnResList?.map((item: any) => {
            switch (item.prtnCode) {
              // 배정가능(미배정)
              case '':
                tmpOptionItem.push({
                  label: `${item.prtnName} / ${item.teamName}`,
                  value: `${item.splrId}-${item.teamid}`,
                });
                break;

              // 배정됐지만 배정가능(작업일반, 작업추가)
              case 'WORK_GNRL':
              case 'WORK_ADED':
                tmpOptionItem.push({
                  label: `${item.prtnName} / ${item.teamName}`,
                  value: `${item.splrId}-${item.teamid}`,
                });
                break;

              default:
                break;
            }
          });

          // 설치팀 셀렉트에 적용함
          setSetupTeamSelectItem(tmpOptionItem);
        }
      });
  };

  // 가동일 테이블 데이터를 불러옴
  const getDowntimeTableData = () => {
    // 기본 행을 정의함
    let tmpData: any[] = [];

    // 기본 행을 추가함
    tmpData.push({
      rowType: 'enable-partner',
      rowCategory: '가용 자원',
      sum: 0,
    });

    // 설치 일자별 가용 여부 목록을 불러와서 행에 일자별 컬럼을 생성함
    detailData?.workAsgnRangeDayList?.map((item: any) => {
      // 가용 자원 행
      tmpData
        .filter((filterItem: any) => filterItem.rowType === 'enable-partner')
        .map((subItem: any) => {
          // 날짜별
          subItem[
            'day' + moment(item.xpctDate, 'YYYY-MM-DD').format('YYYYMMDD')
          ] = item.sgnPsblTeamCnt;
        });
    });

    // 테이블에 적용함
    setDowntimeTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: tmpData,
    }));

    if (splrTypeCode === 'NTWR_CNST' || splrTypeCode === 'ESL_NSTL') {
      // 설치예정일로 컬럼을 스크롤함
      downtimeTableRef.current.api.ensureColumnVisible(
        `day${selectedPrtnDate}`,
        'middle',
      );
    }
  };

  // 협력사/팀 배정 현황 테이블 데이터를 불러옴
  const getAssignedStatusTeamTableData = () => {
    // 배정불가(작업불가, 휴일)
    let tmpDisable: any[] = [];

    // 배정됐지만 배정가능(작업일반, 작업추가)
    let tmpButEnable: any[] = [];

    // 배정가능(미배정)
    let tmpEnable: any[] = [];

    detailData?.workAsgnPrtnResList?.map((item: any) => {
      switch (item.prtnCode) {
        // 배정불가(작업불가, 휴일)
        case 'WORK_NBL':
        case 'DAY_OF':
          tmpDisable.push({
            disable: item,
          });
          break;

        // 배정됐지만 배정가능(작업일반, 작업추가)
        case 'WORK_GNRL':
        case 'WORK_ADED':
          tmpButEnable.push({
            butEnable: item,
          });
          break;

        // 배정가능(미배정)
        case '':
          tmpEnable.push({
            enable: item,
          });
          break;

        default:
          break;
      }
    });

    // // 최종 테이블 데이터를 정의함
    // let tmpTableData: any[] = [];
    //
    // // 세가지 분류 중, 가장 개수가 많은 분류수를 계산함
    // let tmpMaxLength: number = +(
    //   _.max([tmpDisable.length, tmpButEnable.length, tmpEnable.length]) || 0
    // );
    //
    // for (let i = 0; i < tmpMaxLength; i++) {
    //   tmpTableData.push({
    //     disable: tmpDisable[i] || null,
    //     butEnable: tmpButEnable[i] || null,
    //     enable: tmpEnable[i] || null,
    //   });
    // }
    //
    // // 테이블에 적용함
    // setAssignedStatusTeamTable(
    //   (pre: { column: ITableColumn[]; data: any }) => ({
    //     ...pre,
    //     data: tmpTableData,
    //   }),
    // );

    // 테이블에 적용함
    setDisableAssignedStatusTeamTable(
      (pre: { column: ITableColumn[]; data: any }) => ({
        ...pre,
        data: tmpDisable,
      }),
    );
    setButEnableAssignedStatusTeamTable(
      (pre: { column: ITableColumn[]; data: any }) => ({
        ...pre,
        data: tmpButEnable,
      }),
    );
    setEnableAssignedStatusTeamTable(
      (pre: { column: ITableColumn[]; data: any }) => ({
        ...pre,
        data: tmpEnable,
      }),
    );
  };

  // 배정된 협력사/팀 테이블 데이터를 불러옴
  const getAssignedTeamTableData = () => {
    // 테이블에 적용함
    setAssignedTeamTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: detailData?.workBaseList,
    }));
  };

  // 구분의 이름을 불러옴
  const getSplrTypeCodeName = (splrTypeCode: string) => {
    let tmpSplrTypeCodeName: string = '';

    switch (splrTypeCode) {
      case 'NTWR_CNST':
        tmpSplrTypeCodeName = '네트워크';
        break;

      case 'ESL_NSTL':
        tmpSplrTypeCodeName = 'ESL';
        break;

      case 'ESL_DLVR':
        tmpSplrTypeCodeName = '납품';
        break;

      default:
        tmpSplrTypeCodeName = '';
        break;
    }

    return tmpSplrTypeCodeName;
  };

  // 배정현황 > 조회기간 > 년월일 피커를 변경함
  const handleRangeDatePicker_onChange = (event: any) => {
    setRangeDatePicker(event);
  };

  //
  //
  // 밑에 있는 내용들은 새 변수를 만들어서 값을 넣어야 함!!!
  //
  //

  // 가동일 테이블의 셀을 클릭함
  const handleDowntimeTable_onCellClicked = (event: any) => {
    if (event.column.colId.replaceAll('day', '') !== selectedPrtnDate) {
      setModal({
        title: '설치예정일을 변경하시겠습니까?',
        content:
          '설치예정일이 변경되면 배정한 협력사/팀이 화면 상에서 초기화됩니다.',
        useOkayButton: false,
        useCancelButton: true,
        cancelButtonLabel: '아니오',
        button: (
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);

              // 배정된 협력사/팀 테이블 데이터를 초기화함
              setAssignedTeamTable(
                (pre: { column: ITableColumn[]; data: any }) => ({
                  ...pre,
                  data: [],
                }),
              );

              // 설치예정일에 적용함
              setSelectedPrtnDate(event.column.colId.replaceAll('day', ''));
              selectedPrtnDateRef.current = event.column.colId.replaceAll(
                'day',
                '',
              );
            }}
          >
            예
          </Button>
        ),
      });
    }
  };

  // 협력사/팀 테이블의 셀을 더블클릭함
  const handleAssignedStatusTeamTable_onCellDoubleClicked = (event: any) => {
    // 아무런 값이 없는 셀은 아무런 동작을 하지 않음
    if (event.data[event.column.colId] === null) {
      return;
    }

    // 배정불가 셀을 클릭하면 아무런 동작을 하지 않음
    if (event.column.colId === 'disable') {
      setModal({
        title: '오류',
        content: '배정불가 상태의 협력사/팀은 배정할 수 없습니다.',
      });

      return;
    }

    // 신규 등록일 때, 내용의 기본값을 정의함
    event.data[event.column.colId].ttl = `${moment(
      selectedPrtnDate,
      'YYYYMMDD',
    ).format('M월 D일')} ${event.data[event.column.colId].prtnName} ${
      event.data[event.column.colId].teamName
    } ${getSplrTypeCodeName(splrTypeCode)} 작업`;

    // 협력사/팀 배정 모달을 추가함
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: `작업배정 - ${getSplrTypeCodeName(splrTypeCode)} - 협력사/팀 추가`,
      content: (
        <MN2302220305_WorkDistributionModify
          id={tmpId}
          prtnStartDate={moment(selectedPrtnDate, 'YYYYMMDD').toDate()}
          // prtnStartTime="20:00"
          prtnStartTime="07:00"
          // prtnEndDate={moment(selectedPrtnDate, 'YYYYMMDD')
          //   .add(1, 'days')
          //   .toDate()}
          prtnEndDate={moment(selectedPrtnDate, 'YYYYMMDD').toDate()}
          // prtnEndTime="05:00"
          prtnEndTime="17:00"
          selectedCellData={event.data[event.column.colId]}
          callback={(result: any) => {
            // 배정된 협력사/팀 테이블에 적용함
            setAssignedTeamTable(
              (pre: { column: ITableColumn[]; data: any }) => ({
                ...pre,
                data: [
                  ...pre.data,
                  {
                    // bfPrtnDate: prtnDate,
                    // prtnDate: result.prtnDate,
                    // prtnCode: result.selectedCellData.prtnCode,
                    // prtnName: result.selectedCellData.prtnName,
                    // teamName: result.selectedCellData.teamName,
                    prtnCode: 'WORK_GNRL',
                    splrId: result.selectedCellData.splrId,
                    splrTypeCode: result.selectedCellData.splrTypeCode,
                    teamId: result.selectedCellData.teamid,
                    ttl: result.content,
                    workStrtDttm: result.startDateTime,
                    workEndDttm: result.endDateTime,
                    xpctDateEsl: result.xpctDateEsl,
                    xpctDateNtwr: result.xpctDateNtwr,
                  },
                ],
              }),
            );
          }}
        />
      ),
      size: 1000,
    });
  };

  // 배정된 협력사/팀 테이블의 행을 더블클릭함
  const handleAssignedTeamTable_onRowDoubleClicked = (event: any) => {
    // 협력사/팀 배정 모달을 추가함
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: `작업배정 - ${getSplrTypeCodeName(splrTypeCode)} - 협력사/팀 수정`,

      content: (
        <MN2302220305_WorkDistributionModify
          id={tmpId}
          prtnStartDate={moment(
            event.data.workStrtDttm,
            'YYYY-MM-DD HH:mm:ss',
          ).toDate()}
          prtnStartTime={moment(
            event.data.workStrtDttm,
            'YYYY-MM-DD HH:mm:ss',
          ).format('HH:mm')}
          prtnEndDate={moment(
            event.data.workEndDttm,
            'YYYY-MM-DD HH:mm:ss',
          ).toDate()}
          prtnEndTime={moment(
            event.data.workEndDttm,
            'YYYY-MM-DD HH:mm:ss',
          ).format('HH:mm')}
          selectedRowData={event.data}
          useModifyMode={true}
          callback={(result: any) => {
            // 배정된 협력사/팀 테이블 데이터를 복사함
            let tmpAssignedTeamTableData: any = _.cloneDeep(
              assignedTeamTable.data,
            );

            if (result.resultType === 'delete') {
              // 삭제 버튼을 클릭했음

              // 수정된 내용을 적용함
              tmpAssignedTeamTableData = tmpAssignedTeamTableData.filter(
                (filterItem: any) =>
                  !(
                    filterItem.splrTypeCode ===
                      result.selectedRowData.splrTypeCode &&
                    filterItem.splrId === result.selectedRowData.splrId &&
                    filterItem.teamId === result.selectedRowData.teamId
                  ),
              );
            } else {
              // 적용 버튼을 클릭했음

              // 수정된 내용을 적용함
              tmpAssignedTeamTableData.map((item: any) => {
                if (
                  item.splrTypeCode === result.selectedRowData.splrTypeCode &&
                  item.splrId === result.selectedRowData.splrId &&
                  item.teamId === result.selectedRowData.teamId
                ) {
                  // 내용
                  item.ttl = result.content;

                  // 시작일시
                  item.workStrtDttm = result.startDateTime;

                  // 종료일시
                  item.workEndDttm = result.endDateTime;
                }
              });
            }

            // 배정된 협력사/팀 테이블에 적용함
            setAssignedTeamTable(
              (pre: { column: ITableColumn[]; data: any }) => ({
                ...pre,
                data: tmpAssignedTeamTableData,
              }),
            );
          }}
        />
      ),
      size: 1000,
    });
  };

  // 네트워크, ESL일 때, 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    // 배정된 협력사/팀 테이블을 복사함
    // let tmpAssignedTeamTableData: any = _.cloneDeep(assignedTeamTable.data);
    let tmpAssignedTeamTableData: any = _.cloneDeep(setupTable);

    // 날짜 형식을 수정함
    tmpAssignedTeamTableData.map((item: any) => {
      item.workStrtDttm = item.workStrtDttm
        .replaceAll('-', '')
        .replaceAll(':', '')
        .replaceAll(' ', '');
      if (item.workStrtDttm.length === 12) {
        item.workStrtDttm = `${item.workStrtDttm}00`;
      }

      item.workEndDttm = item.workEndDttm
        .replaceAll('-', '')
        .replaceAll(':', '')
        .replaceAll(' ', '');
      if (item.workEndDttm.length === 12) {
        item.workEndDttm = `${item.workEndDttm}00`;
      }
    });

    console.log('>>>>>>', {
      dcmnDntfNmbr: dcmnDntfNmbr,
      splrTypeCode: splrTypeCode,
      // bfXpctDate: prtnDate,
      // xpctDate: moment(selectedPrtnDate, 'YYYYMMDD').format(
      //   'YYYY-MM-DD',
      // ),
      nstlList: tmpAssignedTeamTableData,
    });

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // notifications.show({
            //   title: '알림',
            //   message: '저장 중입니다.',
            // });

            workApi
              .postNstlAsgn({
                dcmnDntfNmbr: dcmnDntfNmbr,
                splrTypeCode: splrTypeCode,
                // bfXpctDate: prtnDate,
                // xpctDate: moment(selectedPrtnDate, 'YYYYMMDD').format(
                //   'YYYY-MM-DD',
                // ),
                nstlList: tmpAssignedTeamTableData,
              })
              .then((data: IApiResult) => {
                console.log('> ok!!!!:', data);

                if (data.code === '200') {
                  notifications.show({
                    title: '알림',
                    message: '저장하였습니다.',
                  });

                  // 목록 새로고침을 추가함
                  setAddRefreshList('MN2302220305Table');

                  // 부모창으로 결과를 넘김
                  callback({});

                  // 컴포넌트 모달을 닫음
                  setRemoveComponentModal(id);

                  setTimeout(() => {
                    let tmpId: string = nanoid();

                    // 컴포넌트 모달을 추가함
                    setAddComponentModal({
                      id: tmpId,
                      title: `작업배정 - ${getSplrTypeCodeName(splrTypeCode)}`,
                      content: (
                        <MN2302220305_WorkDistribution
                          id={tmpId}
                          dcmnDntfNmbr={dcmnDntfNmbr}
                          splrTypeCode={splrTypeCode}
                          prtnDate={selectedPrtnDate}
                        />
                      ),
                      size: 1500,
                    });
                  }, 100);
                } else {
                  console.log('> 작업배정 상세 수정 Error:', data);

                  setModal({
                    title: '오류',
                    content: (
                      <>
                        <div>저장에 실패하였습니다.</div>
                        <div>({data.message})</div>
                      </>
                    ),
                  });
                }
              })
              .catch((error: any) => {
                console.log('> 작업배정 상세 수정 Error:', error);

                setModal({
                  title: '오류',
                  content: `저장에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 납품일 때, 저장 버튼을 클릭함
  const handleSave2Button_onClick = () => {
    if (deliverStartDateInput === null) {
      setModal({
        title: '알림',
        content: '시작날짜를 입력하세요.',
      });

      deliverStartDateInputRef.current?.focus();
      return;
    }

    if (deliverStartTimeInput === '') {
      setModal({
        title: '알림',
        content: '시작시간을 입력하세요.',
      });

      deliverStartTimeInputRef.current?.focus();
      return;
    }

    if (deliverEndDateInput === null) {
      setModal({
        title: '알림',
        content: '종료날짜를 입력하세요.',
      });

      deliverEndDateInputRef.current?.focus();
      return;
    }

    if (deliverEndTimeInput === '') {
      setModal({
        title: '알림',
        content: '종료시간을 입력하세요.',
      });

      deliverEndTimeInputRef.current?.focus();
      return;
    }

    if (
      `${moment(deliverStartDateInput).format(
        'YYYYMMDD',
      )}${deliverStartTimeInput}` >
      `${moment(deliverEndDateInput).format('YYYYMMDD')}${deliverEndTimeInput}`
    ) {
      setModal({
        title: '알림',
        content: '시작/종료일시를 올바르게 입력하세요.',
      });

      deliverEndDateInputRef.current?.focus();
      return;
    }

    if (deliverContentInput.trim() === '') {
      setModal({
        title: '알림',
        content: '내용을 입력하세요.',
      });

      deliverContentInputRef.current?.focus();
      return;
    }

    // 시작일시의 날짜를 저장용으로 변환함
    let tmpDeliverStartDateInput: string =
      moment(deliverStartDateInput, 'YYYYMMDD').format('YYYYMMDD') +
      deliverStartTimeInput +
      '00';
    tmpDeliverStartDateInput = tmpDeliverStartDateInput
      .replaceAll('-', '')
      .replaceAll('.', '')
      .replaceAll(':', '')
      .replaceAll(' ', '');

    // 종료일시의 날짜를 저장용으로 변환함
    let tmpDeliverEndDateInput: string =
      moment(deliverEndDateInput, 'YYYYMMDD').format('YYYYMMDD') +
      deliverEndTimeInput +
      '00';
    tmpDeliverEndDateInput = tmpDeliverEndDateInput
      .replaceAll('-', '')
      .replaceAll('.', '')
      .replaceAll(':', '')
      .replaceAll(' ', '');

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // notifications.show({
            //   title: '알림',
            //   message: '저장 중입니다.',
            // });

            workApi
              .postDrvlAsgn({
                dcmnDntfNmbr: dcmnDntfNmbr,
                ttl: deliverContentInput,
                workStrtDttm: tmpDeliverStartDateInput,
                workEndDttm: tmpDeliverEndDateInput,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  notifications.show({
                    title: '알림',
                    message: '저장하였습니다.',
                  });

                  // 목록 새로고침을 추가함
                  setAddRefreshList('MN2302220305Table');

                  // 부모창으로 결과를 넘김
                  callback({});

                  // 컴포넌트 모달을 닫음
                  setRemoveComponentModal(id);
                } else {
                  console.log('> 작업배정 상세 수정 Error:', data);

                  setModal({
                    title: '오류',
                    content: (
                      <>
                        <div>저장에 실패하였습니다.</div>
                        <div>({data.message})</div>
                      </>
                    ),
                  });
                }
              })
              .catch((error: any) => {
                console.log('> 작업배정 상세 수정 Error:', error);

                setModal({
                  title: '오류',
                  content: `저장에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  /**
   * 설치예정일 테이블
   */

  // 테이블을 정의함
  const [setupTableColumn, setSetupTableColumn] = useState<any>([]);
  const [setupTable, setSetupTable] = useState<any>([]);
  const setupTableRef = useRef<any>(null);

  // 테이블 페이징을 정의함
  const [setupTablePaging, setSetupTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const setupTablePagingCurrentPageRef = useRef<number>(1);

  // 테이블을 초기화함
  const initSetupTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'checkbox',
        width: 60,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      },
      {
        field: 'ttl',
        headerName: '내용',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.ttl);
        },
      },
      {
        field: 'workStrtDt',
        headerName: '설치일자',
        width: 130,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return moment(params.data.workStrtDttm, 'YYYYMMDD').format(
            'YYYY.MM.DD',
          );
        },
      },
      {
        field: 'prtnName',
        headerName: '협력사',
        width: 200,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.prtnName);
        },
      },
      {
        field: 'teamName',
        headerName: '설치팀',
        width: 200,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.teamName);
        },
      },
    );

    // 테이블에 적용함
    setSetupTableColumn(tmpColumn);
  };

  // 테이블의 데이터를 불러옴
  const getSetupTableData = () => {
    let tmpData: any = [];

    console.log('> detailData:', detailData);

    // 테이블에 적용함
    setSetupTable(detailData.workBaseList);
  };

  // 테이블의 전체 삭제 버튼을 클릭함
  const handleSetupTableAllDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '전체 항목을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 테이블에 적용함
            setSetupTable([]);

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 테이블의 선택 삭제 버튼을 클릭함
  const handleSetupTableDeleteButton_onClick = () => {
    // 선택한 항목을 정의함
    let selectedId: any = [];

    // 선택한 항목을 불러옴
    setupTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedId.push(`${item.splrId} ${item.teamId}`);
    });

    // 선택한 항목이 없음
    if (selectedId.length === 0) {
      setModal({
        title: '알림',
        content: '삭제할 항목을 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: `선택하신 ${selectedId.length}개의 항목을 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 선택한 항목을 삭제함
            let tmpTableData: any = setupTable.filter(
              (item: any) =>
                !_.includes(selectedId, `${item.splrId} ${item.teamId}`),
            );

            // 테이블에 적용함
            setSetupTable(tmpTableData);

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });
          }}
        >
          예
        </Button>
      ),
    });
  };

  /**
   * 드로어용 설치예정일 테이블
   */

  // 테이블을 정의함
  const [drawerSetupTableColumn, setDrawerSetupTableColumn] = useState<any>([]);
  const [drawerSetupTable, setDrawerSetupTable] = useState<any>([]);
  const drawerSetupTableRef = useRef<any>(null);

  // 테이블을 초기화함
  const initDrawerSetupTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'ttl',
        headerName: '내용',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.ttl);
        },
      },
      {
        field: 'workStrtDt',
        headerName: '설치일자',
        valueFormatter: (params: ValueFormatterParams) => {
          return moment(params.data.workStrtDttm, 'YYYYMMDD').format(
            'YYYY.MM.DD',
          );
        },
      },
      {
        field: 'prtnName',
        headerName: '협력사',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.prtnName);
        },
      },
      {
        field: 'teamName',
        headerName: '설치팀',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.teamName);
        },
      },
    );

    // 테이블에 적용함
    setDrawerSetupTableColumn(tmpColumn);
  };

  /**
   * 설치예정일 드로어
   */

  // 드로어의 출력 여부를 정의함
  const [openedDrawer, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  // 편집할 항목을 정의함
  const [modifyDrawerData, setModifyDrawerData] = useState<any | null>(null);

  // 선택한 날짜를 정의함
  const [selectedDrawerCalendarDay, setSelectedDrawerCalendarDay] =
    useState<Date>(new Date());

  // 날짜를 클릭함
  const handleSelectedDrawerCalendarDay_onClick = (date: Date) => {
    setSelectedDrawerCalendarDay(date);

    // 시작일시의 날짜 입력에 적용함
    setStartDateInput(date);

    // 시작일시의 시간 입력에 적용함
    // 2024.03.05 - 박재하 대리 요청으로 20:00 -> 07:00으로 변경
    // setStartTimeInput('20:00');
    setStartTimeInput('07:00');

    // 종료일시의 날짜 입력에 적용함
    // 2024.03.05 - 박재하 대리 요청으로 시작일자와 같은 일자로 적용
    // setEndDateInput(moment(date).add(1, 'days').toDate());
    setEndDateInput(date);

    // 종료일시의 시간 입력에 적용함
    // 2024.03.05 - 박재하 대리 요청으로 05:00 -> 17:00으로 변경
    // setEndTimeInput('05:00');
    setEndTimeInput('17:00');

    // 설치팀 셀렉트를 초기화함
    setSetupTeamSelect('');

    // 설치예정일을 추가할 때 사용할 상세 내용을 불러옴
    getDrawerDetailData(date);
  };

  // 시작일시의 날짜 입력을 정의함
  const [startDateInput, setStartDateInput] = useState<Date>(
    selectedDrawerCalendarDay,
  );
  const startDateInputRef = useRef<HTMLInputElement>(null);

  // 시작일시의 시간 입력을 정의함
  // 2024.03.05 - 박재하 대리 요청으로 20:00 -> 07:00으로 변경
  // const [startTimeInput, setStartTimeInput] = useState<string>('20:00');
  const [startTimeInput, setStartTimeInput] = useState<string>('07:00');
  const startTimeInputRef = useRef<HTMLInputElement>(null);

  // 종료일시의 날짜 입력을 정의함
  // 2024.03.05 - 박재하 대리 요청으로 시작일자와 같은 일자로 적용
  // const [endDateInput, setEndDateInput] = useState<Date>(
  //   moment(selectedDrawerCalendarDay).add(1, 'days').toDate(),
  // );
  const [endDateInput, setEndDateInput] = useState<Date>(
    selectedDrawerCalendarDay,
  );
  const endDateInputRef = useRef<HTMLInputElement>(null);

  // 종료일시의 시간 입력을 정의함
  // 2024.03.05 - 박재하 대리 요청으로 05:00 -> 17:00으로 변경
  // const [endTimeInput, setEndTimeInput] = useState<string>('05:00');
  const [endTimeInput, setEndTimeInput] = useState<string>('17:00');
  const endTimeInputRef = useRef<HTMLInputElement>(null);

  // 내용 입력을 정의함
  const [contentInput, setContentInput] = useState<string>('');
  const contentInputRef = useRef<HTMLInputElement>(null);

  // 내용 입력의 값을 변경함
  const handleContentInput_onChange = (event: any) => {
    setContentInput(event.currentTarget.value);
  };

  // 설치팀을 정의함
  const [setupTeam, setSetupTeam] = useState<any>([]);

  // 설치팀 셀렉트를 정의함
  const [setupTeamSelect, setSetupTeamSelect] = useState<string>('');
  const [setupTeamSelectItem, setSetupTeamSelectItem] = useState<IOptionItem[]>(
    [],
  );
  const setupTeamSelectRef = useRef<HTMLInputElement>(null);

  // 설치팀 셀렉트를 초기화함
  const initSetupTeamSelect = () => {
    // 설치예정일을 추가할 때 사용할 상세 내용을 불러옴
    getDrawerDetailData(selectedDrawerCalendarDay);

    // let tmpOptionItem: IOptionItem[] = [];
    //
    // tmpOptionItem.push({
    //   label: '선택하세요.',
    //   value: '',
    // });
    //
    // // 협력사의 팀 목록을 불러옴
    // await prtnApi
    //   .getPrtns({
    //     splrType: splrTypeCode.toString().toLowerCase(),
    //     searchKeyword: '',
    //     pageSize: 9999,
    //     currPageIdx: 1,
    //   })
    //   .then((data: IApiResult) => {
    //     if (data.data.list !== undefined) {
    //       // 설치팀에 적용함
    //       setSetupTeam(data.data.list);
    //
    //       data.data.list.map((item: any) => {
    //         item.prtnTmList.map((subItem: any) => {
    //           // 셀렉트 아이템에 추가함
    //           tmpOptionItem.push({
    //             label: `${item.prtnName} / ${subItem.teamName}`,
    //             value: `${subItem.splrId}-${subItem.teamId}`,
    //           });
    //         });
    //       });
    //
    //       // 셀렉트 아이템에 적용함
    //       setSetupTeamSelectItem(tmpOptionItem);
    //     }
    //   });
  };

  // 설치팀 셀렉트에서 선택한 아이템을 변경함
  const handleSetupTeamSelect_onChange = (event: any) => {
    setSetupTeamSelect(event);
  };

  // 입력한 내용을 설치예정일 테이블에 적용함
  const drawerAddButton_onClick = () => {
    if (startTimeInput === '') {
      setModal({
        title: '알림',
        content: '시작시간을 입력하세요.',
      });

      startTimeInputRef.current?.focus();
      return;
    }

    if (endDateInput === null) {
      setModal({
        title: '알림',
        content: '종료날짜를 입력하세요.',
      });

      endDateInputRef.current?.focus();
      return;
    }

    if (endTimeInput === '') {
      setModal({
        title: '알림',
        content: '종료시간을 입력하세요.',
      });

      endTimeInputRef.current?.focus();
      return;
    }

    if (
      `${moment(startDateInput).format('YYYYMMDD')}${startTimeInput}` >
      `${moment(endDateInput).format('YYYYMMDD')}${endTimeInput}`
    ) {
      setModal({
        title: '알림',
        content: '시작/종료일시를 올바르게 입력하세요.',
      });

      endDateInputRef.current?.focus();
      return;
    }

    if (contentInput.trim() === '') {
      setModal({
        title: '알림',
        content: '내용을 입력하세요.',
      });

      contentInputRef.current?.focus();
      return;
    }

    if (setupTeamSelect === '') {
      setModal({
        title: '알림',
        content: '설치팀을 선택하세요.',
      });

      setupTeamSelectRef.current?.focus();
      return;
    }

    // 편집 구분을 정의함
    let modifyType: string = modifyDrawerData === null ? '추가' : '수정';

    setModal({
      title: '확인',
      content: `입력하신 항목을 ${modifyType}하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 설치팀 셀렉트에서 협력사와 설치팀 코드를 분리함
            let tmpSetupTeamSelect: string[] = setupTeamSelect.split('-');

            // 협력사 정보를 불러옴
            let tmpPartner: any = drawerDetailData.workAsgnPrtnResList.filter(
              (item: any) =>
                item.splrId === tmpSetupTeamSelect[0] &&
                item.teamid === tmpSetupTeamSelect[1],
            )[0];

            let tmpTableData: any = [];

            if (modifyDrawerData === null) {
              tmpTableData.push({
                prtnCode: 'WORK_GNRL',
                splrTypeCode: splrTypeCode,
                ttl: contentInput,
                workStrtDttm:
                  moment(startDateInput).format('YYYYMMDD') +
                  startTimeInput.replaceAll(':', ''),
                workEndDttm:
                  moment(endDateInput).format('YYYYMMDD') +
                  endTimeInput.replaceAll(':', ''),
                prtnName: tmpPartner.prtnName,
                splrId: tmpPartner.splrId,
                teamName: tmpPartner.teamName,
                teamId: tmpPartner.teamid || tmpPartner.teamId,
              });

              console.log('> tmpTableData:', tmpTableData);

              // 테이블에 추가 적용함
              setSetupTable([...setupTable, ...tmpTableData]);
            } else {
              // 테이블에 수정 적용함
              setSetupTable([...setupTable, ...tmpTableData]);
            }

            // // 기존에 없던 항목만 추가함
            // selectedId.map((item: any) => {
            //   if (
            //     _.findIndex(setupTable, {
            //       splrId: item.splrId,
            //       teamId: item.teamId,
            //     }) === -1
            //   ) {
            //     tmpTableData.push(
            //       _.find(setupTable, {
            //         splrId: item.splrId,
            //         teamId: item.teamId,
            //       }),
            //     );
            //   }
            // });

            // notifications.show({
            //   title: '알림',
            //   message: `${modifyType}하였습니다.`,
            // });

            // 드로어를 닫음
            closeDrawer();
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 설치예정일 기준으로 한달 전/후의 날짜를 불러옴
    let tmpStartDate: Date = moment(selectedPrtnDate, 'YYYYMMDD')
      .add(-1, 'months')
      .toDate();
    let tmpEndDate: Date = moment(selectedPrtnDate, 'YYYYMMDD')
      .add(1, 'months')
      .toDate();

    // 배정현황 > 조회기간 > 년월일 피커에 적용함
    setRangeDatePicker([tmpStartDate, tmpEndDate]);

    return () => {};
  }, []);

  // 로그인 정보가 준비될 때 실행함
  useEffect(() => {
    if (!loginUser.id) {
      return;
    }

    console.log('> loginUser:', loginUser);

    // 상세 내용을 불러옴
    getDetailData();

    return () => {};
  }, [loginUser.id]);

  // 상세 정보를 불러온 후 실행함
  useEffect(() => {
    if (JSON.stringify(detailData) === '{}') {
      return;
    }

    if (detailData === null || rangeDate === null) {
      return;
    }

    // 가동일 테이블을 초기화함
    initDowntimeTable();

    // 배정된 협력사/팀 테이블을 초기화함
    initAssignedTeamTable();

    // 협력사/팀 배정 현황 테이블을 초기화함
    initAssignedStatusTeamTable();

    // 협력사/팀 배정불가 테이블을 초기화함
    initDisableAssignedStatusTeamTable();

    // 협력사/팀 배정가능(작업있음) 테이블을 초기화함
    initButEnableAssignedStatusTeamTable();

    // 협력사/팀 배정가능(작업없음) 테이블을 초기화함
    initEnableAssignedStatusTeamTable();

    // 테이블을 초기화함
    initSetupTable();

    // 테이블을 초기화함
    initDrawerSetupTable();

    // 설치팀 셀렉트를 초기화함
    initSetupTeamSelect();

    // 테이블의 데이터를 불러옴
    getSetupTableData();

    return () => {};
  }, [detailData]);

  // 조회기간이 변경될 때 실행함
  useEffect(() => {
    let tmpDates = appUtil.getDatesBetween(
      new Date(moment(rangeDatePicker[0]).format('YYYY-MM-DD')),
      new Date(moment(rangeDatePicker[1]).format('YYYY-MM-DD')),
    );

    // 상세 내용을 불러옴
    getDetailData();

    // 조회기간의 일자들에 적용함
    setRangeDate(tmpDates);

    return () => {};
  }, [rangeDatePicker]);

  // 가동일 날짜 목록이 변경될 때 실행함
  useEffect(() => {
    // 상세 내용을 불러옴
    //getDetailData();

    return () => {};
  }, [rangeDate]);

  // 가동일 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (downtimeTable.column.length === 0) {
      return;
    }

    // 가동일 테이블 데이터를 불러옴
    getDowntimeTableData();

    return () => {};
  }, [downtimeTable.column]);

  // 가동일 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (downtimeTable.column.length === 0) {
      return;
    }

    if (splrTypeCode === 'NTWR_CNST' || splrTypeCode === 'ESL_NSTL') {
      // 설치예정일로 컬럼을 스크롤함
      downtimeTableRef.current.api.ensureColumnVisible(
        `day${selectedPrtnDate}`,
        'middle',
      );
    }

    return () => {};
  }, [downtimeTable.data]);

  // 협력사/팀 배정 현황 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (JSON.stringify(detailData) === '{}') {
      return;
    }

    if (detailData === null || assignedStatusTeamTable.column.length === 0) {
      return;
    }

    // 협력사/팀 배정 현황 테이블 데이터를 불러옴
    getAssignedStatusTeamTableData();

    return () => {};
  }, [assignedStatusTeamTable.column]);

  // 배정된 협력사/팀 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (assignedTeamTable.column.length === 0) {
      return;
    }

    // 배정된 협력사/팀 테이블 데이터를 불러옴
    getAssignedTeamTableData();

    return () => {};
  }, [assignedTeamTable.column]);

  // 드로어가 열리고 닫힐 때 실행함
  useEffect(() => {
    if (openedDrawer) {
      return;
    }

    // 편집할 항목을 초기화함
    setModifyDrawerData(null);

    // 내용 입력을 초기화함
    setContentInput('');

    // 설치팀을 초기화함
    setSetupTeamSelect('');

    return () => {};
  }, [openedDrawer]);

  return (
    <div className="relative space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 주문 폼 그리드 */}
        <PbFormGrid label="주문" cols={2}>
          <PbFormGridCol label="Shop명" colSpan={2}>
            <div>
              <span>{detailData?.workDtlBaseVo?.shipToCode}</span>
            </div>
          </PbFormGridCol>

          <PbFormGridCol label="Shop주소" colSpan={2}>
            <div>
              <span>{detailData?.workDtlBaseVo?.dlvrDrs}</span>
            </div>
          </PbFormGridCol>

          {/*<PbFormGridCol label="설치예정일">*/}
          {/*  <div>*/}
          {/*    <span>*/}
          {/*      /!*  /!* 네트워크일 경우 *!/*!/*/}
          {/*      /!*  {splrTypeCode === 'NTWR_CNST' &&*!/*/}
          {/*      /!*    detailData?.workDtlBaseVo?.xpctDateNtwr.replaceAll('-', '.')}*!/*/}
          {/*      /!*  /!* ESL일 경우 *!/*!/*/}
          {/*      /!*  {splrTypeCode === 'ESL_NSTL' &&*!/*/}
          {/*      /!*    detailData?.workDtlBaseVo?.xpctDateEsl.replaceAll('-', '.')}*!/*/}
          {/*      {moment(selectedPrtnDate, 'YYYYMMDD').format('YYYY.MM.DD')}*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*</PbFormGridCol>*/}

          <PbFormGridCol label="주문번호" colSpan={2}>
            <div>
              <span>{detailData?.workDtlBaseVo?.dcmnScrnNmbr}</span>
            </div>
          </PbFormGridCol>

          <PbFormGridCol label="" isBlank={true}></PbFormGridCol>
        </PbFormGrid>

        {/* 네트워크, ESL일 경우 */}
        {(splrTypeCode === 'NTWR_CNST' || splrTypeCode === 'ESL_NSTL') && (
          <>
            {/* 설치예정일 섹션 */}
            <PbSection
              id="step-2"
              label="설치예정일"
              rightForm={
                <>
                  {/* 버튼 */}
                  {/* 추가, 수정 상태 */}
                  <div className="flex justify-end items-center space-x-2">
                    {/* 전체 삭제 버튼 */}
                    <Button
                      variant="outline"
                      color="pink"
                      radius="xl"
                      onClick={handleSetupTableAllDeleteButton_onClick}
                    >
                      전체 삭제
                    </Button>

                    {/* 선택 삭제 버튼 */}
                    <Button
                      color="pink"
                      radius="xl"
                      onClick={handleSetupTableDeleteButton_onClick}
                    >
                      선택 삭제
                    </Button>

                    {/* 추가 버튼 */}
                    <Button onClick={openDrawer} color="indigo" radius="xl">
                      추가
                    </Button>
                  </div>
                </>
              }
            >
              <div className="space-y-3">
                {/* 테이블 */}
                <div className="w-full h-56">
                  <PbAgGridReact
                    refs={setupTableRef}
                    columnDefs={setupTableColumn}
                    rowData={setupTable}
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      wrapHeaderText: false,
                      autoHeaderHeight: true,
                    }}
                    rowSelection="multiple"
                    sizeColumnsToFit={true}
                    visiblePaging={false}
                    paging={{
                      totalPage: setupTablePaging.totalPage,
                      currentPage: setupTablePaging.currentPage,
                    }}
                    onChangePage={(event: any) => {
                      // 테이블의 페이지를 변경함
                      setupTablePagingCurrentPageRef.current = event;
                      setSetupTablePaging((pre: ITablePaging) => ({
                        ...pre,
                        currentPage: setupTablePagingCurrentPageRef.current,
                      }));

                      // 테이블의 데이터를 불러옴
                      getSetupTableData();
                    }}
                  />
                </div>
              </div>
            </PbSection>

            {/* 배정현황 조회 */}
            <PbSection
              id="step-5"
              label="배정현황 조회"
              rightForm={
                <>
                  {/* 도움말 */}
                  <div className="flex justify-start items-center space-x-1">
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'circle-info']}
                        className="w-4 h-4 text-blue-500"
                      />
                    </div>

                    <div className="flex justify-center items-center space-x-2">
                      <span className="text-base text-gray-600 font-bold">
                        배정되어 있는 현황을 조회할 수 있습니다.
                      </span>
                    </div>
                  </div>
                </>
              }
              className="hidden"
            >
              <div className="p-1 bg-gray-100 border-1 border-gray-300 space-y-7">
                <div className="space-y-2">
                  {/* 기간선택 폼 그리드 */}
                  <PbFormGrid label="기간 선택" cols={1}>
                    <PbFormGridCol label="조회기간">
                      {/* 년월일 피커 */}
                      <DatePickerInput
                        type="range"
                        placeholder="기간을 선택하세요."
                        onChange={handleRangeDatePicker_onChange}
                        value={rangeDatePicker}
                        valueFormat="YYYY.MM.DD"
                        locale="ko"
                        clearable={true}
                        className="w-full"
                      />
                    </PbFormGridCol>
                  </PbFormGrid>

                  <PbSection
                    label=" "
                    rightForm={
                      <>
                        {/* 도움말 */}
                        {/*<div className="flex justify-center items-center space-x-1">*/}
                        {/*  <div className="flex justify-center items-center">*/}
                        {/*    <FontAwesomeIcon*/}
                        {/*      icon={['fas', 'circle-info']}*/}
                        {/*      className="w-4 h-4 text-blue-500"*/}
                        {/*    />*/}
                        {/*  </div>*/}

                        {/*  <div className="flex justify-center items-center space-x-2">*/}
                        {/*    <span className="text-base text-gray-600 font-bold">*/}
                        {/*      해당 날짜의 가용 자원을 클릭하여 설치예정일을 변경할*/}
                        {/*      수 있습니다.*/}
                        {/*    </span>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </>
                    }
                  >
                    {/* 가동일 테이블 */}
                    <div className="w-full h-28">
                      <PbAgGridReact
                        refs={downtimeTableRef}
                        columnDefs={downtimeTable.column}
                        rowData={downtimeTable.data}
                        onCellClicked={handleDowntimeTable_onCellClicked}
                        defaultColDef={{
                          resizable: false,
                          // sortable: true,
                          wrapHeaderText: false,
                          autoHeaderHeight: true,
                        }}
                        rowSelection="none"
                        sizeColumnsToFit={false}
                        visiblePaging={false}
                        suppressCellFocus={true}
                        className="ag-grid-multiple-header-name ag-grid-cell-vertical-border"
                      />
                    </div>
                  </PbSection>
                </div>

                <div className="flex justify-between items-start space-x-5">
                  {/* 협력사/팀 */}
                  <div className="w-132">
                    <PbSection
                      label="협력사/팀"
                      rightForm={
                        <>
                          {/* 도움말 */}
                          {/*<div className="flex justify-center items-center space-x-1">*/}
                          {/*  <div className="flex justify-center items-center">*/}
                          {/*    <FontAwesomeIcon*/}
                          {/*      icon={['fas', 'circle-info']}*/}
                          {/*      className="w-4 h-4 text-blue-500"*/}
                          {/*    />*/}
                          {/*  </div>*/}

                          {/*  <div className="flex justify-center items-center space-x-2">*/}
                          {/*    <span className="text-base text-gray-600 font-bold">*/}
                          {/*      셀을 더블클릭하여 배정할 수 있습니다.*/}
                          {/*    </span>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                        </>
                      }
                    >
                      <div className="flex justify-center items-center space-x-1">
                        {/* 협력사/팀 배정 현황 테이블 - 배정불가 */}
                        {/*<div className="w-full h-80">*/}
                        {/*  <PbAgGridReact*/}
                        {/*    columnDefs={assignedStatusTeamTable.column}*/}
                        {/*    rowData={assignedStatusTeamTable.data}*/}
                        {/*    onCellDoubleClicked={*/}
                        {/*      handleAssignedStatusTeamTable_onCellDoubleClicked*/}
                        {/*    }*/}
                        {/*    defaultColDef={{*/}
                        {/*      resizable: true,*/}
                        {/*      sortable: true,*/}
                        {/*      wrapHeaderText: false,*/}
                        {/*      autoHeaderHeight: true,*/}
                        {/*    }}*/}
                        {/*    rowSelection="single"*/}
                        {/*    sizeColumnsToFit={true}*/}
                        {/*    visiblePaging={false}*/}
                        {/*    suppressCellFocus={true}*/}
                        {/*    className="ag-grid-multiple-header-name ag-grid-cell-vertical-border"*/}
                        {/*  />*/}
                        {/*</div>*/}

                        {/* 탭 */}
                        <Tabs
                          // variant="pills"
                          defaultValue="enable"
                          className="w-full"
                        >
                          {/* 탭 헤더 */}
                          <Tabs.List>
                            <Tabs.Tab value="enable">
                              <span className="text-lg">
                                배정가능(작업없음)
                              </span>
                            </Tabs.Tab>

                            <Tabs.Tab value="but-enable">
                              <span className="text-lg">
                                배정가능(작업있음)
                              </span>
                            </Tabs.Tab>

                            <Tabs.Tab value="disable">
                              <span className="text-lg">배정불가</span>
                            </Tabs.Tab>
                          </Tabs.List>

                          {/* 탭 내용: 배정가능(작업없음) */}
                          <Tabs.Panel value="enable" pt="xl">
                            {/* 협력사/팀 배정 현황 테이블 - 배정가능(작업없음) */}
                            <div className="h-80">
                              <PbAgGridReact
                                columnDefs={
                                  enableAssignedStatusTeamTable.column
                                }
                                rowData={enableAssignedStatusTeamTable.data}
                                // onCellDoubleClicked={
                                //   handleAssignedStatusTeamTable_onCellDoubleClicked
                                // }
                                defaultColDef={{
                                  resizable: true,
                                  sortable: true,
                                  wrapHeaderText: false,
                                  autoHeaderHeight: true,
                                }}
                                rowSelection="single"
                                sizeColumnsToFit={true}
                                visiblePaging={false}
                                suppressCellFocus={true}
                                className="ag-grid-multiple-header-name ag-grid-cell-vertical-border ag-grid-hide-header"
                              />
                            </div>
                          </Tabs.Panel>

                          {/* 탭 내용: 배정가능(작업있음) */}
                          <Tabs.Panel value="but-enable" pt="xl">
                            {/* 협력사/팀 배정 현황 테이블 - 배정가능(작업있음) */}
                            <div className="h-80">
                              <PbAgGridReact
                                columnDefs={
                                  butEnableAssignedStatusTeamTable.column
                                }
                                rowData={butEnableAssignedStatusTeamTable.data}
                                // onCellDoubleClicked={
                                //   handleAssignedStatusTeamTable_onCellDoubleClicked
                                // }
                                defaultColDef={{
                                  resizable: true,
                                  sortable: true,
                                  wrapHeaderText: false,
                                  autoHeaderHeight: true,
                                }}
                                rowSelection="single"
                                sizeColumnsToFit={true}
                                visiblePaging={false}
                                suppressCellFocus={true}
                                className="ag-grid-multiple-header-name ag-grid-cell-vertical-border ag-grid-hide-header"
                              />
                            </div>
                          </Tabs.Panel>

                          {/* 탭 내용: 배정불가 */}
                          <Tabs.Panel value="disable" pt="xl">
                            {/* 협력사/팀 배정 현황 테이블 - 배정불가 */}
                            <div className="h-80">
                              <PbAgGridReact
                                columnDefs={
                                  disableAssignedStatusTeamTable.column
                                }
                                rowData={disableAssignedStatusTeamTable.data}
                                // onCellDoubleClicked={
                                //   handleAssignedStatusTeamTable_onCellDoubleClicked
                                // }
                                defaultColDef={{
                                  resizable: true,
                                  sortable: true,
                                  wrapHeaderText: false,
                                  autoHeaderHeight: true,
                                }}
                                rowSelection="single"
                                sizeColumnsToFit={true}
                                visiblePaging={false}
                                suppressCellFocus={true}
                                className="ag-grid-multiple-header-name ag-grid-cell-vertical-border ag-grid-hide-header"
                              />
                            </div>
                          </Tabs.Panel>
                        </Tabs>
                      </div>
                    </PbSection>
                  </div>

                  {/* 배정된 협력사/팀 */}
                  <div className="grow">
                    <PbSection
                      label="배정된 협력사/팀"
                      rightForm={
                        <>
                          {/* 도움말 */}
                          <div className="flex justify-center items-center space-x-1">
                            <div className="flex justify-center items-center">
                              <FontAwesomeIcon
                                icon={['fas', 'circle-info']}
                                className="w-4 h-4 text-blue-500"
                              />
                            </div>

                            <div className="flex justify-center items-center space-x-2">
                              <span className="text-base text-gray-600 font-bold">
                                행을 더블클릭하여 작업배정 내용을 수정할 수
                                있습니다.
                              </span>
                            </div>
                          </div>
                        </>
                      }
                    >
                      {/* 배정된 협력사/팀 테이블 */}
                      <div className="w-full h-96">
                        <PbAgGridReact
                          columnDefs={assignedTeamTable.column}
                          rowData={assignedTeamTable.data}
                          // onRowDoubleClicked={
                          //   handleAssignedTeamTable_onRowDoubleClicked
                          // }
                          defaultColDef={{
                            resizable: true,
                            sortable: true,
                            wrapHeaderText: false,
                            autoHeaderHeight: true,
                          }}
                          rowSelection="single"
                          sizeColumnsToFit={true}
                          visiblePaging={false}
                          suppressCellFocus={true}
                          className="ag-grid-multiple-header-name ag-grid-cell-vertical-border"
                        />
                      </div>
                    </PbSection>
                  </div>
                </div>
              </div>
            </PbSection>
          </>
        )}

        {/* 납품일 경우 */}
        {splrTypeCode === 'ESL_DLVR' && (
          <PbFormGrid label="납품예정일" cols={2}>
            <PbFormGridCol label="내용" colSpan={2}>
              {/* 텍스트 입력 */}
              <TextInput
                ref={deliverContentInputRef}
                placeholder="내용을 입력하세요."
                onChange={handleDeliverContentInput_onChange}
                value={deliverContentInput}
                className="w-full"
              />
            </PbFormGridCol>

            <PbFormGridCol label="납품일시" colSpan={2}>
              <div className="flex justify-center items-center space-x-1">
                {/* 날짜 */}
                <DateInput
                  ref={deliverStartDateInputRef}
                  locale="ko"
                  firstDayOfWeek={0}
                  monthLabelFormat="YYYY년 MM월"
                  value={deliverStartDateInput}
                  onChange={(event: any) => {
                    setDeliverStartDateInput(event);
                  }}
                  placeholder="일자를 입력하세요."
                  valueFormat="YYYY.MM.DD"
                  className="w-3/5"
                />

                {/* 시간 */}
                <TimeInput
                  ref={deliverStartTimeInputRef}
                  rightSection={
                    <div
                      onClick={() =>
                        deliverStartTimeInputRef.current?.showPicker()
                      }
                      className="button-event flex justify-center items-center"
                    >
                      <FontAwesomeIcon
                        icon={['far', 'clock']}
                        className="w-4 h-4"
                      />
                    </div>
                  }
                  placeholder="시간을 입력하세요."
                  onChange={(event: any) => {
                    setDeliverStartTimeInput(event.currentTarget.value);
                  }}
                  value={deliverStartTimeInput}
                  className="w-2/5"
                />
              </div>

              <div>
                <span>~</span>
              </div>

              <div className="flex justify-center items-center space-x-1">
                {/* 날짜 */}
                <DateInput
                  ref={deliverEndDateInputRef}
                  locale="ko"
                  firstDayOfWeek={0}
                  monthLabelFormat="YYYY년 MM월"
                  value={deliverEndDateInput}
                  onChange={(event: any) => {
                    setDeliverEndDateInput(event);
                  }}
                  placeholder="일자를 입력하세요."
                  valueFormat="YYYY.MM.DD"
                  className="w-3/5"
                />

                {/* 시간 */}
                <TimeInput
                  ref={deliverEndTimeInputRef}
                  rightSection={
                    <div
                      onClick={() =>
                        deliverEndTimeInputRef.current?.showPicker()
                      }
                      className="button-event flex justify-center items-center"
                    >
                      <FontAwesomeIcon
                        icon={['far', 'clock']}
                        className="w-4 h-4"
                      />
                    </div>
                  }
                  onChange={(event: any) => {
                    setDeliverEndTimeInput(event.currentTarget.value);
                  }}
                  value={deliverEndTimeInput}
                  className="w-2/5"
                />
              </div>
            </PbFormGridCol>

            <PbFormGridCol label="" isBlank={true}></PbFormGridCol>
          </PbFormGrid>
        )}
      </div>

      {/* 설치예정일 드로어 */}
      <Drawer
        opened={openedDrawer}
        onClose={closeDrawer}
        position="right"
        size={450}
        title={<span className="text-xl font-semibold">설치예정일 추가</span>}
        zIndex={1000}
      >
        <div className="space-y-3">
          {/* 드로어의 버튼 */}
          <div className="flex justify-end items-center space-x-2">
            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              onClick={drawerAddButton_onClick}
            >
              입력한 내용을 설치예정일에 적용
            </Button>
          </div>

          <div
            style={{ height: 'calc(100vh - 150px)' }}
            className="w-full overflow-y-scroll space-y-3"
          >
            {/* 설치일자 */}
            <div
              style={{ padding: '10px' }}
              className="bg-gray-100/50 space-y-2 rounded"
            >
              {/* 섹션 제목*/}
              <div className="flex justify-start items-center space-x-2">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'dot-circle']}
                    className="w-4 h-4 text-blue-400"
                  />
                </div>

                <span className="text-base text-gray-700 font-semibold leading-none">
                  설치일자
                </span>
              </div>

              <div className="flex justify-center items-center">
                <div>
                  <Calendar
                    locale="ko"
                    firstDayOfWeek={0}
                    monthLabelFormat="YYYY년 MM월"
                    date={selectedDrawerCalendarDay}
                    onPreviousMonth={(date: Date) => {
                      setSelectedDrawerCalendarDay(date);
                    }}
                    onNextMonth={(date: Date) => {
                      setSelectedDrawerCalendarDay(date);
                    }}
                    getDayProps={(date: Date) => ({
                      selected:
                        moment(date).format('YYYYMMDD') ===
                        moment(selectedDrawerCalendarDay).format('YYYYMMDD'),
                      onClick: () =>
                        handleSelectedDrawerCalendarDay_onClick(date),
                    })}
                    renderDay={(date: Date) => {
                      const day: number = date.getDate();
                      const tmpDate: string = moment(date).format('YYYY-MM-DD');
                      const tmpDate2: string =
                        moment(date).format('YYYY.MM.DD');

                      const tmpThatDay: any =
                        drawerDetailData?.workBaseList?.filter(
                          (item: any) =>
                            moment(item.workStrtDttm, 'YYYY-MM-DD').format(
                              'YYYY-MM-DD',
                            ) === tmpDate,
                        );

                      return (
                        <HoverCard shadow="md">
                          <HoverCard.Target>
                            {tmpThatDay.length > 0 ? (
                              <Indicator
                                position="bottom-center"
                                size={7}
                                offset={-3}
                                color="pink"
                              >
                                <div>{day}</div>
                              </Indicator>
                            ) : (
                              <div>{day}</div>
                            )}
                          </HoverCard.Target>

                          {tmpThatDay.length > 0 && (
                            <HoverCard.Dropdown>
                              <div className="w-76 space-y-2">
                                <div className="px-0.5 flex justify-between items-center">
                                  <span className="text-base text-gray-600 font-bold">
                                    {tmpDate2}
                                  </span>

                                  <span className="text-sm text-gray-600 font-bold">
                                    {tmpThatDay.length}건
                                  </span>
                                </div>

                                {/* 테이블 */}
                                <div className="w-full space-y-2">
                                  {tmpThatDay.map(
                                    (item: any, index: number) => (
                                      <div
                                        key={index}
                                        style={{ paddingBottom: '5px' }}
                                        className="bg-slate-200 rounded overflow-hidden"
                                      >
                                        <div
                                          style={{ padding: '5px 10px' }}
                                          className="bg-gray-800 truncate"
                                        >
                                          <span className="text-sm text-white font-bold">
                                            {item.ttl}
                                          </span>
                                        </div>
                                        <div
                                          style={{ padding: '1px 10px' }}
                                          className="truncate"
                                        >
                                          <span className="text-xs text-gray-600 font-bold">
                                            {moment(
                                              item.workStrtDttm,
                                              'YYYY-MM-DD HH:mm',
                                            ).format('YYYY.MM.DD HH:mm')}{' '}
                                            ~{' '}
                                            {moment(
                                              item.workEndDttm,
                                              'YYYY-MM-DD HH:mm',
                                            ).format('YYYY.MM.DD HH:mm')}
                                          </span>
                                        </div>
                                        <div
                                          style={{ padding: '1px 10px' }}
                                          className="truncate"
                                        >
                                          <span className="text-sm text-gray-600 font-bold">
                                            {decode(item.prtnName)} /{' '}
                                            {decode(item.teamName)}
                                          </span>
                                        </div>
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            </HoverCard.Dropdown>
                          )}
                        </HoverCard>
                      );
                    }}
                    size="xl"
                  />
                </div>
              </div>
            </div>

            {/* 내용 */}
            <div
              style={{ padding: '10px' }}
              className="bg-gray-100/50 space-y-2 rounded"
            >
              {/* 섹션 제목*/}
              <div className="flex justify-start items-center space-x-2">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'dot-circle']}
                    className="w-4 h-4 text-blue-400"
                  />
                </div>

                <span className="text-base text-gray-700 font-semibold leading-none">
                  내용
                </span>
              </div>

              <div>
                <TextInput
                  ref={contentInputRef}
                  placeholder="내용을 입력하세요."
                  onChange={handleContentInput_onChange}
                  value={contentInput}
                  className="w-full"
                />
              </div>
            </div>

            {/* 시작일시 */}
            <div
              style={{ padding: '10px' }}
              className="bg-gray-100/50 space-y-2 rounded"
            >
              {/* 섹션 제목*/}
              <div className="flex justify-start items-center space-x-2">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'dot-circle']}
                    className="w-4 h-4 text-blue-400"
                  />
                </div>

                <span className="text-base text-gray-700 font-semibold leading-none">
                  시작일시
                </span>
              </div>

              <div className="flex justify-center items-center space-x-1">
                {/* 날짜 */}
                <DateInput
                  ref={startDateInputRef}
                  locale="ko"
                  firstDayOfWeek={0}
                  monthLabelFormat="YYYY년 MM월"
                  value={startDateInput}
                  valueFormat="YYYY.MM.DD"
                  disabled={true}
                  className="w-3/5"
                />

                {/* 시간 */}
                <TimeInput
                  ref={startTimeInputRef}
                  rightSection={
                    <div
                      onClick={() => startTimeInputRef.current?.showPicker()}
                      className="button-event flex justify-center items-center"
                    >
                      <FontAwesomeIcon
                        icon={['far', 'clock']}
                        className="w-4 h-4"
                      />
                    </div>
                  }
                  placeholder="시간을 입력하세요."
                  onChange={(event: any) => {
                    setStartTimeInput(event.currentTarget.value);
                  }}
                  value={startTimeInput}
                  className="w-2/5"
                />
              </div>
            </div>

            {/* 종료일시 */}
            <div
              style={{ padding: '10px' }}
              className="bg-gray-100/50 space-y-2 rounded"
            >
              {/* 섹션 제목*/}
              <div className="flex justify-start items-center space-x-2">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'dot-circle']}
                    className="w-4 h-4 text-blue-400"
                  />
                </div>

                <span className="text-base text-gray-700 font-semibold leading-none">
                  종료일시
                </span>
              </div>

              <div className="flex justify-center items-center space-x-1">
                {/* 날짜 */}
                <DateInput
                  ref={endDateInputRef}
                  locale="ko"
                  firstDayOfWeek={0}
                  monthLabelFormat="YYYY년 MM월"
                  value={endDateInput}
                  onChange={(event: any) => {
                    setEndDateInput(event);
                  }}
                  placeholder="일자를 입력하세요."
                  valueFormat="YYYY.MM.DD"
                  className="w-3/5"
                />

                {/* 시간 */}
                <TimeInput
                  ref={endTimeInputRef}
                  rightSection={
                    <div
                      onClick={() => endTimeInputRef.current?.showPicker()}
                      className="button-event flex justify-center items-center"
                    >
                      <FontAwesomeIcon
                        icon={['far', 'clock']}
                        className="w-4 h-4"
                      />
                    </div>
                  }
                  onChange={(event: any) => {
                    setEndTimeInput(event.currentTarget.value);
                  }}
                  value={endTimeInput}
                  className="w-2/5"
                />
              </div>
            </div>

            {/* 설치팀 */}
            <div
              style={{ padding: '10px' }}
              className="bg-gray-100/50 space-y-2 rounded"
            >
              {/* 섹션 제목*/}
              <div className="flex justify-start items-center space-x-2">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'dot-circle']}
                    className="w-4 h-4 text-blue-400"
                  />
                </div>

                <span className="text-base text-gray-700 font-semibold leading-none">
                  설치팀
                </span>
              </div>

              <div>
                <Select
                  ref={setupTeamSelectRef}
                  onChange={handleSetupTeamSelect_onChange}
                  data={setupTeamSelectItem}
                  value={setupTeamSelect}
                  className="w-full"
                />
              </div>
            </div>
          </div>

          {/* 드로어의 버튼 */}
          <div className="flex justify-end items-center space-x-2">
            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              onClick={drawerAddButton_onClick}
            >
              입력한 내용을 설치예정일에 적용
            </Button>
          </div>
        </div>
      </Drawer>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 버튼 */}
        <Button
          variant="outline"
          color="gray"
          radius="xl"
          size="md"
          onClick={() => setRemoveComponentModal(id)}
        >
          닫기
        </Button>

        {/* 버튼 */}
        {(splrTypeCode === 'NTWR_CNST' || splrTypeCode === 'ESL_NSTL') && (
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={handleSaveButton_onClick}
          >
            저장
          </Button>
        )}
        {splrTypeCode === 'ESL_DLVR' && (
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={handleSave2Button_onClick}
          >
            저장
          </Button>
        )}
      </div>
    </div>
  );
};

export default MN2302220305_WorkDistribution;
