import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Drawer,
  Popover,
  Select,
  Switch,
  Tabs,
  TextInput,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbFormGridCol } from '../PbFormGridCol';
import { DatePickerInput } from '@mantine/dates';
import { PbFormGrid } from '../PbFormGrid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  IApiResult,
  IComponentModal,
  IComponentModalPageScrollTab,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ITablePaging,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import _ from 'lodash';
import * as workApi from '../../apis/work.api';
import PbSection from '../PbSection/PbSection.component';
import { PbAgGridReact } from '../PbAgGridReact';
import { decode } from 'html-entities';
import moment from 'moment';
import { number } from 'prop-types';
import { notifications } from '@mantine/notifications';
import * as slsApi from '../../apis/sls.api';
import { nanoid } from 'nanoid';
import { addRefreshListStore } from '../../stores/refreshList.store';
import * as sttnApi from '../../apis/sttn.api';
import * as nstlApi from '../../apis/nstl.api';
import { getSttnPrtns, putSttnErp } from '../../apis/sttn.api';
import { useDisclosure } from '@mantine/hooks';
import { ValueFormatterParams } from 'ag-grid-community';
import * as grpApi from '../../apis/grp.api';
import { ReactSortable } from 'react-sortablejs';
import {
  getChckCntrNmbrSplrTypeCode,
  putChckCntrNmbrSplrTypeCode,
} from '../../apis/nstl.api';

interface IComponentProps {
  id?: string;
  cntrNmbr?: string;
  data?: any;
  onClick?: () => void;
  useModifyMode?: boolean;
}

/**
 * 현황 관리 > 계약 현황 > 상세
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220404 = ({
  id = '',
  cntrNmbr = '',
  data,
  onClick,
  useModifyMode = false,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 계약에 접근 가능한 대상자 테이블
   */

  // 테이블을 정의함
  const [authUserTableColumn, setAuthUserTableColumn] = useState<any>([]);
  const [authUserTable, setAuthUserTable] = useState<any>([]);
  const authUserTableRef = useRef<any>(null);

  // 테이블 페이징을 정의함
  const [authUserTablePaging, setAuthUserTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const authUserTablePagingCurrentPageRef = useRef<number>(1);

  // 테이블을 초기화함
  const initAuthUserTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 추가, 수정 상태일 때만 아래의 컬럼을 추가함
    if (!(cntrNmbr && !useModifyMode)) {
      // 컬럼에 추가함
      tmpColumn.push({
        field: 'checkbox',
        width: 60,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      });
    }

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'cmpnName',
        headerName: '회사',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.cmpnName);
        },
      },
      {
        field: 'name',
        headerName: '이름',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.name);
        },
      },
      {
        field: 'nickName',
        headerName: '닉네임',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.nickName);
        },
      },
    );

    // 테이블에 적용함
    setAuthUserTableColumn(tmpColumn);
  };

  // 테이블의 데이터를 불러옴
  const getAuthUserTableData = () => {
    let tmpData: any = [];

    console.log('> detailData:', detailData);

    // 테이블에 적용함
    setAuthUserTable(detailData.grpTmVoList);
  };

  // 테이블의 전체 삭제 버튼을 클릭함
  const handleAuthUserTableAllDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '전체 항목을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 테이블에 적용함
            setAuthUserTable([]);

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 테이블의 선택 삭제 버튼을 클릭함
  const handleAuthUserTableDeleteButton_onClick = () => {
    // 선택한 항목을 정의함
    let selectedId: any = [];

    // 선택한 항목을 불러옴
    authUserTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedId.push(item.userId);
    });

    // 선택한 항목이 없음
    if (selectedId.length === 0) {
      setModal({
        title: '알림',
        content: '삭제할 항목을 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: `선택하신 ${selectedId.length}개의 항목을 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 선택한 항목을 삭제함
            let tmpTableData: any = authUserTable.filter(
              (item: any) => !_.includes(selectedId, item.userId),
            );

            // 테이블에 적용함
            setAuthUserTable(tmpTableData);

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });
          }}
        >
          예
        </Button>
      ),
    });
  };

  /**
   * 계약에 접근 가능한 대상자 드로어
   */

  // 드로어의 출력 여부를 정의함
  const [openedDrawer, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  // 검색 조건 셀렉트를 정의함
  const [drawerSearchSelectItem, setDrawerSearchSelectItem] = useState<
    IOptionItem[]
  >([]);
  const [drawerSearchSelect, setDrawerSearchSelect] = useState<string>('');

  // 검색어 입력을 정의함
  const [drawerSearchInput, setDrawerSearchInput] = useState<string>('');

  // 테이블을 정의함
  const [drawerTableColumn, setDrawerTableColumn] = useState<any>([]);
  const [drawerTable, setDrawerTable] = useState<any>([]);
  const [drawerFilterTable, setDrawerFilterTable] = useState<any>([]);
  const drawerTableRef = useRef<any>(null);

  // 테이블 페이징을 정의함
  const [drawerTablePaging, setDrawerTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const drawerTablePagingCurrentPageRef = useRef<number>(1);

  // 테이블을 초기화함
  const initDrawerTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'checkbox',
        width: 60,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      },
      {
        field: 'cmpnName',
        headerName: '회사',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.cmpnName);
        },
      },
      {
        field: 'name',
        headerName: '이름',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.name);
        },
      },
      {
        field: 'nickName',
        headerName: '닉네임',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.nickName);
        },
      },
    );

    // 테이블에 적용함
    setDrawerTableColumn(tmpColumn);
  };

  // 테이블의 데이터를 불러옴
  const getDrawerTableData = () => {
    sttnApi
      .getSttnPrtns({
        searchType: 'name',
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 테이블에 적용함
          setDrawerTable(data.data);
          setDrawerFilterTable(data.data);
        }
      });
  };

  // 검색어 입력의 값을 변경함
  const drawerSearchInput_onChange = (event: any) => {
    setDrawerSearchInput(event.currentTarget.value);
  };

  // 검색어 입력에서 키를 입력함
  const drawerSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 검색 버튼을 클릭함
      drawerSearchButton_onClick();
    }
  };

  // 검색 버튼을 클릭함
  const drawerSearchButton_onClick = () => {
    // 검색어에 맞게 데이터를 필터링함
    let tmpData: any = drawerTable.filter(
      (filterItem: any) =>
        filterItem.cmpnName.indexOf(drawerSearchInput) > -1 ||
        filterItem.name.indexOf(drawerSearchInput) > -1 ||
        filterItem.nickName.indexOf(drawerSearchInput) > -1,
    );

    // 필터링한 데이터를 테이블에 적용함
    setDrawerFilterTable(tmpData);
  };

  // 선택한 사용자를 계약에 할당함
  const drawerAddButton_onClick = () => {
    // 선택한 항목을 정의함
    let selectedId: any = [];

    // 선택한 항목을 불러옴
    drawerTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedId.push({
        userId: item.userId,
      });
    });

    // 선택한 항목이 없음
    if (selectedId.length === 0) {
      setModal({
        title: '알림',
        content: '추가할 항목을 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: `선택하신 ${selectedId.length}개의 항목을 추가하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            let tmpTableData: any = [];

            // 기존에 없던 항목만 추가함
            selectedId.map((item: any) => {
              if (
                _.findIndex(authUserTable, {
                  userId: item.userId,
                }) === -1
              ) {
                tmpTableData.push(
                  _.find(drawerTable, {
                    userId: item.userId,
                  }),
                );
              }
            });

            // 테이블에 적용함
            setAuthUserTable([...authUserTable, ...tmpTableData]);

            notifications.show({
              title: '알림',
              message: '추가하였습니다.',
            });

            // 드로어를 닫음
            closeDrawer();
          }}
        >
          예
        </Button>
      ),
    });
  };

  /**
   * 순서 정렬 가능한 체크 목록
   */

  // 선택한 점검항목 탭을 정의함(NTWR_CNST, ESL_NSTL)
  const [selectedCheckListTab, setSelectedCheckListTab] = useState<string>('');

  // 편집용 체크 항목 팝오버 출력여부를 정의함
  const [
    openedCheckListContent,
    { open: openCheckListContent, close: closeCheckListContent },
  ] = useDisclosure(false);

  // 체크 목록을 정의함
  const [networkCheckList, setNetworkCheckList] = useState<any[]>([]);
  const [eslCheckList, setEslCheckList] = useState<any[]>([]);

  // 수정을 위해 선택한 체크 목록을 정의함
  const [selectedCheckList, setSelectedCheckList] = useState<any>(null);

  // 편집용 체크 항목 입력을 정의함
  const [checkListContentInput, setCheckListContentInput] =
    useState<string>('');
  const checkListContentInputRef = useRef<HTMLInputElement>(null);

  // 편집용 사용여부 스위치를 정의함
  const [checkListUsedSwitch, setCheckListUsedSwitch] = useState<boolean>(true);

  // 편집용 체크 항목을 초기화함
  const initCheckListContent = () => {
    // 체크 항목 입력을 초기화함
    setCheckListContentInput('');

    // 사용여부 스위치를 초기화함
    setCheckListUsedSwitch(true);

    // 수정을 위해 선택한 체크 목록을 초기화함
    setSelectedCheckList(null);

    // 체크 항목 입력에 포커스를 적용함
    checkListContentInputRef.current?.focus();
  };

  // 체크 항목 입력의 값을 변경함
  const checkListContentInput_onChange = (event: any) => {
    setCheckListContentInput(event.currentTarget.value);
  };

  // 체크 항목 입력에서 키를 입력함
  const checkListContentInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 적용 버튼을 클릭함
      checkListContentButton_onClick();
    }
  };

  // 전체 삭제 버튼을 클릭함
  const handleCheckListAllDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '전체 항목을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            switch (selectedCheckListTab) {
              case 'NTWR_CNST':
                setNetworkCheckList([]);
                break;

              case 'ESL_NSTL':
                setEslCheckList([]);
                break;

              default:
                break;
            }

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 선택 삭제 버튼을 클릭함
  const handleCheckListDeleteButton_onClick = () => {
    let tmpCheck = document.querySelectorAll(
      `.checkbox-${selectedCheckListTab} input[type=checkbox]:checked`,
    ) as NodeListOf<HTMLInputElement>;

    let selectedId: string[] = [];

    // 체크한 항목의 고유번호를 불러옴
    for (const item of tmpCheck as any) {
      selectedId.push(item.value);
    }

    // 선택한 항목이 없음
    if (selectedId.length === 0) {
      setModal({
        title: '알림',
        content: '삭제할 항목을 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: `선택하신 ${selectedId.length}개의 항목을 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            switch (selectedCheckListTab) {
              case 'NTWR_CNST':
                // 선택한 항목을 삭제함
                let tmpData: any = networkCheckList.filter(
                  (item: any) =>
                    !_.includes(selectedId, item.cntnNmbr.toString()),
                );

                // 데이터에 적용함
                setNetworkCheckList(tmpData);
                break;

              case 'ESL_NSTL':
                // 선택한 항목을 삭제함
                let tmpData2: any = eslCheckList.filter(
                  (item: any) =>
                    !_.includes(selectedId, item.cntnNmbr.toString()),
                );

                // 데이터에 적용함
                setEslCheckList(tmpData2);
                break;

              default:
                break;
            }

            let tmpCheck2 = document.querySelectorAll(
              `.checkbox-${selectedCheckListTab} input[type=checkbox]:checked`,
            ) as NodeListOf<HTMLInputElement>;

            // 체크를 해제함
            for (const item of tmpCheck2 as any) {
              item.checked = false;
            }

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 체크 목록에서 아이템을 클릭함
  const checkListItem_onClick = (item: any) => {
    // 수정을 위해 선택한 체크 목록에 적용함
    setSelectedCheckList(item);

    // 체크 항목 입력에 적용함
    setCheckListContentInput(item.cntn);

    // 사용여부 스위치에 적용함
    setCheckListUsedSwitch(item.use);

    // 편집용 팝오버를 출력함
    openCheckListContent();
  };

  // 추가/수정 버튼을 클릭함
  const checkListContentButton_onClick = () => {
    if (checkListContentInput.trim().length === 0) {
      return;
    }

    switch (selectedCheckListTab) {
      case 'NTWR_CNST':
        if (selectedCheckList === null) {
          // 추가
          setNetworkCheckList([
            ...networkCheckList,
            ...[
              {
                cntnNmbr:
                  _.maxBy(networkCheckList, 'cntnNmbr')?.cntnNmbr + 1 || 1,
                cntn: checkListContentInput,
                use: checkListUsedSwitch,
                sortRdr: 0,
                new: true,
              },
            ],
          ]);
        } else {
          // 수정
          let tmpNetworkCheckList: any = _.cloneDeep(networkCheckList);

          tmpNetworkCheckList.map((item: any) => {
            if (item.cntnNmbr === selectedCheckList.cntnNmbr) {
              item.cntn = checkListContentInput;
              item.use = checkListUsedSwitch;
            }
          });

          setNetworkCheckList(tmpNetworkCheckList);

          // 팝오버 출력을 숨김
          closeCheckListContent();
        }
        break;

      case 'ESL_NSTL':
        if (selectedCheckList === null) {
          // 추가
          setEslCheckList([
            ...eslCheckList,
            ...[
              {
                cntnNmbr: _.maxBy(eslCheckList, 'cntnNmbr')?.cntnNmbr + 1 || 1,
                cntn: checkListContentInput,
                use: checkListUsedSwitch,
                sortRdr: 0,
                new: true,
              },
            ],
          ]);
        } else {
          // 수정
          let tmpEslCheckList: any = _.cloneDeep(eslCheckList);

          tmpEslCheckList.map((item: any) => {
            if (item.cntnNmbr === selectedCheckList.cntnNmbr) {
              item.cntn = checkListContentInput;
              item.use = checkListUsedSwitch;
            }
          });

          setEslCheckList(tmpEslCheckList);

          // 팝오버 출력을 숨김
          closeCheckListContent();
        }
        break;

      default:
        break;
    }

    // 체크 항목 입력을 초기화함
    initCheckListContent();
  };

  // 체크 목록의 데이터를 불러옴
  const getCheckListData = () => {
    // 네트워크
    nstlApi
      .getChckCntrNmbrSplrTypeCode({
        cntrNmbr: cntrNmbr,
        splrTypeCode: 'NTWR_CNST',
        useYn: true,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          console.log('> check.n:', data);
          setNetworkCheckList(data.data);
        }
      });

    // ESL
    nstlApi
      .getChckCntrNmbrSplrTypeCode({
        cntrNmbr: cntrNmbr,
        splrTypeCode: 'ESL_NSTL',
        useYn: true,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          console.log('> check.e:', data);
          setEslCheckList(data.data);
        }
      });

    // 임시!!
    // setNetworkCheckList([
    //   { cntnNmbr: 1, cntn: '네트워크 내용.1', use: true, sortRdr: 1 },
    //   { cntnNmbr: 2, cntn: '네트워크 내용.2', use: true, sortRdr: 2 },
    //   { cntnNmbr: 3, cntn: '네트워크 내용.3', use: false, sortRdr: 3 },
    //   { cntnNmbr: 4, cntn: '네트워크 내용.4', use: false, sortRdr: 4 },
    //   { cntnNmbr: 5, cntn: '네트워크 내용.5', use: true, sortRdr: 5 },
    // ]);
  };

  /////////////////////////////////////////////////////////////

  // 상세 내용을 정의함
  const [detailData, setDetailData] = useState<any>(null);

  // 계약 > 담당PM > 셀렉트를 정의함
  const [pmNameSelect, setPmNameSelect] = useState<string>('');
  const [pmNameSelectItem, setPmNameSelectItem] = useState<IOptionItem[]>([]);

  // 계약 > 사용여부 > 셀렉트를 정의함
  const [usedSelect, setUsedSelect] = useState<string>('');
  const [usedSelectItem, setUsedSelectItem] = useState<IOptionItem[]>([]);

  // 계약 폼 그리드 > 담당PM > 셀렉트의 아이템을 초기화함
  const initPmNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '담당자 없음',
      value: '',
    });

    // 판매 계약 담당PM 목록을 불러옴
    sttnApi.getSttnErpSearchPm({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.name,
          value: item.userId,
        });
      });

      // 계약 폼 그리드 > 담당PM > 셀렉트의 아이템에 적용함
      setPmNameSelectItem(tmpOptionItem);
    });
  };

  // 계약 폼 그리드 > 사용여부 > 셀렉트의 아이템을 초기화함
  const initUsedSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '선택하세요.',
        value: '',
      },
      {
        label: '사용',
        value: 'Y',
      },
      {
        label: '미사용',
        value: 'N',
      },
    );

    // 계약 폼 그리드 > 담당PM > 셀렉트의 아이템에 적용함
    setUsedSelectItem(tmpOptionItem);
  };

  // 상세 내용을 불러옴
  const getDetailData = () => {
    sttnApi
      .getSttnErp({
        cntrNmbr: cntrNmbr || '',
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          console.log('> detail:', data.data);

          // 상세 내용에 적용함
          setDetailData(data.data);

          // 계약에 접근 가능한 대상자 테이블에 적용함
          setAuthUserTable(data.data.sttnContractStatusVoList);
        }
      });
  };

  // 해당 작업현황 조회 버튼을 클릭함
  const handleGoInstallWorkStatusListButton_onClick = () => {
    setRemoveComponentModal(id);

    window.location.href = `/install-work-status?search-category=CNTR_NAME&search=${encodeURIComponent(
      decode(detailData?.cntrName),
    )}`;
  };

  // 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '작업현황 상세 수정',
        content: (
          <MN2302220404 id={tmpId} cntrNmbr={cntrNmbr} useModifyMode={true} />
        ),
        size: 1300,
        centered: false,
      });
    }, 100);
  };

  // 저장 버튼(수정)을 클릭함
  const handleSaveUpdateButton_onClick = () => {
    // 네트워크 체크 목록의 순서를 정렬함
    let tmpNetworkCheckList = _.cloneDeep(networkCheckList);

    tmpNetworkCheckList.map((item: any, index: number) => {
      item.sortRdr = index + 1;

      if (item.new) {
        delete item.cntnNmbr;
      }
    });

    console.log('> tmpNetworkCheckList:', tmpNetworkCheckList);

    setNetworkCheckList(tmpNetworkCheckList);

    // ESL 체크 목록의 순서를 정렬함
    let tmpEslCheckList = _.cloneDeep(eslCheckList);

    tmpEslCheckList.map((item: any, index: number) => {
      item.sortRdr = index + 1;

      if (item.new) {
        delete item.cntnNmbr;
      }
    });

    console.log('> tmpEslCheckList:', tmpEslCheckList);

    setEslCheckList(tmpEslCheckList);

    // 계약에 할당된 사용자를 불러옴
    let tmpAuthUser: any[] = [];
    authUserTable.map((item: any) => {
      tmpAuthUser.push({
        userId: item.userId,
      });
    });

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // notifications.show({
            //   title: '알림',
            //   message: '저장 중입니다.',
            // });

            // 네트워크 체크 목록을 저장함
            nstlApi
              .putChckCntrNmbrSplrTypeCode({
                cntrNmbr: cntrNmbr,
                contentVOs: tmpNetworkCheckList,
                splrTypeCode: 'NTWR_CNST',
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  // ESL 체크 목록을 저장함
                  nstlApi
                    .putChckCntrNmbrSplrTypeCode({
                      cntrNmbr: cntrNmbr,
                      contentVOs: tmpEslCheckList,
                      splrTypeCode: 'ESL_NSTL',
                    })
                    .then((data: IApiResult) => {
                      if (data.code === '200') {
                        sttnApi
                          .putSttnErp({
                            cntrNmbr: cntrNmbr,
                            pmId: pmNameSelect,
                            useYn: usedSelect,
                            sttnContractStatusVo: tmpAuthUser,
                          })
                          .then((data: IApiResult) => {
                            if (data.code === '200') {
                              notifications.show({
                                title: '알림',
                                message: '저장하였습니다.',
                              });

                              // 목록 새로고침을 추가함
                              setAddRefreshList('MN2302220404Table');

                              // 컴포넌트 모달을 닫음
                              setRemoveComponentModal(id);

                              setTimeout(() => {
                                let tmpId: string = nanoid();

                                // 컴포넌트 모달을 추가함
                                setAddComponentModal({
                                  id: tmpId,
                                  title: '작업현황 상세',
                                  content: (
                                    <MN2302220404
                                      id={tmpId}
                                      cntrNmbr={cntrNmbr}
                                    />
                                  ),
                                  size: 1300,
                                  centered: false,
                                });
                              }, 100);
                            } else {
                              console.log('> 작업현황 상세 수정 Error:', data);

                              setModal({
                                title: '오류',
                                content: (
                                  <>
                                    <div>저장에 실패하였습니다.</div>
                                    <div>({data.message})</div>
                                  </>
                                ),
                              });
                            }
                          })
                          .catch((error: any) => {
                            console.log('> 작업현황 상세 수정 Error:', error);

                            setModal({
                              title: '오류',
                              content: `저장에 실패하였습니다.(${error.message})`,
                            });
                          });
                      } else {
                        console.log(
                          '> 작업현황 상세 수정(ESL 체크 리스트) Error:',
                          data,
                        );

                        setModal({
                          title: '오류',
                          content: (
                            <>
                              <div>저장에 실패하였습니다.</div>
                              <div>({data.message})</div>
                            </>
                          ),
                        });
                      }
                    })
                    .catch((error: any) => {
                      console.log('> 작업현황 상세 수정 Error:', error);

                      setModal({
                        title: '오류',
                        content: `저장에 실패하였습니다.(${error.message})`,
                      });
                    });
                } else {
                  console.log(
                    '> 작업현황 상세 수정(네트워크 체크 리스트) Error:',
                    data,
                  );

                  setModal({
                    title: '오류',
                    content: (
                      <>
                        <div>저장에 실패하였습니다.</div>
                        <div>({data.message})</div>
                      </>
                    ),
                  });
                }
              })
              .catch((error: any) => {
                console.log('> 작업현황 상세 수정 Error:', error);

                setModal({
                  title: '오류',
                  content: `저장에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 계약 폼 그리드 > 담당PM > 셀렉트에서 선택한 아이템을 변경함
  const handlePmNameSelect_onChange = (event: any) => {
    setPmNameSelect(event);
  };

  // 계약 폼 그리드 > 사용여부 > 셀렉트에서 선택한 아이템을 변경함
  const handleUsedSelect_onChange = (event: any) => {
    setUsedSelect(event);
  };

  // 로그인 정보가 준비될 때 실행함
  useEffect(() => {
    if (!loginUser.id) {
      return;
    }

    // 계약에 접근 가능한 대상자 테이블을 초기화함
    initAuthUserTable();

    // 계약에 접근 가능한 대상자 드로어 테이블을 초기화함
    initDrawerTable();

    // 상세 내용을 불러옴
    getDetailData();

    // 계약 폼 그리드 > 담당 PM 셀렉트의 아이템을 초기화함
    initPmNameSelect();

    // 계약 폼 그리드 > 사용여부 > 셀렉트의 아이템을 초기화함
    initUsedSelect();

    // 체크 목록의 데이터를 불러옴
    getCheckListData();

    return () => {};
  }, [loginUser.id]);

  // 상세 정보를 불러온 후 실행함
  useEffect(() => {
    if (detailData === null) {
      return;
    }

    // 계약 > 담당PM > 셀렉트에 적용함
    setPmNameSelect(detailData?.pmId);

    // 계약 > 사용여부 > 셀렉트에 적용함
    setUsedSelect(detailData?.useYn);

    return () => {};
  }, [detailData]);

  // 드로어가 출력될 때 실행함
  useEffect(() => {
    if (!openedDrawer) {
      return;
    }

    // 검색어를 초기화함
    setDrawerSearchInput('');

    // 데이터를 불러옴
    getDrawerTableData();

    return () => {};
  }, [openedDrawer]);

  return (
    <div className="relative space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div>
        {/* 탭 */}
        <Tabs variant="outline" defaultValue="default">
          {/* 탭 헤더 */}
          <Tabs.List>
            <Tabs.Tab
              value="default"
              onClick={() => {
                // 팝오버 출력을 숨김
                closeCheckListContent();
              }}
            >
              <span className="text-lg">기본정보</span>
            </Tabs.Tab>

            <Tabs.Tab
              value="check-network"
              onClick={() => {
                setSelectedCheckListTab('NTWR_CNST');

                // 팝오버 출력을 숨김
                closeCheckListContent();
              }}
            >
              <span className="text-lg">점검항목(네트워크)</span>
            </Tabs.Tab>

            <Tabs.Tab
              value="check-esl"
              onClick={() => {
                setSelectedCheckListTab('ESL_NSTL');

                // 팝오버 출력을 숨김
                closeCheckListContent();
              }}
            >
              <span className="text-lg">점검항목(ESL)</span>
            </Tabs.Tab>
          </Tabs.List>

          {/* 탭 내용: 기본정보 */}
          <Tabs.Panel value="default" pt="xl" className="space-y-7">
            <div className="space-y-5">
              {/* 프로젝트 폼 그리드 */}
              <PbFormGrid label="프로젝트" cols={2}>
                <PbFormGridCol label="프로젝트명" colSpan={2}>
                  <div>
                    <span>{decode(detailData?.prjcName)}</span>
                  </div>
                </PbFormGridCol>

                <PbFormGridCol label="프로젝트코드">
                  <div>
                    <span>{detailData?.prjcCode}</span>
                  </div>
                </PbFormGridCol>

                <PbFormGridCol label="프로젝트일정">
                  <div>
                    <span>
                      {detailData?.fctvStrtDate?.replaceAll('-', '.')} ~{' '}
                      {detailData?.fctvEndDate?.replaceAll('-', '.')}
                    </span>
                  </div>
                </PbFormGridCol>
              </PbFormGrid>

              {/* 계약 폼 그리드 */}
              <PbFormGrid label="계약" cols={2}>
                <PbFormGridCol label="계약명">
                  <div>
                    <span>{decode(detailData?.cntrName)}</span>
                  </div>
                </PbFormGridCol>

                <PbFormGridCol label="계약번호">
                  <div>
                    <span>{detailData?.cntrScrnNmbr}</span>
                  </div>
                </PbFormGridCol>

                <PbFormGridCol label="고객사">
                  <div>
                    <span>{decode(detailData?.bpName)}</span>
                  </div>
                </PbFormGridCol>

                <PbFormGridCol label="고객사(BP)코드">
                  <div>
                    <span>{detailData?.bpCode}</span>
                  </div>
                </PbFormGridCol>

                <PbFormGridCol label="영업사원">
                  <div>
                    <span>{detailData?.slspName}</span>
                  </div>
                </PbFormGridCol>

                <PbFormGridCol label="담당PM">
                  {/* 조회 상태 */}
                  {cntrNmbr && !useModifyMode && (
                    <div>
                      <span>{detailData?.pmName}</span>
                    </div>
                  )}

                  {/* 수정 상태 */}
                  {cntrNmbr && useModifyMode && (
                    <>
                      {/* 셀렉트 */}
                      <Select
                        onChange={handlePmNameSelect_onChange}
                        data={pmNameSelectItem}
                        value={pmNameSelect}
                        className="w-full"
                      />
                    </>
                  )}
                </PbFormGridCol>
              </PbFormGrid>

              {/* 사용여부 폼 그리드 */}
              <PbFormGrid label="사용여부" cols={2}>
                <PbFormGridCol label="사용여부" colSpan={2}>
                  {/* 조회 상태 */}
                  {cntrNmbr && !useModifyMode && (
                    <div>
                      <span>
                        {detailData?.useYn === 'Y' ? '사용' : '미사용'}
                      </span>
                    </div>
                  )}

                  {/* 수정 상태 */}
                  {cntrNmbr && useModifyMode && (
                    <>
                      {/* 셀렉트 */}
                      <Select
                        onChange={handleUsedSelect_onChange}
                        data={usedSelectItem}
                        value={usedSelect}
                        className="w-full"
                      />
                    </>
                  )}
                </PbFormGridCol>
              </PbFormGrid>

              {/* 계약에 접근 가능한 대상자 섹션 */}
              <PbSection
                id="step-3"
                label="계약에 접근 가능한 대상자"
                rightForm={
                  <>
                    {/* 버튼 */}
                    {/* 추가, 수정 상태 */}
                    {!(cntrNmbr && !useModifyMode) && (
                      <div className="flex justify-end items-center space-x-2">
                        {/* 전체 삭제 버튼 */}
                        <Button
                          variant="outline"
                          color="pink"
                          radius="xl"
                          onClick={handleAuthUserTableAllDeleteButton_onClick}
                        >
                          전체 삭제
                        </Button>

                        {/* 선택 삭제 버튼 */}
                        <Button
                          color="pink"
                          radius="xl"
                          onClick={handleAuthUserTableDeleteButton_onClick}
                        >
                          선택 삭제
                        </Button>

                        {/* 추가 버튼 */}
                        <Button onClick={openDrawer} color="indigo" radius="xl">
                          추가
                        </Button>
                      </div>
                    )}
                  </>
                }
              >
                <div className="space-y-3">
                  {/* 테이블 */}
                  <div className="w-full h-108">
                    <PbAgGridReact
                      refs={authUserTableRef}
                      columnDefs={authUserTableColumn}
                      rowData={authUserTable}
                      defaultColDef={{
                        resizable: true,
                        sortable: true,
                        wrapHeaderText: false,
                        autoHeaderHeight: true,
                      }}
                      rowSelection="multiple"
                      sizeColumnsToFit={true}
                      visiblePaging={true}
                      paging={{
                        totalPage: authUserTablePaging.totalPage,
                        currentPage: authUserTablePaging.currentPage,
                      }}
                      onChangePage={(event: any) => {
                        // 테이블의 페이지를 변경함
                        authUserTablePagingCurrentPageRef.current = event;
                        setAuthUserTablePaging((pre: ITablePaging) => ({
                          ...pre,
                          currentPage:
                            authUserTablePagingCurrentPageRef.current,
                        }));

                        // 테이블의 데이터를 불러옴
                        getAuthUserTableData();
                      }}
                    />
                  </div>
                </div>
              </PbSection>
            </div>
          </Tabs.Panel>

          {/* 탭 내용: 점검항목(네트워크) */}
          <Tabs.Panel value="check-network" pt="xl" className="space-y-7">
            <PbSection
              label="체크할 항목"
              rightForm={
                <>
                  {cntrNmbr &&
                    useModifyMode &&
                    selectedCheckListTab === 'NTWR_CNST' && (
                      <div className="flex justify-end items-center space-x-2">
                        {/* 도움말 */}
                        <div className="flex justify-center items-center space-x-1">
                          <div className="flex justify-center items-center">
                            <FontAwesomeIcon
                              icon={['fas', 'circle-info']}
                              className="w-4 h-4 text-blue-500"
                            />
                          </div>

                          <div className="flex justify-center items-center space-x-2">
                            <span className="text-base text-gray-600 font-bold">
                              저장된 항목은 삭제할 수 없는 대신 사용 여부를
                              비활성화로 변경하실 수 있습니다.
                            </span>
                          </div>
                        </div>

                        {/* 버튼 */}
                        {/*<Button*/}
                        {/*  variant="outline"*/}
                        {/*  color="pink"*/}
                        {/*  radius="xl"*/}
                        {/*  onClick={handleCheckListAllDeleteButton_onClick}*/}
                        {/*>*/}
                        {/*  전체 삭제*/}
                        {/*</Button>*/}

                        {/* 버튼 */}
                        {/*<Button*/}
                        {/*  color="pink"*/}
                        {/*  radius="xl"*/}
                        {/*  onClick={handleCheckListDeleteButton_onClick}*/}
                        {/*>*/}
                        {/*  선택 삭제*/}
                        {/*</Button>*/}

                        {/* 버튼 */}
                        <Popover
                          opened={openedCheckListContent}
                          onClose={initCheckListContent}
                          position="left"
                          withArrow
                          trapFocus
                          shadow="md"
                        >
                          <Popover.Target>
                            <Button
                              onClick={openCheckListContent}
                              color="indigo"
                              radius="xl"
                            >
                              추가
                            </Button>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <div className="space-y-2">
                              {/* 편집 항목 */}
                              <div className="p-1 bg-slate-100 rounded space-y-3">
                                {/* 텍스트 입력 */}
                                <TextInput
                                  ref={checkListContentInputRef}
                                  placeholder="체크 항목을 입력하세요."
                                  onChange={checkListContentInput_onChange}
                                  onKeyUp={checkListContentInput_onKeyUp}
                                  value={checkListContentInput}
                                  className="w-96"
                                />

                                {/* 텍스트 입력 */}
                                <Switch
                                  size="md"
                                  label={
                                    <span className="text-base text-gray-600 font-medium leading-none">
                                      사용 여부
                                    </span>
                                  }
                                  onChange={(event) =>
                                    setCheckListUsedSwitch(
                                      event.currentTarget.checked,
                                    )
                                  }
                                  checked={checkListUsedSwitch}
                                />
                              </div>

                              {/* 버튼 */}
                              <div className="flex justify-end items-center space-x-2">
                                {/* 버튼 */}
                                <Button
                                  variant="outline"
                                  color="gray"
                                  radius="xl"
                                  onClick={closeCheckListContent}
                                >
                                  닫기
                                </Button>

                                {/* 버튼 */}
                                <Button
                                  color="indigo"
                                  radius="xl"
                                  onClick={checkListContentButton_onClick}
                                >
                                  {selectedCheckList === null ? '추가' : '수정'}
                                </Button>
                              </div>
                            </div>
                          </Popover.Dropdown>
                        </Popover>
                      </div>
                    )}
                </>
              }
            >
              {/* 순서 정렬 가능한 체크 목록 */}
              {networkCheckList.length === 0 ? (
                <div className="py-5 flex justify-center items-center bg-slate-100 rounded">
                  <span className="text-base text-gray-600 font-bold">
                    체크할 항목을 추가하세요.
                  </span>
                </div>
              ) : (
                <div className="select-none">
                  <ReactSortable
                    list={networkCheckList}
                    setList={setNetworkCheckList}
                    animation={200}
                    disabled={cntrNmbr && useModifyMode ? false : true}
                    easing="ease-out"
                    className="space-y-2"
                  >
                    {networkCheckList.map((item: any, index: number) => (
                      <div
                        key={index}
                        className={[
                          'bg-blue-100 border-1 border-blue-200 rounded-sm',
                          cntrNmbr &&
                            useModifyMode &&
                            'cursor-move hover:bg-indigo-50 hover:border-indigo-300 active:translate-y-px',
                          item.use === false &&
                            '!border-slate-300 !bg-slate-200',
                          cntrNmbr &&
                            useModifyMode &&
                            item.use === false &&
                            '!border-slate-300 !bg-slate-200 hover:!bg-slate-100 hover:!border-slate-400',
                        ].join(' ')}
                      >
                        {/* 체크 항목 */}
                        <div className="pl-3 pr-3 h-10 flex justify-start items-center space-x-3">
                          {/* 체크박스 */}
                          {cntrNmbr && useModifyMode && (
                            <Checkbox
                              onClick={closeCheckListContent}
                              value={item.cntnNmbr}
                              className={`checkbox-${selectedCheckListTab}`}
                            />
                          )}

                          {/* 번호 */}
                          <div
                            onClick={() => {
                              if (cntrNmbr && useModifyMode) {
                                checkListItem_onClick(item);
                              }
                            }}
                            className={[
                              'w-7 h-7 flex justify-center items-center bg-blue-600 rounded-full',
                              item.use === false && '!bg-slate-500',
                            ].join(' ')}
                          >
                            <span className="text-sm text-white font-bold leading-none">
                              {index + 1}
                            </span>
                          </div>

                          <div
                            onClick={() => {
                              if (cntrNmbr && useModifyMode) {
                                checkListItem_onClick(item);
                              }
                            }}
                            className="grow flex justify-between items-center"
                          >
                            {/* 항목 내용 */}
                            <span
                              className={[
                                'text-sm font-normal leading-none',
                                item.use === true
                                  ? 'text-gray-800'
                                  : 'text-gray-400',
                              ].join(' ')}
                            >
                              {item.cntn}
                            </span>

                            {/* 사용 여부 */}
                            {item.use === true ? (
                              <div className="flex justify-center items-center">
                                <FontAwesomeIcon
                                  icon={['fas', 'toggle-on']}
                                  className="w-8 h-8 text-blue-600"
                                />
                              </div>
                            ) : (
                              <div className="flex justify-center items-center">
                                <FontAwesomeIcon
                                  icon={['fas', 'toggle-off']}
                                  className="w-8 h-8 text-gray-400"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </ReactSortable>
                </div>
              )}
            </PbSection>
          </Tabs.Panel>

          {/* 탭 내용: 점검항목(ESL) */}
          <Tabs.Panel value="check-esl" pt="xl" className="space-y-7">
            <PbSection
              label="체크할 항목"
              rightForm={
                <>
                  {cntrNmbr &&
                    useModifyMode &&
                    selectedCheckListTab === 'ESL_NSTL' && (
                      <div className="flex justify-end items-center space-x-2">
                        {/* 도움말 */}
                        <div className="flex justify-center items-center space-x-1">
                          <div className="flex justify-center items-center">
                            <FontAwesomeIcon
                              icon={['fas', 'circle-info']}
                              className="w-4 h-4 text-blue-500"
                            />
                          </div>

                          <div className="flex justify-center items-center space-x-2">
                            <span className="text-base text-gray-600 font-bold">
                              저장된 항목은 삭제할 수 없는 대신 사용 여부를
                              비활성화로 변경하실 수 있습니다.
                            </span>
                          </div>
                        </div>

                        {/* 버튼 */}
                        {/*<Button*/}
                        {/*  variant="outline"*/}
                        {/*  color="pink"*/}
                        {/*  radius="xl"*/}
                        {/*  onClick={handleCheckListAllDeleteButton_onClick}*/}
                        {/*>*/}
                        {/*  전체 삭제*/}
                        {/*</Button>*/}

                        {/* 버튼 */}
                        {/*<Button*/}
                        {/*  color="pink"*/}
                        {/*  radius="xl"*/}
                        {/*  onClick={handleCheckListDeleteButton_onClick}*/}
                        {/*>*/}
                        {/*  선택 삭제*/}
                        {/*</Button>*/}

                        {/* 버튼 */}
                        <Popover
                          opened={openedCheckListContent}
                          onClose={initCheckListContent}
                          position="left"
                          withArrow
                          trapFocus
                          shadow="md"
                        >
                          <Popover.Target>
                            <Button
                              onClick={openCheckListContent}
                              color="indigo"
                              radius="xl"
                            >
                              추가
                            </Button>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <div className="space-y-2">
                              {/* 편집 항목 */}
                              <div className="p-1 bg-slate-100 rounded space-y-3">
                                {/* 텍스트 입력 */}
                                <TextInput
                                  ref={checkListContentInputRef}
                                  placeholder="체크 항목을 입력하세요."
                                  onChange={checkListContentInput_onChange}
                                  onKeyUp={checkListContentInput_onKeyUp}
                                  value={checkListContentInput}
                                  className="w-96"
                                />

                                {/* 텍스트 입력 */}
                                <Switch
                                  size="md"
                                  label={
                                    <span className="text-base text-gray-600 font-medium leading-none">
                                      사용 여부
                                    </span>
                                  }
                                  onChange={(event) =>
                                    setCheckListUsedSwitch(
                                      event.currentTarget.checked,
                                    )
                                  }
                                  checked={checkListUsedSwitch}
                                />
                              </div>

                              {/* 버튼 */}
                              <div className="flex justify-end items-center space-x-2">
                                {/* 버튼 */}
                                <Button
                                  variant="outline"
                                  color="gray"
                                  radius="xl"
                                  onClick={closeCheckListContent}
                                >
                                  닫기
                                </Button>

                                {/* 버튼 */}
                                <Button
                                  color="indigo"
                                  radius="xl"
                                  onClick={checkListContentButton_onClick}
                                >
                                  {selectedCheckList === null ? '추가' : '수정'}
                                </Button>
                              </div>
                            </div>
                          </Popover.Dropdown>
                        </Popover>
                      </div>
                    )}
                </>
              }
            >
              {/* 순서 정렬 가능한 체크 목록 */}
              {eslCheckList.length === 0 ? (
                <div className="py-5 flex justify-center items-center bg-slate-100 rounded">
                  <span className="text-base text-gray-600 font-bold">
                    체크할 항목을 추가하세요.
                  </span>
                </div>
              ) : (
                <div className="select-none">
                  <ReactSortable
                    list={eslCheckList}
                    setList={setEslCheckList}
                    animation={200}
                    disabled={cntrNmbr && useModifyMode ? false : true}
                    easing="ease-out"
                    className="space-y-2"
                  >
                    {eslCheckList.map((item: any, index: number) => (
                      <div
                        key={index}
                        className={[
                          'bg-blue-100 border-1 border-blue-200 rounded-sm',
                          cntrNmbr &&
                            useModifyMode &&
                            'cursor-move hover:bg-indigo-50 hover:border-indigo-300 active:translate-y-px',
                          item.use === false &&
                            '!border-slate-300 !bg-slate-200',
                          cntrNmbr &&
                            useModifyMode &&
                            item.use === false &&
                            '!border-slate-300 !bg-slate-200 hover:!bg-slate-100 hover:!border-slate-400',
                        ].join(' ')}
                      >
                        {/* 체크 항목 */}
                        <div className="pl-3 pr-3 h-10 flex justify-start items-center space-x-3">
                          {/* 체크박스 */}
                          {cntrNmbr && useModifyMode && (
                            <Checkbox
                              onClick={closeCheckListContent}
                              value={item.cntnNmbr}
                              className={`checkbox-${selectedCheckListTab}`}
                            />
                          )}

                          {/* 번호 */}
                          <div
                            onClick={() => {
                              if (cntrNmbr && useModifyMode) {
                                checkListItem_onClick(item);
                              }
                            }}
                            className={[
                              'w-7 h-7 flex justify-center items-center bg-blue-600 rounded-full',
                              item.use === false && '!bg-slate-500',
                            ].join(' ')}
                          >
                            <span className="text-sm text-white font-bold leading-none">
                              {index + 1}
                            </span>
                          </div>

                          <div
                            onClick={() => {
                              if (cntrNmbr && useModifyMode) {
                                checkListItem_onClick(item);
                              }
                            }}
                            className="grow flex justify-between items-center"
                          >
                            {/* 항목 내용 */}
                            <span
                              className={[
                                'text-sm font-normal leading-none',
                                item.use === true
                                  ? 'text-gray-800'
                                  : 'text-gray-400',
                              ].join(' ')}
                            >
                              {item.cntn}
                            </span>

                            {/* 사용 여부 */}
                            {item.use === true ? (
                              <div className="flex justify-center items-center">
                                <FontAwesomeIcon
                                  icon={['fas', 'toggle-on']}
                                  className="w-8 h-8 text-blue-600"
                                />
                              </div>
                            ) : (
                              <div className="flex justify-center items-center">
                                <FontAwesomeIcon
                                  icon={['fas', 'toggle-off']}
                                  className="w-8 h-8 text-gray-400"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </ReactSortable>
                </div>
              )}
            </PbSection>
          </Tabs.Panel>
        </Tabs>
      </div>

      {/* 계약에 접근 가능한 대상자 드로어 */}
      <Drawer
        opened={openedDrawer}
        onClose={closeDrawer}
        position="right"
        size={700}
        title={
          <span className="text-xl font-semibold">
            계약에 접근 가능한 대상자 추가
          </span>
        }
        zIndex={1000}
        // className="drawer-element"
      >
        <div className="space-y-3">
          {/* 검색 폼 그리드 */}
          <PbFormGrid cols={1}>
            <PbFormGridCol label="검색">
              {/* 셀렉트 */}
              {/*<Select*/}
              {/*  data={drawerSearchSelectItem}*/}
              {/*  value={drawerSearchSelect}*/}
              {/*/>*/}

              {/* 텍스트 입력 */}
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={drawerSearchInput_onChange}
                onKeyUp={drawerSearchInput_onKeyUp}
                value={drawerSearchInput}
              />

              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={drawerSearchButton_onClick}
                >
                  검색
                </Button>
              </div>
            </PbFormGridCol>
          </PbFormGrid>

          {/* 테이블의 상단 버튼 */}
          <div className="flex justify-end items-center space-x-2">
            {/* 할당 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              onClick={drawerAddButton_onClick}
            >
              선택한 사용자를 계약에 할당
            </Button>
          </div>

          {/* 테이블 */}
          <div style={{ height: 'calc(100vh - 220px)' }}>
            <PbAgGridReact
              refs={drawerTableRef}
              columnDefs={drawerTableColumn}
              rowData={drawerFilterTable}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="multiple"
              sizeColumnsToFit={true}
              visiblePaging={true}
              paging={{
                totalPage: drawerTablePaging.totalPage,
                currentPage: drawerTablePaging.currentPage,
              }}
              onChangePage={(event: any) => {
                // 테이블의 페이지를 변경함
                drawerTablePagingCurrentPageRef.current = event;
                setDrawerTablePaging((pre: ITablePaging) => ({
                  ...pre,
                  currentPage: drawerTablePagingCurrentPageRef.current,
                }));

                // 테이블의 데이터를 불러옴
                getDrawerTableData();
              }}
            />
          </div>

          {/* 테이블의 하단 버튼 */}
          <div className="flex justify-end items-center space-x-2">
            {/* 할당 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              onClick={drawerAddButton_onClick}
            >
              선택한 사용자를 계약에 할당
            </Button>
          </div>
        </div>
      </Drawer>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 조회 상태 */}
        {cntrNmbr && !useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            <Button
              color="cyan"
              radius="xl"
              size="md"
              onClick={handleGoInstallWorkStatusListButton_onClick}
            >
              해당 작업현황 조회
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleGoModifyButton_onClick}
            >
              수정 전환
            </Button>
          </>
        )}

        {/* 수정 상태 */}
        {cntrNmbr && useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveUpdateButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220404;
