import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { ICellRendererParams } from 'ag-grid-community';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  NumberInput,
  Textarea,
  FileInput,
  Checkbox,
  Group,
  Radio,
  Anchor,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PageLayout } from '../../components/PageLayout';
import { PbFormGrid } from '../../components/PbFormGrid';
import { PbFormGridCol } from '../../components/PbFormGridCol';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../../stores/refreshList.store';
import * as ntcbApi from '../../apis/ntcb.api';
import { HtmlEditor } from '../HtmlEditor';
import { useElementSize } from '@mantine/hooks';
import { decode, encode } from 'html-entities';
import PbSelect from '../PbSelect/PbSelect.component';

interface IMN2302220713Props {
  id?: string;
  brdId?: string;
  useModifyMode?: boolean;
  data?: any;
  onClick?: () => void;
}

/**
 * <Component 이름>
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220713 = ({
  id = '',
  brdId = '',
  useModifyMode = false,
  data,
  onClick,
}: PropsWithChildren<IMN2302220713Props>) => {
  // 언어를 정의함
  const { t } = useTranslation();
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);
  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**************************** API ****************************/
  //FAQ 등록
  const saveAddFaq = () => {
    ntcbApi
      .postFaq({
        cstyGrpCode: faqCategorySelect,
        cstyCode: faqDetailCategorySelect,
        ttl: faqTitleInput,
        openYn: faqOpenRadio,
        sortRdr: faqOrderInput,
        // dtl: faqAnswerInput,
        dtl: encode(faqAnswerInput.trim() || ''),
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 목록 새로고침
          setAddRefreshList('MN2302220703Table');
          // 컴포넌트 모달을 닫음
          setRemoveComponentModal(id);
        } else {
          console.log('> FAQ 등록 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>저장에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  //FAQ 삭제
  const deleteFaq = () => {
    ntcbApi
      .deleteFaq({
        brdId: brdId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 목록 새로고침
          setAddRefreshList('MN2302220703Table');
          // 컴포넌트 모달을 닫음
          setRemoveComponentModal(id);
        } else {
          console.log('> FAQ 삭제 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>삭제에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  //FAQ 상세 조회
  const getFaqDetail = (brdId: string) => {
    ntcbApi
      .getFaq({
        brdId: brdId,
      })
      .then((data: IApiResult) => {
        if (data.data != null) {
          setFaqTitleInput(data.data.ttl);
          setFaqCategorySelect(data.data.cstyGrpCode);
          // setFaqCategorySelect((pre: ISelect) => ({
          //   ...pre,
          //   value: data.data.cstyGrpCode,
          // }));
          setFaqDetailCategorySelect(data.data.cstyCode);
          // setFaqDetailCategorySelect((pre: ISelect) => ({
          //   ...pre,
          //   value: data.data.cstyCode,
          // }));
          setFaqCategoryInput(data.data.cstyGrpName);
          setFaqDetailCategoryInput(data.data.cstyName);
          setFaqOrderInput(data.data.sortRdr);
          setFaqOpenRadio(data.data.openYn);
          setFaqOpenInput(data.data.openYn === 'Y' ? '공개' : '비공개');
          setFaqRgsterInput(data.data.rgstName);
          // setFaqAnswerInput(data.data.dtl);
          setFaqAnswerInput(decode(data.data.dtl));
          setFaqRgstDate(data.data.rgstDate);
          setFaqModifyDate(data.data.mdfcDate);
        }
      });
  };

  //FAQ 수정 API
  const saveModifyFaq = () => {
    ntcbApi
      .putFaq({
        cstyGrpCode: faqCategorySelect,
        cstyCode: faqDetailCategorySelect,
        ttl: faqTitleInput,
        openYn: faqOpenRadio,
        sortRdr: faqOrderInput,
        // dtl: faqAnswerInput,
        dtl: encode(faqAnswerInput.trim() || ''),
        brdId: brdId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          setModal({
            title: '알림',
            content: '저장하였습니다.',
            callback: () => {
              // 목록 새로고침
              setAddRefreshList('MN2302220703Table');
              // 컴포넌트 모달을 닫음
              setRemoveComponentModal(id);
              setTimeout(() => {
                // 조회 컴포넌트 모달을 추가함
                let tmpId: string = nanoid();
                // 컴포넌트 모달을 추가함
                setAddComponentModal({
                  id: tmpId,
                  title: '공지사항 상세',
                  content: (
                    <MN2302220713
                      id={tmpId}
                      brdId={brdId}
                      useModifyMode={false}
                    />
                  ),
                  size: 1500,
                });
              }, 100);
            },
          });
        } else {
          console.log('> FAQ 수정 Error:', data);
          setModal({
            title: '오류',
            content: (
              <>
                <div>수정에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  //FAQ 유형 조회
  const getFaqCategory = () => {
    ntcbApi
      .getFaqCsType({
        depth: 1,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          //FAQ 유형 셀렉트 초기화
          let tmpOptionItem: IOptionItem[] = [];
          for (var category of data.data.list) {
            tmpOptionItem.push({
              label: category.dtlCodeDscr,
              value: category.dtlCodeId,
            });
          }
          // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
          setFaqCategorySelect(!brdId ? data.data.list[0].dtlCodeId : '');
          setFaqCategorySelectItem(tmpOptionItem);
          // setFaqCategorySelect({
          //   value: !brdId ? data.data.list[0].dtlCodeId : '',
          //   item: tmpOptionItem,
          // });
        }
      });
  };

  //FAQ 유형 상세 조회
  const getFaqDetailCategory = () => {
    ntcbApi
      .getFaqCsType({
        depth: 2,
        searchKeyword: faqCategorySelect,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          //FAQ 유형 셀렉트 초기화
          let tmpOptionItem: IOptionItem[] = [];
          for (var category of data.data.list) {
            tmpOptionItem.push({
              label: category.dtlCodeDscr,
              value: category.dtlCodeId,
            });
          }
          // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
          setFaqDetailCategorySelect(data.data.list[0].dtlCodeId);
          setFaqDetailCategorySelectItem(tmpOptionItem);
          // setFaqDetailCategorySelect({
          //   value: data.data.list[0].dtlCodeId,
          //   item: tmpOptionItem,
          // });
        }
      });
  };

  /**************************** STATE ****************************/
  const [faqTitleInput, setFaqTitleInput] = useState<string>(''); //FAQ 제목
  const faqTitleInputRef = useRef<HTMLInputElement>(null);

  const [faqOrderInput, setFaqOrderInput] = useState<string>('1'); //노출순위
  const [faqCategorySelect, setFaqCategorySelect] = useState<string>('');
  const [faqCategorySelectItem, setFaqCategorySelectItem] = useState<
    IOptionItem[]
  >([]);

  const [faqDetailCategorySelect, setFaqDetailCategorySelect] =
    useState<string>('');
  const [faqDetailCategorySelectItem, setFaqDetailCategorySelectItem] =
    useState<IOptionItem[]>([]);

  const [faqCategoryInput, setFaqCategoryInput] = useState<string>(''); //FAQ 유형
  const [faqDetailCategoryInput, setFaqDetailCategoryInput] =
    useState<string>(''); //FAQ 상세 유형

  const [faqOpenRadio, setFaqOpenRadio] = useState<string>('Y'); //FAQ 노출 라디오
  const [faqOpenInput, setFaqOpenInput] = useState<string>(''); //FAQ 노출

  const [faqRgsterInput, setFaqRgsterInput] = useState<string>(''); //FAQ 등록자
  const [faqAnswerInput, setFaqAnswerInput] = useState<string>(''); //FAQ 내용

  const [faqRgstDate, setFaqRgstDate] = useState<string>(''); //등록일
  const [faqModifyDate, setFaqModifyDate] = useState<string>(''); //수정일

  const [isInitial, setIsInitial] = useState<boolean>(true);

  // 에디터의 크기를 정의함
  const {
    ref: editorRef,
    width: editorWidth,
    height: editorHeight,
  } = useElementSize();

  /**************************** ON EVENT LISTENER ****************************/
  //FAQ 제목 이벤트
  const handleFaqTitleInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 50) {
      setFaqTitleInput((pre) => pre);
    } else {
      setFaqTitleInput(sVal);
    }
  };
  //FAQ 노출 순위 이벤트
  const handleFaqOrderInput_onChange = (event: any) => {
    var sVal = event;
    var byteLength = getStringByteLength(String(sVal));
    if (byteLength > 2) {
      setFaqOrderInput((pre) => pre);
    } else {
      setFaqOrderInput(sVal);
    }
  };
  //FAQ 노출 라디오 변경 이벤트
  const handleFaqOpenRadio_onChange = (event: any) => {
    setFaqOpenRadio(event);
  };
  //FAQ 유형 셀렉트 변경 이벤트
  const handleFaqCategorySelect_onChange = (event: any) => {
    setFaqCategorySelect(event);
    // setFaqCategorySelect({
    //   ...faqCategorySelect,
    //   value: event,
    // });
  };
  //FAQ 상세 유형 셀렉트 변경 이벤트
  const handleFaqDetailCategorySelect_onChange = (event: any) => {
    setFaqDetailCategorySelect(event);
    // setFaqDetailCategorySelect({
    //   ...faqDetailCategorySelect,
    //   value: event,
    // });
  };
  //FAQ 내용
  const handleFaqAnswerInput_onChange = (event: any) => {
    setFaqAnswerInput(event.target.value);
  };

  //수정 전환 버튼 이벤트
  const handleModifyBtn_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);
    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();
      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: 'FAQ 수정',
        content: <MN2302220713 id={tmpId} brdId={brdId} useModifyMode={true} />,
        size: 1500,
      });
    }, 100);
  };

  //신규 저장 버튼 on click
  const handleAddSaveBtn_onClick = () => {
    //필수값 여부 확인
    if (!faqTitleInput.trim()) {
      setModal({
        title: '알림',
        content: '제목을 입력해 주세요.',
      });

      faqTitleInputRef.current?.focus();
      return;
    }
    // if (+faqOrderInput === 0) {
    //   setModal({
    //     title: '알림',
    //     content: '노출 순위를 입력해 주십시오.',
    //   });
    //   return;
    // }
    if (!faqAnswerInput.trim()) {
      setModal({
        title: '알림',
        content: 'FAQ 답변을 입력해 주세요.',
      });

      let obj = document.querySelector(
        '.faq-answer-input .ck-content',
      ) as HTMLDivElement;
      obj.focus();

      return;
    }

    setModal({
      title: '확인',
      content: '저장 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              //협력사 등록 API Call
              saveAddFaq();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  //삭제 버튼 이벤트
  const handleDeleteBtn_onClick = () => {
    setModal({
      title: '확인',
      content: `FAQ [${faqTitleInput}] 를 삭제 하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              //협력사 정보 삭제
              deleteFaq();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  //수정 저장 버튼 on click
  const handleModifySaveBtn_onClick = () => {
    //필수값 여부 확인
    if (!faqTitleInput.trim()) {
      setModal({
        title: '알림',
        content: '제목을 입력해 주세요.',
      });

      faqTitleInputRef.current?.focus();
      return;
    }
    // if (+faqOrderInput === 0) {
    //   setModal({
    //     title: '알림',
    //     content: '노출 순위를 입력해 주십시오.',
    //   });
    //   return;
    // }
    if (!faqAnswerInput.trim()) {
      setModal({
        title: '알림',
        content: 'FAQ 답변을 입력해 주세요.',
      });

      let obj = document.querySelector(
        '.faq-answer-input .ck-content',
      ) as HTMLDivElement;
      obj.focus();

      return;
    }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              //협력사 저장 API Call
              saveModifyFaq();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  // 에디터의 값이 변경됨
  const handleHetmlEditor_onChange = (event: any) => {
    setFaqAnswerInput(event);
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    getFaqCategory();
    return () => {};
  }, []);

  // FAQ 유형 셀렉트박스 변경 이벤트 -> FAQ 상세 유형 셀렉트 초기화
  useEffect(() => {
    if (faqCategorySelect !== '') {
      getFaqDetailCategory();
    }
    return () => {};
  }, [faqCategorySelect]);

  // FAQ 상세 유형 셀렉트 박스 초기화
  useEffect(() => {
    if (brdId && isInitial) {
      getFaqDetail(brdId);
      if (faqDetailCategorySelectItem.length !== 0) {
        setIsInitial(false);
      }
    }

    return () => {};
  }, [faqDetailCategorySelectItem]);

  /**************************** 기타 Function ****************************/
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* FAQ 정보 폼 그리드 */}
        <PbFormGrid label="FAQ 정보" cols={2}>
          <PbFormGridCol label="제목" colSpan={2} withAsterisk={true}>
            <TextInput
              ref={faqTitleInputRef}
              placeholder="제목을 입력하세요."
              onChange={handleFaqTitleInput_onChange}
              readOnly={brdId && !useModifyMode ? true : false}
              variant={brdId && !useModifyMode ? 'unstyled' : 'default'}
              value={decode(faqTitleInput)}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="유형">
            {brdId && !useModifyMode && (
              // <>
              //   <TextInput
              //     placeholder=""
              //     value={faqCategoryInput}
              //     readOnly={brdId && !useModifyMode ? true : false}
              //     variant={brdId && !useModifyMode ? 'unstyled' : 'default'}
              //     className="w-full"
              //   />
              //   2
              //   <TextInput
              //     placeholder=""
              //     value={faqDetailCategoryInput}
              //     readOnly={brdId && !useModifyMode ? true : false}
              //     variant={brdId && !useModifyMode ? 'unstyled' : 'default'}
              //     className="w-full"
              //   />
              // </>

              <div className="flex justify-center items-center space-x-1 whitespace-nowrap">
                <span className="mantine-1umturj">{faqCategoryInput}</span>
                <span className="mantine-1umturj">/</span>
                <span className="mantine-1umturj">
                  {faqDetailCategoryInput}
                </span>
              </div>
            )}
            {(!brdId || useModifyMode) && (
              <>
                <Select
                  onChange={handleFaqCategorySelect_onChange}
                  data={faqCategorySelectItem}
                  value={faqCategorySelect}
                  className="w-full"
                />
                <Select
                  onChange={handleFaqDetailCategorySelect_onChange}
                  data={faqDetailCategorySelectItem}
                  value={faqDetailCategorySelect}
                  className="w-full"
                />
              </>
            )}
          </PbFormGridCol>
          <PbFormGridCol label="노출 순위" withAsterisk={true}>
            <NumberInput
              placeholder=""
              onChange={handleFaqOrderInput_onChange}
              value={+faqOrderInput}
              readOnly={brdId && !useModifyMode ? true : false}
              variant={brdId && !useModifyMode ? 'unstyled' : 'default'}
              min={1}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="등록자">
            <TextInput
              placeholder=""
              value={!brdId ? loginUser.name : faqRgsterInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="노출 상태">
            {brdId && !useModifyMode && (
              <TextInput
                placeholder=""
                value={faqOpenInput}
                className="w-full"
                readOnly={true}
                variant="unstyled"
              />
            )}
            {(!brdId || useModifyMode) && (
              <Radio.Group
                size="sm"
                name="prtnCategory"
                onChange={handleFaqOpenRadio_onChange}
                value={faqOpenRadio}
                className="w-full"
              >
                <Group>
                  <Radio value="Y" label="공개" />
                  <Radio value="N" label="비공개" />
                </Group>
              </Radio.Group>
            )}
          </PbFormGridCol>
          {/*<PbFormGridCol*/}
          {/*  label="FAQ 답변"*/}
          {/*  colSpan={2}*/}
          {/*  className="h-96"*/}
          {/*  withAsterisk={true}*/}
          {/*>*/}
          {/*  /!* 텍스트 영역 *!/*/}
          {/*  <Textarea*/}
          {/*    minRows={15}*/}
          {/*    className="w-full"*/}
          {/*    value={faqAnswerInput}*/}
          {/*    onChange={handleFaqAnswerInput_onChange}*/}
          {/*    readOnly={brdId && !useModifyMode ? true : false}*/}
          {/*    variant={brdId && !useModifyMode ? 'unstyled' : 'default'}*/}
          {/*  />*/}
          {/*</PbFormGridCol>*/}

          {/* FAQ 답변 */}
          {/* 등록 */}
          {!brdId && (
            <PbFormGridCol
              label="FAQ 답변"
              colSpan={2}
              withAsterisk={true}
              className="h-124"
            >
              {/* 에디터 */}
              <div ref={editorRef} className="faq-answer-input w-full h-full">
                <HtmlEditor
                  data={faqAnswerInput}
                  // width={editorWidth}
                  width={1056}
                  height={375}
                  callback={handleHetmlEditor_onChange}
                />
              </div>
            </PbFormGridCol>
          )}

          {/* FAQ 답변 */}
          {/* 수정 */}
          {brdId && useModifyMode && (
            <PbFormGridCol label="FAQ 답변" colSpan={2} className="h-124">
              {/* 에디터 */}
              <div ref={editorRef} className="w-full h-full">
                {editorWidth > 0 && faqAnswerInput && (
                  <HtmlEditor
                    data={faqAnswerInput}
                    width={editorWidth}
                    height={375}
                    callback={handleHetmlEditor_onChange}
                  />
                )}
              </div>
            </PbFormGridCol>
          )}

          {/* FAQ 답변 */}
          {/* 조회 */}
          {brdId && !useModifyMode && (
            <PbFormGridCol label="FAQ 답변" colSpan={2} className="">
              {/* HTML 뷰어 */}
              <div dangerouslySetInnerHTML={{ __html: faqAnswerInput }} />
            </PbFormGridCol>
          )}

          {brdId && (
            <>
              <PbFormGridCol label="등록일">
                <TextInput
                  placeholder=""
                  value={faqRgstDate}
                  readOnly={true}
                  variant="unstyled"
                  className="w-full"
                />
              </PbFormGridCol>
              <PbFormGridCol label="수정일">
                <TextInput
                  placeholder=""
                  value={faqModifyDate}
                  readOnly={true}
                  variant="unstyled"
                  className="w-full"
                />
              </PbFormGridCol>
            </>
          )}
        </PbFormGrid>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        {!brdId && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleAddSaveBtn_onClick}
              >
                저장
              </Button>
            </div>
          </>
        )}
        {brdId && !useModifyMode && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleModifyBtn_onClick}
              >
                수정 전환
              </Button>
            </div>
          </>
        )}
        {brdId && useModifyMode && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                variant="outline"
                color="pink"
                radius="xl"
                size="md"
                onClick={handleDeleteBtn_onClick}
              >
                삭제
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleModifySaveBtn_onClick}
              >
                저장
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220713;
