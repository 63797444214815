import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

/**
 * 다국어
 * @param props
 * @constructor
 */
const Language: React.FC<any> = (props: any) => {
  const [language, setLanguage] = useState<string>('en');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (!language) {
      return;
    }

    (async () => {
      await i18n.changeLanguage(language);
    })();

    return () => {};
  }, [language]);

  return (
    <div className="mt-10">
      <div className="flex justify-center items-center">
        <div className="space-y-2">
          <div className="flex justify-center items-center">
            <div>다국어 테스트</div>
          </div>

          <div className="flex justify-center items-center space-x-2">
            <div
              onClick={() => setLanguage('en')}
              className="button-event px-4 py-1 flex justify-center items-center bg-blue-600 rounded select-none"
            >
              <span className="text-sm text-white font-bold">English</span>
            </div>

            <div
              onClick={() => setLanguage('kr')}
              className="button-event px-3 py-1 flex justify-center items-center bg-blue-600 rounded select-none"
            >
              <span className="text-sm text-white font-bold">한국어</span>
            </div>

            <div
              onClick={() => setLanguage('jp')}
              className="button-event px-3 py-1 flex justify-center items-center bg-blue-600 rounded select-none"
            >
              <span className="text-sm text-white font-bold">日本語</span>
            </div>
          </div>

          <div className="flex justify-center items-center">
            <div>
              <div>1. {t('title', { name: 'Google' })}</div>
              <div>2. {t('description.part1')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Language;
