import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Select, TextInput } from '@mantine/core';
import { DatePickerInput, MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IModal,
  IComponentModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { pageContentStore } from '../stores/page.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { addComponentModalStore } from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import MN2302220202 from '../components/MN2302220202/MN2302220202.component';
import * as slsApi from '../apis/sls.api';
import * as cmnApi from '../apis/cmn.api';
import { MN2302220204 } from '../components/MN2302220204';
import { LoadingTableDataIcon } from '../components/LoadingTableDataIcon';
import { PbAgGridReact } from '../components/PbAgGridReact';
import PbSelect from '../components/PbSelect/PbSelect.component';
import { useLocation } from 'react-router';
import { ValueFormatterParams } from 'ag-grid-community';
import { decode, encode } from 'html-entities';

/**
 * 영업 관리 > 영업 활동 조회
 * @constructor
 */
const MN2302220205Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 주소줄의 인자를 정의함
  const location = useLocation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 검색 폼 그리드 > 검색어 > 셀렉트를 정의함
  // const [searchCategorySelect, setSearchCategorySelect] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const searchCategorySelectRef = useRef<string>('');
  const [searchCategorySelect, setSearchCategorySelect] = useState<string>('');
  const [searchCategorySelectItem, setSearchCategorySelectItem] = useState<
    IOptionItem[]
  >([]);

  // 검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');
  const searchInputRef = useRef<string>('');

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  // 검색 폼 그리드 > 등록기간 > 날짜 피커를 정의함
  const [searchRegDatePicker, setSearchRegDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  // 검색 폼 그리드 > 영업활동기간 > 날짜 피커를 정의함
  const [searchSalesWorkDatePicker, setSearchSalesWorkDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  // 검색 폼 그리드 > 중요도구분 > 셀렉트를 정의함
  // const [searchImportantTypeSelect, setSearchImportantTypeSelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });
  const [searchImportantTypeSelect, setSearchImportantTypeSelect] =
    useState<string>('');
  const [searchImportantTypeSelectItem, setSearchImportantTypeSelectItem] =
    useState<IOptionItem[]>([]);

  // 검색 폼 그리드 > 영업단계구분 > 셀렉트를 정의함
  // const [searchSalesStepTypeSelect, setSearchSalesStepTypeSelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });
  const [searchSalesStepTypeSelect, setSearchSalesStepTypeSelect] =
    useState<string>('');
  const [searchSalesStepTypeSelectItem, setSearchSalesStepTypeSelectItem] =
    useState<IOptionItem[]>([]);

  // 검색 폼 그리드 > 확도 > 셀렉트를 정의함
  // const [searchProbabilitySelect, setSearchProbabilitySelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });
  const [searchProbabilitySelect, setSearchProbabilitySelect] =
    useState<string>('');
  const [searchProbabilitySelectItem, setSearchProbabilitySelectItem] =
    useState<IOptionItem[]>([]);

  // 검색 폼 그리드 > 제안결과구분 > 셀렉트를 정의함
  // const [searchProposalResultTypeSelect, setSearchProposalResultTypeSelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });
  const [searchProposalResultTypeSelect, setSearchProposalResultTypeSelect] =
    useState<string>('');
  const [
    searchProposalResultTypeSelectItem,
    setSearchProposalResultTypeSelectItem,
  ] = useState<IOptionItem[]>([]);

  // 검색 폼 그리드 > 파일여부 > 셀렉트를 정의함
  // const [searchFileExistSelect, setSearchFileExistSelect] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [searchFileExistSelect, setSearchFileExistSelect] =
    useState<string>('');
  const [searchFileExistSelectItem, setSearchFileExistSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 검색 폼 그리드 > 태그 > 입력을 정의함
  const [searchTagInput, setSearchTagInput] = useState<string>('');

  // 검색 결과 테이블 > 정렬 셀렉트를 정의함
  // const [searchResultTableSortSelect, setSearchResultTableSortSelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });
  const [searchResultTableSortSelect, setSearchResultTableSortSelect] =
    useState<string>('');
  const [searchResultTableSortSelectItem, setSearchResultTableSortSelectItem] =
    useState<IOptionItem[]>([]);

  // 검색 결과 테이블을 정의함
  const [searchResultTable, setSearchResultTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 검색 결과 테이블 페이징을 정의함
  const [searchResultTablePaging, setSearchResultTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const searchResultTablePagingCurrentPageRef = useRef<number>(1);

  // 검색 결과 테이블의 로딩 여부를 정의함
  const [searchResultTableLoading, setSearchResultTableLoading] =
    useState<boolean>(false);

  // 테이블의 참조를 정의함
  const searchResultTableRef = useRef<any>(null);

  // 영업 기회 조회 페이지로부터 받은 영업 기회 코드를 정의함
  const searchIdRef = useRef<string>('');

  // 테이블의 컬럼 크기를 조정함
  const handleSearchResultTable_onGridSizeChanged = useCallback(() => {
    searchResultTableRef.current.api.sizeColumnsToFit();
  }, []);

  // 페이지 상단바 > 등록 버튼을 클릭함
  const handleRegButton_onChange = () => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '영업 활동 등록',
      // content: <MN2302220204 id={tmpId} />,
      content: <MN2302220204 id={tmpId} initSlsPrtnId={searchIdRef.current} />,
      size: 1500,
    });
  };

  // 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCategorySelect_onChange = (event: any) => {
    // setSearchCategorySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
    setSearchCategorySelect(event);
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchInput(event.currentTarget.value);
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력에서 키를 입력함
  const handleSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
  const handleSearchButton_onChange = () => {
    // 테이블의 페이지를 변경함
    searchResultTablePagingCurrentPageRef.current = 1;
    setSearchResultTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: searchResultTablePagingCurrentPageRef.current,
    }));

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();
  };

  // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
  const handleSearchCancelButton_onChange = () => {
    // 검색 폼 그리드 > 검색어 > 셀렉트 값을 초기화함
    setSearchCategorySelect('sls_prtn_name');
    // setSearchCategorySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: 'bp_name',
    // }));

    // 검색 폼 그리드 > 검색어 > 검색어 입력 값을 초기화함
    setSearchInput('');

    // 검색 폼 그리드 > 등록기간 > 날짜 피커 값을 초기화함
    setSearchRegDatePicker([null, null]);

    // 검색 폼 그리드 > 영업활동기간 > 날짜 피커 값을 초기화함
    setSearchSalesWorkDatePicker([null, null]);

    // 검색 폼 그리드 > 중요도구분 > 셀렉트 값을 초기화함
    setSearchImportantTypeSelect('');
    // setSearchImportantTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: '',
    // }));

    // 검색 폼 그리드 > 영업단계구분 > 셀렉트 값을 초기화함
    setSearchSalesStepTypeSelect('');
    // setSearchSalesStepTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: '',
    // }));

    // 검색 폼 그리드 > 확도 > 셀렉트 값을 초기화함
    setSearchProbabilitySelect('');
    // setSearchProbabilitySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: '',
    // }));

    // 검색 폼 그리드 > 제안결과구분 > 셀렉트 값을 초기화함
    setSearchProposalResultTypeSelect('');
    // setSearchProposalResultTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: '',
    // }));

    // 검색 폼 그리드 > 태그 > 입력 값을 초기화함
    setSearchTagInput('');

    // 검색 폼 그리드 > 파일여부 > 셀렉트 값을 초기화함
    setSearchFileExistSelect('');
  };

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    setHideDetailSearch(!hideDetailSearch);
  };

  // 검색 폼 그리드 > 등록기간 > 날짜 피커를 변경함
  const handleSearchRegDatePicker_onChange = (event: any) => {
    setSearchRegDatePicker(event);
  };

  // 검색 폼 그리드 > 영업활동기간 > 날짜 피커를 변경함
  const handleSearchSalesWorkDatePicker_onChange = (event: any) => {
    setSearchSalesWorkDatePicker(event);
  };

  // 검색 폼 그리드 > 중요도구분 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchImportantTypeSelect_onChange = (event: any) => {
    setSearchImportantTypeSelect(event);
    // setSearchImportantTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 검색 폼 그리드 > 영업단계구분 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchSalesStepTypeSelect_onChange = (event: any) => {
    setSearchSalesStepTypeSelect(event);
    // setSearchSalesStepTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 검색 폼 그리드 > 확도 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchProbabilitySelect_onChange = (event: any) => {
    setSearchProbabilitySelect(event);
    // setSearchProbabilitySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 검색 폼 그리드 > 제안결과구분 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchProposalResultTypeSelect_onChange = (event: any) => {
    setSearchProposalResultTypeSelect(event);
    // setSearchProposalResultTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 검색 폼 그리드 > 파일여부 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchFileExistSelect_onChange = (event: any) => {
    setSearchFileExistSelect(event);
    // setSearchFileExistSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 검색 폼 그리드 > 태그 > 입력의 값을 변경함
  const handleSearchTagInput_onChange = (event: any) => {
    setSearchTagInput(event.currentTarget.value);
  };

  // 검색 폼 그리드 > 태그 > 입력에서 키를 입력함
  const handleSearchTagInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 검색 결과 테이블의 엑셀 다운로드 버튼을 클릭함
  const handleSearchResultTableExportExcelButton_onClick = () => {
    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <>
          {/* 버튼 */}
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              slsApi
                .getSlsCtvtExcel({
                  searchKeyword: decode(searchInput.trim()),
                  searchType: searchCategorySelect,
                  rgstStDt: searchRegDatePicker[0]
                    ? moment(searchRegDatePicker[0]).format('YYYYMMDD')
                    : '',
                  rgstEndDt: searchRegDatePicker[1]
                    ? moment(searchRegDatePicker[1]).format('YYYYMMDD')
                    : '',
                  ctvtStartDt: searchSalesWorkDatePicker[0]
                    ? moment(searchSalesWorkDatePicker[0]).format('YYYYMMDD')
                    : '',
                  ctvtEndDt: searchSalesWorkDatePicker[1]
                    ? moment(searchSalesWorkDatePicker[1]).format('YYYYMMDD')
                    : '',
                  slsMprtCode: searchImportantTypeSelect,
                  slsStgCode: searchSalesStepTypeSelect,
                  slsPrbbCode: searchProbabilitySelect,
                  sgstRsltCode: searchProposalResultTypeSelect,
                  fileYn: searchFileExistSelect,
                  tag: searchTagInput.trim(),
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    ['영업기회_', moment().format('YYYYMMDD'), '.xlsx'].join(
                      '',
                    ),
                  );
                  document.body.appendChild(link);
                  link.click();
                });

              setRemoveModal(true);
            }}
          >
            예
          </Button>
        </>
      ),
    });
  };

  // 검색 결과 테이블 > 정렬 셀렉트에서 선택한 아이템을 변경함
  const handleSearchResultTableSortSelect_onChange = (event: any) => {
    setSearchResultTableSortSelect(event);
    // setSearchResultTableSortSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));

    // 정렬 순서를 정의함
    let tmpSortType: string[] = event.split(',');

    // 데이터를 정렬함
    let tmpData: any[] = _.orderBy(
      searchResultTable.data,
      [tmpSortType[0]],
      [tmpSortType[1] === 'desc' ? 'desc' : 'asc'],
    );

    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmpData,
    }));
  };

  // 검색 결과 테이블의 행을 클릭함
  const handleSearchResultTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220204';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '영업 활동 상세',
      content: <MN2302220204 id={tmpId} slsCtvtId={row.data.slsCtvtId} />,
      size: 1500,
    });
  };

  // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
  const initSearchCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: 'Project Name',
        value: 'sls_prtn_name',
      },
      {
        label: '영업담당자명',
        value: 'ntrn_cntc_name',
      },
      {
        label: '영업담당자닉네임',
        value: 'nick_name',
      },
      {
        label: '태그',
        value: 'tag',
      },
    );

    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    setSearchCategorySelect('sls_prtn_name');
    setSearchCategorySelectItem(tmpOptionItem);
    // setSearchCategorySelect((pre: ISelect) => ({
    //   value: 'bp_name',
    //   item: tmpOptionItem,
    // }));
  };

  // 검색 폼 그리드 > 중요도구분 > 셀렉트의 아이템을 초기화함
  const initSearchImportantTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(영업 중요도)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'SLS_MPRT' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 중요도구분 > 셀렉트의 아이템에 적용함
        setSearchImportantTypeSelectItem(tmpOptionItem);
        // setSearchImportantTypeSelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // 검색 폼 그리드 > 영업단계구분 > 셀렉트의 아이템을 초기화함
  const initSearchSalesStepTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(영업단계구분)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'SLS_STG' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 영업단계구분 > 셀렉트의 아이템에 적용함
        setSearchSalesStepTypeSelectItem(tmpOptionItem);
        // setSearchSalesStepTypeSelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // 검색 폼 그리드 > 확도 > 셀렉트의 아이템을 초기화함
  const initSearchProbabilitySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(확도)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'SLS_CRTN' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 확도 > 셀렉트의 아이템에 적용함
        setSearchProbabilitySelectItem(tmpOptionItem);
        // setSearchProbabilitySelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // 검색 폼 그리드 > 제안결과구분 > 셀렉트의 아이템을 초기화함
  const initSearchProposalResultTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(제안결과구분)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'PRPS_RSLT' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 제안결과구분 > 셀렉트의 아이템에 적용함
        setSearchProposalResultTypeSelectItem(tmpOptionItem);
        // setSearchProposalResultTypeSelect((pre: ISelect) => ({
        //   ...pre,
        //   item: tmpOptionItem,
        // }));
      });
  };

  // 검색 폼 그리드 > 파일여부 > 셀렉트의 아이템을 초기화함
  const initSearchFileExistSelectSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '파일포함',
        value: 'Y',
      },
      {
        label: '파일미포함',
        value: 'N',
      },
    );

    // 검색 폼 그리드 > 파일여부 > 셀렉트의 아이템에 적용함
    setSearchFileExistSelectItem(tmpOptionItem);
    // setSearchFileExistSelect((pre: ISelect) => ({
    //   ...pre,
    //   item: tmpOptionItem,
    // }));
  };

  // 검색 결과 테이블 > 정렬 셀렉트의 아이템을 초기화함
  const initSearchResultTableSortSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '정렬 순서 선택',
        value: 'no,desc',
      },
      {
        label: '최근 등록일부터',
        value: 'rgstDate,desc',
      },
      {
        label: '예전 등록일부터',
        value: 'rgstDate,asc',
      },
    );

    // 검색 결과 테이블 > 정렬 셀렉트의 아이템에 적용함
    setSearchResultTableSortSelect('no,desc');
    setSearchResultTableSortSelectItem(tmpOptionItem);
    // setSearchResultTableSortSelect((pre: ISelect) => ({
    //   value: 'no,desc',
    //   item: tmpOptionItem,
    // }));
  };

  // 검색 결과 테이블을 초기화함
  const initSearchResultTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'no',
        headerName: 'NO',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
      },
      {
        field: 'slsPrtnId',
        headerName: '영업기회번호',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '영업 활동 정보',
        children: [
          {
            headerName: 'Market Type',
            field: 'mrktTypeName',
            width: 130,
            suppressSizeToFit: true,
          },
          {
            headerName: 'Project Name',
            field: 'slsPrtnName',
            minWidth: 250,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.data.slsPrtnName);
            },
          },
          {
            headerName: '담당자',
            field: 'name',
            width: 80,
            suppressSizeToFit: true,
          },
        ],
      },
      {
        headerName: '영업 내용',
        children: [
          {
            headerName: '영업활동일',
            field: 'slsCtvtDate',
            width: 120,
            suppressSizeToFit: true,
          },
          {
            headerName: '중요도',
            field: 'slsMprtName',
            width: 80,
            suppressSizeToFit: true,
          },
        ],
      },
      {
        headerName: '영업 활동 정보',
        children: [
          {
            headerName: '영업단계',
            field: 'slsStgName',
            width: 130,
            suppressSizeToFit: true,
          },
          {
            headerName: '확도',
            field: 'slsPrbbName',
            width: 80,
            suppressSizeToFit: true,
            type: 'rightAligned',
          },
          {
            headerName: '제안결과',
            field: 'prpsRslt',
            width: 130,
            suppressSizeToFit: true,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.data.prpsRslt);
            },
          },
        ],
      },
      {
        headerName: '영업 내용',
        children: [
          {
            headerName: '내용',
            field: 'slsDtls',
            width: 170,
            suppressSizeToFit: true,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.data.slsDtls);
            },
          },
        ],
      },
      {
        headerName: '첨부파일 정보',
        children: [
          {
            headerName: '파일여부',
            field: 'fileYn',
            valueFormatter: (param: any) =>
              param.value === 'Y' ? '포함' : '미포함',
            width: 120,
            suppressSizeToFit: true,
          },
        ],
      },
      {
        headerName: '등록 정보',
        children: [
          {
            headerName: '등록자',
            field: 'rgstName',
            width: 80,
            suppressSizeToFit: true,
          },
          {
            headerName: '등록일시',
            field: 'rgstDate',
            width: 120,
            suppressSizeToFit: true,
          },
        ],
      },
    );

    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 검색 결과 테이블 데이터를 불러옴
  const getSearchResultTableData = () => {
    // 검색 결과 테이블의 로딩 여부에 적용함
    setSearchResultTableLoading(true);

    slsApi
      .getSlsCtvts({
        searchKeyword: decode(searchInput.trim()),
        searchType: searchCategorySelect,
        rgstStDt: searchRegDatePicker[0]
          ? moment(searchRegDatePicker[0]).format('YYYYMMDD')
          : '',
        rgstEndDt: searchRegDatePicker[1]
          ? moment(searchRegDatePicker[1]).format('YYYYMMDD')
          : '',
        ctvtStartDt: searchSalesWorkDatePicker[0]
          ? moment(searchSalesWorkDatePicker[0]).format('YYYYMMDD')
          : '',
        ctvtEndDt: searchSalesWorkDatePicker[1]
          ? moment(searchSalesWorkDatePicker[1]).format('YYYYMMDD')
          : '',
        slsMprtCode: searchImportantTypeSelect,
        slsStgCode: searchSalesStepTypeSelect,
        slsPrbbCode: searchProbabilitySelect,
        sgstRsltCode: searchProposalResultTypeSelect,
        fileYn: searchFileExistSelect,
        tag: searchTagInput.trim(),
        pageSize: searchResultTablePaging.rowPerPage,
        currPageIdx: searchResultTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setSearchResultTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
            }),
          );
        }

        // 테이블을 페이징함
        setSearchResultTablePaging((pre: ITablePaging) => ({
          ...pre,
          totalPage: data.data.page.totPageCnt,
          totalRow: data.data.page.totItemCnt,
        }));

        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      })
      .catch((error: any) => {
        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
    initSearchCategorySelect();

    // 검색 폼 그리드 > 중요도구분 > 셀렉트의 아이템을 초기화함
    initSearchImportantTypeSelect();

    // 검색 폼 그리드 > 영업단계구분 > 셀렉트의 아이템을 초기화함
    initSearchSalesStepTypeSelect();

    // 검색 폼 그리드 > 확도 > 셀렉트의 아이템을 초기화함
    initSearchProbabilitySelect();

    // 검색 폼 그리드 > 제안결과구분 > 셀렉트의 아이템을 초기화함
    initSearchProposalResultTypeSelect();

    // 검색 폼 그리드 > 파일여부 > 셀렉트의 아이템을 초기화함
    initSearchFileExistSelectSelect();

    // 검색 결과 테이블 > 정렬 셀렉트의 아이템을 초기화함
    //initSearchResultTableSortSelect();

    // 검색 결과 테이블을 초기화함
    initSearchResultTable();

    return () => {};
  }, []);

  // 검색 결과 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (searchResultTable.column.length === 0) {
      return;
    }

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();

    return () => {};
  }, [searchResultTable.column]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220205Table') > -1) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220205Table');
    }

    return () => {};
  }, [refreshList]);

  // 주소줄의 인자가 변경될 때 실행함
  useEffect(() => {
    if (location === undefined || location === null) {
      return;
    }

    const params = new URLSearchParams(location.search);
    const searchCategory = params.get('search-category') || '';
    const search = params.get('search') || '';

    // 영업 기회 조회 페이지로부터 받은 영업 기회 코드에 적용함
    searchIdRef.current = params.get('search-id') || '';

    // 검색 폼 그리드 > 검색어 > 셀렉트의 값에 적용함
    if (searchCategory !== '') {
      setSearchCategorySelect(searchCategory);
      // setSearchCategorySelect((pre: ISelect) => ({
      //   ...pre,
      //   value: searchCategory,
      // }));
    }

    // 검색 폼 그리드 > 검색어 > 검색어 입력에 적용함
    if (search !== '') {
      setSearchInput(search);
    }

    return () => {};
  }, [location]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={
        <>
          {/* 버튼 */}
          <Button color="indigo" radius="xl" onClick={handleRegButton_onChange}>
            등록
          </Button>
        </>
      }
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-10">
        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid cols={2}>
            <PbFormGridCol label="검색어" colSpan={2}>
              {/* 셀렉트 */}
              <Select
                onChange={handleSearchCategorySelect_onChange}
                data={searchCategorySelectItem}
                value={searchCategorySelect}
              />
              {/*<PbSelect*/}
              {/*  onChange={handleSearchCategorySelect_onChange}*/}
              {/*  data={searchCategorySelect.item}*/}
              {/*  value={searchCategorySelect.value}*/}
              {/*  setSelect={setSearchCategorySelect}*/}
              {/*/>*/}

              {/* 텍스트 입력 */}
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={handleSearchInput_onChange}
                onKeyUp={handleSearchInput_onKeyUp}
                value={searchInput}
                className="w-1/5"
              />

              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleSearchButton_onChange}
                >
                  검색
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchCancelButton_onChange}
                >
                  검색 조건 초기화
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleShowSearchDetailButton_onChange}
                >
                  {hideDetailSearch ? (
                    <>
                      상세검색 열기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-down']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      상세검색 닫기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-up']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  )}
                </Button>
              </div>
            </PbFormGridCol>
            <PbFormGridCol label="등록기간" isHidden={hideDetailSearch}>
              {/* 날짜 피커 */}
              <DatePickerInput
                type="range"
                placeholder="기간을 선택하세요."
                onChange={handleSearchRegDatePicker_onChange}
                value={searchRegDatePicker}
                valueFormat="YYYY.MM.DD"
                locale="ko"
                firstDayOfWeek={0}
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="중요도구분" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <Select
                onChange={handleSearchImportantTypeSelect_onChange}
                data={searchImportantTypeSelectItem}
                value={searchImportantTypeSelect}
                className="w-full"
              />
              {/*<PbSelect*/}
              {/*  onChange={handleSearchImportantTypeSelect_onChange}*/}
              {/*  data={searchImportantTypeSelectItem}*/}
              {/*  value={searchImportantTypeSelect}*/}
              {/*  setSelect={setSearchImportantTypeSelect}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
              {/*<PbSelect*/}
              {/*  onChange={handleSearchImportantTypeSelect_onChange}*/}
              {/*  data={searchImportantTypeSelect.item}*/}
              {/*  value={searchImportantTypeSelect.value}*/}
              {/*  setSelect={setSearchImportantTypeSelect}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
            </PbFormGridCol>
            <PbFormGridCol label="영업활동기간" isHidden={hideDetailSearch}>
              {/* 날짜 피커 */}
              <DatePickerInput
                type="range"
                placeholder="기간을 선택하세요."
                onChange={handleSearchSalesWorkDatePicker_onChange}
                value={searchSalesWorkDatePicker}
                valueFormat="YYYY.MM.DD"
                locale="ko"
                firstDayOfWeek={0}
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="영업단계구분" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <Select
                onChange={handleSearchSalesStepTypeSelect_onChange}
                data={searchSalesStepTypeSelectItem}
                value={searchSalesStepTypeSelect}
                className="w-full"
              />
              {/*<PbSelect*/}
              {/*  onChange={handleSearchSalesStepTypeSelect_onChange}*/}
              {/*  data={searchSalesStepTypeSelect.item}*/}
              {/*  value={searchSalesStepTypeSelect.value}*/}
              {/*  setSelect={setSearchSalesStepTypeSelect}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
              {/*<PbSelect*/}
              {/*  onChange={handleSearchSalesStepTypeSelect_onChange}*/}
              {/*  data={searchSalesStepTypeSelect.item}*/}
              {/*  value={searchSalesStepTypeSelect.value}*/}
              {/*  setSelect={setSearchSalesStepTypeSelect}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
            </PbFormGridCol>
            <PbFormGridCol label="확도" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <Select
                onChange={handleSearchProbabilitySelect_onChange}
                data={searchProbabilitySelectItem}
                value={searchProbabilitySelect}
                className="w-full"
              />
              {/*<PbSelect*/}
              {/*  onChange={handleSearchProbabilitySelect_onChange}*/}
              {/*  data={searchProbabilitySelect.item}*/}
              {/*  value={searchProbabilitySelect.value}*/}
              {/*  setSelect={setSearchProbabilitySelect}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
            </PbFormGridCol>
            <PbFormGridCol label="제안결과구분" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <Select
                onChange={handleSearchProposalResultTypeSelect_onChange}
                data={searchProposalResultTypeSelectItem}
                value={searchProposalResultTypeSelect}
                className="w-full"
              />
              {/*<PbSelect*/}
              {/*  onChange={handleSearchProposalResultTypeSelect_onChange}*/}
              {/*  data={searchProposalResultTypeSelect.item}*/}
              {/*  value={searchProposalResultTypeSelect.value}*/}
              {/*  setSelect={setSearchProposalResultTypeSelect}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
            </PbFormGridCol>
            <PbFormGridCol label="파일여부" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <Select
                onChange={handleSearchFileExistSelect_onChange}
                data={searchFileExistSelectItem}
                value={searchFileExistSelect}
                className="w-full"
              />
              {/*<PbSelect*/}
              {/*  onChange={handleSearchFileExistSelect_onChange}*/}
              {/*  data={searchFileExistSelect.item}*/}
              {/*  value={searchFileExistSelect.value}*/}
              {/*  setSelect={setSearchFileExistSelect}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
            </PbFormGridCol>
            <PbFormGridCol
              label=""
              isHidden={hideDetailSearch}
              isBlank={true}
            />
            {/*<PbFormGridCol label="태그" isHidden={hideDetailSearch}>*/}
            {/*  /!* 텍스트 입력 *!/*/}
            {/*  <TextInput*/}
            {/*    placeholder="태그를 입력하세요."*/}
            {/*    onChange={handleSearchTagInput_onChange}*/}
            {/*    onKeyUp={handleSearchTagInput_onKeyUp}*/}
            {/*    value={searchTagInput}*/}
            {/*    className="w-full"*/}
            {/*  />*/}
            {/*</PbFormGridCol>*/}
          </PbFormGrid>

          {/* 테이블 */}
          <div className="space-y-3">
            <div className="flex justify-between items-center space-x-5">
              <div>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchResultTableExportExcelButton_onClick}
                >
                  엑셀 다운로드
                </Button>
              </div>

              <div className="flex justify-center items-center space-x-5">
                <div className="flex justify-center items-center">
                  <span className="text-base text-gray-600 font-bold">
                    총 {searchResultTablePaging.totalRow}건
                  </span>
                </div>

                {/* 셀렉트 */}
                <div className="hidden">
                  {/* 셀렉트 */}
                  <Select
                    onChange={handleSearchResultTableSortSelect_onChange}
                    data={searchResultTableSortSelectItem}
                    value={searchResultTableSortSelect}
                  />
                  {/*<PbSelect*/}
                  {/*  onChange={handleSearchResultTableSortSelect_onChange}*/}
                  {/*  data={searchResultTableSortSelect.item}*/}
                  {/*  value={searchResultTableSortSelect.value}*/}
                  {/*  setSelect={setSearchResultTableSortSelect}*/}
                  {/*/>*/}
                </div>
              </div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-172">
              <PbAgGridReact
                columnDefs={searchResultTable.column}
                rowData={searchResultTable.data}
                loading={searchResultTableLoading}
                setLoading={setSearchResultTableLoading}
                onRowDoubleClicked={handleSearchResultTable_onRowDoubleClicked}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
                visiblePaging={true}
                paging={{
                  totalPage: searchResultTablePaging.totalPage,
                  currentPage: searchResultTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  searchResultTablePagingCurrentPageRef.current = event;
                  setSearchResultTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: searchResultTablePagingCurrentPageRef.current,
                  }));

                  // 검색 결과 테이블 데이터를 불러옴
                  getSearchResultTableData();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220205Page;
