import axios, { AxiosResponse } from 'axios';
import { IApiListResult, IApiResult } from '../interfaces/app.interface';
import api from '../utils/axios.util';
import * as appUtil from '../utils/app.util';

/**
 * NVNT: 팀 재고
 */

/**
 * 그룹 품목 재고 목록 조회
 * GET /api/nvnt/grps/items
 * @param currPageIdx - 현재페이지
 * @param grpSearchKeyword - 검색어(그룹명)
 * @param itemSearchKeyword - 검색어(품목명|품목코드)
 * @param pageSize - 한번에 보여질 갯수
 */
export const getGrpsItems = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nvnt/grps/items'].join('/'),
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 품목 별 그룹(팀)의 재고 상세 조회
 * GET /api/nvnt/teams/item/{itemNmbr}
 * @param itemNmbr - 품목코드
 */
export const getTeamsItemItemNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nvnt/teams/item', params.itemNmbr].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 품목 별 그룹(팀)의 재고 상세 조회
 * GET /api/nvnt/teams/item/{itemNmbr}/{wrhsCode}
 * @param itemNmbr - 품목코드
 */
export const getTeamsItemItemNmbrWrhsCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  console.log(params);

  await api({
    method: 'GET',
    url: ['/api/nvnt/teams/item', params.itemNmbr, params.wrhsCode].join('/'),
    params: {
      pmNspcStts: params.pmNspcStts,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 현장 재고 이력 조회
 * GET /api/nvnt/nvnt/hstr
 */
export const getNvntHstr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nvnt/nvnt/hstr'].join('/'),
    params: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};
