import React, { PropsWithChildren, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { IOptionItem } from '../../interfaces/app.interface';

interface IPbRadioProps {
  group: string;
  item: IOptionItem[];
  value: string;
  onChange?: (event: any) => void;
  disabled?: boolean;
}

/**
 * 라디오
 * @param group 라디오 그룹
 * @param item 아이템
 * @param value 값
 * @param onChange 변경 이벤트
 * @param disabled 비활성화 여부
 * @constructor
 */
const PbRadio = ({
  group,
  item,
  value,
  onChange,
  disabled = false,
}: PropsWithChildren<IPbRadioProps>) => {
  return (
    <div className="flex justify-start items-center space-x-3">
      {item?.map((item: IOptionItem, index: number) => {
        let itemId: string = nanoid();

        return (
          <div key={index}>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id={itemId}
                name={group}
                onChange={onChange}
                checked={item.value === value}
                value={item.value}
                disabled={disabled}
              />
              <label className="form-check-label" htmlFor={itemId}>
                {item.label}
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PbRadio;
