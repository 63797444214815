import React, { PropsWithChildren, useEffect, useState, useRef } from 'react';
import { PbAgGridReact } from '../PbAgGridReact';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  ITableColumn,
  IPageContent,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as nstlApi from '../../apis/nstl.api';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { Button } from '@mantine/core';
import { ValueFormatterParams } from 'ag-grid-community';

interface IComponentProps {
  id?: string;
  data?: any;
  onClick?: () => void;
}

/**
 * 설치 > PM검수대기 > 상세 > 네크워크 공사 작업내역 > 소프트웨어 정보
 * @params id 화면 아이디
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220353_SwInfo = ({
  id = '',
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {

  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  const [swInfoTable, setSwInfoTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  const initSwInfoTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      {
        field: 'ntwrWorkName',
        headerName: '구분',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        field: 'ttl',
        headerName: '내용',
      },
    );

    setSwInfoTable((pre: { column: any[]; data: any }) => ({
      column: tmpColumn,
      data: data,
    }));
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    initSwInfoTable();
    return () => {};
  }, []);

  return (
    <div className="space-y-7">
      <div className="space-y-5">
        <div className="w-full h-152">
          <PbAgGridReact
            columnDefs={swInfoTable.column}
            rowData={swInfoTable.data}
            defaultColDef={{
              resizable: true,
              sortable: false,
              wrapHeaderText: false,
              autoHeaderHeight: true,
            }}
            sizeColumnsToFit={true}
            rowSelection="single"
            overlayNoRowsTemplate="데이터가 없습니다."
            className="ag-theme-alpine"
          />
        </div>
      </div>
      <div className="component-modal-button-area">
        <div>
          <Button
            color="white"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          >
            닫기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MN2302220353_SwInfo;
