import axios, { AxiosResponse } from 'axios';
import {
  IApiListResult,
  IApiResult,
} from '../interfaces/app.interface';
import api from '../utils/axios.util';

/**
 * PRTN: 협력사
 */

/**
 * 협력사 상세
 * GET /api/prtn/prtn/{splrId}
 * @param params splrId 협력사 아이디
 */
export const getPrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/prtn/prtn/', params.splrId].join(''),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 협력사 엑셀 다운로드
 * GET /api/prtn/prtn-excel
 * @param params searchKeyword 검색어
 * @param params splrType 협력사 구분
 */
export const getPrtnPrtnExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/prtn/prtn-excel',
    responseType: 'blob',
    params: {
      searchKeyword: params.searchKeyword || '',
      splrType: params.splrType || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 내부담당자 조회(담당자 변경하기)
 * GET /api/prtn/prtn-prtn-search-user
 * @param params currPageIdx 현재페이지
 * @param params pageSize 페이지 사이즈
 * @param params searchKeyword 검색어(본명)
 */
export const getPrtnPrtnSearchUser = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/prtn/prtn-search-user',
    params: {
      pageSize: params.pageSize || 1000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재 페이지
      searchKeyword: params.searchKeyword || '', // 검색어(본명)
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 협력사 목록
 * GET /api/prtn/prtns
 * @param params currPageIdx 현재페이지
 * @param params searchKeyword 검색어
 * @param params splrType 협력사 구분
 */
export const getPrtns = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/prtn/prtns',
    params: {
      pageSize: params.pageSize || 1000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재페이지
      searchKeyword: params.searchKeyword || '', // 검색어
      splrType: params.splrType || '', // 협력사 구분
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 협력사 작업 목록
 * GET /api/prtn/prtn-work-list
 * @param params currPageIdx 현재페이지
 * @param params searchType 검색 구분
 * @param params splrId 협력사 코드
 */
export const getPrtnWorkList = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/prtn/prtn-work-list',
    params: {
      pageSize: params.pageSize || 1000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재페이지
      searchType: params.searchType || '', // 검색 구분
      splrId: params.splrId || '', // 협력사 코드
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 협력사 등록
 * POST /api/prtn/prtn
 * @param params paramVo
 */
export const postPrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/prtn/prtn',
    data: params
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 협력사 수정
 * PUT /api/prtn/prtn/{splrId}
 * @param params paramVo
 * @param params sqlrId 협력사 아이디
 */
export const putPrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/prtn/prtn/', params.splrId].join(''),
    data: params
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 협력사 삭제
 * DELETE /api/prtn/prtn/{splrId}
 * @param params splrId 협력사 아이디
 */
export const deletePrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/prtn/prtn/', params.splrId].join(''),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 협력사 평가 등록
 * POST /api/prtn/vltn
 * @param params splrId 협력사 아이디
 * @param params dtl 협력사 평가 내용
 */
export const postPrtnVltn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/prtn/vltn',
    data: params
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 협력사 평가 삭제
 * DELETE /api/prtn/vltn/{splrId}/{vltnId}
 * @param params splrId 협력사 아이디
 * @param params vltnId 협력사 평가 아이디
 */
export const deletePrtnVltn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/prtn/vltn', params.splrId, params.vltnId].join('/'),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 협력사 평가 목록
 * GET /api/prtn/vltn
 * @param params currPageIdx 현재페이지
 * @param params pageSize 한번에 보여질 페이지수
 * @param params splrId 협력사 코드
 * @param params useYn 사용여부
 */
export const getPrtnVltnList = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/prtn/vltn',
    params: {
      pageSize: params.pageSize || 10000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재페이지
      useYn: params.useYn || 'Y', // 사용여부
      splrId: params.splrId || '', // 협력사 코드
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 협력사 평가 수정
 * PUT /api/prtn/vltn/{vltnId}
 * @param params paramVo
 * @param params sqlrId 협력사 아이디
 * @param params vltnId 평가 아이디
 * @param params dtl 평가 내용
 */
export const putPrtnVltn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/prtn/vltn/', params.vltnId].join(''),
    data: params
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 협력사 상세 - 작업현황 상세
 * GET /api/prtn/prtn/work/stts
 * @param params splrId 협력사 아이디
 * @param params nstlSttsCode 설치 상태 코드
 */
export const getPrtnPrtnWorkStts = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/prtn/prtn/work/stts',
    params: {
      splrId: params.splrId || '', // 협력사 아이디
      nstlSttsCode: params.nstlSttsCode || '', // 설치 상태 코드
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};