import React, { PropsWithChildren, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbMonthPicker } from '../PbMonthPicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
  IPageContent,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as cmnApi from '../../apis/cmn.api';
import _ from 'lodash';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  Select,
  Radio,
  Checkbox,
  Input,
  Modal,
  PasswordInput,
  TextInput,
  Group,
} from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import { number } from 'prop-types';

interface IComponentProps {
  id: any;
  grpCodeId?: string;
  useModifyMode?: boolean;
  data?: any;
  onClick?: () => void;
}

/**
 * 공통코드 상세 / 수정
 * @param id 화면 아이디
 * @param grpCodeId 공통코드 아이디
 * @param useModifyMode 수정여부
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220805_CODE = ({
  id = '',
  grpCodeId = '',
  useModifyMode = false,
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 상세 Form 변수 초기화
   */

  // 코드 중복확인 확인용
  const [dupGrpCodeId, setDupGrpCodeId] = useState<string>('Y');

  // 공통 코드 > 코드를 정의함
  const [grpCodeIdInput, setGrpCodeIdInput] = useState<string>('');

  // 공통 코드 > 코드를 변경함
  const handleGrpCodeIdInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 30) {
      //30byte 제한
      setGrpCodeIdInput((pre) => pre);
    } else {
      setDupGrpCodeId('N');
      setGrpCodeIdInput(sVal);
    }
  };

  // 공통 코드 > 코드를 초기화함
  const initGrpCodeIdInput = () => {
    // 공통 코드 > 코드 아이템에 적용함
    setGrpCodeIdInput('');
  };

  // 공통 코드 > 코드명칭을 정의함
  const [grpCodeDscrInput, setGrpCodeDscrInput] = useState<string>('');

  // 공통 코드 > 코드명칭을 변경함
  const handleGrpCodeDscrInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 30) {
      //30byte 제한
      setGrpCodeDscrInput((pre) => pre);
    } else {
      setGrpCodeDscrInput(sVal);
    }
  };

  // 공통 코드 > 코드명칭을 초기화함
  const initGrpCodeDscrInput = () => {
    // 공통 코드 > 코드명칭 아이템에 적용함
    setGrpCodeSrcInput('');
  };

  // 공통 코드 > 코드설명을 정의함
  const [grpCodeSrcInput, setGrpCodeSrcInput] = useState<string>('');

  // 공통 코드 > 코드설명을 변경함
  const handleGrpCodeSrcInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 30) {
      //30byte 제한
      setGrpCodeSrcInput((pre) => pre);
    } else {
      setGrpCodeSrcInput(sVal);
    }
  };

  // 공통 코드 > 코드설명을 초기화함
  const initGrpCodeSrcInput = () => {
    // 공통 코드 > 코드설명 아이템에 적용함
    setGrpCodeSrcInput('');
  };

  // 사용 여부
  const [useYnRadio, setUseYnRadio] = useState<string>('Y');

  // 사용 여부 선택
  const handleUseYnRadio_onChange = (event: any) => {
    setUseYnRadio(event);
  };

  /* // 상세 Form 변수 초기화 */

  /**
   * 상세 데이타 검색
   */

  // 사용 가능한 설치 구분 코드 목록을 불러옴
  const getCommonCode = () => {
    cmnApi
      .getCode({
        grpCodeId: grpCodeId,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          // 코드 변경전 중복확인 통과
          setDupGrpCodeId('Y');

          // 공통 코드 > 코드를 초기화함
          setGrpCodeIdInput(data.data.grpCodeId);

          // 공통 코드 > 코드명칭을 초기화함
          setGrpCodeDscrInput(data.data.grpCodeDscr);

          // 공통 코드 > 코드설명을 초기화함
          setGrpCodeSrcInput(data.data.grpCodeSrc);

          // 공통 코드 > 사용여부를 초기화함
          if (_.isEqual(data.data.useYn, '사용')) {
            setUseYnRadio('Y');
          } else if (_.isEqual(data.data.useYn, '미사용')) {
            setUseYnRadio('N');
          }
        }
      });
  };

  const handleDuplicateConfirmButton_onClick = () => {
    cmnApi
      .getCheckCode({
        grpCodeId: grpCodeIdInput.trim(), // 상위 코드
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          // 중복 확인 : 코드 아이디 사용 가능
          case '200':
            setDupGrpCodeId('Y');
            setModal({
              title: '알림',
              content: '사용 가능한 코드 입니다.',
            });
            break;

          // 중복 확인 : 코드 사용 불가
          case '500':
            setDupGrpCodeId('N');
            setModal({
              title: '알림',
              content: data.message,
            });
            break;

          default:
            break;
        }
      });
  };

  /* //  */

  /**
   * 처리
   */

  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  // 컴포넌트 모달 > 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '공통코드 수정',
        content: (
          <MN2302220805_CODE
            id={tmpId}
            grpCodeId={grpCodeId}
            useModifyMode={true}
          />
        ),
        size: 1100,
      });
    }, 100);
  };

  // 컴포넌트 모달 > 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    // 공통코드 입력값 유효성 확인함
    // 코드 입력 확인
    if (_.isEmpty(grpCodeIdInput.trim())) {
      setModal({
        title: '알림',
        content: '코드를 입력하세요.',
      });
      return;
    }

    // 코드 중복 확인 진행 여부 확인
    if (_.isEqual(grpCodeId, grpCodeIdInput.trim())) {
      // 기존 코드와 같은 값은 중복검사 안함
    } else {
      if (_.isEqual(dupGrpCodeId, 'N')) {
        setModal({
          title: '알림',
          content: '코드 중복확인을 해주세요.',
        });
        return;
      }
    }

    // 코드명칭 입력 확인
    if (_.isEmpty(grpCodeDscrInput.trim())) {
      setModal({
        title: '알림',
        content: '코드명칭을 입력하세요.',
      });
      return;
    }

    // 코드설명 입력 확인
    if (_.isEmpty(grpCodeSrcInput.trim())) {
      setModal({
        title: '알림',
        content: '코드설명을 입력하세요.',
      });
      return;
    }

    // 코드등록 등록 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 공통코드 등록을 처리함
              cmnApi
                .postCode({
                  grpCodeId: grpCodeIdInput.trim(), // 코드
                  grpCodeDscr: grpCodeDscrInput.trim(), // 코드
                  grpCodeSrc: grpCodeSrcInput.trim(), // 코드
                  useYn: useYnRadio, // 사용여부
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220805Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);
                      },
                    });
                  } else {
                    console.log('> 코드등록 등록 Error:', data);

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> 코드등록 등록 Error:', error);

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // 컴포넌트 모달 > 저장 버튼(수정)을 클릭함
  const handleSaveUpdateButton_onClick = () => {
    // 공통코드 입력값 유효성 확인함
    // 코드 입력 확인
    if (_.isEmpty(grpCodeIdInput.trim())) {
      setModal({
        title: '알림',
        content: '코드를 입력하세요.',
      });
      return;
    }

    // 코드 중복 확인 진행 여부 확인
    if (_.isEqual(grpCodeId, grpCodeIdInput.trim())) {
      // 기존 코드와 같은 값은 중복검사 안함
    } else {
      if (_.isEqual(dupGrpCodeId, 'N')) {
        setModal({
          title: '알림',
          content: '코드 중복확인을 해주세요.',
        });
        return;
      }
    }

    // 코드명칭 입력 확인
    if (_.isEmpty(grpCodeDscrInput.trim())) {
      setModal({
        title: '알림',
        content: '코드명칭을 입력하세요.',
      });
      return;
    }

    // 코드설명 입력 확인
    if (_.isEmpty(grpCodeSrcInput.trim())) {
      setModal({
        title: '알림',
        content: '코드설명을 입력하세요.',
      });
      return;
    }

    // 코드등록 수정 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 공통코드 등록을 처리함
              cmnApi
                .putCode({
                  grpCodeId: grpCodeId, // 코드
                  // modifiedId: grpCodeIdInput.trim(), // 코드
                  grpCodeDscr: grpCodeDscrInput.trim(), // 코드
                  grpCodeSrc: grpCodeSrcInput.trim(), // 코드
                  useYn: useYnRadio, // 사용여부
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220805Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);
                      },
                    });
                  } else {
                    console.log('> 코드등록 등록 Error:', data);

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> 코드등록 등록 Error:', error);

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // 컴포넌트 모달 > 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    setModal({
      title: '확인',
      content: `공통코드 [${grpCodeId}] 정보를 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 권한 그룹 정보를 삭제함
              cmnApi
                .deleteCode({
                  grpCodeId: grpCodeId,
                })
                .then((data: IApiResult) => {
                  switch (data.code) {
                    case '200':
                      // 모달을 출력함
                      setModal({
                        title: '알림',
                        content: '삭제하였습니다.',
                        callback: () => {
                          // 목록 새로고침을 추가함
                          setAddRefreshList('MN2302220805Table');

                          setRemoveComponentModal(id);
                        },
                      });
                      break;

                    default:
                      console.log('> 공통코드 삭제 Error:', data);

                      // 모달을 출력함
                      setModal({
                        title: t('common.error'),
                        content: '삭제에 실패하였습니다.',
                      });
                      break;
                  }
                })
                .catch((error: any) => {
                  console.log('> 공통코드 삭제 Error:', error);

                  setModal({
                    title: t('common.error'),
                    content: '삭제에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  /* //  */

  /**
   * useEffect
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 공통 코드 > 코드를 초기화함
    initGrpCodeIdInput();

    // 공통 코드 > 코드명칭을 초기화함
    initGrpCodeDscrInput();

    // 공통 코드 > 코드설명을 초기화함
    initGrpCodeSrcInput();

    if (!grpCodeId) {
      return;
    }
    // 공통 코드 > 상세 정보 불러옴
    getCommonCode();

    return () => {};
  }, []);

  /* //  */

  return (
    <div className="space-y-7">
      {/* 공통코드 정보 */}
      <div className="space-y-5">
        {/* 공통코드 폼 그리드 */}
        <PbFormGrid label="공통 코드" cols={2}>
          <PbFormGridCol label="코드" withAsterisk={true}>
            <TextInput
              placeholder="코드를 입력하세요."
              onChange={handleGrpCodeIdInput_onChange}
              value={grpCodeIdInput}
              className="w-full"
              readOnly={!grpCodeId ? false : true}
              variant={!grpCodeId ? 'default' : 'unstyled'}
            />

            {/* 버튼 */}
            {!grpCodeId && (
              <>
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleDuplicateConfirmButton_onClick}
                >
                  중복 확인
                </Button>
              </>
            )}
          </PbFormGridCol>
          <PbFormGridCol label="코드 명칭" withAsterisk={true}>
            <TextInput
              placeholder="코드 명칭을 입력하세요."
              onChange={handleGrpCodeDscrInput_onChange}
              value={grpCodeDscrInput}
              className="w-full"
              readOnly={grpCodeId && !useModifyMode ? true : false}
              variant={grpCodeId && !useModifyMode ? 'unstyled' : 'default'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="코드 설명" withAsterisk={true}>
            <TextInput
              placeholder="코드 설명을 입력하세요."
              onChange={handleGrpCodeSrcInput_onChange}
              value={grpCodeSrcInput}
              className="w-full"
              readOnly={grpCodeId && !useModifyMode ? true : false}
              variant={grpCodeId && !useModifyMode ? 'unstyled' : 'default'}
            />
          </PbFormGridCol>
          <PbFormGridCol label="사용여부" withAsterisk={true}>
            <Radio.Group
              name="commonCodeUseYn"
              onChange={handleUseYnRadio_onChange}
              value={useYnRadio}
              size="sm"
            >
              <Group mt="xs">
                <Radio
                  value="Y"
                  label="사용"
                  disabled={grpCodeId && !useModifyMode ? true : false}
                />
                <Radio
                  value="N"
                  label="미사용"
                  disabled={grpCodeId && !useModifyMode ? true : false}
                />
              </Group>
            </Radio.Group>
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 신규 상태 */}
        {!grpCodeId && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveButton_onClick}
            >
              저장
            </Button>
          </>
        )}

        {/* 조회 상태 */}
        {grpCodeId && !useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleGoModifyButton_onClick}
            >
              수정 전환
            </Button>
          </>
        )}

        {/* 수정 상태 */}
        {grpCodeId && useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              variant="outline"
              color="pink"
              radius="xl"
              size="md"
              onClick={handleRemoveButton_onClick}
            >
              삭제
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveUpdateButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220805_CODE;
