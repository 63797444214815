import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IToken } from '../../interfaces/app.interface';
import * as appUtil from '../../utils/app.util';

/**
 * 사용자 > Azure AD 로그인
 * @constructor
 */
const LoginedPage = () => {
  // 페이지 주소를 정의함
  const location = useLocation();

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    // 주소줄에서 인자값을 불러옴
    const params: any = new URLSearchParams(location.search);

    // 성공 여부를 불러옴
    let success: string = params.get('success');

    // 성공일 때만 실행함
    if (success === 'Y') {
      let accessToken: string = params.get('accessToken');
      let refreshToken: string = params.get('refreshToken');
      let userId: string = params.get('userId');
      let lgnId: string = params.get('lgnId');

      // 로그인한 사용자의 토큰을 정의함
      let tmpToken: IToken = {
        email: lgnId,
        userId: userId,
        tokenType: 'Bearer ',
        accessToken: accessToken,
        accessTokenExpireSecond: 1800,
        refreshToken: refreshToken,
        refreshTokenExpireSecond: 1800,
      };

      (async () => {
        // 로그인한 사용자 토큰을 Local Storage에 저장함
        await appUtil.setToken(tmpToken);

        // 메인 페이지로 이동함
        window.location.href = '/dashboard';
      })();
    } else {
      alert('Azure AD 로그인에 실패하였습니다.');

      window.location.href = '/login';
    }

    return () => {};
  }, [location]);

  return <></>;
};

export default LoginedPage;
