import React, { PropsWithChildren } from 'react';
import { Button, Input, PasswordInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface IFindIdProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 로그인 > 아이디 찾기
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const FindId = ({ data, onClick }: PropsWithChildren<IFindIdProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  return (
    <div className="space-y-5">
      {/* 입력폼 */}
      <div className="w-full space-y-2">
        {/* 아이디 */}
        <div className="flex justify-center items-center space-x-2">
          <div className="grow flex justify-start items-center">
            {/* 언어: 이름 */}
            <span className="text-sm text-gray-500 font-semibold">
              {t('auth.name')}
            </span>
          </div>

          {/* 언어: 이름을 입력하세요. */}
          <Input
            variant="unstyled"
            placeholder={t('auth.enterName')}
            // onChange={(event: any) => setUserId(event.target.value)}
            // value={userId}
            className="flex-none w-60 px-3 bg-white border rounded"
          />
        </div>

        {/* 이메일 주소 */}
        <div className="flex justify-center items-center space-x-2">
          <div className="grow flex justify-start items-center">
            {/* 언어: 이메일 주소 */}
            <span className="text-sm text-gray-500 font-semibold">
              {t('auth.email')}
            </span>
          </div>

          {/* 언어: 이메일 주소를 입력하세요. */}
          <Input
            variant="unstyled"
            placeholder={t('auth.enterEmail')}
            // onChange={(event: any) => setUserId(event.target.value)}
            // value={userId}
            className="flex-none w-60 px-3 bg-white border rounded"
          />
        </div>
      </div>

      {/* 버튼 */}
      <div className="flex justify-center items-center">
        {/* 언어: 아이디 찾기 */}
        <Button
          // onClick={}
          className="!w-full"
        >
          {t('auth.findId')}
        </Button>
      </div>

      {/* 참고 */}
      <div className="flex justify-center items-center">
        {/* 언어: 아이디는 이메일 주소로 발송됩니다. */}
        <span className="text-sm text-gray-500 font-semibold">
          {t('auth.idSendToEmail')}
        </span>
      </div>
    </div>
  );
};

export default FindId;
