import React, { PropsWithChildren, useMemo } from 'react';

interface IPbButtonProps {
  label: string | JSX.Element;
  backgroundColor?: 'purple' | 'white' | 'red';
  size?: 'sm' | 'md' | 'lg';
  width?: '' | 'half' | 'full';
  disabled?: boolean;
  onClick?: () => void;
}

/**
 * 버튼
 * @param label 레이블
 * @param backgroundColor 배경 색상
 * @param size 크기
 * @param width 너비
 * @param disabled 비활성화 여부
 * @param onClick 클릭 이벤트
 * @constructor
 */
const PbButton = ({
  label = 'Button',
  backgroundColor = 'purple',
  size = 'md',
  width = '',
  disabled = false,
  onClick,
}: PropsWithChildren<IPbButtonProps>) => {
  // 버튼의 배경 색상을 정의함
  const backgroundColorStyle: string = useMemo(() => {
    switch (backgroundColor) {
      case 'purple':
        return 'primary';

      case 'white':
        return 'secondary';

      case 'red':
        return '!border !border-rose-500 !text-rose-500';

      default:
        return 'primary';
    }
  }, [backgroundColor]);

  // 버튼의 크기를 정의함
  const sizeStyle: string = useMemo(() => {
    switch (size) {
      case 'sm':
        return 'S';

      case 'md':
        return 'M';

      case 'lg':
        return 'L';

      default:
        return 'M';
    }
  }, [size]);

  return (
    <div
      onClick={!disabled ? onClick : () => {}}
      className="btn-wrapper w-full"
    >
      <span
        className={[
          'btn',
          backgroundColorStyle,
          sizeStyle,
          width && `pb-form-size-${width}`,
          !disabled && 'cursor-pointer hover:opacity-80 active:translate-y-px',
          disabled && '!cursor-default opacity-50',
          'user-select-none',
          'w-full flex justify-center items-center truncate',
        ].join(' ')}
      >
        {label}
      </span>
    </div>
  );
};

export default PbButton;
