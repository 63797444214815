import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IApiListResult,
  IApiResult,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { PageLayout } from '../../components/PageLayout';
import moment from 'moment';
import { PbButton } from '../../components/PbButton';
import { PbSelect2 } from '../../components/PbSelect2';
import { PbTable } from '../../components/PbTable';
import { PbFormGrid } from '../../components/PbFormGrid';
import { PbFormGridCol } from '../../components/PbFormGridCol';
import { PbMonthPicker } from '../../components/PbMonthPicker';
import { PbInputText } from '../../components/PbInputText';
import * as csApi from '../../apis/cs.api';

/**
 * 운영 관리 > CS 현황
 * @constructor
 */
const CS_01_01Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 테이블 컬럼을 정의함
  const [tableColumn, setTableColumn] = useState<ITableColumn[]>([]);

  // 테이블 데이터를 정의함
  const [tableData, setTableData] = useState<any[]>([]);

  // 운영관리 현황을 불러옴
  const [cssPrtn, setCssPrtn] = useState<any>([]);

  // 상세검색 조건의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  //* 검색어 */
  // 검색 폼 그리드 > 검색어 > 셀렉트를 정의함
  const [searcyTypeSelect, setSearchTypeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 검색어 > 검색어종류의 값을 변경함
  const handleSearchTypeSelect_onChange = (event: any) => {
    setSearchTypeSelect({ ...searcyTypeSelect, value: event.target.value });
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
  const [searchTextInput, setSearchTextInput] = useState<string>('');

  // 검색 폼 그리드 > 검색어 > 검색어 입력의 값을 변경함
  const handleSearchTextInput_onChange = (event: any) => {
    setSearchTextInput(event.target.value);
  };
  //* //검색어 */

  //* 등록기간 */
  // 상세검색의 등록기간을 정의함
  const [addDate, setAddDate] = useState<{ start: string; end: string }>({
    start: '',
    end: '',
  });

  // 상세검색의 등록기간;시작의 년월 피커를 변경함
  const handleAddStartDate_onChange = (event: any) => {
    setAddDate({
      ...addDate,
      start: event.target.value,
    });
  };

  // 상세검색의 등록기간;종료의 년월 피커를 변경함
  const handleAddEndDate_onChange = (event: any) => {
    setAddDate({
      ...addDate,
      end: event.target.value,
    });
  };
  //* //등록기간 */

  //* 처리 완료 요청 기간 */
  // 상세검색의 처리완료 요청기간을 정의함
  const [requestDate, setRequestDate] = useState<{
    start: string;
    end: string;
  }>({
    start: '',
    end: '',
  });

  // 상세검색의 처리완료 요청기간;시작의 년월 피커를 변경함
  const handleRequestStartDate_onChange = (event: any) => {
    setRequestDate({
      ...requestDate,
      start: event.target.value,
    });
  };

  // 상세검색의 처리완료 요청기간;종료의 년월 피커를 변경함
  const handleRequestEndDate_onChange = (event: any) => {
    setRequestDate({
      ...requestDate,
      end: event.target.value,
    });
  };
  //* //처리 완료 요청 기간 */

  //* 접수 유형 */
  // 상세검색의 접수유형을 정의함
  // 검색 폼 그리드 > 검색어 > 접수 유형;Depth_1 셀렉트를 정의함
  const [serviceTypeSelect, setServiceTypeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 검색어 > 접수 유형의 값을 변경함
  const handleServiceTypeSelect_onChange = (event: any) => {
    setServiceTypeSelect({ ...serviceTypeSelect, value: event.target.value });
  };

  // 검색 폼 그리드 > 검색어 > 접수 유형;Depth_2 셀렉트를 정의함
  const [serviceTypeChSelect, setServiceTypeChSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 검색어 > 접수 유형의 값을 변경함
  const handleServiceTypeChSelect_onChange = (event: any) => {
    setServiceTypeChSelect({
      ...serviceTypeChSelect,
      value: event.target.value,
    });
  };
  //* //접수 유형 */

  //* 장애 구분 */
  // 검색 폼 그리드 > 검색어 > 장애 구분 셀렉트를 정의함
  const [disableTypeSelect, setDisableTypeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 검색어 > 장애 구분의 값을 변경함
  const handleDisableTypeSelect_onChange = (event: any) => {
    setDisableTypeSelect({ ...disableTypeSelect, value: event.target.value });
  };
  //* //장애 구분 */

  //* 고객 특성 */
  // 검색 폼 그리드 > 검색어 > 고객 특성 셀렉트를 정의함
  const [customerTendencySelect, setCustomerTendencySelect] = useState<ISelect>(
    {
      value: '',
      item: [],
    },
  );

  // 검색 폼 그리드 > 검색어 > 고객 특성의 값을 변경함
  const handleCustomerTendencySelect_onChange = (event: any) => {
    setCustomerTendencySelect({
      ...customerTendencySelect,
      value: event.target.value,
    });
  };
  //* //고객 특성 */

  //* 현재 담당부서 */
  // 검색 폼 그리드 > 검색어 > 현재 담당부서 셀렉트를 정의함
  const [currentDepartmentSelect, setCurrentDepartmentSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 폼 그리드 > 검색어 > 현재 담당부서의 값을 변경함
  const handleCurrentDepartmentSelect_onChange = (event: any) => {
    setCurrentDepartmentSelect({
      ...currentDepartmentSelect,
      value: event.target.value,
    });
  };
  //* //현재 담당부서 */

  //* CS 작업 상태 */
  // 검색 폼 그리드 > 검색어 > CS 작업 상태 셀렉트를 정의함
  const [csWorkStatusSelect, setCsWorkStatusSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 검색어 > CS 작업 상태의 값을 변경함
  const handleCsWorkStatusSelect_onChange = (event: any) => {
    setCsWorkStatusSelect({ ...csWorkStatusSelect, value: event.target.value });
  };
  //* //CS 작업 상태 */

  //* CS 진행 상태 */
  // 검색 폼 그리드 > 검색어 > CS 진행 상태 셀렉트를 정의함
  const [serviceProcessSelect, setServiceProcessSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 검색어 > CS 진행 상태의 값을 변경함
  const handleServiceProcessSelect_onChange = (event: any) => {
    setServiceProcessSelect({
      ...serviceProcessSelect,
      value: event.target.value,
    });
  };
  //* //CS 진행 상태 */

  // CS운영 현황의 항목을 정의함
  const cssPrtnItem: { title: string; column: string }[] = [
    {
      title: 'Identified',
      column: 'dntf',
    },
  ];

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 오늘 날짜를 불러옴
    let today: string = moment().format('yyyy-MM');

    // 상세검색의 등록기간을 초기화함
    setAddDate({
      start: today,
      end: today,
    });

    // 상세검색의 수정기간을 초기화함
    setRequestDate({
      start: today,
      end: today,
    });

    // 검색 폼 그리드 > 검색어 > 검색어 종류 셀렉트의 아이템에 적용함
    setSearchTypeSelect({
      value: '',
      item: [
        {
          label: '고객사명',
          value: '1',
        },
        {
          label: '영업기회명',
          value: '2',
        },
        {
          label: '영업담당자명',
          value: '3',
        },
      ],
    });

    // 검색 폼 그리드 > 접수 유형 종류 셀렉트의 아이템에 적용함
    setServiceTypeSelect({
      value: '',
      item: [],
    });

    //* API */
    //** CS운영 현황 목록 */
    csApi
      .getCss({
        currPageIdx: '1', // 현재페이지
        csDprtCode: '', // CS 부서코드
        csSttsCode: '', // CS 작업 상태
        csSttsCode2: '', // CS 진행 상태
        cstmTrbtCode: '', // 고객 특성 코드
        cstyCode: '', // CS 유형 코드
        cstyGrpCode: '', // CS 유형 그룹 코드
        dsblDntfCode: '', // 장애 구분 코드
        prcsRqstEnDate: '', // 처리완료 요청 기간 종료일
        prcsRqstStDate: '', // 처리완료 요청 기간 시작일
        searchKeyword: '', // 검색어
        searchType: '', // 검색구분
      })
      .then((data: IApiListResult) => {
        if (data.code === '200') {
          // CS 현황 목록
          setCssPrtn(data.data.list);
        }
      });

    return () => {};
  }, []);
  //** //CS운영 현황 목록 */
  //* //API */

  useEffect(() => {
    // 테이블 컬럼을 정의함
    let tmpTableColumn: ITableColumn[] = [];

    // 테이블 컬럼에 추가함
    tmpTableColumn.push(
      {
        column: 'no',
        title: '번호',
        width: '0',
        align: 'center',
      },
      {
        column: '',
        title: '접수유형',
        width: '0',
        align: 'center',
      },
      {
        column: '',
        title: '장애구분',
        width: '0',
        align: 'center',
      },
      {
        column: '',
        title: '문의명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '고객사명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: 'Shop명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '고객특성',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '최근 이관 정보',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '현재 담당부서',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '콜센터 담당자명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '운영팀 담당자명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '개발팀 담당자명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '현장팀 담당자명',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: 'CS 작업상태',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: 'CS 진행상태',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '리드타임',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '처리완료 요청일',
        width: '0',
        align: 'center',
      },
      {
        column: 'step',
        title: '등록일시',
        width: '0',
        align: 'center',
      },
    );

    // 테이블 컬럼에 적용함
    setTableColumn(tmpTableColumn);

    // 테이블 데이터를 정의함
    let tmpTableData: any[] = [];

    // 테이블 데이터에 추가함
    cssPrtnItem.map(
      (item: { title: string; column: string }, index: number) => {
        let tmpTableDataRow: any = {};

        tmpTableDataRow['step'] = item.title;

        cssPrtn.list?.map((subItem: any, index: number) => {
          tmpTableDataRow[subItem.rgstMonth] = subItem[item.column];
        });

        // 테이블 데이터에 추가함
        tmpTableData.push(tmpTableDataRow);
      },
    );

    // 테이블 데이터에 적용함
    setTableData(tmpTableData);

    return () => {};
  }, [cssPrtn]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        <div className="content-container">
          {/* content-wrapper */}
          <div className="content-wrapper">
            {/* col-wrapper */}
            <div className="col-wrapper">
              {/* col-2 */}
              <div className="col-2">
                <div className="table-wrapper">
                  {/* table */}
                  <table className="table-basic status-board-03">
                    <tbody>
                      <tr>
                        <th style={{ height: '64px' }}>전체</th>
                        <th rowSpan={3} colSpan={5} className="bg-gray">
                          {/* team-wrapper */}
                          <div className="team-wrapper">
                            {/* team-name */}
                            <div className="team-name">
                              콜센터
                              <span className="number">000</span>
                            </div>
                            {/* team-name */}

                            {/* status-wrapper */}
                            <div className="status-wrapper">
                              <ul>
                                <li>
                                  진행 중 :<span>200</span>
                                </li>
                                <li className="text-color-red">
                                  미처리 :<span>300</span>
                                </li>
                                <li className="text-color-blue">
                                  완료 :<span>000</span>
                                </li>
                              </ul>
                            </div>
                            {/* status-wrapper */}
                          </div>
                          {/* team-wrapper */}

                          {/* team-wrapper */}
                          <div className="team-wrapper">
                            {/* team-name */}
                            <div className="team-name">
                              운영팀
                              <span className="number">000</span>
                            </div>
                            {/* team-name */}

                            {/* status-wrapper */}
                            <div className="status-wrapper">
                              <ul>
                                <li>
                                  진행 중 :<span>200</span>
                                </li>
                                <li className="text-color-red">
                                  미처리 :<span>300</span>
                                </li>
                                <li className="text-color-blue">
                                  완료 :<span>000</span>
                                </li>
                              </ul>
                            </div>
                            {/* status-wrapper */}
                          </div>
                          {/* team-wrapper */}

                          {/* team-wrapper */}
                          <div className="team-wrapper">
                            {/* team-name */}
                            <div className="team-name">
                              개발팀
                              <span className="number">000</span>
                            </div>
                            {/* team-name */}

                            {/* status-wrapper */}
                            <div className="status-wrapper">
                              <ul>
                                <li>
                                  진행 중 :<span>200</span>
                                </li>
                                <li className="text-color-red">
                                  미처리 :<span>300</span>
                                </li>
                                <li className="text-color-blue">
                                  완료 :<span>000</span>
                                </li>
                              </ul>
                            </div>
                            {/* status-wrapper */}
                          </div>
                          {/* team-wrapper */}

                          {/* team-wrapper */}
                          <div className="team-wrapper">
                            {/* team-name */}
                            <div className="team-name">
                              현장팀
                              <span className="number">000</span>
                            </div>
                            {/* team-name */}

                            {/* status-wrapper */}
                            <div className="status-wrapper">
                              <ul>
                                <li>
                                  진행 중 :<span>200</span>
                                </li>
                                <li className="text-color-red">
                                  미처리 :<span>300</span>
                                </li>
                                <li className="text-color-blue">
                                  완료 :<span>000</span>
                                </li>
                              </ul>
                            </div>
                            {/* status-wrapper */}
                          </div>
                          {/* team-wrapper */}
                        </th>
                      </tr>
                      <tr>
                        <td>22</td>
                      </tr>
                    </tbody>
                  </table>
                  {/* table */}
                </div>
              </div>
              {/*col-2 */}

              {/* col-2 */}
              <div className="col-2">
                <div className="table-wrapper">
                  {/* table */}
                  <table className="table-basic status-board-02">
                    <colgroup className="mobile-none">
                      <col style={{ width: '15%' }} />
                      <col style={{ width: 'auto' }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>접수 유형</th>
                        <td>
                          {/* graph-bar-wrapper-S */}0
                          <div className="graph-bar-wrapper-S">
                            <ul>
                              <li>
                                <div className="label">접속장애</div>
                                <div className="graph-bar">
                                  <div
                                    className="graph-navyBlue"
                                    style={{ width: '80%' }}
                                  ></div>
                                </div>
                                <div className="graph-data">57건</div>
                              </li>

                              <li>
                                <div className="label">패스워드</div>
                                <div className="graph-bar">
                                  <div
                                    className="graph-navyBlue"
                                    style={{ width: '100%' }}
                                  ></div>
                                </div>
                                <div className="graph-data">57건</div>
                              </li>

                              <li>
                                <div className="label">통신</div>
                                <div className="graph-bar">
                                  <div
                                    className="graph-navyBlue"
                                    style={{ width: '70%' }}
                                  ></div>
                                </div>
                                <div className="graph-data">57건</div>
                              </li>

                              <li>
                                <div className="label">PDA</div>
                                <div className="graph-bar">
                                  <div
                                    className="graph-navyBlue"
                                    style={{ width: '40%' }}
                                  ></div>
                                </div>
                                <div className="graph-data">57건</div>
                              </li>

                              <li>
                                <div className="label">네트워크</div>
                                <div className="graph-bar">
                                  <div
                                    className="graph-navyBlue"
                                    style={{ width: '10%' }}
                                  ></div>
                                </div>
                                <div className="graph-data">57건</div>
                              </li>

                              <li>
                                <div className="label">AS</div>
                                <div className="graph-bar">
                                  <div
                                    className="graph-navyBlue"
                                    style={{ width: '30%' }}
                                  ></div>
                                </div>
                                <div className="graph-data">57건</div>
                              </li>

                              <li>
                                <div className="label">TAG</div>
                                <div className="graph-bar">
                                  <div
                                    className="graph-navyBlue"
                                    style={{ width: '90%' }}
                                  ></div>
                                </div>
                                <div className="graph-data">57건</div>
                              </li>

                              <li>
                                <div className="label">리뉴얼</div>
                                <div className="graph-bar">
                                  <div
                                    className="graph-navyBlue"
                                    style={{ width: '50%' }}
                                  ></div>
                                </div>
                                <div className="graph-data">57건</div>
                              </li>

                              <li>
                                <div className="label">기타</div>
                                <div className="graph-bar">
                                  <div
                                    className="graph-navyBlue"
                                    style={{ width: '80%' }}
                                  ></div>
                                </div>
                                <div className="graph-data">57건</div>
                              </li>
                            </ul>
                          </div>
                          {/* graph-bar-wrapper-S */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* table */}
                </div>
              </div>
              {/* col-2 */}
            </div>
            {/* col-wrapper */}
          </div>
          {/* content-wrapper */}

          {/* 검색폼 */}
          <PbFormGrid label="검색" cols={2}>
            <PbFormGridCol label="검색어" colSpan={2}>
              {/* 셀렉트 */}
              <PbSelect2
                size="xs"
                onChange={handleSearchTypeSelect_onChange}
                item={searcyTypeSelect.item}
                value={searcyTypeSelect.value}
                useDefaultItem={true}
              />
              {/* 검색어 입력 */}
              <PbInputText
                placeholder="검색어를 입력하세요."
                size="sm"
                onChange={handleSearchTextInput_onChange}
                value={searchTextInput}
              />
              <PbButton
                label="통합 검색"
                backgroundColor="purple"
                size="sm"
                onClick={() => {
                  console.log('검색');
                }}
              />

              <PbButton
                label="취소"
                backgroundColor="white"
                size="sm"
                onClick={() => {
                  console.log('취소');
                }}
              />

              <PbButton
                label={hideDetailSearch ? '상세검색 열기' : '상세검색 닫기'}
                backgroundColor="white"
                size="sm"
                onClick={() => setHideDetailSearch(!hideDetailSearch)}
              />
            </PbFormGridCol>
            <PbFormGridCol label="등록기간" isHidden={hideDetailSearch}>
              <PbMonthPicker
                onChange={handleAddStartDate_onChange}
                value={addDate.start}
              />

              <div className="mx-2">
                <span>~</span>
              </div>

              <PbMonthPicker
                onChange={handleAddEndDate_onChange}
                value={addDate.end}
              />
            </PbFormGridCol>
            <PbFormGridCol
              label="처리 완료 요청 기간"
              isHidden={hideDetailSearch}
            >
              <PbMonthPicker
                onChange={handleRequestStartDate_onChange}
                value={requestDate.start}
              />

              <div className="mx-2">
                <span>~</span>
              </div>

              <PbMonthPicker
                onChange={handleRequestEndDate_onChange}
                value={requestDate.end}
              />
            </PbFormGridCol>

            <PbFormGridCol label="접수 유형" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect2
                size="xs"
                onChange={handleServiceTypeSelect_onChange}
                item={serviceTypeSelect.item}
                value={serviceTypeSelect.value}
                useDefaultItem={true}
              />
              {/* 셀렉트 */}
              <PbSelect2
                size="xs"
                // onChange={handleClientSelect_onChange}
                // item={clientSelect.item}
                // value={clientSelect.value}
                useDefaultItem={true}
              />
            </PbFormGridCol>

            <PbFormGridCol label="장애 구분" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect2
                size="xs"
                // onChange={handleClientSelect_onChange}
                // item={clientSelect.item}
                // value={clientSelect.value}
                useDefaultItem={true}
              />
            </PbFormGridCol>

            <PbFormGridCol label="고객 특성" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect2
                size="xs"
                // onChange={handleClientSelect_onChange}
                // item={clientSelect.item}
                // value={clientSelect.value}
                useDefaultItem={true}
              />
            </PbFormGridCol>

            <PbFormGridCol label="현재 담당부서" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect2
                size="xs"
                // onChange={handleClientSelect_onChange}
                // item={clientSelect.item}
                // value={clientSelect.value}
                useDefaultItem={true}
              />
            </PbFormGridCol>

            <PbFormGridCol label="CS 작업 상태" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect2
                size="xs"
                // onChange={handleClientSelect_onChange}
                // item={clientSelect.item}
                // value={clientSelect.value}
                useDefaultItem={true}
              />
            </PbFormGridCol>

            <PbFormGridCol label="CS 진행 상태" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect2
                size="xs"
                // onChange={handleClientSelect_onChange}
                // item={clientSelect.item}
                // value={clientSelect.value}
                useDefaultItem={true}
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* // 검색폼 */}

          {/* content-wrapper */}
          <div className="content-wrapper">
            {/* table-wrapper */}
            <div className="table-wrapper">
              {/* info-wrapper */}
              <div className="info-wrapper">
                <div className="total">
                  총 조회 <span className="bold">20</span>건
                </div>
                <PbButton
                  label="엑셀 다운로드"
                  backgroundColor="white"
                  size="sm"
                  // onClick={handleSearchOpenDetailButton_onChange}
                />

                {/* select-wrapper */}
                <div className="select-wrapper">
                  <div className="form-select-wrapper">
                    <select defaultValue="1" className="form-select">
                      <option value="1">최근 등록일 순</option>
                      <option>select 1</option>
                      <option>select 2</option>
                    </select>
                  </div>
                </div>
                {/* select-wrapper */}
              </div>
              {/* info-wrapper */}

              {/* table */}
              <PbTable
                column={tableColumn}
                data={tableData}
                striped={false}
                highlightOnHover={true}
                userSelectNone={true}
                usePagination={true}
                onClickRow={(item: any) => console.log('> item: ', item)}
              />
              {/* table */}
            </div>
            {/* table-wrapper */}
          </div>
          {/* content-wrapper */}
        </div>
        {/* content-container */}
      </div>
    </PageLayout>
  );
};

export default CS_01_01Page;
