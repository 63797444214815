import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  Checkbox,
  Radio,
  Progress,
} from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { MN2302220631 } from '../components/MN2302220631'; //등록,상세,수정 Component
import * as prtnApi from '../apis/prtn.api';
import * as cmnApi from '../apis/cmn.api';
import { PbAgGridReact } from '../components/PbAgGridReact';
import { decode } from 'html-entities';
import PbSelect from '../components/PbSelect/PbSelect.component';
import { ValueFormatterParams } from 'ag-grid-community';

/**
 * 파트너사 관리 > 협력사 관리
 * @constructor
 */
const MN2302220601Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);
  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);
  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);
  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);
  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**************************** API 호출 ****************************/
  //파트너사 유형 코드 조회
  const getPrtnCategoryCode = () => {
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'SPLR_TYPE',
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          let tmpOptionItem: IOptionItem[] = [];
          tmpOptionItem.push({
            label: '전체',
            value: '',
          });
          for (var code of data.data.list) {
            tmpOptionItem.push({
              label: code.dtlCodeDscr,
              value: code.dtlCodeId.toLowerCase(),
            });
          }
          // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
          setPrtnCategorySelect({
            value: '',
            item: tmpOptionItem,
          });
        }
      });
  };

  //파트너사 목록 조회
  const getPrtnList = () => {
    // 파트너사 테이블의 로딩 여부에 적용함
    setPrtnListTableLoading(true);

    prtnApi
      .getPrtns({
        splrType: prtnCategorySelect.value,
        searchKeyword: prtnNameInput.trim(),
        pageSize: prtnListTablePaging.rowPerPage,
        currPageIdx: prtnListTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함(협력사명으로 정렬)
          setPrtnListTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));

          // 테이블을 페이징함
          setPrtnListTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }

        // 파트너사 테이블의 로딩 여부에 적용함
        setPrtnListTableLoading(false);
      });
  };

  /**************************** STATE ****************************/
  //담당자명 검색어
  const [prtnNameInput, setPrtnNameInput] = useState<string>('');

  //협력사 유형 셀렉트 state
  const [prtnCategorySelect, setPrtnCategorySelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  //파트너사 테이블
  const prtnListTableRef = useRef(null);

  //파트너사 테이블 state
  const [prtnListTable, setPrtnListTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  // 파트너사 테이블 페이징을 정의함
  const [prtnListTablePaging, setPrtnListTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const prtnListTablePagingCurrentPageRef = useRef<number>(1);

  // 파트너사 테이블의 로딩 여부를 정의함
  const [prtnListTableLoading, setPrtnListTableLoading] =
    useState<boolean>(false);

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  /**************************** ON EVENT LISTENER ****************************/
  //협력사명 입력 이벤트
  const handlePrtnNameInput_onChange = (event: any) => {
    setPrtnNameInput(event.target.value);
  };

  //협력사명 엔터 이벤트
  const handlePrtnNameInput_onEnterKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      prtnListTablePagingCurrentPageRef.current = 1;
      setPrtnListTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: prtnListTablePagingCurrentPageRef.current,
      }));

      getPrtnList();
    }
  };

  //검색 버튼 이벤트
  const handlePrtnSearchBtn_onClick = () => {
    // 테이블의 페이지를 변경함
    prtnListTablePagingCurrentPageRef.current = 1;
    setPrtnListTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: prtnListTablePagingCurrentPageRef.current,
    }));

    getPrtnList();
  };

  //검색 조건 초기화 버튼 이벤트
  const handleCancelButton_onClick = () => {
    setPrtnNameInput('');
    setPrtnCategorySelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));
  };

  //파트너사 유형 셀렉트 변경 이벤트
  const handlePrtnCategorySelect_onChange = (event: any) => {
    setPrtnCategorySelect({
      ...prtnCategorySelect,
      value: event,
    });
  };

  //파트너사 목록 클릭 (ROW CLICK)
  const handlePrtnListTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220631';
    //컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '협력사 상세',
      content: (
        <MN2302220631
          id={tmpId}
          splrId={row.data.splrId}
          useModifyMode={false}
        />
      ),
      size: 1500,
    });
  };
  //등록 버튼 클릭
  const handlePrtnAddBtn_onClick = () => {
    let tmpId: string = 'MN2302220631';
    setAddComponentModal({
      id: tmpId,
      title: '협력사 등록',
      content: <MN2302220631 id={tmpId} />,
      size: 1500,
    });
  };

  //엑셀 다운로드 버튼 클릭
  const handlePrtnListTableExportExcelBtn_onClick = () => {
    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="purple"
            size="sm"
            onClick={() => {
              prtnApi
                .getPrtnPrtnExcel({
                  searchKeyword: prtnNameInput,
                  splrType: prtnCategorySelect.value,
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    ['협력사_', moment().format('YYYYMMDD'), '.xlsx'].join(''),
                  );
                  document.body.appendChild(link);
                  link.click();
                });
              setRemoveModal(true);
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    setHideDetailSearch(!hideDetailSearch);
  };

  /**************************** INIT ****************************/
  //파트너사 유형 셀렉트 초기화
  const initPrtnCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '네트워크 공사',
        value: 'ntwr_cnst',
      },
      {
        label: 'ESL 설치',
        value: 'esl_nstl',
      },
    );

    // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
    setPrtnCategorySelect({
      value: '',
      item: tmpOptionItem,
    });
  };

  //파트너사 테이블 초기화
  const initPrtnListTable = () => {
    let tmpColumn: any = [];
    tmpColumn.push(
      {
        field: 'no',
        headerName: '번호',
        width: 80,
        suppressSizeToFit: true,
      },
      {
        field: 'prtnName',
        headerName: '협력사명',
      },
      {
        field: 'teamName',
        headerName: '팀명',
        width: 150,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          let tmpStr: string = '';
          params.data.prtnTmList.map((item: any) => {
            if (tmpStr) {
              tmpStr += ', ';
            }
            tmpStr += item.teamName;
          });

          return tmpStr;
        },
      },
      {
        field: 'splrTypeName',
        headerName: '유형',
        width: 150,
        suppressSizeToFit: true,
      },
      // {
      //   field: 'cntcName',
      //   headerName: '담당자명',
      //   width: 100,
      //   suppressSizeToFit: true,
      // },

      // {
      //   field: 'fulWork',
      //   headerName: '전체 작업',
      //   width: 110,
      //   suppressSizeToFit: true,
      // },
      {
        field: 'fulWork',
        headerName: '전체 작업',
        width: 110,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="relative w-full h-full flex justify-center items-center">
            {/* 수치 */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-30">
              <span className="text-sm text-white font-bold">
                {params.data.fulWork}
              </span>
            </div>

            {/* 그래프 */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-20">
              <Progress
                size="xl"
                radius="xs"
                color="rgb(100 116 139)"
                value={100}
                className="w-full !h-6"
              />
            </div>
          </div>
        ),
      },
      // {
      //   field: 'prcd',
      //   headerName: '진행중',
      //   width: 110,
      //   suppressSizeToFit: true,
      // },
      {
        field: 'prcd',
        // headerName: '진행중',
        headerName: '작업대기',
        width: 110,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="relative w-full h-full flex justify-center items-center">
            {/* 수치 */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-30">
              <span className="text-sm font-bold">{params.data.prcd}</span>
            </div>

            {/* 그래프 */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-20">
              <Progress
                size="xl"
                radius="xs"
                color="rgb(217 249 157)"
                value={(params.data.prcd / params.data.fulWork) * 100}
                className="w-full !h-6"
              />
            </div>
          </div>
        ),
      },
      // {
      //   field: 'dly',
      //   headerName: '지연',
      //   width: 110,
      //   suppressSizeToFit: true,
      // },
      // {
      //   field: 'dly',
      //   headerName: '지연',
      //   width: 110,
      //   suppressSizeToFit: true,
      //   cellRenderer: (params: any) => (
      //     <div className="relative w-full h-full flex justify-center items-center">
      //       {/* 수치 */}
      //       <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-30">
      //         <span className="text-sm font-bold">{params.data.dly}</span>
      //       </div>
      //
      //       {/* 그래프 */}
      //       <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-20">
      //         <Progress
      //           size="xl"
      //           radius="xs"
      //           color="rgb(254 205 211)"
      //           value={(params.data.dly / params.data.fulWork) * 100}
      //           className="w-full !h-6"
      //         />
      //       </div>
      //     </div>
      //   ),
      // },
      // {
      //   field: 'workCmpl',
      //   headerName: '작업완료',
      //   width: 110,
      //   suppressSizeToFit: true,
      // },
      {
        field: 'workCmpl',
        headerName: '작업완료',
        width: 110,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="relative w-full h-full flex justify-center items-center">
            {/* 수치 */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-30">
              <span className="text-sm font-bold">{params.data.workCmpl}</span>
            </div>

            {/* 그래프 */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-20">
              <Progress
                size="xl"
                radius="xs"
                color="rgb(153 246 228)"
                value={(params.data.workCmpl / params.data.fulWork) * 100}
                className="w-full !h-6"
              />
            </div>
          </div>
        ),
      },
      // {
      //   field: 'nspcCmpl',
      //   headerName: '검수완료',
      //   width: 110,
      //   suppressSizeToFit: true,
      // },
      {
        field: 'nspcCmpl',
        headerName: '검수완료',
        width: 110,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="relative w-full h-full flex justify-center items-center">
            {/* 수치 */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-30">
              <span className="text-sm font-bold">{params.data.nspcCmpl}</span>
            </div>

            {/* 그래프 */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-20">
              <Progress
                size="xl"
                radius="xs"
                color="rgb(186 230 253)"
                value={(params.data.nspcCmpl / params.data.fulWork) * 100}
                className="w-full !h-6"
              />
            </div>
          </div>
        ),
      },
      // {
      //   field: 'pmNspcCmpl',
      //   headerName: 'PM 검수완료',
      //   width: 110,
      //   suppressSizeToFit: true,
      // },
      {
        field: 'pmNspcCmpl',
        headerName: 'PM검수완료',
        width: 110,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="relative w-full h-full flex justify-center items-center">
            {/* 수치 */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-30">
              <span className="text-sm font-bold">
                {params.data.pmNspcCmpl}
              </span>
            </div>

            {/* 그래프 */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-20">
              <Progress
                size="xl"
                radius="xs"
                color="rgb(199 210 254)"
                value={(params.data.pmNspcCmpl / params.data.fulWork) * 100}
                className="w-full !h-6"
              />
            </div>
          </div>
        ),
      },
      // {
      //   field: 'rgstDate',
      //   headerName: '등록일',
      //   width: 130,
      //   suppressSizeToFit: true,
      // },
    );

    setPrtnListTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    //initPrtnCategorySelect();   //협력사 유형 셀렉트 초기화
    getPrtnCategoryCode();
    initPrtnListTable(); //협력사 목록 테이블 초기화(컬럼)
    return () => {};
  }, []);

  // 파트너 목록 테이블 값 초기화
  useEffect(() => {
    if (prtnListTable.column.length === 0) {
      return;
    }
    getPrtnList();
    return () => {};
  }, [prtnListTable.column]);

  //셀렉트 박스 변경 시
  // useEffect(() => {
  //   getPrtnList();
  //   return () => {};
  // }, [prtnCategorySelect.value]);

  // 목록 새로고침 시
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }
    if (_.indexOf(refreshList, 'MN2302220601Table') > -1) {
      // 테이블의 페이지를 변경함
      prtnListTablePagingCurrentPageRef.current = 1;
      setPrtnListTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: prtnListTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getPrtnList();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220601Table');
    }
    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      //pageInfoBarLeftArea={<></>}
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={
        <>
          <Button color="indigo" radius="xl" onClick={handlePrtnAddBtn_onClick}>
            등록
          </Button>
        </>
      }
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid label="" cols={2}>
            <PbFormGridCol label="협력사명">
              {/* 검색어 텍스트 입력 */}
              <TextInput
                placeholder="협력사명을 입력하세요."
                onChange={handlePrtnNameInput_onChange}
                onKeyUp={handlePrtnNameInput_onEnterKeyPress}
                value={prtnNameInput}
                className="w-full"
              />
              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handlePrtnSearchBtn_onClick}
                >
                  검색
                </Button>
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleCancelButton_onClick}
                >
                  검색 조건 초기화
                </Button>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleShowSearchDetailButton_onChange}
                  hidden={true}
                >
                  {hideDetailSearch ? (
                    <>
                      상세검색 열기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-down']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      상세검색 닫기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-up']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  )}
                </Button>
              </div>
            </PbFormGridCol>

            <PbFormGridCol label="유형" isHidden={hideDetailSearch}>
              {/* 검색어 텍스트 입력 */}
              <PbSelect
                onChange={handlePrtnCategorySelect_onChange}
                data={prtnCategorySelect.item}
                value={prtnCategorySelect.value}
                setSelect={setPrtnCategorySelect}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 테이블 */}
          <div className="space-y-3">
            <div className="flex justify-between items-center space-x-5">
              <div>
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handlePrtnListTableExportExcelBtn_onClick}
                >
                  엑셀 다운로드
                </Button>
              </div>

              <div className="flex justify-center items-center space-x-5">
                <div className="flex justify-center items-center">
                  <span className="text-base text-gray-600 font-bold">
                    총 {prtnListTablePaging.totalRow}건
                  </span>
                </div>
              </div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-160">
              <PbAgGridReact
                columnDefs={prtnListTable.column}
                rowData={prtnListTable.data}
                loading={prtnListTableLoading}
                setLoading={setPrtnListTableLoading}
                onRowDoubleClicked={handlePrtnListTable_onRowDoubleClicked}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
                visiblePaging={true}
                paging={{
                  totalPage: prtnListTablePaging.totalPage,
                  currentPage: prtnListTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  prtnListTablePagingCurrentPageRef.current = event;
                  setPrtnListTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: prtnListTablePagingCurrentPageRef.current,
                  }));

                  // 테이블 데이터를 불러옴
                  getPrtnList();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220601Page;
