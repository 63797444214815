import React, { PropsWithChildren, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

interface IPbCheckboxProps {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

/**
 * 체크박스
 * @param label 레이블
 * @param checked 체크 여부
 * @param disabled 비활성화 여부
 * @param onChange 변경 이벤트
 * @constructor
 */
const PbCheckbox = ({
  label,
  checked = false,
  disabled = false,
  onChange,
}: PropsWithChildren<IPbCheckboxProps>) => {
  const [formId, setFormId] = useState<string>('');

  useEffect(() => {
    setFormId(nanoid());

    return () => {};
  }, []);

  return (
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="checkbox"
        id={formId}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <label className="form-check-label user-select-none" htmlFor={formId}>
        {label}
      </label>
    </div>
  );
};

export default PbCheckbox;
