import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { PbAgGridReact } from '../PbAgGridReact';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
  IPageContent,
} from '../../interfaces/app.interface';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as nstlApi from '../../apis/nstl.api';
import * as cmnApi from '../../apis/cmn.api';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { Button, Select, TextInput } from '@mantine/core';
import MN2302220352_Item from '../MN2302220352_Item/MN2302220352_Item.component';
import MN2302220352_Stock from '../MN2302220352_Stock/MN2302220352_Stock.component';
import { Comment } from '../Comment';
import PbSelect from '../PbSelect/PbSelect.component';

interface MN2302220353_Esl {
  id?: string;
  splrId?: string;
  teamId?: string;
  dcmnDntfNmbr?: string;
  dcmnScrnNmbr?: string;
  pmInspectionResult?: string;
  useModifyMode?: boolean;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * 진행중 작업 관리 상세 _ Esl 작업결과 등록
 * @param id 화면 아이디
 * @param splrId 협력사 아이디
 * @param teamId 협력사(팀) 아이디
 * @param dcmnDntfNmbr 문서 번호(Doc Entry)
 * @param dcmnScrnNmbr 문서 번호(Doc Num) - B1 출력 문서 번호
 * @param pmInspectionResult PM검수결과
 * @param data <인자>
 * @param onClick <이벤트>
 * @param callback modal 수정정보 반영을 위한 이벤트
 * @constructor
 */

let valEslSetUpListTable: any[] = [];

let loadSplrId: string = '';
let loadTeamId: string = '';

const MN2302220353_Esl = ({
  id = '',
  splrId = '',
  teamId = '',
  dcmnDntfNmbr = '',
  dcmnScrnNmbr = '',
  pmInspectionResult = '',
  useModifyMode = false,
  data,
  onClick,
  callback,
}: PropsWithChildren<MN2302220353_Esl>) => {
  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);
  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**************************** API 호출 ****************************/

  /**************************** STATE ****************************/

  // 이 컴포넌트의 전체 데이터를 정의함
  const [apiData, setApiData] = useState<any | null>(null);

  //작업 내용 선택 셀렉트
  const [workContSelect, setWorkContSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  //작업 스텝 탭 state
  const [workStepTab, setWorkStepTab] = useState<string>('ntwk');

  //작업 상태 선택 셀렉트
  const [workStateSelect, setWorkStateSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  const [projectNameInput, setProjectNameInput] = useState<string>(''); //프로젝트 이름
  const [customerNameInput, setCustomerNameInput] = useState<string>(''); //고객사명
  const [shopNameInput, setShopNameInput] = useState<string>(''); //Shop 명
  const [orderNoInnput, setOrderNoInnput] = useState<string>(''); //주문번호
  const [prtnNameInput, setPrtnNameInput] = useState<string>(''); //협력사 명
  const [prtnTeamNameInput, setPrtnTeamNameInput] = useState<string>(''); //협력사 명
  const [workDateInput, setWorkDateInput] = useState<string>(''); //작업일정
  const [workStatusInput, setWorkStatusInput] = useState<string>(''); //작업상태

  //ESL 설치 테이블
  const eslSetUpTableRef = useRef<any>(null);

  //ESL 설치 테이블
  const [eslSetUpTable, setEslSetUpTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  // ESL 설치 테이블의 로딩 여부를 정의함
  const [eslSetUpTableLoading, setEslSetUpTableLoading] =
    useState<boolean>(false);

  /**************************** ON EVENT LISTENER ****************************/
  //작업 내용 셀렉트 변경 이벤트
  const handleWorkContSelect_onChange = (event: any) => {
    setWorkContSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));

    loadTeamId = event; // 변경 값으로 협력사 아이디 설정 - 다시 로드하기 위함

    // 작업 내용이 변경되면, 데이타 다시 불러온다
    // initEsl();

    initWorkStateSelect(); //작업 상태 셀렉트 초기화
    initEslSetUpTable(); //ESL 설치 등록 테이블 초기화
    getNstlWorkEsl();
  };

  //작업 상태 셀렉트 변경 이벤트
  const handleWorkStateSelect_onChange = (event: any) => {
    setWorkStateSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  /**************************** INIT ****************************/
  // ESL 작업 내역 셀렉트 초기화
  const initWorkContSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    nstlApi
      .getNstlPrcd({
        dcmnDntfNmbr: dcmnDntfNmbr,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          // ESL 공사 작업 내역 분리
          let tmpNetworkList: any = data.data.installPrtnList.filter(
            (item: any) => _.isEqual(item.splrTypeCode, 'ESL_NSTL'),
          );

          tmpNetworkList.map((item: any) => {
            tmpOptionItem.push({
              label: item.ttl,
              value: item.teamId,
            });
          });

          setWorkContSelect({
            value: loadTeamId,
            item: tmpOptionItem,
          });
        }
      })
      .catch((error: any) => {});
  };

  //작업 상태 셀렉트 초기화
  const initWorkStateSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];
    // 설치구분 상세를 불러옴
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'NSTL_STTS',
      })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            if (_.isEqual(item.dtlCodeId, 'PM_NSPC_CMPL')) {
              // PM 검수완료
              // PM 검수완료 는 PM검수대기 상세 에서
            } else {
              tmpOptionItem.push({
                label: item.dtlCodeDscr,
                value: item.dtlCodeId,
              });
            }

            setWorkStateSelect({
              value: 'PRCD',
              item: tmpOptionItem,
            });
          },
        );
      });
  };

  //ESL 설치 등록 테이블
  const initEslSetUpTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      {
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      },
      {
        field: 'itemNmbr',
        headerName: '품목 번호',
        editable: false,
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: 'itemDtls',
        headerName: '품목명',
        editable: false,
      },
      {
        field: 'qntt',
        headerName: '계획 수량',
        editable: false,
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: 'nstlQntt',
        headerName: '설치 수량',
        width: 100,
        suppressSizeToFit: true,
        type: 'number',
      },
      {
        field: 'lostQntt',
        headerName: '분실 수량',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: '반납 예정 수량',
        width: 140,
        children: [
          {
            field: 'rtrnNrmlQntt',
            headerName: '일반',
            width: 70,
            suppressSizeToFit: true,
          },
          {
            field: 'rtrnDfctQntt',
            headerName: '불량',
            width: 70,
            suppressSizeToFit: true,
          },
        ],
      },
    );

    if (_.isEqual(pmInspectionResult, 'Y')) {
      // 검수 완료 상태
    } else {
      // PM 검수 전 품목 불러오기 가능
      tmpColumn.push({
        headerName: '',
        field: '',
        width: 140,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="w-full col-span-3 space-y-3">
            <div className="space-y-3">
              <Button
                color="indigo"
                radius="xl"
                onClick={() => handleAddEslSetUpBtn_onClick(params)}
              >
                품목 불러오기
              </Button>
            </div>
          </div>
        ),
      });
    }

    setEslSetUpTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  const handleAddEslSetUpBtn_onClick = (params: any) => {
    let tmpId: string = nanoid();
    let type: string = 'REQ';
    if (params.data) {
      type = 'MOD';
    }
    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '품목 불러오기',
      content: (
        <MN2302220352_Item
          data={params.data}
          dcmnDntfNmbr={dcmnDntfNmbr}
          type={type}
          id={tmpId}
          callback={(data: any) => {
            if (data) {
              let ongoing: boolean = true;

              valEslSetUpListTable.map((item: any) => {
                if (_.isEqual(item.itemNmbr, data.itemNmbr)) {
                  setModal({
                    title: '알림',
                    content: '품목은 중복 등록 불가능합니다.',
                  });
                  ongoing = false;
                  return;
                }
              });

              if (ongoing) {
                // 중복 품목이 없어야 진행
                if (_.isEqual(type, 'REQ')) {
                  data.modifyRowIndex = valEslSetUpListTable.length;
                  valEslSetUpListTable.push(data);
                } else if (_.isEqual(type, 'MOD')) {
                  valEslSetUpListTable.map((item: any) => {
                    if (_.isEqual(item.modifyRowIndex, data.modifyRowIndex)) {
                      item.itemNmbr = data.itemNmbr;
                      item.itemDtls = data.itemDtls;
                      item.qntt = data.qntt;
                      item.nstlQntt = data.nstlQntt;
                      item.lostQntt = data.lostQntt;
                      item.rtrnNrmlQntt = data.rtrnNrmlQntt;
                      item.rtrnDfctQntt = data.rtrnDfctQntt;
                    }
                  });
                }

                setEslSetUpTable(
                  (pre: { column: ITableColumn[]; data: any }) => ({
                    ...pre,
                    data: valEslSetUpListTable,
                  }),
                );
              }
            }
          }}
        />
      ),
      size: 1500,
    });
  };

  // ESL 설치 등록 테이블 행 삭제
  const handleRmEslSetUpBtn_onClick = () => {
    let selectedRows: string[] = [];

    // 삭제할 첨부 파일 아이디 목록을 불러옴
    eslSetUpTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedRows.push(item.modifyRowIndex);
    });

    let tmlEslSetUpTableData: any = eslSetUpTable.data.filter(
      (item: any) => !_.includes(selectedRows, item.modifyRowIndex),
    );

    // 행삭제 데이타 소프트웨어 테이블 적용
    setEslSetUpTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlEslSetUpTableData,
    }));
  };

  // ESL 설치 등록 수량 데이타 변경
  const handleEslSetUpTable_onCellValueChanged = (event: any) => {
    var sVal = event.value;
    var byteLength = getStringByteLength(sVal);

    if (byteLength > 10 || !_.toNumber(sVal)) {
      // 10byte 제한, 숫자만
      let tmpList: any = [];
      eslSetUpTable.data.map((item: any) => {
        if (_.isEqual(item.itemNmbr, event.data.itemNmbr)) {
          item[event.column.colId] = event.oldValue;
        }
        tmpList.push(item);
      });

      setEslSetUpTable((pre: { column: ITableColumn[]; data: any }) => ({
        ...pre,
        data: tmpList,
      }));
    }
  };

  const handleStockMoveBtn_onClick = (params: any) => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'Shop별 설치 작업결과 등록',
      content: (
        <MN2302220352_Stock
          data={params.data}
          dcmnDntfNmbr={dcmnDntfNmbr}
          splrId={splrId}
          id={tmpId}
        />
      ),
      size: 1400,
    });
  };

  /**
   *
   */

  /* //  */

  /**
   * 처리
   */

  // 입력값 byte 확인
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  // Shop별 설치 일정 계획 상세 이동 버튼 클릭
  const handleMoveDetailBtn_onClick = () => {
    setRemoveComponentModal(id);
  };

  // 작업 결과 조회(ESL)
  const getNstlWorkEsl = () => {
    // loading - start
    setEslSetUpTableLoading(true);

    nstlApi
      .getNstlWorkEsl({
        splrId: loadSplrId,
        teamId: loadTeamId,
        dcmnDntfNmbr: dcmnDntfNmbr,
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          // 이 컴포넌트의 전체 데이터에 적용함
          setApiData(data.data);

          setWorkStateSelect((pre: ISelect) => ({
            ...pre,
            value: data.data.nstlSttsCode,
          }));

          setProjectNameInput(data.data.prjcName); // 프로젝트 이름
          setCustomerNameInput(data.data.bpName); // 고객사명
          setShopNameInput(data.data.shipToCode); // Shop명
          setOrderNoInnput(dcmnScrnNmbr); // 주문번호(Doc Num - B1 출력 문서 번호)
          setPrtnNameInput(data.data.prtnName); // 협력사명
          setPrtnTeamNameInput(`${data.data.prtnName} (${data.data.teamName})`); // 협력사명
          setWorkDateInput(
            `${data.data.workStrtDttm} ~ ${data.data.workEndDttm}`,
          ); // 작업 일정
          setWorkStatusInput(data.data.nstlSttsName); // 작업 상태

          data.data.eslList.map((item: any, index: number) => {
            item['modifyRowIndex'] = index; // 수정용 key 값 생성
            item.checkbox = index;

            // 수량 정보가 공백의 경우 0 입력
            if (_.isEmpty(item.qntt)) {
              item.qntt = 0;
            }
            if (_.isEmpty(item.nstlQntt)) {
              item.nstlQntt = 0;
            }
            if (_.isEmpty(item.lostQntt)) {
              item.lostQntt = 0;
            }
            if (_.isEmpty(item.rtrnNrmlQntt)) {
              item.rtrnNrmlQntt = 0;
            }
            if (_.isEmpty(item.rtrnDfctQntt)) {
              item.rtrnDfctQntt = 0;
            }
          });
          // 소프트웨어 정보 테이블 적용
          setEslSetUpTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.eslList,
          }));
        }

        // loading - end
        setEslSetUpTableLoading(false);
      })
      .catch((error: any) => {
        // loading - end
        setEslSetUpTableLoading(false);
        // 검색 결과 테이블에 적용함
        setEslSetUpTable((pre: { column: ITableColumn[]; data: any }) => ({
          ...pre,
          loading: false,
        }));
      });
  };

  // 컴포넌트 모달 > 저장 버튼(수정)을 클릭함
  const handleModifySaveBtn_onClick = () => {

    let onGoing:boolean = true;
    eslSetUpTable.data.map((item:any, index:number) => {
      if (item.nstlQntt < 1) {
        onGoing = false;
      }
    });

    if (onGoing) {
      // 설치 수량이 0이 아닌 경우만 저장
    } else {
      setModal({
        title: '알림',
        content: '설치 수량이 0인 항목이 있습니다.',
      });
      return;
    }

    // Shop별 설치 일정 계획 수정 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // Shop 별 설치 작업결과 저장 을 처리함
              nstlApi
                .putNstlWorkEsl({
                  dcmnDntfNmbr: dcmnDntfNmbr,
                  installWorkEslParamList: eslSetUpTable.data,
                  nstlSttsCode: workStateSelect.value,
                  splrId: loadSplrId,
                  teamId: loadTeamId,
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        // setAddRefreshList('MN2302220353Table');

                        if (callback !== undefined) {
                          callback({}); // MN2302220353 의 callback
                        }
                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);
                      },
                    });
                  } else {
                    console.log(
                      '> 설치 > Shop별 설치 일정계획 수정 Error:',
                      data,
                    );

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log(
                    '> 설치 > Shop별 설치 일정계획 수정 Error:',
                    error,
                  );

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // ESL 작업결과 초기화
  const initEsl = () => {
    initWorkContSelect(); //작업 내용 셀렉트 초기화
    initWorkStateSelect(); //작업 상태 셀렉트 초기화

    initEslSetUpTable(); //ESL 설치 등록 테이블 초기화

    getNstlWorkEsl();
  };
  /* //  */

  /**************************** USE EFFECT ****************************/

  // Network 정보 변경시
  useEffect(() => {
    // Network 작업 정보 수정을 위한 javascript 영역 변수에 복사
    valEslSetUpListTable = _.cloneDeep(eslSetUpTable.data);
    return () => {};
  }, [eslSetUpTable.data]);

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    loadSplrId = splrId;
    loadTeamId = teamId;

    // ESL 작업결과 초기화
    initEsl();

    return () => {};
  }, []);

  return (
    <div className="space-y-7">
      <div className="space-y-5">
        <PbFormGrid className="mb-2" label="" cols={2}>
          <PbFormGridCol label="작업 내역" colSpan={2}>
            <PbSelect
              onChange={handleWorkContSelect_onChange}
              data={workContSelect.item}
              value={workContSelect.value}
              setSelect={setWorkContSelect}
              // className="w-80"
            />
            {/* 버튼 */}
            {/*<div className="flex justify-center items-center space-x-2 w-full">*/}
            {/*  <div className="right-btn">*/}
            {/*    <Button*/}
            {/*      variant="outline"*/}
            {/*      color="gray"*/}
            {/*      radius="xl"*/}
            {/*      onClick={handleMoveDetailBtn_onClick}*/}
            {/*    >*/}
            {/*      Shop별 설치 일정 계획 상세 이동*/}
            {/*    </Button>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </PbFormGridCol>
          <PbFormGridCol
            label="작업 상태"
            colSpan={2}
            isHidden={_.isEqual(pmInspectionResult, 'Y') ? true : false}
          >
            <PbSelect
              onChange={handleWorkStateSelect_onChange}
              data={workStateSelect.item}
              value={workStateSelect.value}
              setSelect={setWorkStateSelect}
              // className="w-80"
            />
          </PbFormGridCol>
        </PbFormGrid>
        <PbFormGrid className="mb-2" label="프로젝트 정보" cols={2}>
          <PbFormGridCol label="프로젝트 명">
            <TextInput
              placeholder=""
              value={projectNameInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객사 명">
            <TextInput
              placeholder=""
              value={customerNameInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="Shop 명">
            <TextInput
              placeholder=""
              value={shopNameInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="주문 번호">
            <TextInput
              placeholder=""
              value={orderNoInnput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        <PbFormGrid className="mb-2" label="작업 정보" cols={2}>
          <PbFormGridCol label="담당 협력사(팀)">
            <TextInput
              placeholder=""
              value={prtnTeamNameInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="작업 일정">
            <TextInput
              placeholder=""
              value={workDateInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="작업 상태" colSpan={2}>
            <TextInput
              placeholder=""
              value={workStatusInput}
              readOnly={true}
              variant={'unstyled'}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>
        <div className="content-wrapper">
          <div className="title-wrapper mt-1">
            <h3 className="leading-none">
              <span className="text-lg text-gray-600 font-semibold">
                ESL 설치 등록
              </span>
            </h3>
            <Button
              className={'ml-3'}
              variant="outline"
              color="gray"
              radius="xl"
              hidden={_.isEqual(pmInspectionResult, 'Y') ? true : false}
              onClick={handleStockMoveBtn_onClick}
            >
              재고 이동 요청
            </Button>
            <div className={'right-btn !space-x-2'}>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                hidden={_.isEqual(pmInspectionResult, 'Y') ? true : false}
                onClick={handleRmEslSetUpBtn_onClick}
              >
                - 행삭제
              </Button>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                hidden={_.isEqual(pmInspectionResult, 'Y') ? true : false}
                onClick={handleAddEslSetUpBtn_onClick}
              >
                + 행추가
              </Button>
            </div>
          </div>
          <div className="w-full h-64">
            <PbAgGridReact
              refs={eslSetUpTableRef}
              columnDefs={eslSetUpTable.column}
              rowData={eslSetUpTable.data}
              loading={eslSetUpTableLoading}
              setLoading={setEslSetUpTableLoading}
              onCellValueChanged={handleEslSetUpTable_onCellValueChanged}
              defaultColDef={{
                resizable: true,
                sortable: false,
                editable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              sizeColumnsToFit={true}
              rowSelection="single"
              overlayNoRowsTemplate="데이터가 없습니다."
              className="ag-theme-alpine"
            />
          </div>
        </div>

        {/* 댓글 섹션 */}
        {apiData !== null && (
          <div className="mt-2 align-left">
            <Comment
              id={nanoid()}
              dcmnDntfNmbr={dcmnDntfNmbr}
              splrId={loadSplrId}
              teamId={loadTeamId}
              splrTypeCode={apiData.splrTypeCode}
              callback={(result: any) => {}}
            />
          </div>
        )}
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        {/* 수정 */}
        <div>
          <Button
            variant="outline"
            color="gray"
            radius="xl"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          >
            닫기
          </Button>
        </div>
        {/* <div>
          <Button
            variant="outline"
            color="pink"
            radius="xl"
            size="md"
            // onClick={handleDeleteBtn_onClick}
          >
            삭제
          </Button>
        </div> */}
        <div className={_.isEqual(pmInspectionResult, 'Y') ? 'hidden' : ''}>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={handleModifySaveBtn_onClick}
          >
            저장
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MN2302220353_Esl;
