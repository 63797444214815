import React, { PropsWithChildren, useEffect, useState, useRef } from 'react';
import { PbAgGridReact } from '../PbAgGridReact';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  ITableColumn,
  IPageContent,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as nstlApi from '../../apis/nstl.api';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { Button, TextInput } from '@mantine/core';
import { ValueFormatterParams } from 'ag-grid-community';

interface IComponentProps {
  id: string;
  type?: string;
  dcmnDntfNmbr?: string;
  data?: any;
  onClick?: () => void;
  callback?: (data: any) => void;
}

/**
 * 설치 > 진행중작업관리 > 상세 > 품목 불러오기
 * @param id 화면아이디
 * @param type 수정여부
 * @param dcmnDntfNmbr 문서 번호
 * @param data <인자>
 * @param onClick <이벤트>
 * @params callback 부모창 데이타 return 함수
 * @constructor
 */
const MN2302220352_Item = ({
  id = '',
  type = '',
  dcmnDntfNmbr = '',
  data,
  onClick,
  callback,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**************************** API 호출 ****************************/

  // 품목 정보 불러오기
  const getNstlItems = () => {
    nstlApi
      .getNstlItems({
        dcmnDntfNmbr: dcmnDntfNmbr,
        searchKeyword: searchInput.trim(),
        pageSize: 1000,
        currPageIdx: 1,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setItemListTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));
        }
      });
  };

  /**************************** STATE ****************************/
  // 품목 테이블 정의
  const [itemListTable, setItemListTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  /**************************** ON EVENT LISTENER ****************************/
  // 검색 정의
  const [searchInput, setSearchInput] = useState<string>('');
  const handleSearchInput_onChange = (event: any) => {
    setSearchInput(event.currentTarget.value);
  };

  // 검색 버튼 클릭
  const handleSearchButton_onClick = () => {
    // 품목정보 검색
    getNstlItems();
  };

  // 품목 테이블 행을 클릭함
  const handleItemListTable_onRowDoubleClicked = (row: any) => {
    if (callback !== undefined) {
      let rtnObj = {
        modifyRowIndex: type === 'REQ' ? 0 : data.modifyRowIndex,
        itemNmbr: row.data.itemNmbr,
        itemDtls: row.data.itemDtls,
        qntt: '-',
        nstlQntt: '0',
        lostQntt: '0',
        rtrnNrmlQntt: '0',
        rtrnDfctQntt: '0',
        type: type,
      };

      callback(rtnObj);
      // 컴포넌트 모달을 닫음
      setRemoveComponentModal(id);
    }
  };

  /**************************** INIT ****************************/
  // 품목 테이블 초기화
  const initItemListTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      {
        field: 'no',
        headerName: '순번',
        width: 70,
        suppressSizeToFit: true,
      },
      {
        field: 'itemNmbr',
        headerName: '품목번호',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        field: 'itemDtls',
        headerName: '품목명',
      },
      {
        field: 'codeName',
        headerName: '제조업체',
        width: 250,
        suppressSizeToFit: true,
      },
    );

    setItemListTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /**************************** USE EFFECT ****************************/

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    initItemListTable(); // 협력사 목록 테이블 초기화(컬럼)
    return () => {};
  }, []);

  // 품목 테이블 초기화
  useEffect(() => {
    if (itemListTable.column.length === 0) {
      return;
    }
    getNstlItems();
    return () => {};
  }, [itemListTable.column]);

  return (
    <div className="space-y-7">
      <div className="space-y-5">
        <PbFormGrid cols={2}>
          <PbFormGridCol label="검색어" colSpan={2}>
            <TextInput
              placeholder="검색어를 입력하세요."
              onChange={handleSearchInput_onChange}
              value={searchInput}
              className="w-1/5"
            />
            <div className="flex justify-center items-center space-x-2">
              <Button
                color="indigo"
                radius="xl"
                onClick={handleSearchButton_onClick}
              >
                검색
              </Button>
            </div>
          </PbFormGridCol>
        </PbFormGrid>
      </div>
      <div className="space-y-5">
        <div className="w-full h-152">
          <PbAgGridReact
            columnDefs={itemListTable.column}
            rowData={itemListTable.data}
            onRowDoubleClicked={handleItemListTable_onRowDoubleClicked}
            defaultColDef={{
              resizable: true,
              sortable: false,
              wrapHeaderText: false,
              autoHeaderHeight: true,
            }}
            sizeColumnsToFit={true}
            rowSelection="single"
            overlayNoRowsTemplate="데이터가 없습니다."
            className="ag-theme-alpine"
          />
        </div>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        <div>
          <Button
            variant="outline"
            color="gray"
            radius="xl"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          >
            닫기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MN2302220352_Item;
