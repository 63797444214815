import React, { PropsWithChildren } from 'react';
import { Popover } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';

interface IComponentProps {
  icon?: any | null;
  width?: number;
  data?: any;
  onClick?: () => void;
}

/**
 * 도움말 팝오버
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const PbHelpPopover = ({
  icon = null,
  width = 200,
  data,
  onClick,
  children,
}: PropsWithChildren<IComponentProps>) => {
  // 팝오버의 출력 여부를 정의함
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover
      width={width}
      position="bottom"
      withArrow
      shadow="sm"
      opened={opened}
    >
      <Popover.Target>
        <div
          onMouseEnter={open}
          onMouseLeave={close}
          className="flex justify-center items-center"
        >
          {icon === null ? (
            <FontAwesomeIcon
              icon={['fas', 'circle-info']}
              className="w-5 h-5 text-blue-400"
            />
          ) : (
            icon
          )}
        </div>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
        {children}
      </Popover.Dropdown>
    </Popover>
  );
};

export default PbHelpPopover;
