import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { Button, Select, TextInput, Checkbox, Radio } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { MN2302220712 } from '../components/MN2302220712'; //등록,상세,수정 Component
import * as ntcbApi from '../apis/ntcb.api';
import { PbAgGridReact } from '../components/PbAgGridReact';
import PbSelect from '../components/PbSelect/PbSelect.component';
import * as appUtil from '../utils/app.util';
import { decode } from 'html-entities';

/**
 * 게시판 > 공지사항
 * @constructor
 */
const MN2302220702Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);
  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);
  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);
  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);
  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**************************** API 호출 ****************************/
  //공지사항 목록 조회
  const getNotifyList = () => {
    // 공지사항 테이블의 로딩 여부에 적용함
    setNotifyTableLoading(true);

    ntcbApi
      .getNotices({
        openYn: notifyOpenSelect,
        ntcTypeCode: notifyCategorySelect,
        ttl: notifyTitleInput,
        pageSize: notifyTablePaging.rowPerPage,
        currPageIdx: notifyTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setNotifyTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));

          // 테이블을 페이징함
          setNotifyTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }

        // 공지사항 테이블의 로딩 여부에 적용함
        setNotifyTableLoading(false);
      });
  };

  /**************************** STATE ****************************/
  //제목 검색어
  const [notifyTitleInput, setNotifyTitleInput] = useState<string>('');

  //공지사항 공개 상태 셀렉트 state
  const [notifyOpenSelect, setNotifyOpenSelect] = useState<string>('');
  const [notifyOpenSelectItem, setNotifyOpenSelectItem] = useState<
    IOptionItem[]
  >([]);

  //공지사항 유형 셀렉트 state
  const [notifyCategorySelect, setNotifyCategorySelect] = useState<string>('');
  const [notifyCategorySelectItem, setNotifyCategorySelectItem] = useState<
    IOptionItem[]
  >([]);

  //공지사항 테이블
  const notifyTableRef = useRef(null);
  //공지사항 테이블 state
  const [notifyTable, setNotifyTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  // 공지사항 테이블 페이징을 정의함
  const [notifyTablePaging, setNotifyTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const notifyTablePagingCurrentPageRef = useRef<number>(1);

  // 공지사항 테이블의 로딩 여부를 정의함
  const [notifyTableLoading, setNotifyTableLoading] = useState<boolean>(false);

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  /**************************** ON EVENT LISTENER ****************************/
  //공지사항 제목 검색어 이벤트
  const handleNotifyTitleInput_onChange = (event: any) => {
    setNotifyTitleInput(event.target.value);
  };
  //공지사항 공개 상태 셀렉트 변경 이벤트
  const handleNotifyOpenSelect_onChange = (event: any) => {
    setNotifyOpenSelect(event);
    // setNotifyOpenSelect({
    //   ...notifyOpenSelect,
    //   value: event,
    // });
  };
  //공지사항 유형 셀렉트 변경 이벤트
  const handleNotifyCategorySelect_onChange = (event: any) => {
    setNotifyCategorySelect(event);
    // setNotifyCategorySelect({
    //   ...notifyCategorySelect,
    //   value: event,
    // });
  };
  //검색 조건 초기화 버튼 이벤트
  const handleCancelBtn_onClick = () => {
    setNotifyTitleInput('');
    setNotifyOpenSelect('');
    // setNotifyOpenSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: '',
    // }));
    setNotifyCategorySelect('');
    // setNotifyCategorySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: '',
    // }));
  };
  //제목 검색 on keyup
  const handleNotifyTitleInput__onEnterKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      notifyTablePagingCurrentPageRef.current = 1;
      setNotifyTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: notifyTablePagingCurrentPageRef.current,
      }));

      getNotifyList();
    }
  };
  //제목 검색 버튼 이벤트
  const handleNotifySearchBtn_onClick = () => {
    // 테이블의 페이지를 변경함
    notifyTablePagingCurrentPageRef.current = 1;
    setNotifyTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: notifyTablePagingCurrentPageRef.current,
    }));

    getNotifyList();
  };
  //공지사항 목록 클릭 (ROW Click)
  const handleNotifyTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220712';
    //컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '공지사항 상세',
      content: (
        <MN2302220712 id={tmpId} brdId={row.data.brdId} useModifyMode={false} />
      ),
      size: 1500,
    });
  };
  //등록 버튼 클릭
  const handleNotifyAddBtn_onClick = () => {
    let tmpId: string = 'MN2302220712';
    setAddComponentModal({
      id: tmpId,
      title: '공지사항 등록',
      content: <MN2302220712 id={tmpId} />,
      size: 1500,
    });
  };

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    setHideDetailSearch(!hideDetailSearch);
  };

  /**************************** INIT ****************************/
  //공지사항 공개 상태 셀렉트 초기화
  const initNotifyOpenSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];
    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '공개',
        value: 'Y',
      },
      {
        label: '비공개',
        value: 'N',
      },
    );

    // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
    setNotifyOpenSelect('');
    setNotifyOpenSelectItem(tmpOptionItem);
    // setNotifyOpenSelect({
    //   value: '',
    //   item: tmpOptionItem,
    // });
  };

  //공지사항 유형 셀렉트 초기화
  const initNotifyCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];
    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '소식',
        value: 'NEWS',
      },
      {
        label: '네트워크',
        value: 'NTWR',
      },
      {
        label: 'ESL',
        value: 'ESL',
      },
      {
        label: '기타',
        value: 'ETC',
      },
    );

    setNotifyCategorySelect('');
    setNotifyCategorySelectItem(tmpOptionItem);
    // setNotifyCategorySelect({
    //   value: '',
    //   item: tmpOptionItem,
    // });
  };

  //공지사항 테이블 초기화
  const initNotifyTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      {
        field: 'no',
        headerName: '번호',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'ntcTypeName',
        headerName: '공지 유형',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        field: 'ttl',
        headerName: '제목',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.ttl);
        },
      },
      {
        field: 'openYn',
        headerName: '공개 상태',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: 'rgstDate',
        headerName: '등록일',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        field: 'vws',
        headerName: '조회수',
        width: 100,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      // {
      //   field: 'xpsrStrt',
      //   headerName: '공개 기간',
      //   valueFormatter: (params: ValueFormatterParams) => {
      //     return params.data.xpsrStrt + ' ~ ' + params.data.xpsrNdt;
      //   },
      //   width: 220,
      //   suppressSizeToFit: true,
      // },
      // {
      //   field: 'rgstId',
      //   headerName: '등록자',
      // },
    );

    setNotifyTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    initNotifyOpenSelect();
    initNotifyCategorySelect();
    initNotifyTable();
    return () => {};
  }, []);

  //공지사항 테이블 값 초기화
  useEffect(() => {
    if (notifyTable.column.length === 0) {
      return;
    }
    getNotifyList();
    return () => {};
  }, [notifyTable.column]);

  //공개 상태 셀렉트 박스 변경 시
  // useEffect(() => {
  //   getNotifyList();
  //   return () => {};
  // }, [notifyOpenSelect.value]);

  //유형 셀렉트 박스 변경 시
  // useEffect(() => {
  //   getNotifyList();
  //   return () => {};
  // }, [notifyCategorySelect.value]);

  //목록 새로고침 시
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }
    if (_.indexOf(refreshList, 'MN2302220702Table') > -1) {
      // 테이블의 페이지를 변경함
      notifyTablePagingCurrentPageRef.current = 1;
      setNotifyTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: notifyTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getNotifyList();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220702Table');
    }
    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      // pageInfoBarLeftArea={<></>}
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={
        <>
          <Button
            color="indigo"
            radius="xl"
            onClick={handleNotifyAddBtn_onClick}
          >
            등록
          </Button>
        </>
      }
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid label="" cols={2}>
            <PbFormGridCol label="제목" colSpan={2}>
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={handleNotifyTitleInput_onChange}
                onKeyUp={handleNotifyTitleInput__onEnterKeyPress}
                value={decode(notifyTitleInput)}
                className="w-full"
              />
              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleNotifySearchBtn_onClick}
                >
                  검색
                </Button>
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleCancelBtn_onClick}
                >
                  검색 조건 초기화
                </Button>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleShowSearchDetailButton_onChange}
                >
                  {hideDetailSearch ? (
                    <>
                      상세검색 열기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-down']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      상세검색 닫기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-up']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  )}
                </Button>
              </div>
            </PbFormGridCol>
            <PbFormGridCol
              label="공개 상태"
              colSpan={1}
              isHidden={hideDetailSearch}
            >
              <Select
                onChange={handleNotifyOpenSelect_onChange}
                data={notifyOpenSelectItem}
                value={notifyOpenSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="유형" colSpan={1} isHidden={hideDetailSearch}>
              <Select
                onChange={handleNotifyCategorySelect_onChange}
                data={notifyCategorySelectItem}
                value={notifyCategorySelect}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 테이블 */}
          <div className="space-y-3">
            <div className="flex justify-between items-center space-x-5">
              <div className="flex justify-center items-center">
                <span className="text-base text-gray-600 font-bold">
                  총 {notifyTable.data.length}건
                </span>
              </div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-160">
              <PbAgGridReact
                columnDefs={notifyTable.column}
                rowData={notifyTable.data}
                loading={notifyTableLoading}
                setLoading={setNotifyTableLoading}
                onRowDoubleClicked={handleNotifyTable_onRowDoubleClicked}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
                visiblePaging={true}
                paging={{
                  totalPage: notifyTablePaging.totalPage,
                  currentPage: notifyTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  notifyTablePagingCurrentPageRef.current = event;
                  setNotifyTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: notifyTablePagingCurrentPageRef.current,
                  }));

                  // 테이블 데이터를 불러옴
                  getNotifyList();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220702Page;
