import React, { PropsWithChildren, useEffect, useState, useRef } from 'react';
import { PbAgGridReact } from '../PbAgGridReact';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  ITableColumn,
  IPageContent,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as nstlApi from '../../apis/nstl.api';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { Button } from '@mantine/core';
import { ValueFormatterParams } from 'ag-grid-community';

interface IComponentProps {
  id: string;
  pSplrTypeCode?: string;
  callback?: (data: any) => void;
}

/**
 * 설치 > 미등록작업 목록 > 수정 > 협력사(팀) 불러오기
 * @params id 화면 아이디
 * @params pSplrTypeCode 협력사 설치 구분
 * @params callback 부모창 데이타 return 함수
 * @constructor
 */
const MN2302220351_Partner = ({
  id = '',
  pSplrTypeCode = '',
  callback,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**************************** API 호출 ****************************/
  //사용자 목록 조회
  const getNstlPrtnTeams = () => {
    nstlApi.getNstlPrtnTeams({}).then((data: IApiResult) => {
      if (data.data.list !== undefined) {
        // 설치 구분에 맞는 협력사만 가져온다.
        let typePrtnList: any = data.data.list.filter((item: any) =>
          _.isEqual(item.splrTypeCode, pSplrTypeCode),
        );
        setPrtnListTable((pre: { column: ITableColumn[]; data: any }) => ({
          ...pre,
          data: typePrtnList,
        }));
      }
    });
  };

  /**************************** STATE ****************************/

  // 협력사 테이블 state
  const [prtnListTable, setPrtnListTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  /**************************** ON EVENT LISTENER ****************************/

  // 협력사 테이블의 행을 클릭함
  const handlePrtnListTable_onRowDoubleClicked = (row: any) => {
    if (callback !== undefined) {
      callback(row.data);
      // 컴포넌트 모달을 닫음
      setRemoveComponentModal(id);
    }
  };

  /**************************** INIT ****************************/
  //협력사 테이블 초기화
  const initPrtnListTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      {
        field: 'splrTypeCode',
        headerName: '유형',
        width: 300,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.data.splrTypeCode === 'ESL_NSTL') {
            return 'ESL 설치 작업';
          } else if (params.data.splrTypeCode === 'NTWR_CNST') {
            return '네트워크 공사 작업';
          }
        },
      },
      {
        field: 'prtnName',
        headerName: '회사명',
      },
      {
        field: 'teamName',
        headerName: '팀명',
      },
      {
        field: 'cntcName',
        headerName: '담당자명',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        field: 'celPhn',
        headerName: '전화번호',
        width: 150,
        suppressSizeToFit: true,
      },
    );

    setPrtnListTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    initPrtnListTable(); // 협력사 목록 테이블 초기화(컬럼)
    return () => {};
  }, []);

  // 협력사 목록 테이블 값 초기화
  useEffect(() => {
    if (prtnListTable.column.length === 0) {
      return;
    }
    getNstlPrtnTeams();
    return () => {};
  }, [prtnListTable.column]);

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* <PbFormGrid label="검색" cols={2}>
          <PbFormGridCol label="협력사명" colSpan={2}>
            <TextInput
              placeholder="검색어를 입력하세요."
              onChange={handleCntcNameInput_onChange}
              onKeyUp={handleCnteNameInput_onKeyup}
              value={cntcNameInput}
              className="w-full"
            />
            <div className="flex justify-center items-center space-x-2">
              <Button
                color="white"
                size="sm"
                onClick={handleCancelButton_onClick}
              >초기화</Button>
              <Button
                color="purple"
                size="sm"
                onClick={handleCntcSerchBtn_onClick}
              >검색</Button>
            </div>
          </PbFormGridCol>
        </PbFormGrid> */}
        {/* 테이블 */}
        <div className="w-full h-152">
          <PbAgGridReact
            columnDefs={prtnListTable.column}
            rowData={prtnListTable.data}
            onRowDoubleClicked={handlePrtnListTable_onRowDoubleClicked}
            defaultColDef={{
              resizable: true,
              sortable: false,
              wrapHeaderText: false,
              autoHeaderHeight: true,
            }}
            sizeColumnsToFit={true}
            rowSelection="single"
            overlayNoRowsTemplate="데이터가 없습니다."
            className="ag-theme-alpine"
          />
        </div>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        <div>
          <Button
            color="white"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          >
            닫기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MN2302220351_Partner;
