import React, { PropsWithChildren } from 'react';
import { IFileDownloadList } from '../../interfaces/app.interface';
import prettyBytes from 'pretty-bytes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as cmnApi from '../../apis/cmn.api';
import * as appUtil from '../../utils/app.util';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import _ from 'lodash';

interface IComponentProps {
  data?: IFileDownloadList[];
  onClick?: () => void;
  visibleAddFileButton?: boolean;
  onClickAddFileButton?: (payload: File[]) => void;
  onClickRemoveFileButton?: (event: any) => void;
  visibleSwipeRightAction?: boolean;
  onClickFileDownloadButton?: (event: any) => void;
}

/**
 * 파일 다운로드 목록
 * @param data 파일 목록
 * @param visibleAddFileButton 파일 추가 버튼 표시 여부
 * @param onClickAddFileButton 파일 추가 버튼 클릭 이벤트
 * @param onClickRemoveFileButton 파일 삭제 버튼 클릭 이벤트
 * @param visibleSwipeRightAction 오른쪽 스위프트 액션 표시 여부
 * @constructor
 */
const FileDownloadList = ({
  data,
  onClick,
  visibleAddFileButton = false,
  onClickAddFileButton = (payload: File[]) => {},
  onClickRemoveFileButton = (event: any) => {},
  visibleSwipeRightAction = false,
  onClickFileDownloadButton = (event: any) => {},
}: PropsWithChildren<IComponentProps>) => {
  // 파일을 다운로드함
  const handleFileDownload_onClick = (event: any) => {
    cmnApi
      .getFileDownloadFileId({
        fileId: event.id,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', event.originalFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <div className="w-full space-y-2">
      {/* 파일 추가 버튼 */}
      {/*{visibleAddFileButton && (*/}
      {/*  <FileButton onChange={onClickAddFileButton} multiple={true}>*/}
      {/*    {(props) => (*/}
      {/*      <div*/}
      {/*        {...props}*/}
      {/*        className="button-event w-full py-2 bg-sky-100/50 border border-sky-200 border-dashed space-y-1 rounded"*/}
      {/*      >*/}
      {/*        <div className="flex justify-center items-center">*/}
      {/*          <FontAwesomeIcon*/}
      {/*            icon={['fas', 'plus']}*/}
      {/*            className="w-5 h-5 text-sky-500"*/}
      {/*          />*/}
      {/*        </div>*/}

      {/*        <div className="flex justify-center items-center">*/}
      {/*          <span className="text-base text-sky-700">파일 추가</span>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </FileButton>*/}
      {/*)}*/}

      {/* 첨부파일 목록 */}
      {data?.map((item: IFileDownloadList, index: number) => (
        <div
          key={index}
          onClick={() => handleFileDownload_onClick(item)}
          style={{ padding: '5px 10px' }}
          className="button-event w-full flex justify-between items-center bg-blue-200 rounded space-x-5 overflow-hidden"
        >
          <div className="grow flex justify-start items-center space-x-2">
            {/* 아이콘 */}
            <div className="flex justify-center items-center">
              <FontAwesomeIcon
                icon={
                  appUtil.getFileNameExtension(item.originalFileName)
                    .icon as IconProp
                }
                className="w-3 h-3 text-blue-600"
              />
            </div>

            {/* 파일명 */}
            <div className="flex justify-center items-center">
              <span className="text-sm text-gray-700 font-medium">
                {item.originalFileName}
              </span>
            </div>
          </div>

          <div className="flex-none w-16 flex justify-end items-center">
            {/* 파일 용량 */}
            <span className="text-xs text-gray-700 font-medium">
              {prettyBytes(_.toNumber(item.size || 0))}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FileDownloadList;
