import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useElementSize } from '@mantine/hooks';
import _ from 'lodash';
import { PbTable } from '../PbTable';
import {
  IApiResult,
  IComponentModal,
  ITableColumn,
} from '../../interfaces/app.interface';
import * as csApi from '../../apis/cs.api';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { nanoid } from 'nanoid';
import { PbButton } from '../PbButton';
import { MN2302220502_NetworkDetail } from '../MN2302220502_NetworkDetail';
import { LoadingTableDataIcon } from '../LoadingTableDataIcon';
import { AgGridReact } from 'ag-grid-react';
import { PbAgGridReact } from '../PbAgGridReact';

interface IComponentProps {
  id?: string;
  data?: any;
  prjcCode?: string;
  bpCode?: string;
  shipToCode?: string;
  onClick?: () => void;
}

/**
 * 운영 관리 > CS 조회 > 등록 > 네트워크 작업 결과
 * @param id 컴포넌트 모달 아이디
 * @param data <인자>
 * @param prjcCode 프로젝트코드
 * @param bpCode 고객사코드
 * @param shipToCode shop코드(shopId 또는 shipToCode라 함)
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220502_Network = ({
  id = '',
  data,
  prjcCode = '',
  bpCode = '',
  shipToCode = '',
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 페이지의 내용 영역 크기를 정의함
  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useElementSize();

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 네트워크 공사 작업 결과 테이블을 정의함
  const [networkTable, setNetworkTable] = useState<{
    column: any;
    data: any;
    loading?: boolean;
  }>({ column: [], data: [], loading: false });

  // 네트워크 공사 작업 결과 테이블의 로딩 여부를 정의함
  const [networkTableLoading, setNetworkTableLoading] =
    useState<boolean>(false);

  // 네트워크 공사 작업 결과 테이블을 초기화함
  const initNetworkTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '문서고유번호(주문번호)',
        field: 'dcmnDntfNmbr',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: '협력사명',
        field: 'prtnName',
        width: 300,
        suppressSizeToFit: true,
      },
      { headerName: '제목', field: 'ttl' },
      {
        headerName: '작업시작일시',
        field: 'workStrtDttm',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: '작업종료일시',
        field: 'workEndDttm',
        width: 200,
        suppressSizeToFit: true,
      },
    );

    // 검색 결과 테이블에 적용함
    setNetworkTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 네트워크 공사 작업 결과 테이블 데이터를 불러옴
  const getNetworkTableData = () => {
    // 네트워크 공사 작업 결과 테이블의 로딩 여부에 적용함
    setNetworkTableLoading(true);

    csApi
      .getNtwrRstl({
        prjcCode: prjcCode,
        bpCode: bpCode,
        shipToCode: shipToCode,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setNetworkTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));

          // 네트워크 공사 작업 결과 테이블의 로딩 여부에 적용함
          setNetworkTableLoading(false);
        }
      })
      .catch((error: any) => {
        // 네트워크 공사 작업 결과 테이블의 로딩 여부에 적용함
        setNetworkTableLoading(false);
      });
  };

  // 네트워크 공사 작업 결과 테이블의 행을 클릭함
  const handleNetworkTable_onRowDoubleClicked = (row: any) => {
    // 상세 이력을 불러옴
    let tmpDetailData: any[] = _.sortBy(row.data.ntwrList, ['sortRdr']);

    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: `네트워크 작업 결과 상세 이력: ${row.data.prtnName} / ${row.data.ttl}`,
      content: (
        <MN2302220502_NetworkDetail id={tmpId} detailData={tmpDetailData} />
      ),
      size: 1000,
    });
  };

  useEffect(() => {
    // 네트워크 공사 작업 결과 테이블을 초기화함
    initNetworkTable();

    return () => {};
  }, []);

  // 네트워크 공사 작업 결과 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (networkTable.column.length === 0) {
      return;
    }

    // 네트워크 공사 작업 결과 테이블 데이터를 불러옴
    getNetworkTableData();

    return () => {};
  }, [networkTable.column]);

  return (
    <div className="space-y-7">
      {/* 테이블 */}
      <div className="w-full h-152">
        <PbAgGridReact
          columnDefs={networkTable.column}
          rowData={networkTable.data}
          loading={networkTableLoading}
          setLoading={setNetworkTableLoading}
          onRowDoubleClicked={handleNetworkTable_onRowDoubleClicked}
          defaultColDef={{
            resizable: true,
            sortable: true,
            wrapHeaderText: false,
            autoHeaderHeight: true,
          }}
          rowSelection="single"
          sizeColumnsToFit={true}
        />
      </div>

      {/* 테이블 */}
      {/*<div className="relative w-full h-96">*/}
      {/*  /!* 로딩 중 아이콘 *!/*/}
      {/*  <LoadingTableDataIcon visible={networkTable.loading} />*/}

      {/*  <AgGridReact*/}
      {/*    columnDefs={networkTable.column}*/}
      {/*    rowData={networkTable.data}*/}
      {/*    onRowClicked={handleNetworkTable_onRowDoubleClicked}*/}
      {/*    defaultColDef={{*/}
      {/*      initialWidth: 150,*/}
      {/*      resizable: true,*/}
      {/*      sortable: true,*/}
      {/*      wrapHeaderText: false,*/}
      {/*      autoHeaderHeight: true,*/}
      {/*      suppressSizeToFit: true,*/}
      {/*    }}*/}
      {/*    rowSelection="single"*/}
      {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
      {/*    className="ag-theme-alpine"*/}
      {/*  ></AgGridReact>*/}
      {/*</div>*/}

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        <div>
          <PbButton
            label="닫기"
            backgroundColor="white"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          />
        </div>
      </div>
    </div>
  );
};

export default MN2302220502_Network;
