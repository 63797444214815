import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useDownloadExcel } from 'react-export-table-to-excel';
import _ from 'lodash';
import {
  IApiResult,
  ILeftMenu,
  ILoginUser,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
  ISelect,
  IOptionItem,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import { Button, Select, TextInput } from '@mantine/core';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { PbAgGridReact } from '../components/PbAgGridReact';
import MN2302220880 from '../components/MN2302220880/MN2302220880.component';
import * as appUtil from '../utils/app.util';
import * as nvntApi from '../apis/nvnt.api';
import { ValueFormatterParams } from 'ag-grid-community';
import { decode } from 'html-entities';
import { useDisclosure, useResizeObserver } from '@mantine/hooks';
import { cubicBezier, motion } from 'framer-motion';
import {
  getTeamsItemItemNmbr,
  getTeamsItemItemNmbrWrhsCode,
} from '../apis/nvnt.api';
import { notifications } from '@mantine/notifications';

/**
 * 현황 관리 > 현장 재고 현황
 * @constructor
 */
const MN2302220405Page = () => {
  /**
   * 저장소
   */

  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**
   * 검색
   */

  // 검색어 입력을 정의함
  const [searchTextInput, setSearchTextInput] = useState<string>('');
  const searchTextInputRef = useRef<string>('');

  // 품목명/품목코드 검색어 입력을 정의함
  const [searchText2Input, setSearchText2Input] = useState<string>('');
  const searchText2InputRef = useRef<string>('');

  // PM 검수상태 셀렉트를 정의함
  const [searchPmStatusSelect, setSearchPmStatusSelect] =
    useState<string>('CMPL');
  const [searchPmStatusSelectItem, setSearchPmStatusSelectItem] = useState<
    IOptionItem[]
  >([]);
  const searchPmStatusSelectRef = useRef<HTMLInputElement>(null);

  // 검색어 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchTextInput(event.target.value);
    searchTextInputRef.current = event.target.value;
  };

  // 검색어 입력에서 키를 입력함
  const handleSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 품목명/품목코드 검색어 입력의 값을 변경함
  const handleSearch2Input_onChange = (event: any) => {
    setSearchText2Input(event.target.value);
    searchText2InputRef.current = event.target.value;
  };

  // 품목명/품목코드 검색어 입력에서 키를 입력함
  const handleSearch2Input_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // PM 검수상태 셀렉트를 초기화함
  const initSearchPmStatusSelect = async () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '검수완료',
        value: 'CMPL',
      },
      {
        label: '미검수',
        value: 'NCMP',
      },
    );

    // 셀렉트 아이템에 적용함
    setSearchPmStatusSelectItem(tmpOptionItem);
  };

  // PM 검수상태 셀렉트에서 선택한 아이템을 변경함
  const handleSearchPmStatusSelect_onChange = (event: any) => {
    setSearchPmStatusSelect(event);
  };

  // 검색 버튼을 클릭함
  const handleSearchButton_onClick = () => {
    // 테이블의 페이지를 변경함
    searchResultTablePagingCurrentPageRef.current = 1;
    setSearchResultTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: searchResultTablePagingCurrentPageRef.current,
    }));

    // 테이블의 데이터를 불러옴
    getSearchResultTableData();
  };

  // 검색 조건 초기화 버튼을 클릭함
  const handleSearchCancelButton_onClick = () => {
    // 설치 그룹명을 초기화함
    setSearchTextInput('');
    searchTextInputRef.current = '';

    // 품목명/품목코드를 초기화함
    setSearchText2Input('');
    searchText2InputRef.current = '';

    // 검색 버튼을 클릭함
    handleSearchButton_onClick();
  };

  /**
   * 검색 결과 테이블
   */

  // 테이블의 크기 감시를 정의함
  const [searchResultTableSizeRef, searchResultTableRect] = useResizeObserver();

  // 테이블을 정의함
  const [searchResultTableColumn, setSearchResultTableColumn] = useState<any>(
    [],
  );
  const [searchResultTable, setSearchResultTable] = useState<any>([]);
  const searchResultTableRef = useRef<any>(null);

  // 테이블 페이징을 정의함
  const [searchResultTablePaging, setSearchResultTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const searchResultTablePagingCurrentPageRef = useRef<number>(1);

  // 테이블의 로딩 여부를 정의함
  const [searchResultTableLoading, setSearchResultTableLoading] =
    useState<boolean>(false);

  // 초기 컬럼을 정의함
  const defaultColumn: any = [
    {
      field: 'no',
      headerName: '번호',
      width: 80,
      suppressSizeToFit: true,
      type: 'rightAligned',
      valueFormatter: (params: any) =>
        appUtil.getFormatNumber(params.value || 0),
    },
    {
      field: 'itemDtls',
      headerName: '품목명',
      width: 300,
      suppressSizeToFit: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return decode(params.data.itemDtls);
      },
    },
    {
      field: 'itemNmbr',
      headerName: '품목코드',
      width: 120,
      suppressSizeToFit: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return decode(params.data.itemNmbr);
      },
    },
    {
      field: 'grpNvntSum',
      headerName: '합계',
      width: 120,
      suppressSizeToFit: true,
      type: 'rightAligned',
      valueFormatter: (params: any) =>
        appUtil.getFormatNumber(params.value || 0),
    },
  ];

  // 테이블을 초기화함
  const initSearchResultTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn = defaultColumn;

    // 테이블에 적용함
    setSearchResultTableColumn(tmpColumn);
  };

  // 테이블의 데이터를 불러옴
  const getSearchResultTableData = () => {
    // 검색 결과 테이블의 로딩 여부에 적용함
    setSearchResultTableLoading(true);

    nvntApi
      .getGrpsItems({
        grpSearchKeyword: searchTextInputRef.current.trim(),
        itemSearchKeyword: searchText2InputRef.current.trim(),
        pmNspcStts: searchPmStatusSelect,
        wrhsCode: '', // 창고코드
        pageSize: searchResultTablePaging.rowPerPage,
        currPageIdx: searchResultTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        console.log('> data:', data);

        if (data.data.list !== undefined) {
          // 추가할 컬럼을 정의함
          let tmpColumn: any = [];

          data.data.list.map((item: any, index: number) => {
            item.grpNvnt.map((subItem: any, subIndex: number) => {
              if (_.findIndex(tmpColumn, { field: subItem.grpId }) < 0) {
                // 컬럼에 추가함
                tmpColumn.push({
                  field: subItem.grpId,
                  headerName: subItem.grpNm,
                  width: 110,
                  suppressSizeToFit: true,
                  type: 'rightAligned',
                  valueFormatter: (params: any) =>
                    appUtil.getFormatNumber(params.value || 0),
                });
              }

              // 설치팀별로 재고를 추가함
              item[subItem.grpId] = subItem.nvntCnt;
            });
          });

          // 설치 그룹 이름 순서로 컬럼을 정렬함
          tmpColumn = _.sortBy(tmpColumn, 'headerName');

          // 테이블에 적용함
          setSearchResultTableColumn([...defaultColumn, ...tmpColumn]);

          // 테이블에 적용함
          setSearchResultTable(data.data.list);

          // 테이블을 페이징함
          setSearchResultTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }
      })
      .finally(() => {
        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      });
  };

  // 테이블의 행을 더블클릭함
  const handleSearchResultTable_onRowDoubleClicked = (row: any) => {
    // 상세 검색 결과 품목 이름 및 품목 코드에 적용함
    setSelectedDetailItemName(decode(row.data.itemDtls));
    setSelectedDetailItemCode(decode(row.data.itemNmbr));

    console.log('> row.data:', row.data);

    nvntApi
      .getTeamsItemItemNmbrWrhsCode({
        itemNmbr: row.data.itemNmbr,
        pmNspcStts: searchPmStatusSelect,
        wrhsCode: '03ONS', // 현장창고코드
      })
      .then((data: IApiResult) => {
        console.log('> data:', data);

        if (data.data !== undefined) {
          // 테이블에 적용함
          setDetailSearchResultTable(data.data);

          // 결과가 있을 때만 상세 검색 결과 레이어를 출력함
          if (data.data.length > 0) {
            // 상세 검색 결과 레이어를 출력함
            openDetailSearchResultTable();
          } else {
            notifications.show({
              title: '알림',
              message: '상세 정보가 없습니다.',
            });

            // 상세 검색 결과 레이어를 닫음
            closeDetailSearchResultTable();
          }
        }
      })
      .finally(() => {
        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      });
  };

  /**
   * 상세 검색 결과 테이블
   */

  // 상세 검색 결과 품목 이름 및 품목 코드를 정의함
  const [selectedDetailItemName, setSelectedDetailItemName] =
    useState<string>('');
  const [selectedDetailItemCode, setSelectedDetailItemCode] =
    useState<string>('');

  // 테이블의 출력 여부를 정의함
  const [
    openedDetailSearchResultTable,
    { open: openDetailSearchResultTable, close: closeDetailSearchResultTable },
  ] = useDisclosure(false);

  // 테이블을 정의함
  const [detailSearchResultTableColumn, setDetailSearchResultTableColumn] =
    useState<any>([]);
  const [detailSearchResultTable, setDetailSearchResultTable] = useState<any>(
    [],
  );
  const detailSearchResultTableRef = useRef<any>(null);

  // 테이블 페이징을 정의함
  const [detailSearchResultTablePaging, setDetailSearchResultTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const detailSearchResultTablePagingCurrentPageRef = useRef<number>(1);

  // 테이블의 로딩 여부를 정의함
  const [detailSearchResultTableLoading, setDetailSearchResultTableLoading] =
    useState<boolean>(false);

  // 테이블을 초기화함
  const initDetailSearchResultTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'grpName',
        headerName: '설치그룹',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.grpName);
        },
      },
      {
        field: 'teamName',
        headerName: '설치팀',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.teamName);
        },
      },
      {
        field: 'crNvntQntt',
        headerName: '재고',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'crFltyQntt',
        headerName: '불량',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'crBrknQntt',
        headerName: '파손',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'crLossQntt',
        headerName: '분실',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
    );

    // 테이블에 적용함
    setDetailSearchResultTableColumn(tmpColumn);
  };

  // 테이블의 데이터를 불러옴
  const getDetailSearchResultTableData = () => {
    // 검색 결과 테이블의 로딩 여부에 적용함
    setDetailSearchResultTableLoading(true);

    // grpApi
    //   .getGrps({
    //     searchKeyword: searchTextInputRef.current.trim(),
    //     pageSize: searchResultTablePaging.rowPerPage,
    //     currPageIdx: searchResultTablePagingCurrentPageRef.current,
    //   })
    //   .then((data: IApiResult) => {
    //     if (data.data.list !== undefined) {
    //       // 테이블에 적용함
    //       setDetailSearchResultTable(data.data.list);
    //
    //       // 테이블을 페이징함
    //       setDetailSearchResultTablePaging((pre: ITablePaging) => ({
    //         ...pre,
    //         totalPage: data.data.page.totPageCnt,
    //         totalRow: data.data.page.totItemCnt,
    //       }));
    //     }
    //   })
    //   .finally(() => {
    //     // 검색 결과 테이블의 로딩 여부에 적용함
    //     setDetailSearchResultTableLoading(false);
    //   });
  };

  /**
   * 초기화
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // PM 검수상태 셀렉트를 초기화함
    initSearchPmStatusSelect();

    // 검색 결과 테이블을 초기화함
    initSearchResultTable();

    // 테이블의 데이터를 불러옴
    getSearchResultTableData();

    // 상세 검색 결과 테이블을 초기화함
    initDetailSearchResultTable();

    return () => {};
  }, []);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220808Page') > -1) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 테이블의 데이터를 불러옴
      getSearchResultTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220808Page');
    }

    // 검색어 입력의 값을 초기화함
    setSearchTextInput('');
    searchTextInputRef.current = '';

    return () => {};
  }, [refreshList]);

  /**
   * 렌더링
   */

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-5">
        {/* 검색 폼 그리드 */}
        <PbFormGrid label="검색" cols={2}>
          <PbFormGridCol label="설치 그룹명">
            {/* 검색어 텍스트 입력 */}
            <TextInput
              placeholder="검색어를 입력하세요."
              size="sm"
              onChange={handleSearchInput_onChange}
              onKeyUp={handleSearchInput_onKeyUp}
              value={searchTextInput}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="품목명 / 품목코드">
            {/* 검색어 텍스트 입력 */}
            <TextInput
              placeholder="검색어를 입력하세요."
              size="sm"
              onChange={handleSearch2Input_onChange}
              onKeyUp={handleSearch2Input_onKeyUp}
              value={searchText2Input}
              className="w-full"
            />

            {/* 버튼 */}
            <div className="flex justify-center items-center space-x-2">
              {/* 검색 버튼 */}
              <Button
                color="indigo"
                radius="xl"
                onClick={handleSearchButton_onClick}
              >
                검색
              </Button>

              {/* 검색 조건 초기화 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                onClick={handleSearchCancelButton_onClick}
              >
                검색 조건 초기화
              </Button>
            </div>
          </PbFormGridCol>

          <PbFormGridCol label="PM 검수상태">
            <Select
              ref={searchPmStatusSelectRef}
              onChange={handleSearchPmStatusSelect_onChange}
              data={searchPmStatusSelectItem}
              value={searchPmStatusSelect}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="" isBlank={true}></PbFormGridCol>
        </PbFormGrid>

        {/* 검색 결과 테이블 */}
        <div className="space-y-3 overflow-hidden">
          {/* 조회 결과 개수 */}
          <div className="flex justify-between items-center space-x-5">
            <div></div>

            <div className="flex justify-center items-center space-x-5">
              <div className="flex justify-center items-center">
                <span className="text-base text-gray-600 font-bold">
                  총 {searchResultTablePaging.totalRow}건
                </span>
              </div>
            </div>
          </div>

          {/* 테이블 */}
          <div ref={searchResultTableSizeRef} className="relative w-full h-160">
            <PbAgGridReact
              refs={searchResultTableRef}
              columnDefs={searchResultTableColumn}
              rowData={searchResultTable}
              loading={searchResultTableLoading}
              setLoading={setSearchResultTableLoading}
              onRowDoubleClicked={handleSearchResultTable_onRowDoubleClicked}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="single"
              sizeColumnsToFit={true}
              visiblePaging={true}
              paging={{
                totalPage: searchResultTablePaging.totalPage,
                currentPage: searchResultTablePaging.currentPage,
              }}
              onChangePage={(event: any) => {
                // 테이블의 페이지를 변경함
                searchResultTablePagingCurrentPageRef.current = event;
                setSearchResultTablePaging((pre: ITablePaging) => ({
                  ...pre,
                  currentPage: searchResultTablePagingCurrentPageRef.current,
                }));

                // 테이블의 데이터를 불러옴
                getSearchResultTableData();
              }}
            />

            {/* 상세 검색 결과 테이블 팝업 */}
            <motion.div
              initial="close"
              animate={openedDetailSearchResultTable ? 'open' : 'close'}
              variants={{
                open: {
                  x: 0,
                  opacity: 1,
                  display: 'block',
                },
                close: {
                  x: 200,
                  opacity: 0,
                  transitionEnd: {
                    display: 'none',
                  },
                },
              }}
              transition={{
                ease: cubicBezier(0.16, 1, 0.3, 1),
                duration: 0.5,
              }}
              style={{ height: searchResultTableRect.height - 50 }}
              className="absolute right-0 top-0 w-160 px-1 py-1 bg-white border-1 border-gray-400 z-20"
            >
              <div className="space-y-3">
                <div className="flex justify-between items-center">
                  {/* 품목 정보 */}
                  <div className="space-x-2">
                    <span className="text-sm text-gray-600 font-bold">
                      품목:
                    </span>
                    <span className="text-sm text-gray-600 font-semibold">
                      {selectedDetailItemName}({selectedDetailItemCode})
                    </span>
                  </div>

                  {/* 닫기 버튼 */}
                  <div
                    onClick={closeDetailSearchResultTable}
                    className="button-event flex justify-center items-center"
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'close']}
                      className="w-5 h-5 text-gray-400"
                    />
                  </div>
                </div>

                {/* 테이블 */}
                <div
                  style={{ height: searchResultTableRect.height - 110 }}
                  className="w-full"
                >
                  <PbAgGridReact
                    refs={detailSearchResultTableRef}
                    columnDefs={detailSearchResultTableColumn}
                    rowData={detailSearchResultTable}
                    loading={detailSearchResultTableLoading}
                    setLoading={setDetailSearchResultTableLoading}
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      wrapHeaderText: false,
                      autoHeaderHeight: true,
                    }}
                    rowSelection="single"
                    sizeColumnsToFit={true}
                    visiblePaging={false}
                    paging={{
                      totalPage: detailSearchResultTablePaging.totalPage,
                      currentPage: detailSearchResultTablePaging.currentPage,
                    }}
                    onChangePage={(event: any) => {
                      // 테이블의 페이지를 변경함
                      detailSearchResultTablePagingCurrentPageRef.current =
                        event;
                      setDetailSearchResultTablePaging((pre: ITablePaging) => ({
                        ...pre,
                        currentPage:
                          detailSearchResultTablePagingCurrentPageRef.current,
                      }));

                      // 테이블의 데이터를 불러옴
                      getDetailSearchResultTableData();
                    }}
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220405Page;
