import { atom } from 'recoil';
import { ILanguage } from '../interfaces/app.interface';

/**
 * 현재 선택한 언어
 */
export const languageStore = atom({
  key: 'languageStore',
  default: {
    language: '',
  } as ILanguage,
});
