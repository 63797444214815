import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Button,
  Drawer,
  Tabs,
  Textarea,
  TextInput,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbFormGridCol } from '../PbFormGridCol';
import { DatePickerInput } from '@mantine/dates';
import { PbFormGrid } from '../PbFormGrid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  IApiResult,
  IComponentModal,
  IComponentModalPageScrollTab,
  ILeftMenu,
  ILoginUser,
  IModal,
  ITablePaging,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import _ from 'lodash';
import * as workApi from '../../apis/work.api';
import PbSection from '../PbSection/PbSection.component';
import { PbAgGridReact } from '../PbAgGridReact';
import { decode } from 'html-entities';
import { nanoid } from 'nanoid';
import { MN2302220202_ManagerClient } from '../MN2302220202_ManagerClient';
import { MN2302220305_WorkDistribution } from '../MN2302220305_WorkDistribution';
import moment from 'moment';
import { notifications } from '@mantine/notifications';
import { MN2302220603_SelectWorkType } from '../MN2302220603_SelectWorkType';
import { MN2302220305_ChangeStep } from '../MN2302220305_ChangeStep';
import { ValueFormatterParams } from 'ag-grid-community';
import { FileDownloadList } from '../FileDownloadList';
import { ImageFilePreviewList } from '../ImageFilePreviewList';
import * as slsApi from '../../apis/sls.api';
import { useDisclosure } from '@mantine/hooks';
import * as nstlApi from '../../apis/nstl.api';

interface IComponentProps {
  id?: string;
  dcmnDntfNmbr: string;
  useModifyMode?: boolean;
  data?: any;
  onClick?: () => void;
}

/**
 * 설치 관리 > 작업 현황 > 상세
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220305 = ({
  id = '',
  dcmnDntfNmbr = '',
  useModifyMode = false,
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 상세 내용을 정의함
  const [detailData, setDetailData] = useState<any>(null);

  // 네트워크 탭 > 설치일정 테이블을 정의함
  const [networkInstallDateTable, setNetworkInstallDateTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 네트워크 탭 > 팀 테이블을 정의함
  const [networkTeamTable, setNetworkTeamTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 네트워크 탭 > 소프트웨어를 정의함
  const [networkSoftware, setNetworkSoftware] = useState<any[]>([]);

  // ESL 탭 > 설치일정 테이블을 정의함
  const [eslInstallDateTable, setEslInstallDateTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // ESL 탭 > 팀 테이블을 정의함
  const [eslTeamTable, setEslTeamTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // ESL 탭 > 설치품목 테이블을 정의함
  const [eslInstallItemTable, setEslInstallItemTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 납품 탭 > 내용을 정의함
  const [deliverContent, setDeliverContent] = useState<string>('');

  // 납품 탭 > 납품일시를 정의함
  const [deliverDate, setDeliverDate] = useState<string>('');

  // 납품 탭 > 납품상태를 정의함
  const [deliverStatus, setDeliverStatus] = useState<string>('');

  // 품목 탭 > 설치정보 테이블을 정의함
  const [installItemTable, setInstallItemTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 메모 입력을 정의함
  const [memo, setMemo] = useState<string>('');

  // 네트워크 탭 > 설치일정 테이블을 초기화함
  const initNetworkInstallDateTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '협력사',
        field: 'prtnName',
        width: 110,
        suppressSizeToFit: true,
      },
      {
        headerName: '팀',
        field: 'teamName',
        width: 140,
        suppressSizeToFit: true,
      },
      {
        headerName: '내용',
        field: 'ttl',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.ttl);
        },
      },
      {
        headerName: '작업일정',
        field: 'pmName',
        width: 170,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="h-12 flex justify-start items-center">
            <div className="w-full space-y-1">
              {/* 시작일자 */}
              <div className="leading-none truncate">
                <span className="">
                  {moment(params.data.workStrtDttm, 'YYYYMMDDHHmm').format(
                    'YYYY.MM.DD HH:mm',
                  )}{' '}
                  ~
                </span>
              </div>

              {/* 중료일자 */}
              <div className="leading-none truncate">
                <span className="">
                  {moment(params.data.workEndDttm, 'YYYYMMDDHHmm').format(
                    'YYYY.MM.DD HH:mm',
                  )}
                </span>
              </div>
            </div>
          </div>
        ),
      },
      {
        headerName: '설치상태',
        field: 'nstlSttsName',
        width: 110,
        suppressSizeToFit: true,
      },
      {
        headerName: '작업완료일시\n작업완료담당자',
        field: 'workCmplDate',
        width: 160,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="h-12 flex justify-start items-center">
            <div className="w-full space-y-1">
              {/* 일자 */}
              <div className="leading-none truncate">
                <span className="">{params.data.workCmplDate}</span>
              </div>

              {/* 담당자 */}
              <div className="leading-none truncate">
                <span className="">{params.data.workCmplNm}</span>
              </div>
            </div>
          </div>
        ),
      },
      {
        headerName: '검수완료일시\n검수완료담당자',
        field: 'nspcCmplDate',
        width: 160,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="h-12 flex justify-start items-center">
            <div className="w-full space-y-1">
              {/* 일자 */}
              <div className="leading-none truncate">
                <span className="">{params.data.nspcCmplDate}</span>
              </div>

              {/* 담당자 */}
              <div className="leading-none truncate">
                <span className="">{params.data.nspcCmplNm}</span>
              </div>
            </div>
          </div>
        ),
      },
      {
        headerName: '점검항목\n(완료 / 전체)',
        field: 'checkList',
        width: 120,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="cursor-pointer">
            {params.data.chckYsCnt || 0} / {params.data.chckTtlCnt || 0}
          </div>
        ),
      },
      // {
      //   headerName: '작업상태',
      //   field: 'prtnWorkName',
      //   width: 120,
      //   suppressSizeToFit: true,
      // },
    );

    // 테이블에 적용함
    setNetworkInstallDateTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 네트워크 탭 > 팀 테이블을 초기화함
  const initNetworkTeamTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: 'NO',
        field: 'no',
        width: 80,
        suppressSizeToFit: true,
      },
      {
        headerName: '협력사',
        field: 'prtnName',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '팀',
        field: 'teamName',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '내용',
        field: 'cntrName',
      },
      {
        headerName: '작업상태',
        field: 'prtnWorkName',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '작업일정',
        field: 'pmName',
        width: 200,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => decode(params.value),
      },
    );

    // 테이블에 적용함
    setNetworkTeamTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // ESL 탭 > 설치일정 테이블을 초기화함
  const initEslInstallDateTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '협력사',
        field: 'prtnName',
        width: 110,
        suppressSizeToFit: true,
      },
      {
        headerName: '팀',
        field: 'teamName',
        width: 140,
        suppressSizeToFit: true,
      },
      {
        headerName: '내용',
        field: 'ttl',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.ttl);
        },
      },
      {
        headerName: '작업일정',
        field: 'pmName',
        width: 170,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="h-12 flex justify-start items-center">
            <div className="w-full space-y-1">
              {/* 시작일자 */}
              <div className="leading-none truncate">
                <span className="">
                  {moment(params.data.workStrtDttm, 'YYYYMMDDHHmm').format(
                    'YYYY.MM.DD HH:mm',
                  )}{' '}
                  ~
                </span>
              </div>

              {/* 중료일자 */}
              <div className="leading-none truncate">
                <span className="">
                  {moment(params.data.workEndDttm, 'YYYYMMDDHHmm').format(
                    'YYYY.MM.DD HH:mm',
                  )}
                </span>
              </div>
            </div>
          </div>
        ),
      },
      {
        headerName: '설치상태',
        field: 'nstlSttsName',
        width: 110,
        suppressSizeToFit: true,
      },
      {
        headerName: '작업완료일시\n작업완료담당자',
        field: 'workCmplDate',
        width: 160,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="h-12 flex justify-start items-center">
            <div className="w-full space-y-1">
              {/* 일자 */}
              <div className="leading-none truncate">
                <span className="">{params.data.workCmplDate}</span>
              </div>

              {/* 담당자 */}
              <div className="leading-none truncate">
                <span className="">{params.data.workCmplNm}</span>
              </div>
            </div>
          </div>
        ),
      },
      {
        headerName: '검수완료일시\n검수완료담당자',
        field: 'nspcCmplDate',
        width: 160,
        suppressSizeToFit: true,
        // cellRenderer: (params: any) => (
        //   <>
        //     {moment(params.data.nspcCmplDate, 'YYYYMMDDHHmm').format(
        //       'YYYY.MM.DD HH:mm',
        //     )}
        //   </>
        // ),
        cellRenderer: (params: any) => (
          <div className="h-12 flex justify-start items-center">
            <div className="w-full space-y-1">
              {/* 일자 */}
              <div className="leading-none truncate">
                <span className="">{params.data.nspcCmplDate}</span>
              </div>

              {/* 담당자 */}
              <div className="leading-none truncate">
                <span className="">{params.data.nspcCmplNm}</span>
              </div>
            </div>
          </div>
        ),
      },
      {
        headerName: '점검항목\n(완료 / 전체)',
        field: 'checkList',
        width: 120,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <div className="cursor-pointer">
            {params.data.chckYsCnt || 0} / {params.data.chckTtlCnt || 0}
          </div>
        ),
      },
      // {
      //   headerName: '작업상태',
      //   field: 'prtnWorkName',
      //   width: 120,
      //   suppressSizeToFit: true,
      // },
    );

    // 테이블에 적용함
    setEslInstallDateTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // ESL 탭 > 팀 테이블을 초기화함
  const initEslTeamTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: 'NO',
        field: 'no',
        width: 80,
        suppressSizeToFit: true,
      },
      {
        headerName: '협력사',
        field: 'prtnName',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '팀',
        field: 'teamName',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '내용',
        field: 'cntrName',
      },
      {
        headerName: '작업상태',
        field: 'prtnWorkName',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '작업일정',
        field: 'pmName',
        width: 200,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => decode(params.value),
      },
    );

    // 테이블에 적용함
    setEslTeamTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // ESL 탭 > 설치품목 테이블을 초기화함
  const initEslInstallItemTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '구분',
        children: [
          { headerName: '품목번호', field: 'itemNmbr', width: 100 },
          {
            headerName: '품목명',
            field: 'itemDtls',
            width: 300,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.value);
            },
          },
          {
            headerName: '계획',
            field: 'qntt',
            width: 100,
            type: 'rightAligned',
          },
        ],
      },
      {
        headerName: '현장',
        children: [
          {
            headerName: '수령',
            field: 'rcvdQntt',
            width: 100,
            type: 'rightAligned',
          },
          {
            headerName: '설치',
            field: 'nstlQntt',
            width: 100,
            type: 'rightAligned',
          },
          {
            headerName: '여유',
            field: 'freQntt',
            width: 100,
            type: 'rightAligned',
          },
          {
            headerName: '분실',
            field: 'lostQntt',
            width: 100,
            type: 'rightAligned',
          },
          {
            headerName: '불량',
            field: 'porQntt',
            width: 100,
            type: 'rightAligned',
          },
          {
            headerName: '파손',
            field: 'brkgQntt',
            width: 100,
            type: 'rightAligned',
          },
        ],
      },
      {
        headerName: '팀재고',
        children: [
          {
            headerName: '보유',
            field: 'aaa',
            width: 100,
            type: 'rightAligned',
          },
          {
            headerName: '사용',
            field: 'bbb',
            width: 100,
            type: 'rightAligned',
          },
          {
            headerName: '잔여',
            field: 'ccc',
            width: 100,
            type: 'rightAligned',
          },
        ],
      },
      {
        headerName: '재고이전(예상)',
        children: [
          {
            headerName: '재고추가',
            field: 'aaaa',
            width: 100,
            type: 'rightAligned',
          },
          {
            headerName: '재고반납일반',
            field: 'bbbb',
            width: 120,
            type: 'rightAligned',
          },
          {
            headerName: '재고반납불량',
            field: 'cccc',
            width: 120,
            type: 'rightAligned',
          },
        ],
      },
    );

    // 테이블에 적용함
    setEslInstallItemTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 품목 탭 > 설치정보 테이블을 초기화함
  const initInstallItemTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '품목번호',
        field: 'itemNmbr',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '품목명',
        field: 'itemDtls',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.value);
        },
      },
      {
        headerName: '창고',
        field: 'wrhsName',
        width: 130,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.value);
        },
      },
      {
        headerName: '계획',
        field: 'qntt',
        width: 100,
        suppressSizeToFit: true,
        type: 'rightAligned',
      },
      {
        headerName: '수령',
        field: 'rcvdQntt',
        width: 100,
        suppressSizeToFit: true,
        type: 'rightAligned',
      },
    );

    // 테이블에 적용함
    setInstallItemTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 네트워크 탭 > 설치일정 테이블 데이터를 불러옴
  const getNetworkInstallDateTableData = () => {
    setNetworkInstallDateTable((pre: { column: any; data: any }) => ({
      ...pre,
      // data: detailData?.workDtlPrtnVoList,
      data: detailData?.workDtlPrtnVoList?.filter(
        (filterItem: any) => filterItem.splrTypeCode === 'NTWR_CNST',
      ),
    }));
  };

  // 네트워크 탭 > 팀 테이블 데이터를 불러옴
  const getNetworkTeamTableData = () => {
    setNetworkTeamTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: detailData?.workDtlPrtnVoList,
    }));
  };

  // 네트워크 탭 > 소프트웨어 데이터를 불러옴
  const getNetworkSoftware = () => {
    let tmpWorkNtwrVoList: any[] = _.cloneDeep(detailData?.workNtwrVoList);

    // 데이터가 없으면 기본 데이터를 생성함
    if (tmpWorkNtwrVoList.length === 0) {
      tmpWorkNtwrVoList.push(
        {
          ntwrWorkCode: 'GTWY_IP',
          ntwrWorkName: 'G/W IP',
          splrId: '',
          teamId: '',
          teamName: '',
          ttl: '',
          sortRdr: 1,
        },
        {
          ntwrWorkCode: 'SRVR_IP',
          ntwrWorkName: 'Server IP',
          splrId: '',
          teamId: '',
          teamName: '',
          ttl: '',
          sortRdr: 2,
        },
        {
          ntwrWorkCode: 'MAC_ADDR',
          ntwrWorkName: 'MAC Address',
          splrId: '',
          teamId: '',
          teamName: '',
          ttl: '',
          sortRdr: 3,
        },
        {
          ntwrWorkCode: 'GTWY_FW_VER',
          ntwrWorkName: 'G/W F/W Version',
          splrId: '',
          teamId: '',
          teamName: '',
          ttl: '',
          sortRdr: 4,
        },
      );
    }

    // 네트워크 탭 > 소프트웨어에 적용함
    setNetworkSoftware(tmpWorkNtwrVoList);
  };

  // ESL 탭 > 설치일정 테이블 데이터를 불러옴
  const getEslInstallDateTableData = () => {
    setEslInstallDateTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: detailData?.workDtlPrtnVoList?.filter(
        (filterItem: any) => filterItem.splrTypeCode === 'ESL_NSTL',
      ),
    }));
  };

  // ESL 탭 > 팀 테이블 데이터를 불러옴
  const getEslTeamTableData = () => {
    setEslTeamTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: detailData?.workDtlPrtnVoList,
    }));
  };

  // ESL 탭 > 설치품목 테이블 데이터를 불러옴
  const getEslInstallItemTableData = () => {
    setEslInstallItemTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: detailData?.workEslVoList,
    }));
  };

  // 품목 탭 > 설치정보 테이블 데이터를 불러옴
  const getInstallItemTableData = () => {
    setInstallItemTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: detailData?.workEslVoList,
    }));
  };

  // 상세 내용을 불러옴
  const getDetailData = () => {
    workApi
      .getSttDtlAllDcmnDntfNmbr({
        dcmnDntfNmbr: dcmnDntfNmbr || '',
        splrId: 'ALL',
        teamId: 'ALL',
      })
      .then((data: IApiResult) => {
        if (data.data !== undefined) {
          console.log('> detail:', data.data);

          // 상세 내용에 적용함
          setDetailData(data.data);

          // 메모 입력에 적용함
          setMemo(data.data?.memo);

          // 납품 탭 > 내용에 적용함
          setDeliverContent(data.data?.workDtlDlvrVo.ttl);

          // 납품 탭 > 납품일시에 적용함
          setDeliverDate(
            `${data.data?.workDtlDlvrVo.workStrtDttm} ~ ${data.data?.workDtlDlvrVo.workEndDttm}`,
          );

          // 납품 탭 > 납품상태에 적용함
          setDeliverStatus(data.data?.workDtlDlvrVo.nstlSttsName);
        }
      });
  };

  // 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '작업현황 상세',
        content: (
          <MN2302220305
            id={tmpId}
            dcmnDntfNmbr={dcmnDntfNmbr}
            useModifyMode={true}
          />
        ),
        size: 1300,
        centered: false,
      });
    }, 100);
  };

  // 메모 입력의 값을 변경함
  const handleMemo_onChange = (event: any) => {
    setMemo(event.target.value);
  };

  // 기본정보 탭 > 주문 > 네트워크설치일자 > 수정 버튼을 클릭함
  const handleInstallNetworkButton_onClick = () => {
    // 수정 컴포넌트 모달을 추가함
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '작업배정 - 네트워크',
      content: (
        <MN2302220305_WorkDistribution
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          splrTypeCode="NTWR_CNST"
          prtnDate={moment(
            detailData?.xpctDateNtwr || moment(new Date()).format('YYYY-MM-DD'),
            'YYYY-MM-DD',
          ).format('YYYYMMDD')}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData();
          }}
        />
      ),
      size: 1500,
    });
  };

  // 기본정보 탭 > 주문 > ESL설치일자 > 수정 버튼을 클릭함
  const handleInstallEslButton_onClick = () => {
    // 수정 컴포넌트 모달을 추가함
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '작업배정 - ESL',
      content: (
        <MN2302220305_WorkDistribution
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          splrTypeCode="ESL_NSTL"
          prtnDate={moment(
            detailData?.xpctDateEsl || moment(new Date()).format('YYYY-MM-DD'),
            'YYYY-MM-DD',
          ).format('YYYYMMDD')}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData();
          }}
        />
      ),
      size: 1400,
    });
  };

  // 기본정보 탭 > 주문 > 납품일자 > 수정 버튼을 클릭함
  const handleInstallDoButton_onClick = () => {
    // 수정 컴포넌트 모달을 추가함
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '작업배정 - 납품',
      content: (
        <MN2302220305_WorkDistribution
          id={tmpId}
          dcmnDntfNmbr={dcmnDntfNmbr}
          splrTypeCode="ESL_DLVR"
          prtnDate={moment(
            detailData?.xpctDateEsl || moment(new Date()).format('YYYY-MM-DD'),
            'YYYY-MM-DD',
          ).format('YYYYMMDD')}
          callback={(result: any) => {
            // 상세 내용을 불러옴
            getDetailData();
          }}
        />
      ),
      size: 1400,
    });
  };

  // 네트워크/ESL 탭 > 설치일정 > 테이블의 셀을 더블클릭함
  const handleInstallDateTable_onCellClicked = (event: any) => {
    // 조회 상태일 때만 진행함
    if (dcmnDntfNmbr && useModifyMode) {
      return;
    }

    // 점검항목 컬럼에만 반응함
    if (event.column.colId === 'checkList') {
      console.log('> data:', event.data);

      // 드로어용 선택 데이터에 적용함
      setSelectedDrawerData(event.data);

      // 테이블의 데이터를 불러옴
      getDrawerTableData(event.data);

      // 드로어를 출력함
      openDrawer();
    }
  };

  // 네트워크 탭 > 설치일정 > 테이블의 행을 더블클릭함
  const handleNetworkInstallDateTable_onRowDoubleClicked = (event: any) => {
    // 수정 상태일 때만 진행함
    if (dcmnDntfNmbr && !useModifyMode) {
      return;
    }

    let tmpId: string = 'MN2302220305_ChangeStep';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '진행상태 선택',
      content: (
        <MN2302220305_ChangeStep
          id={tmpId}
          // selectedData={row.data}
          // callback={(result: any) => {
          //   let tmpData: any[] = _.cloneDeep(manageDowntimeTable.data);
          //
          //   tmpData.map((item: any) => {
          //     if (
          //       item.splrId === row.data.splrId &&
          //       item.teamid === row.data.teamid &&
          //       item.cntrNmbr === row.data.cntrNmbr &&
          //       item.shipToCode === row.data.shipToCode
          //     ) {
          //       item.prtnWorkName = result.label;
          //       item.prtnCode = result.value;
          //     }
          //   });
          //
          //   // 테이블에 적용함
          //   setManageDowntimeTable((pre: { column: any; data: any }) => ({
          //     ...pre,
          //     data: tmpData,
          //   }));
          // }}
        />
      ),
      size: 300,
    });

    // setModal({
    //   title: '확인',
    //   content: '작업완료로 처리하시겠습니까?',
    //   useOkayButton: false,
    //   useCancelButton: true,
    //   cancelButtonLabel: '아니오',
    //   button: (
    //     <Button
    //       color="indigo"
    //       radius="xl"
    //       size="md"
    //       onClick={() => {
    //         setRemoveModal(true);
    //
    //         // 작업 내역 - 재고이전 포함 작업완료(ESL)
    //         //             workApi
    //         //               .putWorkEslNvntRqstDcmnDntfNmbr({
    //         //                 dcmnDntfNmbr: selectedData.dcmnDntfNmbr || '',
    //         //                 splrId: selectedData.splrId || '',
    //         //                 teamId: selectedData.teamId || '',
    //         //                 bfrNstlSttsCode: selectedData.nstlSttsCode,
    //         //                 nstlSttsCode: 'WORK_CMPL',
    //         //                 cmnDtl: dynamicNormalComment.content,
    //         //                 cmnfileList:
    //         //                   dynamicNormalComment.fileList?.map(
    //         //                     (fileItem: any) => fileItem.id,
    //         //                   ) || [],
    //         //                 nstlCnfrDtl: dynamicInstallComment.content,
    //         //                 nstlCnfrfileList:
    //         //                   dynamicInstallComment.fileList?.map(
    //         //                     (fileItem: any) => fileItem.id,
    //         //                   ) || [],
    //         //                 nvntFlag: event.key,
    //         //                 workEslParamList: getDynamicWorkEslVoListToArray(),
    //         //                 workNvntRqstList: getDynamicStockMovementVoListToArray(),
    //         //               })
    //         //               .then((data: IApiResult) => {
    //         //                 if (data.code === '200') {
    //         //                   // 부모창으로 값을 전달함
    //         //                   callback('refresh-list');
    //         //
    //         //                   Toast.show({
    //         //                     position: 'bottom',
    //         //                     content: '처리하였습니다.',
    //         //                   });
    //         //
    //         //                   // 브라우저 저장소를 삭제함
    //         //                   localStorage.removeItem(localStorageIdref.current);
    //         //
    //         //                   // 삭제할 팝업 저장소에 적용함
    //         //                   setRemovePopup(id);
    //         //                 } else {
    //         //                   Toast.show({
    //         //                     position: 'bottom',
    //         //                     content: '처리 중 오류가 발생하였습니다.',
    //         //                   });
    //         //                 }
    //         //               })
    //         //               .catch((error: any) => {
    //         //                 console.log('> 작업완료 Error:', error);
    //         //
    //         //                 Toast.show({
    //         //                   position: 'bottom',
    //         //                   content: `처리 중 오류가 발생하였습니다. (${error.message})`,
    //         //                 });
    //         //               });
    //       }}
    //     >
    //       예
    //     </Button>
    //   ),
    // });
  };

  // 네트워크 탭 > 소프트웨어 > 저장 버튼을 클릭함
  const handleNetworkSoftwareSaveButton_onClick = () => {
    setModal({
      title: '확인',
      content: '소프트웨어 정보를 저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            //   workApi
            //     .postNstlAsgn({
            //       dcmnDntfNmbr: dcmnDntfNmbr,
            //       splrTypeCode: splrTypeCode,
            //       bfXpctDate: prtnDate,
            //       xpctDate: moment(selectedPrtnDate, 'YYYYMMDD').format(
            //         'YYYY-MM-DD',
            //       ),
            //       nstlList: tmpAssignedTeamTableData,
            //     })
            //     .then((data: IApiResult) => {
            //       if (data.code === '200') {
            //         notifications.show({
            //           title: '알림',
            //           message: '처리하였습니다.',
            //         });
            //
            //         // // 목록 새로고침을 추가함
            //         // setAddRefreshList('MN2302220404Table');
            //
            //         // 컴포넌트 모달을 닫음
            //         setRemoveComponentModal(id);
            //       } else {
            //         console.log('> 작업현황의 설치일정 작업완료 처리 Error:', data);
            //
            //         setModal({
            //           title: '오류',
            //           content: (
            //             <>
            //               <div>저장에 실패하였습니다.</div>
            //               <div>({data.message})</div>
            //             </>
            //           ),
            //         });
            //       }
            //     })
            //     .catch((error: any) => {
            //       console.log('> 작업현황의 설치일정 작업완료 처리 Error:', error);
            //
            //       setModal({
            //         title: '오류',
            //         content: `작업완료 처리에 실패하였습니다.(${error.message})`,
            //       });
            //     });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // ESL 탭 > 설치일정 > 테이블의 행을 더블클릭함
  const handleEslInstallDateTable_onRowDoubleClicked = (event: any) => {
    // 수정 상태일 때만 진행함
    if (dcmnDntfNmbr && !useModifyMode) {
      return;
    }

    let tmpId: string = 'MN2302220305_ChangeStep';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '진행상태 선택',
      content: (
        <MN2302220305_ChangeStep
          id={tmpId}
          // selectedData={row.data}
          // callback={(result: any) => {
          //   let tmpData: any[] = _.cloneDeep(manageDowntimeTable.data);
          //
          //   tmpData.map((item: any) => {
          //     if (
          //       item.splrId === row.data.splrId &&
          //       item.teamid === row.data.teamid &&
          //       item.cntrNmbr === row.data.cntrNmbr &&
          //       item.shipToCode === row.data.shipToCode
          //     ) {
          //       item.prtnWorkName = result.label;
          //       item.prtnCode = result.value;
          //     }
          //   });
          //
          //   // 테이블에 적용함
          //   setManageDowntimeTable((pre: { column: any; data: any }) => ({
          //     ...pre,
          //     data: tmpData,
          //   }));
          // }}
        />
      ),
      size: 300,
    });
  };

  // 네트워크 탭 > 소프트웨어 > 수정 버튼을 클릭함
  const handleNetworkSoftwareModifyButton_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '소프트웨어 정보 수정',
      content: (
        <MN2302220202_ManagerClient
          id={tmpId}
          callback={(callbackData: any) => {
            // // 임시 순번을 추가함
            // callbackData['workRdr'] = nanoid();
            //
            // // 담당자(고객사) 폼 그리드 > 담당자 > 테이블에 적용함
            // setManagerClientTable((pre: { column: any; data: any }) => ({
            //   ...pre,
            //   data: [...managerClientTable.data, callbackData],
            // }));
          }}
        />
      ),
      size: 1000,
    });
  };

  // 컴포넌트 모달 > 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 메모를 수정함
            workApi
              .putUpdateMemo({
                dcmnDntfNmbr: dcmnDntfNmbr,
                memo: memo,
              })
              .then((data: IApiResult) => {
                // if (data.code === '200') {
                notifications.show({
                  title: '알림',
                  message: '저장하였습니다.',
                });

                // 컴포넌트 모달을 닫음
                setRemoveComponentModal(id);

                setTimeout(() => {
                  // 조회 컴포넌트 모달을 추가함
                  let tmpId: string = nanoid();

                  // 컴포넌트 모달을 추가함
                  setAddComponentModal({
                    id: tmpId,
                    title: '작업현황 상세',
                    content: (
                      <MN2302220305
                        id={tmpId}
                        dcmnDntfNmbr={dcmnDntfNmbr}
                        useModifyMode={false}
                      />
                    ),
                    size: 1500,
                  });
                }, 100);
                // } else {
                //   setModal({
                //     title: '오류',
                //     content: (
                //       <>
                //         <div>저장에 실패하였습니다.</div>
                //         <div>({data.message})</div>
                //       </>
                //     ),
                //   });
                // }
              })
              .catch((error: any) => {
                setModal({
                  title: '오류',
                  content: `저장에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  /**
   * 점검항목 결과 드로어
   */

  // 드로어의 출력 여부를 정의함
  const [openedDrawer, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  // 테이블을 정의함
  const [drawerTableColumn, setDrawerTableColumn] = useState<any>([]);
  const [drawerTable, setDrawerTable] = useState<any>([]);
  const drawerTableRef = useRef<any>(null);

  // 드로어용 선택 데이터를 정의함
  const [selectedDrawerData, setSelectedDrawerData] = useState<any>(null);

  // 테이블 페이징을 정의함
  const [drawerTablePaging, setDrawerTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const drawerTablePagingCurrentPageRef = useRef<number>(1);

  // 테이블을 초기화함
  const initDrawerTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'no',
        headerName: '번호',
        width: 70,
        suppressSizeToFit: true,
        type: 'rightAligned',
        // valueFormatter: (params: ValueFormatterParams) => {
        //   return decode(params.data.no);
        // },
        cellRenderer: (params: any) => (
          <>
            {params.data.rslt === true ? (
              decode(params.data.no)
            ) : (
              <span className="text-rose-600">{decode(params.data.no)}</span>
            )}
          </>
        ),
      },
      {
        field: 'cntn',
        headerName: '점검항목',
        // valueFormatter: (params: ValueFormatterParams) => {
        //   return decode(params.data.cntn);
        // },
        cellRenderer: (params: any) => (
          <>
            {params.data.rslt === true ? (
              decode(params.data.cntn)
            ) : (
              <span className="text-rose-600">{decode(params.data.cntn)}</span>
            )}
          </>
        ),
      },
      {
        field: 'rslt',
        headerName: '점검결과',
        width: 90,
        suppressSizeToFit: true,
        cellRenderer: (params: any) => (
          <>
            {params.data.rslt === true ? (
              'O'
            ) : (
              <span className="text-rose-600">X</span>
            )}
          </>
        ),
      },
    );

    // 테이블에 적용함
    setDrawerTableColumn(tmpColumn);
  };

  // 테이블의 데이터를 불러옴
  const getDrawerTableData = (selectedData: any) => {
    // 작업별 체크 결과 목록을 불러옴
    nstlApi
      .getChckRsltCntrNmbrDcmnDntfNmbrSplrTypeCodePrtnDateSplrIdTeamId({
        cntrNmbr: detailData.cntrNmbr,
        dcmnDntfNmbr: detailData.dcmnDntfNmbr,
        prtnDate: moment(selectedData.workStrtDttm).format('YYYYMMDD'),
        splrId: selectedData.splrId,
        splrTypeCode: selectedData.splrTypeCode,
        teamId: selectedData.teamId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          console.log('> check result:', data.data);

          // 번호 컬럼을 추가함
          data.data.map((item: any, index: number) => {
            item['no'] = index + 1;
          });

          // 테이블에 적용함
          setDrawerTable(data.data);
        }
      });
  };

  ////////////////////

  // 로그인 정보가 준비될 때 실행함
  useEffect(() => {
    if (!loginUser.id) {
      return;
    }

    // 상세 내용을 불러옴
    getDetailData();

    // 네트워크 탭 > 설치일정 테이블을 초기화함
    initNetworkInstallDateTable();

    // 네트워크 탭 > 팀 테이블을 초기화함
    initNetworkTeamTable();

    // ESL 탭 > 설치일정 테이블을 초기화함
    initEslInstallDateTable();

    // ESL 탭 > 팀 테이블을 초기화함
    initEslTeamTable();

    // ESL 탭 > 설치품목 테이블을 초기화함
    initEslInstallItemTable();

    // 품목 탭 > 설치정보 테이블을 초기화함
    initInstallItemTable();

    // 점검항목 결과 드로어 테이블을 초기화함
    initDrawerTable();

    return () => {};
  }, [loginUser.id]);

  // 상세 정보를 불러온 후 실행함
  useEffect(() => {
    if (detailData === null) {
      return;
    }

    // 네트워크 탭 > 설치일정 테이블 데이터를 불러옴
    getNetworkInstallDateTableData();

    // 네트워크 탭 > 팀 테이블 데이터를 불러옴
    getNetworkTeamTableData();

    // 네트워크 탭 > 소프트웨어 데이터를 불러옴
    getNetworkSoftware();

    // ESL 탭 > 설치일정 테이블 데이터를 불러옴
    getEslInstallDateTableData();

    // ESL 탭 > 팀 테이블 데이터를 불러옴
    getEslTeamTableData();

    // ESL 탭 > 설치품목 테이블 데이터를 불러옴
    getEslInstallItemTableData();

    // 품목 탭 > 설치정보 테이블 데이터를 불러옴
    getInstallItemTableData();

    return () => {};
  }, [detailData]);

  // 네트워크 탭 > 설치일정 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (networkInstallDateTable.column.length === 0) {
      return;
    }

    // 네트워크 탭 > 설치일정 테이블 데이터를 불러옴
    getNetworkInstallDateTableData();

    return () => {};
  }, [networkInstallDateTable.column]);

  // 네트워크 탭 > 팀 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (networkTeamTable.column.length === 0) {
      return;
    }

    // 네트워크 탭 > 팀 테이블 데이터를 불러옴
    getNetworkTeamTableData();

    return () => {};
  }, [networkTeamTable.column]);

  // ESL 탭 > 설치일정 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (eslInstallDateTable.column.length === 0) {
      return;
    }

    // ESL 탭 > 설치일정 테이블 데이터를 불러옴
    getEslInstallDateTableData();

    return () => {};
  }, [eslInstallDateTable.column]);

  // ESL 탭 > 팀 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (eslTeamTable.column.length === 0) {
      return;
    }

    // ESL 탭 > 팀 테이블 데이터를 불러옴
    getEslTeamTableData();

    return () => {};
  }, [eslTeamTable.column]);

  // ESL 탭 > 설치품목 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (eslInstallItemTable.column.length === 0) {
      return;
    }

    // ESL 탭 > 설치품목 테이블 데이터를 불러옴
    getEslInstallItemTableData();

    return () => {};
  }, [eslInstallItemTable.column]);

  // 품목 탭 > 설치정보 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (installItemTable.column.length === 0) {
      return;
    }

    // 품목 탭 > 설치정보 테이블 데이터를 불러옴
    getInstallItemTableData();

    return () => {};
  }, [installItemTable.column]);

  return (
    <div className="relative">
      <div>
        {/* 탭 */}
        <Tabs variant="outline" defaultValue="1">
          {/* 탭 헤더 */}
          <Tabs.List>
            <Tabs.Tab value="1">
              <span className="text-lg">기본정보</span>
            </Tabs.Tab>

            <Tabs.Tab value="2">
              <span className="text-lg">네트워크</span>
            </Tabs.Tab>

            <Tabs.Tab value="3">
              <span className="text-lg">ESL</span>
            </Tabs.Tab>

            <Tabs.Tab value="6">
              <span className="text-lg">납품</span>
            </Tabs.Tab>

            <Tabs.Tab value="work-cmpl-comment">
              <span className="text-lg">작업완료결과</span>
            </Tabs.Tab>

            <Tabs.Tab value="nspc-cmpl-comment">
              <span className="text-lg">검수완료결과</span>
            </Tabs.Tab>

            <Tabs.Tab value="pm-nspc-cmpl-comment">
              <span className="text-lg">PM검수완료결과</span>
            </Tabs.Tab>

            <Tabs.Tab value="4">
              <span className="text-lg">품목</span>
            </Tabs.Tab>

            <Tabs.Tab value="5">
              <span className="text-lg">검수</span>
            </Tabs.Tab>
          </Tabs.List>

          {/* 탭 내용: 기본정보 */}
          <Tabs.Panel value="1" pt="xl" className="space-y-7">
            {/* 프로젝트 폼 그리드 */}
            <PbFormGrid id="step-1" label="프로젝트" cols={2}>
              <PbFormGridCol label="프로젝트명" colSpan={2}>
                <div>
                  <span>{decode(detailData?.prjcName)}</span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="프로젝트코드">
                <div>
                  <span>{detailData?.prjcCode}</span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="프로젝트일정">
                <div>
                  <span>
                    {detailData?.fctvStrtDate} ~ {detailData?.fctvEndDate}
                  </span>
                </div>
              </PbFormGridCol>
            </PbFormGrid>

            {/* 계약 폼 그리드 */}
            <PbFormGrid id="step-2" label="계약" cols={2}>
              <PbFormGridCol label="계약명">
                <div>
                  <span>{decode(detailData?.cntrName)}</span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="계약번호">
                <div>
                  <span>{detailData?.cntrScrnNmbr}</span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="고객사">
                <div>
                  <span>{decode(detailData?.bpName)}</span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="고객사(BP)코드">
                <div>
                  <span>{detailData?.bpCode}</span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="영업사원">
                <div>
                  <span>{detailData?.slspName}</span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="담당PM">
                <div>
                  <span>{detailData?.pmName}</span>
                </div>
              </PbFormGridCol>
            </PbFormGrid>

            {/* 주문 폼 그리드 */}
            <PbFormGrid id="step-3" label="주문" cols={2}>
              <PbFormGridCol label="Shop명">
                <div>
                  <span>{decode(detailData?.shipToCode)}</span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="Shop주소">
                <div>
                  <span>{decode(detailData?.dlvrDrs)}</span>
                </div>
              </PbFormGridCol>

              {/*<PbFormGridCol label="설치예정일">*/}
              {/*  <div>*/}
              {/*    <span>{detailData?.xpctDate?.replaceAll('-', '.')}</span>*/}
              {/*  </div>*/}
              {/*</PbFormGridCol>*/}

              <PbFormGridCol label="주문번호">
                <div>
                  <span>{detailData?.dcmnScrnNmbr}</span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="납품일자">
                <div className="flex justify-center items-center space-x-3">
                  {/*<span>{detailData?.xpctDateNtwr?.replaceAll('-', '.')}</span>*/}

                  <Badge variant="outline">{detailData?.dlvrCnt}</Badge>

                  {dcmnDntfNmbr && useModifyMode && (
                    <Button
                      color="indigo"
                      radius="xl"
                      size="xs"
                      onClick={handleInstallDoButton_onClick}
                    >
                      수정
                    </Button>
                  )}
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="네트워크설치일자">
                <div className="flex justify-center items-center space-x-3">
                  {/*<span>{detailData?.xpctDateNtwr?.replaceAll('-', '.')}</span>*/}

                  <Badge variant="outline">{detailData?.ntwrCnt}</Badge>

                  {dcmnDntfNmbr && useModifyMode && (
                    <Button
                      color="indigo"
                      radius="xl"
                      size="xs"
                      onClick={handleInstallNetworkButton_onClick}
                    >
                      수정
                    </Button>
                  )}
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="ESL설치일자">
                <div className="flex justify-center items-center space-x-3">
                  {/*<span>{detailData?.xpctDateEsl?.replaceAll('-', '.')}</span>*/}

                  <Badge variant="outline">{detailData?.eslCnt}</Badge>

                  {dcmnDntfNmbr && useModifyMode && (
                    <Button
                      color="indigo"
                      radius="xl"
                      size="xs"
                      onClick={handleInstallEslButton_onClick}
                    >
                      수정
                    </Button>
                  )}
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="" isBlank={true}></PbFormGridCol>
            </PbFormGrid>

            {/* 검수 폼 그리드 */}
            <PbFormGrid id="step-4" label="검수" cols={2}>
              <PbFormGridCol label="PM검수완료담당자">
                {/*<div>/!*<span>{decode('')} 완료수 / 작업수</span>*!/</div>*/}
                <div>
                  <span>{detailData?.workBillingDtlResVo?.pmNspcCmplNm}</span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="PM검수완료일시">
                <div>
                  <span>{detailData?.workBillingDtlResVo?.workDate}</span>
                </div>
              </PbFormGridCol>
            </PbFormGrid>

            {/* 기타 */}
            <PbFormGrid id="step-5" label="기타" cols={1}>
              <PbFormGridCol label="메모">
                <div className="w-full">
                  <Textarea
                    placeholder={
                      dcmnDntfNmbr && !useModifyMode
                        ? ''
                        : '메모를 입력해 주세요.'
                    }
                    onChange={handleMemo_onChange}
                    value={decode(memo)}
                    minRows={5}
                    // maxLength={10000}
                    readOnly={dcmnDntfNmbr && !useModifyMode ? true : false}
                    variant={
                      dcmnDntfNmbr && !useModifyMode ? 'unstyled' : 'default'
                    }
                    className="w-full"
                  />
                </div>
              </PbFormGridCol>
            </PbFormGrid>
          </Tabs.Panel>

          {/* 탭 내용: 네트워크 */}
          <Tabs.Panel value="2" pt="xl" className="space-y-7">
            {/* 설치일정 섹션 */}
            <PbSection
              label="설치일정"
              rightForm={
                <>
                  {/* 도움말 */}
                  {!(dcmnDntfNmbr && !useModifyMode) && (
                    <div className="flex justify-center items-center space-x-1">
                      <div className="flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'circle-info']}
                          className="w-4 h-4 text-blue-500"
                        />
                      </div>

                      <div className="flex justify-center items-center space-x-2">
                        <span className="text-base text-gray-600 font-bold">
                          행을 더블클릭하여 진행상태를 변경할 수 있습니다.
                        </span>
                      </div>
                    </div>
                  )}
                </>
              }
            >
              {/* 설치일정 테이블 */}
              <div className="w-full h-60">
                <PbAgGridReact
                  columnDefs={networkInstallDateTable.column}
                  rowData={networkInstallDateTable.data}
                  onRowDoubleClicked={
                    handleNetworkInstallDateTable_onRowDoubleClicked
                  }
                  onCellClicked={handleInstallDateTable_onCellClicked}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="none"
                  sizeColumnsToFit={true}
                  visiblePaging={false}
                  className="ag-grid-multiple-header-name"
                />
              </div>
            </PbSection>

            {/* 소프트웨어 폼 그리드 */}
            <PbFormGrid
              id="step-1"
              label="소프트웨어"
              cols={2}
              // rightForm={
              //   <>
              //     {!(dcmnDntfNmbr && !useModifyMode) && (
              //       <Button
              //         color="indigo"
              //         radius="xl"
              //         onClick={handleNetworkSoftwareSaveButton_onClick}
              //       >
              //         저장
              //       </Button>
              //     )}
              //   </>
              // }
            >
              <PbFormGridCol label="G/W IP">
                {/* 조회 상태 */}
                {dcmnDntfNmbr && !useModifyMode && (
                  <div>
                    <span>
                      {_.find(networkSoftware, { ntwrWorkCode: 'GTWY_IP' })
                        ?.ttl || ''}
                    </span>
                  </div>
                )}
                {/* 수정 상태 */}
                {!(dcmnDntfNmbr && !useModifyMode) && (
                  <div>
                    <span>
                      {_.find(networkSoftware, { ntwrWorkCode: 'GTWY_IP' })
                        ?.ttl || ''}
                    </span>
                  </div>
                  // <TextInput
                  //   placeholder="G/W IP를 입력하세요."
                  //   onChange={(event: any) => {
                  //     let tmpNetworkSoftware: any[] =
                  //       _.cloneDeep(networkSoftware);
                  //
                  //     tmpNetworkSoftware.map((item: any) => {
                  //       if (item.ntwrWorkCode === 'GTWY_IP') {
                  //         item.ttl = event.currentTarget.value;
                  //       }
                  //     });
                  //
                  //     setNetworkSoftware(tmpNetworkSoftware);
                  //   }}
                  //   value={
                  //     _.find(networkSoftware, { ntwrWorkCode: 'GTWY_IP' })
                  //       ?.ttl || ''
                  //   }
                  //   className="w-full"
                  // />
                )}
              </PbFormGridCol>

              <PbFormGridCol label="Server IP">
                {/* 조회 상태 */}
                {dcmnDntfNmbr && !useModifyMode && (
                  <div>
                    <span>
                      {_.find(networkSoftware, { ntwrWorkCode: 'SRVR_IP' })
                        ?.ttl || ''}
                    </span>
                  </div>
                )}
                {/* 수정 상태 */}
                {!(dcmnDntfNmbr && !useModifyMode) && (
                  <div>
                    <span>
                      {_.find(networkSoftware, { ntwrWorkCode: 'SRVR_IP' })
                        ?.ttl || ''}
                    </span>
                  </div>
                  // <TextInput
                  //   placeholder="Server IP를 입력하세요."
                  //   onChange={(event: any) => {
                  //     let tmpNetworkSoftware: any[] =
                  //       _.cloneDeep(networkSoftware);
                  //
                  //     tmpNetworkSoftware.map((item: any) => {
                  //       if (item.ntwrWorkCode === 'SRVR_IP') {
                  //         item.ttl = event.currentTarget.value;
                  //       }
                  //     });
                  //
                  //     setNetworkSoftware(tmpNetworkSoftware);
                  //   }}
                  //   value={
                  //     _.find(networkSoftware, { ntwrWorkCode: 'SRVR_IP' })
                  //       ?.ttl || ''
                  //   }
                  //   className="w-full"
                  // />
                )}
              </PbFormGridCol>

              <PbFormGridCol label="MAC Address">
                {/* 조회 상태 */}
                {dcmnDntfNmbr && !useModifyMode && (
                  <div>
                    <span>
                      {_.find(networkSoftware, { ntwrWorkCode: 'MAC_ADDR' })
                        ?.ttl || ''}
                    </span>
                  </div>
                )}
                {/* 수정 상태 */}
                {!(dcmnDntfNmbr && !useModifyMode) && (
                  <div>
                    <span>
                      {_.find(networkSoftware, { ntwrWorkCode: 'MAC_ADDR' })
                        ?.ttl || ''}
                    </span>
                  </div>
                  // <TextInput
                  //   placeholder="MAC Address를 입력하세요."
                  //   onChange={(event: any) => {
                  //     let tmpNetworkSoftware: any[] =
                  //       _.cloneDeep(networkSoftware);
                  //
                  //     tmpNetworkSoftware.map((item: any) => {
                  //       if (item.ntwrWorkCode === 'MAC_ADDR') {
                  //         item.ttl = event.currentTarget.value;
                  //       }
                  //     });
                  //
                  //     setNetworkSoftware(tmpNetworkSoftware);
                  //   }}
                  //   value={
                  //     _.find(networkSoftware, { ntwrWorkCode: 'MAC_ADDR' })
                  //       ?.ttl || ''
                  //   }
                  //   className="w-full"
                  // />
                )}
              </PbFormGridCol>

              <PbFormGridCol label="G/W F/W Version">
                {/* 조회 상태 */}
                {dcmnDntfNmbr && !useModifyMode && (
                  <div>
                    <span>
                      {_.find(networkSoftware, { ntwrWorkCode: 'GTWY_FW_VER' })
                        ?.ttl || ''}
                    </span>
                  </div>
                )}
                {/* 수정 상태 */}
                {!(dcmnDntfNmbr && !useModifyMode) && (
                  <div>
                    <span>
                      {_.find(networkSoftware, { ntwrWorkCode: 'GTWY_FW_VER' })
                        ?.ttl || ''}
                    </span>
                  </div>
                  // <TextInput
                  //   placeholder="G/W F/W Version을 입력하세요."
                  //   onChange={(event: any) => {
                  //     let tmpNetworkSoftware: any[] =
                  //       _.cloneDeep(networkSoftware);
                  //
                  //     tmpNetworkSoftware.map((item: any) => {
                  //       if (item.ntwrWorkCode === 'GTWY_FW_VER') {
                  //         item.ttl = event.currentTarget.value;
                  //       }
                  //     });
                  //
                  //     setNetworkSoftware(tmpNetworkSoftware);
                  //   }}
                  //   value={
                  //     _.find(networkSoftware, { ntwrWorkCode: 'GTWY_FW_VER' })
                  //       ?.ttl || ''
                  //   }
                  //   className="w-full"
                  // />
                )}
              </PbFormGridCol>
            </PbFormGrid>
          </Tabs.Panel>

          {/* 탭 내용: ESL */}
          <Tabs.Panel value="3" pt="xl" className="space-y-7">
            {/* 설치일정 섹션 */}
            <PbSection
              label="설치일정"
              rightForm={
                <>
                  {/* 도움말 */}
                  {!(dcmnDntfNmbr && !useModifyMode) && (
                    <div className="flex justify-center items-center space-x-1">
                      <div className="flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'circle-info']}
                          className="w-4 h-4 text-blue-500"
                        />
                      </div>

                      <div className="flex justify-center items-center space-x-2">
                        <span className="text-base text-gray-600 font-bold">
                          행을 더블클릭하여 진행상태를 변경할 수 있습니다.
                        </span>
                      </div>
                    </div>
                  )}
                </>
              }
            >
              {/* 설치일정 테이블 */}
              <div className="w-full h-60">
                <PbAgGridReact
                  columnDefs={eslInstallDateTable.column}
                  rowData={eslInstallDateTable.data}
                  onRowDoubleClicked={
                    handleEslInstallDateTable_onRowDoubleClicked
                  }
                  onCellClicked={handleInstallDateTable_onCellClicked}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="none"
                  sizeColumnsToFit={true}
                  visiblePaging={false}
                  className="ag-grid-multiple-header-name"
                />
              </div>
            </PbSection>

            {/* 팀 정보 섹션 */}
            {/*<PbSection label="팀 정보">*/}
            {/*  /!* 설치일정 테이블 *!/*/}
            {/*  <div className="w-full h-60">*/}
            {/*    <PbAgGridReact*/}
            {/*      columnDefs={eslTeamTable.column}*/}
            {/*      rowData={eslTeamTable.data}*/}
            {/*      defaultColDef={{*/}
            {/*        resizable: true,*/}
            {/*        sortable: true,*/}
            {/*        wrapHeaderText: false,*/}
            {/*        autoHeaderHeight: true,*/}
            {/*      }}*/}
            {/*      rowSelection="none"*/}
            {/*      sizeColumnsToFit={true}*/}
            {/*      visiblePaging={false}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</PbSection>*/}

            {/* 설치품목 섹션 */}
            <PbSection label="설치품목">
              {/* 설치품목 테이블 */}
              <div className="w-full h-96">
                <PbAgGridReact
                  columnDefs={eslInstallItemTable.column}
                  rowData={eslInstallItemTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="none"
                  sizeColumnsToFit={false}
                  visiblePaging={false}
                />
              </div>
            </PbSection>
          </Tabs.Panel>

          {/* 탭 내용: 납품 */}
          <Tabs.Panel value="6" pt="xl" className="space-y-7">
            {/* 납품일정 섹션 */}
            <PbFormGrid label="납품예정일" cols={2}>
              <PbFormGridCol label="내용" colSpan={2}>
                <span>{decode(deliverContent)}</span>
              </PbFormGridCol>

              <PbFormGridCol label="납품일자">
                <span>{deliverDate}</span>
              </PbFormGridCol>

              <PbFormGridCol label="납품상태">
                <span>{deliverStatus}</span>
              </PbFormGridCol>
            </PbFormGrid>
          </Tabs.Panel>

          {/* 탭 내용: 작업완료결과 */}
          {/* 작업완료, 검수완료, PM검수완료 상태일 때만 출력 */}
          <Tabs.Panel value="work-cmpl-comment" pt="xl" className="space-y-7">
            <PbSection label="작업완료결과">
              <div className="space-y-1">
                {/* 완료결과가 없음 */}
                {detailData?.workPrgrVoList?.filter(
                  (filterItem: any) => filterItem.nstlSttsCode === 'WORK_CMPL',
                ).length === 0 && (
                  <div className="space-y-3">
                    <PbFormGrid cols={2}>
                      <PbFormGridCol label="구분" colSpan={2}>
                        <div>
                          <span>입력된 작업완료 결과 없음</span>
                        </div>
                      </PbFormGridCol>
                    </PbFormGrid>
                  </div>
                )}

                {_.uniqBy(detailData?.workPrgrVoList, 'teamId').map(
                  (teamItem: any, teamIndex: number) => (
                    <>
                      {detailData?.workPrgrVoList.filter(
                        (filterItem: any) =>
                          (filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'WORK_CMPL' &&
                            filterItem.nstlFileCode === 'CMN') ||
                          (filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'WORK_CMPL' &&
                            filterItem.nstlFileCode === 'NSTL_CNFR'),
                      ).length > 0 && (
                        <div key={teamIndex} className="space-y-1">
                          {/* 일반 */}
                          {detailData?.workPrgrVoList
                            .filter(
                              (filterItem: any) =>
                                filterItem.teamId === teamItem.teamId &&
                                filterItem.nstlSttsCode === 'WORK_CMPL' &&
                                filterItem.nstlFileCode === 'CMN',
                            )
                            .map((item: any, index: number) => {
                              // 첨부파일 목록을 정의함
                              let tmpFileList: any[] = [];

                              // 첨부파일 목록을 생성함
                              item.fileInfoList.map((fileItem: any) => {
                                tmpFileList.push({
                                  id: fileItem.fileId,
                                  originalFileName: fileItem.rgnlFileName,
                                  saveFileName: fileItem.pldFileName,
                                  size: fileItem.fileSize,
                                });
                              });

                              return (
                                <div key={index} className="space-y-3">
                                  <PbFormGrid cols={1}>
                                    <PbFormGridCol label="구분">
                                      <div>
                                        <span>{`${teamItem.prtnName} / ${teamItem.teamName} - 일반 첨부파일`}</span>
                                      </div>
                                    </PbFormGridCol>

                                    <PbFormGridCol label="내용">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: decode(
                                            (item.dtl || '').replace(
                                              /\n/g,
                                              '<br />',
                                            ),
                                          ),
                                        }}
                                      />
                                    </PbFormGridCol>

                                    <PbFormGridCol label="첨부파일">
                                      <FileDownloadList data={tmpFileList} />
                                    </PbFormGridCol>

                                    <PbFormGridCol label="미리보기">
                                      <ImageFilePreviewList
                                        data={tmpFileList}
                                      />
                                    </PbFormGridCol>
                                  </PbFormGrid>
                                </div>
                              );
                            })}

                          {/* 설치 확인서 */}
                          {detailData.workPrgrVoList
                            .filter(
                              (filterItem: any) =>
                                filterItem.teamId === teamItem.teamId &&
                                filterItem.nstlSttsCode === 'WORK_CMPL' &&
                                filterItem.nstlFileCode === 'NSTL_CNFR',
                            )
                            .map((item: any, index: number) => {
                              // 첨부파일 목록을 정의함
                              let tmpFileList: any[] = [];

                              // 첨부파일 목록을 생성함
                              item.fileInfoList.map((fileItem: any) => {
                                tmpFileList.push({
                                  id: fileItem.fileId,
                                  originalFileName: fileItem.rgnlFileName,
                                  saveFileName: fileItem.pldFileName,
                                  size: fileItem.fileSize,
                                });
                              });

                              return (
                                <div key={index} className="space-y-3">
                                  <PbFormGrid cols={1}>
                                    <PbFormGridCol label="구분">
                                      <div>
                                        <span>{`${teamItem.prtnName} / ${teamItem.teamName} - 설치 확인서`}</span>
                                      </div>
                                    </PbFormGridCol>

                                    <PbFormGridCol label="내용">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: decode(
                                            (item.dtl || '').replace(
                                              /\n/g,
                                              '<br />',
                                            ),
                                          ),
                                        }}
                                      />
                                    </PbFormGridCol>

                                    <PbFormGridCol label="첨부파일">
                                      <FileDownloadList data={tmpFileList} />
                                    </PbFormGridCol>

                                    <PbFormGridCol label="미리보기">
                                      <ImageFilePreviewList
                                        data={tmpFileList}
                                      />
                                    </PbFormGridCol>
                                  </PbFormGrid>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </>
                  ),
                )}
              </div>
            </PbSection>
          </Tabs.Panel>

          {/* 탭 내용: 검수완료결과 */}
          {/* 검수완료, PM검수완료 상태일 때만 출력 */}
          <Tabs.Panel value="nspc-cmpl-comment" pt="xl" className="space-y-7">
            <PbSection label="검수완료결과">
              <div className="space-y-1">
                {/* 완료결과가 없음 */}
                {detailData?.workPrgrVoList?.filter(
                  (filterItem: any) => filterItem.nstlSttsCode === 'NSPC_CMPL',
                ).length === 0 && (
                  <div className="space-y-3">
                    <PbFormGrid cols={2}>
                      <PbFormGridCol label="구분" colSpan={2}>
                        <div>
                          <span>입력된 검수완료 결과 없음</span>
                        </div>
                      </PbFormGridCol>
                    </PbFormGrid>
                  </div>
                )}

                {_.uniqBy(detailData?.workPrgrVoList, 'teamId').map(
                  (teamItem: any, teamIndex: number) => (
                    <div key={teamIndex} className="space-y-1">
                      {/* 일반 */}
                      {detailData?.workPrgrVoList
                        .filter(
                          (filterItem: any) =>
                            filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'NSPC_CMPL' &&
                            filterItem.nstlFileCode === 'CMN',
                        )
                        .map((item: any, index: number) => {
                          // 첨부파일 목록을 정의함
                          let tmpFileList: any[] = [];

                          // 첨부파일 목록을 생성함
                          item.fileInfoList.map((fileItem: any) => {
                            tmpFileList.push({
                              id: fileItem.fileId,
                              originalFileName: fileItem.rgnlFileName,
                              saveFileName: fileItem.pldFileName,
                              size: fileItem.fileSize,
                            });
                          });

                          return (
                            <div key={index} className="space-y-3">
                              <PbFormGrid cols={1}>
                                <PbFormGridCol label="구분">
                                  <div>
                                    <span>{`${teamItem.prtnName} / ${teamItem.teamName} - 일반 첨부파일`}</span>
                                  </div>
                                </PbFormGridCol>

                                <PbFormGridCol label="내용">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: decode(
                                        (item.dtl || '').replace(
                                          /\n/g,
                                          '<br />',
                                        ),
                                      ),
                                    }}
                                  />
                                </PbFormGridCol>

                                <PbFormGridCol label="첨부파일">
                                  <FileDownloadList data={tmpFileList} />
                                </PbFormGridCol>

                                <PbFormGridCol label="미리보기">
                                  <ImageFilePreviewList data={tmpFileList} />
                                </PbFormGridCol>
                              </PbFormGrid>
                            </div>
                          );
                        })}

                      {/* 설치 확인서 */}
                      {detailData.workPrgrVoList
                        .filter(
                          (filterItem: any) =>
                            filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'NSPC_CMPL' &&
                            filterItem.nstlFileCode === 'NSTL_CNFR',
                        )
                        .map((item: any, index: number) => {
                          // 첨부파일 목록을 정의함
                          let tmpFileList: any[] = [];

                          // 첨부파일 목록을 생성함
                          item.fileInfoList.map((fileItem: any) => {
                            tmpFileList.push({
                              id: fileItem.fileId,
                              originalFileName: fileItem.rgnlFileName,
                              saveFileName: fileItem.pldFileName,
                              size: fileItem.fileSize,
                            });
                          });

                          return (
                            <div key={index} className="space-y-3">
                              <PbFormGrid cols={1}>
                                <PbFormGridCol label="구분">
                                  <div>
                                    <span>{`${teamItem.prtnName} / ${teamItem.teamName} - 설치 확인서`}</span>
                                  </div>
                                </PbFormGridCol>

                                <PbFormGridCol label="내용">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: decode(
                                        (item.dtl || '').replace(
                                          /\n/g,
                                          '<br />',
                                        ),
                                      ),
                                    }}
                                  />
                                </PbFormGridCol>

                                <PbFormGridCol label="첨부파일">
                                  <FileDownloadList data={tmpFileList} />
                                </PbFormGridCol>

                                <PbFormGridCol label="미리보기">
                                  <ImageFilePreviewList data={tmpFileList} />
                                </PbFormGridCol>
                              </PbFormGrid>
                            </div>
                          );
                        })}
                    </div>
                  ),
                )}
              </div>
            </PbSection>
          </Tabs.Panel>

          {/* 탭 내용: PM검수완료결과 */}
          {/* 검수완료, PM검수완료결과 상태일 때만 출력 */}
          <Tabs.Panel
            value="pm-nspc-cmpl-comment"
            pt="xl"
            className="space-y-7"
          >
            <PbSection label="PM검수완료결과">
              <div className="space-y-1">
                {/* 완료결과가 없음 */}
                {detailData?.workPrgrVoList?.filter(
                  (filterItem: any) =>
                    filterItem.nstlSttsCode === 'PM_NSPC_CMPL',
                ).length === 0 && (
                  <div className="space-y-3">
                    <PbFormGrid cols={2}>
                      <PbFormGridCol label="구분" colSpan={2}>
                        <div>
                          <span>입력된 PM검수완료 결과 없음</span>
                        </div>
                      </PbFormGridCol>
                    </PbFormGrid>
                  </div>
                )}

                {_.uniqBy(detailData?.workPrgrVoList, 'teamId').map(
                  (teamItem: any, teamIndex: number) => (
                    <div key={teamIndex} className="space-y-1">
                      {/* 일반 */}
                      {detailData?.workPrgrVoList
                        .filter(
                          (filterItem: any) =>
                            filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'PM_NSPC_CMPL' &&
                            filterItem.nstlFileCode === 'CMN',
                        )
                        .map((item: any, index: number) => {
                          // 첨부파일 목록을 정의함
                          let tmpFileList: any[] = [];

                          // 첨부파일 목록을 생성함
                          item.fileInfoList.map((fileItem: any) => {
                            tmpFileList.push({
                              id: fileItem.fileId,
                              originalFileName: fileItem.rgnlFileName,
                              saveFileName: fileItem.pldFileName,
                              size: fileItem.fileSize,
                            });
                          });

                          return (
                            <div key={index} className="space-y-3">
                              <PbFormGrid cols={1}>
                                <PbFormGridCol label="구분">
                                  <div>
                                    <span>{`${teamItem.prtnName} / ${teamItem.teamName} - 일반 첨부파일`}</span>
                                  </div>
                                </PbFormGridCol>

                                <PbFormGridCol label="내용">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: decode(
                                        (item.dtl || '').replace(
                                          /\n/g,
                                          '<br />',
                                        ),
                                      ),
                                    }}
                                  />
                                </PbFormGridCol>

                                <PbFormGridCol label="첨부파일">
                                  <FileDownloadList data={tmpFileList} />
                                </PbFormGridCol>

                                <PbFormGridCol label="미리보기">
                                  <ImageFilePreviewList data={tmpFileList} />
                                </PbFormGridCol>
                              </PbFormGrid>
                            </div>
                          );
                        })}

                      {/* 설치 확인서 */}
                      {detailData.workPrgrVoList
                        .filter(
                          (filterItem: any) =>
                            filterItem.teamId === teamItem.teamId &&
                            filterItem.nstlSttsCode === 'PM_NSPC_CMPL' &&
                            filterItem.nstlFileCode === 'NSTL_CNFR',
                        )
                        .map((item: any, index: number) => {
                          // 첨부파일 목록을 정의함
                          let tmpFileList: any[] = [];

                          // 첨부파일 목록을 생성함
                          item.fileInfoList.map((fileItem: any) => {
                            tmpFileList.push({
                              id: fileItem.fileId,
                              originalFileName: fileItem.rgnlFileName,
                              saveFileName: fileItem.pldFileName,
                              size: fileItem.fileSize,
                            });
                          });

                          return (
                            <div key={index} className="space-y-3">
                              <PbFormGrid cols={1}>
                                <PbFormGridCol label="구분">
                                  <div>
                                    <span>{`${teamItem.prtnName} / ${teamItem.teamName} - 설치 확인서`}</span>
                                  </div>
                                </PbFormGridCol>

                                <PbFormGridCol label="내용">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: decode(
                                        (item.dtl || '').replace(
                                          /\n/g,
                                          '<br />',
                                        ),
                                      ),
                                    }}
                                  />
                                </PbFormGridCol>

                                <PbFormGridCol label="첨부파일">
                                  <FileDownloadList data={tmpFileList} />
                                </PbFormGridCol>

                                <PbFormGridCol label="미리보기">
                                  <ImageFilePreviewList data={tmpFileList} />
                                </PbFormGridCol>
                              </PbFormGrid>
                            </div>
                          );
                        })}
                    </div>
                  ),
                )}
              </div>
            </PbSection>
          </Tabs.Panel>

          {/* 탭 내용: 품목 */}
          <Tabs.Panel value="4" pt="xl" className="space-y-7">
            {/* 설치정보 섹션 */}
            <PbSection label="설치정보">
              {/* 설치일정 테이블 */}
              <div className="w-full h-120">
                <PbAgGridReact
                  columnDefs={installItemTable.column}
                  rowData={installItemTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="none"
                  sizeColumnsToFit={true}
                  visiblePaging={false}
                />
              </div>
            </PbSection>
          </Tabs.Panel>

          {/* 탭 내용: 검수 */}
          <Tabs.Panel value="5" pt="xl" className="space-y-7">
            {/* 빌링 여부 폼 그리드 */}
            <PbFormGrid id="step-1" label="빌링 여부" cols={1}>
              <PbFormGridCol label="세금계산서발행일">
                <div>
                  <span>{detailData?.workBillingDtlResVo?.billDate}</span>
                </div>
              </PbFormGridCol>
            </PbFormGrid>

            {/* 검수 내역 폼 그리드 */}
            <PbFormGrid id="step-2" label="검수 내역" cols={1}>
              <PbFormGridCol label="소프트웨어버전" withAsterisk={true}>
                <div>
                  <span>{detailData?.workBillingDtlResVo?.vrsnNfrm}</span>
                </div>
              </PbFormGridCol>
            </PbFormGrid>

            {/* 검수 이력 섹션 */}
            {/*<PbSection label="검수 이력">*/}
            {/*  <div>댓글</div>*/}
            {/*</PbSection>*/}
          </Tabs.Panel>
        </Tabs>
      </div>

      {/* 점검항목 결과 드로어 */}
      <Drawer
        opened={openedDrawer}
        onClose={closeDrawer}
        position="right"
        size={700}
        title={<span className="text-xl font-semibold">점검항목 결과</span>}
        zIndex={1000}
      >
        <div className="space-y-3">
          {/* 기본 정보 */}
          <div>
            <PbFormGrid label="" cols={1}>
              <PbFormGridCol label="협력사 / 팀">
                <div>
                  <span>
                    {decode(selectedDrawerData?.prtnName)} /{' '}
                    {decode(selectedDrawerData?.teamName)}
                  </span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="내용">
                <div>
                  <span>{decode(selectedDrawerData?.ttl)}</span>
                </div>
              </PbFormGridCol>

              <PbFormGridCol label="작업일정">
                <div>
                  <span>
                    {decode(selectedDrawerData?.workStrtDttm)} ~{' '}
                    {decode(selectedDrawerData?.workEndDttm)}
                  </span>
                </div>
              </PbFormGridCol>
            </PbFormGrid>
          </div>

          {/* 테이블 */}
          <div style={{ height: 'calc(100vh - 210px)' }}>
            <PbAgGridReact
              refs={drawerTableRef}
              columnDefs={drawerTableColumn}
              rowData={drawerTable}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="multiple"
              sizeColumnsToFit={true}
              getRowStyle={(params: any) => {
                switch (params.data.rslt) {
                  // X
                  case false:
                    return { backgroundColor: 'rgb(255,241,249)' };

                  default:
                    break;
                }
              }}
              visiblePaging={false}
              paging={{
                totalPage: drawerTablePaging.totalPage,
                currentPage: drawerTablePaging.currentPage,
              }}
              // onChangePage={(event: any) => {
              //   // 테이블의 페이지를 변경함
              //   drawerTablePagingCurrentPageRef.current = event;
              //   setDrawerTablePaging((pre: ITablePaging) => ({
              //     ...pre,
              //     currentPage: drawerTablePagingCurrentPageRef.current,
              //   }));
              //
              //   // 테이블의 데이터를 불러옴
              //   getDrawerTableData();
              // }}
            />
          </div>
        </div>
      </Drawer>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 신규 상태 */}
        {!dcmnDntfNmbr && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>
          </>
        )}

        {/* 조회 상태 */}
        {dcmnDntfNmbr && !useModifyMode && (
          <>
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {loginUser.cmpnCode !== 'LVYN' && (
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleGoModifyButton_onClick}
              >
                수정 전환
              </Button>
            )}
          </>
        )}

        {/* 수정 상태 */}
        {dcmnDntfNmbr && useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              취소
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220305;
