import { atom } from 'recoil';
import {
  IComponentModal,
  IComponentModalPageScrollTab,
  ISizeId,
} from '../interfaces/app.interface';

/**
 * 컴포넌트 모달
 */
export const componentModalStore = atom({
  key: 'componentModalStore',
  default: [] as IComponentModal[],
});

/**
 * 추가할 컴포넌트 모달
 */
export const addComponentModalStore = atom({
  key: 'addComponentModalStore',
  default: null as IComponentModal | null,
});

/**
 * 수정할 컴포넌트 모달
 */
export const modifyComponentModalStore = atom({
  key: 'modifyComponentModalStore',
  default: null as IComponentModal | null,
});

/**
 * 크기를 수정할 컴포넌트 모달
 */
export const modifySizeComponentModalStore = atom({
  key: 'modifySizeComponentModalStore',
  default: null as ISizeId | null,
});

/**
 * 삭제할 컴포넌트 모달
 */
export const removeComponentModalStore = atom({
  key: 'removeComponentModalStore',
  default: null as string | null,
});

/**
 * 전체 컴포넌트 모달 삭제
 */
export const removeAllComponentModalStore = atom({
  key: 'removeAllComponentModalStore',
  default: false as boolean,
});

/**
 * 컴포넌트 모달에 페이지 스크롤 탭을 추가
 */
export const addPageScrollTabComponentModalStore = atom({
  key: 'addPageScrollTabComponentModalStore',
  default: null as { id: string; tab: IComponentModalPageScrollTab } | null,
});

/**
 * 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경
 */
export const selectPageScrollTabComponentModalStore = atom({
  key: 'selectPageScrollTabComponentModalStore',
  default: null as { id: string; active: string } | null,
});
