import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Select } from '@mantine/core';
import * as appUtil from '../../utils/app.util';
import { useRecoilState } from 'recoil';
import { ILanguage } from '../../interfaces/app.interface';
import { languageStore } from '../../stores/language.store';

interface ILanguageSelectProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 언어 선택 메뉴
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const LanguageMenu = ({
  data,
  onClick,
}: PropsWithChildren<ILanguageSelectProps>) => {
  // 언어를 정의함
  const { t, i18n } = useTranslation();

  // 현재 선택한 언어를 정의함
  const [language, setLanguage] = useRecoilState<ILanguage>(languageStore);

  // 언어 선택폼을 변경함
  const handleLanguageSelect_onChange = (param: any) => {
    // 현재 언어를 Local Storage에 저장함
    appUtil.setLanguage(param).then(() => {
      setLanguage({ language: param });
    });
  };

  useEffect(() => {
    // 현재 선택한 언어를 초기화함
    setLanguage({ language: appUtil.getLanguage() });

    return () => {};
  }, []);

  // 언어가 변경될 때마다 실행함
  useEffect(() => {
    if (!language.language) {
      return;
    }

    (async () => {
      await i18n.changeLanguage(language.language);
    })();

    return () => {};
  }, [language.language]);

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <div className="id-wrapper">
          <div className="button-event">
            {/* 언어: 로그아웃 */}
            <span className="btn-logout">{t('common.language')}</span>
          </div>
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        {/*<Menu.Label>Language</Menu.Label>*/}
        <Menu.Item onClick={() => handleLanguageSelect_onChange('en')}>
          English
        </Menu.Item>
        <Menu.Item onClick={() => handleLanguageSelect_onChange('kr')}>
          한국어
        </Menu.Item>
        <Menu.Item onClick={() => handleLanguageSelect_onChange('jp')}>
          日本語
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default LanguageMenu;
