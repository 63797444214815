import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, RangeSlider, Select, TextInput } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IModal,
  IComponentModal,
  ISlider,
  INumberOptionItem,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { pageContentStore } from '../stores/page.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { addComponentModalStore } from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import MN2302220202 from '../components/MN2302220202/MN2302220202.component';
import * as slsApi from '../apis/sls.api';
import * as cmnApi from '../apis/cmn.api';
import { PbTable } from '../components/PbTable';
import { PbAgGridReact } from '../components/PbAgGridReact';
import PbSelect from '../components/PbSelect/PbSelect.component';
import { ValueFormatterParams } from 'ag-grid-community';
import { decode } from 'html-entities';
import * as appUtil from '../utils/app.util';
import { number } from 'prop-types';

/**
 * 영업 관리 > 영업 기회 조회
 * @constructor
 */

// 검색 폼 그리드 > 검색어 > 셀렉트를 정의함
let varSearchCategorySelect: string = '';

// 검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
let varSearchInput: string = '';

// 검색 폼 그리드 > 등록기간 > 년월 피커를 정의함
let varSearchRegDatePicker: [Date | null, Date | null] = [null, null];

// 검색 폼 그리드 > 수정기간 > 년월 피커를 정의함
let varSearchModifyDatePicker: [Date | null, Date | null] = [null, null];

// 검색 폼 그리드 > Industry > Industry 1 셀렉트를 정의함
let varSearchIndustry1Select: string = '';

// 검색 폼 그리드 > Industry > Industry 2 셀렉트를 정의함
let varSearchIndustry2Select: string = '';

// 검색 폼 그리드 > Solution > Solution 1 셀렉트를 정의함
let varSearchSolution1Select: string = '';

// 검색 폼 그리드 > Solution > Solution 2 셀렉트를 정의함
let varSearchSolution2Select: string = '';

// 검색 폼 그리드 > 딜종류 > 셀렉트를 정의함
let varSearchDealTypeSelect: string = '';

// 검색 폼 그리드 > 납품구분 > 셀렉트를 정의함
let varSearchDeliveryTypeSelect: string = '';

// 검색 폼 그리드 > 영업단계구분 > 셀렉트를 정의함
let varSearchSaleStepTypeSelect: string = '';

// 검색 폼 그리드 > 제안결과구분 > 셀렉트를 정의함
let varSearchProposalResultTypeSelect: string = '';

// 검색 폼 그리드 > 확도 > 셀렉트를 정의함
let varSearchProbabilitySelect: string = '';

// 검색 폼 그리드 > 확도 > 슬라이더를 정의함
let varSearchProbabilitySlider: [number, number] = [0, 100];

// 검색 폼 그리드 > 태그 > 입력을 정의함
let varSearchTagInput: string = '';

const MN2302220203Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 영업 기회 현황을 정의함
  const [slsPrtn, setSlsPrtn] = useState<any>([]);

  // 전체 현황 테이블을 정의함
  const [totalStatusTable, setTotalStatusTable] = useState<{
    column: ITableColumn[];
    data: any;
  }>({ column: [], data: [] });

  // 항목별 현황 테이블을 정의함
  const [categoryStatusTable, setCategoryStatusTable] = useState<{
    column: ITableColumn[];
    data: any;
  }>({ column: [], data: [] });

  // 비율별 현황 테이블을 정의함
  const [percentStatusTable, setPercentStatusTable] = useState<{
    column: ITableColumn[];
    data: any;
  }>({ column: [], data: [] });

  // 현황 테이블을 정의함
  const [statusTable, setStatusTable] = useState<{
    column: ITableColumn[];
    data: any;
  }>({ column: [], data: [] });

  // 년월 현황 테이블을 정의함
  const [statusTable2, setStatusTable2] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 년월 현황 테이블의 로딩 여부를 정의함
  const [statusTable2Loading, setStatusTable2Loading] =
    useState<boolean>(false);

  // 검색 폼 그리드 > 검색어 > 셀렉트를 정의함
  const [searchCategorySelect, setSearchCategorySelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  // 검색 폼 그리드 > 등록기간 > 년월 피커를 정의함
  const [searchRegDatePicker, setSearchRegDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  // 검색 폼 그리드 > 수정기간 > 년월 피커를 정의함
  const [searchModifyDatePicker, setSearchModifyDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  // 검색 폼 그리드 > Industry > Industry 1 셀렉트를 정의함
  const [searchIndustry1Select, setSearchIndustry1Select] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > Industry > Industry 2 셀렉트를 정의함
  const [searchIndustry2Select, setSearchIndustry2Select] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > Solution > Solution 1 셀렉트를 정의함
  const [searchSolution1Select, setSearchSolution1Select] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > Solution > Solution 2 셀렉트를 정의함
  const [searchSolution2Select, setSearchSolution2Select] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 딜종류 > 셀렉트를 정의함
  const [searchDealTypeSelect, setSearchDealTypeSelect] = useState<ISelect>({
    value: '',
    item: [],
  });

  // 검색 폼 그리드 > 납품구분 > 셀렉트를 정의함
  const [searchDeliveryTypeSelect, setSearchDeliveryTypeSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 폼 그리드 > 영업단계구분 > 셀렉트를 정의함
  const [searchSaleStepTypeSelect, setSearchSaleStepTypeSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 폼 그리드 > 제안결과구분 > 셀렉트를 정의함
  const [searchProposalResultTypeSelect, setSearchProposalResultTypeSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 폼 그리드 > 확도 > 셀렉트를 정의함
  const [searchProbabilitySelect, setSearchProbabilitySelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 폼 그리드 > 확도 > 슬라이더를 정의함
  const [searchProbabilitySlider, setSearchProbabilitySlider] =
    useState<ISlider>({
      value: [0, 100],
      item: [],
    });

  // 검색 폼 그리드 > 태그 > 입력을 정의함
  const [searchTagInput, setSearchTagInput] = useState<string>('');

  // 검색 결과 테이블 > 정렬 셀렉트를 정의함
  const [searchResultTableSortSelect, setSearchResultTableSortSelect] =
    useState<ISelect>({
      value: '',
      item: [],
    });

  // 검색 결과 테이블을 정의함
  const [searchResultTable, setSearchResultTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 검색 결과 테이블 페이징을 정의함
  const [searchResultTablePaging, setSearchResultTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const searchResultTablePagingCurrentPageRef = useRef<number>(1);

  // 검색 결과 테이블의 로딩 여부를 정의함
  const [searchResultTableLoading, setSearchResultTableLoading] =
    useState<boolean>(false);

  // 테이블의 참조를 정의함
  const statusTable2Ref = useRef<any>(null);

  // 영업 기회 현황의 항목을 정의함
  const slsPrtnCategoryItem: { title: string; column: string }[] = [
    {
      title: 'Identified',
      column: 'dntf',
    },
    {
      title: 'Contacted',
      column: 'cntc',
    },
    {
      title: 'Qualified',
      column: 'qlfd',
    },
    {
      title: 'Proposing',
      column: 'prps',
    },
    {
      title: 'Proposed',
      column: 'prpd',
    },
    {
      title: 'Decided',
      column: 'dcdd',
    },
    {
      title: 'Pending',
      column: 'pndn',
    },
  ];

  // 영업 기회 현황의 비율 항목을 정의함
  const slsPrtnPercentItem: { title: string; column: string }[] = [
    {
      title: '100%',
      column: 'p100',
    },
    {
      title: '90%',
      column: 'p90',
    },
    {
      title: '80%',
      column: 'p80',
    },
    {
      title: '50%',
      column: 'p50',
    },
    {
      title: '30%',
      column: 'p30',
    },
    {
      title: '10%',
      column: 'p10',
    },
    {
      title: '0%',
      column: 'p0',
    },
  ];

  // 페이지 상단바 > 등록 버튼을 클릭함
  const handleRegButton_onChange = () => {
    let tmpId: string = 'MN2302220202';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '영업 기회 등록',
      content: <MN2302220202 id={tmpId} />,
      size: 1500,
    });
  };

  // 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCategorySelect_onChange = (event: any) => {
    varSearchCategorySelect = event;
    setSearchCategorySelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    varSearchInput = event.currentTarget.value;
    setSearchInput(event.currentTarget.value);
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력에서 키를 입력함
  const handleSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
  const handleSearchButton_onClick = () => {
    // 테이블의 페이지를 변경함
    searchResultTablePagingCurrentPageRef.current = 1;
    setSearchResultTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: searchResultTablePagingCurrentPageRef.current,
    }));

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();
  };

  // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
  const handleSearchCancelButton_onClick = () => {
    // 검색 폼 그리드 > 검색어 > 셀렉트 값을 초기화함
    varSearchCategorySelect = 'sls_prtn_name';
    setSearchCategorySelect((pre: ISelect) => ({
      ...pre,
      value: 'sls_prtn_name',
    }));

    // 검색 폼 그리드 > 검색어 > 검색어 입력 값을 초기화함
    varSearchInput = '';
    setSearchInput('');

    // 검색 폼 그리드 > 등록기간 > 년월 피커 값을 초기화함
    varSearchRegDatePicker = [null, null];
    setSearchRegDatePicker([null, null]);

    // 검색 폼 그리드 > 수정기간 > 년월 피커 값을 초기화함
    varSearchModifyDatePicker = [null, null];
    setSearchModifyDatePicker([null, null]);

    // 검색 폼 그리드 > Industry > Industry 1 셀렉트 값을 초기화함
    varSearchIndustry1Select = '';
    setSearchIndustry1Select((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > Industry > Industry 2 셀렉트 값을 초기화함
    varSearchIndustry2Select = '';
    setSearchIndustry2Select((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > Solution > Solution 1 셀렉트 값을 초기화함
    varSearchSolution1Select = '';
    setSearchSolution1Select((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > Solution > Solution 2 셀렉트 값을 초기화함
    varSearchSolution2Select = '';
    setSearchSolution2Select((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 딜종류 > 셀렉트 값을 초기화함
    varSearchDealTypeSelect = '';
    setSearchDealTypeSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 납품구분 > 셀렉트 값을 초기화함
    varSearchDeliveryTypeSelect = '';
    setSearchDeliveryTypeSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 영업단계구분 > 셀렉트 값을 초기화함
    varSearchSaleStepTypeSelect = '';
    setSearchSaleStepTypeSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 제안결과구분 > 셀렉트 값을 초기화함
    varSearchProposalResultTypeSelect = '';
    setSearchProposalResultTypeSelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 확도 > 셀렉트 값을 초기화함
    varSearchProbabilitySelect = '';
    setSearchProbabilitySelect((pre: ISelect) => ({
      ...pre,
      value: '',
    }));

    // 검색 폼 그리드 > 확도 > 슬라이더 값을 초기화함
    varSearchProbabilitySlider = [0, 100];
    setSearchProbabilitySlider((pre: ISlider) => ({
      ...pre,
      value: [0, 100],
    }));

    // 검색 폼 그리드 > 태그 > 입력 값을 초기화함
    varSearchTagInput = '';
    setSearchTagInput('');
  };

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    setHideDetailSearch(!hideDetailSearch);
  };

  // 검색 폼 그리드 > 등록기간 > 년월 피커를 변경함
  const handleSearchRegDatePicker_onChange = (event: any) => {
    varSearchRegDatePicker = event;
    setSearchRegDatePicker(event);
  };

  // 검색 폼 그리드 > 수정기간 > 년월 피커를 변경함
  const handleSearchModifyDatePicker_onChange = (event: any) => {
    varSearchModifyDatePicker = event;
    setSearchModifyDatePicker(event);
  };

  // 검색 폼 그리드 > Industry > Industry 1 셀렉트에서 선택한 아이템을 변경함
  const handleSearchIndustry1Select_onChange = (event: any) => {
    varSearchIndustry1Select = event;
    setSearchIndustry1Select((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > Industry > Industry 2 셀렉트에서 선택한 아이템을 변경함
  const handleSearchIndustry2Select_onChange = (event: any) => {
    varSearchIndustry2Select = event;
    setSearchIndustry2Select((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > Solution > Solution 1 셀렉트에서 선택한 아이템을 변경함
  const handleSearchSolution1Select_onChange = (event: any) => {
    varSearchSolution1Select = event;
    setSearchSolution1Select((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > Solution > Solution 2 셀렉트에서 선택한 아이템을 변경함
  const handleSearchSolution2Select_onChange = (event: any) => {
    varSearchSolution2Select = event;
    setSearchSolution2Select((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 딜종류 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchDealTypeSelect_onChange = (event: any) => {
    varSearchDealTypeSelect = event;
    setSearchDealTypeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 납품구분 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchDeliveryTypeSelect_onChange = (event: any) => {
    varSearchDeliveryTypeSelect = event;
    setSearchDeliveryTypeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 영업단계구분 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchSaleStepTypeSelect_onChange = (event: any) => {
    console.log('> event:', event);
    varSearchSaleStepTypeSelect = event;
    setSearchSaleStepTypeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 제안결과구분 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchProposalResultTypeSelect_onChange = (event: any) => {
    varSearchProposalResultTypeSelect = event;
    setSearchProposalResultTypeSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 확도 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchProbabilitySelect_onChange = (event: any) => {
    varSearchProbabilitySelect = event;
    setSearchProbabilitySelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 확도 > 슬라이더에서 선택한 아이템을 변경함
  const handleSearchProbabilitySlider_onChange = (event: any) => {
    varSearchProbabilitySlider = event;
    setSearchProbabilitySlider((pre: ISlider) => ({
      ...pre,
      value: event,
    }));
  };

  // 검색 폼 그리드 > 태그 > 입력의 값을 변경함
  const handleSearchTagInput_onChange = (event: any) => {
    varSearchTagInput = event.currentTarget.value;
    setSearchTagInput(event.currentTarget.value);
  };

  // 검색 폼 그리드 > 태그 > 입력에서 키를 입력함
  const handleSearchTagInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 검색 결과 테이블의 엑셀 다운로드 버튼을 클릭함
  const handleSearchResultTableExportExcelButton_onClick = () => {
    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <>
          {/* 버튼 */}
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              slsApi
                .getSlsPrtnExcel({
                  // cntGrpCode: , // Account 그룹
                  searchKeyword: searchInput.trim(), // 검색어
                  searchType: searchCategorySelect.value, // 검색 구분
                  rgstStDt: searchRegDatePicker[0]
                    ? moment(searchRegDatePicker[0]).format('YYYYMM')
                    : '', // 등록기간 종료일
                  rgstEndDt: searchRegDatePicker[1]
                    ? moment(searchRegDatePicker[1]).format('YYYYMM')
                    : '', // 등록기간 시작일
                  mdfcEndDt: searchModifyDatePicker[1]
                    ? moment(searchModifyDatePicker[1]).format('YYYYMM')
                    : '', // 수정기간 종료일
                  mdfcStDt: searchModifyDatePicker[0]
                    ? moment(searchModifyDatePicker[0]).format('YYYYMM')
                    : '', // 수정기간 시작일
                  ndstDntfCode: searchIndustry1Select.value, // 산업 구분 코드
                  ndstSubCode: searchIndustry2Select.value, // 산업 구분 하위 코드
                  ordr: searchResultTableSortSelect.value, // 정렬 구분
                  slsSltnCode: searchSolution1Select.value, // 영업 솔루션 코드
                  slsSubCode: searchSolution2Select.value, // 영업 솔루션 하위코드
                  slsDealCode: searchDealTypeSelect.value, // 딜 종류
                  slsDlvrCode: searchDeliveryTypeSelect.value, // 납품 구분
                  slsStgCode: searchSaleStepTypeSelect.value, // 영업 단계 코드
                  sgstRsltCode: searchProposalResultTypeSelect.value, // 제안 결과 코드
                  stSlsPrbbCode: `P${searchProbabilitySlider.value[0]}`, // 영업 확도 코드
                  edSlsPrbbCode: `P${searchProbabilitySlider.value[1]}`, // 영업 확도 코드
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    ['영업기회_', moment().format('YYYYMMDD'), '.xlsx'].join(
                      '',
                    ),
                  );
                  document.body.appendChild(link);
                  link.click();
                });

              setRemoveModal(true);
            }}
          >
            예
          </Button>
        </>
      ),
    });
  };

  // 검색 결과 테이블 > 정렬 셀렉트에서 선택한 아이템을 변경함
  const handleSearchResultTableSortSelect_onChange = (event: any) => {
    setSearchResultTableSortSelect((pre: ISelect) => ({
      ...pre,
      value: event,
    }));

    // // 정렬 순서를 정의함
    // let tmpSortType: string[] = event.split(',');

    // // 데이터를 정렬함
    // let tmpData: any[] = _.orderBy(
    //   searchResultTable.data,
    //   [tmpSortType[0]],
    //   [tmpSortType[1] === 'desc' ? 'desc' : 'asc'],
    // );

    // // 검색 결과 테이블에 적용함
    // setSearchResultTable((pre: { column: any; data: any }) => ({
    //   ...pre,
    //   data: tmpData,
    // }));
  };

  // 검색 결과 테이블의 행을 클릭함
  const handleSearchResultTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220202';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '영업 기회 상세',
      content: <MN2302220202 id={tmpId} slsPrtnId={row.data.slsPrtnId} />,
      size: 1500,
    });
  };

  // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
  const initSearchCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      // {
      //   label: '고객사명',
      //   value: 'bp_name',
      // },
      {
        label: 'Project Name',
        value: 'sls_prtn_name',
      },
      {
        label: '영업담당자명',
        value: 'ntrn_cntc_name',
      },
      {
        label: '태그',
        value: 'tag',
      },
    );

    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    varSearchCategorySelect = 'sls_prtn_name';
    setSearchCategorySelect((pre: ISelect) => ({
      value: 'sls_prtn_name',
      item: tmpOptionItem,
    }));
  };

  // 검색 폼 그리드 > Industry > Industry 1 셀렉트의 아이템을 초기화함
  const initSearchIndustry1Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // tmpOptionItem.push(
    //   {
    //     label: '전체',
    //     value: '',
    //   },
    //   {
    //     label: 'Auto',
    //     value: 'AUTO',
    //   },
    //   {
    //     label: 'Consumer',
    //     value: 'CNSM',
    //   },
    //   {
    //     label: 'ER&I',
    //     value: 'ERNI',
    //   },
    //   {
    //     label: 'FS',
    //     value: 'FS',
    //   },
    //   {
    //     label: 'LSHC',
    //     value: 'LSHC',
    //   },
    //   {
    //     label: 'Public',
    //     value: 'PBLC',
    //   },
    //   {
    //     label: 'TMT',
    //     value: 'TMT',
    //   },
    // );

    // 공통코드를 불러옴
    appUtil.getCommonCode('NDST_DNTF', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 검색 폼 그리드 > Industry > Industry 1 셀렉트의 아이템에 적용함
    varSearchIndustry1Select = '';
    setSearchIndustry1Select((pre: ISelect) => ({
      value: '',
      item: tmpOptionItem,
    }));
  };

  // 검색 폼 그리드 > Industry > Industry 2 셀렉트의 아이템을 초기화함
  const initSearchIndustry2Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // // Industry 구분 하위코드를 불러옴
    // slsApi
    //   .getSlsPrtnSearchNdstcode({ dtlCodeId: searchIndustry1Select.value })
    //   .then((data: IApiResult) => {
    //     data.data.list.map((item: any, index: number) => {
    //       tmpOptionItem.push({
    //         label: item.dtlCodeDscr,
    //         value: item.dtlCodeId,
    //       });
    //     });
    //   });

    // 공통코드를 불러옴
    appUtil.getCommonCode('NDST_CTGR', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 검색 폼 그리드 > Industry > Industry 2 셀렉트의 아이템에 적용함
    varSearchIndustry2Select = '';
    setSearchIndustry2Select({
      value: '',
      item: tmpOptionItem,
    });
  };

  // 검색 폼 그리드 > Solution > Solution 1 셀렉트의 아이템을 초기화함
  const initSearchSolution1Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({ label: '전체', value: '' });

    //   tmpOptionItem.push(
    //   { label: '전체', value: '' },
    //   {
    //     label: 'META',
    //     value: 'META',
    //   },
    //   {
    //     label: 'IWP',
    //     value: 'IWP',
    //   },
    //   {
    //     label: 'MES',
    //     value: 'MES',
    //   },
    //   {
    //     label: 'ESL',
    //     value: 'ESL',
    //   },
    //   {
    //     label: 'CONSULTING',
    //     value: 'CNSL',
    //   },
    //   {
    //     label: 'Others',
    //     value: 'THRS',
    //   },
    // );

    // 공통코드를 불러옴
    appUtil.getCommonCode('SLS_MAIN', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 검색 폼 그리드 > Solution > Solution 1 셀렉트의 아이템에 적용함
    varSearchSolution1Select = '';
    setSearchSolution1Select((pre: ISelect) => ({
      value: '',
      item: tmpOptionItem,
    }));
  };

  // 검색 폼 그리드 > Solution > Solution 2 셀렉트의 아이템을 초기화함
  const initSearchSolution2Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // // Solution 하위코드를 불러옴
    // slsApi
    //   .getSlsPrtnSearchSltncode({ dtlCodeId: searchSolution1Select.value })
    //   .then((data: IApiResult) => {
    //     data.data.list.map((item: any, index: number) => {
    //       tmpOptionItem.push({
    //         label: item.dtlCodeDscr,
    //         value: item.dtlCodeId,
    //       });
    //     });
    //   });

    // 공통코드를 불러옴
    appUtil.getCommonCode('SLS_DTL', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 검색 폼 그리드 > Solution > Solution 2 셀렉트의 아이템에 적용함
    varSearchSolution2Select = '';
    setSearchSolution2Select({
      value: '',
      item: tmpOptionItem,
    });
  };

  // 검색 폼 그리드 > 딜종류 > 셀렉트의 아이템을 초기화함
  const initSearchDealTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(딜종류)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'SLS_DEAL' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 딜종류 > 셀렉트의 아이템에 적용함
        setSearchDealTypeSelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  // 검색 폼 그리드 > 납품구분 > 셀렉트의 아이템을 초기화함
  const initSearchDeliveryTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(납품구분)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'SLS_DLVR' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 납품구분 > 셀렉트의 아이템에 적용함
        setSearchDeliveryTypeSelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  // 검색 폼 그리드 > 영업단계구분 > 셀렉트의 아이템을 초기화함
  const initSearchSaleStepTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(영업단계구분)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'SLS_STG' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 영업단계구분 > 셀렉트의 아이템에 적용함
        setSearchSaleStepTypeSelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  // 검색 폼 그리드 > 제안결과구분 > 셀렉트의 아이템을 초기화함
  const initSearchProposalResultTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(제안결과구분)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'PRPS_RSLT' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 제안결과구분 > 셀렉트의 아이템에 적용함
        setSearchProposalResultTypeSelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  // 검색 폼 그리드 > 확도 > 셀렉트의 아이템을 초기화함
  const initSearchProbabilitySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '전체',
      value: '',
    });

    // 상세 공통코드(확도)를 불러옴
    cmnApi
      .getDetailCodesAll({ grpCodeId: 'SLS_CRTN' })
      .then((data: IApiResult) => {
        _.sortBy(data.data.list, ['sortRdr']).map(
          (item: any, index: number) => {
            tmpOptionItem.push({
              label: item.dtlCodeDscr,
              value: item.dtlCodeId,
            });
          },
        );

        // 검색 폼 그리드 > 확도 > 셀렉트의 아이템에 적용함
        setSearchProbabilitySelect((pre: ISelect) => ({
          ...pre,
          item: tmpOptionItem,
        }));
      });
  };

  // 검색 폼 그리드 > 확도 > 슬라이더의 아이템을 초기화함
  const initSearchProbabilitySlider = () => {
    let tmpOptionItem: INumberOptionItem[] = [];

    // 퍼센트를 생성함
    _.range(0, 101, 10).map((item: number, index: number) => {
      tmpOptionItem.push({
        label: `${item}%`,
        value: item,
      });
    });

    // 검색 폼 그리드 > 확도 > 슬라이더의 아이템에 적용함
    setSearchProbabilitySlider((pre: ISlider) => ({
      ...pre,
      item: tmpOptionItem,
    }));

    // 상세 공통코드(확도)를 불러옴
    // cmnApi
    //   .getDetailCodesAll({ grpCodeId: 'SLS_CRTN' })
    //   .then((data: IApiResult) => {
    //     _.sortBy(data.data.list, ['sortRdr']).map(
    //       (item: any, index: number) => {
    //         tmpOptionItem.push({
    //           label: item.dtlCodeDscr,
    //           value: +item.dtlCodeId.replaceAll('P', ''),
    //         });
    //       },
    //     );
    //
    //     // 검색 폼 그리드 > 확도 > 슬라이더의 아이템에 적용함
    //     setSearchProbabilitySlider((pre: ISlider) => ({
    //       ...pre,
    //       item: tmpOptionItem,
    //     }));
    //   });
  };

  // 영업 기회 현황을 불러옴
  const getSlsPrtnStatus = () => {
    // 검색 결과 테이블의 로딩 여부에 적용함
    setStatusTable2Loading(true);

    slsApi
      .getSlsPrtnStatus({
        stDate: moment().add(-11, 'M').format('YYYYMMDD'),
        endDate: moment().format('YYYYMMDD'),
      })
      .then((data: IApiResult) => {
        // 영업 기회 현황에 적용함
        setSlsPrtn(data.data);

        // 검색 결과 테이블의 로딩 여부에 적용함
        setStatusTable2Loading(false);
      })
      .catch((error: any) => {
        // 검색 결과 테이블의 로딩 여부에 적용함
        setStatusTable2Loading(false);
      });
  };

  // 항목별 현황 테이블을 초기화함
  const initCategoryStatusTable = () => {
    // 컬럼을 정의함
    let tmpColumn: ITableColumn[] = [];

    // 데이터를 정의함
    let tmpData: any = {};

    // 컬럼을 생성함
    slsPrtnCategoryItem.map(
      (item: { title: string; column: string }, index: number) => {
        tmpColumn.push({
          column: item.column,
          title: item.title,
          width: '0',
          align: 'center',
          cellRenderer: (params: any) => {
            return (
              <span
                className={[
                  (params === 0 || params === '0') && 'text-blue-200',
                ].join(' ')}
              >
                {params}
              </span>
            );
          },
        });
      },
    );

    // 데이터를 생성함
    slsPrtnCategoryItem.map(
      (item: { title: string; column: string }, index: number) => {
        tmpData[item.column] = slsPrtn.totalCnt[item.column] || 0;
      },
    );

    // 테이블에 적용함
    setCategoryStatusTable((pre: { column: ITableColumn[]; data: any }) => ({
      column: tmpColumn,
      data: [tmpData],
    }));
  };

  // 전체 현황 테이블을 초기화함
  const initTotalStatusTable = () => {
    // 컬럼을 정의함
    let tmpColumn: ITableColumn[] = [];

    // 데이터를 정의함
    let tmpData: any = {};

    // 컬럼을 생성함
    tmpColumn.push({
      column: 'total',
      title: '전체',
      width: '0',
      align: 'center',
      cellRenderer: (params: any) => {
        return (
          <span
            className={[
              (params === 0 || params === '0') && 'text-blue-200',
            ].join(' ')}
          >
            {params}
          </span>
        );
      },
    });

    // 데이터를 생성함
    tmpData['total'] = slsPrtn.totalCnt['total'] || 0;

    // 테이블에 적용함
    setTotalStatusTable((pre: { column: ITableColumn[]; data: any }) => ({
      column: tmpColumn,
      data: [tmpData],
    }));
  };

  // 비율별 현황 테이블을 초기화함
  const initPercentStatusTable = () => {
    // 컬럼을 정의함
    let tmpColumn: ITableColumn[] = [];

    // 데이터를 정의함
    let tmpData: any = {};

    // 컬럼을 생성함
    slsPrtnPercentItem.map(
      (item: { title: string; column: string }, index: number) => {
        tmpColumn.push({
          column: item.column,
          title: item.title,
          width: '0',
          align: 'center',
          cellRenderer: (params: any) => {
            return (
              <span
                className={[
                  (params === 0 || params === '0') && 'text-blue-200',
                ].join(' ')}
              >
                {params}
              </span>
            );
          },
        });
      },
    );

    // 데이터를 생성함
    slsPrtnPercentItem.map(
      (item: { title: string; column: string }, index: number) => {
        tmpData[item.column] = slsPrtn.totalCnt[item.column] || 0;
      },
    );

    // 테이블에 적용함
    setPercentStatusTable((pre: { column: ITableColumn[]; data: any }) => ({
      column: tmpColumn,
      data: [tmpData],
    }));
  };

  // 현황 테이블을 초기화함
  const initStatusTable = () => {
    // 컬럼을 정의함
    let tmpColumn: ITableColumn[] = [];

    // 데이터를 정의함
    let tmpData: any[] = [];

    // 컬럼에 추가함
    tmpColumn.push({
      column: 'step',
      title: '단계',
      width: '0',
      align: 'center',
    });

    // 컬럼을 생성함
    _.orderBy(slsPrtn.monthList, ['rgstMonth'], ['desc'])?.map(
      (item: any, index: number) => {
        tmpColumn.push({
          column: moment(item.rgstMonth).format('yyyyMM'),
          title: moment(item.rgstMonth).format('yyyy.MM'),
          width: '100',
          align: 'center',
        });
      },
    );

    // 데이터를 생성함
    slsPrtnCategoryItem.map(
      (item: { title: string; column: string }, index: number) => {
        let tmpDataRow: any = {};

        tmpDataRow['step'] = item.title;

        slsPrtn.monthList?.map((subItem: any, index: number) => {
          tmpDataRow[subItem.rgstMonth] = subItem[item.column];
        });

        tmpData.push(tmpDataRow);
      },
    );

    // 현황 테이블에 적용함
    setStatusTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
      // data: tmpData,
    }));
  };

  // 검색 결과 테이블 > 정렬 셀렉트의 아이템을 초기화함
  const initSearchResultTableSortSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '최근 등록일순',
        value: 'ordrByRgstDt',
      },
      {
        label: '최근 수정일순',
        value: 'ordrByMdfcDt',
      },
      {
        label: '확도 높은 순',
        value: 'ordrByPrbb',
      },
      {
        label: 'NSR 규모 높은 순',
        value: 'ordrByNsr',
      },
    );

    // tmpOptionItem.push(
    //   {
    //     label: '정렬 순서 선택',
    //     value: 'no,desc',
    //   },
    //   {
    //     label: '최근 등록일부터',
    //     value: 'rgstDate,desc',
    //   },
    //   {
    //     label: '예전 등록일부터',
    //     value: 'rgstDate,asc',
    //   },
    //   {
    //     label: '고객사명',
    //     value: 'bpName,asc',
    //   },
    //   {
    //     label: '계정',
    //     value: 'cnt,asc',
    //   },
    //   {
    //     label: '최근 프로젝트 완료일부터',
    //     value: 'stmtCmplDate,desc',
    //   },
    //   {
    //     label: '예전 프로젝트 완료일부터',
    //     value: 'stmtCmplDate,asc',
    //   },
    // );

    // 검색 결과 테이블 > 정렬 셀렉트의 아이템에 적용함
    setSearchResultTableSortSelect((pre: ISelect) => ({
      value: 'ordrByRgstDt',
      item: tmpOptionItem,
    }));
  };

  // 년월별 현황 테이블을 초기화함
  const initStatusTable2 = () => {
    // 검색 결과 테이블의 로딩 여부에 적용함
    setStatusTable2Loading(true);

    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push({
      headerName: '단계',
      field: 'step',
      pinned: 'left',
      width: 150,
      suppressSizeToFit: true,
    });

    // 컬럼을 생성함
    _.orderBy(slsPrtn.monthList, ['rgstMonth'], ['desc'])?.map(
      (item: any, index: number) => {
        tmpColumn.push({
          headerName: `${moment(item.rgstMonth).format('yyyy')}\n${moment(
            item.rgstMonth,
          ).format('MM')}`,
          field: moment(item.rgstMonth).format('yyyyMM'),
          width: 100,
          type: 'rightAligned',
          cellRenderer: (params: any) => {
            return (
              <span
                className={[
                  (params.value === 0 || params.value === '0') &&
                    'text-blue-200',
                ].join(' ')}
              >
                {params.value}
              </span>
            );
          },
        });
      },
    );

    // 데이터를 정의함
    let tmpData: any[] = [];

    // 데이터를 생성함
    slsPrtnCategoryItem.map(
      (item: { title: string; column: string }, index: number) => {
        let tmpDataRow: any = {};

        tmpDataRow['step'] = item.title;

        slsPrtn.monthList?.map((subItem: any, index: number) => {
          tmpDataRow[moment(subItem.rgstMonth).format('yyyyMM')] =
            subItem[item.column];
        });

        tmpData.push(tmpDataRow);
      },
    );

    // 검색 결과 테이블에 적용함
    setStatusTable2((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
      data: tmpData,
    }));

    // 검색 결과 테이블의 로딩 여부에 적용함
    setStatusTable2Loading(false);
  };

  // 검색 결과 테이블을 초기화함
  const initSearchResultTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'no',
        headerName: 'NO',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
      },
      {
        field: 'slsPrtnId',
        headerName: '영업기회번호',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '영업 정보',
        children: [
          {
            headerName: 'Project Name',
            field: 'slsPrtnName',
            width: 270,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.data.slsPrtnName);
            },
          },
        ],
      },
      {
        headerName: '고객사 정보',
        children: [
          { headerName: 'Industry', field: 'ndstDntfName', width: 150 },
          { headerName: 'Type', field: 'ndstSubName', width: 150 },
          { headerName: 'Division_SP', field: 'cntGrpName', width: 150 },
          { headerName: 'Client Name', field: 'slsPlcName', width: 200 },
          { headerName: 'Market Type', field: 'mrktTypeName', width: 130 },
          {
            headerName: '사업자등록번호',
            field: 'cmpnRgstNmbr',
            width: 150,
            valueFormatter: (params: ValueFormatterParams) => {
              return decode(params.data.cmpnRgstNmbr);
            },
          },
        ],
      },
      {
        headerName: '영업 정보',
        children: [
          { headerName: 'Product.1', field: 'slsSltnName', width: 150 },
          { headerName: 'Product.2', field: 'slsSubName', width: 150 },
          { headerName: 'Deal Type', field: 'slsDealName', width: 130 },
          { headerName: 'Service Type', field: 'slsDlvrName', width: 200 },
        ],
      },
      {
        headerName: '프로젝트 정보',
        children: [
          {
            headerName: 'Expected Order Date',
            field: 'xpctFxdDate',
            width: 130,
          },
          { headerName: '프로젝트 시작일', field: 'stmtStrtDate', width: 130 },
          { headerName: '프로젝트 완료일', field: 'stmtCmplDate', width: 130 },
          {
            headerName: 'Expected Contract Amount',
            field: 'cntrSizeMnt',
            width: 180,
            type: 'rightAligned',
          },
          {
            headerName: 'Expected Direct Cost',
            field: 'nsrSclMnt',
            width: 180,
            type: 'rightAligned',
          },
          {
            headerName: 'NSR X 확도',
            field: 'nsrxprb',
            width: 120,
            type: 'rightAligned',
          },
        ],
      },
      {
        headerName: '영업 활동 정보',
        children: [
          { headerName: '영업단계', field: 'slsStgName', width: 130 },
          {
            headerName: '확도',
            field: 'slsPrbbName',
            width: 90,
            type: 'rightAligned',
          },
          { headerName: '제안결과', field: 'sgstRsltName', width: 130 },
          // { headerName: '제안결과코드', field: 'sgstRsltCode', width: 130 },
        ],
      },
      {
        headerName: '담당자(내부)',
        children: [{ headerName: '담당자', field: 'ntrnCntcName', width: 130 }],
      },
      {
        headerName: '등록 정보',
        children: [
          { headerName: '등록자', field: 'rgstName', width: 100 },
          { headerName: '등록일시', field: 'rgstDate', width: 130 },
        ],
      },
    );

    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 검색 결과 테이블 데이터를 불러옴
  const getSearchResultTableData = () => {
    // 검색 결과 테이블의 로딩 여부에 적용함
    setSearchResultTableLoading(true);

    slsApi
      .getSlsPrtns({
        searchKeyword: varSearchInput.trim(),
        searchType: varSearchCategorySelect,
        rgstStDt: varSearchRegDatePicker[0]
          ? moment(varSearchRegDatePicker[0]).format('YYYYMM')
          : '',
        rgstEndDt: varSearchRegDatePicker[1]
          ? moment(varSearchRegDatePicker[1]).format('YYYYMM')
          : '',
        mdfcStDt: varSearchModifyDatePicker[0]
          ? moment(varSearchModifyDatePicker[0]).format('YYYYMM')
          : '',
        mdfcEndDt: varSearchModifyDatePicker[1]
          ? moment(varSearchModifyDatePicker[1]).format('YYYYMM')
          : '',
        ndstDntfCode: varSearchIndustry1Select,
        ndstSubCode: varSearchIndustry2Select,
        slsSltnCode: varSearchSolution1Select,
        slsSubCode: varSearchSolution2Select,
        slsDealCode: varSearchDealTypeSelect,
        slsDlvrCode: varSearchDeliveryTypeSelect,
        sgstRsltCode: varSearchProposalResultTypeSelect,
        slsStgCode: varSearchSaleStepTypeSelect,
        stSlsPrbbCode: `P${varSearchProbabilitySlider[0]}`,
        edSlsPrbbCode: `P${varSearchProbabilitySlider[1]}`,
        // stSlsPrbbCode:
        //   varSearchProbabilitySlider[0] === 0 &&
        //   varSearchProbabilitySlider[1] === 100
        //     ? 'P0'
        //     : `P${varSearchProbabilitySlider[0]}`,
        // edSlsPrbbCode:
        //   varSearchProbabilitySlider[0] === 0 &&
        //   varSearchProbabilitySlider[1] === 100
        //     ? ''
        //     : `P${varSearchProbabilitySlider[1]}`,
        ordr: searchResultTableSortSelect.value,
        tag: varSearchTagInput.trim(),
        pageSize: searchResultTablePaging.rowPerPage,
        currPageIdx: searchResultTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          data.data.list.map((item: any, index: number) => {
            item.cntrSizeMnt = addComma(item.cntrSizeMnt);
            item.nsrSclMnt = addComma(item.nsrSclMnt);
          });

          // 검색 결과 테이블에 적용함
          setSearchResultTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
            }),
          );

          // 테이블을 페이징함
          setSearchResultTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }

        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      })
      .catch((error: any) => {
        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      });
  };

  // 숫자를 천단위 , 추가
  const addComma = (value: string) => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 영업 기회 현황을 불러옴
    getSlsPrtnStatus();

    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
    initSearchCategorySelect();

    // 검색 폼 그리드 > Industry > Industry 1 셀렉트의 아이템을 초기화함
    initSearchIndustry1Select();

    // 검색 폼 그리드 > Industry > Industry 2 셀렉트의 아이템을 초기화함
    initSearchIndustry2Select();

    // 검색 폼 그리드 > Solution > Solution 1 셀렉트의 아이템을 초기화함
    initSearchSolution1Select();

    // 검색 폼 그리드 > Solution > Solution 2 셀렉트의 아이템을 초기화함
    initSearchSolution2Select();

    // 검색 폼 그리드 > 딜종류 > 셀렉트의 아이템을 초기화함
    initSearchDealTypeSelect();

    // 검색 폼 그리드 > 납품구분 > 셀렉트의 아이템을 초기화함
    initSearchDeliveryTypeSelect();

    // 검색 폼 그리드 > 영업단계구분 > 셀렉트의 아이템을 초기화함
    initSearchSaleStepTypeSelect();

    // 검색 폼 그리드 > 제안결과구분 > 셀렉트의 아이템을 초기화함
    initSearchProposalResultTypeSelect();

    // 검색 폼 그리드 > 확도 > 셀렉트의 아이템을 초기화함
    initSearchProbabilitySelect();

    // 검색 폼 그리드 > 확도 > 슬라이더의 아이템을 초기화함
    initSearchProbabilitySlider();

    // 검색 결과 테이블 > 정렬 셀렉트의 아이템을 초기화함
    initSearchResultTableSortSelect();

    // 검색 결과 테이블을 초기화함
    initSearchResultTable();

    return () => {};
  }, []);

  // 영업 기회 현황이 불러온 후 실행함
  useEffect(() => {
    if (slsPrtn.length === 0) {
      return;
    }

    // 전체 현황 테이블을 초기화함
    initTotalStatusTable();

    // 항목별 현황 테이블을 초기화함
    initCategoryStatusTable();

    // 비율별 현황 테이블을 초기화함
    initPercentStatusTable();

    // 현황 테이블을 초기화함
    initStatusTable();

    // 년월 현황 테이블을 초기화함
    initStatusTable2();

    return () => {};
  }, [slsPrtn]);

  // 검색 폼 그리드 > Industry > Industry 1 셀렉트에서 선택한 아이템이 변경될 때 실행함
  useEffect(() => {
    // // 검색 폼 그리드 > Industry > Industry 2 셀렉트의 아이템을 초기화함
    // initSearchIndustry2Select();

    return () => {};
  }, [searchIndustry1Select.value]);

  // 검색 폼 그리드 > Solution > Solution 1 셀렉트에서 선택한 아이템이 변경될 때 실행함
  useEffect(() => {
    // // 검색 폼 그리드 > Solution > Solution 2 셀렉트의 아이템을 초기화함
    // initSearchSolution2Select();

    return () => {};
  }, [searchSolution1Select.value]);

  // 검색 결과 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (searchResultTable.column.length === 0) {
      return;
    }

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();

    return () => {};
  }, [searchResultTable.column]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220203Table') > -1) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 영업 기회 현황을 불러옴
      getSlsPrtnStatus();

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220203Table');
    }

    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={
        <>
          {/* 버튼 */}
          <Button color="indigo" radius="xl" onClick={handleRegButton_onChange}>
            등록
          </Button>
        </>
      }
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-10">
        {/* 현황 */}
        <div className="">
          {/* 요약 현황 */}
          <div className="w-full flex justify-center items-center space-x-2">
            {/* 전체 */}
            <div className="grow h-32">
              <PbTable
                column={totalStatusTable.column}
                data={totalStatusTable.data}
                striped={false}
                highlightOnHover={true}
                userSelectNone={true}
              />
            </div>

            {/* 항목별 */}
            <div className="grow h-32">
              <PbTable
                column={categoryStatusTable.column}
                data={categoryStatusTable.data}
                striped={false}
                highlightOnHover={true}
                userSelectNone={true}
                onClickCell={(cell: any) => {
                  // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
                  handleSearchCancelButton_onClick();

                  // 검색 폼 그리드 > 영업단계구분 > 셀렉트에 적용함
                  varSearchSaleStepTypeSelect = _.upperCase(
                    cell.column.column.toString() || '',
                  );
                  setSearchSaleStepTypeSelect((pre: ISelect) => ({
                    ...pre,
                    value: varSearchSaleStepTypeSelect,
                  }));

                  // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
                  handleSearchButton_onClick();
                }}
              />
            </div>

            {/* 비율별 */}
            <div className="grow h-32">
              <PbTable
                column={percentStatusTable.column}
                data={percentStatusTable.data}
                striped={false}
                highlightOnHover={true}
                userSelectNone={true}
                onClickCell={(cell: any) => {
                  // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
                  handleSearchCancelButton_onClick();

                  let tmpValue: number = +cell.column.column.replaceAll(
                    'p',
                    '',
                  );

                  // 검색 폼 그리드 > 확도 > 슬라이더 값에 적용함
                  varSearchProbabilitySlider = [tmpValue, tmpValue];
                  setSearchProbabilitySlider((pre: ISlider) => ({
                    ...pre,
                    value: [tmpValue, tmpValue],
                  }));

                  // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
                  handleSearchButton_onClick();
                }}
              />
            </div>
          </div>

          {/* 테이블 */}
          <div className="w-full h-104">
            <PbAgGridReact
              columnDefs={statusTable2.column}
              rowData={statusTable2.data}
              loading={statusTable2Loading}
              setLoading={setStatusTable2Loading}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="single"
              sizeColumnsToFit={true}
              className="ag-grid-multiple-header-name"
            />
          </div>
        </div>

        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid cols={2}>
            <PbFormGridCol label="검색어" colSpan={2}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchCategorySelect_onChange}
                data={searchCategorySelect.item}
                value={searchCategorySelect.value}
                setSelect={setSearchCategorySelect}
              />

              {/* 텍스트 입력 */}
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={handleSearchInput_onChange}
                onKeyUp={handleSearchInput_onKeyUp}
                value={searchInput}
                className="w-1/5"
              />

              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                {/* 버튼 */}
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleSearchButton_onClick}
                >
                  검색
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchCancelButton_onClick}
                >
                  검색 조건 초기화
                </Button>

                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleShowSearchDetailButton_onChange}
                >
                  {hideDetailSearch ? (
                    <>
                      상세검색 열기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-down']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      상세검색 닫기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-up']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  )}
                </Button>
              </div>
            </PbFormGridCol>
            <PbFormGridCol label="등록기간" isHidden={hideDetailSearch}>
              {/* 년월 피커 */}
              <MonthPickerInput
                type="range"
                placeholder="기간을 선택하세요."
                onChange={handleSearchRegDatePicker_onChange}
                value={searchRegDatePicker}
                valueFormat="YYYY.MM"
                locale="ko"
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="수정기간" isHidden={hideDetailSearch}>
              {/* 년월 피커 */}
              <MonthPickerInput
                type="range"
                placeholder="기간을 선택하세요."
                onChange={handleSearchModifyDatePicker_onChange}
                value={searchModifyDatePicker}
                valueFormat="YYYY.MM"
                locale="ko"
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Industry / Type" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchIndustry1Select_onChange}
                data={searchIndustry1Select.item}
                value={searchIndustry1Select.value}
                setSelect={setSearchIndustry1Select}
                className="w-1/2"
              />

              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchIndustry2Select_onChange}
                data={searchIndustry2Select.item}
                value={searchIndustry2Select.value}
                setSelect={setSearchIndustry2Select}
                className="w-1/2"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Product" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchSolution1Select_onChange}
                data={searchSolution1Select.item}
                value={searchSolution1Select.value}
                setSelect={setSearchSolution1Select}
                className="w-1/2"
              />

              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchSolution2Select_onChange}
                data={searchSolution2Select.item}
                value={searchSolution2Select.value}
                setSelect={setSearchSolution2Select}
                className="w-1/2"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Deal Type" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchDealTypeSelect_onChange}
                data={searchDealTypeSelect.item}
                value={searchDealTypeSelect.value}
                setSelect={setSearchDealTypeSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Service Type" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchDeliveryTypeSelect_onChange}
                data={searchDeliveryTypeSelect.item}
                value={searchDeliveryTypeSelect.value}
                setSelect={setSearchDeliveryTypeSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="영업단계구분" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchSaleStepTypeSelect_onChange}
                data={searchSaleStepTypeSelect.item}
                value={searchSaleStepTypeSelect.value}
                setSelect={setSearchSaleStepTypeSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="제안결과구분" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              <PbSelect
                onChange={handleSearchProposalResultTypeSelect_onChange}
                data={searchProposalResultTypeSelect.item}
                value={searchProposalResultTypeSelect.value}
                setSelect={setSearchProposalResultTypeSelect}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="확도" isHidden={hideDetailSearch}>
              {/* 셀렉트 */}
              {/*<Select*/}
              {/*  onChange={handleSearchProbabilitySelect_onChange}*/}
              {/*  data={searchProbabilitySelect.item}*/}
              {/*  value={searchProbabilitySelect.value}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
              {/* 슬라이더 */}
              <RangeSlider
                marks={searchProbabilitySlider.item}
                value={searchProbabilitySlider.value}
                onChange={handleSearchProbabilitySlider_onChange}
                step={10}
                minRange={0}
                label={null}
                showLabelOnHover={false}
                className="w-full pb-1"
              />
            </PbFormGridCol>
            <PbFormGridCol
              label=""
              isHidden={hideDetailSearch}
              isBlank={true}
            />
            {/*<PbFormGridCol label="태그" isHidden={hideDetailSearch}>*/}
            {/*  /!* 텍스트 입력 *!/*/}
            {/*  <TextInput*/}
            {/*    placeholder="태그를 입력하세요."*/}
            {/*    onChange={handleSearchTagInput_onChange}*/}
            {/*    onKeyUp={handleSearchTagInput_onKeyUp}*/}
            {/*    value={searchTagInput}*/}
            {/*    className="w-full"*/}
            {/*  />*/}
            {/*</PbFormGridCol>*/}
          </PbFormGrid>

          {/* 테이블 */}
          <div className="space-y-3">
            <div className="flex justify-between items-center space-x-5">
              <div>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchResultTableExportExcelButton_onClick}
                >
                  엑셀 다운로드
                </Button>
              </div>

              <div className="flex justify-center items-center space-x-5">
                <div className="flex justify-center items-center">
                  <span className="text-base text-gray-600 font-bold">
                    총 {searchResultTablePaging.totalRow}건
                  </span>
                </div>

                {/* 셀렉트 */}
                {/*<div>*/}
                {/*  /!* 셀렉트 *!/*/}
                {/*  <PbSelect*/}
                {/*    onChange={handleSearchResultTableSortSelect_onChange}*/}
                {/*    data={searchResultTableSortSelect.item}*/}
                {/*    value={searchResultTableSortSelect.value}*/}
                {/*    setSelect={setSearchResultTableSortSelect}*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-172">
              <PbAgGridReact
                columnDefs={searchResultTable.column}
                rowData={searchResultTable.data}
                loading={searchResultTableLoading}
                setLoading={setSearchResultTableLoading}
                onRowDoubleClicked={handleSearchResultTable_onRowDoubleClicked}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={false}
                visiblePaging={true}
                paging={{
                  totalPage: searchResultTablePaging.totalPage,
                  currentPage: searchResultTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  searchResultTablePagingCurrentPageRef.current = event;
                  setSearchResultTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: searchResultTablePagingCurrentPageRef.current,
                  }));

                  // 검색 결과 테이블 데이터를 불러옴
                  getSearchResultTableData();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220203Page;
