import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbMonthPicker } from '../PbMonthPicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  IComponentModalPageScrollTab,
  IComponentModalPageScrollTabStep,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PbDatePicker } from '../PbDatePicker';
import * as slsApi from '../../apis/sls.api';
import * as cmnApi from '../../apis/cmn.api';
import _ from 'lodash';
import { PbTable } from '../PbTable';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { MN2302220202_Manager } from '../MN2302220202_Manager';
import MN2302220204 from '../MN2302220204/MN2302220204.component';
import { PbInputNumber } from '../PbInputNumber';
import { addRefreshListStore } from '../../stores/refreshList.store';
import {
  Button,
  FileButton,
  NumberInput,
  Popover,
  ScrollArea,
  Select,
  Stepper,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { AgGridReact } from 'ag-grid-react';
import { number } from 'prop-types';
import { notifications } from '@mantine/notifications';
import PbSection from '../PbSection/PbSection.component';
import prettyBytes from 'pretty-bytes';
import { getSlsNqryTrgt } from '../../apis/sls.api';
import { decode } from 'html-entities';
import { PbAgGridReact } from '../PbAgGridReact';
import PbSelect from '../PbSelect/PbSelect.component';
import { phoneNumberformatter } from '../../utils/app.util';
import * as appUtil from '../../utils/app.util';
import { FooterModalArea } from '../FooterModalArea';
import { MN2302220202_ManagerClient } from '../MN2302220202_ManagerClient';
import { doc } from 'prettier';
import ReactDOM, { createRoot } from 'react-dom/client';
import { getNtnCrnc } from '../../apis/cmn.api';

interface IMN2302220202Props {
  id?: string;
  slsPrtnId?: string;
  useModifyMode?: boolean;
}

/**
 * 영업 관리 > 영업 기회 조회 > 등록, 조회, 수정
 * @param id 컴포넌트 모달 아이디
 * @param slsPrtnId 영업 기회 아이디
 * @param useModifyMode 수정 모드 사용 여부
 * @constructor
 */

// 고객사 정보 폼 그리드 > Industry > Industry 1 셀렉트를 정의함
let valClientIndustry1Select: string = '';

// 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트를 정의함
let valClientIndustry2Select: string = '';

// 영업 정보 폼 그리드 > Solution > Solution 1 셀렉트를 정의함
let valSalesSolution1Select: string = '';

// 영업 정보 폼 그리드 > Solution > Solution 2 셀렉트를 정의함
let valSalesSolution2Select: string = '';

const MN2302220202 = ({
  id = '',
  slsPrtnId = '',
  useModifyMode = false,
}: PropsWithChildren<IMN2302220202Props>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 국가, 통화를 정의함
  const [currencyData, setCurrencyData] = useState<any[]>([]);

  // 고객사 정보 폼 그리드 > Industry > Industry 1 셀렉트를 정의함
  // const [clientIndustry1Select, setClientIndustry1Select] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [clientIndustry1Select, setClientIndustry1Select] =
    useState<string>('');
  const [clientIndustry1SelectItem, setClientIndustry1SelectItem] = useState<
    IOptionItem[]
  >([]);
  const clientIndustry1SelectRef = useRef<HTMLInputElement>(null);

  // 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트를 정의함
  // const [clientIndustry2Select, setClientIndustry2Select] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [clientIndustry2Select, setClientIndustry2Select] =
    useState<string>('');
  const [clientIndustry2SelectItem, setClientIndustry2SelectItem] = useState<
    IOptionItem[]
  >([]);
  const clientIndustry2SelectRef = useRef<HTMLInputElement>(null);

  // 고객사 정보 폼 그리드 > Account > 셀렉트를 정의함
  // const [clientAccountSelect, setClientAccountSelect] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [clientAccountSelect, setClientAccountSelect] = useState<string>('');
  const [clientAccountSelectItem, setClientAccountSelectItem] = useState<
    IOptionItem[]
  >([]);
  const clientAccountSelectRef = useRef<HTMLInputElement>(null);

  // 고객사 정보 폼 그리드 > 고객사명 > 셀렉트를 정의함
  // const [clientNameSelect, setClientNameSelect] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [clientNameSelect, setClientNameSelect] = useState<string>('');
  const [clientNameSelectItem, setClientNameSelectItem] = useState<
    IOptionItem[]
  >([]);
  const clientNameSelectRef = useRef<HTMLInputElement>(null);

  // // 고객사 정보 폼 그리드 > 고객사명 > 입력을 정의함
  // const [clientNameInput, setClientNameInput] = useState<string>('');

  // 고객사 정보 폼 그리드 > 고객사코드 > 입력을 정의함
  const [clientCodeInput, setClientCodeInput] = useState<string>('');

  // 고객사 정보 폼 그리드 > 주소 > 입력을 정의함
  const [clientAddressInput, setClientAddressInput] = useState<string>('');

  // 고객사 정보 폼 그리드 > 사업자등록번호 > 입력을 정의함
  const [clientRegNoInput, setClientRegNoInput] = useState<string>('');

  // 고객사 정보 폼 그리드 > 대표자명 > 입력을 정의함
  const [clientCeoInput, setClientCeoInput] = useState<string>('');

  // 고객사 정보 폼 그리드 > 대표번호 > 입력을 정의함
  const [clientTelInput, setClientTelInput] = useState<string>('');

  // 고객사 정보 폼 그리드 > Client Name > 입력을 정의함
  const [clientNameInput, setClientNameInput] = useState<string>('');
  const clientNameInputRef = useRef<HTMLInputElement>(null);

  // 고객사 정보 폼 그리드 > Sold To Name > 입력을 정의함
  const [soldToNameInput, setSoldToNameInput] = useState<string>('');

  // 고객사 정보 폼 그리드 > Ship To Name > 입력을 정의함
  const [shipToNameInput, setShipToNameInput] = useState<string>('');

  // 고객사 정보 폼 그리드 > Bill To Name > 입력을 정의함
  const [billToNameInput, setBillToNameInput] = useState<string>('');

  // // 고객사 정보 폼 그리드 > Market Type > 입력을 정의함
  // const [marketTypeInput, setMarketTypeInput] = useState<string>('');

  // 고객사 정보 폼 그리드 > Market Type > 셀렉트를 정의함
  const [marketTypeSelect, setMarketTypeSelect] = useState<string>('');
  const [marketTypeSelectItem, setMarketTypeSelectItem] = useState<
    IOptionItem[]
  >([]);
  const marketTypeSelectRef = useRef<HTMLInputElement>(null);

  // 고객사 정보 폼 그리드 > Region > 셀렉트를 정의함
  // const [regionSelect, setRegionSelect] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [regionSelect, setRegionSelect] = useState<string>('KR');
  const [regionSelectItem, setRegionSelectItem] = useState<IOptionItem[]>([]);
  const regionSelectRef = useRef<HTMLInputElement>(null);

  // 고객사 정보 폼 그리드 > 매출처 > 입력을 정의함
  const [clientSellingPartnerInput, setClientSellingPartnerInput] =
    useState<string>('');

  // 태그 정보 폼 그리드 > 태그 > 입력을 정의함
  const [clientTagInput, setClientTagInput] = useState<string>('');

  // 영업 정보 폼 그리드 > 영업기회명 > 입력을 정의함
  const [salesOpportunityInput, setSalesOpportunityInput] =
    useState<string>('');
  const salesOpportunityInputRef = useRef<HTMLInputElement>(null);

  // 영업 정보 폼 그리드 > Solution > Solution 1 셀렉트를 정의함
  // const [salesSolution1Select, setSalesSolution1Select] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [salesSolution1Select, setSalesSolution1Select] = useState<string>('');
  const [salesSolution1SelectItem, setSalesSolution1SelectItem] = useState<
    IOptionItem[]
  >([]);
  const salesSolution1SelectRef = useRef<HTMLInputElement>(null);

  // 영업 정보 폼 그리드 > Solution > Solution 2 셀렉트를 정의함
  // const [salesSolution2Select, setSalesSolution2Select] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [salesSolution2Select, setSalesSolution2Select] = useState<string>('');
  const [salesSolution2SelectItem, setSalesSolution2SelectItem] = useState<
    IOptionItem[]
  >([]);
  const salesSolution2SelectRef = useRef<HTMLInputElement>(null);

  // 영업 정보 폼 그리드 > 딜종류 > 셀렉트를 정의함
  // const [salesDealTypeSelect, setSalesDealTypeSelect] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [salesDealTypeSelect, setSalesDealTypeSelect] = useState<string>('');
  const [salesDealTypeSelectItem, setSalesDealTypeSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 영업 정보 폼 그리드 > 납품구분 > 셀렉트를 정의함
  // const [salesDeliveryTypeSelect, setSalesDeliveryTypeSelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });
  const [salesDeliveryTypeSelect, setSalesDeliveryTypeSelect] =
    useState<string>('');
  const [salesDeliveryTypeSelectItem, setSalesDeliveryTypeSelectItem] =
    useState<IOptionItem[]>([]);
  const salesDeliveryTypeSelectRef = useRef<HTMLInputElement>(null);

  // 영업 정보 폼 그리드 > 경쟁사 > 입력을 정의함
  const [salesCompetitorInput, setSalesCompetitorInput] = useState<string>('');
  const salesCompetitorInputRef = useRef<HTMLInputElement>(null);

  // 영업 정보 폼 그리드 > 협력사 > 입력을 정의함
  const [salesPartnerInput, setSalesPartnerInput] = useState<string>('');

  // 영업 정보 폼 그리드 > Amount > 입력을 정의함
  const [salesEstimatedAmountInput, setSalesEstimatedAmountInput] =
    useState<string>('');
  const salesEstimatedAmountInputRef = useRef<HTMLInputElement>(null);

  // 영업 정보 폼 그리드 > Amount Currency > 셀렉트를 정의함
  // const [
  //   salesEstimatedAmountCurrencySelect,
  //   setSalesEstimatedAmountCurrencySelect,
  // ] = useState<ISelect>({
  //   value: '',
  //   item: [],
  // });
  const [
    salesEstimatedAmountCurrencySelect,
    setSalesEstimatedAmountCurrencySelect,
  ] = useState<string>('KRW');
  const [
    salesEstimatedAmountCurrencySelectItem,
    setSalesEstimatedAmountCurrencySelectItem,
  ] = useState<IOptionItem[]>([]);
  const salesEstimatedAmountCurrencySelectRef = useRef<HTMLInputElement>(null);

  // 영업 정보 폼 그리드 > 필요/지원사항 > 입력을 정의함
  const [salesNecessitySupportInput, setSalesNecessitySupportInput] =
    useState<string>('');

  // 프로젝트 정보 폼 그리드 > Proposal Submission Date > 날짜 피커를 정의함
  const [
    planProposalSubmissionDatePicker,
    setPlanProposalSubmissionDatePicker,
  ] = useState<Date | null>(null);

  // 프로젝트 정보 폼 그리드 > 수주일 > 날짜 피커를 정의함
  const [
    planEstimatedConfirmationDatePicker,
    setPlanEstimatedConfirmationDatePicker,
  ] = useState<Date | null>(null);

  // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 날짜 피커를 정의함
  const [planEstimatedDatePicker, setPlanEstimatedDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  // 프로젝트 정보 폼 그리드 > 기간(월) > 입력을 정의함
  const [planEstimatedMonthInput, setPlanEstimatedMonthInput] =
    useState<string>('');

  // 프로젝트 정보 폼 그리드 > Expected Contract Amount > 입력을 정의함
  const [salesContractSizeInput, setSalesContractSizeInput] =
    useState<string>('');

  // 프로젝트 정보 폼 그리드 > Expected Contract Amount Currency > 셀렉트를 정의함
  // const [salesContractSizeCurrencySelect, setSalesContractSizeCurrencySelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });
  const [salesContractSizeCurrencySelect, setSalesContractSizeCurrencySelect] =
    useState<string>('KRW');
  const [
    salesContractSizeCurrencySelectItem,
    setSalesContractSizeCurrencySelectItem,
  ] = useState<IOptionItem[]>([]);

  // 프로젝트 정보 폼 그리드 > Expected Direct Cost > 입력을 정의함
  const [salesNsrSizeInput, setSalesNsrSizeInput] = useState<string>('');

  // 프로젝트 정보 폼 그리드 > Expected Direct Cost Currency > 셀렉트를 정의함
  // const [salesNsrSizeCurrencySelect, setSalesNsrSizeCurrencySelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });
  const [salesNsrSizeCurrencySelect, setSalesNsrSizeCurrencySelect] =
    useState<string>('KRW');
  const [salesNsrSizeCurrencySelectItem, setSalesNsrSizeCurrencySelectItem] =
    useState<IOptionItem[]>([]);

  // 프로젝트 정보 폼 그리드 > NSR X 확도 > 입력을 정의함
  const [salesNsrProbabilityInput, setSalesNsrProbabilityInput] =
    useState<string>('');

  // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블을 정의함
  const [salesEstimatedAmountTable, setSalesEstimatedAmountTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  const salesEstimatedAmountTableRef = useRef<HTMLElement>(null);

  // 프로젝트 정보 폼 그리드 > Sales VAT > 입력을 정의함
  const [salesVatInput, setSalesVatInput] = useState<{
    id: string;
    name: string;
    nickName?: string;
  }>({
    id: '',
    name: '',
    nickName: '',
  });
  const salesVatInputRef = useRef<HTMLButtonElement>(null);

  // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 검색어 > 셀렉트를 정의함
  // const [salesVatTargetUserSearchSelect, setSalesVatTargetUserSearchSelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });
  const [salesVatTargetUserSearchSelect, setSalesVatTargetUserSearchSelect] =
    useState<string>('');
  const [
    salesVatTargetUserSearchSelectItem,
    setSalesVatTargetUserSearchSelectItem,
  ] = useState<IOptionItem[]>([]);

  // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 검색어 > 입력을 정의함
  const [salesVatTargetUserSearchInput, setSalesVatTargetUserSearchInput] =
    useState<string>('');

  // 프로젝트 정보 폼 그리드 > Sales VAT > 테이블 참조를 정의함
  const salesVatTargetUserTableRef = useRef<any>(null);

  // 프로젝트 정보 폼 그리드 > Sales VAT > 테이블을 정의함
  const [salesVatTargetUserTable, setSalesVatTargetUserTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 프로젝트 정보 폼 그리드 > Sales VAT > 팝업 출력 여부를 정의함
  const [visibleSalesVatTargetUser, setVisibleSalesVatTargetUser] =
    useState<boolean>(false);

  // 영업 활동 정보 폼 그리드 > 영업단계 > 입력을 정의함
  const [salesWorkStepInput, setSalesWorkStepInput] = useState<string>('');

  // 영업 활동 정보 폼 그리드 > 확도 > 입력을 정의함
  const [salesWorkProbabilityInput, setSalesWorkProbabilityInput] =
    useState<string>('');

  // 영업 활동 정보 폼 그리드 > 제안결과 > 입력을 정의함
  const [salesWorkProposalResultInput, setSalesWorkProposalResultInput] =
    useState<string>('');

  // 영업 활동 정보 폼 그리드 > 제안결과내용 > 입력을 정의함
  const [
    salesWorkProposalResultContentInput,
    setSalesWorkProposalResultContentInput,
  ] = useState<string>('');

  // SWOT 분석 폼 그리드 > Strength > 입력을 정의함
  const [swotStrengthInput, setSwotStrengthInput] = useState<string>('');

  // SWOT 분석 폼 그리드 > Weakness > 입력을 정의함
  const [swotWeaknessInput, setSwotWeaknessInput] = useState<string>('');

  // SWOT 분석 폼 그리드 > Opportunities > 입력을 정의함
  const [swotOpportunitiesInput, setSwotOpportunitiesInput] =
    useState<string>('');

  // SWOT 분석 폼 그리드 > Threats > 입력을 정의함
  const [swotThreatsInput, setSwotThreatsInput] = useState<string>('');

  // 담당자(고객사) 폼 그리드 > 담당자 > 이름 > 입력을 정의함
  const [managerClientNameInput, setManagerClientNameInput] =
    useState<string>('');

  // 담당자(고객사) 폼 그리드 > 담당자 > 직급 > 입력을 정의함
  const [managerClientPositionInput, setManagerClientPositionInput] =
    useState<string>('');

  // 담당자(고객사) 폼 그리드 > 담당자 > 부서 > 입력을 정의함
  const [managerClientDepartmentInput, setManagerClientDepartmentInput] =
    useState<string>('');

  // 담당자(고객사) 폼 그리드 > 담당자 > 전화번호 > 입력을 정의함
  const [managerClientPhoneInput, setManagerClientPhoneInput] =
    useState<string>('');

  // 담당자(고객사) 폼 그리드 > 담당자 > 이메일주소 > 입력을 정의함
  const [managerClientEmailInput, setManagerClientEmailInput] =
    useState<string>('');

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블을 정의함
  const [managerClientTable, setManagerClientTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  const managerClientTableRef = useRef<any>(null);

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 > 선택한 행을 정의함
  const [selectedManagerClientTableRow, setSelectedManagerClientTableRow] =
    useState<any>(null);

  // 담당자(내부) 폼 그리드 > 아이디 > 입력을 정의함
  const [managerInsideIdInput, setManagerInsideIdInput] = useState<string>('');

  // 담당자(내부) 폼 그리드 > 이름 > 입력을 정의함
  const [managerInsideNameInput, setManagerInsideNameInput] =
    useState<string>('');

  // 담당자(내부) 폼 그리드 > 본부 > 입력을 정의함
  const [managerInsideHeadquarterInput, setManagerInsideHeadquarterInput] =
    useState<string>('');

  // 담당자(내부) 폼 그리드 > 부서 > 입력을 정의함
  const [managerInsideDepartmentInput, setManagerInsideDepartmentInput] =
    useState<string>('');

  // 담당자(내부) 폼 그리드 > Email > 입력을 정의함
  const [managerInsideEmailInput, setManagerInsideEmailInput] =
    useState<string>('');

  // 담당자(내부) 폼 그리드 > 휴대폰 > 입력을 정의함
  const [managerInsidePhoneInput, setManagerInsidePhoneInput] =
    useState<string>('');

  // 조회 권한 폼 그리드 > 테이블 참조를 정의함
  const readAuthTableRef = useRef<any>(null);

  // 조회 권한 폼 그리드 > 테이블을 정의함
  const [readAuthTable, setReadAuthTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 조회 권한 폼 그리드 > 대상자 > 검색 폼 그리드 > 검색어 > 셀렉트를 정의함
  // const [readAuthTargetUserSearchSelect, setReadAuthTargetUserSearchSelect] =
  //   useState<ISelect>({
  //     value: '',
  //     item: [],
  //   });
  const [readAuthTargetUserSearchSelect, setReadAuthTargetUserSearchSelect] =
    useState<string>('');
  const [
    readAuthTargetUserSearchSelectItem,
    setReadAuthTargetUserSearchSelectItem,
  ] = useState<IOptionItem[]>([]);

  // 조회 권한 폼 그리드 > 대상자 > 검색 폼 그리드 > 검색어 > 입력을 정의함
  const [readAuthTargetUserSearchInput, setReadAuthTargetUserSearchInput] =
    useState<string>('');

  // 조회 권한 폼 그리드 > 대상자 > 테이블 참조를 정의함
  const readAuthTargetUserTableRef = useRef<any>(null);

  // 조회 권한 폼 그리드 > 대상자 > 테이블을 정의함
  const [readAuthTargetUserTable, setReadAuthTargetUserTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 조회 권한 폼 그리드 > 대상자 > 팝업 출력 여부를 정의함
  const [visibleReadAuthTargetUser, setVisibleReadAuthTargetUser] =
    useState<boolean>(false);

  // 영업 기회 상세 데이터를 정의함
  const [slsData, setSlsData] = useState<any>(null);

  // 숫자에 콤마를 추가함
  const getFormatNumber = (number: any) => {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  // 컴포넌트 모달 > 저장 버튼(신규)을 클릭함
  const handleSaveAddButton_onClick = async () => {
    // 삭제할 수 없는 조회 권한 사용자를 불러옴
    let tmpSlsXpsrRequiredList: string[] = await getCannotDeleteReadAuthUser();

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블 데이터를 저장용 데이터 형식으로 변환함
    let tmpSalesEstimatedAmountTableData: any = [];

    salesEstimatedAmountTable.data.map((item: any) => {
      if (item.category === '국내' || item.category === '해외') {
        for (let key in item) {
          if (key !== 'category' && key !== 'total') {
            tmpSalesEstimatedAmountTableData.push({
              slsRvncCode: item.category === '국내' ? 'DMST' : 'VRSS',
              baseDate: moment(key, 'YYYYMM').format('YYYY.MM'),
              mnt: item[key],
            });
          }
        }
      }
    });

    // 고객사 정보 폼 그리드 > Industry > Industry 1 셀렉트를 확인함
    if (!clientIndustry1Select) {
      setModal({
        title: '알림',
        content: 'Industry를 선택해 주세요.',
      });

      clientIndustry1SelectRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트를 확인함
    if (!clientIndustry2Select) {
      setModal({
        title: '알림',
        content: 'Industry Type을 선택해 주세요.',
      });

      clientIndustry2SelectRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Division_SP > 셀렉트를 확인함
    if (!clientAccountSelect) {
      setModal({
        title: '알림',
        content: 'Division_SP를 선택해 주세요.',
      });

      clientAccountSelectRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Client Name > 입력을 확인함
    if (!clientNameInput.trim()) {
      setModal({
        title: '알림',
        content: 'Client Name을 입력해 주세요.',
      });

      clientNameInputRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Channel > 셀렉트를 확인함
    if (!clientNameSelect) {
      setModal({
        title: '알림',
        content: 'Channel을 선택해 주세요.',
      });

      clientNameSelectRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Market Type > 입력을 확인함
    // if (!marketTypeInput.trim()) {
    //   setModal({
    //     title: '알림',
    //     content: 'Market Type을 입력해 주세요.',
    //   });
    //
    //   return;
    // }

    // 고객사 정보 폼 그리드 > Market Type > 셀렉트를 확인함
    if (!marketTypeSelect) {
      setModal({
        title: '알림',
        content: 'Market Type을 선택해 주세요.',
      });

      marketTypeSelectRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Region > 셀렉트를 확인함
    if (!regionSelect) {
      setModal({
        title: '알림',
        content: 'Region을 선택해 주세요.',
      });

      regionSelectRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Project Name > 입력을 확인함
    if (!salesOpportunityInput.trim()) {
      setModal({
        title: '알림',
        content: 'Project Name을 입력해 주세요.',
      });

      salesOpportunityInputRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Product > 셀렉트를 확인함
    if (!salesSolution1Select) {
      setModal({
        title: '알림',
        content: 'Product를 선택해 주세요.',
      });

      salesSolution1SelectRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Product > 셀렉트를 확인함
    if (!salesSolution2Select) {
      setModal({
        title: '알림',
        content: 'Product를 선택해 주세요.',
      });

      salesSolution2SelectRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Service Type > 셀렉트를 확인함
    if (!salesDeliveryTypeSelect) {
      setModal({
        title: '알림',
        content: 'Service Type을 선택해 주세요.',
      });

      salesDeliveryTypeSelectRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > 경쟁사 > 입력을 확인함
    if (_.isEmpty(salesCompetitorInput)) {
      setModal({
        title: '알림',
        content: '경쟁사를 입력해 주세요.',
      });

      salesCompetitorInputRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Amount > 입력을 확인함
    if (!salesEstimatedAmountInput) {
      setModal({
        title: '알림',
        content: 'Amount를 입력해 주세요.',
      });

      salesEstimatedAmountInputRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Amount Currency > 셀렉트를 확인함
    if (!salesEstimatedAmountCurrencySelect) {
      setModal({
        title: '알림',
        content: 'Amount Currency를 선택해 주세요.',
      });

      salesEstimatedAmountCurrencySelectRef.current?.focus();
      return;
    }

    // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 입력을 확인함
    if (planEstimatedDatePicker[0] === null) {
      setModal({
        title: '알림',
        content: '프로젝트기간을 입력해 주세요.',
      });

      let obj = document.querySelector(
        '.plan-estimated-date-picker button',
      ) as HTMLButtonElement;
      obj.focus();

      return;
    }

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블을 확인함
    if (tmpSalesEstimatedAmountTableData.length === 0) {
      setModal({
        title: '알림',
        content: '기간별예상매출금액(원)을 입력해 주세요.',
      });

      salesEstimatedAmountTableRef.current?.focus();
      return;
    }

    // 프로젝트 정보 폼 그리드 > Sales VAT > 입력을 확인함
    if (_.isEmpty(salesVatInput.name)) {
      setModal({
        title: '알림',
        content: 'Sales VAT를 입력해 주세요.',
      });

      salesVatInputRef.current?.focus();
      return;
    }

    // 조회 권한 대상자의 아이디를 정의함
    let tmpSlsXpsrTrgtList: string[] = [];

    // 조회 권한 대상자의 아이디를 불러옴
    readAuthTable.data.map((item: any) => {
      tmpSlsXpsrTrgtList.push(item.userId);
    });

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 영업 기회 조회 등록을 처리함
            slsApi
              .postSlsPrtn({
                cmptName: salesCompetitorInput.trim(),
                // cntGrpName: clientAccountNameInput,
                cntGrpCode: clientAccountSelect,
                bpName: '',
                bpCode: '',
                mrktTypeCode: marketTypeSelect,
                rgn: regionSelect,
                slsChnlCode: clientNameSelect,
                soldToName: soldToNameInput.trim(),
                shipToName: shipToNameInput.trim(),
                billToName: billToNameInput.trim(),
                dtldDrs: clientAddressInput.trim(),
                cmpnRgstNmbr: clientRegNoInput.trim(),
                rprsName: decode(clientCeoInput.trim()),
                phnNmbr: clientTelInput.trim(),
                slsPlcName: clientNameInput.trim(),
                cntrSizeMnt: salesContractSizeInput,
                cntrSizeCrnc: salesContractSizeCurrencySelect,
                list: tmpSalesEstimatedAmountTableData,
                ncsrSprt: salesNecessitySupportInput.trim(),
                ndstDntfCode: clientIndustry1Select,
                ndstSubCode: clientIndustry2Select,
                nsrSclMnt: salesNsrSizeInput,
                nsrSclCrnc: salesNsrSizeCurrencySelect,
                ntrnCntcId: managerInsideIdInput.trim(),
                prpsDate: planProposalSubmissionDatePicker
                  ? moment(planProposalSubmissionDatePicker).format('YYYYMMDD')
                  : '',
                prtnName: salesPartnerInput.trim(),
                slsDealCode: salesDealTypeSelect,
                slsDlvrCode: salesDeliveryTypeSelect,
                slsPrtnName: salesOpportunityInput.trim(),
                slsSltnCode: salesSolution1Select,
                slsSubCode: salesSolution2Select,
                stmtStrtDate: planEstimatedDatePicker[0]
                  ? moment(planEstimatedDatePicker[0]).format('YYYYMMDD')
                  : '',
                stmtCmplDate: planEstimatedDatePicker[1]
                  ? moment(planEstimatedDatePicker[1]).format('YYYYMMDD')
                  : '',
                xpctFxdDate: planEstimatedConfirmationDatePicker
                  ? moment(planEstimatedConfirmationDatePicker).format(
                      'YYYYMMDD',
                    )
                  : '',
                xpctQnttDtls: salesEstimatedAmountInput,
                xpctQnttMnt: salesEstimatedAmountInput,
                xpctQnttCrnc: salesEstimatedAmountCurrencySelect,
                slsVat: salesVatInput.id,
                cstmMngrList: managerClientTable.data,
                tag: clientTagInput.trim(),
                slsXpsrTrgtList: tmpSlsXpsrTrgtList,
                slsXpsrRequiredList: tmpSlsXpsrRequiredList,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  setModal({
                    title: '알림',
                    content: '저장하였습니다.',
                    callback: () => {
                      // 목록 새로고침을 추가함
                      setAddRefreshList('MN2302220203Table');

                      // 컴포넌트 모달을 닫음
                      setRemoveComponentModal(id);
                    },
                  });
                } else {
                  console.log('> 영업 기회 조회 등록 Error:', data);

                  setModal({
                    title: '오류',
                    content: (
                      <>
                        <div>저장에 실패하였습니다.</div>
                        <div>({data.message})</div>
                      </>
                    ),
                  });
                }
              })
              .catch((error: any) => {
                console.log('> 영업 기회 조회 등록 Error:', error);

                setModal({
                  title: '오류',
                  content: `저장에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 삭제할 수 없는 조회 권한 사용자를 불러옴
  const getCannotDeleteReadAuthUser = async (): Promise<string[]> => {
    // 삭제할 조회 권한 아이디 목록을 정의함
    let userId: string[] = [];

    await slsApi
      .getSlsNqryTrgt({})
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          data.data.list.map((item: any) => {
            userId.push(item.userId);
          });
        }
      })
      .catch((error: any) => {});

    return userId;
  };

  // 컴포넌트 모달 > 저장 버튼(수정)을 클릭함
  const handleSaveUpdateButton_onClick = async () => {
    // 삭제할 수 없는 조회 권한 사용자를 불러옴
    let tmpSlsXpsrRequiredList: string[] = await getCannotDeleteReadAuthUser();

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블 데이터를 저장용 데이터 형식으로 변환함
    let tmpSalesEstimatedAmountTableData: any = [];

    salesEstimatedAmountTable.data.map((item: any) => {
      if (item.category === '국내' || item.category === '해외') {
        for (let key in item) {
          if (key !== 'category' && key !== 'total') {
            tmpSalesEstimatedAmountTableData.push({
              slsRvncCode: item.category === '국내' ? 'DMST' : 'VRSS',
              baseDate: moment(key, 'YYYYMM').format('YYYY.MM'),
              mnt: item[key],
            });
          }
        }
      }
    });

    let tmpSalesEstimatedAmountTableDataSum: number =
      _.sumBy(tmpSalesEstimatedAmountTableData, 'mnt') || 0;

    console.log(
      '> tmpSalesEstimatedAmountTableData:',
      tmpSalesEstimatedAmountTableDataSum,
    );

    // 고객사 정보 폼 그리드 > Industry > Industry 1 셀렉트를 확인함
    if (!clientIndustry1Select) {
      setModal({
        title: '알림',
        content: 'Industry를 선택해 주세요.',
      });

      clientIndustry1SelectRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트를 확인함
    if (!clientIndustry2Select) {
      setModal({
        title: '알림',
        content: 'Industry Type을 선택해 주세요.',
      });

      clientIndustry2SelectRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Division_SP > 셀렉트를 확인함
    if (!clientAccountSelect) {
      setModal({
        title: '알림',
        content: 'Division_SP를 선택해 주세요.',
      });

      clientAccountSelectRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Client Name > 입력을 확인함
    if (!clientNameInput.trim()) {
      setModal({
        title: '알림',
        content: 'Client Name을 입력해 주세요.',
      });

      clientNameInputRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Channel > 셀렉트를 확인함
    if (!clientNameSelect) {
      setModal({
        title: '알림',
        content: 'Channel을 선택해 주세요.',
      });

      clientNameSelectRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Market Type > 입력을 확인함
    // if (!marketTypeInput.trim()) {
    //   setModal({
    //     title: '알림',
    //     content: 'Market Type을 입력해 주세요.',
    //   });
    //
    //   return;
    // }

    // 고객사 정보 폼 그리드 > Market Type > 셀렉트를 확인함
    if (!marketTypeSelect) {
      setModal({
        title: '알림',
        content: 'Market Type을 선택해 주세요.',
      });

      marketTypeSelectRef.current?.focus();
      return;
    }

    // 고객사 정보 폼 그리드 > Region > 셀렉트를 확인함
    if (!regionSelect) {
      setModal({
        title: '알림',
        content: 'Region을 선택해 주세요.',
      });

      regionSelectRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Project Name > 입력을 확인함
    if (!salesOpportunityInput.trim()) {
      setModal({
        title: '알림',
        content: 'Project Name을 입력해 주세요.',
      });

      salesOpportunityInputRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Product > 셀렉트를 확인함
    if (!salesSolution1Select) {
      setModal({
        title: '알림',
        content: 'Product를 선택해 주세요.',
      });

      salesSolution1SelectRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Product > 셀렉트를 확인함
    if (!salesSolution2Select) {
      setModal({
        title: '알림',
        content: 'Product를 선택해 주세요.',
      });

      salesSolution2SelectRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Service Type > 셀렉트를 확인함
    if (!salesDeliveryTypeSelect) {
      setModal({
        title: '알림',
        content: 'Service Type을 선택해 주세요.',
      });

      salesDeliveryTypeSelectRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > 경쟁사 > 입력을 확인함
    if (_.isEmpty(salesCompetitorInput)) {
      setModal({
        title: '알림',
        content: '경쟁사를 입력해 주세요.',
      });

      salesCompetitorInputRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Amount > 입력을 확인함
    if (!salesEstimatedAmountInput) {
      setModal({
        title: '알림',
        content: 'Amount를 입력해 주세요.',
      });

      salesEstimatedAmountInputRef.current?.focus();
      return;
    }

    // 영업 정보 폼 그리드 > Amount Currency > 셀렉트를 확인함
    if (!salesEstimatedAmountCurrencySelect) {
      setModal({
        title: '알림',
        content: 'Amount Currency를 선택해 주세요.',
      });

      salesEstimatedAmountCurrencySelectRef.current?.focus();
      return;
    }

    // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 입력을 확인함
    if (planEstimatedDatePicker[0] === null) {
      setModal({
        title: '알림',
        content: '프로젝트기간을 입력해 주세요.',
      });

      let obj = document.querySelector(
        '.plan-estimated-date-picker button',
      ) as HTMLButtonElement;
      obj.focus();

      return;
    }

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블을 확인함
    if (tmpSalesEstimatedAmountTableData.length === 0) {
      setModal({
        title: '알림',
        content: '기간별예상매출금액(원)을 입력해 주세요.',
      });

      salesEstimatedAmountTableRef.current?.focus();
      return;
    }

    // 프로젝트 정보 폼 그리드 > Sales VAT > 입력을 확인함
    if (_.isEmpty(salesVatInput.name)) {
      setModal({
        title: '알림',
        content: 'Sales VAT를 입력해 주세요.',
      });

      salesVatInputRef.current?.focus();
      return;
    }

    // 조회 권한 대상자의 아이디를 정의함
    let tmpSlsXpsrTrgtList: string[] = [];

    // 조회 권한 대상자의 아이디를 불러옴
    readAuthTable.data.map((item: any) => {
      tmpSlsXpsrTrgtList.push(item.userId);
    });

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            notifications.show({
              title: '알림',
              message: '저장 중입니다.',
            });

            // 영업 기회 수정을 처리함
            slsApi
              .putSlsPrtn({
                slsPrtnId: slsPrtnId,
                cmptName: salesCompetitorInput.trim(),
                cntGrpCode: clientAccountSelect,
                bpName: '',
                bpCode: '',
                mrktTypeCode: marketTypeSelect,
                rgn: regionSelect,
                slsChnlCode: clientNameSelect,
                soldToName: soldToNameInput.trim(),
                shipToName: shipToNameInput.trim(),
                billToName: billToNameInput.trim(),
                dtldDrs: clientAddressInput.trim(),
                cmpnRgstNmbr: clientRegNoInput.trim(),
                rprsName: decode(clientCeoInput.trim()),
                phnNmbr: clientTelInput.trim(),
                slsPlcName: clientNameInput.trim(),
                cntrSizeMnt: salesContractSizeInput,
                cntrSizeCrnc: salesContractSizeCurrencySelect,
                list: tmpSalesEstimatedAmountTableData,
                ncsrSprt: salesNecessitySupportInput.trim(),
                ndstDntfCode: clientIndustry1Select,
                ndstSubCode: clientIndustry2Select,
                nsrSclMnt: salesNsrSizeInput,
                nsrSclCrnc: salesNsrSizeCurrencySelect,
                ntrnCntcId: managerInsideIdInput.trim(),
                prpsDate: planProposalSubmissionDatePicker
                  ? moment(planProposalSubmissionDatePicker).format('YYYYMMDD')
                  : '',
                prtnName: salesPartnerInput.trim(),
                slsDealCode: salesDealTypeSelect,
                slsDlvrCode: salesDeliveryTypeSelect,
                slsPrtnName: salesOpportunityInput.trim(),
                slsSltnCode: salesSolution1Select,
                slsSubCode: salesSolution2Select,
                stmtStrtDate: planEstimatedDatePicker[0]
                  ? moment(planEstimatedDatePicker[0]).format('YYYYMMDD')
                  : '',
                stmtCmplDate: planEstimatedDatePicker[1]
                  ? moment(planEstimatedDatePicker[1]).format('YYYYMMDD')
                  : '',
                xpctFxdDate: planEstimatedConfirmationDatePicker
                  ? moment(planEstimatedConfirmationDatePicker).format(
                      'YYYYMMDD',
                    )
                  : '',
                xpctQnttDtls: salesEstimatedAmountInput,
                xpctQnttMnt: salesEstimatedAmountInput,
                xpctQnttCrnc: salesEstimatedAmountCurrencySelect,
                slsVat: salesVatInput.id,
                cstmMngrList: managerClientTable.data,
                tag: clientTagInput.trim(),
                // slsXpsrTrgtList: readAuthTable.data,
                slsXpsrTrgtList: tmpSlsXpsrTrgtList,
                slsXpsrRequiredList: tmpSlsXpsrRequiredList,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  notifications.show({
                    title: '알림',
                    message: '저장하였습니다.',
                  });

                  // 목록 새로고침을 추가함
                  setAddRefreshList('MN2302220203Table');

                  // 컴포넌트 모달을 닫음
                  setRemoveComponentModal(id);

                  setTimeout(() => {
                    // 조회 컴포넌트 모달을 추가함
                    let tmpId: string = nanoid();

                    // 컴포넌트 모달을 추가함
                    setAddComponentModal({
                      id: tmpId,
                      title: '영업 기회 상세',
                      content: (
                        <MN2302220202 id={tmpId} slsPrtnId={slsPrtnId} />
                      ),
                      size: 1500,
                    });
                  }, 100);

                  // setModal({
                  //   title: '알림',
                  //   content: '저장하였습니다.',
                  //   callback: () => {
                  //     // 목록 새로고침을 추가함
                  //     setAddRefreshList('MN2302220203Table');
                  //
                  //     // 컴포넌트 모달을 닫음
                  //     setRemoveComponentModal(id);
                  //
                  //     setTimeout(() => {
                  //       // 조회 컴포넌트 모달을 추가함
                  //       let tmpId: string = nanoid();
                  //
                  //       // 컴포넌트 모달을 추가함
                  //       setAddComponentModal({
                  //         id: tmpId,
                  //         title: '영업 기회 상세',
                  //         content: (
                  //           <MN2302220202 id={tmpId} slsPrtnId={slsPrtnId} />
                  //         ),
                  //         size: 1500,
                  //       });
                  //     }, 100);
                  //   },
                  // });
                } else {
                  console.log('> 영업 기회 수정 Error:', data);

                  setModal({
                    title: '오류',
                    content: (
                      <>
                        <div>저장에 실패하였습니다.</div>
                        <div>({data.message})</div>
                      </>
                    ),
                  });
                }
              })
              .catch((error: any) => {
                console.log('> 영업 기회 수정 Error:', error);

                setModal({
                  title: '오류',
                  content: `저장에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 컴포넌트 모달 > 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    setModal({
      title: '확인',
      content: '이 영업 기회를 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 영업 기회를 삭제함
            slsApi
              .deleteSlsPrtn({
                slsPrtnId: slsPrtnId,
              })
              .then((data: IApiResult) => {
                switch (data.code) {
                  case '200':
                    // 모달을 출력함
                    setModal({
                      title: '알림',
                      content: '삭제하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220203Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);
                      },
                    });
                    break;

                  case '':
                    // 모달을 출력함
                    setModal({
                      title: t('common.error'),
                      content: '삭제에 실패하였습니다.',
                    });
                    break;

                  default:
                    break;
                }
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 컴포넌트 모달 > 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '영업 기회 수정',
        content: (
          <MN2302220202 id={tmpId} slsPrtnId={slsPrtnId} useModifyMode={true} />
        ),
        size: 1500,
      });
    }, 100);
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 담당자 추가 버튼을 클릭함
  const handleAddManagerClientButton_onClick = () => {
    // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 > 선택한 행을 초기화함
    setSelectedManagerClientTableRow(null);
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 저장 버튼을 클릭함
  const handleSaveManagerClientButton_onClick = () => {
    // 담당자(고객사) 폼 그리드 > 담당자 > 이름 > 입력을 확인함
    if (!managerClientNameInput.trim()) {
      setModal({
        title: '알림',
        content: '담당자(고객사) 이름을 입력해 주세요.',
      });

      return;
    }

    // 담당자(고객사) 폼 그리드 > 담당자 > 전화번호 > 입력을 확인함
    // if (!managerClientPhoneInput.trim()) {
    //   setModal({
    //     title: '알림',
    //     content: '담당자(고객사) 전화번호를 입력해 주세요.',
    //   });
    //   return;
    // }

    // 담당자(고객사) 폼 그리드 > 담당자 > 이메일주소 > 입력을 확인함
    // if (!managerClientEmailInput.trim()) {
    //   setModal({
    //     title: '알림',
    //     content: '담당자(고객사) 이메일주소를 입력해 주세요.',
    //   });
    //   return;
    // }

    // 담당자(고객사) 테이블에 추가할 데이터를 생성함
    // let tmpData: any = _.cloneDeep(managerClientTable.data);
    let tmpData: any = {
      cstmMngrName: managerClientNameInput.trim(),
      cstmMngrPstn: managerClientPositionInput.trim(),
      cstmMngrDprt: managerClientDepartmentInput.trim(),
      cstmMngrClph: managerClientPhoneInput.trim(),
      cstmMngrMl: managerClientEmailInput.trim(),
    };

    if (selectedManagerClientTableRow === null) {
      /* 추가 */

      // 담당자(고객사) 폼 그리드 > 담당자 > 테이블에 적용함
      setManagerClientTable((pre: { column: any; data: any }) => ({
        ...pre,
        data: [...managerClientTable.data, tmpData],
      }));

      // 담당자(내부) 폼 그리드 > 모든 입력을 초기화함
      initManagerClientAllForm();
    } else {
      /* 수정 */

      // 목록에서 수정할 행의 순서를 불러옴
      let tmpIndex: number = _.indexOf(
        managerClientTable.data,
        selectedManagerClientTableRow,
      );

      // 담당자(고객사) 폼 그리드 > 담당자 > 테이블의 데이터에 적용함
      setManagerClientTable((pre: { column: any; data: any }) => ({
        ...pre,
        data: [
          ...pre.data.slice(0, tmpIndex),
          tmpData,
          ...pre.data.slice(tmpIndex + 1),
        ],
      }));
    }

    // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 > 선택한 행을 초기화함
    setSelectedManagerClientTableRow(null);
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 삭제 버튼을 클릭함
  const handleRemoveManagerClientButton_onClick = () => {
    setModal({
      title: '확인',
      content: `${selectedManagerClientTableRow.cstmMngrName}님을 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="md"
            onClick={() => {
              setRemoveModal(true);

              // 목록에서 삭제할 행의 순서를 불러옴
              let tmpIndex: number = _.indexOf(
                managerClientTable.data,
                selectedManagerClientTableRow,
              );

              // 담당자(고객사) 폼 그리드 > 담당자 > 테이블의 데이터에서 해당 행을 삭제함
              setManagerClientTable((pre: { column: any; data: any }) => ({
                ...pre,
                data: [
                  ...pre.data.slice(0, tmpIndex),
                  ...pre.data.slice(tmpIndex + 1),
                ],
              }));

              // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 > 선택한 행을 초기화함
              setSelectedManagerClientTableRow(null);
            }}
          />
        </div>
      ),
    });
  };

  // 담당자(내부) 폼 그리드 > 담당자 변경 버튼을 클릭함
  const handleManagerButton_onClick = () => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '담당자 변경',
      content: (
        <MN2302220202_Manager
          id={tmpId}
          callback={(data: any) => {
            // 담당자(내부) 폼 그리드 > 아이디 > 입력에 적용함
            setManagerInsideIdInput(data.userId || '');

            // 담당자(내부) 폼 그리드 > 이름 > 입력에 적용함
            setManagerInsideNameInput(data.name || '');

            // 담당자(내부) 폼 그리드 > 본부 > 입력에 적용함
            setManagerInsideHeadquarterInput(data.hdqrName || '');

            // 담당자(내부) 폼 그리드 > 부서 > 입력에 적용함
            setManagerInsideDepartmentInput(data.dprtName || '');

            // 담당자(내부) 폼 그리드 > Email > 입력에 적용함
            setManagerInsideEmailInput(data.ml || '');

            // 담당자(내부) 폼 그리드 > 휴대폰 > 입력에 적용함
            setManagerInsidePhoneInput(data.celPhn || '');
          }}
        />
      ),
      size: 1300,
    });
  };

  // 고객사 정보 폼 그리드 > Industry > Industry 1 셀렉트에서 선택한 아이템을 변경함
  const handleClientIndustry1Select_onChange = (event: any) => {
    valClientIndustry1Select = event;
    valClientIndustry2Select = '';
    // setClientIndustry1Select((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
    setClientIndustry1Select(event);
  };

  // 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트에서 선택한 아이템을 변경함
  const handleClientIndustry2Select_onChange = (event: any) => {
    valClientIndustry2Select = event;
    // setClientIndustry2Select((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
    setClientIndustry2Select(event);
  };

  // 고객사 정보 폼 그리드 > Account > 셀렉트에서 선택한 아이템을 변경함
  const handleClientAccountSelect_onChange = (event: any) => {
    // setClientAccountSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
    setClientAccountSelect(event);
  };

  // 고객사 정보 폼 그리드 > 고객사명 > 셀렉트에서 선택한 아이템을 변경함
  const handleClientNameSelect_onChange = (event: any) => {
    // setClientNameSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
    setClientNameSelect(event);
  };
  const handleClientNameSelect_onChange2 = (event: any) => {
    // setClientNameSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
    setClientNameSelect(event);

    // 고객사 목록을 불러옴
    slsApi
      .getSlsPrtnSearchBp({
        searchType: 'bp_code',
        searchKeyword: event,
      })
      .then((data: IApiResult) => {
        if (data.data.list.length > 0) {
          // // 고객사 정보 폼 그리드 > 고객사선택 > 셀렉트에 적용함
          // setClientNameSelect((pre: ISelect) => ({
          //   ...pre,
          //   value: data.data.list[0].bpName || '',
          // }));
          // 고객사 정보 폼 그리드 > 고객사선택 > 셀렉트에 적용함
          // setClientNameSelect((pre: ISelect) => ({
          //   ...pre,
          //   value: '',
          // }));
          setClientNameSelect('');

          // 고객사 정보 폼 그리드 > 고객사명 > 입력에 적용함
          setClientNameInput(data.data.list[0].bpName || '');

          // 고객사 정보 폼 그리드 > 고객사코드 > 입력에 적용함
          setClientCodeInput(data.data.list[0].bpCode || '');

          // 고객사 정보 폼 그리드 > 주소 > 입력에 적용함
          setClientAddressInput(data.data.list[0].dtldDrs || '');

          // 고객사 정보 폼 그리드 > 사업자등록번호 > 입력에 적용함
          setClientRegNoInput(data.data.list[0].cmpnRgstNmbr || '');

          // 고객사 정보 폼 그리드 > 대표자명 > 입력에 적용함
          setClientCeoInput(data.data.list[0].rprsName || '');

          // 고객사 정보 폼 그리드 > 대표번호 > 입력에 적용함
          setClientTelInput(data.data.list[0].phnNmbr || '');

          // 고객사 정보 폼 그리드 > 매출처 > 입력에 적용함
          // setClientSellingPartnerInput(data.data.list[0].slsPlcName || '');
        } else {
          // 고객사 정보 폼 그리드 > 고객사선택 > 셀렉트에 적용함
          // setClientNameSelect((pre: ISelect) => ({
          //   ...pre,
          //   value: '',
          // }));
          setClientNameSelect('');

          // 고객사 정보 폼 그리드 > 고객사이름 > 입력을 초기화함
          setClientNameInput('');

          // 고객사 정보 폼 그리드 > 고객사코드 > 입력을 초기화함
          setClientCodeInput('');

          // 고객사 정보 폼 그리드 > 주소 > 입력을 초기화함
          setClientAddressInput('');

          // 고객사 정보 폼 그리드 > 사업자등록번호 > 입력을 초기화함
          setClientRegNoInput('');

          // 고객사 정보 폼 그리드 > 대표자명 > 입력을 초기화함
          setClientCeoInput('');

          // 고객사 정보 폼 그리드 > 대표번호 > 입력을 초기화함
          setClientTelInput('');

          // 고객사 정보 폼 그리드 > 매출처 > 입력을 초기화함
          // setClientSellingPartnerInput('');
        }
      });
  };

  // // 고객사 정보 폼 그리드 > 고객사명 > 입력의 값을 변경함
  // const handleClientNameInput_onChange = (event: any) => {
  //   setClientNameInput(event.target.value);
  // };

  // 고객사 정보 폼 그리드 > 고객사코드 > 입력의 값을 변경함
  const handleClientCodeInput_onChange = (event: any) => {
    setClientCodeInput(event.target.value);
  };

  // 고객사 정보 폼 그리드 > 주소 > 입력의 값을 변경함
  const handleClientAddressInput_onChange = (event: any) => {
    setClientAddressInput(event.target.value);
  };

  // 고객사 정보 폼 그리드 > 사업자등록번호 > 입력의 값을 변경함
  const handleClientRegNoInput_onChange = (event: any) => {
    setClientRegNoInput(event.target.value);
  };

  // 고객사 정보 폼 그리드 > 대표자명 > 입력의 값을 변경함
  const handleClientCeoInput_onChange = (event: any) => {
    setClientCeoInput(event.target.value);
  };

  // 고객사 정보 폼 그리드 > 대표번호 > 입력의 값을 변경함
  const handleClientTelInput_onChange = (event: any) => {
    let tmpStr: string = phoneNumberformatter(event.target.value);

    setClientTelInput(tmpStr);
  };

  // 고객사 정보 폼 그리드 > Client Name > 입력의 값을 변경함
  const handleClientNameInput_onChange = (event: any) => {
    setClientNameInput(event.target.value);
  };

  // 고객사 정보 폼 그리드 > Sold To Name > 입력의 값을 변경함
  const handleSoldToNameInput_onChange = (event: any) => {
    setSoldToNameInput(event.target.value);
  };

  // 고객사 정보 폼 그리드 > Ship To Name > 입력의 값을 변경함
  const handleShipToNameInput_onChange = (event: any) => {
    setShipToNameInput(event.target.value);
  };

  // 고객사 정보 폼 그리드 > Bill To Name > 입력의 값을 변경함
  const handleBillToNameInput_onChange = (event: any) => {
    setBillToNameInput(event.target.value);
  };

  // // 고객사 정보 폼 그리드 > Market Type > 입력의 값을 변경함
  // const handleMarketTypeInput_onChange = (event: any) => {
  //   setMarketTypeInput(event.target.value);
  // };

  // 고객사 정보 폼 그리드 > Market Type > 셀렉트의 값을 변경함
  const handleMarketTypeSelect_onChange = (event: any) => {
    setMarketTypeSelect(event);
  };

  // 고객사 정보 폼 그리드 > Region > 셀렉트의 값을 변경함
  const handleRegionSelect_onChange = (event: any) => {
    // setRegionSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
    setRegionSelect(event);
  };

  // 고객사 정보 폼 그리드 > 매출처 > 입력의 값을 변경함
  const handleClientSellingPartnerInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 100) {
      //100byte 제한
      setClientSellingPartnerInput((pre) => pre);
    } else {
      setClientSellingPartnerInput(sVal);
    }
  };

  // 태그 정보 폼 그리드 > 태그 > 입력의 값을 변경함
  const handleClientTagInput_onChange = (event: any) => {
    var sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 1024) {
    //   // 1024 byte 제한
    //   setClientTagInput((pre) => pre);
    // } else {
    //   setClientTagInput(sVal);
    // }
    setClientTagInput(sVal);
  };

  // 영업 정보 폼 그리드 > 영업기회명 > 입력의 값을 변경함
  const handleSalesOpportunityInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 128) {
      // 128 byte 제한
      setSalesOpportunityInput((pre) => pre);
    } else {
      setSalesOpportunityInput(sVal);
    }
  };

  // 영업 정보 폼 그리드 > Solution > Solution 1 셀렉트에서 선택한 아이템을 변경함
  const handleSalesSolution1Select_onChange = (event: any) => {
    valSalesSolution1Select = event;
    valSalesSolution2Select = '';
    setSalesSolution1Select(event);
    // setSalesSolution1Select((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 영업 정보 폼 그리드 > Solution > Solution 2 셀렉트에서 선택한 아이템을 변경함
  const handleSalesSolution2Select_onChange = (event: any) => {
    valSalesSolution2Select = event;
    setSalesSolution2Select(event);
    // setSalesSolution2Select((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 영업 정보 폼 그리드 > 딜종류 > 셀렉트에서 선택한 아이템을 변경함
  const handleSalesDealTypeSelect_onChange = (event: any) => {
    setSalesDealTypeSelect(event);
    // setSalesDealTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 영업 정보 폼 그리드 > 납품구분 > 셀렉트에서 선택한 아이템을 변경함
  const handleSalesDeliveryTypeSelect_onChange = (event: any) => {
    setSalesDeliveryTypeSelect(event);
    // setSalesDeliveryTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 영업 정보 폼 그리드 > 경쟁사 > 입력의 값을 변경함
  const handleSalesCompetitorInput_onChange = (event: any) => {
    var sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 1024) {
    //   // 1024 byte 제한
    //   setSalesCompetitorInput((pre) => pre);
    // } else {
    //   setSalesCompetitorInput(sVal);
    // }
    setSalesCompetitorInput(sVal);
  };

  // 영업 정보 폼 그리드 > 협력사 > 입력의 값을 변경함
  const handleSalesPartnerInput_onChange = (event: any) => {
    var sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 1024) {
    //   // 1024 byte 제한
    //   setSalesPartnerInput((pre) => pre);
    // } else {
    //   setSalesPartnerInput(sVal);
    // }
    setSalesPartnerInput(sVal);
  };

  // 영업 정보 폼 그리드 > 예상물량 > 입력의 값을 변경함
  const handleSalesEstimatedAmountInput_onChange = (event: any) => {
    setSalesEstimatedAmountInput(event);

    // let sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 50) {
    //   //50 byte 제한
    //   setSalesEstimatedAmountInput((pre) => pre);
    // } else {
    //   setSalesEstimatedAmountInput(sVal);
    // }
  };

  // 영업 정보 폼 그리드 > Amount Currency > 셀렉트의 값을 변경함
  const handleSalesEstimatedAmountCurrencySelect_onChange = (event: any) => {
    setSalesEstimatedAmountCurrencySelect(event);
    // setSalesEstimatedAmountCurrencySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 영업 정보 폼 그리드 > 필요/지원사항 > 입력의 값을 변경함
  const handleSalesNecessitySupportInput_onChange = (event: any) => {
    setSalesNecessitySupportInput(event.target.value);

    // var sVal = event.target.value;
    // var byteLength = getStringByteLength(sVal);
    // if (byteLength > 50) {
    //   //50 byte 제한
    //   setSalesNecessitySupportInput((pre) => pre);
    // } else {
    //   setSalesNecessitySupportInput(sVal);
    // }
  };

  // 프로젝트 정보 폼 그리드 > Proposal Submission Date > 날짜 피커를 변경함
  const handlePlanProposalSubmissionDatePicker_onChange = (event: any) => {
    setPlanProposalSubmissionDatePicker(event);
  };

  // 프로젝트 정보 폼 그리드 > 수주일 > 날짜 피커를 변경함
  const handlePlanEstimatedConfirmationDatePicker_onChange = (event: any) => {
    setPlanEstimatedConfirmationDatePicker(event);
  };

  // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 날짜 피커를 변경함
  const handlePlanEstimatedDatePicker_onChange = (event: any) => {
    setPlanEstimatedDatePicker(event);
  };

  // 프로젝트 정보 폼 그리드 > Expected Contract Amount > 입력의 값을 변경함
  const handleSalesContractSizeInput_onChange = (event: any) => {
    setSalesContractSizeInput(event);
  };

  // 프로젝트 정보 폼 그리드 > Expected Contract Amount Currency > 셀렉트의 값을 변경함
  const handleSalesContractSizeCurrencySelect_onChange = (event: any) => {
    setSalesContractSizeCurrencySelect(event);
    // setSalesContractSizeCurrencySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 프로젝트 정보 폼 그리드 > Expected Direct Cost > 입력의 값을 변경함
  const handleSalesNsrSizeInput_onChange = (event: any) => {
    setSalesNsrSizeInput(event);
  };

  // 프로젝트 정보 폼 그리드 > Expected Direct Cost Currency > 셀렉트의 값을 변경함
  const handleSalesNsrSizeCurrencySelect_onChange = (event: any) => {
    setSalesNsrSizeCurrencySelect(event);
    // setSalesNsrSizeCurrencySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블 > 컬럼의 값을 변경함
  const handleSalesEstimatedAmountTable_onCellValueChanged = (event: any) => {
    let tmpData: any = [];

    event.node.parent.allLeafChildren.map((row: any, index: number) => {
      tmpData.push(row.data);
    });

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블의 셀 값을 계산함
    tmpData = sumSalesEstimatedAmountTableData(
      salesEstimatedAmountTable.column,
      tmpData,
    );

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블에 적용함
    setSalesEstimatedAmountTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmpData,
    }));
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블의 행 순서를 변경함
  const handleManagerClientTable_onRowDragEnd = (event: any) => {
    let tmpData: any = [];

    event.node.parent.allLeafChildren.map((row: any, index: number) => {
      tmpData.push(row.data);
    });

    // 담당자(고객사) 폼 그리드 > 담당자 > 테이블에 적용함
    setManagerClientTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmpData,
    }));
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블의 행을 클릭함
  const handleManagerClientTable_onRowClicked = (event: any) => {
    // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 > 선택한 행에 적용함
    setSelectedManagerClientTableRow(event.data);
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블의 행을 더블 클릭함
  const handleManagerClientTable_onRowDoubleClicked = (event: any) => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '담당자(고객사) 수정',
      content: (
        <MN2302220202_ManagerClient
          id={tmpId}
          data={event.data}
          callback={(callbackData: any) => {
            // 목록에서 수정할 행의 순서를 불러옴
            let tmpIndex: number = _.indexOf(
              managerClientTable.data,
              selectedManagerClientTableRow,
            );

            // 담당자(고객사) 폼 그리드 > 담당자 > 테이블의 데이터에 적용함
            setManagerClientTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: [
                ...pre.data.slice(0, tmpIndex),
                callbackData,
                ...pre.data.slice(tmpIndex + 1),
              ],
            }));
          }}
        />
      ),
      size: 1000,
    });
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 > 전체 삭제 버튼을 클릭함
  const handleManagerClientDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '담당자 전체를 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });

            setManagerClientTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: [],
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 > 선택 삭제 버튼을 클릭함
  const handleManagerClientSelectedDeleteButton_onClick = () => {
    // 삭제할 아이디 목록을 정의함
    let selectedId: string[] = [];

    // 삭제할 아이디 목록을 불러옴
    managerClientTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedId.push(item.workRdr);
    });

    console.log('> setManagerClientTable', managerClientTable);

    if (selectedId.length === 0) {
      setModal({
        title: '알림',
        content: '삭제할 담당자를 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: '선택하신 담당자를 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            notifications.show({
              title: '알림',
              message: '삭제하였습니다.',
            });

            // 목록에서 삭제할 담당자를 적용한 후 최종 목록을 정의함
            let tmlTableData: any = managerClientTable.data.filter(
              (item: any) => !_.includes(selectedId, item.workRdr),
            );

            setManagerClientTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: tmlTableData,
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 > 추가 버튼을 클릭함
  const handleManagerClientAddButton_onClick = (event: any) => {
    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '담당자(고객사) 추가',
      content: (
        <MN2302220202_ManagerClient
          id={tmpId}
          callback={(callbackData: any) => {
            // 임시 순번을 추가함
            callbackData['workRdr'] = nanoid();

            // 담당자(고객사) 폼 그리드 > 담당자 > 테이블에 적용함
            setManagerClientTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: [...managerClientTable.data, callbackData],
            }));
          }}
        />
      ),
      size: 1000,
    });
  };

  // 담당자(고객사) 폼 그리드 > 고객사 > 이름 > 입력의 값을 변경함
  const handleManagerClientNameInput_onChange = (event: any) => {
    setManagerClientNameInput(event.target.value);
  };

  // 담당자(고객사) 폼 그리드 > 고객사 > 직급 > 입력의 값을 변경함
  const handleManagerClientPositionInput_onChange = (event: any) => {
    setManagerClientPositionInput(event.target.value);
  };

  // 담당자(고객사) 폼 그리드 > 고객사 > 부서 > 입력의 값을 변경함
  const handleManagerClientDepartmentInput_onChange = (event: any) => {
    setManagerClientDepartmentInput(event.target.value);
  };

  // 담당자(고객사) 폼 그리드 > 고객사 > 전화번호 > 입력의 값을 변경함
  const handleManagerClientPhoneInput_onChange = (event: any) => {
    setManagerClientPhoneInput(phoneNumberformatter(event.target.value));
  };

  // 담당자(고객사) 폼 그리드 > 고객사 > 이메일주소 > 입력의 값을 변경함
  const handleManagerClientEmailInput_onChange = (event: any) => {
    setManagerClientEmailInput(event.target.value);
  };

  // 조회 권한 폼 그리드 > 전체 삭제 버튼을 클릭함
  const handleReadAuthDeleteButton_onClick = () => {
    slsApi
      .getSlsNqryTrgt({})
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          setModal({
            title: '확인',
            content: '조회 권한 전체 사용자를 삭제하시겠습니까?',
            useOkayButton: false,
            useCancelButton: true,
            cancelButtonLabel: '아니오',
            button: (
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={() => {
                  setRemoveModal(true);

                  // 조회 권한 폼 그리드 > 테이블에 적용함
                  setReadAuthTable((pre: { column: any; data: any }) => ({
                    ...pre,
                    data: data.data.list,
                  }));

                  notifications.show({
                    title: '확인',
                    message: (
                      <span className="text-lg">
                        조회 권한 전체 사용자를 삭제하였습니다.
                      </span>
                    ),
                    color: 'indigo',
                  });
                }}
              >
                예
              </Button>
            ),
          });
        }
      })
      .catch((error: any) => {});
  };

  // 조회 권한 폼 그리드 > 선택 삭제 버튼을 클릭함
  const handleReadAuthSelectedDeleteButton_onClick = () => {
    slsApi
      .getSlsNqryTrgt({})
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 삭제할 조회 권한 아이디 목록을 정의함
          let selectedId: string[] = [];

          // 기본값을 삭제하려 했는지 확인함
          let tmpDeleteDefaultData: boolean = false;

          // 삭제할 조회 권한 아이디 목록을 불러옴
          readAuthTableRef.current.api.getSelectedRows().map((item: any) => {
            if (_.findIndex(data.data.list, { userId: item.userId }) === -1) {
              selectedId.push(item.userId);
            } else {
              tmpDeleteDefaultData = true;
            }
          });

          if (selectedId.length === 0 && tmpDeleteDefaultData) {
            notifications.show({
              title: '확인',
              message: (
                <span className="text-lg">
                  삭제할 수 없는 사용자가 있습니다.
                </span>
              ),
              color: 'pink',
            });

            return;
          }

          if (selectedId.length === 0) {
            notifications.show({
              title: '확인',
              message: (
                <span className="text-lg">
                  삭제할 조회 권한 사용자를 체크하여 선택하세요.
                </span>
              ),
              color: 'pink',
            });

            return;
          }

          setModal({
            title: '확인',
            content: '선택하신 조회 권한 사용자를 삭제하시겠습니까?',
            useOkayButton: false,
            useCancelButton: true,
            cancelButtonLabel: '아니오',
            button: (
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={() => {
                  setRemoveModal(true);

                  // 조회 권한 목록에서 삭제할 조회 권한을 적용한 후 최종 목록을 정의함
                  let tmlReadAuthTableData: any = readAuthTable.data.filter(
                    (item: any) => !_.includes(selectedId, item.userId),
                  );

                  // 조회 권한 폼 그리드 > 테이블에 적용함
                  setReadAuthTable((pre: { column: any; data: any }) => ({
                    ...pre,
                    data: tmlReadAuthTableData,
                  }));

                  notifications.show({
                    title: '확인',
                    message: (
                      <span className="text-lg">
                        선택하신 조회 권한 사용자를 삭제하였습니다.
                      </span>
                    ),
                    color: 'indigo',
                  });
                }}
              >
                예
              </Button>
            ),
          });
        }
      })
      .catch((error: any) => {});
  };

  // 조회 권한 폼 그리드 > 대상자 > 적용 버튼을 클릭함
  const handleReadAuthTargetUserApplyButton_onClick = (event: any) => {
    // 추가할 대상자 목록을 정의함
    let selectedId: string[] = [];

    // 추가할 대상자 아이디 목록을 불러옴
    readAuthTargetUserTableRef.current.api
      .getSelectedRows()
      .map((item: any) => {
        selectedId.push(item.userId);
      });

    if (selectedId.length === 0) {
      notifications.show({
        title: '확인',
        message: (
          <span className="text-lg">추가할 대상자를 체크하여 선택하세요.</span>
        ),
        color: 'pink',
      });

      return;
    }

    // 조회 권한 폼 그리드 > 대상자 > 팝업 출력 여부에 적용함
    setVisibleReadAuthTargetUser(false);

    // 대상자 목록을 불러옴
    let tmlReadAuthTableData: any[] = _.cloneDeep(readAuthTable.data);

    // 기존에 없던 대상자를 목록에 추가함
    selectedId.map((item: string) => {
      if (_.findIndex(tmlReadAuthTableData, { userId: item }) === -1) {
        tmlReadAuthTableData.push(
          _.find(readAuthTargetUserTable.data, { userId: item }),
        );
      }
    });

    // 조회 권한 폼 그리드 > 테이블에 적용함
    setReadAuthTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlReadAuthTableData,
    }));

    notifications.show({
      title: '확인',
      message: <span className="text-lg">대상자를 추가하였습니다.</span>,
      color: 'indigo',
    });
  };

  // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const handleSalesVatTargetUserSearchSelect_onChange = (event: any) => {
    setSalesVatTargetUserSearchSelect(event);
    // setSalesVatTargetUserSearchSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 검색어 > 입력의 값을 변경함
  const handleSalesVatTargetUserSearchInput_onChange = (event: any) => {
    setSalesVatTargetUserSearchInput(event.currentTarget.value);
  };

  // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 검색어 > 입력에서 키를 입력함
  const handleSalesVatTargetUserSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 검색 결과 테이블 데이터를 불러옴
      getSalesVatTargetUserTableData();
    }
  };

  // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 검색어 > 버튼을 클릭함
  const handleSalesVatTargetUserSearchButton_onChange = () => {
    // 검색 결과 테이블 데이터를 불러옴
    getSalesVatTargetUserTableData();
  };

  // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 적용 버튼을 클릭함
  const handleSalesVatTargetUserApplyButton_onClick = (event: any) => {
    // 추가할 목록을 정의함
    let selectedUser: any = null;

    if (event.type === 'rowDoubleClicked') {
      selectedUser = event.data;
    } else {
      // 추가할 아이디 목록을 불러옴
      salesVatTargetUserTableRef.current.api
        .getSelectedRows()
        .map((item: any) => {
          selectedUser = item;
        });
    }

    if (selectedUser === null) {
      notifications.show({
        title: '확인',
        message: (
          <span className="text-lg">
            추가할 디토닉 직원을 체크하여 선택하세요.
          </span>
        ),
        color: 'pink',
      });

      return;
    }

    setSalesVatInput({
      id: selectedUser.userId,
      name: selectedUser.name,
    });

    // 프로젝트 정보 폼 그리드 > Sales VAT > 팝업 출력 여부에 적용함
    setVisibleSalesVatTargetUser(false);
  };

  // 영업 활동 등록 으로 이동 - 영업기회 전달
  const handleGoSlsReqButton_onClick = () => {
    setRemoveComponentModal(id);

    let tmpId: string = nanoid();

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '영업 활동 등록',
      content: (
        <MN2302220204
          id={tmpId}
          slsPrtnId={slsPrtnId}
          initSlsPrtnId={slsPrtnId}
        />
      ),
      size: 1500,
    });
  };

  // 영업 활동 조회 버튼을 클릭함
  const handleGoSlsListButton_onClick = () => {
    setRemoveComponentModal(id);

    window.location.href = `/sales-work-management?search-category=sls_prtn_name&search=${encodeURIComponent(
      decode(slsData.slsPrtnName),
    )}&search-id=${slsData.slsPrtnId}`;
  };

  // 영업 기회 상세 데이터를 불러옴
  const getSlsData = () => {
    slsApi
      .getSlsPrtn({
        slsPrtnId: slsPrtnId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 영업 기회 상세 데이터에 적용함
          setSlsData(data.data);

          console.log('> ok:', data.data);
        }
      });
  };

  // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블의 셀 값을 계산함
  const sumSalesEstimatedAmountTableData = (column: any, data: any): any => {
    let tmpData: any = _.cloneDeep(data);

    // 국내 힙계를 초기화함
    tmpData[0]['total'] = 0;

    // 해외 힙계를 초기화함
    tmpData[1]['total'] = 0;

    // 총계를 초기화함
    tmpData[2]['total'] = 0;

    // 년월 컬럼마다 합계를 구함
    column
      .filter(
        (item: any) => item.field !== 'category' && item.field !== 'total',
      )
      .map((item: any, index: number) => {
        // 년월 컬럼마다 합계를 구함
        tmpData[2][item.field] =
          tmpData[0][item.field] + tmpData[1][item.field];

        // 국내 힙계를 구함
        tmpData[0]['total'] += +tmpData[0][item.field];

        // 해외 힙계를 구함
        tmpData[1]['total'] += +tmpData[1][item.field];

        // 총계를 구함
        tmpData[2]['total'] += +tmpData[2][item.field];
      });

    if (slsPrtnId && !useModifyMode) {
      // 계산된 값에 콤마를 추가함
      column
        .filter(
          (item: any) => item.field !== 'category' && item.field !== 'total',
        )
        .map((item: any, index: number) => {
          // 년월 컬럼마다 합계를 구함
          tmpData[2][item.field] = addComma(tmpData[2][item.field]);
          tmpData[1][item.field] = addComma(tmpData[1][item.field]);
          tmpData[0][item.field] = addComma(tmpData[0][item.field]);

          // 국내 힙계를 구함
          tmpData[0]['total'] = addComma(tmpData[0]['total']);

          // 해외 힙계를 구함
          tmpData[1]['total'] = addComma(tmpData[1]['total']);

          // 총계를 구함
          tmpData[2]['total'] = addComma(tmpData[2]['total']);
        });
    }

    return tmpData;
  };

  // 조회 권한 폼 그리드 > 대상자 > 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const readAuthTargetUserSearchSelect_onChange = (event: any) => {
    setReadAuthTargetUserSearchSelect(event);
    // setReadAuthTargetUserSearchSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: event,
    // }));
  };

  // 조회 권한 폼 그리드 > 대상자 > 검색 폼 그리드 > 검색어 > 입력의 값을 변경함
  const readAuthTargetUserSearchInput_onChange = (event: any) => {
    setReadAuthTargetUserSearchInput(event.currentTarget.value);
  };

  // 조회 권한 폼 그리드 > 대상자 > 검색 폼 그리드 > 검색어 > 입력에서 키를 입력함
  const readAuthTargetUserSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 검색 결과 테이블 데이터를 불러옴
      getReadAuthTargetUserTableData();
    }
  };

  // 조회 권한 폼 그리드 > 대상자 > 검색 폼 그리드 > 검색어 > 버튼을 클릭함
  const handleReadAuthTargetUserSearchButton_onChange = () => {
    // 검색 결과 테이블 데이터를 불러옴
    getReadAuthTargetUserTableData();
  };

  // 국가, 통화 정보를 불러옴
  const getNtnCrnc = () => {
    cmnApi
      .getNtnCrnc()
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 국가, 통화에 적용함
          setCurrencyData(data.data.list);
        }
      })
      .catch((error: any) => {});
  };

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    if (!slsPrtnId) {
      // 신규
      tmpTab = [
        {
          label: '고객사',
          targetId: 'step-1',
        },
        {
          label: '검색 태그',
          targetId: 'step-2',
        },
        {
          label: '담당자',
          targetId: 'step-4',
        },
        {
          label: '조회 권한',
          targetId: 'step-5',
        },
      ];
    } else if (slsPrtnId && !useModifyMode) {
      // 조회
      tmpTab = [
        {
          label: '고객사',
          targetId: 'step-1',
        },
        {
          label: '검색 태그',
          targetId: 'step-2',
        },
        {
          label: '영업 활동',
          targetId: 'step-3',
        },
        {
          label: '담당자',
          targetId: 'step-4',
        },
        {
          label: '조회 권한',
          targetId: 'step-5',
        },
      ];
    } else if (slsPrtnId && useModifyMode) {
      // 수정
      tmpTab = [
        {
          label: '고객사',
          targetId: 'step-1',
        },
        {
          label: '검색 태그',
          targetId: 'step-2',
        },
        {
          label: '영업 활동',
          targetId: 'step-3',
        },
        {
          label: '담당자',
          targetId: 'step-4',
        },
        {
          label: '조회 권한',
          targetId: 'step-5',
        },
      ];
    }

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 고객사 정보 폼 그리드 > Industry > Industry 1 셀렉트의 아이템을 초기화함
  const initClientIndustry1Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsPrtnId || (slsPrtnId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // tmpOptionItem.push(
    //   {
    //     label: 'Auto',
    //     value: 'AUTO',
    //   },
    //   {
    //     label: 'Consumer',
    //     value: 'CNSM',
    //   },
    //   {
    //     label: 'ER&I',
    //     value: 'ERNI',
    //   },
    //   {
    //     label: 'FS',
    //     value: 'FS',
    //   },
    //   {
    //     label: 'LSHC',
    //     value: 'LSHC',
    //   },
    //   {
    //     label: 'Public',
    //     value: 'PBLC',
    //   },
    //   {
    //     label: 'TMT',
    //     value: 'TMT',
    //   },
    // );

    // 공통코드를 불러옴
    appUtil.getCommonCode('NDST_DNTF', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 고객사 정보 폼 그리드 > Industry > Industry 1 셀렉트의 아이템에 적용함
    valClientIndustry1Select = '';
    // setClientIndustry1Select((pre: ISelect) => ({
    //   ...pre,
    //   item: tmpOptionItem,
    // }));
    setClientIndustry1SelectItem(tmpOptionItem);
  };

  // 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트의 아이템을 초기화함
  const initClientIndustry2Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsPrtnId || (slsPrtnId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // // Industry 구분 하위코드를 불러옴
    // slsApi
    //   .getSlsPrtnSearchNdstcode({ dtlCodeId: valClientIndustry1Select })
    //   .then((data: IApiResult) => {
    //     data.data.list.map((item: any, index: number) => {
    //       let tmpOptionItem2: IOptionItem[] = [];
    //
    //       tmpOptionItem2.push({
    //         label: item.dtlCodeDscr,
    //         value: item.dtlCodeId,
    //       });
    //
    //       // 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트의 아이템에 적용함
    //       setClientIndustry2Select((pre: ISelect) => ({
    //         ...pre,
    //         item: [...pre.item, ...tmpOptionItem2],
    //       }));
    //     });
    //   });

    // 공통코드를 불러옴
    appUtil.getCommonCode('NDST_CTGR', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트의 아이템에 적용함
    valClientIndustry2Select = '';
    // setClientIndustry2Select((pre: ISelect) => ({
    //   ...pre,
    //   item: tmpOptionItem,
    // }));
    setClientIndustry2SelectItem(tmpOptionItem);
  };

  // 고객사 정보 폼 그리드 > Account > 셀렉트의 아이템을 초기화함
  const initClientAccountSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsPrtnId || (slsPrtnId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 영업기회 등록 - Division SP 목록 조회 목록을 불러옴
    slsApi
      .geSlsDivisionSp({
        grpCodeId: 'CNT_GRP',
      })
      .then((data: IApiResult) => {
        if (data.data.list.length > 0) {
          _.sortBy(data.data.list, ['sortRdr']).map(
            (item: any, index: number) => {
              tmpOptionItem.push({
                label: item.dtlCodeDscr,
                value: item.dtlCodeId,
              });
            },
          );

          // 고객사 정보 폼 그리드 > Account > 셀렉트의 아이템에 적용함
          // setClientAccountSelect((pre: ISelect) => ({
          //   ...pre,
          //   item: tmpOptionItem,
          // }));
          setClientAccountSelectItem(tmpOptionItem);
        }
      });
  };

  // 고객사 정보 폼 그리드 > 고객사명 > 셀렉트의 아이템을 초기화함
  // 고객사 정보 폼 그리드 > Channel > 셀렉트의 아이템을 초기화함
  const initClientNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsPrtnId || (slsPrtnId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // // 고객사 목록을 불러옴
    // slsApi.getSlsPrtnSearchBp({}).then((data: IApiResult) => {
    //   data.data.list.map((item: any, index: number) => {
    //     tmpOptionItem.push({
    //       label: item.bpName,
    //       value: item.bpCode,
    //     });
    //   });
    // });

    // 공통코드를 불러옴
    appUtil.getCommonCode('SLS_CHNL', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 고객사 정보 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
    // setClientNameSelect((pre: ISelect) => ({
    //   ...pre,
    //   item: tmpOptionItem,
    // }));
    setClientNameSelectItem(tmpOptionItem);
  };

  // 고객사 정보 폼 그리드 > Market Type > 셀렉트의 아이템을 초기화함
  const initMarketTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsPrtnId || (slsPrtnId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 공통코드를 불러옴
    appUtil.getCommonCode('MRKT_TYPE', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 고객사 정보 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
    setMarketTypeSelectItem(tmpOptionItem);
  };

  // 고객사 정보 폼 그리드 > 고객사이름 > 입력을 초기화함
  const initClientNameInput = () => {
    setClientNameInput('');
  };

  // 고객사 정보 폼 그리드 > 고객사코드 > 입력을 초기화함
  const initClientCodeInput = () => {
    setClientCodeInput('');
  };

  // 고객사 정보 폼 그리드 > 주소 > 입력을 초기화함
  const initClientAddressInput = () => {
    setClientAddressInput('');
  };

  // 고객사 정보 폼 그리드 > 사업자등록번호 > 입력을 초기화함
  const initClientRegNoInput = () => {
    setClientRegNoInput('');
  };

  // 고객사 정보 폼 그리드 > 대표자명 > 입력을 초기화함
  const initClientCeoInput = () => {
    setClientCeoInput('');
  };

  // 고객사 정보 폼 그리드 > 대표번호 > 입력을 초기화함
  const initClientTelInput = () => {
    setClientTelInput('');
  };

  // 고객사 정보 폼 그리드 > 매출처 > 입력을 초기화함
  const initClientSellingPartnerInput = () => {
    setClientSellingPartnerInput('');
  };

  // 태그 정보 폼 그리드 > 태그 > 입력을 초기화함
  const initClientTagInput = () => {
    setClientTagInput('');
  };

  // 영업 정보 폼 그리드 > 영업기회명 > 입력을 초기화함
  const initSalesOpportunityInput = () => {
    setSalesOpportunityInput('');
  };

  // 영업 정보 폼 그리드 > Solution > Solution 1 셀렉트의 아이템을 초기화함
  const initSalesSolution1Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsPrtnId || (slsPrtnId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // tmpOptionItem.push(
    //   {
    //     label: 'META',
    //     value: 'META',
    //   },
    //   {
    //     label: 'IWP',
    //     value: 'IWP',
    //   },
    //   {
    //     label: 'MES',
    //     value: 'MES',
    //   },
    //   {
    //     label: 'ESL',
    //     value: 'ESL',
    //   },
    //   {
    //     label: 'CONSULTING',
    //     value: 'CNSL',
    //   },
    //   {
    //     label: 'Others',
    //     value: 'THRS',
    //   },
    // );

    // 공통코드를 불러옴
    appUtil.getCommonCode('SLS_MAIN', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 영업 정보 폼 그리드 > Solution > Solution 1 셀렉트의 아이템에 적용함
    valSalesSolution1Select = '';
    setSalesSolution1SelectItem(tmpOptionItem);
    // setSalesSolution1Select((pre: ISelect) => ({
    //   ...pre,
    //   item: tmpOptionItem,
    // }));
  };

  // 영업 정보 폼 그리드 > Solution > Solution 2 셀렉트의 아이템을 초기화함
  const initSalesSolution2Select = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsPrtnId || (slsPrtnId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // // Solution 하위코드를 불러옴
    // slsApi
    //   .getSlsPrtnSearchSltncode({ dtlCodeId: valSalesSolution1Select })
    //   .then((data: IApiResult) => {
    //     data.data.list.map((item: any, index: number) => {
    //       let tmpOptionItem2: IOptionItem[] = [];
    //
    //       tmpOptionItem2.push({
    //         label: item.dtlCodeDscr,
    //         value: item.dtlCodeId,
    //       });
    //
    //       // 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트의 아이템에 적용함
    //       setSalesSolution2Select((pre: ISelect) => ({
    //         ...pre,
    //         item: [...pre.item, ...tmpOptionItem2],
    //       }));
    //     });
    //   });

    // 공통코드를 불러옴
    appUtil.getCommonCode('SLS_DTL', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 영업 정보 폼 그리드 > Solution > Solution 2 셀렉트의 아이템에 적용함
    valSalesSolution2Select = '';
    setSalesSolution2SelectItem(tmpOptionItem);
    // setSalesSolution2Select((pre: ISelect) => ({
    //   ...pre,
    //   item: tmpOptionItem,
    // }));
  };

  // 영업 정보 폼 그리드 > 딜종류 > 셀렉트의 아이템을 초기화함
  const initSalesDealTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsPrtnId || (slsPrtnId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 공통코드를 불러옴
    appUtil.getCommonCode('SLS_DEAL', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 영업 정보 폼 그리드 > 딜종류 > 셀렉트의 아이템에 적용함
    setSalesDealTypeSelectItem(tmpOptionItem);
    // setSalesDealTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   item: tmpOptionItem,
    // }));
  };

  // 영업 정보 폼 그리드 > 납품구분 > 셀렉트의 아이템을 초기화함
  const initSalesDeliveryTypeSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsPrtnId || (slsPrtnId && useModifyMode)) {
      tmpOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 공통코드를 불러옴
    appUtil.getCommonCode('SLS_DLVR', (data: any[]) => {
      _.sortBy(data, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.dtlCodeDscr,
          value: item.dtlCodeId,
        });
      });
    });

    // 영업 정보 폼 그리드 > 납품구분 > 셀렉트의 아이템에 적용함
    setSalesDeliveryTypeSelectItem(tmpOptionItem);
  };

  // 영업 정보 폼 그리드 > 경쟁사 > 입력을 초기화함
  const initSalesCompetitorInput = () => {
    setSalesCompetitorInput('');
  };

  // 영업 정보 폼 그리드 > 협력사 > 입력을 초기화함
  const initSalesPartnerInput = () => {
    setSalesPartnerInput('');
  };

  // 영업 정보 폼 그리드 > 예상물량 > 입력을 초기화함
  const initSalesEstimatedAmountInput = () => {
    setSalesEstimatedAmountInput('');
  };

  // 영업 정보 폼 그리드 > 필요/지원사항 > 입력을 초기화함
  const initSalesNecessitySupportInput = () => {
    setSalesNecessitySupportInput('');
  };

  // 프로젝트 정보 폼 그리드 > Proposal Submission Date > 날짜 피커를 초기화함
  const initPlanProposalSubmissionDatePicker = () => {
    setPlanProposalSubmissionDatePicker(null);
  };

  // 프로젝트 정보 폼 그리드 > 수주일 > 날짜 피커를 초기화함
  const initPlanEstimatedConfirmationDatePicker = () => {
    setPlanEstimatedConfirmationDatePicker(null);
  };

  // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 날짜 피커를 초기화함
  const initPlanEstimatedDatePicker = () => {
    setPlanEstimatedDatePicker([null, null]);
  };

  // 프로젝트 정보 폼 그리드 > 프로젝트완료일 > 날짜 피커를 초기화함
  // const initPlanEstimatedEndDatePicker = () => {
  //   setPlanEstimatedEndDatePicker('');
  // };

  // 프로젝트 정보 폼 그리드 > 기간(월) > 입력을 초기화함
  const initPlanEstimatedMonthInput = () => {
    setPlanEstimatedMonthInput('');
  };

  // 프로젝트 정보 폼 그리드 > 계약규모(원) > 입력을 초기화함
  const initSalesContractSizeInput = () => {
    setSalesContractSizeInput('');
  };

  // 프로젝트 정보 폼 그리드 > NSR규모(원) > 입력을 초기화함
  const initSalesNsrSizeInput = () => {
    setSalesNsrSizeInput('');
  };

  // 프로젝트 정보 폼 그리드 > NSR X 확도 > 입력을 초기화함
  const initSalesNsrProbabilityInput = () => {
    setSalesNsrProbabilityInput('');
  };

  // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블을 초기화함
  const initSalesEstimatedAmountTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 데이터를 정의함
    let tmpData: any[] = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '구분',
        field: 'category',
        width: 90,
        editable: false,
        pinned: 'left',
        suppressSizeToFit: true,
      },
      {
        headerName: '총계',
        field: 'total',
        width: 170,
        editable: false,
        pinned: 'left',
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) => getFormatNumber(params.value),
      },
    );

    // 데이터에 추가함
    tmpData.push(
      {
        category: '국내',
        total: 0,
      },
      {
        category: '해외',
        total: 0,
      },
      {
        category: '합계',
        total: 0,
      },
    );

    // 프로젝트기간에 모든 날짜가 선택되어있을 때, 기간별예상매출금액(원) 테이블을 출력함
    if (
      planEstimatedDatePicker[0] !== null &&
      planEstimatedDatePicker[1] !== null
    ) {
      // 프로젝트 시작 년월을 불러옴
      let tmpStartYear: number = +moment(planEstimatedDatePicker[0]).format(
        'YYYY',
      );
      let tmpStartMonth: number = +moment(planEstimatedDatePicker[0]).format(
        'MM',
      );

      // 프로젝트 완료 년월을 불러옴
      let tmpEndYear: number = +moment(planEstimatedDatePicker[1]).format(
        'YYYY',
      );
      let tmpEndMonth: number = +moment(planEstimatedDatePicker[1]).format(
        'MM',
      );

      // 프로젝트 기간(월)을 계산함
      let tmpBetweenMonth: number =
        (tmpEndYear - tmpStartYear) * 12 + (tmpEndMonth - tmpStartMonth) + 1;

      // 프로젝트 기간(월)만큼 컬럼을 추가함
      for (let i: number = 0; i < tmpBetweenMonth; i++) {
        // 날짜 이름으로 된 컬럼 이름을 생성함
        let tmpColumnName: string = moment(planEstimatedDatePicker[0])
          .add(i, 'M')
          .format('YYYY.MM');
        let tmpColumnField: string = moment(planEstimatedDatePicker[0])
          .add(i, 'M')
          .format('YYYYMM');

        // 컬럼에 추가함
        tmpColumn.push({
          headerName: tmpColumnName,
          field: tmpColumnField,
          width: 160,
          type: 'rightAligned',
          valueParser: (params: any) =>
            _.isNaN(_.toNumber(params.newValue))
              ? 0
              : _.toNumber(+params.newValue),
          valueFormatter: (params: any) => getFormatNumber(params.value),
        });

        // 데이터에 추가함
        tmpData[0][tmpColumnField] = 0;
        tmpData[1][tmpColumnField] = 0;
        tmpData[2][tmpColumnField] = 0;

        // 불러온 데이터가 있으면 해당 데이터를 적용함
        if (slsPrtnId) {
          slsData.list.map((item: any) => {
            let tmpBaseDateColumn: string = item.baseDate.replaceAll('.', '');

            switch (item.slsRvncCode) {
              // 국내
              case 'DMST':
                tmpData[0][tmpBaseDateColumn] = +item.mnt.replaceAll(',', '');
                break;

              // 해외
              case 'VRSS':
                tmpData[1][tmpBaseDateColumn] = +item.mnt.replaceAll(',', '');
                break;

              default:
                break;
            }
          });
        }
      }
    }

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블의 셀 값을 계산함
    tmpData = sumSalesEstimatedAmountTableData(tmpColumn, tmpData);

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블에 적용함
    setSalesEstimatedAmountTable((pre: { column: any; data: any }) => ({
      column: tmpColumn,
      data: tmpData,
    }));
  };

  // 영업 활동 정보 폼 그리드 > 영업단계 > 입력을 초기화함
  const initSalesWorkStepInput = () => {
    setSalesWorkStepInput('');
  };

  // 영업 활동 정보 폼 그리드 > 확도 > 입력을 초기화함
  const initSalesWorkProbabilityInput = () => {
    setSalesWorkProbabilityInput('');
  };

  // 영업 활동 정보 폼 그리드 > 제안결과 > 입력을 초기화함
  const initSalesWorkProposalResultInput = () => {
    setSalesWorkProposalResultInput('');
  };

  // 영업 활동 정보 폼 그리드 > 제안결과내용 > 입력을 초기화함
  const initSalesWorkProposalResultContentInput = () => {
    setSalesWorkProposalResultContentInput('');
  };

  // SWOT 분석 폼 그리드 > Strength > 입력을 초기화함
  const initSwotStrengthInput = () => {
    setSwotStrengthInput('');
  };

  // SWOT 분석 폼 그리드 > Weakness > 입력을 초기화함
  const initSwotWeaknessInput = () => {
    setSwotWeaknessInput('');
  };

  // SWOT 분석 폼 그리드 > Opportunities > 입력을 초기화함
  const initSwotOpportunitiesInput = () => {
    setSwotOpportunitiesInput('');
  };

  // SWOT 분석 폼 그리드 > Threats > 입력을 초기화함
  const initSwotThreatsInput = () => {
    setSwotThreatsInput('');
  };

  // 담당자(고객사) 폼 그리드 > 이름 > 입력을 초기화함
  const initManagerClientNameInput = () => {
    setManagerClientNameInput('');
  };

  // 담당자(고객사) 폼 그리드 > Email > 입력을 초기화함
  const initManagerClientEmailInput = () => {
    setManagerClientEmailInput('');
  };

  // 담당자(고객사) 폼 그리드 > 휴대폰 > 입력을 초기화함
  const initManagerClientPhoneInput = () => {
    setManagerClientPhoneInput('');
  };

  // 담당자(고객사) 폼 그리드 > 직급 > 입력을 초기화함
  const initManagerClientPositionInput = () => {
    setManagerClientPositionInput('');
  };

  // 담당자(고객사) 폼 그리드 > 부서 > 입력을 초기화함
  const initManagerClientDepartmentInput = () => {
    setManagerClientDepartmentInput('');
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블을 초기화함
  const initManagerClientTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 추가, 수정 상태일 때만 체크박스를 추가함
    if (!slsPrtnId || useModifyMode) {
      // 컬럼에 추가함
      tmpColumn.push({
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      });
    }

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '이름',
        field: 'cstmMngrName',
        width: 160,
        suppressSizeToFit: true,
      },
      {
        headerName: '직급',
        field: 'cstmMngrPstn',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '부서',
        field: 'cstmMngrDprt',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: '전화번호',
        field: 'cstmMngrClph',
        width: 170,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => phoneNumberformatter(params.value),
      },
      {
        headerName: '이메일주소',
        field: 'cstmMngrMl',
      },
    );

    // 담당자(고객사) 폼 그리드 > 담당자 > 테이블에 적용함
    setManagerClientTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 데이터를 불러옴
  const getManagerClientTableData = () => {
    // 담당자(고객사) 폼 그리드 > 담당자 > 테이블에 적용함
    setManagerClientTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: slsData.cstmMngrList,
    }));
  };

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 > 선택한 행을 초기화함
  const initSelectedManagerClientTableRow = () => {
    setSelectedManagerClientTableRow(null);
  };

  // 담당자(내부) 폼 그리드 > 모든 입력을 초기화함
  const initManagerClientAllForm = () => {
    // 담당자(고객사) 폼 그리드 > 담당자 > 이름 > 입력을 초기화함
    setManagerClientNameInput('');

    // 담당자(고객사) 폼 그리드 > 담당자 > 전화번호 > 입력을 초기화함
    setManagerClientPhoneInput('');

    // 담당자(고객사) 폼 그리드 > 담당자 > 이메일주소 > 입력을 초기화함
    setManagerClientEmailInput('');

    // 담당자(고객사) 폼 그리드 > 담당자 > 직급 > 입력을 초기화함
    setManagerClientPositionInput('');

    // 담당자(고객사) 폼 그리드 > 담당자 > 부서 > 입력을 초기화함
    setManagerClientDepartmentInput('');
  };

  // 담당자(내부) 폼 그리드 > 아이디 > 입력을 초기화함
  const initManagerInsideIdInput = () => {
    setManagerInsideIdInput(loginUser.id);
  };

  // 담당자(내부) 폼 그리드 > 이름 > 입력을 초기화함
  const initManagerInsideNameInput = () => {
    setManagerInsideNameInput(loginUser.name || '');
  };

  // 담당자(내부) 폼 그리드 > 본부 > 입력을 초기화함
  const initManagerInsideHeadquarterInput = () => {
    setManagerInsideHeadquarterInput(loginUser.hdqrName || '');
  };

  // 담당자(내부) 폼 그리드 > 부서 > 입력을 초기화함
  const initManagerInsideDepartmentInput = () => {
    setManagerInsideDepartmentInput(loginUser.dprtName || '');
  };

  // 담당자(내부) 폼 그리드 > Email > 입력을 초기화함
  const initManagerInsideEmailInput = () => {
    setManagerInsideEmailInput(loginUser.ml || '');
  };

  // 담당자(내부) 폼 그리드 > 휴대폰 > 입력을 초기화함
  const initManagerInsidePhoneInput = () => {
    setManagerInsidePhoneInput(loginUser.celPhn || '');
  };

  // 조회 권한 폼 그리드 > 대상자 > 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
  const initReadAuthTargetUserSearchSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '이름',
        value: 'name',
      },
      {
        label: '닉네임',
        value: 'nick_name',
      },
    );

    // 조회 권한 폼 그리드 > 대상자 > 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    setReadAuthTargetUserSearchSelect('name');
    setReadAuthTargetUserSearchSelectItem(tmpOptionItem);
    // setReadAuthTargetUserSearchSelect((pre: ISelect) => ({
    //   value: 'name',
    //   item: tmpOptionItem,
    // }));
  };

  // 조회 권한 폼 그리드 > 테이블을 초기화함
  const initReadAuthTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 추가, 수정 상태일 때만 체크박스를 추가함
    if (!slsPrtnId || useModifyMode) {
      // 컬럼에 추가함
      tmpColumn.push({
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      });
    }

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '회사',
        field: 'cmpnName',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '본부',
        field: 'hdqrName',
        width: 170,
        suppressSizeToFit: true,
      },
      {
        headerName: '부서',
        field: 'dprtName',
        width: 150,
        suppressSizeToFit: true,
      },
      // { headerName: 'CS부서', field: 'csDprtName', width: 200 },
      {
        headerName: '직급',
        field: 'pstnName',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '닉네임',
        field: 'nickName',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '이름',
        field: 'name',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: '휴대폰번호',
        field: 'celPhn',
        width: 150,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => phoneNumberformatter(params.value),
      },
      { headerName: '이메일', field: 'ml' },
    );

    // 조회 권한 폼 그리드 > 테이블에 적용함
    setReadAuthTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 조회 권한 폼 그리드 > 테이블 데이터를 불러옴
  const getReadAuthTableData = () => {
    // 조회 권한 폼 그리드 > 테이블에 적용함
    setReadAuthTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: slsData.slsXpsrTrgtList,
    }));
  };

  // 조회 권한 폼 그리드 > 테이블 기본값 데이터를 불러옴
  const getReadAuthTableDefaultData = () => {
    slsApi
      .getSlsNqryTrgt({})
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 조회 권한 폼 그리드 > 테이블에 적용함
          setReadAuthTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));
        }
      })
      .catch((error: any) => {});
  };

  // 조회 권한 폼 그리드 > 대상자 > 테이블을 초기화함
  const initReadAuthTargetUserTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'checkbox',
        width: 60,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressSizeToFit: true,
      },
      {
        headerName: '회사',
        field: 'cmpnName',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '본부',
        field: 'hdqrName',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '부서',
        field: 'dprtName',
        width: 120,
        suppressSizeToFit: true,
      },
      // { headerName: 'CS부서', field: 'csDprtName', width: 200 },
      {
        headerName: '직급',
        field: 'pstnName',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: '닉네임',
        field: 'nickName',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '이름',
        field: 'name',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '휴대폰번호',
        field: 'celPhn',
        width: 150,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => phoneNumberformatter(params.value),
      },
      { headerName: '이메일', field: 'ml' },
    );

    // 테이블에 적용함
    setReadAuthTargetUserTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 조회 권한 폼 그리드 > 대상자 > 테이블 데이터를 불러옴
  const getReadAuthTargetUserTableData = () => {
    // 테이블에 적용함
    setReadAuthTargetUserTable((pre: { column: any; data: any }) => ({
      ...pre,
      loading: true,
    }));

    cmnApi
      .getCmnUsers({
        searchType: readAuthTargetUserSearchSelect,
        searchKeyword: readAuthTargetUserSearchInput,
        pageSize: 10000,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 조회 권한 폼 그리드 > 대상자 > 테이블에 적용함
          setReadAuthTargetUserTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
              loading: false,
            }),
          );
        }
      })
      .catch((error: any) => {
        // 조회 권한 폼 그리드 > 대상자 > 테이블에 적용함
        setReadAuthTargetUserTable(
          (pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            loading: false,
          }),
        );
      });
  };

  // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
  const initSalesVatTargetUserSearchSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '이름',
        value: 'name',
      },
      {
        label: '닉네임',
        value: 'nick_name',
      },
    );

    // 조회 권한 폼 그리드 > 대상자 > 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    setSalesVatTargetUserSearchSelect('name');
    setSalesVatTargetUserSearchSelectItem(tmpOptionItem);
    // setSalesVatTargetUserSearchSelect((pre: ISelect) => ({
    //   value: 'name',
    //   item: tmpOptionItem,
    // }));
  };

  // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 테이블을 초기화함
  const initSalesVatTargetUserTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '이름',
        field: 'name',
      },
      {
        headerName: '닉네임',
        field: 'nickName',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '본부',
        field: 'hdqrName',
        width: 170,
        suppressSizeToFit: true,
      },
      {
        headerName: '부서',
        field: 'dprtName',
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: '직급',
        field: 'pstnName',
        width: 110,
        suppressSizeToFit: true,
      },
    );

    // 테이블에 적용함
    setSalesVatTargetUserTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 테이블 데이터를 불러옴
  const getSalesVatTargetUserTableData = () => {
    // 테이블에 적용함
    setSalesVatTargetUserTable((pre: { column: any; data: any }) => ({
      ...pre,
      loading: true,
    }));

    cmnApi
      .getCmnUsers({
        searchType: salesVatTargetUserSearchSelect,
        searchKeyword: salesVatTargetUserSearchInput,
        pageSize: 20000,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 디토닉 업테만 불러옴
          setSalesVatTargetUserTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list.filter(
                (subItem: any) => subItem.cmpnCode === 'DTNC',
              ),
              loading: false,
            }),
          );
        }
      })
      .catch((error: any) => {
        setSalesVatTargetUserTable(
          (pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            loading: false,
          }),
        );
      });
  };

  // 숫자를 천단위 , 추가
  const addComma = (value: string) => {
    // return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value;
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 수정 상태일 때는 기존에 저장한 데이터를 불러옴
    if (slsPrtnId) {
      // 영업 기회 상세 데이터를 불러옴
      getSlsData();
    }

    // 국가, 통화 정보를 불러옴
    getNtnCrnc();

    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    // 고객사 정보 폼 그리드 > Industry > Industry 1 셀렉트의 아이템을 초기화함
    initClientIndustry1Select();

    // 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트의 아이템을 초기화함
    initClientIndustry2Select();

    // 고객사 정보 폼 그리드 > Account > 셀렉트의 아이템을 초기화함
    initClientAccountSelect();

    // 고객사 정보 폼 그리드 > 고객사명 > 셀렉트의 아이템을 초기화함
    initClientNameSelect();

    // 고객사 정보 폼 그리드 > Market Type > 셀렉트의 아이템을 초기화함
    initMarketTypeSelect();

    // 고객사 정보 폼 그리드 > 고객사이름 > 입력을 초기화함
    initClientNameInput();

    // 고객사 정보 폼 그리드 > 고객사코드 > 입력을 초기화함
    initClientCodeInput();

    // 고객사 정보 폼 그리드 > 주소 > 입력을 초기화함
    initClientAddressInput();

    // 고객사 정보 폼 그리드 > 사업자등록번호 > 입력을 초기화함
    initClientRegNoInput();

    // 고객사 정보 폼 그리드 > 대표자명 > 입력을 초기화함
    initClientCeoInput();

    // 고객사 정보 폼 그리드 > 대표번호 > 입력을 초기화함
    initClientTelInput();

    // 고객사 정보 폼 그리드 > 매출처 > 입력을 초기화함
    initClientSellingPartnerInput();

    // 태그 정보 폼 그리드 > 태그 > 입력을 초기화함
    initClientTagInput();

    // 영업 정보 폼 그리드 > 영업기회명 > 입력을 초기화함
    initSalesOpportunityInput();

    // 영업 정보 폼 그리드 > Solution > Solution 1 셀렉트의 아이템을 초기화함
    initSalesSolution1Select();

    // 영업 정보 폼 그리드 > Solution > Solution 2 셀렉트의 아이템을 초기화함
    initSalesSolution2Select();

    // 영업 정보 폼 그리드 > 딜종류 > 셀렉트의 아이템을 초기화함
    initSalesDealTypeSelect();

    // 영업 정보 폼 그리드 > 납품구분 > 셀렉트의 아이템을 초기화함
    initSalesDeliveryTypeSelect();

    // 영업 정보 폼 그리드 > 경쟁사 > 입력을 초기화함
    initSalesCompetitorInput();

    // 영업 정보 폼 그리드 > 협력사 > 입력을 초기화함
    initSalesPartnerInput();

    // 영업 정보 폼 그리드 > 예상물량 > 입력을 초기화함
    initSalesEstimatedAmountInput();

    // 영업 정보 폼 그리드 > 필요/지원사항 > 입력을 초기화함
    initSalesNecessitySupportInput();

    // 프로젝트 정보 폼 그리드 > Proposal Submission Date > 날짜 피커를 초기화함
    initPlanProposalSubmissionDatePicker();

    // 프로젝트 정보 폼 그리드 > 수주일 > 날짜 피커를 초기화함
    initPlanEstimatedConfirmationDatePicker();

    // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 날짜 피커를 초기화함
    initPlanEstimatedDatePicker();

    // 프로젝트 정보 폼 그리드 > 기간(월) > 입력을 초기화함
    initPlanEstimatedMonthInput();

    // 프로젝트 정보 폼 그리드 > 계약규모(원) > 입력을 초기화함
    initSalesContractSizeInput();

    // 프로젝트 정보 폼 그리드 > NSR규모(원) > 입력을 초기화함
    initSalesNsrSizeInput();

    // 프로젝트 정보 폼 그리드 > NSR X 확도 > 입력을 초기화함
    initSalesNsrProbabilityInput();

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블을 초기화함
    initSalesEstimatedAmountTable();

    // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
    initSalesVatTargetUserSearchSelect();

    // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 테이블을 초기화함
    initSalesVatTargetUserTable();

    // 영업 활동 정보 폼 그리드 > 영업단계 > 입력을 초기화함
    initSalesWorkStepInput();

    // 영업 활동 정보 폼 그리드 > 확도 > 입력을 초기화함
    initSalesWorkProbabilityInput();

    // 영업 활동 정보 폼 그리드 > 제안결과 > 입력을 초기화함
    initSalesWorkProposalResultInput();

    // 영업 활동 정보 폼 그리드 > 제안결과내용 > 입력을 초기화함
    initSalesWorkProposalResultContentInput();

    // SWOT 분석 폼 그리드 > Strength > 입력을 초기화함
    initSwotStrengthInput();

    // SWOT 분석 폼 그리드 > Weakness > 입력을 초기화함
    initSwotWeaknessInput();

    // SWOT 분석 폼 그리드 > Opportunities > 입력을 초기화함
    initSwotOpportunitiesInput();

    // SWOT 분석 폼 그리드 > Threats > 입력을 초기화함
    initSwotThreatsInput();

    // 담당자(고객사) 폼 그리드 > 담당자 > 테이블을 초기화함
    initManagerClientTable();

    // 담당자(고객사) 폼 그리드 > 이름 > 입력을 초기화함
    initManagerClientNameInput();

    // 담당자(고객사) 폼 그리드 > Email > 입력을 초기화함
    initManagerClientEmailInput();

    // 담당자(고객사) 폼 그리드 > 휴대폰 > 입력을 초기화함
    initManagerClientPhoneInput();

    // 담당자(고객사) 폼 그리드 > 직급 > 입력을 초기화함
    initManagerClientPositionInput();

    // 담당자(고객사) 폼 그리드 > 부서 > 입력을 초기화함
    initManagerClientDepartmentInput();

    // 담당자(내부) 폼 그리드 > 아이디 > 입력을 초기화함
    initManagerInsideIdInput();

    // 담당자(내부) 폼 그리드 > 이름 > 입력을 초기화함
    initManagerInsideNameInput();

    // 담당자(내부) 폼 그리드 > 본부 > 입력을 초기화함
    initManagerInsideHeadquarterInput();

    // 담당자(내부) 폼 그리드 > 부서 > 입력을 초기화함
    initManagerInsideDepartmentInput();

    // 담당자(내부) 폼 그리드 > Email > 입력을 초기화함
    initManagerInsideEmailInput();

    // 담당자(내부) 폼 그리드 > 휴대폰 > 입력을 초기화함
    initManagerInsidePhoneInput();

    // 조회 권한 폼 그리드 > 대상자 > 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
    initReadAuthTargetUserSearchSelect();

    // 조회 권한 폼 그리드 > 테이블을 초기화함
    initReadAuthTable();

    // 조회 권한 폼 그리드 > 대상자 > 테이블을 초기화함
    initReadAuthTargetUserTable();

    return () => {};
  }, []);

  // 영업 기회 상세 데이터를 불러온 후 실행함
  useEffect(() => {
    if (!slsData) {
      return;
    }

    // 고객사 정보 폼 그리드 > Industry > Industry 1 셀렉트에 초기값을 적용함
    valClientIndustry1Select = slsData.ndstDntfCode;
    // setClientIndustry1Select((pre: ISelect) => ({
    //   ...pre,
    //   value: slsData.ndstDntfCode,
    // }));
    setClientIndustry1Select(slsData.ndstDntfCode);

    // 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트에 초기값을 적용함
    valClientIndustry2Select = slsData.ndstSubCode;
    // setClientIndustry2Select((pre: ISelect) => ({
    //   ...pre,
    //   value: slsData.ndstSubCode,
    // }));
    setClientIndustry2Select(slsData.ndstSubCode);
    // setTimeout(() => {
    //   valClientIndustry2Select = slsData.ndstSubCode;
    //   setClientIndustry2Select((pre: ISelect) => ({
    //     ...pre,
    //     value: slsData.ndstSubCode,
    //   }));
    // }, 500);

    // 고객사 정보 폼 그리드 > Account > 셀렉트의 아이템을 초기화함
    // setClientAccountSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsData.cntGrpCode,
    // }));
    setClientAccountSelect(slsData.cntGrpCode);

    // 고객사 정보 폼 그리드 > 고객사명 > 셀렉트의 아이템을 초기화함
    // setClientNameSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsData.bpName,
    // }));

    // 고객사 정보 폼 그리드 > Sold To Name > 입력에 초기값을 적용함
    setSoldToNameInput(slsData.soldToName || '');

    // 고객사 정보 폼 그리드 > Ship To Name > 입력에 초기값을 적용함
    setShipToNameInput(slsData.shipToName || '');

    // 고객사 정보 폼 그리드 > Bill To Name > 입력에 초기값을 적용함
    setBillToNameInput(slsData.billToName || '');

    // 고객사 정보 폼 그리드 > 고객사이름 > 입력에 초기값을 적용함
    setClientNameInput(slsData.slsPlcName || '');

    // 고객사 정보 폼 그리드 > 고객사코드 > 입력에 초기값을 적용함
    setClientCodeInput(slsData.bpCode || '');
    setRegionSelect(slsData.rgn || '');

    // 고객사 정보 폼 그리드 > Channel > 셀렉트에 초기값을 적용함
    setClientNameSelect(slsData.slsChnlCode);

    // 고객사 정보 폼 그리드 > Market Type > 입력에 초기값을 적용함
    // setMarketTypeInput(slsData.bpName || '');

    // 고객사 정보 폼 그리드 > Market Type > 셀렉트에 초기값을 적용함
    setMarketTypeSelect(slsData.mrktTypeCode);

    // 고객사 정보 폼 그리드 > 주소 > 입력에 초기값을 적용함
    setClientAddressInput(slsData.dtldDrs);

    // 고객사 정보 폼 그리드 > 사업자등록번호 > 입력에 초기값을 적용함
    setClientRegNoInput(slsData.cmpnRgstNmbr);

    // 고객사 정보 폼 그리드 > 대표자명 > 입력에 초기값을 적용함
    setClientCeoInput(slsData.rprsName);

    // 고객사 정보 폼 그리드 > 대표번호 > 입력에 초기값을 적용함
    setClientTelInput(slsData.phnNmbr);

    // 고객사 정보 폼 그리드 > 매출처 > 입력에 초기값을 적용함
    setClientSellingPartnerInput(slsData.slsPlcName);

    // 태그 정보 폼 그리드 > 태그 > 입력에 초기값을 적용함
    setClientTagInput(slsData.tag);

    // 영업 정보 폼 그리드 > 영업기회명 > 입력에 초기값을 적용함
    setSalesOpportunityInput(slsData.slsPrtnName);

    // 영업 정보 폼 그리드 > Solution > Solution 1 셀렉트에 초기값을 적용함
    valSalesSolution1Select = slsData.slsSltnCode;
    setSalesSolution1Select(slsData.slsSltnCode);
    // setSalesSolution1Select((pre: ISelect) => ({
    //   ...pre,
    //   value: slsData.slsSltnCode,
    // }));

    // 영업 정보 폼 그리드 > Solution > Solution 2 셀렉트에 초기값을 적용함
    valSalesSolution2Select = slsData.slsSubCode;
    setSalesSolution2Select(slsData.slsSubCode);
    // setSalesSolution2Select((pre: ISelect) => ({
    //   ...pre,
    //   value: slsData.slsSubCode,
    // }));
    // setTimeout(() => {
    //   valSalesSolution2Select = slsData.slsSubCode;
    //   setSalesSolution2Select((pre: ISelect) => ({
    //     ...pre,
    //     value: slsData.slsSubCode,
    //   }));
    // }, 500);

    // 영업 정보 폼 그리드 > 딜종류 > 셀렉트에 초기값을 적용함
    setSalesDealTypeSelect(slsData.slsDealCode);
    // setSalesDealTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsData.slsDealCode,
    // }));

    // 영업 정보 폼 그리드 > 납품구분 > 셀렉트에 초기값을 적용함
    setSalesDeliveryTypeSelect(slsData.slsDlvrCode);
    // setSalesDeliveryTypeSelect((pre: ISelect) => ({
    //   ...pre,
    //   value: slsData.slsDlvrCode,
    // }));

    // 영업 정보 폼 그리드 > 경쟁사 > 입력에 초기값을 적용함
    setSalesCompetitorInput(slsData.cmptName);

    // 영업 정보 폼 그리드 > 협력사 > 입력에 초기값을 적용함
    setSalesPartnerInput(slsData.prtnName);

    // 영업 정보 폼 그리드 > 예상물량 > 입력에 초기값을 적용함
    setSalesEstimatedAmountInput(slsData.xpctQnttMnt);

    // 영업 정보 폼 그리드 > Amount Currency > 셀렉트에 초기값을 적용함
    setSalesEstimatedAmountCurrencySelect(slsData.xpctQnttCrnc);

    // 영업 정보 폼 그리드 > 필요/지원사항 > 입력에 초기값을 적용함
    setSalesNecessitySupportInput(slsData.ncsrSprt);

    // 프로젝트 정보 폼 그리드 > Proposal Submission Date > 날짜 피커에 초기값을 적용함
    setPlanProposalSubmissionDatePicker(
      moment(slsData.prpsDate, 'YYYY-MM-DD').toDate(),
    );

    // 프로젝트 정보 폼 그리드 > 수주일 > 날짜 피커에 초기값을 적용함
    setPlanEstimatedConfirmationDatePicker(
      moment(slsData.xpctFxdDate, 'YYYY-MM-DD').toDate(),
    );

    // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 날짜 피커에 초기값을 적용함
    setPlanEstimatedDatePicker([
      moment(slsData.stmtStrtDate, 'YYYY-MM-DD').toDate(),
      moment(slsData.stmtCmplDate, 'YYYY-MM-DD').toDate(),
    ]);

    // 프로젝트 정보 폼 그리드 > 계약규모(원) > 입력에 초기값을 적용함
    setSalesContractSizeInput(slsData.cntrSizeMnt.replaceAll(',', ''));

    // 프로젝트 정보 폼 그리드 > Expected Contract Amount Currency > 셀렉트에 초기값을 적용함
    setSalesContractSizeCurrencySelect(slsData.cntrSizeCrnc);

    // 프로젝트 정보 폼 그리드 > NSR규모(원) > 입력에 초기값을 적용함
    setSalesNsrSizeInput(slsData.nsrSclMnt.replaceAll(',', ''));

    // 프로젝트 정보 폼 그리드 > Expected Direct Cost > 셀렉트에 초기값을 적용함
    setSalesNsrSizeCurrencySelect(slsData.nsrSclCrnc);

    // 프로젝트 정보 폼 그리드 > NSR X 확도 > 입력에 초기값을 적용함
    // if (slsData.nsrSclMnt && slsData.slsPrbbName.replace('%', '')) {
    //   let tmpSalesNsrProbabilityInput: number =
    //     (slsData.nsrSclMnt * +slsData.slsPrbbName.replace('%', '')) / 100;
    //   setSalesNsrProbabilityInput(tmpSalesNsrProbabilityInput.toString());
    // }
    setSalesNsrProbabilityInput(slsData.nsrxprb || '');

    // 프로젝트 정보 폼 그리드 > Sales VAT > 입력에 초기값을 적용함
    setSalesVatInput({
      id: slsData.slsVat,
      name: slsData.slsVatName,
    });

    // 영업 활동 정보 폼 그리드 > 영업단계 > 입력을 초기화함
    setSalesWorkStepInput(slsData.slsStgName);

    // 영업 활동 정보 폼 그리드 > 확도 > 입력을 초기화함
    setSalesWorkProbabilityInput(slsData.slsPrbbName);

    // 영업 활동 정보 폼 그리드 > 제안결과 > 입력을 초기화함
    setSalesWorkProposalResultInput(slsData.sgstRsltName);

    // 영업 활동 정보 폼 그리드 > 제안결과내용 > 입력을 초기화함
    setSalesWorkProposalResultContentInput(slsData.prpsRslt);

    // SWOT 분석 폼 그리드 > Strength > 입력을 초기화함
    setSwotStrengthInput(slsData.swotStrn);

    // SWOT 분석 폼 그리드 > Weakness > 입력을 초기화함
    setSwotWeaknessInput(slsData.swotWkns);

    // SWOT 분석 폼 그리드 > Opportunities > 입력을 초기화함
    setSwotOpportunitiesInput(slsData.swotPrtn);

    // SWOT 분석 폼 그리드 > Threats > 입력을 초기화함
    setSwotThreatsInput(slsData.swotThrt);

    // 담당자(내부) 폼 그리드 > 아이디 > 입력을 초기화함
    setManagerInsideIdInput(slsData.ntrnCntcId);

    // 담당자(내부) 폼 그리드 > 이름 > 입력을 초기화함
    setManagerInsideNameInput(slsData.name);

    // 담당자(내부) 폼 그리드 > 본부 > 입력을 초기화함
    setManagerInsideHeadquarterInput(slsData.hdqrName);

    // 담당자(내부) 폼 그리드 > 부서 > 입력을 초기화함
    setManagerInsideDepartmentInput(slsData.dprtName);

    // 담당자(내부) 폼 그리드 > Email > 입력을 초기화함
    setManagerInsideEmailInput(slsData.ml);

    // 담당자(내부) 폼 그리드 > 휴대폰 > 입력을 초기화함
    setManagerInsidePhoneInput(slsData.celPhn);

    return () => {};
  }, [slsData]);

  // 고객사 담당자 휴대전화 - 입력하기
  // useEffect(() => {
  //   if (managerClientPhoneInput.length === 10) {
  //     setManagerClientPhoneInput(managerClientPhoneInput.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
  //   }
  //   if (managerClientPhoneInput.length === 11) {
  //     setManagerClientPhoneInput(
  //       managerClientPhoneInput
  //         .replace(/-/g, '')
  //         .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
  //     );
  //   }
  //   if (managerClientPhoneInput.length === 13) {
  //     setManagerClientPhoneInput(
  //       managerClientPhoneInput
  //         .replace(/-/g, '')
  //         .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
  //     );
  //   }
  // }, [managerClientPhoneInput]);

  // // 고객사 정보 폼 그리드 > Industry > Industry 1 셀렉트에서 선택한 아이템이 변경될 때 실행함
  // useEffect(() => {
  //   // 고객사 정보 폼 그리드 > Industry > Industry 2 셀렉트의 아이템을 초기화함
  //   initClientIndustry2Select();
  //
  //   return () => {};
  // }, [clientIndustry1Select.value]);

  // 영업 정보 폼 그리드 > Solution > Solution 1 셀렉트에서 선택한 아이템이 변경될 때 실행함
  // useEffect(() => {
  //   // 영업 정보 폼 그리드 > Solution > Solution 2 셀렉트의 아이템을 초기화함
  //   initSalesSolution2Select();
  //
  //   return () => {};
  // }, [salesSolution1Select.value]);

  // 프로젝트 정보 폼 그리드 > 프로젝트기간 > 날짜 피커에서 선택한 아이템이 변경될 때 실행함
  useEffect(() => {
    if (planEstimatedDatePicker[0] === null) {
      setPlanEstimatedMonthInput('');

      return;
    }

    if (
      planEstimatedDatePicker[0] !== null &&
      planEstimatedDatePicker[1] !== null
    ) {
      // 프로젝트 시작 년월을 불러옴
      let tmpStartYear: number = +moment(planEstimatedDatePicker[0]).format(
        'YYYY',
      );
      let tmpStartMonth: number = +moment(planEstimatedDatePicker[0]).format(
        'MM',
      );

      // 프로젝트 완료 년월을 불러옴
      let tmpEndYear: number = +moment(planEstimatedDatePicker[1]).format(
        'YYYY',
      );
      let tmpEndMonth: number = +moment(planEstimatedDatePicker[1]).format(
        'MM',
      );

      // 프로젝트 기간(월)을 계산함
      let tmpBetweenMonth: number =
        (tmpEndYear - tmpStartYear) * 12 + (tmpEndMonth - tmpStartMonth) + 1;

      // 프로젝트 기간(일)을 계산함
      let tmpBetweenDay: number = moment(planEstimatedDatePicker[1]).diff(
        moment(planEstimatedDatePicker[0]),
        'days',
      );

      // 프로젝트 정보 폼 그리드 > 기간(월) > 입력에 적용함
      setPlanEstimatedMonthInput(
        `약 ${tmpBetweenMonth.toString()}개월(총 ${tmpBetweenDay.toString()}일)`,
      );
    }

    // 프로젝트 정보 폼 그리드 > 기간별예상매출금액(원) > 테이블을 초기화함
    initSalesEstimatedAmountTable();

    return () => {};
  }, [planEstimatedDatePicker]);

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (managerClientTable.column.length === 0 || slsData === null) {
      return;
    }

    // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 데이터를 불러옴
    getManagerClientTableData();

    // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 > 선택한 행을 초기화함
    initSelectedManagerClientTableRow();

    return () => {};
  }, [managerClientTable.column, slsData]);

  // 담당자(고객사) 폼 그리드 > 담당자 > 테이블 > 선택한 행이 변경될 때 실행함
  useEffect(() => {
    if (selectedManagerClientTableRow === null) {
      // 담당자(내부) 폼 그리드 > 모든 입력을 초기화함
      initManagerClientAllForm();
    } else {
      // 담당자(고객사) 폼 그리드 > 담당자 > 이름 > 입력에 적용함
      setManagerClientNameInput(selectedManagerClientTableRow.cstmMngrName);

      // 담당자(고객사) 폼 그리드 > 담당자 > 전화번호 > 입력에 적용함
      setManagerClientPhoneInput(selectedManagerClientTableRow.cstmMngrClph);

      // 담당자(고객사) 폼 그리드 > 담당자 > 이메일주소 > 입력에 적용함
      setManagerClientEmailInput(selectedManagerClientTableRow.cstmMngrMl);

      // 담당자(고객사) 폼 그리드 > 담당자 > 직급 > 입력에 적용함
      setManagerClientPositionInput(selectedManagerClientTableRow.cstmMngrPstn);

      // 담당자(고객사) 폼 그리드 > 담당자 > 부서 > 입력에 적용함
      setManagerClientDepartmentInput(
        selectedManagerClientTableRow.cstmMngrDprt,
      );
    }

    return () => {};
  }, [selectedManagerClientTableRow]);

  // 조회 권한 폼 그리드 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (readAuthTable.column.length === 0) {
      return;
    }

    // 등록일 때, 조회 권한 대상자에 기본값을 적용함
    if (slsPrtnId === '') {
      // 조회 권한 폼 그리드 > 테이블 기본값 데이터를 불러옴
      getReadAuthTableDefaultData();
    }
  }, [readAuthTable.column]);

  // 조회 권한 폼 그리드 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (
      readAuthTable.column.length === 0 ||
      slsData === null ||
      slsData.slsXpsrTrgtList === undefined
    ) {
      return;
    }

    // 조회 권한 폼 그리드 > 테이블 데이터를 불러옴
    getReadAuthTableData();

    return () => {};
  }, [readAuthTable.column, slsData]);

  // 조회 권한 폼 그리드 > 대상자 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (readAuthTargetUserTable.column.length === 0) {
      return;
    }

    // 조회 권한 폼 그리드 > 대상자 > 테이블 데이터를 불러옴
    getReadAuthTargetUserTableData();

    return () => {};
  }, [readAuthTargetUserTable.column]);

  // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (salesVatTargetUserTable.column.length === 0) {
      return;
    }

    // 프로젝트 정보 폼 그리드 > Sales VAT > 검색 폼 그리드 > 테이블 데이터를 불러옴
    getSalesVatTargetUserTableData();

    return () => {};
  }, [salesVatTargetUserTable.column]);

  /**************************** 기타 Function ****************************/
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  // 국가, 통화가 변경될 때 실행함
  useEffect(() => {
    if (currencyData.length === 0) {
      return;
    }

    let tmpCountryOptionItem: IOptionItem[] = [];
    let tmpCurrencyOptionItem: IOptionItem[] = [];

    // 등록, 수정일 경우에만 적용함
    if (!slsPrtnId || (slsPrtnId && useModifyMode)) {
      tmpCountryOptionItem.push({
        label: '선택하세요.',
        value: '',
      });

      tmpCurrencyOptionItem.push({
        label: '선택하세요.',
        value: '',
      });
    }

    // 정렬 순서를 숫자로 변경함
    currencyData.map((item: any, index: number) => {
      item.sortRdr = +item.sortRdr;
    });

    _.sortBy(currencyData, ['ntn']).map((item: any, index: number) => {
      tmpCountryOptionItem.push({
        label: `${item.iata}(${item.ntn})`,
        value: item.iata,
      });
    });

    _.sortBy(currencyData, ['sortRdr']).map((item: any, index: number) => {
      tmpCurrencyOptionItem.push({
        label: item.crncCode,
        value: item.crncCode,
      });
    });

    // 통화 단위를 중복 제거함
    tmpCurrencyOptionItem = _.uniqBy(tmpCurrencyOptionItem, 'value');

    // 고객사 정보 폼 그리드 > Region > 셀렉트의 아이템에 적용함
    setRegionSelectItem(tmpCountryOptionItem);
    // setRegionSelect((pre: ISelect) => ({
    //   ...pre,
    //   item: tmpCountryOptionItem,
    // }));

    // 영업 정보 폼 그리드 > Amount Currency > 셀렉트의 아이템에 적용함
    setSalesEstimatedAmountCurrencySelectItem(tmpCurrencyOptionItem);
    // setSalesEstimatedAmountCurrencySelect((pre: ISelect) => ({
    //   ...pre,
    //   item: tmpCurrencyOptionItem,
    // }));

    // 프로젝트 정보 폼 그리드 > Expected Contract Amount Currency > 셀렉트의 아이템에 적용함
    setSalesContractSizeCurrencySelectItem(tmpCurrencyOptionItem);
    // setSalesContractSizeCurrencySelect((pre: ISelect) => ({
    //   ...pre,
    //   item: tmpCurrencyOptionItem,
    // }));

    // 프로젝트 정보 폼 그리드 > Expected Contract Amount Currency > 셀렉트의 아이템에 적용함
    setSalesNsrSizeCurrencySelectItem(tmpCurrencyOptionItem);
    // setSalesNsrSizeCurrencySelect((pre: ISelect) => ({
    //   ...pre,
    //   item: tmpCurrencyOptionItem,
    // }));

    return () => {};
  }, [currencyData]);

  return (
    <>
      <div className="space-y-7">
        {/* 컴포넌트 모달 내용 */}
        <div className="space-y-5">
          {/* 고객사 정보 폼 그리드 */}
          <PbFormGrid id="step-1" label="고객사 정보" cols={2}>
            <PbFormGridCol label="Industry / Type" withAsterisk={true}>
              {slsPrtnId && !useModifyMode ? (
                // 조회

                <div className="flex justify-center items-center space-x-1 whitespace-nowrap">
                  <span className="mantine-ka5ttw">
                    {clientIndustry1Select}
                  </span>
                  <span className="mantine-ka5ttw">/</span>
                  <span className="mantine-ka5ttw">
                    {clientIndustry2Select}
                  </span>
                </div>
              ) : (
                // 등록, 수정

                <>
                  <Select
                    ref={clientIndustry1SelectRef}
                    onChange={handleClientIndustry1Select_onChange}
                    data={clientIndustry1SelectItem}
                    value={clientIndustry1Select}
                    readOnly={slsPrtnId && !useModifyMode ? true : false}
                    variant={
                      slsPrtnId && !useModifyMode ? 'unstyled' : 'default'
                    }
                    className="w-1/2"
                  />

                  <Select
                    ref={clientIndustry2SelectRef}
                    onChange={handleClientIndustry2Select_onChange}
                    data={clientIndustry2SelectItem}
                    value={clientIndustry2Select}
                    readOnly={slsPrtnId && !useModifyMode ? true : false}
                    variant={
                      slsPrtnId && !useModifyMode ? 'unstyled' : 'default'
                    }
                    className="w-1/2"
                  />
                </>
              )}
            </PbFormGridCol>
            <PbFormGridCol label="Division_SP" withAsterisk={true}>
              {/* 셀렉트 */}
              {/*<PbSelect*/}
              {/*  refs={clientAccountSelectRef}*/}
              {/*  onChange={handleClientAccountSelect_onChange}*/}
              {/*  data={clientAccountSelect.item}*/}
              {/*  value={clientAccountSelect.value}*/}
              {/*  setSelect={setClientAccountSelect}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
              <Select
                ref={clientAccountSelectRef}
                onChange={handleClientAccountSelect_onChange}
                data={clientAccountSelectItem}
                value={clientAccountSelect}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            {/*<PbFormGridCol label="매출처" colSpan={2}>*/}
            {/*  /!* 텍스트 입력 *!/*/}
            {/*  <TextInput*/}
            {/*    placeholder={*/}
            {/*      slsPrtnId && !useModifyMode ? '' : '매출처를 입력하세요.'*/}
            {/*    }*/}
            {/*    onChange={handleClientSellingPartnerInput_onChange}*/}
            {/*    value={clientSellingPartnerInput}*/}
            {/*    readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
            {/*    variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
            {/*    className="w-full"*/}
            {/*  />*/}
            {/*</PbFormGridCol>*/}
            <PbFormGridCol label="Client Name" withAsterisk={true}>
              {/* 텍스트 입력 */}
              <TextInput
                ref={clientNameInputRef}
                placeholder={
                  slsPrtnId && !useModifyMode ? '' : 'Client Name을 입력하세요.'
                }
                onChange={handleClientNameInput_onChange}
                value={clientNameInput}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Sold To Name">
              {/* 텍스트 입력 */}
              <TextInput
                placeholder={
                  slsPrtnId && !useModifyMode
                    ? ''
                    : 'Sold To Name을 입력하세요.'
                }
                onChange={handleSoldToNameInput_onChange}
                value={decode(soldToNameInput)}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Ship To Name">
              {/* 텍스트 입력 */}
              <TextInput
                placeholder={
                  slsPrtnId && !useModifyMode
                    ? ''
                    : 'Ship To Name을 입력하세요.'
                }
                onChange={handleShipToNameInput_onChange}
                value={decode(shipToNameInput)}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Bill To Name">
              {/* 텍스트 입력 */}
              <TextInput
                placeholder={
                  slsPrtnId && !useModifyMode
                    ? ''
                    : 'Bill To Name을 입력하세요.'
                }
                onChange={handleBillToNameInput_onChange}
                value={decode(billToNameInput)}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol
              // label="고객사 기본정보 선택"
              label="Channel"
              colSpan={2}
              withAsterisk={true}
            >
              {/* 셀렉트 */}
              {/*<PbSelect*/}
              {/*  onChange={handleClientNameSelect_onChange}*/}
              {/*  data={clientNameSelect.item}*/}
              {/*  value={clientNameSelect.value}*/}
              {/*  setSelect={setClientNameSelect}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
              <Select
                ref={clientNameSelectRef}
                onChange={handleClientNameSelect_onChange}
                data={clientNameSelectItem}
                value={clientNameSelect}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                searchable={true}
                clearable={true}
                className="w-full"
              />
              {/*<PbSelect*/}
              {/*  placeholder={*/}
              {/*    slsPrtnId && !useModifyMode*/}
              {/*      ? ''*/}
              {/*      : '고객사의 기본정보를 선택하세요.'*/}
              {/*  }*/}
              {/*  onChange={handleClientNameSelect_onChange}*/}
              {/*  data={clientNameSelect.item}*/}
              {/*  value={clientNameSelect.value}*/}
              {/*  setSelect={setClientNameSelect}*/}
              {/*  searchable={true}*/}
              {/*  clearable={true}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
            </PbFormGridCol>
            <PbFormGridCol label="Market Type" withAsterisk={true}>
              {/* 텍스트 입력 */}
              {/*<TextInput*/}
              {/*  placeholder={*/}
              {/*    slsPrtnId && !useModifyMode ? '' : 'Market Type을 입력하세요.'*/}
              {/*  }*/}
              {/*  onChange={handleMarketTypeInput_onChange}*/}
              {/*  value={marketTypeInput}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
              {/* 셀렉트 */}
              <Select
                ref={marketTypeSelectRef}
                onChange={handleMarketTypeSelect_onChange}
                data={marketTypeSelectItem}
                value={marketTypeSelect}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Region" withAsterisk={true}>
              {/* 셀렉트 */}
              <Select
                ref={regionSelectRef}
                onChange={handleRegionSelect_onChange}
                data={regionSelectItem}
                value={regionSelect}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                searchable={true}
                clearable={true}
                className="w-full"
              />
              {/*<PbSelect*/}
              {/*  onChange={handleRegionSelect_onChange}*/}
              {/*  data={regionSelect.item}*/}
              {/*  value={regionSelect.value}*/}
              {/*  setSelect={setRegionSelect}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
            </PbFormGridCol>
            {/*<PbFormGridCol label="고객사코드">*/}
            {/*  /!* 텍스트 입력 *!/*/}
            {/*  <TextInput*/}
            {/*    placeholder={*/}
            {/*      slsPrtnId && !useModifyMode ? '' : '고객사코드를 입력하세요.'*/}
            {/*    }*/}
            {/*    onChange={handleClientCodeInput_onChange}*/}
            {/*    value={clientCodeInput}*/}
            {/*    readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
            {/*    variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
            {/*    className="w-full"*/}
            {/*  />*/}
            {/*</PbFormGridCol>*/}
            <PbFormGridCol label="사업자등록번호">
              {/* 텍스트 입력 */}
              <TextInput
                placeholder={
                  slsPrtnId && !useModifyMode
                    ? ''
                    : '사업자등록번호를 입력하세요.'
                }
                onChange={handleClientRegNoInput_onChange}
                value={decode(clientRegNoInput)}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="주소">
              {/* 텍스트 입력 */}
              <TextInput
                placeholder={
                  slsPrtnId && !useModifyMode ? '' : '주소를 입력하세요.'
                }
                onChange={handleClientAddressInput_onChange}
                value={decode(clientAddressInput)}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="대표자명">
              {/* 텍스트 입력 */}
              <TextInput
                placeholder={
                  slsPrtnId && !useModifyMode ? '' : '대표자명을 입력하세요.'
                }
                onChange={handleClientCeoInput_onChange}
                value={decode(clientCeoInput)}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="대표번호">
              {/* 텍스트 입력 */}
              <TextInput
                placeholder={
                  slsPrtnId && !useModifyMode ? '' : '대표번호를 입력하세요.'
                }
                onChange={handleClientTelInput_onChange}
                value={phoneNumberformatter(clientTelInput)}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 영업 정보 폼 그리드 */}
          <PbFormGrid label="영업 정보" cols={2}>
            <PbFormGridCol label="Project Name" withAsterisk={true}>
              {/* 텍스트 입력 */}
              <TextInput
                ref={salesOpportunityInputRef}
                placeholder={
                  slsPrtnId && !useModifyMode
                    ? ''
                    : 'Project Name을 입력하세요.'
                }
                onChange={handleSalesOpportunityInput_onChange}
                value={decode(salesOpportunityInput)}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Product" withAsterisk={true}>
              {slsPrtnId && !useModifyMode ? (
                // 조회

                <div className="flex justify-center items-center space-x-1 whitespace-nowrap">
                  <span className="mantine-ka5ttw">{salesSolution1Select}</span>
                  <span className="mantine-ka5ttw">/</span>
                  <span className="mantine-ka5ttw">{salesSolution2Select}</span>
                </div>
              ) : (
                // 등록, 수정

                <>
                  {/* 셀렉트 */}
                  <Select
                    ref={salesSolution1SelectRef}
                    onChange={handleSalesSolution1Select_onChange}
                    data={salesSolution1SelectItem}
                    value={salesSolution1Select}
                    readOnly={slsPrtnId && !useModifyMode ? true : false}
                    variant={
                      slsPrtnId && !useModifyMode ? 'unstyled' : 'default'
                    }
                    className="w-1/2"
                  />

                  {/* 셀렉트 */}
                  <Select
                    ref={salesSolution2SelectRef}
                    onChange={handleSalesSolution2Select_onChange}
                    data={salesSolution2SelectItem}
                    value={salesSolution2Select}
                    readOnly={slsPrtnId && !useModifyMode ? true : false}
                    variant={
                      slsPrtnId && !useModifyMode ? 'unstyled' : 'default'
                    }
                    className="w-1/2"
                  />
                </>
              )}
            </PbFormGridCol>
            <PbFormGridCol label="Deal Type">
              {/* 셀렉트 */}
              <Select
                onChange={handleSalesDealTypeSelect_onChange}
                data={salesDealTypeSelectItem}
                value={salesDealTypeSelect}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
              {/*<PbSelect*/}
              {/*  onChange={handleSalesDealTypeSelect_onChange}*/}
              {/*  data={salesDealTypeSelect.item}*/}
              {/*  value={salesDealTypeSelect.value}*/}
              {/*  setSelect={setSalesDealTypeSelect}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
            </PbFormGridCol>
            <PbFormGridCol label="Service Type" withAsterisk={true}>
              {/* 셀렉트 */}
              <Select
                ref={salesDeliveryTypeSelectRef}
                onChange={handleSalesDeliveryTypeSelect_onChange}
                data={salesDeliveryTypeSelectItem}
                value={salesDeliveryTypeSelect}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
              {/*<PbSelect*/}
              {/*  onChange={handleSalesDeliveryTypeSelect_onChange}*/}
              {/*  data={salesDeliveryTypeSelect.item}*/}
              {/*  value={salesDeliveryTypeSelect.value}*/}
              {/*  setSelect={setSalesDeliveryTypeSelect}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-full"*/}
              {/*/>*/}
            </PbFormGridCol>
            <PbFormGridCol label="경쟁사" withAsterisk={true}>
              {/* 텍스트 입력 */}
              <TextInput
                ref={salesCompetitorInputRef}
                placeholder={
                  slsPrtnId && !useModifyMode ? '' : '경쟁사를 입력하세요.'
                }
                onChange={handleSalesCompetitorInput_onChange}
                value={decode(salesCompetitorInput)}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Amount / Currency" withAsterisk={true}>
              {/* 텍스트 입력 */}
              {/*<TextInput*/}
              {/*  placeholder={*/}
              {/*    slsPrtnId && !useModifyMode ? '' : 'Amount를 입력하세요.'*/}
              {/*  }*/}
              {/*  onChange={handleSalesEstimatedAmountInput_onChange}*/}
              {/*  value={decode(salesEstimatedAmountInput)}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-1/2"*/}
              {/*/>*/}

              {slsPrtnId && !useModifyMode ? (
                // 조회

                <div className="flex justify-center items-center space-x-1">
                  <span className="mantine-ka5ttw">
                    {(+(salesEstimatedAmountInput || '0')).toLocaleString()}
                  </span>
                  <span className="mantine-ka5ttw">
                    {salesEstimatedAmountCurrencySelect}
                  </span>
                </div>
              ) : (
                // 등록, 수정

                <>
                  <NumberInput
                    ref={salesEstimatedAmountInputRef}
                    placeholder={
                      slsPrtnId && !useModifyMode ? '' : 'Amount를 입력하세요.'
                    }
                    onChange={handleSalesEstimatedAmountInput_onChange}
                    value={+salesEstimatedAmountInput}
                    readOnly={slsPrtnId && !useModifyMode ? true : false}
                    variant={
                      slsPrtnId && !useModifyMode ? 'unstyled' : 'default'
                    }
                    parser={(value: string) => value.replace(/\$\s?|(,*)/g, '')}
                    formatter={(value: string) =>
                      !Number.isNaN(parseFloat(value))
                        ? value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                        : ''
                    }
                    className="w-1/2"
                  />

                  {/* 셀렉트 */}
                  <Select
                    ref={salesEstimatedAmountCurrencySelectRef}
                    onChange={handleSalesEstimatedAmountCurrencySelect_onChange}
                    data={salesEstimatedAmountCurrencySelectItem}
                    value={salesEstimatedAmountCurrencySelect}
                    readOnly={slsPrtnId && !useModifyMode ? true : false}
                    variant={
                      slsPrtnId && !useModifyMode ? 'unstyled' : 'default'
                    }
                    searchable={true}
                    clearable={true}
                    className="w-1/2"
                  />
                </>
              )}
              {/*<PbSelect*/}
              {/*  onChange={handleSalesEstimatedAmountCurrencySelect_onChange}*/}
              {/*  data={salesEstimatedAmountCurrencySelect.item}*/}
              {/*  value={salesEstimatedAmountCurrencySelect.value}*/}
              {/*  setSelect={setSalesEstimatedAmountCurrencySelect}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-1/2"*/}
              {/*/>*/}
            </PbFormGridCol>
            <PbFormGridCol label="Deal Overview" className="h-62" colSpan={2}>
              {/* 텍스트 영역 */}
              <Textarea
                placeholder={
                  slsPrtnId && !useModifyMode
                    ? ''
                    : '최대한 상세히 입력해 주세요.'
                }
                onChange={handleSalesPartnerInput_onChange}
                value={decode(salesPartnerInput)}
                minRows={10}
                maxLength={1024}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Support / Decision Required" colSpan={2}>
              {/* 텍스트 영역 */}
              <Textarea
                placeholder={
                  slsPrtnId && !useModifyMode
                    ? ''
                    : '거래 필요 지원사항을 입력해 주세요.'
                }
                onChange={handleSalesNecessitySupportInput_onChange}
                value={decode(salesNecessitySupportInput)}
                minRows={10}
                maxLength={1024}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 프로젝트 정보 폼 그리드 */}
          <PbFormGrid label="프로젝트 정보" cols={2}>
            <PbFormGridCol label="Proposal Submission Date">
              {/* 날짜 피커 */}
              <DatePickerInput
                placeholder={
                  slsPrtnId && !useModifyMode
                    ? ''
                    : 'Proposal Submission Date를 선택하세요.'
                }
                onChange={handlePlanProposalSubmissionDatePicker_onChange}
                value={planProposalSubmissionDatePicker}
                valueFormat="YYYY.MM.DD"
                locale="ko"
                firstDayOfWeek={0}
                clearable={true}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Expected Order Date">
              {/* 날짜 피커 */}
              <DatePickerInput
                placeholder={
                  slsPrtnId && !useModifyMode
                    ? ''
                    : 'Expected Order Date를 선택하세요.'
                }
                onChange={handlePlanEstimatedConfirmationDatePicker_onChange}
                value={planEstimatedConfirmationDatePicker}
                valueFormat="YYYY.MM.DD"
                locale="ko"
                firstDayOfWeek={0}
                clearable={true}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="프로젝트기간" withAsterisk={true}>
              {/* 날짜 피커 */}
              <DatePickerInput
                type="range"
                placeholder={
                  slsPrtnId && !useModifyMode ? '' : '기간을 선택하세요.'
                }
                onChange={handlePlanEstimatedDatePicker_onChange}
                value={planEstimatedDatePicker}
                valueFormat="YYYY.MM.DD"
                locale="ko"
                firstDayOfWeek={0}
                clearable={true}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="plan-estimated-date-picker w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Expected Contract Amount / Currency">
              {/* 숫자 입력 */}
              {/*<NumberInput*/}
              {/*  placeholder={*/}
              {/*    slsPrtnId && !useModifyMode*/}
              {/*      ? ''*/}
              {/*      : 'Expected Contract Amount를 입력하세요.'*/}
              {/*  }*/}
              {/*  onChange={handleSalesContractSizeInput_onChange}*/}
              {/*  value={+salesContractSizeInput}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  formatter={(value) =>*/}
              {/*    !Number.isNaN(parseFloat(value))*/}
              {/*      ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')*/}
              {/*      : ''*/}
              {/*  }*/}
              {/*  className="w-1/2"*/}
              {/*/>*/}

              {/* 텍스트 입력 */}
              {/*<TextInput*/}
              {/*  placeholder={*/}
              {/*    slsPrtnId && !useModifyMode*/}
              {/*      ? ''*/}
              {/*      : 'Expected Contract Amount를 입력하세요.'*/}
              {/*  }*/}
              {/*  onChange={handleSalesContractSizeInput_onChange}*/}
              {/*  value={salesContractSizeInput}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-1/2"*/}
              {/*/>*/}

              {slsPrtnId && !useModifyMode ? (
                // 조회

                <div className="flex justify-center items-center space-x-1">
                  <span className="mantine-ka5ttw">
                    {(+(salesContractSizeInput || '0')).toLocaleString()}
                  </span>
                  <span className="mantine-ka5ttw">
                    {salesContractSizeCurrencySelect}
                  </span>
                </div>
              ) : (
                // 등록, 수정

                <>
                  <NumberInput
                    placeholder={
                      slsPrtnId && !useModifyMode
                        ? ''
                        : 'Expected Contract Amount를 입력하세요.'
                    }
                    onChange={handleSalesContractSizeInput_onChange}
                    value={+salesContractSizeInput}
                    readOnly={slsPrtnId && !useModifyMode ? true : false}
                    variant={
                      slsPrtnId && !useModifyMode ? 'unstyled' : 'default'
                    }
                    parser={(value: string) => value.replace(/\$\s?|(,*)/g, '')}
                    formatter={(value: string) =>
                      !Number.isNaN(parseFloat(value))
                        ? `${value}`.replace(
                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                            ',',
                          )
                        : ''
                    }
                    className="w-1/2"
                  />

                  {/* 셀렉트 */}
                  <Select
                    onChange={handleSalesContractSizeCurrencySelect_onChange}
                    data={salesContractSizeCurrencySelectItem}
                    value={salesContractSizeCurrencySelect}
                    readOnly={slsPrtnId && !useModifyMode ? true : false}
                    variant={
                      slsPrtnId && !useModifyMode ? 'unstyled' : 'default'
                    }
                    searchable={true}
                    clearable={true}
                    className="w-1/2"
                  />
                </>
              )}
              {/*<PbSelect*/}
              {/*  onChange={handleSalesContractSizeCurrencySelect_onChange}*/}
              {/*  data={salesContractSizeCurrencySelect.item}*/}
              {/*  value={salesContractSizeCurrencySelect.value}*/}
              {/*  setSelect={setSalesContractSizeCurrencySelect}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-1/2"*/}
              {/*/>*/}
            </PbFormGridCol>
            <PbFormGridCol label="기간(개월)">
              {/* 숫자 입력 */}
              {/*<NumberInput*/}
              {/*  value={+planEstimatedMonthInput}*/}
              {/*  readOnly={true}*/}
              {/*  variant="unstyled"*/}
              {/*  className="w-full"*/}
              {/*/>*/}
              {/* 텍스트 입력 */}
              <TextInput
                value={planEstimatedMonthInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Expected Direct Cost / Currency">
              {/* 숫자 입력 */}
              {/*<NumberInput*/}
              {/*  placeholder={*/}
              {/*    slsPrtnId && !useModifyMode ? '' : 'NSR규모를 입력하세요.'*/}
              {/*  }*/}
              {/*  onChange={handleSalesNsrSizeInput_onChange}*/}
              {/*  value={+salesNsrSizeInput}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  formatter={(value) =>*/}
              {/*    !Number.isNaN(parseFloat(value))*/}
              {/*      ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')*/}
              {/*      : ''*/}
              {/*  }*/}
              {/*  className="w-full"*/}
              {/*/>*/}

              {/* 텍스트 입력 */}
              {/*<TextInput*/}
              {/*  placeholder={*/}
              {/*    slsPrtnId && !useModifyMode*/}
              {/*      ? ''*/}
              {/*      : 'Expected Direct Cost를 입력하세요.'*/}
              {/*  }*/}
              {/*  onChange={handleSalesNsrSizeInput_onChange}*/}
              {/*  value={salesNsrSizeInput}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-1/2"*/}
              {/*/>*/}

              {slsPrtnId && !useModifyMode ? (
                // 조회

                <div className="flex justify-center items-center space-x-1">
                  <span className="mantine-ka5ttw">
                    {(+(salesNsrSizeInput || '0')).toLocaleString()}
                  </span>
                  <span className="mantine-ka5ttw">
                    {salesNsrSizeCurrencySelect}
                  </span>
                </div>
              ) : (
                // 등록, 수정

                <>
                  <NumberInput
                    placeholder={
                      slsPrtnId && !useModifyMode
                        ? ''
                        : 'Expected Direct Cost를 입력하세요.'
                    }
                    onChange={handleSalesNsrSizeInput_onChange}
                    value={+salesNsrSizeInput}
                    readOnly={slsPrtnId && !useModifyMode ? true : false}
                    variant={
                      slsPrtnId && !useModifyMode ? 'unstyled' : 'default'
                    }
                    parser={(value: string) => value.replace(/\$\s?|(,*)/g, '')}
                    formatter={(value: string) =>
                      !Number.isNaN(parseFloat(value))
                        ? `${value}`.replace(
                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                            ',',
                          )
                        : ''
                    }
                    className="w-1/2"
                  />

                  {/* 셀렉트 */}
                  <Select
                    onChange={handleSalesNsrSizeCurrencySelect_onChange}
                    data={salesNsrSizeCurrencySelectItem}
                    value={salesNsrSizeCurrencySelect}
                    readOnly={slsPrtnId && !useModifyMode ? true : false}
                    variant={
                      slsPrtnId && !useModifyMode ? 'unstyled' : 'default'
                    }
                    searchable={true}
                    clearable={true}
                    className="w-1/2"
                  />
                </>
              )}
              {/*<PbSelect*/}
              {/*  onChange={handleSalesNsrSizeCurrencySelect_onChange}*/}
              {/*  data={salesNsrSizeCurrencySelect.item}*/}
              {/*  value={salesNsrSizeCurrencySelect.value}*/}
              {/*  setSelect={setSalesNsrSizeCurrencySelect}*/}
              {/*  readOnly={slsPrtnId && !useModifyMode ? true : false}*/}
              {/*  variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}*/}
              {/*  className="w-1/2"*/}
              {/*/>*/}
            </PbFormGridCol>
            <PbFormGridCol label="NSR X 확도" colSpan={2}>
              {/* 숫자 입력 */}
              <NumberInput
                value={+salesNsrProbabilityInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
            {/* 기간별예상매출금액(원) 폼 그리드 컬럼 */}
            {/* 조회 상태 */}
            {slsPrtnId && !useModifyMode && (
              <PbFormGridCol
                label="기간별예상매출금액(원)"
                colSpan={2}
                withAsterisk={true}
                className="h-60"
              >
                <div className="w-full space-y-3">
                  {/* 테이블 */}
                  <div className="w-full h-56">
                    <PbAgGridReact
                      refs={salesEstimatedAmountTableRef}
                      columnDefs={salesEstimatedAmountTable.column}
                      rowData={salesEstimatedAmountTable.data}
                      defaultColDef={{
                        resizable: true,
                        sortable: true,
                        wrapHeaderText: false,
                        autoHeaderHeight: true,
                      }}
                      rowSelection="single"
                      sizeColumnsToFit={false}
                    />
                  </div>

                  {/* 테이블 */}
                  {/*<div className="w-full h-56">*/}
                  {/*  <AgGridReact*/}
                  {/*    columnDefs={salesEstimatedAmountTable.column}*/}
                  {/*    rowData={salesEstimatedAmountTable.data}*/}
                  {/*    defaultColDef={{*/}
                  {/*      resizable: true,*/}
                  {/*      sortable: true,*/}
                  {/*      wrapHeaderText: false,*/}
                  {/*      autoHeaderHeight: true,*/}
                  {/*      suppressSizeToFit: false,*/}
                  {/*    }}*/}
                  {/*    onGridReady={(event) => event.api.sizeColumnsToFit()}*/}
                  {/*    rowSelection="single"*/}
                  {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
                  {/*    className="ag-theme-alpine"*/}
                  {/*  ></AgGridReact>*/}
                  {/*</div>*/}
                </div>
              </PbFormGridCol>
            )}
            {/* 기간별예상매출금액(원) 폼 그리드 컬럼 */}
            {/* 추가, 수정 상태 */}
            {(!slsPrtnId || useModifyMode) && (
              <PbFormGridCol
                label="기간별예상매출금액(원)"
                colSpan={2}
                withAsterisk={true}
                className="h-60"
              >
                <div className="w-full space-y-3">
                  {/* 테이블 */}
                  <div className="w-full h-56">
                    <PbAgGridReact
                      columnDefs={salesEstimatedAmountTable.column}
                      rowData={salesEstimatedAmountTable.data}
                      onCellValueChanged={
                        handleSalesEstimatedAmountTable_onCellValueChanged
                      }
                      defaultColDef={{
                        resizable: true,
                        sortable: true,
                        editable: true,
                        wrapHeaderText: false,
                        autoHeaderHeight: true,
                      }}
                      rowSelection="single"
                      sizeColumnsToFit={false}
                    />
                  </div>
                </div>
              </PbFormGridCol>
            )}
            <PbFormGridCol label="Sales VAT" colSpan={2} withAsterisk={true}>
              <div className="flex justify-center items-center space-x-3">
                <div>
                  {salesVatInput.name ||
                    (!slsPrtnId || useModifyMode ? '선택하세요.' : '없음')}
                </div>

                {/* 버튼 */}
                {(!slsPrtnId || useModifyMode) && (
                  <Popover
                    opened={visibleSalesVatTargetUser}
                    onClose={() => setVisibleSalesVatTargetUser(false)}
                    position="top-end"
                    transitionProps={{ transition: 'pop' }}
                    withArrow={false}
                    shadow="xl"
                    zIndex={100}
                    offset={10}
                  >
                    <Popover.Target>
                      <Button
                        ref={salesVatInputRef}
                        onClick={() => setVisibleSalesVatTargetUser(true)}
                        color="indigo"
                        radius="xl"
                      >
                        직원 선택
                      </Button>
                    </Popover.Target>

                    <Popover.Dropdown className="border-1 !border-gray-500">
                      <PbSection label="디토닉 직원 선택">
                        <div className="w-200 space-y-3">
                          {/* 검색 폼 그리드 */}
                          <PbFormGrid cols={1}>
                            <PbFormGridCol label="검색">
                              {/* 셀렉트 */}
                              <Select
                                onChange={
                                  handleSalesVatTargetUserSearchSelect_onChange
                                }
                                data={salesVatTargetUserSearchSelectItem}
                                value={salesVatTargetUserSearchSelect}
                                className="w-1/3"
                              />
                              {/*<PbSelect*/}
                              {/*  onChange={*/}
                              {/*    handleSalesVatTargetUserSearchSelect_onChange*/}
                              {/*  }*/}
                              {/*  data={salesVatTargetUserSearchSelect.item}*/}
                              {/*  value={salesVatTargetUserSearchSelect.value}*/}
                              {/*  setSelect={setSalesVatTargetUserSearchSelect}*/}
                              {/*  className="w-1/3"*/}
                              {/*/>*/}

                              {/* 텍스트 입력 */}
                              <TextInput
                                placeholder="검색어를 입력하세요."
                                onChange={
                                  handleSalesVatTargetUserSearchInput_onChange
                                }
                                onKeyUp={
                                  handleSalesVatTargetUserSearchInput_onKeyUp
                                }
                                value={salesVatTargetUserSearchInput}
                                className="w-1/3"
                              />

                              {/* 버튼 */}
                              <div className="flex justify-center items-center space-x-2">
                                {/* 버튼 */}
                                <Button
                                  color="indigo"
                                  radius="xl"
                                  onClick={
                                    handleSalesVatTargetUserSearchButton_onChange
                                  }
                                >
                                  검색
                                </Button>
                              </div>
                            </PbFormGridCol>
                          </PbFormGrid>

                          {/* 테이블 */}
                          <div className="w-full h-88">
                            <PbAgGridReact
                              refs={salesVatTargetUserTableRef}
                              columnDefs={salesVatTargetUserTable.column}
                              rowData={salesVatTargetUserTable.data}
                              onRowDoubleClicked={
                                handleSalesVatTargetUserApplyButton_onClick
                              }
                              defaultColDef={{
                                resizable: true,
                                sortable: true,
                                wrapHeaderText: false,
                                autoHeaderHeight: true,
                              }}
                              rowSelection="single"
                              sizeColumnsToFit={true}
                            />
                          </div>

                          <div className="flex justify-end items-center space-x-2 hidden">
                            {/* 버튼 */}
                            <Button
                              color="indigo"
                              radius="xl"
                              onClick={
                                handleSalesVatTargetUserApplyButton_onClick
                              }
                            >
                              적용
                            </Button>
                          </div>
                        </div>
                      </PbSection>
                    </Popover.Dropdown>
                  </Popover>
                )}
              </div>
            </PbFormGridCol>
          </PbFormGrid>

          {/* 태그 정보 폼 그리드 */}
          <PbFormGrid id="step-2" label="검색을 위한 태그" cols={2}>
            <PbFormGridCol label="태그명" colSpan={2} className="h-36">
              {/* 텍스트 영역 */}
              <Textarea
                placeholder={
                  slsPrtnId && !useModifyMode ? '' : '태그명을 입력하세요.'
                }
                onChange={handleClientTagInput_onChange}
                value={decode(clientTagInput)}
                minRows={5}
                readOnly={slsPrtnId && !useModifyMode ? true : false}
                variant={slsPrtnId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 영업 활동 정보 폼 그리드 */}
          <PbFormGrid
            id="step-3"
            label="영업 활동 정보"
            cols={2}
            className={!slsPrtnId ? 'hidden' : ''}
          >
            <PbFormGridCol label="영업단계">
              {/* 텍스트 입력 */}
              <TextInput
                value={salesWorkStepInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="확도">
              {/* 텍스트 입력 */}
              <TextInput
                value={salesWorkProbabilityInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
            {salesWorkStepInput === 'DECIDED' && (
              <>
                <PbFormGridCol label="제안결과">
                  {/* 텍스트 입력 */}
                  <TextInput
                    value={salesWorkProposalResultInput}
                    readOnly={true}
                    variant="unstyled"
                    className="w-full"
                  />
                </PbFormGridCol>
                <PbFormGridCol label="제안결과내용">
                  {/* 텍스트 입력 */}
                  <TextInput
                    value={salesWorkProposalResultContentInput}
                    readOnly={true}
                    variant="unstyled"
                    className="w-full"
                  />
                </PbFormGridCol>
              </>
            )}
          </PbFormGrid>

          {/* SWOT 분석 폼 그리드 */}
          <PbFormGrid
            label="SWOT 분석"
            cols={2}
            className={!slsPrtnId ? 'hidden' : ''}
          >
            <PbFormGridCol label="Strength">
              {/* 텍스트 입력 */}
              <TextInput
                value={swotStrengthInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Weakness">
              {/* 텍스트 입력 */}
              <TextInput
                value={swotWeaknessInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Opportunities">
              {/* 텍스트 입력 */}
              <TextInput
                value={swotOpportunitiesInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="Threats">
              {/* 텍스트 입력 */}
              <TextInput
                value={swotThreatsInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 담당자(고객사) 폼 그리드 */}
          {/* 조회 상태 */}
          {/*{slsPrtnId && !useModifyMode && (*/}
          {/*  <PbFormGrid label="담당자(고객사)" cols={1}>*/}
          {/*    <PbFormGridCol label="담당자" className="h-84">*/}
          {/*      <div className="w-full space-y-3">*/}
          {/*        /!* 테이블 *!/*/}
          {/*        <div className="w-full h-80">*/}
          {/*          <PbAgGridReact*/}
          {/*            columnDefs={managerClientTable.column}*/}
          {/*            rowData={managerClientTable.data}*/}
          {/*            defaultColDef={{*/}
          {/*              resizable: true,*/}
          {/*              sortable: true,*/}
          {/*              wrapHeaderText: false,*/}
          {/*              autoHeaderHeight: true,*/}
          {/*            }}*/}
          {/*            rowSelection="single"*/}
          {/*            sizeColumnsToFit={true}*/}
          {/*          />*/}
          {/*        </div>*/}

          {/*        /!* 테이블 *!/*/}
          {/*        /!*<div className="w-full h-80">*!/*/}
          {/*        /!*  <AgGridReact*!/*/}
          {/*        /!*    columnDefs={managerClientTable.column}*!/*/}
          {/*        /!*    rowData={managerClientTable.data}*!/*/}
          {/*        /!*    defaultColDef={{*!/*/}
          {/*        /!*      resizable: true,*!/*/}
          {/*        /!*      sortable: true,*!/*/}
          {/*        /!*      wrapHeaderText: false,*!/*/}
          {/*        /!*      autoHeaderHeight: true,*!/*/}
          {/*        /!*      suppressSizeToFit: true,*!/*/}
          {/*        /!*    }}*!/*/}
          {/*        /!*    rowSelection="single"*!/*/}
          {/*        /!*    overlayNoRowsTemplate="데이터가 없습니다."*!/*/}
          {/*        /!*    className="ag-theme-alpine"*!/*/}
          {/*        /!*  ></AgGridReact>*!/*/}
          {/*        /!*</div>*!/*/}
          {/*      </div>*/}
          {/*    </PbFormGridCol>*/}
          {/*  </PbFormGrid>*/}
          {/*)}*/}

          {/* 담당자(고객사) 폼 그리드 */}
          {/* 추가, 수정 상태 */}
          {/*{(!slsPrtnId || useModifyMode) && (*/}
          {/*  <PbFormGrid label="담당자(고객사)" cols={1}>*/}
          {/*    <PbFormGridCol label="담당자" className="h-168">*/}
          {/*      <div className="w-full space-y-3">*/}
          {/*        /!* 버튼 *!/*/}
          {/*        <div className="flex justify-end items-center">*/}
          {/*          /!* 버튼 *!/*/}
          {/*          <Button*/}
          {/*            color="indigo"*/}
          {/*            radius="xl"*/}
          {/*            onClick={handleAddManagerClientButton_onClick}*/}
          {/*          >*/}
          {/*            담당자 추가*/}
          {/*          </Button>*/}
          {/*        </div>*/}

          {/*        /!* 테이블 *!/*/}
          {/*        <div className="w-full h-80">*/}
          {/*          <PbAgGridReact*/}
          {/*            columnDefs={managerClientTable.column}*/}
          {/*            rowData={managerClientTable.data}*/}
          {/*            onRowDragEnd={handleManagerClientTable_onRowDragEnd}*/}
          {/*            onRowClicked={handleManagerClientTable_onRowClicked}*/}
          {/*            rowDragManaged={true}*/}
          {/*            animateRows={true}*/}
          {/*            defaultColDef={{*/}
          {/*              resizable: true,*/}
          {/*              sortable: true,*/}
          {/*              wrapHeaderText: false,*/}
          {/*              autoHeaderHeight: true,*/}
          {/*            }}*/}
          {/*            rowSelection="single"*/}
          {/*            sizeColumnsToFit={true}*/}
          {/*          />*/}
          {/*        </div>*/}

          {/*        /!* 테이블 *!/*/}
          {/*        /!*<div className="w-full h-80">*!/*/}
          {/*        /!*  <AgGridReact*!/*/}
          {/*        /!*    columnDefs={managerClientTable.column}*!/*/}
          {/*        /!*    rowData={managerClientTable.data}*!/*/}
          {/*        /!*    onRowDragEnd={handleManagerClientTable_onRowDragEnd}*!/*/}
          {/*        /!*    onRowClicked={handleManagerClientTable_onRowClicked}*!/*/}
          {/*        /!*    defaultColDef={{*!/*/}
          {/*        /!*      resizable: true,*!/*/}
          {/*        /!*      sortable: true,*!/*/}
          {/*        /!*      wrapHeaderText: false,*!/*/}
          {/*        /!*      autoHeaderHeight: true,*!/*/}
          {/*        /!*      suppressSizeToFit: true,*!/*/}
          {/*        /!*    }}*!/*/}
          {/*        /!*    rowDragManaged={true}*!/*/}
          {/*        /!*    animateRows={true}*!/*/}
          {/*        /!*    rowSelection="single"*!/*/}
          {/*        /!*    overlayNoRowsTemplate="데이터가 없습니다."*!/*/}
          {/*        /!*    className="ag-theme-alpine"*!/*/}
          {/*        /!*  ></AgGridReact>*!/*/}
          {/*        /!*</div>*!/*/}

          {/*        /!* 담당자(고객사) 폼 그리드 *!/*/}
          {/*        <PbFormGrid label="담당자 편집" cols={2}>*/}
          {/*          <PbFormGridCol label="이름" withAsterisk={true}>*/}
          {/*            /!* 텍스트 입력 *!/*/}
          {/*            <TextInput*/}
          {/*              placeholder="이름을 입력하세요."*/}
          {/*              onChange={handleManagerClientNameInput_onChange}*/}
          {/*              value={managerClientNameInput}*/}
          {/*              className="w-full"*/}
          {/*            />*/}
          {/*          </PbFormGridCol>*/}
          {/*          <PbFormGridCol label="직급">*/}
          {/*            /!* 텍스트 입력 *!/*/}
          {/*            <TextInput*/}
          {/*              placeholder="직급을 입력하세요."*/}
          {/*              onChange={handleManagerClientPositionInput_onChange}*/}
          {/*              value={managerClientPositionInput}*/}
          {/*              className="w-full"*/}
          {/*            />*/}
          {/*          </PbFormGridCol>*/}
          {/*          <PbFormGridCol label="부서">*/}
          {/*            /!* 텍스트 입력 *!/*/}
          {/*            <TextInput*/}
          {/*              placeholder="부서를 입력하세요."*/}
          {/*              onChange={handleManagerClientDepartmentInput_onChange}*/}
          {/*              value={managerClientDepartmentInput}*/}
          {/*              className="w-full"*/}
          {/*            />*/}
          {/*          </PbFormGridCol>*/}
          {/*          <PbFormGridCol label="전화번호">*/}
          {/*            /!* 텍스트 입력 *!/*/}
          {/*            <TextInput*/}
          {/*              placeholder="전화번호를 입력하세요."*/}
          {/*              onChange={handleManagerClientPhoneInput_onChange}*/}
          {/*              value={phoneNumberformatter(managerClientPhoneInput)}*/}
          {/*              className="w-full"*/}
          {/*            />*/}
          {/*          </PbFormGridCol>*/}
          {/*          <PbFormGridCol label="이메일주소" colSpan={2}>*/}
          {/*            /!* 텍스트 입력 *!/*/}
          {/*            <TextInput*/}
          {/*              placeholder="이메일주소를 입력하세요."*/}
          {/*              onChange={handleManagerClientEmailInput_onChange}*/}
          {/*              value={managerClientEmailInput}*/}
          {/*              className="w-full"*/}
          {/*            />*/}
          {/*          </PbFormGridCol>*/}
          {/*        </PbFormGrid>*/}

          {/*        /!* 버튼 *!/*/}
          {/*        <div className="flex justify-end items-center space-x-2">*/}
          {/*          /!* 버튼 *!/*/}
          {/*          {selectedManagerClientTableRow !== null && (*/}
          {/*            <Button*/}
          {/*              variant="outline"*/}
          {/*              color="pink"*/}
          {/*              radius="xl"*/}
          {/*              onClick={handleRemoveManagerClientButton_onClick}*/}
          {/*            >*/}
          {/*              삭제*/}
          {/*            </Button>*/}
          {/*          )}*/}

          {/*          /!* 버튼 *!/*/}
          {/*          <Button*/}
          {/*            color="indigo"*/}
          {/*            radius="xl"*/}
          {/*            onClick={handleSaveManagerClientButton_onClick}*/}
          {/*          >*/}
          {/*            저장*/}
          {/*          </Button>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </PbFormGridCol>*/}
          {/*  </PbFormGrid>*/}
          {/*)}*/}

          {/* 담당자(고객사) 섹션 */}
          <PbSection
            id="step-4"
            label="담당자(고객사)"
            rightForm={
              <>
                {/* 버튼 */}
                {/* 추가, 수정 상태 */}
                {(!slsPrtnId || useModifyMode) && (
                  <div className="flex justify-end items-center space-x-2">
                    {/* 버튼 */}
                    <Button
                      variant="outline"
                      color="pink"
                      radius="xl"
                      onClick={handleManagerClientDeleteButton_onClick}
                    >
                      전체 삭제
                    </Button>

                    {/* 버튼 */}
                    <Button
                      color="pink"
                      radius="xl"
                      onClick={handleManagerClientSelectedDeleteButton_onClick}
                    >
                      선택 삭제
                    </Button>

                    {/* 버튼 */}
                    <Button
                      onClick={handleManagerClientAddButton_onClick}
                      color="indigo"
                      radius="xl"
                    >
                      추가
                    </Button>
                  </div>
                )}
              </>
            }
          >
            <div className="space-y-3">
              {/* 테이블 */}
              <div className="w-full h-80">
                <PbAgGridReact
                  refs={managerClientTableRef}
                  columnDefs={managerClientTable.column}
                  rowData={managerClientTable.data}
                  onRowClicked={handleManagerClientTable_onRowClicked}
                  onRowDoubleClicked={
                    handleManagerClientTable_onRowDoubleClicked
                  }
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="multiple"
                  sizeColumnsToFit={true}
                />
              </div>
            </div>
          </PbSection>

          {/* 담당자(내부) 폼 그리드 */}
          <PbFormGrid label="담당자(내부)" cols={2}>
            <PbFormGridCol label="아이디">
              {/* 텍스트 입력 */}
              <TextInput
                value={managerInsideIdInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />

              {/* 버튼 */}
              {(!slsPrtnId || useModifyMode) && (
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleManagerButton_onClick}
                >
                  담당자 변경
                </Button>
              )}
            </PbFormGridCol>
            <PbFormGridCol label="이름">
              {/* 텍스트 입력 */}
              <TextInput
                value={managerInsideNameInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="본부">
              {/* 텍스트 입력 */}
              <TextInput
                value={managerInsideHeadquarterInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="부서">
              {/* 텍스트 입력 */}
              <TextInput
                value={managerInsideDepartmentInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="E-mail">
              {/* 텍스트 입력 */}
              <TextInput
                value={managerInsideEmailInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="휴대폰번호">
              {/* 텍스트 입력 */}
              <TextInput
                value={phoneNumberformatter(managerInsidePhoneInput)}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 조회 권한 폼 그리드 */}
          <PbSection
            id="step-5"
            label="조회 권한"
            rightForm={
              <>
                {/* 버튼 */}
                {/* 추가, 수정 상태 */}
                {(!slsPrtnId || useModifyMode) && (
                  <div className="flex justify-end items-center space-x-2">
                    {/* 버튼 */}
                    <Button
                      variant="outline"
                      color="pink"
                      radius="xl"
                      onClick={handleReadAuthDeleteButton_onClick}
                    >
                      전체 삭제
                    </Button>

                    {/* 버튼 */}
                    <Button
                      color="pink"
                      radius="xl"
                      onClick={handleReadAuthSelectedDeleteButton_onClick}
                    >
                      선택 삭제
                    </Button>

                    {/* 버튼 */}
                    <Popover
                      opened={visibleReadAuthTargetUser}
                      onClose={() => setVisibleReadAuthTargetUser(false)}
                      position="top-end"
                      transitionProps={{ transition: 'pop' }}
                      withArrow={false}
                      shadow="xl"
                      zIndex={100}
                      offset={10}
                    >
                      <Popover.Target>
                        <Button
                          onClick={() => setVisibleReadAuthTargetUser(true)}
                          color="indigo"
                          radius="xl"
                        >
                          추가
                        </Button>
                      </Popover.Target>

                      <Popover.Dropdown className="border-1 !border-gray-500">
                        <PbSection label="추가할 대상자 선택">
                          <div className="space-y-3">
                            {/* 검색 폼 그리드 */}
                            <PbFormGrid cols={1}>
                              <PbFormGridCol label="검색">
                                {/* 셀렉트 */}
                                <Select
                                  onChange={
                                    readAuthTargetUserSearchSelect_onChange
                                  }
                                  data={readAuthTargetUserSearchSelectItem}
                                  value={readAuthTargetUserSearchSelect}
                                />
                                {/*<PbSelect*/}
                                {/*  onChange={*/}
                                {/*    readAuthTargetUserSearchSelect_onChange*/}
                                {/*  }*/}
                                {/*  data={readAuthTargetUserSearchSelect.item}*/}
                                {/*  value={readAuthTargetUserSearchSelect.value}*/}
                                {/*  setSelect={setReadAuthTargetUserSearchSelect}*/}
                                {/*/>*/}

                                {/* 텍스트 입력 */}
                                <TextInput
                                  placeholder="검색어를 입력하세요."
                                  onChange={
                                    readAuthTargetUserSearchInput_onChange
                                  }
                                  onKeyUp={
                                    readAuthTargetUserSearchInput_onKeyUp
                                  }
                                  value={readAuthTargetUserSearchInput}
                                  className="w-1/5"
                                />

                                {/* 버튼 */}
                                <div className="flex justify-center items-center space-x-2">
                                  {/* 버튼 */}
                                  <Button
                                    color="indigo"
                                    radius="xl"
                                    onClick={
                                      handleReadAuthTargetUserSearchButton_onChange
                                    }
                                  >
                                    검색
                                  </Button>
                                </div>
                              </PbFormGridCol>
                            </PbFormGrid>

                            {/* 테이블 */}
                            <div style={{ width: '1100px' }} className="h-96">
                              <PbAgGridReact
                                refs={readAuthTargetUserTableRef}
                                columnDefs={readAuthTargetUserTable.column}
                                rowData={readAuthTargetUserTable.data}
                                defaultColDef={{
                                  resizable: true,
                                  sortable: true,
                                  wrapHeaderText: false,
                                  autoHeaderHeight: true,
                                }}
                                rowSelection="multiple"
                                sizeColumnsToFit={true}
                              />
                            </div>

                            {/* 테이블 */}
                            {/*<div style={{ width: '1000px' }} className="h-96">*/}
                            {/*  <AgGridReact*/}
                            {/*    ref={readAuthTargetUserTableRef}*/}
                            {/*    columnDefs={readAuthTargetUserTable.column}*/}
                            {/*    rowData={readAuthTargetUserTable.data}*/}
                            {/*    defaultColDef={{*/}
                            {/*      resizable: true,*/}
                            {/*      sortable: true,*/}
                            {/*      editable: false,*/}
                            {/*      wrapHeaderText: false,*/}
                            {/*      autoHeaderHeight: true,*/}
                            {/*      suppressSizeToFit: false,*/}
                            {/*    }}*/}
                            {/*    rowSelection="multiple"*/}
                            {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
                            {/*    className="ag-theme-alpine"*/}
                            {/*  ></AgGridReact>*/}
                            {/*</div>*/}

                            <div className="flex justify-end items-center space-x-2">
                              {/* 버튼 */}
                              <Button
                                color="indigo"
                                radius="xl"
                                onClick={
                                  handleReadAuthTargetUserApplyButton_onClick
                                }
                              >
                                적용
                              </Button>
                            </div>
                          </div>
                        </PbSection>
                      </Popover.Dropdown>
                    </Popover>
                  </div>
                )}
              </>
            }
          >
            <div className="space-y-3">
              {/* 테이블 */}
              <div className="w-full h-80">
                <PbAgGridReact
                  refs={readAuthTableRef}
                  columnDefs={readAuthTable.column}
                  rowData={readAuthTable.data}
                  defaultColDef={{
                    resizable: true,
                    sortable: true,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="multiple"
                  sizeColumnsToFit={true}
                />
              </div>
            </div>
          </PbSection>
        </div>

        {/* 컴포넌트 모달 버튼 */}
        <div className="component-modal-button-area space-x-2">
          {/* 신규 상태 */}
          {!slsPrtnId && (
            <>
              {/* 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>

              {/* 버튼 */}
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleSaveAddButton_onClick}
              >
                저장
              </Button>
            </>
          )}

          {/* 조회 상태 */}
          {slsPrtnId && !useModifyMode && (
            <>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>

              <Button
                color="cyan"
                radius="xl"
                size="md"
                onClick={handleGoSlsListButton_onClick}
              >
                해당 영업 활동 조회
              </Button>

              <Button
                color="cyan"
                radius="xl"
                size="md"
                onClick={handleGoSlsReqButton_onClick}
              >
                해당 영업 활동 등록
              </Button>

              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleGoModifyButton_onClick}
              >
                수정 전환
              </Button>
            </>
          )}

          {/* 수정 상태 */}
          {slsPrtnId && useModifyMode && (
            <>
              {/* 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>

              {/* 버튼 */}
              <Button
                variant="outline"
                color="pink"
                radius="xl"
                size="md"
                onClick={handleRemoveButton_onClick}
              >
                삭제
              </Button>

              {/* 버튼 */}
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleSaveUpdateButton_onClick}
              >
                저장
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MN2302220202;
