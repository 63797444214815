import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  Button,
  Drawer,
  HoverCard,
  Indicator,
  SegmentedControl,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { Calendar, DateInput, TimeInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IModal,
  IComponentModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { pageContentStore } from '../stores/page.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { addComponentModalStore } from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { Group, Checkbox } from '@mantine/core';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import MN2302220351 from '../components/MN2302220351/MN2302220351.component';
import { MN2302220301_Days } from '../components/MN2302220301_Days';
import * as cmnApi from '../apis/cmn.api';
import * as nstlApi from '../apis/nstl.api';
import * as csApi from '../apis/cs.api';
import * as sttnApi from '../apis/sttn.api';
import PbSelect from '../components/PbSelect/PbSelect.component';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { decode } from 'html-entities';
import { useDisclosure, useElementSize } from '@mantine/hooks';
import { cubicBezier, motion } from 'framer-motion';
import { nanoid } from 'nanoid';
import * as workApi from '../apis/work.api';
import { MN2302220305 } from '../components/MN2302220305';
import { notifications } from '@mantine/notifications';
import * as prtnApi from '../apis/prtn.api';
import {
  getWorkStt,
  postForecast,
  postForecastFrcsIdApply,
} from '../apis/work.api';

//
// ---
//
// 안 쓰는 소스 정리해야 함!!!!
//
// 드로어, 편집드로어 변수정의하는거 분리해야 함!!!
//
// 기존 필요없는 소스 정리!!!
//
// ---
//

// 캘린더 이벤트의 배경 색상을 정의함
const eventColor: {
  id: string;
  textColor: string;
  backgroundColor: string;
  borderColor: string;
}[] = [
  {
    id: '-',
    textColor: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(100, 100, 100)',
    borderColor: 'rgb(80, 80, 80)',
  },
  {
    id: 'PRCD',
    textColor: 'rgb(0,76,62)',
    backgroundColor: 'rgb(0, 236, 194)',
    borderColor: 'rgb(0, 223, 183)',
  },
  {
    id: 'WORK_CMPL',
    textColor: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(0, 120, 255)',
    borderColor: 'rgb(0, 106, 227)',
  },
  {
    id: 'NSPC_CMPL',
    textColor: 'rgb(86, 65, 0)',
    backgroundColor: 'rgb(255, 215, 95)',
    borderColor: 'rgb(250, 206, 66)',
  },
  {
    id: 'PM_NSPC_CMPL',
    textColor: 'rgb(60, 27, 0)',
    backgroundColor: 'rgb(255, 138, 37)',
    borderColor: 'rgb(232, 113, 2)',
  },
];

// 임시!
const initCalendarEvent = {
  display: 'NTWR_CNST',
  title: '',
  start: moment(new Date()).format('YYYY-MM-DD 09:00:00'),
  end: moment(new Date()).format('YYYY-MM-DD 18:00:00'),
  state: '-',
  ..._.find(eventColor, { id: '-' }),
  extendedProps: {
    id: 0,
    display: 'NTWR_CNST',
    title: '',
    content: '',
    start: moment(new Date()).format('YYYY-MM-DD 09:00:00'),
    end: moment(new Date()).format('YYYY-MM-DD 18:00:00'),
    state: '-',
    isNew: true,
    cntrNmbr: '',
    dcmnDntfNmbr: '',
    frcsId: '',
    pmId: '',
    shipToCode: '',
    splrId: '',
    teamId: '',
  },
};

// _.find(eventColor, { id: item.dtlCodeId })
//   ?.backgroundColor || '',

// 임시!
// 개발용 캘런더 이벤트를 정의함
// const devCalendarEvent = [
// {
//   display: 'ESL_NSTL',
//   title: '시간제 이벤트 입니다.',
//   start: '2024-02-22T10:00:00',
//   state: 'type1',
//   ...eventColor['type1'],
//   extendedProps: {
//     id: '1',
//     display: 'ESL_NSTL',
//     title: '시간제 이벤트 입니다.',
//     start: '2024-02-22T10:00:00',
//     state: 'type1',
//     isNew: false,
//   },
// },
// {
//   display: 'NTWR_CNST',
//   title: '시간제2 이벤트 입니다.',
//   start: '2024-02-22T12:55:00',
//   state: 'type4',
//   ...eventColor['type4'],
//   extendedProps: {
//     id: '2',
//     display: 'NTWR_CNST',
//     title: '시간제2 이벤트 입니다.',
//     start: '2024-02-22T12:55:00',
//     state: 'type4',
//     isNew: false,
//   },
// },
// {
//   display: 'NTWR_CNST',
//   title: '설치 예정입니다.',
//   start: '2024-02-22',
//   state: 'type2',
//   ...eventColor['type2'],
//   extendedProps: {
//     id: '3',
//     display: 'NTWR_CNST',
//     title: '설치 예정입니다.',
//     start: '2024-02-22',
//     state: 'type2',
//     isNew: false,
//   },
// },
// {
//   display: 'ESL_NSTL',
//   title: '테스트',
//   start: '2024-02-09',
//   state: 'type3',
//   ...eventColor['type3'],
//   extendedProps: {
//     id: '4',
//     display: 'ESL_NSTL',
//     title: '테스트',
//     start: '2024-02-09',
//     state: 'type3',
//     isNew: false,
//   },
// },
// {
//   display: 'NTWR_CNST',
//   title: '기간제 이벤트 테스트입니다. 기간제 이벤트 테스트입니다.',
//   start: '2024-02-08',
//   end: '2024-02-13',
//   state: 'type1',
//   ...eventColor['type1'],
//   extendedProps: {
//     id: '5',
//     display: 'NTWR_CNST',
//     title: '기간제 이벤트 테스트입니다. 기간제 이벤트 테스트입니다.',
//     start: '2024-02-08',
//     end: '2024-02-13',
//     state: 'type1',
//     isNew: false,
//   },
// },
// {
//   display: 'NTWR_CNST',
//   title:
//     '기간제 #9 이벤트 테스트입니다. 기간제 #9 이벤트 테스트입니다. 기간제 #9 이벤트 테스트입니다. 기간제 #9 이벤트 테스트입니다. 기간제 #9 이벤트 테스트입니다.',
//   start: '2024-02-21T09:00',
//   end: '2024-02-23T13:00',
//   state: 'type1',
//   ...eventColor['type1'],
//   extendedProps: {
//     id: '6',
//     display: 'NTWR_CNST',
//     title:
//       '기간제 #9 이벤트 테스트입니다. 기간제 #9 이벤트 테스트입니다. 기간제 #9 이벤트 테스트입니다. 기간제 #9 이벤트 테스트입니다. 기간제 #9 이벤트 테스트입니다.',
//     start: '2024-02-21T09:00',
//     end: '2024-02-23T13:00',
//     state: 'type1',
//     isNew: false,
//   },
// },
// {
//   display: 'ESL_NSTL',
//   title: '테스트샵 #1',
//   start: '2024-02-19',
//   end: '2024-02-22',
//   state: 'type1',
//   ...eventColor['type1'],
//   extendedProps: {
//     id: '7',
//     display: 'ESL_NSTL',
//     title: '테스트샵 #1',
//     start: '2024-02-19',
//     end: '2024-02-22',
//     state: 'type1',
//     isNew: false,
//   },
// },
// {
//   display: 'ESL_NSTL',
//   title: '테스트샵 #2',
//   start: '2024-02-14',
//   state: 'type4',
//   ...eventColor['type4'],
//   extendedProps: {
//     id: '8',
//     display: 'ESL_NSTL',
//     title: '테스트샵 #2',
//     start: '2024-02-14',
//     state: 'type4',
//     isNew: false,
//   },
// },
// {
//   display: 'ESL_NSTL',
//   title: '샘플샵 #1',
//   start: '2024-01-02',
//   state: 'type3',
//   ...eventColor['type3'],
//   extendedProps: {
//     id: '9',
//     display: 'ESL_NSTL',
//     title: '샘플샵 #1',
//     start: '2024-01-02',
//     state: 'type3',
//     isNew: false,
//   },
// },
// {
//   display: 'ESL_NSTL',
//   title: '샘플샵 #2',
//   start: '2024-01-21',
//   state: 'type2',
//   ...eventColor['type2'],
//   extendedProps: {
//     id: '10',
//     display: 'ESL_NSTL',
//     title: '샘플샵 #2',
//     start: '2024-01-21',
//     state: 'type2',
//     isNew: false,
//   },
// },
// {
//   display: 'NTWR_CNST',
//   title: '테스트샵 #5',
//   start: '2024-01-05',
//   end: '2024-01-10',
//   state: 'type4',
//   ...eventColor['type4'],
//   extendedProps: {
//     id: '11',
//     display: 'NTWR_CNST',
//     title: '테스트샵 #5',
//     start: '2024-01-05',
//     end: '2024-01-10',
//     state: 'type4',
//     isNew: false,
//   },
// },
// {
//   display: 'NTWR_CNST',
//   title: '테스트샵 #6',
//   start: '2024-01-29',
//   end: '2024-02-03',
//   state: 'type3',
//   ...eventColor['type3'],
//   extendedProps: {
//     id: '12',
//     display: 'NTWR_CNST',
//     title: '테스트샵 #6',
//     start: '2024-01-29',
//     end: '2024-02-03',
//     state: 'type3',
//     isNew: false,
//   },
// },
// {
//   display: 'NTWR_CNST',
//   title: '샘플샵 #3',
//   start: '2024-03-21',
//   state: 'type1',
//   ...eventColor['type1'],
//   extendedProps: {
//     id: '13',
//     display: 'NTWR_CNST',
//     title: '샘플샵 #3',
//     start: '2024-03-21',
//     state: 'type1',
//     isNew: false,
//   },
// },
// {
//   display: 'ESL_NSTL',
//   title: '테스트샵 #7',
//   start: '2024-03-30',
//   end: '2024-04-02',
//   state: 'type2',
//   ...eventColor['type2'],
//   extendedProps: {
//     id: '14',
//     display: 'ESL_NSTL',
//     title: '테스트샵 #7',
//     start: '2024-03-30',
//     end: '2024-04-02',
//     state: 'type2',
//     isNew: false,
//   },
// },
// {
//   display: 'NTWR_CNST',
//   title: '예정이벤트 #1(편집가능)',
//   start: '2024-02-27',
//   state: 'type0',
//   ...eventColor['type0'],
//   extendedProps: {
//     id: '15',
//     display: 'NTWR_CNST',
//     title: '예정이벤트 #1(편집가능)',
//     content: '예정입니다.',
//     start: '2024-02-27',
//     state: 'type0',
//     isNew: true,
//   },
// },
// {
//   display: 'ESL_NSTL',
//   title: '예정이벤트 #2',
//   start: '2024-02-28',
//   state: 'type0',
//   ...eventColor['type0'],
//   extendedProps: {
//     id: '16',
//     display: 'ESL_NSTL',
//     title: '예정이벤트 #2',
//     content: '예정입니다.',
//     start: '2024-02-28',
//     state: 'type0',
//     isNew: true,
//   },
// },
// ];

// 드로어 편집폼의 계약 셀렉트 사용자 정의 목록 컴포넌트를 정의함
const DrawerFormLayerProjectSelectItemComponent = forwardRef<
  HTMLDivElement,
  any
>(({ label, cntrNmbr, cntrPrgrName, bpName, ...others }: any, ref) => (
  <div ref={ref} key={cntrNmbr || ''} {...others}>
    {cntrPrgrName === '-' ? (
      <div className="flex justify-start items-center">
        <span className="text-base text-gray-900 font-normal leading-none">
          {label || ''}
        </span>
      </div>
    ) : (
      <div className="flex justify-start items-center space-x-1">
        <div className="flex-none w-14 truncate">{cntrPrgrName}</div>
        <div className="grow">
          <span className="text-xs text-blue-700 font-semibold leading-none">
            {cntrNmbr || ''}
          </span>{' '}
          <span className="text-xs text-gray-500 font-normal leading-none">
            {bpName || ''}
          </span>
          <br />
          <span className="text-base text-gray-900 font-normal leading-none">
            {label || ''}
          </span>
        </div>
      </div>
    )}
  </div>
));

/**
 * 설치 관리 > 설치 일정
 * @constructor
 */
const MN2302220307Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**
   * 캘린더
   */

  // 설치상태 코드를 정의함
  const [nstlSttsCode, setNstlSttsCode] = useState<any[]>([]);

  // 캘린더의 년월을 정의함
  const [calendarDateYM, setCalendarDateYM] = useState<string>(
    moment(new Date()).format('YYYYMM'),
  );

  // 캘린더의 선택한 날짜를 정의함
  const [selectedCalendarDate, setSelectedCalendarDate] = useState<string>('');

  // 캘린더 이벤트의 필터링 구분을 정의함
  const [selectedDisplayType, setSelectedDisplayType] = useState<string>('');

  // 캘린더 이벤트를 정의함(API를 통해 불러온 원본 데이터)
  const [calendarEvent, setCalendarEvent] = useState<any[]>([]);

  // 필터링된 캘린더 이벤트를 정의함(원본 데이터를 구분값으로 필터링한 데이터)
  const [calendarFilterEvent, setCalendarFilterEvent] = useState<any[]>([]);

  // 선택한 캘린더 이벤트를 정의함
  const [selectedCalendarEvent, setSelectedCalendarEvent] = useState<
    any | null
  >(null);

  // 캘린더 참조를 정의함
  const calendarRef = useRef<any>(null);

  // 설치구분 상세를 불러옴
  const getNstlSttsCode = () => {
    cmnApi
      .getDetailCodesAll({
        grpCodeId: 'NSTL_STTS',
      })
      .then((data: IApiResult) => {
        // 설치상태 코드에 적용함
        setNstlSttsCode([
          ...[
            {
              dtlCodeDscr: '설치예정',
              dtlCodeId: '-',
              dtlCodeSrc: '',
              editId: '-',
              editName: '',
              grpCodeId: 'NSTL_STTS',
              mdfcDate: '-',
              rgstDate: '',
              rgstId: '',
              rgstName: null,
              sortRdr: 0,
              useYn: '사용',
            },
          ],
          ...data.data.list,
        ]);
      });
  };

  // 캘린더 이벤트를 불러옴
  const getCalendarEvent = () => {
    let tmpCalendarEvent: any = [];

    // 내 작업 목록을 불러옴
    workApi
      .getSttMyWorks({
        srchStrtDate: moment(`${calendarDateYM}`, 'YYYYMM').format(
          'YYYY-MM-01',
        ),
        srchEndDate: moment(`${calendarDateYM}`, 'YYYYMM').format('YYYY-MM-31'),
      })
      .then((data: any) => {
        data?.map((item: any, index: number) => {
          tmpCalendarEvent.push({
            display: item.workTypeCode,
            title: item.ttl || item.shipToCode,
            start: item.workStrtDttm,
            end: item.workEndDttm,
            state: item.nstlSttsCode || '-',
            ..._.find(eventColor, { id: item.nstlSttsCode || '-' }),
            extendedProps: {
              id: index.toString(),
              display: item.workTypeCode,
              title: item.ttl || item.shipToCode,
              content: '',
              start: item.workStrtDttm,
              end: item.workEndDttm,
              state: item.nstlSttsCode || '-',
              isNew: false,
              cntrNmbr: item.cntrNmbr,
              dcmnDntfNmbr: item.dcmnDntfNmbr,
              frcsId: item.frcsId,
              pmId: item.pmId,
              shipToCode: item.shipToCode,
              splrId: item.splrId,
              teamId: item.teamId,
            },
          });
        });

        // 캘린더 이벤트에 적용함
        setCalendarEvent(tmpCalendarEvent);
      });

    // 임시 이벤트를 추가함
    // tmpCalendarEvent = devCalendarEvent;
  };

  // 캘린더의 이전달, 다음달 버튼을 클릭함
  const handleCalendarMoveMonthButton_onClick = (param: number) => {
    let tmpDate: string = '';

    if (param === 0) {
      // 오늘(이번달)로 이동함
      tmpDate = moment(new Date()).format('YYYYMM');
    } else {
      // 이전달, 다음달로 이동함
      tmpDate = moment(calendarDateYM).add(param, 'months').format('YYYYMM');
    }

    // 캘린더의 년월에 적용함
    setCalendarDateYM(tmpDate);

    // 캘린더의 월을 이동함
    switch (param) {
      // 이전달
      case -1:
        calendarRef.current.getApi().prev();
        break;

      // 다음달
      case 1:
        calendarRef.current.getApi().next();
        break;

      // 오늘
      default:
        calendarRef.current.getApi().today();
        break;
    }
  };

  // 캘린더의 날짜를 클릭함
  const handleCalendarDate_onClick = (arg: any) => {
    // 드로어 캘린더의 선택한 날짜에 적용함
    setSelectedDrawerCalendarDate(arg.dateStr);

    // 드로어 캘린더의 선택한 년월에 적용함
    setSelectedDrawerCalendarDateYM(moment(arg.dateStr).format('YYYY-MM'));

    // 드로어를 출력함
    openDrawer();
  };

  // 캘린더의 이벤트를 클릭함
  const handleCalendarEvent_onClick = (arg: any) => {
    let tmpItem: any = arg.event.extendedProps;

    // 선택한 캘린더 이벤트에 적용함
    setSelectedCalendarEvent(tmpItem);
  };

  // 캘린더의 월간 날짜가 변경될 실행함
  useEffect(() => {
    // 캘린더 이벤트를 불러옴
    getCalendarEvent();
  }, [calendarDateYM]);

  // 캘린더 이벤트 및 필터링 구분이 변경될 실행함
  useEffect(() => {
    let tmpCalendarFilterEvent: any = [];

    let tmpCalendarEvent = _.cloneDeep(calendarEvent);

    switch (selectedDisplayType) {
      // 네트워크
      case 'NTWR_CNST':
        tmpCalendarFilterEvent = tmpCalendarEvent.filter(
          (item: any) => item.display === 'NTWR_CNST',
        );
        break;

      // ESL
      case 'ESL_NSTL':
        tmpCalendarFilterEvent = tmpCalendarEvent.filter(
          (item: any) => item.display === 'ESL_NSTL',
        );
        break;

      // 기타
      case 'NO_PLAN':
        tmpCalendarFilterEvent = tmpCalendarEvent.filter(
          (item: any) => item.display === 'NO_PLAN',
        );
        break;

      default:
        tmpCalendarFilterEvent = tmpCalendarEvent;
        break;
    }

    // 임의의 고유코드를 적용함
    tmpCalendarFilterEvent.forEach((item: any, index: number) => {
      item.id = index.toString();
    });

    // 필터링된 캘린더 이벤트에 적용함
    setCalendarFilterEvent(tmpCalendarFilterEvent);
  }, [calendarEvent, selectedDisplayType]);

  // 캘린더 이벤트를 클릭할 때 실행함
  useEffect(() => {
    // let tmpItem: any = arg.event.extendedProps.item;
    // let tmpId: string = 'MN2302220351';
    //
    // // 컴포넌트 모달을 추가함
    // setAddComponentModal({
    //   id: tmpId,
    //   title: 'Shop별 설치 일정 계획 상세',
    //   content: <MN2302220351 id={tmpId} dcmnDntfNmbr={tmpItem.dcmnDntfNmbr} />,
    //   size: 1500,
    // });

    // let tmpId: string = 'MN2302220305';
    //
    // // 컴포넌트 모달을 추가함
    // setAddComponentModal({
    //   id: tmpId,
    //   title: '작업현황 상세',
    //   content: <MN2302220305 id={tmpId} dcmnDntfNmbr={row.data.dcmnDntfNmbr} />,
    //   size: 1300,
    //   centered: false,
    // });

    if (selectedCalendarEvent === null) {
      return;
    }

    // 작업 예정 이벤트는 편집 화면으로 이동함
    if (selectedCalendarEvent?.dcmnDntfNmbr === null) {
      // notifications.show({
      //   title: '알림',
      //   message: '진행 중인 작업내용이 없습니다.',
      // });

      // 캘린더의 날짜를 클릭함
      handleCalendarDate_onClick({
        dateStr: moment(selectedCalendarEvent).format('YYYY-MM-DD'),
      });

      // 드로어의 편집 레이어에 편집할 항목을 적용함
      setDrawerFormData({
        display: selectedCalendarEvent.display,
        title: selectedCalendarEvent.ttl || selectedCalendarEvent.shipToCode,
        start: selectedCalendarEvent.start,
        end: selectedCalendarEvent.end,
        state: selectedCalendarEvent.state || '-',
        ..._.find(eventColor, { id: selectedCalendarEvent.state || '-' }),
        extendedProps: selectedCalendarEvent,
      });

      // 드로어의 편집 레이어를 출력함
      openDrawerFormLayer();

      return;
    }

    let tmpId: string = 'MN2302220351';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'Shop별 설치 일정 계획 상세',
      content: (
        <MN2302220351
          id={tmpId}
          dcmnDntfNmbr={selectedCalendarEvent?.dcmnDntfNmbr}
        />
      ),
      size: 1500,
    });

    // 선택한 이벤트를 초기화함
    setSelectedCalendarEvent(null);
  }, [selectedCalendarEvent]);

  /**
   * 드로어 캘린더
   */

  // 드로어 캘린더의 선택한 날짜를 정의함
  const [selectedDrawerCalendarDate, setSelectedDrawerCalendarDate] =
    useState<string>('');

  // 드로어 캘린더의 선택한 년월을 정의함
  const [selectedDrawerCalendarDateYM, setSelectedDrawerCalendarDateYM] =
    useState<string>('');

  // 드로어 캘린더 이벤트의 필터링 구분을 정의함
  const [selectedDrawerDisplayType, setSelectedDrawerDisplayType] =
    useState<string>('');

  // 드로어 캘린더 이벤트를 정의함(API를 통해 불러온 원본 데이터)
  const [drawerCalendarEvent, setDrawerCalendarEvent] = useState<any[]>([]);

  // 드로어 캘린더 년월 이벤트를 정의함(API를 통해 불러온 원본 데이터)
  const [drawerCalendarMonthEvent, setDrawerCalendarMonthEvent] = useState<
    any[]
  >([]);

  // 필터링된 드로어 캘린더 이벤트를 정의함(원본 데이터를 구분값으로 필터링한 데이터)
  const [drawerCalendarFilterEvent, setDrawerCalendarFilterEvent] = useState<
    any[]
  >([]);

  // 필터링된 드로어 캘린더 년월 이벤트를 정의함(원본 데이터를 구분값으로 필터링한 데이터)
  const [drawerCalendarMonthFilterEvent, setDrawerCalendarMonthFilterEvent] =
    useState<any[]>([]);

  // 선택한 드로어 캘린더 이벤트를 정의함
  const [selectedDrawerCalendarEvent, setSelectedDrawerCalendarEvent] =
    useState<any | null>(null);

  // 캘린더의 선택한 날짜에 해당하는 이벤트를 정의함
  const [selectedDateEvent, setSelectedDateEvent] = useState<any[]>([]);

  // 캘린더의 날짜를 클릭함
  const handleCalendar_onDateClick = (arg: any) => {
    let tmpId: string = `calendarEvent_${arg.dateStr}`;

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: moment(arg.dateStr).format('YYYY년 MM월 DD일'),
      content: (
        <MN2302220301_Days
          id={tmpId}
          searchYmd={moment(arg.dateStr).format('YYYYMMDD')}
        />
      ),
      size: 750,
      closeOnClickOutside: true,
    });
  };

  // 드로어 캘린더 월 이벤트를 불러옴
  const getDrawerCalendarMonthEvent = () => {
    let tmpEvent: any = [];

    // 내 작업 목록을 불러옴
    workApi
      .getSttMyWorks({
        srchStrtDate: moment(selectedDrawerCalendarDate).format('YYYY-MM-01'),
        srchEndDate: moment(selectedDrawerCalendarDate).format('YYYY-MM-31'),
      })
      .then((data: any) => {
        data?.map((item: any, index: number) => {
          tmpEvent.push({
            display: item.workTypeCode,
            title: item.ttl || item.shipToCode,
            start: item.workStrtDttm,
            end: item.workEndDttm,
            state: item.nstlSttsCode || '-',
            ..._.find(eventColor, { id: item.nstlSttsCode || '-' }),
            extendedProps: {
              id: index.toString(),
              display: item.workTypeCode,
              title: item.ttl || item.shipToCode,
              content: '',
              start: item.workStrtDttm,
              end: item.workEndDttm,
              state: item.nstlSttsCode || '-',
              isNew: false,
              cntrNmbr: item.cntrNmbr,
              dcmnDntfNmbr: item.dcmnDntfNmbr,
              frcsId: item.frcsId,
              pmId: item.pmId,
              shipToCode: item.shipToCode,
              splrId: item.splrId,
              teamId: item.teamId,
            },
          });
        });

        // 드로어 캘린더 월 이벤트에 적용함
        setDrawerCalendarMonthEvent(tmpEvent);
      });
  };

  // 캘린더 및 드로어 캘린더의 년월(해당 일 영역을 클릭)을 선택할 때 실행함
  useEffect(() => {
    if (selectedDrawerCalendarDateYM === '') {
      return;
    }

    // 드로어 캘린더 월 이벤트를 불러옴
    getDrawerCalendarMonthEvent();

    return () => {};
  }, [selectedDrawerCalendarDateYM]);

  // 드로어 캘린더의 이벤트가 변경될 때 실행함
  useEffect(() => {
    // 캘린더의 선택한 날짜에 해당하는 이벤트를 필터링함
    const tmpEvent = drawerCalendarEvent.filter(
      (item: any) =>
        moment(item.start, 'YYYYMMDD').format('YYYYMMDD') ===
          moment(selectedDrawerCalendarDate, 'YYYYMMDD').format('YYYYMMDD') ||
        (item.end !== undefined &&
          item.end !== null &&
          moment(item.start, 'YYYYMMDD').format('YYYYMMDD') <=
            moment(selectedDrawerCalendarDate, 'YYYYMMDD').format('YYYYMMDD') &&
          moment(item.end, 'YYYYMMDD').format('YYYYMMDD') >
            moment(selectedDrawerCalendarDate, 'YYYYMMDD').format('YYYYMMDD')),
    );

    // 필터링한 캘린더의 이벤트를 임의의 순서로 정렬함(기간, 종일, 시간 순서)
    let tmpEvent2: any[] = [];

    // 기간제 이벤트를 불러옴
    tmpEvent
      .filter(
        (filterItem: any) =>
          filterItem.end !== undefined && filterItem.end !== null,
      )
      .map((item: any) => {
        tmpEvent2.push(item);
      });

    // 종일제 이벤트를 불러옴
    tmpEvent
      .filter(
        (filterItem: any) =>
          filterItem.start.length === 10 &&
          (filterItem.end === undefined || filterItem.end === null),
      )
      .map((item: any) => {
        tmpEvent2.push(item);
      });

    // 시간제 이벤트를 불러옴
    tmpEvent
      .filter(
        (filterItem: any) =>
          filterItem.start.length > 10 &&
          (filterItem.end === undefined || filterItem.end === null),
      )
      .map((item: any) => {
        tmpEvent2.push(item);
      });
  }, [drawerCalendarEvent]);

  // 캘린더 및 드로어 캘린더의 년월을 선택할 때 실행함
  useEffect(() => {
    // if (selectedDrawerCalendarDateYM === '') {
    //   return;
    // }
    //
    // let tmpCalendarEvent: any = [];
    //
    // // 내 작업 목록을 불러옴
    // workApi
    //   .getSttMyWorks({
    //     srchStrtDate: moment(selectedDrawerCalendarDate).format('YYYY-MM-01'),
    //     srchEndDate: moment(selectedDrawerCalendarDate).format('YYYY-MM-31'),
    //   })
    //   .then((data: any) => {
    //     console.log('> api:', data);
    //
    //     data?.map((item: any, index: number) => {
    //       console.log('> find:', _.find(eventColor, { id: item.dtlCodeId }));
    //
    //       tmpCalendarEvent.push({
    //         display: item.workTypeCode,
    //         title: item.ttl || item.shipToCode,
    //         start: item.workStrtDttm,
    //         end: item.workEndDttm,
    //         state: item.nstlSttsCode || '-',
    //         ..._.find(eventColor, { id: item.nstlSttsCode || '-' }),
    //         extendedProps: {
    //           id: index.toString(),
    //           display: item.workTypeCode,
    //           title: item.ttl || item.shipToCode,
    //           content: '',
    //           start: item.workStrtDttm,
    //           end: item.workEndDttm,
    //           state: item.nstlSttsCode || '-',
    //           isNew: false,
    //           cntrNmbr: item.cntrNmbr,
    //           dcmnDntfNmbr: item.dcmnDntfNmbr,
    //           frcsId: item.frcsId,
    //           pmId: item.pmId,
    //           shipToCode: item.shipToCode,
    //           splrId: item.splrId,
    //           teamId: item.teamId,
    //         },
    //       });
    //     });
    //
    //     // 드로어 캘린더에 이벤트를 적용함
    //     setDrawerCalendarMonthEvent(tmpCalendarEvent);
    //   });

    // // 캘린더의 선택한 년월에 해당하는 이벤트를 필터링함
    // const tmpEvent = calendarEvent.filter(
    //   (item: any) =>
    //     moment(item.start, 'YYYYMMDD').format('YYYYMM') ===
    //       moment(selectedDrawerCalendarDateYM, 'YYYY-MM').format('YYYYMM') ||
    //     (item.end !== undefined &&
    //       item.end !== null &&
    //       moment(item.start, 'YYYYMMDD').format('YYYYMM') <=
    //         moment(selectedDrawerCalendarDateYM, 'YYYY-MM').format('YYYYMM') &&
    //       moment(item.end, 'YYYYMMDD').format('YYYYMM') >=
    //         moment(selectedDrawerCalendarDateYM, 'YYYY-MM').format('YYYYMM')),
    // );
    //
    // console.log('> drawer tmpEvent YM!!!!:', selectedDrawerCalendarDateYM);
    //
    // // 필터링한 캘린더의 이벤트를 임의의 순서로 정렬함(기간, 종일, 시간 순서)
    // let tmpEvent2: any[] = [];
    //
    // // 기간제 이벤트를 불러옴
    // tmpEvent
    //   .filter(
    //     (filterItem: any) =>
    //       filterItem.end !== undefined && filterItem.end !== null,
    //   )
    //   .map((item: any) => {
    //     tmpEvent2.push(item);
    //   });
    //
    // // 종일제 이벤트를 불러옴
    // tmpEvent
    //   .filter(
    //     (filterItem: any) =>
    //       filterItem.start.length === 10 &&
    //       (filterItem.end === undefined || filterItem.end === null),
    //   )
    //   .map((item: any) => {
    //     tmpEvent2.push(item);
    //   });
    //
    // // 시간제 이벤트를 불러옴
    // tmpEvent
    //   .filter(
    //     (filterItem: any) =>
    //       filterItem.start.length > 10 &&
    //       (filterItem.end === undefined || filterItem.end === null),
    //   )
    //   .map((item: any) => {
    //     tmpEvent2.push(item);
    //   });
    //
    // console.log('> drawer tmpEvent YM:', tmpEvent2);

    // // 캘린더의 선택한 날짜에 해당하는 이벤트를 드로어 캘린더 이벤트에 적용함
    // setDrawerCalendarMonthEvent(tmpEvent2);

    return () => {};
  }, [selectedDrawerCalendarDateYM]);

  // 드로어 캘린더 이벤트 및 드로어 캘린더 필터링 구분이 변경될 때 실행함
  useEffect(() => {
    let tmpMonthFilterEvent: any = [];
    let tmpFilterEvent: any = [];

    switch (selectedDrawerDisplayType) {
      // 네트워크
      case 'NTWR_CNST':
      // ESL
      case 'ESL_NSTL':
      // 기타
      case 'NO_PLAN':
        tmpMonthFilterEvent = drawerCalendarMonthEvent.filter(
          (item: any) => item.display === selectedDrawerDisplayType,
        );
        tmpFilterEvent = drawerCalendarMonthEvent.filter(
          (item: any) =>
            item.display === selectedDrawerDisplayType &&
            (moment(item.start, 'YYYYMMDD').format('YYYYMMDD') ===
              moment(selectedDrawerCalendarDate, 'YYYYMMDD').format(
                'YYYYMMDD',
              ) ||
              (item.end !== undefined &&
                item.end !== null &&
                moment(item.start, 'YYYYMMDD').format('YYYYMMDD') <=
                  moment(selectedDrawerCalendarDate, 'YYYYMMDD').format(
                    'YYYYMMDD',
                  ) &&
                moment(item.end, 'YYYYMMDD').format('YYYYMMDD') >
                  moment(selectedDrawerCalendarDate, 'YYYYMMDD').format(
                    'YYYYMMDD',
                  ))),
        );
        break;

      default:
        tmpMonthFilterEvent = drawerCalendarMonthEvent;
        tmpFilterEvent = drawerCalendarMonthEvent.filter(
          (item: any) =>
            moment(item.start, 'YYYYMMDD').format('YYYYMMDD') ===
              moment(selectedDrawerCalendarDate, 'YYYYMMDD').format(
                'YYYYMMDD',
              ) ||
            (item.end !== undefined &&
              item.end !== null &&
              moment(item.start, 'YYYYMMDD').format('YYYYMMDD') <=
                moment(selectedDrawerCalendarDate, 'YYYYMMDD').format(
                  'YYYYMMDD',
                ) &&
              moment(item.end, 'YYYYMMDD').format('YYYYMMDD') >=
                moment(selectedDrawerCalendarDate, 'YYYYMMDD').format(
                  'YYYYMMDD',
                )),
        );
        break;
    }

    // 필터링된 드로어 캘린더 이벤트에 적용함
    setDrawerCalendarMonthFilterEvent(tmpMonthFilterEvent);
    setDrawerCalendarFilterEvent(tmpFilterEvent);

    console.log('> tmpFilterEvent:', tmpFilterEvent);
  }, [
    drawerCalendarMonthEvent,
    selectedDrawerDisplayType,
    selectedDrawerCalendarDate,
  ]);

  // 드로어 캘린더의 날짜가 변경될 때 실행함
  useEffect(() => {
    // 년월이 바뀌면 해당 년월의 이벤트를 다시 불러와야 함
    if (
      selectedDrawerCalendarDateYM !==
      moment(selectedDrawerCalendarDate).format('YYYY-MM')
    ) {
      // 드로어 캘린더의 선택한 년월에 적용함
      setSelectedDrawerCalendarDateYM(
        moment(selectedDrawerCalendarDate).format('YYYY-MM'),
      );
    }
  }, [selectedDrawerCalendarDate]);

  /**
   * 캘린더에서 선택한 날짜에 해당하는 이벤트 조회 드로어
   */

  // 드로어의 출력 여부를 정의함
  const [openedDrawer, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);

  // 드로어의 편집 레이어 출력 여부를 정의함
  const [
    openedDrawerFormLayer,
    { open: openDrawerFormLayer, close: closeDrawerFormLayer },
  ] = useDisclosure(false);

  // 드로어의 편집 레이어의 참조를 정의함
  const {
    ref: drawerFormLayerRef,
    width: drawerFormLayerWidth,
    height: drawerFormLayerHeight,
  } = useElementSize();

  // 계약 셀렉트를 정의함
  const [
    drawerFormLayerProjectSelectItem,
    setDrawerFormLayerProjectSelectItem,
  ] = useState<IOptionItem[]>([]);
  const drawerFormLayerProjectSelectRef = useRef<HTMLInputElement>(null);

  // 협력사 / 설치팀 셀렉트를 정의함
  const [drawerFormLayerTeamSelectItem, setDrawerFormLayerTeamSelectItem] =
    useState<IOptionItem[]>([]);
  const drawerFormLayerTeamSelectRef = useRef<HTMLInputElement>(null);

  // 예정 일정을 추가할 때 사용할 계약 셀렉트 아이템을 불러옴
  const getDrawerFormLayerProjectSelectItem = () => {
    // let tmpOptionItem: IOptionItem[] = [];
    let tmpOptionItem: any[] = [];

    tmpOptionItem.push({
      cntrPrgrName: '-',
      prjcName: '-',
      label: '선택하세요.',
      value: '',
    });

    sttnApi
      .getSttnErps({
        searchType: 'prjc_name',
        searchKeyword: '',
        cntrPrgrCode: '',
        useYn: '',
        pmId: loginUser.id,
        pageSize: 100000,
        currPageIdx: 1,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          data.data.list.forEach((item: any) => {
            tmpOptionItem.push({
              ...item,
              label: item.prjcName,
              value: item.cntrNmbr,
            });
          });
        }
      });

    // 계약 셀렉트에 적용함
    setDrawerFormLayerProjectSelectItem(tmpOptionItem);
  };

  // 예정 일정을 추가할 때 사용할 설치팀 셀렉트 아이템을 불러옴
  const getDrawerFormLayerTeamSelectItem = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push({
      label: '선택하세요.',
      value: '-',
    });

    prtnApi
      .getPrtns({
        splrType: '',
        searchKeyword: '',
        pageSize: 100000,
        currPageIdx: 1,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          data.data.list.forEach((item: any) => {
            item.prtnTmList.forEach((subItem: any) => {
              tmpOptionItem.push({
                label: `${item.prtnName} / ${subItem.teamName}`,
                value: `${item.splrId}-${subItem.teamId}`,
              });
            });
          });
        }
      });

    // 설치팀 셀렉트에 적용함
    setDrawerFormLayerTeamSelectItem(tmpOptionItem);
  };

  // 드로어가 닫힐 때 실행함
  useEffect(() => {
    if (!openedDrawer) {
      // 드로어의 편집 레이어도 같이 닫음
      closeDrawerFormLayer();

      // 드로어 캘린더의 선택한 날짜를 초기화함
      setSelectedDrawerCalendarDate('');

      // 드로어 캘린더의 선택한 년월을 초기화함
      setSelectedDrawerCalendarDateYM('');

      return;
    }
  }, [openedDrawer]);

  /**
   * 선택한 일자 일정 조회 드로어
   */

  // 드로어의 편집 레이어의 편집할 항목을 정의함
  const [drawerFormData, setDrawerFormData] = useState<any>(initCalendarEvent);

  // 드로어의 편집 레이어의 예상일시를 정의함
  const drawerFormDataStartDateRef = useRef<HTMLInputElement>(null);
  const drawerFormDataStartTimeRef = useRef<HTMLInputElement>(null);
  const drawerFormDataEndDateRef = useRef<HTMLInputElement>(null);
  const drawerFormDataEndTimeRef = useRef<HTMLInputElement>(null);

  // 선택한 이벤트를 정의함
  const [selectedDrawerEvent, setSelectedDrawerEvent] = useState<any | null>(
    null,
  );

  // 선택한 날짜를 정의함
  const [selectedDrawerCalendarDay, setSelectedDrawerCalendarDay] =
    useState<Date>(new Date());

  // 날짜에 해당하는 이벤트를 불러옴
  const getDrawerEventByDate = () => {
    // 임시!
    // 나중에는 API를 통해서 데이터를 불러와야함!!!
    //selectedDrawerEvent.start
    // 캘린더의 선택한 날짜에 해당하는 이벤트를 불러옴
    // setSelectedDateEvent();
  };

  // 시작일시의 날짜 입력을 정의함
  const [startDateInput, setStartDateInput] = useState<Date>(
    selectedDrawerCalendarDay,
  );
  const startDateInputRef = useRef<HTMLInputElement>(null);

  // 시작일시의 시간 입력을 정의함
  const [startTimeInput, setStartTimeInput] = useState<string>('20:00');
  const startTimeInputRef = useRef<HTMLInputElement>(null);

  // 종료일시의 날짜 입력을 정의함
  const [endDateInput, setEndDateInput] = useState<Date>(
    moment(selectedDrawerCalendarDay).add(1, 'days').toDate(),
  );
  const endDateInputRef = useRef<HTMLInputElement>(null);

  // 종료일시의 시간 입력을 정의함
  const [endTimeInput, setEndTimeInput] = useState<string>('05:00');
  const endTimeInputRef = useRef<HTMLInputElement>(null);

  // 내용 입력을 정의함
  const [contentInput, setContentInput] = useState<string>('');
  const contentInputRef = useRef<HTMLInputElement>(null);

  // 내용 입력의 값을 변경함
  const handleContentInput_onChange = (event: any) => {
    setContentInput(event.currentTarget.value);
  };

  // 설치팀 셀렉트를 정의함
  const [setupTeamSelect, setSetupTeamSelect] = useState<string>('');

  // 드로어의 편집 레이어의 삭제 버튼을 클릭함
  const drawerFormLayerDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '이 일정을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            workApi
              .deleteForecastFrcsId({
                frcsId: drawerFormData.extendedProps.frcsId,
              })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  notifications.show({
                    title: '알림',
                    message: '삭제하였습니다.',
                  });

                  // 캘린더 이벤트를 불러옴
                  getCalendarEvent();

                  // 드로어를 닫음
                  closeDrawer();
                } else {
                  console.log('> 작업 예정 삭제 Error:', data);

                  setModal({
                    title: '오류',
                    content: (
                      <>
                        <div>삭제에 실패하였습니다.</div>
                        <div>({data.message})</div>
                      </>
                    ),
                  });
                }
              })
              .catch((error: any) => {
                console.log('> 작업 예정 삭제 Error:', error);

                setModal({
                  title: '오류',
                  content: `삭제에 실패하였습니다.(${error.message})`,
                });
              });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 드로어의 편집 레이어의 연동 적용 버튼을 클릭함
  const drawerFormLayerApplyButton_onClick = () => {
    // 작업 현황 목록을 불러옴
    workApi
      .getWorkStt({
        cmplFlag: '',
        cmpnCode: '',
        currPageIdx: 1,
        // dcmnDntfNmbr: '',
        dcmnScrnNmbr: '',
        nstlSttsCode: '',
        pageSize: 10,
        pmId: loginUser.id,
        prjcCode: '',
        searchKeyword: '',
        searchType: 'CNTR_NAME',
        splrTypeCode: drawerFormData.extendedProps.display,
      })
      .then((data: any) => {
        if (data.data.list !== undefined) {
          // 작업 현황을 불러옴
          let tmpItem = data.data.list.filter(
            (item: any) =>
              item.cntrNmbr === drawerFormData.extendedProps.cntrNmbr,
          );

          // 일치하는 작업 현황이 있을 때만 실행함
          if (tmpItem.length > 0) {
            setModal({
              title: '확인',
              content: '이 항목을 연동하시겠습니까?',
              useOkayButton: false,
              useCancelButton: true,
              cancelButtonLabel: '아니오',
              button: (
                <Button
                  color="indigo"
                  radius="xl"
                  size="md"
                  onClick={() => {
                    setRemoveModal(true);

                    workApi
                      .postForecastFrcsIdApply({
                        frcsId: drawerFormData.extendedProps.frcsId,
                        targetDcmnDntfNmbr: tmpItem[0].dcmnDntfNmbr,
                      })
                      .then((data: IApiResult) => {
                        if (data.code === '200') {
                          notifications.show({
                            title: '알림',
                            message: '적용하였습니다.',
                          });

                          // 캘린더 이벤트를 불러옴
                          getCalendarEvent();

                          // 드로어를 닫음
                          closeDrawer();
                        } else {
                          console.log('> 작업 예정 적용 Error:', data);

                          setModal({
                            title: '오류',
                            content: (
                              <>
                                <div>적용에 실패하였습니다.</div>
                                <div>({data.message})</div>
                              </>
                            ),
                          });
                        }
                      })
                      .catch((error: any) => {
                        console.log('> 작업 예정 적용 Error:', error);

                        setModal({
                          title: '오류',
                          content: `적용에 실패하였습니다.(${error.message})`,
                        });
                      });
                    //
                    // notifications.show({
                    //   title: '알림',
                    //   message: '적용하였습니다.',
                    // });

                    // 드로어를 닫음
                    closeDrawer();
                  }}
                >
                  예
                </Button>
              ),
            });
          }
        }
      });
  };

  // 드로어의 편집 레이어의 저장 버튼을 클릭함
  const drawerFormLayerSaveButton_onClick = () => {
    if (drawerFormData.extendedProps.title === '') {
      setModal({
        title: '알림',
        content: '작업명을 입력하세요.',
      });

      // if (contentInputRef.current !== null) {
      //   contentInputRef.current.focus();
      // }

      return;
    }

    if (drawerFormData.extendedProps.start > drawerFormData.extendedProps.end) {
      setModal({
        title: '알림',
        content: '예상일시가 옳바르지 않습니다.',
      });

      if (drawerFormDataStartDateRef.current !== null) {
        drawerFormDataStartDateRef.current.focus();
      }

      return;
    }

    if (drawerFormData.extendedProps.cntrNmbr === '') {
      setModal({
        title: '알림',
        content: '계약을 선택하세요.',
      });

      return;
    }

    if (drawerFormData.extendedProps.shipToCode === '') {
      setModal({
        title: '알림',
        content: '납품처를 입력하세요.',
      });

      return;
    }

    if (drawerFormData.extendedProps.teamId === '') {
      setModal({
        title: '알림',
        content: '협력사 / 설치팀을 선택하세요.',
      });

      return;
    }

    // 편집 구분을 정의함
    let modifyType: string = drawerFormData.extendedProps.isNew
      ? '추가'
      : '수정';

    setModal({
      title: '확인',
      content: `입력하신 일정을 ${modifyType}하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            if (drawerFormData.extendedProps.isNew) {
              /**
               * 추가
               */

              workApi
                .postForecast({
                  cntrNmbr: drawerFormData.extendedProps.cntrNmbr,
                  shipToCode: drawerFormData.extendedProps.shipToCode,
                  splrId: drawerFormData.extendedProps.splrId,
                  splrTypeCode: drawerFormData.extendedProps.display,
                  teamId: drawerFormData.extendedProps.teamId,
                  ttl: drawerFormData.extendedProps.title,
                  workEndDttm: moment(drawerFormData.extendedProps.end).format(
                    'YYYYMMDDHHmmss',
                  ),
                  workStrtDttm: moment(
                    drawerFormData.extendedProps.start,
                  ).format('YYYYMMDDHHmmss'),
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    notifications.show({
                      title: '알림',
                      message: '저장하였습니다.',
                    });

                    // 캘린더 이벤트를 불러옴
                    getCalendarEvent();

                    // 드로어를 닫음
                    closeDrawer();
                  } else {
                    console.log('> 작업 예정 생성 Error:', data);

                    setModal({
                      title: '오류',
                      content: (
                        <>
                          <div>저장에 실패하였습니다.</div>
                          <div>({data.message})</div>
                        </>
                      ),
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> 작업 예정 생성 Error:', error);

                  setModal({
                    title: '오류',
                    content: `저장에 실패하였습니다.(${error.message})`,
                  });
                });
            } else {
              /**
               * 수정
               */

              workApi
                .putForecastFrcsId({
                  frcsId: drawerFormData.extendedProps.frcsId,
                  cntrNmbr: drawerFormData.extendedProps.cntrNmbr,
                  shipToCode: drawerFormData.extendedProps.shipToCode,
                  splrId: drawerFormData.extendedProps.splrId,
                  splrTypeCode: drawerFormData.extendedProps.display,
                  teamId: drawerFormData.extendedProps.teamId,
                  ttl: drawerFormData.extendedProps.title,
                  workEndDttm: moment(drawerFormData.extendedProps.end).format(
                    'YYYYMMDDHHmmss',
                  ),
                  workStrtDttm: moment(
                    drawerFormData.extendedProps.start,
                  ).format('YYYYMMDDHHmmss'),
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    notifications.show({
                      title: '알림',
                      message: '저장하였습니다.',
                    });

                    // 캘린더 이벤트를 불러옴
                    getCalendarEvent();

                    // 드로어를 닫음
                    closeDrawer();
                  } else {
                    console.log('> 작업 예정 수정 Error:', data);

                    setModal({
                      title: '오류',
                      content: (
                        <>
                          <div>저장에 실패하였습니다.</div>
                          <div>({data.message})</div>
                        </>
                      ),
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> 작업 예정 수정 Error:', error);

                  setModal({
                    title: '오류',
                    content: `저장에 실패하였습니다.(${error.message})`,
                  });
                });
            }

            // notifications.show({
            //   title: '알림',
            //   message: `${modifyType}하였습니다.`,
            // });
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 설치구분 상세를 불러옴
    getNstlSttsCode();

    return () => {};
  }, []);

  // 드로어의 편집 레이어를 닫을 때 실행함
  useEffect(() => {
    if (!openedDrawerFormLayer) {
      // 드로어의 편집 레이어의 편집할 항목을 초기화함
      setDrawerFormData(initCalendarEvent);

      return;
    }

    // 예정 일정을 추가할 때 사용할 계약 셀렉트 아이템을 불러옴
    getDrawerFormLayerProjectSelectItem();

    // 예정 일정을 추가할 때 사용할 설치팀 셀렉트 아이템을 불러옴
    getDrawerFormLayerTeamSelectItem();

    // 신규 이벤트일 경우에만 초기화함
    if (drawerFormData.extendedProps.isNew) {
      let newInitCalendarEvent = initCalendarEvent;

      // 새 이벤트에 드로어 캘린더에서 선택한 일시를 적용함
      newInitCalendarEvent.extendedProps.start = moment(
        selectedDrawerCalendarDate,
      ).format('YYYY-MM-DD 09:00:00');
      newInitCalendarEvent.extendedProps.end = moment(
        selectedDrawerCalendarDate,
      ).format('YYYY-MM-DD 18:00:00');
      newInitCalendarEvent.start = newInitCalendarEvent.extendedProps.start;
      newInitCalendarEvent.end = newInitCalendarEvent.extendedProps.end;

      // 드로어의 편집 레이어의 편집할 항목에 적용함
      setDrawerFormData(newInitCalendarEvent);
    }

    return () => {};
  }, [openedDrawerFormLayer]);

  // 드로어의 편집 레이어 정보가 변경될 때 실행함
  useEffect(() => {
    return () => {};
  }, [drawerFormData]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        {/* 이벤트 색상 범주 */}
        <div className="pb-1 flex justify-end items-center space-x-3">
          {nstlSttsCode.map((item: any, index: number) => (
            <div
              key={index}
              className="flex justify-start items-center space-x-1"
            >
              {/* 색상 네모 */}
              <div
                style={{
                  backgroundColor:
                    _.find(eventColor, { id: item.dtlCodeId })
                      ?.backgroundColor || '',
                }}
                className="w-4 h-4 rounded-sm"
              ></div>

              {/* 범주 */}
              <span className="font-sans text-sm font-bold leading-none">
                {item.dtlCodeDscr}
              </span>
            </div>
          ))}
          {/*/!* 색상 #0 *!/*/}
          {/*<div className="flex justify-start items-center space-x-1">*/}
          {/*  /!* 색상 네모 *!/*/}
          {/*  <div*/}
          {/*    style={{ backgroundColor: eventColor['type0'].backgroundColor }}*/}
          {/*    className="w-4 h-4 rounded-sm"*/}
          {/*  ></div>*/}
          {/*  /!* 범주 *!/*/}
          {/*  <span className="font-sans text-sm font-bold leading-none">*/}
          {/*    상태0*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*/!* 색상 #1 *!/*/}
          {/*<div className="flex justify-start items-center space-x-1">*/}
          {/*  /!* 색상 네모 *!/*/}
          {/*  <div*/}
          {/*    style={{ backgroundColor: eventColor['type1'].backgroundColor }}*/}
          {/*    className="w-4 h-4 rounded-sm"*/}
          {/*  ></div>*/}
          {/*  /!* 범주 *!/*/}
          {/*  <span className="font-sans text-sm font-bold leading-none">*/}
          {/*    상태1*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*/!* 색상 #2 *!/*/}
          {/*<div className="flex justify-start items-center space-x-1">*/}
          {/*  /!* 색상 네모 *!/*/}
          {/*  <div*/}
          {/*    style={{ backgroundColor: eventColor['type2'].backgroundColor }}*/}
          {/*    className="w-4 h-4 rounded-sm"*/}
          {/*  ></div>*/}
          {/*  /!* 범주 *!/*/}
          {/*  <span className="font-sans text-sm font-bold leading-none">*/}
          {/*    상태2*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*/!* 색상 #3 *!/*/}
          {/*<div className="flex justify-start items-center space-x-1">*/}
          {/*  /!* 색상 네모 *!/*/}
          {/*  <div*/}
          {/*    style={{ backgroundColor: eventColor['type3'].backgroundColor }}*/}
          {/*    className="w-4 h-4 rounded-sm"*/}
          {/*  ></div>*/}
          {/*  /!* 범주 *!/*/}
          {/*  <span className="font-sans text-sm font-bold leading-none">*/}
          {/*    상태3*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*/!* 색상 #4 *!/*/}
          {/*<div className="flex justify-start items-center space-x-1">*/}
          {/*  /!* 색상 네모 *!/*/}
          {/*  <div*/}
          {/*    style={{ backgroundColor: eventColor['type4'].backgroundColor }}*/}
          {/*    className="w-4 h-4 rounded-sm"*/}
          {/*  ></div>*/}
          {/*  /!* 범주 *!/*/}
          {/*  <span className="font-sans text-sm font-bold leading-none">*/}
          {/*    상태4*/}
          {/*  </span>*/}
          {/*</div>*/}
        </div>

        {/* 캘린더 */}
        <div className="space-y-2">
          {/* 년월 정보 */}
          <div className="flex justify-between items-center">
            {/* 왼쪽 영역 */}
            <div className="w-1/3 flex justify-start items-center">
              {/* 필터링 스위치 */}
              <SegmentedControl
                value={selectedDisplayType}
                onChange={setSelectedDisplayType}
                size="md"
                color="blue"
                data={[
                  { label: '전체', value: '' },
                  { label: '네트워크', value: 'NTWR_CNST' },
                  { label: 'ESL', value: 'ESL_NSTL' },
                  { label: '계획미수립', value: 'NO_PLAN' },
                ]}
              />
            </div>

            {/* 가운데 영역 */}
            <div className="w-1/3 flex justify-center items-center">
              {/* 이전달 */}
              <div
                onClick={() => handleCalendarMoveMonthButton_onClick(-1)}
                className="button-event px-1 py-1 flex justify-center items-center"
              >
                <FontAwesomeIcon
                  icon={['fas', 'chevron-left']}
                  className="w-5 h-5 text-gray-400"
                />
              </div>

              {/* 년월 */}
              <div className="pt-0.5 leading-none">
                <span className="text-3xl font-bold leading-none">
                  {moment(calendarDateYM).format('YYYY.MM')}
                </span>
              </div>

              {/* 다음달 */}
              <div
                onClick={() => handleCalendarMoveMonthButton_onClick(1)}
                className="button-event px-1 py-1 flex justify-center items-center"
              >
                <FontAwesomeIcon
                  icon={['fas', 'chevron-right']}
                  className="w-5 h-5 text-gray-400"
                />
              </div>
            </div>

            {/* 오른쪽 영역 */}
            <div className="w-1/3 flex justify-end items-center">
              {/* 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => handleCalendarMoveMonthButton_onClick(0)}
              >
                오늘
              </Button>
            </div>
          </div>

          {/* 캘린더 */}
          <FullCalendar
            ref={calendarRef}
            locale="ko"
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={false}
            weekends={true}
            events={calendarFilterEvent}
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: false,
            }}
            // droppable={droppable}
            // editable={editable}
            showNonCurrentDates={false}
            buttonText={{
              today: '오늘',
            }}
            initialDate={calendarDateYM}
            eventContent={renderCalendarEventContent}
            eventColor={'rgba(0, 0, 0, 0)'}
            eventBackgroundColor={'rgba(0, 0, 0, 0)'}
            eventBorderColor={'rgba(0, 0, 0, 0)'}
            dateClick={handleCalendarDate_onClick}
            eventClick={handleCalendarEvent_onClick}
            // eventChange={handleCalendar_onEventChange}
          />
        </div>
      </div>

      {/* 캘린더에서 선택한 날짜에 해당하는 이벤트 조회 드로어 */}
      <Drawer
        opened={openedDrawer}
        onClose={closeDrawer}
        position="right"
        size={450}
        title={
          <div className="relative w-full">
            <span className="text-xl font-semibold">일정 조회</span>

            {/* 일정 추가 버튼 */}
            <div className="fixed right-12 top-3 z-1010">
              <Button
                color="indigo"
                radius="xl"
                size="xs"
                onClick={() => {
                  // 드로어의 편집 레이어에 편집할 항목을 적용함
                  setDrawerFormData({
                    ...drawerFormData,
                    extendedProps: {
                      ...drawerFormData.extendedProps,
                      start: selectedDrawerCalendarDate,
                    },
                  });

                  openDrawerFormLayer();
                }}
              >
                일정 추가
              </Button>
            </div>
          </div>
        }
        zIndex={1000}
      >
        {/* 편집 레이어 */}
        <motion.div
          initial="close"
          animate={openedDrawerFormLayer ? 'open' : 'close'}
          variants={{
            open: {
              x: 0,
              y: 0,
              opacity: 1,
              display: 'block',
            },
            close: {
              x: 0,
              y: 50,
              opacity: 0,
              transitionEnd: {
                display: 'none',
              },
            },
          }}
          transition={{
            ease: cubicBezier(0.16, 1, 0.3, 1),
            duration: 0.6,
          }}
          className="fixed left-0 bottom-0 w-full h-2/3 bg-white z-1030 overflow-hidden"
        >
          <div ref={drawerFormLayerRef} className="w-full h-full space-y-2">
            {/* 레이어 헤더 */}
            <div className="absolute left-0 top-0 w-full h-12 bg-white z-20">
              {/* 레이어 제목 */}
              <div className="pl-3.5 h-full flex justify-start items-center">
                <span className="text-xl font-semibold">
                  예상 일정{' '}
                  {drawerFormData.extendedProps.isNew ? '추가' : '수정'}
                </span>
              </div>

              {/* 레이어 닫기 버튼 */}
              <div
                onClick={closeDrawerFormLayer}
                className="button-event absolute right-3 top-1/2 -translate-y-1/2 p-0.5 flex justify-center items-center z-20"
              >
                <FontAwesomeIcon
                  icon={['fas', 'xmark']}
                  className="w-4 h-4 text-gray-500"
                />
              </div>
            </div>

            {/* 레이어 내용 */}
            <div
              style={{
                height: drawerFormLayerHeight - 65,
              }}
              className="px-1 pt-3 w-full overflow-y-auto"
            >
              {/*<div className="px-1 pt-3 pb-1 w-full border-t border-gray-300 overflow-y-auto">*/}
              <div className="space-y-1">
                {/* 구분 */}
                <div className="p-[10px] bg-slate-100 space-y-2 rounded">
                  {/* 섹션 제목*/}
                  <div className="flex justify-start items-center space-x-2">
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'dot-circle']}
                        className="w-4 h-4 text-blue-400"
                      />
                    </div>

                    <span className="text-base text-gray-700 font-semibold leading-none">
                      구분
                    </span>
                  </div>

                  <div>
                    <SegmentedControl
                      onChange={(event: any) =>
                        setDrawerFormData({
                          ...drawerFormData,
                          extendedProps: {
                            ...drawerFormData.extendedProps,
                            display: event,
                          },
                        })
                      }
                      value={drawerFormData.extendedProps.display}
                      size="md"
                      color="blue"
                      data={[
                        { label: '네트워크', value: 'NTWR_CNST' },
                        { label: 'ESL', value: 'ESL_NSTL' },
                        { label: '계획미수립', value: 'NO_PLAN' },
                      ]}
                    />
                  </div>
                </div>

                {/* 제목 */}
                <div className="p-[10px] bg-slate-100 space-y-2 rounded">
                  {/* 섹션 제목*/}
                  <div className="flex justify-start items-center space-x-2">
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'dot-circle']}
                        className="w-4 h-4 text-blue-400"
                      />
                    </div>

                    <span className="text-base text-gray-700 font-semibold leading-none">
                      작업명
                    </span>
                    <span className="text-base text-red-700 font-bold leading-none">
                      *
                    </span>
                  </div>

                  <div>
                    <TextInput
                      ref={contentInputRef}
                      placeholder="작업명을 입력하세요."
                      onChange={(event: any) =>
                        setDrawerFormData({
                          ...drawerFormData,
                          extendedProps: {
                            ...drawerFormData.extendedProps,
                            title: event.currentTarget.value,
                          },
                        })
                      }
                      value={drawerFormData.extendedProps.title}
                      className="w-full"
                    />
                  </div>
                </div>

                {/* 내용 */}
                {/*<div className="p-[10px] bg-slate-100 space-y-2 rounded">*/}
                {/*  /!* 섹션 제목*!/*/}
                {/*  <div className="flex justify-start items-center space-x-2">*/}
                {/*    <div className="flex justify-center items-center">*/}
                {/*      <FontAwesomeIcon*/}
                {/*        icon={['fas', 'dot-circle']}*/}
                {/*        className="w-4 h-4 text-blue-400"*/}
                {/*      />*/}
                {/*    </div>*/}

                {/*    <span className="text-base text-gray-700 font-semibold leading-none">*/}
                {/*      내용*/}
                {/*    </span>*/}
                {/*  </div>*/}

                {/*  <div>*/}
                {/*    <Textarea*/}
                {/*      placeholder="내용을 입력해 주세요."*/}
                {/*      onChange={(event: any) =>*/}
                {/*        setDrawerFormData({*/}
                {/*          ...drawerFormData,*/}
                {/*          extendedProps: {*/}
                {/*            ...drawerFormData.extendedProps,*/}
                {/*            content: event.currentTarget.value,*/}
                {/*          },*/}
                {/*        })*/}
                {/*      }*/}
                {/*      value={drawerFormData.extendedProps.content}*/}
                {/*      minRows={3}*/}
                {/*      // maxLength={10000}*/}
                {/*      className="w-full"*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/* 예상일시 */}
                <div className="p-[10px] bg-slate-100 space-y-2 rounded">
                  {/* 섹션 제목*/}
                  <div className="flex justify-start items-center space-x-2">
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'dot-circle']}
                        className="w-4 h-4 text-blue-400"
                      />
                    </div>

                    <span className="text-base text-gray-700 font-semibold leading-none">
                      예상일시
                    </span>
                    <span className="text-base text-red-700 font-bold leading-none">
                      *
                    </span>
                  </div>

                  <div className="space-y-2">
                    {/* 시작일시 */}
                    <div className="flex justify-center items-center">
                      <div className="w-full flex justify-center items-center space-x-1">
                        {/* 날짜 */}
                        <DateInput
                          ref={drawerFormDataStartDateRef}
                          locale="ko"
                          firstDayOfWeek={0}
                          monthLabelFormat="YYYY년 MM월"
                          onChange={(event: any) =>
                            setDrawerFormData({
                              ...drawerFormData,
                              start: moment(event).format(
                                `YYYY-MM-DD ${moment(
                                  drawerFormData.extendedProps.start,
                                ).format('HH:mm:00')}`,
                              ),
                              extendedProps: {
                                ...drawerFormData.extendedProps,
                                start: moment(event).format(
                                  `YYYY-MM-DD ${moment(
                                    drawerFormData.extendedProps.start,
                                  ).format('HH:mm:00')}`,
                                ),
                              },
                            })
                          }
                          value={moment(
                            drawerFormData.extendedProps.start,
                            'YYYY-MM-DD',
                          ).toDate()}
                          placeholder="일자를 입력하세요."
                          valueFormat="YYYY.MM.DD"
                          className="w-3/5"
                        />

                        {/* 시간 */}
                        <TimeInput
                          ref={drawerFormDataStartTimeRef}
                          rightSection={
                            <div
                              onClick={() =>
                                drawerFormDataStartTimeRef.current?.showPicker()
                              }
                              className="button-event flex justify-center items-center"
                            >
                              <FontAwesomeIcon
                                icon={['far', 'clock']}
                                className="w-4 h-4"
                              />
                            </div>
                          }
                          placeholder="시간을 입력하세요."
                          onChange={(event: any) =>
                            setDrawerFormData({
                              ...drawerFormData,
                              start: moment(event).format(
                                `${moment(
                                  drawerFormData.extendedProps.start,
                                ).format('YYYY-MM-DD')} ${
                                  event.currentTarget.value
                                }:00`,
                              ),
                              extendedProps: {
                                ...drawerFormData.extendedProps,
                                start: moment(event).format(
                                  `${moment(
                                    drawerFormData.extendedProps.start,
                                  ).format('YYYY-MM-DD')} ${
                                    event.currentTarget.value
                                  }:00`,
                                ),
                              },
                            })
                          }
                          value={moment(
                            drawerFormData.extendedProps.start,
                          ).format('HH:mm:00')}
                          className="w-2/5"
                        />
                      </div>

                      <div className="w-10 flex justify-end items-center">
                        <span className="text-sm text-gray-700 font-normal">
                          부터
                        </span>
                      </div>
                    </div>

                    {/* 종료일시 */}
                    <div className="flex justify-center items-center">
                      <div className="w-full flex justify-center items-center space-x-1">
                        {/* 날짜 */}
                        <DateInput
                          ref={drawerFormDataEndDateRef}
                          locale="ko"
                          firstDayOfWeek={0}
                          monthLabelFormat="YYYY년 MM월"
                          onChange={(event: any) =>
                            setDrawerFormData({
                              ...drawerFormData,
                              end: moment(event).format(
                                `YYYY-MM-DD ${moment(
                                  drawerFormData.extendedProps.end,
                                ).format('HH:mm:00')}`,
                              ),
                              extendedProps: {
                                ...drawerFormData.extendedProps,
                                end: moment(event).format(
                                  `YYYY-MM-DD ${moment(
                                    drawerFormData.extendedProps.end,
                                  ).format('HH:mm:00')}`,
                                ),
                              },
                            })
                          }
                          value={moment(
                            drawerFormData.extendedProps.end,
                            'YYYY-MM-DD',
                          ).toDate()}
                          placeholder="일자를 입력하세요."
                          valueFormat="YYYY.MM.DD"
                          className="w-3/5"
                        />

                        {/* 시간 */}
                        <TimeInput
                          ref={drawerFormDataEndTimeRef}
                          rightSection={
                            <div
                              onClick={() =>
                                drawerFormDataEndTimeRef.current?.showPicker()
                              }
                              className="button-event flex justify-center items-center"
                            >
                              <FontAwesomeIcon
                                icon={['far', 'clock']}
                                className="w-4 h-4"
                              />
                            </div>
                          }
                          placeholder="시간을 입력하세요."
                          onChange={(event: any) =>
                            setDrawerFormData({
                              ...drawerFormData,
                              end: moment(event).format(
                                `${moment(
                                  drawerFormData.extendedProps.end,
                                ).format('YYYY-MM-DD')} ${
                                  event.currentTarget.value
                                }:00`,
                              ),
                              extendedProps: {
                                ...drawerFormData.extendedProps,
                                end: moment(event).format(
                                  `${moment(
                                    drawerFormData.extendedProps.end,
                                  ).format('YYYY-MM-DD')} ${
                                    event.currentTarget.value
                                  }:00`,
                                ),
                              },
                            })
                          }
                          value={moment(
                            drawerFormData.extendedProps.end,
                          ).format('HH:mm:00')}
                          className="w-2/5"
                        />
                      </div>

                      <div className="w-10 flex justify-end items-center">
                        <span className="text-sm text-gray-700 font-normal">
                          까지
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 계약 */}
                <div className="p-[10px] bg-slate-100 space-y-2 rounded">
                  {/* 섹션 제목*/}
                  <div className="flex justify-start items-center space-x-2">
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'dot-circle']}
                        className="w-4 h-4 text-blue-400"
                      />
                    </div>

                    <span className="text-base text-gray-700 font-semibold leading-none">
                      계약
                    </span>
                    <span className="text-base text-red-700 font-bold leading-none">
                      *
                    </span>
                  </div>

                  <div>
                    <Select
                      ref={drawerFormLayerProjectSelectRef}
                      onChange={(event: any) =>
                        setDrawerFormData({
                          ...drawerFormData,
                          extendedProps: {
                            ...drawerFormData.extendedProps,
                            cntrNmbr: event,
                          },
                        })
                      }
                      itemComponent={DrawerFormLayerProjectSelectItemComponent}
                      data={drawerFormLayerProjectSelectItem}
                      value={drawerFormData.extendedProps.cntrNmbr}
                      className="w-full"
                    />
                  </div>
                </div>

                {/* 납품처 */}
                <div className="p-[10px] bg-slate-100 space-y-2 rounded">
                  {/* 섹션 제목*/}
                  <div className="flex justify-start items-center space-x-2">
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'dot-circle']}
                        className="w-4 h-4 text-blue-400"
                      />
                    </div>

                    <span className="text-base text-gray-700 font-semibold leading-none">
                      납품처
                    </span>
                    <span className="text-base text-red-700 font-bold leading-none">
                      *
                    </span>
                  </div>

                  <div>
                    <TextInput
                      ref={contentInputRef}
                      placeholder="납품처를 입력하세요."
                      onChange={(event: any) =>
                        setDrawerFormData({
                          ...drawerFormData,
                          extendedProps: {
                            ...drawerFormData.extendedProps,
                            shipToCode: event.currentTarget.value,
                          },
                        })
                      }
                      value={drawerFormData.extendedProps.shipToCode}
                      className="w-full"
                    />
                  </div>
                </div>

                {/* 협력사 / 설치팀 */}
                <div className="p-[10px] bg-slate-100 space-y-2 rounded">
                  {/* 섹션 제목*/}
                  <div className="flex justify-start items-center space-x-2">
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'dot-circle']}
                        className="w-4 h-4 text-blue-400"
                      />
                    </div>

                    <span className="text-base text-gray-700 font-semibold leading-none">
                      협력사 / 설치팀
                    </span>
                    <span className="text-base text-red-700 font-bold leading-none">
                      *
                    </span>
                  </div>

                  <div>
                    <Select
                      ref={drawerFormLayerTeamSelectRef}
                      onChange={(event: any) =>
                        setDrawerFormData({
                          ...drawerFormData,
                          extendedProps: {
                            ...drawerFormData.extendedProps,
                            splrId: event.split('-')[0] || '',
                            teamId: event.split('-')[1] || '',
                          },
                        })
                      }
                      data={drawerFormLayerTeamSelectItem}
                      value={`${drawerFormData.extendedProps.splrId || ''}-${
                        drawerFormData.extendedProps.teamId || ''
                      }`}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* 버튼 */}
            <div className="px-1 flex justify-end items-center space-x-2">
              {/* 취소 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={closeDrawerFormLayer}
              >
                취소
              </Button>

              {/* 삭제 버튼 */}
              {!drawerFormData.extendedProps.isNew && (
                <Button
                  color="pink"
                  radius="xl"
                  size="md"
                  onClick={drawerFormLayerDeleteButton_onClick}
                >
                  삭제
                </Button>
              )}

              {/* 연동 적용 버튼 */}
              {!drawerFormData.extendedProps.isNew && (
                <Button
                  color="cyan"
                  radius="xl"
                  size="md"
                  onClick={drawerFormLayerApplyButton_onClick}
                >
                  연동 적용
                </Button>
              )}

              {/* 저장 버튼 */}
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={drawerFormLayerSaveButton_onClick}
              >
                저장
              </Button>
            </div>
          </div>
        </motion.div>

        {/* 일정 추가/편집용 반투명 검은 배경 */}
        <motion.div
          initial="close"
          animate={openedDrawerFormLayer ? 'open' : 'close'}
          variants={{
            open: {
              x: 0,
              y: 0,
              opacity: 1,
              display: 'block',
            },
            close: {
              x: 0,
              y: 0,
              opacity: 0,
              transitionEnd: {
                display: 'none',
              },
            },
          }}
          transition={{
            ease: cubicBezier(0.16, 1, 0.3, 1),
            duration: 0.5,
          }}
          className="absolute left-0 top-0 w-full h-full bg-black/50 z-1020"
        >
          <div onClick={closeDrawerFormLayer} className="w-full h-full"></div>
        </motion.div>

        <div className="space-y-3">
          <div
            style={{ height: 'calc(100vh - 70px)' }}
            className="w-full overflow-y-scroll space-y-3"
          >
            {/* 필터링 스위치 */}
            <div className="sticky top-0 flex justify-start items-center border-1 border-[#f1f3f5] bg-[#f1f3f5] rounded-sm z-20">
              <SegmentedControl
                value={selectedDrawerDisplayType}
                onChange={setSelectedDrawerDisplayType}
                color="blue"
                data={[
                  { label: '전체', value: '' },
                  { label: '네트워크', value: 'NTWR_CNST' },
                  { label: 'ESL', value: 'ESL_NSTL' },
                  { label: '계획미수립', value: 'NO_PLAN' },
                ]}
              />
            </div>

            {/* 설치일자 달력 */}
            <div className="p-[10px] bg-gray-100/50 space-y-2 rounded">
              <div className="flex justify-center items-center">
                <div>
                  <Calendar
                    locale="ko"
                    firstDayOfWeek={0}
                    monthLabelFormat="YYYY년 MM월"
                    // date={selectedDrawerCalendarDay}
                    date={
                      selectedDrawerCalendarDate === ''
                        ? new Date()
                        : moment(
                            selectedDrawerCalendarDate,
                            'YYYY-MM-DD',
                          ).toDate()
                    }
                    onPreviousMonth={(date: Date) => {
                      // 이전달 날짜를 선택한 이벤트에 적용함
                      // setSelectedDrawerEvent({
                      //   ...selectedDrawerEvent,
                      //   start: moment(date).format('YYYY-MM-DD'),
                      // });
                      setSelectedDrawerCalendarDate(
                        moment(date).format('YYYY-MM-DD'),
                      );

                      // 드로어 캘린더의 선택한 년월에 적용함
                      setSelectedDrawerCalendarDateYM(
                        moment(date).format('YYYY-MM'),
                      );
                    }}
                    onNextMonth={(date: Date) => {
                      // 다음달 날짜를 선택한 이벤트에 적용함
                      // setSelectedDrawerEvent({
                      //   ...selectedDrawerEvent,
                      //   start: moment(date).format('YYYY-MM-DD'),
                      // });
                      setSelectedDrawerCalendarDate(
                        moment(date).format('YYYY-MM-DD'),
                      );

                      // 드로어 캘린더의 선택한 년월에 적용함
                      setSelectedDrawerCalendarDateYM(
                        moment(date).format('YYYY-MM'),
                      );
                    }}
                    getDayProps={(date: Date) => ({
                      selected:
                        // moment(date).format('YYYYMMDD') === moment(selectedDrawerCalendarDay).format('YYYYMMDD'),
                        moment(date).format('YYYYMMDD') ===
                        moment(
                          selectedDrawerCalendarDate === ''
                            ? new Date()
                            : moment(
                                selectedDrawerCalendarDate,
                                'YYYY-MM-DD',
                              ).toDate(),
                        ).format('YYYYMMDD'),
                      onClick: () => {
                        // 드로어 캘린더의 선택한 날짜에 적용함
                        setSelectedDrawerCalendarDate(
                          moment(date).format('YYYY-MM-DD'),
                        );

                        // // 선택한 날짜를 선택한 이벤트에 적용함
                        // setSelectedDrawerEvent({
                        //   ...selectedDrawerEvent,
                        //   start: moment(date).format('YYYY-MM-DD'),
                        // });

                        // // 날짜에 해당하는 이벤트를 불러옴
                        // getDrawerEventByDate();
                      },
                    })}
                    renderDay={(date: Date) => {
                      const day: number = date.getDate();
                      const tmpDate: string = moment(date).format('YYYY-MM-DD');
                      const tmpDate2: string =
                        moment(date).format('YYYY.MM.DD');

                      // const tmpThatDay: any = [];
                      // drawerDetailData?.workBaseList?.filter(
                      //   (item: any) =>
                      //     moment(item.workStrtDttm, 'YYYY-MM-DD').format(
                      //       'YYYY-MM-DD',
                      //     ) === tmpDate,
                      // );

                      const tmpThatDay: any =
                        drawerCalendarMonthFilterEvent.filter(
                          (item: any) =>
                            moment(item.start, 'YYYYMMDD').format(
                              'YYYYMMDD',
                            ) ===
                              moment(tmpDate, 'YYYY-MM-DD').format(
                                'YYYYMMDD',
                              ) ||
                            (item.end !== undefined &&
                              item.end !== null &&
                              moment(item.start, 'YYYYMMDD').format(
                                'YYYYMMDD',
                              ) <=
                                moment(tmpDate, 'YYYY-MM-DD').format(
                                  'YYYYMMDD',
                                ) &&
                              moment(item.end, 'YYYYMMDD').format('YYYYMMDD') >=
                                moment(tmpDate, 'YYYY-MM-DD').format(
                                  'YYYYMMDD',
                                )),
                        );

                      return (
                        <>
                          {tmpThatDay.length > 0 ? (
                            <Indicator
                              // position="bottom-center"
                              size={16}
                              offset={-4}
                              color={'rgba(255, 255, 255, 0)'}
                              label={
                                <div className="px-[4px] py-[2px] flex justify-center items-center bg-white border-1 border-gray-300 rounded-full">
                                  <span className="font-sans text-xs font-bold text-black leading-none">
                                    {tmpThatDay.length}
                                  </span>
                                </div>
                              }
                            >
                              <div>{day}</div>
                            </Indicator>
                          ) : (
                            <div>{day}</div>
                          )}
                        </>
                      );
                    }}
                    size="xl"
                  />
                </div>
              </div>
            </div>

            {/* 이벤트 목록 */}
            <div className="space-y-1">
              {drawerCalendarFilterEvent.map((item: any, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    // 선택한 이벤트가 예정 이벤트일 때만 편집 레이어를 출력함
                    if (item.state === '-') {
                      // 드로어의 편집 레이어에 편집할 항목을 적용함
                      setDrawerFormData(item);

                      // 드로어의 편집 레이어를 출력함
                      openDrawerFormLayer();
                    } else {
                      // 선택한 캘린더 이벤트에 적용함
                      setSelectedCalendarEvent(item.extendedProps);

                      // 드로어를 닫음
                      closeDrawer();
                    }
                  }}
                  className="button-event border-1 border-gray-300 bg-white hover:border-gray-400 rounded-sm space-y-1"
                >
                  <div className="px-[5px] pt-[5px] pb-[1px] h-full flex justify-between items-center">
                    {/* 구분 및 일시 */}
                    <div className="flex justify-center items-center space-x-2">
                      {/* 구분 아이콘 */}
                      {item.display === 'NTWR_CNST' && (
                        <div className="w-[30px] h-[16px] flex justify-center items-center border-1 border-black bg-[#FF4359] rounded-sm">
                          <span className="font-sans text-xs text-white font-bold leading-none">
                            NET
                          </span>
                        </div>
                      )}
                      {item.display === 'ESL_NSTL' && (
                        <div className="w-[30px] h-[16px] flex justify-center items-center border-1 border-black bg-[#4A0875] rounded-sm">
                          <span className="font-sans text-xs text-white font-bold leading-none">
                            ESL
                          </span>
                        </div>
                      )}
                      {item.display === 'NO_PLAN' && (
                        <div className="w-[30px] h-[16px] flex justify-center items-center border-1 border-black bg-[#333333] rounded-sm">
                          <span className="font-sans text-xs text-white font-bold leading-none">
                            ETC
                          </span>
                        </div>
                      )}

                      {/* 일시 */}
                      <div className="font-sans flex justify-start items-center space-x-1">
                        {/* 시작일시 */}
                        <span className="text-sm text-gray-800 font-normal leading-none">
                          {item.start.length > 10
                            ? moment(item.start).format('YYYY.MM.DD HH:mm')
                            : moment(item.start).format('YYYY.MM.DD')}
                        </span>

                        {/* 종료일시 */}
                        {item.end !== undefined && item.end !== null && (
                          <>
                            <span className="text-sm text-gray-800 font-normal leading-none">
                              ~
                            </span>
                            <span className="text-sm text-gray-800 font-normal leading-none">
                              {item.end.length > 10
                                ? moment(item.end).format('YYYY.MM.DD HH:mm')
                                : moment(item.end).format('YYYY.MM.DD')}
                            </span>
                          </>
                        )}

                        {/* 종일 구분 */}
                        {item.start.length === 10 &&
                          (item.end === undefined || item.end === null) && (
                            <span className="text-sm text-gray-800 font-normal leading-none">
                              (종일)
                            </span>
                          )}
                      </div>
                    </div>

                    {/* 상태 */}
                    <div className="flex justify-center items-center space-x-1.5">
                      <div
                        style={{
                          padding: '0 7px',
                          color: item.textColor,
                          backgroundColor: item.backgroundColor,
                        }}
                        className="h-5 flex justify-center items-center rounded-full"
                      >
                        <span className="font-sans text-xs font-medium leading-none">
                          {
                            _.find(nstlSttsCode, { dtlCodeId: item.state })
                              ?.dtlCodeDscr
                          }
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* 내용 */}
                  <div className="px-[8px] pt-[6px] pb-[3px] flex items-stretch bg-gradient-to-t from-white to-gray-100/70">
                    <span className="text-sm text-gray-700 font-normal leading-snug">
                      {item.title}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Drawer>
    </PageLayout>
  );
};

// 캘린더 이벤트 스타일을 정의함
const renderCalendarEventContent = (eventInfo: any) => {
  return (
    <div
      className={[
        eventInfo.timeText ? 'px-[2px]' : 'hover:bg-gray-200/10',
        'font-sans tracking-tight leading-none overflow-hidden rounded-sm',
      ].join(' ')}
    >
      <div className="flex justify-start items-center space-x-0.5">
        {/* 시간 이벤트일 때, 시간을 출력함 */}
        {eventInfo.timeText &&
          (eventInfo.event?.end === undefined ||
            eventInfo.event?.end === null) && (
            <div className="flex justify-start items-center">
              <span
                className={[
                  'pl-[1px] w-14 text-xs font-medium',
                  eventInfo.event?.textColor,
                ].join(' ')}
              >
                {eventInfo.timeText}
              </span>
            </div>
          )}

        {/* 이벤트 제목 */}
        <div className="p-[3px] flex justify-start items-center space-x-[5px]">
          {/* 구분 아이콘 */}
          {eventInfo.event?.display === 'NTWR_CNST' && (
            <div className="py-[1px] w-[30px] flex justify-center items-center border-1 border-black bg-[#FF4359] rounded-sm">
              <span className="text-xs text-white font-bold">NET</span>
            </div>
            // <div className="py-[1px] w-[30px] flex justify-center items-center border-1 border-black bg-[#FF4359] rounded-sm">
            //   <span className="text-xs text-white font-bold">NET</span>
            // </div>
          )}
          {eventInfo.event?.display === 'ESL_NSTL' && (
            <div className="py-[1px] w-[30px] flex justify-center items-center border-1 border-black bg-[#4A0875] rounded-sm">
              <span className="text-xs text-white font-bold">ESL</span>
            </div>
          )}
          {eventInfo.event?.display === 'NO_PLAN' && (
            <div className="py-[1px] w-[30px] flex justify-center items-center border-1 border-black bg-[#333333] rounded-sm">
              <span className="text-xs text-white font-bold">ETC</span>
            </div>
          )}

          {/* 이벤트 제목 */}
          <div className="flex justify-center items-center">
            <span className="text-sm font-normal hover:opacity-70">
              {eventInfo.event.title}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MN2302220307Page;

/**
 * 2024.01.25 설치일정 설계회의 진행함
 *
 * 달력---
 *
 * 달력에서 이벤트 드래그는 아직 필요없음
 * 이벤트는 샵 이름만 출력
 * 설치예정일, 확정일(실제 설치일), 완료일에 맞게 이벤트의 색상을 변경함
 * 설치예정일에 확정일이 추가되면 설치예정일은 달력에서 제거
 * 완료일은 화면에 따로 추가하지는 않음(해당 이벤트에 색상 변경 정도만..)
 * 이벤트에 네트워크, ESL 구분을 아이콘이나 명칭으로 처리
 * 달력 상단에 네트워크, ESL 필터링 추가
 * 이벤트를 클릭하면 해당 이벤트의 작업현황을 모달로 출력
 * 달력의 하루 날짜 칸을 클릭하면 오른쪽에 드로어 출력
 * PM, 업체 담당자들에 맞게 각각 내용 출력
 *
 * 드로어---
 * 일정추가 버튼을 클릭하면 B1에서 데이터를 받기 전에 임의의 내용을 작성 가능
 * 일정추가: 날짜, 제목, 내용 등으로 간단하게만 작성
 * 이벤트를 클릭하면 드로어가 닫히고(가능하다면 모달 그대로 출력) 작업현황 모달 출력
 * 이벤트 정보에는 간단한 추가 정보만 출력
 *
 * 작업현황 > 작업배정---
 * 네트워크, ESL에 설치예정일을 등록할 때 수정도 가능해야 함
 */
