import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useDownloadExcel } from 'react-export-table-to-excel';
import _ from 'lodash';
import {
  IApiListResult,
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  IMatchMenuIdPageId,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import * as cmnApi from '../apis/cmn.api';
import moment from 'moment';
import { PbButton } from '../components/PbButton';
import { PbTable } from '../components/PbTable';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import PbSection from '../components/PbSection/PbSection.component';
import { PbDatePicker } from '../components/PbDatePicker';
import { PbInputText } from '../components/PbInputText';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  componentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import { MN2302220805_CODE } from '../components/MN2302220805_CODE';
import { MN2302220805_DETAILCODE } from '../components/MN2302220805_DETAILCODE';
import { nanoid } from 'nanoid';
import { Button, Select, TextInput, Radio, Group } from '@mantine/core';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { AgGridReact } from 'ag-grid-react';
import { ValueFormatterParams } from 'ag-grid-community';

/**
 * 시스템 관리 > 메일 관리
 * @constructor
 */
const MN2302220801Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**
   * 변수 선언
   */

  // 재고담당email 입력을 정의
  const [nvntMngrInput, setNvntMngrInput] = useState<string>('');

  // 재고담당email 입력값을 변경
  const handleNvntMngrInput_onChange = (event: any) => {
    setNvntMngrInput(event.target.value);
  };

  // 콜센터email 입력을 정의
  const [calCntrInput, setCalCntrInput] = useState<string>('');

  // 콜센터email 입력값을 변경
  const handleCalCntrInput_onChange = (event: any) => {
    setCalCntrInput(event.target.value);
  };

  // 운영팀email 입력을 정의
  const [prtnTeamInput, setPrtnTeamInput] = useState<string>('');

  // 운영팀email 입력값을 변경
  const handlePrtnTeamInput_onChange = (event: any) => {
    setPrtnTeamInput(event.target.value);
  };

  // 개발팀email 입력을 정의
  const [dvlpTeamInput, setDvlpTeamInput] = useState<string>('');

  // 개발팀email 입력값을 변경
  const handleDvlpTeamInput_onChange = (event: any) => {
    setDvlpTeamInput(event.target.value);
  };

  /* //  */

  /**
   * 데이타 검색
   */

  // 메일 설정 정보를 불러옴
  const getMailList = () => {
    cmnApi.getMailList({}).then((data: IApiResult) => {
      if (data.code === '200') {
        data.data.list.map((item: any, index: number) => {
          if (_.isEqual(item.mailStngCode, 'NVNT_MNGR')) {
            // 재고담당 이메일을 설정
            setNvntMngrInput(item.mailNfrm);
          } else if (_.isEqual(item.mailStngCode, 'CAL_CNTR')) {
            // 콜센터 이메일을 설정
            setCalCntrInput(item.mailNfrm);
          } else if (_.isEqual(item.mailStngCode, 'DVLP_TEAM')) {
            // 개발팀 이메일을 설정
            setDvlpTeamInput(item.mailNfrm);
          } else if (_.isEqual(item.mailStngCode, 'PRTN_TEAM')) {
            // 운영팀 이메일을 설정
            setPrtnTeamInput(item.mailNfrm);
          }
        });
      }
    });
  };

  /* //  */

  /**
   * 처리
   */

  const checkEmail = (email: string) => {
    var regExp =
      /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    // 형식에 맞는 경우 true 리턴
    return regExp.test(email);
  };

  // 메일 관리 > 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    // 입력값 유효성 확인

    // 재고담당 대표 메일 입력 확인
    if (_.isEmpty(nvntMngrInput.trim())) {
      setModal({
        title: '알림',
        content: '재고담당 메일을 입력하세요.',
      });
      return;
    }

    // 재고담당 대표 메일 정합성 확인
    if (checkEmail(nvntMngrInput.trim())) {
      // 정합성 통과
    } else {
      setModal({
        title: '알림',
        content: '재고담당 메일의 형식이 올바르지 않습니다.',
      });
      return;
    }

    // 콜센터 대표 메일 입력 확인
    if (_.isEmpty(calCntrInput.trim())) {
      setModal({
        title: '알림',
        content: '콜센터 메일을 입력하세요.',
      });
      return;
    }

    // 콜센터 대표 메일 정합성 확인
    if (checkEmail(calCntrInput.trim())) {
      // 정합성 통과
    } else {
      setModal({
        title: '알림',
        content: '콜센터 메일의 형식이 올바르지 않습니다.',
      });
      return;
    }

    // 움영팀 대표 메일 입력 확인
    if (_.isEmpty(prtnTeamInput.trim())) {
      setModal({
        title: '알림',
        content: '움영팀 메일을 입력하세요.',
      });
      return;
    }

    // 움영팀 대표 메일 정합성 확인
    if (checkEmail(prtnTeamInput.trim())) {
      // 정합성 통과
    } else {
      setModal({
        title: '알림',
        content: '움영팀 메일의 형식이 올바르지 않습니다.',
      });
      return;
    }

    // 개발팀 대표 메일 입력 확인
    if (_.isEmpty(dvlpTeamInput.trim())) {
      setModal({
        title: '알림',
        content: '개발팀 메일을 입력하세요.',
      });
      return;
    }

    // 개발팀 대표 메일 정합성 확인
    if (checkEmail(dvlpTeamInput.trim())) {
      // 정합성 통과
    } else {
      setModal({
        title: '알림',
        content: '개발팀 메일의 형식이 올바르지 않습니다.',
      });
      return;
    }

    // 메일 관리 정보 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 사용자 등록을 처리함
              cmnApi
                .putMail({
                  calCntrMail: calCntrInput.trim(), // 콜센터 대표 메일
                  dvlpTeamMail: dvlpTeamInput.trim(), // 개발팀 대표 메일
                  nvntMngrMail: nvntMngrInput.trim(), // 재고담당 대표 메일
                  prtnTeamMail: prtnTeamInput.trim(), // 움영팀 대표 메일
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                    });
                  } else {
                    console.log('> 메일 설정 등록 Error:', data);

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> 메일 설정 등록 Error:', error);

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  /* //  */

  /**
   * useEffect
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 재고담당 메일 입력값을 초기화
    setNvntMngrInput('');

    // 콜센터 메일 입력값을 초기화
    setCalCntrInput('');

    // 개발팀 메일 입력값을 초기화
    setDvlpTeamInput('');

    // 운영팀 메일 입력값을 초기화
    setPrtnTeamInput('');

    // 메일설정 정보 불러오기
    getMailList();

    return () => {};
  }, []);

  /* //  */

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={
        <>
          {/* 버튼 */}
          <Button color="indigo" radius="xl" onClick={handleSaveButton_onClick}>
            저장
          </Button>
        </>
      }
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-10">
        {/* 재고담당 메일 정보 */}
        <div className="space-y-1">
          <PbFormGrid label="재고 담당 메일 정보" cols={2}>
            <PbFormGridCol label="재고담당" colSpan={2} withAsterisk={true}>
              <TextInput
                placeholder="Email을 입력하세요."
                onChange={handleNvntMngrInput_onChange}
                value={nvntMngrInput}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          <div>
            <span className="text-blue-700">
              ※ 재고이동 요청 시, 위 지정한 담당자에게 메일이 발송됩니다.
            </span>
          </div>
        </div>
        {/* CS부서 메일 정보 */}
        <div className="space-y-1">
          <PbFormGrid label="CS부서 메일 정보" cols={2}>
            <PbFormGridCol label="콜센터" colSpan={2} withAsterisk={true}>
              <TextInput
                placeholder="Email을 입력하세요."
                onChange={handleCalCntrInput_onChange}
                value={calCntrInput}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="운영팀" colSpan={2} withAsterisk={true}>
              <TextInput
                placeholder="Email을 입력하세요."
                onChange={handlePrtnTeamInput_onChange}
                value={prtnTeamInput}
                className="w-full"
              />
            </PbFormGridCol>
            <PbFormGridCol label="개발팀" colSpan={2} withAsterisk={true}>
              <TextInput
                placeholder="Email을 입력하세요."
                onChange={handleDvlpTeamInput_onChange}
                value={dvlpTeamInput}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          <div>
            <span className="text-blue-700">
              ※ CS 작업 "이관" 시, 담당자 미 지정 시, 위 지정한 담당자에게
              메일이 발송됩니다.
            </span>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220801Page;
