import { atom } from 'recoil';
import { ILeftMenu, ILoginUser } from '../interfaces/app.interface';

/**
 * 선택한 페이지
 */
export const selectedPageStore = atom({
  key: 'selectedPageStore',
  default: {} as ILeftMenu,
});
