import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useElementSize } from '@mantine/hooks';
import { PbTable } from '../PbTable';
import {
  IApiResult,
  ISelect,
  ITableColumn,
} from '../../interfaces/app.interface';
import * as csApi from '../../apis/cs.api';
import { getCsHstrs } from '../../apis/cs.api';
import { PbButton } from '../PbButton';
import { useRecoilState } from 'recoil';
import {
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { useNavigate } from 'react-router-dom';
import { LoadingTableDataIcon } from '../LoadingTableDataIcon';
import { AgGridReact } from 'ag-grid-react';
import { PbAgGridReact } from '../PbAgGridReact';

interface IComponentProps {
  id?: string;
  data?: any;
  prjcCode?: string;
  bpCode?: string;
  shipToCode?: string;
  onClick?: () => void;
}

/**
 * 운영 관리 > CS 조회 > 등록 > CS 히스토리
 * @param id 컴포넌트 모달 아이디
 * @param data <인자>
 * @param prjcCode 프로젝트코드
 * @param bpCode 고객사코드
 * @param shipToCode shop코드(shopId 또는 shipToCode라 함)
 * @param onClick <이벤트>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220502_CsHistory = ({
  id = '',
  data,
  prjcCode = '',
  bpCode = '',
  shipToCode = '',
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 페이지의 내용 영역 크기를 정의함
  const {
    ref: contentRef,
    width: contentWidth,
    height: contentHeight,
  } = useElementSize();

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // CS 히스토리 테이블을 정의함
  const [csHistoryTable, setCsHistoryTable] = useState<{
    column: any;
    data: any;
    loading?: boolean;
  }>({ column: [], data: [], loading: false });

  // CS 히스토리 테이블의 로딩 여부를 정의함
  const [csHistoryTableLoading, setCsHistoryTableLoading] =
    useState<boolean>(false);

  // CS 히스토리 테이블을 불러옴
  const initCsHistoryTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      { headerName: '문의유형 그룹명', field: 'cstyGrpName', width: 180 },
      { headerName: '문의유형명', field: 'cstyName', width: 180 },
      { headerName: '장애구분', field: 'dsblDntfName', width: 100 },
      { headerName: '문의명', field: 'nqryName', width: 450 },
      { headerName: '고객특성', field: 'cstmTrbtName', width: 100 },
      { headerName: 'CS상태', field: 'csSttsName', width: 120 },
      { headerName: '접수일', field: 'rgstDate', width: 150 },
      { headerName: '처리완료요청일', field: 'cprcsRqstDateol8', width: 150 },
    );

    // 검색 결과 테이블에 적용함
    setCsHistoryTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // CS 히스토리 테이블 데이터를 불러옴
  const getCsHistoryTableData = () => {
    // CS 히스토리 테이블의 로딩 여부에 적용함
    setCsHistoryTableLoading(true);

    csApi
      .getCsHstrs({
        prjcCode: prjcCode,
        bpCode: bpCode,
        shipToCode: shipToCode,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setCsHistoryTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list,
          }));

          // CS 히스토리 테이블의 로딩 여부에 적용함
          setCsHistoryTableLoading(false);
        }
      })
      .catch((error: any) => {
        // CS 히스토리 테이블의 로딩 여부에 적용함
        setCsHistoryTableLoading(false);
      });
  };

  // 컴포넌트 모달 버튼 > 자세히 보기 버튼을 클릭함
  const handleDetailViewButton_onClick = () => {
    // 전체 컴포넌트 모달을 닫음
    setRemoveAllComponentModal(true);

    // 운영 관리 > CS 조회 페이지로 이동함
    // navigate('/cs-management?a=1&b=2');
    window.location.href = '/cs-management?a=1&b=2';
  };

  useEffect(() => {
    // CS 히스토리 테이블을 초기화함
    initCsHistoryTable();

    return () => {};
  }, []);

  // CS 히스토리 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (csHistoryTable.column.length === 0) {
      return;
    }

    // CS 히스토리 테이블 데이터를 불러옴
    getCsHistoryTableData();

    return () => {};
  }, [csHistoryTable.column]);

  return (
    <div className="space-y-7">
      {/* 테이블 */}
      <div className="w-full h-152">
        <PbAgGridReact
          columnDefs={csHistoryTable.column}
          rowData={csHistoryTable.data}
          loading={csHistoryTableLoading}
          setLoading={setCsHistoryTableLoading}
          defaultColDef={{
            resizable: true,
            sortable: true,
            wrapHeaderText: false,
            autoHeaderHeight: true,
          }}
          rowSelection="single"
          sizeColumnsToFit={true}
        />
      </div>

      {/* 테이블 */}
      {/*<div className="relative w-full h-96">*/}
      {/*  /!* 로딩 중 아이콘 *!/*/}
      {/*  <LoadingTableDataIcon visible={csHistoryTable.loading} />*/}

      {/*  <AgGridReact*/}
      {/*    columnDefs={csHistoryTable.column}*/}
      {/*    rowData={csHistoryTable.data}*/}
      {/*    defaultColDef={{*/}
      {/*      initialWidth: 150,*/}
      {/*      resizable: true,*/}
      {/*      sortable: true,*/}
      {/*      wrapHeaderText: false,*/}
      {/*      autoHeaderHeight: true,*/}
      {/*      suppressSizeToFit: true,*/}
      {/*    }}*/}
      {/*    rowSelection="single"*/}
      {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
      {/*    className="ag-theme-alpine"*/}
      {/*  ></AgGridReact>*/}
      {/*</div>*/}

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        {/*<div>*/}
        {/*  <PbButton*/}
        {/*    label="자세히 보기"*/}
        {/*    backgroundColor="purple"*/}
        {/*    size="md"*/}
        {/*    onClick={handleDetailViewButton_onClick}*/}
        {/*  />*/}
        {/*</div>*/}

        <div>
          <PbButton
            label="닫기"
            backgroundColor="white"
            size="md"
            onClick={() => setRemoveComponentModal(id)}
          />
        </div>
      </div>
    </div>
  );
};

export default MN2302220502_CsHistory;
