import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import { useRecoilState } from 'recoil';
import { removeComponentModalStore } from '../../stores/componentModal.store';
import * as appUtil from '../../utils/app.util';
import _ from 'lodash';
import { number } from 'prop-types';
import { IOptionItem } from '../../interfaces/app.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IComponentProps {
  id: string;
  selectedData?: any;
  data?: any;
  onClick?: () => void;
  callback?: (result: any) => void;
}

/**
 * 설치 관리 > 작업 현황 > 상세 > 진행상태 변경
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220305_ChangeStep = ({
  id,
  selectedData,
  data,
  onClick,
  callback = (result: any) => {},
}: PropsWithChildren<IComponentProps>) => {
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 버튼을 클릭함
  const handleSelectButton_onClick = (param: any) => {
    // 부모창으로 결과를 넘김
    callback({
      label: param.label,
      value: param.value,
    });

    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="pb-2 space-y-2">
      <div className="h-0.5" />

      <div className="w-full space-y-1">
        {/* 작업대기 */}
        <Button
          color="indigo"
          size="md"
          // onClick={() => handleSelectButton_onClick(item)}
          className="!w-full"
        >
          <div className="flex justify-center items-center space-x-2">
            <span>작업완료</span>
          </div>
        </Button>

        {/* 작업완료 */}
        <Button
          color="red"
          size="md"
          // onClick={() => handleSelectButton_onClick(item)}
          className="!w-full"
        >
          <div className="flex justify-center items-center space-x-2">
            <span>작업완료 취소</span>
          </div>
        </Button>
        <Button
          color="indigo"
          size="md"
          // onClick={() => handleSelectButton_onClick(item)}
          className="!w-full"
        >
          <div className="flex justify-center items-center space-x-2">
            <span>검수완료</span>
          </div>
        </Button>

        {/* 검수완료 */}
        <Button
          color="red"
          size="md"
          // onClick={() => handleSelectButton_onClick(item)}
          className="!w-full"
        >
          <div className="flex justify-center items-center space-x-2">
            <span>검수완료 취소</span>
          </div>
        </Button>
        <Button
          color="indigo"
          size="md"
          // onClick={() => handleSelectButton_onClick(item)}
          className="!w-full"
        >
          <div className="flex justify-center items-center space-x-2">
            <span>PM검수완료</span>
          </div>
        </Button>

        {/* PM검수완료 */}
        <Button
          color="red"
          size="md"
          // onClick={() => handleSelectButton_onClick(item)}
          className="!w-full"
        >
          <div className="flex justify-center items-center space-x-2">
            <span>PM검수완료 취소</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default MN2302220305_ChangeStep;
