import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  IPageContent,
  ITableColumn,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { pageContentStore } from '../stores/page.store';
import { PageLayout } from '../components/PageLayout';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { addComponentModalStore } from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import * as sttnApi from '../apis/sttn.api';
import _ from 'lodash';
import { MN2302220305 } from '../components/MN2302220305';
import { Button, Progress, Select, TextInput } from '@mantine/core';
import * as workApi from '../apis/work.api';
import { decode } from 'html-entities';
import moment from 'moment';
import * as nstlApi from '../apis/nstl.api';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import PbSection from '../components/PbSection/PbSection.component';
import { PbHelpPopover } from '../components/PbHelpPopover';
import { PbAgGridReact } from '../components/PbAgGridReact';
import { getSttnErps } from '../apis/sttn.api';
import { MN2302220404 } from '../components/MN2302220404';
import { ValueFormatterParams } from 'ag-grid-community';
import * as appUtil from '../utils/app.util';

/**
 * 현황 관리 > 계약 현황
 * @constructor
 */
const MN2302220404Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 검색 폼 그리드 > 검색어 > 셀렉트를 정의함
  const [searchCategorySelect, setSearchCategorySelect] = useState<string>('');
  const [searchCategorySelectItem, setSearchCategorySelectItem] = useState<
    IOptionItem[]
  >([]);

  // 검색 폼 그리드 > 담당PM 셀렉트를 정의함
  const [pmNameSelect, setPmNameSelect] = useState<string>('');
  const [pmNameSelectItem, setPmNameSelectItem] = useState<IOptionItem[]>([]);

  // 검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  // 검색 폼 그리드 > 계약진행상태 > 셀렉트를 정의함
  const [processStatusSelect, setProcessStatusSelect] = useState<string>('');
  const [processStatusSelectItem, setProcessStatusSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 검색 폼 그리드 > 사용여부 > 셀렉트를 정의함
  const [usedSelect, setUsedSelect] = useState<string>('');
  const [usedSelectItem, setUsedSelectItem] = useState<IOptionItem[]>([]);

  // 검색 결과 테이블을 정의함
  const [searchResultTable, setSearchResultTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 검색 결과 테이블 페이징을 정의함
  const [searchResultTablePaging, setSearchResultTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const searchResultTablePagingCurrentPageRef = useRef<number>(1);

  // 검색 결과 테이블의 로딩 여부를 정의함
  const [searchResultTableLoading, setSearchResultTableLoading] =
    useState<boolean>(false);

  // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
  const initSearchCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '프로젝트명',
        value: 'prjc_name',
      },
      {
        label: '고객사명',
        value: 'bp_name',
      },
      {
        label: '계약명',
        value: 'cntr_name',
      },
    );

    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    setSearchCategorySelect('prjc_name');
    setSearchCategorySelectItem(tmpOptionItem);
  };

  // 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCategorySelect_onChange = (event: any) => {
    setSearchCategorySelect(event);
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchInput(event.currentTarget.value);
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력에서 키를 입력함
  const handleSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
  const handleSearchButton_onChange = () => {
    // 테이블의 페이지를 변경함
    searchResultTablePagingCurrentPageRef.current = 1;
    setSearchResultTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: searchResultTablePagingCurrentPageRef.current,
    }));

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();
  };

  // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
  const handleSearchCancelButton_onChange = () => {
    // 검색 폼 그리드 > 검색어 > 셀렉트 값을 초기화함
    setSearchCategorySelect('prjc_name');

    // 검색 폼 그리드 > 검색어 > 검색어 입력 값을 초기화함
    setSearchInput('');

    // 검색 폼 그리드 > 담당PM > 셀렉트를 초기화함
    setPmNameSelect('');

    // 검색 폼 그리드 > 계약진행상태 > 셀렉트를 초기화함
    setProcessStatusSelect('');

    // 검색 폼 그리드 > 사용여부 > 셀렉트를 초기화함
    setUsedSelect('');
  };

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    setHideDetailSearch(!hideDetailSearch);
  };

  // 검색 폼 그리드 > 담당 PM 셀렉트에서 선택한 아이템을 변경함
  const handlePmNameSelect_onChange = (event: any) => {
    setPmNameSelect(event);
  };

  // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템을 초기화함
  const initPmNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: 'ALL',
      },
      {
        label: '담당자 없음',
        value: '',
      },
    );

    // 판매 계약 담당PM 목록을 불러옴
    sttnApi.getSttnErpSearchPm({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.name,
          value: item.userId,
        });
      });

      // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템에 적용함
      setPmNameSelectItem(tmpOptionItem);
    });
  };

  // 검색 폼 그리드 > 계약진행상태 > 셀렉트의 아이템을 초기화함
  const initProcessStatusSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '진행중',
        value: 'prcd',
      },
      {
        label: '종료',
        value: 'end',
      },
    );

    // 셀렉트의 아이템에 적용함
    setProcessStatusSelectItem(tmpOptionItem);
  };

  // 검색 폼 그리드 > 사용여부 > 셀렉트의 아이템을 초기화함
  const initUsedSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '사용',
        value: 'Y',
      },
      {
        label: '미사용',
        value: 'N',
      },
    );

    // 셀렉트의 아이템에 적용함
    setUsedSelectItem(tmpOptionItem);
  };

  // 검색 결과 테이블의 행을 클릭함
  const handleSearchResultTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220404';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '계약현황 상세',
      content: <MN2302220404 id={tmpId} cntrNmbr={row.data.cntrNmbr} />,
      size: 1300,
      centered: false,
    });
  };

  // 검색 결과 테이블의 엑셀 다운로드 버튼을 클릭함
  const handleSearchResultTableExportExcelButton_onClick = () => {
    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <>
          {/* 버튼 */}
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              sttnApi
                .getSttnErpExcel({
                  searchType: searchCategorySelect,
                  searchKeyword: decode(searchInput.trim()),
                  cntrPrgrCode: processStatusSelect,
                  pmId: pmNameSelect,
                  useYn: usedSelect,
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    ['작업현황_', moment().format('YYYYMMDD'), '.xlsx'].join(
                      '',
                    ),
                  );
                  document.body.appendChild(link);
                  link.click();
                });

              setRemoveModal(true);
            }}
          >
            예
          </Button>
        </>
      ),
    });
  };

  // 검색 폼 그리드 > 계약진행상태 > 셀렉트에서 선택한 아이템을 변경함
  const handleProcessStatusSelect_onChange = (event: any) => {
    setProcessStatusSelect(event);
  };

  // 검색 폼 그리드 > 사용여부 > 셀렉트에서 선택한 아이템을 변경함
  const handleUsedSelect_onChange = (event: any) => {
    setUsedSelect(event);
  };

  // 검색 결과 테이블을 초기화함
  const initSearchResultTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: 'NO',
        field: 'no',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        headerName: '계약명',
        field: 'cntrName',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.cntrName);
        },
      },
      // {
      //   headerName: '계약번호',
      //   field: 'cntrNmbr',
      //   width: 100,
      //   suppressSizeToFit: true,
      //   valueFormatter: (params: ValueFormatterParams) => {
      //     return decode(params.data.cntrNmbr);
      //   },
      // },
      {
        headerName: '고객사명',
        field: 'bpName',
        width: 200,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.bpName);
        },
      },
      {
        headerName: '담당PM',
        field: 'pmName',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: '계약진행상태',
        field: 'cntrPrgrName',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '전체주문건',
        field: 'dcmnCnt',
        width: 150,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        headerName: '계획미수립 주문건',
        field: 'dcmnNotPlanCnt',
        width: 150,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        headerName: '사용여부',
        field: 'useYn',
        width: 100,
        suppressSizeToFit: true,
      },
    );

    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 검색 결과 테이블 데이터를 불러옴
  const getSearchResultTableData = () => {
    // 검색 결과 테이블의 로딩 여부에 적용함
    setSearchResultTableLoading(true);

    sttnApi
      .getSttnErps({
        searchKeyword: decode(searchInput.trim()),
        searchType: searchCategorySelect,
        pmId: pmNameSelect,
        cntrPrgrCode: processStatusSelect,
        useYn: usedSelect,
        pageSize: searchResultTablePaging.rowPerPage,
        currPageIdx: searchResultTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setSearchResultTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
            }),
          );

          console.log('> data.data.list:', data.data.list);
        }

        // 테이블을 페이징함
        setSearchResultTablePaging((pre: ITablePaging) => ({
          ...pre,
          totalPage: data.data.page.totPageCnt,
          totalRow: data.data.page.totItemCnt,
        }));

        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      })
      .catch((error: any) => {
        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
    initSearchCategorySelect();

    // 검색 폼 그리드 > 담당PM > 셀렉트의 아이템을 초기화함
    initPmNameSelect();

    // 검색 폼 그리드 > 계약진행상태 > 셀렉트의 아이템을 초기화함
    initProcessStatusSelect();

    // 검색 폼 그리드 > 사용여부 > 셀렉트의 아이템을 초기화함
    initUsedSelect();

    return () => {};
  }, []);

  useEffect(() => {
    if (!loginUser.id) {
      return;
    }

    // 검색 결과 테이블을 초기화함
    initSearchResultTable();

    return () => {};
  }, [
    loginUser.id,
    loginUser.cmpnCode,
    loginUser.thrtDvsnCode,
    loginUser.nstlCtgrCode,
  ]);

  // 검색 결과 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (searchResultTable.column.length === 0) {
      return;
    }

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();

    return () => {};
  }, [searchResultTable.column]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }
    if (_.indexOf(refreshList, 'MN2302220404Table') > -1) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220404Table');
    }

    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-5">
        {/* 검색 폼 그리드 */}
        <PbFormGrid cols={2}>
          <PbFormGridCol label="검색어" colSpan={2}>
            {/* 셀렉트 */}
            <Select
              onChange={handleSearchCategorySelect_onChange}
              data={searchCategorySelectItem}
              value={searchCategorySelect}
            />

            {/* 텍스트 입력 */}
            <TextInput
              placeholder="검색어를 입력하세요."
              onChange={handleSearchInput_onChange}
              onKeyUp={handleSearchInput_onKeyUp}
              value={searchInput}
              className="w-1/2"
            />

            {/* 버튼 */}
            <div className="flex justify-center items-center space-x-2">
              {/* 버튼 */}
              <Button
                color="indigo"
                radius="xl"
                onClick={handleSearchButton_onChange}
              >
                검색
              </Button>

              {/* 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                onClick={handleSearchCancelButton_onChange}
              >
                검색 조건 초기화
              </Button>

              {/* 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                onClick={handleShowSearchDetailButton_onChange}
                className="hidden"
              >
                {hideDetailSearch ? (
                  <>
                    상세검색 열기
                    <div className="ml-1 flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'caret-down']}
                        className="w-5 h-5"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    상세검색 닫기
                    <div className="ml-1 flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'caret-up']}
                        className="w-5 h-5"
                      />
                    </div>
                  </>
                )}
              </Button>
            </div>
          </PbFormGridCol>

          <PbFormGridCol label="담당PM">
            {/* 셀렉트 */}
            <Select
              onChange={handlePmNameSelect_onChange}
              data={pmNameSelectItem}
              value={pmNameSelect}
              className="w-full"
              placeholder="검색어를 입력하시거나 목록을 선택하세요."
              searchable={true}
              clearable={true}
            />
          </PbFormGridCol>

          <PbFormGridCol label="계약진행상태">
            {/* 셀렉트 */}
            <Select
              onChange={handleProcessStatusSelect_onChange}
              data={processStatusSelectItem}
              value={processStatusSelect}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="사용여부">
            {/* 셀렉트 */}
            <Select
              onChange={handleUsedSelect_onChange}
              data={usedSelectItem}
              value={usedSelect}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol isBlank={true}></PbFormGridCol>
        </PbFormGrid>

        {/* 테이블 */}
        <div className="space-y-3">
          <div className="flex justify-between items-center space-x-5">
            <div>
              {/* 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                onClick={handleSearchResultTableExportExcelButton_onClick}
              >
                엑셀 다운로드
              </Button>
            </div>

            <div className="flex justify-center items-center space-x-5">
              <div className="flex justify-center items-center">
                <span className="text-base text-gray-600 font-bold">
                  총 {searchResultTablePaging.totalRow}건
                </span>
              </div>
            </div>
          </div>

          {/* 테이블 */}
          <div className="w-full h-160">
            <PbAgGridReact
              onCellClicked={(event: any) => {
                // 클릭한 셀에 대한 전체 내용
                console.log(event);

                // 클릭한 셀의 아이디
                console.log(event.column.colId);
              }}
              onCellDoubleClicked={(event: any) => {
                // 위의 내용과 동일
              }}
              columnDefs={searchResultTable.column}
              rowData={searchResultTable.data}
              loading={searchResultTableLoading}
              setLoading={setSearchResultTableLoading}
              onRowDoubleClicked={handleSearchResultTable_onRowDoubleClicked}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="single"
              sizeColumnsToFit={true}
              visiblePaging={true}
              paging={{
                totalPage: searchResultTablePaging.totalPage,
                currentPage: searchResultTablePaging.currentPage,
              }}
              onChangePage={(event: any) => {
                // 테이블의 페이지를 변경함
                searchResultTablePagingCurrentPageRef.current = event;
                setSearchResultTablePaging((pre: ITablePaging) => ({
                  ...pre,
                  currentPage: searchResultTablePagingCurrentPageRef.current,
                }));

                // 검색 결과 테이블 데이터를 불러옴
                getSearchResultTableData();
              }}
              className="ag-grid-multiple-header-name"
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220404Page;
