import React, { PropsWithChildren, useMemo } from 'react';

interface IPbInputTextProps {
  placeholder?: string;
  size?: 'tiny' | 'mini' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  value?: any;
  onChange?: (event: any) => void;
  onEnterKeyPress?: (event: any) => void;
  disabled?: boolean;
  readOnly?: boolean;
  usePassword?: boolean;
}

/**
 * 텍스트 입력
 * @param placeholder 설명
 * @param size 가로 크기
 * @param value 값
 * @param onChange 값 변경 이벤트
 * @param onEnterKeyPress 엔터키 누름 이벤트
 * @param disabled 비활성화 여부
 * @param readOnly 읽기 전용 여부
 * @param usePassword 비밀번호 입력 여부
 * @constructor
 */
const PbInputText = ({
  placeholder = '입력하세요.',
  size,
  value,
  onChange,
  onEnterKeyPress,
  disabled = false,
  readOnly = false,
  usePassword = false,
}: PropsWithChildren<IPbInputTextProps>) => {
  return (
    <input
      type={usePassword ? 'password' : 'text'}
      placeholder={placeholder}
      onChange={onChange}
      onKeyUp={(event: any) => {
        if (onEnterKeyPress && event.keyCode === 13) {
          onEnterKeyPress(event);
        }
      }}
      value={value}
      className={[
        'form-control',
        size && `pb-form-size-${size}`,
        'focus:border-b focus:!border-purple-400',
        'read-only:!bg-white read-only:!border-none',
      ].join(' ')}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};

export default PbInputText;
