import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  IPageContent,
  ISelect,
  ITableColumn,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { pageContentStore } from '../stores/page.store';
import { PageLayout } from '../components/PageLayout';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { addComponentModalStore } from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import moment from 'moment/moment';
import * as csApi from '../apis/cs.api';
import * as cmnApi from '../apis/cmn.api';
import * as sttnApi from '../apis/sttn.api';
import _ from 'lodash';
import * as nstlApi from '../apis/nstl.api';
import { MN2302220302_Days } from '../components/MN2302220302_Days';
import MN2302220352 from '../components/MN2302220352/MN2302220352.component';
import {
  Button,
  Group,
  Progress,
  Radio,
  Select,
  Tabs,
  TextInput,
} from '@mantine/core';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import PbSelect from '../components/PbSelect/PbSelect.component';
import PbSection from '../components/PbSection/PbSection.component';
import { PbHelpPopover } from '../components/PbHelpPopover';
import { PbAgGridReact } from '../components/PbAgGridReact';
import { PbFullCalendar } from '../components/PbFullCalendar';
import * as slsApi from '../apis/sls.api';
import { decode } from 'html-entities';
import * as workApi from '../apis/work.api';
import { getSttExcel, getWorkStt } from '../apis/work.api';
import { ValueFormatterParams } from 'ag-grid-community';
import { MN2302220204 } from '../components/MN2302220204';
import { MN2302220305 } from '../components/MN2302220305';
import { useLocation } from 'react-router';
import * as appUtil from '../utils/app.util';
import { LoadingTableDataIcon } from '../components/LoadingTableDataIcon';
import Lottie from 'lottie-react';
import loadingAnimation from '../components/LoadingTableDataIcon/loading.json';

/**
 * 설치 관리 > 작업 현황
 * @constructor
 */
const MN2302220305Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 주소줄의 인자를 정의함
  const location = useLocation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 검색 폼 그리드 > 검색어 > 셀렉트를 정의함
  const [searchCategorySelect, setSearchCategorySelect] = useState<string>('');
  const [searchCategorySelectItem, setSearchCategorySelectItem] = useState<
    IOptionItem[]
  >([]);

  // 검색 폼 그리드  > 담당 PM 셀렉트를 정의함
  const [pmNameSelect, setPmNameSelect] = useState<string>('ALL');
  const [pmNameSelectItem, setPmNameSelectItem] = useState<IOptionItem[]>([]);

  // 검색 폼 그리드 > 검색어 > 검색어 입력을 정의함
  const [searchInput, setSearchInput] = useState<string>('');

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  // 검색 폼 그리드 > 진행상태 > 셀렉트를 정의함
  const [processStatusSelect, setProcessStatusSelect] = useState<string>('');
  const [processStatusSelectItem, setProcessStatusSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 검색 폼 그리드 > 작업상태 > 셀렉트를 정의함
  const [workStatusSelect, setWorkStatusSelect] = useState<string>('');
  const [workStatusSelectItem, setWorkStatusSelectItem] = useState<
    IOptionItem[]
  >([]);

  // 검색 폼 그리드 > 주문번호 > 입력을 정의함
  const [orderNoInput, setOrderNoInput] = useState<string>('');

  // 검색 결과 테이블을 정의함
  const [searchResultTable, setSearchResultTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  const [searchResultWorkSttVo, setSearchResultWorkSttVo] = useState<any>({});
  const [searchResultWorkSttVo2, setSearchResultWorkSttVo2] = useState<any>({});

  // 검색 결과 테이블 페이징을 정의함
  const [searchResultTablePaging, setSearchResultTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const searchResultTablePagingCurrentPageRef = useRef<number>(1);

  // 검색 결과 테이블의 로딩 여부를 정의함
  const [searchResultTableLoading, setSearchResultTableLoading] =
    useState<boolean>(false);

  // 작업유형 > ESL
  const [isEslNstl, setIsEslNstl] = useState<string>('N');
  // 작업유형 > 네트워크
  const [isNtwrCnst, setIsNtwrCnst] = useState<string>('N');

  // 네트워크 / ESL 설치의 경우 일부 기능 제한
  const [funcHiddenFlag, setFuncHiddenFlag] = useState<boolean>(true);

  // ESL 진척현황 데이저 저장소
  const [eslStatus, setEslStatus] = useState<{
    billPcntg: string; // 진척도 billing
    blngCmplCnt: string; // 설치완료 - Billing 완료
    blngNcmpCnt: string; // 설치완료 - Billing 미완료
    cmplPcntg: string; // 진척도 실적
    planCmplCnt: string; // 설치 미완료 - 계획 수립
    planNcmpCnt: string; // 설치 미완료 - 계획 미수립
    totalCnt: string; // 전체
    totalPrjcEnd: string; // 전체 프로젝트 일정 - 종료일
    totalPrjcStrt: string; // 전체 프로젝트 일정 - 시작일
  }>();

  // 설치완료/미완료 현황의 항목을 클릭하여 조회를 시도했는지의 여부를 정의함
  const isChangeStatusRef = useRef<boolean>(false);

  // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
  const initSearchCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '계약명',
        value: 'CNTR_NAME',
      },
      {
        label: '협력사명',
        value: 'PRTN_NAME',
      },
    );

    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템에 적용함
    setSearchCategorySelect('CNTR_NAME');
    setSearchCategorySelectItem(tmpOptionItem);
  };

  // 검색 폼 그리드 > 검색어 > 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCategorySelect_onChange = (event: any) => {
    setSearchCategorySelect(event);
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchInput(event.currentTarget.value);
  };

  // 검색 폼 그리드 > 검색어 > 검색어 입력에서 키를 입력함
  const handleSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
  const handleSearchButton_onClick = () => {
    // 테이블의 페이지를 변경함
    searchResultTablePagingCurrentPageRef.current = 1;
    setSearchResultTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: searchResultTablePagingCurrentPageRef.current,
    }));

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();
  };

  // 검색 폼 그리드 > 검색어 > 검색 조건 초기화 버튼을 클릭함
  const handleSearchCancelButton_onChange = () => {
    // 검색 폼 그리드 > 검색어 > 셀렉트 값을 초기화함
    setSearchCategorySelect('CNTR_NAME');

    // 검색 폼 그리드 > 검색어 > 검색어 입력 값을 초기화함
    setSearchInput('');

    // 검색 폼 그리드  > 담당PM 셀렉트를 초기화함
    setPmNameSelect('ALL');

    // 검색 폼 그리드  > 진행상태 셀렉트를 초기화함
    setProcessStatusSelect('');

    // 검색 폼 그리드  > 작업상태 셀렉트를 초기화함
    setWorkStatusSelect('');

    // 검색 폼 그리드 > 주문번호 > 입력을 초기화함
    setOrderNoInput('');
  };

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    setHideDetailSearch(!hideDetailSearch);
  };

  // 검색 폼 그리드 > 담당 PM 셀렉트에서 선택한 아이템을 변경함
  const handlePmNameSelect_onChange = (event: any) => {
    setPmNameSelect(event);
  };

  // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템을 초기화함
  const initPmNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: 'ALL',
      },
      {
        label: '담당자 없음',
        value: '',
      },
    );

    // 판매 계약 담당PM 목록을 불러옴
    sttnApi.getSttnErpSearchPm({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.name,
          value: item.userId,
        });
      });

      // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템에 적용함
      setPmNameSelectItem(tmpOptionItem);
    });
  };

  // 검색 폼 그리드 > 진행상태 > 셀렉트의 아이템을 초기화함
  const initProcessStatusSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '계획미수립',
        value: 'PLAN_NCMP',
      },
      {
        label: '계획수립',
        value: 'PLAN_CMPL',
      },
      {
        label: '빌링미완료',
        value: 'BLNG_NCMP',
      },
      {
        label: '빌링완료',
        value: 'BLNG_CMPL',
      },
    );

    // 셀렉트의 아이템에 적용함
    setProcessStatusSelectItem(tmpOptionItem);
  };

  // 검색 폼 그리드 > 작업상태 > 셀렉트의 아이템을 초기화함
  const initWorkStatusSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: '',
      },
      {
        label: '작업대기',
        value: 'PRCD',
      },
      {
        label: '작업완료',
        value: 'WORK_CMPL',
      },
      {
        label: '검수완료',
        value: 'NSPC_CMPL',
      },
      {
        label: 'PM검수완료',
        value: 'PM_NSPC_CMPL',
      },
    );

    // 셀렉트의 아이템에 적용함
    setWorkStatusSelectItem(tmpOptionItem);
  };

  // 검색 폼 그리드 > 주문번호 > 입력의 값을 변경함
  const handleOrderNoInput_onChange = (event: any) => {
    setOrderNoInput(event.currentTarget.value);
  };

  // 검색 폼 그리드 > 주문번호 > 입력에서 키를 입력함
  const handleOrderNoInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 검색 폼 그리드 > 주문번호 > 검색 버튼을 클릭함
  const handleOrderNoButton_onClick = () => {
    // 테이블의 페이지를 변경함
    searchResultTablePagingCurrentPageRef.current = 1;
    setSearchResultTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: searchResultTablePagingCurrentPageRef.current,
    }));

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();
  };

  // 검색 결과 테이블의 행을 클릭함
  const handleSearchResultTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220305';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '작업현황 상세',
      content: <MN2302220305 id={tmpId} dcmnDntfNmbr={row.data.dcmnDntfNmbr} />,
      size: 1300,
      centered: false,
    });
  };

  // 검색 결과 테이블의 엑셀 다운로드 버튼을 클릭함
  const handleSearchResultTableExportExcelButton_onClick = () => {
    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <>
          {/* 버튼 */}
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              workApi
                .getSttExcel({
                  searchKeyword: decode(searchInput.trim()),
                  searchType: searchCategorySelect,
                  cmplFlag: processStatusSelect,
                  nstlSttsCode: workStatusSelect,
                  pmId: pmNameSelect,
                  // splrTypeCode: 'ESL_NSTL',
                  pageSize: 10000000,
                  currPageIdx: 1,
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    ['작업현황_', moment().format('YYYYMMDD'), '.xlsx'].join(
                      '',
                    ),
                  );
                  document.body.appendChild(link);
                  link.click();
                });

              setRemoveModal(true);
            }}
          >
            예
          </Button>
        </>
      ),
    });
  };

  // 검색 결과 테이블의 배치 재실행 버튼을 클릭함
  const handleSearchResultTableRerunBatchButton_onClick = () => {
    //
  };

  // 검색 폼 그리드 > 진행상태 > 셀렉트에서 선택한 아이템을 변경함
  const handleProcessStatusSelect_onChange = (event: any) => {
    setProcessStatusSelect(event);
  };

  // 검색 폼 그리드 > 작업상태 > 셀렉트에서 선택한 아이템을 변경함
  const handleWorkStatusSelect_onChange = (event: any) => {
    setWorkStatusSelect(event);
  };

  // ESL 진척현황을 불러옴
  const getEslStatus = () => {
    // ESL 진척현황 불러오기
    sttnApi
      .getSttnEslPrtn({
        currPageIdx: 1,
        pageSize: 100000,
        cmplFlag: '',
        userId: loginUser.id,
        cmpnCode: loginUser.cmpnCode,
      })
      .then((data: IApiResult) => {
        // ESL 진척 현황 적용
        setEslStatus(data.data.cntVo);
      });
  };

  // 검색 결과 테이블의 엑셀 다운로드 버튼을 클릭함
  const handleOngoingProjectTableExportExcelButton_onClick = () => {
    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <>
          {/* 버튼 */}
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              nstlApi
                .getNstlPrcdExcel({
                  isShowAll: 'Y',
                  // nstlSttsCode: installStatusRadio.value || '',
                  isEslNstl: isEslNstl,
                  isNtwrCnst: isNtwrCnst,
                  workBaseYearMonth: '',
                  // cmpnCode: loginUser.cxxmpnCode,
                  userId: loginUser.id,
                  pmId: pmNameSelect,
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    [
                      '진행중 작업 관리_',
                      moment().format('YYYYMMDD'),
                      '.xlsx',
                    ].join(''),
                  );
                  document.body.appendChild(link);
                  link.click();
                });

              setRemoveModal(true);
            }}
          >
            예
          </Button>
        </>
      ),
    });
  };

  // 검색 결과 테이블을 초기화함
  const initSearchResultTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: 'NO',
        field: 'no',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        headerName: '설치계획일(ESL)',
        field: 'xpctDateEsl',
        width: 130,
        suppressSizeToFit: true,
        valueFormatter: (params: any) =>
          (params.value || '').replaceAll('-', '.'),
      },
      {
        headerName: '설치계획일(네트워크)',
        field: 'xpctDateNtwr',
        width: 150,
        suppressSizeToFit: true,
        valueFormatter: (params: any) =>
          (params.value || '').replaceAll('-', '.'),
      },
      {
        headerName: '계약명',
        field: 'cntrName',
        width: 250,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.cntrName);
        },
      },
      {
        headerName: 'Shop명',
        field: 'shipToCode',
        width: 200,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.shipToCode);
        },
      },
      // {
      //   headerName: '주문번호',
      //   field: 'dcmnDntfNmbr',
      //   width: 110,
      //   suppressSizeToFit: true,
      // },
      {
        headerName: '주문번호',
        field: 'dcmnScrnNmbr',
        width: 110,
        suppressSizeToFit: true,
      },
      {
        headerName: '담당PM',
        field: 'pmName',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: '진행상태',
        field: 'cmplName',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '작업상태',
        field: 'nstlSttsName',
        width: 130,
        suppressSizeToFit: true,
      },
      {
        headerName: '협력사(네트워크)',
        field: 'prtnNtwrName',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: '협력사(ESL)',
        field: 'prtnEslName',
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: 'PM검수완료일',
        field: 'workDate',
        width: 130,
        suppressSizeToFit: true,
        valueFormatter: (params: any) =>
          (params.value || '').replaceAll('-', '.'),
      },
      {
        headerName: '계산서발행일',
        field: 'billDate',
        width: 130,
        suppressSizeToFit: true,
        valueFormatter: (params: any) =>
          (params.value || '').replaceAll('-', '.'),
      },
      {
        headerName: '계획',
        field: 'qntt',
        width: 100,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        headerName: '수령',
        field: 'rcvdQntt',
        width: 100,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        headerName: '설치',
        field: 'nstlQntt',
        width: 100,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        headerName: '여유',
        field: 'freQntt',
        width: 100,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        headerName: '분실',
        field: 'lostQntt',
        width: 100,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        headerName: '파손',
        field: 'brkgQntt',
        width: 100,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
    );

    // 검색 결과 테이블에 적용함
    setSearchResultTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 검색 결과 테이블 데이터를 불러옴
  const getSearchResultTableData = () => {
    // 올리브영일 경우에 검색 조건에 사용자 아이디를 적용함
    let tmpLimitDataUserId: string = '';

    // 올리브영일 경우에 검색 조건에 사용자 아이디를 적용함
    if (loginUser.cmpnCode === 'LVYN') {
      tmpLimitDataUserId = loginUser.id;
    }

    console.log('> loginUser:', loginUser);
    console.log('> tmpLimitDataUserId:', tmpLimitDataUserId);

    // 검색 결과 테이블의 로딩 여부에 적용함
    setSearchResultTableLoading(true);

    workApi
      .getWorkStt({
        searchKeyword: decode(searchInput.trim()),
        searchType: searchCategorySelect,
        pmId: pmNameSelect,
        cmplFlag: processStatusSelect,
        nstlSttsCode: workStatusSelect,
        // dcmnDntfNmbr: orderNoInput,
        dcmnScrnNmbr: orderNoInput,
        pageSize: searchResultTablePaging.rowPerPage,
        currPageIdx: searchResultTablePagingCurrentPageRef.current,
        abcde: tmpLimitDataUserId,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          // 검색 결과 테이블에 적용함
          setSearchResultTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list,
            }),
          );

          /* 전체 현황 */

          // 현황의 총합을 계산함
          let tmpSumCnt: number =
            +data.data.workSttVo?.planNcmpCnt +
            +data.data.workSttVo?.planCmplCnt +
            +data.data.workSttVo?.blngNcmpCnt +
            +data.data.workSttVo?.blngCmplCnt;
          data.data.workSttVo['sumCnt'] = tmpSumCnt;

          // 진척도의 실적 비율을 계산함
          let tmpResultRatio: number =
            ((+data.data.workSttVo?.blngNcmpCnt +
              +data.data.workSttVo?.blngCmplCnt) /
              +data.data.workSttVo?.sumCnt) *
            100;
          data.data.workSttVo['resultRatio'] = tmpResultRatio;

          // 진척도의 Billing 비율을 계산함
          let tmpBillingRatio: number =
            (+data.data.workSttVo?.blngCmplCnt /
              (+data.data.workSttVo?.blngNcmpCnt +
                +data.data.workSttVo?.blngCmplCnt)) *
            100;
          data.data.workSttVo['billingRatio'] = tmpBillingRatio;

          // 전체 현황, 전체 진척도에 적용함
          setSearchResultWorkSttVo(data.data.workSttVo);

          console.log('> data.data.workSttVo:', data.data.workSttVo);

          /* 검색 결과에 따른 현황 */

          // 현황 기본값을 정의함
          let tmpWorkSttVo: any = {
            sumCnt: 0,
            planNcmpCnt: 0,
            planCmplCnt: 0,
            blngNcmpCnt: 0,
            blngCmplCnt: 0,
            resultRatio: 0,
            billingRatio: 0,
          };

          if (data.data.list.length > 0) {
            tmpWorkSttVo.planNcmpCnt = data.data.list[0]?.planNcmpCnt;
            tmpWorkSttVo.planCmplCnt = data.data.list[0]?.planCmplCnt;
            tmpWorkSttVo.blngNcmpCnt = data.data.list[0]?.blngNcmpCnt;
            tmpWorkSttVo.blngCmplCnt = data.data.list[0]?.blngCmplCnt;

            // 현황의 총합을 계산함
            let tmpSumCnt2: number =
              +tmpWorkSttVo.planNcmpCnt +
              +tmpWorkSttVo.planCmplCnt +
              +tmpWorkSttVo.blngNcmpCnt +
              +tmpWorkSttVo.blngCmplCnt;
            tmpWorkSttVo.sumCnt = tmpSumCnt2;

            // 진척도의 실적 비율을 계산함
            let tmpResultRatio2: number =
              ((+tmpWorkSttVo.blngNcmpCnt + +tmpWorkSttVo.blngCmplCnt) /
                +tmpWorkSttVo.sumCnt) *
              100;
            tmpWorkSttVo.resultRatio = tmpResultRatio2;

            // 진척도의 Billing 비율을 계산함
            let tmpBillingRatio2: number =
              (+tmpWorkSttVo.blngCmplCnt /
                (+tmpWorkSttVo.blngNcmpCnt + +tmpWorkSttVo.blngCmplCnt)) *
              100;
            tmpWorkSttVo.billingRatio = tmpBillingRatio2;
          }

          // 전체 현황, 전체 진척도에 적용함
          setSearchResultWorkSttVo2(tmpWorkSttVo);
        }

        // 테이블을 페이징함
        setSearchResultTablePaging((pre: ITablePaging) => ({
          ...pre,
          totalPage: data.data.page.totPageCnt,
          totalRow: data.data.page.totItemCnt,
        }));

        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      })
      .catch((error: any) => {
        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 검색 폼 그리드 > 검색어 > 셀렉트의 아이템을 초기화함
    initSearchCategorySelect();

    // 검색 폼 그리드 > 담당PM > 셀렉트의 아이템을 초기화함
    initPmNameSelect();

    // 검색 폼 그리드 > 진행상태 > 셀렉트의 아이템을 초기화함
    initProcessStatusSelect();

    // 검색 폼 그리드 > 작업상태 > 셀렉트의 아이템을 초기화함
    initWorkStatusSelect();

    return () => {};
  }, []);

  useEffect(() => {
    if (!loginUser.id) {
      return;
    }

    // 검색 결과 테이블을 초기화함
    initSearchResultTable();

    // 검수자/협력사 별로 명시적으로 표시함
    if (
      // _.isEqual(loginUser.thrtDvsnCode, 'MNGR') || // 관리자
      (_.isEqual(loginUser.nstlCtgrCode, 'WORK_NSPC') || // 작업검수자
        _.isEqual(loginUser.nstlCtgrCode, 'PM_NSPC')) && // PM 검수자
      !_.isEqual(loginUser.cmpnCode, 'PRTN')
    ) {
      // 검색 폼 그리드 > 작업유형 > 라디오의 아이템을 초기화함
      setFuncHiddenFlag(false); // 작업검수자, PM 검수자만 가능한 검색 기능 활성화 Flag
      setIsEslNstl('Y'); // 진행중 목록에 협력사 구분 없어짐
      setIsNtwrCnst('Y');
    } else {
      // 네트워크 / ESL 설치 협력사는 유형 선택 제외
      if (_.isEqual(loginUser.cmpnCode, 'PRTN')) {
        // 협력사는 사용자 정보에 맞는 데이타만 검색 가능
        if (_.isEqual(loginUser.nstlCtgrCode, 'NSTL_PRTN')) {
          // 협력사라도 타 작업도 일단 확인은 가능
          setIsEslNstl('Y');
          setIsNtwrCnst('Y');
          // setIsEslNstl('Y');
          // setIsNtwrCnst('N');
          setFuncHiddenFlag(true);
        } else if (_.isEqual(loginUser.nstlCtgrCode, 'NTWR_PRTN')) {
          // setIsEslNstl('N');
          // 협력사라도 타 작업도 일단 확인은 가능
          setIsEslNstl('Y');
          setIsNtwrCnst('Y');
          // setIsNtwrCnst('Y');
          setFuncHiddenFlag(true);
        } else {
          setIsEslNstl('N');
          setIsNtwrCnst('N');
          setFuncHiddenFlag(true);
        }
      }
    }

    return () => {};
  }, [
    loginUser.id,
    loginUser.cmpnCode,
    loginUser.thrtDvsnCode,
    loginUser.nstlCtgrCode,
  ]);

  // 검색 결과 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (searchResultTable.column.length === 0) {
      return;
    }

    // 검색 결과 테이블 데이터를 불러옴
    getSearchResultTableData();

    return () => {};
  }, [searchResultTable.column]);

  // 년월이 변경될 때 실행함
  useEffect(() => {
    if (!loginUser.id) {
      return;
    }

    // ESL 진척현황을 불러옴
    getEslStatus();

    return () => {};
  }, [loginUser.id, pmNameSelect]);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220305Table') > -1) {
      // 검색 결과 테이블 데이터를 불러옴
      getSearchResultTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220305Table');
    }

    return () => {};
  }, [refreshList]);

  // 주소줄의 인자가 변경될 때 실행함
  useEffect(() => {
    if (location === undefined || location === null) {
      return;
    }

    const params = new URLSearchParams(location.search);
    const searchCategory = params.get('search-category') || '';
    const search = params.get('search') || '';

    // 검색 폼 그리드 > 검색어 > 셀렉트의 값에 적용함
    if (searchCategory !== '') {
      setSearchCategorySelect(searchCategory);
    }

    // 검색 폼 그리드 > 검색어 > 검색어 입력에 적용함
    if (search !== '') {
      setSearchInput(search);
    }

    // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
    handleSearchButton_onClick();

    return () => {};
  }, [location]);

  // 진행상태 셀렉트가 변경됐을 때 실행함
  useEffect(() => {
    if (isChangeStatusRef.current === false) {
      return;
    }

    // 검색 폼 그리드 > 검색어 > 검색 버튼을 클릭함
    handleSearchButton_onClick();

    isChangeStatusRef.current = false;

    return () => {};
  }, [processStatusSelect]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-5">
        {/* 검색 폼 그리드 */}
        <PbFormGrid cols={2}>
          <PbFormGridCol label="검색어" colSpan={2}>
            {/* 셀렉트 */}
            <Select
              onChange={handleSearchCategorySelect_onChange}
              data={searchCategorySelectItem}
              value={searchCategorySelect}
            />

            {/* 텍스트 입력 */}
            <TextInput
              placeholder="검색어를 입력하세요."
              onChange={handleSearchInput_onChange}
              onKeyUp={handleSearchInput_onKeyUp}
              value={searchInput}
              className="w-1/2"
            />

            {/* 버튼 */}
            <div className="flex justify-center items-center space-x-2">
              {/* 버튼 */}
              <Button
                color="indigo"
                radius="xl"
                onClick={handleSearchButton_onClick}
              >
                검색
              </Button>

              {/* 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                onClick={handleSearchCancelButton_onChange}
              >
                검색 조건 초기화
              </Button>

              {/* 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                onClick={handleShowSearchDetailButton_onChange}
                className="hidden"
              >
                {hideDetailSearch ? (
                  <>
                    상세검색 열기
                    <div className="ml-1 flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'caret-down']}
                        className="w-5 h-5"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    상세검색 닫기
                    <div className="ml-1 flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['fas', 'caret-up']}
                        className="w-5 h-5"
                      />
                    </div>
                  </>
                )}
              </Button>
            </div>
          </PbFormGridCol>

          <PbFormGridCol label="담당PM">
            {/* 셀렉트 */}
            <Select
              onChange={handlePmNameSelect_onChange}
              data={pmNameSelectItem}
              value={pmNameSelect}
              className="w-full"
              placeholder="검색어를 입력하시거나 목록을 선택하세요."
              searchable={true}
              clearable={true}
            />
          </PbFormGridCol>

          <PbFormGridCol label="진행상태">
            {/* 셀렉트 */}
            <Select
              onChange={handleProcessStatusSelect_onChange}
              data={processStatusSelectItem}
              value={processStatusSelect}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="작업상태">
            {/* 셀렉트 */}
            <Select
              onChange={handleWorkStatusSelect_onChange}
              data={workStatusSelectItem}
              value={workStatusSelect}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="주문번호">
            {/* 텍스트 입력 */}
            <TextInput
              placeholder="주문번호를 입력하세요."
              onChange={handleOrderNoInput_onChange}
              onKeyUp={handleOrderNoInput_onKeyUp}
              value={orderNoInput}
              className="w-full"
            />

            {/* 버튼 */}
            {/*<div className="flex justify-center items-center space-x-2">*/}
            {/*  /!* 버튼 *!/*/}
            {/*  <Button*/}
            {/*    color="indigo"*/}
            {/*    radius="xl"*/}
            {/*    onClick={handleOrderNoButton_onClick}*/}
            {/*  >*/}
            {/*    검색*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </PbFormGridCol>

          <PbFormGridCol isBlank={true}></PbFormGridCol>
        </PbFormGrid>

        {/* 사용안함 */}
        {/* 전체 진척 현황, 진척도 */}
        <PbSection label="" className="hidden mt-2">
          <div className="h-full flex justify-between items-center space-x-5">
            {/* 왼쪽 테이블 */}
            <div className="w-3/5">
              <div className="table-wrapper">
                <table className="table-list">
                  <colgroup className="mobile-none">
                    <col />
                    <col />
                    <col />
                  </colgroup>

                  <thead>
                    <tr>
                      <th rowSpan={2} className="w-1/5 border-r leading-none">
                        <div className="flex justify-center items-center space-x-2">
                          <span>전체</span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              해당 프로젝트의 전체 주문 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        colSpan={2}
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <span>
                          설치
                          <br />
                          미완료
                        </span>
                      </th>
                      <th
                        colSpan={2}
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 leading-5"
                      >
                        <span>
                          설치
                          <br />
                          완료
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            계획
                            <br />
                            미수립
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              EMS에 작업 일정 및 협력사를 등록했지만, PM 검수가
                              완료되지 않은 주문 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            계획
                            <br />
                            수립
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              EMS에 작업 일정 및 협력사를 등록하지 않은 주문
                              건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            Billing
                            <br />
                            미완료
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              PM 검수 완료 처리가 된 주문 중, 빌링 처리가 되지
                              않은 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            Billing
                            <br />
                            완료
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              PM 검수 완료 처리가 된 주문 중, 빌링 처리까지
                              완료된 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="flex-none h-12">
                        {/* 전체 */}
                        <span>[]{searchResultWorkSttVo?.sumCnt}</span>
                      </td>
                      <td className="flex-none h-12">
                        {/* 설치미완료 > 계획미수립 */}
                        <span>{searchResultWorkSttVo?.planNcmpCnt}</span>
                      </td>
                      <td className="flex-none h-12">
                        {/* 설치미완료 > 계획수립 */}
                        <span>{searchResultWorkSttVo?.planCmplCnt}</span>
                      </td>
                      <td className="flex-none h-12">
                        {/* 설치완료 > Billing미완료 */}
                        <span>{searchResultWorkSttVo?.blngNcmpCnt}</span>
                      </td>
                      <td className="flex-none h-12">
                        {/* 설치완료 > Billing완료 */}
                        <span>{searchResultWorkSttVo?.blngCmplCnt}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* 오른쪽 테이블 */}
            <div className="w-2/5">
              <div className="table-wrapper">
                <table className="table-list">
                  <colgroup className="mobile-none">
                    <col />
                    <col />
                    <col />
                  </colgroup>

                  <thead>
                    <tr>
                      <th rowSpan={2} className="w-1/5 border-r leading-none">
                        <span>진척도</span>
                      </th>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-1/5 !bg-white leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>실적</span>

                          <PbHelpPopover width={250}>
                            <span className="text-sm">
                              (빌링미완료 + 빌링완료) / 전체
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-3/5 !bg-white leading-none"
                      >
                        <div className="w-full pr-5 flex justify-center items-center">
                          {/* 차트 */}
                          <div className="grow">
                            <Progress
                              size="xl"
                              radius="xs"
                              color="indigo"
                              value={
                                isNaN(
                                  +searchResultWorkSttVo?.resultRatio?.toFixed(
                                    1,
                                  ),
                                )
                                  ? 0
                                  : +searchResultWorkSttVo?.resultRatio?.toFixed(
                                      1,
                                    )
                              }
                            />
                          </div>

                          {/* 퍼센트 */}
                          <div className="flex-none w-14 flex justify-end items-center">
                            <span>
                              {isNaN(
                                +searchResultWorkSttVo?.resultRatio?.toFixed(1),
                              )
                                ? 0
                                : +searchResultWorkSttVo?.resultRatio?.toFixed(
                                    1,
                                  )}
                              %
                            </span>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-1/5 !bg-white leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>Billing</span>

                          <PbHelpPopover width={250}>
                            <span className="text-sm">
                              빌링완료 / (빌링미완료 + 빌링완료)
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-3/5 !bg-white leading-none"
                      >
                        <div className="w-full pr-5 flex justify-center items-center">
                          {/* 차트 */}
                          <div className="grow">
                            <Progress
                              size="xl"
                              radius="xs"
                              color="lime"
                              value={
                                +(
                                  searchResultWorkSttVo?.billingRatio || 0
                                ).toFixed(1)
                              }
                            />
                          </div>

                          {/* 퍼센트 */}
                          <div className="flex-none w-14 flex justify-end items-center">
                            <span>
                              {
                                +(
                                  searchResultWorkSttVo?.billingRatio || 0
                                ).toFixed(1)
                              }
                              %
                            </span>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </PbSection>

        {/* 검색 조건에 따른 진척 현황, 진척도 */}
        <PbSection label="" className="mt-2">
          <div className="h-full flex justify-between items-center space-x-5">
            {/* 왼쪽 테이블 */}
            <div className="w-3/5">
              <div className="table-wrapper">
                <table className="table-list">
                  <colgroup className="mobile-none">
                    <col />
                    <col />
                    <col />
                  </colgroup>

                  <thead>
                    <tr>
                      <th rowSpan={2} className="w-1/5 border-r leading-none">
                        <div className="flex justify-center items-center space-x-2">
                          <span>전체</span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              해당 프로젝트의 전체 주문 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        colSpan={2}
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <span>
                          설치
                          <br />
                          미완료
                        </span>
                      </th>
                      <th
                        colSpan={2}
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 leading-5"
                      >
                        <span>
                          설치
                          <br />
                          완료
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            계획
                            <br />
                            미수립
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              EMS에 작업 일정 및 협력사를 등록했지만, PM 검수가
                              완료되지 않은 주문 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            계획
                            <br />
                            수립
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              EMS에 작업 일정 및 협력사를 등록하지 않은 주문
                              건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 border-r leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            Billing
                            <br />
                            미완료
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              PM 검수 완료 처리가 된 주문 중, 빌링 처리가 되지
                              않은 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '3.5rem' }}
                        className="!py-0 w-1/5 leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>
                            Billing
                            <br />
                            완료
                          </span>

                          <PbHelpPopover>
                            <span className="text-sm">
                              PM 검수 완료 처리가 된 주문 중, 빌링 처리까지
                              완료된 건입니다.
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td
                        onClick={() => {
                          isChangeStatusRef.current = true;
                          setProcessStatusSelect('');
                        }}
                        className="flex-none h-12 cursor-pointer"
                      >
                        {/* 전체 */}
                        <span>{searchResultWorkSttVo2?.sumCnt}</span>
                      </td>
                      <td
                        onClick={() => {
                          isChangeStatusRef.current = true;
                          setProcessStatusSelect('PLAN_NCMP');
                        }}
                        className="flex-none h-12 cursor-pointer"
                      >
                        {/* 설치미완료 > 계획미수립 */}
                        <span>{searchResultWorkSttVo2?.planNcmpCnt}</span>
                      </td>
                      <td
                        onClick={() => {
                          isChangeStatusRef.current = true;
                          setProcessStatusSelect('PLAN_CMPL');
                        }}
                        className="flex-none h-12 cursor-pointer"
                      >
                        {/* 설치미완료 > 계획수립 */}
                        <span>{searchResultWorkSttVo2?.planCmplCnt}</span>
                      </td>
                      <td
                        onClick={() => {
                          isChangeStatusRef.current = true;
                          setProcessStatusSelect('BLNG_NCMP');
                        }}
                        className="flex-none h-12 cursor-pointer"
                      >
                        {/* 설치완료 > Billing미완료 */}
                        <span>{searchResultWorkSttVo2?.blngNcmpCnt}</span>
                      </td>
                      <td
                        onClick={() => {
                          isChangeStatusRef.current = true;
                          setProcessStatusSelect('BLNG_CMPL');
                        }}
                        className="flex-none h-12 cursor-pointer"
                      >
                        {/* 설치완료 > Billing완료 */}
                        <span>{searchResultWorkSttVo2?.blngCmplCnt}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* 오른쪽 테이블 */}
            <div className="w-2/5">
              <div className="table-wrapper">
                <table className="table-list">
                  <colgroup className="mobile-none">
                    <col />
                    <col />
                    <col />
                  </colgroup>

                  <thead>
                    <tr>
                      <th rowSpan={2} className="w-1/5 border-r leading-none">
                        <span>진척도</span>
                      </th>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-1/5 !bg-white leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>실적</span>

                          <PbHelpPopover width={250}>
                            <span className="text-sm">
                              (빌링미완료 + 빌링완료) / 전체
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-3/5 !bg-white leading-none"
                      >
                        <div className="w-full pr-5 flex justify-center items-center">
                          {/* 차트 */}
                          <div className="grow">
                            <Progress
                              size="xl"
                              radius="xs"
                              color="indigo"
                              value={
                                isNaN(
                                  +searchResultWorkSttVo2?.resultRatio?.toFixed(
                                    1,
                                  ),
                                )
                                  ? 0
                                  : +searchResultWorkSttVo2?.resultRatio?.toFixed(
                                      1,
                                    )
                              }
                            />
                          </div>

                          {/* 퍼센트 */}
                          <div className="flex-none w-14 flex justify-end items-center">
                            <span>
                              {isNaN(
                                +searchResultWorkSttVo2?.resultRatio?.toFixed(
                                  1,
                                ),
                              )
                                ? 0
                                : +searchResultWorkSttVo2?.resultRatio?.toFixed(
                                    1,
                                  )}
                              %
                            </span>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-1/5 !bg-white leading-5"
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <span>Billing</span>

                          <PbHelpPopover width={250}>
                            <span className="text-sm">
                              빌링완료 / (빌링미완료 + 빌링완료)
                            </span>
                          </PbHelpPopover>
                        </div>
                      </th>
                      <th
                        style={{ height: '5.25rem' }}
                        className="w-3/5 !bg-white leading-none"
                      >
                        <div className="w-full pr-5 flex justify-center items-center">
                          {/* 차트 */}
                          <div className="grow">
                            <Progress
                              size="xl"
                              radius="xs"
                              color="lime"
                              value={
                                +(
                                  searchResultWorkSttVo2?.billingRatio || 0
                                ).toFixed(1)
                              }
                            />
                          </div>

                          {/* 퍼센트 */}
                          <div className="flex-none w-14 flex justify-end items-center">
                            <span>
                              {
                                +(
                                  searchResultWorkSttVo2?.billingRatio || 0
                                ).toFixed(1)
                              }
                              %
                            </span>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </PbSection>

        {/* 테이블 */}
        <div className="space-y-3">
          <div className="flex justify-between items-center space-x-5">
            <div className="space-x-2">
              {/* 버튼 */}
              {loginUser.cmpnCode !== 'LVYN' && (
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleSearchResultTableExportExcelButton_onClick}
                >
                  엑셀 다운로드
                </Button>
              )}

              {/* 버튼 */}
              {/*<Button*/}
              {/*  variant="outline"*/}
              {/*  color="gray"*/}
              {/*  radius="xl"*/}
              {/*  onClick={handleSearchResultTableRerunBatchButton_onClick}*/}
              {/*>*/}
              {/*  배치 재실행*/}
              {/*</Button>*/}
            </div>

            <div className="flex justify-center items-center space-x-5">
              {/* 로딩바 */}
              {searchResultTableLoading && (
                <div className="flex justify-center items-center space-x-2">
                  <FontAwesomeIcon
                    icon={['fas', 'circle-notch']}
                    className="w-6 h-6 text-blue-600 animate-spin"
                  />

                  <span className="text-base text-blue-600 font-bold">
                    Loading...
                  </span>
                </div>
              )}

              <div className="flex justify-center items-center">
                <span className="text-base text-gray-600 font-bold">
                  총 {searchResultTablePaging.totalRow}건
                </span>
              </div>
            </div>
          </div>

          {/* 테이블 */}
          <div className="w-full h-160">
            <PbAgGridReact
              columnDefs={searchResultTable.column}
              rowData={searchResultTable.data}
              loading={searchResultTableLoading}
              setLoading={setSearchResultTableLoading}
              onRowDoubleClicked={handleSearchResultTable_onRowDoubleClicked}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="single"
              sizeColumnsToFit={true}
              visiblePaging={true}
              paging={{
                totalPage: searchResultTablePaging.totalPage,
                currentPage: searchResultTablePaging.currentPage,
              }}
              onChangePage={(event: any) => {
                // 테이블의 페이지를 변경함
                searchResultTablePagingCurrentPageRef.current = event;
                setSearchResultTablePaging((pre: ITablePaging) => ({
                  ...pre,
                  currentPage: searchResultTablePagingCurrentPageRef.current,
                }));

                // 검색 결과 테이블 데이터를 불러옴
                getSearchResultTableData();
              }}
              className="ag-grid-multiple-header-name"
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220305Page;
