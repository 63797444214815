import axios, { AxiosResponse } from 'axios';
import { IApiListResult, IApiResult } from '../interfaces/app.interface';
import api from '../utils/axios.util';
import moment from 'moment/moment';

/**
 * NSTL: 설치
 */

/**
 * 빌링 목록 조회
 * GET /api/nstl/billing
 * @param params currPageIdx 현재페이지
 * @param params endDate 검수 완료 검색 종료일
 * @param params pageSize 페이지 사이즈
 * @param params searchKeyword 검색어
 * @param params searchType 검색타입
 * @param params stDate 검수 완료 검색 시작일
 */
export const getNstlBilling = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/nstl/billing',
    params: {
      pageSize: params.pageSize || 1000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재 페이지
      searchType: params.searchType || '', // 검색 구분
      searchKeyword: params.searchKeyword || '', // 검색어
      stDate: params.stDate || '', // 검수 완료 검색 시작일
      endDate: params.endDate || '', // 검수 완료 검색 종료일
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 빌링 상세
 * GET /api/nstl/billing/{dcmnDntfNmbr}
 * @param params dcmnDntfNmbr  문서 고유번호(주문 번호)
 */
export const getNstlBillingDetail = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nstl/billing', params.dcmnDntfNmbr].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통 - 프로젝트 목록 조회
 * GET /api/nstl/cntr-prjcs
 * @param params currPageIdx 현재페이지
 */
export const getNstlCntrPrjcs = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nstl/cntr-prjcs'].join(''),
    params: {
      prjcName: params.prjcName || '', // 프로젝트 이름
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통 - 품목 목록 조회(팝업용)
 * GET /api/nstl/items/{dcmnDntfNmbr}
 * @param params currPageIdx 현재페이지
 * @param params dcmnDntfNmbr 주문번호
 * @param params pageSize 페이지 사이즈
 * @param params searchKeyword 검색어
 */
export const getNstlItems = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: ['/api/nstl/items', params.dcmnDntfNmbr].join('/'),
    params: {
      pageSize: params.pageSize || 1000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재 페이지
      searchKeyword: params.searchKeyword || '', // 검색어
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통 - 품목 목록 조회(재고 이동 요청 selectbox)
 * GET /api/nstl/nvnt-trns-items/{dcmnDntfNmbr}
 * @param params 문서 번호
 */
export const getNstlNvntTrnsItems = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nstl/nvnt-trns-items', params.dcmnDntfNmbr].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 미등록 작업 등록
 * POST /api/nstl/not-rgst
 * @param params dcmnDntfNmbr 주문번호
 * @param params nstlList.splrId 협력사 아이디
 * @param params nstlList.splrTypeCode 협력사 구분 코드(ESL : ESL_NSTL, NETWORK : NTWR_CNST)
 * @param params nstlList.ttl 내용
 * @param params nstlList.workEndDttm 작업 종료일
 * @param params nstlList.workStrtDttm 작업 시작일
 */
export const postNstlNotRgst = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/nstl/not-rgst',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postNstlNotRgst Error:', postNstlNotRgst);
    });

  return result;
};

/**
 * 공통 - 협력사 수정
 * PUT /api/nstl/prtns/{dcmnDntfNmbr}
 * @param params dcmnDntfNmbr 주문번호
 * @param params installPrtnParamVoList 작업내역 목록(Network:NTWR_CNST, ESL:ESL_NSTL)
 */
export const putNstlPrtns = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/nstl/prtns', params.dcmnDntfNmbr].join('/'),
    data: params.installPrtnParamVoList,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putNstlPrtns Error:', putNstlPrtns);
    });

  return result;
};

/**
 * 공통 - 협력사 목록 조회(팝업용)
 * GET /api/nstl/prtns
 */
export const getNstlPrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/nstl/prtns',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> getNstlPrtn Error:', getNstlPrtn);
    });

  return result;
};

/**
 * 공통 - 협력사(팀) 목록 조회(팝업용)
 * GET /api/nstl/prtns_teams
 */
export const getNstlPrtnTeams = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/nstl/prtns_teams',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> getNstlPrtnTeams Error:', getNstlPrtnTeams);
    });

  return result;
};

/**
 * 미등록 작업 목록
 * GET /api/nstl/not-rgsts
 * @param params currPageIdx 현재페이지
 * @param params pageSize 페이지 사이즈
 * @param params allNotRgst 전체 미등록(*)
 * @param params eslNotRgst ESL 설치 정보만 미등록(*)
 * @param params isShowAll 전체 보기(*)
 * @param params ntwrNotRgst 네트워크 공사 정보만 미등록(*)
 * @param params prjcCode 프로젝트 코드(*)
 * @param params workBaseYearMonth 작업 기준 년월
 */
export const getNotRgsts = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/nstl/not-rgsts',
    params: {
      pageSize: params.pageSize || 1000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재 페이지
      allNotRgst: params.allNotRgst || 'N', // 전체 미등록(Y, N)(*)
      eslNotRgst: params.eslNotRgst || 'N', // ESL 설치 정보만 미등록(Y, N)(*)
      isShowAll: params.isShowAll || 'Y', // 전체 보기(Y, N)(*)
      ntwrNotRgst: params.ntwrNotRgst || 'N', // 네트워크 공사 정보만 미등록(Y, N)(*)
      prjcCode: params.prjcCode || '', // 프로젝트 코드(*)
      workBaseYearMonth: params.workBaseYearMonth || '', // 작업 기준 년월
      pmId: params.pmId, // 담당PM
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 미등록 작업 엑셀 다운로드
 * GET /api/nstl/not-rgst-excel
 * @param params allNotRgst 전체 미등록(*)
 * @param params eslNotRgst ESL 설치 정보만 미등록(*)
 * @param params isShowAll 전체 보기(*)
 * @param params ntwrNotRgst 네트워크 공사 정보만 미등록(*)
 * @param params prjcCode 프로젝트 코드(*)
 * @param params workBaseYearMonth 작업 기준 년월
 */
export const getNotRgstExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/nstl/not-rgst-excel',
    responseType: 'blob',
    params: {
      allNotRgst: params.allNotRgst || 'N', // 전체 미등록(*)
      eslNotRgst: params.eslNotRgst || 'N', // ESL 설치 정보만 미등록(*)
      isShowAll: params.isShowAll || 'Y', // 전체 보기(*)
      ntwrNotRgst: params.ntwrNotRgst || 'N', // 네트워크 공사 정보만 미등록(*)
      prjcCode: params.prjcCode || '', // 프로젝트 코드(*)
      workBaseYearMonth: params.workBaseYearMonth || '', // 작업 기준 년월
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 미등록 작업 특정일 조회
 * GET /api/nstl/not-rgst-shop/{yyyymmdd}
 * @param params allNotRgst 전체 미등록(*)
 * @param params eslNotRgst ESL 설치 정보만 미등록(*)
 * @param params ntwrNotRgst 네트워크 공사 정보만 미등록(*)
 * @param params prjcCode 프로젝트 코드(*)
 * @param params yyyymmdd 검색일자(*)
 */
export const getNstlNotRgstShop = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: ['/api/nstl/not-rgst-shop', params.yyyymmdd].join('/'),
    params: {
      allNotRgst: params.allNotRgst || '', // 전체 미등록(*)
      eslNotRgst: params.eslNotRgst || '', // ESL 설치 정보만 미등록(*)
      isShowAll: params.isShowAll || '', // 전체 보기(*)
      ntwrNotRgst: params.ntwrNotRgst || '', // 네트워크 공사 정보만 미등록(*)
      prjcCode: params.prjcCode || '', // 프로젝트 코드(*)
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 미등록 작업 상세
 * GET /api/nstl/not-rgst/{dcmnDntfNmbr}
 * @param params dcmnDntfNmbr 문서 고유번호(주문 번호)
 */
export const getNstlNotRgst = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nstl/not-rgst', params.dcmnDntfNmbr].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 진행중 작업 목록 조회
 * GET /api/nstl/prcds
 * @param params currPageIdx 현재페이지
 * @param params endDate 검수 완료 검색 종료일
 * @param params pageSize 페이지 사이즈
 * @param params isEslNstl ESL 설치유형
 * @param params isNtwrCnst 네트워크 공사 유형
 * @param params isShowAll 전체 보기
 * @param params nstlSttsCode 설치상태 코드
 * @param params prjcCode 프로젝트 코드
 * @param params splrId 협력사 아이디
 * @param params workBaseYearMonth 작업 기준 년월
 */
export const getNstlPrcds = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/nstl/prcds',
    params: {
      pageSize: params.pageSize || 1000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재 페이지
      isEslNstl: params.isEslNstl || '', // ESL 설치유형
      isNtwrCnst: params.isNtwrCnst || '', // 네트워크 공사 유형
      isShowAll: params.isShowAll || '', // 전체 보기
      nstlSttsCode: params.nstlSttsCode || '', // 설치상태 코드
      prjcCode: params.prjcCode || '', // 프로젝트 코드
      splrId: params.splrId || '', // 협력사 아이디
      workBaseYearMonth: params.workBaseYearMonth || '', // 작업 기준 년월
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 진행중 작업 목록 엑셀 다운로드
 * GET /api/nstl/prcd-excel
 * @param params currPageIdx 현재페이지
 * @param params endDate 검수 완료 검색 종료일
 * @param params pageSize 페이지 사이즈
 * @param params isEslNstl ESL 설치유형
 * @param params isNtwrCnst 네트워크 공사 유형
 * @param params isShowAll 전체 보기
 * @param params nstlSttsCode 설치상태 코드
 * @param params prjcCode 프로젝트 코드
 * @param params splrId 협력사 아이디
 * @param params workBaseYearMonth 작업 기준 년월
 * @param params PM 담당자 아이디
 */
export const getNstlPrcdExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/nstl/prcd-excel',
    responseType: 'blob',
    params: {
      pageSize: params.pageSize || 100000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재 페이지
      isEslNstl: params.isEslNstl || 'Y', // ESL 설치유형
      isNtwrCnst: params.isNtwrCnst || 'Y', // 네트워크 공사 유형
      isShowAll: params.isShowAll || 'Y', // 전체 보기
      nstlSttsCode: params.nstlSttsCode || '', // 설치상태 코드
      prjcCode: params.prjcCode || '', // 프로젝트 코드
      splrId: params.splrId || '', // 협력사 아이디
      workBaseYearMonth: params.workBaseYearMonth || '', // 작업 기준 년월
      pmId: params.pmId || '', // PM 담당자 아이디
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 진행중 작업 상세 조회
 * GET /api/nstl/prcd/{dcmnDntfNmbr}
 * @param params dcmnDntfNmbr 문서 고유번호(주문 번호)
 */
export const getNstlPrcd = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nstl/prcd', params.dcmnDntfNmbr].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 진행중 작업 상세 조회(팀 포함 협력사 기준)
 * GET /api/nstl/prcd/{dcmnDntfNmbr}/{userId}/{cmpnCode}
 * @param params dcmnDntfNmbr 문서 고유번호(주문 번호)
 */
export const getNstlPrcdPrtn = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: [
      '/api/nstl/prcd',
      params.dcmnDntfNmbr,
      params.userId,
      params.cmpnCode,
    ].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통 - 진행 상황(댓글)
 * GET /api/nstl/prgr
 * @param dcmnDntfNmbr 문서 고유 번호(주문번호)
 */
export const getNstlPrgr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/nstl/prgr',
    params: {
      dcmnDntfNmbr: params.dcmnDntfNmbr || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> getNstlPrgr:', error);
    });

  return result;
};

/**
 * PM 설치 일정 상세
 * GET /api/nstl/pm-nspc-detail/{dcmnDntfNmbr}
 */
export const getNstlPmNspcDetail = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nstl/pm-nspc-detail', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> getNstlPmNspcDetail Error:', getNstlPmNspcDetail);
    });

  return result;
};

/**
 * PM 검수 상세 등록/수정(PM 검수 완료)
 * POST /api/nstl/pm-nspc-detail
 * @param params dcmnDntfNmbr 주문번호
 * @param params vrsnNfrm 버전 정보
 * @param params fileList 설치 확인서 파일
 * @param params fileDscrList 설치 확인서 설명
 * @param params bfFileIdList 기존 설치 확인서 파일 아이디
 * @param params bfFileDscrList 기존 설치 확인서 설명(파일 아이디와 개수 일치 필요)
 */
export const postNstlPmNspcDetail = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/nstl/pm-nspc-detail',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      dcmnDntfNmbr: params.dcmnDntfNmbr || '',
      vrsnNfrm: params.vrsnNfrm || '',
      fileList: params.fileList || [],
      fileDscrList: params.fileDscrList || [],
      bfFileIdList: params.bfFileIdList || [],
      bfFileDscrList: params.bfFileDscrList || [],
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postNstlPmNspcDetail Error:', postNstlPmNspcDetail);
    });

  return result;
};

/**
 * PM 검수 취소
 * PUT /api/nstl/pm-nspc-cancel/{dcmnDntfNmbr}
 * @param params dcmnDntfNmbr 주문번호
 */
export const putNstlPmNspcCancel = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/nstl/pm-nspc-cancel', params.dcmnDntfNmbr].join('/'),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putNstlPmNspcCancel Error:', putNstlPmNspcCancel);
    });

  return result;
};

/**
 * PM 검수 대기 현황 목록
 * GET /api/nstl/pm-nspc-stnds
 * @param params currPageIdx 현재페이지
 * @param params pageSize 페이지 사이즈
 * @param params isShowAll 전체 보기
 * @param params nstlSttsCode 설치상태 코드
 * @param params prjcCode 프로젝트 코드
 * @param params workBaseYearMonth 작업 기준 년월
 * @param params pmId PM 담당자 아이디
 */
export const getNstlPmNspcStnds = async (params: any) => {
  // 결과를 정의함
  let result: IApiListResult = {
    code: '',
    message: '',
    data: {
      page: {
        currPageIdx: 0,
        isFirstPage: true,
        isLastPage: true,
        offsetIndex: 0,
        pageBottomSize: 0,
        pageSize: 0,
        totItemCnt: 0,
        totPageCnt: 0,
      },
      list: [],
    },
  };

  await api({
    method: 'GET',
    url: '/api/nstl/pm-nspc-stnds',
    params: {
      pageSize: params.pageSize || 100000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재 페이지
      isShowAll: params.isShowAll || '', // 전체 보기
      nstlSttsCode: params.nstlSttsCode || '', // 설치상태 코드
      prjcCode: params.prjcCode || '', // 프로젝트 코드
      workBaseYearMonth: params.workBaseYearMonth || '', // 작업 기준 년월
      pmId: params.pmId || '', // PM 담당자 아이디
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * PM검수대기 현황 엑셀 다운로드
 * GET /api/nstl/pm-nspc-stnd-excel
 * @param params allNotRgst 전체 미등록(*)
 * @param params eslNotRgst ESL 설치 정보만 미등록(*)
 * @param params isShowAll 전체 보기(*)
 * @param params ntwrNotRgst 네트워크 공사 정보만 미등록(*)
 * @param params prjcCode 프로젝트 코드(*)
 * @param params workBaseYearMonth 작업 기준 년월
 * @param params pmId PM 담당자 아이디
 */
export const getPmNspcStndExcel = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/nstl/pm-nspc-stnd-excel',
    responseType: 'blob',
    params: {
      pageSize: params.pageSize || 10000, // 페이지 사이즈
      currPageIdx: params.currPageIdx || '1', // 현재 페이지
      isShowAll: params.isShowAll || '', // 전체 보기
      nstlSttsCode: params.nstlSttsCode || '', // 설치상태 코드
      prjcCode: params.prjcCode || '', // 프로젝트 코드
      workBaseYearMonth: params.workBaseYearMonth || '', // 작업 기준 년월
      pmId: params.pmId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통 - 재고 이전 요청 취소
 * PUT /api/work/nvnt-cncl/{nvntDcmnDntfNmbr}
 * @param params docEntry 재고이전 문서번호
 */
export const putNstlNvntCncl = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/work/nvnt-cncl', params.nvntDcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putNstlNvntCncl Error:', putNstlNvntCncl);
    });

  return result;
};

/**
 * 진행중 작업 목록 조회
 * GET /api/nstl/prcds
 * @param params.currPageIdx 현재페이지
 * @param params.isEslNstl ESL 설치 유형
 * @param params.isNtwrCnst 네트워크 공사 유형
 * @param params.isShowAll 전체 보기
 * @param params.nstlSttsCode 설치 상태 코드(-: 전체작업, prcd: 진행중, work_cmpl: 작업완료, dly:지연)
 * @param params.pageSize 한번에 보여질 갯수
 * @param params.prjcCode 프로젝트 코드(기본값 : EMS 관리 대상 계약 프로젝트 조회 후 첫번째 index 값)
 * @param params.cmpnCode 사용자 회사 코드(협력사 여부)
 * @param params.userId 사용자 아이디
 * @param params.workBaseYearMonth 작업 기준 년월
 * @param params.pmId PM 담당자 아이디
 */
export const getPrcds = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/nstl/prcds',
    params: {
      currPageIdx: params.currPageIdx || 1,
      isEslNstl: params.isEslNstl || 'Y',
      isNtwrCnst: params.isNtwrCnst || 'Y',
      isShowAll: params.isShowAll || 'Y',
      nstlSttsCode: params.nstlSttsCode,
      pageSize: params.pageSize || 10000,
      prjcCode: params.prjcCode || '',
      cmpnCode: params.cmpnCode || '',
      userId: params.userId || '',
      workBaseYearMonth: params.workBaseYearMonth || '',
      pmId: params.pmId || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 진행중 작업 특정일 조회
 * GET /api/nstl/prcds/{yyyymmdd}
 * @param params.isEslNstl ESL 설치 유형
 * @param params.isNtwrCnst 네트워크 공사 유형
 * @param params.nstlSttsCode 설치 상태 코드(-: 전체작업, prcd: 진행중, work_cmpl: 작업완료, dly:지연)
 * @param params.prjcCode 프로젝트 코드(기본값 : EMS 관리 대상 계약 프로젝트 조회 후 첫번째 index 값)
 * @param params.yyyymmdd 검색일자
 */
export const getPrcdsAtDay = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nstl/prcds', params.yyyymmdd].join('/'),
    params: {
      isEslNstl: params.isEslNstl || 'Y',
      isNtwrCnst: params.isNtwrCnst || 'Y',
      nstlSttsCode: params.nstlSttsCode,
      prjcCode: params.prjcCode || '',
      yyyymmdd: params.yyyymmdd || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 진행중 작업 상세 조회
 * GET /api/nstl/prcds/{dcmnDntfNmbr}
 * @param params.getPrcdsDcmnDntfNmbr 주문번호
 */
export const getPrcdsDcmnDntfNmbr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nstl/prcd', params.dcmnDntfNmbr].join('/'),
    params: {},
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통 - 작업 결과 조회(네트워크)
 * GET /api/nstl/work-ntwr
 * @param dcmnDntfNmbr 주문번호
 * @param splrId 협력사 아이디
 * @param teamId 협력사(팀) 아이디
 */
export const getNstlWorkNtwr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/nstl/work-ntwr',
    params: {
      dcmnDntfNmbr: params.dcmnDntfNmbr,
      splrId: params.splrId,
      teamId: params.teamId,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통 - 작업 결과 수정(네트워크)
 * PUT /api/nstl/work-ntwr/{dcmnDntfNmbr}
 * @param params dcmnDntfNmbr 주문번호
 * @param params installWorkNtwrParamList.ntwrWorkCode 네트워크작업 코드(게이트웨이IP:GTWY_IP, 서버IP:SRVR_IP)
 * @param params installWorkNtwrParamList.ttl 내용
 * @param params ntslSttsCode 설치상태 코드(PRCD: 진행중, WORK_CMPL: 작업완료, NSPC_CMPL: 검수완료)
 * @param params splrId 협력사 아이디
 */
export const putNstlWorkNtwr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/nstl/work-ntwr', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putNstlWorkNtwr Error:', putNstlWorkNtwr);
    });

  return result;
};

/**
 * 공통 - 작업 결과 조회(ESL)
 * GET /api/nstl/work-esl
 * @param dcmnDntfNmbr 주문번호
 * @param splrId 협력사 아이디
 * @param teamId 협력사(팀) 아이디
 */
export const getNstlWorkEsl = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/nstl/work-esl',
    params: {
      dcmnDntfNmbr: params.dcmnDntfNmbr,
      splrId: params.splrId,
      teamId: params.teamId,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 공통 - 작업 결과 수정(ESL)
 * PUT /api/nstl/work-esl/{dcmnDntfNmbr}
 * @param params dcmnDntfNmbr 주문번호
 * @param params installWorkEslParamList.itemNmbr 품목 번호
 * @param params installWorkEslParamList.lostQntt 분실 수량
 * @param params installWorkEslParamList.nsttQntt 설치 수량
 * @param params installWorkEslParamList.rtrnDfctQntt 반납 불량 수량
 * @param params installWorkEslParamList.rtrnNrmlQntt 반납 일반 수량
 * @param params ntslSttsCode 설치상태 코드(PRCD: 진행중, WORK_CMPL: 작업완료, NSPC_CMPL: 검수완료)
 * @param params splrId 협력사 아이디
 */
export const putNstlWorkEsl = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/nstl/work-esl', params.dcmnDntfNmbr].join('/'),
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putNstlWorkNtwr Error:', putNstlWorkNtwr);
    });

  return result;
};

/**
 * 공통 - 재고 이전 요청(팝업용)
 * POST /api/nstl/nvnt-rqst
 * @param params dcmnDntfNmbr 주문번호
 * @param params splrId 협력사 아이디
 * @param params list.itemCode 품목 코드
 * @param params list.ttl 내용
 * @param params list.quantity 수량
 * @param params list.stckSttsCode 재규 상태(CMN: 일반, EROR: 불량)
 * @param params list.stckTypeCode 재고 유형(STCK_RTRN: 재고반납, STCK_AD: 재고 추가)
 */
export const postNstlNvntRgst = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/nstl/nvnt-rqst',
    data: params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postNstlNotRgst Error:', postNstlNotRgst);
    });

  return result;
};

/**
 * 공통 - 진행 상황 등록(댓글)
 * POST /api/nstl/prgr
 * @param params dcmnDntfNmbr dcmnDntfNmbr
 * @param params splrId 협력사 아이디
 * @param params teamId 협력사(팀) 아이디
 * @param params dtl 댓글 내용
 * @param params fileList 파일 목록
 */
export const postNstlPrgr = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: '/api/nstl/prgr',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      dcmnDntfNmbr: params.dcmnDntfNmbr || '',
      splrId: params.splrId || '',
      teamId: params.teamId || '',
      fileList: params.fileList || [],
      dtl: params.dtl || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      console.log('> postNstlPrgr:', response.data);
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postNstlPrgr Error:', postNstlPmNspcDetail);
    });

  return result;
};

/**
 * 공통 - 진행 상황 수정(댓글)
 * POST /api/nstl/prgr/{nstlPrgrId}
 * @param params dcmnDntfNmbr 문서번호
 * @param params splrId 협력사 아이디
 * @param params nstlPrgrId 설치 진행상황 아이디
 * @param params bfFileIdList 기존 파일 아이디 목록
 * @param params fileList 새 파일 목록
 * @param params dtl 댓글 내용
 */
export const postNstlPrgrNstlPrgrId = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: ['/api/nstl/prgr', params.nstlPrgrId].join('/'),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: {
      dcmnDntfNmbr: params.dcmnDntfNmbr || '',
      splrId: params.splrId || '',
      teamId: params.teamId || '',
      bfFileIdList: params.bfFileIdList || [],
      fileList: params.fileList || [],
      dtl: params.dtl || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postNstlPrgrNstlPrgrId Error:', error);
    });

  return result;
};

/**
 * 공통 - 진행 상황 삭제(댓글)
 * DELETE /api/nstl/prgr/{nstlPrgrId}
 * @param params nstlPrgrId 설치 진행상황 아이디
 */
export const deleteNstlPrgrNstlPrgrId = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'DELETE',
    url: ['/api/nstl/prgr', params.nstlPrgrId].join('/'),
    data: {},
  })
    .then((response: AxiosResponse<any>) => {
      console.log('> deleteNstlPrgrNstlPrgrId:', response.data);
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> deleteNstlPrgrNstlPrgrId Error:', error);
    });

  return result;
};

/**
 * 계약별 체크리스트 항목 조회
 * GET /api/nstl/chck/{cntrNmbr}/{splrTypeCode}
 * @param cntrNmbr 계약번호
 * @param splrTypeCode 협력사 유형 코드(ESL_NSTL, NTWR_CNST)
 * @param useYn 사용 여부(true, false)
 */
export const getChckCntrNmbrSplrTypeCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: ['/api/nstl/chck', params.cntrNmbr, params.splrTypeCode].join('/'),
    params: {
      // useYn: params.useYn,
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 계약별 체크리스트 항목 등록
 * PUT /api/nstl/chck/{cntrNmbr}/{splrTypeCode}
 * @param params cntrNmbr 계약번호
 * @param params contentVOs 체크 항목
 * @param params splrTypeCode 협력사 유형 코드(NTWR_CNST, ESL_NSTL)
 */
export const putChckCntrNmbrSplrTypeCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'PUT',
    url: ['/api/nstl/chck', params.cntrNmbr, params.splrTypeCode].join('/'),
    data: params.contentVOs,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> putChckCntrNmbrSplrTypeCode Error:', error);
    });

  return result;
};

/**
 * 작업별 체크결과 조회
 * GET /api/nstl/chck/rslt/{cntrNmbr}/{dcmnDntfNmbr}/{splrTypeCode}/{prtnDate}/{splrId}/{teamId}
 * @param cntrNmbr 계약번호
 * @param dcmnDntfNmbr 문서번호
 * @param prtnDate 설치일
 * @param splrId 협력사 ID
 * @param splrTypeCode 협력사 유형 코드(ESL_NSTL, NTWR_CNST)
 * @param teamId 설치팀 ID
 */
export const getChckRsltCntrNmbrDcmnDntfNmbrSplrTypeCodePrtnDateSplrIdTeamId =
  async (params: any) => {
    // 결과를 정의함
    let result: IApiResult = {
      code: '',
      message: '',
      data: {},
    };

    await api({
      method: 'GET',
      url: [
        '/api/nstl/chck/rslt',
        params.cntrNmbr,
        params.dcmnDntfNmbr,
        params.splrTypeCode,
        params.prtnDate,
        params.splrId,
        params.teamId,
      ].join('/'),
      params: {},
    })
      .then((response: AxiosResponse<any>) => {
        result = response.data;
      })
      .catch((error: any) => {});

    return result;
  };

/**
 * 작업별 체크 결과 등록
 * PUT /api/nstl/chck/rslt/{cntrNmbr}/{dcmnDntfNmbr}/{splrTypeCode}/{prtnDate}/{splrId}/{teamId}
 * @param params cntrNmbr 계약번호
 * @param params contentVOs 체크 항목
 * @param params splrTypeCode 협력사 유형 코드(NTWR_CNST, ESL_NSTL)
 */
export const putChckRsltCntrNmbrDcmnDntfNmbrSplrTypeCodePrtnDateSplrIdTeamId =
  async (params: any) => {
    // 결과를 정의함
    let result: IApiResult = {
      code: '',
      message: '',
      data: {},
    };

    await api({
      method: 'PUT',
      url: [
        '/api/nstl/chck/rslt',
        params.cntrNmbr,
        params.dcmnDntfNmbr,
        params.splrTypeCode,
        params.prtnDate,
        params.splrId,
        params.teamId,
      ].join('/'),
      data: params.resultRegistVOs,
    })
      .then((response: AxiosResponse<any>) => {
        result = response.data;
      })
      .catch((error: any) => {
        console.log(
          '> putChckRsltCntrNmbrDcmnDntfNmbrSplrTypeCodePrtnDateSplrIdTeamId Error:',
          error,
        );
      });

    return result;
  };
