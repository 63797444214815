import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { Button, Select, TextInput, Checkbox, Radio } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { MN2302220713 } from '../components/MN2302220713'; //등록,상세,수정 Component
import * as ntcbApi from '../apis/ntcb.api';
import { getCsType } from '../apis/cs.api';
import { PbAgGridReact } from '../components/PbAgGridReact';
import PbSelect from '../components/PbSelect/PbSelect.component';
import { decode } from 'html-entities';
import * as appUtil from '../utils/app.util';

/**
 * 게시판 > FAQ
 * @constructor
 */
const MN2302220703Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);
  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);
  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);
  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);
  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**************************** API 호출 ****************************/
  //FAQ 유형 조회
  const getFaqCategory = () => {
    ntcbApi
      .getFaqCsType({
        depth: 1,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          //FAQ 유형 셀렉트 초기화
          let tmpOptionItem: IOptionItem[] = [];
          tmpOptionItem.push({ label: '전체', value: '' });
          for (var category of data.data.list) {
            tmpOptionItem.push({
              label: category.dtlCodeDscr,
              value: category.dtlCodeId,
            });
          }
          // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
          setFaqCategorySelect('');
          setFaqCategorySelectItem(tmpOptionItem);
          // setFaqCategorySelect({
          //   value: '',
          //   item: tmpOptionItem,
          // });
        }
      });
  };

  //FAQ 유형 상세 조회
  const getFaqDetailCategory = () => {
    ntcbApi
      .getFaqCsType({
        depth: 2,
        searchKeyword: faqCategorySelect,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          //FAQ 유형 셀렉트 초기화
          let tmpOptionItem: IOptionItem[] = [];
          tmpOptionItem.push({ label: '전체', value: '' });
          for (var category of data.data.list) {
            tmpOptionItem.push({
              label: category.dtlCodeDscr,
              value: category.dtlCodeId,
            });
          }
          // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
          setFaqDetailCategorySelect('');
          setFaqDetailCategorySelectItem(tmpOptionItem);
          // setFaqDetailCategorySelect({
          //   value: '',
          //   item: tmpOptionItem,
          // });
        }
      });
  };

  //FAQ 목록 조회
  const getFaqList = () => {
    // FAQ 테이블의 로딩 여부에 적용함
    setFaqTableLoading(true);

    ntcbApi
      .getFaqs({
        cstyGrpCode: faqCategorySelect,
        cstyCode: faqDetailCategorySelect,
        searchKeyword: faqTitleInput,
        pageSize: faqTablePaging.rowPerPage,
        currPageIdx: faqTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          var result = data.data.list;
          result = result
            .sort((a: any, b: any) => Number(a.sortRdr) - Number(b.sortRdr))
            .sort(
              (a: any, b: any) => a.ttl.toLowerCase() < b.ttl.toLowerCase(),
            );

          // 검색 결과 테이블에 적용함
          setFaqTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: result,
          }));

          // 테이블을 페이징함
          setFaqTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }

        // FAQ 테이블의 로딩 여부에 적용함
        setFaqTableLoading(false);
      });
  };

  /**************************** STATE ****************************/
  //제목 검색어
  const [faqTitleInput, setFaqTitleInput] = useState<string>('');

  //FAQ 유형 셀렉트 state
  const [faqCategorySelect, setFaqCategorySelect] = useState<string>('');
  const [faqCategorySelectItem, setFaqCategorySelectItem] = useState<
    IOptionItem[]
  >([]);

  //FAQ 유형 상세 셀렉트 state
  const [faqDetailCategorySelect, setFaqDetailCategorySelect] =
    useState<string>('');
  const [faqDetailCategorySelectItem, setFaqDetailCategorySelectItem] =
    useState<IOptionItem[]>([]);

  //FAQ 테이블 state
  const [faqTable, setFaqTable] = useState<{
    column: any[];
    data: any;
  }>({ column: [], data: [] });

  // FAQ 테이블 페이징을 정의함
  const [faqTablePaging, setFaqTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const faqTablePagingCurrentPageRef = useRef<number>(1);

  // FAQ 테이블의 로딩 여부를 정의함
  const [faqTableLoading, setFaqTableLoading] = useState<boolean>(false);

  // 검색 폼 그리드 > 상세검색의 나타냄을 정의함
  const [hideDetailSearch, setHideDetailSearch] = useState<boolean>(false);

  /**************************** ON EVENT LISTENER ****************************/
  //FAQ 제목 검색어 이벤트
  const handleFaqTitleInput_onChange = (event: any) => {
    setFaqTitleInput(event.target.value);
  };
  //FAQ 유형 셀렉트 변경 이벤트
  const handleFaqCategorySelect_onChange = (event: any) => {
    setFaqCategorySelect(event);
    // setFaqCategorySelect({
    //   ...faqCategorySelect,
    //   value: event,
    // });
  };
  //FAQ 유형 상세 셀렉트 변경 이벤트
  const handleFaqDetailCategorySelect_onChange = (event: any) => {
    setFaqDetailCategorySelect(event);
    // setFaqDetailCategorySelect({
    //   ...faqDetailCategorySelect,
    //   value: event,
    // });
  };

  //검색 조건 초기화 버튼 이벤트
  const handleCancelBtn_onClick = () => {
    setFaqTitleInput('');
    setFaqCategorySelect('');
    // setFaqCategorySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: '',
    // }));
    setFaqDetailCategorySelect('');
    // setFaqDetailCategorySelect((pre: ISelect) => ({
    //   ...pre,
    //   value: '',
    // }));
  };
  //제목 검색 on keyup
  const handleFaqTitleInput_onEnterKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 페이지를 변경함
      faqTablePagingCurrentPageRef.current = 1;
      setFaqTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: faqTablePagingCurrentPageRef.current,
      }));

      getFaqList();
    }
  };
  //제목 검색 버튼 이벤트
  const handleFaqSearchBtn_onClick = () => {
    // 테이블의 페이지를 변경함
    faqTablePagingCurrentPageRef.current = 1;
    setFaqTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: faqTablePagingCurrentPageRef.current,
    }));

    getFaqList();
  };
  //FAQ 목록 클릭 (ROW Click)
  const handleFaqTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220713';
    //컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: 'FAQ 상세',
      content: (
        <MN2302220713 id={tmpId} brdId={row.data.brdId} useModifyMode={false} />
      ),
      size: 1500,
    });
  };
  //등록 버튼 클릭
  const handleFaqAddBtn_onClick = () => {
    let tmpId: string = 'MN2302220713';
    setAddComponentModal({
      id: tmpId,
      title: 'FAQ 등록',
      content: <MN2302220713 id={tmpId} />,
      size: 1500,
    });
  };

  // 검색 폼 그리드 > 검색어 > 상세검색 열기닫기 버튼을 클릭함
  const handleShowSearchDetailButton_onChange = () => {
    setHideDetailSearch(!hideDetailSearch);
  };

  /**************************** INIT ****************************/
  //FAQ 테이블 초기화
  const initFaqTable = () => {
    let tmpColumn: any[] = [];
    tmpColumn.push(
      {
        field: 'no',
        headerName: '번호',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        headerName: 'CS 유형',
        children: [
          {
            headerName: '그룹',
            field: 'cstyGrpName',
            width: 170,
            suppressSizeToFit: true,
          },
          {
            headerName: '상세',
            field: 'cstyName',
            width: 170,
            suppressSizeToFit: true,
          },
        ],
      },
      {
        field: 'ttl',
        headerName: '제목',
        valueFormatter: (params: any) => decode(params.value),
      },
      {
        field: 'xpsrStts',
        headerName: '노출 상태',
        width: 100,
        suppressSizeToFit: true,
      },
      // {
      //   field: 'sortRdr',
      //   headerName: '노출 순위',
      // },
      {
        field: 'rgstDate',
        headerName: '등록일',
        width: 130,
        suppressSizeToFit: true,
      },
      // {
      //   field: 'mdfcDate',
      //   headerName: '수정일'
      // }
    );
    setFaqTable((pre: { column: any[]; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    initFaqTable();
    getFaqCategory();
    return () => {};
  }, []);

  //FAQ 테이블 값 초기화
  useEffect(() => {
    if (faqTable.column.length === 0) {
      return;
    }
    getFaqList();
    return () => {};
  }, [faqTable.column]);

  //유형 셀렉트 박스 변경 시
  useEffect(() => {
    if (faqCategorySelect === '') {
      let tmpOptionItem: IOptionItem[] = [];
      tmpOptionItem.push({ label: '전체', value: '' });
      // 검색 폼 그리드 > 고객사명 > 셀렉트의 아이템에 적용함
      setFaqDetailCategorySelect('');
      setFaqDetailCategorySelectItem(tmpOptionItem);
      // setFaqDetailCategorySelect({
      //   value: '',
      //   item: tmpOptionItem,
      // });
    } else {
      getFaqDetailCategory();
    }
    return () => {};
  }, [faqCategorySelect]);

  //유형 상세 셀렉트 박스 변경 시
  // useEffect(() => {
  //   getFaqList();
  //   return () => {};
  // }, [faqDetailCategorySelect.value]);

  //목록 새로고침 시
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }
    if (_.indexOf(refreshList, 'MN2302220703Table') > -1) {
      // 테이블의 페이지를 변경함
      faqTablePagingCurrentPageRef.current = 1;
      setFaqTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: faqTablePagingCurrentPageRef.current,
      }));

      // 검색 결과 테이블 데이터를 불러옴
      getFaqList();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220703Table');
    }
    return () => {};
  }, [refreshList]);

  return (
    <PageLayout
      // pageInfoBarLeftArea={<></>}
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={
        <>
          <Button color="indigo" radius="xl" onClick={handleFaqAddBtn_onClick}>
            등록
          </Button>
        </>
      }
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid label="" cols={2}>
            <PbFormGridCol label="제목" colSpan={2}>
              <TextInput
                placeholder="검색어를 입력하세요."
                onChange={handleFaqTitleInput_onChange}
                onKeyUp={handleFaqTitleInput_onEnterKeyPress}
                value={decode(faqTitleInput)}
                className="w-full"
              />
              {/* 버튼 */}
              <div className="flex justify-center items-center space-x-2">
                <Button
                  color="indigo"
                  radius="xl"
                  onClick={handleFaqSearchBtn_onClick}
                >
                  검색
                </Button>
                <Button
                  color="gray"
                  variant="outline"
                  radius="xl"
                  onClick={handleCancelBtn_onClick}
                >
                  검색 조건 초기화
                </Button>
                {/* 버튼 */}
                <Button
                  variant="outline"
                  color="gray"
                  radius="xl"
                  onClick={handleShowSearchDetailButton_onChange}
                >
                  {hideDetailSearch ? (
                    <>
                      상세검색 열기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-down']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      상세검색 닫기
                      <div className="ml-1 flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'caret-up']}
                          className="w-5 h-5"
                        />
                      </div>
                    </>
                  )}
                </Button>
              </div>
            </PbFormGridCol>

            <PbFormGridCol label="유형" colSpan={2} isHidden={hideDetailSearch}>
              <Select
                onChange={handleFaqCategorySelect_onChange}
                data={faqCategorySelectItem}
                value={faqCategorySelect}
                className="w-full"
              />
              <Select
                onChange={handleFaqDetailCategorySelect_onChange}
                data={faqDetailCategorySelectItem}
                value={faqDetailCategorySelect}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 테이블 */}
          <div className="space-y-3">
            <div className="flex justify-between items-center space-x-5">
              <div className="flex justify-center items-center">
                <span className="text-base text-gray-600 font-bold">
                  총 {faqTable.data.length}건
                </span>
              </div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-172">
              <PbAgGridReact
                columnDefs={faqTable.column}
                rowData={faqTable.data}
                loading={faqTableLoading}
                setLoading={setFaqTableLoading}
                onRowDoubleClicked={handleFaqTable_onRowDoubleClicked}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
                visiblePaging={true}
                paging={{
                  totalPage: faqTablePaging.totalPage,
                  currentPage: faqTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  faqTablePagingCurrentPageRef.current = event;
                  setFaqTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: faqTablePagingCurrentPageRef.current,
                  }));

                  // 테이블 데이터를 불러옴
                  getFaqList();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220703Page;
