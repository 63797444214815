import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import { PbAgGridReact } from '../PbAgGridReact';
import { ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  Textarea,
  FileInput,
  FileButton,
  Checkbox,
  Group,
  Radio,
  Anchor,
  Overlay,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
  IComponentModalPageScrollTab,
  IComponentModalPageScrollTabStep,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PageLayout } from '../../components/PageLayout';
import { PbFormGrid } from '../../components/PbFormGrid';
import { PbFormGridCol } from '../../components/PbFormGridCol';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../../stores/refreshList.store';
import { HtmlEditor } from '../HtmlEditor';
import { useElementSize } from '@mantine/hooks';
import { decode, encode } from 'html-entities';
import PbSection from '../PbSection/PbSection.component';
import { notifications } from '@mantine/notifications';
import { overlayStore } from '../../stores/overlay.store';
import * as nstlApi from '../../apis/nstl.api';
import * as ntcbApi from '../../apis/ntcb.api';
import MN2302220353_SwInfo from '../MN2302220353_SwInfo/MN2302220353_SwInfo.component';
import MN2302220353_EslSetup from '../MN2302220353_EslSetup/MN2302220353_EslSetup.component';
import * as appUtil from '../../utils/app.util';

interface MN2302220354Props {
  id?: string;
  dcmnDntfNmbr?: string;
  data?: any;
  onClick?: () => void;
}

/**
 * 설치관리 > 완료작업 빌링 현황 > 빌링 현황 상세
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220354 = ({
  id = '',
  dcmnDntfNmbr = '',
  data,
  onClick,
}: PropsWithChildren<MN2302220354Props>) => {
  // 언어를 정의함
  const { t } = useTranslation();
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);
  // 화면 위 검은 화면 저장소를 정의함
  const [visibleOverlay, setVisibleOverlay] =
    useRecoilState<boolean>(overlayStore);

  /**************************** API ****************************/
  //빌링 현황 상세 조회
  const getBillingDetail = () => {
    nstlApi
      .getNstlBillingDetail({
        dcmnDntfNmbr: dcmnDntfNmbr,
      })
      .then((data: IApiResult) => {
        if (data.data != null) {
          //판매 계약 정보
          setCntrNmbrInput(data.data.cntrNmbr);
          setDcmnScrnNmbrInput(data.data.dcmnScrnNmbr);
          setPrjcNameInput(data.data.prjcName);
          setPrjcCodeInput(data.data.prjcCode);
          setWorkScheduleInput(
            `${data.data.fctvStrtDate} ~ ${data.data.fctvEndDate}`,
          );
          setBpNameInput(data.data.bpName);
          setBpCodeInput(data.data.bpCode);
          setShipToCodeInput(data.data.shipToCode);
          setDlvrNmbrInput(
            data.data.dlvrNmbr === '' ? '-' : data.data.dlvrNmbr,
          );

          setWorkTotalDateInput(
            `${data.data.workStrtDttm} ~ ${data.data.workEndDttm}`,
          );
          setWorkDateInput(data.data.workDate);

          //빌링 정보
          setRnvcNmbrInput(
            data.data.rnvcNmbr === '' ? '-' : data.data.rnvcNmbr,
          );
          setBillDateInput(
            data.data.billDate === '' ? '-' : data.data.billDate,
          );
          //버전 정보
          setVrsnNfrmInput(data.data.vrsnNfrm);
          //설치 확인서
          setFileTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.fileList,
          }));
          //네트워크공사내역
          setNtwkTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.ntwrList,
          }));
          //ESL설치작업내역
          setEslTable((pre: { column: any; data: any }) => ({
            ...pre,
            data: data.data.eslList,
          }));
        }
      });
  };

  /**************************** STATE ****************************/
  //판매 계약 정보
  const [cntrNmbrInput, setCntrNmbrInput] = useState<string>(''); //판매 계약 번호
  const [dcmnScrnNmbrInput, setDcmnScrnNmbrInput] = useState<string>(''); //주문 번호
  const [prjcNameInput, setPrjcNameInput] = useState<string>(''); //프로젝트명
  const [prjcCodeInput, setPrjcCodeInput] = useState<string>(''); //프로젝트 코드
  const [workScheduleInput, setWorkScheduleInput] = useState<string>(''); //프로젝트 일정
  const [bpNameInput, setBpNameInput] = useState<string>(''); //고객사명
  const [bpCodeInput, setBpCodeInput] = useState<string>(''); //고객사코드
  const [shipToCodeInput, setShipToCodeInput] = useState<string>(''); //Shop명
  const [dlvrNmbrInput, setDlvrNmbrInput] = useState<string>(''); //납품 번호
  const [workTotalDateInput, setWorkTotalDateInput] = useState<string>(''); //전체 작업 기간
  const [workDateInput, setWorkDateInput] = useState<string>(''); //PM 검수 완료일시
  // 빌링 정보
  const [rnvcNmbrInput, setRnvcNmbrInput] = useState<string>(''); //AR 송장번호
  const [billDateInput, setBillDateInput] = useState<string>(''); //계산서 발행일
  //버전 정보
  const [vrsnNfrmInput, setVrsnNfrmInput] = useState<string>(''); //버전정보

  // 설치 확인서 > 파일 테이블
  const fileTableRef = useRef<any>(null);
  const [fileTable, setFileTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  // 네트워크 공사 작업 내역
  const ntwkTableRef = useRef<any>(null);
  const [ntwkTable, setNtwkTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  // ESL 설치 작업 내역
  const eslTableRef = useRef<any>(null);
  const [eslTable, setEslTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  /**************************** ON EVENT LISTENER ****************************/
  // 첨부파일 다운로드를 클릭함
  const handleFileDownload_onClick = (fileId: string, rgnlFileName: string) => {
    ntcbApi
      .getNncmFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  // 네트워크 공사 작업 내역 상세보기
  // const handleNetworkSwInfoDetailButton_onClick = (row: any) => {
  //   let tmpId: string = nanoid();

  //   // 컴포넌트 모달을 추가함
  //   setAddComponentModal({
  //     id: tmpId,
  //     title: '소프트웨어 정보',
  //     content: (
  //       <MN2302220353_SwInfo
  //         data={row.data.ntwrList}
  //         id={tmpId}
  //       />
  //     ),
  //     size: 1500,
  //   });
  // };

  // ESL 설치 등록정보
  // const handleEslSetUpDetailButton_onClick = (row: any) => {
  //   let tmpId: string = nanoid();

  //   // 컴포넌트 모달을 추가함
  //   setAddComponentModal({
  //     id: tmpId,
  //     title: 'ESL 설치 등록 정보',
  //     content: (
  //       <MN2302220353_EslSetup
  //         data={row.data}
  //         id={tmpId}
  //       />
  //     ),
  //     size: 1500,
  //   });
  // };

  /**************************** INIT ****************************/

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    tmpTab = [
      {
        label: '판매계약',
        targetId: 'step-1',
      },
      {
        label: '설치확인서',
        targetId: 'step-2',
      },
      {
        label: '네트워크 작업',
        targetId: 'step-3',
      },
      {
        label: 'ESL 작업',
        targetId: 'step-4',
      },
    ];

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 설치확인서 그리드 > 파일 테이블 초기화
  const initFileTable = () => {
    let tmpColumn: any = [];
    tmpColumn.push(
      {
        headerName: '첨부',
        field: 'rgnlFileName',
        editable: false,
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center space-x-3">
            <div
              onClick={() =>
                handleFileDownload_onClick(
                  params.data.fileId,
                  params.data.rgnlFileName,
                )
              }
              title={`다운로드: ${params.data.rgnlFileName}`}
              className="button-event px-0.5 py-0.5 flex justify-center items-center"
            >
              <FontAwesomeIcon
                icon={['far', 'file']}
                className="w-5 h-5 text-gray-500"
              />
            </div>
            <div>
              <span>{params.value}</span>
            </div>
          </div>
        ),
      },
      {
        headerName: '용량',
        field: 'fileSize',
        width: 120,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => prettyBytes(_.toNumber(params.value)),
      },
      {
        headerName: '설명',
        field: 'fileDscr',
      },
    );
    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  //네트워크 공사 작업 내역"
  const initNtwkTable = () => {
    let tmpColumn: any = [];
    tmpColumn.push(
      {
        headerName: '네트워크 내용',
        field: 'ntwrTtl',
      },
      {
        headerName: '구분',
        field: 'ntwrWorkName',
      },
      {
        headerName: '내용',
        field: 'ttl',
      },
      {
        headerName: '작업 일정',
        field: 'workSchedule',
        valueFormatter: (params: any) => {
          return `${params.data.workStrtDttm} ~ ${params.data.workEndDttm}`;
        },
      },
      // {
      //   headerName: '내용',
      //   field: 'ttl',
      // },
      // {
      //   headerName: '상태',
      //   field: 'nstlSttsName',
      //   width: 150,
      //   suppressSizeToFit: true,
      // },
      // {
      //   headerName: '작업 일정',
      //   field: 'workDate',
      //   width: 450,
      //   suppressSizeToFit: true,
      //   valueFormatter: (params: ValueFormatterParams) => {
      //     return `${moment(params.data.workStrtDttm).format(
      //       'YYYY-MM-DD HH:mm',
      //     )} ~ ${moment(params.data.workEndDttm).format('YYYY-MM-DD HH:mm')}`;
      //   },
      // },
      // {
      //   headerName: '협력사명',
      //   field: 'prtnName',
      // },
    );
    // 첨부 파일 섹션 > 테이블에 적용함
    setNtwkTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  //ESL 설치 작업 내역
  const initEslTable = () => {
    let tmpColumn: any = [];
    tmpColumn.push(
      {
        headerName: '내용',
        field: 'ttl',
      },
      {
        headerName: '품목 번호',
        field: 'itemNmbr',
      },
      {
        headerName: '설치 수량',
        field: 'nstlQntt',
      },
      {
        headerName: '분실 수량',
        field: 'lostQntt',
      },
      {
        headerName: '반납 예정 수량(불량)',
        field: 'rtrnDfctQntt',
      },
      {
        headerName: '반납 예정 수량(일반)',
        field: 'rtrnNrmlQntt',
      },
      {
        headerName: '작업 일정',
        field: 'workSchedule',
        valueFormatter: (params: any) => {
          return `${params.data.workStrtDttm} ~ ${params.data.workEndDttm}`;
        },
      },
    );
    // 첨부 파일 섹션 > 테이블에 적용함
    setEslTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };
  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    getBillingDetail(); //빌링 현황 상세 조회
    initFileTable(); //설치확인서 파일 테이블 초기화
    initNtwkTable(); //네트워크설치공사 테이블 초기화
    initEslTable(); //ESL설치 작업 내역
    return () => {};
  }, []);

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (
      fileTable.column.length === 0 ||
      ntwkTable.column.length === 0 ||
      eslTable.column.length === 0
    ) {
      return;
    }

    // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블 데이터를 불러옴
    getBillingDetail();
    return () => {};
  }, [fileTable.column, ntwkTable.column, eslTable.column]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 판매 계약 정보 그리드 */}
        <PbFormGrid id="step-1" label="판매 계약 정보" cols={2}>
          <PbFormGridCol label="판매 계약 번호" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={cntrNmbrInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="주문 번호" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={dcmnScrnNmbrInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="프로젝트명" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={prjcNameInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="프로젝트 코드" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={prjcCodeInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="프로젝트 일정" colSpan={2}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={workScheduleInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객사명" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={bpNameInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="고객사 코드" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={bpCodeInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="Shop명" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={shipToCodeInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="납품 번호" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={dlvrNmbrInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="전체 작업 기간" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={workTotalDateInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="PM 검수 완료일시" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={workDateInput}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 빌링 정보 그리드 */}
        <PbFormGrid label="빌링 정보" cols={2}>
          <PbFormGridCol label="AR 송장번호" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={rnvcNmbrInput}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="계산서 발행일" colSpan={1}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={billDateInput}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 버전 정보 그리드 */}
        <PbFormGrid label="버전 정보" cols={2}>
          <PbFormGridCol label="소프트웨어 버전" colSpan={2}>
            <TextInput
              readOnly={true}
              variant={'unstyled'}
              value={vrsnNfrmInput}
              className="w-full"
            />
          </PbFormGridCol>
        </PbFormGrid>

        {/* 설치 확인서 그리드 */}
        <PbFormGrid id="step-2" label="설치 확인서" cols={1}>
          <div className="w-full h-80">
            <PbAgGridReact
              refs={fileTableRef}
              columnDefs={fileTable.column}
              rowData={fileTable.data}
              defaultColDef={{
                resizable: true,
                sortable: true,
                editable: false,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              sizeColumnsToFit={true}
              rowSelection="single"
              overlayNoRowsTemplate="데이터가 없습니다."
              className="ag-theme-alpine"
            />
          </div>
        </PbFormGrid>

        {/* 네트워크 공사 작업 내역 그리드 */}
        <PbFormGrid id="step-3" label="네트워크 공사 작업 내역" cols={1}>
          <div className="w-full h-80">
            <PbAgGridReact
              refs={ntwkTableRef}
              columnDefs={ntwkTable.column}
              rowData={ntwkTable.data}
              // onRowClicked={handleNetworkSwInfoDetailButton_onClick}
              defaultColDef={{
                resizable: true,
                sortable: true,
                editable: false,
                wrapHeaderText: false,
                autoHeaderHeight: true,
                suppressSizeToFit: false,
              }}
              sizeColumnsToFit={true}
              rowSelection="single"
              overlayNoRowsTemplate="데이터가 없습니다."
              className="ag-theme-alpine"
            />
          </div>
        </PbFormGrid>

        {/* ESL 설치 작업 내역 그리드 */}
        <PbFormGrid id="step-4" label="ESL 설치 작업 내역" cols={1}>
          <div className="w-full h-80">
            <PbAgGridReact
              refs={eslTableRef}
              columnDefs={eslTable.column}
              rowData={eslTable.data}
              // onRowClicked={handleEslSetUpDetailButton_onClick}
              defaultColDef={{
                resizable: true,
                sortable: true,
                editable: false,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              sizeColumnsToFit={true}
              rowSelection="single"
              overlayNoRowsTemplate="데이터가 없습니다."
              className="ag-theme-alpine"
            />
          </div>
        </PbFormGrid>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 버튼 */}
        <Button
          variant="outline"
          color="gray"
          radius="xl"
          size="md"
          onClick={() => setRemoveComponentModal(id)}
        >
          닫기
        </Button>
      </div>
    </div>
  );
};

export default MN2302220354;
