import React, { PropsWithChildren, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Button, Input, TextInput } from '@mantine/core';
import * as cmnApi from '../../apis/cmn.api';
import {
  addComponentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { IApiResult, IModal } from '../../interfaces/app.interface';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { getFindPassword } from '../../apis/cmn.api';
import _ from 'lodash';
import { overlayStore } from '../../stores/overlay.store';
import { notifications } from '@mantine/notifications';

interface IFindPasswordProps {
  id?: string;
  data?: any;
  onClick?: () => void;
}

/**
 * 로그인 > 비밀번호 초기화
 * @param id 화면 아이디
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const FindPassword = ({
  id = '',
  data,
  onClick,
}: PropsWithChildren<IFindPasswordProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 화면 위 검은 화면 저장소를 정의함
  const [visibleOverlay, setVisibleOverlay] =
    useRecoilState<boolean>(overlayStore);

  // 아이디 입력을 정의함
  const [userId, setUserId] = useState<string>('');

  // 로그인 아이디 입력을 정의함
  const [lgnId, setLgnId] = useState<string>('');

  // 이메일주소 입력을 정의함
  const [email, setEmail] = useState<string>('');

  // 로그인 아이디 입력의 값을 변경함
  const handleLgnIdInput_onChange = (event: any) => {
    setLgnId(event.target.value);
  };

  // 아이디 입력의 값을 변경함
  const handleUserIdInput_onChange = (event: any) => {
    setUserId(event.target.value);
  };

  // 이메일주소 입력의 값을 변경함
  const handleEmailInput_onChange = (event: any) => {
    setEmail(event.target.value);
  };

  // 이메일주소로 확인 메일 발송 버튼을 클릭함
  const handleSendEmailButton_onClick = () => {
    // 처리 순서
    // 1. DB에서 아이디와 이메일주소가 일치하는 사용자를 조회함(이미 API에서 구현되어 있을 수 있음)
    // 2. 사용자에게 확인 메일을 보냄
    // 3. 웹브라우저에서 사용자의 메일함(개인이 사용하는 메일 서비스 사이트)으로 접속하여 메일이 왔는지 확인함
    // 4. 메일이 왔으면 어떠한 링크 주소가 포함되어 있을 것임
    // 5. 링크 주소를 클릭하면 비밀번호 변경 페이지로 이동함(컴포넌트를 새로 만들어야 함)
    // 6. 비밀번호 변경 컴포넌트가 출력되면, 사용자가 자신의 새 비밀번호를 입력하고 확인 버튼을 클릭함
    // 7. 새 비밀번호가 DB에 저장됨
    //
    // 참고
    // 1. 사용자 로그인 비밀번호는 기본값 1234로 되어 있음
    // 2. 로그인 할 때마다 사용자가 초기 비밀번호를 변경했는지를 확인함(변경했는지를 확인하는 컬럼이 있음)
    // 3. 비밀번호 변경 컴포넌트에서 비밀번호를 변경하면 해당 확인 컬럼의 값을 변경함

    // if(!userId){
    //   setModal({
    //     title: '알림',
    //     content: '사용자 아이디를 입력하세요.',
    //   });

    //   return;
    // }

    // 사용자 정보 가져오기 - 아이디 / Email 비교
    // getCmnUserInfo();

    // 입력된 비밀번호로 임시 비밀번호 발송
    initUserPassword();
  };

  /**
   * 사용자 아이디, 이메일 확인
   */
  // 권한 정보 상세 데이터를 정의함
  const [cmnUserInfo, setCmnUserInfo] = useState<any>(null);

  // 권한 정보 상세 데이터를 불러옴
  const getCmnUserInfo = () => {
    cmnApi
      .getUser({
        userId: userId,
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 권한 정보 상세 데이터에 적용함
          setCmnUserInfo(data.data);
        }
      });
  };
  /* // 사용자 아이디, 이메일 확인 */

  /**
   * 입력된 Email 로 임시 비밀번호 발송
   */
  const initUserPassword = () => {
    if (_.isEmpty(lgnId)) {
      setModal({
        title: '알림',
        content: '아이디(이메일)를 입력하세요.',
      });
      return;
    }

    // 화면 위의 검은 화면 출력 여부에 적용함
    setVisibleOverlay(true);

    notifications.show({
      title: '알림',
      message: '메일을 발송 중입니다.',
    });

    cmnApi
      .getFindPassword({
        lgnId: lgnId,
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          case '200':
            // 화면 위의 검은 화면 출력 여부에 적용함
            setVisibleOverlay(false);

            notifications.show({
              title: '알림',
              message: '메일을 발송했습니다.',
            });

            // 컴포넌트 모달을 닫음
            setRemoveComponentModal(id);

            // setModal({
            //   title: '알림',
            //   content: '메일을 발송했습니다.',
            //   callback() {
            //     // 컴포넌트 모달을 닫음
            //     setRemoveComponentModal(id);
            //   },
            // });
            break;

          case '500':
            // 화면 위의 검은 화면 출력 여부에 적용함
            setVisibleOverlay(false);

            console.log('> 메일 발송에 Error:', data);
            setModal({
              title: '오류',
              content: '메일 발송에 실패하였습니다.',
            });
            break;

          default:
            // 화면 위의 검은 화면 출력 여부에 적용함
            setVisibleOverlay(false);
            break;
        }
      })
      .catch((error: any) => {
        // 화면 위의 검은 화면 출력 여부에 적용함
        setVisibleOverlay(false);

        console.log('> 권한 정보 수정 Error:', error);
        setModal({
          title: '오류',
          content: '메일 발송에 실패하였습니다.',
        });
      });
  };

  /* // 입력된 Email 로 임시 비밀번호 발송 */

  // 사용자 아이디, Email 확인
  // useEffect(() => {

  //   if (!cmnUserInfo) {
  //     return;
  //   }

  //   if (email === cmnUserInfo.ml) {
  //     initUserPassword();
  //   }
  //   return () => {};
  // }, [cmnUserInfo]);

  return (
    <div className="pb-2 space-y-5">
      {/* 입력폼 */}
      <div className="w-full space-y-2">
        {/* 아이디 */}
        {/* <div className="flex justify-center items-center space-x-2">
          <div className="w-20 flex justify-start items-center">
            <span className="text-sm text-gray-500 font-semibold">아이디</span>
          </div>
          <TextInput
            placeholder="아이디를 입력하세요."
            onChange={handleUserIdInput_onChange}
            value={userId}
            className="grow"
          />
        </div> */}

        {/* 이메일 주소 */}
        <div className="flex justify-center items-center space-x-2">
          <div className="w-20 flex justify-start items-center">
            <span className="text-sm text-gray-500 font-semibold">아이디</span>
          </div>

          {/* 텍스트 입력 */}
          <TextInput
            placeholder="아이디(이메일)를 입력하세요."
            onChange={handleLgnIdInput_onChange}
            value={lgnId}
            className="grow"
          />
        </div>
      </div>

      {/* 버튼 */}
      <div className="flex justify-center items-center">
        {/* 버튼 */}
        <Button
          color="indigo"
          radius="xl"
          onClick={handleSendEmailButton_onClick}
          className="grow"
        >
          임시 비밀번호 메일 발송
        </Button>
      </div>

      {/* 참고 */}
      <div className="flex justify-center items-center">
        <span className="text-sm text-gray-500 font-semibold">
          임시 비밀번호를 이메일로 발송합니다.
        </span>
      </div>
    </div>
  );
};

export default FindPassword;
